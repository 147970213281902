// src/utils/milestones/milestoneStyles.js

/**
 * Creates style objects for milestone components with proper color handling
 * @param {Object} milestone The milestone data object
 * @returns {Object} Style objects for various components
 */
export const getMilestoneStyles = (milestone) => {
  // Colors are defined in the milestone object
  const { colors } = milestone;

  // Default main colors from the application
  const defaultColors = {
    red: "#991616",
    gold: "#d19a10",
    green: "#848717",
    white: "#FCFCEE",
    gray: "#808080",
    darkgray: "#DADADA",
  };

  // Handle the multicolor special case
  const primaryColor =
    colors.primary === "multicolor" ? defaultColors.gray : colors.primary;

  // Use safe color values for backgrounds - prevent Tailwind processing issues
  const fromColor = colors.gradient.from.replace(/\//g, "-");
  const viaColor = colors.gradient.via;
  const toColor = colors.gradient.to;

  // Handle highlight color safely
  const highlightColor = colors.highlight.split("/")[0];
  const highlightOpacity = colors.highlight.includes("/")
    ? colors.highlight.split("/")[1]
    : "100";

  return {
    // Card styles
    card: {
      // Use direct background style instead of classes for gradients
      backgroundGradient: "", // This will now be applied as inline style
      borderHover:
        colors.primary === "multicolor"
          ? "hover:border-[#616a37]"
          : `hover:border-[${highlightColor}]`,
    },

    // Header styles
    header: {
      headerBg: `bg-[${colors.secondary}]`,
      titleColor: "text-black", // Consistent for all milestones
    },

    // Button styles
    buttons: {
      primaryButton: `bg-[${primaryColor}]`,
      primaryButtonHover: `hover:bg-[${highlightColor}]`,
    },

    // Progress bar
    progressBar: {
      gradient: "", // This will now be applied as inline style
    },

    // Text styles
    text: {
      highlightBg: "", // This will now be applied as inline style
      primaryColor: `text-[${primaryColor}]`,
    },

    // Modal styles
    modal: {
      boxShadow: primaryColor,
    },

    // Raw color values for inline styles
    colors: {
      primary: primaryColor,
      secondary: colors.secondary,
      highlight: highlightColor,
      highlightOpacity: highlightOpacity,
      gradient: {
        from: fromColor.replace(/\[|\]/g, ""),
        via: viaColor.replace(/\[|\]/g, ""),
        to: toColor.replace(/\[|\]/g, ""),
      },
    },
  };
};

/**
 * Creates inline style objects for React components
 * @param {Object} milestone The milestone data object
 * @returns {Object} Style objects for various components
 */
export const getMilestoneInlineStyles = (milestone) => {
  const { colors } = milestone;
  const styles = getMilestoneStyles(milestone);

  // Handle the multicolor special case
  const primaryColor =
    colors.primary === "multicolor" ? "#808080" : colors.primary;

  // Create gradient string for background
  const gradientBg = `linear-gradient(to bottom right, ${styles.colors.gradient.from}, ${styles.colors.gradient.via}, ${styles.colors.gradient.to})`;

  // Create gradient for progress bar
  const progressGradient = `linear-gradient(to right, ${colors.secondary}, ${primaryColor})`;

  // Create highlight background with opacity
  const highlightBg = styles.colors.highlightOpacity
    ? `rgba(${hexToRgb(styles.colors.highlight)}, ${
        parseInt(styles.colors.highlightOpacity) / 100
      })`
    : styles.colors.highlight;

  return {
    progressBar: {
      width: `${milestone.progress}%`,
      background: progressGradient,
    },
    card: {
      background: gradientBg,
    },
    modal: {
      boxShadow: `0 10px 25px -5px ${primaryColor}, 0 8px 10px -6px rgba(0, 0, 0, 0.1)`,
    },
    highlight: {
      backgroundColor: highlightBg,
    },
  };
};

/**
 * Helper function to convert hex color to RGB
 * @param {string} hex The hex color code
 * @returns {string} RGB values as "r,g,b"
 */
function hexToRgb(hex) {
  // Remove the # if it exists
  hex = hex.replace("#", "");

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}
