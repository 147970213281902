// support-articles.js
export const supportCategories = [
  {
    id: "general",
    title: "General",
    slug: "general",
    description: "Common questions about Fast Fluency Africa",
    icon: "🌍", // We can replace these with actual icon components
    articles: [
      {
        id: "fluency-meaning",
        title: "What does fluency even mean for us?",
        slug: "fluency-meaning",
        preview:
          "Our definition of fluency goes beyond just language skills...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">According to Barkhuizen there are three elements to what most people understand as 'fluency,' namely: Accuracy, Complexity, & Fluency. Therefore, 'fluency' is the ability to process the 2nd language with 'native-like' rapidity or the extent to which the language produced in performing a task manifests pausing, hesitation, or reformation. However, our definition is not limited to languages but is a more broad concept that speaks to mastery in a specific skill set or area of knowledge which we believe is more expansive and suited to our objectives.</p>
        `,
        tags: ["definition", "concept", "fluency"],
        popularityRank: 1,
        lastUpdated: "2025-02-10",
        relatedArticleIds: ["learning-time", "assessment-criteria"],
      },
      {
        id: "africa-logo",
        title: "Why is the continent of Africa in your logo upside down?",
        slug: "africa-logo",
        preview: "The story behind our unique logo design...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Is it? 🤨🧐🤔🙆‍♂️🤯</p>`,
        tags: ["logo", "branding"],
        popularityRank: 5,
        lastUpdated: "2025-01-15",
        relatedArticleIds: ["long-term-strategy"],
      },
      {
        id: "long-term-strategy",
        title: "What is your long term strategy?",
        slug: "long-term-strategy",
        preview: "Our vision for the future of Fast Fluency Africa...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">You'll see.</p>`,
        tags: ["strategy", "future", "vision"],
        popularityRank: 3,
        lastUpdated: "2025-02-20",
        relatedArticleIds: ["edutainment-meaning"],
      },
    ],
  },
  {
    id: "learning-journey",
    title: "Learning Journey",
    slug: "learning-journey",
    description: "Everything about your language learning experience",
    icon: "🚀",
    articles: [
      {
        id: "learning-time",
        title: "How long until I become fluent?",
        slug: "learning-time",
        preview:
          "The timeframe for achieving fluency depends on several factors...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Generally speaking the answer is: it depends. Many academics have have researched this question under varying conditions and the consensus is that it really is up to you as an individual and how much time you spend deliberately practicing your target language.</p>
        `,
        tags: ["timeframe", "fluency", "learning speed"],
        popularityRank: 2,
        lastUpdated: "2025-02-05",
        relatedArticleIds: ["fluency-meaning", "assessment-criteria"],
      },
      {
        id: "available-resources",
        title: "What resources do I get access to?",
        slug: "available-resources",
        preview:
          "A comprehensive list of all learning materials and resources we provide...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Study Guides, Texts (Dialogues, Short Stories, Folk Tales, etc.), Exercises, Coaching Sessions, Vocabulary Lists & many more as we keep producing new kinds of learning material literally every day.</p>
        `,
        tags: ["resources", "materials", "learning tools"],
        popularityRank: 4,
        lastUpdated: "2025-01-25",
        relatedArticleIds: ["learning-method"],
      },
      {
        id: "learning-method",
        title: "How does the learning take place?",
        slug: "learning-method",
        preview: "Our approach to effective language acquisition...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">It is up to you to get the most out of what we have to offer by booking regular coaching sessions with our mother tongue speaking language coaches or to incorporate our electronic lesson materials into your own self-study schedules. Either way you will have access to all of our materials geared towards getting you speaking in little to no time at all. Once you are given access to our materials you will have to complete a certain number of modules before you can be considered fluent.</p>
        `,
        tags: ["methodology", "coaching", "self-study"],
        popularityRank: 6,
        lastUpdated: "2025-02-15",
        relatedArticleIds: ["available-resources", "assessment-methods"],
      },
    ],
  },
  // Continue with the rest of the categories...
  {
    id: "assessment",
    title: "Assessment & Progress",
    slug: "assessment",
    description: "How we measure your language proficiency",
    icon: "📊",
    articles: [
      {
        id: "assessment-criteria",
        title:
          "What objective measure or criteria do we use to determine when you are 'fluent?'",
        slug: "assessment-criteria",
        preview:
          "Our comprehensive approach to measuring language proficiency...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">OK so earlier we spoke about what fluency means for us and what are some sub components of the general academically accepted definition of fluency click here if you missed that. Bearing all of the above in mind we'll use an analogy to explain here. So our neighbours down in Europe have an evaluation criteria specifically for this purpose when it comes to assessing the oral proficiency of speakers of various European languages known as the CEFR standard you can find out more about this on the official CEFR website —— but basically we did a lot of research and couldn't find any reliable equivalent for our African Languages so we decided we would build our own system.</p>
        `,
        tags: ["criteria", "assessment", "fluency measurement"],
        popularityRank: 7,
        lastUpdated: "2025-01-20",
        relatedArticleIds: ["fluency-meaning", "assessment-methods"],
      },
      {
        id: "assessment-methods",
        title:
          "How will I be assessed against the different learning outcomes?",
        slug: "assessment-methods",
        preview: "The methods and tools we use to evaluate your progress...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We created our own assessment methods that we use here at FFA in order to measure your level of progress. Firstly we use our native mother tongue speaking language coaches to do objective assessments of your proficiency at any given time and we also employ a variety of assessment based tasks and challenges that we measure against the standard of fluency we talked about earlier.</p>
        `,
        tags: ["methods", "assessment", "evaluation"],
        popularityRank: 9,
        lastUpdated: "2025-02-12",
        relatedArticleIds: ["assessment-criteria", "learning-method"],
      },
    ],
  },
  // Remaining categories from module-data.js formatted for support-articles.js
  // Add these to your supportCategories array

  {
    id: "courses-languages",
    title: "Courses & Languages",
    slug: "courses-languages",
    description: "Our available language options and courses",
    icon: "📚",
    articles: [
      {
        id: "unavailable-language",
        title:
          "The language I want to learn isn't available yet - what can I do?",
        slug: "unavailable-language",
        preview:
          "Options for learning languages not yet offered in our catalog...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If your target language is currently unavailable you can always notify us so that we can gauge the existing demand for the language and if there is enough interest (at least 10 - 25 other requests) we will prepare a beta program just for you, with limited resources primarily centred around coaching sessions, for you to start learning the language you want as soon as you can.</p>
        `,
        tags: ["languages", "requests", "beta program"],
        popularityRank: 8,
        lastUpdated: "2025-01-18",
        relatedArticleIds: ["languages-available", "platform-scope"],
      },
      {
        id: "languages-available",
        title: "How many languages do you currently provide courses for?",
        slug: "languages-available",
        preview: "Current language offerings and future expansion plans...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">At the moment we are focusing all of our energies on finalizing our isiZulu product lines (academic and vocational) so that we have a solid pedagogical foundation to build from when we expand to other African languages beyond the South African borders i.e. Kiswahili, Igbo, Yoruba, Amharic, Kikuyu, Chichewa, Shona etc.</p>
        `,
        tags: ["languages", "isizulu", "expansion"],
        popularityRank: 10,
        lastUpdated: "2025-02-08",
        relatedArticleIds: ["unavailable-language", "platform-scope"],
      },
      {
        id: "platform-scope",
        title: "Does your platform only cater for languages?",
        slug: "platform-scope",
        preview:
          "Our current focus and long-term vision beyond language acquisition...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For now yes, our long term goal is geared towards mother tongue education which is much broader but in order to get there we have decided to start with language acquisition which is the first skill any aspiring learner must master before they can begin acquiring new knowledge.</p>
        `,
        tags: ["platform", "mother tongue", "education"],
        popularityRank: 12,
        lastUpdated: "2025-01-30",
        relatedArticleIds: ["languages-available", "long-term-strategy"],
      },
    ],
  },
  {
    id: "pricing-plans",
    title: "Pricing & Plans",
    slug: "pricing-plans",
    description: "Subscriptions and payment information",
    icon: "💰",
    articles: [
      {
        id: "subscription-cost",
        title: "How much does it all cost?",
        slug: "subscription-cost",
        preview:
          "Information about our pricing tiers and subscription plans...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Our entry level subscription plan is free. Paid members get access to more advanced features. For more information please consult our Pricing page.</p>
        `,
        tags: ["pricing", "subscription", "plans"],
        popularityRank: 5,
        lastUpdated: "2025-02-15",
        relatedArticleIds: ["available-resources", "learning-method"],
      },
    ],
  },
  {
    id: "careers",
    title: "Careers",
    slug: "careers",
    description: "Job opportunities at Fast Fluency Africa",
    icon: "👩‍💼",
    articles: [
      {
        id: "open-positions",
        title: "What positions does your company still need to fill?",
        slug: "open-positions",
        preview: "Current vacancies and roles we're actively recruiting for...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We are still in our very early days so we have quite a number of vacancies to fill. At our Governance or Executive level we have vacancies in the Council of Elders, COO, CFO, & CEO positions. In our Tech department we are looking for: Front End Developers, UI / UX Designers, Back End Developers, Mobile Developers, & DevOps. Concerning the Language Unit, we currently need: a Language Morena, Head of Service Delivery, Head of Instructional Design, Language Coaches, Instructional Designers, Translators, Voice Over Actors, Proofreaders, & Editors. Lastly, in our Sales & Marketing department we need Sales Reps.</p>
        `,
        tags: ["careers", "jobs", "positions", "hiring"],
        popularityRank: 13,
        lastUpdated: "2025-01-25",
        relatedArticleIds: ["hiring-requirements", "join-tribe"],
      },
      {
        id: "hiring-requirements",
        title:
          "What kind of background or prerequisites do you look for when hiring?",
        slug: "hiring-requirements",
        preview: "Qualifications and requirements for joining our team...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We love working with people from all walks of life. If you've never used a computer before it's fine, we'll teach you how. If you barely speak English it's fine, we'll learn your mother tongue. If you have 7 degrees, it's also fine we'll forgive you for knowing too much. If you are fresh out of high school, that is also fine you can come get some work experience with us. Our only real non-negotiable prerequisite is that you must have access to a reliable Internet connection. As we are a remote work inclined organization we rarely do any work in person, so if you can't guarantee your access to internet we won't be able to provide you with a fruitful working experience.</p>
        `,
        tags: ["requirements", "hiring", "qualifications"],
        popularityRank: 15,
        lastUpdated: "2025-02-01",
        relatedArticleIds: ["open-positions", "join-tribe"],
      },
      {
        id: "join-tribe",
        title: "How can I join the Tribe?",
        slug: "join-tribe",
        preview:
          "The application process and hiring timeline for Fast Fluency Africa...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Although we are always actively looking for new talent, our experiences in the past have also taught us that sometimes we're not always ready for great talent. So to avoid disappointment we now prefer to only hire annually at the beginning of each new financial year when we have a clearer picture of our staffing needs for the year. If you miss our regular intake period you'll unfortunately just have to wait another year. If you would like to apply so that we have your details when our hiring season kicks in you can always submit your details at the 'Join the Tribe' link in the footer below, but please note that we'll only review your application when our hiring season begins.</p>
        `,
        tags: ["application", "hiring", "tribe"],
        popularityRank: 17,
        lastUpdated: "2025-01-20",
        relatedArticleIds: ["open-positions", "hiring-requirements"],
      },
    ],
  },
  {
    id: "course-specifications",
    title: "Course Specifications",
    slug: "course-specifications",
    description: "Detailed information about our learning programs",
    icon: "📋",
    articles: [
      {
        id: "specific-learning-objectives",
        title: "Do we offer courses for specific learning objectives?",
        slug: "specific-learning-objectives",
        preview:
          "Specialized learning paths tailored to different goals and demographics...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Yes, we are actively developing learning materials for three specific demographics, in particular people with an academic requirement, A social motivation or, a work related or vocational incentive. Right now we currently only have the social product line ready for use specifically for isiZulu but during the 2025/2026 financial year we will be piloting our academic line starting with Grades R - 6 for isiZulu, aligned with the CAPS documents.</p>
        `,
        tags: ["objectives", "specialized courses", "demographics"],
        popularityRank: 14,
        lastUpdated: "2025-02-14",
        relatedArticleIds: ["edutainment-meaning", "languages-available"],
      },
      {
        id: "edutainment-meaning",
        title: "What do you mean by 'edutainment?'",
        slug: "edutainment-meaning",
        preview: "Our approach to combining education with entertainment...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Obviously, this is a portmanteau of education and entertainment. Our interpretation of this concept is to create educational experiences which have entertainment value. This means that even in our purely pedagogical products we aspire to make the content as entertaining as possible so that even if you aren't interested in learning you'll still show up just for the entertainment value.</p>
        `,
        tags: ["edutainment", "learning approach", "entertainment"],
        popularityRank: 11,
        lastUpdated: "2025-01-10",
        relatedArticleIds: ["specific-learning-objectives", "learning-method"],
      },
    ],
  },
  {
    id: "app-features",
    title: "App Features",
    slug: "app-features",
    description: "Learn how to use Fast Fluency Africa's key features",
    icon: "⚙️",
    articles: [
      {
        id: "hearts-system",
        title: "What are hearts and how do they work?",
        slug: "hearts-system",
        preview: "Understanding the heart system for lesson attempts...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Hearts represent the number of attempts you have to complete lessons. Each Fast Fluency Africa account starts with 5 hearts. When you make a mistake in a lesson, you lose one heart. If you run out of hearts, you'll need to wait for them to refill (one heart every 30 minutes) or upgrade to our Morena subscription for unlimited hearts.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Hearts are displayed at the top of your screen during lessons and on your profile dashboard. This system encourages careful learning while giving you the flexibility to make some mistakes along the way.</p>`,
        tags: ["hearts", "lessons", "attempts", "features"],
        popularityRank: 2,
        lastUpdated: "2025-03-01",
        relatedArticleIds: ["hearts-refill", "premium-benefits"],
      },
      {
        id: "hearts-refill",
        title: "Why did I lose hearts, and how do I get more?",
        slug: "hearts-refill",
        preview: "Information about heart depletion and refill options...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Hearts are depleted when you make mistakes during lessons. Each incorrect answer costs one heart. To get more hearts, you have several options:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Wait for automatic refills - one heart regenerates every 30 minutes</li>
            <li>2) Complete practice exercises which don't cost hearts and can earn you heart refills</li>
            <li>3) Watch short advertisements to earn an extra heart (available once every 8 hours)</li>
            <li>4) Upgrade to our Morena subscription for unlimited hearts</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If your hearts aren't refilling as expected, check your device's time settings or your internet connection, as synchronization issues can sometimes affect the refill timer.</p>
        `,
        tags: ["hearts", "refill", "mistakes", "practice"],
        popularityRank: 3,
        lastUpdated: "2025-02-28",
        relatedArticleIds: ["hearts-system", "practice-mode"],
      },
      {
        id: "streak-system",
        title: "What is a streak and how do I maintain it?",
        slug: "streak-system",
        preview: "Everything about maintaining your daily learning streak...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">A streak in Fast Fluency Africa represents consecutive days of learning. Your current streak is displayed on your profile and home screen. To maintain your streak, you need to complete at least one lesson or practice session each day. Streaks reset at midnight in your local time zone.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you miss a day, your streak will reset to zero. Premium members have access to Streak Freeze, which maintains your streak for one day of inactivity.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Remember that consistent practice is key to language learning, and the streak feature is designed to encourage daily engagement with your target language.</p>
        `,
        tags: ["streak", "daily", "consistency", "motivation"],
        popularityRank: 4,
        lastUpdated: "2025-02-25",
        relatedArticleIds: ["hearts-system", "premium-benefits"],
      },
      {
        id: "leaderboards",
        title: "How do leaderboards and achievements work?",
        slug: "leaderboards",
        preview: "Understanding the competitive and achievement features...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa's leaderboards and achievements are designed to motivate you and track your progress. Leaderboards rank users based on XP (experience points) earned through completing lessons and practice sessions. You can view weekly rankings of friends or all local learners studying your target language.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Achievements are earned by completing specific milestones like finishing course units, maintaining streaks, or mastering specific skills. Gold, silver, and bronze badges are awarded based on your performance level.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">These features are intended to make learning more engaging while providing visual indicators of your progress and commitment to language learning.</p>
        `,
        tags: ["leaderboards", "achievements", "competition", "badges"],
        popularityRank: 6,
        lastUpdated: "2025-02-20",
        relatedArticleIds: ["streak-system", "progress-tracking"],
      },
      {
        id: "reset-progress",
        title: "Can I reset my progress in a course?",
        slug: "reset-progress",
        preview: "How to restart a course while preserving your account...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Yes, you can reset your progress in any course while keeping your account intact. To do this, go to Settings > Courses > Select the course you want to reset > Reset Progress. You'll be asked to confirm this action as it cannot be undone.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Resetting a course will return all lessons to their initial state, clear your progress statistics for that course, and remove any XP you earned from it. However, your streak, hearts, and account details will remain unchanged.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">This feature is useful if you want to start fresh after taking a long break or if you want to reinforce your learning from the beginning.</p>
        `,
        tags: ["reset", "restart", "progress", "courses"],
        popularityRank: 8,
        lastUpdated: "2025-02-15",
        relatedArticleIds: ["progress-tracking", "course-structure"],
      },
      {
        id: "practice-mode",
        title: "How do I practice weak areas without losing hearts?",
        slug: "practice-mode",
        preview: "Using practice mode for risk-free learning reinforcement...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Practice Mode allows you to strengthen your skills without risking hearts. To access Practice Mode, tap on the dumbbell icon on your home screen or select 'Practice' from any unit you've already started.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Unlike regular lessons, Practice Mode doesn't deduct hearts when you make mistakes, making it perfect for reinforcing challenging concepts. The system intelligently focuses on areas where you've struggled in previous lessons.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Regular practice sessions not only help solidify your knowledge but can also earn you additional hearts to use in graded lessons. We recommend using Practice Mode frequently, especially before attempting more advanced units.</p>
        `,
        tags: ["practice", "hearts", "review", "reinforcement"],
        popularityRank: 5,
        lastUpdated: "2025-03-05",
        relatedArticleIds: ["hearts-system", "hearts-refill"],
      },
    ],
  },
  {
    id: "technical-help",
    title: "Technical Help",
    slug: "technical-help",
    description: "Solutions for common technical issues",
    icon: "🔧",
    articles: [
      {
        id: "app-crashing",
        title: "The app is crashing or freezing during lessons",
        slug: "app-crashing",
        preview: "Troubleshooting steps for app stability issues...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're experiencing crashes or freezes during lessons, try these troubleshooting steps:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Close all background apps to free up memory on your device</li>
            <li>2) Restart the Fast Fluency Africa app</li>
            <li>3) Check for app updates in your app store</li>
            <li>4) Ensure your device has sufficient storage space available</li>
            <li>5) Try disconnecting and reconnecting to your WiFi network</li>
            <li>6) Restart your device completely</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If problems persist, you can try reinstalling the app (note that you'll need to log back in with your account details).</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Our app uses Firebase for data synchronization, so temporary connectivity issues can sometimes cause freezing. If you continue to experience problems after trying these steps, please contact our support team with details about your device model and operating system version.</p>
        `,
        tags: ["crash", "freeze", "technical", "troubleshooting"],
        popularityRank: 7,
        lastUpdated: "2025-03-10",
        relatedArticleIds: ["progress-not-saving", "offline-mode"],
      },
      {
        id: "progress-not-saving",
        title: "My progress isn't saving. How do I fix this?",
        slug: "progress-not-saving",
        preview: "Solutions for when your learning progress doesn't save...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Progress not saving can be frustrating, but it's usually fixable. First, ensure you have a stable internet connection, as our app uses Firebase to sync your progress across devices. If you're using offline mode, your progress should sync once you reconnect to the internet.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If problems persist:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Check that you're logged into the correct account</li>
            <li>2) Verify your device's date and time settings are accurate (incorrect settings can cause synchronization issues)</li>
            <li>3) Clear the app's cache (Settings > Apps > Fast Fluency Africa > Storage > Clear Cache)</li>
            <li>4) Force close and restart the app</li>
          </ul>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you complete a lesson but don't see progress updates immediately, wait 5-10 minutes for synchronization to complete. For persistent issues, try logging out and back into your account to reset the connection to our servers.</p>
        `,
        tags: ["progress", "saving", "sync", "firebase"],
        popularityRank: 9,
        lastUpdated: "2025-03-08",
        relatedArticleIds: ["app-crashing", "offline-mode"],
      },
      {
        id: "device-compatibility",
        title: "Is Fast Fluency Africa compatible with my device?",
        slug: "device-compatibility",
        preview: "Platform and system requirements for optimal performance...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa is designed to work on a wide range of devices. Our app is compatible with:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>iOS devices running iOS 13.0 or newer</li>
            <li>Android devices running Android 7.0 (Nougat) or newer</li>
            <li>Web browsers including Chrome, Firefox, Safari, and Edge (latest two major versions)</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For the best experience, we recommend devices with at least 2GB of RAM and 100MB of free storage space. Some older devices may experience performance issues with audio exercises or video content.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're using an older device and experiencing problems, consider using our web version at app.fastfluencyafrica.com which has lower hardware requirements. Note that while the app works on tablets and iPads, the interface is optimized for smartphone screens.</p>
        `,
        tags: ["compatibility", "devices", "requirements", "performance"],
        popularityRank: 10,
        lastUpdated: "2025-02-05",
        relatedArticleIds: ["app-crashing", "app-updates"],
      },
      {
        id: "app-updates",
        title: "How do I update the app to the latest version?",
        slug: "app-updates",
        preview: "Ensuring you have the most recent version of the app...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Keeping Fast Fluency Africa updated ensures you have access to the latest features, content, and bug fixes.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To update on iOS: Open the App Store, tap on your profile icon, scroll down to see available updates, and tap 'Update' next to Fast Fluency Africa.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For Android: Open the Google Play Store, tap on your profile icon, select 'Manage apps & device', look for Fast Fluency Africa under 'Updates available', and tap 'Update'.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you use the web version, simply refresh your browser to get the latest version.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We recommend enabling automatic updates in your device settings. If you can't update, check that your device meets our minimum requirements (iOS 13.0+ or Android 7.0+) and that you have sufficient storage space and a stable internet connection.</p>
        `,
        tags: ["update", "version", "app store", "google play"],
        popularityRank: 12,
        lastUpdated: "2025-02-28",
        relatedArticleIds: ["device-compatibility", "app-crashing"],
      },
      {
        id: "offline-mode",
        title: "Can I use the app offline?",
        slug: "offline-mode",
        preview: "Learning without an internet connection...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Yes, Fast Fluency Africa offers offline learning capabilities, but with some limitations. To use offline mode:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Connect to WiFi before downloading content</li>
            <li>2) Go to Settings > Offline Learning</li>
            <li>3) Select the units or lessons you want to download for offline use</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Once downloaded, you can complete these lessons without an internet connection. Limitations of offline mode include: no access to coaching sessions, inability to participate in leaderboards, and some audio exercises may be unavailable.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Your progress will be saved locally and synchronized with our servers when you reconnect to the internet. Offline content may become unavailable after 30 days without reconnecting online.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Premium subscribers can download more content for offline use than free users. To manage your downloaded content, return to Settings > Offline Learning.</p>
        `,
        tags: ["offline", "download", "connection", "mobile data"],
        popularityRank: 11,
        lastUpdated: "2025-02-10",
        relatedArticleIds: ["progress-not-saving", "premium-benefits"],
      },
    ],
  },
  {
    id: "account-help",
    title: "Account Help",
    slug: "account-help",
    description: "Managing your Fast Fluency Africa account",
    icon: "👤",
    articles: [
      {
        id: "change-credentials",
        title: "How do I change my username, email, or password?",
        slug: "change-credentials",
        preview: "Steps to update your account information...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To change your account information in Fast Fluency Africa:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Go to Settings > Account > Profile Information</li>
            <li>2) To change your username, tap on 'Username' and enter a new one (must be unique and between 3-20 characters)</li>
            <li>3) To change your email, tap on 'Email', enter your new email address, and verify it via the link we'll send</li>
            <li>4) To change your password, tap on 'Password', enter your current password for verification, then create and confirm your new password (must be at least 8 characters with a mix of letters, numbers, and symbols)</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Changes to email and password will require you to log in again with your new credentials. For security reasons, we'll send notifications to both your old and new email addresses when these details are changed.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're unable to access your account to make these changes, please use the 'Forgot Password' option on the login screen or contact our support team.</p>
        `,
        tags: ["account", "username", "email", "password"],
        popularityRank: 5,
        lastUpdated: "2025-03-05",
        relatedArticleIds: ["login-issues", "reset-password"],
      },
      {
        id: "login-issues",
        title: "I can't log in—what should I do?",
        slug: "login-issues",
        preview: "Troubleshooting login problems and account recovery...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're having trouble logging into your Fast Fluency Africa account, try these steps:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Double-check that you're entering the correct email/username and password (passwords are case-sensitive)</li>
            <li>2) Ensure caps lock is turned off</li>
            <li>3) Try the 'Forgot Password' option to reset your password via email</li>
            <li>4) If using social login (Google, Facebook, etc.), try disconnecting and reconnecting to WiFi, then attempt again</li>
            <li>5) Clear your browser cache/cookies if using the web version</li>
            <li>6) Check if you're using the correct email address (try any alternative emails you might have used)</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're still unable to access your account, our support team can help with account recovery by verifying your identity through details such as your registration date, subscription information, or learning history.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For security reasons, we can only provide account recovery assistance to the original account owner using the registered email address.</p>
        `,
        tags: ["login", "access", "recovery", "authentication"],
        popularityRank: 3,
        lastUpdated: "2025-03-10",
        relatedArticleIds: ["reset-password", "change-credentials"],
      },
      {
        id: "reset-password",
        title: "How do I reset my password?",
        slug: "reset-password",
        preview: "Steps to regain access when you forget your password...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To reset your Fast Fluency Africa password:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) On the login screen, tap 'Forgot Password'</li>
            <li>2) Enter the email address associated with your account</li>
            <li>3) Check your email inbox for a password reset link (also check spam/junk folders)</li>
            <li>4) Follow the link and create a new password (must be at least 8 characters with a mix of letters, numbers, and symbols)</li>
            <li>5) Log in with your new password</li>
          </ul>
          p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The reset link expires after 24 hours for security reasons. If you don't receive the reset email within 10 minutes, verify you entered the correct email address and check all your email folders.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you no longer have access to the email address associated with your account, please contact our support team with proof of account ownership, such as transaction records for purchases or details about your learning history.</p>
        `,
        tags: ["password", "reset", "forgot", "security"],
        popularityRank: 2,
        lastUpdated: "2025-03-15",
        relatedArticleIds: ["login-issues", "change-credentials"],
      },
      {
        id: "delete-account",
        title: "How do I delete my account or request my data?",
        slug: "delete-account",
        preview: "Managing your personal data and account deletion...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa respects your data privacy rights. To delete your account:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Go to Settings > Account > Delete Account</li>
            <li>2) Read the information about what will be deleted (learning history, achievements, and personal data)</li>
            <li>3) Enter your password to confirm</li>
            <li>4) Select a reason for leaving (optional but helpful for our improvement)</li>
            <li>5) Tap 'Delete My Account'</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Please note that account deletion is permanent and will cancel any active subscriptions.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To request a copy of your personal data instead (GDPR rights): Go to Settings > Account > Privacy > Request Data Export. We'll compile your data and send it to your registered email address within 30 days, typically much sooner.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The export will include your personal information, learning statistics, and activity history. If you need more specific data or have questions about data retention, please contact our support team.</p>
        `,
        tags: ["delete", "GDPR", "data", "privacy"],
        popularityRank: 15,
        lastUpdated: "2025-01-20",
        relatedArticleIds: ["data-protection", "subscription-management"],
      },
      {
        id: "unexpected-logout",
        title: "Why am I logged out unexpectedly?",
        slug: "unexpected-logout",
        preview: "Reasons for automatic logouts and how to prevent them...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Unexpected logouts can occur for several reasons:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Security timeout - after 30 days of inactivity, we automatically log you out to protect your account</li>
            <li>2) Password changes - changing your password on another device will log you out of all devices</li>
            <li>3) Multiple device restrictions - free accounts are limited to two active devices; logging in on a third device will log you out of the least recently used one</li>
            <li>4) App updates - major updates sometimes require re-authentication</li>
            <li>5) Cache/data clearing - if you or an optimization app cleared app data, your login session would be removed</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To minimize unexpected logouts: check the 'Remember Me' option when logging in, ensure your app is updated regularly, and consider upgrading to a premium account for more simultaneous devices.</p>
        <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you experience frequent unexpected logouts that don't fit these scenarios, it could indicate a technical issue or potential unauthorized access attempts - please contact our support team immediately.</p>
        `,
        tags: ["logout", "session", "security", "devices"],
        popularityRank: 8,
        lastUpdated: "2025-02-15",
        relatedArticleIds: ["login-issues", "data-protection"],
      },
    ],
  },
  {
    id: "subscription-help",
    title: "Subscription & Payments",
    slug: "subscription-help",
    description: "Managing your subscription and payment options",
    icon: "💳",
    articles: [
      {
        id: "premium-benefits",
        title: "What are the benefits of Morena Premium?",
        slug: "premium-benefits",
        preview: "Features and advantages of our premium subscription...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Morena Premium unlocks the full Fast Fluency Africa experience with these benefits:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Unlimited hearts - never pause your learning due to mistakes</li>
            <li>2) Offline access to all lessons - learn anywhere without internet</li>
            <li>3) Priority booking for coaching sessions with native speakers</li>
            <li>4) Ad-free experience throughout the app</li>
            <li>5) Advanced progress analytics to track your improvement</li>
            <li>6) Access to specialized vocabulary modules for academic, business, and medical fields</li>
            <li>7) Streak Freeze feature to maintain your streak during days you can't practice</li>
            <li>8) Unlimited hint usage during challenging lessons</li>
            <li>9) Early access to new languages and features before they're available to free users</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Morena Premium is available as a monthly subscription at R149/month or an annual subscription at R1499/year (saving over 15%). All subscriptions include a 7-day free trial period.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For a detailed comparison between free and premium features, visit the Subscription page in the app.</p>
        `,
        tags: ["premium", "morena", "subscription", "benefits"],
        popularityRank: 1,
        lastUpdated: "2025-03-10",
        relatedArticleIds: ["subscription-management", "family-plan"],
      },
      {
        id: "subscription-management",
        title: "How do I manage or cancel my subscription?",
        slug: "subscription-management",
        preview: "Controlling your subscription settings and billing...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Managing your Fast Fluency Africa subscription differs based on how you subscribed.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For subscriptions purchased through the app:</p>
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Go to Settings > Subscription > Manage Subscription</li>
            <li>2) View your current plan, next billing date, and management options</li>
            <li>3) To cancel, select 'Cancel Subscription' and follow the prompts</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For iOS users who subscribed through Apple: Go to your device's Settings > Your Name > Subscriptions > Fast Fluency Africa.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For Android users who subscribed through Google Play: Open Google Play Store > Profile > Payments & subscriptions > Subscriptions > Fast Fluency Africa.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For web subscribers: Go to Account Settings > Billing.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Cancellations must be made at least 24 hours before your next billing date to avoid charges. After cancellation, you'll maintain premium access until the end of your current billing period. Note that deleting the app does not cancel your subscription.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you need help with subscription issues, please contact our support team with your account email and approximate subscription start date.</p>
        `,
        tags: ["subscription", "cancel", "billing", "payment"],
        popularityRank: 4,
        lastUpdated: "2025-03-05",
        relatedArticleIds: ["premium-benefits", "refunds"],
      },
      {
        id: "refunds",
        title: "How do I request a refund or use a promo code?",
        slug: "refunds",
        preview: "Information about refunds and promotional discounts...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For refund requests:</p>
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>If you subscribed through our website, contact our support team with your account email and reason for the refund request.</li>
            <li>For App Store purchases, contact Apple Support directly.</li>
            <li>For Google Play purchases, contact Google Play Support.</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Refund policies vary by platform, but generally require requests within 48 hours of an accidental purchase.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To use a promo code:</p>
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Go to Settings > Subscription > Redeem Code</li>
            <li>2) Enter your code and tap 'Apply'</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Promo codes can provide discounts on subscriptions or unlock premium features for a limited time.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Student discounts are available with valid educational email addresses - verify through Settings > Subscription > Student Discount.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For bulk educational or organizational discounts, please contact our sales team at sales@fastfluencyafrica.com. Note that we cannot combine multiple promotional offers, and codes typically expire within 30 days of issuance.</p>
        `,
        tags: ["refund", "promo", "discount", "code"],
        popularityRank: 7,
        lastUpdated: "2025-02-20",
        relatedArticleIds: ["subscription-management", "premium-benefits"],
      },
      {
        id: "family-plan",
        title: "What is the Family Plan and how does it work?",
        slug: "family-plan",
        preview: "Sharing Fast Fluency Africa with your family members...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The Fast Fluency Africa Family Plan allows up to 6 family members to enjoy Morena Premium benefits under one subscription at R249/month or R2499/year. Here's how it works:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) The family organizer purchases the Family Plan through Settings > Subscription > Family Plan</li>
            <li>2) The organizer sends invitation links to family members via email or phone number</li>
            <li>3) Invitees create their own accounts or link existing ones to the Family Plan</li>
            <li>4) Each member maintains their individual learning progress, achievements, and statistics</li>
            <li>5) The organizer can monitor overall usage and learning activity of all members</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Family members must live at the same residential address. Each member gets all Morena Premium benefits plus the Family Progress Dashboard to track and encourage each other's learning.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The family organizer is responsible for payments and can remove members at any time. If the organizer cancels the subscription, all members will revert to free accounts at the end of the billing period.</p>
        `,
        tags: ["family", "plan", "group", "shared"],
        popularityRank: 6,
        lastUpdated: "2025-02-25",
        relatedArticleIds: ["premium-benefits", "subscription-management"],
      },
      {
        id: "payment-security",
        title: "Is my payment information secure?",
        slug: "payment-security",
        preview: "How we protect your financial data...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa takes payment security seriously. We never store your complete credit card information on our servers. Instead, we use industry-standard payment processors (Stripe and PayFast) that maintain PCI DSS compliance for all transactions.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Your payment details are encrypted using TLS/SSL during transmission, and we implement additional security measures like tokenization to protect sensitive data.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For subscriptions through app stores, Apple and Google handle all payment processing directly, following their respective security protocols.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We recommend enabling two-factor authentication on your account for additional security. If you notice any unauthorized charges or suspicious activity, please contact our support team immediately and consider changing your password.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">For added security when making payments on our website, verify that the URL begins with 'https://' and shows a padlock icon in your browser's address bar.</p>
        `,
        tags: ["security", "payment", "encryption", "PCI"],
        popularityRank: 9,
        lastUpdated: "2025-02-15",
        relatedArticleIds: ["data-protection", "subscription-management"],
      },
    ],
  },
  {
    id: "learning-features",
    title: "Learning Experience",
    slug: "learning-features",
    description: "Maximize your language learning journey",
    icon: "📝",
    articles: [
      {
        id: "course-structure",
        title: "How are courses structured, and how do I choose the right one?",
        slug: "course-structure",
        preview: "Understanding the organization of learning content...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa courses are structured in a progressive pathway designed for optimal learning. Each language course contains:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Units - major themes or competency areas</li>
            <li>2) Lessons - focused learning modules within units</li>
            <li>3) Exercises - interactive activities within lessons</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We currently offer three course pathways for each language: Social (for casual conversation and cultural connection), Academic (aligned with educational standards), and Vocational (for professional contexts).</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To choose the right course, consider your learning goals: Are you learning for personal enrichment, academic requirements, or career advancement? Each pathway is clearly labeled with required time commitment and difficulty level.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">You can switch between pathways at any time without losing progress. We recommend starting with a placement test (available on your dashboard) to determine your current proficiency level. Based on your results, we'll suggest the appropriate starting point.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Remember that consistent practice with any pathway will lead to improvement, so choose one that aligns with your interests to stay motivated.</p>
        `,
        tags: ["courses", "structure", "units", "lessons"],
        popularityRank: 3,
        lastUpdated: "2025-03-10",
        relatedArticleIds: ["progress-tracking", "skip-lessons"],
      },
      {
        id: "skip-lessons",
        title: "Can I skip lessons or test out of levels?",
        slug: "skip-lessons",
        preview: "Options for advancing past content you already know...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Yes, Fast Fluency Africa allows you to skip content based on your existing knowledge. There are several ways to advance:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Placement Test - When starting a new language, take the comprehensive placement test to bypass beginner levels</li>
            <li>2) Checkpoint Challenges - At the end of each unit, complete the checkpoint challenge with at least 80% accuracy to unlock the next unit</li>
            <li>3) Unit Tests - Take a unit test to demonstrate mastery and skip all lessons within that unit</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Premium members can attempt to test out of any unit at any time, while free users can test out of one unit per month.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Note that while skipping content can save time, it may leave gaps in vocabulary or grammatical understanding that could be important later. We recommend only skipping content you genuinely already know well.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you skip a unit but later want to review it, you can always go back and complete skipped lessons without losing your current position in advanced units.</p>
        `,
        tags: ["skip", "test out", "advance", "placement"],
        popularityRank: 8,
        lastUpdated: "2025-02-18",
        relatedArticleIds: ["course-structure", "reset-progress"],
      },
      {
        id: "coaching-sessions",
        title: "How to book a language coaching session",
        slug: "coaching-sessions",
        preview:
          "Getting the most out of live sessions with native speakers...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Language coaching sessions with native speakers are a powerful way to accelerate your fluency. To book a session:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Go to the 'Coaching' tab in the app</li>
            <li>2) Select your preferred coach based on availability, specialization, and reviews</li>
            <li>3) Choose an available time slot (sessions are 30 or 60 minutes)</li>
            <li>4) Select your focus area or specific topics you want to practice</li>
            <li>5) Complete your booking (free users get one 30-minute session per month; premium users get four)</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Prepare for your session by reviewing relevant vocabulary and preparing questions. During the session, don't worry about making mistakes - coaches are trained to provide constructive feedback in a supportive environment.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">After each session, you'll receive a summary of feedback and suggested areas for improvement. If you need to reschedule, please do so at least 24 hours in advance to avoid using your session allocation.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Technical requirements include a stable internet connection and a device with a working microphone and camera (optional but recommended).</p>
        `,
        tags: ["coaching", "native speaker", "session", "conversation"],
        popularityRank: 5,
        lastUpdated: "2025-03-01",
        relatedArticleIds: ["course-structure", "premium-benefits"],
      },
      {
        id: "dictionary-usage",
        title: "How to use the Dictionary",
        slug: "dictionary-usage",
        preview: "Maximizing the built-in language reference tool...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The Fast Fluency Africa Dictionary is a comprehensive tool designed specifically for African languages. To use it effectively:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Access the Dictionary from the main menu or by tapping on any word during lessons</li>
            <li>2) Search for words in either English or your target language using the search bar</li>
            <li>3) For nouns in Bantu languages like isiZulu, try searching both with and without the noun class prefix if you don't find results initially</li>
            <li>4) Listen to native pronunciation by tapping the speaker icon</li>
            <li>5) View example sentences showing the word in context</li>
            <li>6) Save words to your personal vocabulary list by tapping the bookmark icon</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Advanced dictionary features include: filtering words by category (greetings, food, business terms, etc.), viewing related words and expressions, and accessing detailed grammatical information for verbs (including conjugation patterns).</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The dictionary works offline once you've downloaded the language pack (Premium feature). For languages like isiZulu, the dictionary includes dialectal variations and cultural context notes to enhance your understanding of nuances in different regions and settings.</p>
        `,
        tags: ["dictionary", "vocabulary", "reference", "pronunciation"],
        popularityRank: 10,
        lastUpdated: "2025-02-10",
        relatedArticleIds: ["offline-mode", "course-structure"],
      },
      {
        id: "progress-tracking",
        title: "How to track your learning progress",
        slug: "progress-tracking",
        preview: "Understanding your analytics and improvement metrics...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa offers comprehensive progress tracking to help you monitor your language learning journey. To access your progress data: Go to Profile > Statistics or tap the progress chart icon on your homepage.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Key metrics include:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Fluency Score - our proprietary assessment of your overall competency</li>
            <li>2) Vocabulary Mastery - words you've learned and their retention strength</li>
            <li>3) Grammar Accuracy - your performance on grammatical constructions</li>
            <li>4) Speaking Confidence - based on pronunciation exercises and coaching sessions</li>
            <li>5) Daily/Weekly/Monthly activity trends</li>
            <li>6) Time spent learning by category</li>
          </ul>
          p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Premium users get additional analytics including competency breakdowns by language skill (reading, writing, listening, speaking), personalized weak area identification, and projected milestone achievements based on your learning pace.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We recommend reviewing your progress weekly to identify areas needing more practice. The Fluency Assessment (available monthly) provides a comprehensive evaluation of your skills and updates your Fluency Score.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Remember that language learning progress isn't always linear—occasional plateaus are normal and part of the consolidation process.</p>
        `,
        tags: ["progress", "statistics", "analytics", "tracking"],
        popularityRank: 4,
        lastUpdated: "2025-02-28",
        relatedArticleIds: ["fluency-meaning", "course-structure"],
      },
    ],
  },
  {
    id: "data-security",
    title: "Security & Privacy",
    slug: "data-security",
    description: "How we protect your information",
    icon: "🔒",
    articles: [
      {
        id: "data-protection",
        title: "How does Fast Fluency Africa protect my data?",
        slug: "data-protection",
        preview: "Our approach to data security and privacy...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa implements multiple layers of protection for your data:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) All data transmission uses TLS/SSL encryption to prevent interception</li>
            <li>2) User authentication data (passwords) are hashed using industry-standard bcrypt algorithms</li>
            <li>3) Our Firebase infrastructure implements strict security rules with field-level access controls</li>
            <li>4) Regular security audits and penetration testing ensure our systems remain secure</li>
            <li>5) Personal data is compartmentalized and access-restricted even within our organization</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We follow data minimization principles, collecting only information necessary for app functionality. Your learning data is used to personalize your experience and improve our language models, always in anonymized form for any analytical purposes.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We never sell your personal information to third parties. Data is backed up regularly with encrypted backups stored in multiple secure locations.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">In the unlikely event of a security incident affecting your data, we are committed to transparent notification in accordance with applicable laws. For full details, please review our Privacy Policy, accessible from the app's Settings menu.</p>
        `,
        tags: ["security", "encryption", "firebase", "privacy"],
        popularityRank: 7,
        lastUpdated: "2025-02-15",
        relatedArticleIds: ["compromised-account", "delete-account"],
      },
      {
        id: "compromised-account",
        title: "What should I do if my account was compromised?",
        slug: "compromised-account",
        preview: "Steps to take if you suspect unauthorized access...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you suspect your Fast Fluency Africa account has been compromised, take these immediate steps:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) Change your password immediately through the Settings menu or the 'Forgot Password' feature</li>
            <li>2) Check your account details (email, profile information) to ensure nothing has been altered</li>
            <li>3) Review your subscription status for any unauthorized changes</li>
            <li>4) Log out of all devices (Settings > Security > Log Out All Devices)</li>
            <li>5) Enable two-factor authentication if not already active (Settings > Security > Two-Factor Authentication)</li>
            <li>6) Contact our support team immediately with details of any suspicious activity</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Signs of a compromised account may include: unexpected password reset emails, login notifications from unfamiliar locations, changes to your profile you didn't make, or unusual learning activity in your progress history.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">As a preventative measure, we recommend using a unique, strong password for your Fast Fluency Africa account, avoiding public WiFi for login unless using a VPN, and regularly reviewing your account activity.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We take security very seriously and will work with you to investigate any potential breach.</p>
        `,
        tags: ["security", "hack", "unauthorized", "compromised"],
        popularityRank: 11,
        lastUpdated: "2025-02-05",
        relatedArticleIds: ["data-protection", "reset-password"],
      },
      {
        id: "user-content-guidelines",
        title: "How do I report inappropriate content?",
        slug: "user-content-guidelines",
        preview: "Community standards and content reporting process...",
        content: `<p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fast Fluency Africa maintains a respectful learning environment with clear community guidelines. To report inappropriate content:</p>
          
          <ul class="mt-4 text-md sm:text-md md:text-lg lg:text-xl">
            <li>1) For content in lessons or exercises, tap the flag icon in the corner of the screen, select the reason for reporting, and add details if needed</li>
            <li>2) For inappropriate behavior during coaching sessions, use the feedback form after the session or contact support immediately</li>
            <li>3) For community forum posts, use the 'Report' option on the specific post</li>
            <li>4) For offensive user profiles or messages, go to the user's profile and select 'Report User'</li>
          </ul>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">All reports are reviewed by our content moderation team, typically within 24-48 hours. We investigate thoroughly and take appropriate action based on our community guidelines. Actions may include content removal, warnings, or account suspension depending on severity.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We define inappropriate content as anything containing hate speech, harassment, explicit sexual content, promotion of harmful activities, or deliberate cultural insensitivity.</p>
          <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">We appreciate your help in maintaining a positive learning environment and treat all reports confidentially. If you experience urgent harassment, please contact our support team directly for immediate assistance.</p>
        `,
        tags: ["report", "inappropriate", "moderation", "community"],
        popularityRank: 12,
        lastUpdated: "2025-01-30",
        relatedArticleIds: ["compromised-account", "coaching-sessions"],
      },
    ],
  },
];

// Helper function to get all articles as a flat array
export const getAllArticles = () => {
  return supportCategories.flatMap((category) =>
    category.articles.map((article) => ({
      ...article,
      categoryId: category.id,
      categoryTitle: category.title,
      categorySlug: category.slug,
    }))
  );
};

// Helper function to get popular articles
export const getPopularArticles = (limit = 5) => {
  return getAllArticles()
    .sort((a, b) => a.popularityRank - b.popularityRank)
    .slice(0, limit);
};

// Helper function to search articles
export const searchArticles = (query) => {
  if (!query) return [];

  const searchTerms = query.toLowerCase().split(" ");

  return getAllArticles().filter((article) => {
    const searchableText = `
        ${article.title.toLowerCase()} 
        ${article.content.toLowerCase()} 
        ${article.tags.join(" ").toLowerCase()}
        ${article.categoryTitle.toLowerCase()}
      `;

    return searchTerms.every((term) => searchableText.includes(term));
  });
};

// Helper function to get article by ID
export const getArticleById = (id) => {
  return getAllArticles().find((article) => article.id === id);
};

// Helper function to get category by slug
export const getCategoryBySlug = (slug) => {
  return supportCategories.find((category) => category.slug === slug);
};

// Helper to get related articles for a given article
export const getRelatedArticles = (articleId) => {
  const article = getArticleById(articleId);
  if (!article) return [];

  return article.relatedArticleIds
    .map((id) => getArticleById(id))
    .filter(Boolean);
};
