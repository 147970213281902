import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import { cancelBooking } from "../../../firebase/bookings-api";
import { isPast, addHours } from "date-fns";
import { Calendar, RefreshCw, Search } from "lucide-react";
import {
  getUserTimezone,
  convertFromUTC,
} from "../../../utils/bookings/timezoneUtils";
import SessionCard from "../shared/session-card";

const StudentDashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchBookings();
  }, [activeTab]);

  const fetchBookings = async () => {
    try {
      setLoading(true);

      const userId = auth.currentUser?.uid;
      if (!userId) {
        setLoading(false);
        return;
      }

      const timezone = getUserTimezone();

      // Query bookings where current user is the student with the active tab's status
      const bookingsRef = collection(db, "bookings");
      const q = query(
        bookingsRef,
        where("studentId", "==", userId),
        where("status", "==", activeTab)
      );

      const querySnapshot = await getDocs(q);
      const bookingsList = [];

      // Process each booking
      for (const docSnap of querySnapshot.docs) {
        try {
          const bookingData = docSnap.data();

          // Convert UTC to local timezone
          const localSessionDateTime = bookingData.sessionDateTime
            ? convertFromUTC(bookingData.sessionDateTime.toDate(), timezone)
            : null;

          bookingsList.push({
            id: docSnap.id,
            ...bookingData,
            sessionDateTime: localSessionDateTime,
            userRole: "student", // Explicitly set role for SessionCard
          });
        } catch (bookingError) {
          console.error(
            `Error processing booking ${docSnap.id}:`,
            bookingError
          );
        }
      }

      // Sort by date - upcoming first
      const sortedBookings = bookingsList.sort((a, b) => {
        if (!a.sessionDateTime || !b.sessionDateTime) return 0;
        return new Date(a.sessionDateTime) - new Date(b.sessionDateTime);
      });

      setBookings(sortedBookings);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchBookings();
    setTimeout(() => {
      setRefreshing(false);
    }, 500); // Just to show the animation for visual feedback
  };

  const handleCancelSession = async (bookingId) => {
    try {
      // Check if cancellation is within policy
      const booking = bookings.find((b) => b.id === bookingId);
      const sessionTime = new Date(booking.sessionDateTime);
      const now = new Date();
      const hoursUntilSession = (sessionTime - now) / (1000 * 60 * 60);

      if (hoursUntilSession < 24) {
        if (
          !window.confirm(
            "Cancellations within 24 hours of the session may be subject to a fee. Do you still want to cancel?"
          )
        ) {
          return;
        }
      }

      setLoading(true);
      await cancelBooking(bookingId);
      await fetchBookings();
      alert("Session cancelled successfully");
    } catch (error) {
      console.error("Error cancelling session:", error);
      alert("There was an error cancelling the session: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderBookingsList = () => {
    if (loading) {
      return (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green"></div>
        </div>
      );
    }

    if (bookings.length === 0) {
      return (
        <div className="text-center py-8 text-gray">
          <p className="mb-4">You have no {activeTab} sessions.</p>

          {activeTab === "upcoming" && (
            <a
              href="/booking/coaches"
              className="inline-block px-4 py-2 bg-green text-black border-2 border-b-4 border-black rounded-md hover:bg-opacity-90 mt-2"
            >
              <Search size={18} className="inline mr-2" />
              Find a Coach
            </a>
          )}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {bookings.map((booking) => (
          <SessionCard
            key={booking.id}
            booking={booking}
            onCancel={
              activeTab === "upcoming" ? handleCancelSession : undefined
            }
            showRatingButton={true}
            className="mb-4"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <div className="mb-4 sm:mb-8">
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2 sm:mb-4">
          My Sessions
        </h1>
        <p className="text-gray">View and manage your coaching sessions.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-8">
        <div className="md:col-span-2">
          {/* Session Tabs */}
          <div className="bg-white border-2 border-b-4 border-darkgray shadow-md">
            <div className="flex border-b-2 border-darkgray overflow-x-auto">
              <button
                className={`px-3 sm:px-4 py-3 font-medium text-sm flex-1 whitespace-nowrap ${
                  activeTab === "upcoming"
                    ? "text-green border-b-4 border-green"
                    : "text-gray hover:text-black"
                }`}
                onClick={() => setActiveTab("upcoming")}
              >
                Upcoming Sessions
              </button>
              <button
                className={`px-3 sm:px-4 py-3 font-medium text-sm flex-1 whitespace-nowrap ${
                  activeTab === "completed"
                    ? "text-green border-b-4 border-green"
                    : "text-gray hover:text-black"
                }`}
                onClick={() => setActiveTab("completed")}
              >
                Completed Sessions
              </button>
              <button
                className={`px-3 sm:px-4 py-3 font-medium text-sm flex-1 whitespace-nowrap ${
                  activeTab === "cancelled"
                    ? "text-green border-b-4 border-green"
                    : "text-gray hover:text-black"
                }`}
                onClick={() => setActiveTab("cancelled")}
              >
                Cancelled Sessions
              </button>
            </div>

            <div className="p-3 sm:p-4">{renderBookingsList()}</div>
          </div>
        </div>

        {/* Quick Actions - Desktop sidebar, Mobile at bottom */}
        <div className="order-first md:order-last">
          <div className="bg-white border-2 border-b-4 border-darkgray shadow-md p-4">
            <h2 className="text-xl font-bold text-gray-800 mb-4">
              Quick Actions
            </h2>

            <div className="space-y-3">
              <button
                onClick={handleRefresh}
                className={`w-full px-4 py-2 bg-green bg-opacity-10 rounded text-green border-2 border-b-4 border-green hover:bg-opacity-20 flex items-center justify-center`}
                disabled={refreshing}
              >
                <RefreshCw
                  size={18}
                  className={`mr-2 ${refreshing ? "animate-spin" : ""}`}
                />
                {refreshing ? "Refreshing..." : "Refresh Sessions"}
              </button>

              <a
                href="/booking/coaches"
                className="block w-full px-4 py-2 bg-green rounded border-black shadow-md text-black text-center border-2 border-b-4 hover:bg-opacity-90"
              >
                <Search size={18} className="inline mr-2" />
                Find a Coach
              </a>
            </div>

            {/* Session Summary */}
            <div className="mt-6">
              <h3 className="font-medium text-gray mb-2">Session Summary</h3>
              <div className="grid grid-cols-2 gap-3">
                <div className="border-2 border-gray border-b-4 rounded p-3 text-center">
                  <div className="text-2xl font-bold text-green">
                    {bookings.length}
                  </div>
                  <div className="text-xs text-gray">
                    {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
                  </div>
                </div>

                <div className="border-2 border-gray border-b-4 rounded p-3 text-center">
                  <div className="text-2xl font-bold text-gold">
                    {bookings.reduce(
                      (total, booking) => total + (booking.duration || 0),
                      0
                    )}
                  </div>
                  <div className="text-xs text-gray">Minutes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
