import {
  IoCloseCircleOutline,
  IoLanguage,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { ControlButton } from "./controls/control-buttons";
import { Link } from "react-router-dom";

export const Header = ({
  currentDialogue,
  setLearningMaterialId,
  setModal,
  toggled,
  toggle,
  onDialogueComplete,
  setScore,
  ids,
  isSubscribed,
  hasPassed,
}) => (
  <div className="px-1 pl-2 pr-2 pb-4 pt-4 w-5/5 m-auto">
    <div className="border-2 border-b-4 border-black rounded px-1 m-auto shadow-2xl">
      <div className="flex flex-row justify-between items-center p-3">
        {/* Close Button */}
        {/* <span
          id={ids[0]}
          className="cursor-pointer hover:text-green text-center"
        >
          <ControlButton
            Icon={IoCloseCircleOutline}
            onClick={() => {
              setLearningMaterialId(1);
              setModal(true);
            }}
            className="w-full"
          />
        </span> */}

        {/* Translate Toggle
        <div className="relative inline-block">
          <button
            id={ids[1]}
            className={`cursor-pointer text-center ${
              isSubscribed === "mabena" || hasPassed
                ? "hover:text-green"
                : "text-gray"
            }`}
            onClick={(e) => {
              if (isSubscribed === "mabena" || hasPassed) {
                toggle(!toggled);
              } else {
                e.preventDefault();
              }
            }}
          >
            <div className="flex flex-col items-center">
              <IoLanguage className="w-full text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl" />
              <p className="text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl uppercase relative">
                {toggled ? "Hide Translations" : "Show Translations"}
                {isSubscribed !== "mabena" && !hasPassed && (
                  <span className="absolute z-10 w-64 p-2 text-sm text-black bg-white border border-black rounded shadow-lg opacity-0 hover:opacity-100 transition-all duration-200 bottom-full left-1/2 -translate-x-1/2 translate-y-2 font-semibold">
                    <span className="text-outline-black">🔒</span> Upgrade to a{" "}
                    <Link to="/checkout">
                      <b className="hover:text-green">Mabena Membership</b>
                    </Link>{" "}
                    or conquer this lesson to unlock this feature!
                  </span>
                )}
              </p>
            </div>
          </button>
        </div> */}

        {/* Next Button */}
        <span
          id={ids[2]}
          className="cursor-pointer hover:text-green text-center flex flex-col"
        >
          {/* <ControlButton
            Icon={IoChevronForwardCircleOutline}
            onClick={() => {
              onDialogueComplete();
              setScore(0);
            }}
            className="w-full"
          /> */}
        </span>
      </div>

      {/* Title Section */}
      <div className="text-center">
        <div className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-outline-black">
          {currentDialogue.icon}
        </div>
        <h1 className="text-center sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl p-2 font-bold font-headers">
          {currentDialogue.title.toUpperCase()}
        </h1>
      </div>
    </div>
  </div>
);
