// src/components/settings/analytics-settings-card.jsx
import React, { useState, useCallback, memo, useEffect } from "react";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";
import * as IoIcons from "react-icons/io5";
import { AnalyticsService } from "../../services/enhanced-analytics.service";

// Use React.memo to prevent unnecessary re-renders
const AnalyticsSettingsCard = memo(() => {
  // Track local state for immediate UI updates
  const [localTrackingEnabled, setLocalTrackingEnabled] = useState(() => {
    // Initialize from localStorage first for immediate value
    const storedValue = localStorage.getItem("analytics-tracking-enabled");
    return storedValue !== null ? storedValue === "true" : true;
  });

  // Use our safer analytics hook
  const { toggleTracking } = useSafeAnalytics();
  const [showInfo, setShowInfo] = useState(false);

  // Debug tracking state on mount and after changes
  useEffect(() => {
    console.log(
      "Analytics Settings Card - Current local state:",
      localTrackingEnabled
    );
  }, [localTrackingEnabled]);

  // Direct toggle implementation that updates all layers
  const handleToggle = useCallback(() => {
    console.log("Toggle clicked, current state:", localTrackingEnabled);

    const newValue = !localTrackingEnabled;
    try {
      // 1. Update local state for immediate UI feedback
      setLocalTrackingEnabled(newValue);

      // 2. Update localStorage
      localStorage.setItem("analytics-tracking-enabled", newValue.toString());

      // 3. Update service cache directly
      AnalyticsService.updateTrackingCache(newValue);
      console.log(`Directly updated service cache to: ${newValue}`);

      // 4. Call context toggle (may be async, so do this last)
      if (typeof toggleTracking === "function") {
        toggleTracking();
      }
    } catch (error) {
      console.error("Error toggling tracking:", error);

      // Revert local state if there was an error
      setLocalTrackingEnabled(!newValue);
    }
  }, [localTrackingEnabled, toggleTracking]);

  // Use useCallback to memoize event handlers
  const handleToggleInfo = useCallback(() => {
    setShowInfo((prev) => !prev);
  }, []);

  const handleResetPreferences = useCallback(() => {
    try {
      console.log("Resetting tracking preferences");

      // 1. Remove from localStorage
      localStorage.removeItem("analytics-tracking-enabled");

      // 2. Set default value (false for safety)
      const defaultValue = false;

      // 3. Update local state
      setLocalTrackingEnabled(defaultValue);

      // 4. Update service directly
      AnalyticsService.updateTrackingCache(defaultValue);

      // 5. Notify user
      alert("Your analytics tracking preferences have been reset.");
    } catch (error) {
      console.error("Error resetting preferences:", error);
      alert("Failed to reset preferences. Please try again.");
    }
  }, []);

  // For better performance in settings page
  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-8">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="font-headers text-left mt-4 w-full font-bold text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Privacy Settings
        </h1>
        <p className="text-left w-full text-sm sm:text-sm md:text-base lg:text-lg">
          Control data collection and privacy preferences for your account.
        </p>

        <div className="w-full mt-4 p-4 sm:p-6 border-2 border-b-4 rounded-lg">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
            <label className="flex items-center cursor-pointer w-full mb-3 sm:mb-0">
              <span className="text-base sm:text-lg mr-3 sm:mr-4">
                Analytics Tracking
              </span>
              <div className="relative">
                {/* Toggle switch - Use local state for immediate feedback */}
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={localTrackingEnabled}
                  onChange={handleToggle}
                  aria-label="Toggle analytics tracking"
                />
                <div
                  className={`block w-12 sm:w-14 h-7 sm:h-8 rounded-full ${
                    localTrackingEnabled ? "bg-green" : "bg-gray"
                  }`}
                ></div>
                <div
                  className={`absolute left-1 top-1 bg-white w-5 sm:w-6 h-5 sm:h-6 rounded-full transition-transform ${
                    localTrackingEnabled
                      ? "transform translate-x-5 sm:translate-x-6"
                      : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 sm:ml-4 text-xs sm:text-sm text-gray">
                {localTrackingEnabled
                  ? "Tracking enabled"
                  : "Tracking disabled"}
              </span>
            </label>

            <div className="text-3xl sm:text-4xl mt-2 sm:mt-0">
              {localTrackingEnabled ? "📊" : "🔒"}
            </div>
          </div>

          <p className="text-sm text-gray-500 mt-4">
            When enabled, we collect anonymous usage data to improve your
            learning experience. This helps us understand how you use the app
            and make it better.
            <button
              onClick={handleToggleInfo}
              className="ml-2 text-green hover:underline inline-flex items-center"
            >
              {showInfo ? "Hide details" : "Learn more"}
              <IoIcons.IoChevronDown
                className={`ml-1 transition-transform ${
                  showInfo ? "transform rotate-180" : ""
                }`}
              />
            </button>
          </p>

          {showInfo && (
            <div className="mt-4 bg-gray-50 p-4 rounded-lg border border-gray-200 text-sm">
              <h3 className="font-bold mb-2">What data do we collect?</h3>
              <ul className="list-disc pl-5 space-y-1">
                <li>Lesson completions and progress</li>
                <li>Practice session results</li>
                <li>Time spent on different activities</li>
                <li>Features you use most frequently</li>
              </ul>

              <h3 className="font-bold mt-4 mb-2">How we use this data:</h3>
              <ul className="list-disc pl-5 space-y-1">
                <li>Improve lesson content and difficulty</li>
                <li>Enhance features based on usage patterns</li>
                <li>Identify and fix issues</li>
                <li>Create personalized learning experiences</li>
              </ul>

              <p className="mt-4">
                We never sell your personal data or share it with third parties
                without your consent. You can disable tracking at any time, and
                your learning progress will not be affected.
              </p>
            </div>
          )}

          <div className="mt-4 p-3 bg-red bg-opacity-10 rounded-lg border border-red border-opacity-30">
            <div className="flex items-start">
              <IoIcons.IoInformationCircle className="text-red flex-shrink-0 mt-0.5 mr-2" />
              <p className="text-sm text-gray-700">
                Disabling analytics tracking may limit our ability to optimize
                your learning experience and provide personalized content
                recommendations, but won't affect your core learning
                functionality.
              </p>
            </div>
          </div>

          {/* Simplified Data Rights Section */}
          <div className="mt-6 pt-4 border-t border-gray-200">
            <h3 className="text-lg font-semibold mb-3">Your Data Rights</h3>
            <p className="text-sm text-gray-600 mb-4">
              Under data protection regulations like GDPR, you have the right to
              access and control your personal data. To request a full export or
              deletion of your data, please contact our support team.
            </p>

            <button
              onClick={handleResetPreferences}
              className="flex items-center justify-center px-4 py-3 bg-red border-2 border-red border-b-4 text-white rounded hover:bg-opacity-90 w-full"
            >
              <IoIcons.IoRefresh className="mr-2" />
              Reset Tracking Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

AnalyticsSettingsCard.displayName = "AnalyticsSettingsCard";

export default AnalyticsSettingsCard;
