import React, { useEffect, useState, useRef } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import PuffLoader from "react-spinners/PuffLoader";

import Hero from "../../components/cards/hero.component.jsx";
import Feature from "../../components/cards/features.component.jsx";
import Testimonial from "../../components/cards/testimonials.component.jsx";
import ArticleHero from "../../components/cards/articles-hero.component.jsx";
import MailOptinForm from "../../components/cards/mailoptinform.component.jsx";
import Footer from "../../components/footer/footer.component.jsx";
import Notification from "../../components/notifications/notification-toast.component.jsx";
import CookieConsentManager from "../cookies/cookies-consent-manager.jsx";

function HomePage() {
  // Page title
  useEffect(() => {
    document.title =
      "Fast Fluency Africa - The fun way to know African languages, cultures, and stories";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Loading state
  const [loading, setLoading] = useState(true);

  // Separate hero loading state to ensure hero renders properly
  const [heroReady, setHeroReady] = useState(false);

  // Refs
  const heroRef = useRef(null);

  // Scroll progress for parallax effects
  const { scrollYProgress } = useScroll();
  const scrollOpacity = useTransform(scrollYProgress, [0, 0.1], [1, 0]);
  const scrollScale = useTransform(scrollYProgress, [0, 0.1], [1, 0.99]);

  // Refs for scroll into view animations
  const [featuresRef, featuresInView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const [articlesRef, articlesInView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const [testimonialsRef, testimonialsInView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const [mailOptinRef, mailOptinInView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Loading animation
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
      // Force window to top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Effect to make hero visible after loading
  useEffect(() => {
    if (!loading) {
      // Delay setting heroReady to ensure DOM has updated
      const heroTimeout = setTimeout(() => {
        setHeroReady(true);
        // Force scroll to top again
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);

      return () => clearTimeout(heroTimeout);
    }
  }, [loading]);

  // Variants for staggered animations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  // Section header animation variant
  const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        duration: 0.8,
      },
    },
  };

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <motion.div
          key="loader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          data-testid="loading-spinner"
          className="flex flex-col justify-center items-center h-screen bg-white"
        >
          <PuffLoader color={"#991616"} loading={loading} size={100} />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="mt-6 text-red font-headers text-lg"
          >
            Your Pan-African Pilgrimage Starts Now...
          </motion.p>
        </motion.div>
      ) : (
        <motion.div
          key="content"
          className="flex flex-col justify-between items-center m-auto relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Scroll to top button */}
          <motion.button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 z-50 bg-red text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg border-2 border-white"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: scrollYProgress.get() > 0.1 ? 1 : 0,
              scale: scrollYProgress.get() > 0.1 ? 1 : 0.8,
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 15l-6-6-6 6" />
            </svg>
          </motion.button>

          <main className="flex flex-col justify-between items-center w-full m-auto overflow-hidden">
            <Notification />
            <CookieConsentManager />

            {/* Hero Section with guaranteed visibility */}
            <div
              ref={heroRef}
              className="w-full"
              style={{
                opacity: 1,
                visibility: "visible",
                display: "block",
                position: "relative",
                zIndex: 10,
              }}
            >
              <Hero />
            </div>

            {/* Feature Section */}
            <motion.div
              ref={featuresRef}
              className="flex flex-col justify-center items-center w-full overflow-hidden"
              variants={containerVariants}
              initial="hidden"
              animate={featuresInView ? "visible" : "hidden"}
            >
              <motion.h2
                variants={headerVariants}
                className=" mb-6 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8 mt-6"
                data-testid="features-header"
              >
                Achieve{" "}
                <span className="text-red italic relative">
                  fluency fast
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-1 bg-red rounded-full"
                    initial={{ width: 0 }}
                    animate={featuresInView ? { width: "100%" } : { width: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                  />
                </span>{" "}
                inside our Edutainment Vault
              </motion.h2>

              <motion.div variants={itemVariants} className="w-full px-4">
                <Feature />
              </motion.div>
            </motion.div>

            {/* Articles Section */}
            <motion.div
              ref={articlesRef}
              className="flex flex-col justify-center items-center w-5/6 bg-[#FCFCEE] relative overflow-hidden"
              variants={containerVariants}
              initial="hidden"
              animate={articlesInView ? "visible" : "hidden"}
            >
              {/* Decorative elements */}
              <motion.div
                className="absolute top-6 left-6 w-24 h-24 bg-red/10 rounded-full"
                initial={{ scale: 0 }}
                animate={articlesInView ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
              <motion.div
                className="absolute bottom-6 right-6 w-32 h-32 bg-gold/10 rounded-full"
                initial={{ scale: 0 }}
                animate={articlesInView ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              />

              <motion.h2
                variants={headerVariants}
                className=" mb-6 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8 relative z-10"
                data-testid="articles-header"
              >
                Get started{" "}
                <span className="text-red italic relative">
                  for free
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-1 bg-red rounded-full"
                    initial={{ width: 0 }}
                    animate={articlesInView ? { width: "100%" } : { width: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                  />
                </span>{" "}
                with some of our best content
              </motion.h2>

              <motion.div
                variants={itemVariants}
                className="w-full px-4 relative z-10"
              >
                <ArticleHero />
              </motion.div>
            </motion.div>

            {/* Testimonial Section */}
            <motion.div
              ref={testimonialsRef}
              className="flex flex-col justify-center items-center w-full py-12 relative overflow-hidden"
              variants={containerVariants}
              initial="hidden"
              animate={testimonialsInView ? "visible" : "hidden"}
            >
              {/* Quote mark decorative element */}
              <motion.div
                className="absolute top-16 left-12 text-8xl font-serif text-red/10"
                initial={{ opacity: 0, x: -50 }}
                animate={
                  testimonialsInView
                    ? { opacity: 1, x: 0 }
                    : { opacity: 0, x: -50 }
                }
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                "
              </motion.div>
              <motion.div
                className="absolute bottom-16 right-12 text-8xl font-serif text-red/10"
                initial={{ opacity: 0, x: 50 }}
                animate={
                  testimonialsInView
                    ? { opacity: 1, x: 0 }
                    : { opacity: 0, x: 50 }
                }
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                "
              </motion.div>

              <motion.h2
                variants={headerVariants}
                className=" mb-6 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8"
                data-testid="testimonials-header"
              >
                Here's what{" "}
                <span className="text-red relative">
                  some people
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-1 bg-red rounded-full"
                    initial={{ width: 0 }}
                    animate={
                      testimonialsInView ? { width: "100%" } : { width: 0 }
                    }
                    transition={{ duration: 0.8, delay: 0.5 }}
                  />
                </span>{" "}
                think about us
              </motion.h2>

              <motion.div variants={itemVariants} className="w-5/6">
                <Testimonial />
              </motion.div>
            </motion.div>

            {/* Mail Optin Section */}
            <motion.div
              ref={mailOptinRef}
              className="flex flex-col justify-center items-center w-5/6 py-12 bg-[#FCFCEE] relative overflow-hidden"
              variants={containerVariants}
              initial="hidden"
              animate={mailOptinInView ? "visible" : "hidden"}
            >
              {/* Decorative elements */}
              <motion.div
                className="absolute top-0 right-0 w-24 h-24 bg-green/10 rotate-45"
                initial={{ scale: 0 }}
                animate={mailOptinInView ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
              <motion.div
                className="absolute bottom-0 left-0 w-32 h-32 bg-gold/10 rotate-45"
                initial={{ scale: 0 }}
                animate={mailOptinInView ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              />

              <motion.h2
                variants={headerVariants}
                className=" mb-6 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8 relative z-10"
                data-testid="mailoptin-header"
              >
                <span className="text-red relative">
                  Stay connected
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-1 bg-red rounded-full"
                    initial={{ width: 0 }}
                    animate={mailOptinInView ? { width: "100%" } : { width: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                  />
                </span>{" "}
                with your <span>family and friends...</span>{" "}
                <span className="text-red relative">
                  the fun way
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-1 bg-red rounded-full"
                    initial={{ width: 0 }}
                    animate={mailOptinInView ? { width: "100%" } : { width: 0 }}
                    transition={{ duration: 0.8, delay: 0.7 }}
                  />
                </span>
              </motion.h2>

              <motion.div
                variants={itemVariants}
                className="w-full px-4 relative z-10"
              >
                <MailOptinForm />
              </motion.div>
            </motion.div>
          </main>

          {/* Footer with fade-in effect */}
          <motion.footer
            className="bg-gradient-to-b from-white to-gold w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Footer />
          </motion.footer>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default HomePage;
