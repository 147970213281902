import MCQFlash from "../social/exercise-mcqflash.component.jsx";
import TagThePairs from "../social/exercise-tagging.component.jsx";
import ArrangeExercise from "../social/exercise-arrange-mock.component.jsx";
import TypeWhatYouHear from "../social/exercise-audio-trans-mock.component.jsx";
import FillInBlank from "../social/exercise-fitb-mock.componenet.jsx";
import AudioMCQMock from "../social/exercise-mcq-audio.component.jsx";
import TranslationMock from "../social/exercise-translate-input-mock.component.jsx";
import MarkCorrectMeaning from "../social/exercise-mark-correct-mock.component.jsx";
import PictureMatchingMock from "../social/exercise-matchimg-mock.jsx";
import MultipleChoiceV1 from "../social/exercise-mcq-v1.component.jsx";
import ShuffleExercise from "../social/exercise-shuffle-mock.component.jsx";
import ReadAndRespond from "../social/exercise-read-and-respond-mock.component.jsx";

const ExerciseComponents = {
  audio_mcq: AudioMCQMock,
  mcqflash: MCQFlash,
  shuffle_words: ShuffleExercise,
  arrange: ArrangeExercise,
  fill_in_the_blank: FillInBlank,
  mcq1: MultipleChoiceV1,
  matching_images: PictureMatchingMock,
  tagging_pairs: TagThePairs,
  translate_input: TranslationMock,
  audio_transcription: TypeWhatYouHear,
  mark_correct_meaning: MarkCorrectMeaning,
  read_and_respond: ReadAndRespond,
};

export default ExerciseComponents;
