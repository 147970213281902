import { useState, useEffect, useCallback } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";

/**
 * Custom hook for dictionary search functionality
 * @param {string} initialSearchTerm - Initial search term (optional)
 * @param {Function} onResultsFound - Callback for when results are found
 * @returns {Object} Search state and handlers
 */
export const useDictionarySearch = (
  initialSearchTerm = "",
  onResultsFound = () => {}
) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);

  // Create search variants for more comprehensive searching
  const createSearchVariants = useCallback((term) => {
    if (!term) return [];

    const trimmedLower = term.trim().toLowerCase();

    // Basic variants: original, capitalized
    const variants = [
      trimmedLower,
      trimmedLower.charAt(0).toUpperCase() + trimmedLower.slice(1),
    ];

    // Handle verb variations
    if (trimmedLower.startsWith("to ")) {
      // For "to fill" search for "fill" and "-fill"
      const verbRoot = trimmedLower.substring(3);
      variants.push(verbRoot);
      variants.push(`-${verbRoot}`);
    } else {
      // For "fill" search for "to fill" and "-fill"
      variants.push(`to ${trimmedLower}`);
      variants.push(`-${trimmedLower}`);
    }

    // If term already starts with "-", also search without it
    if (trimmedLower.startsWith("-") && trimmedLower.length > 1) {
      const withoutDash = trimmedLower.substring(1);
      variants.push(withoutDash);
      variants.push(`to ${withoutDash}`);
    }

    // Remove duplicates and empty strings
    return [...new Set(variants)].filter((v) => v);
  }, []);

  // Clear results
  const clearResults = useCallback(() => {
    setResults([]);
  }, []);

  // Perform search
  const performSearch = useCallback(
    async (term) => {
      if (!term?.trim()) {
        clearResults();
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        // Format and create search variants for the term
        const searchVariants = createSearchVariants(term);

        if (searchVariants.length === 0) {
          setIsLoading(false);
          return;
        }

        const searchesRef = collection(db, "searches");
        let allPromises = [];
        let queryFields = ["entry", "translation", "definition", "english"];

        // Create queries for all combinations of fields and variants
        queryFields.forEach((field) => {
          searchVariants.forEach((variant) => {
            allPromises.push(
              getDocs(query(searchesRef, where(field, "==", variant)))
            );
          });
        });

        const snapshots = await Promise.all(allPromises);

        // Log raw results count for debugging
        const totalDocs = snapshots.reduce(
          (total, snapshot) => total + snapshot.docs.length,
          0
        );

        // Combine and process all results
        const combined = snapshots.flatMap((snapshot) =>
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        // Convert numeric IDs to strings for consistent deduplication
        const processedResults = combined.map((item) => ({
          ...item,
          id: String(item.id),
        }));

        // Deduplicate results by ID
        const uniqueResults = Array.from(
          new Map(processedResults.map((item) => [item.id, item])).values()
        );

        // Update search history
        setSearchHistory((prev) => {
          if (!prev.includes(term)) {
            const newHistory = [term, ...prev].slice(0, 10); // Keep last 10 searches
            return newHistory;
          }
          return prev;
        });

        // Set results
        setResults(uniqueResults);

        // Call result found callback
        onResultsFound(uniqueResults.length > 0);
      } catch (err) {
        console.error("Search error:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [clearResults, createSearchVariants, onResultsFound]
  );

  // Search with a new term
  const search = useCallback(
    (term) => {
      setSearchTerm(term);
      performSearch(term);
    },
    [performSearch]
  );

  // Return search state and handlers
  return {
    searchTerm,
    results,
    isLoading,
    error,
    searchHistory,
    search,
    clearResults,
    hasResults: results.length > 0,
  };
};

export default useDictionarySearch;
