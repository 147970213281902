// components/modals/CoinsModalContent.jsx - Mobile Responsive Version
import React from "react";
import { Link } from "react-router-dom";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";

const CoinsModalContent = ({ onClose }) => {
  const { balance, purchaseHistory, addCurrency } = useShopWithPowerUps();
  const { streak, userStats } = useSafeAnalytics(); // Add analytics data

  // Get recent transactions (last 5)
  const recentTransactions = purchaseHistory
    ? purchaseHistory
        .slice(0, 5)
        .sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0))
    : [];

  const handleAddCoins = async () => {
    // This would normally open a payment modal or redirect to payment gateway
    // For now, let's simulate a "Watch Ad" functionality
    try {
      const result = await addCurrency(10, "ad_reward");
      if (result.success) {
        alert("You earned 10 coins for watching an ad!");
      } else {
        alert("Failed to add coins: " + result.message);
      }
    } catch (error) {
      console.error("Error adding coins:", error);
      alert("An error occurred while trying to add coins.");
    }
  };

  // Calculate potential streak rewards
  const getStreakRewardProgress = () => {
    const currentStreak = streak || userStats?.current_streak || 0;

    // Milestone tiers
    const milestones = [
      { days: 3, reward: "15 coins" },
      { days: 7, reward: "30 coins" },
      { days: 14, reward: "70 coins + 1 streak freeze" },
      { days: 30, reward: "150 coins + special badge" },
    ];

    // Find the next milestone
    const nextMilestone = milestones.find((m) => currentStreak < m.days);

    if (!nextMilestone) {
      return { text: "All streak milestones completed!", progress: 100 };
    }

    // Find the previous milestone
    const prevMilestoneIndex =
      milestones.findIndex((m) => m.days === nextMilestone.days) - 1;
    const prevMilestone =
      prevMilestoneIndex >= 0 ? milestones[prevMilestoneIndex] : { days: 0 };

    // Calculate progress to next milestone
    const totalDays = nextMilestone.days - prevMilestone.days;
    const daysCompleted = currentStreak - prevMilestone.days;
    const progress = Math.round((daysCompleted / totalDays) * 100);

    return {
      text: `${currentStreak}/${nextMilestone.days} days`,
      nextReward: nextMilestone.reward,
      progress,
    };
  };

  const streakProgress = getStreakRewardProgress();

  return (
    <div className="space-y-3 md:space-y-4 px-2 md:px-0">
      <div className="text-center">
        <span className="text-3xl md:text-4xl">🟠</span>
        <h3 className="text-xl md:text-2xl font-bold mt-1 md:mt-2">
          Your Balance: {Math.round(balance)}
        </h3>
        <p className="text-xs md:text-sm text-gray mt-1">
          Coins can be used to purchase items in the Shop
        </p>
      </div>

      <div className="space-y-2 md:space-y-3">
        <h4 className="font-semibold text-sm md:text-base">
          Spend your coins:
        </h4>

        <Link to="/shop" onClick={onClose}>
          <button className="w-full bg-gold text-black border-b-4 border-2 border-black py-2 rounded hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center text-xs md:text-sm">
            <span className="mr-2">🛒</span>
            Visit Shop
          </button>
        </Link>
      </div>

      {/* Enhanced streak reward progress section */}
      <div className="bg-red bg-opacity-10 p-3 md:p-4 rounded-lg border-2 border-red">
        <h4 className="font-semibold text-red mb-1 md:mb-2 text-sm md:text-base">
          Streak Rewards Progress
        </h4>
        <div className="flex justify-between text-xs md:text-sm mb-1">
          <span>
            Current Streak: {streak || userStats?.current_streak || 0} days
          </span>
          <span>{streakProgress.text}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
          <div
            className="bg-red h-2 rounded-full"
            style={{ width: `${streakProgress.progress}%` }}
          ></div>
        </div>
        {streakProgress.nextReward && (
          <p className="text-xs md:text-sm text-gray-700">
            Next reward: {streakProgress.nextReward}
          </p>
        )}
      </div>

      {recentTransactions.length > 0 && (
        <div className="mt-3 md:mt-4">
          <h4 className="font-semibold mb-1 md:mb-2 text-sm md:text-base">
            Recent Transactions:
          </h4>
          <div className="border-darkgray border-b-4 border-2 rounded overflow-hidden">
            {recentTransactions.map((transaction, index) => (
              <div
                key={index}
                className="p-2 md:p-3 border-b border-darkgray last:border-b-0 flex justify-between items-center"
              >
                <div>
                  <p className="font-medium text-xs md:text-sm">
                    {transaction.productName ||
                      transaction.type ||
                      "Transaction"}
                  </p>
                  <p className="text-xs text-gray">
                    {transaction.timestamp
                      ? new Date(transaction.timestamp).toLocaleDateString()
                      : "Unknown date"}
                  </p>
                </div>
                <span
                  className={`font-bold text-xs md:text-sm ${
                    transaction.amount > 0 ? "text-red" : "text-green"
                  }`}
                >
                  {transaction.amount > 0
                    ? `-${transaction.amount}`
                    : `${transaction.amount}`}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="border-darkgray border-b-4 border-2 p-3 md:p-4 rounded">
        <h4 className="font-semibold mb-1 md:mb-2 text-sm md:text-base">
          Ways to Earn Coins
        </h4>
        <ul className="space-y-1 text-xs md:text-sm text-gray">
          <li>
            • Maintain your learning streak (
            {streak || userStats?.current_streak || 0} days current)
          </li>
          <li>
            • Reach XP milestones (current: {userStats?.total_xp || 0} XP)
          </li>
          <li>• Complete daily achievements</li>
          <li>• Perfect lesson scores</li>
        </ul>
      </div>
    </div>
  );
};

export default CoinsModalContent;
