import React, { useContext, useEffect, useState } from "react";
import { LearningMaterialsContext } from "../../../helpers/contexts";
import { motion, AnimatePresence } from "framer-motion";

function ProgressBar() {
  const { exercisesCompleted } = useContext(LearningMaterialsContext);
  const [prevCompleted, setPrevCompleted] = useState(exercisesCompleted);
  const [showCelebration, setShowCelebration] = useState(false);
  const [recentlyIncreased, setRecentlyIncreased] = useState(false);
  const [milestone, setMilestone] = useState(null);

  // Total exercises required to complete
  const totalExercises = 12;

  // Calculate percentage for easier milestone management
  const percentage = Math.floor((exercisesCompleted / totalExercises) * 100);
  // Ensure percentage never exceeds 100%
  const safePercentage = Math.min(percentage, 100);

  // Determine if we should show confetti based on reaching milestones
  useEffect(() => {
    if (exercisesCompleted > prevCompleted) {
      // Progress increased - show brief celebration
      setRecentlyIncreased(true);
      setTimeout(() => setRecentlyIncreased(false), 1500);

      // Check for major milestones (25%, 50%, 75%, 100%)
      const currentMilestone = Math.floor(safePercentage / 25) * 25;
      if (
        currentMilestone >= 25 &&
        currentMilestone >
          Math.floor(((prevCompleted / totalExercises) * 100) / 25) * 25
      ) {
        setMilestone(currentMilestone);
        setShowCelebration(true);
        setTimeout(() => setShowCelebration(false), 3000);
      }
    }

    setPrevCompleted(exercisesCompleted);
  }, [exercisesCompleted, prevCompleted, safePercentage]);

  // Helper to get colors based on progress
  const getProgressColors = () => {
    // Colors transition based on progress
    if (safePercentage < 25) return "bg-gray";
    if (safePercentage < 50) return "bg-gold";
    if (safePercentage < 75) return "bg-gold";
    return "bg-red";
  };

  // Characters that appear at different milestones
  const getMilestoneEmoji = () => {
    if (safePercentage >= 100) return "🏆";
    if (safePercentage >= 75) return "🚀";
    if (safePercentage >= 50) return "🌟";
    if (safePercentage >= 25) return "👍";
    return "😊";
  };

  // Calculate the safe progress width percentage (never more than 100%)
  const getProgressWidth = () => {
    const width = (exercisesCompleted / totalExercises) * 100;
    return `${Math.min(width, 100)}%`;
  };

  // Calculate emoji x position (ensure it doesn't go beyond the container)
  const getEmojiXPosition = () => {
    // Calculate percentage (capped at 100%)
    const progressPercent = Math.min(
      (exercisesCompleted / totalExercises) * 100,
      100
    );

    // If we're at or near 100%, position it at the end minus its width
    if (progressPercent > 97) {
      return "calc(100% - 24px)";
    }

    // Otherwise, center it on the current progress point
    return `calc(${progressPercent}% - 12px)`;
  };

  return (
    <div className="relative w-full flex justify-center mb-1">
      {/* Container with extra padding to ensure emojis are visible */}
      <div className="relative w-11/12 sm:w-10/12 pt-6 sm:pt-8 pb-2">
        {/* Main progress bar - with overflow hidden to contain the fill */}
        <div className="bg-darkgray border-gray border-2 border-b-4 h-6 sm:h-8 sticky w-full rounded relative overflow-hidden">
          {/* Progress fill - using calculated width with upper bound */}
          <motion.div
            className={`h-full ${getProgressColors()} rounded-r`}
            initial={{ width: 0 }}
            animate={{
              width: getProgressWidth(),
              scale: recentlyIncreased ? [1, 1.03, 1] : 1,
            }}
            transition={{
              width: { duration: 0.5, ease: "easeOut" },
              scale: { duration: 0.5, times: [0, 0.5, 1], ease: "easeInOut" },
            }}
            style={{ maxWidth: "100%" }} // Extra safeguard
          >
            {/* Only show percentage if there's enough width */}
            {safePercentage > 10 && (
              <motion.div
                className="absolute top-0 right-1 h-full flex items-center justify-center text-white font-bold text-xs sm:text-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {/* {safePercentage}% */}
              </motion.div>
            )}
          </motion.div>
        </div>

        {/* Progress character that rides along - positioned ABOVE the bar (outside the overflow:hidden container) */}
        <motion.div
          className="absolute top-[-24px] sm:top-[-30px] text-xl sm:text-2xl"
          initial={{ x: 0 }}
          animate={{
            x: getEmojiXPosition(),
            y: recentlyIncreased ? [0, -10, 0] : 0,
          }}
          transition={{
            x: { duration: 0.5 },
            y: { duration: 0.5, ease: "easeOut" },
          }}
          style={{
            zIndex: 30,
            marginTop: "28px", // Extra margin to ensure visibility
          }}
        >
          {/* {getMilestoneEmoji()} */}
        </motion.div>

        {/* Stars that appear when progress increases - positioned relative to container */}
        <AnimatePresence>
          {recentlyIncreased && (
            <>
              {[...Array(5)].map((_, i) => {
                // Calculate a safe x position that won't exceed container bounds
                const progressPos = Math.min(
                  (exercisesCompleted / totalExercises) * 100,
                  98
                );
                const baseXPos = `calc(${progressPos}% - 12px)`;

                return (
                  <motion.div
                    key={`star-${i}`}
                    className="absolute text-gold text-base sm:text-xl pointer-events-none"
                    initial={{
                      opacity: 1,
                      scale: 0,
                      x: baseXPos,
                      y: 4, // Start inside the progress bar
                    }}
                    animate={{
                      opacity: [1, 0],
                      scale: [0, 1.5],
                      x: `calc(${baseXPos} + ${-20 + Math.random() * 40}px)`,
                      y: [4, -30 + Math.random() * -20], // Go upward from the bar
                    }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.8 + Math.random() * 0.5,
                      ease: "easeOut",
                      delay: Math.random() * 0.3,
                    }}
                    style={{ zIndex: 20 }}
                  >
                    ✨
                  </motion.div>
                );
              })}
            </>
          )}
        </AnimatePresence>
      </div>

      {/* Milestone celebrations - positioned BELOW the bar for visibility */}
      <AnimatePresence>
        {showCelebration && (
          <motion.div
            className="absolute top-8 sm:top-10 left-1/2 transform -translate-x-1/2 bg-white px-3 sm:px-4 py-2 rounded-full border-2 border-gray shadow-lg"
            initial={{ opacity: 0, y: 20, scale: 0.8 }}
            animate={{
              opacity: 1,
              y: 0,
              scale: 1,
              rotate: [-5, 5, -3, 3, 0],
            }}
            exit={{ opacity: 0, y: -20, scale: 0.8 }}
            transition={{
              duration: 0.5,
              rotate: { duration: 0.5, times: [0, 0.25, 0.5, 0.75, 1] },
            }}
            style={{ zIndex: 40 }}
          >
            <div className="flex items-center space-x-1 sm:space-x-2">
              <span className="text-base sm:text-xl">
                {milestone === 100
                  ? "🎉"
                  : milestone === 75
                  ? "🚀"
                  : milestone === 50
                  ? "🌟"
                  : "👍"}
              </span>
              <span className="font-bold text-green text-xs sm:text-sm">
                {milestone === 100
                  ? "Amazing! You did it!"
                  : milestone === 75
                  ? "Wow! Almost there!"
                  : milestone === 50
                  ? "Halfway there!"
                  : "Great start!"}
              </span>
              <span className="text-base sm:text-xl">
                {milestone === 100
                  ? "🎉"
                  : milestone === 75
                  ? "🚀"
                  : milestone === 50
                  ? "🌟"
                  : "👍"}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Confetti celebration for major milestones */}
      <AnimatePresence>
        {showCelebration && (
          <>
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={`confetti-${i}`}
                className="absolute pointer-events-none"
                style={{
                  top: `${20 + Math.random() * 30}px`, // Position relative to progress bar
                  left: `${20 + Math.random() * 60}%`, // Spread across the screen width
                  width: `${5 + Math.random() * 10}px`,
                  height: `${5 + Math.random() * 10}px`,
                  background: `hsl(${Math.random() * 360}, 80%, 60%)`,
                  borderRadius: Math.random() > 0.5 ? "50%" : "0%",
                  zIndex: 25,
                }}
                initial={{
                  y: 0,
                  opacity: 1,
                  rotate: 0,
                }}
                animate={{
                  y: [0, 100 + Math.random() * 50], // Keep within visible area
                  x: [0, -30 + Math.random() * 60],
                  opacity: [1, 1, 0],
                  rotate: Math.random() * 360,
                }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 1.2 + Math.random() * 0.8,
                  ease: "easeOut",
                  times: [0, 0.8, 1],
                }}
              />
            ))}
          </>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ProgressBar;
