import React, { useState } from "react";
import { default as ffaloginlogo } from "../../assets/icons/FFA-beta-logo-alt.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import AuthRedirectService from "../../services/auth-redirect.service";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [guestLoading, setGuestLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [earlyAccessError, setEarlyAccessError] = useState(false);
  const [earlyAccessLoading, setEarlyAccessLoading] = useState(false);

  const { signIn, googleSignIn, guestLogin } = useAuth();

  const handleGuestLogin = async () => {
    try {
      await setGuestLoading(true);
      await guestLogin();
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      await setGuestLoading(false);
    }
  };

  const handleEarlyAccessNavigate = () => {
    setEarlyAccessLoading(true);
    setTimeout(() => {
      navigate("/early-access");
    }, 1000);
  };

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    setEarlyAccessError(false);
    setError("");

    try {
      const result = await googleSignIn();

      // If the URL has the early access error param, show the appropriate error
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("error") === "early_access_unauthorized") {
        setEarlyAccessError(true);
      } else if (result && result.error === "early_access_unauthorized") {
        setEarlyAccessError(true);
      } else if (result && result.error) {
        setError(result.error);
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setGoogleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required!"),
      password: Yup.string().required("Required!"),
    }),
    onSubmit: async () => {
      setEmailLoading(true);
      try {
        AuthRedirectService.clearAllOnboardingStorage();
        await signIn(formik.values.email, formik.values.password);
        navigate("/dashboard");
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        await setEmailLoading(false);
      }
    },
  });

  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-4 py-8 bg-gradient-to-b from-gold to-white">
      <div
        className="w-full mx-auto bg-white/40 border border-gold rounded-lg shadow-lg p-6 md:p-8 
        xs:w-5/6 
        sm:w-4/5 
        md:w-3/5 
        lg:w-2/5 
        xl:w-1/3 
        2xl:max-w-2/5"
      >
        {/* Early Access Alert */}
        {earlyAccessError && (
          <div className="mb-6 p-4 bg-red/10 border-l-4 border-red text-black rounded">
            <h4 className="font-headers font-bold text-red mb-1">
              Early Access Restricted
            </h4>
            <p className="text-sm md:text-base">
              Our application is currently in early access. Your account hasn't
              been approved yet.
              <a
                href="/early-access"
                className="ml-1 text-red font-semibold underline"
              >
                Join our waitlist
              </a>{" "}
              to be notified when public access becomes available.
            </p>
          </div>
        )}

        {/* Logo */}
        <div className="flex justify-center mb-6">
          <Link to="/">
            <img
              className="h-24 md:h-32 lg:h-40"
              src={ffaloginlogo}
              alt="Fast Fluency Africa logo"
            />
          </Link>
        </div>

        {/* Heading */}
        <h3 className="text-center text-black font-headers font-bold uppercase text-base md:text-lg lg:text-xl mb-6">
          Sign in to your account
        </h3>

        {/* Google Sign In */}
        <div className="mb-6">
          <button
            onClick={handleGoogleSignIn}
            className="w-full flex items-center justify-center bg-white text-black border-2 border-b-4 border-black hover:border-green active:border-green py-3 px-4 rounded font-medium text-sm md:text-base uppercase transition duration-150 ease-in-out transform active:scale-95 active:translate-y-1 active:border-b-2"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {googleLoading ? (
              <BeatLoader
                color={hovered ? "#db4437" : "#848717"}
                loading={googleLoading}
                size={8}
              />
            ) : (
              <>
                <FcGoogle className="text-xl mr-3" />
                <span className="font-body font-bold">Sign in with Google</span>
              </>
            )}
          </button>
        </div>

        {/* Divider */}
        <div className="flex items-center mb-6">
          <div className="flex-1 h-px bg-black"></div>
          <div className="px-4 text-sm md:text-base uppercase font-medium">
            sign in with email
          </div>
          <div className="flex-1 h-px bg-black"></div>
        </div>

        {/* Email Form */}
        <form onSubmit={formik.handleSubmit} className="mb-6">
          {/* Email Field */}
          <div className="mb-4">
            <div className="flex items-center border-2 border-b-4 border-black hover:border-green focus-within:border-green bg-white rounded overflow-hidden">
              <span className="text-base p-3">✉️</span>
              <input
                className="flex-1 p-3 bg-white text-sm md:text-base uppercase focus:outline-none"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <p className="mt-1 text-red text-sm">{formik.errors.email}</p>
            )}
          </div>

          {/* Password Field */}
          <div className="mb-6">
            <div className="flex items-center border-2 border-b-4 border-black hover:border-green focus-within:border-green bg-white rounded overflow-hidden">
              <span className="text-base p-3">🔒</span>
              <input
                className="flex-1 p-3 bg-white text-sm md:text-base uppercase focus:outline-none"
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </div>
            {formik.touched.password && formik.errors.password && (
              <p className="mt-1 text-red text-sm">{formik.errors.password}</p>
            )}
          </div>

          {/* Sign In Button */}
          <button
            type="submit"
            data-testid="sign-in-button"
            className="w-full py-3 px-6 border-2 border-b-4 rounded text-center bg-transparent text-green font-bold hover:text-white border-green hover:border-white hover:bg-green text-sm md:text-base uppercase shadow-md hover:shadow-lg transition duration-150 transform active:scale-95 active:translate-y-1 active:border-b-2"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {emailLoading ? (
              <BeatLoader
                color={hovered ? "#FCFCEE" : "#848717"}
                loading={emailLoading}
                size={8}
              />
            ) : (
              "Sign In"
            )}
          </button>

          {/* Error Message */}
          {error && (
            <p className="mt-3 text-center text-green text-sm md:text-base">
              Please type in a correct email and password combination.
            </p>
          )}
        </form>

        {/* Divider */}
        <div className="flex items-center mb-6">
          <div className="flex-1 h-px bg-black"></div>
          <div className="px-4 text-sm md:text-base uppercase font-medium">
            Don't have an account?
          </div>
          <div className="flex-1 h-px bg-black"></div>
        </div>

        {/* Join Waitlist Button */}
        <div className="mb-6">
          <button
            onClick={handleEarlyAccessNavigate}
            className="w-full py-3 px-6 border-2 border-b-4 rounded text-center bg-transparent text-black font-bold hover:text-white border-black hover:border-white hover:bg-black text-sm md:text-base uppercase shadow-md hover:shadow-lg transition duration-150 transform active:scale-95 active:translate-y-1 active:border-b-2"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {earlyAccessLoading ? (
              <BeatLoader
                color={hovered ? "#FCFCEE" : "#000000"}
                loading={earlyAccessLoading}
                size={8}
              />
            ) : (
              "Join Waitlist"
            )}
          </button>
        </div>

        {/* Terms */}
        <div className="text-center text-sm">
          <p className="font-body">
            By signing into Fast Fluency Africa you agree to our{" "}
            <Link to="/terms" className="font-semibold underline">
              Terms & Conditions
            </Link>{" "}
            as well as our{" "}
            <Link to="/privacy" className="font-semibold underline">
              Privacy Policy.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
