// components/articles/breadcrumbs.jsx
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
  return (
    <nav className="flex py-3 px-5 text-sm" aria-label="Breadcrumb">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="mx-2 text-gray-400">/</span>}
          {item.href ? (
            <Link
              to={item.href}
              className="text-red hover:text-red-dark transition-colors"
            >
              {item.label}
            </Link>
          ) : (
            <span className="text-black">{item.label}</span>
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
