import React from "react";
import { Link } from "react-router-dom";
import africanPattern from "../../assets/imgs/patterns-2.png"; // Replace with the actual image path

const BetaCard = () => {
  const cardStyle = {
    backgroundImage: `url(${africanPattern})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div
      data-testid="beta-card"
      className="mt-4 w-full m-auto p-4 sm:p-6 md:p-8 rounded text-right border-2 border-b-4 border-darkgray shadow-2xl"
      style={cardStyle}
    >
      <div className="text-outline-black text-center text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
        <span className="rotate-180">🌍</span>📚
      </div>
      <div className="pt-1 pr-2">
        <h1 className="font-headers text-base sm:text-md md:text-lg lg:text-xl xl:text-2xl text-black text-center">
          <span className="font-semibold uppercase">
            Fast Fluency Africa Beta Version
          </span>
        </h1>
        <h3 className="font-regular text-xs sm:text-sm md:text-md lg:text-lg text-black text-center font-italic font-light">
          Welcome to our early access program! As we fine tune and make
          improvements to the overall user experience and the application we
          also want to give you an opportunity to get early access to new
          features so that you can give us feedback and help us cater directly
          to your needs.
        </h3>
      </div>
    </div>
  );
};

export default BetaCard;
