import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils";
import { useAuth } from "../../../helpers/UserAuthContextProvider";
import LoadingSpinner from "../common/loading-spinner";
import { LegacyDialogue } from "./texts-generic.component";
import { useNavigate } from "react-router-dom";

const DIALOGUES_PER_PAGE = 20;

const LegacyDialogueContainer = () => {
  const { user } = useAuth();
  const [dialogues, setDialogues] = useState([]);
  const [selectedDialogueId, setSelectedDialogueId] = useState(null);
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dialogueLoading, setDialogueLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDialogues, setFilteredDialogues] = useState([]);
  const navigate = useNavigate();

  // Fetch initial dialogues
  useEffect(() => {
    fetchDialogues();
  }, []);

  // Filter dialogues when search term changes
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredDialogues(dialogues);
    } else {
      const filtered = dialogues.filter((dialogue) => {
        // Search in both title and dialogue number
        const titleMatch = dialogue.title
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const numberMatch = dialogue.no?.toString() === searchTerm.trim();
        return titleMatch || numberMatch;
      });
      setFilteredDialogues(filtered);
    }
  }, [searchTerm, dialogues]);

  // Load the selected dialogue's full data when ID changes
  useEffect(() => {
    if (selectedDialogueId) {
      fetchDialogueDetails(selectedDialogueId);
    } else {
      setSelectedDialogue(null);
    }
  }, [selectedDialogueId]);

  const fetchDialogues = async (loadMore = false) => {
    try {
      setLoading(true);

      let dialoguesQuery;

      if (loadMore && lastVisible) {
        dialoguesQuery = query(
          collection(db, "dialogues"),
          orderBy("no"), // Order by dialogue number instead of title
          startAfter(lastVisible),
          limit(DIALOGUES_PER_PAGE)
        );
      } else {
        dialoguesQuery = query(
          collection(db, "dialogues"),
          orderBy("no"), // Order by dialogue number instead of title
          limit(DIALOGUES_PER_PAGE)
        );
      }

      const snapshot = await getDocs(dialoguesQuery);

      if (snapshot.empty) {
        setHasMore(false);
        setLoading(false);
        return;
      }

      // Get the last visible document
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      setLastVisible(lastDoc);

      const dialogueData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (loadMore) {
        setDialogues((prev) => [...prev, ...dialogueData]);
      } else {
        setDialogues(dialogueData);
        // Set the first dialogue as selected by default if there's no selection
        if (dialogueData.length > 0 && !selectedDialogueId) {
          setSelectedDialogueId(dialogueData[0].id);
        }
      }
    } catch (error) {
      console.error("Error fetching dialogues:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDialogueDetails = async (dialogueId) => {
    try {
      setDialogueLoading(true);

      const dialogueRef = doc(db, "dialogues", dialogueId);
      const dialogueDoc = await getDoc(dialogueRef);

      if (dialogueDoc.exists()) {
        setSelectedDialogue({
          id: dialogueDoc.id,
          ...dialogueDoc.data(),
        });
      } else {
        console.error("Dialogue not found");
        setSelectedDialogue(null);
      }
    } catch (error) {
      console.error("Error fetching dialogue details:", error);
      setSelectedDialogue(null);
    } finally {
      setDialogueLoading(false);
    }
  };

  const loadMoreDialogues = () => {
    if (hasMore && !loading) {
      fetchDialogues(true);
    }
  };

  const handleDialogueChange = (e) => {
    setSelectedDialogueId(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 border-2 border-b-4 border-black rounded p-4 bg-white shadow-2xl">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handleNavigateBack}
            className="text-gold hover:text-red p-2 flex items-center"
          >
            <span className="mr-1">←</span> Back
          </button>
          <h1 className="text-3xl font-bold font-headers text-center flex-grow">
            LEGACY DIALOGUES
          </h1>
          <div className="w-16"></div> {/* Spacer for balance */}
        </div>

        {/* Search and Filter */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search dialogues by title..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 border-2 border-b-4 border-darkgray rounded"
          />
        </div>

        {/* Dropdown Selection */}
        <div className="mb-4">
          <label className="block mb-2 font-bold">Select Dialogue:</label>
          <select
            value={selectedDialogueId || ""}
            onChange={handleDialogueChange}
            className="w-full p-2 border-2 border-b-4 border-darkgray rounded"
            disabled={loading}
          >
            <option value="" disabled>
              Select a dialogue
            </option>
            {filteredDialogues.map((dialogue) => (
              <option key={dialogue.id} value={dialogue.id}>
                {dialogue.no ? `${dialogue.no}. ` : ""}
                {dialogue.title || `Dialogue ${dialogue.no}`}
              </option>
            ))}
          </select>
        </div>

        {/* Dialogues count info */}
        <div className="text-center text-sm text-gray mb-3">
          Showing {dialogues.length} of approximately 336 dialogues
          {searchTerm.trim() !== "" &&
            ` (filtered: ${filteredDialogues.length} results)`}
        </div>

        {/* Load More Button */}
        {searchTerm.trim() === "" && (
          <div className="text-center">
            <button
              onClick={loadMoreDialogues}
              disabled={!hasMore || loading}
              className={`px-4 py-2 border-2 border-b-4 rounded ${
                hasMore && !loading
                  ? "border-gold text-gold hover:bg-gold hover:text-white"
                  : "border-darkgray text-darkgray"
              }`}
            >
              {loading
                ? "Loading..."
                : hasMore
                ? "Load More Dialogues"
                : "No More Dialogues"}
            </button>
          </div>
        )}
      </div>

      {/* Selected Dialogue Display */}
      {loading && dialogues.length === 0 ? (
        <div className="text-center py-8">
          <LoadingSpinner />
        </div>
      ) : dialogueLoading ? (
        <div className="text-center py-8">
          <LoadingSpinner />
          <p className="mt-2">Loading dialogue content...</p>
        </div>
      ) : selectedDialogue ? (
        <div className="legacy-dialogue-wrapper border-2 border-b-4 border-black rounded p-4 bg-white shadow-2xl">
          <LegacyDialogue
            dialogue={selectedDialogue}
            onClose={() => navigate(-1)}
            ids={["legacy-close", "legacy-translate", "legacy-next"]}
          />
        </div>
      ) : (
        <div className="text-center py-8 border-2 border-b-4 border-darkgray rounded p-4 bg-white">
          <p>Please select a dialogue from the dropdown above</p>
        </div>
      )}
    </div>
  );
};

export default LegacyDialogueContainer;
