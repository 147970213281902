// Improved progress-overview.jsx
import React, { useState, useEffect } from "react";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import UserProgressService from "../../services/analytics/user-progress-service";
import FlashcardService from "../../services/flashcard/firebase-data-adapter";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics"; // Add analytics hook
import BeatLoader from "react-spinners/BeatLoader";

function ProgressOverview() {
  const { user } = useAuth();
  const { userStats } = useSafeAnalytics(); // Get analytics data
  const [loading, setLoading] = useState(true);
  const [overallProgress, setOverallProgress] = useState({
    totalDecks: 0,
    totalCards: 0,
    completedDecks: 0,
    masteredCards: 0,
    progressPercentage: 0,
    recentDecks: [],
  });

  useEffect(() => {
    const fetchProgressStats = async () => {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        // Fetch all decks first
        const { publicDecks, customDecks } =
          await FlashcardService.getFlashcardDecks(user.uid);
        const allDecks = [...publicDecks, ...customDecks];

        // Get deck IDs
        const deckIds = allDecks.map((deck) => deck.id);

        // Get progress for all decks
        const allProgress = await UserProgressService.getAllUserProgress(
          user.uid
        );

        // Calculate overall stats
        let totalCards = 0;
        let masteredCards = 0;
        let completedDecks = 0;

        // Get decks with most recent activity
        const recentDeckIds = Object.entries(allProgress.decks || {})
          .filter(([_, deckProgress]) => deckProgress.lastStudied)
          .sort((a, b) => {
            const timeA =
              a[1].lastStudied?.toDate?.() || new Date(a[1].lastStudied);
            const timeB =
              b[1].lastStudied?.toDate?.() || new Date(b[1].lastStudied);
            return timeB - timeA;
          })
          .slice(0, 3)
          .map(([deckId]) => deckId);

        const recentDecks = [];

        // Process each deck
        for (const deck of allDecks) {
          totalCards += deck.cardCount;

          // Calculate progress for this deck
          const progress = await UserProgressService.calculateDeckProgress(
            user.uid,
            deck.id,
            deck.cardCount
          );

          masteredCards += progress.masteredCards;

          // Check if deck is fully mastered
          if (progress.masteredCards === deck.cardCount && deck.cardCount > 0) {
            completedDecks++;
          }

          // Check if this is a recent deck
          if (recentDeckIds.includes(deck.id)) {
            recentDecks.push({
              id: deck.id,
              name: deck.name,
              progress: progress.completionPercentage,
              lastStudied: allProgress.decks[deck.id].lastStudied,
            });
          }
        }

        // Calculate overall progress percentage
        const progressPercentage =
          totalCards > 0 ? Math.round((masteredCards / totalCards) * 100) : 0;

        setOverallProgress({
          totalDecks: allDecks.length,
          totalCards,
          completedDecks,
          masteredCards,
          progressPercentage,
          recentDecks,
        });
      } catch (error) {
        console.error("Error fetching progress stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProgressStats();
  }, [user?.uid]);

  if (!user) {
    return null;
  }

  if (loading) {
    return (
      <div className="bg-white w-full p-4 rounded-lg shadow-md flex justify-center items-center h-32">
        <BeatLoader color={"#848717"} loading={true} size={8} />
      </div>
    );
  }

  return (
    <div className="bg-white w-full p-4 rounded-lg shadow-md">
      <h2 className="text-base sm:text-lg font-bold mb-3">
        Your Learning Progress
      </h2>

      {/* Summary Numbers */}
      <div className="grid grid-cols-2 gap-2 sm:gap-3 mb-4">
        <div className="bg-gray-50 p-2 sm:p-3 rounded text-center">
          <div className="text-lg sm:text-xl font-bold text-green">
            {overallProgress.masteredCards}
          </div>
          <div className="text-xs text-gray-600">Cards Mastered</div>
        </div>
        <div className="bg-gray-50 p-2 sm:p-3 rounded text-center">
          <div className="text-lg sm:text-xl font-bold text-green">
            {overallProgress.progressPercentage}%
          </div>
          <div className="text-xs text-gray-600">Overall Progress</div>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="mb-4">
        <div className="flex justify-between text-xs mb-1">
          <span>Overall Progress</span>
          <span>
            {overallProgress.masteredCards}/{overallProgress.totalCards} cards
          </span>
        </div>
        <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-green"
            style={{ width: `${overallProgress.progressPercentage}%` }}
          ></div>
        </div>
      </div>

      {/* Recent Decks */}
      {overallProgress.recentDecks.length > 0 && (
        <>
          <h3 className="text-sm font-bold mb-2">Recently Studied</h3>
          <div className="space-y-2">
            {overallProgress.recentDecks.map((deck) => (
              <div key={deck.id} className="bg-gray-50 p-2 rounded text-sm">
                <div className="flex justify-between mb-1">
                  <span className="font-medium text-xs sm:text-sm truncate max-w-[70%]">
                    {deck.name}
                  </span>
                  <span className="text-xs bg-green text-white px-1.5 py-0.5 rounded-full">
                    {deck.progress}%
                  </span>
                </div>
                <div className="w-full h-1 bg-gray-200 rounded-full">
                  <div
                    className="h-full bg-green rounded-full"
                    style={{ width: `${deck.progress}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {/* Enhanced Analytics Stats */}
      {userStats && (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <h3 className="text-sm font-bold mb-2">Learning Analytics</h3>
          <div className="grid grid-cols-2 gap-2">
            <div className="bg-gray-50 p-2 rounded">
              <p className="text-xs text-gray-600">Overall XP</p>
              <p className="font-bold">{userStats.total_xp || 0}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded">
              <p className="text-xs text-gray-600">Current Streak</p>
              <p className="font-bold">{userStats.current_streak || 0} days</p>
            </div>
            <div className="bg-gray-50 p-2 rounded">
              <p className="text-xs text-gray-600">Today's XP</p>
              <p className="font-bold">{userStats.today_xp || 0}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded">
              <p className="text-xs text-gray-600">Weekly XP</p>
              <p className="font-bold">{userStats.weekly_xp || 0}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgressOverview;
