import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  increment,
} from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { useForm } from "react-hook-form";
import { default as hero } from "../../assets/imgs/hero-2-min-beta.png";
import { default as img1 } from "../../assets/imgs/early-access/1.jpg";
import { default as img2 } from "../../assets/imgs/early-access/2.jpg";
import { default as img3 } from "../../assets/imgs/early-access/3.jpg";
import { default as img4 } from "../../assets/imgs/early-access/4.jpg";
import { default as img5 } from "../../assets/imgs/early-access/5.jpg";
import { default as img6 } from "../../assets/imgs/early-access/6.jpg";
import { default as img7 } from "../../assets/imgs/early-access/7.jpg";
import { default as img8 } from "../../assets/imgs/early-access/8.jpg";
import { default as img9 } from "../../assets/imgs/early-access/9.jpg";
import { default as img10 } from "../../assets/imgs/early-access/10.jpg";
import { default as img11 } from "../../assets/imgs/early-access/11.jpg";
import { default as img12 } from "../../assets/imgs/early-access/12.jpg";
import { default as img13 } from "../../assets/imgs/early-access/13.jpg";
import { default as img14 } from "../../assets/imgs/early-access/14.jpg";
import { default as img15 } from "../../assets/imgs/early-access/15.jpg";
import { default as img16 } from "../../assets/imgs/early-access/16.jpg";
import { default as img17 } from "../../assets/imgs/early-access/17.jpg";
import { default as img18 } from "../../assets/imgs/early-access/18.jpg";
import { default as img19 } from "../../assets/imgs/early-access/19.jpg";
import { default as img20 } from "../../assets/imgs/early-access/20.gif";
import { default as img21 } from "../../assets/imgs/early-access/21.gif";
import { default as img22 } from "../../assets/imgs/early-access/22.gif";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import { DYKData } from "../../zombiedata/didyouknow";
import { BeatLoader } from "react-spinners";
import FeedbackMenu from "../../components/support/feedback-menu";
import Footer from "../../components/footer/footer.component";
import { useInView } from "react-intersection-observer";

// Image data for reusable components
const timelineData = [
  {
    year: 2018,
    title: "Foundation",
    description:
      "Fast Fluency Africa was established with a mission to make African language learning accessible, authentic, and enjoyable.",
    imageAlt: "Fast Fluency Africa early coaching sessions moment",
    image: img20,
    borderColor: "gold",
  },
  {
    year: 2019,
    title: "Expansion",
    description:
      "Added multiple language offerings and partnered with educational institutions like Sonop Residence & Luminous Day House.",
    imageAlt: "Luminous Day House Information Session",
    image: img9,
    borderColor: "red",
  },
  {
    year: 2019,
    title: "Digital Transition",
    description:
      "Successfully pivoted to digital learning during global challenges, continuing to provide quality language education.",
    imageAlt: "Digital learning session in progress",
    image: img19,
    borderColor: "green",
  },
  {
    year: 2020,
    title: "Growing Community",
    description:
      "Reached significant milestones in user growth and expanded our network of language coaches.",
    imageAlt: "SA Sign Language coaching session with students",
    image: img21,
    borderColor: "gold",
  },
  {
    year: 2022,
    title: "Platform Development",
    description:
      "Began development of our comprehensive language learning platform with enhanced features and accessibility.",
    imageAlt: "Platform development team meeting",
    image: img22,
    borderColor: "red",
  },
];

const galleryData = [
  {
    image: img2,
    alt: "isiZulu Catch Up Session",
    caption: "isiZulu Catch Up Session",
    borderColor: "red",
    size: "h-80",
    span: "",
  },
  {
    image: img3,
    alt: "SASL Language Coaching Session",
    caption: "SASL Language Coaching Session",
    borderColor: "gold",
    size: "h-80",
    span: "",
  },
  {
    image: img4,
    alt: "SASL Language Coaching Session",
    caption: "SASL Language Coaching Session",
    borderColor: "green",
    size: "h-80",
    span: "",
  },
  {
    image: img5,
    alt: "TuksVillage Language Workshop",
    caption: "TuksVillage Language Workshop",
    borderColor: "red",
    size: "h-80",
    span: "",
  },
  {
    image: img13,
    alt: "isiXhosa Language Coaching Session",
    caption: "isiXhosa Language Coaching Session",
    borderColor: "gold",
    size: "h-80",
    span: "",
  },
  {
    image: img14,
    alt: "isiZulu Language Coaching Session",
    caption: "isiZulu Language Coaching Session",
    borderColor: "green",
    size: "h-80",
    span: "",
  },
  {
    image: img12,
    alt: "Sesotho Language Coaching Session",
    caption: "Sesotho Language Coaching Session",
    borderColor: "red",
    size: "h-80",
    span: "",
  },
  {
    image: img16,
    alt: "isiZulu Language Coaching Session",
    caption: "isiZulu Language Coaching Session",
    borderColor: "gold",
    size: "h-80",
    span: "",
  },
  {
    image: img1,
    alt: "Year End Function 2018",
    caption: "Year End Function 2018",
    borderColor: "green",
    size: "h-80",
    span: "",
  },
  {
    image: img6,
    alt: "isiZulu Language Coaching Session",
    caption: "isiZulu Language Coaching Session",
    borderColor: "red",
    size: "h-80",
    span: "",
  },
  {
    image: img17,
    alt: "isiZulu Language Coaching Session",
    caption: "isiZulu Language Coaching Session",
    borderColor: "gold",
    size: "h-80",
    span: "",
  },
  {
    image: img18,
    alt: "isiZulu Language Coaching Session",
    caption: "isiZulu Language Coaching Session",
    borderColor: "green",
    size: "h-80",
    span: "",
  },
];

const featureData = [
  {
    title: "Audio Enabled Translations",
    description:
      "Use our authentic pronunciations to improve your speaking and listening skills with our audio-enabled translations feature.",
    emoji: "🔊",
    borderColor: "red",
    bgColor: "red/10",
    textColor: "red",
  },
  {
    title: "Learning Analytics",
    description:
      "Track your progress with detailed reports and statistics to help you stay motivated and focused on your language learning journey.",
    emoji: "📊",
    borderColor: "gold",
    bgColor: "gold/10",
    textColor: "gold",
  },
  {
    title: "300+ Learning Materials",
    description:
      "Access over 300 units, lessons, exercises, and interactive texts with additional curated flashcards covering 50+ topics.",
    emoji: "🗂️",
    borderColor: "green",
    bgColor: "green/10",
    textColor: "green",
  },
  {
    title: "Practice Sessions",
    description:
      "Reinforce your learning with interactive practice sessions designed to improve retention and fluency.",
    emoji: "🏋🏾‍♀️",
    borderColor: "red",
    bgColor: "red/10",
    textColor: "red",
  },
  {
    title: "Gamification",
    description:
      "Stay motivated with streaks, XP, trophies, in-app shop purchases to power up your learning, and achievements to mark language milestones.",
    emoji: "❤️",
    borderColor: "gold",
    bgColor: "gold/10",
    textColor: "gold",
  },
  {
    title: "24/7 Customer Support",
    description:
      "Get help whenever you need it with our round-the-clock customer support team dedicated to your success.",
    emoji: "👷‍♀️",
    borderColor: "green",
    bgColor: "green/10",
    textColor: "green",
  },
];

// Timeline Event Component - Redesigned for better mobile responsiveness
const TimelineEvent = ({ item, index, itemVariants }) => {
  const isEven = index % 2 === 0;

  return (
    <motion.div className="relative" variants={itemVariants}>
      <div className="flex flex-col md:flex-row md:items-center">
        {/* Image container */}
        <div
          className={`w-full md:w-1/2 md:px-6 mb-6 md:mb-0 order-1 ${
            isEven ? "md:order-1" : "md:order-2"
          }`}
        >
          <div
            className={`relative rounded border-2 border-b-4 border-${item.borderColor} overflow-hidden shadow-md w-full aspect-video`}
          >
            <img
              src={item.image}
              alt={item.imageAlt}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-3">
              <p className="text-white text-sm font-medium">{item.imageAlt}</p>
            </div>
            {/* Date timestamp */}
            <div className="absolute top-4 right-4 bg-red text-white border border-b-2 border-r-2 rotate-45 rounded-sm w-12 h-12 flex items-center justify-center shadow-lg z-10">
              <span className="text-base md:text-lg font-bold -rotate-45">
                {item.year}
              </span>
            </div>
          </div>
        </div>

        {/* Text container */}
        <div
          className={`w-full md:w-1/2 md:px-6 order-2 ${
            isEven ? "md:order-2" : "md:order-1"
          }`}
        >
          <div
            className={`bg-white rounded border-2 border-b-4 border-${item.borderColor} p-6 shadow-md hover:shadow-lg transition-shadow`}
          >
            <h3 className="text-xl font-bold text-red mb-2">{item.title}</h3>
            <p className="text-gray-700">{item.description}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

// Gallery Item Component
const GalleryItem = ({ item, itemVariants }) => {
  return (
    <motion.div
      className={`relative rounded border-2 border-b-4 border-${item.borderColor} overflow-hidden shadow-lg group h-64 sm:h-72 md:h-80`}
      variants={itemVariants}
      whileHover={{ y: -5 }}
    >
      <img
        src={item.image}
        alt={item.alt}
        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
        <p className="text-white p-4 text-sm">{item.caption}</p>
      </div>
    </motion.div>
  );
};

// Feature Item Component
const FeatureItem = ({ feature, itemVariants }) => {
  return (
    <motion.div
      className={`bg-white rounded border-2 border-b-4 p-6 shadow-lg hover:shadow-xl transition-shadow border-${feature.borderColor}`}
      variants={itemVariants}
    >
      <div
        className={`h-14 w-14 bg-${feature.bgColor} rounded-sm border-2 border-${feature.textColor} rotate-45 flex items-center justify-center mb-4`}
      >
        <span
          className={`w-5 h-7 text-xl -rotate-45 text-${feature.textColor}`}
        >
          {feature.emoji}
        </span>
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-2">{feature.title}</h3>
      <p className="text-gray-600">{feature.description}</p>
    </motion.div>
  );
};

// Testimonial Component
const Testimonial = ({ quote, name, role, index, itemVariants }) => {
  return (
    <motion.div
      className="bg-white/10 backdrop-blur-sm rounded-sm border border-b-4 border-white p-6 relative mb-6 md:mb-0"
      variants={itemVariants}
    >
      <div className="absolute -top-6 left-6 w-12 h-12 bg-gold border border-b-2 border-r-2 border-white rounded-sm rotate-45 flex items-center justify-center">
        <span className="text-2xl -rotate-45">"</span>
      </div>
      <div className="pt-6">
        <p className="mb-6 italic">{quote}</p>
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-sm rotate-45 bg-gold/50 mr-3"></div>
          <div>
            <h4 className="font-bold">{name}</h4>
            <p className="text-sm opacity-75">{role}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const EarlyAccessPage = () => {
  const [waitlistCount, setWaitlistCount] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const randomIndex = Math.floor(Math.random() * DYKData.length);
  const randomObject = DYKData[randomIndex];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const languageOptions = [
    "isiZulu",
    "isiXhosa",
    "Sepedi",
    "Kiswahili",
    "Sesotho",
    "Chichewa",
    "Ovambo",
    "Shona",
    "Bemba",
  ];

  // No longer need intersection observers since we're using whileInView directly

  useEffect(() => {
    document.title = "Early Access Waiting List | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Set loading to true when component mounts
    setLoading(true);

    // Hide loading screen after a delay
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 second delay - adjust as needed

    // Clean up
    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    const fetchWaitlistCount = async () => {
      try {
        const statsDoc = await getDoc(doc(db, "waitlist_stats", "counter"));
        if (statsDoc.exists()) {
          setWaitlistCount(statsDoc.data().count);
        } else {
          // Fallback to a default number
          setWaitlistCount(Math.floor(Math.random() * 60) + 120);
        }
      } catch (error) {
        console.error("Error fetching waitlist count:", error);
        setWaitlistCount(Math.floor(Math.random() * 60) + 120);
      }
    };

    fetchWaitlistCount();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      console.log("Is loading-1");
      // Add the user to waitlist
      await addDoc(collection(db, "early_access_users"), {
        name: data.name,
        email: data.email,
        phone: data.phone,
        location: data.location,
        targetLanguage: data.targetLanguage,
        timestamp: new Date(),
      });
      console.log("Is loading-2");
      // Get current stats
      const statsRef = doc(db, "waitlist_stats", "counter");
      const statsDoc = await getDoc(statsRef);

      console.log("Is loading-3");
      if (statsDoc.exists()) {
        // Update the counter
        await updateDoc(statsRef, {
          count: increment(1),
          lastUpdated: new Date(),
        });
        setWaitlistCount(statsDoc.data().count + 1);
        console.log("Is loading-4");
      } else {
        // Create the counter if it doesn't exist
        await setDoc(statsRef, {
          count: 1,
          lastUpdated: new Date(),
        });
        setWaitlistCount(1);
        console.log("Is loading-5");
      }

      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error("Error adding user to waitlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  return loading ? (
    <div className="flex flex-col justify-center items-center h-screen bg-white m-auto">
      <span className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center">
        🤲🏾
      </span>
      <h1 className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl font-headers font-semibold text-center uppercase text-green">
        Consulting the Ancestors
      </h1>
      <BeatLoader color={"#848717"} loading={loading} size={10} />
      <div className="flex flex-col justify-center items-center mt-4 mx-4">
        <span className="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center">
          {randomObject.emoji}
        </span>
        <h1 className="text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-semibold text-center">
          {randomObject.type === "dyk" ? `Did you know?` : `African Proverb`}
        </h1>
        <p className="text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-body font-light text-center">
          {randomObject.factoid}
        </p>
      </div>
    </div>
  ) : (
    <div className="bg-[#FCFCEE] min-h-screen">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-red to-gold text-white">
        <div>
          <NavBarPrimary />
          <FeedbackMenu />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-16 md:py-20 lg:py-24 w-full sm:w-5/6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <motion.div
              className="md:w-3/5 lg:w-1/2 mb-10 md:mb-0"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-headers uppercase text-center md:text-left">
                Early Access Program
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl mb-8 mx-auto md:mx-0 max-w-xl">
                Join the waitlist for our upcoming app launch and be among the
                first to experience a breathtaking way to know African
                languages, culture, and stories.
              </p>
              <motion.div
                className="bg-white/20 backdrop-blur-sm rounded border-white border-2 border-b-4 p-4 mb-8 w-full sm:w-4/5 md:w-auto"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 10,
                  delay: 0.4,
                }}
              >
                <span className="block text-sm uppercase tracking-wide">
                  People waiting for access
                </span>
                <span className="text-4xl font-bold">{waitlistCount}+</span>
              </motion.div>
              <motion.a
                href="#join-waitlist"
                className="inline-block border-green hover:bg-green/10 text-green font-bold py-3 px-8 border-2 border-b-4 rounded shadow-lg transition-all transform hover:scale-105 hover:shadow-xl"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Join the Waitlist
              </motion.a>
            </motion.div>

            <motion.div
              className="hidden md:block md:w-2/5 lg:w-1/2"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.7 }}
            >
              <img
                src={hero}
                alt="Fast Fluency Africa App Preview"
                className="rounded max-w-full h-auto"
              />
            </motion.div>
          </div>
        </div>

        {/* Wave divider */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full h-auto"
          >
            <path
              fill="#FCFCEE"
              fillOpacity="1"
              d="M0,32L48,42.7C96,53,192,75,288,74.7C384,75,480,53,576,48C672,43,768,53,864,58.7C960,64,1056,64,1152,56C1248,48,1344,32,1392,24L1440,16L1440,100L1392,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
            />
          </svg>
        </div>
      </div>

      {/* Video Section */}
      <section className="py-16 w-full sm:w-5/6 mx-auto">
        <motion.div
          className="max-w-6xl mx-auto px-4"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.7 }}
        >
          <div className="text-center mb-12">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
              See What's Coming Next
            </h2>
            <p className="text-base sm:text-lg text-gray-700 max-w-3xl mx-auto">
              Watch this preview of all the exciting features waiting for you in
              our upcoming app release
            </p>
          </div>
          <div className="w-full max-w-[600px]  mx-auto px-4 max-w-md lg:max-h-screen lg:flex lg:items-center lg:justify-center">
            <div className="relative pt-[56.25%] max-h-[70vh] rounded border-2 border-b-4 mx-auto border-darkgray overflow-hidden shadow-xl w-full aspect-[9/16]">
              <iframe
                width="315"
                height="560"
                className="absolute top-0 left-0 w-full h-full border-0"
                src="https://www.youtube.com/embed/KUoP7pcmyio"
                title="Fast Fluency Africa v2.0.0 Preview"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Journey Section with Enhanced Timeline */}
      <section className="py-16 bg-[#FCFCEE] w-full sm:w-5/6 mx-auto">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
              Our Journey Since 2018
            </h2>
            <p className="text-base sm:text-lg text-gray-700 max-w-2xl mx-auto">
              From humble beginnings to creating a revolutionary language
              learning platform
            </p>
          </div>

          <motion.div
            className="space-y-16"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            {timelineData.map((item, index) => (
              <TimelineEvent
                key={index}
                item={item}
                index={index}
                itemVariants={itemVariants}
              />
            ))}
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-gradient-to-b from-[#FCFCEE] to-white w-full sm:w-5/6 mx-auto">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
              Packed with Amazing Features
            </h2>
            <p className="text-base sm:text-lg text-gray-700 max-w-2xl mx-auto">
              Our app is designed to make learning African languages fun,
              effective, and accessible
            </p>
          </div>

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            {featureData.map((feature, index) => (
              <FeatureItem
                key={index}
                feature={feature}
                itemVariants={itemVariants}
              />
            ))}
          </motion.div>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="py-16 bg-white w-full sm:w-5/6 mx-auto">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
              Our Impact Over the Years
            </h2>
            <p className="text-base sm:text-lg text-gray-700 max-w-3xl mx-auto">
              A glimpse of our journey teaching African languages and connecting
              cultures
            </p>
          </div>

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            {galleryData.map((item, index) => (
              <GalleryItem
                key={index}
                item={item}
                itemVariants={itemVariants}
              />
            ))}
          </motion.div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-red text-white w-full mx-auto">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 font-headers">
              What Our Customers Say
            </h2>
            <p className="text-base sm:text-lg max-w-2xl mx-auto opacity-90">
              Hear from people who have already experienced our language
              learning approach
            </p>
          </div>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-8"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            <Testimonial
              quote="After I started learning isiZulu people became more open with me. This has been a very productive and eye-opening cultural experience for me."
              name="Faeeza L."
              role="isiZulu Student (2018-2021)"
              index={0}
              itemVariants={itemVariants}
            />

            <Testimonial
              quote="I really enjoy the language sessions because the casual element makes it nice. FFA is helping me actually learn how to speak the language quickly."
              name="Marne S."
              role="SA Sign Language (2018-2020)"
              index={1}
              itemVariants={itemVariants}
            />

            <Testimonial
              quote="We can confidently say that we are more than happy with the standard of services that Fast Fluency Africa has provided us. We highly recommend their services."
              name="Sonop Residence"
              role="Multiple Languages (2019)"
              index={2}
              itemVariants={itemVariants}
            />
          </motion.div>
        </div>
      </section>

      {/* Waitlist Form Section */}
      <section
        id="join-waitlist"
        className="py-16 bg-[#FCFCEE] w-full sm:w-5/6 mx-auto"
      >
        <motion.div
          className="max-w-4xl mx-auto px-4"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.7 }}
        >
          <div className="bg-white rounded border-b-4 border-2 border-darkgray shadow-xl overflow-hidden">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 bg-green p-6 md:p-8 text-white">
                <h2 className="text-2xl sm:text-3xl font-bold mb-6 font-headers">
                  Join Our Waitlist
                </h2>
                <p className="mb-6">
                  Be among the first to experience Fast Fluency Africa when we
                  launch. Sign up now to secure your early access.
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Early app access</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Exclusive launch support</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Special founding member perks</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Be the first to provide feedback</span>
                  </li>
                </ul>
              </div>

              <div className="w-full md:w-1/2 p-6 md:p-8">
                {isSubmitted ? (
                  <motion.div
                    className="h-full flex flex-col items-center justify-center text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="w-16 h-16 rounded-sm flex items-center justify-center mb-4">
                      <span className="w-8 h-8 text-5xl">🎊</span>
                    </div>
                    <h3 className="text-2xl font-bold text-black mb-2">
                      Congratulations!
                    </h3>
                    <p className="text-black">
                      You've been added to our waitlist! If you would like to
                      improve your chances of being shortlisted for our program{" "}
                      <a
                        href="https://bit.ly/ffascreensurvey"
                        target="_blank"
                        rel="noreferrer"
                        className="text-green font-semibold underline"
                      >
                        please complete this brief survey here
                      </a>
                      <span> </span> to help use assess your suitability for our
                      program.
                    </p>
                  </motion.div>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Full Name
                      </label>
                      <input
                        id="name"
                        type="text"
                        className={`w-full px-4 py-2 border-2 border-b-4 rounded-md outline-none border-darkgray focus:ring-2 focus:ring-gold focus:border-transparent ${
                          errors.name ? "border-red" : "border-gray-300"
                        }`}
                        placeholder="Your name"
                        {...register("name", { required: "Name is required" })}
                      />
                      {errors.name && (
                        <p className="mt-1 text-sm text-red">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Email Address
                      </label>
                      <input
                        id="email"
                        type="email"
                        className={`w-full px-4 py-2 border-2 border-b-4 rounded-md outline-none border-darkgray focus:ring-2 focus:ring-gold focus:border-transparent ${
                          errors.email ? "border-red" : "border-gray-300"
                        }`}
                        placeholder="your.email@example.com"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="mt-1 text-sm text-red">
                          {errors.email.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Phone Number
                      </label>
                      <input
                        id="phone"
                        type="tel"
                        className={`w-full px-4 py-2 border-2 border-b-4 rounded-md outline-none border-darkgray focus:ring-2 focus:ring-gold focus:border-transparent ${
                          errors.phone ? "border-red" : "border-gray-300"
                        }`}
                        placeholder="+27 61 123 4567"
                        {...register("phone", {
                          required: "Phone number is required",
                        })}
                      />
                      {errors.phone && (
                        <p className="mt-1 text-sm text-red">
                          {errors.phone.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Current Location
                      </label>
                      <input
                        id="location"
                        type="text"
                        className={`w-full px-4 py-2 border-2 border-b-4 rounded-md outline-none border-darkgray focus:ring-2 focus:ring-gold focus:border-transparent ${
                          errors.location ? "border-red" : "border-gray-300"
                        }`}
                        placeholder="South Africa"
                        {...register("location", {
                          required: "Location is required",
                        })}
                      />
                      {errors.location && (
                        <p className="mt-1 text-sm text-red">
                          {errors.location.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="targetLanguage"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Target Language
                      </label>
                      <select
                        id="targetLanguage"
                        className={`w-full px-4 py-2 border-2 border-b-4 rounded-md outline-none border-darkgray focus:ring-2 focus:ring-gold focus:border-transparent ${
                          errors.targetLanguage
                            ? "border-red"
                            : "border-gray-300"
                        }`}
                        {...register("targetLanguage", {
                          required: "Please select a language",
                        })}
                      >
                        <option value="">Select a language</option>
                        {languageOptions.map((lang) => (
                          <option key={lang} value={lang}>
                            {lang}
                          </option>
                        ))}
                      </select>
                      {errors.targetLanguage && (
                        <p className="mt-1 text-sm text-red">
                          {errors.targetLanguage.message}
                        </p>
                      )}
                    </div>

                    <motion.button
                      type="submit"
                      disabled={isLoading}
                      className="w-full border-red border-2 border-b-4 hover:bg-red text-red hover:text-white font-bold py-3 px-4 rounded-lg shadow transition-colors"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {isLoading ? (
                        <span className="flex items-center justify-center">
                          <svg
                            className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Processing...
                        </span>
                      ) : (
                        "Join the Waitlist"
                      )}
                    </motion.button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-b from-white to-gold w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default EarlyAccessPage;
