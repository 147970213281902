// flashcard-repair.js
import { db } from "../../firebase/firebase.utils";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  writeBatch,
} from "firebase/firestore";

/**
 * Utility to analyze and repair flashcard database structure
 * Run this from your browser console as a one-time fix
 */
export const analyzeAndRepairFlashcards = async () => {
  try {
    console.log("🔍 Analyzing flashcard collection structure...");

    // Get all flashcards
    const flashcardsRef = collection(db, "flashcards");
    const snapshot = await getDocs(flashcardsRef);

    const flashcards = snapshot.docs.map((doc) => ({
      id: doc.id,
      ref: doc.ref,
      ...doc.data(),
    }));

    console.log(`Found ${flashcards.length} total flashcards`);

    // Analyze structure
    let needsDeckIdRepair = 0;
    let needsOrderRepair = 0;
    let needsHintRepair = 0;

    // Group by deck ID pattern for repair
    const flashcardsByDeckPrefix = {};

    flashcards.forEach((card) => {
      // Check for ID pattern like "vowels1-word"
      const idParts = card.id.split("-");
      if (idParts.length >= 2) {
        const deckPrefix = idParts[0];

        // Group cards by deck prefix
        if (!flashcardsByDeckPrefix[deckPrefix]) {
          flashcardsByDeckPrefix[deckPrefix] = [];
        }
        flashcardsByDeckPrefix[deckPrefix].push(card);

        // Check for missing deckId field
        if (!card.deckId || card.deckId !== deckPrefix) {
          needsDeckIdRepair++;
        }

        // Check for missing order field
        if (card.order === undefined) {
          needsOrderRepair++;
        }

        // Check for missing hint field
        if (!card.hint) {
          needsHintRepair++;
        }
      }
    });

    console.log("Analysis complete:");
    console.log(`- ${needsDeckIdRepair} cards need deckId repair`);
    console.log(`- ${needsOrderRepair} cards need order field repair`);
    console.log(`- ${needsHintRepair} cards are missing hint field`);
    console.log(
      `- Found ${
        Object.keys(flashcardsByDeckPrefix).length
      } potential deck prefixes`
    );

    // Show deck prefixes and counts
    Object.entries(flashcardsByDeckPrefix).forEach(([prefix, cards]) => {
      console.log(`  - ${prefix}: ${cards.length} cards`);
    });

    // Ask for confirmation before repair
    if (needsDeckIdRepair === 0 && needsOrderRepair === 0) {
      console.log("✅ No critical repairs needed!");
      return;
    }

    const shouldRepair = window.confirm(
      `Repair ${needsDeckIdRepair} cards with missing/incorrect deckId and ${needsOrderRepair} cards with missing order field?`
    );

    if (!shouldRepair) {
      console.log("Repair cancelled by user");
      return;
    }

    // Perform repair in batches
    console.log("🔧 Starting repair...");
    let totalRepaired = 0;

    // Process each deck prefix group
    for (const [deckPrefix, cards] of Object.entries(flashcardsByDeckPrefix)) {
      const batchSize = 500; // Firestore batch limit
      let currentBatch = writeBatch(db);
      let operationsInBatch = 0;
      let batchCount = 1;

      console.log(
        `Repairing ${cards.length} cards for deck prefix "${deckPrefix}"`
      );

      // Sort by existing order or alphabetically by ID
      const sortedCards = cards.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        return a.id.localeCompare(b.id);
      });

      // Update each card
      sortedCards.forEach((card, index) => {
        const updates = {};
        let needsUpdate = false;

        // Fix deckId field
        if (!card.deckId || card.deckId !== deckPrefix) {
          updates.deckId = deckPrefix;
          needsUpdate = true;
        }

        // Fix order field
        if (card.order === undefined) {
          updates.order = index;
          needsUpdate = true;
        }

        // Only update if needed
        if (needsUpdate) {
          currentBatch.update(card.ref, updates);
          operationsInBatch++;
          totalRepaired++;

          // Check if we need to commit this batch and start a new one
          if (operationsInBatch >= batchSize) {
            console.log(
              `Committing batch ${batchCount} for prefix "${deckPrefix}"`
            );
            // In an actual implementation, we would await here, but for browser console use we'll skip that
            currentBatch.commit();

            // Start a new batch
            currentBatch = writeBatch(db);
            operationsInBatch = 0;
            batchCount++;
          }
        }
      });

      // Commit final batch if it has operations
      if (operationsInBatch > 0) {
        console.log(
          `Committing final batch ${batchCount} for prefix "${deckPrefix}"`
        );
        currentBatch.commit();
      }
    }

    console.log(`🎉 Repair complete! Fixed ${totalRepaired} flashcards.`);
    console.log("Please refresh the page to see the changes.");
  } catch (error) {
    console.error("Error during analysis/repair:", error);
  }
};

// To run this from the browser console:
// 1. Import this file in your app
// 2. Make the function available on window:
window.repairFlashcards = analyzeAndRepairFlashcards;
// 3. Call from console: repairFlashcards()
