import React, { useState, useEffect } from "react";
import {
  ChevronDown,
  ChevronUp,
  Filter,
  Calendar,
  Download,
  Mail,
  User,
  AlertCircle,
  Lightbulb,
  Book,
  BookOpen,
  CheckCircle,
  Archive,
  Eye,
  EyeOff,
  Bug,
} from "lucide-react";
import {
  collection,
  query,
  getDocs,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";

const AdminFeedbackDashboard = () => {
  useEffect(() => {
    document.title = "Admin Support Dashboard | Fast Fluency Africa";
  }, []);
  // State for feedback data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedbackItems, setFeedbackItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  // State for filters
  const [activeTab, setActiveTab] = useState("all");
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setDate(new Date().getDate() - 30)),
    end: new Date(),
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedItems, setExpandedItems] = useState({});
  const [showResolved, setShowResolved] = useState(false);

  // Colors from provided color scheme
  const colors = {
    red: "#991616",
    gold: "#d19a10",
    green: "#848717",
    white: "#FCFCEE",
    gray: "#808080",
    darkgray: "#DADADA",
  };

  // Handle resolving a feedback item
  const handleResolveItem = async (id) => {
    try {
      setLoading(true);
      const feedbackRef = doc(db, "feedback", id);
      await updateDoc(feedbackRef, {
        resolved: true,
        resolvedAt: new Date(),
      });

      // Update local state to mark item as resolved
      setFeedbackItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? { ...item, resolved: true, resolvedAt: new Date() }
            : item
        )
      );

      // Close the expanded view
      setExpandedItems((prev) => ({
        ...prev,
        [id]: false,
      }));
    } catch (err) {
      console.error("Error resolving feedback item:", err);
      setError("Failed to resolve feedback item.");
    } finally {
      setLoading(false);
    }
  };

  // Handle deleting a feedback item
  const handleDeleteItem = async (id) => {
    if (
      !window.confirm(
        "Are you sure you want to permanently delete this feedback item? This action cannot be undone."
      )
    ) {
      return;
    }

    try {
      setLoading(true);
      const feedbackRef = doc(db, "feedback", id);
      await deleteDoc(feedbackRef);

      // Remove item from local state
      setFeedbackItems((prevItems) =>
        prevItems.filter((item) => item.id !== id)
      );
    } catch (err) {
      console.error("Error deleting feedback item:", err);
      setError("Failed to delete feedback item.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data from Firestore
  const fetchFeedbackData = async () => {
    setLoading(true);
    setError(null);

    try {
      // Create a query against the feedback collection
      const feedbackRef = collection(db, "feedback");
      const q = query(feedbackRef);
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setFeedbackItems([]);
        setFilteredItems([]);
        setLoading(false);
        return;
      }

      // Process the documents
      const items = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const id = doc.id;

        // Convert Firestore timestamp to JS Date if it exists
        let timestamp = new Date();
        if (data.timestamp) {
          if (data.timestamp instanceof Timestamp) {
            timestamp = data.timestamp.toDate();
          } else if (data.timestamp._seconds) {
            timestamp = new Date(data.timestamp._seconds * 1000);
          } else if (typeof data.timestamp === "string") {
            timestamp = new Date(data.timestamp);
          }
        }

        // Convert resolvedAt timestamp if it exists
        let resolvedAt = data.resolvedAt
          ? data.resolvedAt instanceof Timestamp
            ? data.resolvedAt.toDate()
            : data.resolvedAt._seconds
            ? new Date(data.resolvedAt._seconds * 1000)
            : typeof data.resolvedAt === "string"
            ? new Date(data.resolvedAt)
            : null
          : null;

        // Determine feedback type based on document structure - ENHANCED VERSION
        let type = data.type;
        if (!type) {
          // Check for learningMaterialState field
          if (data.learningMaterialState === "dialogue") {
            type = "dialogue";
          } else if (data.learningMaterialState === "dictionary") {
            type = "dictionary";
          }
          // Additional checks for other ways to identify types
          else if (data.learningMaterialId !== undefined) {
            type = "dialogue"; // Assume it's a learning material if it has a learningMaterialId
          } else if (data.entry !== undefined && data.language !== undefined) {
            type = "dictionary"; // Assume it's a dictionary if it has entry and language fields
          } else if (
            data.description !== undefined &&
            data.activity !== undefined
          ) {
            type = "bug"; // Likely a bug report
          } else if (data.description !== undefined && !data.activity) {
            type = "idea"; // Likely an idea
          } else {
            type = "unknown";
          }
        }

        console.log(`Document ${id} identified as type: ${type}`);

        // Create structured feedback item
        const feedbackItem = {
          id,
          type,
          timestamp,
          resolved: data.resolved || false,
          resolvedAt,
          userId: data.userId || "Unknown",
          userEmail: data.userEmail || "Not provided",
          contactAllowed:
            data.contactAllowed !== undefined ? data.contactAllowed : true,

          // Specific fields based on type
          ...(type === "bug" && {
            description: data.description || "",
            activity: data.activity || "",
            options: data.options || [],
          }),

          ...(type === "idea" && {
            description: data.description || "",
          }),

          ...(type === "dialogue" && {
            learningMaterialId: data.learningMaterialId,
            options: data.options || [],
          }),

          ...(type === "dictionary" && {
            entry: data.entry || "",
            language: data.language || "",
            definitions: data.definitions || "",
            englishTranslations: data.englishTranslations || "",
            examplesInASentence: data.examplesInASentence || "",
            synonyms: data.synonyms || "",
            antonyms: data.antonyms || "",
            partOfSpeech: data.partOfSpeech || "",
            pronunciationWritten: data.pronunciationWritten || "",
          }),
        };

        items.push(feedbackItem);
      });

      // Sort by timestamp, newest first
      items.sort((a, b) => b.timestamp - a.timestamp);

      // DEBUG LOGS
      console.log("Total items loaded:", items.length);

      // Create a detailed breakdown of item types
      const typeBreakdown = items.reduce((acc, item) => {
        acc[item.type] = acc[item.type] || [];
        acc[item.type].push({
          id: item.id,
          firstFields: Object.keys(item).slice(0, 5),
        });
        return acc;
      }, {});

      console.log(
        "Type breakdown:",
        Object.keys(typeBreakdown).map((type) => ({
          type,
          count: typeBreakdown[type].length,
          sampleIds: typeBreakdown[type].slice(0, 2).map((i) => i.id),
        }))
      );

      // Log any items that couldn't be classified properly
      if (typeBreakdown.unknown) {
        console.log(
          "Unknown type items:",
          typeBreakdown.unknown.map((i) => i.id)
        );
      }

      // Log specific counts by type
      console.log("Bugs:", items.filter((item) => item.type === "bug").length);
      console.log(
        "Ideas:",
        items.filter((item) => item.type === "idea").length
      );
      console.log(
        "Learning Material:",
        items.filter((item) => item.type === "dialogue").length
      );
      console.log(
        "Dictionary:",
        items.filter((item) => item.type === "dictionary").length
      );

      setFeedbackItems(items);
    } catch (err) {
      console.error("Error fetching feedback data:", err);
      setError("Failed to fetch feedback data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchFeedbackData();
  }, []);

  // Filter feedback items when filters change - UPDATED VERSION
  useEffect(() => {
    let filtered = [...feedbackItems];
    console.log("Starting filtering with", feedbackItems.length, "items");
    console.log("Active tab:", activeTab);

    // Only show unresolved items if showResolved is false
    if (!showResolved) {
      filtered = filtered.filter((item) => !item.resolved);
      console.log("After resolved filter:", filtered.length, "items");
    }

    // Filter by tab/type
    if (activeTab !== "all") {
      filtered = filtered.filter((item) => {
        const result = item.type === activeTab;
        return result;
      });
      console.log(
        "After tab filter:",
        filtered.length,
        "items with type",
        activeTab
      );

      // Log the types that are in the filtered array to debug
      const typesInFiltered = filtered.map((item) => item.type);
      console.log("Types in filtered array:", [...new Set(typesInFiltered)]);
    }

    // Filter by date range
    filtered = filtered.filter((item) => {
      const itemDate = new Date(item.timestamp);
      return itemDate >= dateRange.start && itemDate <= dateRange.end;
    });
    console.log("After date filter:", filtered.length, "items");

    // Filter by search term
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter((item) => {
        // Search in description, email, or any relevant field
        return (
          (item.description && item.description.toLowerCase().includes(term)) ||
          (item.userEmail && item.userEmail.toLowerCase().includes(term)) ||
          (item.entry && item.entry.toLowerCase().includes(term)) ||
          (item.options &&
            item.options.some(
              (opt) =>
                typeof opt === "string" && opt.toLowerCase().includes(term)
            ))
        );
      });
      console.log("After search filter:", filtered.length, "items");
    }

    // Sort by date (newest first)
    filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    setFilteredItems(filtered);
  }, [activeTab, dateRange, searchTerm, feedbackItems, showResolved]);

  // Toggle item expansion
  const toggleItemExpansion = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Format date for display
  const formatDate = (date) => {
    if (!date) return "N/A";
    return new Date(date).toLocaleString();
  };

  // Get summary counts
  const getSummary = () => {
    // Only count unresolved items for the summary unless we're showing resolved items
    const itemsToCount = showResolved
      ? feedbackItems
      : feedbackItems.filter((item) => !item.resolved);

    return {
      total: itemsToCount.length,
      bug: itemsToCount.filter((item) => item.type === "bug").length,
      idea: itemsToCount.filter((item) => item.type === "idea").length,
      dialogue: itemsToCount.filter((item) => item.type === "dialogue").length,
      dictionary: itemsToCount.filter((item) => item.type === "dictionary")
        .length,
    };
  };

  // Get icon and color for feedback type
  const getFeedbackTypeDetails = (type) => {
    switch (type) {
      case "bug":
        return {
          icon: <Bug size={18} />,
          color: colors.red,
          label: "Bug",
        };
      case "idea":
        return {
          icon: <Lightbulb size={18} />,
          color: colors.gold,
          label: "Idea",
        };
      case "dialogue":
        return {
          icon: <BookOpen size={18} />,
          color: colors.green,
          label: "Learning Material",
        };
      case "dictionary":
        return {
          icon: <Book size={18} />,
          color: colors.gray,
          label: "Dictionary",
        };
      default:
        return {
          icon: <AlertCircle size={18} />,
          color: colors.darkgray,
          label: "Unknown",
        };
    }
  };

  // Render content specific to feedback type
  const renderTypeSpecificContent = (item) => {
    switch (item.type) {
      case "bug":
        return (
          <div className="mt-2">
            <p>
              <strong>Description:</strong> {item.description}
            </p>
            {item.activity && (
              <p>
                <strong>Activity:</strong> {item.activity}
              </p>
            )}
            {item.options && item.options.length > 0 && (
              <div>
                <strong>Selected option:</strong>
                <ul className="mt-1 list-disc pl-5">
                  {item.options.map((option, idx) => (
                    <li key={idx}>{option}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );

      case "idea":
        return (
          <div className="mt-2">
            <p>
              <strong>Description:</strong> {item.description}
            </p>
          </div>
        );

      case "dialogue":
        return (
          <div className="mt-2">
            <p>
              <strong>Learning Material ID:</strong> {item.learningMaterialId}
            </p>
            {item.options && item.options.length > 0 && (
              <div>
                <strong>Selected option:</strong>
                <ul className="mt-1 list-disc pl-5">
                  {item.options.map((option, idx) => (
                    <li key={idx}>{option}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );

      case "dictionary":
        return (
          <div className="mt-2">
            <p>
              <strong>Entry:</strong> {item.entry}
            </p>
            <p>
              <strong>Language:</strong> {item.language}
            </p>
            {item.definitions && (
              <p>
                <strong>Definitions:</strong> {item.definitions}
              </p>
            )}
            {item.englishTranslations && (
              <p>
                <strong>English Translations:</strong>{" "}
                {item.englishTranslations}
              </p>
            )}
            {item.examplesInASentence && (
              <p>
                <strong>Examples:</strong> {item.examplesInASentence}
              </p>
            )}
            {item.synonyms && (
              <p>
                <strong>Synonyms:</strong> {item.synonyms}
              </p>
            )}
            {item.antonyms && (
              <p>
                <strong>Antonyms:</strong> {item.antonyms}
              </p>
            )}
            {item.partOfSpeech && (
              <p>
                <strong>Part of Speech:</strong> {item.partOfSpeech}
              </p>
            )}
            {item.pronunciationWritten && (
              <p>
                <strong>Pronunciation:</strong> {item.pronunciationWritten}
              </p>
            )}
          </div>
        );

      default:
        return (
          <div className="mt-2">
            <pre className="text-xs overflow-auto">
              {JSON.stringify(item, null, 2)}
            </pre>
          </div>
        );
    }
  };

  const summary = getSummary();

  if (error) {
    return (
      <div className="flex justify-center items-center h-64 bg-red-50 text-red-600 p-4 rounded">
        <AlertCircle size={20} className="mr-2" />
        {error}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        Loading feedback data...
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4 bg-white rounded shadow h-screen">
      <h1 className="text-2xl font-bold mb-6" style={{ color: colors.red }}>
        Feedback Dashboard
      </h1>

      {/* Summary */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
        <div
          className="bg-white rounded shadow p-4 border-l-4"
          style={{ borderColor: colors.red }}
        >
          <div className="text-sm font-medium text-gray-500">Total</div>
          <div className="text-2xl font-bold">{summary.total}</div>
        </div>

        <div
          className="bg-white rounded shadow p-4 border-l-4"
          style={{ borderColor: colors.red }}
        >
          <div className="text-sm font-medium text-gray-500">Bugs</div>
          <div className="text-2xl font-bold">{summary.bug}</div>
        </div>

        <div
          className="bg-white rounded shadow p-4 border-l-4"
          style={{ borderColor: colors.gold }}
        >
          <div className="text-sm font-medium text-gray-500">Ideas</div>
          <div className="text-2xl font-bold">{summary.idea}</div>
        </div>

        <div
          className="bg-white rounded shadow p-4 border-l-4"
          style={{ borderColor: colors.green }}
        >
          <div className="text-sm font-medium text-gray-500">
            Learning Material
          </div>
          <div className="text-2xl font-bold">{summary.dialogue}</div>
        </div>

        <div
          className="bg-white rounded shadow p-4 border-l-4"
          style={{ borderColor: colors.gray }}
        >
          <div className="text-sm font-medium text-gray-500">Dictionary</div>
          <div className="text-2xl font-bold">{summary.dictionary}</div>
        </div>
      </div>

      {/* Filters */}
      <div className="bg-white rounded shadow p-4 mb-6 w-5/6 mx-auto">
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
          <div className="flex items-center mb-4 md:mb-0">
            <Filter size={16} className="mr-2" />
            <h2 className="font-semibold">Filters</h2>
          </div>

          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="px-3 py-2 border rounded w-full md:w-64"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="flex items-center border rounded px-3 py-2">
              <Calendar size={16} className="mr-2" />
              <span className="text-sm text-gray-600">Last 30 days</span>
            </div>

            <button
              className="flex items-center bg-white border rounded px-3 py-2"
              onClick={() => setShowResolved(!showResolved)}
            >
              {showResolved ? (
                <>
                  <EyeOff size={16} className="mr-2" />
                  <span>Hide Resolved</span>
                </>
              ) : (
                <>
                  <Eye size={16} className="mr-2" />
                  <span>Show Resolved</span>
                </>
              )}
            </button>

            <button
              className="flex items-center bg-white border rounded px-3 py-2"
              onClick={() => fetchFeedbackData()}
              disabled={loading}
            >
              <div className={`mr-2 ${loading ? "animate-spin" : ""}`}>
                {loading ? (
                  <svg className="w-4 h-4" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                  </svg>
                )}
              </div>
              <span>Refresh</span>
            </button>

            <button className="flex items-center bg-white border rounded px-3 py-2">
              <Download size={16} className="mr-2" />
              <span>Export</span>
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex border-b overflow-x-auto mx-auto">
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === "all"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("all")}
          >
            All
          </button>
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === "bug"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("bug")}
          >
            Bugs
          </button>
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === "idea"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("idea")}
          >
            Ideas
          </button>
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === "dialogue"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("dialogue")}
          >
            Learning Material
          </button>
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === "dictionary"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("dictionary")}
          >
            Dictionary
          </button>
        </div>
      </div>

      {/* Feedback Items */}
      <div className="space-y-4 w-5/6 mx-auto">
        {filteredItems.length === 0 ? (
          <div className="bg-white rounded shadow p-8 text-center text-gray-500">
            No feedback items found matching your filters.
          </div>
        ) : (
          filteredItems.map((item) => {
            const { icon, color, label } = getFeedbackTypeDetails(item.type);
            const isExpanded = expandedItems[item.id];

            return (
              <div
                key={item.id}
                className={`bg-white rounded shadow overflow-hidden ${
                  item.resolved ? "opacity-70" : ""
                }`}
                style={{ borderLeft: `4px solid ${color}` }}
              >
                <div
                  className="p-4 cursor-pointer flex justify-between items-center"
                  onClick={() => toggleItemExpansion(item.id)}
                >
                  <div className="flex items-center">
                    <div className="flex items-center mr-4">
                      <span className="mr-2" style={{ color }}>
                        {icon}
                      </span>
                      <span className="font-medium">{label}</span>
                      {item.resolved && (
                        <span className="ml-2 bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                          Resolved
                        </span>
                      )}
                    </div>

                    <div className="hidden md:block ml-4 text-gray-500 text-sm">
                      {item.type === "bug" || item.type === "idea" ? (
                        <span className="truncate max-w-md inline-block">
                          {item.description}
                        </span>
                      ) : item.type === "dialogue" ? (
                        <span>
                          Learning Material ID: {item.learningMaterialId}
                        </span>
                      ) : (
                        <span>Entry: {item.entry}</span>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="text-gray-500 text-sm mr-4">
                      {formatDate(item.timestamp)}
                    </div>
                    {isExpanded ? (
                      <ChevronUp size={18} />
                    ) : (
                      <ChevronDown size={18} />
                    )}
                  </div>
                </div>

                {isExpanded && (
                  <div className="p-4 border-t">
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center text-sm text-gray-500">
                        <div className="flex items-center mr-4">
                          <User size={16} className="mr-1" />
                          <span>{item.userId}</span>
                        </div>
                        {item.userEmail && (
                          <div className="flex items-center">
                            <Mail size={16} className="mr-1" />
                            <span>{item.userEmail}</span>
                          </div>
                        )}
                        <div className="ml-4">
                          <span>
                            Contact allowed:{" "}
                            {item.contactAllowed ? "Yes" : "No"}
                          </span>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex gap-2">
                        {!item.resolved ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleResolveItem(item.id);
                            }}
                            className="flex items-center px-3 py-1 bg-green-100 text-green-700 rounded hover:bg-green-200"
                          >
                            <CheckCircle size={16} className="mr-1" />
                            Mark Resolved
                          </button>
                        ) : (
                          <div className="text-xs text-gray-500">
                            Resolved on: {formatDate(item.resolvedAt)}
                          </div>
                        )}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteItem(item.id);
                          }}
                          className="flex items-center px-3 py-1 bg-red-100 text-red-700 rounded hover:bg-red-200"
                        >
                          <Archive size={16} className="mr-1" />
                          Delete
                        </button>
                      </div>
                    </div>

                    {renderTypeSpecificContent(item)}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AdminFeedbackDashboard;
