// utils/timestamp-utils.js

/**
 * Utility functions for standardizing timestamp handling across the application
 * Helps solve inconsistencies between Firestore Timestamps, Date objects, and ISO strings
 */

import { Timestamp } from "firebase/firestore";

/**
 * Converts any timestamp-like value to a JavaScript Date object
 * Handles Firestore Timestamps, Date objects, ISO strings, and numbers
 *
 * @param {any} timestampValue - The timestamp value to convert
 * @returns {Date|null} - JavaScript Date object or null if invalid
 */
export const toDate = (timestampValue) => {
  if (!timestampValue) return null;

  try {
    // If it's already a Date object
    if (timestampValue instanceof Date) {
      return timestampValue;
    }

    // If it's a Firestore Timestamp
    if (timestampValue?.toDate && typeof timestampValue.toDate === "function") {
      return timestampValue.toDate();
    }

    // If it's a string (ISO format)
    if (typeof timestampValue === "string") {
      return new Date(timestampValue);
    }

    // If it's a number (milliseconds)
    if (typeof timestampValue === "number") {
      return new Date(timestampValue);
    }

    // If it's an object with seconds and nanoseconds (Firestore Timestamp format)
    if (timestampValue?.seconds && timestampValue?.nanoseconds) {
      return new Timestamp(
        timestampValue.seconds,
        timestampValue.nanoseconds
      ).toDate();
    }

    // Couldn't convert
    console.warn("Could not convert to Date:", timestampValue);
    return null;
  } catch (error) {
    console.error("Error converting timestamp to Date:", error);
    return null;
  }
};

/**
 * Formats a timestamp for display
 *
 * @param {any} timestampValue - The timestamp value to format
 * @param {Object} options - Intl.DateTimeFormat options
 * @returns {string} - Formatted date string or empty string if invalid
 */
export const formatTimestamp = (timestampValue, options = {}) => {
  const date = toDate(timestampValue);

  if (!date) return "";

  const defaultOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formatOptions = { ...defaultOptions, ...options };

  try {
    return new Intl.DateTimeFormat("default", formatOptions).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return date.toString();
  }
};

/**
 * Checks if a timestamp is in the future
 *
 * @param {any} timestampValue - The timestamp value to check
 * @returns {boolean} - True if the timestamp is in the future
 */
export const isFuture = (timestampValue) => {
  const date = toDate(timestampValue);
  if (!date) return false;

  return date > new Date();
};

/**
 * Checks if a timestamp is in the past
 *
 * @param {any} timestampValue - The timestamp value to check
 * @returns {boolean} - True if the timestamp is in the past
 */
export const isPast = (timestampValue) => {
  const date = toDate(timestampValue);
  if (!date) return false;

  return date < new Date();
};

/**
 * Checks if two timestamps represent the same day
 *
 * @param {any} timestamp1 - First timestamp
 * @param {any} timestamp2 - Second timestamp
 * @returns {boolean} - True if both timestamps are on the same day
 */
export const isSameDay = (timestamp1, timestamp2) => {
  const date1 = toDate(timestamp1);
  const date2 = toDate(timestamp2);

  if (!date1 || !date2) return false;

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

/**
 * Returns the number of days between two timestamps
 *
 * @param {any} startTimestamp - Start timestamp
 * @param {any} endTimestamp - End timestamp
 * @returns {number} - Number of days between the timestamps
 */
export const daysBetween = (startTimestamp, endTimestamp) => {
  const startDate = toDate(startTimestamp);
  const endDate = toDate(endTimestamp);

  if (!startDate || !endDate) return 0;

  // Strip time part for accurate day calculation
  const start = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const end = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((end - start) / millisecondsPerDay));
};

/**
 * Adds a specified number of hours to a timestamp
 *
 * @param {any} timestampValue - The timestamp to modify
 * @param {number} hours - The number of hours to add
 * @returns {Date} - New Date with added hours
 */
export const addHours = (timestampValue, hours) => {
  const date = toDate(timestampValue) || new Date();

  return new Date(date.getTime() + hours * 60 * 60 * 1000);
};

/**
 * Adds a specified number of days to a timestamp
 *
 * @param {any} timestampValue - The timestamp to modify
 * @param {number} days - The number of days to add
 * @returns {Date} - New Date with added days
 */
export const addDays = (timestampValue, days) => {
  const date = toDate(timestampValue) || new Date();

  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
};

/**
 * Gets the end of the current day (23:59:59.999)
 *
 * @param {any} timestampValue - Optional timestamp (defaults to now)
 * @returns {Date} - Date object set to the end of the day
 */
export const endOfDay = (timestampValue = null) => {
  const date = toDate(timestampValue) || new Date();

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );
};

/**
 * Gets the start of the current day (00:00:00.000)
 *
 * @param {any} timestampValue - Optional timestamp (defaults to now)
 * @returns {Date} - Date object set to the start of the day
 */
export const startOfDay = (timestampValue = null) => {
  const date = toDate(timestampValue) || new Date();

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
};
