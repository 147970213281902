// src/utils/units/unitStyles.js

/**
 * Creates style objects for unit components with proper color handling
 * @param {Object} unit The unit data object
 * @returns {Object} Style objects for various components
 */
export const getUnitStyles = (unit) => {
  // Default main colors from the application
  const defaultColors = {
    red: "#991616",
    gold: "#d19a10",
    green: "#848717",
    white: "#FCFCEE",
    gray: "#808080",
    darkgray: "#DADADA",
  };

  // Default to red if no color provided
  const primaryColor = unit?.color || defaultColors.red;

  return {
    // Button styles
    buttons: {
      primary: `bg-[${primaryColor}]`,
      primaryHover: `hover:bg-[${
        primaryColor === "#991616" ? "#932929" : primaryColor
      }]`,
    },

    // Text styles
    text: {
      primary: `text-[${primaryColor}]`,
    },

    // Table styles
    table: {
      header: `bg-[${primaryColor}]`,
    },

    // Raw color values for inline styles
    colors: {
      primary: primaryColor,
      primaryDark: primaryColor === "#991616" ? "#932929" : primaryColor,
    },
  };
};

/**
 * Creates inline style objects for unit components
 * @param {Object} unit The unit data object
 * @returns {Object} Style objects for various components
 */
export const getUnitInlineStyles = (unit) => {
  const styles = getUnitStyles(unit);

  return {
    buttons: {
      primary: {
        backgroundColor: styles.colors.primary,
      },
      primaryHover: {
        backgroundColor: styles.colors.primaryDark,
      },
    },
    text: {
      primary: {
        color: styles.colors.primary,
      },
    },
    table: {
      header: {
        backgroundColor: styles.colors.primary,
      },
    },
    accordion: {
      header: {
        backgroundColor: styles.colors.primary,
      },
    },
  };
};
