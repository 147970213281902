import React, { useState, useEffect } from "react";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import { useShopWithNotifications } from "../../hooks/useShopWithNotifications";
import { Search, X } from "lucide-react";
import ShopNavigation from "./shop-navigation.component";
import ProductCard from "./product-card.component";
import NavigationBar from "../navigation-bar-2/side-navigation-bar.component";
import FloatingActionMenu from "../navigation-bar-2/floating-side-menu";
import FeedbackMenu from "../support/feedback-menu";

const Shop = () => {
  const {
    balance,
    products,
    purchaseProductWithNotification,
    isLoading,
    userStats,
  } = useShopWithNotifications();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [notification, setNotification] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Process products whenever they change
  useEffect(() => {
    console.log("Processing products:", products.length);

    // Helper to normalize boolean values (handle strings, actual booleans, etc.)
    const isAvailable = (value) => {
      // Handle string values of "true"/"false"
      if (typeof value === "string") {
        return value.toLowerCase() === "true";
      }

      // Handle boolean values directly
      return value === true;
    };

    // Filter to only available products
    const available = products.filter((product) =>
      isAvailable(product.available)
    );
    console.log(
      `Filtered ${products.length} products to ${available.length} available`
    );

    setAvailableProducts(available);
  }, [products]);

  // Update filtered products whenever availableProducts, category, or search changes
  useEffect(() => {
    const filtered = availableProducts.filter((product) => {
      const matchesCategory =
        selectedCategory === "all" || product.category === selectedCategory;

      const matchesSearch =
        !searchQuery ||
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (product.description &&
          product.description
            .toLowerCase()
            .includes(searchQuery.toLowerCase()));

      return matchesCategory && matchesSearch;
    });

    console.log(
      `Applied category/search filters - showing ${filtered.length} products`
    );
    setFilteredProducts(filtered);
  }, [availableProducts, selectedCategory, searchQuery]);

  // Get unique categories from available products only
  const categories = [
    "all",
    ...new Set(
      availableProducts.map((product) => product.category).filter(Boolean)
    ),
  ];

  // Handle purchase
  const handlePurchase = async (productId) => {
    try {
      const result = await purchaseProductWithNotification(productId);

      setNotification({
        type: result.success ? "success" : "error",
        message: result.message,
      });

      // Clear notification after 3 seconds
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    } catch (error) {
      setNotification({
        type: "error",
        message: error.message,
      });

      // Clear notification after 3 seconds
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    }
  };

  // Clear search handler
  const handleClearSearch = () => {
    setSearchQuery("");
  };

  if (isLoading) {
    return (
      <>
        <NavigationBar />
        <ShopNavigation />
        <FloatingActionMenu />
        <FeedbackMenu />
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <NavigationBar />
      <ShopNavigation />
      <FloatingActionMenu />
      <FeedbackMenu />
      <div className="container mx-auto px-4 py-4 sm:py-8">
        {userStats && (
          <div className="mb-4 sm:mb-6 p-3 sm:p-4 border-2 border-darkgray rounded-lg bg-white">
            <h2 className="text-lg sm:text-xl font-bold mb-2">
              Your Power-Ups
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4">
              {userStats.dictionarySearchesRemaining > 0 && (
                <div className="bg-gray-100 p-3 rounded-lg">
                  <div className="flex items-center mb-1">
                    <span className="text-lg sm:text-xl mr-2">📚</span>
                    <span className="font-medium text-sm sm:text-base">
                      Dictionary
                    </span>
                  </div>
                  <p className="text-green text-xs sm:text-sm">
                    {userStats.dictionarySearchesRemaining} searches remaining
                  </p>
                </div>
              )}

              {userStats.xpMultiplier > 1 && (
                <div className="bg-gray-100 p-3 rounded-lg">
                  <div className="flex items-center mb-1">
                    <span className="text-lg sm:text-xl mr-2">⚡</span>
                    <span className="font-medium text-sm sm:text-base">
                      XP Boost
                    </span>
                  </div>
                  <p className="text-gold text-xs sm:text-sm">
                    {userStats.xpMultiplier}× multiplier active
                  </p>
                </div>
              )}

              {userStats.streakSaversRemaining > 0 && (
                <div className="bg-gray-100 p-3 rounded-lg">
                  <div className="flex items-center mb-1">
                    <span className="text-lg sm:text-xl mr-2">🔥</span>
                    <span className="font-medium text-sm sm:text-base">
                      Streak Savers
                    </span>
                  </div>
                  <p className="text-red text-xs sm:text-sm">
                    {userStats.streakSaversRemaining} savers available
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {notification && (
          <div
            className={`mb-4 sm:mb-6 p-3 sm:p-4 rounded-lg ${
              notification.type === "success"
                ? "bg-green text-white"
                : "bg-red text-white"
            }`}
          >
            {notification.message}
          </div>
        )}

        <div className="mb-6 sm:mb-8">
          {/* Category filters - horizontally scrollable on mobile */}
          <div className="overflow-x-auto -mx-4 px-4 pb-2 mb-4">
            <div className="flex flex-nowrap gap-2 min-w-max sm:flex-wrap">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`px-3 sm:px-4 py-2 border border-b-2 border-darkgray text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
                    selectedCategory === category
                      ? "bg-green text-white border-black border-2 border-b-4"
                      : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
                  }`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {/* Search input with clear button */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 sm:p-3 pl-9 sm:pl-10 text-sm sm:text-base border border-darkgray border-b-2 focus:outline-none focus:ring-2 focus:ring-green"
            />
            <Search className="h-4 w-4 sm:h-5 sm:w-5 text-darkgray absolute left-3 top-2.5 sm:top-3.5" />

            {searchQuery && (
              <button
                onClick={handleClearSearch}
                className="absolute right-3 top-2.5 sm:top-3.5"
                aria-label="Clear search"
              >
                <X className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400 hover:text-gray-600" />
              </button>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                balance={balance}
                onPurchase={handlePurchase}
              />
            ))
          ) : (
            <div className="col-span-full flex flex-col items-center justify-center py-8 sm:py-12 text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-14 w-14 sm:h-16 sm:w-16 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p className="text-base sm:text-lg text-center">
                No products found matching your criteria.
              </p>
              {searchQuery && (
                <button
                  onClick={handleClearSearch}
                  className="mt-4 px-4 py-2 bg-green text-white rounded-md"
                >
                  Clear Search
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Shop;
