import React, { useState, useContext, useEffect, useRef } from "react";
import { db } from "../../../firebase/firebase.utils.js";
import {
  collection,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  query,
  orderBy,
  increment,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import {
  LMContext,
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { ModalContext } from "../../../helpers/modalcontext";
import BeatLoader from "react-spinners/BeatLoader";
import ExerciseSummary from "./lesson-completion.component.jsx";
import PracticeCompletionSummary from "./practice-completion-summary.jsx";
import { ScoreContext } from "../../../helpers/ScoreContext.js";
import { LearningMaterialsContext } from "../../../helpers/contexts.js";
import { motion } from "framer-motion";

import AnswerButtons from "./answer-buttons.jsx";
import IncorrectFeedback from "./incorrect-feedback.jsx";
import CorrectFeedback from "./correct-feedback.jsx";
import ExitModalDisplay from "./exit-modal-display.jsx";
import HeaderBar from "./header-bar.jsx";
import ExerciseRenderer from "./exercise-renderer.jsx";
import DialogueContainer from "../texts/dialogue-container.jsx";
import PracticeConfirmationModal from "./practice-confirmation-modal.jsx";

import { useAuth } from "../../../helpers/UserAuthContextProvider.js";
// REPLACED: import { AnalyticsService } from "../../../services/enhanced-analytics.service.js";
import { useSafeAnalytics } from "../../../hooks/useSafeAnalytics.jsx"; // NEW: Import the hook

import correctSound from "../../../assets/sound/success.mp3";
import incorrectSound from "../../../assets/sound/fail-2.mp3";
import { useAttemptsWithNotifications } from "../../../hooks/useAttemptsHook.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { chunkArray } from "../../../helpers/arrayHelpers.js";
import { shuffleArray } from "../../../helpers/arrayHelpers.js";

import PowerUpUtils from "../../../services/power-ups/power-up-utils.js";
import { toDate, isFuture } from "../../../utils/timestamp-utils"; // Add these after implementing the utility
import { checkStreakProtection } from "../../../helpers/quiz-methods.js";

import {
  checkQuestion,
  skipQuestion,
  nextExercise,
  handleAnswer,
  handleContinue,
  openModal,
} from "../../../helpers/quiz-methods.js";

const QuizMultimode = ({ mode = "lesson", skipConfirmationProp = false }) => {
  const { user } = useAuth();
  // NEW: Use the safe analytics hook
  const { trackEvent, calculateBoostedXP } = useSafeAnalytics();

  const { feedback, setFeedback } = useContext(feedbackContext);
  const [loading, setLoading] = useState(false);
  const { score, setScore } = useContext(ScoreContext);
  const [optionChosen, setOptionChosen] = useState(null);
  const { modal, setModal } = useContext(ModalContext);
  const [hidden, setHidden] = useState(false);
  const { learningMaterialId } = useContext(LMContext);
  const [exercises, setExercises] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [hovered, setHovered] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [exercisesCompleted, setExercisesCompleted] = useState(0);
  const [learningMaterialState, setLearningMaterialState] = useState("quiz");
  const [learningMaterial, setLearningMaterial] = useState([]);
  const [exerciseIndex, setExerciseIndex] = useState(0);
  const [enrollment, setEnrollment] = useState({});
  const [userMotive, setUserMotive] = useState("");
  const { resetScore, recordScore } = useContext(ScoreContext);

  // Additional state for practice mode specific features
  const [exerciseResults, setExerciseResults] = useState({});

  // Analytics tracking state
  const [analyticsData, setAnalyticsData] = useState({
    startTime: new Date(),
    questionsAnswered: 0,
    correctAnswers: 0,
    incorrectAnswers: 0,
    attemptsUsed: 0,
    vocabularyLearned: [],
    totalXP: 0,
    exerciseTypes: {},
    exerciseResults: {},
  });

  const correctSoundRef = useRef(null);
  const incorrectSoundRef = useRef(null);

  const {
    deductAttempt,
    attempts,
    recordPracticeSessionWithNotification,
    deductAttemptWithNotification,
  } = useAttemptsWithNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const isPracticeMode =
    location.state?.practiceMode ||
    mode === "practice" ||
    learningMaterialId?.mode === "practice";

  useEffect(() => {
    if (learningMaterialId) {
      // Reset score when starting a new lesson/practice
      resetScore();

      // Create a unique key for tracking scores (if needed)
      const lessonKey = isPracticeMode
        ? "practice"
        : `skill_${learningMaterialId.skill}_lesson_${learningMaterialId.lesson?.lessonNumber}`;

      console.log(`Starting new learning material: ${lessonKey}`);
    }
  }, [learningMaterialId, resetScore, isPracticeMode]);

  useEffect(() => {
    // Reset all state when component mounts
    resetQuizState();

    // Explicitly reset score to ensure it starts from 0
    setScore(0);

    return () => {
      // Also reset when component unmounts
      resetQuizState();
    };
  }, []);

  // The issue is likely in the event listener setup and timing
  // Update your special exercise event handler with this version:

  // Special exercise event handler
  useEffect(() => {
    // Store processed events to prevent duplicate handling
    const processedEvents = new Set();

    // Handler for matching exercise completion
    const handleMatchingCompletion = (event) => {
      const { exerciseId, timestamp, isCorrect } = event.detail;

      // Debug current state
      console.log(`Matching exercise completed at index ${exerciseIndex}:`, {
        exerciseId,
        isFirst: exerciseIndex === 0,
        currentMaterial: learningMaterial[exerciseIndex]?.id,
        isCorrect,
        timestamp,
      });

      // Prevent duplicate handling
      const eventKey = `matching_${exerciseId}_${timestamp}`;
      if (processedEvents.has(eventKey)) {
        console.log("Skipping duplicate matching event:", eventKey);
        return;
      }
      processedEvents.add(eventKey);

      // Ensure we've actually loaded learning material
      if (!learningMaterial.length) {
        console.warn("Received matching event before learning material loaded");
        return;
      }

      // Set option chosen based on result
      setOptionChosen(isCorrect ? "completed" : "failed");

      // Set appropriate feedback
      setFeedback(isCorrect ? "correct" : "incorrect");

      // Get a stable copy of the current index to use in the timeout
      const currentIndex = exerciseIndex;

      // Trigger check with a small delay to ensure state is updated
      setTimeout(() => {
        handleSpecialExerciseCheck({
          exerciseType: "matching_images",
          exerciseId,
          isCorrect,
          capturedIndex: currentIndex,
          // Add flag indicating this is the first exercise
          isFirstExercise: currentIndex === 0,
        });
      }, 500);
    };

    // Handler for tagging exercise completion
    const handleTaggingCompletion = (event) => {
      const { exerciseId, timestamp, isCorrect } = event.detail;

      // Debug current state
      console.log(`Tagging exercise completed at index ${exerciseIndex}:`, {
        exerciseId,
        isFirst: exerciseIndex === 0,
        currentMaterial: learningMaterial[exerciseIndex]?.id,
        isCorrect,
        timestamp,
      });

      // Prevent duplicate handling
      const eventKey = `tagging_${exerciseId}_${timestamp}`;
      if (processedEvents.has(eventKey)) {
        console.log("Skipping duplicate tagging event:", eventKey);
        return;
      }
      processedEvents.add(eventKey);

      // Ensure we've actually loaded learning material
      if (!learningMaterial.length) {
        console.warn("Received tagging event before learning material loaded");
        return;
      }

      // Set option chosen based on result
      setOptionChosen(isCorrect ? "completed" : "failed");

      // Set appropriate feedback
      setFeedback(isCorrect ? "correct" : "incorrect");

      // Get a stable copy of the current index to use in the timeout
      const currentIndex = exerciseIndex;

      // Trigger check with a small delay to ensure state is updated
      setTimeout(() => {
        handleSpecialExerciseCheck({
          exerciseType: "tagging_pairs",
          exerciseId,
          isCorrect,
          capturedIndex: currentIndex,
          // Add flag indicating this is the first exercise
          isFirstExercise: currentIndex === 0,
        });
      }, 500);
    };

    // Add event listeners
    window.addEventListener(
      "matching_exercise_completed",
      handleMatchingCompletion
    );
    window.addEventListener(
      "tagging_exercise_completed",
      handleTaggingCompletion
    );

    console.log(
      "Set up special exercise event listeners at index:",
      exerciseIndex
    );

    // Cleanup
    return () => {
      window.removeEventListener(
        "matching_exercise_completed",
        handleMatchingCompletion
      );
      window.removeEventListener(
        "tagging_exercise_completed",
        handleTaggingCompletion
      );
      console.log("Removed special exercise event listeners");
    };
  }, [exerciseIndex, learningMaterial]); // Include learningMaterial in dependencies

  // Apply this as a complete replacement for your handleSpecialExerciseCheck function:

  const handleSpecialExerciseCheck = (details) => {
    const {
      isCorrect,
      exerciseType,
      exerciseId,
      isFirstExercise,
      capturedIndex,
    } = details;

    // Special logging for first exercise
    if (isFirstExercise) {
      console.log("Processing first exercise special case:", {
        exerciseType,
        exerciseId,
        isCorrect,
        currentExerciseIndex: exerciseIndex,
        capturedIndex,
      });
    }

    // Find the exercise by ID instead of using exerciseIndex
    // This is crucial for the first exercise case
    let targetExercise = null;
    let targetIndex =
      capturedIndex !== undefined ? capturedIndex : exerciseIndex;

    // Try to find the exercise in the learning material
    for (let i = 0; i < learningMaterial.length; i++) {
      if (learningMaterial[i].id === exerciseId) {
        targetExercise = learningMaterial[i];
        targetIndex = i;
        break;
      }
    }

    // If we couldn't find the exact exercise, use the current one as fallback
    if (!targetExercise) {
      targetExercise = learningMaterial[targetIndex];
      console.warn(
        "Could not find exercise by ID, using index-based fallback:",
        { exerciseId, fallbackIndex: targetIndex }
      );
    }

    // Safety check - if we still don't have a valid exercise, create a minimal one
    if (!targetExercise) {
      console.error("Unable to find a valid exercise, using minimal data");
      targetExercise = {
        id: exerciseId || `unknown-${Date.now()}`,
        type: exerciseType,
        content: { question: "Unknown exercise" },
        skill: learningMaterialId?.skill || 0,
        lesson: learningMaterialId?.lesson?.lessonNumber || 0,
      };
    }

    // Play appropriate sound immediately
    if (isCorrect && correctSoundRef.current) {
      correctSoundRef.current.play().catch(console.error);
    } else if (!isCorrect && incorrectSoundRef.current) {
      incorrectSoundRef.current.play().catch(console.error);
    }

    console.log(`Processing special exercise check: ${exerciseType}`, {
      isCorrect,
      targetExercise,
      currentIndex: exerciseIndex,
      isFirstExercise,
    });

    // Store result for review immediately
    setExerciseResults((prev) => ({
      ...prev,
      [targetExercise.id]: {
        exerciseId: targetExercise.id,
        type: targetExercise.type || exerciseType,
        question: targetExercise.content?.question || "Exercise question",
        correctAnswer: isCorrect ? "completed" : "not completed",
        userAnswer: isCorrect ? "completed" : "failed",
        isCorrect,
      },
    }));

    // Update analytics data immediately
    setAnalyticsData((prev) => {
      const xpEarned = calculateXP(exerciseType, isCorrect);

      // Count exercise types
      const exerciseTypes = { ...prev.exerciseTypes };
      exerciseTypes[exerciseType] = (exerciseTypes[exerciseType] || 0) + 1;

      // Update exercise results
      const exerciseResults = {
        ...prev.exerciseResults,
        [targetExercise.id]: {
          exerciseId: targetExercise.id,
          type: targetExercise.type || exerciseType,
          question: targetExercise.content?.question || "Exercise question",
          correctAnswer: isCorrect ? "completed" : "not completed",
          userAnswer: isCorrect ? "completed" : "failed",
          isCorrect,
        },
      };

      console.log(`Updating analytics for ${exerciseType}:`, {
        prevCorrectAnswers: prev.correctAnswers,
        newCorrectAnswers: isCorrect
          ? prev.correctAnswers + 1
          : prev.correctAnswers,
        isCorrect,
      });

      return {
        ...prev,
        questionsAnswered: prev.questionsAnswered + 1,
        correctAnswers: isCorrect
          ? prev.correctAnswers + 1
          : prev.correctAnswers,
        incorrectAnswers: !isCorrect
          ? prev.incorrectAnswers + 1
          : prev.incorrectAnswers,
        attemptsUsed: prev.attemptsUsed + 1,
        totalXP: prev.totalXP + xpEarned,
        exerciseTypes,
        exerciseResults,
      };
    });

    // Track analytics events if user is logged in
    if (user?.uid) {
      // UPDATED: Using trackEvent from useSafeAnalytics

      // Track question answered
      trackEvent(
        isPracticeMode
          ? "practice_question_answered"
          : "lesson_question_answered",
        {
          exerciseType: exerciseType,
          exerciseId: targetExercise.id,
          isCorrect,
        }
      );

      // Track correct/incorrect answer
      trackEvent(isCorrect ? "correct_answer" : "incorrect_answer", {
        exerciseType: exerciseType,
        exerciseId: targetExercise.id,
        skillId: targetExercise.skill,
        lessonId: targetExercise.lesson,
        mode: isPracticeMode ? "practice" : "lesson",
      });

      // Track attempt used
      trackEvent("attempt_used", {
        context: isCorrect ? "correct_answer" : "incorrect_answer",
        remainingAttempts: attempts.count - 1,
        mode: isPracticeMode ? "practice" : "lesson",
      });
    }

    // Always update the UI immediately
    if (isCorrect) {
      setFeedback("correct");
    } else {
      setFeedback("incorrect");
    }

    // Use a shorter transition delay for better UX
    const transitionDelay = 1000; // 1 second delay

    try {
      // Update score if correct
      if (isCorrect) {
        setScore((prev) => prev + 1);
      } else {
        // Deduct attempt
        deductAttemptWithNotification().catch(console.error);
      }

      // Key fix: Properly handle the exercise progression
      setTimeout(() => {
        setFeedback(null);
        setOptionChosen(null);

        // Increment the completed exercises counter
        const newCompletedCount = exercisesCompleted + 1;
        setExercisesCompleted(newCompletedCount);

        // For first exercise, ensure we're moving to index 1 explicitly
        const nextIndex = isFirstExercise ? 1 : exerciseIndex + 1;

        console.log("Exercise progression for special exercise:", {
          wasFirst: isFirstExercise,
          currentExerciseIndex: exerciseIndex,
          forcingToIndex: nextIndex,
          totalExercises: learningMaterial.length,
          completedCount: newCompletedCount,
        });

        // Only complete if we've done all 12 exercises
        if (newCompletedCount >= 12) {
          console.log("All 12 exercises completed, showing summary");
          handleLessonCompletion();
          setLearningMaterialState("complete");
        }
        // If we've reached the end of available exercises but haven't done 12
        else if (nextIndex >= learningMaterial.length) {
          console.log(
            "Reached end of available exercises, looping back to beginning"
          );
          setExerciseIndex(0);
        }
        // Normal case - proceed to next exercise
        else {
          console.log(
            `Moving to exercise ${nextIndex + 1} of ${learningMaterial.length}`
          );

          // This is the critical fix for the first exercise issue
          // Force a specific index instead of an incremental update
          setExerciseIndex(nextIndex);

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }, transitionDelay);
    } catch (error) {
      console.error("Error processing special exercise check:", error);

      // Emergency fallback - safely move to next question
      setTimeout(() => {
        // For first exercise, always move to index 1 in case of error
        const nextIndex = isFirstExercise
          ? 1
          : exerciseIndex + 1 < learningMaterial.length
          ? exerciseIndex + 1
          : 0;

        setExerciseIndex(nextIndex);
        setFeedback(null);
        setOptionChosen(null);
      }, 1000);
    }
  };

  // Handle practice mode confirmation
  useEffect(() => {
    if (
      isPracticeMode &&
      !location.state?.skipConfirmation &&
      !skipConfirmationProp
    ) {
      setShowConfirmationModal(true);
    }
  }, [isPracticeMode, location.state?.skipConfirmation, skipConfirmationProp]);

  // Track lesson started (only if not practice mode)
  useEffect(() => {
    if (
      user?.uid &&
      !isPracticeMode &&
      learningMaterialId?.skill &&
      learningMaterialId?.lesson
    ) {
      // UPDATED: Using trackEvent from useSafeAnalytics
      trackEvent("lesson_started", {
        skillId: learningMaterialId.skill,
        lessonId: learningMaterialId.lesson.lessonNumber,
        lessonName: learningMaterialId.lesson.lessonName || "",
        mode: "lesson",
      });
    } else if (user?.uid && isPracticeMode) {
      // UPDATED: Using trackEvent from useSafeAnalytics
      trackEvent("practice_started", {
        mode: "practice",
        skills: learningMaterialId?.lessons?.map((l) => l.skill) || [],
      });
    }
  }, [user?.uid, learningMaterialId, isPracticeMode, trackEvent]);

  // Firestore query construction for lessons
  const getLessonExercises = () => {
    if (!learningMaterialId?.skill || !learningMaterialId?.lesson) {
      console.error("Missing skill/lesson information");
      return query(collection(db, "socials"), where("skill", "==", -1));
    }

    return query(
      collection(db, "socials"),
      where("skill", "==", learningMaterialId.skill),
      where("lesson", "==", learningMaterialId.lesson.lessonNumber),
      orderBy("exerciseOrder")
    );
  };

  const getPracticeExercises = () => {
    if (!learningMaterialId?.lessons?.length) return [];

    // Group lessons by skill number
    const lessonsBySkill = learningMaterialId.lessons.reduce(
      (acc, { skill, lesson }) => {
        acc[skill] = acc[skill] || [];
        acc[skill].push(lesson);
        return acc;
      },
      {}
    );

    // Create queries compatible with your index
    return Object.entries(lessonsBySkill).flatMap(([skill, lessons]) => {
      const batches = chunkArray(lessons, 10); // Firestore 'in' limit

      return batches.map((batch) =>
        query(
          collection(db, "socials"),
          where("skill", "==", Number(skill)),
          where("lesson", "in", batch.map(Number)),
          orderBy("exerciseOrder") // Matches your existing index
        )
      );
    });
  };

  // Monitor attempts count
  useEffect(() => {
    if (attempts.count === 0) {
      navigate("/units");
    }
  }, [attempts.count, navigate]);

  // Setup sound effects
  useEffect(() => {
    correctSoundRef.current = new Audio(correctSound);
    incorrectSoundRef.current = new Audio(incorrectSound);

    return () => {
      correctSoundRef.current.pause();
      correctSoundRef.current.currentTime = 0;
      incorrectSoundRef.current.pause();
      incorrectSoundRef.current.currentTime = 0;
    };
  }, []);

  // Load user data
  useEffect(() => {
    let isMounted = true;
    const getCurrUser = async () => {
      if (user && isMounted) {
        try {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setEnrollment({ ...docSnap.data(), id: docSnap.id });
            setIsSubscribed(docSnap.data().membership);
            setUserMotive(docSnap.data().motive);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    getCurrUser();
    return () => {
      isMounted = false;
    };
  }, [user?.uid]);

  // Load exercises based on mode
  useEffect(() => {
    const loadExercises = async () => {
      setLoading(true);
      setScore(0);
      try {
        let exercises = [];

        if (isPracticeMode) {
          // Practice mode - handle batched queries
          const practiceQueries = getPracticeExercises();

          if (practiceQueries.length === 0) {
            throw new Error("No valid practice queries generated");
          }

          // Execute all queries in parallel
          const querySnapshots = await Promise.all(
            practiceQueries.map((q) => getDocs(q))
          );

          // Merge and flatten results from all batches
          exercises = querySnapshots.flatMap((snapshot) =>
            snapshot.docs.map((d) => ({
              id: d.id,
              ...d.data(),
              // Add derived fields if needed
              compositeId: `${d.data().skill}_${d.data().lesson}`,
            }))
          );

          // Shuffle and limit to 12 exercises
          if (exercises.length > 0) {
            exercises = shuffleArray(exercises).slice(0, 12);
          } else {
            console.warn("No practice exercises found");
          }
        } else {
          // Regular lesson mode
          const lessonQuery = getLessonExercises();
          const snapshot = await getDocs(lessonQuery);

          exercises = snapshot.docs.map((d) => ({
            id: d.id,
            ...d.data(),
            // Add consistent fields
            compositeId: `${d.data().skill}_${d.data().lesson}`,
          }));

          if (exercises.length > 0) {
            exercises = shuffleArray(exercises);

            // If there are more than 12 exercises, limit to 12 for consistency with practice mode
            if (exercises.length > 12) {
              exercises = exercises.slice(0, 12);
            }
          }

          // Optional: Filter by exercise type if needed
          // exercises = exercises.filter((ex) => ex.type === "audio_mcq");
        }

        console.log("Final exercises loaded:", exercises);
        setExercises(exercises);
        setLearningMaterial(exercises);
      } catch (error) {
        console.error("Exercise loading failed:", error);
        // Optional: Set error state for UI feedback
        setExercises([]);
      } finally {
        setLoading(false);
      }
    };

    // Only load exercises if confirmation is passed or we're in lesson mode
    if (!isPracticeMode || !showConfirmationModal) {
      loadExercises();
    }
  }, [isPracticeMode, learningMaterialId, showConfirmationModal]);

  // Calculate XP based on exercise type and correctness
  const calculateXP = (exerciseType, isCorrect) => {
    // Base XP values
    const baseValues = {
      mcqflash: 5,
      mcq1: 5,
      audio_mcq: 8,
      tagging_pairs: 10,
      arrange: 8,
      fill_in_the_blank: 12,
      matching_images: 7,
      translate_input: 15,
      audio_transcription: 12,
      mark_correct_meaning: 6,
      read_and_respond: 10,
      shuffle_words: 10,
    };

    // Base value for the exercise type or default 5 XP
    const baseXP = baseValues[exerciseType] || 5;

    // If correct, full XP. If incorrect, 1/3 of XP (for trying)
    return isCorrect ? baseXP : Math.floor(baseXP / 3);
  };

  // Track vocabulary learned
  const trackVocabulary = (exercise) => {
    // Only track certain exercise types that teach vocabulary
    const vocabularyTypes = [
      "mcqflash",
      "audio_mcq",
      "translate_input",
      "matching_images",
    ];

    if (!vocabularyTypes.includes(exercise.type)) {
      return [];
    }

    // Extract vocabulary words based on exercise type
    let words = [];

    switch (exercise.type) {
      case "mcqflash":
      case "audio_mcq":
        if (exercise.question) {
          words.push({
            word: exercise.question,
            translation: exercise.correctAnswer || "",
            context: exercise.hint || "",
          });
        }
        break;
      case "translate_input":
        if (exercise.targetWord) {
          words.push({
            word: exercise.targetWord,
            translation: exercise.correctAnswer || "",
            context: exercise.hint || "",
          });
        }
        break;
      case "matching_images":
        if (exercise.pairs && Array.isArray(exercise.pairs)) {
          exercise.pairs.forEach((pair) => {
            if (pair.text) {
              words.push({
                word: pair.text,
                translation: pair.translation || "",
                context: "",
              });
            }
          });
        }
        break;
    }

    return words;
  };

  const syncAnalyticsWithCompletedExercises = () => {
    setAnalyticsData((prev) => {
      // Use exactly 12 as the completed count for the final summary
      const exerciseCount = Math.max(exercisesCompleted, 12);

      console.log("Final analytics synchronization:", {
        previousQuestionsAnswered: prev.questionsAnswered,
        forcingToExactly: 12,
        actualExercisesCompleted: exercisesCompleted,
      });

      // Count result details
      const resultCount = Object.keys(prev.exerciseResults || {}).length;
      const correctCount = Object.values(prev.exerciseResults || {}).filter(
        (result) => result.isCorrect
      ).length;
      const incorrectCount = resultCount - correctCount;

      // Ensure all values are consistent with exactly 12 questions
      return {
        ...prev,
        questionsAnswered: 12, // Force to exactly 12
        correctAnswers: Math.max(prev.correctAnswers, correctCount),
        incorrectAnswers: Math.max(prev.incorrectAnswers, 12 - correctCount),
      };
    });
  };

  const handleLessonCompletion = async () => {
    syncAnalyticsWithCompletedExercises();

    // Apply XP boost before using the totalXP value for analytics
    let finalXpToAward = analyticsData.totalXP;
    let xpBoostInfo = null;

    // Check for XP boosters using the new safe analytics hook
    if (user?.uid && analyticsData?.totalXP > 0) {
      try {
        // Calculate boosted XP using the hook method
        const xpResult = await calculateBoostedXP(analyticsData.totalXP);

        // If there was a boost, update the XP value
        if (xpResult.hasBoost) {
          finalXpToAward = xpResult.xp;
          xpBoostInfo = {
            originalXP: analyticsData.totalXP,
            boostedXP: xpResult.xp,
            multiplier: xpResult.multiplier,
          };

          console.log(
            `Applied XP boost: ${analyticsData.totalXP} → ${finalXpToAward} (${xpResult.multiplier}×)`
          );

          // Update analytics data with boosted values
          setAnalyticsData((prev) => ({
            ...prev,
            totalXP: finalXpToAward,
            originalXP: analyticsData.totalXP, // Store original for reference
            xpMultiplier: xpResult.multiplier,
            xpBoostApplied: true,
          }));
        }
      } catch (error) {
        console.error("Error calculating boosted XP:", error);
      }
    }

    setTimeout(() => {
      // Log the final analytics after a small delay to ensure state updates have propagated
      console.log("FINAL ANALYTICS DATA:", {
        questionsAnswered: analyticsData.questionsAnswered,
        correctAnswers: analyticsData.correctAnswers,
        incorrectAnswers: analyticsData.incorrectAnswers,
        exerciseTypes: analyticsData.exerciseTypes,
        exerciseResults: Object.keys(analyticsData.exerciseResults).length,
        originalXP: xpBoostInfo?.originalXP || analyticsData.totalXP,
        boostedXP: xpBoostInfo?.boostedXP || analyticsData.totalXP,
        xpMultiplier: xpBoostInfo?.multiplier || 1,
      });
    }, 100);

    if (!isPracticeMode && user?.uid) {
      // Regular lesson completion logic
      try {
        const userRef = doc(db, "users", user.uid);
        const skillNumber = Number(learningMaterialId.skill); // Ensure number
        const lessonNumber = Number(learningMaterialId.lesson.lessonNumber); // Ensure number

        // Calculate the score directly without calling calculatePercentageScore
        const finalScore = (() => {
          if (analyticsData && analyticsData.questionsAnswered > 0) {
            return Math.round(
              (analyticsData.correctAnswers / analyticsData.questionsAnswered) *
                100
            );
          }
          // Fallback calculation using the score context
          return Math.round((score / 12) * 100);
        })();

        console.log("Marking lesson as completed:", {
          skillNumber,
          lessonNumber,
          score: finalScore,
        });

        // Get user document to check streak status
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const lastActivity = userData.lastActivity?.toDate() || new Date(0);
          const currentStreak = userData.streak || 0;

          // Check if the streak would be broken (last activity more than a day ago)
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          const lastActivityDate = new Date(lastActivity);
          lastActivityDate.setHours(0, 0, 0, 0);

          // Prepare updates object
          const updates = {
            [`progress.${skillNumber}.${lessonNumber}.completed`]: true,
            [`progress.${skillNumber}.${lessonNumber}.score`]: finalScore,
            [`progress.${skillNumber}.${lessonNumber}.completedAt`]:
              serverTimestamp(),
            lastActivity: serverTimestamp(), // Always update last activity
          };

          // If last activity was before yesterday, check for streak protection
          if (lastActivityDate < yesterday) {
            // Check if streak is protected
            const protection = await PowerUpUtils.checkStreakProtection(
              user.uid
            );

            if (protection.isProtected) {
              // Streak is protected, keep current streak
              console.log(`Streak protected by ${protection.source}`);

              // Track streak protection in analytics
              trackEvent("streak_protection_applied", {
                source: protection.source,
                previousStreak: currentStreak,
                context: "lesson_completion",
              });
            } else {
              // Streak not protected, reset it to 1 (today counts as day 1)
              console.log("Streak not protected, resetting to 1");
              updates.streak = 1;

              // Track streak reset in analytics
              trackEvent("streak_reset", {
                previousStreak: currentStreak,
                context: "lesson_completion",
              });
            }
          } else if (lastActivityDate.getTime() === yesterday.getTime()) {
            // Last activity was yesterday, increment streak
            updates.streak = increment(1);

            // Track streak increment in analytics
            trackEvent("streak_incremented", {
              newStreak: currentStreak + 1,
              context: "lesson_completion",
            });
          }
          // If lastActivityDate is today, no need to update streak

          // Apply all updates
          await updateDoc(userRef, updates);
        } else {
          // Just apply basic updates if user doc doesn't exist
          await updateDoc(userRef, {
            [`progress.${skillNumber}.${lessonNumber}.completed`]: true,
            [`progress.${skillNumber}.${lessonNumber}.score`]: finalScore,
            [`progress.${skillNumber}.${lessonNumber}.completedAt`]:
              serverTimestamp(),
            lastActivity: serverTimestamp(),
          });
        }

        // Track analytics event for lesson completion if not already tracked
        if (user?.uid && analyticsData) {
          // Track lesson completion in analytics
          trackEvent("lesson_completed", {
            skillId: skillNumber,
            lessonId: lessonNumber,
            lessonName: learningMaterialId.lesson.lessonName || "",
            timeSpent: Math.floor(
              (new Date() - analyticsData.startTime) / 1000
            ), // in seconds
            questionsAnswered: analyticsData.questionsAnswered,
            correctAnswers: analyticsData.correctAnswers,
            incorrectAnswers: analyticsData.incorrectAnswers,
            attemptsUsed: analyticsData.attemptsUsed,
            totalXP: finalXpToAward, // Use boosted value
            xpBoostApplied: xpBoostInfo !== null,
            originalXP: xpBoostInfo?.originalXP || finalXpToAward,
            xpMultiplier: xpBoostInfo?.multiplier || 1,
          });

          // Additional tracking for XP boost
          if (xpBoostInfo) {
            trackEvent("xp_boost_applied", {
              baseXP: xpBoostInfo.originalXP,
              boostedXP: xpBoostInfo.boostedXP,
              multiplier: xpBoostInfo.multiplier,
              source: "lesson_completion",
            });
          }

          // Track vocabulary learned
          if (analyticsData.vocabularyLearned.length > 0) {
            trackEvent("vocabulary_learned", {
              count: analyticsData.vocabularyLearned.length,
              words: analyticsData.vocabularyLearned,
              source: `lesson_${skillNumber}_${lessonNumber}`,
            });
          }

          // Track XP earned
          trackEvent("xp_earned", {
            xp: finalXpToAward, // Use boosted XP value
            originalXp: analyticsData.totalXP,
            source: `lesson_${skillNumber}_${lessonNumber}`,
            xpBoostApplied: xpBoostInfo !== null,
            xpMultiplier: xpBoostInfo?.multiplier || 1,
          });
        }
      } catch (error) {
        console.error("Completion update failed:", error);
      }
    } else if (isPracticeMode && user?.uid) {
      // Practice mode completion logic - Keep as is
      try {
        // Record practice session in attempts system
        const practiceResult = await recordPracticeSessionWithNotification();

        // Update analytics with practice results
        setAnalyticsData((prev) => ({
          ...prev,
          practiceResult: practiceResult,
        }));

        // Check streak protection for practice mode too
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const lastActivity = userData.lastActivity?.toDate() || new Date(0);
          const currentStreak = userData.streak || 0;

          // Check streak logic (similar to above but for practice mode)
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          const lastActivityDate = new Date(lastActivity);
          lastActivityDate.setHours(0, 0, 0, 0);

          // Prepare updates
          const updates = {
            lastActivity: serverTimestamp(),
          };

          // Apply streak protection logic
          if (lastActivityDate < yesterday) {
            const protection = await PowerUpUtils.checkStreakProtection(
              user.uid
            );

            if (protection.isProtected) {
              console.log(
                `Streak protected by ${protection.source} in practice mode`
              );

              trackEvent("streak_protection_applied", {
                source: protection.source,
                previousStreak: currentStreak,
                context: "practice_completion",
              });
            } else {
              console.log(
                "Streak not protected in practice mode, resetting to 1"
              );
              updates.streak = 1;

              trackEvent("streak_reset", {
                previousStreak: currentStreak,
                context: "practice_completion",
              });
            }
          } else if (lastActivityDate.getTime() === yesterday.getTime()) {
            updates.streak = increment(1);

            trackEvent("streak_incremented", {
              newStreak: currentStreak + 1,
              context: "practice_completion",
            });
          }

          // Apply updates
          await updateDoc(userRef, updates);
        }

        // Track practice completion in analytics with boosted XP values
        trackEvent("practice_completed", {
          timeSpent: Math.floor((new Date() - analyticsData.startTime) / 1000), // in seconds
          questionsAnswered: analyticsData.questionsAnswered,
          correctAnswers: analyticsData.correctAnswers,
          incorrectAnswers: analyticsData.incorrectAnswers,
          attemptsUsed: analyticsData.attemptsUsed,
          totalXP: finalXpToAward, // Use boosted value
          exerciseTypes: analyticsData.exerciseTypes,
          livesEarned: practiceResult.regeneratedLives,
          xpBoostApplied: xpBoostInfo !== null,
          originalXP: xpBoostInfo?.originalXP || finalXpToAward,
          xpMultiplier: xpBoostInfo?.multiplier || 1,
        });

        // Track vocabulary learned in practice mode
        if (analyticsData.vocabularyLearned.length > 0) {
          trackEvent("vocabulary_learned", {
            count: analyticsData.vocabularyLearned.length,
            words: analyticsData.vocabularyLearned,
            source: "practice",
          });
        }

        // Track XP earned from practice with boost info
        trackEvent("xp_earned", {
          xp: finalXpToAward, // Use boosted value
          originalXp: analyticsData.totalXP,
          source: "practice",
          xpBoostApplied: xpBoostInfo !== null,
          xpMultiplier: xpBoostInfo?.multiplier || 1,
        });

        // Track streak contribution
        trackEvent("streak_contribution", {
          source: "practice",
          xp: finalXpToAward, // Use boosted value
        });
      } catch (error) {
        console.error("Practice completion handling failed:", error);
      }
    }
  };

  const handleCheckQuestion = () => {
    // Get current exercise
    const currentExercise = learningMaterial[exerciseIndex];

    // Special handling for matching/tagging exercises that don't use the CHECK button
    // They signal completion through special optionChosen values
    const isSpecialExerciseType =
      currentExercise.type === "matching_images" ||
      currentExercise.type === "tagging_pairs";

    if (isSpecialExerciseType) {
      // Check if this is a success or failure case
      const isCompleted =
        optionChosen === "COMPLETE_MATCHING" ||
        optionChosen === "COMPLETE_TAGGING" ||
        optionChosen === "completed";
      const isFailed =
        optionChosen === "FAILED_MATCHING" ||
        optionChosen === "FAILED_TAGGING" ||
        optionChosen === "failed";

      if (!isCompleted && !isFailed) {
        // Exercise is still in progress, don't process yet
        return;
      }

      // Process the result - either success or failure
      const isCorrect = isCompleted;

      // Store result for review
      setExerciseResults((prev) => ({
        ...prev,
        [currentExercise.id]: {
          exerciseId: currentExercise.id,
          type: currentExercise.type,
          question: currentExercise.content?.question || "",
          correctAnswer: isCorrect ? "completed" : "not completed",
          userAnswer: isCorrect ? "completed" : "failed",
          isCorrect,
        },
      }));

      // Update analytics data
      setAnalyticsData((prev) => {
        const exerciseType = currentExercise.type;
        const xpEarned = calculateXP(exerciseType, isCorrect);

        // Count exercise types
        const exerciseTypes = { ...prev.exerciseTypes };
        exerciseTypes[exerciseType] = (exerciseTypes[exerciseType] || 0) + 1;

        // Update exercise results for review
        const exerciseResults = {
          ...prev.exerciseResults,
          [currentExercise.id]: {
            exerciseId: currentExercise.id,
            type: currentExercise.type,
            question: currentExercise.content?.question || "",
            correctAnswer: isCorrect ? "completed" : "not completed",
            userAnswer: isCorrect ? "completed" : "failed",
            isCorrect,
          },
        };

        console.log("Updating analytics for matching/tagging:", {
          prevCorrectAnswers: prev.correctAnswers,
          newCorrectAnswers: isCorrect
            ? prev.correctAnswers + 1
            : prev.correctAnswers,
          isCorrect,
        });

        return {
          ...prev,
          questionsAnswered: prev.questionsAnswered + 1,
          correctAnswers: isCorrect
            ? prev.correctAnswers + 1
            : prev.correctAnswers,
          incorrectAnswers: !isCorrect
            ? prev.incorrectAnswers + 1
            : prev.incorrectAnswers,
          attemptsUsed: prev.attemptsUsed + 1,
          totalXP: prev.totalXP + xpEarned,
          exerciseTypes,
          exerciseResults,
        };
      });

      // Track analytics events for the answer
      if (user?.uid) {
        // UPDATED: Using trackEvent from useSafeAnalytics

        // Track question answered
        trackEvent(
          isPracticeMode
            ? "practice_question_answered"
            : "lesson_question_answered",
          {
            exerciseType: currentExercise.type,
            exerciseId: currentExercise.id,
            isCorrect,
          }
        );

        // Track correct/incorrect answer
        trackEvent(isCorrect ? "correct_answer" : "incorrect_answer", {
          exerciseType: currentExercise.type,
          exerciseId: currentExercise.id,
          skillId: currentExercise.skill,
          lessonId: currentExercise.lesson,
          mode: isPracticeMode ? "practice" : "lesson",
        });

        // Track attempt used
        trackEvent("attempt_used", {
          context: isCorrect ? "correct_answer" : "incorrect_answer",
          remainingAttempts: attempts.count - 1,
          mode: isPracticeMode ? "practice" : "lesson",
        });
      }

      // Play the appropriate sound
      if (isCorrect && correctSoundRef.current) {
        correctSoundRef.current.play();
      } else if (!isCorrect && incorrectSoundRef.current) {
        incorrectSoundRef.current.play();
      }

      // Call checkQuestion to process the result
      return checkQuestion({
        currentExercise,
        optionChosen: isCorrect ? "completed" : "failed",
        isCorrect,
        setFeedback,
        setScore,
        deductAttempt: deductAttemptWithNotification,
        setOptionChosen,
      });
    }

    // --- Regular exercise handling below ---

    // Normalize answers for more robust comparison
    const normalizeAnswer = (answer) => {
      if (answer === null || answer === undefined) return "";
      return String(answer).trim().toLowerCase();
    };

    // Get the correct answer based on exercise type
    const getCorrectAnswer = (exercise) => {
      // Special handling for mark_correct_meaning type
      if (exercise.type === "mark_correct_meaning") {
        // Check if correctAnswer is an object with text property
        if (
          exercise.validation &&
          exercise.validation.correctAnswer &&
          typeof exercise.validation.correctAnswer === "object" &&
          exercise.validation.correctAnswer.text
        ) {
          console.log(
            "Found mark_correct_meaning with object correctAnswer:",
            exercise.validation.correctAnswer.text
          );
          return exercise.validation.correctAnswer.text;
        }
      }

      // Check if there's a direct correctAnswer in validation
      if (
        exercise.validation &&
        exercise.validation.correctAnswer !== undefined
      ) {
        // General handling for correctAnswer object
        if (typeof exercise.validation.correctAnswer === "object") {
          if (exercise.validation.correctAnswer.text !== undefined) {
            console.log(
              "Found object correctAnswer with text property:",
              exercise.validation.correctAnswer.text
            );
            return exercise.validation.correctAnswer.text;
          }
        }
        return exercise.validation.correctAnswer;
      }

      // Handle different exercise types
      switch (exercise.type) {
        case "mcqflash":
          if (exercise.content && exercise.content.options) {
            const correctOption = exercise.content.options.find(
              (option) => option.isCorrect === true
            );
            return correctOption ? correctOption.text : "";
          }
          break;

        case "audio_mcq":
          if (exercise.validation && exercise.validation.correctAnswer) {
            return exercise.validation.correctAnswer;
          }
          break;

        case "translate_input":
        case "audio_transcription":
        case "fill_in_the_blank":
          if (exercise.validation && exercise.validation.correctAnswer) {
            return exercise.validation.correctAnswer;
          }
          break;

        case "read_and_respond":
        case "mark_correct_meaning":
          if (exercise.validation && exercise.validation.correctAnswer) {
            if (typeof exercise.validation.correctAnswer === "string") {
              return exercise.validation.correctAnswer;
            } else if (typeof exercise.validation.correctAnswer === "object") {
              return exercise.validation.correctAnswer.text || "";
            }
          }
          break;

        case "mcq1":
          if (exercise.validation && exercise.validation.correctAnswer) {
            return exercise.validation.correctAnswer;
          }
          break;

        case "arrange":
        case "shuffle_words":
          if (exercise.validation && exercise.validation.correctAnswer) {
            return exercise.validation.correctAnswer;
          }
          break;

        // For other exercise types, check if there's a correctAnswer in the content
        default:
          if (exercise.correctAnswer !== undefined) {
            return exercise.correctAnswer;
          }
          break;
      }

      // If no specific handler found, attempt some fallbacks
      if (exercise.correctAnswer !== undefined) {
        return exercise.correctAnswer;
      }

      console.warn(
        `Unable to determine correct answer for exercise type: ${exercise.type}`,
        exercise
      );
      return "";
    };

    // Get the correct answer for this exercise
    const correctAnswerValue = getCorrectAnswer(currentExercise);

    // Log the extracted answer for debugging
    console.log("Extracted correct answer:", {
      exerciseType: currentExercise.type,
      correctAnswerValue,
      rawCorrectAnswer: currentExercise.validation?.correctAnswer,
      userSelection: optionChosen,
    });

    // Improved correctness check with normalized comparison
    const userAnswer = normalizeAnswer(optionChosen);
    const correctAnswer = normalizeAnswer(correctAnswerValue);
    const isCorrect = userAnswer === correctAnswer;

    console.log("Answer check:", {
      userAnswer,
      correctAnswer,
      isCorrect,
      original: { user: optionChosen, correct: correctAnswerValue },
    });

    // Store result for review
    setExerciseResults((prev) => ({
      ...prev,
      [currentExercise.id]: {
        exerciseId: currentExercise.id,
        type: currentExercise.type,
        question:
          currentExercise.question ||
          currentExercise.targetWord ||
          currentExercise.content?.question ||
          "",
        correctAnswer: correctAnswerValue,
        userAnswer: optionChosen,
        isCorrect,
      },
    }));

    // Update analytics data with correct answer status
    setAnalyticsData((prev) => {
      const exerciseType = currentExercise.type;
      const xpEarned = calculateXP(exerciseType, isCorrect);

      // Count exercise types
      const exerciseTypes = { ...prev.exerciseTypes };
      exerciseTypes[exerciseType] = (exerciseTypes[exerciseType] || 0) + 1;

      // Track vocabulary
      const newVocabulary = trackVocabulary(currentExercise);
      const updatedVocabulary = [...prev.vocabularyLearned];
      newVocabulary.forEach((word) => {
        // Check if word is already in the list
        if (!updatedVocabulary.some((v) => v.word === word.word)) {
          updatedVocabulary.push(word);
        }
      });

      // Update exercise results for review
      const exerciseResults = {
        ...prev.exerciseResults,
        [currentExercise.id]: {
          exerciseId: currentExercise.id,
          type: currentExercise.type,
          question:
            currentExercise.question ||
            currentExercise.targetWord ||
            currentExercise.content?.question ||
            "",
          correctAnswer: correctAnswerValue,
          userAnswer: optionChosen,
          isCorrect,
        },
      };

      // Debug the analytics update
      console.log("Updating analytics:", {
        prevCorrectAnswers: prev.correctAnswers,
        newCorrectAnswers: isCorrect
          ? prev.correctAnswers + 1
          : prev.correctAnswers,
        isCorrect,
      });

      return {
        ...prev,
        questionsAnswered: prev.questionsAnswered + 1,
        correctAnswers: isCorrect
          ? prev.correctAnswers + 1
          : prev.correctAnswers,
        incorrectAnswers: !isCorrect
          ? prev.incorrectAnswers + 1
          : prev.incorrectAnswers,
        attemptsUsed: prev.attemptsUsed + 1,
        totalXP: prev.totalXP + xpEarned,
        exerciseTypes,
        vocabularyLearned: updatedVocabulary,
        exerciseResults,
      };
    });

    // Track analytics events for the answer
    if (user?.uid) {
      // UPDATED: Using trackEvent from useSafeAnalytics

      // Track question answered
      trackEvent(
        isPracticeMode
          ? "practice_question_answered"
          : "lesson_question_answered",
        {
          exerciseType: currentExercise.type,
          exerciseId: currentExercise.id,
          isCorrect,
        }
      );

      // Track correct/incorrect answer
      trackEvent(isCorrect ? "correct_answer" : "incorrect_answer", {
        exerciseType: currentExercise.type,
        exerciseId: currentExercise.id,
        skillId: currentExercise.skill,
        lessonId: currentExercise.lesson,
        mode: isPracticeMode ? "practice" : "lesson",
      });

      // Track attempt used
      trackEvent("attempt_used", {
        context: isCorrect ? "correct_answer" : "incorrect_answer",
        remainingAttempts: attempts.count - 1,
        mode: isPracticeMode ? "practice" : "lesson",
      });
    }

    // Play the appropriate sound
    if (isCorrect && correctSoundRef.current) {
      correctSoundRef.current.play();
    } else if (!isCorrect && incorrectSoundRef.current) {
      incorrectSoundRef.current.play();
    }

    // Make sure we're passing our isCorrect determination to the checkQuestion function
    return checkQuestion({
      currentExercise,
      optionChosen,
      isCorrect, // Pass our determined isCorrect value
      setFeedback,
      setScore,
      deductAttempt,
      setOptionChosen,
    });
  };

  const handleSkipQuestion = () => {
    // Get current exercise for tracking
    const currentExercise = learningMaterial[exerciseIndex];

    // Store result for review
    setExerciseResults((prev) => ({
      ...prev,
      [currentExercise.id]: {
        exerciseId: currentExercise.id,
        type: currentExercise.type,
        question: currentExercise.question || currentExercise.targetWord || "",
        correctAnswer: currentExercise.correctAnswer,
        userAnswer: "skipped",
        isCorrect: false,
        skipped: true,
      },
    }));

    // Update analytics data for skipped question
    setAnalyticsData((prev) => ({
      ...prev,
      questionsAnswered: prev.questionsAnswered + 1,
      attemptsUsed: prev.attemptsUsed + 1,
      incorrectAnswers: prev.incorrectAnswers + 1, // Count skips as incorrect
      exerciseResults: {
        ...prev.exerciseResults,
        [currentExercise.id]: {
          exerciseId: currentExercise.id,
          type: currentExercise.type,
          question:
            currentExercise.question || currentExercise.targetWord || "",
          correctAnswer: currentExercise.correctAnswer,
          userAnswer: "skipped",
          isCorrect: false,
          skipped: true,
        },
      },
    }));

    // Track skip in analytics
    if (user?.uid) {
      // UPDATED: Using trackEvent from useSafeAnalytics
      trackEvent("question_skipped", {
        exerciseType: currentExercise.type,
        exerciseId: currentExercise.id,
        skillId: currentExercise.skill,
        lessonId: currentExercise.lesson,
        mode: isPracticeMode ? "practice" : "lesson",
      });

      // Track attempt used
      trackEvent("attempt_used", {
        context: "skipped_question",
        remainingAttempts: attempts.count - 1,
        mode: isPracticeMode ? "practice" : "lesson",
      });
    }

    return skipQuestion({
      feedback,
      deductAttempt: deductAttemptWithNotification,
      setFeedback,
      setOptionChosen,
    });
  };

  // Updated nextExercise function with proper completion logic
  // Replace your current nextExercise function with this one
  const nextExercise = () => {
    // First, increment completed exercises counter
    const newCompletedCount = exercisesCompleted + 1;
    setExercisesCompleted(newCompletedCount);

    // KEY FIX: Check the current completed count BEFORE incrementing for decision making
    // Exercises are 0-indexed, so we need to check against (REQUIRED_EXERCISES - 1)
    const REQUIRED_EXERCISES = 12;

    console.log("Exercise progression:", {
      currentExerciseIndex: exerciseIndex,
      totalExercisesAvailable: learningMaterial.length,
      completedCount: newCompletedCount,
      requiredExercises: REQUIRED_EXERCISES,
    });

    // Check if this was the last exercise (index 11, which is the 12th exercise)
    // We use >= REQUIRED_EXERCISES to be safe
    if (newCompletedCount >= REQUIRED_EXERCISES) {
      console.log("All 12 exercises completed, showing summary");
      handleLessonCompletion();
      setLearningMaterialState("complete");
      return;
    }

    // Check if we've reached the end of available exercises
    if (exerciseIndex >= learningMaterial.length - 1) {
      console.log(
        "Reached end of available exercises, looping back to beginning"
      );
      setExerciseIndex(0);
      return;
    }

    // Normal case - move to the next exercise
    const nextIndex = exerciseIndex + 1;
    console.log(
      `Moving to exercise ${nextIndex + 1} of ${learningMaterial.length}`
    );
    setExerciseIndex(nextIndex);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Update handleNextExercise to use this function
  const handleNextExercise = () => {
    nextExercise();
  };

  const handleRightAnswer = () =>
    handleAnswer({
      setFeedback,
      setOptionChosen,
      handleNextExercise, // Pass the progression handler
    });

  const handleWrongAnswer = () =>
    handleAnswer({
      setFeedback,
      setOptionChosen,
      handleNextExercise, // Pass the same handler
    });

  const handleRightContinue = () =>
    handleContinue({
      setLoading,
      handleAnswer: handleRightAnswer, // Pass the configured handler
    });

  const handleWrongContinue = () =>
    handleContinue({
      type: "incorrect",
      setLoading,
      handleAnswer: handleWrongAnswer,
    });

  const handleOpenModal = () => openModal(setModal);

  const handlePracticeConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const resetQuizState = () => {
    // Reset UI state
    setFeedback(null);
    setOptionChosen(null);
    setHidden(false);
    setLoading(false);

    // Reset progress tracking
    setExercisesCompleted(0);
    setExerciseIndex(0);

    // Reset score - add this line
    setScore(0);

    // Reset learning material state
    setLearningMaterialState("quiz");

    // Reset analytics data - add this for consistency
    setAnalyticsData({
      startTime: new Date(),
      questionsAnswered: 0,
      correctAnswers: 0,
      incorrectAnswers: 0,
      attemptsUsed: 0,
      vocabularyLearned: [],
      totalXP: 0,
      exerciseTypes: {},
      exerciseResults: {},
    });

    console.log("Quiz state has been reset (including score)");
  };

  // Handle modal closing/exiting
  const handleExitQuiz = () => {
    // Reset state before navigating
    resetQuizState();
    navigate("/units");
  };

  // Modify your existing handleCancelPractice function
  const handleCancelPractice = () => {
    setShowConfirmationModal(false);
    resetQuizState();
    navigate("/units");
  };

  // Add useEffect to reset state when component loads
  useEffect(() => {
    // Reset all state when component mounts
    resetQuizState();

    return () => {
      // Also reset when component unmounts
      resetQuizState();
    };
  }, []);

  // Add cleanup in useEffect
  useEffect(() => {
    return () => {
      setLoading(false); // Reset loading on unmount
    };
  }, []);

  return (
    <LearningMaterialsContext.Provider
      value={{
        learningMaterialState,
        setLearningMaterialState,
        exercisesCompleted,
        score,
        setScore,
        currentSkill: learningMaterialId?.skill,
        currentLesson: learningMaterialId?.lesson,
        // Add analytics data to context so it can be used in completion summaries
        analyticsData,
        isPracticeMode,
      }}
    >
      <optionChosenContext.Provider value={{ optionChosen, setOptionChosen }}>
        <motion.div
          className="relative flex flex-col h-screen overflow-hidden"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.1 }}
        >
          {showConfirmationModal && (
            <PracticeConfirmationModal
              onClose={handleCancelPractice}
              onConfirm={handlePracticeConfirmation}
            />
          )}

          <div id="section-1" className="sticky top-0 z-30 w-full bg-white">
            {modal && <ExitModalDisplay onExit={handleExitQuiz} />}

            {!modal &&
              learningMaterialState === "quiz" &&
              learningMaterialState !== "complete" &&
              learningMaterialState !== "dialogue" && (
                <HeaderBar
                  handleOpenModal={handleOpenModal}
                  attempts={attempts}
                  isPracticeMode={isPracticeMode}
                />
              )}
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="flex justify-center">
              <div className="w-full max-w-2xl py-4">
                {loading ? (
                  <div className="flex justify-around items-center h-[625px]">
                    <BeatLoader color="#848717" loading={loading} size={15} />
                  </div>
                ) : (
                  <>
                    {learningMaterialState !== "complete" &&
                      learningMaterial.length > 0 && (
                        <div className="min-h-[625px] flex items-center justify-center">
                          <ExerciseRenderer
                            exerciseIndex={exerciseIndex}
                            learningMaterial={learningMaterial}
                          />
                        </div>
                      )}
                    {learningMaterialState === "complete" && !isPracticeMode ? (
                      <ExerciseSummary />
                    ) : learningMaterialState === "complete" &&
                      isPracticeMode ? (
                      <PracticeCompletionSummary />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Fixed footer for feedback - always at bottom */}
          <div className="sticky bottom-0 w-full bg-white z-20">
            {learningMaterialState === "dialogue" && <DialogueContainer />}

            {learningMaterialState !== "complete" &&
              learningMaterialState !== "dialogue" &&
              learningMaterialState === "quiz" && (
                <div className="w-full">
                  {!feedback ? (
                    <AnswerButtons
                      onSkip={handleSkipQuestion}
                      onCheck={handleCheckQuestion}
                      optionChosen={optionChosen}
                      hidden={hidden}
                    />
                  ) : feedback === "correct" ? (
                    <CorrectFeedback
                      loading={loading}
                      hovered={hovered}
                      setHovered={setHovered}
                      onContinue={handleRightContinue}
                      hidden={hidden}
                    />
                  ) : (
                    <IncorrectFeedback
                      loading={loading}
                      hovered={hovered}
                      setHovered={setHovered}
                      onContinue={handleWrongContinue}
                      hidden={hidden}
                    />
                  )}
                </div>
              )}
          </div>
        </motion.div>
      </optionChosenContext.Provider>
    </LearningMaterialsContext.Provider>
  );
};

export default QuizMultimode;
