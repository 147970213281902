import React from "react";
import { useStorageImage } from "../../hooks/useStorageImage";

const ProductCard = ({ product, balance, onPurchase }) => {
  const { url: imageUrl, loading: imageLoading } = useStorageImage(
    product.imagePath
  );

  return (
    <div className="bg-white shadow-md overflow-hidden transition-transform hover:transform hover:-translate-y-1 hover:shadow-lg flex flex-col border-2 border-b-4 border-darkgray h-full min-h-[350px]">
      <div className="h-36 sm:h-48 overflow-hidden">
        {imageLoading ? (
          <div className="animate-pulse flex items-center justify-center h-full w-full bg-gray">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 sm:h-12 sm:w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt={product.name}
            className="h-full w-full object-cover"
          />
        ) : (
          <div className="flex items-center justify-center h-full w-full bg-gray text-darkgray">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 sm:h-16 sm:w-16"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="p-3 sm:p-4 flex flex-col flex-grow">
        <div className="flex-grow">
          <h3 className="font-bold text-base sm:text-lg mb-1 text-gray-800">
            {product.name}
          </h3>
          <p className="text-gray-600 text-xs sm:text-sm mb-3 line-clamp-2">
            {product.description}
          </p>
          <div className="flex items-center mb-4 gap-1">
            <span className="text-outline-black text-sm sm:text-base">🟠</span>
            <span className="font-bold text-gray-800 text-sm sm:text-base">
              {product.price}
            </span>
          </div>

          {/* Show limited time badge if applicable */}
          {product.limited && product.endDate && (
            <div className="mb-2">
              <span className="bg-red-100 text-red text-xs font-semibold px-2 py-1 rounded-full">
                LIMITED TIME
              </span>
              <span className="text-xs text-gray ml-1">
                Ends {new Date(product.endDate).toLocaleDateString()}
              </span>
            </div>
          )}

          {/* Show uses if applicable */}
          {product.maxUses && (
            <div className="mb-2 text-xs text-gray">
              {product.maxUses} use{product.maxUses > 1 ? "s" : ""}
            </div>
          )}

          {/* Show expiry if applicable */}
          {product.expiresAfter && (
            <div className="mb-2 text-xs text-gray">
              Valid for {product.expiresAfter} days
            </div>
          )}
        </div>

        <button
          className={`w-full py-2 px-3 sm:px-4 border-2 border-b-4 border-black font-medium mt-2 text-sm sm:text-base ${
            balance >= product.price
              ? "bg-green text-white hover:bg-greentwo transition-colors"
              : "bg-darkgray text-black cursor-not-allowed"
          }`}
          onClick={() => balance >= product.price && onPurchase(product.id)}
          disabled={balance < product.price}
        >
          {balance >= product.price ? "Purchase" : "Not enough coins"}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
