import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../../../helpers/BookingsContext";
import { useNotifications } from "../../../helpers/NotificationsContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils";

// Keep track of which bookings have been processed across renders
const PROCESSED_BOOKINGS = new Set();

const BookingSuccess = () => {
  const navigate = useNavigate();
  const { refreshBookings } = useBooking();
  const { addNotification } = useNotifications();
  const [isProcessing, setIsProcessing] = useState(true);
  const [statusMessage, setStatusMessage] = useState(
    "Processing your booking..."
  );
  const [error, setError] = useState(null);
  const [checkAttempts, setCheckAttempts] = useState(0);
  const [bookingData, setBookingData] = useState(null);

  // Use a ref to track if notification has been sent to prevent duplicates
  const notificationSentRef = useRef(false);

  // Track if the component is mounted
  const isMountedRef = useRef(true);

  // Track this component's mount count
  const uniqueInstanceId = useRef(
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  );

  // Maximum number of attempts to check
  const MAX_ATTEMPTS = 10;

  useEffect(() => {
    console.log(
      `[BookingSuccess ${uniqueInstanceId.current}] Component mounted`
    );

    isMountedRef.current = true;

    return () => {
      console.log(
        `[BookingSuccess ${uniqueInstanceId.current}] Component unmounting`
      );
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    console.log(
      `[BookingSuccess ${uniqueInstanceId.current}] Running booking processing effect`
    );

    // Get the booking ID from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    console.log(
      `[BookingSuccess ${uniqueInstanceId.current}] URL search params:`,
      Object.fromEntries(urlParams.entries())
    );

    // Try multiple possible parameter names that PayFast might use
    const bookingId =
      urlParams.get("booking_id") ||
      urlParams.get("m_payment_id") ||
      urlParams.get("custom_str1") ||
      sessionStorage.getItem("pendingBookingId");

    console.log(
      `[BookingSuccess ${uniqueInstanceId.current}] Retrieved booking ID:`,
      bookingId
    );

    if (!bookingId) {
      setError(
        "No booking ID found. Please check your booking status in the dashboard."
      );
      setIsProcessing(false);
      return;
    }

    // Check the global set to avoid processing the same booking twice
    if (PROCESSED_BOOKINGS.has(bookingId)) {
      console.log(
        `[BookingSuccess ${uniqueInstanceId.current}] Booking ${bookingId} already processed by another instance`
      );
      setStatusMessage(
        "Your booking has been processed. Redirecting to dashboard..."
      );
      setIsProcessing(false);

      // Navigate to dashboard after a short delay
      setTimeout(() => {
        if (isMountedRef.current) {
          navigate("/booking/dashboard");
        }
      }, 2000);

      return;
    }

    // Mark this booking as being processed
    PROCESSED_BOOKINGS.add(bookingId);

    // Check if we've already processed this booking in a previous session
    const storageKey = `booking_processed_${bookingId}`;
    if (localStorage.getItem(storageKey)) {
      console.log(
        `[BookingSuccess ${uniqueInstanceId.current}] This booking has already been processed in a previous session`
      );
      setStatusMessage(
        "Your booking has been processed. Redirecting to dashboard..."
      );
      setIsProcessing(false);

      // Navigate to dashboard after a short delay
      setTimeout(() => {
        if (isMountedRef.current) {
          navigate("/booking/dashboard");
        }
      }, 2000);

      return;
    }

    // Clear from session storage if it exists there
    sessionStorage.removeItem("pendingBookingId");

    // Function to check booking status directly in Firestore (read-only)
    const checkBookingStatus = async () => {
      try {
        // Skip if component unmounted
        if (!isMountedRef.current) return;

        // Skip if we already sent a notification
        if (notificationSentRef.current) {
          console.log(
            `[BookingSuccess ${uniqueInstanceId.current}] Notification already sent, skipping status check`
          );
          return;
        }

        console.log(
          `[BookingSuccess ${
            uniqueInstanceId.current
          }] Checking booking status for: ${bookingId}, attempt: ${
            checkAttempts + 1
          }`
        );

        // Get the booking document directly from Firestore
        const bookingRef = doc(db, "bookings", bookingId);
        const bookingSnap = await getDoc(bookingRef);

        if (!bookingSnap.exists()) {
          setError("Booking not found. Please check your dashboard.");
          setIsProcessing(false);
          return;
        }

        const data = bookingSnap.data();
        setBookingData({ id: bookingId, ...data }); // Store the booking data with ID
        console.log(
          `[BookingSuccess ${uniqueInstanceId.current}] Booking status:`,
          data.status
        );

        // Check the booking status
        if (data.status === "upcoming" && data.paymentConfirmed) {
          // Payment is confirmed and processed successfully
          setStatusMessage(
            "Payment confirmed! Your booking has been completed successfully."
          );

          // Only send notification if it hasn't been sent during this component lifecycle
          if (!notificationSentRef.current) {
            console.log(
              `[BookingSuccess ${uniqueInstanceId.current}] Sending payment confirmation notification`
            );

            // Generate a specific booking notification key
            const notificationKey = `notification_payment_confirmed_${bookingId}`;

            // Only send if we haven't sent this specific notification before
            if (!localStorage.getItem(notificationKey)) {
              // Send a payment confirmation notification
              await addNotification({
                title: "Payment Confirmed",
                message: `Your payment for the session with ${
                  data.coachName || "your coach"
                } has been confirmed.`,
                type: "payment_received",
                data: { bookingId },
              });

              // Mark as sent in localStorage
              localStorage.setItem(notificationKey, "true");
            } else {
              console.log(
                `[BookingSuccess ${uniqueInstanceId.current}] Payment notification already sent for this booking`
              );
            }

            // Mark as sent using the ref
            notificationSentRef.current = true;

            // Also mark as processed in localStorage to prevent future processing
            localStorage.setItem(storageKey, "true");
          }

          // Try to refresh bookings, but don't fail if it errors
          try {
            await refreshBookings();
          } catch (refreshError) {
            console.error(
              `[BookingSuccess ${uniqueInstanceId.current}] Error refreshing bookings:`,
              refreshError
            );
            // Continue even if this fails
          }

          // Only update state if still mounted
          if (isMountedRef.current) {
            setIsProcessing(false);

            // Navigate to dashboard after a short delay
            setTimeout(() => {
              if (isMountedRef.current) {
                navigate("/booking/dashboard");
              }
            }, 3000);
          }
        } else if (data.status === "payment_failed") {
          // Payment failed
          setError(
            data.paymentFailureReason || "Your payment could not be processed."
          );

          // Only send notification if it hasn't been sent
          if (!notificationSentRef.current) {
            // Generate a specific booking notification key
            const notificationKey = `notification_payment_failed_${bookingId}`;

            // Only send if we haven't sent this specific notification before
            if (!localStorage.getItem(notificationKey)) {
              // Send a payment failed notification
              await addNotification({
                title: "Payment Failed",
                message: `There was an issue with your payment: ${
                  data.paymentFailureReason || "Unknown error"
                }`,
                type: "payment_failed",
                data: { bookingId },
              });

              // Mark as sent in localStorage
              localStorage.setItem(notificationKey, "true");
            } else {
              console.log(
                `[BookingSuccess ${uniqueInstanceId.current}] Payment failed notification already sent for this booking`
              );
            }

            // Mark as sent using the ref
            notificationSentRef.current = true;

            // Also mark as processed in localStorage
            localStorage.setItem(storageKey, "true");
          }

          // Try to refresh bookings, ignore errors
          try {
            await refreshBookings();
          } catch (e) {
            console.error(
              `[BookingSuccess ${uniqueInstanceId.current}] Error refreshing bookings:`,
              e
            );
          }

          if (isMountedRef.current) {
            setIsProcessing(false);
          }
        } else if (data.status === "pending_payment") {
          // Payment is still pending
          if (checkAttempts < MAX_ATTEMPTS) {
            // Update message to show progress
            if (checkAttempts > 2) {
              setStatusMessage(
                "Still processing your payment. This may take a few moments..."
              );
            }
            if (checkAttempts > 5) {
              setStatusMessage(
                "Payment is being verified. Please be patient..."
              );
            }

            // Keep checking if component is still mounted
            if (isMountedRef.current) {
              setCheckAttempts((prev) => prev + 1);
              setTimeout(checkBookingStatus, 2000);
            }
          } else {
            // We've reached maximum attempts
            setStatusMessage(
              "Your payment is still being processed. You'll be able to view your booking in the dashboard once processing is complete."
            );

            try {
              await refreshBookings();
            } catch (e) {
              console.error(
                `[BookingSuccess ${uniqueInstanceId.current}] Error refreshing bookings:`,
                e
              );
            }

            if (isMountedRef.current) {
              setIsProcessing(false);

              setTimeout(() => {
                if (isMountedRef.current) {
                  navigate("/booking/dashboard");
                }
              }, 3000);
            }
          }
        } else {
          // Any other status
          try {
            await refreshBookings();
          } catch (e) {
            console.error(
              `[BookingSuccess ${uniqueInstanceId.current}] Error refreshing bookings:`,
              e
            );
          }

          if (isMountedRef.current) {
            setIsProcessing(false);
            setStatusMessage(
              `Booking status: ${data.status}. You'll be redirected to the dashboard.`
            );

            setTimeout(() => {
              if (isMountedRef.current) {
                navigate("/booking/dashboard");
              }
            }, 3000);
          }
        }
      } catch (error) {
        console.error(
          `[BookingSuccess ${uniqueInstanceId.current}] Error checking booking status:`,
          error
        );
        if (isMountedRef.current) {
          setError(
            "Error checking your booking status. Please check your dashboard."
          );
          setIsProcessing(false);
        }
      }
    };

    // Start checking with a small delay
    const timeoutId = setTimeout(checkBookingStatus, 1000);

    // Clean up function to prevent state updates after unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, refreshBookings, addNotification]);

  // Separate effect for handling check attempts to avoid re-running the main effect
  useEffect(() => {
    if (
      checkAttempts > 0 &&
      checkAttempts < MAX_ATTEMPTS &&
      isProcessing &&
      !notificationSentRef.current
    ) {
      const timeoutId = setTimeout(() => {
        if (isMountedRef.current) {
          // Only check booking status if we're still processing and haven't sent a notification
          const checkBookingStatus = async () => {
            // Implementation same as above
            // This is a simplified placeholder for the actual implementation
            console.log(
              `[BookingSuccess ${uniqueInstanceId.current}] Checking attempt ${
                checkAttempts + 1
              }`
            );

            // Here you would implement the actual checking logic
            // This is just a stub to demonstrate the pattern
          };

          checkBookingStatus();
        }
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [checkAttempts, isProcessing]);

  return (
    <div className="p-4 sm:p-8 max-w-md mx-auto mt-6 sm:mt-12 bg-white rounded-lg shadow border-2 border-darkgray">
      <div className="text-center">
        {isProcessing ? (
          <>
            <div className="inline-flex items-center justify-center w-16 h-16 bg-green bg-opacity-20 rounded-full mb-4">
              <span className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green"></span>
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
              Processing Your Booking
            </h2>
            <p className="text-gray-600 text-sm sm:text-base">
              {statusMessage}
            </p>
            {checkAttempts > 5 && (
              <p className="text-xs sm:text-sm text-gray-500 mt-4">
                Attempt {checkAttempts} of {MAX_ATTEMPTS}
              </p>
            )}
          </>
        ) : error ? (
          <>
            <div className="inline-flex items-center justify-center w-16 h-16 bg-red bg-opacity-20 rounded-full mb-4">
              <span className="text-red text-2xl">!</span>
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
              There was an issue
            </h2>
            <p className="text-gray-600 text-sm sm:text-base">{error}</p>
            <button
              onClick={() => navigate("/booking/dashboard")}
              className="mt-6 px-4 py-2 bg-green text-white rounded-md hover:bg-opacity-90"
            >
              Go to Dashboard
            </button>
          </>
        ) : bookingData?.status === "pending_payment" ? (
          <>
            <div className="inline-flex items-center justify-center w-16 h-16 bg-gold bg-opacity-20 rounded-full mb-4">
              <span className="text-gold text-2xl">⏱</span>
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
              Payment Processing
            </h2>
            <p className="text-gray-600 text-sm sm:text-base">
              {statusMessage}
            </p>
            <button
              onClick={() => navigate("/booking/dashboard")}
              className="mt-6 px-4 py-2 bg-green text-white rounded-md hover:bg-opacity-90"
            >
              Go to Dashboard
            </button>
          </>
        ) : (
          <>
            <div className="inline-flex items-center justify-center w-16 h-16 bg-green bg-opacity-20 rounded-full mb-4">
              <span className="text-green text-2xl">✓</span>
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-gray mb-2">
              Payment Successful!
            </h2>
            <p className="text-gray-600 text-sm sm:text-base">
              Your booking has been confirmed. You will be redirected to your
              dashboard shortly.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default BookingSuccess;
