import React, { createContext, useState, useEffect } from "react";

// Create a more robust score context without the circular dependency
export const ScoreContext = createContext({
  score: 0,
  setScore: () => {},
  resetScore: () => {},
  getScorePercentage: () => 0,
  scoreHistory: {},
  recordScore: () => {},
});

export const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [scoreHistory, setScoreHistory] = useState({});

  // Function to explicitly reset the score
  const resetScore = () => {
    setScore(0);
  };

  // Function to record a score with a specific key
  const recordScore = (lessonKey) => {
    if (!lessonKey || score === 0) return;

    setScoreHistory((prev) => ({
      ...prev,
      [lessonKey]: score,
    }));

    // Optionally reset the score after recording
    setScore(0);
  };

  // Calculate percentage score based on total exercises (default 12)
  const getScorePercentage = (totalExercises = 12) => {
    const percentage = (score / Math.max(totalExercises, 1)) * 100;
    return Math.min(Math.max(0, percentage), 100);
  };

  return (
    <ScoreContext.Provider
      value={{
        score,
        setScore,
        resetScore,
        getScorePercentage,
        scoreHistory,
        recordScore,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};
