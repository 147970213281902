import React, { useState, useEffect } from "react";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Footer from "../../components/footer/footer.component";
import { motion } from "framer-motion";
import { PuffLoader } from "react-spinners";
import { Link } from "react-router-dom";
import FeedbackMenu from "../../components/support/feedback-menu";

// Pricing tiers data with all the features organized
const pricingData = [
  {
    name: "George",
    description: "Perfect for beginners",
    price: "FREE",
    frequency: "",
    color: "green",
    path: "/early-access",
    btnText: "Start for Free",
    popular: false,
    features: {
      content: {
        title: "Content",
        features: [
          { name: "Dictionary Searches", value: "20/day" },
          { name: "Lesson Mode", value: true },
          { name: "Practice Mode", value: true },
          { name: "Story Mode", value: false },
          { name: "Vocabulary Lists", value: false },
          { name: "Teardowns", value: false },
          { name: "Grammar Guides", value: false },
        ],
      },
      learning: {
        title: "Learning Enhancements",
        features: [
          { name: "Content Updates", value: "Once a month" },
          { name: "Flashcards", value: "Limited" },
          { name: "Audio", value: false },
          { name: "Translations", value: false },
          { name: "Offline Mode", value: false },
        ],
      },
      engagement: {
        title: "Engagement Systems",
        features: [
          { name: "Ads", value: true },
          { name: "Streaks", value: true },
          { name: "Lives", value: "5/day" },
          // { name: "Achievements", value: "Basic" },
          { name: "Trophies", value: "Limited" },
          { name: "Coins", value: "Basic earning" },
        ],
      },
      premium: {
        title: "Premium Services",
        features: [
          { name: "Language Coaching Sessions", value: false },
          { name: "Analytics and Reporting", value: false },
          { name: "Support Level", value: "Email" },
          { name: "Personalized Learning", value: false },
          // { name: "Community Access", value: "Read only" },
          { name: "Custom Content", value: false },
        ],
      },
    },
  },
  {
    name: "Mabena",
    description: "Our most popular choice",
    price: "247",
    currency: "R",
    frequency: "/month",
    color: "gold",
    path: "/early-access",
    btnText: "Get Started",
    popular: true,
    features: {
      content: {
        title: "Content",
        features: [
          { name: "Dictionary Searches", value: "50/day" },
          { name: "Lesson Mode", value: true },
          { name: "Practice Mode", value: true },
          { name: "Story Mode", value: true },
          { name: "Vocabulary Lists", value: true },
          { name: "Teardowns", value: true },
          { name: "Grammar Guides", value: true },
        ],
      },
      learning: {
        title: "Learning Enhancements",
        features: [
          { name: "Content Updates", value: "Every 2 weeks" },
          { name: "Flashcards", value: true },
          { name: "Audio", value: true },
          { name: "Translations", value: true },
          { name: "Offline Mode", value: false },
        ],
      },
      engagement: {
        title: "Engagement Systems",
        features: [
          { name: "Ads", value: false },
          { name: "Streaks", value: true },
          { name: "Lives", value: "10/day" },
          // { name: "Achievements", value: true },
          { name: "Trophies", value: true },
          { name: "Coins", value: "2x earning" },
        ],
      },
      premium: {
        title: "Premium Services",
        features: [
          { name: "Language Coaching Sessions", value: "1/month" },
          { name: "Analytics and Reporting", value: "Basic" },
          { name: "Support Level", value: "Priority Email" },
          { name: "Personalized Learning", value: false },
          // { name: "Community Access", value: true },
          { name: "Custom Content", value: false },
        ],
      },
    },
  },
  {
    name: "Morena",
    description: "For serious language learners",
    price: "597",
    currency: "R",
    frequency: "/month",
    color: "red",
    path: "/early-access",
    btnText: "Go Premium",
    popular: false,
    features: {
      content: {
        title: "Content",
        features: [
          { name: "Dictionary Searches", value: "Unlimited" },
          { name: "Lesson Mode", value: true },
          { name: "Practice Mode", value: true },
          { name: "Story Mode", value: true },
          { name: "Vocabulary Lists", value: true },
          { name: "Teardowns", value: true },
          { name: "Grammar Guides", value: true },
        ],
      },
      learning: {
        title: "Learning Enhancements",
        features: [
          { name: "Content Updates", value: "Weekly" },
          { name: "Flashcards", value: true },
          { name: "Audio", value: true },
          { name: "Translations", value: true },
          { name: "Offline Mode", value: true },
        ],
      },
      engagement: {
        title: "Engagement Systems",
        features: [
          { name: "Ads", value: false },
          { name: "Streaks", value: true },
          { name: "Lives", value: "Unlimited" },
          // { name: "Achievements", value: true },
          { name: "Trophies", value: true },
          { name: "Coins", value: "3x earning" },
        ],
      },
      premium: {
        title: "Premium Services",
        features: [
          { name: "Language Coaching Sessions", value: "4/month" },
          { name: "Analytics and Reporting", value: "Advanced" },
          { name: "Support Level", value: "24/7 Chat" },
          { name: "Personalized Learning", value: "Advanced" },
          // { name: "Community Access", value: "Priority" },
          { name: "Custom Content", value: true },
        ],
      },
    },
  },
];

// FAQ data
const faqData = [
  {
    question: "Can I switch plans later?",
    answer:
      "Yes, you can upgrade or downgrade your plan at any time. Changes will be applied at the start of your next billing cycle.",
  },
  {
    question: "Do you offer refunds?",
    answer:
      "We offer a 14-day money-back guarantee for all paid plans. If you're not satisfied, you can request a full refund within this period.",
  },
  {
    question: "What languages are available?",
    answer:
      "We currently offer courses in isiZulu, but depending on demand we are able to offer paid language coaching services for isiXhosa, Sesotho, and several other African languages. We're constantly adding more languages to our platform.",
  },
  {
    question: "How do Language Coaching Sessions work?",
    answer:
      "Language Coaching Sessions allow you to schedule one-on-one virtual sessions with native speakers and language experts. These sessions are tailored to your learning needs and provide personalized guidance to give you opportunities to build conversational skills.",
  },
];

// PricingCard Component
const PricingCard = ({ tier, isActive, setActive }) => {
  const getColorClass = (color) => {
    switch (color) {
      case "red":
        return { bg: "bg-red", text: "text-red", border: "border-red" };
      case "gold":
        return { bg: "bg-gold", text: "text-gold", border: "border-gold" };
      case "green":
        return { bg: "bg-green", text: "text-green", border: "border-green" };
      default:
        return { bg: "bg-gray", text: "text-gray", border: "border-gray" };
    }
  };

  const colors = getColorClass(tier.color);

  return (
    <motion.div
      className={`relative bg-white rounded-md shadow-lg border-2 border-b-4 ${
        tier.popular ? colors.border : "border-darkgray"
      } p-6 flex flex-col h-full ${
        tier.popular ? "md:-mt-4 md:pt-10 md:pb-10 z-10" : ""
      } ${isActive ? "block" : "md:block hidden"}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        delay: tier.popular ? 0 : 0.2,
        type: "spring",
        stiffness: 100,
      }}
      whileHover={{
        y: -5,
        transition: { type: "spring", stiffness: 300 },
      }}
      onClick={() => setActive(tier.name)}
    >
      {tier.popular && (
        <div
          className={`absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${colors.bg} text-white px-4 py-1 rounded-full text-sm font-bold`}
        >
          Most Popular
        </div>
      )}

      <div className="mb-4">
        <h3 className={`text-2xl font-bold font-headers ${colors.text}`}>
          {tier.name}
        </h3>
        <p className="text-gray mt-1">{tier.description}</p>
      </div>

      <div className="mb-6">
        <div className="flex items-baseline">
          <span className={`text-4xl font-bold ${colors.text}`}>
            {tier.currency}
            {tier.price}
          </span>
          <span className="text-gray ml-1">{tier.frequency}</span>
        </div>
      </div>

      {/* Feature highlights - just a few key features */}
      <div className="mb-6 flex-grow">
        <ul className="space-y-3">
          {Object.values(tier.features)
            .flatMap(
              (category) =>
                category.features
                  .filter(
                    (feature) =>
                      // Show only key features that are true or have special values
                      feature.value === true ||
                      (typeof feature.value === "string" &&
                        feature.value !== "false" &&
                        feature.value !== "Limited" &&
                        feature.value !== "Basic")
                  )
                  .slice(0, 2) // Take just 2 key features from each category
            )
            .slice(0, 5)
            .map((feature, index) => (
              <li key={index} className="flex items-start">
                <div
                  className={`h-5 w-5 mr-2 flex-shrink-0 ${colors.bg} rounded-sm rotate-45 mt-0.5`}
                >
                  <svg
                    className="w-5 h-5 text-white -rotate-45"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <span className="text-sm">
                  {feature.name}{" "}
                  {typeof feature.value === "string" && feature.value !== "true"
                    ? `(${feature.value})`
                    : ""}
                </span>
              </li>
            ))}
        </ul>
      </div>

      <motion.a
        href={tier.path}
        className={`block w-full text-center py-3 px-4 rounded-md ${colors.border} border-2 border-b-4 ${colors.text} hover:${colors.bg} hover:text-white transition-colors duration-300 font-bold`}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.97 }}
      >
        {tier.btnText}
      </motion.a>
    </motion.div>
  );
};

// Feature Value Component - displays whether a feature is included and how
const FeatureValue = ({ value, color }) => {
  const getColorClass = (color) => {
    switch (color) {
      case "red":
        return { bg: "bg-red", text: "text-red", border: "border-red" };
      case "gold":
        return { bg: "bg-gold", text: "text-gold", border: "border-gold" };
      case "green":
        return { bg: "bg-green", text: "text-green", border: "border-green" };
      default:
        return { bg: "bg-gray", text: "text-gray", border: "border-gray" };
    }
  };

  const colors = getColorClass(color);

  if (value === true) {
    return (
      <div className="flex justify-center">
        <div
          className={`h-5 w-5 ${colors.bg} rounded-sm rotate-45 flex items-center justify-center`}
        >
          <svg
            className="w-4 h-4 text-white -rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    );
  } else if (value === false) {
    return (
      <div className="flex justify-center">
        <div className="h-5 w-5 bg-gray/20 rounded-sm rotate-45 flex items-center justify-center">
          <svg
            className="w-4 h-4 text-gray -rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center">
        <span
          className={`text-sm font-medium text-center ${
            value === "Limited" || value === "Basic" ? "text-gray" : colors.text
          }`}
        >
          {value}
        </span>
      </div>
    );
  }
};

// FAQ Item Component
const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="border-b border-darkgray/30 last:border-b-0"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1, duration: 0.5 }}
    >
      <button
        className="flex justify-between items-center w-full py-4 text-left font-medium hover:text-red transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-headers">{question}</span>
        <div
          className={`h-6 w-6 bg-gold rounded-sm rotate-45 flex items-center justify-center transition-transform duration-300 ${
            isOpen ? "rotate-[225deg]" : "rotate-45"
          }`}
        >
          <svg
            className="w-4 h-4 text-white -rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <motion.div
        className="overflow-hidden"
        initial={false}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <p className="pb-4 text-gray-700">{answer}</p>
      </motion.div>
    </motion.div>
  );
};

function Pricing() {
  // State for mobile tier selection
  const [activeTier, setActiveTier] = useState("Mabena");

  // State for FAQ accordion
  const [expandedFaq, setExpandedFaq] = useState(null);

  useEffect(() => {
    document.title = "Pricing | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className="bg-red">
        <NavBarPrimary />
        <FeedbackMenu />
      </div>

      {/* Hero Section */}
      <div className="flex flex-row justify-start items-center p-16 w-full bg-gradient-to-b from-red to-black">
        <div className="flex flex-col justify-center items-center w-full max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-white font-headers sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl uppercase font-bold mb-4">
              Choose Your Learning Journey
            </h1>
            <p className="text-white text-lg">
              Select the perfect plan to accelerate your African language
              fluency
            </p>
          </motion.div>
        </div>
      </div>

      {/* Pricing Section */}
      <section className="py-16 px-4">
        <div className="max-w-6xl mx-auto w-5/6">
          {/* Mobile Tier Selector - Only visible on small screens */}
          <div className="flex justify-center mb-8 md:hidden">
            <div className="inline-flex border-2 border-darkgray rounded-lg overflow-hidden">
              {pricingData.map((tier) => (
                <button
                  key={tier.name}
                  className={`px-4 py-2 text-sm font-medium ${
                    activeTier === tier.name
                      ? `bg-${tier.color} text-white`
                      : "bg-white text-gray-700"
                  }`}
                  onClick={() => setActiveTier(tier.name)}
                >
                  {tier.name}
                </button>
              ))}
            </div>
          </div>

          {/* Pricing Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {pricingData.map((tier) => (
              <PricingCard
                key={tier.name}
                tier={tier}
                isActive={activeTier === tier.name}
                setActive={setActiveTier}
              />
            ))}
          </div>

          {/* Feature Comparison */}
          <motion.div
            className="mt-16 bg-white rounded-lg border-2 border-b-4 border-darkgray shadow-lg overflow-hidden"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <div className="p-6 bg-gradient-to-r from-red to-gold">
              <h2 className="text-2xl font-bold text-white font-headers text-center">
                Compare All Features
              </h2>
            </div>

            <div className="p-6">
              {/* Feature Categories */}
              {Object.entries(pricingData[0].features).map(
                ([categoryKey, category]) => (
                  <div key={categoryKey} className="mb-8">
                    <h3 className="text-xl font-bold mb-4 font-headers text-red border-b-2 border-darkgray/30 pb-2">
                      {category.title}
                    </h3>

                    <div className="grid grid-cols-4 gap-4">
                      {/* Column Headers */}
                      <div className="font-medium">Feature</div>
                      {pricingData.map((tier) => (
                        <div
                          key={tier.name}
                          className="text-center font-medium"
                        >
                          <span className={`text-${tier.color}`}>
                            {tier.name}
                          </span>
                        </div>
                      ))}

                      {/* Feature Rows */}
                      {category.features.map((feature, idx) => (
                        <React.Fragment key={idx}>
                          <div className="py-2 text-sm">{feature.name}</div>
                          {pricingData.map((tier) => (
                            <div
                              key={`${tier.name}-${feature.name}`}
                              className="py-2"
                            >
                              <FeatureValue
                                value={
                                  tier.features[categoryKey].features[idx].value
                                }
                                color={tier.color}
                              />
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-gradient-to-r from-red to-gold text-white">
        <div className="max-w-6xl mx-auto px-4 w-5/6">
          <h2 className="text-3xl font-bold font-headers text-center mb-12">
            What Our Learners Say
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Testimonial 1 */}
            <motion.div
              className="bg-white/20 backdrop-blur-sm rounded-md border-2 border-b-4 border-white p-6 relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{ y: -5 }}
            >
              <div className="absolute -top-5 left-6 w-10 h-10 bg-gold border-2 border-b-2 border-r-2 border-white rounded-sm rotate-45 flex items-center justify-center">
                <span className="text-xl -rotate-45">"</span>
              </div>
              <div className="pt-6">
                <p className="mb-6 italic">
                  "After just 3 months with Fast Fluency Africa, I can now
                  confidently have basic conversations in isiZulu. The
                  interactive lessons made learning enjoyable."
                </p>
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-sm rotate-45 bg-white/30 mr-3"></div>
                  <div>
                    <h4 className="font-bold">Jemimah K.</h4>
                    <p className="text-sm opacity-75">Mabena Plan Member</p>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Testimonial 2 */}
            <motion.div
              className="bg-white/20 backdrop-blur-sm rounded-md border-2 border-b-4 border-white p-6 relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              whileHover={{ y: -5 }}
            >
              <div className="absolute -top-5 left-6 w-10 h-10 bg-gold border-2 border-b-2 border-r-2 border-white rounded-sm rotate-45 flex items-center justify-center">
                <span className="text-xl -rotate-45">"</span>
              </div>
              <div className="pt-6">
                <p className="mb-6 italic">
                  "The casual approach that FFA has really creates a safe space
                  to make mistakes and actually learn much quicker than a formal
                  learning space."
                </p>
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-sm rotate-45 bg-white/30 mr-3"></div>
                  <div>
                    <h4 className="font-bold">Marchall P.</h4>
                    <p className="text-sm opacity-75">SASL Student</p>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Testimonial 3 */}
            <motion.div
              className="bg-white/20 backdrop-blur-sm rounded-md border-2 border-b-4 border-white p-6 relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ y: -5 }}
            >
              <div className="absolute -top-5 left-6 w-10 h-10 bg-gold border-2 border-b-2 border-r-2 border-white rounded-sm rotate-45 flex items-center justify-center">
                <span className="text-xl -rotate-45">"</span>
              </div>
              <div className="pt-6">
                <p className="mb-6 italic">
                  "I am grateful to have discovered this platform. It has
                  tremendously helped my daughter develop her vocabulary and
                  ability to converse in isiZulu. FFA is truly a great
                  platform."
                </p>
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-sm rotate-45 bg-white/30 mr-3"></div>
                  <div>
                    <h4 className="font-bold">Dumo N.</h4>
                    <p className="text-sm opacity-75">
                      Parent of Mabena Plan Member
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 bg-white">
        <div className="max-w-3xl mx-auto px-4 w-5/6">
          <h2 className="text-3xl font-bold font-headers text-center mb-12">
            Frequently Asked Questions
          </h2>

          <div className="bg-[#FCFCEE] rounded-lg border-2 border-b-4 border-darkgray shadow-lg p-6">
            {faqData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                index={index}
              />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-[#FCFCEE]">
        <div className="max-w-4xl mx-auto px-4 text-center w-5/6">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg border-2 border-b-4 border-green shadow-lg p-8"
          >
            <h2 className="text-2xl md:text-3xl font-bold font-headers mb-4">
              Ready to Start Your Language Journey?
            </h2>
            <p className="text-lg mb-8">
              Join thousands of learners mastering African languages with Fast
              Fluency Africa
            </p>

            <Link
              to="/early-access"
              className="inline-block py-3 px-8 bg-green text-white font-bold rounded-md border-2 border-b-4 border-darkgray shadow-lg"
            >
              Join the Waitlist
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Footer */}
      <div className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </div>
    </motion.div>
  );
}

export default Pricing;
