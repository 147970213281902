// components/articles/related-articles.jsx
import React from "react";
import { Link } from "react-router-dom";

const RelatedArticles = ({ articles }) => {
  if (!articles || articles.length === 0) {
    return <p className="text-gray text-sm">No related articles found</p>;
  }

  return (
    <ul className="space-y-2">
      {articles.map((article) => (
        <li
          key={article.id}
          className="border-b border-gray-200 pb-2 last:border-b-0"
        >
          <div className="flex items-center mb-2">
            {article.image && (
              <div className="mr-2 w-10 h-10 flex-shrink-0">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-full object-cover rounded"
                />
              </div>
            )}
            <Link
              to={`/articles/${article.slug}`}
              className="text-red hover:underline flex-grow"
            >
              {article.title}
            </Link>
          </div>
          <div className="text-xs text-gray-500">
            {new Date(article.publishDate).toLocaleDateString()} • ⏱️{" "}
            {article.readTime}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default RelatedArticles;
