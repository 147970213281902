import React, { useState, useEffect } from "react";
import ShopNavigation from "./shop-navigation.component";
import NavigationBar from "../navigation-bar-2/side-navigation-bar.component";
import FloatingActionMenu from "../navigation-bar-2/floating-side-menu";
import { Link } from "react-router-dom";
import { useShopWithNotifications } from "../../hooks/useShopWithNotifications";
import ActivePowerUps from "../power-ups/active-power-ups";
import InventoryItemCard from "./inventory-item-card.component";
import PowerUpManager from "../../services/power-ups/power-up-manager";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { RefreshCw } from "lucide-react";

const Inventory = () => {
  const {
    inventory,
    inventoryItemWithNotification,
    isLoading,
    refreshInventory,
  } = useShopWithNotifications();

  const [notification, setNotification] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const { user } = useAuth();
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    console.log(
      `Inventory component received ${inventory.length} items from context`
    );

    // Log a sample item if available
    if (inventory.length > 0) {
      console.log("Sample item from inventory state:", inventory[0]);
    }
  }, [inventory]);

  // Modify this one to not call refreshInventory
  useEffect(() => {
    const checkExpiredPowerUps = async () => {
      if (!user?.uid) return;

      try {
        // Check for any expired power-ups
        await PowerUpManager.checkAllExpiredPowerUps(user.uid);
        // No need to refresh inventory - the listener will update automatically
      } catch (error) {
        console.error("Error checking expired power-ups:", error);
      }
    };

    checkExpiredPowerUps();
  }, [user?.uid]);

  // Add this code before your return statement in Inventory.component.jsx
  useEffect(() => {
    // Log how many items are being filtered out
    const usedItems = inventory.filter((item) => item.isUsed === true);
    const unusedItems = inventory.filter((item) => item.isUsed === false);

    console.log(`Inventory has ${inventory.length} total items`);
    console.log(`Used items (filtered out): ${usedItems.length}`);
    console.log(`Unused items (displayed): ${unusedItems.length}`);

    // If all items are marked as used, show a warning
    if (inventory.length > 0 && unusedItems.length === 0) {
      console.warn(
        "⚠️ All inventory items are marked as used - nothing will display!"
      );
    }
  }, [inventory]);

  // Keep the manual refresh button for user convenience
  const handleManualRefresh = async () => {
    console.log("Manual refresh requested");
    setRefreshing(true);
    setNotification({
      type: "info",
      message: "Refreshing inventory...",
    });

    try {
      await refreshInventory();

      setNotification({
        type: "success",
        message: "Inventory refreshed!",
      });
    } catch (error) {
      setNotification({
        type: "error",
        message: "Failed to refresh inventory",
      });
    } finally {
      setRefreshing(false);

      setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
  };

  // Group inventory items by product type
  const groupedInventory = inventory.reduce((acc, item) => {
    // Log filtering decision
    console.log(
      `Processing item: ${item.name}, isUsed: ${item.isUsed}, including in display`
    );

    const existingGroup = acc.find(
      (group) => group.productId === item.productId
    );

    if (existingGroup) {
      existingGroup.count += 1;
      existingGroup.items.push(item);
    } else {
      acc.push({
        productId: item.productId,
        name: item.name,
        description: item.description,
        category: item.category || "other",
        imagePath: item.imagePath,
        count: 1,
        items: [item],
      });
    }

    return acc;
  }, []);

  // Get unique categories
  const categories = [
    "all",
    ...new Set(groupedInventory.map((group) => group.category)),
  ];

  // Filter items by category
  const filteredInventory = groupedInventory.filter((group) => {
    return selectedCategory === "all" || group.category === selectedCategory;
  });

  // Enhanced handleUseProduct function
  const handleUseProduct = async (inventoryItemId) => {
    try {
      console.log("Attempting to use inventory item:", inventoryItemId);

      // Show loading notification
      setNotification({
        type: "info",
        message: "Activating power-up...",
      });

      // Use the item through the shop context
      const result = await inventoryItemWithNotification(inventoryItemId);
      console.log("Inventory item use result:", result);

      // Show success or error notification
      setNotification({
        type: result.success ? "success" : "error",
        message: result.message,
      });

      // Clear notification after 3 seconds
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    } catch (error) {
      console.error("Error in handleUseProduct:", error);
      setNotification({
        type: "error",
        message: error.message || "An error occurred",
      });

      // Clear notification after 3 seconds
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    }
  };

  if (isLoading) {
    return (
      <>
        <NavigationBar />
        <ShopNavigation />
        <FloatingActionMenu />
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <NavigationBar />
      <ShopNavigation />
      <FloatingActionMenu />

      <div className="container mx-auto px-4 py-4 sm:py-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <h1 className="text-2xl sm:text-3xl font-bold text-black mb-3 sm:mb-0">
            My Items
          </h1>
          <button
            onClick={handleManualRefresh}
            disabled={refreshing}
            className="px-4 py-2 bg-gold text-white rounded-md hover:bg-[#b88700] transition-colors flex items-center"
          >
            <RefreshCw
              size={18}
              className={`mr-2 ${refreshing ? "animate-spin" : ""}`}
            />
            {refreshing ? "Refreshing..." : "Refresh Inventory"}
          </button>
        </div>

        {notification && (
          <div
            className={`mb-4 sm:mb-6 p-3 sm:p-4 rounded-lg ${
              notification.type === "success"
                ? "bg-green text-white"
                : notification.type === "info"
                ? "bg-blue-500 text-white"
                : "bg-red text-white"
            }`}
          >
            {notification.message}
          </div>
        )}

        {/* Active Power-Ups Section from new component */}
        <ActivePowerUps />

        {inventory.length > 0 && groupedInventory.length > 0 ? (
          <>
            <div className="overflow-x-auto -mx-4 px-4 pb-2 mb-4">
              <div className="flex flex-nowrap gap-2 min-w-max sm:flex-wrap">
                {categories.map((category) => (
                  <button
                    key={category}
                    className={`px-3 sm:px-4 py-2 border border-b-2 border-darkgray text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
                      selectedCategory === category
                        ? "bg-green text-white border-black border-2 border-b-4"
                        : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
              {filteredInventory.map((group) => (
                <InventoryItemCard
                  key={group.productId}
                  group={group}
                  onUse={handleUseProduct}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center py-8 sm:py-12 text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-14 w-14 sm:h-16 sm:w-16 mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              />
            </svg>
            <p className="text-base sm:text-lg mb-4">Your inventory is empty</p>
            <Link
              to="/shop"
              className="px-4 py-2 bg-red text-white border border-b-2 border-darkgray rounded font-medium hover:bg-[#932929] transition-colors"
            >
              Visit Shop
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Inventory;
