import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import { default as childemoji } from "../../../assets/emojis/child-soccer.png";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import PropTypes from "prop-types";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

const MarkCorrectMeaning = ({ data }) => {
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [sentenceAudioUrl, setSentenceAudioUrl] = useState("");
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { setFeedback } = useContext(feedbackContext);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Audio state
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio());

  // Apply audio settings when they change
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Create shuffled options combining correctAnswer and distractors
  useEffect(() => {
    if (!data) return;

    try {
      // Get the correct answer text from the validation object
      const correctAnswer = data.validation?.correctAnswer?.text || "go"; // Default fallback

      // Get distractors from content
      const distractors =
        data.content?.distractors?.map((d) => d.text || d) || [];

      // Create full options array and shuffle it
      const options = [correctAnswer, ...distractors].filter(Boolean);
      setShuffledOptions(options.sort(() => Math.random() - 0.5));
    } catch (error) {
      console.error("Error creating options:", error);
      setShuffledOptions([]);
    }
  }, [data]);

  // Fetch audio for the Zulu sentence
  useEffect(() => {
    const fetchAudio = async () => {
      if (!data) return;

      try {
        // Get the audio URL directly from validation.correctAnswer.audio.fullPhrase.audio
        if (data.validation?.correctAnswer?.audio?.fullPhrase?.audio) {
          const url = await getAudioDownloadUrl(
            data.validation.correctAnswer.audio.fullPhrase.audio
          );
          console.log("Fetched sentence audio:", url);
          setSentenceAudioUrl(url);

          // Set the URL on the audio element
          audioRef.current.src = url;
          audioRef.current.load();
        }
      } catch (error) {
        console.error("Error fetching audio:", error);
      }
    };

    fetchAudio();
  }, [data]);

  // Handle option selection
  const handleSelect = (option) => {
    // Always ensure we're setting a string value for validation
    setOptionChosen(option);
    setFeedback(null);

    // Debug
    console.log("Selected option:", option);
    console.log(
      "Correct answer (validation):",
      data.validation?.correctAnswer?.text || data.validation?.correctAnswer
    );
  };

  // Play audio for source sentence
  const handlePlaySentence = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (sentenceAudioUrl && audioRef.current) {
      // If already playing, pause it
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        return;
      }

      // Otherwise play the audio
      audioRef.current.currentTime = 0;

      audioRef.current.play().catch((err) => {
        console.error("Error playing audio:", err);
      });

      setIsPlaying(true);
    }
  };

  // Pause audio
  const handlePauseAudio = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  // If no data, show error
  if (!data) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  // Get correct answer for validation
  const correctAnswer = data.validation?.correctAnswer?.text || "go";

  return (
    <div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        {/* Exercise Header */}
        <h1 className="text-lg sm:text-xl font-bold text-left font-headers text-black mb-3 sm:mb-4">
          {data.content.question}
        </h1>

        {/* Source Sentence */}
        <div className="mb-3 sm:mb-4 p-3 sm:p-5 bg-white border-2 border-b-4 border-black relative">
          <p
            className={`text-base sm:text-lg font-medium cursor-pointer ${
              !audioEnabled ? "text-gray-500" : ""
            }`}
            onMouseEnter={audioEnabled ? handlePlaySentence : undefined}
            onMouseLeave={handlePauseAudio}
          >
            {data.content.sourceSentence}
            {sentenceAudioUrl && (
              <span
                className={`ml-2 text-xs ${
                  isPlaying ? "text-green" : "text-gray-400"
                }`}
              >
                {audioEnabled ? (isPlaying ? "🔊" : "🔈") : "🔇"}
              </span>
            )}
          </p>
          <div className="absolute -bottom-3 right-4 bg-white px-2 text-xs sm:text-sm text-green">
            {data.languageNote || ""}
          </div>

          {/* Audio disabled message */}
          {!audioEnabled && sentenceAudioUrl && (
            <div className="text-center text-xs text-gray-500 mt-2">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}
        </div>

        {/* Emoji/Image Section - reduced size for mobile */}
        <div className="p-2 sm:p-4 relative">
          <img
            alt="language-helper"
            src={childemoji}
            className="m-auto text-4xl text-outline-gray h-28 sm:h-40 cursor-pointer p-2 sm:p-4"
          />
        </div>

        {/* Answer Options Grid - single column on mobile, two columns on larger screens */}
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 mb-6">
          {shuffledOptions.map((option, index) => (
            <motion.button
              key={index}
              onClick={() => handleSelect(option)}
              className={`p-3 sm:p-5 text-left rounded-lg border-2 transition-all flex items-center ${
                optionChosen === option
                  ? "hover:border-green border-b-4 bg-white border-green text-green shadow-lg hover:shadow-xl"
                  : "bg-otherwhite border-b-4 bg-white text-gray border-gray hover:text-green hover:border-green"
              }`}
            >
              <span className="w-5 h-5 sm:w-6 sm:h-6 flex items-center justify-center mr-2 sm:mr-3 border rounded-full text-xs sm:text-sm">
                {String.fromCharCode(65 + index)}
              </span>
              <div className="flex-1 text-sm sm:text-base">{option}</div>
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
};

MarkCorrectMeaning.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      sourceSentence: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      distractors: PropTypes.array.isRequired,
    }).isRequired,
    validation: PropTypes.shape({
      correctAnswer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string,
          audio: PropTypes.object,
        }),
      ]).isRequired,
    }).isRequired,
    languageNote: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default MarkCorrectMeaning;
