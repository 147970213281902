import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { UnitsSocialData } from "../../zombiedata/units-social";
import { SkillsSocialData } from "../../zombiedata/skills-social.js";
import Skills from "../../components/vault/skills/skills-primary.component";
import { GuidebookContext } from "../../helpers/contexts";
import { useCourse } from "../../helpers/CourseContextProvider";
import { useMilestone } from "../../helpers/MilestoneProvider";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component";
import { motion } from "framer-motion";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { LMContext } from "../../helpers/context3";
import { db } from "../../firebase/firebase.utils";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { LearningMaterialsContext } from "../../helpers/contexts";
import FloatingActionMenu from "../../components/navigation-bar-2/floating-side-menu";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";
import PracticeConfirmationModal from "../../components/vault/quiz/practice-confirmation-modal";
import UnitDetailsModal from "../../components/vault/units/unit-detail-modal";
import unitOverviewData, {
  findUnitData,
} from "../../zombiedata/unit-overview-data";

// Normalize course name function to ensure consistency
const normalizeCourse = (course) => {
  if (course === "Social") return "isiZuluSocial";
  return course || "isiZuluSocial";
};

function UnitsPage({ id }) {
  const { courses, loading: courseLoading } = useCourse();
  const { guidebook } = useContext(GuidebookContext);
  const { milestone, loading: milestoneLoading } = useMilestone();

  const [userSnap, setUserSnap] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeUnit, setActiveUnit] = useState(0);
  const unitRefs = useRef([]);

  // Use a memo to safely access current units with a normalized course name
  const currentUnits = useMemo(() => {
    // Always normalize the course name
    const normalizedCourse = normalizeCourse(courses);

    console.log(
      `[UnitsPage] Getting units for courses=${normalizedCourse}, milestone=${milestone}`
    );

    if (!milestone) {
      console.log("[UnitsPage] Missing milestone");
      return [];
    }

    // First try with the normalized name
    let units = UnitsSocialData[normalizedCourse]?.[milestone];

    // If we don't find anything, as a fallback, also check the other format
    // This provides backward compatibility during the transition
    if (!units && normalizedCourse === "isiZuluSocial") {
      units = UnitsSocialData["Social"]?.[milestone];
      console.log(
        "[UnitsPage] Using 'Social' data as fallback for 'isiZuluSocial'"
      );
    }

    console.log(
      `[UnitsPage] Found ${units?.length || 0} units for ${milestone}`
    );
    return units || [];
  }, [courses, milestone]);

  const dependencyArrayValue = useMemo(() => [currentUnits], [currentUnits]);

  const { setLearningMaterialId } = useContext(LMContext);
  const { user } = useAuth();
  const { setLearningMaterialState } = useContext(LearningMaterialsContext);
  const [showPracticeModal, setShowPracticeModal] = useState(false);
  const [practiceData, setPracticeData] = useState(null);

  // State for unit overview modal
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const [enrollment, setEnrollment] = useState({});
  const [skill, setSkill] = useState(null);

  // Track the current skill and lesson for display
  const [currentSkill, setCurrentSkill] = useState(1);
  const [currentLesson, setCurrentLesson] = useState(1);
  const [currentSkillData, setCurrentSkillData] = useState(null);

  // User data loading
  useEffect(() => {
    let isMounted = true;

    const getCurrUser = async () => {
      if (!user) {
        if (isMounted) setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && isMounted) {
          const userData = docSnap.data();

          // Get the current skill and lesson numbers
          const currentSkillNum = userData.currentEnroll?.currentSkill || 1;
          const currentLessonNum = userData.currentEnroll?.currentLesson || 1;

          // Update our state with fresh data
          setEnrollment({
            ...userData,
            id: docSnap.id,
            currentSkill: currentSkillNum,
            currentLesson: currentLessonNum,
          });

          setSkill(currentSkillNum);
          setCurrentSkill(currentSkillNum);
          setCurrentLesson(currentLessonNum);

          // Always use the normalized course name
          const normalizedCourse = normalizeCourse(
            userData.currentEnroll?.course
          );

          // Find current skill data for UI display
          findCurrentSkillData(
            currentSkillNum,
            currentLessonNum,
            normalizedCourse,
            userData.membership || "George"
          );
        }
      } catch (error) {
        console.error("[UnitsPage] Error fetching user data:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    getCurrUser();

    // Cleanup function to prevent state updates after unmount
    return () => {
      isMounted = false;
    };
  }, [user]);

  // Find the current skill data to display
  const findCurrentSkillData = (
    skillNumber,
    lessonNumber,
    course,
    membership
  ) => {
    try {
      if (!skillNumber || !course || !membership) {
        return;
      }

      // Format membership properly
      const formattedMembership =
        membership.charAt(0).toUpperCase() + membership.slice(1);

      // Normalize the course name
      const normalizedCourse = normalizeCourse(course);

      // Find all courses
      let allCourses =
        SkillsSocialData[normalizedCourse]?.[formattedMembership];

      // Fallback to "Social" if necessary
      if (!allCourses && normalizedCourse === "isiZuluSocial") {
        allCourses = SkillsSocialData["Social"]?.[formattedMembership];
      }

      if (!allCourses) {
        console.error(
          `[UnitsPage] No skills data found for ${normalizedCourse}/${formattedMembership}`
        );
        return;
      }

      // Search through all units to find the skill
      let foundUnit = null;
      let foundSkill = null;

      for (const unit of allCourses) {
        const skill = unit.skills?.find((s) => s.skillNumber === skillNumber);
        if (skill) {
          foundUnit = unit;
          foundSkill = skill;
          break;
        }
      }

      if (foundSkill && foundUnit) {
        // Find the specific lesson if possible
        const lesson = foundSkill.lessons?.find(
          (l) => l.lessonNumber === lessonNumber
        );

        setCurrentSkillData({
          unitId: foundUnit.unitId,
          unitName: foundUnit.name,
          skillNumber: skillNumber,
          skillName: foundSkill.name,
          skillEmoji: foundSkill.emoji,
          lessonNumber: lessonNumber,
          lessonName: lesson?.name || `Lesson ${lessonNumber}`,
        });

        // Set the active unit index to match the unit with this skill
        const unitIndex = currentUnits.findIndex(
          (u) => u.id === foundUnit.unitId
        );
        if (unitIndex >= 0) {
          setActiveUnit(unitIndex);
        }
      }
    } catch (error) {
      console.error("[UnitsPage] Error finding skill data:", error);
    }
  };

  // Check if required context is available
  useEffect(() => {
    const allContextsLoaded = !loading && !milestoneLoading && !courseLoading;

    if (allContextsLoaded) {
      console.log("[UnitsPage] All contexts loaded:", { courses, milestone });

      if (!courses || !milestone || currentUnits.length === 0) {
        console.error("[UnitsPage] Missing required context after loading:", {
          courses,
          milestone,
          unitsCount: currentUnits.length,
        });
        navigate("/vault");
      }
    }
  }, [
    courses,
    milestone,
    currentUnits,
    navigate,
    loading,
    milestoneLoading,
    courseLoading,
  ]);

  useEffect(() => {
    document.title = "Units | Fast Fluency Africa";
  }, []);

  useEffect(() => {
    setUserSnap(milestone);
  }, [milestone]);

  // Wait for all context providers to finish loading
  useEffect(() => {
    if (!milestoneLoading && !courseLoading) {
      const timer = setTimeout(() => setLoading(false), 500);
      return () => clearTimeout(timer);
    }
  }, [milestoneLoading, courseLoading]);

  // Handle opening the unit overview modal
  const handleUnitOverview = (unit, index) => {
    // Always use the default unit data to ensure tables render correctly
    const defaultUnitData = unitOverviewData["george1-unit1"];

    // Set the selected unit while preserving the data we need for tables
    setSelectedUnit({
      ...unit,
      id: "george1-unit1", // Force using the ID that has complete data
      ...defaultUnitData, // Copy all the data from our default unit
      name: `${milestone} ${index + 1}, Unit ${index + 1}`,
      index: index,
    });

    // Open the modal
    setModalOpen(true);
  };

  // Close the unit overview modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const prevUnits = useRef(currentUnits);
  if (prevUnits.current !== currentUnits) {
    unitRefs.current = [];
    prevUnits.current = currentUnits;
  }

  const onBack = () => {
    navigate("/vault");
  };

  const selectLearningMaterialText = () => {
    setLearningMaterialState(null);
  };

  const handlePracticeMode = () => {
    try {
      const currentEnroll = enrollment?.currentEnroll || {};
      const skills = currentEnroll.skills || {};

      // Get completed lessons as numbers
      const completedLessons = Object.entries(skills).flatMap(
        ([skillNumber, skillData]) => {
          const lessons = skillData.completedLessons || [];
          return lessons.map((lessonNumber) => ({
            skill: parseInt(skillNumber, 10),
            lesson: parseInt(lessonNumber),
          }));
        }
      );

      if (completedLessons.length === 0) {
        alert(
          "You haven't completed any lessons yet! Complete at least one lesson to use Practice Mode."
        );
        return;
      }

      // Create practice data
      const practiceData = {
        mode: "practice",
        lessons: completedLessons,
        timestamp: Date.now(),
      };

      // Show confirmation modal
      setShowPracticeModal(true);
      setPracticeData(practiceData);
    } catch (error) {
      console.error("Practice mode setup failed:", error);
    }
  };

  const handleConfirmPractice = () => {
    // Hide modal
    setShowPracticeModal(false);

    // Now navigate to practice mode with the data
    navigate("/practice", {
      state: {
        learningMaterialId: practiceData,
        skipConfirmation: true,
      },
    });
  };

  const handleCancelPractice = () => {
    setShowPracticeModal(false);
    setPracticeData(null);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = unitRefs.current.indexOf(entry.target);
            setActiveUnit(index);
          }
        });
      },
      { threshold: 0.5, rootMargin: "-50px 0px 0px 0px" }
    );

    // Filter out null refs before observing
    const validRefs = unitRefs.current.filter((ref) => ref !== null);
    validRefs.forEach((ref) => observer.observe(ref));

    return () => observer.disconnect();
  }, [dependencyArrayValue]);

  // Show loading while any context is loading
  if (loading || milestoneLoading || courseLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <PuffLoader color="#991616" size={100} />
      </div>
    );
  }

  // Handle case where milestone is still missing after loading
  if (!milestone) {
    return (
      <div className="flex flex-col items-center justify-center h-screen gap-4">
        <PuffLoader color="#991616" size={60} />
        <p className="text-gray-600">Loading learning progress...</p>
        <button
          onClick={() => navigate("/vault")}
          className="px-6 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
        >
          Return to Vault
        </button>
      </div>
    );
  }

  // Handle empty units array
  if (!currentUnits || currentUnits.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen gap-4">
        <div className="text-4xl">😕</div>
        <p className="text-gray-600">No units found for {milestone}</p>
        <button
          onClick={() => navigate("/vault")}
          className="px-6 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
        >
          Return to Vault
        </button>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="relative bg-white"
    >
      <NavigationBar currentSkillData={currentSkillData} />
      {/* Floating Action Menu with conditional buttons */}
      <FloatingActionMenu
        showFlashcards={true}
        showPractice={true}
        showStats={true}
        handlePracticeMode={handlePracticeMode}
        additionalButtons={[]}
      />
      <FeedbackMenu />
      <div className="snap-y snap-proximity overflow-y-auto">
        {currentUnits.map((unit, index) => {
          if (!unit || !unit.id) {
            console.error("Invalid unit structure at index:", index);
            return null; // Skip invalid units
          }
          return (
            <section
              key={unit.id}
              ref={(el) => (unitRefs.current[index] = el)}
              className="snap-y snap-proximity overflow-y-auto"
            >
              {/* Unit Banner */}
              <div
                className={`sticky top-4 z-30 transition-opacity duration-300 ${
                  activeUnit === index ? "opacity-100" : "opacity-0"
                } bg-red border-b-4 border-2 w-full xs:w-11/12 sm:w-5/6 mx-auto border-black shadow-lg`}
              >
                <div className="px-3 xs:px-4 py-3 max-w-4xl mx-auto flex flex-col items-start">
                  <span className="text-3xl sm:text-4xl text-outline-white">
                    {unit.emoji}
                  </span>
                  <h1 className="text-white font-bold text-xl sm:text-2xl text-left">
                    {milestone} {index + 1}, Unit {index + 1}
                  </h1>
                  <p className="text-white text-sm sm:text-md text-left">
                    {unit.description}
                  </p>
                  <div className="flex flex-col xs:flex-row gap-2 w-full sm:w-auto">
                    <button
                      onClick={() => onBack()}
                      className="border-white p-2 sm:p-4 mt-2 border-b-4 text-white py-2 bg-red rounded transform
                    active:scale-95 active:translate-y-1
                    transition-all duration-150 ease-out-expo font-semibold
                    text-xs sm:text-sm md:text-base hover:shadow-xl
                    hover:bg-[#932929] relative overflow-hidden"
                    >
                      <span className="font-semibold">← Back to Vault</span>
                    </button>
                    <button
                      onClick={() => handleUnitOverview(unit, index)}
                      className="border-2 border-white p-2 mt-2 border-b-4 text-white py-2 bg-red rounded transform
                    active:scale-95 active:translate-y-1
                    transition-all duration-150 ease-out-expo font-semibold shadow-lg
                    text-xs sm:text-sm md:text-base hover:shadow-xl
                    hover:bg-[#932929] relative overflow-hidden"
                    >
                      <span className="text-outline-white">📚</span> Unit{" "}
                      {index + 1} Overview
                    </button>
                  </div>
                </div>
              </div>

              {/* Skills List */}
              <div className="relative z-30 bg-white min-h-[calc(75vh-200px)] mt-6 sm:mt-10 pb-8">
                <div className="max-w-4xl mx-auto">
                  <h3 className="text-lg sm:text-xl text-black font-semibold font-body text-center">
                    <p className="relative text-center font-medium mx-auto w-4/6 text-gray">
                      <span className="bg-white font-semibold px-2 relative z-10">
                        {unit.name}
                      </span>
                      <span
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <span className="w-full border-t border-2 rounded border-darkgray"></span>
                      </span>
                    </p>
                  </h3>

                  <div className="w-3/6 mx-auto">
                    <Skills
                      unitId={unit.id}
                      unitName={unit.name}
                      enrollment={enrollment}
                      setEnrollment={setEnrollment}
                    />
                  </div>
                </div>
              </div>

              {/* Next Unit Fade Effect */}
              {index < currentUnits.length - 1 && (
                <div className="absolute bottom-0 w-full h-64 bg-gradient-to-t from-white to-transparent" />
              )}
            </section>
          );
        })}
      </div>

      {/* Unit Details Modal */}
      {modalOpen && selectedUnit && (
        <UnitDetailsModal
          unit={selectedUnit}
          isOpen={modalOpen}
          onClose={handleCloseModal}
        />
      )}

      {showPracticeModal && (
        <PracticeConfirmationModal
          onClose={handleCancelPractice}
          onConfirm={handleConfirmPractice}
        />
      )}
    </motion.div>
  );
}

export default UnitsPage;
