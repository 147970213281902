import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import AuthRedirectService from "../../services/auth-redirect.service";

/**
 * FloatingActionMenu component with responsive design
 * Displays on the side for desktop and at bottom for mobile
 * Adapts layout and sizing based on screen size
 */
const FloatingActionMenu = ({
  showOnboarding = false,
  onStartTour = () => {},
  showFlashcards = false,
  showPractice = false,
  showStats = false,
  handlePracticeMode = null,
  additionalButtons = [],
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Load menu state from localStorage, default to true on desktop, false on mobile
  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    const savedState = localStorage.getItem("floatingMenuOpen");
    return savedState !== null ? JSON.parse(savedState) : !isMobile;
  });

  // Track window size for responsive layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Save menu state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("floatingMenuOpen", JSON.stringify(isMenuOpen));
  }, [isMenuOpen]);

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // Function to open FFAQs in new tab
  const goFFAQ = (route) => {
    const win = window.open(route, "_blank");
    win.focus();
  };

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Default practice mode handler
  const defaultPracticeHandler = () => {
    console.log("🚨 PRACTICE BUTTON CLICKED");
    navigate("/practice");
  };

  // Get all buttons to display
  const allButtons = [
    ...(showOnboarding
      ? [
          {
            icon: "🚀",
            onClick: onStartTour,
            title: "Onboarding",
          },
        ]
      : []),
    ...(showFlashcards
      ? [
          {
            icon: "🗃️",
            onClick: () => navigate("/flashcard"),
            title: "Flashcards",
          },
        ]
      : []),
    ...(showPractice
      ? [
          {
            icon: "🏋🏾‍♀️",
            onClick: handlePracticeMode || defaultPracticeHandler,
            title: "Practice",
          },
        ]
      : []),
    ...(showStats
      ? [
          {
            icon: "📊",
            onClick: () => navigate("/analytics"),
            title: "Statistics",
          },
        ]
      : []),
    ...additionalButtons,
    // Fixed buttons
    {
      icon: "🙋🏾‍♀️",
      onClick: () => goFFAQ("/support"),
      title: "FFAQs",
    },
    {
      icon: "👤",
      onClick: () => navigate("/profile"),
      title: "Profile",
    },
    {
      icon: "⚙️",
      onClick: () => navigate("/settings"),
      title: "Settings",
    },
    {
      icon: "✌️",
      onClick: handleLogout,
      title: "Logout",
    },
  ];

  // Button rendering function - side menu style
  const renderSideMenuButton = (
    content,
    onClick,
    title,
    isDisabled = false
  ) => (
    <div className="flex flex-col justify-center items-center my-1 sm:my-2 hover:text-green hover:border-green">
      <div
        className={`w-8 h-8 sm:w-10 sm:h-10 md:w-11 md:h-11 rotate-45 border drop-shadow rounded border-gray border-b-2 border-r-2 cursor-pointer`}
      >
        <motion.div
          whileTap={{ scale: 0.95 }}
          className="w-full h-full flex justify-center bg-white rounded items-center cursor-pointer"
          onClick={isDisabled ? undefined : onClick}
          title={title}
        >
          <span className="text-base sm:text-lg md:text-xl -rotate-45 text-outline-darkgray text-white">
            {content}
          </span>
        </motion.div>
      </div>
      <div className="p-1 sm:p-2">
        <p className="text-xs hidden sm:block">{title}</p>
        <p className="text-xs sm:hidden">{title.charAt(0)}</p>
      </div>
    </div>
  );

  // Button rendering function - bottom menu style for mobile
  const renderBottomMenuButton = (
    content,
    onClick,
    title,
    isDisabled = false
  ) => (
    <motion.div
      whileTap={{ scale: 0.95 }}
      className="flex flex-col items-center justify-center cursor-pointer"
      onClick={isDisabled ? undefined : onClick}
    >
      <div className="w-10 h-10 bg-white rotate-45 border drop-shadow rounded border-gray border-b-2 border-r-2 flex items-center justify-center">
        <span className="text-lg text-outline-darkgray text-white  -rotate-45">
          {content}
        </span>
      </div>
      <p className="text-xs mt-1">{title}</p>
    </motion.div>
  );

  // Side menu animations
  const sideMenuVariants = {
    open: { x: 0, opacity: 1 },
    closed: { x: "-100%", opacity: 0.5 },
  };

  const sideToggleVariants = {
    open: { x: "calc(100% - 5px)", rotate: 0 },
    closed: { x: 0, rotate: 180 },
  };

  // Bottom menu animations
  const bottomMenuVariants = {
    open: { y: 0, opacity: 1 },
    closed: { y: "100%", opacity: 0 },
  };

  const bottomToggleVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  };

  // For desktop: side menu
  if (!isMobile) {
    return (
      <div className="fixed top-[55%] transform -translate-y-1/2 z-40 flex items-center">
        {/* Toggle button */}
        <motion.div
          animate={isMenuOpen ? "open" : "closed"}
          variants={sideToggleVariants}
          initial={isMenuOpen ? "open" : "closed"}
          transition={{ duration: 0.3 }}
          className="absolute z-50 cursor-pointer bg-white border-2 border-darkgray shadow-lg p-1 sm:p-2 rounded-r-md"
          onClick={toggleMenu}
          style={isMenuOpen ? { left: 45 } : { left: 0 }}
        >
          <div className="w-5 h-5 sm:w-6 sm:h-6 flex justify-center text-darkgray items-center">
            ◃
          </div>
        </motion.div>

        {/* Side menu panel */}
        <AnimatePresence>
          <motion.div
            animate={isMenuOpen ? "open" : "closed"}
            variants={sideMenuVariants}
            initial={isMenuOpen ? "open" : "closed"}
            transition={{ type: "spring", stiffness: 400, damping: 30 }}
            className="bg-white border-2 border-darkgray border-b-4 shadow-lg flex flex-col items-center justify-center py-2 sm:py-3 md:py-4 max-w-[85px] w-[65px] sm:w-[75px] md:w-[85px]"
            style={{
              height: "auto",
              maxHeight: "85vh",
              overflowY: "auto",
            }}
          >
            {allButtons.map((button, index) => (
              <React.Fragment key={index}>
                {index === allButtons.length - 4 && index !== 0 && (
                  <div className="w-12 sm:w-14 md:w-16 h-[0.125rem] bg-darkgray border-t border-darkgray my-1 sm:my-2"></div>
                )}
                {renderSideMenuButton(
                  button.icon,
                  button.onClick,
                  button.title,
                  button.disabled
                )}
              </React.Fragment>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  }

  // For mobile: bottom menu
  return (
    <>
      {/* Toggle button for bottom menu */}
      <motion.div
        animate={isMenuOpen ? "open" : "closed"}
        variants={bottomToggleVariants}
        initial={isMenuOpen ? "open" : "closed"}
        className="fixed bottom-1 left-1/2 -m-6 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white rounded-full shadow-lg p-2 border border-darkgray"
        onClick={toggleMenu}
      >
        <div className="w-5 h-5 flex justify-center items-center text-gray">
          <span className="text-2xl">{isMenuOpen ? "▼" : "▲"}</span>
        </div>
      </motion.div>

      {/* Bottom menu panel */}
      <AnimatePresence>
        <motion.div
          animate={isMenuOpen ? "open" : "closed"}
          variants={bottomMenuVariants}
          initial={isMenuOpen ? "open" : "closed"}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
          className="fixed bottom-0 left-0 right-0 z-40 bg-white border-t-2 border-darkgray shadow-lg py-3 px-2"
        >
          <div className="flex justify-around items-start  pb-2">
            {allButtons
              .slice(0, Math.min(allButtons.length, 5))
              .map((button, index) => (
                <div key={index} className="px-1">
                  {renderBottomMenuButton(
                    button.icon,
                    button.onClick,
                    button.title,
                    button.disabled
                  )}
                </div>
              ))}
          </div>

          {/* Display additional buttons in a second row if there are more than 5 */}
          {allButtons.length > 5 && (
            <div className="flex justify-around items-start mt-2 ">
              {allButtons.slice(5).map((button, index) => (
                <div key={index} className="px-1">
                  {renderBottomMenuButton(
                    button.icon,
                    button.onClick,
                    button.title,
                    button.disabled
                  )}
                </div>
              ))}
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default FloatingActionMenu;
