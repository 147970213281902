import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  CheckCircle,
  Calendar,
  Clock,
  Copy,
  Video as VideoIcon,
  Mail,
  Users,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebase.utils";
import { MeetingService } from "../../../utils/bookings/meeting-service";

const BookingConfirmation = ({
  bookingDetails,
  coachProfile,
  sessionDateTime,
  meetingLink,
  meetingPassword,
  meetingStartUrl,
  alternativeHosts = [],
  userRole = null, // 'coach' or 'student'
  onDone,
}) => {
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyPasswordSuccess, setCopyPasswordSuccess] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [isHost, setIsHost] = useState(false);

  // Get current user role and email
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentUserEmail(user.email);

      // Determine if current user is the coach
      const isCoach =
        userRole === "coach" ||
        (bookingDetails && bookingDetails.coachId === user.uid);

      // Check if current user is a host (either directly or as alternative host)
      const isAltHost =
        alternativeHosts && alternativeHosts.includes(user.email);

      setIsHost(isCoach || isAltHost);
    }
  }, [userRole, bookingDetails, alternativeHosts]);

  const copyToClipboard = (text, isPassword = false) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (isPassword) {
          setCopyPasswordSuccess(true);
          setTimeout(() => setCopyPasswordSuccess(false), 3000);
        } else {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 3000);
        }
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Get the actual meeting link string - safely handle different formats
  const actualMeetingLink = meetingLink || bookingDetails?.meetingLink || "";

  // Get the actual meeting password - safely handle different formats
  const actualMeetingPassword =
    meetingPassword || bookingDetails?.meetingPassword || "";

  // Get the actual meeting start URL - safely handle different formats
  const actualMeetingStartUrl =
    meetingStartUrl || bookingDetails?.meetingStartUrl || "";

  // Determine if this is a Zoom meeting based on link URL
  const isZoomMeeting = MeetingService.isZoomMeeting(actualMeetingLink);

  return (
    <div className="max-w-md w-full mx-auto bg-white border-2 border-b-4 border-darkgray p-4 sm:p-6 rounded">
      <div className="text-center mb-6">
        <div className="inline-flex items-center justify-center w-16 h-16 bg-green bg-opacity-20 rounded-full mb-4">
          <CheckCircle className="text-green" size={32} />
        </div>
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">
          Booking Confirmed!
        </h2>
        <p className="text-gray mt-2">
          Your session has been successfully booked.
        </p>
      </div>

      <div className="border-2 border-darkgray p-3 sm:p-4 rounded-lg mb-6">
        <h3 className="font-bold mb-3">Session Details</h3>

        {sessionDateTime && (
          <>
            <div className="flex items-center text-gray-600 mb-2">
              <Calendar className="mr-2 flex-shrink-0" size={16} />
              <span className="text-sm sm:text-base">
                {format(new Date(sessionDateTime), "EEEE, MMMM d, yyyy")}
              </span>
            </div>
            <div className="flex items-center text-gray-600 mb-4">
              <Clock className="mr-2 flex-shrink-0" size={16} />
              <span className="text-sm sm:text-base">
                {format(new Date(sessionDateTime), "h:mm a")}
              </span>
              {bookingDetails?.duration && (
                <>
                  <span className="mx-1">•</span>
                  <span>{bookingDetails.duration} minutes</span>
                </>
              )}
            </div>
          </>
        )}

        {/* Participant information */}
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <Users size={16} className="mr-2 text-gray-600 flex-shrink-0" />
            <span className="text-gray-600 font-medium">Participants</span>
          </div>

          {/* Coach info with improved name handling */}
          {coachProfile && (
            <div className="flex items-center mb-2 pl-3 sm:pl-6">
              <div className="h-8 w-8 bg-darkgray rounded-full flex items-center justify-center mr-2 flex-shrink-0">
                {coachProfile.photoURL ? (
                  <img
                    src={coachProfile.photoURL}
                    alt={coachProfile.displayName || coachProfile.name}
                    className="h-8 w-8 rounded-full object-cover"
                  />
                ) : (
                  <span className="text-white">👤</span>
                )}
              </div>
              <div className="min-w-0">
                <div className="font-medium text-sm sm:text-base truncate">
                  {bookingDetails?.coachName ||
                    coachProfile.name ||
                    coachProfile.displayName ||
                    "Coach"}
                </div>
                <div className="text-xs text-gray-500">
                  Coach
                  {isHost && coachProfile.email === currentUserEmail
                    ? " (You)"
                    : ""}
                </div>
              </div>
            </div>
          )}

          {/* Student info with improved name handling */}
          <div className="flex items-center pl-3 sm:pl-6">
            <div className="h-8 w-8 bg-darkgray rounded-full flex items-center justify-center mr-2 flex-shrink-0">
              <span className="text-white">👤</span>
            </div>
            <div className="min-w-0">
              <div className="font-medium text-sm sm:text-base truncate">
                {bookingDetails?.studentName ||
                  bookingDetails?.student?.name ||
                  bookingDetails?.student?.displayName ||
                  "Student"}
                {!isHost && " (You)"}
              </div>
              <div className="text-xs text-gray-500">Attendee</div>
            </div>
          </div>

          {/* Meeting Link Section - Enhanced for Zoom */}
          {actualMeetingLink && (
            <div className="border-t-2 border-darkgray pt-4 mt-2">
              <div className="flex items-center mb-2">
                <VideoIcon
                  className={`mr-2 flex-shrink-0 ${
                    isZoomMeeting ? "text-blue-500" : "text-green"
                  }`}
                  size={16}
                />
                <span className="font-medium">
                  {isZoomMeeting ? "Zoom Meeting" : "Meeting Link"}
                </span>
                {isHost && (
                  <span className="ml-2 text-xs bg-green text-white px-2 py-1 rounded-full">
                    Host
                  </span>
                )}
              </div>

              {/* Meeting link with copy button */}
              <div className="flex items-center bg-darkgray bg-opacity-20 p-2 rounded mb-2">
                <div className="text-xs sm:text-sm text-gray-600 mr-2 flex-grow truncate">
                  {actualMeetingLink}
                </div>
                <button
                  onClick={() => copyToClipboard(actualMeetingLink)}
                  className="p-1 hover:bg-darkgray hover:bg-opacity-30 rounded flex-shrink-0"
                  title="Copy to clipboard"
                >
                  {copySuccess ? (
                    <span className="text-green">✓</span>
                  ) : (
                    <Copy size={14} />
                  )}
                </button>
              </div>

              {/* Zoom password section with enhanced styling */}
              {actualMeetingPassword && (
                <div
                  className={`mt-2 p-2 sm:p-3 rounded ${
                    isZoomMeeting ? "bg-blue-100" : "bg-gold bg-opacity-10"
                  }`}
                >
                  <p className="text-xs sm:text-sm flex items-center flex-wrap gap-1">
                    <span className="font-semibold mr-2">
                      {isZoomMeeting ? "Zoom Password:" : "Meeting Password:"}
                    </span>
                    <span className="font-mono bg-white px-2 py-1 rounded border border-darkgray">
                      {actualMeetingPassword}
                    </span>
                    <button
                      onClick={() =>
                        copyToClipboard(actualMeetingPassword, true)
                      }
                      className="ml-1 p-1 hover:bg-darkgray hover:bg-opacity-30 rounded flex-shrink-0"
                      title="Copy password"
                    >
                      {copyPasswordSuccess ? (
                        <span className="text-green">✓</span>
                      ) : (
                        <Copy size={14} />
                      )}
                    </button>
                  </p>
                  {isZoomMeeting && (
                    <p className="text-xs text-gray-500 mt-1">
                      You'll need this password to join the Zoom meeting
                    </p>
                  )}
                </div>
              )}

              {/* Different buttons for host vs attendee */}
              <div className="mt-3">
                {isHost && actualMeetingStartUrl ? (
                  // Host gets a start meeting button with the start URL
                  <a
                    href={actualMeetingStartUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`inline-flex items-center justify-center w-full px-4 py-2 rounded ${
                      isZoomMeeting
                        ? "bg-blue-600 hover:bg-blue-700 text-white"
                        : "bg-green hover:bg-opacity-90 text-white"
                    }`}
                  >
                    <VideoIcon size={16} className="mr-2" />
                    <span>
                      {isZoomMeeting
                        ? "Start Zoom Meeting (Host)"
                        : "Start Meeting (Host)"}
                    </span>
                  </a>
                ) : (
                  // Regular attendee gets a join meeting button
                  <a
                    href={actualMeetingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`inline-flex items-center justify-center w-full px-4 py-2 rounded ${
                      isZoomMeeting
                        ? "bg-blue-500 hover:bg-blue-600 text-white"
                        : "bg-gold hover:bg-opacity-90 text-white"
                    }`}
                  >
                    <VideoIcon size={16} className="mr-2" />
                    <span>
                      {isZoomMeeting ? "Join Zoom Meeting" : "Join Meeting"}
                    </span>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Action buttons */}
        <div className="flex flex-col sm:flex-row sm:space-x-3 space-y-2 sm:space-y-0">
          <button
            onClick={() => navigate("/dashboard")}
            className="sm:flex-1 px-4 py-2 border-2 border-b-4 border-gray text-gray rounded hover:bg-gray hover:bg-opacity-10 order-2 sm:order-1"
          >
            Go to Dashboard
          </button>

          <button
            onClick={onDone || (() => navigate("/dashboard"))}
            className="sm:flex-1 px-4 py-2 bg-green text-white border-2 border-b-4 border-black rounded hover:bg-opacity-90 order-1 sm:order-2"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
