import React from "react";
import { motion } from "framer-motion";

function FlashcardDeckCard({
  deck,
  isLocked = false,
  progress = 0,
  onClick,
  completedCards = 0,
  progressStats = null, // New prop for detailed progress stats
}) {
  // Calculate the progress percentage
  const progressPercentage =
    deck.cardCount > 0
      ? Math.min(100, Math.round((completedCards / deck.cardCount) * 100))
      : 0;

  // Get mastered, familiar, and learning counts from progressStats if available
  const masteredCards = progressStats?.masteredCards || completedCards;
  const familiarCards = progressStats?.familiarCards || 0;
  const learningCards = progressStats?.learningCards || 0;

  return (
    <motion.div
      className="relative h-auto min-h-[14rem] sm:h-64 border-2 border-b-4 border-darkgray shadow-md overflow-hidden bg-white cursor-pointer"
      whileHover={{ y: -5, boxShadow: "0 10px 20px rgba(0,0,0,0.15)" }}
      onClick={onClick}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Lock overlay for locked decks */}
      {isLocked && (
        <div className="absolute inset-0 bg-gray bg-opacity-70 flex flex-col items-center justify-center z-10 text-white">
          <div className="text-4xl mb-2">🔒</div>
          <p className="font-bold">Locked</p>
          <p className="text-sm text-center mt-2 px-4">
            Complete previous lessons to unlock
          </p>
        </div>
      )}

      {/* Card content */}
      <div className="p-3 sm:p-4 flex flex-col h-full">
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg sm:text-xl font-bold text-black mr-2 line-clamp-2">
            {deck.name}
          </h3>
          <span className="flex-shrink-0 border-gold text-gold border-2 border-b-4 text-xs font-bold px-2 py-1 rounded">
            {deck.cardCount} cards
          </span>
        </div>

        <p className="text-xs sm:text-sm text-gray mb-auto line-clamp-2">
          {deck.description ||
            "Practice these flashcards to improve your vocabulary."}
        </p>

        {/* Difficulty indicators */}
        <div className="mb-2 mt-2">
          <div className="flex items-center">
            <span className="text-xs text-gray mr-2">Difficulty:</span>
            <div className="flex">
              {Array.from({ length: 5 }).map((_, i) => (
                <div
                  key={i}
                  className={`w-2 h-2 rounded-full mr-1 ${
                    i < (deck.difficulty || 3) ? "bg-gold" : "bg-darkgray"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Themes/tags */}
        {deck.tags && deck.tags.length > 0 && (
          <div className="flex flex-wrap mb-2 gap-1">
            {deck.tags.slice(0, 2).map((tag, index) => (
              <span
                key={index}
                className="text-xs px-2 py-1 bg-green bg-opacity-10 text-green rounded truncate max-w-[45%]"
              >
                {tag}
              </span>
            ))}
            {deck.tags.length > 2 && (
              <span className="text-xs px-2 py-1 bg-gray text-gray-600 rounded">
                +{deck.tags.length - 2}
              </span>
            )}
          </div>
        )}

        {/* Progress bar */}
        <div className="mt-auto">
          <div className="flex justify-between text-xs mb-1">
            <span>{progressPercentage}% complete</span>
            <span>
              {completedCards}/{deck.cardCount}
            </span>
          </div>
          <div className="w-full h-2 bg-white border border-gray rounded-full overflow-hidden">
            <div
              className="h-full bg-green transition-all duration-300"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>

          {/* Knowledge level indicators - only show if we have progressStats */}
          {progressStats &&
            (progressStats.masteredCards > 0 ||
              progressStats.familiarCards > 0 ||
              progressStats.learningCards > 0) && (
              <div className="flex justify-between mt-2">
                <div className="flex items-center">
                  <div className="w-2 h-2 rounded-full bg-green mr-1"></div>
                  <span className="text-xs text-gray">{masteredCards}</span>
                </div>
                {(familiarCards > 0 || learningCards > 0) && (
                  <>
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-gold mr-1"></div>
                      <span className="text-xs text-gray">{familiarCards}</span>
                    </div>
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-blue-500 mr-1"></div>
                      <span className="text-xs text-gray">{learningCards}</span>
                    </div>
                  </>
                )}
              </div>
            )}
        </div>
      </div>
    </motion.div>
  );
}

export default FlashcardDeckCard;
