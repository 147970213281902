// components/common/PatternBackground.jsx
import React from "react";
import PropTypes from "prop-types";

/**
 * A component that creates a patterned background with advanced options
 * @param {Object} props - Component props
 * @param {string} props.patternUrl - URL to the pattern image
 * @param {string} props.backgroundColor - Background color behind the pattern
 * @param {number} props.patternSize - Size of each pattern instance (in pixels)
 * @param {number} props.opacity - Opacity of the pattern (0-1)
 * @param {string} props.className - Additional CSS classes
 * @param {React.ReactNode} props.children - Child components to render on top of the pattern
 */
const PatternBackground = ({
  patternUrl,
  backgroundColor = "#FCFCEE",
  patternSize = 400,
  opacity = 0.15, // Pattern opacity
  className = "",
  children,
}) => {
  const backgroundStyle = {
    position: "relative",
    backgroundColor: backgroundColor,
    overflow: "hidden",
    minHeight: "100vh",
  };

  const patternStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${patternUrl})`,
    backgroundSize: `${patternSize}px auto`,
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    opacity: opacity,
    pointerEvents: "none", // Make pattern non-interactive
    zIndex: 0, // Place pattern behind content
  };

  const contentStyle = {
    position: "relative",
    zIndex: 1, // Place content above pattern
    minHeight: "100vh",
  };

  return (
    <div className={`pattern-background ${className}`} style={backgroundStyle}>
      <div className="pattern-overlay" style={patternStyle} />
      <div className="pattern-content" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

PatternBackground.propTypes = {
  patternUrl: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  patternSize: PropTypes.number,
  opacity: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PatternBackground;
