import React, { useContext, useState, useEffect } from "react";
import Quiz from "../../components/vault/exercises/quiz.component.jsx";
import { ModalContext } from "../../helpers/modalcontext.js";
import BeatLoader from "react-spinners/BeatLoader";
import { DYKData } from "../../zombiedata/didyouknow.js";
import QuizNew from "../../components/vault/quiz/quiz-multimode.jsx";
import DialogueContainer from "../../components/vault/texts/dialogue-container.jsx";

import { motion } from "framer-motion";
import { LearningMaterialsContext } from "../../helpers/contexts.js";

function SandboxPage() {
  const { modal, setModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const randomIndex = Math.floor(Math.random() * DYKData.length);
  const randomObject = DYKData[randomIndex];
  const { learningMaterialState, setlearningMaterialState } = useContext(
    LearningMaterialsContext
  );

  useEffect(() => {
    document.title = "Sandbox | Fast Fluency Africa";
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <ModalContext.Provider value={{ modal, setModal }}>
        {learningMaterialState === "dialogue" ? (
          <DialogueContainer />
        ) : (
          <QuizNew />
        )}
      </ModalContext.Provider>
    </motion.div>
  );
  //
  // loading ? (
  //   <div className="flex flex-col justify-center items-center h-screen bg-white m-auto">
  //     <span className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center">
  //       🤲🏾
  //     </span>
  //     <h1 className="sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-headers font-semibold text-center uppercase text-green">
  //       Consulting the Ancestors
  //     </h1>
  //     <BeatLoader color={"#848717"} loading={loading} size={10} />
  //     <div className="flex flex-col justify-center items-center mt-4 mx-4">
  //       <span className="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center">
  //         {randomObject.emoji}
  //       </span>
  //       <h1 className="sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-semibold text-center">
  //         {randomObject.type === "dyk" ? `Did you know?` : `African Proverb`}
  //       </h1>
  //       <p className="sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-body font-light text-center">
  //         {randomObject.factoid}
  //       </p>
  //     </div>
  //   </div>
  // ) :
}

export default SandboxPage;
