import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaCheck, FaTimes, FaRegQuestionCircle } from "react-icons/fa";

/**
 * Helper function to safely display potentially complex exercise data
 * @param {any} value - The value to be displayed
 * @returns {string} - Safe string representation of the value
 */
const getSafeDisplayValue = (value) => {
  if (value === null || value === undefined) return "Not available";

  if (typeof value === "object") {
    // Handle objects with audio and text properties (common in language exercises)
    if (value.text !== undefined) return value.text;

    // Handle arrays differently
    if (Array.isArray(value)) {
      return value
        .map((item) =>
          typeof item === "object" ? JSON.stringify(item) : String(item)
        )
        .join(", ");
    }

    // Last resort: stringify the object but limit its length
    const stringified = JSON.stringify(value);
    return stringified.length > 100
      ? stringified.substring(0, 100) + "..."
      : stringified;
  }

  return String(value);
};

/**
 * Modal component to review exercises completed in a lesson
 */
const LessonReviewModal = ({ analyticsData, onClose }) => {
  const [activeTab, setActiveTab] = useState("all");

  // Early return if no analytics data is available
  if (!analyticsData || !analyticsData.exerciseResults) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] overflow-auto">
          <h2 className="text-xl font-bold mb-4">Exercise Review</h2>
          <p>No exercise data available to review.</p>
          <button
            onClick={onClose}
            className="mt-4 w-full py-2 bg-gray text-white rounded font-bold"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  // Convert exerciseResults object to array for filtering and mapping
  const exerciseResultsArray = Object.values(analyticsData.exerciseResults);

  // Filter exercises based on active tab
  const filteredExercises =
    activeTab === "all"
      ? exerciseResultsArray
      : activeTab === "correct"
      ? exerciseResultsArray.filter((exercise) => exercise.isCorrect)
      : exerciseResultsArray.filter((exercise) => !exercise.isCorrect);

  // Format exercise type for display
  const formatExerciseType = (type) => {
    if (!type) return "Unknown";
    return type
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        className="bg-white rounded border-b-4 border-2 border-darkgray p-6 w-4/6 max-w-2xl mx-auto max-h-[90vh] overflow-auto"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-green">Exercise Review</h2>
          <button onClick={onClose} className="text-gray hover:text-red">
            <FaTimes size={24} />
          </button>
        </div>

        {/* Tabs */}
        <div className="flex mb-6 border-b border-darkgray">
          <button
            className={`py-2 px-4 font-medium ${
              activeTab === "all"
                ? "border-b-2 border-green text-green"
                : "text-gray"
            }`}
            onClick={() => setActiveTab("all")}
          >
            All ({exerciseResultsArray.length})
          </button>
          <button
            className={`py-2 px-4 font-medium ${
              activeTab === "correct"
                ? "border-b-2 border-green text-green"
                : "text-gray"
            }`}
            onClick={() => setActiveTab("correct")}
          >
            Correct ({exerciseResultsArray.filter((e) => e.isCorrect).length})
          </button>
          <button
            className={`py-2 px-4 font-medium ${
              activeTab === "incorrect"
                ? "border-b-2 border-red text-red"
                : "text-gray"
            }`}
            onClick={() => setActiveTab("incorrect")}
          >
            Incorrect ({exerciseResultsArray.filter((e) => !e.isCorrect).length}
            )
          </button>
        </div>

        {/* Exercise list */}
        <div className="space-y-4">
          {filteredExercises.length === 0 ? (
            <p className="text-center py-8 text-gray">
              No exercises to display for this filter.
            </p>
          ) : (
            filteredExercises.map((exercise, index) => (
              <div
                key={exercise.exerciseId || index}
                className={`p-4 rounded-lg border-2 border-b-4 ${
                  exercise.isCorrect
                    ? "border-green bg-green bg-opacity-5"
                    : "border-red bg-red bg-opacity-5"
                }`}
              >
                <div className="flex justify-between items-start mb-2">
                  <div className="flex items-center">
                    <span className="mr-2 text-xl">
                      {exercise.isCorrect ? (
                        <FaCheck className="text-green" />
                      ) : (
                        <FaTimes className="text-red" />
                      )}
                    </span>
                    <span className="font-medium text-sm">
                      {formatExerciseType(exercise.type)}
                    </span>
                  </div>
                  {exercise.skipped && (
                    <span className="bg-gold bg-opacity-20 text-gold text-xs px-2 py-1 rounded">
                      Skipped
                    </span>
                  )}
                </div>

                <div className="mt-2">
                  <p className="text-gray-700 mb-2">
                    {getSafeDisplayValue(exercise.question)}
                  </p>

                  <div className="mt-3 space-y-2">
                    <div className="flex items-start">
                      <div className="w-24 flex-shrink-0 text-sm font-medium text-gray-600">
                        Your Answer:
                      </div>
                      <div
                        className={`text-sm ${
                          exercise.isCorrect ? "text-green" : "text-red"
                        }`}
                      >
                        {exercise.userAnswer
                          ? getSafeDisplayValue(exercise.userAnswer)
                          : "No answer provided"}
                      </div>
                    </div>

                    {!exercise.isCorrect && (
                      <div className="flex items-start">
                        <div className="w-24 flex-shrink-0 text-sm font-medium text-gray-600">
                          Correct:
                        </div>
                        <div className="text-sm text-green">
                          {exercise.correctAnswer
                            ? getSafeDisplayValue(exercise.correctAnswer)
                            : "No correct answer provided"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <button
          onClick={onClose}
          className="mt-6 w-full py-3 bg-green text-black border-2 border-b-4 border-black rounded font-bold hover:bg-opacity-90 transition-all"
        >
          Close Review
        </button>
      </motion.div>
    </motion.div>
  );
};

export default LessonReviewModal;
