// src/utils/analytics-guard.js

/**
 * Global analytics guard to prevent refresh loops
 * This is a standalone utility that will intercept and block
 * problematic refresh patterns across the application
 */

// Global settings
const REFRESH_COOLDOWN = 5000; // 5 seconds minimum between any refreshes
const MAX_REFRESHES_PER_SESSION = 10; // Maximum number of refreshes allowed per session
const DISABLE_ALL_BACKGROUND_REFRESHES = false; // Set to false to allow background refreshes

// State tracking
let lastRefreshTime = 0;
let refreshCount = 0;
let isRefreshing = false;
let originalConsoleLog = null;
let originalConsoleError = null;

// Initialize the guard
export function initAnalyticsGuard() {
  // Save original console methods
  if (!originalConsoleLog) {
    originalConsoleLog = console.log;
  }

  if (!originalConsoleError) {
    originalConsoleError = console.error;
  }

  // Replace console.log to filter analytics spam
  console.log = function (...args) {
    // Filter out analytics logging spam
    if (
      typeof args[0] === "string" &&
      (args[0].includes("Refreshing analytics data") ||
        args[0].includes("Analytics refresh") ||
        args[0].includes("analytics snapshot"))
    ) {
      // Silence these messages
      return;
    }

    // Pass through other logs
    originalConsoleLog.apply(console, args);
  };

  // Reset counters when new session starts
  window.addEventListener("pageshow", () => {
    refreshCount = 0;
  });

  // Monitor for component unmounting to cleanup
  window.addEventListener("pagehide", () => {
    // Reset guards
    isRefreshing = false;
  });

  originalConsoleLog("Analytics Guard: Initialized - Preventing refresh loops");
}

// Function to check if a refresh should be allowed
export function shouldAllowRefresh(forceRefresh = false) {
  const now = Date.now();

  // Allow any state updates for tracking toggle operations
  if (forceRefresh) {
    return true;
  }

  // Block if already refreshing
  if (isRefreshing) {
    return false;
  }

  // Block if we've hit max refreshes (unless forced)
  if (refreshCount >= MAX_REFRESHES_PER_SESSION && !forceRefresh) {
    return false;
  }

  // Block if too soon since last refresh (unless forced)
  if (now - lastRefreshTime < REFRESH_COOLDOWN && !forceRefresh) {
    return false;
  }

  // Allow critical operations
  return true;
}

// Function to track when refresh starts
export function trackRefreshStart() {
  isRefreshing = true;
  refreshCount++;
  lastRefreshTime = Date.now();
}

// Function to track when refresh completes
export function trackRefreshComplete() {
  isRefreshing = false;
}

// Initialize the guard immediately
initAnalyticsGuard();

// Export a function to force a one-time refresh when needed
export function forceOneTimeRefresh(callback) {
  // Allow a single refresh regardless of state
  lastRefreshTime = 0;
  isRefreshing = false;

  // Execute callback
  if (typeof callback === "function") {
    try {
      callback();
    } catch (e) {
      originalConsoleError("Error in forced refresh:", e);
    }
  }
}

// Special function to bypass analytics guard for toggle operations
export function bypassGuardForToggle(callback) {
  // Always allow toggle operations
  if (typeof callback === "function") {
    try {
      callback();
    } catch (e) {
      originalConsoleError("Error in toggle operation:", e);
    }
  }
}
