// utils/flashcard-notifications-utils.js
import { useNotifications } from "../../helpers/NotificationsContext";
import { useNotificationTemplates } from "./notification-util";

// Notification types for flashcard system
export const FLASHCARD_NOTIFICATION_TYPES = {
  DECK_UNLOCKED: "deck_unlocked",
  DECK_MASTERED: "deck_mastered",
  CARD_MASTERED: "card_mastered",
  PRACTICE_REMINDER: "practice_reminder",
  NEW_DECKS_AVAILABLE: "new_decks_available",
};

/**
 * Create a notification for when a flashcard deck is unlocked
 * @param {Object} deck - The unlocked deck
 * @param {number} skill - The skill number that unlocked it
 * @param {number} lesson - The lesson number that unlocked it
 * @returns {Object} The notification object
 */
export const createDeckUnlockedNotification = (deck, skill, lesson) => {
  return {
    title: "🎴 New Flashcards Unlocked!",
    message: `You've unlocked the "${deck.name}" flashcard deck by completing Skill ${skill}, Lesson ${lesson}. Practice them now!`,
    type: FLASHCARD_NOTIFICATION_TYPES.DECK_UNLOCKED,
    data: {
      deckId: deck.id,
      deckName: deck.name,
      unlockedBySkill: skill,
      unlockedByLesson: lesson,
    },
    link: `/flashcard`,
  };
};

/**
 * Create a notification for when a user has mastered a deck
 * @param {Object} deck - The mastered deck
 * @param {number} masteredCards - Number of mastered cards
 * @returns {Object} The notification object
 */
export const createDeckMasteredNotification = (deck, masteredCards) => {
  return {
    title: "🏆 Flashcard Deck Mastered!",
    message: `Congratulations! You've mastered all ${masteredCards} cards in the "${deck.name}" deck.`,
    type: FLASHCARD_NOTIFICATION_TYPES.DECK_MASTERED,
    data: {
      deckId: deck.id,
      deckName: deck.name,
      masteredCards,
    },
    link: `/flashcard`,
  };
};

/**
 * Create a notification for when a user has mastered a significant number of cards
 * @param {number} count - Number of cards mastered
 * @param {string} deckName - Name of the deck
 * @returns {Object} The notification object
 */
export const createCardsMasteredNotification = (count, deckName) => {
  return {
    title: "🎓 Vocabulary Progress",
    message: `You've mastered ${count} ${
      count === 1 ? "card" : "cards"
    } from the "${deckName}" deck! Keep up the good work!`,
    type: FLASHCARD_NOTIFICATION_TYPES.CARD_MASTERED,
    data: {
      deckName,
      count,
    },
    link: `/flashcard`,
  };
};

/**
 * Create a notification to remind users to practice their flashcards
 * @param {number} daysInactive - Days since last practice
 * @param {number} decksInProgress - Number of decks in progress
 * @returns {Object} The notification object
 */
export const createPracticeReminderNotification = (
  daysInactive,
  decksInProgress
) => {
  return {
    title: "📝 Flashcard Practice Reminder",
    message: `It's been ${daysInactive} days since you last practiced. You have ${decksInProgress} ${
      decksInProgress === 1 ? "deck" : "decks"
    } in progress.`,
    type: FLASHCARD_NOTIFICATION_TYPES.PRACTICE_REMINDER,
    data: {
      daysInactive,
      decksInProgress,
    },
    link: `/flashcard`,
  };
};

/**
 * Hook to easily send flashcard-related notifications
 */
export const useFlashcardNotifications = () => {
  const { addNotification } = useNotifications();
  const { sendNotification } = useNotificationTemplates();

  // Send a notification when a deck is unlocked
  const sendDeckUnlockedNotification = (deck, skill, lesson) => {
    // Try to use the template first if one exists
    const fromTemplate = sendNotification("DECK_UNLOCKED", {
      deckName: deck.name,
      skill,
      lesson,
    });

    // If template fails or doesn't exist, use our custom notification
    if (!fromTemplate) {
      return addNotification(
        createDeckUnlockedNotification(deck, skill, lesson)
      );
    }

    return fromTemplate;
  };

  // Send a notification when a deck is mastered
  const sendDeckMasteredNotification = (deck, masteredCards) => {
    return addNotification(createDeckMasteredNotification(deck, masteredCards));
  };

  // Send a notification for card mastery milestones
  const sendCardsMasteredNotification = (count, deckName) => {
    return addNotification(createCardsMasteredNotification(count, deckName));
  };

  // Send a practice reminder notification
  const sendPracticeReminderNotification = (daysInactive, decksInProgress) => {
    return addNotification(
      createPracticeReminderNotification(daysInactive, decksInProgress)
    );
  };

  return {
    sendDeckUnlockedNotification,
    sendDeckMasteredNotification,
    sendCardsMasteredNotification,
    sendPracticeReminderNotification,
  };
};

export default useFlashcardNotifications;
