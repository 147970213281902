import React, { useEffect, useState, useRef } from "react";
import { default as power } from "../../assets/pfm-logo-2-min.png";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component.jsx";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer.component.jsx";
import { useInView } from "react-intersection-observer";
import { PuffLoader } from "react-spinners";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";
import { Link } from "react-router-dom";

const AboutPage = () => {
  // State for animated typing effect
  const [visibleText, setVisibleText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const baseText =
    "Since 2016 we have been the only African mother tongue edutainment community for ";
  const words = ["Language", "Culture", "Stories"];
  const fullText = `${baseText}${words
    .slice(0, -1)
    .join(", ")}, and ${words.slice(-1)}`;

  const [loading, setLoading] = useState(true);

  // Loading animation
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Page title
  useEffect(() => {
    document.title = "About us | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Stats data
  const statsData = [
    { value: "2016", label: "founded", color: "red" },
    { value: "50+", label: "subscriptions", color: "gold" },
    { value: "200+", label: "coaching hours", color: "green" },
    { value: "3000+", label: "resources", color: "black" },
  ];

  const [statsRef, statsInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  // Animated counter component with fix for scroll restart issue
  const AnimatedCounter = ({ endValue, suffix = "", duration = 2000 }) => {
    const [count, setCount] = useState(0);
    const numberValue = parseInt(endValue.replace(/[^0-9]/g, ""));
    const countRef = useRef(0);
    const startTimeRef = useRef(null);
    const hasStartedAnimationRef = useRef(false); // Track if animation has started

    useEffect(() => {
      // Only run the animation once when the stats section first comes into view
      if (!statsInView || hasStartedAnimationRef.current) return;

      // Mark that we've started the animation and should never restart it
      hasStartedAnimationRef.current = true;

      let animationFrameId;

      const animate = (timestamp) => {
        if (!startTimeRef.current) startTimeRef.current = timestamp;
        const progress = timestamp - startTimeRef.current;

        const progressRatio = progress / duration;
        const nextCount = Math.min(
          Math.floor(numberValue * progressRatio),
          numberValue
        );

        if (nextCount !== countRef.current) {
          setCount(nextCount);
          countRef.current = nextCount;
        }

        if (progress < duration) {
          animationFrameId = requestAnimationFrame(animate);
        } else {
          setCount(numberValue);
        }
      };

      animationFrameId = requestAnimationFrame(animate);

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }, [numberValue, duration, statsInView]);

    return (
      <span>
        {count}
        {suffix}
      </span>
    );
  };

  // Values data
  const valuesData = [
    { name: "COMMUNITY", color: "red" },
    { name: "FUN", color: "gold" },
    { name: "SPEED", color: "green" },
    { name: "QUALITY", color: "red" },
    { name: "ORIGINALITY", color: "gold" },
  ];

  // Timeline data
  const timelineData = [
    {
      year: "2016",
      title: "Foundation",
      description:
        "Fast Fluency Africa was established with a mission to make African language learning accessible and enjoyable.",
    },
    {
      year: "2018",
      title: "First Programs",
      description:
        "Launched our initial language coaching sessions service and developed early learning resources.",
    },
    {
      year: "2019",
      title: "Digital Transition",
      description:
        "Pivoted to digital learning offerings to address administrative challenges, continuing to provide quality language edutainment.",
    },
    {
      year: "2022",
      title: "Growth & Expansion",
      description:
        "Began development of our first web application iteration, to reach more users across different regions.",
    },
    {
      year: "2024",
      title: "Modern Platform",
      description:
        "Launched our first iteration of our comprehensive digital platform with enhanced features and accessibility.",
    },
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  // // Typing effect
  // useEffect(() => {
  //   const textInterval = setInterval(() => {
  //     if (currentIndex < fullText.length) {
  //       setVisibleText(fullText.slice(0, currentIndex + 1));
  //       setCurrentIndex(currentIndex + 1);
  //     } else {
  //       clearInterval(textInterval);
  //     }
  //   }, 45);

  //   return () => {
  //     clearInterval(textInterval);
  //   };
  // }, [fullText, currentIndex]);

  // Set page title
  useEffect(() => {
    document.title = "About Us | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Intersection observers for sections
  const [visionRef, visionInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [originsRef, originsInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [approachRef, approachInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [valuesRef, valuesInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [timelineRef, timelineInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const [workRef, workInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  // Helper function to render colored text
  const renderColoredText = (text, words, colors) => {
    let elements = [];
    let lastIndex = 0;

    words.forEach((word, index) => {
      const wordIndex = text.indexOf(word, lastIndex);
      if (wordIndex !== -1) {
        elements.push(
          <span key={wordIndex}>{text.slice(lastIndex, wordIndex)}</span>
        );
        elements.push(
          <span key={word} className={colors[index]}>
            {word}
          </span>
        );
        lastIndex = wordIndex + word.length;
      }
    });

    if (lastIndex < text.length) {
      elements.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return elements;
  };

  // Color helper
  const getColorClasses = (color) => {
    switch (color) {
      case "red":
        return { bg: "bg-red", text: "text-red", border: "border-red" };
      case "gold":
        return { bg: "bg-gold", text: "text-gold", border: "border-gold" };
      case "green":
        return { bg: "bg-green", text: "text-green", border: "border-green" };
      default:
        return { bg: "bg-gray", text: "text-gray", border: "border-gray" };
    }
  };

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col min-h-screen bg-white"
    >
      {/* Header Section */}
      <div className="bg-gradient-to-r from-red to-black text-white">
        <NavBarPrimary />
        <FeedbackMenu />

        <div className="max-w-6xl mx-auto px-4 py-16 md:py-20 lg:py-24 w-5/6">
          <div className="text-center md:text-left md:flex md:items-center md:justify-between">
            <div className="md:max-w-2xl">
              <motion.h1
                className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-headers uppercase"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                About Us
              </motion.h1>
              <motion.p
                className="text-xl md:text-2xl mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Innovative EdTech solutions, for Africans, by Africans
              </motion.p>
            </div>
          </div>
        </div>

        {/* Wave divider */}
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full h-auto"
          >
            <path
              fill="#FCFCEE"
              fillOpacity="1"
              d="M0,32L48,42.7C96,53,192,75,288,74.7C384,75,480,53,576,48C672,43,768,53,864,58.7C960,64,1056,64,1152,56C1248,48,1344,32,1392,24L1440,16L1440,100L1392,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
            />
          </svg>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow bg-[#FCFCEE] w-5/6 mx-auto">
        <div className="max-w-6xl mx-auto px-4 py-8">
          {/* Company Introduction */}
          <section className="mb-20">
            <div className="flex flex-col items-center justify-center mb-12">
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="mb-6"
              >
                <a
                  href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block"
                >
                  <img src={power} alt="PowerFM" className="h-12 md:h-16" />
                </a>
              </motion.div>

              <div className="min-h-[130px] flex items-center justify-center">
                <motion.h2
                  className="text-2xl md:text-3xl lg:text-4xl text-center font-headers font-bold"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  {/* {renderColoredText(visibleText, words, [
                    "text-red",
                    "text-gold",
                    "text-green",
                  ])} */}

                  <p>
                    Since 2016 we have been the only African mother tongue
                    edutainment community for{" "}
                    <span className="text-red"> Language,</span>{" "}
                    <span className="text-gold">Culture,</span> and{" "}
                    <span className="text-green">Stories</span>
                  </p>
                </motion.h2>
              </div>
            </div>

            {/* Stats Cards - Updated to include animation */}
            <motion.div
              ref={statsRef}
              className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 my-12"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              {statsData.map((stat, index) => {
                const colors = getColorClasses(stat.color);
                const hasSuffix = stat.value.includes("+");

                return (
                  <motion.div
                    key={index}
                    className={`border-2 border-b-4 ${colors.border} rounded-lg shadow-lg p-6 text-center`}
                    variants={itemVariants}
                    whileHover={{ y: -5, transition: { duration: 0.2 } }}
                  >
                    <span
                      className={`block text-3xl md:text-4xl lg:text-5xl font-bold font-headers ${colors.text} mb-2`}
                    >
                      {stat.value === "2016" ? (
                        stat.value
                      ) : (
                        <AnimatedCounter
                          endValue={stat.value}
                          suffix={hasSuffix ? "+" : ""}
                          duration={2000 + index * 500}
                        />
                      )}
                    </span>
                    <span className="text-sm md:text-base font-body">
                      {stat.label}
                    </span>
                  </motion.div>
                );
              })}
            </motion.div>
          </section>

          {/* Vision & Mission Section */}
          <motion.section ref={visionRef} className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{
                opacity: visionInView ? 1 : 0,
                y: visionInView ? 0 : 30,
              }}
              transition={{ duration: 0.7 }}
            >
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-red flex items-center justify-center mr-4">
                  <span className="text-white text-2xl -rotate-45 text-outline-red">
                    👁️
                  </span>
                </div>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-headers font-bold">
                  Vision & Mission
                </h2>
              </div>

              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-red shadow-lg">
                <p className="text-lg md:text-xl">
                  The{" "}
                  <span className="font-bold text-red">
                    #1 African edutainment community
                  </span>{" "}
                  creating fun ways to know{" "}
                  <span className="font-bold">all</span> African languages,
                  cultures, and stories by 2050.
                </p>
              </div>
            </motion.div>
          </motion.section>

          {/* Origins Section */}
          <motion.section ref={originsRef} className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{
                opacity: originsInView ? 1 : 0,
                y: originsInView ? 0 : 30,
              }}
              transition={{ duration: 0.7 }}
            >
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-gold flex items-center justify-center mr-4">
                  <span className="text-white text-2xl -rotate-45 text-outline-gold">
                    🌱
                  </span>
                </div>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-headers font-bold">
                  Origins
                </h2>
              </div>

              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-gold shadow-lg">
                <p className="text-md md:text-lg mb-4">
                  Language is deeper than words; it's the key to building an
                  identity. Our humble beginnings started with our founders, who
                  experienced the struggle of language barriers firsthand, not
                  just in their native languages (Chichewa & Setswana), but also
                  across different cultural landscapes.
                </p>
                <p className="text-md md:text-lg">
                  These challenges led them to a simple yet effective framework
                  for language learning, focusing on vocabulary and grammar.
                  Now, we're committed to helping others overcome similar
                  challenges in a fun, natural, and immersive way.
                </p>
              </div>
            </motion.div>
          </motion.section>

          {/* Timeline Section (New) */}
          <motion.section ref={timelineRef} className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{
                opacity: timelineInView ? 1 : 0,
                y: timelineInView ? 0 : 30,
              }}
              transition={{ duration: 0.7 }}
            >
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-green flex items-center justify-center mr-4">
                  <span className="text-white text-xl -rotate-45 text-outline-green">
                    📅
                  </span>
                </div>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-headers font-bold">
                  Our Journey
                </h2>
              </div>

              <div className="relative">
                {/* Timeline line */}
                <div className="absolute left-[22px] md:left-1/2 top-0 bottom-0 w-1 bg-gold transform md:-translate-x-1/2 z-0"></div>

                {/* Timeline events */}
                {timelineData.map((event, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{
                      opacity: timelineInView ? 1 : 0,
                      y: timelineInView ? 0 : 20,
                    }}
                    transition={{
                      duration: 0.5,
                      delay: timelineInView ? index * 0.2 : 0,
                    }}
                    className={`relative flex flex-col md:flex-row items-start md:items-center mb-12 ${
                      index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                    }`}
                  >
                    {/* Timeline dot */}
                    <div className="absolute left-[18px] md:left-1/2 w-8 h-8 bg-red rounded-sm rotate-45 border-2 border-gold transform md:-translate-x-1/2 z-10 shadow-lg"></div>

                    {/* Content box */}
                    <div
                      className={`ml-16 md:ml-0 md:w-[45%] ${
                        index % 2 === 0 ? "md:pr-8 md:text-right" : "md:pl-8"
                      }`}
                    >
                      <div
                        className={`bg-white p-5 rounded-lg border-2 border-b-4 ${
                          index % 2 === 0 ? "border-red" : "border-gold"
                        } shadow-lg`}
                      >
                        <div className="flex items-center mb-2 justify-between">
                          <h3 className="text-xl font-bold font-headers">
                            {event.title}
                          </h3>
                          <span
                            className={`font-bold ${
                              index % 2 === 0 ? "text-red" : "text-gold"
                            }`}
                          >
                            {event.year}
                          </span>
                        </div>
                        <p className="text-gray-700">{event.description}</p>
                      </div>
                    </div>

                    {/* Spacer for the other side on mobile */}
                    <div
                      className={`hidden md:block md:w-[45%] ${
                        index % 2 === 0 ? "md:pl-8" : "md:pr-8"
                      }`}
                    ></div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </motion.section>

          {/* Approach Section */}
          <motion.section ref={approachRef} className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{
                opacity: approachInView ? 1 : 0,
                y: approachInView ? 0 : 30,
              }}
              transition={{ duration: 0.7 }}
            >
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-red flex items-center justify-center mr-4">
                  <span className="text-white text-2xl -rotate-45 text-outline-red">
                    🎯
                  </span>
                </div>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-headers font-bold">
                  Approach
                </h2>
              </div>

              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-red shadow-lg">
                <p className="text-md md:text-lg mb-4">
                  We believe that education should be fun. We combine education
                  and entertainment—
                  <span className="font-bold text-gold">Edutainment</span>—to
                  create immersive learning experiences that resonate with
                  diverse audiences.
                </p>
                <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                  <motion.div
                    className="bg-[#FCFCEE] p-4 rounded-lg border-2 border-l-4 border-gold"
                    whileHover={{ x: 5 }}
                  >
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-sm rotate-45 bg-gold/20 flex items-center justify-center mr-2">
                        <span className="text-gold text-xl -rotate-45">📚</span>
                      </div>
                      <h3 className="font-bold text-gold">Quality Materials</h3>
                    </div>
                    <p className="text-sm">
                      Carefully crafted course content designed by language
                      experts
                    </p>
                  </motion.div>

                  <motion.div
                    className="bg-[#FCFCEE] p-4 rounded-lg border-2 border-l-4 border-green"
                    whileHover={{ x: 5 }}
                  >
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-sm rotate-45 bg-green/20 flex items-center justify-center mr-2">
                        <span className="text-green text-xl -rotate-45">
                          🔍
                        </span>
                      </div>
                      <h3 className="font-bold text-green">Dictionaries</h3>
                    </div>
                    <p className="text-sm">
                      Comprehensive African language dictionaries at your
                      fingertips
                    </p>
                  </motion.div>

                  <motion.div
                    className="bg-[#FCFCEE] p-4 rounded-lg border-2 border-l-4 border-red"
                    whileHover={{ x: 5 }}
                  >
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-sm rotate-45 bg-red/20 flex items-center justify-center mr-2">
                        <span className="text-red text-xl -rotate-45">👨‍🏫</span>
                      </div>
                      <h3 className="font-bold text-red">Expert Access</h3>
                    </div>
                    <p className="text-sm">
                      Connect with subject matter experts for personalized
                      guidance
                    </p>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </motion.section>

          {/* Values Section */}
          <motion.section ref={valuesRef} className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{
                opacity: valuesInView ? 1 : 0,
                y: valuesInView ? 0 : 30,
              }}
              transition={{ duration: 0.7 }}
            >
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 rounded-sm rotate-45 border-gold border-2 border-b-4 border-r-4 flex items-center justify-center mr-4">
                  <span className="text-white text-2xl -rotate-45 text-outline-gold">
                    ⭐
                  </span>
                </div>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-headers font-bold">
                  Our Values
                </h2>
              </div>

              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate={valuesInView ? "visible" : "hidden"}
                className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4"
              >
                {valuesData.map((value, index) => {
                  const colors = getColorClasses(value.color);
                  return (
                    <motion.div
                      key={index}
                      variants={itemVariants}
                      whileHover={{
                        scale: 1.05,
                        rotate: [0, 1, -1, 1, 0],
                        transition: { duration: 0.3 },
                      }}
                      className={`border-2 border-b-4 ${colors.border} rounded-lg bg-white shadow-lg p-6 text-center flex items-center justify-center`}
                    >
                      <span
                        className={`text-lg md:text-xl font-bold font-headers ${colors.text}`}
                      >
                        {value.name}
                      </span>
                    </motion.div>
                  );
                })}
              </motion.div>

              <div className="mt-6 text-center">
                <p className="text-sm italic text-gray">
                  <span className="font-semibold">Please note:</span> We still
                  haven't fully figured out what these values truly mean to us
                  yet, but they do sound appropriate for now. 🤓
                </p>
              </div>
            </motion.div>
          </motion.section>

          {/* Work with Us Section */}
          <motion.section ref={workRef} className="mb-20">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: workInView ? 1 : 0, y: workInView ? 0 : 30 }}
              transition={{ duration: 0.7 }}
            >
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-green flex items-center justify-center mr-4">
                  <span className="text-white text-xl -rotate-45 text-outline-green">
                    👥
                  </span>
                </div>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-headers font-bold">
                  Work with Us
                </h2>
              </div>

              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-green shadow-lg relative overflow-hidden">
                <div className="absolute top-0 right-0 w-24 h-24 bg-green/10 rounded-full -mr-8 -mt-8 z-0"></div>
                <div className="absolute bottom-0 left-0 w-40 h-40 bg-gold/10 rounded-full -ml-16 -mb-16 z-0"></div>

                <div className="relative z-10">
                  <p className="text-md md:text-lg mb-4">
                    We are a 100% remote and largely asynchronous workplace,
                    offering flexibility without compromising on quality. We're
                    devoted to our community, pledging 35% of our equity to
                    enhance communal well-being through our Lekgotla.
                  </p>
                  <p className="text-md md:text-lg mb-4">
                    We invite you to be a part of a community that values
                    learning as a lifelong journey. With FFA, learning is not
                    just a one-time affair but a lifestyle.
                  </p>
                  <p className="text-md md:text-lg">
                    If you're tired of a traditional work environment and want
                    to be a part of something groundbreaking,
                    <Link to="/careers">
                      <motion.span
                        className="inline-block ml-2 px-4 py-2 bg-green text-white font-bold rounded-md hover:bg-green-700 transition-colors"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        apply today!
                      </motion.span>
                    </Link>
                  </p>
                </div>
              </div>
            </motion.div>
          </motion.section>

          {/* CTA Section */}
          <motion.section
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="mb-20 text-center"
          >
            <div className="bg-gradient-to-r from-red to-gold rounded-lg py-12 px-6 shadow-xl">
              <h2 className="text-3xl font-bold text-white font-headers mb-4">
                Start Your Pan-African Pilgrimage Today!
              </h2>
              <p className="text-white text-lg mb-8 max-w-3xl mx-auto">
                Learn African languages in a fun and engaging way that connects
                you to cultures and stories across the continent.
              </p>
              <motion.div
                className="flex flex-wrap justify-center gap-4"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <motion.a
                  href="/pricing"
                  className="px-8 py-3 bg-white text-red font-bold rounded-md border-2 border-b-4 border-white shadow-lg hover:bg-white/90 transition-colors"
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  See Our Plans
                </motion.a>
                <motion.a
                  href="/early-access"
                  className="px-8 py-3 bg-transparent text-white font-bold rounded-md border-2 border-b-4 border-white shadow-lg hover:bg-white/10 transition-colors"
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Join Waitlist
                </motion.a>
              </motion.div>
            </div>
          </motion.section>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-b from-white to-gold w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default AboutPage;
