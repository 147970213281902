// components/notifications/notification-error-boundary.jsx
import React from "react";

class NotificationErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("Notification error caught:", error);
    this.setState({ errorInfo });

    // Log this error to your analytics or monitoring service
    // This helps track how many users are affected
    try {
      // Example: Send to your analytics service
      // analytics.logEvent('notification_error', {
      //   error: error.message,
      //   userAgent: navigator.userAgent
      // });
    } catch (e) {
      // Ignore analytics errors
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="notification-error-fallback">
          {/* Render nothing or a very minimal UI */}
          {this.props.fallback || null}
        </div>
      );
    }

    return this.props.children;
  }
}

export default NotificationErrorBoundary;
