// src/notification-polyfill.js

// Create polyfill if Notification is undefined
if (typeof Notification === "undefined") {
  console.warn("Notification API not available - using polyfill");

  // Create minimal mock implementation
  window.Notification = function (title, options) {
    console.log("Would show notification:", title, options);
    // This constructor does nothing but prevents errors
    this.title = title;
    this.options = options;
  };

  // Mock permission values
  window.Notification.permission = "denied";

  // Mock the requestPermission method
  window.Notification.requestPermission = function () {
    console.log("Notification permission requested but API not available");
    return Promise.resolve("denied");
  };
}

// No export needed - this file just needs to be imported
