// components/articles/category-list.jsx
import React from "react";
import { Link } from "react-router-dom";

const CategoryList = ({ categories }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {categories.map((category) => (
        <Link
          key={category.id}
          to={`/articles/category/${category.slug}`}
          className="block p-6 border-2 border-b-4 border-darkgray rounded hover:border-green transition-colors"
        >
          <div className="flex items-center mb-3">
            <span className="text-2xl mr-3">{category.icon}</span>
            <h3 className="text-lg font-bold">{category.title}</h3>
          </div>
          <p className="text-sm text-gray-600">{category.description}</p>
          <div className="mt-4 text-red text-sm font-medium">
            View articles ({category.articles.length}) →
          </div>
        </Link>
      ))}
    </div>
  );
};

export default CategoryList;
