import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { db } from "../../../firebase/firebase.utils";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../../../helpers/UserAuthContextProvider";
import BeatLoader from "react-spinners/BeatLoader";

function CreateCustomDeckModal({ onClose, onDeckCreated }) {
  const { user } = useAuth();
  const [deckName, setDeckName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  const [difficulty, setDifficulty] = useState(1);
  const [isPublic, setIsPublic] = useState(false);
  const [cards, setCards] = useState([
    { targetWord: "", translation: "", hint: "", example: "", id: Date.now() },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  // Add tag
  const handleAddTag = () => {
    if (currentTag.trim() && !tags.includes(currentTag.trim())) {
      setTags([...tags, currentTag.trim()]);
      setCurrentTag("");
    }
  };

  // Remove tag
  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  // Add card
  const handleAddCard = () => {
    setCards([
      ...cards,
      {
        targetWord: "",
        translation: "",
        hint: "",
        example: "",
        id: Date.now(),
      },
    ]);
  };

  // Remove card
  const handleRemoveCard = (cardId) => {
    // Ensure we don't remove all cards
    if (cards.length > 1) {
      setCards(cards.filter((card) => card.id !== cardId));
    }
  };

  // Update card field
  const handleCardChange = (cardId, field, value) => {
    setCards(
      cards.map((card) =>
        card.id === cardId ? { ...card, [field]: value } : card
      )
    );
  };

  // Form validation
  const validateForm = () => {
    if (!deckName.trim()) {
      setError("Deck name is required");
      return false;
    }

    if (
      cards.some((card) => !card.targetWord.trim() || !card.translation.trim())
    ) {
      setError("All cards must have at least a target word and translation");
      return false;
    }

    return true;
  };

  // Create deck
  const handleCreateDeck = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    setError("");

    try {
      // Create deck document
      const newDeckRef = await addDoc(collection(db, "flashcard_decks"), {
        name: deckName.trim(),
        description: description.trim(),
        createdBy: user.uid,
        createdAt: serverTimestamp(),
        isPublic: isPublic,
        isCustom: true,
        difficulty: difficulty,
        tags: tags,
        cardCount: cards.length,
      });

      // Create card documents
      const cardPromises = cards.map((card, index) =>
        addDoc(collection(db, "flashcards"), {
          deckId: newDeckRef.id,
          targetWord: card.targetWord.trim(),
          translation: card.translation.trim(),
          hint: card.hint.trim(),
          example: card.example.trim(),
          order: index,
          createdBy: user.uid,
          createdAt: serverTimestamp(),
        })
      );

      await Promise.all(cardPromises);

      // Success
      onDeckCreated({
        id: newDeckRef.id,
        name: deckName,
        description: description,
        cardCount: cards.length,
        tags: tags,
        difficulty: difficulty,
        isPublic: isPublic,
        isCustom: true,
      });
    } catch (error) {
      console.error("Error creating custom deck:", error);
      setError("Failed to create deck. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-2 sm:p-4 overflow-y-auto">
        <motion.div
          className="bg-white w-full max-w-3xl rounded-xl shadow-xl my-4 sm:my-8"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ type: "spring", damping: 25 }}
        >
          {/* Header */}
          <div className="bg-green text-white p-4 sm:p-6 sticky top-0 z-10">
            <div className="flex justify-between items-center">
              <h2 className="text-xl sm:text-2xl font-bold">
                Create Custom Deck
              </h2>
              <button
                onClick={onClose}
                className="text-white opacity-70 hover:opacity-100 text-xl p-1"
                disabled={isSubmitting}
              >
                ×
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="p-4 sm:p-6 max-h-[70vh] overflow-y-auto">
            {/* Deck Info */}
            <div className="mb-6">
              <h3 className="font-bold text-base sm:text-lg mb-4">
                Deck Information
              </h3>

              <div className="mb-4">
                <label
                  htmlFor="deckName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Deck Name*
                </label>
                <input
                  id="deckName"
                  type="text"
                  value={deckName}
                  onChange={(e) => setDeckName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green"
                  placeholder="e.g., Common Greetings"
                  disabled={isSubmitting}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green"
                  placeholder="Describe what this deck is about..."
                  rows="3"
                  disabled={isSubmitting}
                ></textarea>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Difficulty Level
                </label>
                <div className="flex flex-wrap items-center">
                  {[1, 2, 3, 4, 5].map((level) => (
                    <button
                      key={level}
                      onClick={() => setDifficulty(level)}
                      className={`w-8 h-8 rounded-full mx-1 mb-1 ${
                        level <= difficulty ? "bg-gold" : "bg-gray-200"
                      }`}
                      disabled={isSubmitting}
                    >
                      {level}
                    </button>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Categories/Tags
                </label>
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <input
                    type="text"
                    value={currentTag}
                    onChange={(e) => setCurrentTag(e.target.value)}
                    className="flex-grow px-3 py-2 border border-gray-300 rounded-md sm:rounded-l-md sm:rounded-r-none focus:outline-none focus:ring-2 focus:ring-green"
                    placeholder="Add a category (e.g., 'Beginner', 'Greetings')"
                    onKeyPress={(e) => e.key === "Enter" && handleAddTag()}
                    disabled={isSubmitting}
                  />
                  <button
                    onClick={handleAddTag}
                    className="px-4 py-2 bg-green text-white rounded-md sm:rounded-l-none sm:rounded-r-md hover:bg-opacity-90"
                    disabled={isSubmitting || !currentTag.trim()}
                  >
                    Add
                  </button>
                </div>
                {tags.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {tags.map((tag, index) => (
                      <span
                        key={index}
                        className="px-3 py-1 bg-green bg-opacity-10 text-green rounded-full text-sm flex items-center"
                      >
                        {tag}
                        <button
                          onClick={() => handleRemoveTag(tag)}
                          className="ml-2 text-xs focus:outline-none"
                          disabled={isSubmitting}
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <div className="mb-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                    className="mr-2"
                    disabled={isSubmitting}
                  />
                  <span className="text-sm">
                    Make this deck public (visible to all users)
                  </span>
                </label>
              </div>
            </div>

            {/* Cards */}
            <div>
              <h3 className="font-bold text-base sm:text-lg mb-4">
                Flashcards
              </h3>

              {cards.map((card, index) => (
                <div
                  key={card.id}
                  className="mb-6 p-3 sm:p-4 border border-gray-200 rounded-lg bg-gray-50"
                >
                  <div className="flex justify-between items-center mb-3">
                    <h4 className="font-medium">Card {index + 1}</h4>
                    <button
                      onClick={() => handleRemoveCard(card.id)}
                      className="text-red-600 hover:text-red-800 p-1"
                      disabled={isSubmitting || cards.length <= 1}
                    >
                      Remove
                    </button>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div>
                      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                        Target Word*
                      </label>
                      <input
                        type="text"
                        value={card.targetWord}
                        onChange={(e) =>
                          handleCardChange(
                            card.id,
                            "targetWord",
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green"
                        placeholder="Word in target language"
                        disabled={isSubmitting}
                      />
                    </div>
                    <div>
                      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                        Translation*
                      </label>
                      <input
                        type="text"
                        value={card.translation}
                        onChange={(e) =>
                          handleCardChange(
                            card.id,
                            "translation",
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green"
                        placeholder="Translation"
                        disabled={isSubmitting}
                      />
                    </div>
                    <div>
                      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                        Hint
                      </label>
                      <input
                        type="text"
                        value={card.hint}
                        onChange={(e) =>
                          handleCardChange(card.id, "hint", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green"
                        placeholder="Hint to help remember this word"
                        disabled={isSubmitting}
                      />
                    </div>
                    <div>
                      <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                        Example
                      </label>
                      <input
                        type="text"
                        value={card.example}
                        onChange={(e) =>
                          handleCardChange(card.id, "example", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green"
                        placeholder="Example usage"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              ))}

              <button
                onClick={handleAddCard}
                className="w-full py-2 border-2 border-dashed border-gray-300 rounded-lg hover:border-green hover:text-green transition-colors"
                disabled={isSubmitting}
              >
                + Add Another Card
              </button>
            </div>

            {/* Error message */}
            {error && (
              <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg text-sm">
                {error}
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="p-4 sm:p-6 border-t sticky bottom-0 bg-white">
            <div className="flex gap-3 justify-end">
              <button
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateDeck}
                className="px-4 py-2 bg-green text-white rounded-lg hover:bg-opacity-90 flex items-center justify-center min-w-[100px]"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <BeatLoader color={"#FFFFFF"} loading={true} size={8} />
                ) : (
                  "Create Deck"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

export default CreateCustomDeckModal;
