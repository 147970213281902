// src/components/.../milestone-card.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../helpers/UserAuthContextProvider";
import { useMilestone } from "../../../helpers/MilestoneProvider";

const MilestoneCard = ({ milestone, onDetailsClick }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { milestone: currentMilestone, setMilestone, loading } = useMilestone();

  const handleMilestone = async () => {
    try {
      if (!user) {
        throw new Error("User not authenticated");
      }

      console.log("[MilestoneCard] Setting milestone:", milestone.name);

      // Disable button during update to prevent multiple clicks
      if (loading) {
        console.log("[MilestoneCard] Update already in progress, please wait");
        return;
      }

      // Only update if the milestone is different
      if (currentMilestone === milestone.name) {
        console.log(
          "[MilestoneCard] Milestone already set, navigating directly"
        );
        navigate("/units");
        return;
      }

      // Update milestone using the provider function
      await setMilestone(milestone.name);

      // Add a small delay to ensure the state update completes
      setTimeout(() => {
        console.log("[MilestoneCard] Navigation to /units");
        navigate("/units");
      }, 100);
    } catch (error) {
      console.error("[MilestoneCard] Error setting milestone:", error);
      alert("Failed to set milestone. Please try again.");
    }
  };

  // The rest of your component remains the same...

  // Handle showing more details
  const handleDetails = (e) => {
    e.preventDefault();
    if (onDetailsClick) {
      // Use custom handler for modal implementation
      onDetailsClick(milestone);
    } else {
      // Default navigation to milestone details page
      navigate(`/milestones/${milestone.id}`);
    }
  };

  // Style setup code remains the same...
  // Set up text segments for highlighting
  const descriptionParts = milestone.tagline.split(milestone.highlightText);

  // Get colors directly from milestone
  const { colors } = milestone;
  const primaryColor =
    colors.primary === "multicolor" ? "#808080" : colors.primary;
  const highlightColor = colors.highlight.split("/")[0];
  const secondaryColor = colors.secondary;

  // Create direct style objects
  const cardStyle = {
    background: `linear-gradient(to bottom right, ${colors.gradient.from.replace(
      /\[|\]|\//g,
      ""
    )}, 
                                ${colors.gradient.via.replace(
                                  /\[|\]|\//g,
                                  ""
                                )}, 
                                ${colors.gradient.to.replace(
                                  /\[|\]|\//g,
                                  ""
                                )})`,
  };

  const progressBarStyle = {
    width: `${milestone.progress}%`,
    background: `linear-gradient(to right, ${secondaryColor}, ${primaryColor})`,
  };

  const highlightStyle = {
    backgroundColor: highlightColor,
    color: "white",
  };

  const buttonStyle = {
    backgroundColor: primaryColor,
  };

  const buttonHoverStyle = {
    backgroundColor: highlightColor,
  };

  const headerStyle = {
    backgroundColor: secondaryColor,
  };

  return (
    <div
      data-testid={`learning-card-${milestone.id}`}
      className="relative mt-4 w-full sm:w-11/12 md:w-5/6 mx-auto p-4 sm:p-6 mb-6 sm:mb-8 rounded border-2 border-b-[6px] shadow-2xl flex flex-col md:flex-row 
            border-black hover:border-[#991616] transition-all duration-300 group hover:scale-[101%]"
      style={cardStyle}
    >
      {/* Card content JSX remains the same */}
      <div className="absolute inset-0 bg-gradient-to-b from-white/10 to-transparent rounded pointer-events-none" />

      <div className="flex-1 pr-0 md:pr-8 text-left relative z-10">
        <div className="mb-4 sm:mb-6">
          <div
            className="w-fit px-3 py-1 sm:px-4 sm:py-2 rounded border-2 border-b-4 border-black shadow-lg"
            style={headerStyle}
          >
            <span className="font-headers font-bold text-xs sm:text-sm md:text-lg uppercase text-black tracking-wide">
              {milestone.name}
            </span>
          </div>
          <h1 className="mt-3 sm:mt-4 font-headers text-base sm:text-xl md:text-2xl text-black drop-shadow-md">
            {milestone.subtitle}
          </h1>
        </div>

        <div className="mb-4 sm:mb-8 h-3 sm:h-4 bg-[#ffcfce]/20 backdrop-blur-sm border-2 border-black border-b-4 rounded-full overflow-hidden">
          <div
            className="h-full rounded-full transition-all duration-500 ease-out-expo"
            style={progressBarStyle}
          >
            <div className="absolute right-0 h-full w-[2px] bg-white/50 animate-pulse" />
          </div>
        </div>

        <p className="mb-4 sm:mb-8 font-medium text-xs sm:text-sm md:text-lg text-black leading-relaxed">
          {descriptionParts[0]}
          {milestone.highlightText && (
            <span className="highlight italic px-1" style={highlightStyle}>
              {milestone.highlightText}
            </span>
          )}
          {descriptionParts.length > 1 ? descriptionParts[1] : ""}
        </p>

        <div className="flex flex-col xs:flex-row gap-3 sm:gap-4">
          <button
            className="px-4 sm:px-8 py-2 sm:py-3 text-black rounded transform
                  active:scale-95 active:translate-y-1 border-2 border-b-4 border-black
                  transition-all duration-150 ease-out-expo font-semibold shadow-lg
                  text-xs sm:text-sm md:text-lg hover:shadow-xl relative overflow-hidden"
            style={buttonStyle}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor;
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor;
            }}
            onClick={handleMilestone}
            disabled={loading}
          >
            <span className="relative z-10">
              {loading ? "Loading..." : "Keep Learning"}
            </span>
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,#ffcfce/10%,transparent)]" />
          </button>

          <button
            onClick={handleDetails}
            className="self-center relative group text-black font-medium text-xs sm:text-sm md:text-lg 
                      border-b-2 border-dashed border-transparent pb-[2px]
                      bg-gradient-to-r from-black to-black bg-[length:0%_2px] bg-no-repeat bg-left-bottom 
                      transition-all duration-300 ease-out-expo hover:bg-[length:100%_2px]"
          >
            See More Details →
          </button>
        </div>
      </div>

      <div className="mt-6 md:mt-0 flex items-center justify-center md:w-2/5 relative">
        <div className="absolute w-[120%] h-[120%] bg-[radial-gradient(circle,#e5e8c7/15%,transparent)]" />
        <img
          src={milestone.image}
          className="h-32 xs:h-40 sm:h-48 md:h-64 max-h-[20rem] transform group-hover:scale-110 md:group-hover:scale-125 group-hover:rotate-6 md:group-hover:rotate-12 transition-all duration-500 ease-out-expo"
          alt={milestone.imageAlt}
        />
      </div>
    </div>
  );
};

export default MilestoneCard;
