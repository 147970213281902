// pages/articles/search.jsx
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import PuffLoader from "react-spinners/PuffLoader";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Breadcrumbs from "../../components/articles/breadcrumbs";
import ArticleList from "../../components/articles/article-list";
import ArticleSearch from "../../components/articles/search";
import Footer from "../../components/footer/footer.component";
import { searchArticles } from "../../zombiedata/articles-2";

const SearchPage = () => {
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("q") || "";
    setQuery(searchQuery);

    if (searchQuery) {
      setLoading(true);
      // Simulate loading time for search results
      setTimeout(() => {
        const foundResults = searchArticles(searchQuery);
        setResults(foundResults);
        setLoading(false);
      }, 500);

      document.title = `Search: ${searchQuery} | Blog | Fast Fluency Africa`;
    } else {
      document.title = "Search | Blog | Fast Fluency Africa";
      setLoading(false);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.search]);

  const breadcrumbItems = [
    { label: "Blog", href: "/articles" },
    { label: "Search Results" },
  ];

  return (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <div className="bg-red w-full">
        <NavBarPrimary />
      </div>

      <main className="flex-grow container mx-auto px-4 py-8">
        <Breadcrumbs items={breadcrumbItems} />

        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-2">
            {query ? `Search Results for "${query}"` : "Search the Blog"}
          </h1>
          {!loading && query && (
            <p className="text-gray-600">
              {results.length} {results.length === 1 ? "result" : "results"}{" "}
              found
            </p>
          )}
        </div>

        <div className="max-w-xl mb-8">
          <ArticleSearch
            defaultValue={query}
            placeholder="Search for articles..."
          />
        </div>

        {loading ? (
          <div className="flex justify-center py-20">
            <PuffLoader color={"#991616"} loading={loading} size={40} />
          </div>
        ) : query ? (
          <ArticleList
            articles={results}
            emptyMessage={`No results found for "${query}". Please try another search term.`}
          />
        ) : (
          <div className="text-center py-10">
            <p className="text-gray-500">
              Enter a search term to find articles.
            </p>
          </div>
        )}
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default SearchPage;
