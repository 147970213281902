// src/components/dialogue/speech-bubble.jsx
import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

// Define app color palette
const APP_COLORS = {
  red: "#991616",
  gold: "#d19a10",
  green: "#848717",
  black: "#000000",
  white: "#FCFCEE",
};

// Gender-based avatar fallbacks
const genderAvatars = {
  male: "👨🏾",
  female: "👩🏾",
  null: "👤",
};

export const SpeechBubble = ({
  text,
  translation,
  speaker,
  gender,
  turn,
  audioUrl,
  previousSpeaker,
  isPlaying = false,
  onPlayAudio,
  standalone = false, // If used standalone, handles its own audio
}) => {
  const [showTranslation, setShowTranslation] = useState(false);
  const [bubbleAudioUrl, setBubbleAudioUrl] = useState("");
  const [isAudioLoading, setIsAudioLoading] = useState(true);
  const [audioError, setAudioError] = useState(null);
  const [localIsPlaying, setLocalIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Determine which side to place the bubble based on turn
  const isLeftSide = turn % 2 === 1;

  // If speaker is null or "null", use the previous speaker
  const actualSpeaker =
    !speaker || speaker === "null" ? previousSpeaker : speaker;

  // Get avatar based on gender (we'll use emoji for now, can be replaced with images)
  const avatar = genderAvatars[gender] || "👤";

  // Determine color based on speaker or position (alternating colors for unknown speakers)
  const getSpeakerColor = (speakerName) => {
    // Assign colors based on the first letter of the speaker's name if available
    if (!speakerName) return APP_COLORS.black;

    const firstChar = speakerName.charAt(0).toLowerCase();

    if (firstChar >= "a" && firstChar <= "f") return APP_COLORS.red;
    if (firstChar >= "g" && firstChar <= "l") return APP_COLORS.gold;
    if (firstChar >= "m" && firstChar <= "s") return APP_COLORS.green;
    return APP_COLORS.black;
  };

  const color = getSpeakerColor(actualSpeaker);

  // Simplify bubble styles - all bubbles have black text on white background
  const bubbleStyles = {
    bg: APP_COLORS.white,
    text: APP_COLORS.black,
    nameColor: color, // Only the speaker name uses their color
    translationColor: "#4A4A4A",
    border: "1px solid #000000",
  };

  // Load audio URL if in standalone mode
  useEffect(() => {
    if (!standalone || !audioUrl) {
      setIsAudioLoading(false);
      return;
    }

    let isMounted = true;

    const loadAudio = async () => {
      setIsAudioLoading(true);
      setAudioError(null);

      try {
        const url = await getAudioDownloadUrl(audioUrl);
        if (isMounted) {
          setBubbleAudioUrl(url);
          setIsAudioLoading(false);
        }
      } catch (err) {
        console.error("Error loading speech bubble audio:", err);
        if (isMounted) {
          setAudioError("Failed to load audio");
          setIsAudioLoading(false);
        }
      }
    };

    loadAudio();

    return () => {
      isMounted = false;
    };
  }, [audioUrl, standalone]);

  // Handle audio playback and events when in standalone mode
  useEffect(() => {
    if (!standalone) return;

    const audio = audioRef.current;

    if (!audio || !bubbleAudioUrl) return;

    const handleEnded = () => {
      setLocalIsPlaying(false);
    };

    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.pause();
    };
  }, [bubbleAudioUrl, standalone, audioRef.current]);

  // Handle parent-controlled playback
  useEffect(() => {
    if (standalone) return; // Skip if in standalone mode

    setLocalIsPlaying(isPlaying);
  }, [isPlaying, standalone]);

  // Handle audio play/pause for standalone mode
  const handlePlayAudio = () => {
    if (!standalone || !audioRef.current) return;

    if (localIsPlaying) {
      audioRef.current.pause();
      setLocalIsPlaying(false);
    } else {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
        setAudioError("Could not play audio");
      });
      setLocalIsPlaying(true);
    }

    if (onPlayAudio) onPlayAudio();
  };

  return (
    <motion.div
      className={`flex items-start gap-3 mb-6 ${
        isLeftSide ? "" : "flex-row-reverse"
      }`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      {standalone && <audio ref={audioRef} src={bubbleAudioUrl} />}

      {/* Avatar */}
      <div
        className="flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center text-xl"
        style={{
          backgroundColor: `${color}20`,
          border: `2px solid ${color}`,
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {avatar}
      </div>

      {/* Speech bubble */}
      <div
        className={`relative max-w-[85%] border-2 border-b-4 p-4 ${
          isLeftSide ? "rounded-tl-none" : "rounded-tr-none"
        }`}
        style={{
          backgroundColor: bubbleStyles.bg,
          color: bubbleStyles.text,
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      >
        {/* Speaker name if available */}
        {actualSpeaker && (
          <div
            className="text-xs font-bold mb-1"
            style={{ color: bubbleStyles.nameColor }}
          >
            {actualSpeaker}
          </div>
        )}

        {/* Original text */}
        <p className="text-lg mb-1">{text}</p>

        {/* Audio button for standalone mode */}
        {standalone && audioUrl && (
          <button
            onClick={handlePlayAudio}
            disabled={isAudioLoading}
            className={`mt-1 text-xs flex items-center ${
              isAudioLoading
                ? "text-gray-400"
                : "text-gold hover:text-gold-dark"
            }`}
          >
            <span className="mr-1">
              {isAudioLoading ? "🔄" : localIsPlaying ? "⏸️" : "▶️"}
            </span>
            <span>
              {isAudioLoading
                ? "Loading audio..."
                : localIsPlaying
                ? "Pause"
                : "Listen"}
            </span>
          </button>
        )}

        {/* Translation toggle */}
        {translation && (
          <>
            <button
              onClick={() => setShowTranslation(!showTranslation)}
              className="text-xs underline mt-1"
              style={{ color: bubbleStyles.translationColor }}
            >
              {showTranslation ? "Hide translation" : "Show translation"}
            </button>

            {showTranslation && (
              <motion.p
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                className="text-sm italic mt-2"
                style={{ color: bubbleStyles.translationColor }}
              >
                "{translation}"
              </motion.p>
            )}
          </>
        )}

        {/* Visual indicator for audio playing */}
        {(isPlaying || localIsPlaying) && (
          <div className="absolute top-2 right-2">
            <div className="flex space-x-1">
              <motion.div
                className="w-1.5 h-1.5 bg-gold rounded-full"
                animate={{ scaleY: [0.4, 1, 0.4] }}
                transition={{ duration: 0.8, repeat: Infinity }}
              />
              <motion.div
                className="w-1.5 h-1.5 bg-gold rounded-full"
                animate={{ scaleY: [0.4, 1, 0.4] }}
                transition={{ duration: 0.8, repeat: Infinity, delay: 0.2 }}
              />
              <motion.div
                className="w-1.5 h-1.5 bg-gold rounded-full"
                animate={{ scaleY: [0.4, 1, 0.4] }}
                transition={{ duration: 0.8, repeat: Infinity, delay: 0.4 }}
              />
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

SpeechBubble.propTypes = {
  text: PropTypes.string.isRequired,
  translation: PropTypes.string,
  speaker: PropTypes.string,
  gender: PropTypes.string,
  turn: PropTypes.number,
  audioUrl: PropTypes.string,
  previousSpeaker: PropTypes.string,
  isPlaying: PropTypes.bool,
  onPlayAudio: PropTypes.func,
  standalone: PropTypes.bool,
};

SpeechBubble.defaultProps = {
  turn: 1,
  gender: "male",
  previousSpeaker: "",
  isPlaying: false,
  onPlayAudio: () => {},
  standalone: false,
};
