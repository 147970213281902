// components/modals/XpModalContent.jsx - Mobile Responsive Version
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import AchievementsList from "../achievements/achievements-list";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";
import { FaStar, FaHeart, FaBook, FaTrophy, FaChartBar } from "react-icons/fa";

const XpModalContent = ({ onClose }) => {
  const { user } = useAuth();
  const { powerUpStatus, refreshPowerUpStatus } = useShopWithPowerUps();

  const {
    userStats,
    todayXp,
    weeklyXp,
    totalXp,
    streak,
    isLoading,
    refreshAnalytics,
    calculateLevel,
    getLevelProgress,
    achievements,
  } = useSafeAnalytics();

  // Only refresh once when the modal opens
  useEffect(() => {
    if (user?.uid) {
      // Only refresh once when component mounts, not repeatedly
      refreshAnalytics(false); // Use false to avoid forced refresh
    }
  }, [user?.uid]);

  // Local state
  const [activeTab, setActiveTab] = useState("stats");
  const [xpBoost, setXpBoost] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);

  // Update XP boost information whenever powerUpStatus or userStats changes
  useEffect(() => {
    // Priority 1: Check power-ups from the hook (real-time)
    if (powerUpStatus?.xpBoost?.active) {
      setXpBoost({
        multiplier: powerUpStatus.xpBoost.multiplier,
        expiresAt: powerUpStatus.xpBoost.expiresAt,
      });
    }
    // Priority 2: Check data from Firebase (fallback)
    else if (userStats?.xp_boost?.active) {
      setXpBoost({
        multiplier: userStats.xp_boost.multiplier,
        expiresAt: userStats.xp_boost.expiresAt,
      });
    }
    // Priority 3: Check legacy data structure
    else if (userStats?.xpMultiplier && userStats.xpMultiplier > 1) {
      setXpBoost({
        multiplier: userStats.xpMultiplier,
        expiresAt: userStats.xpBoostExpiresAt,
      });
    }
    // No boost active
    else {
      setXpBoost(null);
    }
  }, [powerUpStatus, userStats]);

  // Also refresh power-up status when component mounts
  useEffect(() => {
    if (refreshPowerUpStatus) {
      refreshPowerUpStatus();
    }
  }, [refreshPowerUpStatus]);

  // Use context-provided level calculations
  const currentLevel = calculateLevel();
  const nextLevel = currentLevel + 1;
  const progressToNextLevel = getLevelProgress();

  // Calculate XP needed for next level
  const xpForNextLevel = nextLevel * 1000;
  const currentTotalXp = totalXp; // Use the direct accessor
  const xpNeeded = xpForNextLevel - currentTotalXp;

  // Format date for display
  const formatExpiryDate = (dateValue) => {
    if (!dateValue) return "Unknown";

    const date =
      typeof dateValue === "object" ? dateValue : new Date(dateValue);

    // If invalid date
    if (isNaN(date.getTime())) return "Unknown";

    // Get current date for comparison
    const now = new Date();
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    // Format time
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const timeString = `${formattedHours}:${formattedMinutes} ${ampm}`;

    if (isToday) {
      return `Today at ${timeString}`;
    } else {
      // For tomorrow or later dates
      return date.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      });
    }
  };

  // Enhanced generateRecentActivity function that includes practice activities
  const generateRecentActivity = () => {
    const activities = [];

    // Check if we have recent_xp_activities from userStats
    if (
      userStats?.recent_xp_activities &&
      userStats.recent_xp_activities.length > 0
    ) {
      // Clone activities
      activities.push(...userStats.recent_xp_activities);
    }

    // Check for today's practice activity if not already in the list
    const today = new Date().toISOString().split("T")[0];
    if (userStats?.daily_activity?.[today]?.practice_questions > 0) {
      // Check if we already have a practice session for today
      const hasPracticeActivity = activities.some(
        (activity) =>
          activity.source === "Practice session" &&
          formatActivityDate(activity.date) === "Today"
      );

      // If no practice activity is in the list yet, add one
      if (!hasPracticeActivity) {
        const practiceXp =
          userStats.daily_activity[today].practice_questions * 2; // 2 XP per question
        activities.push({
          date: new Date(),
          source: "Practice session",
          xp: practiceXp,
        });
      }
    }

    // If we still have no activities, provide fallback data
    if (activities.length === 0) {
      return [
        { date: new Date(), source: "Lesson completed", xp: 10 },
        {
          date: new Date(Date.now() - 86400000),
          source: "Practice session",
          xp: 5,
        },
        {
          date: new Date(Date.now() - 86400000 * 2),
          source: "Streak bonus",
          xp: 15,
        },
      ];
    }

    // Sort by date (newest first) and limit to 5
    return activities
      .sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      })
      .slice(0, 5);
  };

  const formatActivityDate = (dateValue) => {
    if (!dateValue) return "Unknown";

    const date =
      typeof dateValue === "object" ? dateValue : new Date(dateValue);

    // If invalid date
    if (isNaN(date.getTime())) return "Unknown";

    // Get current date for comparison
    const now = new Date();
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    const isYesterday =
      now.getDate() - date.getDate() === 1 &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    if (isToday) {
      return "Today";
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
      });
    }
  };

  // Midnight check - if it's just after midnight, show a message
  const isMidnightRefresh = () => {
    const now = new Date();
    return now.getHours() < 1 && todayXp === 0 && weeklyXp > 0;
  };

  // Manual refresh function (as a fallback)
  const forceFullRefresh = () => {
    console.log("Manual refresh requested");
    setRefreshCount((prev) => prev + 1);

    // Call context's refresh method
    refreshAnalytics(true);

    // Also refresh power-ups
    if (refreshPowerUpStatus) {
      refreshPowerUpStatus();
    }
  };

  // Get recent activities
  const recentActivities = generateRecentActivity();

  return (
    <div className="space-y-3 md:space-y-4 px-2 md:px-0">
      {isLoading ? (
        <div className="text-center p-4 md:p-6">
          <div className="inline-block animate-spin rounded-full h-6 w-6 md:h-8 md:w-8 border-b-2 border-green mb-2 md:mb-3"></div>
          <p className="text-sm md:text-base">Loading XP data...</p>
        </div>
      ) : (
        <>
          <div className="text-center">
            <span className="text-3xl md:text-4xl">🏆</span>
            <h3 className="text-xl md:text-2xl font-bold mt-1 md:mt-2">
              Your XP: {currentTotalXp}
            </h3>
            <p className="text-xs md:text-sm text-gray mt-1">
              Earn XP by completing lessons and exercises
            </p>
          </div>

          {/* XP Boost Banner */}
          {xpBoost && (
            <div className="border-gold border-2 border-b-4 text-gold p-2 md:p-3 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-bold text-sm md:text-base">
                    XP Boost Active!
                  </p>
                  <p className="text-xs md:text-sm">
                    Earning {xpBoost.multiplier}× XP until{" "}
                    {formatExpiryDate(xpBoost.expiresAt)}
                  </p>
                </div>
                <div className="bg-white text-gold h-8 w-8 md:h-10 md:w-10 rounded-full flex items-center justify-center font-bold text-base md:text-lg">
                  {xpBoost.multiplier}×
                </div>
              </div>
            </div>
          )}

          {/* Midnight message */}
          {isMidnightRefresh() && (
            <div className="border-green border-2 border-b-4 text-green p-2 md:p-3 rounded-lg">
              <div className="flex items-center">
                <div>
                  <p className="font-bold text-sm md:text-base">
                    It's a new day!
                  </p>
                  <p className="text-xs md:text-sm">
                    Your Today's XP counter has reset. Start earning more XP!
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Tabs */}
          <div className="flex border-b">
            <button
              onClick={() => setActiveTab("stats")}
              className={`py-1 md:py-2 px-3 md:px-4 text-sm md:text-base font-medium ${
                activeTab === "stats"
                  ? "border-b-2 border-green text-green"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              Stats & Progress
            </button>
            <button
              onClick={() => setActiveTab("achievements")}
              className={`py-1 md:py-2 px-3 md:px-4 text-sm md:text-base font-medium ${
                activeTab === "achievements"
                  ? "border-b-2 border-green text-green"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              Achievements
            </button>
          </div>

          {activeTab === "stats" ? (
            <>
              {/* Level progress section */}
              <div className="border-darkgray border-b-4 border-2 rounded p-3 md:p-4">
                <div className="flex justify-between items-end mb-1 md:mb-2">
                  <div>
                    <p className="text-xs md:text-sm text-gray">
                      Current Level
                    </p>
                    <p className="text-xl md:text-3xl font-bold">
                      {currentLevel}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-xs md:text-sm text-gray">Next Level</p>
                    <p className="text-xl md:text-3xl font-bold">{nextLevel}</p>
                  </div>
                </div>

                <div className="w-full bg-darkgray border border-gray rounded-full h-3 md:h-4 mb-1">
                  <div
                    className="bg-green h-full rounded-full"
                    style={{ width: `${progressToNextLevel}%` }}
                  ></div>
                </div>

                <div className="text-center text-xs md:text-sm text-gray-600">
                  {xpNeeded} XP needed for next level
                </div>
              </div>

              {/* XP stats cards */}
              <div className="grid grid-cols-2 gap-2 md:gap-4">
                <div className="border-darkgray border-2 border-b-4 rounded p-2 md:p-4">
                  <p className="text-xs md:text-sm text-gray-600">Today's XP</p>
                  <p className="text-lg md:text-2xl font-bold">{todayXp}</p>
                  {xpBoost && (
                    <p className="text-xs text-gold mt-1">
                      With {xpBoost.multiplier}× boost!
                    </p>
                  )}
                </div>
                <div className="border-darkgray border-2 border-b-4 rounded p-2 md:p-4">
                  <p className="text-xs md:text-sm text-gray-600">Weekly XP</p>
                  <p className="text-lg md:text-2xl font-bold">{weeklyXp}</p>
                </div>
              </div>

              {/* Recent activity section */}
              <div className="border-darkgray border-b-4 border-2 rounded p-3 md:p-4">
                <h4 className="font-semibold text-sm md:text-base mb-2">
                  Recent Activity
                </h4>
                <div className="space-y-2">
                  {recentActivities.map((activity, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <div>
                        <p className="text-xs md:text-sm font-medium">
                          {activity.source}
                        </p>
                        <p className="text-xs text-gray-500">
                          {formatActivityDate(activity.date)}
                        </p>
                      </div>
                      <div className="bg-green bg-opacity-10 text-green rounded-full px-2 py-1 text-xs md:text-sm font-bold">
                        +{activity.xp} XP
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* How to earn XP section */}
              <div className="border-darkgray border-b-4 border-2 p-3 md:p-4 rounded">
                <h4 className="font-semibold mb-1 md:mb-2 text-sm md:text-base">
                  Ways to Earn XP
                </h4>
                <ul className="space-y-1 text-xs md:text-sm text-gray-700">
                  <li>• Complete lessons (+10-40 XP)</li>
                  <li>• Perfect scores (+5 XP bonus)</li>
                  <li>• Practice exercises (+2 XP per question)</li>
                  <li>• Maintain your streak (bonus XP every 7 days)</li>
                  <li>• Complete achievements (+5-100 XP)</li>
                </ul>

                <Link to="/vault" onClick={onClose}>
                  <button className="w-full mt-3 bg-green text-black py-2 rounded border-b-4 border-2 border-black hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center text-xs md:text-sm">
                    <span className="mr-2">📚</span>
                    Continue Learning
                  </button>
                </Link>
              </div>
            </>
          ) : (
            <div className="max-h-[60vh] overflow-y-auto">
              <AchievementsList preloadedAchievements={achievements} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default XpModalContent;
