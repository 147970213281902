// pages/articles/[article-slug].jsx
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Breadcrumbs from "../../components/articles/breadcrumbs";
import Footer from "../../components/footer/footer.component";
import RelatedArticles from "../../components/articles/related-articles";
import {
  getArticleBySlug,
  getRelatedArticles,
} from "../../zombiedata/articles-2";

// Utility function to parse HTML content
const createMarkup = (html) => {
  return { __html: html };
};

const BlogPage = () => {
  const { articleSlug } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = () => {
      setLoading(true);
      // Get article data
      const foundArticle = getArticleBySlug(articleSlug);

      if (!foundArticle) {
        // If article not found, redirect to articles index
        navigate("/articles");
        return;
      }

      setArticle(foundArticle);

      // Get related articles
      if (foundArticle.id) {
        const related = getRelatedArticles(foundArticle.id);
        setRelatedArticles(related);
      }

      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    fetchArticle();
  }, [articleSlug, navigate]);

  useEffect(() => {
    if (article) {
      document.title = `${article.title} | Fast Fluency Africa`;
    }
  }, [article]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [articleSlug]);

  // Return loading screen while fetching article
  if (loading) {
    return (
      <div
        data-testid="loading-spinner"
        className="flex flex-row justify-center items-center h-screen"
      >
        <PuffLoader color={"#991616"} loading={loading} size={100} />
      </div>
    );
  }

  // Return early if article not found
  if (!article) return null;

  // Build breadcrumb items
  const breadcrumbItems = [
    { label: "Blog", href: "/articles" },
    {
      label: article.categoryTitle,
      href: `/articles/category/${article.categorySlug}`,
    },
    { label: article.title },
  ];

  return (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <div className="bg-red w-full">
        <NavBarPrimary />
      </div>

      <div className="flex flex-row justify-start items-center p-8 w-full bg-gradient-to-b from-red to-black">
        <div className="container mx-auto">
          <h1 className="text-white font-headers text-2xl md:text-3xl lg:text-4xl uppercase font-bold">
            {article.title}
          </h1>
        </div>
      </div>

      <main className="flex-grow container mx-auto px-4 py-8">
        <Breadcrumbs items={breadcrumbItems} />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <article className="bg-white p-6 border-2 border-darkgray rounded-lg">
              <div className="flex flex-wrap mb-4">
                <span className="text-gray-500 mr-4">{article.author}</span>
                <span className="text-gray-500 mr-4">
                  {new Date(article.publishDate).toLocaleDateString()}
                </span>
                <span className="text-gray-500">⏱️ {article.readTime}</span>
              </div>

              {/* Display featured image if available */}
              {article.image && (
                <div className="mb-6">
                  <img
                    src={`${article.image}`}
                    alt={article.imageAlt || "Article featured image"}
                    className="w-full rounded-lg"
                  />
                  {article.imageCaption && (
                    <p className="text-center text-gray-500 text-sm mt-2">
                      {article.imageCaption}
                    </p>
                  )}
                </div>
              )}

              {/* Article tags */}
              <div className="flex flex-wrap gap-2 mb-6">
                {article.tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-3 py-1 bg-gray-100 text-xs rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>

              {/* Article content */}
              <div
                className="prose max-w-none"
                dangerouslySetInnerHTML={createMarkup(article.content)}
              />

              {/* Article footer */}
              <div className="mt-8 pt-6 border-t border-gray-200">
                <p className="text-sm text-gray-500">
                  Last updated:{" "}
                  {new Date(article.lastUpdated).toLocaleDateString()}
                </p>

                <div className="mt-4">
                  <p className="text-sm">Was this article helpful?</p>
                  <div className="flex gap-2 mt-2">
                    <button className="px-4 py-2 border border-green text-green rounded hover:bg-green hover:text-white transition-colors">
                      Yes
                    </button>
                    <button className="px-4 py-2 border border-red text-red rounded hover:bg-red hover:text-white transition-colors">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div>
            {/* Related articles sidebar */}
            <div className="bg-gray-50 p-5 rounded-lg">
              <h3 className="font-bold mb-4">Related Articles</h3>
              <RelatedArticles articles={relatedArticles} />
            </div>

            {/* CTA box */}
            <div className="mt-6 bg-gray-50 p-5 rounded-lg">
              <h3 className="font-bold mb-4">
                Start Learning African Languages
              </h3>
              <p className="text-sm mb-4">
                Inspired by this article? Take the next step in your language
                learning journey with Fast Fluency Africa!
              </p>
              <a
                href="/login"
                className="block w-full py-2 px-4 bg-red text-white text-center rounded-lg hover:bg-red-dark transition-colors"
              >
                Get Started Free
              </a>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default BlogPage;
