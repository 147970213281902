import { db } from "../../firebase/firebase.utils";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  increment,
  serverTimestamp,
} from "firebase/firestore";

// Knowledge levels
const KNOWLEDGE_LEVELS = {
  UNKNOWN: 0, // Never seen or always wrong
  LEARNING: 1, // Sometimes correct
  FAMILIAR: 2, // Usually correct
  MASTERED: 3, // Consistently correct
};

class UserProgressService {
  /**
   * Get user progress for all decks
   * @param {string} userId - Firebase auth user ID
   * @returns {Promise<Object>} - Map of deck IDs to progress data
   */
  async getAllUserProgress(userId) {
    if (!userId) return {};

    try {
      // Get the user's progress document
      const userProgressRef = doc(db, "user_progress", userId);
      const userProgressSnap = await getDoc(userProgressRef);

      if (userProgressSnap.exists()) {
        return userProgressSnap.data().decks || {};
      }

      // If no progress document exists yet, create an empty one
      await setDoc(userProgressRef, {
        decks: {},
        lastUpdated: serverTimestamp(),
      });
      return {};
    } catch (error) {
      console.error("Error fetching user progress:", error);
      return {};
    }
  }

  /**
   * Get user progress for specific decks
   * @param {string} userId - Firebase auth user ID
   * @param {Array<string>} deckIds - Array of deck IDs to get progress for
   * @returns {Promise<Object>} - Map of deck IDs to progress data
   */
  async getDeckProgress(userId, deckIds) {
    if (!userId || !deckIds || deckIds.length === 0) return {};

    try {
      const allProgress = await this.getAllUserProgress(userId);

      // Filter to only the requested decks
      const filteredProgress = {};
      for (const deckId of deckIds) {
        filteredProgress[deckId] = allProgress[deckId] || {
          completedCards: 0,
          masteredCards: 0,
          lastStudied: null,
          cards: {},
        };
      }

      return filteredProgress;
    } catch (error) {
      console.error("Error fetching specific deck progress:", error);
      return {};
    }
  }

  /**
   * Update progress for a specific card
   * @param {string} userId - Firebase auth user ID
   * @param {string} deckId - Deck ID
   * @param {string} cardId - Card ID
   * @param {Object} progressData - Progress data to update
   * @returns {Promise<void>}
   */
  async updateCardProgress(userId, deckId, cardId, progressData) {
    if (!userId || !deckId || !cardId) return;

    try {
      const userProgressRef = doc(db, "user_progress", userId);
      const userProgressSnap = await getDoc(userProgressRef);

      // Prepare the update object for the specific card
      const cardPath = `decks.${deckId}.cards.${cardId}`;
      const updateObj = {};

      // Add each progress field to the update object
      Object.entries(progressData).forEach(([key, value]) => {
        updateObj[`${cardPath}.${key}`] = value;
      });

      // Also update the lastStudied timestamp for the deck
      updateObj[`decks.${deckId}.lastStudied`] = serverTimestamp();

      // Check if this card is now mastered
      const wasMastered =
        userProgressSnap.exists() &&
        userProgressSnap.data().decks?.[deckId]?.cards?.[cardId]
          ?.knowledgeLevel === KNOWLEDGE_LEVELS.MASTERED;

      const isNowMastered =
        progressData.knowledgeLevel === KNOWLEDGE_LEVELS.MASTERED;

      // Update mastered count if status changed
      if (!wasMastered && isNowMastered) {
        updateObj[`decks.${deckId}.masteredCards`] = increment(1);
      } else if (wasMastered && !isNowMastered) {
        updateObj[`decks.${deckId}.masteredCards`] = increment(-1);
      }

      // If document doesn't exist yet, create it
      if (!userProgressSnap.exists()) {
        await setDoc(userProgressRef, {
          userId,
          decks: {
            [deckId]: {
              lastStudied: serverTimestamp(),
              masteredCards: isNowMastered ? 1 : 0,
              completedCards: 1,
              cards: {
                [cardId]: progressData,
              },
            },
          },
          lastUpdated: serverTimestamp(),
        });
      } else {
        // Update the existing document
        await updateDoc(userProgressRef, {
          ...updateObj,
          lastUpdated: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Error updating card progress:", error);
    }
  }

  /**
   * Calculate overall progress for a deck
   * @param {string} userId - Firebase auth user ID
   * @param {string} deckId - Deck ID
   * @param {number} totalCards - Total number of cards in the deck
   * @returns {Promise<Object>} Progress statistics
   */
  async calculateDeckProgress(userId, deckId, totalCards) {
    try {
      const progress = await this.getDeckProgress(userId, [deckId]);
      const deckProgress = progress[deckId] || { cards: {}, masteredCards: 0 };

      // Count cards by knowledge level
      const knowledgeLevels = { 0: 0, 1: 0, 2: 0, 3: 0 };
      let cardsWithProgress = 0;

      Object.values(deckProgress.cards || {}).forEach((card) => {
        const level = card.knowledgeLevel || 0;
        knowledgeLevels[level]++;
        if (level > 0) cardsWithProgress++;
      });

      return {
        totalCards,
        cardsWithProgress,
        masteredCards: knowledgeLevels[3],
        familiarCards: knowledgeLevels[2],
        learningCards: knowledgeLevels[1],
        unknownCards: totalCards - cardsWithProgress,
        completionPercentage:
          totalCards > 0
            ? Math.round((knowledgeLevels[3] / totalCards) * 100)
            : 0,
        progressPercentage:
          totalCards > 0
            ? Math.round((cardsWithProgress / totalCards) * 100)
            : 0,
        lastStudied: deckProgress.lastStudied || null,
      };
    } catch (error) {
      console.error("Error calculating deck progress:", error);
      return {
        totalCards,
        cardsWithProgress: 0,
        masteredCards: 0,
        familiarCards: 0,
        learningCards: 0,
        unknownCards: totalCards,
        completionPercentage: 0,
        progressPercentage: 0,
        lastStudied: null,
      };
    }
  }
}

export default new UserProgressService();
