import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessage } from "firebase/messaging";
import { messaging, requestToken } from "../../firebase/firebase.utils";
import { useNotifications } from "../../helpers/NotificationsContext";

const NotificationToast = () => {
  const { addNotification } = useNotifications();

  useEffect(() => {
    // Request FCM token silently (without prompting)
    // This will only get a token if permission was previously granted
    const initFCM = async () => {
      try {
        await requestToken(false);
      } catch (err) {
        console.warn("Push notifications not available:", err);
        // Continue with in-app notifications only
      }
    };

    initFCM();

    // Set up message listener with error handling
    const handleMessage = () => {
      try {
        const unsubscribe = onMessage(messaging, (payload) => {
          console.log("Received foreground message:", payload);

          // Extract notification details from payload
          const { notification, data } = payload;

          if (notification) {
            // Show toast notification
            showToast(notification.title, notification.body);

            // Store in Firestore via context
            addNotification({
              title: notification.title,
              message: notification.body,
              type: data?.type || "system",
              read: false,
              link: data?.link || null,
            });
          }
        });

        return unsubscribe;
      } catch (error) {
        console.warn("Firebase messaging setup error:", error);
        return () => {}; // Return empty function if setup fails
      }
    };

    // Only set up FCM listener if permission is granted
    let unsubscribe = () => {};
    if (Notification.permission === "granted") {
      unsubscribe = handleMessage();
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [addNotification]);

  // Function to display toast notifications
  const showToast = (title, body) => {
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full sm:w-4/6 bg-white shadow-lg rounded border-darkgray border-2 border-b-4 pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-3 sm:p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-gold flex items-center justify-center text-white">
                  <span className="text-sm sm:text-base">🔔</span>
                </div>
              </div>
              <div className="ml-3 flex-1">
                <p className="text-xs sm:text-sm font-headers text-gray font-bold truncate">
                  {title}
                </p>
                <p className="mt-1 text-xs sm:text-sm text-gray font-lighter line-clamp-2">
                  {body}
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-2 sm:p-4 flex items-center justify-center text-xs sm:text-sm font-medium text-red hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red"
            >
              Close
            </button>
          </div>
        </div>
      ),
      {
        duration: 5000,
        position: window.innerWidth < 640 ? "bottom-center" : "top-right",
      }
    );
  };

  // Custom Toaster component with responsive positioning
  return (
    <Toaster
      toastOptions={{
        className: "",
        duration: 5000,
        position: window.innerWidth < 640 ? "bottom-center" : "top-right",
        style: {
          maxWidth: window.innerWidth < 640 ? "90vw" : "380px",
        },
      }}
    />
  );
};

export default NotificationToast;
