import React, { useState } from "react";
import { db, auth } from "../../firebase/firebase.utils";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";

/**
 * Utility component to clear all notifications for the current user
 * Add this temporarily to a page to clear notifications
 */
const ClearNotificationsUtility = () => {
  const [isClearing, setIsClearing] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);

  const clearAllNotifications = async () => {
    try {
      setIsClearing(true);
      setResult(null);
      setError(null);

      const user = auth.currentUser;
      if (!user) {
        setError("You must be logged in to clear notifications");
        setIsClearing(false);
        return;
      }

      // Query all notifications for the current user
      const notificationsRef = collection(db, "notifications");
      const q = query(notificationsRef, where("userId", "==", user.uid));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        setResult("No notifications found to delete.");
        setIsClearing(false);
        return;
      }

      // Count notifications
      const count = snapshot.size;
      setNotificationCount(count);

      // Delete all notifications
      const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      // Clear local storage keys related to notifications
      const keys = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (
          key &&
          (key.startsWith("booking_processed_") ||
            key.startsWith("cancel_processed_") ||
            key.includes("notification"))
        ) {
          keys.push(key);
        }
      }

      // Remove the keys
      keys.forEach((key) => localStorage.removeItem(key));

      setResult(
        `Successfully deleted ${count} notifications and cleared ${keys.length} localStorage keys.`
      );

      // Also clear session storage
      const sessionKeys = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (
          key &&
          (key.startsWith("processed_") ||
            key.includes("booking") ||
            key.includes("notification"))
        ) {
          sessionKeys.push(key);
        }
      }

      // Remove the session keys
      sessionKeys.forEach((key) => sessionStorage.removeItem(key));

      setResult(
        `Successfully deleted ${count} notifications, cleared ${keys.length} localStorage keys and ${sessionKeys.length} sessionStorage keys.`
      );
    } catch (error) {
      console.error("Error clearing notifications:", error);
      setError(`Error clearing notifications: ${error.message}`);
    } finally {
      setIsClearing(false);
    }
  };

  return (
    <div className="">
      {/* <h2 className="text-xl font-bold mb-4">Notification Cleanup Utility</h2> */}
      <button
        onClick={clearAllNotifications}
        disabled={isClearing}
        className="px-4 py-2 bg-red hover:bg-red/90 disabled:opacity-50 border-black border-b-4 border-2 text-black rounded text-sm"
      >
        {isClearing ? "Clearing..." : "Clear All Notifications"}
      </button>

      {/* {result && (
        <div className="mt-4 p-3 bg-green/10 text-green border border-green/20 rounded">
          {result}
        </div>
      )} */}

      {error && (
        <div className="mt-4 p-3 bg-red/10 text-red border border-red/20 rounded">
          {error}
        </div>
      )}

      {/* {notificationCount > 0 && (
        <div className="mt-4">
          <p>Deleted {notificationCount} notifications.</p>
        </div>
      )} */}
    </div>
  );
};

export default ClearNotificationsUtility;
