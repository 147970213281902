// pages/support/article/[articleId].jsx
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/firebase.utils";
import { doc, getDoc, setDoc, updateDoc, increment } from "firebase/firestore";
import { PuffLoader } from "react-spinners";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import SupportHeader from "../../../components/support/support-header";
import Breadcrumbs from "../../../components/support/breadcrumbs";
import ArticleList from "../../../components/support/article-list";
import Footer from "../../../components/footer/footer.component";
import {
  getArticleById,
  getRelatedArticles,
} from "../../../zombiedata/support-articles";
import FeedbackMenu from "../../../components/support/feedback-menu";

const ArticlePage = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const article = getArticleById(articleId);
  const relatedArticles = article ? getRelatedArticles(article.id) : [];
  const [loading, setLoading] = useState(false);
  // Inside the ArticlePage component, add these states
  const [hasVoted, setHasVoted] = useState(false);
  const [voteSubmitted, setVoteSubmitted] = useState(false);
  const [voteFeedback, setVoteFeedback] = useState("");
  // Utility function to create markup
  const createMarkup = (html) => {
    return { __html: html };
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (!article) {
      navigate("/support");
      return;
    }

    document.title = `${article.title} | Support Center | Fast Fluency Africa`;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [article, articleId, navigate]);

  // Add this useEffect to check if user has voted
  useEffect(() => {
    const voteStatus = localStorage.getItem(`article-vote-${articleId}`);
    if (voteStatus) {
      setHasVoted(true);
    }
  }, [articleId]);

  if (!article) return null;

  const breadcrumbItems = [
    { label: "Support Center", href: "/support" },
    {
      label: article.categoryTitle,
      href: `/support/category/${article.categorySlug}`,
    },
    { label: article.title },
  ];

  // Add this function to handle votes
  const handleVote = async (isHelpful) => {
    try {
      const articleRef = doc(db, "support_articles", articleId);

      // Check if document exists
      const docSnap = await getDoc(articleRef);

      if (docSnap.exists()) {
        // Document exists, update it
        await updateDoc(articleRef, {
          [isHelpful ? "helpfulVotes" : "notHelpfulVotes"]: increment(1),
          totalVotes: increment(1),
        });
      } else {
        // Document doesn't exist, create it
        await setDoc(articleRef, {
          articleId: articleId,
          helpfulVotes: isHelpful ? 1 : 0,
          notHelpfulVotes: isHelpful ? 0 : 1,
          totalVotes: 1,
        });
      }

      // Mark as voted in localStorage
      localStorage.setItem(`article-vote-${articleId}`, "true");
      setHasVoted(true);
      setVoteSubmitted(true);
      setVoteFeedback(
        isHelpful
          ? "Thank you for your feedback! We're glad this article was helpful."
          : "Thank you for your feedback! We'll work on improving this article."
      );

      // Hide feedback message after 5 seconds
      setTimeout(() => {
        setVoteSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error("Error updating vote:", error);
      setVoteFeedback(
        "There was an error submitting your feedback. Please try again."
      );
      setVoteSubmitted(true);
    }
  };

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <SupportHeader />
      <FeedbackMenu />

      <main className="flex-grow container mx-auto px-4 py-8">
        <Breadcrumbs items={breadcrumbItems} />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <article className="bg-white p-6 border-2 border-darkgray rounded-lg">
              <h1 className="text-3xl font-bold mb-4">{article.title}</h1>

              <div className="flex flex-wrap gap-2 mb-6">
                {article.tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-3 py-1 bg-gray-100 text-xs rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>

              <div
                className="prose max-w-none"
                dangerouslySetInnerHTML={createMarkup(article.content)}
              />

              <div className="mt-8 pt-6 border-t border-gray-200">
                <p className="text-sm text-gray-500">
                  Last updated:{" "}
                  {new Date(article.lastUpdated).toLocaleDateString()}
                </p>

                <div className="mt-4">
                  <p className="text-sm">Was this article helpful?</p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleVote(true)}
                      disabled={hasVoted}
                      className={`px-4 py-2 border border-green text-green rounded transition-colors ${
                        hasVoted
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-green hover:text-white"
                      }`}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => handleVote(false)}
                      disabled={hasVoted}
                      className={`px-4 py-2 border border-red text-red rounded transition-colors ${
                        hasVoted
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-red hover:text-white"
                      }`}
                    >
                      No
                    </button>
                  </div>

                  {voteSubmitted && (
                    <div className="mt-3 p-3 bg-gray-50 rounded-md text-sm">
                      {voteFeedback}
                    </div>
                  )}
                </div>
              </div>
            </article>
          </div>

          <div>
            <div className="bg-gray-50 p-5 rounded-lg">
              <h3 className="font-bold mb-4">Related Articles</h3>
              {relatedArticles.length > 0 ? (
                <ul className="space-y-2">
                  {relatedArticles.map((related) => (
                    <li key={related.id}>
                      <a
                        href={`/support/article/${related.id}`}
                        className="text-red hover:underline block py-1"
                      >
                        {related.title}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500 text-sm">
                  No related articles found
                </p>
              )}
            </div>

            <div className="mt-6 bg-gray-50 p-5 rounded-lg">
              <h3 className="font-bold mb-4">Need More Help?</h3>
              <p className="text-sm mb-4">
                Can't find what you're looking for? Our support team is here to
                help!
              </p>
              <a
                href="/contact"
                className="block w-full py-2 px-4 bg-red text-white text-center rounded-lg hover:bg-red-dark transition-colors"
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default ArticlePage;
