import React, { useEffect, useState } from "react";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import ShopNavigation from "./shop-navigation.component";
import NavigationBar from "../navigation-bar-2/side-navigation-bar.component";
import FloatingActionMenu from "../navigation-bar-2/floating-side-menu";
import { Link } from "react-router-dom";
import { getPurchaseHistory } from "../../firebase/shop-api";
import { RefreshCw } from "lucide-react";

const PurchaseHistory = () => {
  const { purchaseHistory, isLoading } = useShopWithPowerUps();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    // If we already have purchase history in the context, use that
    if (purchaseHistory && purchaseHistory.length > 0) {
      setPurchases(purchaseHistory);
      setLoading(false);
      return;
    }

    // Otherwise fetch it directly from the API
    fetchHistory();
  }, [purchaseHistory]);

  const fetchHistory = async () => {
    try {
      setLoading(true);
      const result = await getPurchaseHistory();

      if (result && result.purchases) {
        // Format the date strings to Date objects
        const formattedPurchases = result.purchases.map((purchase) => ({
          ...purchase,
          timestamp: purchase.timestamp ? new Date(purchase.timestamp) : null,
        }));

        setPurchases(formattedPurchases);
      }
    } catch (err) {
      console.error("Failed to fetch purchase history:", err);
      setError(err.message || "Failed to load purchase history");
    } finally {
      setLoading(false);
    }
  };

  // Handle manual refresh
  const handleRefresh = async () => {
    setRefreshing(true);
    setError(null);

    try {
      await fetchHistory();
    } catch (err) {
      setError("Failed to refresh purchase history. Please try again.");
    } finally {
      setRefreshing(false);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Unknown";

    const date = new Date(dateString);

    // Use shorter format on mobile
    if (window.innerWidth < 640) {
      return date.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    }

    // Full format on larger screens
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (isLoading || loading) {
    return (
      <>
        <NavigationBar />
        <ShopNavigation />
        <FloatingActionMenu />
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <NavigationBar />
      <ShopNavigation />
      <FloatingActionMenu />

      <div className="container mx-auto px-4 py-4 sm:py-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6">
          <h1 className="text-2xl sm:text-3xl font-bold text-black mb-3 sm:mb-0">
            Purchase History
          </h1>

          <button
            onClick={handleRefresh}
            disabled={refreshing}
            className="px-4 py-2 bg-gold text-white rounded-md hover:bg-[#b88700] transition-colors flex items-center"
          >
            <RefreshCw
              size={18}
              className={`mr-2 ${refreshing ? "animate-spin" : ""}`}
            />
            {refreshing ? "Refreshing..." : "Refresh History"}
          </button>
        </div>

        {error && (
          <div className="mb-4 sm:mb-6 p-3 sm:p-4 rounded-lg bg-red text-white">
            {error}
          </div>
        )}

        {purchases && purchases.length > 0 ? (
          <div className="max-w-3xl mx-auto bg-white shadow-md overflow-hidden border-2 border-b-4 border-darkgray">
            {/* Desktop table view */}
            <div className="hidden sm:block overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray border-b-2 border-darkgray">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-2/5"
                    >
                      Item
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider w-1/5"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider w-2/5"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray">
                  {purchases.map((purchase) => (
                    <tr
                      key={purchase.id}
                      className="hover:bg-gray hover:bg-opacity-10"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm font-medium text-gray-900">
                            {purchase.productName}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center justify-center gap-1">
                          <span className="text-outline-black">🟠</span>
                          <span className="text-sm font-bold text-gray-900">
                            {purchase.amount}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <div className="text-sm text-gray-900">
                          {formatDate(purchase.timestamp)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Mobile card view */}
            <div className="sm:hidden">
              <div className="bg-gray py-3 px-4 border-b-2 border-darkgray">
                <span className="text-xs font-medium text-white uppercase">
                  Purchase History
                </span>
              </div>
              <ul className="divide-y divide-gray">
                {purchases.map((purchase) => (
                  <li key={purchase.id} className="px-4 py-3">
                    <div className="flex justify-between items-start mb-1">
                      <div className="text-sm font-medium text-gray-900">
                        {purchase.productName}
                      </div>
                      <div className="flex items-center gap-1 ml-2">
                        <span className="text-outline-black text-xs">🟠</span>
                        <span className="text-xs font-bold text-gray-900">
                          {purchase.amount}
                        </span>
                      </div>
                    </div>
                    <div className="text-xs text-gray-500">
                      {formatDate(purchase.timestamp)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-8 sm:py-12 text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-14 w-14 sm:h-16 sm:w-16 mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
            <p className="text-base sm:text-lg mb-4">No purchase history yet</p>
            <Link
              to="/shop"
              className="px-4 py-2 bg-red text-white border-2 border-b-4 border-darkgray font-medium hover:bg-[#932929] transition-colors"
            >
              Visit Shop
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default PurchaseHistory;
