import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { motion, AnimatePresence } from "framer-motion";
import { useAttemptsWithNotifications } from "../../hooks/useAttemptsHook";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import { useNotifications } from "../../helpers/NotificationsContext";
import NotificationBell from "../notifications/notifications-bell";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";

// Import the modal components
import StatModal from "../modals/stat-modal";
import LivesModalContent from "../modals/lives-modal";
import CoursesModalContent from "../modals/courses-modal";
import CoinsModalContent from "../modals/coins-modal";
import StreaksModalContent from "../modals/streak-modal";
import XpModalContent from "../modals/xp-modal";
import NotificationErrorBoundary from "../notifications/notification-error-boundary";

function NavigationBar({ currentSkillData }) {
  const [open, setOpen] = useState(false);

  // Modal state
  const [activeModal, setActiveModal] = useState(null);

  const { attempts } = useAttemptsWithNotifications();
  const { user, membership } = useAuth();
  const { balance } = useShopWithPowerUps();
  const {
    userStats,
    totalXp = 0,
    streak = 0,
    isLoading: analyticsLoading,
    refreshAnalytics,
  } = useSafeAnalytics();
  const { unreadCount } = useNotifications();

  // Get streak from userStats if available
  const hasActiveStreak = streak > 0;

  // Refresh key stats when component mounts or when user changes
  useEffect(() => {
    if (user?.uid) {
      // Refresh analytics when the navigation bar loads to ensure latest stats
      refreshAnalytics();
    }
  }, [user?.uid, refreshAnalytics]);

  const menuVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
        type: "spring",
        stiffness: 120,
      },
    },
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: "afterChildren",
      },
    },
  };

  const itemVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: -20 },
  };

  // Open the corresponding modal
  const openModal = (modalType) => {
    setActiveModal(modalType);
  };

  // Close any open modal
  const closeModal = () => {
    setActiveModal(null);
  };

  if (attempts.loading || !user) {
    return (
      <div className="flex items-center">
        <div className="animate-pulse bg-gray h-6 w-20 rounded"></div>
      </div>
    );
  }

  return (
    <div className="relative">
      <header className="bg-white border-b-4 p-2 text-lg md:text-xl xl:text-2xl 2xl:text-3xl cursor-pointer sm:flex sm:justify-between items-center h-12 sm:h-14 md:h-16 lg:h-18 xl:h-20 2xl:h-22 border-darkgray">
        {/* Mobile Header Section */}
        <div className="sm:hidden flex justify-between items-center w-full">
          <motion.button
            onClick={() => setOpen(!open)}
            className="text-black hover:text-green focus:outline-none z-50"
            whileTap={{ scale: 0.95 }}
          >
            <motion.svg
              className="h-6 w-6"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              animate={open ? "open" : "closed"}
            >
              <motion.path
                fill="currentColor"
                initial={false}
                variants={{
                  open: {
                    d: "M32.3839 12.6875L23.8979 21.1715L15.4139 12.6875L12.5859 15.5155L21.0699 23.9995L12.5859 32.4835L15.4139 35.3115L23.8979 26.8275L32.3839 35.3115L35.2119 32.4835L26.7279 23.9995L35.2119 15.5155L32.3839 12.6875Z",
                  },
                  closed: {
                    d: "M42 36H6V32H42V36ZM42 26H6V22H42V26ZM42 16H6V12H42V16Z",
                  },
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              />
            </motion.svg>
          </motion.button>

          {/* Mobile Icons - Visible when menu is closed */}
          {!open && (
            <div className="flex items-center space-x-4">
              {/* Lives Icon */}
              <div
                className="flex items-center justify-center h-8 w-8 relative cursor-pointer"
                onClick={() => openModal("lives")}
                aria-label="Lives"
              >
                <span className="text-sm sm:text-base text-outline-black">
                  ❤️
                </span>
              </div>

              {/* Courses Icon */}
              <div
                className="flex items-center justify-center h-8 w-8 relative cursor-pointer"
                onClick={() => openModal("courses")}
                aria-label="Courses"
              >
                <span className="text-sm sm:text-base text-outline-black">
                  🇿🇦
                </span>
              </div>

              {/* Coins Icon */}
              <div
                className="flex items-center justify-center h-8 w-8 relative cursor-pointer"
                onClick={() => openModal("coins")}
                aria-label="Coins"
              >
                <span className="text-sm sm:text-base text-outline-black">
                  🟠
                </span>
              </div>

              {/* Streak Icon */}
              <div
                className={`flex items-center justify-center h-8 w-8 relative cursor-pointer ${
                  !hasActiveStreak ? "grayscale-100" : ""
                }`}
                onClick={() => hasActiveStreak && openModal("streaks")}
                aria-label="Streak"
              >
                <span className="text-sm sm:text-base text-outline-black">
                  🔥
                </span>
              </div>

              {/* XP Icon */}
              {!analyticsLoading && totalXp > 0 && (
                <div
                  className="flex items-center justify-center h-8 w-8 relative cursor-pointer"
                  onClick={() => openModal("xp")}
                  aria-label="XP"
                >
                  <span className="text-sm sm:text-base text-outline-black">
                    🏆
                  </span>
                </div>
              )}

              {/* Notification Bell */}
              <NotificationErrorBoundary>
                <NotificationBell />
              </NotificationErrorBoundary>
            </div>
          )}
        </div>

        {/* Centered Desktop Menu Section */}
        <div
          className="hidden sm:flex absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 space-x-0 md:space-x-1 lg:space-x-2 xl:space-x-3"
          initial={false}
        >
          {/* Home Button */}
          <div className="flex flex-col justify-center items-center">
            <Link to="/dashboard" className="text-center">
              <button className="flex flex-col items-center justify-center block px-2 text-black font-semibold rounded hover:text-green cursor-pointer">
                <div className="text-outline-black text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
                  🏠
                </div>
                <span className="text-xs md:text-sm lg:text-base font-semibold hover:text-green">
                  Home
                </span>
              </button>
            </Link>
          </div>

          {/* Vault Button */}
          <div className="flex flex-col justify-center items-center">
            <Link to="/vault" className="text-center">
              <button className="flex flex-col items-center justify-center block px-2 text-black font-semibold rounded hover:text-green cursor-pointer">
                <div className="text-outline-black text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
                  🔐
                </div>
                <span className="text-xs md:text-sm lg:text-base font-semibold hover:text-green">
                  Vault
                </span>
              </button>
            </Link>
          </div>

          {/* Dictionary Button */}
          <div className="flex flex-col justify-center items-center">
            <Link to="/dictionary" className="text-center">
              <button className="flex flex-col items-center justify-center block px-2 text-black font-semibold rounded hover:text-green cursor-pointer">
                <div className="text-outline-black text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
                  🔍
                </div>
                <span className="text-xs md:text-sm lg:text-base font-semibold hover:text-green">
                  Dictionary
                </span>
              </button>
            </Link>
          </div>

          {/* Bookings Button */}
          <div className="flex flex-col justify-center items-center">
            <Link to="/booking" className="text-center">
              <button className="flex flex-col items-center justify-center block px-2 text-black font-semibold rounded hover:text-green cursor-pointer">
                <div className="text-outline-black text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
                  💬
                </div>
                <span className="text-xs md:text-sm lg:text-base font-semibold hover:text-green">
                  Bookings
                </span>
              </button>
            </Link>
          </div>

          {/* Shop Button */}
          <div className="flex flex-col justify-center items-center">
            <Link to="/shop" className="text-center">
              <button className="flex flex-col items-center justify-center block px-2 text-black font-semibold rounded hover:text-green cursor-pointer">
                <div className="text-outline-black text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
                  🛒
                </div>
                <span className="text-xs md:text-sm lg:text-base font-semibold hover:text-green">
                  Shop
                </span>
              </button>
            </Link>
          </div>
        </div>

        {/* Desktop Icons Section - Right Aligned */}
        <div className="hidden sm:flex items-center space-x-2 md:space-x-3 lg:space-x-4 ml-auto pr-4 md:pr-6 lg:pr-8 h-full">
          {/* Lives Icon */}
          <div
            className="cursor-pointer flex items-center justify-center h-10 w-10"
            onClick={() => openModal("lives")}
            aria-label="Lives"
          >
            <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-outline-black hover:text-outline-green">
              ❤️
            </div>
          </div>

          {/* Courses Icon */}
          <div
            className="cursor-pointer flex items-center justify-center h-10 w-10"
            onClick={() => openModal("courses")}
            aria-label="Courses"
          >
            <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-outline-black hover:text-outline-green">
              🇿🇦
            </div>
          </div>

          {/* Coins Icon */}
          <div
            className="cursor-pointer flex items-center justify-center h-10 w-10"
            onClick={() => openModal("coins")}
            aria-label="Coins"
          >
            <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-outline-black hover:text-outline-green">
              🟠
            </div>
          </div>

          {/* Streak Icon */}
          <div
            className={`flex items-center justify-center h-10 w-10 ${
              hasActiveStreak ? "cursor-pointer" : ""
            }`}
            onClick={() => hasActiveStreak && openModal("streaks")}
            aria-label="Streak"
          >
            <div
              className={`text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-outline-black hover:text-outline-green ${
                !hasActiveStreak ? "grayscale-100" : ""
              }`}
            >
              🔥
            </div>
          </div>

          {/* XP Icon */}
          {totalXp > 0 && (
            <div
              className="cursor-pointer flex items-center justify-center h-10 w-10"
              onClick={() => openModal("xp")}
              aria-label="XP"
            >
              <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-outline-black hover:text-outline-green">
                🏆
              </div>
            </div>
          )}

          {/* Notification Bell */}
          <NotificationErrorBoundary>
            <NotificationBell />
          </NotificationErrorBoundary>
        </div>

        {/* Mobile Menu Overlay */}
        <AnimatePresence>
          {open && (
            <motion.div
              className="sm:hidden fixed inset-0 bg-white z-40 overflow-auto"
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
            >
              <motion.div className="p-4 space-y-4 pt-8">
                <motion.div variants={itemVariants}>
                  <Link
                    to="/dashboard"
                    onClick={() => setOpen(false)}
                    className="block"
                  >
                    <button className="flex items-center w-full p-3 text-black hover:text-green rounded-lg">
                      <span className="text-2xl mr-3 text-outline-black">
                        🏠
                      </span>
                      <span className="text-lg font-semibold">Home</span>
                    </button>
                  </Link>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <Link
                    to="/vault"
                    onClick={() => setOpen(false)}
                    className="block"
                  >
                    <button className="flex items-center w-full p-3 text-black hover:text-green rounded-lg">
                      <span className="text-2xl mr-3 text-outline-black">
                        🔐
                      </span>
                      <span className="text-lg font-semibold">Vault</span>
                    </button>
                  </Link>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <Link
                    to="/dictionary"
                    onClick={() => setOpen(false)}
                    className="block"
                  >
                    <button className="flex items-center w-full p-3 text-black hover:text-green rounded-lg">
                      <span className="text-2xl mr-3 text-outline-black">
                        🔍
                      </span>
                      <span className="text-lg font-semibold">Dictionary</span>
                    </button>
                  </Link>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <Link
                    to="/booking"
                    onClick={() => setOpen(false)}
                    className="block"
                  >
                    <button className="flex items-center w-full p-3 text-black hover:text-green rounded-lg">
                      <span className="text-2xl mr-3 text-outline-black">
                        💬
                      </span>
                      <span className="text-lg font-semibold">Bookings</span>
                    </button>
                  </Link>
                </motion.div>

                <motion.div variants={itemVariants}>
                  <Link
                    to="/shop"
                    onClick={() => setOpen(false)}
                    className="block"
                  >
                    <button className="flex items-center w-full p-3 text-black hover:text-green rounded-lg">
                      <span className="text-2xl mr-3 text-outline-black">
                        🛒
                      </span>
                      <span className="text-lg font-semibold">Shop</span>
                    </button>
                  </Link>
                </motion.div>

                {/* Gamification Stats in Mobile Menu */}
                <div className="border-t border-darkgray mt-6 pt-6">
                  <h3 className="text-lg font-semibold text-gray mb-4">
                    Your Stats
                  </h3>
                  <div className="grid grid-cols-3 gap-4">
                    <div
                      className="flex flex-col items-center p-3 border-2 border-b-4 border-darkgray rounded-lg"
                      onClick={() => {
                        setOpen(false);
                        openModal("lives");
                      }}
                    >
                      <span className="text-2xl mb-1 text-outline-darkgray">
                        ❤️
                      </span>
                      <span className="text-sm text-gray">
                        {attempts.count} Lives
                      </span>
                    </div>

                    <div
                      className="flex flex-col items-center p-3 border-2 border-b-4 border-darkgray rounded-lg"
                      onClick={() => {
                        setOpen(false);
                        openModal("coins");
                      }}
                    >
                      <span className="text-2xl mb-1 text-outline-darkgray">
                        🟠
                      </span>
                      <span className="text-sm text-gray">
                        {Math.round(balance)} Coins
                      </span>
                    </div>

                    {hasActiveStreak && (
                      <div
                        className="flex flex-col items-center p-3 border-2 border-b-4 border-darkgray rounded-lg"
                        onClick={() => {
                          setOpen(false);
                          openModal("streaks");
                        }}
                      >
                        <span className="text-2xl mb-1 text-outline-darkgray">
                          🔥
                        </span>
                        <span className="text-sm text-gray">{streak} Days</span>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </header>

      {/* Modals */}
      <StatModal
        isOpen={activeModal === "lives"}
        onClose={closeModal}
        title="Lives"
      >
        <LivesModalContent onClose={closeModal} />
      </StatModal>

      <StatModal
        isOpen={activeModal === "courses"}
        onClose={closeModal}
        title="Your Courses"
      >
        <CoursesModalContent
          onClose={closeModal}
          currentSkillData={currentSkillData}
        />
      </StatModal>

      <StatModal
        isOpen={activeModal === "coins"}
        onClose={closeModal}
        title="Coins"
      >
        <CoinsModalContent onClose={closeModal} />
      </StatModal>

      <StatModal
        isOpen={activeModal === "streaks"}
        onClose={closeModal}
        title="Streak"
      >
        <StreaksModalContent onClose={closeModal} />
      </StatModal>

      <StatModal
        isOpen={activeModal === "xp"}
        onClose={closeModal}
        title="Experience Points"
      >
        <XpModalContent onClose={closeModal} />
      </StatModal>
    </div>
  );
}

export default NavigationBar;
