// Complete flashcard.page.jsx with all functions
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component";
import BeatLoader from "react-spinners/BeatLoader";
// import { AnalyticsService } from "../../services/enhanced-analytics.service";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics"; // NEW: Import useSafeAnalytics
import FlashcardDeck from "../../components/vault/flashcards/flashcard-deck";
import FlashcardDeckCard from "../../components/vault/flashcards/flashcard-deck-card";
import FlashcardDeckDetailsModal from "../../components/vault/flashcards/flashcard-deck-details-modal";
import CreateCustomDeckModal from "../../components/vault/flashcards/create-custom-deck-modal";
import { motion } from "framer-motion";
import FlashcardService from "../../services/flashcard/firebase-data-adapter";
import userProgressService from "../../services/analytics/user-progress-service";
import FloatingActionMenu from "../../components/navigation-bar-2/floating-side-menu";
import FeedbackMenu from "../../components/support/feedback-menu";
import deckUnlockService from "../../services/flashcard/deck-unlock-service";
import DeckUnlockNotification from "../../components/vault/flashcards/deck-unlock-notification";
// Import the flashcard notifications hook
import useFlashcardNotifications from "../../utils/notifications/flashcard-notifications-utils";

import { analyzeAndRepairFlashcards } from "./flashcard-repair";

function FlashCardPage() {
  const { user } = useAuth();
  const { trackEvent, refreshAnalytics } = useSafeAnalytics();
  const [loading, setLoading] = useState(true);
  const [decks, setDecks] = useState([]);
  const [customDecks, setCustomDecks] = useState([]);
  const [filteredDecks, setFilteredDecks] = useState([]);
  const [selectedDeckId, setSelectedDeckId] = useState(null);
  const [showDeckDetails, setShowDeckDetails] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userProgress, setUserProgress] = useState({});
  const [progressStats, setProgressStats] = useState({}); // Add this for detailed stats
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("all"); // all, public, custom
  const [sortBy, setSortBy] = useState("name"); // name, popularity, difficulty
  const [showPracticeMode, setShowPracticeMode] = useState(false);
  const [practiceMode, setPracticeMode] = useState("flashcards");
  const [recentlyStudiedDecks, setRecentlyStudiedDecks] = useState([]); // For showing recently studied decks
  const [newlyUnlockedDeck, setNewlyUnlockedDeck] = useState(null);

  // Add these refs to prevent refresh loops
  const isInitialMount = useRef(true);
  const lastRefreshTime = useRef(0);
  const isRefreshing = useRef(false);

  // Get the flashcard notifications hook
  const flashcardNotifications = useFlashcardNotifications();

  // // Set up notification handlers for the deck unlock service on component mount
  // useEffect(() => {
  //   if (flashcardNotifications) {
  //     // Register notification handlers with the deck unlock service
  //     deckUnlockService.setNotificationHandlers({
  //       sendDeckUnlockedNotification:
  //         flashcardNotifications.sendDeckUnlockedNotification,
  //     });

  //     return () => {
  //       // Clear notification handlers when component unmounts
  //       deckUnlockService.setNotificationHandlers(null);
  //     };
  //   }
  // }, [flashcardNotifications]);

  // Mark newly unlocked notification as shown
  const handleCloseUnlockNotification = async () => {
    if (user?.uid && newlyUnlockedDeck) {
      await deckUnlockService.markNotificationShown(
        user.uid,
        newlyUnlockedDeck.id
      );
      setNewlyUnlockedDeck(null);
    }
  };

  // Handle viewing a newly unlocked deck
  const handleViewUnlockedDeck = (deckId) => {
    // Find the deck and select it
    const deck = [...decks, ...customDecks].find((d) => d.id === deckId);
    if (deck) {
      setSelectedDeckId(deckId);
      setShowDeckDetails(true);
      handleCloseUnlockNotification();
    }
  };

  React.useEffect(() => {
    window.repairFlashcards = analyzeAndRepairFlashcards;
  }, []);

  // Modify your useEffect to use the debounced refresh and only on initial mount
  useEffect(() => {
    document.title = "Flashcards | Fast Fluency Africa";

    // Track page view in analytics - only once
    if (user?.uid && isInitialMount.current) {
      trackEvent("enhanced_flashcard_page_viewed");
      isInitialMount.current = false;
    }

    // Using an async function inside useEffect
    const initializeFlashcards = async () => {
      try {
        // Start loading state
        setLoading(true);

        if (user?.uid) {
          // Check if decks were recently unlocked in another component
          const recentlyUnlocked = sessionStorage.getItem(
            "decks_unlocked_recently"
          );

          if (recentlyUnlocked === "true") {
            // ... unlock handling code remains the same
          } else {
            // Regular refresh
            await deckUnlockService.refreshDeckUnlockStatus(user.uid);
          }
        }

        // Then fetch decks with the refreshed unlock status
        await fetchDecksWithUnlockStatus();

        // Only refresh analytics once after initialization
        if (user?.uid && isInitialMount.current) {
          // Use force=true for initial load only
          await forceRefreshAnalytics(true);
          isInitialMount.current = false;
        }
      } catch (error) {
        console.error("Error during flashcard initialization:", error);
      } finally {
        setLoading(false);
      }
    };

    // Call the async initialization function
    initializeFlashcards();
  }, [user?.uid]); // Remove trackEvent and refreshAnalytics from dependencies

  // Replace the window focus handler with this debounced version
  useEffect(() => {
    const refreshOnFocus = () => {
      // Only refresh if:
      // 1. User is logged in
      // 2. User is NOT in practice mode
      // 3. User is viewing the deck list
      if (user?.uid && !showPracticeMode && !showDeckDetails) {
        console.log("Window focused, refreshing flashcard deck list...");

        // Use a less disruptive refresh that won't reset UI state
        const refreshDeckListOnly = async () => {
          try {
            // Only fetch decks that aren't currently being practiced
            if (selectedDeckId) {
              // Exclude the selected deck from refresh to avoid disrupting practice
            }

            // Fetch decks list only
            await fetchDecksWithUnlockStatus();

            // Use debounced refresh with force=false for focus events
            await forceRefreshAnalytics(false);
          } catch (error) {
            console.error("Error during focus refresh:", error);
          }
        };

        refreshDeckListOnly();
      }
    };

    // Add event listener
    window.addEventListener("focus", refreshOnFocus);

    // Clean up
    return () => {
      window.removeEventListener("focus", refreshOnFocus);
    };
  }, [user?.uid, showPracticeMode, showDeckDetails, selectedDeckId]); // Remove refreshAnalytics from dependencies

  // Fetch decks with unlock status using the enhanced unlock service

  // Remove the refreshDeckUnlockStatus call at the beginning:

  const fetchDecksWithUnlockStatus = async () => {
    try {
      // console.log("Fetching flashcard decks with unlock status...");
      setLoading(true);

      if (user?.uid) {
        // Get all decks with lock status
        const rawDecksWithStatus =
          await deckUnlockService.getAllDecksWithUnlockStatus(user.uid);

        // Safety check: ensure we received an array and validate the data
        if (!Array.isArray(rawDecksWithStatus)) {
          console.error(
            "Unexpected response from deck unlock service:",
            rawDecksWithStatus
          );
          setLoading(false);
          return;
        }

        // Filter out invalid deck entries to avoid rendering errors
        const decksWithStatus = rawDecksWithStatus.filter(
          (deck) =>
            deck &&
            typeof deck === "object" &&
            deck.id &&
            deck.name &&
            typeof deck.cardCount === "number" &&
            typeof deck.isUnlocked === "boolean"
        );

        // console.log(
        //   `Received ${rawDecksWithStatus.length} decks, ${decksWithStatus.length} valid after filtering`
        // );

        // Log the status of each deck to help debug
        decksWithStatus.forEach((deck) => {
          // console.log(
          //   `Deck ${deck.id} (${deck.name}): ${
          //     deck.isUnlocked ? "UNLOCKED" : "LOCKED"
          //   }, CardCount: ${deck.cardCount}`
          // );
        });

        // Separate into public and custom decks (with additional safety checks)
        const publicDecksData = decksWithStatus.filter(
          (deck) => !deck.isCustom
        );
        const customDecksData = decksWithStatus.filter((deck) => deck.isCustom);

        // console.log(
        //   `Split into ${publicDecksData.length} public decks and ${customDecksData.length} custom decks`
        // );

        // Update state with valid data
        setDecks(publicDecksData);
        setCustomDecks(customDecksData);

        // Check for newly unlocked decks
        const newlyUnlocked = decksWithStatus.find(
          (deck) => deck.isNewlyUnlocked
        );
        if (newlyUnlocked) {
          setNewlyUnlockedDeck(newlyUnlocked);
        }

        // Get user progress for all decks
        const allDeckIds = decksWithStatus.map((deck) => deck.id);
        const progress = await FlashcardService.getUserProgress(
          user.uid,
          allDeckIds
        );
        setUserProgress(progress);

        // Calculate detailed progress stats
        const detailedProgressStats = {};
        for (const deck of decksWithStatus) {
          if (deck.isUnlocked) {
            // Only calculate for unlocked decks
            detailedProgressStats[deck.id] =
              await userProgressService.calculateDeckProgress(
                user.uid,
                deck.id,
                deck.cardCount
              );
          }
        }
        setProgressStats(detailedProgressStats);

        // Apply initial filters
        applyFiltersAndSort(
          publicDecksData,
          customDecksData,
          filter,
          sortBy,
          searchQuery
        );

        // Also fetch recently studied decks
        fetchRecentlyStudiedDecks(decksWithStatus);
      } else {
        // For non-logged in users, just get public decks
        const { publicDecks: publicDecksData } =
          await FlashcardService.getFlashcardDecks();
        setDecks(
          publicDecksData.map((deck) => ({
            ...deck,
            isUnlocked: !deck.isLockedByDefault,
          }))
        );
        setCustomDecks([]);
        applyFiltersAndSort(publicDecksData, [], filter, sortBy, searchQuery);
      }
    } catch (error) {
      console.error("Error fetching decks:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch available decks and user progress
  const fetchDecksAndProgress = async () => {
    setLoading(true);
    try {
      // console.log("Fetching flashcard decks using FlashcardService...");

      // Use the adapter service to get decks
      const { publicDecks: publicDecksData, customDecks: customDecksData } =
        await FlashcardService.getFlashcardDecks(user?.uid);

      // Fetch user's progress data if logged in
      let progressData = {};
      let detailedProgressStats = {};

      if (user?.uid) {
        const allDecks = [...publicDecksData, ...customDecksData];
        const allDeckIds = allDecks.map((deck) => deck.id);

        // Use the adapter service to get user progress
        progressData = await FlashcardService.getUserProgress(
          user?.uid,
          allDeckIds
        );

        // Use our new service to get detailed progress stats
        for (const deck of allDecks) {
          detailedProgressStats[deck.id] =
            await userProgressService.calculateDeckProgress(
              user.uid,
              deck.id,
              deck.cardCount
            );
        }

        // Also fetch recently studied decks
        fetchRecentlyStudiedDecks(allDecks);
      }

      setDecks(publicDecksData);
      setCustomDecks(customDecksData);
      setUserProgress(progressData);
      setProgressStats(detailedProgressStats);

      // Apply initial filtering and sorting
      applyFiltersAndSort(publicDecksData, customDecksData, "all", "name", "");
    } catch (error) {
      console.error("Error fetching flashcard decks:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch recently studied decks
  const fetchRecentlyStudiedDecks = async (allDecks) => {
    if (!user?.uid) return;

    try {
      // Get all user progress
      const allProgress = await userProgressService.getAllUserProgress(
        user.uid
      );

      // Find decks with recent study activity
      const decksWithActivity = Object.entries(allProgress.decks || {})
        .filter(([_, deckProgress]) => deckProgress.lastStudied)
        .sort((a, b) => {
          // Sort by most recent study time
          const timeA =
            a[1].lastStudied?.toDate?.() || new Date(a[1].lastStudied);
          const timeB =
            b[1].lastStudied?.toDate?.() || new Date(b[1].lastStudied);
          return timeB - timeA;
        })
        .slice(0, 3) // Take top 3 most recent
        .map(([deckId, deckProgress]) => {
          const deck = allDecks.find((d) => d.id === deckId);
          if (deck) {
            return {
              id: deckId,
              name: deck.name,
              progress: Math.round(
                (deckProgress.masteredCards / deck.cardCount) * 100
              ),
              lastStudied: deckProgress.lastStudied,
            };
          }
          return null;
        })
        .filter(Boolean);

      setRecentlyStudiedDecks(decksWithActivity);
    } catch (error) {
      console.error("Error fetching recently studied decks:", error);
    }
  };

  // Add this function to your FlashCardPage component
  const refreshDeckUnlockStatusOnly = async () => {
    try {
      // console.log("Refreshing deck unlock status only");

      if (user?.uid) {
        // Explicitly refresh the unlock status first
        await deckUnlockService.refreshDeckUnlockStatus(user.uid, true);

        // Force a fresh data fetch for deck status only
        const rawDecksWithStatus =
          await deckUnlockService.getAllDecksWithUnlockStatus(user.uid);

        if (!Array.isArray(rawDecksWithStatus)) {
          console.error(
            "Unexpected response from deck unlock service:",
            rawDecksWithStatus
          );
          return;
        }

        // Filter and process as before
        const decksWithStatus = rawDecksWithStatus.filter(
          (deck) => deck && typeof deck === "object" && deck.id && deck.name
        );

        // console.log("Deck unlock status refreshed. Unlock status:");
        decksWithStatus.forEach((deck) => {
          // console.log(
          //   `Deck ${deck.id} (${deck.name}): ${
          //     deck.isUnlocked ? "UNLOCKED" : "LOCKED"
          //   }`
          // );
        });

        // Update state directly without changing other aspects
        const publicDecksData = decksWithStatus.filter(
          (deck) => !deck.isCustom
        );
        const customDecksData = decksWithStatus.filter((deck) => deck.isCustom);

        setDecks(publicDecksData);
        setCustomDecks(customDecksData);

        // Apply filters with the updated data
        applyFiltersAndSort(
          publicDecksData,
          customDecksData,
          filter,
          sortBy,
          searchQuery
        );
      }
    } catch (error) {
      console.error("Error refreshing deck unlock status:", error);
    }
  };

  // Apply filters and sorting
  // Replace your current applyFiltersAndSort function with this one
  const applyFiltersAndSort = (
    publicDecks = [],
    customDecks = [],
    filterType,
    sortType,
    query
  ) => {
    // console.log("applyFiltersAndSort called with:", {
    //   publicDecksCount: Array.isArray(publicDecks)
    //     ? publicDecks.length
    //     : "not an array",
    //   customDecksCount: Array.isArray(customDecks)
    //     ? customDecks.length
    //     : "not an array",
    //   filterType,
    //   sortType,
    // });

    // Safety checks - ensure we have valid arrays and filter out invalid decks
    const safePublicDecks = Array.isArray(publicDecks)
      ? publicDecks.filter(
          (deck) => deck && typeof deck === "object" && deck.id
        )
      : [];

    const safeCustomDecks = Array.isArray(customDecks)
      ? customDecks.filter(
          (deck) => deck && typeof deck === "object" && deck.id
        )
      : [];

    // Combine decks based on filter
    let combined = [];
    if (filterType === "all") {
      combined = [...safePublicDecks, ...safeCustomDecks];
    } else if (filterType === "public") {
      combined = [...safePublicDecks];
    } else if (filterType === "custom") {
      combined = [...safeCustomDecks];
    }

    // Apply search filter if query exists
    if (query) {
      const lowerQuery = query.toLowerCase();
      combined = combined.filter(
        (deck) =>
          (deck.name && deck.name.toLowerCase().includes(lowerQuery)) ||
          (deck.description &&
            deck.description.toLowerCase().includes(lowerQuery)) ||
          (deck.tags &&
            Array.isArray(deck.tags) &&
            deck.tags.some(
              (tag) => tag && tag.toLowerCase().includes(lowerQuery)
            ))
      );
    }

    // Apply sorting with safety checks for each field
    let sorted = [...combined];
    if (sortType === "name") {
      sorted.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    } else if (sortType === "popularity") {
      sorted.sort((a, b) => (b.cardCount || 0) - (a.cardCount || 0));
    } else if (sortType === "difficulty") {
      sorted.sort((a, b) => (a.difficulty || 1) - (b.difficulty || 1));
    } else if (sortType === "progress") {
      sorted.sort((a, b) => {
        const progressA = progressStats[a.id]?.completionPercentage || 0;
        const progressB = progressStats[b.id]?.completionPercentage || 0;
        return progressB - progressA;
      });
    }

    // console.log(
    //   `applyFiltersAndSort: Found ${sorted.length} decks after filtering and sorting`
    // );

    // Final validation before setting state
    const validSorted = sorted.filter(
      (deck) =>
        deck &&
        typeof deck === "object" &&
        deck.id &&
        deck.name &&
        deck.cardCount !== undefined
    );

    setFilteredDecks(validSorted);
  };

  // Replace your current renderDeckCards function with this one
  const renderDeckCards = () => {
    // First, ensure filteredDecks is an array and filter out any undefined/invalid decks
    if (!Array.isArray(filteredDecks) || filteredDecks.length === 0) {
      return (
        <div className="col-span-3 p-8 text-center bg-gray-50 rounded-xl">
          <p>No flashcard decks available. Try adjusting your filters.</p>
        </div>
      );
    }

    // Filter out any potentially invalid/undefined deck entries
    const validDecks = filteredDecks.filter(
      (deck) =>
        deck &&
        typeof deck === "object" &&
        deck.id &&
        deck.name &&
        deck.cardCount !== undefined
    );

    if (validDecks.length === 0) {
      return (
        <div className="col-span-3 p-8 text-center bg-gray-50 rounded-xl">
          <p>No valid flashcard decks found with the current filters.</p>
        </div>
      );
    }

    // Log deck info for debugging
    // console.log(
    //   `Rendering ${validDecks.length} valid decks out of ${filteredDecks.length} filtered decks`
    // );

    // return validDecks.map((deck) => (
    //   <FlashcardDeckCard
    //     key={deck.id}
    //     deck={deck}
    //     isLocked={!deck.isUnlocked}
    //     unlockRequirements={deck.unlockRequirements}
    //     progress={progressStats[deck.id]?.completionPercentage || 0}
    //     completedCards={progressStats[deck.id]?.masteredCards || 0}
    //     progressStats={progressStats[deck.id]}
    //     isNewlyUnlocked={deck.isNewlyUnlocked}
    //     onClick={() => (deck.isUnlocked ? handleDeckSelect(deck.id) : null)}
    //   />
    // ));

    return filteredDecks.map((deck) => (
      <FlashcardDeckCard // Use the enhanced debug version instead of regular FlashcardDeckCard
        key={deck.id}
        deck={deck}
        isLocked={!deck.isUnlocked}
        unlockRequirements={deck.unlockRequirements}
        progress={progressStats[deck.id]?.completionPercentage || 0}
        completedCards={progressStats[deck.id]?.masteredCards || 0}
        progressStats={progressStats[deck.id]}
        isNewlyUnlocked={deck.isNewlyUnlocked}
        onClick={() => (deck.isUnlocked ? handleDeckSelect(deck.id) : null)}
      />
    ));

    // {
    //   showPracticeMode && selectedDeckId && (
    //     <div>
    //       <h2 className="text-xl font-bold mb-4">
    //         FlashcardDeck Component (Standard)
    //       </h2>
    //       <FlashcardDeck deckId={selectedDeckId} initialMode={practiceMode} />
    //       <hr className="my-8 border-t border-gray-300" />
    //       <h2 className="text-xl font-bold mb-4">
    //         SimpleFlashcardDeck Component (Direct Query)
    //       </h2>
    //       <SimpleFlashcardDeck deckId={selectedDeckId} />
    //       <DirectQueryDebugger deckId={selectedDeckId} />
    //     </div>
    //   );
    // }
  };

  // Handle search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    applyFiltersAndSort(decks, customDecks, filter, sortBy, query);
  };

  // Handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    applyFiltersAndSort(decks, customDecks, newFilter, sortBy, searchQuery);
  };

  // Handle sort change
  const handleSortChange = (newSort) => {
    setSortBy(newSort);
    applyFiltersAndSort(decks, customDecks, filter, newSort, searchQuery);
  };

  // Handle deck selection - updated to use trackEvent from useSafeAnalytics
  const handleDeckSelect = (deckId) => {
    setSelectedDeckId(deckId);
    setShowDeckDetails(true);

    // Track deck selection in analytics
    if (user?.uid) {
      // UPDATED: Use trackEvent from useSafeAnalytics
      trackEvent("flashcard_deck_selected", {
        deckId,
      });
    }
  };

  // Handle closing deck details modal
  const handleCloseDeckDetails = () => {
    setShowDeckDetails(false);
  };

  // Handle starting practice - updated to use trackEvent from useSafeAnalytics
  const handleStartPractice = (mode) => {
    setPracticeMode(mode);
    setShowDeckDetails(false);
    setShowPracticeMode(true);

    // Track start practice in analytics
    if (user?.uid) {
      // UPDATED: Use trackEvent from useSafeAnalytics
      trackEvent("flashcard_practice_started", {
        deckId: selectedDeckId,
        mode,
      });
    }
  };

  // Then modify your handleReturnToDeckList function to use the debounced version:
  const handleReturnToDeckList = async () => {
    setShowPracticeMode(false);

    // First refresh unlock status explicitly
    try {
      await refreshDeckUnlockStatusOnly();

      // Use force=true since this is an important user action
      if (user?.uid) {
        await forceRefreshAnalytics(true);
      }

      // Only after that completes, reset the selection
      setSelectedDeckId(null);
    } catch (error) {
      console.error("Error during deck status refresh:", error);
      setSelectedDeckId(null);
    }
  };

  // Handle opening create deck modal
  const handleOpenCreateModal = () => {
    // Check if user is premium before allowing deck creation
    if (user?.uid) {
      setShowCreateModal(true);
    } else {
      // Could show a message about needing to be logged in
      // console.log("User must be logged in to create custom decks");
    }
  };

  // Handle deck creation - updated to use trackEvent from useSafeAnalytics
  const handleDeckCreated = async (newDeck) => {
    setShowCreateModal(false);

    // Add the new deck to the list and select it
    setCustomDecks((prev) => [newDeck, ...prev]);
    setSelectedDeckId(newDeck.id);
    setShowDeckDetails(true);

    // Update filtered decks
    applyFiltersAndSort(
      decks,
      [...customDecks, newDeck],
      filter,
      sortBy,
      searchQuery
    );

    // Track deck creation in analytics
    if (user?.uid) {
      // UPDATED: Use trackEvent from useSafeAnalytics
      trackEvent("custom_flashcard_deck_created", {
        deckId: newDeck.id,
        cardCount: newDeck.cardCount,
      });

      // ADDED: Force refresh analytics after deck creation
      await refreshAnalytics(true);
    }
  };

  // Replace your existing forceRefreshAnalytics function with this debounced version
  const forceRefreshAnalytics = async (force = false) => {
    if (!user?.uid) return;

    // Prevent refresh if one is already in progress
    if (isRefreshing.current) {
      console.log("Skipping analytics refresh - already in progress");
      return;
    }

    // Debounce refreshes - only allow one every 3 seconds unless forced
    const now = Date.now();
    if (!force && now - lastRefreshTime.current < 3000) {
      console.log("Skipping analytics refresh - too soon since last refresh");
      return;
    }

    try {
      console.log("Refreshing analytics data (debounced)...");
      isRefreshing.current = true;
      lastRefreshTime.current = now;

      await refreshAnalytics(force);

      console.log("Analytics data refresh complete");
    } catch (error) {
      console.error("Error refreshing analytics data:", error);
    } finally {
      isRefreshing.current = false;
    }
  };

  // Get selected deck
  const getSelectedDeck = () => {
    return (
      [...decks, ...customDecks].find((deck) => deck.id === selectedDeckId) ||
      null
    );
  };

  // Add this function to your FlashCardPage component
  const handleDeckProgressUpdate = async (deckId) => {
    if (!user?.uid) return;

    // console.log(`Updating progress stats for deck ${deckId}`);

    try {
      // Find the deck to get the card count
      const deck = [...decks, ...customDecks].find((d) => d.id === deckId);
      if (!deck) {
        console.warn(
          `Could not find deck with ID ${deckId} for progress update`
        );
        return;
      }

      // Calculate updated progress stats
      const updatedStats = await userProgressService.calculateDeckProgress(
        user.uid,
        deckId,
        deck.cardCount
      );

      // Update the progress stats state
      setProgressStats((prev) => ({
        ...prev,
        [deckId]: updatedStats,
      }));

      // console.log(`Updated progress stats for deck ${deckId}:`, updatedStats);
    } catch (error) {
      console.error(`Error updating progress stats for deck ${deckId}:`, error);
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <NavigationBar />
        <FloatingActionMenu
          showFlashcards={true}
          showStats={true}
          additionalButtons={
            [
              // Example of a custom button - can add more as needed
            ]
          }
        />
        {/* Deck Unlock Notification */}
        {newlyUnlockedDeck && (
          <DeckUnlockNotification
            deck={newlyUnlockedDeck}
            onClose={handleCloseUnlockNotification}
            onViewDeck={handleViewUnlockedDeck}
          />
        )}
        <FeedbackMenu />
        <div className="flex flex-col justify-center items-center min-h-[80vh]">
          <BeatLoader color={"#848717"} loading={loading} size={15} />
          <p className="mt-4 text-gray-600">Loading flashcard decks...</p>
        </div>
      </div>
    );
  }

  // Show practice mode
  if (showPracticeMode && selectedDeckId) {
    return (
      <div className="min-h-screen bg-white">
        <NavigationBar />
        <FloatingActionMenu
          showFlashcards={true}
          showStats={true}
          additionalButtons={
            [
              // Example of a custom button - can add more as needed
            ]
          }
        />
        <div className="container mx-auto px-3 sm:px-4 py-6 sm:py-8">
          <div className="max-w-4xl mx-auto">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-3">
              <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-black">
                {getSelectedDeck()?.name || "Flashcard Practice"}
              </h1>
              <button
                onClick={handleReturnToDeckList}
                className="px-4 py-2 border-2 border-b-4 border-darkgray text-gray rounded-md hover:bg-opacity-60"
              >
                Back to Decks
              </button>
            </div>

            {/* Flashcard Deck Component */}
            <FlashcardDeck
              deckId={selectedDeckId}
              initialMode={practiceMode}
              onProgressUpdate={handleDeckProgressUpdate}
            />
          </div>
        </div>
      </div>
    );
  }

  // No decks available
  if (decks.length === 0 && customDecks.length === 0) {
    return (
      <div className="min-h-screen bg-white">
        <NavigationBar />
        <FloatingActionMenu
          showFlashcards={true}
          showStats={true}
          additionalButtons={
            [
              // Example of a custom button - can add more as needed
            ]
          }
        />
        <div className="flex flex-col justify-center items-center min-h-[80vh]">
          <div className="text-5xl mb-4">🃏</div>
          <h2 className="text-2xl font-bold mb-4">
            No flashcard decks available
          </h2>
          <p className="text-gray-600 mb-6">
            There are no flashcard decks in the system yet.
          </p>

          {user?.uid && (
            <button
              onClick={handleOpenCreateModal}
              className="px-6 py-3 bg-green text-white rounded-lg font-bold hover:bg-opacity-90"
            >
              Create Your Own Deck
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <NavigationBar />
      <FloatingActionMenu
        showFlashcards={true}
        showStats={true}
        additionalButtons={
          [
            // Example of a custom button - can add more as needed
          ]
        }
      />

      {/* Deck Unlock Notification */}
      {newlyUnlockedDeck && (
        <DeckUnlockNotification
          deck={newlyUnlockedDeck}
          onClose={handleCloseUnlockNotification}
          onViewDeck={handleViewUnlockedDeck}
        />
      )}

      <div className="container mx-auto px-3 sm:px-4 py-6 sm:py-8">
        <div className="max-w-6xl mx-auto">
          {/* Header */}
          <div className="mb-6 sm:mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold text-black mb-2">
              Flashcards
            </h1>
            <p className="text-gray-600 text-sm sm:text-base">
              Practice and improve your vocabulary with these flashcard decks.
            </p>
          </div>

          {/* Recently Studied Decks - Only show if user has recently studied decks */}
          {recentlyStudiedDecks.length > 0 && (
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-4">Recently Studied</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {recentlyStudiedDecks.map((deck) => (
                  <motion.div
                    key={deck.id}
                    className="border-2 border-green border-opacity-50 rounded-lg p-3 sm:p-4 cursor-pointer"
                    whileHover={{
                      y: -3,
                      boxShadow: "0 5px 10px rgba(0,0,0,0.1)",
                    }}
                    onClick={() => handleDeckSelect(deck.id)}
                  >
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="font-bold text-sm sm:text-base truncate pr-2">
                        {deck.name}
                      </h3>
                      <span className="text-xs bg-green text-white px-2 py-1 rounded-full flex-shrink-0">
                        {deck.progress}%
                      </span>
                    </div>
                    <div className="w-full h-1 bg-gray-200 rounded-full">
                      <div
                        className="h-full bg-green rounded-full"
                        style={{ width: `${deck.progress}%` }}
                      ></div>
                    </div>
                    <div className="text-xs text-gray-500 mt-2">
                      Last studied:{" "}
                      {deck.lastStudied?.toDate?.().toLocaleDateString() ||
                        new Date(deck.lastStudied).toLocaleDateString()}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          )}

          {/* Controls */}
          <div className="flex flex-col sm:flex-row gap-4 mb-8">
            {/* Search */}
            <div className="flex-grow">
              <input
                type="text"
                placeholder="Search flashcard decks..."
                className="w-full px-4 py-2 border-2 border-b-4 border-darkgray rounded focus:outline-none focus:ring-2 focus:ring-green"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>

            {/* Filters */}
            <div className="flex flex-col xs:flex-row gap-2">
              <select
                value={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
                className="px-4 py-2 border-2 border-b-4 border-darkgray rounded focus:outline-none focus:ring-2 focus:ring-green"
              >
                <option value="all">All Decks</option>
                <option value="public">Public Decks</option>
                <option value="custom">My Custom Decks</option>
              </select>

              <select
                value={sortBy}
                onChange={(e) => handleSortChange(e.target.value)}
                className="px-4 py-2 border-2 border-b-4 border-darkgray rounded focus:outline-none focus:ring-2 focus:ring-green"
              >
                <option value="name">Sort by Name</option>
                <option value="difficulty">Sort by Difficulty</option>
                <option value="popularity">Sort by Popularity</option>
                {user?.uid && (
                  <option value="progress">Sort by Progress</option>
                )}
              </select>
            </div>

            {/* Create Deck Button */}
            {user?.uid && (
              <button
                onClick={handleOpenCreateModal}
                className="px-4 py-2 bg-green text-black rounded border-b-4 border-2 border-black hover:bg-opacity-90 whitespace-nowrap mt-2 sm:mt-0"
              >
                Create Deck
              </button>
            )}
          </div>

          {/* Deck Grid */}
          {filteredDecks.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
              {renderDeckCards()}
            </div>
          ) : (
            <div className="bg-gray-50 rounded-xl p-4 sm:p-8 text-center">
              <div className="text-4xl sm:text-5xl mb-4">🔍</div>
              <h3 className="text-lg sm:text-xl font-bold mb-2">
                No matching decks found
              </h3>
              <p className="text-gray-600 mb-4 text-sm sm:text-base">
                Try adjusting your search or filters to find flashcard decks.
              </p>
              <button
                onClick={() => {
                  setSearchQuery("");
                  setFilter("all");
                  setSortBy("name");
                  applyFiltersAndSort(decks, customDecks, "all", "name", "");
                }}
                className="px-4 py-2 bg-green text-white rounded-lg hover:bg-opacity-90"
              >
                Clear Filters
              </button>
            </div>
          )}

          {/* No custom decks message */}
          {user?.uid && filter === "custom" && customDecks.length === 0 && (
            <motion.div
              className="mt-8 bg-gray-50 rounded-xl p-8 text-center border-2 border-dashed border-gray-200"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <div className="text-5xl mb-4">✨</div>
              <h3 className="text-xl font-bold mb-2">
                Create Your First Custom Deck
              </h3>
              <p className="text-gray-600 mb-4">
                You haven't created any custom flashcard decks yet. Create your
                first deck to start personalizing your learning experience.
              </p>
              <button
                onClick={handleOpenCreateModal}
                className="px-6 py-3 bg-green text-white rounded-lg font-bold hover:bg-opacity-90"
              >
                Create Custom Deck
              </button>
            </motion.div>
          )}
        </div>
      </div>

      {/* Deck Details Modal */}
      {showDeckDetails && selectedDeckId && (
        <FlashcardDeckDetailsModal
          deck={getSelectedDeck()}
          onClose={handleCloseDeckDetails}
          onStartPractice={handleStartPractice}
          userProgress={userProgress[selectedDeckId] || {}}
          progressStats={progressStats[selectedDeckId]} // Pass detailed progress stats
        />
      )}

      {/* Create Custom Deck Modal */}
      {showCreateModal && (
        <CreateCustomDeckModal
          onClose={() => setShowCreateModal(false)}
          onDeckCreated={handleDeckCreated}
        />
      )}
      {/* <MainPageDebugger /> */}
    </div>
  );
}

export default FlashCardPage;
