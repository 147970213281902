import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { default as ffalogofull } from "../../assets/icons/ffa-beta-full-logo.svg";

function NavBarPrimary() {
  const [open, setOpen] = useState(false);

  return (
    <nav className="sm:flex sm:justify-between sm:items-center sm:px-4 p-4 w-6/6 flex flex-row justify-center items-center">
      <div className="flex items-center justify-around">
        <div className="flex flex-row justify-center items-center m-auto w-4/4">
          <Link
            to="/"
            className="cursor-pointer hover:border-green hover:border-1"
          >
            <img className="h-8" src={!open ? ffalogofull : ""} alt="" />
          </Link>
        </div>
        <div className="sm:hidden sm:flex cursor-pointer flex flex-row justify-center items-center text-right block h-8 ">
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="text-white hover:text-green focus:text-green focus:outline-none cursor-pointer"
          >
            <svg
              className="h-8 w-6 fill-current"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {open ? (
                <path d="M32.3839 12.6875L23.8979 21.1715L15.4139 12.6875L12.5859 15.5155L21.0699 23.9995L12.5859 32.4835L15.4139 35.3115L23.8979 26.8275L32.3839 35.3115L35.2119 32.4835L26.7279 23.9995L35.2119 15.5155L32.3839 12.6875Z" />
              ) : (
                <path d="M42 36H6V32H42V36ZM42 26H6V22H42V26ZM42 16H6V12H42V16Z" />
              )}
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`sm:flex cursor-pointer flex flex-row justify-center items-center text-right ${
          open ? "block" : "hidden"
        } `}
      >
        <div className="flex flex-row justify-center items-center">
          <Link
            to="/about"
            className="font-headers uppercase cursor:pointer block px-4 text-white font-bold rounded opacity-100 hover:text-green cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
          >
            About
          </Link>
          <Link
            to="/articles"
            className="font-headers uppercase cursor:pointer block px-4 text-white font-bold rounded opacity-100 hover:text-green cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
          >
            Articles
          </Link>
          <Link
            to="/careers"
            className="font-headers uppercase cursor:pointer block px-4 text-white font-bold rounded opacity-100 hover:text-green cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
          >
            Join the Tribe
          </Link>
          {/* <a
            href="https://bit.ly/ffarecruitment"
            target="_blank"
            rel="noreferrer"
            className="px-4 font-headers text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl uppercase cursor:pointer block text-white font-bold rounded  opacity-100 hover:text-green"
          >
            Tribe
          </a> */}
          {/* <Link to={user ? "/dashboard" : "/login"} className="text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-headers uppercase cursor:pointer block px-4 text-white font-bold rounded opacity-100 hover:text-green border-2 border-transparent cursor-pointer">
              SIGN IN
            </Link> */}
        </div>
      </div>
    </nav>
  );
}

export default NavBarPrimary;
