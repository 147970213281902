import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import PuffLoader from "react-spinners/PuffLoader";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const zuluUnitData = {
  teardownNumber: "- The Sounds of isiZulu",
  topic: "The Sounds of isiZulu",
  overview:
    "In this unit you will navigate the different sounds native to isiZulu. By the end of this unit you will be introduced to the pronunciations of each alphabetic symbol to make sure that you can easily work your way through speaking isiZulu with the confidence of a native mother tongue speaker.",
  outcomes: [
    "Identify the different vowel and consonant sounds in isiZulu",
    "Learn how to pronounce the click sounds found in isiZulu",
  ],
  estimatedDuration: "60 MINUTES",
  keyConcepts: ["VOWELS", "CONSONANTS", "CLICKS"],
};

const vowelsData = [
  {
    englishSound: "a",
    howToWrite: "ah",
    example: "hamba (hah-mbah)",
    meaning: "go",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand60.wav",
  },
  {
    englishSound: "e",
    howToWrite: "eh",
    example: "Ngiyezwa (ngee-yeh-zwah)",
    meaning: "I understand",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/PinkPanther60.wav",
  },
  {
    englishSound: "i",
    howToWrite: "ee",
    example: "emini (eh-mee-nee)",
    meaning: "at midday",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/StarWars60.wav",
  },
];

const consonantsData = [
  {
    englishSound: "b & bh",
    howToWrite: "b",
    example: "libhubesi (lee-boo-beh-see)",
    meaning: "lion",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand60.wav",
  },
  {
    englishSound: "nk",
    howToWrite: "nk",
    example: "Akunankinga (ah-goo-nah-nkee-ngah)",
    meaning: "No problem",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/PinkPanther60.wav",
  },
  {
    englishSound: "ng",
    howToWrite: "ng",
    example: "Ngiyabonga (ngee-yah-baw-ngah)",
    meaning: "thank you",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/StarWars60.wav",
  },
];

const clicksData = [
  {
    englishSound: "c",
    howToWrite: "/",
    example: "ngicela (ngee-/eh-lah)",
    meaning: "please",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand60.wav",
    howExplanation:
      "Place the tip of your tongue against the back of your top front teeth and pull the tongue down sharply to make a sucking sound (like 'tut tut').",
  },
  {
    englishSound: "q",
    howToWrite: "!",
    example: "amaqanda (ah-mah-!ah-ndah)",
    meaning: "eggs",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/PinkPanther60.wav",
    howExplanation:
      "Curl your tongue backward, place the front part against the middle part of your mouth’s roof, and pull down sharply (like pulling a cork).",
  },
  {
    englishSound: "x",
    howToWrite: "//",
    example: "Uxolo (oo-//aw-law)",
    meaning: "Excuse me",
    audioSrc: "https://www2.cs.uic.edu/~i101/SoundFiles/StarWars60.wav",
    howExplanation:
      "Keep your tongue against the roof of your mouth and suck in air through the sides (like a rider urging a horse).",
  },
];

/**
 * Simple Accordion component to show/hide details.
 */
function AccordionSection({ title, content, isOpen, onToggle }) {
  return (
    <div className="w-full max-w-3xl my-3">
      <button
        className="w-full text-left px-4 py-2 bg-red  
                   rounded-t-md font-semibold text-white border-2 border-black border-b-4 rounded"
        onClick={onToggle}
      >
        {title}
      </button>
      {isOpen && (
        <div className="px-4 py-2 border-2 border-black border-b-4 rounded">
          {content}
        </div>
      )}
    </div>
  );
}

/**
 * Main Guidebook Component
 */
export default function Guidebook() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Accordion states: whether vowels, consonants, clicks are open
  const [showVowels, setShowVowels] = useState(false);
  const [showConsonants, setShowConsonants] = useState(false);
  const [showClicks, setShowClicks] = useState(false);

  const onBack = () => {
    navigate("/units");
  };

  useEffect(() => {
    // Simulate a short loading period
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-screen min-h-[400px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <PuffLoader color={"#991616"} loading={loading} size={100} />
      </motion.div>
    );
  }

  const {
    teardownNumber,
    topic,
    overview,
    outcomes,
    estimatedDuration,
    keyConcepts,
  } = zuluUnitData;

  return (
    <motion.div
      className="h-full w-full bg-white p-4 m-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button
        onClick={() => onBack()}
        className="flex items-center text-red hover:text-black transition-colors"
      >
        <FiArrowLeft className="mr-2" /> Back to Vault
      </button>
      {/* Header / Title */}
      <h1 className="text-2xl font-bold text-left mt-4 mb-6 text-red">
        Unit 1 - Overview
      </h1>

      {/* Topic and overview */}
      <div className="max-w-3xl mx-auto mb-4">
        <h2 className="text-xl font-semibold mb-2">{topic}</h2>
        <p className="text-md mb-4 normal-case">{overview}</p>

        <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
          <div>
            <h3 className="font-semibold">
              {" "}
              <span className="text-outline-black">🎯</span> Outcomes:
            </h3>
            <ul className="list-disc ml-5 sentence">
              {outcomes.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
          {/* <div className="mt-4 sm:mt-0">
            <h3 className="font-semibold">Estimated Duration:</h3>
            <p>{estimatedDuration}</p>
          </div> */}
        </div>

        <div className="mb-4">
          <h3 className="font-semibold sentence">
            {" "}
            <span className="text-outline-black">⚡️</span> Key Concepts:
          </h3>
          <p>{keyConcepts.join(", ")}</p>
        </div>
      </div>

      {/* Accordions for Vowels, Consonants, Clicks */}
      <div className="mx-auto flex flex-col items-center">
        <AccordionSection
          title="Vowels"
          isOpen={showVowels}
          onToggle={() => setShowVowels(!showVowels)}
          content={
            <div>
              <p className="mb-2 text-sm text-gray">
                Below are some key vowels in isiZulu, with examples & audio:
              </p>
              <table className="w-full table-auto text-left mb-4">
                <thead>
                  <tr className="bg-red text-white">
                    <th className="px-2 py-1">ENGLISH SOUND</th>
                    <th className="px-2 py-1">HOW TO WRITE IT</th>
                    <th className="px-2 py-1">EXAMPLE</th>
                    <th className="px-2 py-1">MEANING</th>
                    <th className="px-2 py-1">AUDIO</th>
                  </tr>
                </thead>
                <tbody>
                  {vowelsData.map((vowel, idx) => (
                    <TableRowWithAudio key={idx} data={vowel} />
                  ))}
                </tbody>
              </table>
            </div>
          }
        />

        <AccordionSection
          title="Consonants"
          isOpen={showConsonants}
          onToggle={() => setShowConsonants(!showConsonants)}
          content={
            <div>
              <p className="mb-2 text-sm text-gray">
                Below are some key consonants in isiZulu, with examples & audio:
              </p>
              <table className="w-full table-auto text-left mb-4">
                <thead>
                  <tr className="bg-red text-white">
                    <th className="px-2 py-1">ENGLISH SOUND</th>
                    <th className="px-2 py-1">HOW TO WRITE IT</th>
                    <th className="px-2 py-1">EXAMPLE</th>
                    <th className="px-2 py-1">MEANING</th>
                    <th className="px-2 py-1">AUDIO</th>
                  </tr>
                </thead>
                <tbody>
                  {consonantsData.map((consonant, idx) => (
                    <TableRowWithAudio key={idx} data={consonant} />
                  ))}
                </tbody>
              </table>
            </div>
          }
        />

        <AccordionSection
          title="Clicks"
          isOpen={showClicks}
          onToggle={() => setShowClicks(!showClicks)}
          content={
            <div>
              <p className="mb-2 text-sm text-gray">
                isiZulu also features click sounds, which can seem intimidating
                at first. Here are some common ones:
              </p>
              <table className="w-full table-auto text-left mb-4">
                <thead>
                  <tr className="bg-red text-white">
                    <th className="px-2 py-1">CLICK SOUND</th>
                    <th className="px-2 py-1">HOW TO WRITE IT</th>
                    <th className="px-2 py-1">EXAMPLE</th>
                    <th className="px-2 py-1">MEANING</th>
                    <th className="px-2 py-1">AUDIO</th>
                  </tr>
                </thead>
                <tbody>
                  {clicksData.map((clickSound, idx) => (
                    <TableRowWithAudio key={idx} data={clickSound} />
                  ))}
                </tbody>
              </table>

              {/* If needed, we can show the 'How (click)' explanation below each row or separately */}
              <div className="text-sm text-gray mt-2">
                {clicksData.map((clickSound, idx) => (
                  <div key={idx} className="mt-2">
                    <strong>{clickSound.englishSound}:</strong>{" "}
                    {clickSound.howExplanation || ""}
                  </div>
                ))}
              </div>
            </div>
          }
        />
      </div>
    </motion.div>
  );
}

/**
 * Helper row component that renders table data and a small audio button.
 *
 * We store the audioRef in local state each time so the row can handle its own playback.
 * Alternatively, you could have a single audio element shared among all rows
 * if you prefer (like prior examples).
 */
function TableRowWithAudio({ data }) {
  const { englishSound, howToWrite, example, meaning, audioSrc } = data;
  const audioRef = useRef(null);

  const handlePlayAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  return (
    <tr className="border-b border-red">
      <td className="px-2 py-1">{englishSound}</td>
      <td className="px-2 py-1">{howToWrite}</td>
      <td className="px-2 py-1">{example}</td>
      <td className="px-2 py-1">{meaning}</td>
      <td className="px-2 py-1">
        {/* Hidden audio element */}
        <audio ref={audioRef} src={audioSrc} />
        <button
          onClick={handlePlayAudio}
          className="text-outline-red text-white px-2 py-1 rounded hover:bg-blue-600"
        >
          🔊
        </button>
      </td>
    </tr>
  );
}
