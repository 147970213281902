import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

export const QuestionPhase = ({ question, onAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  // Validate and transform question data
  const safeQuestion = {
    id: question?.id || "no-id",
    text: question?.text || "Question text missing",
    type: question?.type || "mcq",
    correctAnswers: question?.correctAnswers || [],
    options:
      question?.options?.map((opt) => ({
        id: opt?.id || String(Math.random()),
        text: opt?.text || "Unlabeled option",
        isCorrect: question?.correctAnswers?.includes(opt?.id) || false,
      })) || [],
  };

  const handleOptionSelection = (optionId) => {
    if (submitted) return;
    setSelectedOption(optionId);
  };

  // Reset state when question changes
  useEffect(() => {
    setSelectedOption(null);
    setSubmitted(false);
    setIsCorrect(null);
  }, [question?.id]);

  const handleSubmit = () => {
    if (!selectedOption || submitted) return;

    const correct = safeQuestion.correctAnswers.includes(selectedOption);
    setIsCorrect(correct);
    setSubmitted(true);

    // Delay the onAnswer callback to allow animation to play
    setTimeout(() => {
      onAnswer(correct);
    }, 1500);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      y: -30,
      transition: { duration: 0.3 },
    },
  };

  const optionVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.div
      className="question-container p-6 bg-white shadow-lg border-2 border-b-4 border-black max-w-2xl mx-auto"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/* Question header with icon */}
      <div className="mb-6 flex items-center">
        <span className="text-2xl mr-3">🤔</span>
        <h3 className="text-xl md:text-2xl font-bold text-black">
          {safeQuestion.text}
        </h3>
      </div>

      {/* Options list */}
      <div className="space-y-3 mb-6">
        {safeQuestion.options.map((option) => {
          const isSelected = selectedOption === option.id;
          const showCorrect = submitted && option.isCorrect;
          const showIncorrect = submitted && isSelected && !option.isCorrect;

          return (
            <motion.button
              key={option.id}
              variants={optionVariants}
              onClick={() => handleOptionSelection(option.id)}
              className={`
                w-full text-left p-4 transition-all duration-200
                border-2 border-b-4 flex items-center
                ${
                  isSelected
                    ? "border-green bg-green-50"
                    : "border-gray hover:border-darkgray hover:text-darkgray"
                }
                ${
                  showCorrect ? "bg-green text-white border-2 border-black" : ""
                }
                ${
                  showIncorrect ? "bg-red border-2 text-white border-black" : ""
                }
                ${submitted ? "cursor-default" : "cursor-pointer"}
              `}
              disabled={submitted}
            >
              {/* Selection indicator */}
              <span
                className={`
                inline-block w-6 h-6 mr-3 flex-shrink-0
                border-2 border-b-4 flex items-center justify-center
                ${
                  isSelected
                    ? "border-green bg-green text-white"
                    : "border-black hover:border-darkgray"
                }
                ${showCorrect ? "bg-green text-white border border-white" : ""}
                ${showIncorrect ? " bg-red text-white border border-white" : ""}
              `}
              >
                {showCorrect && "✓"}
                {showIncorrect && "✗"}
                {isSelected && !submitted && "•"}
              </span>

              {/* Option text */}
              <span
                className={
                  showCorrect || showIncorrect ? "text-white" : "text-black"
                }
              >
                {option.text}
              </span>
            </motion.button>
          );
        })}
      </div>

      {/* Submit button or feedback */}
      {!submitted ? (
        <motion.button
          onClick={handleSubmit}
          disabled={!selectedOption}
          className={`
            w-full py-3 font-medium
            transition-all duration-300
            ${
              !selectedOption
                ? "bg-darkgray text-black cursor-not-allowed"
                : "bg-green text-white hover:bg-greenthree"
            }
          `}
        >
          Check Answer
        </motion.button>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`
            text-center p-4 font-medium
            ${
              isCorrect
                ? "bg-green text-white border-2 border-black"
                : "bg-red text-white border-2 border-black"
            }
          `}
        >
          {isCorrect ? "✓ Correct! Good job." : "✗ That's not quite right."}
        </motion.div>
      )}
    </motion.div>
  );
};

QuestionPhase.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(["mcq", "checkbox", "fill"]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        isCorrect: PropTypes.bool,
      })
    ),
    correctAnswers: PropTypes.array,
  }).isRequired,
  onAnswer: PropTypes.func.isRequired,
};
