import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNotifications } from "../../helpers/NotificationsContext";
import { motion, AnimatePresence } from "framer-motion";

const NotificationBell = () => {
  const { notifications, unreadCount, markAsRead, markAllAsRead } =
    useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleNotificationClick = (notificationId) => {
    markAsRead(notificationId);
  };

  const handleMarkAllAsRead = (e) => {
    e.stopPropagation();
    markAllAsRead();
  };

  const dropdownVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      scale: 0.95,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 25,
      },
    },
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Bell icon with improved tap target */}
      <div
        className="flex items-center justify-center h-10 w-10 cursor-pointer"
        onClick={toggleDropdown}
        aria-label="Notifications"
      >
        <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-outline-black hover:text-outline-green relative">
          🔔
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 w-4 h-4 md:w-5 md:h-5 bg-green text-white text-xs rounded-full flex items-center justify-center">
              {unreadCount > 9 ? "9+" : unreadCount}
            </span>
          )}
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute right-0 mt-2 w-72 sm:w-80 bg-white rounded shadow-lg z-50 max-h-fit border-2 border-b-4 border-darkgray"
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className="p-3 border-b border-gray flex justify-between items-center">
              <h3 className="font-semibold text-sm md:text-base">
                Notifications
              </h3>
              {unreadCount > 0 && (
                <button
                  onClick={handleMarkAllAsRead}
                  className="text-xs text-green hover:underline py-1 px-2 rounded"
                >
                  Mark all as read
                </button>
              )}
            </div>

            <div className="overflow-y-auto max-h-64 md:max-h-72">
              {notifications.length === 0 ? (
                <div className="py-4 px-3 text-center text-gray">
                  No notifications yet
                </div>
              ) : (
                notifications.slice(0, 5).map((notification) => (
                  <div
                    key={notification.id}
                    className={`p-3 border-b border-gray hover:bg-gray/10 cursor-pointer ${
                      !notification.read ? "bg-darkgray/5" : ""
                    }`}
                    onClick={() => handleNotificationClick(notification.id)}
                  >
                    <div className="flex justify-between items-start">
                      <div className="w-full">
                        <p
                          className={`text-sm font-medium ${
                            !notification.read ? "font-bold" : ""
                          }`}
                        >
                          {notification.title}
                        </p>
                        <p className="text-xs text-gray my-1">
                          {notification.message}
                        </p>
                        <p className="text-xs text-gray">
                          {notification.createdAt.toLocaleString()}
                        </p>
                      </div>
                      {!notification.read && (
                        <span className="w-2 h-2 bg-green rounded-full mt-1 ml-2 flex-shrink-0"></span>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="p-3 md:p-4 text-center border-t border-gray">
              <Link
                to="/notifications"
                className="text-sm text-green hover:underline py-2 px-4 inline-block"
                onClick={() => setIsOpen(false)}
              >
                View all notifications
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NotificationBell;
