import { createContext, useContext, useReducer } from "react";

const DialogueFlowContext = createContext();

const initialState = {
  phase: "narrator", // narrator -> dialogue -> question -> complete
  currentStep: 0, // Current speech bubble index
  audioPlaying: false,
  userAttempts: 0,
  score: 0,
  questionsAnswered: [],
  speechProgress: 0,
  currentQuestion: 0,
  lastCompletedSpeechIndex: -1, // Track last completed speech for checkpoints
  questionCheckpoints: [], // Speech indices where questions should appear
  questionShownAtCheckpoints: [], // Track which checkpoints we've already shown questions for
};

const reducer = (state, action) => {
  switch (action.type) {
    case "NEXT_STEP":
      return {
        ...state,
        currentStep: state.currentStep + 1,
        lastCompletedSpeechIndex: state.currentStep,
      };

    case "NEXT_PHASE":
      const nextPhase = getNextPhase(state.phase);
      return {
        ...state,
        phase: nextPhase,
        // Reset step when moving to dialogue phase
        currentStep: nextPhase === "dialogue" ? 0 : state.currentStep,
      };

    case "SET_PHASE":
      return {
        ...state,
        phase: action.payload,
        // Reset step when explicitly setting phase to dialogue
        currentStep: action.payload === "dialogue" ? 0 : state.currentStep,
      };

    case "SHOW_QUESTION":
      // Calculate which question index to show based on checkpoint
      const currentCheckpointIndex = state.questionCheckpoints.indexOf(
        state.currentStep
      );

      return {
        ...state,
        phase: "question",
        lastCompletedSpeechIndex: state.currentStep,
        // Set the question index based on the checkpoint we're at
        currentQuestion:
          currentCheckpointIndex !== -1
            ? currentCheckpointIndex
            : state.currentQuestion,
        // Mark this checkpoint as having shown a question
        questionShownAtCheckpoints: [
          ...state.questionShownAtCheckpoints,
          state.currentStep,
        ],
      };

    case "RETURN_TO_DIALOGUE_NEXT_STEP":
      // Ensure we don't accidentally show questions again after returning
      const nextStep = state.lastCompletedSpeechIndex + 1;
      return {
        ...state,
        phase: "dialogue",
        // Go to the next step after the last completed one
        currentStep: nextStep,
        // Ensure this step won't trigger a question immediately
        questionShownAtCheckpoints: state.questionCheckpoints.includes(nextStep)
          ? [...state.questionShownAtCheckpoints, nextStep]
          : state.questionShownAtCheckpoints,
      };

    case "NEXT_QUESTION":
      return {
        ...state,
        currentQuestion: state.currentQuestion + 1,
      };

    case "ANSWER_QUESTION":
      return {
        ...state,
        score: state.score + (action.payload.isCorrect ? 100 : -25),
        userAttempts: state.userAttempts + action.payload.attempts,
        questionsAnswered: [...state.questionsAnswered, action.payload],
        // Do NOT increment currentQuestion here - we do that explicitly with NEXT_QUESTION
      };

    case "SET_QUESTION_CHECKPOINTS":
      return {
        ...state,
        questionCheckpoints: action.payload,
      };

    case "COMPLETE_DIALOGUE":
      return {
        ...state,
        phase: "complete",
      };

    case "SET_AUDIO_PLAYING":
      return {
        ...state,
        audioPlaying: action.payload,
      };

    case "RESET_DIALOGUE":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const getNextPhase = (currentPhase) => {
  const phases = ["narrator", "dialogue", "question", "complete"];
  const currentIndex = phases.indexOf(currentPhase);
  return phases[currentIndex + 1] || "complete";
};

export const DialogueFlowProvider = ({ children }) => (
  <DialogueFlowContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DialogueFlowContext.Provider>
);

export const useDialogueFlow = () => useContext(DialogueFlowContext);
