// CorrectFeedback.jsx with enhanced micro-interactions for kids
import React, { useState, useEffect } from "react";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import BeatLoader from "react-spinners/BeatLoader";
import { motion, AnimatePresence } from "framer-motion";

const CorrectFeedback = ({
  loading,
  hovered,
  onContinue,
  hidden,
  setHovered,
}) => {
  const [showStars, setShowStars] = useState(false);

  // Show stars with slight delay after component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStars(true);
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    if (!loading) {
      onContinue();
    }
  };

  // Celebration confetti/stars effect
  const StarAnimation = () => (
    <AnimatePresence>
      {showStars && (
        <>
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={`star-${i}`}
              className="absolute text-yellow-300 text-xl sm:text-2xl"
              initial={{
                opacity: 1,
                x: "50%",
                y: "50%",
                scale: 0,
              }}
              animate={{
                opacity: [1, 1, 0],
                scale: [0, 1, 1.5],
                y: ["50%", `${30 - i * 15}%`],
                x: [`50%`, `${40 + i * 5 * (i % 2 === 0 ? 1 : -1)}%`],
                rotate: Math.random() * 360,
              }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1.5 + Math.random(),
                ease: "easeOut",
                delay: i * 0.1,
              }}
            >
              ✨
            </motion.div>
          ))}
        </>
      )}
    </AnimatePresence>
  );

  return (
    <motion.div
      className="relative flex justify-center items-center border-t-2 border-darkgray w-full bg-green py-3 sm:py-4 overflow-hidden"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
    >
      {/* Star animations */}
      <StarAnimation />

      <div
        className={
          hidden
            ? "hidden"
            : "flex flex-row justify-between w-full sm:w-4/5 mx-auto px-2 sm:px-0"
        }
      >
        <div className="flex flex-row justify-center items-center">
          <motion.div
            initial={{ scale: 0, rotate: -180 }}
            animate={{
              scale: [0, 1.2, 1],
              rotate: [-180, 0],
            }}
            transition={{
              type: "spring",
              delay: 0.2,
              stiffness: 200,
              times: [0, 0.6, 1],
            }}
          >
            <IoCheckmarkCircleSharp className="my-2 sm:my-3 text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl text-white" />
          </motion.div>

          <div>
            <motion.h3
              className="font-headers font-bold text-white text-xs sm:text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
            >
              Well done
            </motion.h3>
            <motion.p
              className="font-body text-white text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg"
              initial={{ opacity: 0, x: -20 }}
              animate={{
                opacity: 1,
                x: 0,
                scale: [1, 1.1, 1], // Subtle pulse
              }}
              transition={{
                delay: 0.4,
                scale: {
                  times: [0, 0.5, 1],
                  duration: 0.8,
                  repeat: 2,
                  repeatType: "reverse",
                },
              }}
            >
              That's more like it!
            </motion.p>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center">
          <motion.button
            onClick={handleClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            disabled={loading}
            className="capitalize cursor-pointer text-xs sm:text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-1 pb-1 sm:pt-2 sm:pb-2 pl-3 pr-3 sm:pl-4 sm:pr-4 font-body text-center px-3 py-3 sm:px-4 sm:py-4 text-white bg-green font-bold border-2 hover:text-green border-white hover:border-green hover:bg-white rounded w-24 sm:w-32 h-10 sm:h-12 m-auto transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 0px 8px rgba(255,255,255,0.5)",
            }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5 },
            }}
          >
            {loading ? (
              <div className="px-4 sm:px-8">
                <BeatLoader
                  color={hovered ? "#848717" : "#FCFCEE"}
                  loading={loading}
                  size={5}
                />
              </div>
            ) : (
              "CONTINUE"
            )}
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default CorrectFeedback;
