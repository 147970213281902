import React, { useState } from "react";
import { Star, X } from "lucide-react";
import useBooking from "../../../hooks/useBooking";
import { formatDateTime } from "../../../utils/bookings/dateUtils";

const RatingModal = ({ booking, onClose, otherUser }) => {
  const { rateSession } = useBooking();
  const [rating, setRating] = useState(booking.rating || 0);
  const [feedback, setFeedback] = useState(booking.feedback || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const sessionDate = booking.sessionDateTime
    ? new Date(booking.sessionDateTime)
    : null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (rating === 0) {
      setError("Please select a rating");
      return;
    }

    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      const result = await rateSession(booking.id, rating, feedback);

      if (result && result.success) {
        setSuccess(result.message || "Rating submitted successfully");
        setTimeout(() => {
          onClose();
        }, 1500);
      } else {
        setError((result && result.message) || "Failed to submit rating");
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err);
      setError(err.message || "An error occurred while submitting your rating");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white border-2 border-b-4 border-darkgray shadow-lg p-4 sm:p-6 w-full max-w-md">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">Rate Your Session</h3>
          <button onClick={onClose} className="text-gray hover:text-gray-600">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <p className="text-gray mb-3 text-sm sm:text-base">
              How was your session with{" "}
              {otherUser?.displayName || otherUser?.name || "your coach"}?
              {sessionDate && (
                <span className="block text-xs sm:text-sm mt-1">
                  {formatDateTime(sessionDate, "EEEE, MMMM d, yyyy h:mm a")}
                </span>
              )}
            </p>

            <div className="flex justify-center space-x-1 sm:space-x-2 mb-6">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => setRating(star)}
                  className="focus:outline-none transition-transform hover:scale-110"
                >
                  <Star
                    size={window.innerWidth < 640 ? 36 : 48}
                    fill={rating >= star ? "#d19a10" : "none"}
                    color="#d19a10"
                    className="transition-all duration-150"
                  />
                </button>
              ))}
            </div>

            <div>
              <label className="block text-gray mb-2 text-sm sm:text-base">
                Feedback (Optional)
              </label>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Share your experience with this coach..."
                className="w-full p-2 border-b-4 border-2 border-gray rounded text-sm sm:text-base"
                rows={4}
              ></textarea>
            </div>

            {error && (
              <div className="mt-3 text-red-500 text-xs sm:text-sm">
                {error}
              </div>
            )}
            {success && (
              <div className="mt-3 text-green-500 text-xs sm:text-sm">
                {success}
              </div>
            )}
          </div>

          <div className="flex flex-col-reverse sm:flex-row sm:justify-end space-y-2 space-y-reverse sm:space-y-0 sm:space-x-3">
            <button
              type="button"
              className="px-4 py-2 border-2 text-gray border-b-4 border-gray rounded hover:bg-darkgray"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-gold cursor-pointer text-black border-black border-2 border-b-4 rounded hover:bg-opacity-80 flex items-center justify-center ${
                isSubmitting ? "opacity-70 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting || rating === 0}
            >
              {isSubmitting ? (
                <>
                  <span className="w-4 h-4 border-t-2 border-white rounded-full animate-spin mr-2"></span>
                  Submitting...
                </>
              ) : (
                "Submit Rating"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RatingModal;
