// components/articles/article-search.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ArticleSearch = ({
  placeholder = "Search for articles...",
  defaultValue = "",
  categorySlug = "",
}) => {
  const [query, setQuery] = useState(defaultValue);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      if (categorySlug) {
        // If searching within a category, we can apply additional filtering later
        navigate(
          `/articles/search?q=${encodeURIComponent(
            query.trim()
          )}&category=${categorySlug}`
        );
      } else {
        navigate(`/articles/search?q=${encodeURIComponent(query.trim())}`);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={placeholder}
          className="w-full p-4 pl-12 pr-10 border-b-4 border-2 border-darkgray rounded focus:border-red focus:outline-none"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="w-6 h-6">🔍</span>
        </div>
        <button
          type="submit"
          className="absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <span className="text-red hover:text-red-dark font-bold uppercase">
            Search
          </span>
        </button>
      </div>
    </form>
  );
};

export default ArticleSearch;
