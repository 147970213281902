import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { motion } from "framer-motion";
import childemoji from "../../../assets/emojis/child-soccer.png";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import PropTypes from "prop-types";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

const ReadAndRespond = ({ data }) => {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);
  const [shuffledOptions, setShuffledOptions] = useState([]);

  // Audio state
  const [fullPhraseUrl, setFullPhraseUrl] = useState("");
  const [wordAudioUrls, setWordAudioUrls] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioKey, setCurrentAudioKey] = useState(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  const audioRef = useRef(new Audio());

  // Apply audio settings when they change
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Compute the exercise data
  const exerciseData = useMemo(() => {
    if (!data) return null;

    // Extract source sentence text
    let sourceSentenceText = "";
    if (data.content.sourceSentence) {
      if (typeof data.content.sourceSentence === "string") {
        sourceSentenceText = data.content.sourceSentence;
      } else if (typeof data.content.sourceSentence === "object") {
        if (data.content.sourceSentence.text) {
          sourceSentenceText = data.content.sourceSentence.text;
        } else if (
          data.content.sourceSentence.wordBreakdown &&
          data.content.sourceSentence.wordBreakdown.length > 0
        ) {
          // Extract text from wordBreakdown if available
          sourceSentenceText = data.content.sourceSentence.wordBreakdown
            .map((word) => word.text)
            .join(" ");
        }
      }
    }

    // Extract highlighted word text
    let highlightedWordText = "";
    if (data.content.highlightedWord) {
      if (typeof data.content.highlightedWord === "string") {
        highlightedWordText = data.content.highlightedWord;
      } else if (typeof data.content.highlightedWord === "object") {
        highlightedWordText = data.content.highlightedWord.text || "";
      }
    }

    return {
      ...data,
      sourceSentenceText: sourceSentenceText,
      highlightedWordText: highlightedWordText,
    };
  }, [data]);

  // Shuffle answer options on mount, removing duplicates
  useEffect(() => {
    if (!exerciseData) return;

    try {
      const correctAnswer = exerciseData.validation.correctAnswer;
      let distractors = exerciseData.content.distractors || [];

      // Create a set of options (automatically removes duplicates)
      const optionsSet = new Set([correctAnswer, ...distractors]);

      // Convert back to array and shuffle
      const uniqueOptions = Array.from(optionsSet);
      setShuffledOptions(uniqueOptions.sort(() => Math.random() - 0.5));

      // Clear previous selections
      setFeedback(null);
      setOptionChosen(null);
    } catch (error) {
      console.error("Error preparing options:", error);
    }
  }, [exerciseData, setFeedback, setOptionChosen]);

  // Fetch the full phrase audio URL
  useEffect(() => {
    const fetchPhraseAudio = async () => {
      if (!exerciseData?.content) return;

      try {
        // Try multiple potential audio sources
        let audioSource = null;

        // Try sourceSentence.audio.fullPhrase
        if (exerciseData.content.sourceSentence?.audio?.fullPhrase) {
          audioSource = exerciseData.content.sourceSentence.audio.fullPhrase;
        }
        // Try direct audio path
        else if (exerciseData.audio) {
          audioSource = exerciseData.audio;
        }
        // Try vocabulary array audio
        else if (
          exerciseData.vocabulary &&
          exerciseData.vocabulary[0]?.audio?.fullPhrase
        ) {
          audioSource = exerciseData.vocabulary[0].audio.fullPhrase;
        }

        if (audioSource) {
          const url = await getAudioDownloadUrl(audioSource);
          setFullPhraseUrl(url);
          console.log("Fetched phrase audio:", url);

          // Set the URL on the audio element
          audioRef.current.src = url;
          audioRef.current.load();
        }
      } catch (err) {
        console.error("Error fetching phrase audio:", err);
      }
    };

    fetchPhraseAudio();
  }, [exerciseData]);

  // Fetch word audio URLs
  useEffect(() => {
    const fetchWordAudios = async () => {
      if (!exerciseData) return;

      try {
        const urls = {};

        // Fetch highlighted word audio if available
        if (exerciseData.content.highlightedWord?.audio) {
          urls[exerciseData.highlightedWordText] = await getAudioDownloadUrl(
            exerciseData.content.highlightedWord.audio
          );
        }

        // Fetch wordBreakdown audios if available
        const wordBreakdown =
          exerciseData.content.sourceSentence?.wordBreakdown;
        if (wordBreakdown && Array.isArray(wordBreakdown)) {
          for (const word of wordBreakdown) {
            if (word.audio) {
              urls[word.text] = await getAudioDownloadUrl(word.audio);
            }
          }
        }

        setWordAudioUrls(urls);
      } catch (error) {
        console.error("Error fetching word audios:", error);
      }
    };

    fetchWordAudios();
  }, [exerciseData]);

  const handleSelect = (option) => {
    if (!feedback) {
      setOptionChosen(option);
    }
  };

  // Audio control functions
  const handlePlay = (speed = 1.0) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioRef.current && fullPhraseUrl) {
      // If already playing this audio, stop it
      if (isPlaying && currentAudioKey === `phrase-${speed}`) {
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
        return;
      }

      // Set up audio playback
      audioRef.current.src = fullPhraseUrl;
      audioRef.current.playbackRate = speed;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlaying(true);
      setCurrentAudioKey(`phrase-${speed}`);
    }
  };

  const handlePlayWord = (wordText) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audioUrl = wordAudioUrls[wordText];
    if (audioUrl && audioRef.current) {
      // If already playing this audio, stop it
      if (isPlaying && currentAudioKey === wordText) {
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
        return;
      }

      // Set up audio playback
      audioRef.current.src = audioUrl;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlaying(true);
      setCurrentAudioKey(wordText);
    }
  };

  const handlePause = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioKey(null);
    }
  };

  if (!exerciseData) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  // Check if this is a single word or a full phrase
  const isSingleWord = !exerciseData.sourceSentenceText.includes(" ");

  // Get word breakdown if it exists, otherwise fallback
  const wordBreakdown = exerciseData.content.sourceSentence?.wordBreakdown;

  // For single word phrases without wordBreakdown
  const sourceSentenceWords = isSingleWord
    ? [exerciseData.sourceSentenceText || exerciseData.highlightedWordText]
    : exerciseData.sourceSentenceText.split(/\s+/);

  // Helper functions for audio button content
  const getAudioButtonContent = (speed) => {
    if (!audioEnabled) {
      return "🔇";
    }

    if (isPlaying && currentAudioKey === `phrase-${speed}`) {
      return "⏸️";
    }

    return speed === 1.0 ? "▶️" : "🐢";
  };

  return (
    <div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <h1 className="text-lg sm:text-xl font-bold text-left font-headers text-black mb-3 sm:mb-4">
          {exerciseData.content.question}
        </h1>

        <div className="mb-4 sm:mb-6 p-3 sm:p-4 bg-white border-2 border-b-4 border-black relative">
          {wordBreakdown ? (
            // Display with word breakdown
            <p className="text-base sm:text-lg italic">
              {wordBreakdown.map((word, idx) => {
                const isHighlighted =
                  word.text.toLowerCase() ===
                  exerciseData.highlightedWordText.toLowerCase();
                return (
                  <React.Fragment key={idx}>
                    <span
                      className={`cursor-pointer ${
                        isHighlighted ? "bg-[#d19a0d] px-1" : ""
                      } ${!audioEnabled ? "text-gray-500" : ""}`}
                      onMouseEnter={() =>
                        audioEnabled && handlePlayWord(word.text)
                      }
                      onMouseLeave={handlePause}
                    >
                      {word.text}
                      {isPlaying && currentAudioKey === word.text && (
                        <span className="ml-1 text-xs">🔊</span>
                      )}
                    </span>
                    {idx < wordBreakdown.length - 1 && " "}
                  </React.Fragment>
                );
              })}
            </p>
          ) : (
            // No word breakdown, display simple sentence
            <p
              className={`text-base sm:text-lg italic cursor-pointer ${
                !audioEnabled ? "text-gray-500" : ""
              }`}
              onMouseEnter={audioEnabled ? () => handlePlay(1.0) : undefined}
              onMouseLeave={handlePause}
            >
              {isSingleWord ? (
                // Just highlight the single word
                <span className="bg-[#d19a0d] px-1">
                  {sourceSentenceWords[0]}
                </span>
              ) : (
                // Handle multi-word sentence by highlighting the target word
                sourceSentenceWords.map((word, idx) => (
                  <React.Fragment key={idx}>
                    {word.toLowerCase() ===
                    exerciseData.highlightedWordText.toLowerCase() ? (
                      <span className="bg-[#d19a0d] px-1">{word}</span>
                    ) : (
                      word
                    )}
                    {idx < sourceSentenceWords.length - 1 && " "}
                  </React.Fragment>
                ))
              )}
              {isPlaying &&
                (currentAudioKey === "phrase-1" ||
                  currentAudioKey === "phrase-0.75") && (
                  <span className="ml-1 text-xs">🔊</span>
                )}
            </p>
          )}

          {/* Audio controls */}
          <div className="mt-3 sm:mt-4 flex space-x-4 justify-center">
            <button
              onClick={() => handlePlay(1.0)}
              className={`p-2 rounded border text-outline-black text-xl sm:text-2xl ${
                !audioEnabled ? "opacity-50" : ""
              }`}
              title={
                audioEnabled
                  ? "Play at normal speed"
                  : "Audio is disabled globally"
              }
            >
              {getAudioButtonContent(1.0)}
            </button>
            <button
              onClick={() => handlePlay(0.75)}
              className={`p-2 rounded border text-outline-black text-xl sm:text-2xl ${
                !audioEnabled ? "opacity-50" : ""
              }`}
              title={
                audioEnabled
                  ? "Play at slow speed"
                  : "Audio is disabled globally"
              }
            >
              {getAudioButtonContent(0.75)}
            </button>
          </div>

          {/* Audio disabled message */}
          {!audioEnabled && (
            <div className="text-center text-xs text-gray-500 mt-2">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}

          {exerciseData.translationNote && (
            <div className="absolute -bottom-3 right-4 bg-white px-2 text-xs sm:text-sm text-green">
              {exerciseData.translationNote}
            </div>
          )}
        </div>

        <div className="mb-4 sm:mb-6 p-2 sm:p-4 relative">
          <img
            alt="child-emoji"
            src={childemoji}
            className="m-auto text-4xl h-32 sm:h-48 cursor-pointer p-2 sm:p-4"
          />
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 mb-6">
          {shuffledOptions.map((option, index) => (
            <motion.button
              key={index}
              onClick={() => handleSelect(option)}
              className={`p-3 sm:p-4 text-left rounded-lg border-2 transition-all flex items-center ${
                optionChosen === option
                  ? "border-green border-b-4 bg-white text-green shadow-lg hover:shadow-xl"
                  : "bg-white text-gray border-gray hover:text-green hover:border-green"
              }`}
            >
              <span className="w-5 h-5 sm:w-6 sm:h-6 flex items-center justify-center mr-2 sm:mr-3 border rounded-full text-xs sm:text-sm">
                {String.fromCharCode(65 + index)}
              </span>
              <div className="flex-1 text-sm sm:text-base">{option}</div>
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
};

ReadAndRespond.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      question: PropTypes.string,
      distractors: PropTypes.array,
      highlightedWord: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string,
          audio: PropTypes.any,
        }),
      ]),
      sourceSentence: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string,
          audio: PropTypes.any,
          wordBreakdown: PropTypes.array,
        }),
      ]),
    }),
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string,
    }),
    audio: PropTypes.any,
    vocabulary: PropTypes.array,
    id: PropTypes.string,
  }),
};

export default ReadAndRespond;
