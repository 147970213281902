// components/achievements/AchievementItem.jsx
import React from "react";
import { motion } from "framer-motion";

const AchievementItem = ({ achievement }) => {
  const {
    icon,
    title,
    description,
    progress,
    progressText,
    isComplete,
    currentValue,
    targetValue,
  } = achievement;

  // Calculate the visual progress percentage (capped at 100% if complete)
  const visualProgressPercentage = isComplete ? 100 : Math.min(100, progress);

  // Format the progress text to show actual vs target for display
  const formattedProgressText = () => {
    // If we don't have explicit current/target values, try to parse them from progressText
    if (!currentValue && !targetValue && progressText) {
      const parts = progressText.split("/");
      if (parts.length === 2) {
        const current = parseInt(parts[0]);
        const target = parseInt(parts[1].split(" ")[0]); // Split off any text after the number

        if (!isNaN(current) && !isNaN(target)) {
          // If we successfully parsed values and achievement is complete
          if (isComplete) {
            return `${target}/${target}`;
          }
          return progressText;
        }
      }
      return progressText; // Unable to parse, use as-is
    }

    if (isComplete) {
      // For completed achievements, show the achievement requirement as met
      return `${targetValue}/${targetValue}`;
    } else {
      // For in-progress achievements, show actual progress
      return `${currentValue}/${targetValue}`;
    }
  };

  // For display purposes only - show actual progress in parentheses if exceeding target
  const extraProgressInfo = () => {
    if (isComplete && currentValue > targetValue) {
      return ` (${currentValue} total)`;
    }
    return "";
  };

  return (
    <div
      className={`border-2 border-b-4 rounded p-4 mb-3 ${
        isComplete ? "border-green bg-green bg-opacity-10" : "border-gray-300"
      }`}
    >
      <div className="flex items-start">
        <div className="text-3xl mr-3">{icon}</div>
        <div className="flex-1">
          <div className="flex justify-between items-center mb-1">
            <h4 className="font-bold">{title}</h4>
            {isComplete && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="bg-green text-white text-xs px-2 py-1 rounded-full"
              >
                Complete!
              </motion.div>
            )}
          </div>
          <p className="text-sm text-gray mb-2">{description}</p>

          <div className="w-full bg-darkgray border-gray border rounded-full h-2.5 mb-1">
            <motion.div
              className={`h-full rounded-full ${
                isComplete ? "bg-green" : "bg-gold"
              }`}
              initial={{ width: 0 }}
              animate={{ width: `${visualProgressPercentage}%` }}
              transition={{ duration: 1, delay: 0.2 }}
            />
          </div>

          <div className="flex justify-between text-xs text-gray">
            <span>
              {formattedProgressText()}
              {extraProgressInfo()}
            </span>
            <span>
              {isComplete ? "100" : Math.round(Math.min(100, progress))}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchievementItem;
