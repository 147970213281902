// Skills.js - Original layout with mobile responsiveness
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LearningMaterialsContext } from "../../../helpers/contexts";
import { LMContext } from "../../../helpers/context3";
import { useCourse } from "../../../helpers/CourseContextProvider.js";
import { useMilestone } from "../../../helpers/MilestoneProvider.js";
import { SkillsSocialData } from "../../../zombiedata/skills-social.js";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../../../helpers/UserAuthContextProvider.js";
import { db } from "../../../firebase/firebase.utils.js";
import UpdatedSkill from "./skill-dropdown.component.jsx";
import { PuffLoader } from "react-spinners";

// Define app colors
const COLORS = {
  red: "#991616",
  gold: "#d19a10",
  green: "#848717",
  white: "#FCFCEE",
  gray: "#808080",
  darkgray: "#DADADA",
};

function Skills({ unitId, unitName, enrollment, setEnrollment }) {
  const { courses } = useCourse();
  const { milestone } = useMilestone();
  const { setLearningMaterialState } = useContext(LearningMaterialsContext);
  const { setLearningMaterialId, learningMaterialId } = useContext(LMContext);
  const { user } = useAuth();
  const navigate = useNavigate();

  const [expandedSkillId, setExpandedSkillId] = useState(null);
  const [skillProgress, setSkillProgress] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [hoveredSkill, setHoveredSkill] = useState(null);
  const [activeSkill, setActiveSkill] = useState(null);

  // Directly access current skill and lesson from enrollment
  const currentSkill = enrollment?.currentEnroll?.currentSkill || 1;
  const currentLesson = enrollment?.currentEnroll?.currentLesson || 1;

  // User data fetching
  useEffect(() => {
    const getCurrUser = async () => {
      setIsLoading(true);
      if (user) {
        try {
          const docRef = doc(db, "users", user?.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();

            setEnrollment({
              ...userData,
              id: docSnap.id,
              currentSkill: userData.currentEnroll?.currentSkill || 1,
              currentLesson: userData.currentEnroll?.currentLesson || 1,
            });

            // Pre-calculate skill progress
            if (userData.progress) {
              const progressMap = {};
              // Get the current unit's skills
              const currentUnit = SkillsSocialData[courses]?.[milestone]?.find(
                (unit) => unit.unitId === unitId
              );

              if (currentUnit?.skills) {
                currentUnit.skills.forEach((skill) => {
                  if (skill.lessons) {
                    const completed = skill.lessons.filter(
                      (lesson) =>
                        userData.progress[skill.skillNumber]?.[
                          lesson.lessonNumber
                        ]?.completed
                    ).length;

                    // Make sure we calculate a valid percentage
                    const total = skill.lessons.length;
                    progressMap[skill.skillNumber] =
                      total > 0 ? (completed / total) * 100 : 0;
                  }
                });
                setSkillProgress(progressMap);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
          setIsFirstRender(false);
        }
      }
    };

    getCurrUser();
  }, [user, unitId, courses, milestone, setEnrollment]);

  const updateSkillProgress = (skillId, progress) => {
    setSkillProgress((prev) => ({ ...prev, [skillId]: progress }));
  };

  // Improved skill unlocking logic
  const isSkillUnlocked = (skill) => {
    if (!enrollment?.currentEnroll) return false;

    // A skill is unlocked if:
    // 1. It's the current skill or earlier
    // 2. OR it has completed lessons (for backward compatibility)
    const isCurrentOrEarlier =
      skill.skillNumber <= enrollment.currentEnroll.currentSkill;
    const hasCompletedLessons =
      enrollment.currentEnroll.skills?.[skill.skillNumber]?.completedLessons
        ?.length > 0;

    return isCurrentOrEarlier || hasCompletedLessons;
  };

  const calculateSkillProgress = (skill) => {
    // First try to use pre-calculated progress
    if (skillProgress[skill.skillNumber] !== undefined) {
      return skillProgress[skill.skillNumber];
    }

    // Otherwise calculate it
    if (!enrollment?.progress || !skill.lessons) return 0;

    const completed = skill.lessons.filter(
      (lesson) =>
        enrollment.progress[skill.skillNumber]?.[lesson.lessonNumber]?.completed
    ).length;

    return skill.lessons.length > 0
      ? (completed / skill.lessons.length) * 100
      : 0;
  };

  // Button click handler
  function handleSkillClick(skillId) {
    if (expandedSkillId === skillId) {
      setExpandedSkillId(null);
    } else {
      setExpandedSkillId(skillId);
    }
  }

  // Get the current unit's skills
  const currentUnit = SkillsSocialData[courses]?.[milestone]?.find(
    (unit) => unit.unitId === unitId
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-10">
        <PuffLoader color={COLORS.red} loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row flex-wrap justify-center items-center">
        {currentUnit?.skills?.map((skillItem) => {
          const unlocked = isSkillUnlocked(skillItem);
          const progress = calculateSkillProgress(skillItem);
          const isHovered = hoveredSkill === skillItem.skillNumber;
          const isActive = activeSkill === skillItem.skillNumber;
          const isExpanded = expandedSkillId === skillItem.skillNumber;

          // Different color for completed skills
          const progressColor =
            progress >= 100
              ? COLORS.green // Green for completed
              : COLORS.red; // Red for in-progress

          // Choose appropriate animations
          const pulseOnCompletion = progress >= 100;

          // Slower animation for first render to create a staggered effect
          const animationDuration = isFirstRender
            ? 1000 + skillItem.skillNumber * 200 // Staggered animation
            : 800; // Regular animation speed

          // Show percentage on hover
          const showPercentage = isHovered && unlocked;

          return (
            <div
              key={skillItem.skillNumber}
              className={`flex flex-col items-center mt-8 p-6 m-6 relative ${
                isExpanded ? "z-30" : "z-10"
              }`}
            >
              <div
                className={`relative inline-block transition-transform duration-300 ${
                  isActive ? "scale-95" : ""
                }`}
                onMouseEnter={() => setHoveredSkill(skillItem.skillNumber)}
                onMouseLeave={() => setHoveredSkill(null)}
              >
                <button
                  onClick={() => {
                    if (!unlocked) return;
                    handleSkillClick(skillItem.skillNumber);
                  }}
                  className={`relative z-10 m-auto border border-black border-r-2 border-b-2 px-6 py-6 drop-shadow cursor-pointer transform rotate-45 transition-all duration-300 ${
                    unlocked
                      ? `hover:shadow-lg ${
                          isHovered ? "shadow-lg bg-white/10" : ""
                        }`
                      : "bg-[#808080] cursor-not-allowed opacity-70"
                  }`}
                  disabled={!unlocked}
                  aria-expanded={isExpanded}
                  aria-label={`Skill ${skillItem.skillNumber}: ${skillItem.name}`}
                >
                  <h1
                    className={`text-2xl -rotate-22 px-4 py-3 font-bold rounded bg-red hover:bg-white border-r-4 border-2 hover:text-2xl rotate-22 m-auto transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4 transition-all duration-300 ${
                      unlocked
                        ? ""
                        : "bg-gray cursor-not-allowed hover:bg-gray grayscale-100"
                    }`}
                  >
                    <div className="text-2xl text-outline-black -rotate-45">
                      {skillItem.emoji}
                    </div>
                  </h1>
                </button>

                {/* Progress indicator */}
                {unlocked && (
                  <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 text-xs text-center text-gray">
                    {progress >= 100 ? (
                      <span className="text-green font-medium">Completed</span>
                    ) : (
                      <span>{Math.round(progress)}% complete</span>
                    )}
                  </div>
                )}

                {/* Tooltip on hover */}
                {isHovered && (
                  <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-black/80 text-white text-xs px-2 py-1 rounded whitespace-nowrap z-50">
                    {skillItem.name}
                  </div>
                )}
              </div>

              {/* Dropdown positioned below skill */}
              {isExpanded && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-4 z-40">
                  <UpdatedSkill
                    unitName={unitName}
                    skill={skillItem}
                    enrollment={enrollment}
                    selectLearningMaterialText={() =>
                      setLearningMaterialState(null)
                    }
                    setLearningMaterialState={setLearningMaterialState}
                    setLearningMaterialId={setLearningMaterialId}
                    learningMaterialId={learningMaterialId}
                    updateSkillProgress={(progress) =>
                      updateSkillProgress(skillItem.skillNumber, progress)
                    }
                    navigate={navigate}
                    onClose={() => {
                      setExpandedSkillId(null);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Skills;
