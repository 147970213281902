// components/modals/lives-modal.jsx - Mobile Responsive Version
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAttemptsWithNotifications } from "../../hooks/useAttemptsHook";
import useShopWithPowerUps from "../../hooks/useShopWithPowerUps";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";

const LivesModalContent = ({ onClose }) => {
  const { attempts, refreshAttempts, isRefreshing } =
    useAttemptsWithNotifications();
  const { userStats: shopUserStats } = useShopWithPowerUps();
  const { userStats: analyticsUserStats } = useSafeAnalytics();
  const [timeRemaining, setTimeRemaining] = useState("--:--");
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [purchasedLives, setPurchasedLives] = useState(0);

  // Get additional lives from userStats
  useEffect(() => {
    if (shopUserStats) {
      setPurchasedLives(shopUserStats.livesRemaining || 0);
    }
  }, [shopUserStats]);

  useEffect(() => {
    let interval;

    const calculateTime = () => {
      if (!attempts.nextRegeneration)
        return { display: "00:00", percentage: 100 };

      const now = new Date();
      const regenTime = attempts.nextRegeneration;
      const difference = regenTime - now;

      if (difference <= 0) {
        return { display: "00:00", percentage: 100 };
      }

      // Calculate total seconds in regeneration interval (30 minutes = 1800 seconds)
      const totalSeconds = 30 * 60;

      // Calculate seconds remaining
      const secondsRemaining = Math.floor(difference / 1000);

      // Calculate percentage complete for progress circle
      const percentComplete = 100 - (secondsRemaining / totalSeconds) * 100;

      const minutes = Math.floor(secondsRemaining / 60);
      const seconds = secondsRemaining % 60;

      return {
        display: `${String(minutes).padStart(2, "0")}:${String(
          seconds
        ).padStart(2, "0")}`,
        percentage: percentComplete,
      };
    };

    const updateTimer = () => {
      const { display, percentage } = calculateTime();
      setTimeRemaining(display);
      setProgressPercentage(percentage);

      // Auto-refresh when timer hits zero
      if (display === "00:00" && attempts.count < 5 && !isRefreshing) {
        refreshAttempts();
      }
    };

    // Initial update
    updateTimer();

    // Set interval for updates
    interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [
    attempts.nextRegeneration,
    attempts.count,
    refreshAttempts,
    isRefreshing,
  ]);

  const handleRefresh = async () => {
    try {
      await refreshAttempts();
    } catch (error) {
      console.error("Failed to refresh attempts:", error);
    }
  };

  // Calculate total available lives (regenerated + purchased)
  const totalLives = attempts.count + purchasedLives;

  return (
    <div className="space-y-3 md:space-y-4 px-2 md:px-4">
      <div className="text-center">
        <span className="text-3xl md:text-4xl">❤️</span>
        <h3 className="text-xl md:text-2xl font-bold mt-1 md:mt-2">
          Your Lives: {totalLives}
        </h3>
        <p className="text-xs md:text-sm text-gray mt-1">
          Lives allow you to take lessons. You lose a life when you make
          mistakes.
        </p>
      </div>

      {/* Display purchased lives if available */}
      {purchasedLives > 0 && (
        <div className="bg-green bg-opacity-10 p-3 md:p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <h4 className="font-semibold text-green text-sm md:text-base">
                Bonus Lives
              </h4>
              <p className="text-xs md:text-sm">
                You have <span className="font-bold">{purchasedLives}</span>{" "}
                additional lives from the shop
              </p>
            </div>
            <div className="h-10 w-10 md:h-12 md:w-12 flex items-center justify-center bg-green text-white rounded-full text-xl font-bold">
              +{purchasedLives}
            </div>
          </div>
        </div>
      )}

      {attempts.count < 5 && (
        <div className="bg-white border-2 border-b-4 shadow flex flex-col items-center border-darkgray p-3 md:p-4 rounded">
          <div className="flex flex-col sm:flex-row justify-center items-center gap-3 md:gap-4 w-full">
            <div className="text-center">
              <h4 className="font-semibold text-sm md:text-base">
                Next life in:
              </h4>
              <p className="text-lg md:text-xl font-bold">
                {isRefreshing ? "Loading..." : timeRemaining}
              </p>
            </div>
            <div className="relative h-14 w-14 md:h-16 md:w-16">
              <svg className="w-full h-full -rotate-90" viewBox="0 0 24 24">
                <circle
                  className="text-darkgray"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="transparent"
                  r="10"
                  cx="12"
                  cy="12"
                />
                <circle
                  className="text-red"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="transparent"
                  strokeLinecap="round"
                  strokeDasharray="62.8"
                  strokeDashoffset={62.8 - (progressPercentage / 100) * 62.8}
                  r="10"
                  cx="12"
                  cy="12"
                />
              </svg>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg md:text-xl font-bold">
                {attempts.count}
              </div>
            </div>
          </div>

          <button
            onClick={handleRefresh}
            disabled={isRefreshing || timeRemaining !== "00:00"}
            className={`mt-2 w-full sm:w-1/2 py-2 rounded border-b-4 border-2 text-sm md:text-base transition-all duration-200 ${
              isRefreshing || timeRemaining !== "00:00"
                ? "bg-darkgray text-black cursor-not-allowed"
                : "bg-green text-black hover:bg-opacity-90"
            }`}
          >
            {isRefreshing ? "Refreshing..." : "Refresh"}
          </button>
        </div>
      )}

      <div className="border-2 border-b-4 border-darkgray rounded mx-auto p-3 md:p-4">
        <h4 className="font-semibold text-left text-sm md:text-base">
          Get more lives:
        </h4>
        <Link to="/vault" onClick={onClose}>
          <button className="w-full mt-2 bg-gold text-black py-2 rounded border-b-4 border-2 border-black hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center text-xs md:text-sm">
            <span className="mr-2">🎯</span>
            Practice to earn lives
          </button>
        </Link>

        <Link to="/shop" onClick={onClose}>
          <button className="w-full mt-2 bg-red text-black py-2 rounded border-b-4 border-2 border-black hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center text-xs md:text-sm">
            <span className="mr-2">🛒</span>
            Buy lives in the Shop
          </button>
        </Link>
      </div>

      {attempts.practiceSessionsCompleted > 0 && (
        <div className="bg-gold bg-opacity-10 p-3 md:p-4 rounded-lg">
          <h4 className="font-semibold text-gold mb-2 text-sm md:text-base">
            Practice Progress
          </h4>
          <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
            <div
              className="bg-gold h-2 rounded-full"
              style={{
                width: `${(attempts.practiceSessionsCompleted / 6) * 100}%`,
              }}
            ></div>
          </div>
          <p className="text-xs md:text-sm text-gray-700">
            {attempts.practiceSessionsCompleted}/6 practice sessions completed
            for bonus lives
          </p>
        </div>
      )}

      {/* Enhanced stats section with analytics data */}
      <div className="border-darkgray border-b-4 border-2 p-3 md:p-4 rounded">
        <h4 className="font-semibold mb-2 text-sm md:text-base">
          How lives work:
        </h4>
        <ul className="space-y-1 text-xs md:text-sm text-gray-700">
          <li>• Lives regenerate automatically every 30 minutes</li>
          <li>• Maximum 5 standard lives at any time</li>
          <li>• Complete practice sessions to earn extra lives</li>
          <li>• You can purchase additional lives in the shop</li>
          <li>• Purchased lives are used after standard lives</li>
        </ul>
      </div>

      {/* Learning Stats */}
      <div className="border-darkgray border-b-4 border-2 p-3 md:p-4 rounded">
        <h4 className="font-semibold mb-2 text-sm md:text-base">
          Your Learning Stats:
        </h4>
        <div className="grid grid-cols-2 gap-2 md:gap-3">
          <div className="bg-gray-50 p-2 rounded text-center">
            <p className="text-xs text-gray-600">Practice Questions</p>
            <p className="font-bold text-sm md:text-base">
              {analyticsUserStats?.practice_questions_answered || 0}
            </p>
          </div>
          <div className="bg-gray-50 p-2 rounded text-center">
            <p className="text-xs text-gray-600">Today's XP</p>
            <p className="font-bold text-sm md:text-base">
              {analyticsUserStats?.today_xp || 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivesModalContent;
