import { useState } from "react";
import { useAuth } from "../../../helpers/UserAuthContextProvider";
import { DialogueContent } from "./dialogue-content.jsx";
import { Header } from "./header.jsx";
import ExitModal from "../../modals/caution-exit-modal.component.jsx";

/**
 * A modified version of LegacyDialogue that accepts the dialogue directly
 * instead of fetching it from useDialogues hook
 */
export const LegacyDialogue = ({
  dialogue,
  onClose,
  ids = ["close", "translate", "next"],
}) => {
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [toggled, toggle] = useState(false);

  if (!dialogue) return <div>No dialogue selected</div>;

  return (
    <div className="container mx-auto px-4">
      <div
        className={
          modal ? "fixed inset-0 z-50 bg-black bg-opacity-50" : "hidden"
        }
      >
        <ExitModal onConfirm={onClose} />
      </div>
      <Header
        currentDialogue={dialogue}
        setLearningMaterialId={() => {}}
        setModal={setModal}
        toggled={toggled}
        toggle={toggle}
        onDialogueComplete={() => {}}
        setScore={() => {}}
        ids={ids}
        isSubscribed="mabena" // Enable translation toggle
        hasPassed={true} // Enable translation toggle
      />

      <DialogueContent currentDialogue={dialogue} toggled={toggled} />
    </div>
  );
};
