// AuthRedirectService.js
/**
 * This service handles the redirection logic after authentication
 * to ensure users are properly directed to the early access onboarding flow
 * or to the main application based on their status.
 *
 * TEMPORARY IMPLEMENTATION: Using email-based lookups instead of UIDs
 */

import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/firebase.utils";

class AuthRedirectService {
  /**
   * Gets a user-specific localStorage key
   *
   * @param {string} userId - The user's ID
   * @param {string} key - The base key name
   * @returns {string} - The user-specific key
   */
  static getUserStorageKey(userId, key) {
    return `user_${userId}_${key}`;
  }

  /**
   * Clears all onboarding-related localStorage values for a specific user
   *
   * @param {string} userId - The user's ID
   */
  static clearUserOnboardingStorage(userId) {
    localStorage.removeItem(
      this.getUserStorageKey(userId, "earlyAccessOnboardingCompleted")
    );
    localStorage.removeItem(
      this.getUserStorageKey(userId, "earlyAccessOnboardingProgress")
    );

    // Also remove old-style storage
    localStorage.removeItem("earlyAccessOnboardingCompleted");
    localStorage.removeItem("earlyAccessOnboardingProgress");

    // For debugging - log the cleared values
    console.log(`Cleared onboarding storage for user ${userId}`);
  }

  /**
   * Find a user document by email address
   *
   * @param {string} email - The user's email
   * @returns {Promise<Object|null>} - The user document and reference, or null if not found
   */
  static async findUserByEmail(email) {
    if (!email) return null;

    try {
      // Query for the user by email
      console.log("Auth redirect running and checking user status");
      const usersRef = collection(db, "authorized_users");
      const q = query(usersRef, where("email", "==", email.toLowerCase()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log(`No user found with email: ${email}`);
        return null;
      }

      // Return the first matching document
      const doc = querySnapshot.docs[0];
      return {
        id: doc.id,
        data: doc.data(),
        ref: doc.ref,
      };
    } catch (error) {
      console.error(`Error finding user by email: ${error}`);
      return null;
    }
  }

  /**
   * Checks if the user has completed the early access onboarding process
   *
   * @param {Object} user - Firebase user object
   * @returns {Promise<boolean>} - Whether the user has completed onboarding
   */
  static async hasCompletedOnboarding(user) {
    if (!user || !user.uid) return false;

    // Check the user-specific localStorage key
    const storageKey = this.getUserStorageKey(
      user.uid,
      "earlyAccessOnboardingCompleted"
    );
    const completedOnboarding = localStorage.getItem(storageKey);

    // For debugging - log the localStorage value
    console.log(
      `Onboarding completion check for ${user.uid}: localStorage=${completedOnboarding}`
    );

    if (completedOnboarding === "true") {
      return true;
    }

    // If not in localStorage, check Firestore using email
    try {
      const userDoc = await this.findUserByEmail(user.email);

      // For debugging - log the database check result
      console.log(
        `Firestore check for ${user.email}: found=${!!userDoc}, data=`,
        userDoc ? JSON.stringify(userDoc.data) : "N/A"
      );

      if (userDoc && userDoc.data.onboarding_completed === true) {
        // Update localStorage for future checks
        localStorage.setItem(storageKey, "true");
        return true;
      }

      // If no document or onboarding not completed
      return false;
    } catch (error) {
      console.error("Error checking onboarding status:", error);
      return completedOnboarding === "true"; // Fallback to localStorage
    }
  }

  /**
   * Checks if the user is authorized for early access
   *
   * @param {Object} user - Firebase user object
   * @returns {Promise<boolean>} - Whether the user is authorized for early access
   */
  static async isAuthorizedForEarlyAccess(user) {
    if (!user) return false;

    try {
      // Find the user document by email
      const userDoc = await this.findUserByEmail(user.email);

      // For debugging - log the authorization check
      console.log(
        `Authorization check for ${user.email}: found=${!!userDoc}, data=`,
        userDoc ? JSON.stringify(userDoc.data) : "N/A"
      );

      return userDoc && userDoc.data.status === "approved";
    } catch (error) {
      console.error("Error checking early access authorization:", error);

      // For debugging - log the error
      console.log(`Authorization error for ${user.email}: ${error.message}`);

      return false;
    }
  }

  /**
   * Determines the appropriate redirect path after authentication
   *
   * @param {Object} user - Firebase user object
   * @param {boolean} forceCheck - Whether to force a recheck of onboarding status
   * @returns {Promise<string>} - The path to redirect to
   */
  static async getRedirectPath(user, forceCheck = false) {
    if (!user) {
      return "/login"; // Not authenticated
    }

    // For debugging - log the redirect check
    console.log(`Calculating redirect path for user ${user.email}`);

    // If forceCheck is true, clear the localStorage cache
    if (forceCheck) {
      this.clearUserOnboardingStorage(user.uid);
      console.log(
        `Force check requested - cleared localStorage for ${user.uid}`
      );
    }

    // Check if user is authorized for early access
    const isAuthorized = await this.isAuthorizedForEarlyAccess(user);
    if (!isAuthorized) {
      // Return to login with early access error flag
      console.log(
        `User ${user.email} is not authorized for early access, redirecting to login`
      );
      return "/login?error=early_access_unauthorized";
    }

    // Check if user has completed onboarding
    const hasCompleted = await this.hasCompletedOnboarding(user);
    if (!hasCompleted) {
      console.log(
        `User ${user.email} has not completed onboarding, redirecting to onboarding`
      );
      return "/early-access-onboarding";
    }

    // User is authenticated, authorized, and has completed onboarding
    console.log(
      `User ${user.email} is fully authorized and has completed onboarding, redirecting to dashboard`
    );
    return "/dashboard";
  }

  /**
   * Sets up an auth state listener that will redirect users based on their status
   *
   * @param {Object} auth - Firebase auth instance
   * @param {Function} navigate - React Router's navigate function
   * @returns {Function} - Unsubscribe function to clean up the listener
   */
  static setupAuthRedirection(auth, navigate) {
    return onAuthStateChanged(auth, async (user) => {
      if (user) {
        const redirectPath = await this.getRedirectPath(user);
        navigate(redirectPath, { replace: true });
      } else {
        // Clear onboarding data on logout
        localStorage.removeItem("earlyAccessOnboardingCompleted");
        localStorage.removeItem("earlyAccessOnboardingProgress");
        navigate("/login", { replace: true });
      }
    });
  }

  /**
   * Updates the user's onboarding status in both localStorage and Firestore
   *
   * @param {string} userId - The user's ID
   * @param {string} email - The user's email
   * @param {boolean} completed - Whether onboarding is completed
   * @returns {Promise<boolean>} - Whether the update was successful
   */
  static async updateOnboardingStatus(userId, email, completed = true) {
    // Update localStorage using user-specific key
    const storageKey = this.getUserStorageKey(
      userId,
      "earlyAccessOnboardingCompleted"
    );
    localStorage.setItem(storageKey, completed.toString());

    // For debugging - log the localStorage update
    console.log(
      `Updated localStorage for ${userId}: ${storageKey}=${completed}`
    );

    // Update Firestore
    try {
      // Find the user document by email
      const userDoc = await this.findUserByEmail(email);

      if (!userDoc) {
        console.error(
          `Cannot update onboarding status: No user found with email ${email}`
        );
        return false;
      }

      // Update existing document
      await updateDoc(userDoc.ref, {
        onboarding_completed: completed,
        onboarding_completed_date: serverTimestamp(),
      });

      console.log(
        `Updated Firestore for ${email}: onboarding_completed=${completed}`
      );
      return true;
    } catch (error) {
      console.error("Error updating onboarding status in Firestore:", error);

      // For debugging - log the error
      console.log(`Firestore update error for ${email}: ${error.message}`);

      // Return false since we couldn't update Firestore
      return false;
    }
  }

  /**
   * Force clear the onboarding status and redirect to onboarding
   *
   * @param {string} userId - The user's ID
   * @param {string} email - The user's email
   * @param {Function} navigate - React Router's navigate function
   */
  static async resetOnboardingAndRedirect(userId, email, navigate) {
    // Clear localStorage
    this.clearUserOnboardingStorage(userId);

    // Try to update Firestore
    try {
      const userDoc = await this.findUserByEmail(email);

      if (userDoc) {
        await updateDoc(userDoc.ref, {
          onboarding_completed: false,
          onboarding_completed_date: null,
        });
        console.log(`Reset onboarding in Firestore for ${email}`);
      }
    } catch (error) {
      console.error(`Error resetting onboarding in Firestore: ${error}`);
    }

    // Navigate to onboarding
    navigate("/early-access-onboarding", { replace: true });

    console.log(
      `Reset onboarding for ${email} and redirected to onboarding flow`
    );
  }
}

export default AuthRedirectService;
