// components/support/ArticlePreview.jsx
import React from "react";
import { Link } from "react-router-dom";

const ArticlePreview = ({ article }) => {
  return (
    <Link
      to={`/support/article/${article.id}`}
      className="block p-5 mb-4 border-2 border-b-4 border-darkgray rounded hover:border-green transition-colors"
    >
      <h3 className="text-lg font-medium mb-2">{article.title}</h3>
      <p className="text-sm text-gray-600 mb-3">{article.preview}</p>
      <div className="flex justify-between items-center">
        <div className="flex space-x-2">
          {article.tags.slice(0, 2).map((tag) => (
            <span
              key={tag}
              className="px-2 py-1 bg-gray-100 text-xs rounded-full"
            >
              {tag}
            </span>
          ))}
        </div>
        <span className="text-red text-sm">Read more →</span>
      </div>
    </Link>
  );
};

export default ArticlePreview;
