import React from "react";
import ProgressBar from "./progress-bar.component";
import * as Io5Icons from "react-icons/io5";

const HeaderBar = ({ handleOpenModal, attempts, isPracticeMode = false }) => (
  <div className="text-xl sm:text-2xl flex flex-row items-center justify-between px-2 sm:px-6 py-2 w-full sm:w-11/12 lg:w-5/6 mx-auto h-[70px] sm:h-[80px]">
    {/* Left side - Exit button - increased tap target for mobile */}
    <div className="">
      <button className="cursor-pointer p-2" onClick={handleOpenModal}>
        <Io5Icons.IoCloseSharp className="text-2xl sm:text-3xl text-gray hover:text-green" />
      </button>
    </div>

    {/* Center - Progress bar and/or Practice Mode label */}
    <div className="flex-1 flex flex-col items-center max-w-md mx-auto">
      {isPracticeMode && (
        <div className="flex items-center bg-gold border-b-4 border-2 border-gold bg-opacity-10 px-2 sm:px-4 py-1 rounded">
          <span className="text-gold mr-1 sm:mr-2 text-base sm:text-lg">
            🏋🏾‍♀️
          </span>
          <span className="text-xs sm:text-sm font-semibold text-gold">
            Practice Mode
          </span>
        </div>
      )}
      {/* The progress bar component itself */}
      <div className="w-full">
        <ProgressBar />
      </div>
    </div>

    {/* Right side - Attempts/Lives counter */}
    <div className="flex justify-end">
      <div className="flex items-center gap-1">
        <div className="text-xl sm:text-2xl text-outline-black">❤️</div>
        <div className="text-base sm:text-lg font-semibold">
          {attempts.count}
        </div>
      </div>
    </div>
  </div>
);

export default HeaderBar;
