import { initializeApp } from "@firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: "ffa-beta",
  storageBucket: "ffa-beta.appspot.com",
  messagingSenderId: "145349147628",
  appId: "1:145349147628:web:4d62b27ed44c18e4dddec9",
  measurementId: "G-75G5J9MLPC",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "us-central1");
export const analytics = getAnalytics(app);

export const storage = getStorage(app);
export const messaging = getMessaging(app);

// Your existing requestToken function can be updated to this:
export const requestToken = async (showPrompt = false) => {
  try {
    // Check if notification permission is already granted
    if (Notification.permission === "granted") {
      // Permission already granted, get token
      return getTokenAndStore();
    } else if (Notification.permission === "denied") {
      // Permission was previously denied
      // console.log("Notification permission previously denied");
      return null;
    } else {
      // Need to request permission
      if (showPrompt) {
        // Only show browser prompt if user clicked a UI button
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          return getTokenAndStore();
        } else {
          // console.log("Notification permission denied by user");
          return null;
        }
      } else {
        // Don't show browser prompt automatically
        // console.log("Notification permission not requested automatically");
        return null;
      }
    }
  } catch (err) {
    console.error("Error requesting notification permission:", err);
    return null;
  }
};

// Helper function to get token and store it
const getTokenAndStore = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: `${process.env.REACT_APP_CLOUD_MESSAGE_VAPID_KEY}`,
    });

    if (currentToken) {
      // console.log("FCM token obtained:", currentToken);

      // Store token in user's document if user is logged in
      const user = auth.currentUser;
      if (user) {
        await storeTokenForUser(user.uid, currentToken);
      }

      return currentToken;
    } else {
      console.log("No registration token available");
      return null;
    }
  } catch (err) {
    console.error("Error retrieving registration token:", err);
    return null;
  }
};

// Function to store FCM token in user's document
export const storeTokenForUser = async (userId, token) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      fcmTokens: arrayUnion(token),
    });
    // console.log("FCM token stored for user", userId);
    return true;
  } catch (error) {
    console.error("Error storing FCM token:", error);
    return false;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("payload", payload);
      resolve(payload);
    });
  });

// Optional: Custom analytics helper functions
export const trackPageView = (pageName) => {
  logEvent(analytics, "page_view", {
    page_title: pageName,
    page_location: window.location.href,
  });
};

export const trackAppError = (error) => {
  logEvent(analytics, "exception", {
    description: error.message,
    fatal: false,
  });
};

export const LearningAnalyticsEvents = {
  // Lesson Events
  LESSON_STARTED: "lesson_started",
  LESSON_COMPLETED: "lesson_completed",

  // Vocabulary Events
  VOCABULARY_UNLOCKED: "vocabulary_unlocked",
  FLASHCARD_VIEWED: "flashcard_viewed",
  FLASHCARD_MASTERED: "flashcard_mastered",
  DICTIONARY_SEARCHED: "dictionary_searched",

  // Practice Events
  PRACTICE_SESSION_STARTED: "practice_session_started",
  PRACTICE_QUESTION_ANSWERED: "practice_question_answered",

  // Expert Interaction Events
  EXPERT_SESSION_BOOKED: "expert_session_booked",
  EXPERT_SESSION_ATTENDED: "expert_session_attended",

  // Engagement Events
  DAILY_LOGIN: "daily_login",
  STREAK_MAINTAINED: "streak_maintained",

  // Error Tracking
  MISTAKE_MADE: "mistake_made",
};
