// src/components/exercises/MCQFlash.jsx
import React, { useContext, useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import PropTypes from "prop-types";
import AudioWord from "../common/audio-word.component.jsx";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";
import BeatLoader from "react-spinners/BeatLoader";

function MCQFlash({ data }) {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);
  const [loading, setLoading] = useState(true);

  // Audio state
  const [audioUrls, setAudioUrls] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioKey, setCurrentAudioKey] = useState(null);
  const [audioLoading, setAudioLoading] = useState(true);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  const audioRef = useRef(new Audio());

  // Apply audio settings when they change
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Reset selection and feedback when data changes
  useEffect(() => {
    setOptionChosen(null);
    setFeedback(null);

    // Simulate loading state
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [data, setOptionChosen, setFeedback]);

  // Load audio URLs for options
  useEffect(() => {
    const fetchAudioUrls = async () => {
      if (!data?.content?.options) {
        setAudioLoading(false);
        return;
      }

      setAudioLoading(true);

      try {
        const urls = {};

        await Promise.all(
          data.content.options.map(async (option) => {
            if (!option || !option.audio) return;

            try {
              // Get key for the audio URL (usually the option text)
              const key = option.text || String(option);

              // Get the download URL
              urls[key] = await getAudioDownloadUrl(option.audio);
            } catch (err) {
              console.error(`Error fetching audio for option:`, option, err);
            }
          })
        );

        setAudioUrls(urls);
      } catch (err) {
        console.error("Error fetching audio URLs:", err);
      } finally {
        setAudioLoading(false);
      }
    };

    fetchAudioUrls();
  }, [data]);

  // Play audio for an option
  const handlePlayAudio = (optionText) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const url = audioUrls[optionText];
    if (!url || !audioRef.current) return;

    // If already playing this audio, stop it
    if (isPlaying && currentAudioKey === optionText) {
      audioRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioKey(null);
      return;
    }

    // Set up audio playback
    audioRef.current.src = url;
    audioRef.current.currentTime = 0;

    // Play and update state
    audioRef.current.play().catch((err) => {
      console.error("Audio playback error:", err);
    });

    setIsPlaying(true);
    setCurrentAudioKey(optionText);
  };

  // Handle user selecting an option
  const handleOptionClick = (optionText) => {
    if (!feedback) {
      setOptionChosen(optionText);
    }
  };

  // Defensive check for valid data
  if (
    !data ||
    !data.content?.options ||
    !Array.isArray(data.content?.options)
  ) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  // Show loading state
  const isLoadingState = loading || audioLoading;
  if (isLoadingState) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-full min-h-[300px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <BeatLoader color={"#848717"} loading={loading} size={15} />
      </motion.div>
    );
  }

  return (
    <motion.div
      className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <div className="w-full">
          {/* The question prompt */}
          <div className="border-2 border-b-4 border-black font-body p-3 sm:p-4">
            <p
              data-testid="exercise-question"
              className="text-base sm:text-lg italic font-medium text-black p-2 sm:p-4"
            >
              {data.content.question}
            </p>

            {/* Audio disabled message */}
            {!audioEnabled && (
              <div className="text-center text-xs text-gray-500 pb-2">
                Audio is disabled globally. Enable it in Settings.
              </div>
            )}
          </div>
        </div>

        {/* Render each option as a button with text + image */}
        <form className="w-full mt-2 flex flex-col justify-center items-center">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 max-w-[600px]">
            {data.content.options.map((option, index) => {
              // Determine if user selected this option
              const isSelected = optionChosen === option.text;
              const isCorrect =
                option.isCorrect ||
                option.text === data.validation?.correctAnswer;
              const isAudioPlaying =
                isPlaying && currentAudioKey === option.text;

              // Determine styling based on feedback
              let buttonClass = `
                transition-all 
                py-3 px-3 sm:p-4 rounded-lg border-2 border-b-4 font-body text-sm 
                flex flex-col items-center justify-center
                cursor-pointer
                focus:outline-none transform active:scale-95 active:translate-y3 active:border-b-2 ease-in-out
                min-h-[70px] sm:min-h-[80px]
              `;

              if (feedback) {
                if (isCorrect && isSelected) {
                  // Correct selection styling
                  buttonClass += " bg-green text-white border-green";
                } else if (isCorrect && !isSelected) {
                  // Correct answer styling when user selects wrong
                  buttonClass += " bg-green text-white border-green";
                } else if (!isCorrect && isSelected) {
                  // Incorrect selection styling
                  buttonClass += " bg-red text-white border-red";
                } else {
                  // Not selected and not correct
                  buttonClass += " bg-white text-gray border-gray";
                }
              } else if (isSelected) {
                // Selected but feedback not yet shown
                buttonClass += " text-gold border-gold";
              } else {
                // Not selected, no feedback yet
                buttonClass +=
                  " bg-white text-gray border-gray hover:text-green hover:border-green hover:bg-white";
              }

              return (
                <button
                  key={option.text || index}
                  type="button"
                  onClick={() => handleOptionClick(option.text)}
                  disabled={!!feedback} // Disable after selection
                  className={buttonClass}
                >
                  {/* Display the image (could be an emoji or URL) */}
                  {option.image && (
                    <div className="text-5xl sm:text-7xl mb-1 sm:mb-2 text-outline-gray">
                      {option.image}
                    </div>
                  )}

                  {/* Display the text with audio */}
                  <div
                    className={`flex items-center ${
                      !audioEnabled ? "text-gray-500" : ""
                    }`}
                    onMouseEnter={() =>
                      audioEnabled && handlePlayAudio(option.text)
                    }
                  >
                    <span>{option.text}</span>
                    {audioUrls[option.text] && (
                      <span className="ml-1 text-xs">
                        {isAudioPlaying ? "🔊" : audioEnabled ? "🔈" : "🔇"}
                      </span>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
        </form>
      </div>
    </motion.div>
  );
}

// Updated PropTypes to account for different data formats
MCQFlash.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.shape({
      question: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          image: PropTypes.string,
          isCorrect: PropTypes.bool,
          audio: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              storagePath: PropTypes.string,
              url: PropTypes.string,
            }),
          ]),
        })
      ).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string,
    }),
    topic: PropTypes.string,
    day: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};

export default MCQFlash;
