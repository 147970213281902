import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { X, Gift, AlertTriangle, CreditCard } from "lucide-react";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import { initiatePayfastPayment } from "../../../firebase/bookings-api";

const PaymentModal = ({ slot, coachProfile, onClose, onConfirm, error }) => {
  const [userRewards, setUserRewards] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [localError, setLocalError] = useState(null);
  const [fetchingRewards, setFetchingRewards] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("payfast"); // Default to PayFast
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Testing price in Rands
  const TEST_PRICE = 3; // R3 for testing
  const PRODUCTION_PRICE = 200; // R200 for production

  // Use test price for now
  const sessionPriceRands = TEST_PRICE;

  // Merge error state - prioritize parent component error if it exists
  const displayError = error || localError;

  // Fetch user's reward points on component mount
  useEffect(() => {
    const fetchUserRewards = async () => {
      try {
        setFetchingRewards(true);
        const user = auth.currentUser;
        if (!user) {
          setFetchingRewards(false);
          return;
        }

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRewards(userData.rewardPoints || 0);
        }
        setFetchingRewards(false);
      } catch (error) {
        console.error("Error fetching user rewards:", error);
        setLocalError("Failed to fetch your reward points. Please try again.");
        setFetchingRewards(false);
      }
    };

    fetchUserRewards();
  }, []);

  // Calculate session duration in minutes
  const duration =
    (new Date(slot.endDateTime) - new Date(slot.startDateTime)) / (1000 * 60);

  // Assuming a rate of 1 reward point per minute
  const sessionPointCost = duration;

  const formatDateTime = (dateTime) => {
    return format(new Date(dateTime), "EEEE, MMMM d, yyyy h:mm a");
  };

  const handlePayfastPayment = async () => {
    try {
      setIsProcessing(true);
      setLocalError(null);
      setIsRedirecting(true);

      console.log(
        "Initiating PayFast payment for coach:",
        coachProfile.id,
        "slot:",
        slot.id
      );

      // Call the initiatePayfastPayment function from your API
      const result = await initiatePayfastPayment(coachProfile.id, slot.id);
      console.log("Payment initiated successfully:", result);

      if (result.success && result.paymentUrl) {
        // Store booking ID in session storage before redirecting
        try {
          sessionStorage.setItem("pendingBookingId", result.bookingId);
          console.log(
            "Stored pendingBookingId in session storage:",
            result.bookingId
          );

          // Log a message to verify the data was stored
          const storedId = sessionStorage.getItem("pendingBookingId");
          console.log(
            "Verified pendingBookingId in session storage:",
            storedId
          );
        } catch (storageError) {
          console.error(
            "Error storing bookingId in sessionStorage:",
            storageError
          );
          // Continue anyway - we'll have fallbacks
        }

        console.log("Redirecting to PayFast:", result.paymentUrl);

        // Small delay to ensure logging completes
        setTimeout(() => {
          window.location.href = result.paymentUrl;
        }, 500); // Increased delay to ensure storage completes
      } else {
        throw new Error(
          "Failed to create payment request: No payment URL returned"
        );
      }
    } catch (error) {
      console.error("Payment request failed:", error);
      setLocalError(`Failed to initiate payment: ${error.message}`);
      setIsProcessing(false);
      setIsRedirecting(false);
    }
  };

  const handleRewardPointsPayment = () => {
    // Never allow booking if user has insufficient points
    if (userRewards < sessionPointCost) {
      setLocalError("Insufficient reward points to book this session");
      return;
    }

    setIsProcessing(true);
    setLocalError(null);

    // Double-check again right before submission
    if (userRewards < sessionPointCost) {
      setLocalError("Insufficient reward points to book this session");
      setIsProcessing(false);
      return;
    }

    // Call parent component's onConfirm function with "rewards" payment method
    onConfirm("rewards");
  };

  const handlePayment = () => {
    if (paymentMethod === "rewards") {
      handleRewardPointsPayment();
    } else if (paymentMethod === "payfast") {
      handlePayfastPayment();
    }
  };

  // Determine if booking should be disabled
  const disableRewardBooking =
    userRewards < sessionPointCost || fetchingRewards;
  const disableBooking =
    isProcessing || (paymentMethod === "rewards" && disableRewardBooking);

  // Check if user has insufficient points to properly display warning
  const hasInsufficientPoints =
    paymentMethod === "rewards" &&
    userRewards < sessionPointCost &&
    !fetchingRewards;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 py-6">
      <div className="bg-white rounded border-b-4 border-2 border-darkgray shadow-lg p-4 sm:p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">Confirm Booking</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
            disabled={isProcessing}
          >
            <X size={20} />
          </button>
        </div>

        {displayError && (
          <div className="bg-red-100 border border-red-400 text-red-700 p-3 rounded-md flex items-start mb-4">
            <AlertTriangle size={18} className="mr-2 mt-0.5 flex-shrink-0" />
            <span className="text-sm">{displayError}</span>
          </div>
        )}

        <div className="mb-6">
          <h4 className="font-medium text-gray-700 mb-2">Session Details</h4>
          <div className="bg-gray-100 p-3 rounded-md">
            <div className="flex justify-between mb-2 flex-wrap gap-1">
              <span className="text-gray-600">Coach:</span>
              <span className="font-medium">{coachProfile?.name}</span>
            </div>
            <div className="flex justify-between mb-2 flex-wrap gap-1">
              <span className="text-gray-600">Date & Time:</span>
              <span className="font-medium text-right">
                {formatDateTime(slot.startDateTime)}
              </span>
            </div>
            <div className="flex justify-between flex-wrap gap-1">
              <span className="text-gray-600">Duration:</span>
              <span className="font-medium">{duration} minutes</span>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h4 className="font-medium text-gray-700 mb-2">Payment Method</h4>
          <div className="space-y-3">
            {/* PayFast Payment Option */}
            <label
              className={`flex items-center p-3 border-2 border-b-4 rounded ${
                paymentMethod === "payfast"
                  ? "border-green ring-1 ring-green"
                  : ""
              }`}
            >
              <input
                type="radio"
                name="paymentMethod"
                value="payfast"
                checked={paymentMethod === "payfast"}
                onChange={() => setPaymentMethod("payfast")}
                className="mr-2"
              />
              <CreditCard className="mr-2 text-gold flex-shrink-0" size={20} />
              <div className="flex-grow min-w-0">
                <div className="flex justify-between flex-wrap gap-1">
                  <span className="text-sm sm:text-base">
                    Pay with Credit Card / EFT
                  </span>
                  <span className="font-medium text-sm sm:text-base">
                    R{sessionPriceRands}
                  </span>
                </div>
                <div className="text-xs text-gray-500 mt-1">
                  Secure payment via PayFast
                </div>
              </div>
            </label>

            {/* Reward Points Option */}
            <label
              className={`flex items-center p-3 border-2 border-b-4 rounded ${
                paymentMethod === "rewards"
                  ? hasInsufficientPoints
                    ? "border-red-400"
                    : "border-green ring-1 ring-green"
                  : ""
              }`}
            >
              <input
                type="radio"
                name="paymentMethod"
                value="rewards"
                checked={paymentMethod === "rewards"}
                onChange={() => setPaymentMethod("rewards")}
                className="mr-2"
                disabled={disableRewardBooking}
              />
              <Gift
                className={`mr-2 flex-shrink-0 ${
                  hasInsufficientPoints ? "text-red-400" : "text-gold"
                }`}
                size={20}
              />
              <div className="flex-grow min-w-0">
                <div className="flex justify-between flex-wrap gap-1">
                  <span className="text-sm sm:text-base">
                    Reward Points Available:
                  </span>
                  {fetchingRewards ? (
                    <span className="font-medium text-sm sm:text-base">
                      Loading...
                    </span>
                  ) : (
                    <span className="font-medium text-sm sm:text-base">
                      {userRewards}
                    </span>
                  )}
                </div>
                <div className="flex justify-between mt-1 flex-wrap gap-1">
                  <span className="text-sm sm:text-base">Session Cost:</span>
                  <span
                    className={`font-medium text-sm sm:text-base ${
                      hasInsufficientPoints ? "text-red-400" : ""
                    }`}
                  >
                    {sessionPointCost} points
                  </span>
                </div>
              </div>
            </label>
          </div>

          {hasInsufficientPoints && (
            <div className="text-red-500 text-xs sm:text-sm mt-2 p-2 bg-red-100 rounded-md flex items-start">
              <AlertTriangle size={14} className="mr-1 mt-0.5 flex-shrink-0" />
              <span>
                You don't have enough reward points to book this session. You
                need {sessionPointCost - userRewards} more points.
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-col sm:flex-row justify-end sm:space-x-3 space-y-2 sm:space-y-0">
          <button
            type="button"
            className="px-4 py-2 border-2 border-b-4 border-gray text-gray rounded hover:bg-gray-100 order-2 sm:order-1"
            onClick={onClose}
            disabled={isProcessing}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`px-4 py-2 border-2 border-b-4 border-green text-green rounded hover:bg-opacity-80 flex items-center justify-center order-1 sm:order-2 ${
              disableBooking ? "opacity-70 cursor-not-allowed" : ""
            }`}
            onClick={handlePayment}
            disabled={disableBooking}
          >
            {isProcessing ? (
              <>
                <span className="w-5 h-5 border-t-2 border-white rounded-full animate-spin mr-2"></span>
                {isRedirecting ? "Redirecting to PayFast..." : "Processing..."}
              </>
            ) : hasInsufficientPoints ? (
              "Insufficient Points"
            ) : paymentMethod === "rewards" ? (
              "Book with Rewards"
            ) : (
              "Pay with PayFast"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
