// src/components/common/audio-player.jsx
import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";

const AudioPlayer = ({
  audioPath,
  color = "#991616",
  size = "medium",
  label,
  className = "",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audioUrl, setAudioUrl] = useState("");
  const [error, setError] = useState(null);
  const audioRef = useRef(new Audio());

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Map size to button dimensions
  const sizeMap = {
    small: "w-8 h-8 text-sm",
    medium: "w-10 h-10 text-base",
    large: "w-12 h-12 text-lg",
  };

  // Set up audio and event listeners
  useEffect(() => {
    let isMounted = true;

    const loadAudio = async () => {
      if (!audioPath) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const url = await getAudioDownloadUrl(audioPath);
        if (isMounted) {
          setAudioUrl(url);
          audioRef.current.src = url;
          audioRef.current.load();
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Error loading audio:", err);
        if (isMounted) {
          setError("Failed to load audio");
          setIsLoading(false);
        }
      }
    };

    loadAudio();

    // Set up event handlers
    const audio = audioRef.current;

    const handleEnded = () => {
      if (isMounted) setIsPlaying(false);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      if (isMounted) {
        setError("Audio playback error");
        setIsPlaying(false);
      }
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      isMounted = false;
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
    };
  }, [audioPath]);

  // Apply global audio setting whenever it changes
  useEffect(() => {
    audioRef.current.muted = !audioEnabled;
  }, [audioEnabled]);

  // Handle click to play/pause
  const handlePlayPause = () => {
    // Check global audio setting before playing
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      audio.currentTime = 0;
      audio.play().catch((err) => {
        console.error("Playback error:", err);
        setError("Playback error");
      });
      setIsPlaying(true);
    }
  };

  // Determine button appearance based on state
  const getButtonContent = () => {
    if (isLoading) {
      return <span>🔄</span>;
    }

    if (error) {
      return <span>❌</span>;
    }

    if (!audioEnabled) {
      return <span>🔇</span>;
    }

    return isPlaying ? <span>⏸️</span> : <span>🔊</span>;
  };

  return (
    <div className={`flex items-center ${className}`}>
      <motion.button
        className={`${
          sizeMap[size]
        } flex items-center justify-center rounded-full text-white ${
          isLoading || error ? "opacity-60" : "hover:opacity-90"
        }`}
        style={{ backgroundColor: color }}
        whileTap={{ scale: 0.95 }}
        onClick={handlePlayPause}
        disabled={isLoading || error}
        title={
          isLoading
            ? "Loading audio..."
            : error
            ? "Error loading audio"
            : !audioEnabled
            ? "Audio is disabled globally"
            : isPlaying
            ? "Pause"
            : "Play"
        }
      >
        {getButtonContent()}
      </motion.button>

      {label && (
        <span className="ml-2 text-sm">
          {label}
          {!audioEnabled && " (Audio disabled)"}
        </span>
      )}

      {error && <span className="ml-2 text-xs text-red-500">{error}</span>}
    </div>
  );
};

export default AudioPlayer;
