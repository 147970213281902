// src/helpers/ClassroomContextProvider.js

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import { useAuth } from "./UserAuthContextProvider";

// Create the context
const ClassroomContext = createContext();

// Create a custom hook to use the context
export const useClassroom = () => {
  return useContext(ClassroomContext);
};

export const ClassroomProvider = ({ children }) => {
  const { user, isTeacher } = useAuth();

  // State for classrooms
  const [classrooms, setClassrooms] = useState([]);
  const [teacherClassrooms, setTeacherClassrooms] = useState([]);
  const [studentClassrooms, setStudentClassrooms] = useState([]);
  const [currentClassroom, setCurrentClassroom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch classrooms based on user role
  useEffect(() => {
    if (!user?.uid) {
      setClassrooms([]);
      setTeacherClassrooms([]);
      setStudentClassrooms([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    // Create queries based on user role
    const teacherQuery = query(
      collection(db, "classrooms"),
      where("coachId", "==", user.uid),
      orderBy("createdAt", "desc")
    );

    const studentQuery = query(
      collection(db, "classrooms"),
      where("students", "array-contains", user.uid),
      orderBy("createdAt", "desc")
    );

    // Subscribe to updates for teacher classrooms
    const teacherUnsubscribe = onSnapshot(
      teacherQuery,
      (snapshot) => {
        const classroomsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          role: "teacher",
        }));
        setTeacherClassrooms(classroomsData);

        // Combined list for all classrooms
        setClassrooms((prevClassrooms) => {
          const studentRooms = prevClassrooms.filter(
            (room) => room.role === "student"
          );
          return [...classroomsData, ...studentRooms];
        });

        setLoading(false);
      },
      (err) => {
        console.error("Error fetching teacher classrooms:", err);
        setError(err.message);
        setLoading(false);
      }
    );

    // Subscribe to updates for student classrooms
    const studentUnsubscribe = onSnapshot(
      studentQuery,
      (snapshot) => {
        const classroomsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          role: "student",
        }));
        setStudentClassrooms(classroomsData);

        // Combined list for all classrooms
        setClassrooms((prevClassrooms) => {
          const teacherRooms = prevClassrooms.filter(
            (room) => room.role === "teacher"
          );
          return [...teacherRooms, ...classroomsData];
        });

        setLoading(false);
      },
      (err) => {
        console.error("Error fetching student classrooms:", err);
        setError(err.message);
        setLoading(false);
      }
    );

    // Cleanup subscriptions
    return () => {
      teacherUnsubscribe();
      studentUnsubscribe();
    };
  }, [user]);

  // Get classroom by ID
  const getClassroom = async (classroomId) => {
    try {
      const classroomRef = doc(db, "classrooms", classroomId);
      const classroomDoc = await getDoc(classroomRef);

      if (!classroomDoc.exists()) {
        return { success: false, error: "Classroom not found" };
      }

      const classroomData = {
        id: classroomDoc.id,
        ...classroomDoc.data(),
      };

      setCurrentClassroom(classroomData);
      return { success: true, data: classroomData };
    } catch (error) {
      console.error("Error fetching classroom:", error);
      return { success: false, error: error.message };
    }
  };

  // Get all assignments for a classroom
  const getClassroomAssignments = async (classroomId) => {
    try {
      const assignmentsQuery = query(
        collection(db, "assignments"),
        where("classroomId", "==", classroomId),
        orderBy("dateAssigned", "desc")
      );

      const assignmentsSnapshot = await getDocs(assignmentsQuery);

      const assignmentsData = assignmentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return { success: true, data: assignmentsData };
    } catch (error) {
      console.error("Error fetching assignments:", error);
      return { success: false, error: error.message };
    }
  };

  // Get all sessions for a classroom
  const getClassroomSessions = async (classroomId) => {
    try {
      const sessionsQuery = query(
        collection(db, "coaching_sessions"),
        where("classroomId", "==", classroomId),
        orderBy("datetime", "desc")
      );

      const sessionsSnapshot = await getDocs(sessionsQuery);

      const sessionsData = sessionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return { success: true, data: sessionsData };
    } catch (error) {
      console.error("Error fetching sessions:", error);
      return { success: false, error: error.message };
    }
  };

  // Get all students in a classroom
  const getClassroomStudents = async (classroomId) => {
    try {
      const classroomRef = doc(db, "classrooms", classroomId);
      const classroomDoc = await getDoc(classroomRef);

      if (!classroomDoc.exists()) {
        return { success: false, error: "Classroom not found" };
      }

      const studentIds = classroomDoc.data().students || [];

      // If no students, return empty array
      if (studentIds.length === 0) {
        return { success: true, data: [] };
      }

      // Get student details
      const studentsData = await Promise.all(
        studentIds.map(async (studentId) => {
          const studentRef = doc(db, "users", studentId);
          const studentDoc = await getDoc(studentRef);

          if (studentDoc.exists()) {
            return {
              id: studentDoc.id,
              ...studentDoc.data(),
            };
          }

          return null;
        })
      );

      // Filter out null values (students not found)
      const validStudents = studentsData.filter((student) => student !== null);

      return { success: true, data: validStudents };
    } catch (error) {
      console.error("Error fetching students:", error);
      return { success: false, error: error.message };
    }
  };

  const value = {
    classrooms,
    teacherClassrooms,
    studentClassrooms,
    currentClassroom,
    loading,
    error,
    getClassroom,
    getClassroomAssignments,
    getClassroomSessions,
    getClassroomStudents,
    setCurrentClassroom,
  };

  return (
    <ClassroomContext.Provider value={value}>
      {children}
    </ClassroomContext.Provider>
  );
};
