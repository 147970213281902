// helpers/BookingsContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "./UserAuthContextProvider";
import { useLocation } from "react-router-dom";
import { useNotifications } from "./NotificationsContext"; // Import the notifications context
import {
  getUserBookings,
  getCoaches,
  getCoachAvailability as getCoachAvailabilityApi,
  bookSession as bookSessionApi,
  cancelBooking as cancelBookingApi,
  completeSession as completeSessionApi,
  rateSession as rateSessionApi,
  initiatePayfastPayment,
  checkPaymentStatus,
} from "../firebase/bookings-api";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";

// Import notification utilities
import {
  createBookingNotification,
  createPaymentConfirmedNotification,
  createPaymentFailedNotification,
  createBookingCancelledNotification,
  createSessionCompletedNotification,
} from "../utils/notifications/booking-notifications-utils";

// Create context
export const BookingsContext = createContext();

// Custom hook to use the bookings context
export const useBooking = () => {
  const context = useContext(BookingsContext);
  if (context === undefined) {
    throw new Error("useBooking must be used within a BookingProvider");
  }
  return context;
};

// Booking provider component
export const BookingProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();
  const location = useLocation();
  const { addNotification } = useNotifications(); // Get addNotification function
  const [userRole, setUserRole] = useState(null);
  const [isCoach, setIsCoach] = useState(false);
  const [coaches, setCoaches] = useState([]);
  const [userBookings, setUserBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pendingBooking, setPendingBooking] = useState(null);

  // Fetch user role and bookings when user changes AND auth is not loading
  useEffect(() => {
    if (authLoading) {
      return;
    }

    const fetchUserData = async () => {
      if (!user) {
        setUserRole(null);
        setIsCoach(false);
        setUserBookings([]);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        // Get user profile to determine role
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role || "student");
          setIsCoach(userData.role === "coach");
        } else {
          setUserRole("student");
          setIsCoach(false);
        }

        // Fetch user's bookings
        await refreshBookings();

        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        setIsLoading(false);
        setError("Failed to load user data. Please try again.");
      }
    };

    fetchUserData();
  }, [user, authLoading]);

  // Enhanced payment return handling
  useEffect(() => {
    if (!user || authLoading) return;

    // Get current path and check if we're on a return page
    const currentPath = location.pathname;
    const isSuccessPath = currentPath.includes("/booking/success");
    const isCancelPath = currentPath.includes("/booking/cancel");

    // Only process if we're on a relevant path
    if (isSuccessPath || isCancelPath) {
      // console.log(`Detected return from PayFast: ${currentPath}`);

      // Check URL parameters - PayFast may use different parameter names
      const urlParams = new URLSearchParams(location.search);

      // Look for booking ID in various possible parameter names
      const bookingId =
        urlParams.get("booking_id") ||
        urlParams.get("m_payment_id") ||
        sessionStorage.getItem("pendingBookingId");

      // console.log("Found booking ID in return URL:", bookingId);

      if (!bookingId) {
        console.warn("No booking ID found in return URL or session storage");
        return;
      }

      // Clear from session storage
      sessionStorage.removeItem("pendingBookingId");

      if (isSuccessPath) {
        // Handle successful payment return
        // console.log(
        //   `Processing successful payment return for booking: ${bookingId}`
        // );

        // Check booking status in database
        checkPaymentStatus(bookingId)
          .then(async (result) => {
            // console.log("Payment status check result:", result);

            // Get booking details for notifications
            const bookingDoc = await getDoc(doc(db, "bookings", bookingId));
            const bookingData = bookingDoc.exists()
              ? { id: bookingId, ...bookingDoc.data() }
              : { id: bookingId };

            if (result.success) {
              if (result.status === "upcoming") {
                setPendingBooking({
                  id: bookingId,
                  status: "success",
                  message:
                    "Your booking has been confirmed! Check your email for details.",
                  meetingLink: result.meetingLink,
                });

                // Send payment confirmed notification
                addNotification(
                  createPaymentConfirmedNotification(bookingData)
                );
              } else if (result.status === "payment_failed") {
                setPendingBooking({
                  id: bookingId,
                  status: "failed",
                  message:
                    "Your payment could not be processed. Please try again.",
                });

                // Send payment failed notification
                addNotification(createPaymentFailedNotification(bookingData));
              } else if (result.status === "pending_payment") {
                setPendingBooking({
                  id: bookingId,
                  status: "pending",
                  message:
                    "Your payment is being processed. We'll update you once confirmed.",
                });
              }
            } else {
              // Handle unsuccessful result
              setPendingBooking({
                id: bookingId,
                status: "error",
                message:
                  "We couldn't verify your payment status. Please contact support.",
              });
            }

            // Always refresh bookings to get latest data
            refreshBookings();
          })
          .catch((error) => {
            console.error("Error checking payment status:", error);
            setPendingBooking({
              id: bookingId,
              status: "error",
              message:
                "Error checking your payment status. Please contact support.",
            });
          });
      } else if (isCancelPath) {
        // Handle payment cancellation
        // console.log(
        //   `Processing payment cancellation for booking: ${bookingId}`
        // );

        setPendingBooking({
          id: bookingId,
          status: "cancelled",
          message:
            "Your payment was cancelled. The timeslot will be released shortly.",
        });

        refreshBookings();
      }
    }
  }, [location, user, authLoading, addNotification]); // Added addNotification to dependencies

  // Fetch available coaches
  useEffect(() => {
    if (authLoading || userRole === null) {
      return;
    }

    const fetchCoaches = async () => {
      try {
        setError(null);
        const result = await getCoaches();
        setCoaches(result.coaches || []);
      } catch (error) {
        console.error("Failed to fetch coaches:", error);
        setCoaches([]);
        setError("Failed to load coaches. Please try again.");
      }
    };

    fetchCoaches();
  }, [userRole, authLoading]);

  // Add this function to your BookingsContext.js file
  // Replace the existing refreshBookings function

  const refreshBookings = async () => {
    // console.log("[BookingsContext] Starting refreshBookings");

    if (!user) {
      // console.log("[BookingsContext] No user, skipping refresh");
      return false;
    }

    try {
      setError(null);

      // Wrap getUserBookings in a try/catch
      let bookingsResult;
      try {
        // console.log("[BookingsContext] Calling getUserBookings API");
        bookingsResult = await getUserBookings();
        // console.log(
        //   "[BookingsContext] getUserBookings returned:",
        //   bookingsResult
        // );
      } catch (apiError) {
        console.error(
          "[BookingsContext] API error in getUserBookings:",
          apiError
        );
        setUserBookings([]);
        return false;
      }

      // Verify the bookingsResult structure
      if (!bookingsResult || typeof bookingsResult !== "object") {
        console.warn(
          "[BookingsContext] Invalid bookings result:",
          bookingsResult
        );
        setUserBookings([]);
        return false;
      }

      // Make sure we have a bookings array, even if empty
      const bookingsArray = Array.isArray(bookingsResult.bookings)
        ? bookingsResult.bookings
        : [];

      // console.log(
      //   "[BookingsContext] Processing",
      //   bookingsArray.length,
      //   "bookings"
      // );

      // Process each booking, with error handling
      const enhancedBookings = await Promise.all(
        bookingsArray.map(async (booking) => {
          try {
            // Check if this booking already has the needed information
            if (!booking) {
              console.warn("[BookingsContext] Found null/undefined booking");
              return null;
            }

            // Get the role information for user display
            const otherUserRole = isCoach ? "student" : "coach";
            const otherUserId = booking[`${otherUserRole}Id`];
            const otherUserNameField = `${otherUserRole}Name`;

            // Only fetch if we need to
            if (otherUserId && !booking[otherUserNameField]) {
              try {
                const userRef = doc(db, "users", otherUserId);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  // Add name information
                  booking[otherUserNameField] =
                    userData.name || userData.displayName || otherUserRole;
                }
              } catch (userError) {
                console.error(
                  `[BookingsContext] Error fetching ${otherUserRole} details:`,
                  userError
                );
                // Use a default name if we can't get the real one
                booking[otherUserNameField] = otherUserRole;
              }
            }

            return booking;
          } catch (error) {
            console.error(`[BookingsContext] Error processing booking:`, error);
            return booking; // Return the original booking if enhancement fails
          }
        })
      );

      // Filter out any null bookings
      const filteredBookings = enhancedBookings.filter(
        (booking) => booking !== null
      );

      // console.log(
      //   "[BookingsContext] Setting",
      //   filteredBookings.length,
      //   "bookings"
      // );
      setUserBookings(filteredBookings);
      return true;
    } catch (error) {
      console.error("[BookingsContext] Failed to refresh bookings:", error);
      setError("Failed to refresh bookings. Please try again.");
      setUserBookings([]); // Set to empty array on error
      return false;
    }
  };

  // Get coach's available slots
  const fetchCoachAvailability = async (coachId, startDate, endDate) => {
    try {
      setError(null);
      const result = await getCoachAvailabilityApi(coachId, startDate, endDate);
      return result.availableSlots || [];
    } catch (error) {
      console.error("Failed to fetch coach availability:", error);
      setError("Failed to load availability. Please try again.");
      return [];
    }
  };

  // Book a session with a coach
  const bookSession = async (
    coachId,
    availabilityId,
    paymentMethod = "coins"
  ) => {
    if (!user) {
      throw new Error("You must be logged in to book a session");
    }

    try {
      setError(null);

      // Handle different payment methods
      if (paymentMethod === "payfast") {
        // For PayFast, we need to initiate the payment and get a payment URL
        const result = await initiatePayfastPayment(coachId, availabilityId);

        if (result.success && result.paymentUrl) {
          // Save pending booking id for reference
          sessionStorage.setItem("pendingBookingId", result.bookingId);

          // Get coach info for notification
          const coachDoc = await getDoc(doc(db, "users", coachId));
          const coachName = coachDoc.exists()
            ? coachDoc.data().name ||
              coachDoc.data().displayName ||
              "your coach"
            : "your coach";

          // Get availability info
          const availabilityDoc = await getDoc(
            doc(db, "users", coachId, "availability", availabilityId)
          );
          const sessionDateTime = availabilityDoc.exists()
            ? availabilityDoc.data().startDateTime?.toDate()
            : new Date();

          // Send booking created notification
          addNotification(
            createBookingNotification({
              id: result.bookingId,
              coachId,
              coachName,
              sessionDateTime,
            })
          );

          // Redirect to PayFast payment page
          window.location.href = result.paymentUrl;

          // Return result for UI handling
          return result;
        } else {
          throw new Error(result.error || "Failed to initiate payment");
        }
      } else {
        // For reward points or coins, use the existing flow
        const result = await bookSessionApi(
          coachId,
          availabilityId,
          paymentMethod
        );

        if (result.success) {
          // Refresh user bookings
          await refreshBookings();

          // Get coach info for notification
          const coachDoc = await getDoc(doc(db, "users", coachId));
          const coachName = coachDoc.exists()
            ? coachDoc.data().name ||
              coachDoc.data().displayName ||
              "your coach"
            : "your coach";

          // Send booking and payment confirmed notifications (for instant methods like coins)
          addNotification(
            createBookingNotification({
              id: result.bookingId,
              coachId,
              coachName,
              sessionDateTime: new Date(result.sessionDateTime),
            })
          );

          addNotification(
            createPaymentConfirmedNotification({
              id: result.bookingId,
              coachId,
              coachName,
            })
          );

          return {
            success: true,
            message: result.message,
            bookingId: result.bookingId,
            meetingLink: result.meetingLink,
          };
        } else {
          throw new Error(result.message || "Failed to book session");
        }
      }
    } catch (error) {
      console.error("Booking failed:", error);
      setError("Failed to book session. " + error.message);
      throw error;
    }
  };

  // Clear pending booking notification
  const clearPendingBooking = () => {
    setPendingBooking(null);
  };

  // Filter bookings by status
  const getFilteredBookings = (status) => {
    return userBookings.filter((booking) => booking.status === status);
  };

  // Cancel booking with notification
  const cancelBooking = async (bookingId) => {
    try {
      setError(null);

      // Get booking details before cancellation
      const bookingDoc = await getDoc(doc(db, "bookings", bookingId));
      const bookingData = bookingDoc.exists()
        ? { id: bookingId, ...bookingDoc.data() }
        : { id: bookingId };

      // Process cancellation
      const result = await cancelBookingApi(bookingId);

      // Send cancellation notification
      addNotification(createBookingCancelledNotification(bookingData, false));

      // Refresh bookings list
      await refreshBookings();
      return result;
    } catch (error) {
      console.error("Cancel booking failed:", error);
      setError("Failed to cancel booking. " + error.message);
      throw error;
    }
  };

  const completeSession = async (bookingId) => {
    try {
      setError(null);

      // Get booking details before marking complete
      const bookingDoc = await getDoc(doc(db, "bookings", bookingId));
      const bookingData = bookingDoc.exists()
        ? { id: bookingId, ...bookingDoc.data() }
        : { id: bookingId };

      // Mark as complete
      const result = await completeSessionApi(bookingId);

      // Send completion notification
      addNotification(createSessionCompletedNotification(bookingData));

      // Refresh bookings
      await refreshBookings();
      return result;
    } catch (error) {
      console.error("Complete session failed:", error);
      setError("Failed to complete session. " + error.message);
      throw error;
    }
  };

  const rateSession = async (bookingId, rating, feedback = "") => {
    try {
      setError(null);
      const result = await rateSessionApi(bookingId, rating, feedback);
      await refreshBookings();
      return result;
    } catch (error) {
      console.error("Rate session failed:", error);
      setError("Failed to rate session. " + error.message);
      throw error;
    }
  };

  const value = {
    userRole,
    isCoach,
    coaches,
    userBookings,
    upcomingBookings: getFilteredBookings("upcoming"),
    completedBookings: getFilteredBookings("completed"),
    cancelledBookings: getFilteredBookings("cancelled"),
    pendingPaymentBookings: getFilteredBookings("pending_payment"),
    pendingBooking,
    clearPendingBooking,
    isLoading,
    error,
    fetchCoachAvailability,
    bookSession,
    cancelBooking,
    completeSession,
    rateSession,
    refreshBookings,
  };

  return (
    <BookingsContext.Provider value={value}>
      {children}
    </BookingsContext.Provider>
  );
};

export default BookingProvider;
