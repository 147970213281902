// components/achievements/AchievementsList.jsx
import React, { useState, useEffect } from "react";
import AchievementItem from "./achievement-item";
import { motion, AnimatePresence } from "framer-motion";
import {
  filterAchievements,
  formatForAchievementItem,
  getUserAchievements,
} from "../../services/analytics/achievements-service";
import { useAnalytics } from "../../helpers/AnalyticsContextProvider";

// This component can be used in two ways:
// 1. Passing analyticsData directly: <AchievementsList analyticsData={analyticsData} />
// 2. Or, passing pre-processed achievements: <AchievementsList preloadedAchievements={achievements} />
const AchievementsList = ({ preloadedAchievements, analyticsData }) => {
  const [filter, setFilter] = useState("all"); // all, completed, inProgress
  const [achievements, setAchievements] = useState([]);
  const analyticsContext = useAnalytics();

  useEffect(() => {
    if (preloadedAchievements) {
      // Use preloaded achievements if provided
      setAchievements(preloadedAchievements);
    } else {
      // Otherwise, generate achievements from analytics data
      const data = analyticsData || analyticsContext?.userStats;
      if (data) {
        setAchievements(getUserAchievements(data));
      }
    }
  }, [preloadedAchievements, analyticsData, analyticsContext?.userStats]);

  if (!achievements || achievements.length === 0) {
    return (
      <div className="text-center p-4 bg-gray-50 rounded-lg">
        <p className="text-gray-500">No achievements available yet.</p>
        <p className="text-sm mt-2">
          Complete lessons and practice sessions to earn achievements!
        </p>
      </div>
    );
  }

  // Filter achievements based on selected filter
  const filteredAchievements = filterAchievements(achievements, filter);

  return (
    <div className="space-y-4">
      <div className="flex justify-center space-x-2 pb-2">
        <button
          onClick={() => setFilter("all")}
          className={`px-3 py-1 text-sm rounded-full ${
            filter === "all"
              ? "bg-green text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          All
        </button>
        <button
          onClick={() => setFilter("completed")}
          className={`px-3 py-1 text-sm rounded-full ${
            filter === "completed"
              ? "bg-green text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          Completed
        </button>
        <button
          onClick={() => setFilter("inProgress")}
          className={`px-3 py-1 text-sm rounded-full ${
            filter === "inProgress"
              ? "bg-green text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          In Progress
        </button>
      </div>

      <AnimatePresence>
        <motion.div layout className="space-y-2">
          {filteredAchievements.length > 0 ? (
            filteredAchievements.map((achievement, index) => (
              <motion.div
                key={achievement.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: index * 0.1 }}
              >
                <AchievementItem
                  achievement={formatForAchievementItem(achievement)}
                />
              </motion.div>
            ))
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center p-4 bg-gray-50 rounded-lg"
            >
              <p className="text-gray-500">
                No {filter === "completed" ? "completed" : "in-progress"}{" "}
                achievements found.
              </p>
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default AchievementsList;
