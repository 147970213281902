import React, { useState } from "react";
import { motion } from "framer-motion";

// This component should be placed in src/components/journey/FourMomentsTimeline.jsx

const momentsData = [
  {
    id: 1,
    phase: "Dawn",
    years: "2016-2025",
    title: "Language",
    color: "red",
    description:
      "The first light of day represents the beginning of our journey, where we established Fast Fluency Africa with a mission to make African language learning accessible, authentic, and enjoyable.",
    offerings: [
      {
        title: "The Language Vault",
        icon: "🔑",
        description:
          "Get fluent fast with our native speaking language coaches and access to our original course materials.",
      },
      {
        title: "Language Coaching",
        icon: "👨‍🏫",
        description:
          "Book up to 5 hours worth of in-person conversation sessions with our native speaking language coaches whenever you need practice.",
      },
      //   {
      //     title: "Zenzeleni DIY",
      //     icon: "📚",
      //     description:
      //       "Follow our monthly electronic structured DIY lesson plan that includes exercises, dialogue, vocabulary, and more.",
      //   },
    ],
  },
  {
    id: 2,
    phase: "Noon",
    years: "TBD",
    title: "Culture",
    color: "gold",
    description:
      "The zenith of the sun signifies the flourishing of life and represents our expansion into cultural education and experiences that deepen connections to African heritage.",
    offerings: [
      {
        title: "Off The Record Live Sessions",
        icon: "🎭",
        description:
          "Our weekly live cultural events focusing on art, food and traditions from various African cultures.",
      },
      {
        title: "Fast Fluency Fridays",
        description:
          "Quarterly cultural events where we celebrate our African heritage through language games, music, art and eye-opening conversations.",
        icon: "🥁",
      },
      {
        title: "Year End Function",
        description:
          "Our annual celebration where we invite everyone to reflect on the year in The African Way.",
        icon: "🎉",
      },
    ],
  },
  {
    id: 3,
    phase: "Dusk",
    years: "TBD",
    title: "Stories",
    color: "green",
    description:
      "As the sun begins to set, we delve into the rich storytelling traditions of Africa, preserving and sharing narratives that have shaped communities for generations.",
    offerings: [
      {
        title: "FFA+",
        description:
          "Exclusive and original African media content to provide real eduatinment value.",
        icon: "📖",
      },
      {
        title: "For The Culture",
        description:
          "Reviews of all things food, art, and lifestyle giving our insights on relevant topics that interest our readers.",
        icon: "📝",
      },
      {
        title: "The FFA Podcast",
        description:
          "Our weekly discussion forum delving into all things African and relevant for today's generation.",
        icon: "🎙️",
      },
    ],
  },
  {
    id: 4,
    phase: "Midnight",
    years: "TBD",
    title: "Heritage",
    color: "black",
    description:
      "As the day concludes, we focus on preserving and celebrating the full spectrum of African heritage, ensuring it continues to inspire future generations.",
    offerings: [
      {
        title: "AI Tools",
        description:
          "Advanced AI systems trained specifically on African languages to provide accurate linguistic and cultural support services.",
        icon: "🤖",
      },
      {
        title: "Virtual Cultural Experiences",
        description:
          "Immersive digital experiences that transport users to cultural events and historical sites across Africa.",
        icon: "🌍",
      },
      {
        title: "Digital Knowledge Repository",
        description:
          "A comprehensive AI-powered database of African languages, cultural practices, and stories accessible to future generations.",
        icon: "💾",
      },
    ],
  },
];

// Diamond marker component
const MomentDiamond = ({ moment, active, onClick }) => {
  return (
    <div className="relative flex flex-col items-center">
      <button
        onClick={() => onClick(moment.id)}
        className={`h-16 w-16 rotate-45 flex items-center justify-center border-2 border-${
          moment.color
        } ${
          active ? `bg-${moment.color}` : "bg-white"
        } transition-all duration-300 shadow-md hover:shadow-lg focus:outline-none`}
        aria-label={`Select ${moment.phase} phase`}
      >
        <span
          className={`text-xl font-bold -rotate-45 ${
            active ? "text-white" : `text-${moment.color}`
          }`}
        >
          {moment.id}
        </span>
      </button>
      <div className="absolute -bottom-8 text-center">
        <span className={`font-bold text-${active ? moment.color : "gray"}`}>
          {moment.phase}
        </span>
      </div>
    </div>
  );
};

// Offering Card component
const OfferingCard = ({ offering, color }) => {
  return (
    <div
      className={`bg-white rounded border-2 border-b-4 border-${color} p-6 shadow-md hover:shadow-lg transition-shadow`}
    >
      <div
        className={`h-14 w-14 bg-${color}/10 rounded-sm border-2 border-${color} rotate-45 flex items-center justify-center mb-4`}
      >
        <span className={`text-xl -rotate-45 text-${color}`}>
          {offering.icon}
        </span>
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-2">{offering.title}</h3>
      <p className="text-gray-600">{offering.description}</p>
    </div>
  );
};

const FourMomentsTimeline = () => {
  const [activeMoment, setActiveMoment] = useState(1);
  const currentMoment = momentsData.find(
    (moment) => moment.id === activeMoment
  );

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-12 bg-[#FCFCEE]">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-red mb-12 font-headers text-center">
        The Four Moments of Our Sun
      </h2>

      {/* Timeline with diamond markers */}
      <div className="relative mb-24">
        {/* Horizontal line */}
        <div className="absolute top-8 left-0 right-0 h-1 bg-darkgray"></div>

        {/* Phase diamonds with proper spacing */}
        <div className="flex justify-between relative">
          {momentsData.map((moment) => (
            <MomentDiamond
              key={moment.id}
              moment={moment}
              active={activeMoment === moment.id}
              onClick={setActiveMoment}
            />
          ))}
        </div>
      </div>

      {/* Active moment content with animation */}
      {currentMoment && (
        <motion.div
          key={currentMoment.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="pt-8"
        >
          <div className="flex flex-col md:flex-row gap-8">
            {/* Left side - Information */}
            <div className="w-full md:w-1/3">
              <div
                className={`bg-white rounded border-2 border-b-4 border-${currentMoment.color} p-6 shadow-md`}
              >
                <h3
                  className={`text-2xl font-bold mb-4 text-${currentMoment.color}`}
                >
                  {currentMoment.title}
                </h3>
                <div className="mb-4 inline-block px-3 py-1 bg-gray/10 rounded">
                  <span className="font-semibold">{currentMoment.years}</span>
                </div>
                <p className="text-gray-700">{currentMoment.description}</p>
              </div>
            </div>

            {/* Right side - Offerings */}
            <div className="w-full md:w-2/3">
              {currentMoment.offerings && currentMoment.offerings.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {currentMoment.offerings.map((offering, index) => (
                    <OfferingCard
                      key={index}
                      offering={offering}
                      color={currentMoment.color}
                    />
                  ))}
                </div>
              ) : (
                <div
                  className={`bg-white rounded border-2 border-b-4 border-${currentMoment.color} p-6 shadow-md text-center`}
                >
                  <p className="text-gray-700">
                    More details about this phase coming soon.
                  </p>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default FourMomentsTimeline;
