import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5";
import { db } from "../../../firebase/firebase.utils.js";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../../../helpers/UserAuthContextProvider.js";
import BeatLoader from "react-spinners/BeatLoader";

/**
 * Confirmation modal shown before starting practice mode
 * Checks if user has completed lessons and explains practice mode benefits
 */
const PracticeConfirmationModal = ({ onClose, onConfirm }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [canPractice, setCanPractice] = useState(false);
  const [completedLessonsCount, setCompletedLessonsCount] = useState(0);

  useEffect(() => {
    const checkEligibility = async () => {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Check if user has completed any lessons
          const completedLessons = userData.currentEnroll?.skills
            ? Object.values(userData.currentEnroll.skills).flatMap(
                (skill) => skill.completedLessons || []
              )
            : [];

          setCompletedLessonsCount(completedLessons.length);
          setCanPractice(completedLessons.length > 0);
        }
      } catch (error) {
        console.error("Error checking practice eligibility:", error);
      } finally {
        setLoading(false);
      }
    };

    checkEligibility();
  }, [user?.uid]);

  const handleConfirm = () => {
    if (canPractice) {
      onConfirm();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white w-4/6 border-b-4 p-6 max-w-md shadow-md mx-4 relative border-2 border-darkgray"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray hover:text-green"
        >
          <IoCloseSharp size={24} />
        </button>

        <h2 className="text-xl font-bold text-center mb-4 text-green">
          Practice Mode
        </h2>

        {loading ? (
          <div className="flex justify-center items-center h-32">
            <BeatLoader color="#848717" size={12} />
          </div>
        ) : !canPractice ? (
          <div className="text-center">
            <div className="mb-4 text-red p-4 border border-red rounded-lg">
              <p className="font-bold">
                You haven't completed any lessons yet!
              </p>
              <p className="mt-2">
                Complete at least one lesson to unlock Practice Mode.
              </p>
            </div>
            <button
              onClick={onClose}
              className="mt-4 bg-green text-white font-bold py-2 px-6 rounded hover:bg-opacity-90 transform active:scale-95"
            >
              Got it
            </button>
          </div>
        ) : (
          <>
            <div className="mb-6">
              <p className="mb-2 text-center">
                <span className="font-bold">Practice Mode</span> lets you review
                exercises from lessons you've already completed without
                affecting your progression.
              </p>

              <div className="bg-gold bg-opacity-10 p-4 rounded mt-4 border-b-4 border-2 border-gold">
                <h3 className="font-bold mb-2">Benefits of Practice Mode:</h3>
                <ul className="list-disc list-inside space-y-1">
                  <li>Earn XP to maintain your streak</li>
                  <li>
                    Regenerate attempts faster (6 completed sessions = 2 extra
                    lives)
                  </li>
                  <li>
                    Strengthen your skills without spending time on full lessons
                  </li>
                  <li>Focus on exercises you find challenging</li>
                </ul>
              </div>
            </div>

            <div className="text-center">
              <p className="mb-4 font-bold">
                You have completed {completedLessonsCount} lesson
                {completedLessonsCount !== 1 ? "s" : ""}.
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={onClose}
                  className="border-gray text-gray border-2 border-b-4 font-bold py-2 px-4 rounded hover:bg-gray hover:bg-opacity-10 transform active:scale-95"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirm}
                  className="bg-green text-black border-black border-2 border-b-4 font-bold py-2 px-6 rounded hover:bg-opacity-90 transform active:scale-95"
                >
                  Start Practice
                </button>
              </div>
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default PracticeConfirmationModal;
