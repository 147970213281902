// IncorrectFeedback.jsx with child-friendly encouraging animations
import React from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import BeatLoader from "react-spinners/BeatLoader";
import AnswerFeedbackModal from "../../modals/answer-feedback-modal.component";
import { motion } from "framer-motion";

const IncorrectFeedback = ({
  loading,
  hovered,
  onContinue,
  hidden,
  setHovered,
}) => {
  const handleClick = () => {
    if (!loading) {
      onContinue();
    }
  };

  // Animation for encouraging dots
  const EncouragingDots = () => (
    <div className="absolute right-2 sm:right-4 bottom-2 sm:bottom-4 flex space-x-1">
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={`dot-${i}`}
          className="w-1.5 sm:w-2 h-1.5 sm:h-2 bg-white rounded-full"
          initial={{ opacity: 0, scale: 0 }}
          animate={{
            opacity: 1,
            scale: [0, 1.2, 1],
            y: [0, -8, 0],
          }}
          transition={{
            duration: 0.8,
            delay: i * 0.2 + 0.5,
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 1,
          }}
        />
      ))}
    </div>
  );

  return (
    <motion.div
      className="relative flex justify-center items-center border-t-2 border-darkgray w-full bg-red py-3 sm:py-4 overflow-hidden"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
    >
      <EncouragingDots />

      <div
        className={
          hidden
            ? "hidden"
            : "flex flex-row justify-between w-full sm:w-4/5 mx-auto px-2 sm:px-0"
        }
      >
        <div className="flex flex-row justify-center items-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{
              scale: [0, 1.1, 1],
              rotate: [0, -5, 5, -3, 0], // Gentle shake for "no"
            }}
            transition={{
              scale: { times: [0, 0.6, 1], duration: 0.5 },
              rotate: {
                times: [0, 0.2, 0.4, 0.6, 1],
                duration: 0.6,
                delay: 0.3,
              },
            }}
          >
            <IoCloseCircleSharp className="my-2 sm:my-3 text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl text-white" />
          </motion.div>

          <div>
            <motion.h3
              className="font-headers font-bold text-white text-xs sm:text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
            >
              Incorrect solution
            </motion.h3>
            <motion.p
              className="font-body text-white text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
            >
              Keep practicing until you get it right
            </motion.p>

            {/* Wrapping the feedback modal to ensure it renders properly */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
              className="hidden sm:block" // Hide on mobile for space
            >
              <AnswerFeedbackModal />
            </motion.div>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center">
          <motion.button
            onClick={handleClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            disabled={loading}
            className="capitalize cursor-pointer bg-red text-xs sm:text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-1 pb-1 sm:pt-2 sm:pb-2 pl-3 pr-3 sm:pl-4 sm:pr-4 font-body text-center px-3 py-3 sm:px-4 sm:py-4 text-white font-bold border-2 hover:text-red border-white hover:border-red hover:bg-white rounded w-24 sm:w-32 h-10 sm:h-12 m-auto transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 0px 8px rgba(255,255,255,0.5)",
            }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5 },
            }}
          >
            {loading ? (
              <div className="px-4 sm:px-8">
                <BeatLoader
                  color={hovered ? "#991616" : "#FCFCEE"}
                  loading={loading}
                  size={5}
                />
              </div>
            ) : (
              "CONTINUE"
            )}
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default IncorrectFeedback;
