// src/hooks/useFetchAudioUrl.jsx
import { useEffect, useState } from "react";
import { extractAudioPath, getAudioDownloadUrl } from "../utils/audio-utils";

/**
 * Hook to fetch audio URLs for a collection of options
 * @param {Array} options - Collection of options with audio data
 * @returns {Object} Object containing audioUrls, loading state, and error
 */
const useFetchAudioUrls = (options) => {
  const [audioUrls, setAudioUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUrls = async () => {
      if (!options) {
        setLoading(false);
        return;
      }

      try {
        const urls = {};
        await Promise.all(
          options.map(async (option) => {
            if (!option) return;

            try {
              // Determine the key to use (usually the option text)
              const key =
                option.text || option.a || option.target || String(option);

              // Extract the audio data based on option structure
              const audioData = option.audio || option;

              // Get the download URL
              urls[key] = await getAudioDownloadUrl(audioData);
            } catch (optionError) {
              console.error(
                `Error fetching audio for option:`,
                option,
                optionError
              );
            }
          })
        );

        setAudioUrls(urls);
      } catch (err) {
        console.error("Error fetching audio URLs:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchUrls();
  }, [options]);

  return { audioUrls, loading, error };
};

export default useFetchAudioUrls;
