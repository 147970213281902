// pages/support/search.jsx
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import SupportHeader from "../../components/support/support-header";
import Breadcrumbs from "../../components/support/breadcrumbs";
import ArticleList from "../../components/support/article-list";
import Footer from "../../components/footer/footer.component";
import { searchArticles } from "../../zombiedata/support-articles";
import FeedbackMenu from "../../components/support/feedback-menu";

const SearchPage = () => {
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("q") || "";
    setQuery(searchQuery);

    if (searchQuery) {
      const foundResults = searchArticles(searchQuery);
      setResults(foundResults);
      document.title = `Search: ${searchQuery} | Support Center | Fast Fluency Africa`;
    } else {
      document.title = "Search | Support Center | Fast Fluency Africa";
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.search]);

  const breadcrumbItems = [
    { label: "Support Center", href: "/support" },
    { label: "Search Results" },
  ];

  return (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <SupportHeader />
      <FeedbackMenu />
      <main className="flex-grow container mx-auto px-4 py-8">
        <Breadcrumbs items={breadcrumbItems} />

        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-2">
            {query
              ? `Search Results for "${query}"`
              : "Search the Support Center"}
          </h1>
          <p className="text-gray-600">
            {results.length} {results.length === 1 ? "result" : "results"} found
          </p>
        </div>

        {query ? (
          <ArticleList
            articles={results}
            emptyMessage={`No results found for "${query}". Please try another search term.`}
          />
        ) : (
          <div className="text-center py-10">
            <p className="text-gray-500">
              Enter a search term to find help articles.
            </p>
          </div>
        )}
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default SearchPage;
