import React, { useState } from "react";
import { motion } from "framer-motion";
import { Articles } from "../../zombiedata/articles-hero";
import { Link } from "react-router-dom";

function ArticleHero() {
  const [hoveredCard, setHoveredCard] = useState(null);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100 },
    },
    hover: {
      y: -10,
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: { type: "spring", stiffness: 400, damping: 10 },
    },
  };

  const buttonVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 10 },
    },
    hover: {
      scale: 1.05,
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.2 },
    },
    tap: {
      scale: 0.95,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.1 },
    },
  };

  const imageVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  return (
    <motion.div
      className="max-w-7xl mx-auto px-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
        {Articles.map((item, index) => (
          <motion.div
            key={index}
            className="h-full relative rounded-lg overflow-hidden border-darkgray border-2 border-b-4 text-black shadow-lg bg-white"
            variants={cardVariants}
            whileHover="hover"
            onHoverStart={() => setHoveredCard(index)}
            onHoverEnd={() => setHoveredCard(null)}
          >
            <div className="relative overflow-hidden h-40">
              <motion.img
                src={item.image}
                alt={item.heading}
                className="h-full w-full object-cover"
                variants={imageVariants}
                animate={hoveredCard === index ? "hover" : "visible"}
              />
              <motion.div
                className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"
                initial={{ opacity: 0 }}
                animate={{ opacity: hoveredCard === index ? 1 : 0 }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div className="p-5">
              <motion.h2
                className="text-center mb-3 text-lg sm:text-lg md:text-xl font-headers font-bold"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.1 + 0.3 }}
              >
                {item.heading}
              </motion.h2>

              <motion.div
                className="h-px w-16 bg-red mx-auto mb-4"
                initial={{ width: 0 }}
                animate={{ width: "4rem" }}
                transition={{ delay: index * 0.1 + 0.4, duration: 0.5 }}
              />

              <motion.p
                className="text-center mb-5 font-body text-gray-700"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.1 + 0.5 }}
              >
                {item.description}
              </motion.p>

              <motion.div
                className="flex justify-center mt-auto"
                variants={buttonVariants}
              >
                {item.calltoaction === "DOWNLOAD" ? (
                  <motion.a
                    href={item.path}
                    target={item.target}
                    className={
                      item.path === "#"
                        ? "cursor-not-allowed text-sm font-medium px-5 py-2 border-2 border-red text-black rounded hover:bg-red hover:text-white hover:border-black transition-colors duration-300 border-b-4 uppercase"
                        : "cursor-pointer text-sm font-medium px-5 py-2 border-2 border-green text-black rounded hover:bg-green hover:text-white hover:border-black transition-colors duration-300 shadow-md hover:shadow-lg border-b-4 uppercase"
                    }
                    whileHover="hover"
                    whileTap="tap"
                  >
                    {item.calltoaction}
                  </motion.a>
                ) : (
                  <motion.div whileHover="hover" whileTap="tap">
                    <Link
                      to={item.path}
                      target={item.target}
                      className={
                        item.path === "#"
                          ? "cursor-not-allowed text-sm font-medium px-5 py-2 border-2 border-red text-black rounded hover:bg-red hover:text-white hover:border-black transition-colors duration-300 border-b-4 uppercase inline-block"
                          : "cursor-pointer text-sm font-medium px-5 py-2 border-2 border-green text-black rounded hover:bg-green hover:text-white hover:border-black transition-colors duration-300 shadow-md hover:shadow-lg border-b-4 uppercase inline-block"
                      }
                    >
                      {item.calltoaction}
                    </Link>
                  </motion.div>
                )}
              </motion.div>
            </div>

            {/* Decorative corner accent */}
            <motion.div
              className="absolute top-0 right-0 w-12 h-12 bg-red/10"
              initial={{ scale: 0, rotate: 0 }}
              animate={{
                scale: hoveredCard === index ? 1 : 0,
                rotate: hoveredCard === index ? 45 : 0,
              }}
              style={{ transformOrigin: "top right" }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}

export default ArticleHero;
