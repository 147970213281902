import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

import { motion } from "framer-motion";

function Terms() {
  useEffect(() => {
    document.title = "Terms | Fast Fluency Africa";
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return loading ? (
    <div
      className="flex flex-row justify-center items-center h-screen"
      data-testid="puff-loader"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="bg-transparent mx-auto w-4/6 text-justify"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            align="center"
            style={{ textAlign: "center", lineHeight: "150%" }}
          >
            <button name="_2cipo4yr3w5d" href="#"></button>
            <div align="center" style={{ lineHeight: "22.5px" }}>
              <div align="center" style={{ lineHeight: "150%" }}>
                <button name="_gm5sejt4p02f" href="#"></button>
                <div
                  align="center"
                  className="font-headers text-2xl text-black"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span style={{ lineHeight: "22.5px", fontSize: 26 }}>
                      <span className="block-component" />
                      <span className="question">TERMS OF SERVICE</span>
                      <span className="statement-end-if-in-editor" />
                    </span>
                  </strong>
                </div>
                <div
                  align="center"
                  style={{ lineHeight: "22.5px", textAlign: "left" }}
                >
                  <button name="_7m5b3xg56u7y" href="#"></button>
                </div>
                <div
                  align="center"
                  className="font-sans text-base text-gray-600"
                  style={{ textAlign: "left", lineHeight: "22.5px" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  className="font-sans text-base text-gray-600"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14.6667px",
                      textAlign: "justify",
                    }}
                  >
                    <strong>
                      Last updated{" "}
                      <span
                        className="block-container question question-in-editor"
                        data-id="e2088df5-25ea-aec9-83d4-6284f5a7e043"
                        data-type="question"
                      >
                        August 31, 2023
                      </span>
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div
              align="center"
              style={{ lineHeight: "17.25px", textAlign: "left" }}
            >
              <br />
            </div>
            <div
              align="center"
              style={{ lineHeight: "17.25px", textAlign: "left" }}
            >
              <br />
            </div>
            <div
              align="center"
              style={{ lineHeight: "17.25px", textAlign: "left" }}
            >
              <br />
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span className="font-sans text-xl text-black">
                        TABLE OF CONTENTS
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#agreement"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    1. AGREEMENT TO TERMS
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#ip"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    2. INTELLECTUAL PROPERTY RIGHTS
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#userreps"
                  >
                    3. USER REPRESENTATIONS
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#userreg"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-type="conditional-block">
                      <span
                        className="block-component"
                        data-record-question-key="user_account_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </span>
                    </span>
                    4. USER REGISTRATION
                  </span>
                </a>
              </span>
              <span className="statement-end-if-in-editor" data-type="close">
                <span style={{ fontSize: 15 }} />
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#prohibited"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    5. PROHIBITED ACTIVITIES
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#ugc"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. USER GENERATED CONTRIBUTIONS
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#license"
                  >
                    7. CONTRIBUTION LICENSE
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#reviews"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span
                      className="block-container if"
                      data-type="if"
                      id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                    >
                      <span data-type="conditional-block">
                        <span
                          className="block-component"
                          data-record-question-key="review_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: 15 }} />
                        </span>
                      </span>
                    </span>
                    8. GUIDELINES FOR REVIEWS
                    <span
                      className="statement-end-if-in-editor"
                      data-type="close"
                    >
                      <span style={{ fontSize: 15 }} />
                    </span>
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#mobile"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span
                      className="block-container if"
                      data-type="if"
                      id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                    >
                      <span data-type="conditional-block">
                        <span
                          className="block-component"
                          data-record-question-key="mobile_app_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: 15 }} />
                        </span>
                      </span>
                    </span>
                    9. MOBILE APPLICATION LICENSE
                  </span>{" "}
                </a>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#agreement"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span
                      className="statement-end-if-in-editor"
                      data-type="close"
                    >
                      <span style={{ fontSize: 15 }} />
                    </span>
                  </span>
                </a>
              </span>
            </div>
            <div align="center" style={{ textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span className="block-component" />
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#socialmedia"
                  >
                    10. SOCIAL MEDIA
                  </a>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#agreement"
                  >
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span
                        className="statement-end-if-in-editor"
                        data-type="close"
                      >
                        <span style={{ fontSize: 15 }} />
                      </span>
                    </span>
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#submissions"
                  >
                    11. SUBMISSIONS
                  </a>
                </span>
              </span>
            </div>
            <div align="center" style={{ textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span className="block-component" />
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#thirdparty"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    12. THIRD-PARTY WEBSITE AND CONTENT
                  </span>
                </a>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#agreement"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span
                      className="statement-end-if-in-editor"
                      data-type="close"
                    >
                      <span style={{ fontSize: 15 }} />
                    </span>
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#advertisers"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span
                      className="block-container if"
                      data-type="if"
                      id="14038561-dad7-be9d-370f-f8aa487b2570"
                    >
                      <span data-type="conditional-block">
                        <span
                          className="block-component"
                          data-record-question-key="advertiser_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: 15 }} />
                        </span>
                      </span>
                    </span>
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#sitemanage"
                  >
                    13. SITE MANAGEMENT
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#privacypolicy1"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span
                      className="block-container if"
                      data-type="if"
                      id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                    >
                      <span data-type="conditional-block">
                        <span
                          className="block-component"
                          data-record-question-key="privacy_policy_option"
                          data-type="statement"
                        >
                          <span style={{ fontSize: 15 }} />
                        </span>
                      </span>
                    </span>
                    14. PRIVACY POLICY
                  </span>
                </a>
                <button
                  className="font-sans text-base text-blue-600 break-words"
                  href="#advertisers"
                />
                <button
                  className="font-sans text-base text-blue-600 break-words"
                  href="#agreement"
                />
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span
                    className="statement-end-if-in-editor"
                    data-type="close"
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        className="block-container if"
                        data-type="if"
                        id="87a7471d-cf82-1032-fdf8-601d37d7b017"
                      >
                        <span data-type="conditional-block">
                          <span
                            className="block-component"
                            data-record-question-key="privacy_policy_followup"
                            data-type="statement"
                            style={{ fontSize: "14.6667px" }}
                          >
                            <span style={{ fontSize: 15 }} />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <button
                  className="font-sans text-base text-blue-600 break-words"
                  href="#privacypolicy"
                />
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#dmca"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="block-component">
                      <span className="block-component" />
                    </span>
                  </span>
                </a>
              </span>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#dmca"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="block-component">
                      <span className="block-container if" data-type="if">
                        <span
                          className="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span style={{ fontSize: 15 }} />
                        </span>
                      </span>
                    </span>
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#copyright1"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="block-component">
                      <span className="block-component" />
                    </span>
                  </span>
                </a>
              </span>
              <span className="block-container if" data-type="if">
                <span className="statement-end-if-in-editor" data-type="close">
                  <span style={{ fontSize: 15 }}>
                    <span className="block-component" />
                  </span>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#copyright2"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    15. COPYRIGHT INFRINGEMENTS
                  </span>
                </a>
              </span>
              <span className="block-container if" data-type="if">
                <span className="statement-end-if-in-editor" data-type="close">
                  <span style={{ fontSize: 15 }} />
                </span>
              </span>
              <span className="block-component" />
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <button
                  className="font-sans text-base text-blue-600 break-words"
                  href="#terms"
                ></button>
                <button
                  className="font-sans text-base text-blue-600 break-words"
                  href="#terms"
                ></button>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  16. TERM AND TERMINATION
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#modifications"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    17. MODIFICATIONS AND INTERRUPTIONS
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#law"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    18. GOVERNING LAW
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#disputes"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    19. DISPUTE RESOLUTION
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#corrections"
                  >
                    20. CORRECTIONS
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#disclaimer"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    21. DISCLAIMER
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#liability"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    22. LIMITATIONS OF LIABILITY
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#indemnification"
                  >
                    23. INDEMNIFICATION
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#userdata"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    24. USER DATA
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#electronic"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span className="block-component" />
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#california"
                  >
                    26. CALIFORNIA USERS AND RESIDENTS
                  </a>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#agreement"
                  >
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span
                        className="statement-end-if-in-editor"
                        data-type="close"
                      >
                        <span style={{ fontSize: 15 }} />
                      </span>
                    </span>
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <a
                  className="font-sans text-base text-blue-600 break-words"
                  href="#misc"
                >
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    27. MISCELLANEOUS
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a
                    className="font-sans text-base text-blue-600 break-words"
                    href="#contact"
                  >
                    28. CONTACT US
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5", textAlign: "left" }}>
              <br />
            </div>
          </div>
          <div
            className="font-sans text-xl text-black"
            id="agreement"
            style={{ lineHeight: "115%" }}
          >
            <button name="_a7mwfgcrtsqn" href="#" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                1. AGREEMENT TO TERMS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you”)
              and{" "}
              <span
                className="block-container question question-in-editor"
                data-id="4ab94aa9-19d1-61e0-711e-42c7d186232b"
                data-type="question"
              >
                Fast Fluency Africa
              </span>
              <span className="block-component" />, doing business as{" "}
              <span className="question">Fast Fluency</span>
              <span className="statement-end-if-in-editor" /> ("
              <span className="block-component" />
              <span className="question">
                <strong>Fast Fluency</strong>
              </span>
              <span className="else-block" />
              ," “<strong>we</strong>," “<strong>us</strong>," or “
              <strong>our</strong>”), concerning your access to and use of the{" "}
              <span
                className="block-container question question-in-editor"
                data-id="92c3b320-1d8b-c74c-db68-d12810736807"
                data-type="question"
              >
                <a
                  href="https://fastfluencyafrica.com"
                  target="_blank"
                  rel="noreferrer"
                  className="font-sans text-base text-blue-600 break-words"
                >
                  https://fastfluencyafrica.com
                </a>
              </span>{" "}
              website as well as any other media form, media channel, mobile
              website or mobile application related, linked, or otherwise
              connected thereto (collectively, the “Site”).
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <span className="question">
                    <span className="block-component" />
                  </span>
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <span className="block-component" />
                  </span>{" "}
                  We are registered in
                  <span className="block-component" />
                  <span className="block-component" />{" "}
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        msoFareastFontFamily: "Calibri",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <span className="question">South Africa</span>
                      <span className="statement-end-if-in-editor">
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              msoFareastFontFamily: "Calibri",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>{" "}
                  and have our registered office at{" "}
                  <span className="question">
                    STAND 11 FORTUNE STREET UNIT D
                  </span>
                  <span className="block-component" />,{" "}
                  <span className="question">info@fastfluencyafrica.com</span>
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        msoFareastFontFamily: "Calibri",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <span className="statement-end-if-in-editor" />
                    </span>
                  </span>
                  , <span className="question">PRETORIA</span>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <span className="font-body text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-body text-base text-gray-600">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="block-component" />,{" "}
                        <span className="question">GAUTENG</span>
                        <span className="statement-end-if-in-editor" />
                        <span className="block-component" />
                        <span className="question">0040</span>
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <span className="statement-end-if-in-editor">.</span>
                  <span className="else-block" />
                </span>
              </span>
              <span className="statement-end-if-in-editor" />
            </span>
            <span className="block-component" /> Our VAT number is{" "}
            <span className="question">2018/554126/07.</span>
            <span className="statement-end-if-in-editor" /> You agree that by
            accessing the Site, you have read, understood, and agreed to be
            bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
            THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
            SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use{" "}
              <span className="block-component" />
              from time to time
              <span className="else-block" />. We will alert you about any
              changes by updating the “Last updated” date of these Terms of Use,
              and you waive any right to receive specific notice of each such
              change. Please ensure that you check the applicable Terms every
              time you use our Site so that you understand which Terms apply.
              You will be subject to, and will be deemed to have been made aware
              of and to have accepted, the changes in any revised Terms of Use
              by your continued use of the Site after the date such revised
              Terms of Use are posted.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span className="block-component" />
                <span className="block-component" />
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              The Site is not tailored to comply with industry-specific
              regulations (Health Insurance Portability and Accountability Act
              (HIPAA), Federal Information Security Management Act (FISMA),
              etc.), so if your interactions would be subjected to such laws,
              you may not use this Site. You may not use the Site in a way that
              would violate the Gramm-Leach-Bliley Act (GLBA).
            </span>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span className="block-component">
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <span className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span data-type="conditional-block">
              <span
                className="block-component"
                data-record-question-key="user_o18_option"
                data-type="statement"
              >
                <span style={{ fontSize: 15 }} />
              </span>
              <span
                className="block-container if"
                data-type="if"
                id="a2595956-7028-dbe5-123e-d3d3a93ed076"
              >
                <span data-type="conditional-block">
                  <span data-type="body">
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                      }}
                    >
                      The Site is intended for users who are at least 13 years
                      of age. All users who are minors in the jurisdiction in
                      which they reside (generally under the age of 18) must
                      have the permission of, and be directly supervised by,
                      their parent or guardian to use the Site. If you are a
                      minor, you must have your parent or guardian read and
                      agree to these Terms of Use prior to you using the Site.
                    </span>
                  </span>
                </span>
              </span>
              <span data-type="body">
                <span
                  style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                >
                  <span className="block-component"></span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="ip"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_4rd71iod99ud" href="#"></button>
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    2.{" "}
                  </span>
                </strong>
                INTELLECTUAL PROPERTY RIGHTS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Unless otherwise indicated, the Site is our proprietary property
              and all source code, databases, functionality, software, website
              designs, audio, video, text, photographs, and graphics on the Site
              (collectively, the “Content”) and the trademarks, service marks,
              and logos contained therein (the “Marks”) are owned or controlled
              by us or licensed to us, and are protected by copyright and
              trademark laws and various other intellectual property rights and
              unfair competition laws of the United States, international
              copyright laws, and international conventions. The Content and the
              Marks are provided on the Site “AS IS” for your information and
              personal use only. Except as expressly provided in these Terms of
              Use, no part of the Site and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="userreps"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_vhkegautf00d" href="#"></button>
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        3.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                USER REPRESENTATIONS
              </span>
            </strong>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              lineHeight: "115%",
            }}
          >
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                By using the Site, you represent and warrant that:{" "}
              </span>
              <span
                className="block-container if"
                data-type="if"
                id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
              >
                <span data-type="conditional-block">
                  <span
                    className="block-component"
                    data-record-question-key="user_account_option"
                    data-type="statement"
                  />
                  <span data-type="body">
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                    >
                      (
                    </span>
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                      }}
                    >
                      1
                    </span>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                    >
                      ) all registration information you submit will be true,
                      accurate, current, and complete; (
                    </span>
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                      }}
                    >
                      2
                    </span>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                    >
                      ) you will maintain the accuracy of such information and
                      promptly update such registration information as necessary
                      <span
                        className="block-container if"
                        data-type="if"
                        id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
                      >
                        <span data-type="conditional-block">
                          <span data-type="body">
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              ;
                            </span>
                          </span>
                        </span>
                        <span
                          className="statement-end-if-in-editor"
                          data-type="close"
                        />
                      </span>
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                      >
                        (
                      </span>
                      <span
                        style={{
                          color: "rgb(89, 89, 89)",
                          fontSize: "14.6667px",
                        }}
                      >
                        3
                      </span>
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                      >
                        ) you have the legal capacity and you agree to comply
                        with these Terms of Use;
                      </span>
                      <span
                        className="block-container if"
                        data-type="if"
                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                      >
                        <span data-type="conditional-block">
                          <span
                            className="block-component"
                            data-record-question-key="user_u13_option"
                            data-type="statement"
                          />
                          <span data-type="body">
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              (
                            </span>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "14.6667px",
                              }}
                            >
                              4
                            </span>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "11pt",
                              }}
                            >
                              ) you are not under the age of 13;
                            </span>
                          </span>
                        </span>
                        <span
                          className="statement-end-if-in-editor"
                          data-type="close"
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                {" "}
                (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                5
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you are not a minor in the jurisdiction in which you reside
                <span
                  className="block-container if"
                  data-type="if"
                  id="76948fab-ec9e-266a-bb91-948929c050c9"
                >
                  <span data-type="conditional-block">
                    <span
                      className="block-component"
                      data-record-question-key="user_o18_option"
                      data-type="statement"
                    />
                    <span data-type="body">
                      , or if a minor, you have received parental permission to
                      use the Site
                    </span>
                  </span>
                  <span
                    className="statement-end-if-in-editor"
                    data-type="close"
                  />
                </span>
                ; (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                6
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you will not access the Site through automated or non-human
                means, whether through a bot, script, or otherwise; (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                7
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you will not use the Site for any illegal or unauthorized
                purpose; and (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                8
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) your use of the Site will not violate any applicable law or
                regulation.
              </span>
              <span
                style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
              />
            </div>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <br />
          </div>
          <div
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              lineHeight: "115%",
            }}
          >
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Site (or any portion thereof).
              </span>
            </div>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: 1 }}>
            <button name="_esuoutkhaf53" href="#"></button>
            <span data-type="conditional-block">
              <span
                className="block-component"
                data-record-question-key="user_account_option"
                data-type="statement"
              >
                <span style={{ fontSize: 15 }} />
              </span>
              <span data-type="body">
                <div
                  className="font-sans text-xl text-black"
                  id="userreg"
                  style={{ lineHeight: "17.25px" }}
                >
                  <strong>
                    <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19,
                              }}
                            >
                              4.
                            </span>
                          </strong>
                        </span>{" "}
                      </strong>
                      USER REGISTRATION
                    </span>
                  </strong>
                </div>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1 }}>
            <span data-type="conditional-block">
              <span data-type="body">
                <div
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    You may be required to register with the Site. You agree to
                    keep your password confidential and will be responsible for
                    all use of your account and password. We reserve the right
                    to remove, reclaim, or change a username you select if we
                    determine, in our sole discretion, that such username is
                    inappropriate, obscene, or otherwise objectionable.
                  </span>
                </div>
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "150%" }}>
          <div style={{ lineHeight: "1.5" }}>
            <span className="statement-end-if-in-editor" data-type="close">
              <span style={{ fontSize: 15 }} />
            </span>
          </div>
          <div style={{ lineHeight: "115%" }}>
            <button name="_1voziltdxegg" href="#"></button>
            <div
              className="font-sans text-xl text-black"
              id="prohibited"
              style={{ lineHeight: "17.25px" }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          5.
                        </span>
                      </strong>
                    </span>{" "}
                  </strong>
                  PROHIBITED ACTIVITIES
                </span>
              </strong>
            </div>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ lineHeight: "115%" }}>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                You may not access or use the Site for any purpose other than
                that for which we make the Site available. The Site may not be
                used in connection with any commercial endeavors except those
                that are specifically endorsed or approved by us.
              </span>
            </div>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ lineHeight: "115%" }}>
            <div style={{ textAlign: "justify", lineHeight: "17.25px" }}>
              <div style={{ lineHeight: "17.25px" }}>
                <div
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    As a user of the Site, you agree not to:
                  </span>
                </div>
              </div>
              <ul>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Nunito",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      Systematically retrieve data or other content from the
                      Site to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Trick, defraud, or mislead us and other users,
                            especially in any attempt to learn sensitive account
                            information such as user passwords.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Circumvent, disable, or otherwise interfere with
                            security-related features of the Site, including
                            features that prevent or restrict the use or copying
                            of any Content or enforce limitations on the use of
                            the Site and/or the Content contained therein.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Disparage, tarnish, or otherwise harm, in our
                            opinion, us and/or the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Use any information obtained from the Site in order
                            to harass, abuse, or harm another person.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Make improper use of our support services or submit
                            false reports of abuse or misconduct.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Use the Site in a manner inconsistent with any
                            applicable laws or regulations.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Engage in unauthorized framing of or linking to the
                            Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Upload or transmit (or attempt to upload or to
                            transmit) viruses, Trojan horses, or other material,
                            including excessive use of capital letters and
                            spamming (continuous posting of repetitive text),
                            that interferes with any party’s uninterrupted use
                            and enjoyment of the Site or modifies, impairs,
                            disrupts, alters, or interferes with the use,
                            features, functions, operation, or maintenance of
                            the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Engage in any automated use of the system, such as
                            using scripts to send comments or messages, or using
                            any data mining, robots, or similar data gathering
                            and extraction tools.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Delete the copyright or other proprietary rights
                            notice from any Content.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Attempt to impersonate another user or person or use
                            the username of another user.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Upload or transmit (or attempt to upload or to
                            transmit) any material that acts as a passive or
                            active information collection or transmission
                            mechanism, including without limitation, clear
                            graphics interchange formats (“gifs”), 1×1 pixels,
                            web bugs, cookies, or other similar devices
                            (sometimes referred to as “spyware” or “passive
                            collection mechanisms” or “pcms”).
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Interfere with, disrupt, or create an undue burden
                            on the Site or the networks or services connected to
                            the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Harass, annoy, intimidate, or threaten any of our
                            employees or agents engaged in providing any portion
                            of the Site to you.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Attempt to bypass any measures of the Site designed
                            to prevent or restrict access to the Site, or any
                            portion of the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Copy or adapt the Site’s software, including but not
                            limited to Flash, PHP, HTML, JavaScript, or other
                            code.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Except as permitted by applicable law, decipher,
                            decompile, disassemble, or reverse engineer any of
                            the software comprising or in any way making up a
                            part of the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Except as may be the result of standard search
                            engine or Internet browser usage, use, launch,
                            develop, or distribute any automated system,
                            including without limitation, any spider, robot,
                            cheat utility, scraper, or offline reader that
                            accesses the Site, or using or launching any
                            unauthorized script or other software.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Use a buying agent or purchasing agent to make
                            purchases on the Site.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Make any unauthorized use of the Site, including
                            collecting usernames and/or email addresses of users
                            by electronic or other means for the purpose of
                            sending unsolicited email, or creating user accounts
                            by automated means or under false pretenses.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Use the Site as part of any effort to compete with
                            us or otherwise use the Site and/or the Content for
                            any revenue-generating endeavor or commercial
                            enterprise.
                          </span>
                        </span>
                      </span>
                    </span>
                    <span className="block-component" />
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Use the Site to advertise or offer to sell goods and
                            services.
                          </span>
                        </span>
                      </span>
                    </span>
                    <span className="statement-end-if-in-editor" />
                    <span className="block-component" />
                  </span>
                </li>
                <li
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <span
                      style={{
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Nunito",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Nunito",
                              fontStyle: "normal",
                              fontVariantLigatures: "normal",
                              fontVariantCaps: "normal",
                              fontWeight: 400,
                              letterSpacing: "normal",
                              orphans: 2,
                              textAlign: "justify",
                              textIndent: "-29.4px",
                              textTransform: "none",
                              whiteSpace: "normal",
                              widows: 2,
                              wordSpacing: 0,
                              WebkitTextStrokeWidth: 0,
                              textDecorationStyle: "initial",
                              textDecorationColor: "initial",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            Sell or otherwise transfer your profile.
                          </span>
                        </span>
                      </span>
                    </span>
                    <span className="statement-end-if-in-editor" />
                    <span className="forloop-component" />
                  </span>
                </li>
              </ul>
              <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                <button href="#" name="_zbbv9tgty199" />
              </div>
            </div>
            <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-sans text-xl text-black"
                    id="ugc"
                    style={{ lineHeight: "17.25px", textAlign: "left" }}
                  >
                    <strong>
                      <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: 19,
                                }}
                              >
                                6.
                              </span>
                            </strong>
                          </span>{" "}
                        </strong>
                        USER GENERATED CONTRIBUTIONS
                      </span>
                    </strong>
                  </div>
                </span>
              </span>
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <br />
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span className="block-component" />
                      The Site may invite you to chat, contribute to, or
                      participate in blogs, message boards, online forums, and
                      other functionality, and may provide you with the
                      opportunity to create, submit, post, display, transmit,
                      perform, publish, distribute, or broadcast content and
                      materials to us or on the Site, including but not limited
                      to text, writings, video, audio, photographs, graphics,
                      comments, suggestions, or personal information or other
                      material (collectively, "Contributions"). Contributions
                      may be viewable by other users of the Site and through
                      third-party websites. As such, any Contributions you
                      transmit may be treated as non-confidential and
                      non-proprietary. When you create or make available any
                      Contributions, you thereby represent and warrant that:
                      <span className="else-block" />
                    </span>
                  </div>
                </span>
              </span>
              <span data-type="conditional-block">
                <span data-type="body">
                  <ul>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        The creation,{" "}
                        <span
                          style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}
                        >
                          distribution, transmission, public display, or
                          performance, and the accessing, downloading, or
                          copying of your Contributions do not and will not
                          infringe the proprietary rights, including but not
                          limited to the copyright, patent, trademark, trade
                          secret, or moral rights of any third party.
                        </span>
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}
                        >
                          You are the creator and owner of or have the necessary
                          licenses, rights, consents, releases, and permissions
                          to use and to authorize us, the Site, and other users
                          of the Site to use your Contributions in any manner
                          contemplated by the Site and these Terms of Use.
                        </span>
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        You have the written consent, release, and/or permission
                        of each and every identifiable individual person in your
                        Contributions to use the name or likeness of each and
                        every such identifiable individual person to enable
                        inclusion and use of your Contributions in any manner
                        contemplated by the Site and these Terms of Use.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions are not false, inaccurate, or
                        misleading.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions are not unsolicited or unauthorized
                        advertising, promotional materials, pyramid schemes,
                        chain letters, spam, mass mailings, or other forms of
                        solicitation.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions are not obscene, lewd, lascivious,
                        filthy, violent, harassing, libelous, slanderous, or
                        otherwise objectionable (as determined by us).
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions do not ridicule, mock, disparage,
                        intimidate, or abuse anyone.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions are not used to harass or threaten
                        (in the legal sense of those terms) any other person and
                        to promote violence against a specific person or class
                        of people.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions do not violate any applicable law,
                        regulation, or rule.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions do not violate the privacy or
                        publicity rights of any third party.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions do not violate any applicable law
                        concerning child pornography, or otherwise intended to
                        protect the health or well-being of minors.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions do not include any offensive comments
                        that are connected to race, national origin, gender,
                        sexual preference, or physical handicap.
                      </span>
                    </li>
                    <li
                      className="font-body text-base text-gray-600"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        Your Contributions do not otherwise violate, or link to
                        material that violates, any provision of these Terms of
                        Use, or any applicable law or regulation.
                      </span>
                    </li>
                  </ul>
                </span>
              </span>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      Any use of the Site in violation of the foregoing violates
                      these Terms of Use and may result in, among other things,
                      termination or suspension of your rights to use the Site.
                    </span>
                  </div>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "115%" }}>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-sans text-xl text-black"
                    id="license"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <strong>
                      <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: 19,
                                }}
                              >
                                7.
                              </span>
                            </strong>
                          </span>{" "}
                        </strong>
                        CONTRIBUTION LICENSE
                      </span>
                    </strong>
                  </div>
                </span>
              </span>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div style={{ lineHeight: "17.25px", textAlign: "left" }}>
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span className="block-component" />
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <br />
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      By posting your Contributions to any part of the Site
                      <span
                        className="block-container if"
                        data-type="if"
                        id="19652acc-9a2a-5ffe-6189-9474402fa6cc"
                      >
                        <span data-type="conditional-block">
                          <span
                            className="block-component"
                            data-record-question-key="socialnetwork_link_option"
                            data-type="statement"
                          />
                          <span data-type="body">
                            {" "}
                            or making Contributions accessible to the Site by
                            linking your account from the Site to any of your
                            social networking accounts
                          </span>
                        </span>
                        <span
                          className="statement-end-if-in-editor"
                          data-type="close"
                        />
                      </span>
                      , you automatically grant, and you represent and warrant
                      that you have the right to grant, to us an unrestricted,
                      unlimited, irrevocable, perpetual, non-exclusive,
                      transferable, royalty-free, fully-paid, worldwide right,
                      and license to host, use, copy, reproduce, disclose, sell,
                      resell, publish, broadcast, retitle, archive, store,
                      cache, publicly perform, publicly display, reformat,
                      translate, transmit, excerpt (in whole or in part), and
                      distribute such Contributions (including, without
                      limitation, your image and voice) for any purpose,
                      commercial, advertising, or otherwise, and to prepare
                      derivative works of, or incorporate into other works, such
                      Contributions, and grant and authorize sublicenses of the
                      foregoing. The use and distribution may occur in any media
                      formats and through any media channels.
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <br />
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      This license will apply to any form, media, or technology
                      now known or hereafter developed, and includes our use of
                      your name, company name, and franchise name, as
                      applicable, and any of the trademarks, service marks,
                      trade names, logos, and personal and commercial images you
                      provide. You waive all moral rights in your Contributions,
                      and you warrant that moral rights have not otherwise been
                      asserted in your Contributions.
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <br />
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      We do not assert any ownership over your Contributions.
                      You retain full ownership of all of your Contributions and
                      any intellectual property rights or other proprietary
                      rights associated with your Contributions. We are not
                      liable for any statements or representations in your
                      Contributions provided by you in any area on the Site. You
                      are solely responsible for your Contributions to the Site
                      and you expressly agree to exonerate us from any and all
                      responsibility and to refrain from any legal action
                      against us regarding your Contributions.
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div style={{ textAlign: "justify", lineHeight: 1 }}>
              <br />
            </div>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                We have the right, in our sole and absolute discretion, (1) to
                edit, redact, or otherwise change any Contributions; (2) to
                re-categorize any Contributions to place them in more
                appropriate locations on the Site; and (3) to pre-screen or
                delete any Contributions at any time and for any reason, without
                notice. We have no obligation to monitor your Contributions.
                <span className="else-block" />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className="block-container if" data-type="if">
                <span data-type="conditional-block">
                  <span
                    className="block-component"
                    data-record-question-key="review_option"
                    data-type="statement"
                  >
                    <span style={{ fontSize: 15 }} />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className="block-container if" data-type="if">
                <span data-type="conditional-block">
                  <span data-type="body">
                    <div
                      className="font-sans text-xl text-black"
                      id="reviews"
                      style={{ lineHeight: "115%" }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19,
                              }}
                            >
                              <strong>
                                <span
                                  style={{
                                    lineHeight: "115%",
                                    fontFamily: "Arial",
                                    fontSize: 19,
                                  }}
                                >
                                  8.
                                </span>
                              </strong>
                            </span>{" "}
                          </strong>
                          GUIDELINES FOR REVIEWS
                        </span>
                      </strong>
                    </div>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: 1 }}>
              <br />
            </div>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                We may provide you areas on the Site to leave reviews or
                ratings. When posting a review, you must comply with the
                following criteria: (1) you should have firsthand experience
                with the person/entity being reviewed; (2) your reviews should
                not contain offensive profanity, or abusive, racist, offensive,
                or hate language; (3) your reviews should not contain
                discriminatory references based on religion, race, gender,
                national origin, age, marital status, sexual orientation, or
                disability; (4) your reviews should not contain references to
                illegal activity; (5) you should not be affiliated with
                competitors if posting negative reviews; (6) you should not make
                any conclusions as to the legality of conduct; (7) you may not
                post any false or misleading statements; and (8) you may not
                organize a campaign encouraging others to post reviews, whether
                positive or negative.
              </span>
            </div>
            <div style={{ lineHeight: 1 }}>
              <br />
            </div>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                We may accept, reject, or remove reviews in our sole discretion.
                We have absolutely no obligation to screen reviews or to delete
                reviews, even if anyone considers reviews objectionable or
                inaccurate. Reviews are not endorsed by us, and do not
                necessarily represent our opinions or the views of any of our
                affiliates or partners. We do not assume liability for any
                review or for any claims, liabilities, or losses resulting from
                any review. By posting a review, you hereby grant to us a
                perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
                assignable, and sublicensable right and license to reproduce,
                modify, translate, transmit by any means, display, perform,
                and/or distribute all content relating to reviews.
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span className="block-container if" data-type="if">
                <span className="statement-end-if-in-editor" data-type="close">
                  <span style={{ fontSize: 15 }} />
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "115%" }}>
            <span style={{ fontSize: 15 }}>
              <button name="_6nl7u6ag6use" />
            </span>
          </div>
          <span
            className="block-container if"
            data-type="if"
            id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
          >
            <span data-type="conditional-block">
              <span
                className="block-component"
                data-record-question-key="mobile_app_option"
                data-type="statement"
              >
                <span style={{ fontSize: 15 }} />
              </span>
              <span data-type="body">
                <div
                  className="font-sans text-xl text-black"
                  id="mobile"
                  style={{ lineHeight: "115%" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19,
                              }}
                            >
                              9.
                            </span>
                          </strong>
                        </span>{" "}
                      </strong>
                      MOBILE APPLICATION LICENSE
                    </span>
                  </strong>
                </div>
              </span>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <span className="block-container if" data-type="if">
            <span data-type="conditional-block">
              <span data-type="body">
                <div style={{ lineHeight: "115%" }}>
                  <button name="_4p0l96wt8fhj" />
                </div>
                <div
                  className="font-sans text-lg text-black"
                  style={{ lineHeight: "115%" }}
                >
                  <strong>
                    <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                      Use License
                    </span>
                  </strong>
                </div>
              </span>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <span className="block-container if" data-type="if">
            <span data-type="conditional-block">
              <span data-type="body">
                <div
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    If you access the Site via a mobile application, then we
                    grant you a revocable, non-exclusive, non-transferable,
                    limited right to install and use the mobile application on
                    wireless electronic devices owned or controlled by you, and
                    to access and use the mobile application on such devices
                    strictly in accordance with the terms and conditions of this
                    mobile application license contained in these Terms of Use.
                    You shall not: (1) except as permitted by applicable law,
                    decompile, reverse engineer, disassemble, attempt to derive
                    the source code of, or decrypt the application; (2) make any
                    modification, adaptation, improvement, enhancement,
                    translation, or derivative work from the application; (3)
                    violate any applicable laws, rules, or regulations in
                    connection with your access or use of the application; (4)
                    remove, alter, or obscure any proprietary notice (including
                    any notice of copyright or trademark) posted by us or the
                    licensors of the application; (5) use the application for
                    any revenue generating endeavor, commercial enterprise, or
                    other purpose for which it is not designed or intended; (6)
                    make the application available over a network or other
                    environment permitting access or use by multiple devices or
                    users at the same time; (7) use the application for creating
                    a product, service, or software that is, directly or
                    indirectly, competitive with or in any way a substitute for
                    the application; (8) use the application to send automated
                    queries to any website or to send any unsolicited commercial
                    e-mail; or (9) use any proprietary information or any of our
                    interfaces or our other intellectual property in the design,
                    development, manufacture, licensing, or distribution of any
                    applications, accessories, or devices for use with the
                    application.
                  </span>
                </div>
              </span>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <span className="block-container if" data-type="if">
            <span data-type="conditional-block">
              <span data-type="body">
                <div
                  className="font-sans text-lg text-black"
                  style={{ lineHeight: "115%" }}
                >
                  <button name="_vzf0b5xscg" />
                  <strong>
                    <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                      Apple and Android Devices
                    </span>
                  </strong>
                </div>
              </span>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <span className="block-container if" data-type="if">
            <span data-type="conditional-block">
              <span data-type="body">
                <div
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    The following terms apply when you use a mobile application
                    obtained from either the Apple Store or Google Play (each an
                    “App Distributor”) to access the Site: (1) the license
                    granted to you for our mobile application is limited to a
                    non-transferable license to use the application on a device
                    that utilizes the Apple iOS or Android operating systems, as
                    applicable, and in accordance with the usage rules set forth
                    in the applicable App Distributor’s terms of service; (2) we
                    are responsible for providing any maintenance and support
                    services with respect to the mobile application as specified
                    in the terms and conditions of this mobile application
                    license contained in these Terms of Use or as otherwise
                    required under applicable law, and you acknowledge that each
                    App Distributor has no obligation whatsoever to furnish any
                    maintenance and support services with respect to the mobile
                    application; (3) in the event of any failure of the mobile
                    application to conform to any applicable warranty, you may
                    notify the applicable App Distributor, and the App
                    Distributor, in accordance with its terms and policies, may
                    refund the purchase price, if any, paid for the mobile
                    application, and to the maximum extent permitted by
                    applicable law, the App Distributor will have no other
                    warranty obligation whatsoever with respect to the mobile
                    application; (4) you represent and warrant that (i) you are
                    not located in a country that is subject to a U.S.
                    government embargo, or that has been designated by the U.S.
                    government as a “terrorist supporting” country and (ii) you
                    are not listed on any U.S. government list of prohibited or
                    restricted parties; (5) you must comply with applicable
                    third-party terms of agreement when using the mobile
                    application, e.g., if you have a VoIP application, then you
                    must not be in violation of their wireless data service
                    agreement when using the mobile application; and (6) you
                    acknowledge and agree that the App Distributors are
                    third-party beneficiaries of the terms and conditions in
                    this mobile application license contained in these Terms of
                    Use, and that each App Distributor will have the right (and
                    will be deemed to have accepted the right) to enforce the
                    terms and conditions in this mobile application license
                    contained in these Terms of Use against you as a third-party
                    beneficiary thereof.
                  </span>
                </div>
              </span>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center">
          <span className="block-container if" data-type="if">
            <span className="statement-end-if-in-editor" data-type="close">
              <span style={{ fontSize: 15 }} />
            </span>
          </span>
        </div>
        <div align="center">
          <span style={{ fontSize: 15 }}>
            <span className="block-component" />
          </span>
        </div>
        <div
          className="font-sans text-xl text-black"
          id="socialmedia"
          style={{ lineHeight: "115%" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19,
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      10.
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              SOCIAL MEDIA
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="font-body text-base text-gray-600"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            As part of the functionality of the Site, you may link your account
            with online accounts you have with third-party service providers
            (each such account, a “Third-Party Account”) by either: (1)
            providing your Third-Party Account login information through the
            Site; or (2) allowing us to access your Third-Party Account, as is
            permitted under the applicable terms and conditions that govern your
            use of each Third-Party Account. You represent and warrant that you
            are entitled to disclose your Third-Party Account login information
            to us and/or grant us access to your Third-Party Account, without
            breach by you of any of the terms and conditions that govern your
            use of the applicable Third-Party Account, and without obligating us
            to pay any fees or making us subject to any usage limitations
            imposed by the third-party service provider of the Third-Party
            Account. By granting us access to any Third-Party Accounts, you
            understand that (1) we may access, make available, and store (if
            applicable) any content that you have provided to and stored in your
            Third-Party Account (the “Social Network Content”) so that it is
            available on and through the Site via your account, including
            without limitation any friend lists and (2) we may submit to and
            receive from your Third-Party Account additional information to the
            extent you are notified when you link your account with the
            Third-Party Account. Depending on the Third-Party Accounts you
            choose and subject to the privacy settings that you have set in such
            Third-Party Accounts, personally identifiable information that you
            post to your Third-Party Accounts may be available on and through
            your account on the Site. Please note that if a Third-Party Account
            or associated service becomes unavailable or our access to such
            Third Party Account is terminated by the third-party service
            provider, then Social Network Content may no longer be available on
            and through the Site. You will have the ability to disable the
            connection between your account on the Site and your Third-Party
            Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
            THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
            ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
            THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
            Social Network Content for any purpose, including but not limited
            to, for accuracy, legality, or non-infringement, and we are not
            responsible for any Social Network Content. You acknowledge and
            agree that we may access your email address book associated with a
            Third-Party Account and your contacts list stored on your mobile
            device or tablet computer solely for purposes of identifying and
            informing you of those contacts who have also registered to use the
            Site. You can deactivate the connection between the Site and your
            Third-Party Account by contacting us using the contact information
            below or through your account settings (if applicable). We will
            attempt to delete any information stored on our servers that was
            obtained through such Third-Party Account, except the username and
            profile picture that become associated with your account.
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="statement-end-if-in-editor" />
          </span>
        </div>
        <div
          className="font-sans text-xl text-black"
          id="submissions"
          style={{ lineHeight: "115%" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19,
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      11.
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              SUBMISSIONS
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="font-body text-base text-gray-600"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other information regarding the
              Site ("Submissions") provided by you to us are non-confidential
              and shall become our sole property. We shall own exclusive rights,
              including all intellectual property rights, and shall be entitled
              to the unrestricted use and dissemination of these Submissions for
              any lawful purpose, commercial or otherwise, without
              acknowledgment or compensation to you. You hereby waive all moral
              rights to any such Submissions, and you hereby warrant that any
              such Submissions are original with you or that you have the right
              to submit such Submissions. You agree there shall be no recourse
              against us for any alleged or actual infringement or
              misappropriation of any proprietary right in your Submissions.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center">
          <span style={{ fontSize: 15 }}>
            <span className="block-component" />
          </span>
        </div>
        <div
          className="font-sans text-xl text-black"
          id="thirdparty"
          style={{ lineHeight: "115%" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19,
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      12.
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              THIRD-PARTY WEBSITE AND CONTENT
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="font-body text-base text-gray-600"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            The Site may contain (or you may be sent via the Site) links to
            other websites ("Third-Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Site or any Third-Party
            Content posted on, available through, or installed from the Site,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices,
            of any website to which you navigate from the Site or relating to
            any applications you use or install from the Site. Any purchases you
            make through Third-Party Websites will be through other websites and
            from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not
            endorse the products or services offered on Third-Party Websites and
            you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless
            from any losses sustained by you or harm caused to you relating to
            or resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center">
          <span style={{ fontSize: 15 }}>
            <span className="statement-end-if-in-editor" />
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <div style={{ lineHeight: "115%" }}>
            <span style={{ fontSize: 15 }}>
              <button name="_29ce8o9pbtmi" />
            </span>
          </div>
          <span
            className="block-container if"
            data-type="if"
            id="14038561-dad7-be9d-370f-f8aa487b2570"
          >
            <span data-type="conditional-block">
              <span
                className="block-component"
                data-record-question-key="advertiser_option"
                data-type="statement"
              >
                <span style={{ fontSize: 15 }} />
              </span>
            </span>
            <div
              className="font-sans text-xl text-black"
              id="sitemanage"
              style={{ lineHeight: "115%" }}
            >
              <button name="_wj13r09u8u3u" />
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19,
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          13.
                        </span>
                      </strong>
                    </span>{" "}
                  </strong>
                  SITE MANAGEMENT
                </span>
              </strong>
            </div>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We reserve the right, but not the obligation, to: (1) monitor the
              Site for violations of these Terms of Use; (2) take appropriate
              legal action against anyone who, in our sole discretion, violates
              the law or these Terms of Use, including without limitation,
              reporting such user to law enforcement authorities; (3) in our
              sole discretion and without limitation, refuse, restrict access
              to, limit the availability of, or disable (to the extent
              technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation,
              notice, or liability, to remove from the Site or otherwise disable
              all files and content that are excessive in size or are in any way
              burdensome to our systems; and (5) otherwise manage the Site in a
              manner designed to protect our rights and property and to
              facilitate the proper functioning of the Site.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <button name="_jugvcvcw0oj9" />
          </div>
          <span
            className="block-container if"
            data-type="if"
            id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
          >
            <span data-type="conditional-block">
              <span
                className="block-component"
                data-record-question-key="privacy_policy_option"
                data-type="statement"
              >
                <span style={{ fontSize: 15 }} />
              </span>
              <span data-type="body">
                <div
                  className="font-sans text-xl text-black"
                  id="privacypolicy1"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19,
                              }}
                            >
                              14.
                            </span>
                          </strong>
                        </span>{" "}
                      </strong>
                      PRIVACY POLICY
                    </span>
                  </strong>
                </div>
                <div style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    We care about data privacy and security.{" "}
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    Please review our Privacy Policy:
                  </span>
                  <b
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    <span
                      className="block-container question question-in-editor"
                      data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1"
                      data-type="question"
                    >
                      fastfluencyafrica.com/privacy
                    </span>
                  </b>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    .{" "}
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    By using the Site, you agree to be bound by our Privacy
                    Policy, which is incorporated into these Terms of Use.
                    Please be advised the Site is hosted in{" "}
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span className="block-component" />
                      <span className="question">South Africa</span>
                      <span className="statement-end-if-in-editor" />
                    </span>
                    . If you access the Site from any other region of the world
                    with laws or other requirements governing personal data
                    collection, use, or disclosure that differ from applicable
                    laws in{" "}
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span className="block-component" />
                      <span className="question">South Africa</span>
                      <span className="statement-end-if-in-editor" />
                    </span>
                    , then through your continued use of the Site, you are
                    transferring your data to{" "}
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span className="block-component" />
                      <span className="question">South Africa</span>
                      <span className="statement-end-if-in-editor" />
                    </span>
                    , and you agree to have your data transferred to and
                    processed in{" "}
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span className="block-component" />
                      <span className="question">South Africa</span>
                      <span className="statement-end-if-in-editor" />
                    </span>
                    . <span className="block-component" />
                  </span>
                  <span
                    className="block-container if"
                    data-type="if"
                    id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                    style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                  >
                    <span data-type="conditional-block">
                      <span
                        className="block-component"
                        data-record-question-key="user_u13_option"
                        data-type="statement"
                      />
                      <span data-type="body">
                        Further, we do not knowingly accept, request, or solicit
                        information from children or knowingly market to
                        children. Therefore, in accordance with the U.S.
                        Children’s Online Privacy Protection Act, if we receive
                        actual knowledge that anyone under the age of 13 has
                        provided personal information to us without the
                        requisite and verifiable parental consent, we will
                        delete that information from the Site as quickly as is
                        reasonably practical.
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                        >
                          <span className="statement-end-if-in-editor" />
                          <span className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </span>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <span className="block-container if" data-type="if">
            <span className="statement-end-if-in-editor" data-type="close">
              <span style={{ fontSize: 15 }} />
            </span>
          </span>
          <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
            <span
              className="block-container if"
              data-type="if"
              id="87a7471d-cf82-1032-fdf8-601d37d7b017"
            >
              <span data-type="conditional-block">
                <span
                  className="block-component"
                  data-record-question-key="privacy_policy_followup"
                  data-type="statement"
                  style={{ fontSize: "14.6667px" }}
                >
                  <span style={{ fontSize: 15 }} />
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "115%" }}>
            <span style={{ fontSize: 15 }}>
              <button name="_n081pott8yce" />
            </span>
          </div>
          <span className="block-component">
            <span className="block-component" />
          </span>
          <span className="block-component">
            <span className="block-container if" data-type="if">
              <span className="statement-end-if-in-editor" data-type="close">
                <span style={{ fontSize: 15 }} />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: 1 }}>
            <span style={{ fontSize: 15 }}>
              <button name="_sg28ikxq3swh" />
            </span>
            <span className="block-component">
              <span className="block-component" />
            </span>
            <span className="block-container if" data-type="if">
              <span className="statement-end-if-in-editor" data-type="close">
                <span style={{ fontSize: 15 }}>
                  <span className="block-component" />
                </span>
              </span>
            </span>
          </div>
        </div>
        <div
          className="font-sans text-xl text-black"
          id="copyright2"
          style={{ lineHeight: "115%" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19,
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      15.
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              COPYRIGHT INFRINGEMENTS
            </span>
          </strong>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div
          align="center"
          className="font-body text-base text-gray-600"
          style={{ textAlign: "left", lineHeight: "1.5" }}
        >
          <span style={{ fontSize: 15 }}>
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Site infringes
            upon any copyright you own or control, please immediately notify us
            using the contact information provided below (a “Notification”). A
            copy of your Notification will be sent to the person who posted or
            stored the material addressed in the Notification. Please be advised
            that pursuant to applicable law you may be held liable for damages
            if you make material misrepresentations in a Notification. Thus, if
            you are not sure that material located on or linked to by the Site
            infringes your copyright, you should consider first contacting an
            attorney.
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span className="block-container if" data-type="if">
            <span className="statement-end-if-in-editor" data-type="close">
              <span style={{ fontSize: 15 }} />
            </span>
          </span>
          <span className="block-component" />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-sans text-xl text-black"
            id="terms"
            style={{ lineHeight: "115%" }}
          >
            <button name="_k3mndam4w6w1" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        16.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                TERM AND TERMINATION
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: 15,
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)",
              }}
            >
              These Terms of Use shall remain in full force and effect while you
              use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
              OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
              (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
              REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
              OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
              TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE{" "}
              <span
                className="block-container if"
                data-type="if"
                id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
              >
                <span data-type="conditional-block">
                  <span
                    className="block-component"
                    data-record-question-key="user_account_option"
                    data-type="statement"
                  />
                  <span data-type="body">YOUR ACCOUNT AND </span>
                </span>
                <span
                  className="statement-end-if-in-editor"
                  data-type="close"
                />
              </span>
              ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
              WARNING, IN OUR SOLE DISCRETION.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="modifications"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_e2dep1hfgltt" />
            <strong>
              <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                <span style={{ fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          17.
                        </span>
                      </strong>
                    </span>{" "}
                  </strong>
                  MODIFICATIONS AND INTERRUPTIONS
                </span>
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We reserve the right to change, modify, or remove the contents of
              the Site at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Site. We also reserve the right to modify or
              discontinue all or part of the Site without notice at any time. We
              will not be liable to you or any third party for any modification,
              price change, suspension, or discontinuance of the Site.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We cannot guarantee the Site will be available at all times. We
              may experience hardware, software, or other problems or need to
              perform maintenance related to the Site, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the Site
              at any time or for any reason without notice to you. You agree
              that we have no liability whatsoever for any loss, damage, or
              inconvenience caused by your inability to access or use the Site
              during any downtime or discontinuance of the Site. Nothing in
              these Terms of Use will be construed to obligate us to maintain
              and support the Site or to supply any corrections, updates, or
              releases in connection therewith.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="law"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_p6vbf8atcwhs" />
            <strong>
              <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                <span style={{ fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19,
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19,
                          }}
                        >
                          18.
                        </span>
                      </strong>
                    </span>{" "}
                  </strong>
                  GOVERNING LAW
                </span>{" "}
              </span>
            </strong>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ lineHeight: "115%" }}>
            <span className="block-component">
              <span style={{ fontSize: 15 }} />
            </span>
          </div>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                These Terms shall be governed by and defined following the laws
                of{" "}
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span className="block-component" />
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span className="question">South Africa</span>
                          </span>
                        </span>
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            . <span className="question">Fast Fluency Africa</span> and yourself
            irrevocably consent that the courts of{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <span className="block-component" />
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span className="question">South Africa</span>
                  </span>
                </span>
                <span className="statement-end-if-in-editor" />
              </span>
            </span>{" "}
            shall have exclusive jurisdiction to resolve any dispute which may
            arise in connection with these terms.
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <span style={{ fontSize: 15 }}>
                  <span className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="disputes"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_v5i5tjw62cyw" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            19.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                DISPUTE RESOLUTION
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "115%" }}>
            <span
              className="block-container if"
              data-type="if"
              id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
            >
              <span data-type="conditional-block">
                <span
                  className="block-component"
                  data-record-question-key="dispute_option"
                  data-type="statement"
                />
                <span data-type="body">
                  <div
                    className="font-sans text-lg text-black"
                    style={{ lineHeight: "17.25px", textAlign: "left" }}
                  >
                    <strong>Informal Negotiations</strong>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      To expedite resolution and control the cost of any
                      dispute, controversy, or claim related to these Terms of
                      Use (each "Dispute" and collectively, the “Disputes”)
                      brought by either you or us (individually, a “Party” and
                      collectively, the “Parties”), the Parties agree to first
                      attempt to negotiate any Dispute (except those Disputes
                      expressly provided below) informally for at least{" "}
                      <span
                        className="block-container question question-in-editor"
                        data-id="33108d82-92fb-fbe9-6ef5-01cac7097351"
                        data-type="question"
                      >
                        thirty (30)
                      </span>{" "}
                      days before initiating arbitration. Such informal
                      negotiations commence upon written notice from one Party
                      to the other Party.
                    </span>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-sans text-lg text-black"
                    style={{ lineHeight: "17.25px", textAlign: "left" }}
                  >
                    <button name="_p2enhfx5sw3j" />
                    <strong>Binding Arbitration</strong>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1 }}>
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span className="block-component" />
            </span>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      Any dispute arising out of or in connection with this
                      contract, including any question regarding its existence,
                      validity, or termination, shall be referred to and finally
                      resolved by the International Commercial Arbitration Court
                      under the European Arbitration Chamber (Belgium, Brussels,
                      Avenue Louise, 146) according to the Rules of this ICAC,
                      which, as a result of referring to it, is considered as
                      the part of this clause. The number of arbitrators shall
                      be <span className="question">one (1)</span>. The seat, or
                      legal place, of arbitration shall be{" "}
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span className="block-component" />
                        <span className="question">Pretoria</span>,{" "}
                        <span className="block-component" />
                        <span className="question">South Africa</span>
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <span className="else-block" />.
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        {" "}
                        The language of the proceedings shall be{" "}
                        <span className="question">Varies</span>.
                      </span>
                    </span>{" "}
                    The governing law of the contract shall be the substantive
                    law of{" "}
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span className="block-component" />
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span className="block-component" />
                            <span className="question">South Africa</span>
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                    <span className="statement-end-if-in-editor" />.
                  </div>
                  <div style={{ lineHeight: "17.25px", textAlign: "left" }}>
                    <button name="_xl7dsk6ndrpp" />
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span className="statement-end-if-in-editor" />
            </span>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-sans text-lg text-black"
                    style={{ lineHeight: "17.25px", textAlign: "left" }}
                  >
                    <button name="_inlv5c77dhih" />
                    <strong>Restrictions</strong>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      The Parties agree that any arbitration shall be limited to
                      the Dispute between the Parties individually. To the full
                      extent permitted by law, (a) no arbitration shall be
                      joined with any other proceeding; (b) there is no right or
                      authority for any Dispute to be arbitrated on a
                      class-action basis or to utilize class action procedures;
                      and (c) there is no right or authority for any Dispute to
                      be brought in a purported representative capacity on
                      behalf of the general public or any other persons.
                    </span>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-sans text-lg text-black"
                    style={{ lineHeight: "17.25px", textAlign: "left" }}
                  >
                    <button name="_mdjlt1af25uq" />
                    <strong>
                      Exceptions to Informal Negotiations and Arbitration
                    </strong>
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <br />
          </div>
          <div style={{ textAlign: "justify", lineHeight: 1 }}>
            <span className="block-container if" data-type="if">
              <span data-type="conditional-block">
                <span data-type="body">
                  <div
                    className="font-body text-base text-gray-600"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      The Parties agree that the following Disputes are not
                      subject to the above provisions concerning informal
                      negotiations and binding arbitration: (a) any Disputes
                      seeking to enforce or protect, or concerning the validity
                      of, any of the intellectual property rights of a Party;
                      (b) any Dispute related to, or arising from, allegations
                      of theft, piracy, invasion of privacy, or unauthorized
                      use; and (c) any claim for injunctive relief. If this
                      provision is found to be illegal or unenforceable, then
                      neither Party will elect to arbitrate any Dispute falling
                      within that portion of this provision found to be illegal
                      or unenforceable and such Dispute shall be decided by a
                      court of competent jurisdiction within the courts listed
                      for jurisdiction above, and the Parties agree to submit to
                      the personal jurisdiction of that court.
                    </span>
                  </div>
                </span>
              </span>
              <span data-type="conditional-block">
                <span
                  className="block-component"
                  data-record-question-key="null"
                  data-type="statement"
                >
                  <span style={{ fontSize: 15 }} />
                </span>
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="corrections"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_mjgzo07ttzx5" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        20.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                CORRECTIONS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              There may be information on the Site that contains typographical
              errors, inaccuracies, or omissions, including descriptions,
              pricing, availability, and various other information. We reserve
              the right to correct any errors, inaccuracies, or omissions and to
              change or update the information on the Site at any time, without
              prior notice.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="disclaimer"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_gvi74blrahf9" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        21.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                DISCLAIMER
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
              THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
              RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
              YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
              CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
              LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
              PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
              USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
              OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
              VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
              THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
              OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
              WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
              PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
              THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
              APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
              WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
              ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
              PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
              THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
              JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="liability"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_4pjah3d0455q" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        22.{" "}
                      </span>
                    </strong>
                  </span>
                </strong>
                LIMITATIONS OF LIABILITY
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.{" "}
              <span
                className="block-container if"
                data-type="if"
                id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
              >
                <span data-type="conditional-block">
                  <span
                    className="block-component"
                    data-record-question-key="limitations_liability_option"
                    data-type="statement"
                  />
                  <span data-type="body">
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                    OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                    OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO{" "}
                    <span
                      className="block-container if"
                      data-type="if"
                      id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                    >
                      <span data-type="conditional-block">
                        <span
                          className="block-component"
                          data-record-question-key="limitations_liability_option"
                          data-type="statement"
                        />
                        <span data-type="body">
                          THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US
                          <span data-type="conditional-block">
                            <span
                              className="block-component"
                              data-record-question-key="limilation_liability_time_option"
                              data-type="statement"
                            />
                            <span data-type="body">
                              <span
                                style={{
                                  fontSize: "11pt",
                                  color: "rgb(89, 89, 89)",
                                  textTransform: "uppercase",
                                }}
                              >
                                DURING THE{" "}
                                <span
                                  className="block-container question question-in-editor"
                                  data-id="98461079-8520-8e55-edbd-84fdc37a26d4"
                                  data-type="question"
                                >
                                  six (6)
                                </span>{" "}
                                MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                                ARISING
                              </span>
                            </span>
                          </span>
                          <span
                            className="statement-end-if-in-editor"
                            data-type="close"
                          />{" "}
                          OR{" "}
                          <span
                            className="block-container question question-in-editor"
                            data-id="243ad246-9e92-b24d-beee-940be6aa7854"
                            data-type="question"
                          >
                            $100.00 USD
                          </span>
                        </span>
                      </span>
                      <span
                        className="statement-end-if-in-editor"
                        data-type="close"
                      />
                    </span>
                    . CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                    LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                    LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                    SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT
                    APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  </span>
                </span>
                <span
                  className="statement-end-if-in-editor"
                  data-type="close"
                />
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="indemnification"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_k5ap68aj1dd4" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        23.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                INDEMNIFICATION
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of:{" "}
              <span
                className="block-container if"
                data-type="if"
                id="475fffa5-05ca-def8-ac88-f426b238903c"
              >
                <span data-type="conditional-block">
                  <span
                    className="block-component"
                    data-record-question-key="user_post_content_option"
                    data-type="statement"
                  />
                  <span data-type="body">(1) your Contributions; </span>
                </span>
                <span
                  className="statement-end-if-in-editor"
                  data-type="close"
                />
              </span>
              (<span style={{ fontSize: "14.6667px" }}>2</span>) use of the
              Site; (<span style={{ fontSize: "14.6667px" }}>3</span>) breach of
              these Terms of Use; (
              <span style={{ fontSize: "14.6667px" }}>4</span>) any breach of
              your representations and warranties set forth in these Terms of
              Use; (<span style={{ fontSize: "14.6667px" }}>5</span>) your
              violation of the rights of a third party, including but not
              limited to intellectual property rights; or (
              <span style={{ fontSize: "14.6667px" }}>6</span>) any overt
              harmful act toward any other user of the Site with whom you
              connected via the Site. Notwithstanding the foregoing, we reserve
              the right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify us,
              and you agree to cooperate, at your expense, with our defense of
              such claims. We will use reasonable efforts to notify you of any
              such claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }} />
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="userdata"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_ftgg17oha0ep" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            24.
                          </span>
                        </strong>
                      </span>
                    </strong>{" "}
                  </span>
                </strong>
                USER DATA
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We will maintain certain data that you transmit to the Site for
              the purpose of managing the performance of the Site, as well as
              data relating to your use of the Site. Although we perform regular
              routine backups of data, you are solely responsible for all data
              that you transmit or that relates to any activity you have
              undertaken using the Site. You agree that we shall have no
              liability to you for any loss or corruption of any such data, and
              you hereby waive any right of action against us arising from any
              such loss or corruption of such data.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="font-sans text-xl text-black"
            id="electronic"
            style={{ lineHeight: "1.5" }}
          >
            <button name="_dkovrslqodui" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        25.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SITE. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span className="block-component" />
            </span>
          </div>
          <div
            className="font-sans text-xl text-black"
            id="california"
            style={{ lineHeight: "115%" }}
          >
            <button name="_cem9cu2usl7k" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        26.{" "}
                      </span>
                    </strong>
                  </span>
                </strong>
                CALIFORNIA USERS AND RESIDENTS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span className="statement-end-if-in-editor" />
              </span>
            </span>
          </div>
          <div
            className="font-sans text-xl text-black"
            id="misc"
            style={{ lineHeight: "115%" }}
          >
            <button name="_d4jvmcnxg0wt" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19,
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19,
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        27.
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                MISCELLANEOUS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="font-body text-base text-gray-600"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              These Terms of Use and any policies or operating rules posted by
              us on the Site or in respect to the Site constitute the entire
              agreement and understanding between you and us. Our failure to
              exercise or enforce any right or provision of these Terms of Use
              shall not operate as a waiver of such right or provision. These
              Terms of Use operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Terms of Use is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Terms of Use and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Terms of Use or use of the
              Site. You agree that these Terms of Use will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Terms of Use and the lack of signing by the parties hereto
              to execute these Terms of Use.
            </span>
          </div>
          <div style={{ lineHeight: 1 }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span className="block-component" />
            </span>
          </div>
          <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
            <br />
          </div>
          <div align="center" style={{ textAlign: "left" }}>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div
              className="font-sans text-xl text-black"
              id="contact"
              style={{ lineHeight: "1.5" }}
            >
              <button name="_t4pq5cwn486q" />
              <strong>
                <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                  <span style={{ fontSize: 19 }}>
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19,
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19,
                            }}
                          >
                            28.
                          </span>
                        </strong>
                      </span>{" "}
                    </strong>
                    CONTACT US
                  </span>
                </span>
              </strong>
            </div>
          </div>
          <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
            <br />
          </div>
          <div align="center" style={{ textAlign: "left" }}>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5" }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                In order to resolve a complaint regarding the Site or to receive
                further information regarding use of the Site, please contact us
                at:
              </span>
            </div>
          </div>
          <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
            <br />
          </div>
          <div align="center" style={{ textAlign: "left" }}>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5" }}
            >
              <span
                style={{
                  fontSize: 15,
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <span
                  className="block-container question question-in-editor"
                  data-id="8a6919c4-2010-e7d6-2305-d74dfb08909d"
                  data-type="question"
                >
                  <strong>Fast Fluency Africa</strong>
                </span>
              </span>
            </div>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="question">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span style={{ fontSize: 15 }}>
                            <span className="block-component" />
                          </span>
                        </strong>
                      </span>
                      STAND 11 FORTUNE STREET UNIT D
                    </span>
                    <span className="statement-end-if-in-editor" />
                  </span>
                </strong>
              </span>
            </div>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="question">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span style={{ fontSize: 15 }}>
                            <span className="block-component" />
                          </span>
                        </strong>
                      </span>
                      info@fastfluencyafrica.com
                    </span>
                    <span className="statement-end-if-in-editor" />
                  </span>
                </strong>
              </span>
            </div>
            <div
              className="font-body text-base text-gray-600"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="question">PRETORIA</span>
                    <span className="block-component" />,{" "}
                    <span className="question">GAUTENG</span>
                  </span>
                </strong>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="statement-end-if-in-editor" />
                  </span>
                </strong>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="block-component" />
                  </span>
                </strong>
              </span>{" "}
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="question">0040</span>
                    <span className="statement-end-if-in-editor" />
                  </span>
                </strong>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="question">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span style={{ fontSize: 15 }}>
                            <span className="block-component" />
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </strong>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="question">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span style={{ fontSize: 15 }}>
                            <span className="block-component" />
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <strong>
              <div
                className="font-body text-base text-gray-600"
                style={{ lineHeight: "1.5" }}
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span className="block-component" />
                      <span className="question">South Africa</span>
                      <span className="statement-end-if-in-editor" />
                    </span>
                  </strong>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span style={{ fontSize: 15 }}>
                            <span className="statement-end-if-in-editor" />
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </strong>
                      </span>
                    </span>
                  </strong>
                </span>
              </div>
              <div
                className="font-body text-base text-gray-600"
                style={{ lineHeight: "1.5" }}
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>Phone: </span>
                  </strong>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                        <span
                          className="block-container question question-in-editor"
                          data-id="dd6f266f-438b-bfdc-9204-0b17e109e270"
                          data-type="question"
                        >
                          081 428 9377
                        </span>
                      </span>
                    </span>
                  </strong>
                </span>
              </div>
              <div
                className="font-body text-base text-gray-600"
                style={{ lineHeight: "1.5" }}
              >
                <span style={{ fontSize: 15 }}>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <strong>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="block-component" />
                          </span>
                        </strong>
                      </span>
                    </span>
                  </strong>
                </span>
              </div>
              <strong>
                <div
                  className="font-body text-base text-gray-600"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: 15,
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      className="block-container question question-in-editor"
                      data-id="fdc2b5b8-c95f-244b-23a7-287f82541348"
                      data-type="question"
                    >
                      <strong>info@fastfluencyafrica.co.za</strong>
                    </span>
                  </span>
                </div>
              </strong>
            </strong>
          </div>
          <strong>
            <strong>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n          ul {\n            list-style-type: square;\n          }\n  \n          ul>li>ul {\n            list-style-type: circle;\n          }\n  \n          ul>li>ul>li>ul {\n            list-style-type: square;\n          }\n  \n          ol li {\n            font-family: Arial;\n          }\n        ",
                }}
              />
            </strong>
          </strong>
        </div>
        <strong>
          <strong>
            <div
              style={{
                color: "#595959",
                fontSize: 14,
                fontFamily: "Arial",
                paddingTop: 16,
              }}
            >
              These terms of use were created using Termly's{" "}
              <a
                style={{ color: "rgb(48, 48, 241) !important" }}
                href="https://termly.io/products/terms-and-conditions-generator"
              >
                Terms and Conditions Generator
              </a>
              .
            </div>
          </strong>
        </strong>
      </div>
    </motion.div>
  );
}

export default Terms;
