import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import { useDialogueFlow } from "../../../helpers/DialogueFlowContext";
import { useDialogues } from "../../../hooks/dialogueHooks";
import { NarratorPhase } from "./phases/narration-phase";
import { SpeechPhase } from "./phases/speech-phase";
import { QuestionPhase } from "./phases/question-phase";
import CompletionScreen from "./phases/completion-phase";
import { ProgressControls } from "./controls/progress-controls";
import { ScoreContext } from "../../../helpers/ScoreContext";
import { LMContext } from "../../../helpers/context3";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";
import { dialoguePropTypes } from "./dialogue-proptypes";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import DialogueHeaderBar from "./controls/header-bar";
import DialogueExitModal from "./controls/exit-modal";
import { useAttemptsWithNotifications } from "../../../hooks/useAttemptsHook";
import { ModalContext } from "../../../helpers/modalcontext";

// *** DIRECT AUDIO CONTROL APPROACH ***
const InteractiveDialogue = ({ onDialogueComplete = () => {} }) => {
  // Context hooks
  const { setScore } = useContext(ScoreContext);
  const { learningMaterialId } = useContext(LMContext);
  const { attempts = { count: 3, max: 3 } } = useAttemptsWithNotifications();
  const { modal, setModal } = useContext(ModalContext);

  // Component state
  const [isReady, setIsReady] = useState(false);
  const [answeredQuestionIndices, setAnsweredQuestionIndices] = useState([]);
  const [audioInitialized, setAudioInitialized] = useState(false);

  // Audio state with direct control
  const [narrationAudioUrl, setNarrationAudioUrl] = useState("");
  const [speechAudioUrl, setSpeechAudioUrl] = useState("");
  const [isNarrationPlaying, setIsNarrationPlaying] = useState(false);
  const [isSpeechPlaying, setIsSpeechPlaying] = useState(false);

  // Audio element refs
  const narrationAudioRef = useRef(new Audio());
  const speechAudioRef = useRef(new Audio());
  const audioLoadedRef = useRef(false);
  const narrationPlayedRef = useRef(false);

  // Custom hooks
  const { dialogues, loading, error } = useDialogues();
  const [state, dispatch] = useDialogueFlow();

  // Derived state
  const currentDialogue =
    dialogues?.find((d) => d.no === learningMaterialId) || null;
  const totalQuestions = currentDialogue?.questions?.length || 0;
  const currentQuestion =
    currentDialogue?.questions?.[state.currentQuestion] || null;
  const totalSpeechBubbles = currentDialogue?.speechInfo?.length || 0;
  const isLastSpeechBubble = state.currentStep === totalSpeechBubbles - 1;

  // Unlock audio on initial user interaction
  useEffect(() => {
    const unlockAudio = () => {
      console.log("Unlocking audio context via user interaction");
      // Create and play a silent audio to unlock audio context
      const tempAudio = new Audio();
      tempAudio.play().catch(() => {});
      tempAudio.pause();
      tempAudio.src = "";

      // Setup our audio elements
      narrationAudioRef.current.volume = 1.0;
      speechAudioRef.current.volume = 1.0;

      // Remove the event listeners
      document.removeEventListener("click", unlockAudio);
      document.removeEventListener("touchstart", unlockAudio);

      setAudioInitialized(true);
    };

    document.addEventListener("click", unlockAudio);
    document.addEventListener("touchstart", unlockAudio);

    return () => {
      document.removeEventListener("click", unlockAudio);
      document.removeEventListener("touchstart", unlockAudio);
    };
  }, []);

  // Set up audio event listeners
  useEffect(() => {
    const narrationAudio = narrationAudioRef.current;
    const speechAudio = speechAudioRef.current;

    // Narration audio events
    const handleNarrationEnded = () => {
      console.log("Narration audio ended");
      setIsNarrationPlaying(false);
      narrationPlayedRef.current = true;
    };

    const handleNarrationPlaying = () => {
      console.log("Narration is now playing");
      setIsNarrationPlaying(true);
    };

    const handleNarrationError = (e) => {
      console.error("Narration audio error:", e);
      setIsNarrationPlaying(false);
    };

    // Speech audio events
    const handleSpeechEnded = () => {
      console.log("Speech audio ended");
      setIsSpeechPlaying(false);
    };

    const handleSpeechPlaying = () => {
      console.log("Speech is now playing");
      setIsSpeechPlaying(true);
    };

    const handleSpeechError = (e) => {
      console.error("Speech audio error:", e);
      setIsSpeechPlaying(false);
    };

    // Add event listeners
    narrationAudio.addEventListener("ended", handleNarrationEnded);
    narrationAudio.addEventListener("playing", handleNarrationPlaying);
    narrationAudio.addEventListener("error", handleNarrationError);

    speechAudio.addEventListener("ended", handleSpeechEnded);
    speechAudio.addEventListener("playing", handleSpeechPlaying);
    speechAudio.addEventListener("error", handleSpeechError);

    return () => {
      // Remove event listeners
      narrationAudio.removeEventListener("ended", handleNarrationEnded);
      narrationAudio.removeEventListener("playing", handleNarrationPlaying);
      narrationAudio.removeEventListener("error", handleNarrationError);

      speechAudio.removeEventListener("ended", handleSpeechEnded);
      speechAudio.removeEventListener("playing", handleSpeechPlaying);
      speechAudio.removeEventListener("error", handleSpeechError);

      // Clean up
      narrationAudio.pause();
      speechAudio.pause();
      narrationAudio.src = "";
      speechAudio.src = "";
    };
  }, []);

  // Load narration audio when dialogue is available
  useEffect(() => {
    if (!currentDialogue || !audioInitialized || audioLoadedRef.current) return;

    const loadNarrationAudio = async () => {
      console.log("Loading narration audio");
      if (currentDialogue.narrationAudio) {
        try {
          const url = await getAudioDownloadUrl(currentDialogue.narrationAudio);
          console.log("Narration audio URL:", url);
          setNarrationAudioUrl(url);

          // Set the source
          narrationAudioRef.current.src = url;
          narrationAudioRef.current.load();

          // Auto-play narration when loaded
          console.log("Attempting to play narration audio automatically");
          narrationAudioRef.current.play().catch((err) => {
            console.warn("Could not auto-play narration:", err);
          });

          audioLoadedRef.current = true;
        } catch (error) {
          console.error("Failed to load narration audio:", error);
        }
      }
    };

    loadNarrationAudio();
  }, [currentDialogue, audioInitialized]);

  // Generate question checkpoints (every 3-5 bubbles) if not already set
  useEffect(() => {
    if (
      currentDialogue &&
      totalQuestions > 0 &&
      state.questionCheckpoints.length === 0
    ) {
      // Distribute questions evenly throughout the dialogue
      const questionSpacing = Math.floor(
        totalSpeechBubbles / (totalQuestions + 1)
      );
      const spacing = Math.max(3, Math.min(5, questionSpacing)); // Between 3-5 bubbles

      const checkpoints = [];
      for (let i = 0; i < totalQuestions; i++) {
        // Calculate checkpoints with a minimum of 3 steps between them
        const checkpoint = Math.min(3 + i * spacing, totalSpeechBubbles - 1);
        checkpoints.push(checkpoint);
      }

      console.log("Setting checkpoints:", checkpoints);
      dispatch({
        type: "SET_QUESTION_CHECKPOINTS",
        payload: checkpoints,
      });
    }
  }, [
    currentDialogue,
    totalQuestions,
    totalSpeechBubbles,
    state.questionCheckpoints.length,
    dispatch,
  ]);

  // Determine if we should show a question at current step
  const shouldShowQuestionAtCurrentStep =
    state.phase === "dialogue" &&
    state.questionCheckpoints.includes(state.currentStep) &&
    !state.questionShownAtCheckpoints.includes(state.currentStep) &&
    state.currentQuestion < totalQuestions;

  // Find the current checkpoint index (how many checkpoints we've passed)
  const getCurrentCheckpointIndex = useCallback(() => {
    if (!state.questionCheckpoints.length) return -1;

    let checkpointIndex = -1;
    for (let i = 0; i < state.questionCheckpoints.length; i++) {
      if (state.currentStep >= state.questionCheckpoints[i]) {
        checkpointIndex = i;
      }
    }
    return checkpointIndex;
  }, [state.questionCheckpoints, state.currentStep]);

  // Initialize dialogue when first loaded
  useEffect(() => {
    if (currentDialogue && !isReady && audioInitialized) {
      setIsReady(true);
      // Reset dialogue state when a new dialogue is loaded
      dispatch({ type: "SET_PHASE", payload: "narrator" });
    }
  }, [currentDialogue, isReady, audioInitialized, dispatch]);

  // Load speech audio when step changes
  useEffect(() => {
    if (state.phase !== "dialogue" || !currentDialogue?.speechInfo) return;

    // Get the current speech item
    const speechItem = currentDialogue.speechInfo[state.currentStep];
    if (!speechItem?.audio) return;

    const loadSpeechAudio = async () => {
      console.log(`Loading speech audio for step ${state.currentStep}`);
      try {
        // Always stop any playing audio first
        narrationAudioRef.current.pause();
        speechAudioRef.current.pause();

        const url = await getAudioDownloadUrl(speechItem.audio);
        console.log("Speech audio URL:", url);
        setSpeechAudioUrl(url);

        // Set the source
        speechAudioRef.current.src = url;
        speechAudioRef.current.load();

        // Auto-play speech audio when loaded
        console.log("Attempting to play speech audio automatically");
        speechAudioRef.current.play().catch((err) => {
          console.warn("Could not auto-play speech audio:", err);
        });
      } catch (error) {
        console.error("Failed to load speech audio:", error);
      }
    };

    loadSpeechAudio();
  }, [state.phase, state.currentStep, currentDialogue]);

  // Track audio playing state in the dialogue flow
  useEffect(() => {
    dispatch({
      type: "SET_AUDIO_PLAYING",
      payload: isNarrationPlaying || isSpeechPlaying,
    });
  }, [isNarrationPlaying, isSpeechPlaying, dispatch]);

  // Handle modal open
  const handleOpenModal = () => {
    setModal(true);
  };

  // Handle phase transitions
  const handleContinue = useCallback(() => {
    // Stop any currently playing audio
    narrationAudioRef.current.pause();
    speechAudioRef.current.pause();

    // Handle based on current phase
    switch (state.phase) {
      case "narrator":
        console.log("Transitioning from narrator to dialogue");
        // Transition from narrator to dialogue
        dispatch({ type: "NEXT_PHASE" });
        break;

      case "dialogue":
        // Check if we should show a question at this step
        if (shouldShowQuestionAtCurrentStep) {
          console.log("Showing question at step:", state.currentStep);
          dispatch({ type: "SHOW_QUESTION" });
        }
        // If on last speech bubble, complete the dialogue or show final question
        else if (isLastSpeechBubble) {
          const hasUnansweredQuestions = state.currentQuestion < totalQuestions;
          if (hasUnansweredQuestions) {
            // Show final question if available
            dispatch({ type: "SHOW_QUESTION" });
          } else {
            dispatch({ type: "COMPLETE_DIALOGUE" });
          }
        }
        // Otherwise go to next speech bubble
        else {
          const nextStep = state.currentStep + 1;
          dispatch({ type: "NEXT_STEP" });
        }
        break;

      case "question":
        // Handled by QuestionPhase component
        break;

      case "complete":
        // Notify parent component that dialogue is complete
        onDialogueComplete();
        break;

      default:
        console.warn("Unknown phase:", state.phase);
    }
  }, [
    state.phase,
    state.currentStep,
    state.currentQuestion,
    isLastSpeechBubble,
    totalQuestions,
    dispatch,
    onDialogueComplete,
    shouldShowQuestionAtCurrentStep,
  ]);

  // Handle answer submission in question phase
  const handleAnswer = useCallback(
    (isCorrect) => {
      // Mark this question as answered
      setAnsweredQuestionIndices((prev) => [...prev, state.currentQuestion]);

      dispatch({
        type: "ANSWER_QUESTION",
        payload: {
          isCorrect,
          attempts: 1,
          scoreChange: isCorrect ? 100 : -25,
          questionIndex: state.currentQuestion,
        },
      });

      // Update score
      setScore((prev) => prev + (isCorrect ? 100 : -25));

      // Auto-advance after delay
      setTimeout(() => {
        // First return to dialogue phase - don't increment question counter yet
        // We'll increment it when we reach the next checkpoint
        if (isLastSpeechBubble && state.currentQuestion >= totalQuestions - 1) {
          // If we've shown all speech bubbles and answered all questions, complete the dialogue
          dispatch({ type: "COMPLETE_DIALOGUE" });
        } else {
          // Return to dialogue at the *next* step after the last completed one
          dispatch({ type: "RETURN_TO_DIALOGUE_NEXT_STEP" });
        }
      }, 1500);
    },
    [
      state.currentQuestion,
      isLastSpeechBubble,
      totalQuestions,
      dispatch,
      setScore,
    ]
  );

  // Loading/error states
  if (loading)
    return <div className="text-center py-8">Loading dialogue...</div>;
  if (error)
    return (
      <div className="text-center py-8 text-red-500">
        Error loading dialogue: {error.message}
      </div>
    );
  if (!currentDialogue)
    return <div className="text-center py-8">Dialogue not found</div>;

  // Helper function to get current audio state for child components
  const getAudioState = () => {
    if (state.phase === "narrator") {
      return {
        isPlaying: isNarrationPlaying,
        togglePlayback: () => {
          if (isNarrationPlaying) {
            narrationAudioRef.current.pause();
          } else {
            narrationAudioRef.current
              .play()
              .catch((err) => console.warn("Play error:", err));
          }
        },
      };
    } else if (state.phase === "dialogue") {
      return {
        isPlaying: isSpeechPlaying,
        togglePlayback: () => {
          if (isSpeechPlaying) {
            speechAudioRef.current.pause();
          } else {
            speechAudioRef.current
              .play()
              .catch((err) => console.warn("Play error:", err));
          }
        },
      };
    }
    return { isPlaying: false, togglePlayback: () => {} };
  };

  return (
    <div className="interactive-dialogue min-h-screen flex flex-col mx-auto w-4/6">
      {/* Exit modal */}
      {modal && <DialogueExitModal />}

      {/* Header with progress and exit button */}
      <div id="section-1" className="w-full min-h-[30px] mt-8">
        <DialogueHeaderBar
          handleOpenModal={handleOpenModal}
          attempts={attempts}
          currentStep={state.currentStep}
          totalSteps={totalSpeechBubbles}
        />
      </div>

      {/* Audio debug control - only in development */}
      {process.env.NODE_ENV === "development" && (
        <div className="bg-gray-100 p-2 text-xs">
          <p>
            Audio Debug:
            {state.phase === "narrator"
              ? ` Narration ${isNarrationPlaying ? "Playing" : "Paused"}`
              : ` Speech ${isSpeechPlaying ? "Playing" : "Paused"}`}
          </p>
          <button
            onClick={() => {
              const audioState = getAudioState();
              audioState.togglePlayback();
            }}
            className="bg-gray-200 px-2 py-1 rounded mx-1"
          >
            Toggle
          </button>
          <button
            onClick={() => {
              narrationAudioRef.current.pause();
              speechAudioRef.current.pause();
              narrationAudioRef.current.currentTime = 0;
              speechAudioRef.current.currentTime = 0;
            }}
            className="bg-gray-200 px-2 py-1 rounded mx-1"
          >
            Reset
          </button>
        </div>
      )}

      {/* Main content - centered vertically and horizontally */}
      <div className="flex-grow flex items-center justify-center h-[500px] mt-8">
        <div className="max-w-3xl w-full px-4 py-6">
          <AnimatePresence mode="wait">
            {state.phase === "narrator" && (
              <NarratorPhase
                dialogue={currentDialogue}
                isPlaying={isNarrationPlaying}
                onPlayAudio={() => {}} // No-op as we're handling audio directly
              />
            )}

            {state.phase === "dialogue" && (
              <SpeechPhase
                step={state.currentStep}
                dialogue={currentDialogue}
                isPlaying={isSpeechPlaying}
                canShowComprehensionCheck={shouldShowQuestionAtCurrentStep}
                onShowQuestion={() => dispatch({ type: "SHOW_QUESTION" })}
                onPlayAudio={() => {}} // No-op as we're handling audio directly
              />
            )}

            {state.phase === "question" && currentQuestion && (
              <QuestionPhase
                question={currentQuestion}
                onAnswer={handleAnswer}
              />
            )}

            {state.phase === "complete" && (
              <CompletionScreen
                score={state.score}
                totalQuestions={totalQuestions}
                correctAnswers={
                  state.questionsAnswered.filter((q) => q.isCorrect).length
                }
                attempts={state.userAttempts}
                onContinue={onDialogueComplete}
              />
            )}
          </AnimatePresence>

          {state.phase !== "complete" && state.phase !== "question" && (
            <div className="mt-4">
              <ProgressControls
                onContinue={handleContinue}
                disabled={state.phase === "question"} // Removed the playing condition to allow progress
                currentStep={state.currentStep}
                totalSteps={totalSpeechBubbles}
                phase={state.phase}
              />

              {/* Explicit audio control buttons */}
              <div className="flex justify-center mt-3">
                <button
                  onClick={() => {
                    const audio =
                      state.phase === "narrator"
                        ? narrationAudioRef.current
                        : speechAudioRef.current;

                    const isPlaying =
                      state.phase === "narrator"
                        ? isNarrationPlaying
                        : isSpeechPlaying;

                    if (isPlaying) {
                      audio.pause();
                    } else {
                      audio
                        .play()
                        .catch((err) => console.warn("Play error:", err));
                    }
                  }}
                  className="text-sm bg-gold text-white px-3 py-1 rounded-full mx-2"
                >
                  {state.phase === "narrator"
                    ? isNarrationPlaying
                      ? "❚❚ Pause Narration"
                      : "▶ Play Narration"
                    : isSpeechPlaying
                    ? "❚❚ Pause Speech"
                    : "▶ Play Speech"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InteractiveDialogue.propTypes = {
  onDialogueComplete: PropTypes.func,
};

export default InteractiveDialogue;
