import React, { useState } from "react";
import { motion } from "framer-motion";
import AudioPlayer from "../common/audio-player";

const UnitOverview = ({ unit, unitData, onBack, isModal = false }) => {
  const [activeTab, setActiveTab] = useState("overview");

  // Helper function to get tab style based on active state
  const getTabStyle = (tabName) => {
    return activeTab === tabName
      ? {
          backgroundColor: unit.color || "#991616",
          color: "#FFFFFF",
        }
      : {
          backgroundColor: "#FFFFFF",
          color: "#000000",
          border: `1px solid ${unit.color || "#991616"}`,
        };
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="p-3 sm:p-4 md:p-6">
      {/* Back button */}
      <div className="mb-4 sm:mb-6">
        <button
          onClick={onBack}
          className="flex items-center text-gray-700 hover:text-gray-900 text-sm sm:text-base"
        >
          <span className="mr-2">←</span>
          <span>Back to {isModal ? "Units" : "Dashboard"}</span>
        </button>
      </div>

      {/* Header */}
      <motion.div
        className="mb-6 sm:mb-8"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Title and audio player */}
        <motion.div
          className="flex flex-col sm:flex-row sm:items-center mb-4 gap-3"
          variants={itemVariants}
        >
          <div className="flex-1">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold">
              {unitData.name}
            </h1>
            <p
              className="text-base sm:text-lg"
              style={{ color: unit.color || "#991616" }}
            >
              {unitData.topic}
            </p>
          </div>

          {/* Add unit title pronunciation audio if available */}
          {unitData.titleAudio && (
            <div className="sm:ml-auto mt-2 sm:mt-0">
              <AudioPlayer
                audioPath={unitData.titleAudio}
                color={unit.color || "#991616"}
                label="Hear pronunciation"
                size="medium"
              />
            </div>
          )}
        </motion.div>

        {/* Tabs */}
        <motion.div
          className="flex flex-wrap mb-4 sm:mb-6 border-b"
          variants={itemVariants}
        >
          <button
            className="py-1 px-2 sm:py-2 sm:px-4 mr-1 sm:mr-2 rounded-t-lg text-sm sm:text-base"
            style={getTabStyle("overview")}
            onClick={() => setActiveTab("overview")}
          >
            Overview
          </button>
          <button
            className="py-1 px-2 sm:py-2 sm:px-4 mr-1 sm:mr-2 rounded-t-lg text-sm sm:text-base"
            style={getTabStyle("vowels")}
            onClick={() => setActiveTab("vowels")}
            disabled={!unitData.vowelsData || unitData.vowelsData.length === 0}
          >
            Vowels
          </button>
          <button
            className="py-1 px-2 sm:py-2 sm:px-4 mr-1 sm:mr-2 rounded-t-lg text-sm sm:text-base"
            style={getTabStyle("consonants")}
            onClick={() => setActiveTab("consonants")}
            disabled={
              !unitData.consonantsData || unitData.consonantsData.length === 0
            }
          >
            Consonants
          </button>
          <button
            className="py-1 px-2 sm:py-2 sm:px-4 rounded-t-lg text-sm sm:text-base"
            style={getTabStyle("clicks")}
            onClick={() => setActiveTab("clicks")}
            disabled={!unitData.clicksData || unitData.clicksData.length === 0}
          >
            Clicks
          </button>
        </motion.div>
      </motion.div>

      {/* Content based on active tab */}
      <motion.div
        className="unit-content"
        key={activeTab}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {activeTab === "overview" && (
          <div>
            <div className="mb-6">
              <h2 className="text-lg sm:text-xl font-semibold mb-2">
                Unit Overview
              </h2>
              <p className="text-base sm:text-lg">{unitData.overview}</p>

              {/* Overview audio narration */}
              {unitData.overviewAudio && (
                <div className="mt-4">
                  <AudioPlayer
                    audioPath={unitData.overviewAudio}
                    color={unit.color || "#991616"}
                    label="Listen to overview"
                    size="medium"
                  />
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div
                className="p-3 sm:p-4 border-2 rounded-lg"
                style={{ borderColor: unit.color || "#991616" }}
              >
                <h3 className="font-semibold mb-2 text-sm sm:text-base">
                  Estimated Duration
                </h3>
                <p className="text-sm sm:text-base">
                  {unitData.estimatedDuration}
                </p>
              </div>
              <div
                className="p-3 sm:p-4 border-2 rounded-lg"
                style={{ borderColor: unit.color || "#991616" }}
              >
                <h3 className="font-semibold mb-2 text-sm sm:text-base">
                  Key Concepts
                </h3>
                <div className="flex flex-wrap gap-2">
                  {unitData.keyConcepts &&
                    unitData.keyConcepts.map((concept, index) => (
                      <span
                        key={index}
                        className="inline-block px-2 sm:px-3 py-1 text-xs sm:text-sm rounded-full text-white"
                        style={{ backgroundColor: unit.color || "#991616" }}
                      >
                        {concept}
                      </span>
                    ))}
                </div>
              </div>
            </div>

            {/* Learning outcomes */}
            <div className="mb-6">
              <h2 className="text-lg sm:text-xl font-semibold mb-2">
                Learning Outcomes
              </h2>
              <ul className="space-y-3">
                {unitData.outcomes &&
                  unitData.outcomes.map((outcome, index) => {
                    const outcomeAudioKey = `outcomeAudio-${index}`;
                    const hasAudio = unitData[outcomeAudioKey] !== undefined;

                    return (
                      <li
                        key={index}
                        className="p-2 sm:p-3 border-l-4 rounded-r-lg flex items-start text-sm sm:text-base"
                        style={{
                          borderLeftColor: unit.color || "#991616",
                          backgroundColor: `${unit.color || "#991616"}10`,
                        }}
                      >
                        <div className="flex-1">
                          <p>{outcome}</p>

                          {/* Add audio for each outcome if available */}
                          {hasAudio && (
                            <div className="mt-2">
                              <AudioPlayer
                                audioPath={unitData[outcomeAudioKey]}
                                color={unit.color || "#991616"}
                                size="small"
                                label="Hear example"
                              />
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}

        {activeTab === "vowels" &&
          unitData.vowelsData &&
          unitData.vowelsData.length > 0 && (
            <div>
              <h2 className="text-lg sm:text-xl font-semibold mb-4">
                Vowels in {unitData.name}
              </h2>
              <div className="overflow-x-auto -mx-3 sm:mx-0">
                <table className="min-w-full border text-sm sm:text-base">
                  <thead>
                    <tr
                      className="text-white"
                      style={{ backgroundColor: unit.color || "#991616" }}
                    >
                      <th className="py-2 px-2 sm:px-4 border">Sound</th>
                      <th className="py-2 px-2 sm:px-4 border">How to Write</th>
                      <th className="py-2 px-2 sm:px-4 border">Example</th>
                      <th className="py-2 px-2 sm:px-4 border">Meaning</th>
                      <th className="py-2 px-2 sm:px-4 border">Listen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitData.vowelsData.map((vowel, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-gray-50" : ""}
                      >
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border font-medium">
                          {vowel.englishSound}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          {vowel.howToWrite}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          {vowel.example}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          {vowel.meaning}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          <AudioPlayer
                            audioPath={vowel.audioSrc}
                            color={unit.color || "#991616"}
                            size="small"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

        {activeTab === "consonants" &&
          unitData.consonantsData &&
          unitData.consonantsData.length > 0 && (
            <div>
              <h2 className="text-lg sm:text-xl font-semibold mb-4">
                Consonants in {unitData.name}
              </h2>
              <div className="overflow-x-auto -mx-3 sm:mx-0">
                <table className="min-w-full border text-sm sm:text-base">
                  <thead>
                    <tr
                      className="text-white"
                      style={{ backgroundColor: unit.color || "#991616" }}
                    >
                      <th className="py-2 px-2 sm:px-4 border">Sound</th>
                      <th className="py-2 px-2 sm:px-4 border">How to Write</th>
                      <th className="py-2 px-2 sm:px-4 border">Example</th>
                      <th className="py-2 px-2 sm:px-4 border">Meaning</th>
                      <th className="py-2 px-2 sm:px-4 border">Listen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitData.consonantsData.map((consonant, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-gray-50" : ""}
                      >
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border font-medium">
                          {consonant.englishSound}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          {consonant.howToWrite}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          {consonant.example}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          {consonant.meaning}
                        </td>
                        <td className="py-2 sm:py-3 px-2 sm:px-4 border">
                          <AudioPlayer
                            audioPath={consonant.audioSrc}
                            color={unit.color || "#991616"}
                            size="small"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

        {activeTab === "clicks" &&
          unitData.clicksData &&
          unitData.clicksData.length > 0 && (
            <div>
              <h2 className="text-lg sm:text-xl font-semibold mb-4">
                Click Sounds in {unitData.name}
              </h2>
              <div className="space-y-4 sm:space-y-6">
                {unitData.clicksData.map((click, index) => (
                  <div
                    key={index}
                    className="border-2 rounded-lg p-3 sm:p-4"
                    style={{ borderColor: unit.color || "#991616" }}
                  >
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2 sm:gap-0 mb-3">
                      <div>
                        <h3 className="text-base sm:text-lg font-bold">
                          {click.englishSound} Sound
                        </h3>
                        <p className="text-xs sm:text-sm text-gray-600">
                          Written as: {click.howToWrite}
                        </p>
                      </div>
                      <AudioPlayer
                        audioPath={click.audioSrc}
                        color={unit.color || "#991616"}
                        size="medium"
                        label="Listen"
                      />
                    </div>

                    <div className="mb-3">
                      <h4 className="font-medium text-sm sm:text-base">
                        How to pronounce:
                      </h4>
                      <p className="text-sm sm:text-base">
                        {click.howExplanation}
                      </p>
                    </div>

                    <div className="bg-gray-50 p-2 sm:p-3 rounded text-sm sm:text-base">
                      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2 sm:gap-0">
                        <div>
                          <span className="font-medium">Example:</span>{" "}
                          {click.example}
                        </div>
                        <div className="mt-1 sm:mt-0">
                          <span className="font-medium">Meaning:</span>{" "}
                          {click.meaning}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </motion.div>
    </div>
  );
};

export default UnitOverview;
