import React, { useState } from "react";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import Register from "../login-register/register.component.jsx";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";

function OnboardingStepFive({ onNext }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Added loading state

  const handleContinue = () => {
    setLoading(true); // Set loading state to true
    // Simulate 2-second delay
    setTimeout(async () => {
      navigate("/dashboard");
      setLoading(false); // Reset loading state
    }, 1500);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
      className="flex flex-col h-full bg-white"
    >
      <div className="flex flex-col justify-center items-center flex-grow">
        <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl">
          👩‍🎨📝
        </span>
        <h1 className="text-center font-headers font-semibold bg-white text-black text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mt-4">
          Create your profile
        </h1>
        <div className="w-full" data-testid="register-component">
          <Register />
        </div>
        <div className="border-t-2 border-darkgray min-h-[90px] w-full">
          <div className="flex flex-row justify-between mx-auto w-2/3">
            <button
              data-testid="continue-button"
              className={
                loading
                  ? "w-36 h-12 mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 border-2 border-b-4 text-gray font-bold hover:text-gray border-otherwhite rounded"
                  : "w-36 h-12 mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 border-2 border-b-4 text-green font-bold hover:text-white border-green hover:border-green hover:bg-green rounded"
              }
              disabled={loading} // Disable if loading
              onClick={handleContinue} // Call the handleContinue function
            >
              {loading ? (
                <div className="px-8">
                  <BeatLoader color={"#848717"} loading={loading} size={8} />
                </div>
              ) : (
                "Continue"
              )}{" "}
              {/* Show loading text if loading */}
            </button>
          </div>
          <div className="hidden flex flex-row justify-between w-2/3 m-auto">
            <Link to="/dashboard">
              <button
                className="mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded"
                onClick={() => {
                  onNext();
                }}
              >
                Continue
              </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default OnboardingStepFive;
