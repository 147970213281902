import React, { useState, useEffect } from "react";
import axios from "axios";
import { MD5 } from "crypto-js";
import { db } from "../../firebase/firebase.utils";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../../helpers/UserAuthContextProvider";

function SubscriptionsCard() {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState("");
  const [subscriptionToken, setSubscriptionToken] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const merchantId = process.env.REACT_APP_PAYFAST_MERCHANT_ID;
  const version = "v1";
  const passphrase = process.env.REACT_APP_PAYFAST_SECURITY_PASSPHRASE;

  const handleCancellation = async () => {
    const onboardingRef = doc(db, "users", `${user?.uid}`);
    try {
      await updateDoc(onboardingRef, {
        membership: "george",
        email: user?.email,
      });
      await user?.reload();
      await user?.getIdToken(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getSubscriptionToken = async () => {
      if (user) {
        const docRef = doc(db, "users", user?.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const token = docSnap.data().membershipToken;
          setSubscriptionToken(token);
        } else {
          console.log("No such document!");
        }
      }
    };
    getSubscriptionToken();
  }, [user?.uid, subscriptionToken, user]);

  const createSignature = (timestamp) => {
    const params = {
      "merchant-id": merchantId,
      timestamp: timestamp,
      token: subscriptionToken,
      version: "v1",
    };

    // Convert the params object to an array of key-value pairs
    const paramArray = Object.entries(params);

    // Sort the array by the keys (i.e., the first element of each pair)
    const sortedParamArray = paramArray.sort((a, b) => {
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      return 0;
    });

    // Convert the sorted array back into a string
    let paramString = sortedParamArray
      .map((pair) => `${pair[0]}=${encodeURIComponent(pair[1])}`)
      .join("&");

    paramString += `&passphrase=${encodeURIComponent(passphrase)}`;

    const signature = MD5(paramString).toString();
    return signature;
  };

  function getCurrentTimestamp() {
    const date = new Date();
    let timestamp = date.toISOString().split(".")[0];

    // Extract the timezone offset in minutes
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneSign = timezoneOffset < 0 ? "+" : "-";
    const timezoneHours = String(
      Math.floor(Math.abs(timezoneOffset) / 60)
    ).padStart(2, "0");
    const timezoneMinutes = String(Math.abs(timezoneOffset) % 60).padStart(
      2,
      "0"
    );

    // Append the timezone offset to the timestamp
    timestamp += timezoneSign + timezoneHours + ":" + timezoneMinutes;
    return timestamp;
  }

  const viewSubscription = () => {
    setLoading(true);
    const timestamp = getCurrentTimestamp();
    const signature = createSignature(timestamp);

    user
      .getIdToken(true)
      .then((idToken) => {
        const headers = {
          Authorization: `Bearer ${idToken}`,
          "merchant-id": merchantId,
          signature: signature,
          timestamp: timestamp,
          version: version,
        };

        return axios.get(
          "https://us-central1-ffa-beta.cloudfunctions.net/payfastSubscriptions/fetchSubscription",
          { headers, withCredentials: true }
        );
      })
      .then((response) => {
        setSubscription(response);
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        setError("An error occurred while fetching your subscription info.");
        setLoading(false);
        if (err && err.response) {
          console.log(err.response); // Detailed error information from the server
        }
      });
  };

  const cancelSubscription = () => {
    setLoading(true);
    const timestamp = getCurrentTimestamp();
    const signature = createSignature(timestamp);

    user
      .getIdToken(true)
      .then((idToken) => {
        const headers = {
          Authorization: `Bearer ${idToken}`,
          "merchant-id": merchantId,
          signature: signature,
          timestamp: timestamp,
          version: version,
        };

        return axios.put(
          "https://us-central1-ffa-beta.cloudfunctions.net/payfastSubscriptions/cancelSubscription",
          {},
          { headers: headers }
        );
      })
      .then((response) => {
        setSubscription(response);
        setLoading(false);
        handleCancellation();
      })
      .catch((err) => {
        setError("An error occurred while fetching the subscription.");
        setLoading(false);
        if (err && err.response) {
          console.log(err.response); // Detailed error information from the server
        }
      });
  };

  return (
    <div
      id="subscriptions"
      className="flex flex-col justify-center items-center mt-4 mb-8 bg-white"
    >
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="text-left w-full font-bold font-headers text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Subscriptions
        </h1>
        <div className="bg-white w-full">
          <p className="text-sm sm:text-sm md:text-base lg:text-lg">
            Manage your subscriptions here.
          </p>

          <div className="w-full mt-6 p-4 sm:p-6 border-2 border-b-4 rounded-lg">
            {!subscription ? (
              <div className="flex justify-center">
                <button
                  onClick={viewSubscription}
                  className="text-sm md:text-base w-full sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-1/2 pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer
                                font-bold hover:text-white border-green bg-white hover:border-green hover:bg-green text-black
                                leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none 
                                focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <span className="mr-2">📑</span>View Subscription
                </button>
              </div>
            ) : null}

            {loading && (
              <div className="flex justify-center my-4">
                <BeatLoader color={"#848717"} loading={loading} size={8} />
              </div>
            )}

            {!subscription && error && (
              <div className="text-red text-center my-4 text-sm md:text-base">
                {error}
              </div>
            )}

            {subscription && (
              <div className="w-full">
                <h1 className="font-headers font-bold my-2 text-lg md:text-xl">
                  Current Plan
                </h1>
                <hr className="mb-4" />

                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
                  <h3 className="font-semibold text-base md:text-lg mb-2">
                    {subscription && subscription.data.amount === 24700
                      ? "Mabena Membership (Monthly)"
                      : "Mabena Membership (Annual)"}
                  </h3>

                  <h2 className="font-bold text-lg md:text-xl mb-3">
                    ZAR{" "}
                    {subscription && subscription.data.amount === 24700
                      ? `${(subscription.data.amount / 100).toFixed(
                          2
                        )} per month`
                      : `${(subscription.data.amount / 100).toFixed(
                          2
                        )} per year`}
                  </h2>

                  <p className="text-sm md:text-base mb-2">
                    Your subscription will be billed on{" "}
                    <b>
                      {subscription &&
                        subscription.data.run_date &&
                        subscription.data.run_date.split("T")[0]}
                    </b>
                  </p>

                  <p className="text-sm md:text-base">
                    This plan is currently{" "}
                    <span
                      className={
                        subscription.data.status_text === "ACTIVE"
                          ? "text-green font-bold"
                          : "text-red font-bold"
                      }
                    >
                      {subscription && subscription.data.status_text}
                    </span>
                  </p>
                </div>

                <div className="flex flex-col space-y-4">
                  <button
                    onClick={() => {
                      setSubscription(false);
                      setShowConfirm(false);
                    }}
                    className="text-sm md:text-base w-full sm:w-4/5 md:w-3/4 mx-auto pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer
                              font-bold hover:text-white border-green bg-white hover:border-green hover:bg-green text-black
                              leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none 
                              focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <span className="mr-2">🙈</span>Hide Subscription
                  </button>

                  <button
                    onClick={() => setShowConfirm(true)}
                    className="text-sm md:text-base w-full sm:w-4/5 md:w-3/4 mx-auto pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer
                              font-bold hover:text-white border-red bg-white hover:border-red hover:bg-red text-black
                              leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none 
                              focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <span className="mr-2">❌</span>Cancel Subscription
                  </button>

                  {showConfirm && (
                    <div className="p-4 bg-red bg-opacity-10 border-2 border-red rounded-lg mt-2">
                      <p className="font-bold text-red text-sm md:text-base mb-3 text-center">
                        Are you sure you want to cancel your subscription? This
                        action is irreversible!
                      </p>

                      <button
                        onClick={cancelSubscription}
                        className="text-sm md:text-base w-full sm:w-4/5 md:w-3/4 mx-auto pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer
                                font-bold text-white border-red bg-red
                                leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none 
                                focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                      >
                        <span className="mr-2">⚠️</span>Yes, Cancel My
                        Subscription
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsCard;
