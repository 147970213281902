// src/helpers/MilestoneProvider.jsx

import React, { createContext, useState, useEffect, useContext } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import { useAuth } from "./UserAuthContextProvider";
import { UnitsSocialData } from "../zombiedata/units-social";

// Create a new context for milestone management
export const MilestoneContext = createContext();

export const MilestoneProvider = ({ children }) => {
  const [milestone, setMilestone] = useState("George"); // Default value
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  // Initialize milestone from Firestore
  useEffect(() => {
    console.log("[MilestoneProvider] Initializing...");
    let isMounted = true;

    const initializeMilestone = async () => {
      if (!user?.uid) {
        console.log("[MilestoneProvider] No user, skipping initialization");
        if (isMounted) setLoading(false);
        return;
      }

      try {
        console.log(
          `[MilestoneProvider] Fetching milestone for user: ${user.uid}`
        );
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          const storedMilestone = userData.currentEnroll?.milestone || "George";

          console.log(
            `[MilestoneProvider] Retrieved milestone from Firebase: ${storedMilestone}`
          );

          // Validate the milestone - check for both isiZuluSocial and Social for backward compatibility
          const isValid = Object.values(UnitsSocialData).some(
            (course) => course[storedMilestone] !== undefined
          );

          if (isValid && isMounted && storedMilestone !== milestone) {
            console.log(
              `[MilestoneProvider] Using valid stored milestone: ${storedMilestone}`
            );
            setMilestone(storedMilestone);
          } else if (!isValid) {
            // Use default if stored value is invalid
            console.warn(
              `[MilestoneProvider] Invalid milestone: ${storedMilestone}, using default`
            );
            if (isMounted && milestone !== "George") {
              setMilestone("George");
            }

            // Update Firestore with the default value
            await setDoc(
              docRef,
              {
                currentEnroll: {
                  ...(userData.currentEnroll || {}),
                  milestone: "George",
                },
              },
              { merge: true }
            );
          }
        } else {
          console.log(
            "[MilestoneProvider] User document doesn't exist, creating with default"
          );
          if (isMounted && milestone !== "George") {
            setMilestone("George");
          }

          await setDoc(
            docRef,
            {
              currentEnroll: {
                milestone: "George",
              },
            },
            { merge: true }
          );
        }
      } catch (err) {
        console.error("[MilestoneProvider] Error initializing milestone:", err);
        setError(err.message);
        // Always ensure a fallback value even if there's an error
        if (isMounted && milestone !== "George") {
          setMilestone("George");
        }
      } finally {
        console.log("[MilestoneProvider] Initialization complete");
        if (isMounted) setLoading(false);
      }
    };

    // Run initialization when component mounts or user changes
    initializeMilestone();

    // Cleanup to prevent state updates after unmount
    return () => {
      isMounted = false;
    };
  }, [user]);

  // Function to update milestone (both state and Firestore)
  const updateMilestone = async (newMilestone) => {
    // Prevent unnecessary updates
    if (newMilestone === milestone) {
      console.log(
        `[MilestoneProvider] Milestone already set to ${newMilestone}, skipping update`
      );
      return;
    }

    if (!user?.uid) {
      console.error(
        "[MilestoneProvider] Cannot update milestone: No authenticated user"
      );
      return;
    }

    try {
      console.log(`[MilestoneProvider] Updating milestone to: ${newMilestone}`);
      setLoading(true);

      // Validate the new milestone
      const isValid = Object.values(UnitsSocialData).some(
        (course) => course[newMilestone] !== undefined
      );

      if (!isValid) {
        throw new Error(`Invalid milestone: ${newMilestone}`);
      }

      // Update state
      setMilestone(newMilestone);

      // Update Firestore
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.exists() ? docSnap.data() : {};

      await setDoc(
        docRef,
        {
          currentEnroll: {
            ...(userData.currentEnroll || {}),
            milestone: newMilestone,
            course: "isiZuluSocial", // Ensure the course is set correctly
          },
        },
        { merge: true }
      );

      console.log(
        "[MilestoneProvider] Milestone updated successfully in Firestore"
      );
    } catch (err) {
      console.error("[MilestoneProvider] Error updating milestone:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Provide milestone state and update function
  const contextValue = {
    milestone,
    setMilestone: updateMilestone,
    loading,
    error,
  };

  console.log(
    `[MilestoneProvider] Current milestone: ${milestone}, loading: ${loading}`
  );

  return (
    <MilestoneContext.Provider value={contextValue}>
      {children}
    </MilestoneContext.Provider>
  );
};

// Custom hook to use milestone context
export const useMilestone = () => {
  const context = useContext(MilestoneContext);
  if (context === undefined) {
    throw new Error("useMilestone must be used within a MilestoneProvider");
  }
  return context;
};
