import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalContext } from "../../helpers/modalcontext.js";
import QuizMultimode from "../../components/vault/quiz/quiz-multimode.jsx";
import { motion } from "framer-motion";
import { LMContext } from "../../helpers/context3.js";
import BeatLoader from "react-spinners/BeatLoader";

/**
 * Dedicated page for practice mode without navigation bar
 */
function PracticePage() {
  const { modal, setModal } = useContext(ModalContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract state from location
  const { learningMaterialId } = location.state || {};

  // Redirect if no data is present
  useEffect(() => {
    if (!learningMaterialId) {
      navigate("/units");
    }
    document.title = "Practice | Fast Fluency Africa";
  }, [learningMaterialId, navigate]);

  // Show loading if no data available
  if (!learningMaterialId) {
    return (
      <div className="h-screen flex items-center justify-center">
        <BeatLoader color="#848717" size={15} />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
      className="bg-white min-h-screen"
    >
      {/* Quiz content - pass skipConfirmation to prevent double modal */}
      <ModalContext.Provider value={{ modal, setModal }}>
        <LMContext.Provider value={{ learningMaterialId }}>
          {/* Force skipConfirmation to true to prevent double modal */}
          <QuizMultimode mode="practice" skipConfirmationProp={true} />
        </LMContext.Provider>
      </ModalContext.Provider>
    </motion.div>
  );
}

export default PracticePage;
