import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";

import NewsFeedCard from "../../components/cards/news-feed-card.component.jsx";
import CurrentEnrolmentsCard from "../../components/cards/current-enrolments.component.jsx";
import RegistrationCard from "../../components/cards/register-card.component.jsx";
import InviteFriends from "../../components/referrals/inviteFriends.component.jsx";
import BetaCard from "../../components/cards/beta-version-card.component.jsx";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";
import ProgressOverview from "../../components/analytics/progress-overview.jsx";

import { db } from "../../firebase/firebase.utils.js";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";

import { motion } from "framer-motion";
import PuffLoader from "react-spinners/PuffLoader";

import Joyride, { STATUS } from "react-joyride";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";

import FloatingActionMenu from "../../components/navigation-bar-2/floating-side-menu.jsx";

function Dashboard() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [completedWalkthrough, setCompletedWalkthrough] = useState(false);
  const [run, setRun] = useState(null);
  const [membership, setMembership] = useState(null);

  // Handle onboarding completion
  const handleOnboardingUpdate = async () => {
    if (!user?.uid) return;

    const onboardingRef = doc(db, "users", `${user.uid}`);
    try {
      await updateDoc(onboardingRef, {
        dashboardOnboardingCompleted: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Check if user has completed onboarding
  useEffect(() => {
    if (!user?.uid) return;

    const userDoc = doc(db, "users", `${user.uid}`);
    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setCompletedWalkthrough(
          docSnapshot.data().dashboardOnboardingCompleted
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  // Get membership status
  useEffect(() => {
    if (!user?.uid) return;

    const userDoc = doc(db, "users", `${user.uid}`);
    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setMembership(docSnapshot.data().membership);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  // Set document title
  useEffect(() => {
    document.title = "Home | Fast Fluency Africa";
  }, []);

  // Loading animation
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Joyride tour steps
  const [steps] = useState([
    {
      target: "#news",
      content:
        "Stay up to date with announcements, promotions, and other major developments whenever we release new features.",
    },
    {
      target: "#current-enrollments",
      content:
        "View the latest lessons that you have yet to complete and easily get back to mastery.",
    },
    {
      target: "#invite-friends",
      content:
        "Use this personal referral link we generated just for you to invite your friends and family.",
    },
    {
      target: "#ffaqs",
      content:
        "If you need a bit of guidance, click on this emoji to easily access various resources that will help you get the most out of your app experience!",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      handleOnboardingUpdate();
    }
  };

  const startTour = () => {
    setRun(true);
  };

  if (loading) {
    return (
      <div
        data-testid="loading-spinner"
        className="flex justify-center items-center h-screen"
      >
        <PuffLoader color={"#991616"} loading={loading} size={100} />
      </div>
    );
  }

  return (
    <div className="bg-white min-h-screen">
      {/* Navigation components - Always visible */}
      <div className="bg-white">
        <NavigationBar />

        {/* Floating Action Menu */}
        <motion.div
          className="fixed z-50 right"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.75 }}
        >
          <FloatingActionMenu
            showFlashcards={false}
            showStats={false}
            additionalButtons={[]}
          />
        </motion.div>
        <FeedbackMenu />
      </div>

      {/* Content area */}
      <motion.div
        className="flex flex-col items-center bg-white w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <div className="w-11/12 sm:w-5/6 md:w-4/5 mx-auto pb-8">
          {/* Registration Card - Only show for guests */}
          {user && membership === "guest" && (
            <div id="sign-up" className="mb-4">
              <RegistrationCard />
            </div>
          )}

          {/* Beta Card */}
          <div className="mb-4">
            <BetaCard />
          </div>

          {/* News Section */}
          <h2 className="text-lg sm:text-xl md:text-2xl font-headers font-bold mb-2">
            News
          </h2>
          <div data-testid="news-feed" id="news" className="mb-6">
            <NewsFeedCard />
          </div>

          {/* Progress Overview */}
          <h2 className="text-lg sm:text-xl md:text-2xl font-headers font-bold mb-2">
            Progress Overview
          </h2>
          <div className="mb-6">
            <ProgressOverview />
          </div>

          {/* Current Enrollments */}
          <h2 className="text-lg sm:text-xl md:text-2xl font-headers font-bold mb-2">
            Current Enrolments
          </h2>
          <div id="current-enrollments" className="mb-6">
            <CurrentEnrolmentsCard />
          </div>

          {/* Invite Friends */}
          <h2 className="text-lg sm:text-xl md:text-2xl font-headers font-bold mb-2">
            Invite friends and family
          </h2>
          <div id="invite-friends" className="mb-8">
            <InviteFriends />
          </div>
        </div>
      </motion.div>

      {/* Joyride tour */}
      <Joyride
        data-testid="joyride"
        steps={steps}
        run={run}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#848717",
            backgroundColor: "#FCFCEE",
            overlayColor: "rgba(79, 26, 0, 0.1)",
            primaryColor: "#000",
            textColor: "#000000",
            width: 300, // Smaller width on mobile
            zIndex: 1000,
            fontFamily: "Nunito",
          },
        }}
      />
    </div>
  );
}

export default Dashboard;
