// firebase/bookings-api.js
import { auth, db } from "../firebase/firebase.utils";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";

// Base URL for your cloud function (modify as needed)
const CLOUD_FUNCTION_BASE_URL = "https://bookings-fmxsydetiq-uc.a.run.app"; // For Cloud Run
// const CLOUD_FUNCTION_BASE_URL = 'https://us-central1-ffa-beta.cloudfunctions.net'; // For Firebase Functions

// For direct Firestore operations (local)
const updateBookingStatus = async (
  bookingId,
  newStatus,
  additionalData = {}
) => {
  try {
    const bookingRef = doc(db, "bookings", bookingId);

    await updateDoc(bookingRef, {
      status: newStatus,
      updatedAt: serverTimestamp(),
      ...additionalData,
    });

    return { success: true };
  } catch (error) {
    console.error("Error updating booking status:", error);
    throw error;
  }
};

// Make a direct HTTP call to your cloud function (more reliable for CORS)
const callCloudFunctionDirectly = async (
  functionName,
  data,
  method = "POST"
) => {
  try {
    // Get Firebase Auth token
    const user = auth.currentUser;
    if (!user) {
      throw new Error("Not authenticated");
    }

    const idToken = await user.getIdToken();

    // Create URL with query params for GET requests
    let url = `${CLOUD_FUNCTION_BASE_URL}/${functionName}`;
    if (method === "GET" && data) {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(data)) {
        params.append(key, value);
      }
      url += `?${params.toString()}`;
    }

    // Make the API call
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: method === "GET" ? undefined : JSON.stringify(data),
    });

    // Check for errors
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Error from cloud function ${functionName}:`, errorText);
      throw new Error(`HTTP error ${response.status} - ${errorText}`);
    }

    // Parse and return the response
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(`Cloud function call failed: ${functionName}`, error);
    throw error;
  }
};

// Fallback to Firebase SDK if direct HTTP fails
const callCloudFunction = async (functionName, data, method = "POST") => {
  try {
    // First try direct HTTP call (more reliable for CORS)
    return await callCloudFunctionDirectly(functionName, data, method);
  } catch (error) {
    console.warn(
      `Direct call failed, falling back to Firebase SDK for ${functionName}`,
      error
    );

    // Fall back to Firebase SDK
    const functions = getFunctions();
    const callable = httpsCallable(functions, functionName);
    const result = await callable(data);
    return result.data;
  }
};

/**
 * Verify the status of a PayFast payment
 * This is used by the client when returning from the PayFast payment page
 * @param {string} bookingId - The ID of the booking to verify
 * @returns {Promise<object>} - Payment verification result
 */
export const verifyPayfastPayment = async (bookingId) => {
  try {
    console.log("Verifying PayFast payment for booking:", bookingId);

    // Call the new endpoint
    const result = await callCloudFunction("verifyPayfastPayment", {
      bookingId,
    });

    console.log("PayFast verification result:", result);
    return result;
  } catch (error) {
    console.error("Error verifying PayFast payment:", error);
    throw error;
  }
};

/**
 * Enhanced version of initiatePayfastPayment that captures more data
 * @param {string} coachId - Coach user ID
 * @param {string} availabilityId - Availability slot ID
 * @returns {Promise<object>} - Payment URL and booking ID
 */
/**
 * Initiate a PayFast payment for a coaching session
 * @param {string} coachId - Coach user ID
 * @param {string} availabilityId - Availability slot ID
 * @returns {Promise<object>} - Payment URL and booking ID
 */
/**
 * Initiate a PayFast payment for a coaching session
 * @param {string} coachId - Coach user ID
 * @param {string} availabilityId - Availability slot ID
 * @returns {Promise<object>} - Payment URL and booking ID
 */
export const initiatePayfastPayment = async (coachId, availabilityId) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("You must be logged in to book a session");
  }

  try {
    console.log("Initiating PayFast payment for:", { coachId, availabilityId });

    // Get slot details to determine duration and price
    const availabilityRef = doc(
      db,
      "users",
      coachId,
      "availability",
      availabilityId
    );
    const availabilityDoc = await getDoc(availabilityRef);

    if (!availabilityDoc.exists()) {
      throw new Error("Availability slot not found");
    }

    const slotData = availabilityDoc.data();
    console.log("Slot data:", slotData);

    // Calculate session duration in minutes
    const startTime = slotData.startDateTime.toDate();
    const endTime = slotData.endDateTime.toDate();
    const duration = (endTime - startTime) / (1000 * 60);

    // Test price of R3, will be changed to R200 for production
    const amount = 3; // R3 test amount

    // Get authentication token
    const idToken = await user.getIdToken();

    // Call the integrated endpoint in bookinghandler function
    const response = await fetch(
      "https://bookings-fmxsydetiq-uc.a.run.app/createPayfastPayment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          coachId,
          availabilityId,
          sessionDateTime: slotData.startDateTime.toDate().toISOString(),
          duration,
          amount,
        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Payment API error:", errorText);
      throw new Error(`Payment failed: ${errorText}`);
    }

    const result = await response.json();
    console.log("Payment API result:", result);

    if (result.success) {
      // Store booking ID in session storage before redirecting
      try {
        sessionStorage.setItem("pendingBookingId", result.bookingId);
        console.log(
          "Stored pendingBookingId in session storage:",
          result.bookingId
        );
      } catch (storageError) {
        console.error(
          "Error storing bookingId in sessionStorage:",
          storageError
        );
      }

      return {
        success: true,
        bookingId: result.bookingId,
        paymentUrl: result.paymentUrl,
      };
    } else {
      throw new Error(result.error || "Failed to create payment");
    }
  } catch (error) {
    console.error("Initiate payment error:", error);
    throw error;
  }
};

/**
 * Check the status of a pending payment
 * @param {string} bookingId - Booking ID
 * @returns {Promise<object>} - Booking status info
 */
export const checkPaymentStatus = async (bookingId) => {
  try {
    // Get the booking document directly
    const bookingRef = doc(db, "bookings", bookingId);
    const bookingDoc = await getDoc(bookingRef);

    if (!bookingDoc.exists()) {
      throw new Error("Booking not found");
    }

    const bookingData = bookingDoc.data();

    return {
      success: true,
      status: bookingData.status,
      paymentMethod: bookingData.paymentMethod,
      meetingLink: bookingData.meetingLink || null,
    };
  } catch (error) {
    console.error("Check payment status error:", error);
    throw error;
  }
};

/**
 * Book a session with a coach
 * This function now defers to server for meeting creation
 *
 * @param {string} coachId - Coach's user ID
 * @param {string} availabilityId - ID of the availability slot being booked
 * @param {string} paymentMethod - 'coins' or 'rewards'
 * @returns {Promise<object>} - Booking confirmation with meeting details
 */
export const bookSession = async (
  coachId,
  availabilityId,
  paymentMethod = "coins"
) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("You must be logged in to book a session");
  }

  try {
    // Call the cloud function to book the session
    const result = await callCloudFunction("bookSession", {
      coachId,
      availabilityId,
      paymentMethod,
    });

    if (!result.success) {
      throw new Error(result.error || "Failed to book session");
    }

    return result;
  } catch (error) {
    // Handle error and refund points/coins if needed
    console.error("Book session error:", error);

    // Attempt to refund the user's points/coins
    if (paymentMethod === "rewards") {
      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const hourlyRate = 60; // Default hourly rate in points
          await updateDoc(userRef, {
            rewardPoints: userDoc.data().rewardPoints + hourlyRate,
          });
        }
      } catch (refundError) {
        console.error("Error refunding reward points:", refundError);
      }
    }

    throw error;
  }
};

// Other API functions remain the same...
export const cancelBooking = async (bookingId) => {
  try {
    // Call the cloud function to cancel the booking
    const result = await callCloudFunction("cancelBooking", {
      bookingId,
    });

    if (!result.success) {
      throw new Error(result.error || "Failed to cancel booking");
    }

    return result;
  } catch (error) {
    console.error("Cancel booking error:", error);
    throw error;
  }
};

export const completeSession = async (bookingId) => {
  try {
    // Call the cloud function to complete the session
    const result = await callCloudFunction("completeSession", {
      bookingId,
    });

    if (!result.success) {
      throw new Error(result.error || "Failed to mark session as completed");
    }

    return result;
  } catch (error) {
    console.error("Complete session error:", error);
    throw error;
  }
};

export const rateSession = async (bookingId, rating, feedback = "") => {
  try {
    // Call the cloud function to rate the session
    const result = await callCloudFunction("rateSession", {
      bookingId,
      rating,
      feedback,
    });

    if (!result.success) {
      throw new Error(result.error || "Failed to rate session");
    }

    return result;
  } catch (error) {
    console.error("Rate session error with details:", {
      message: error.message,
      stack: error.stack,
      originalError: error,
    });
    throw error;
  }
};

// In bookings-api.js, enhance getUserBookings function to include user details
export const getUserBookings = async (role = null, status = null) => {
  try {
    // Build query parameters
    const params = {};
    if (role) params.role = role;
    if (status) params.status = status;

    // CHANGE THIS: Use POST instead of GET
    const result = await callCloudFunction("getUserBookings", params, "POST");

    // If there are bookings returned, enhance them with names
    const bookings = result.bookings || [];

    // Process bookings to ensure they have proper name fields
    if (bookings.length > 0) {
      const enhancedBookings = await Promise.all(
        bookings.map(async (booking) => {
          try {
            // For each booking, check if it already has studentName and coachName
            if (!booking.studentName && booking.studentId) {
              // If not, fetch the student info
              const studentDoc = await getDoc(
                doc(db, "users", booking.studentId)
              );
              if (studentDoc.exists()) {
                const studentData = studentDoc.data();
                booking.studentName =
                  studentData.name || studentData.displayName || "Student";
              }
            }

            if (!booking.coachName && booking.coachId) {
              // Also fetch the coach info
              const coachDoc = await getDoc(doc(db, "users", booking.coachId));
              if (coachDoc.exists()) {
                const coachData = coachDoc.data();
                booking.coachName =
                  coachData.name || coachData.displayName || "Coach";
              }
            }

            // Return the enhanced booking
            return booking;
          } catch (error) {
            console.error(`Error enhancing booking ${booking.id}:`, error);
            return booking; // Return original booking if enhancement fails
          }
        })
      );

      return enhancedBookings;
    }

    return bookings;
  } catch (error) {
    console.error("Get user bookings error:", error);
    // ENHANCEMENT: Instead of throwing, return an empty array to avoid crashing
    return [];
  }
};

export const getCoachAvailability = async (
  coachId,
  startDate = null,
  endDate = null
) => {
  try {
    // Build query parameters
    const params = { coachId };
    if (startDate) params.startDate = startDate.toISOString();
    if (endDate) params.endDate = endDate.toISOString();

    // This should be a GET request
    const result = await callCloudFunction(
      "getCoachAvailability",
      params,
      "GET"
    );

    return result.availableSlots || [];
  } catch (error) {
    console.error("Get coach availability error:", error);
    throw error;
  }
};

export const getCoachProfile = async (coachId) => {
  try {
    // Build query parameters
    const params = { coachId };

    // This should be a GET request
    const result = await callCloudFunction("getCoachProfile", params, "GET");

    return result.profile || null;
  } catch (error) {
    console.error("Get coach profile error:", error);
    throw error;
  }
};

export const getCoaches = async (language = null, minRating = null) => {
  try {
    // Build query parameters
    const params = {};
    if (language) params.language = language;
    if (minRating) params.minRating = minRating;

    // This should be a GET request
    const result = await callCloudFunction("getCoaches", params, "GET");

    return result.coaches || [];
  } catch (error) {
    console.error("Get coaches error:", error);
    throw error;
  }
};

// Add this function to your bookings-api.js file, replacing any existing version
export const createZoomMeetingForBooking = async (bookingId) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("You must be logged in to create a meeting");
  }

  try {
    console.log("Creating Zoom meeting for booking:", bookingId);

    // Get Firebase Auth token
    const idToken = await user.getIdToken();

    // Try all possible URLs, in order of preference
    const possibleUrls = [
      `${CLOUD_FUNCTION_BASE_URL}/bookinghandler/createZoomMeeting`,
      `https://us-central1-ffa-beta.cloudfunctions.net/bookinghandler/createZoomMeeting`,
      `https://bookings-14534914762.us-central1.run.app/createZoomMeeting`,
      `https://createpayfastpayment-fmxsydetiq-uc.a.run.app/createZoomMeeting`,
    ];

    let lastError = null;

    // Try each URL in sequence until one works
    for (const url of possibleUrls) {
      try {
        console.log("Trying URL:", url);
        // Make sure to use the exact parameter name the server expects
        const requestBody = { bookingId: bookingId };
        console.log("Sending request with body:", JSON.stringify(requestBody));

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
            Accept: "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        // Check for successful response
        if (response.ok) {
          const result = await response.json();
          console.log("Successfully created Zoom meeting:", result);
          return result;
        }

        // Store error for later if not successful
        const errorText = await response.text();
        lastError = new Error(`HTTP error ${response.status}: ${errorText}`);
        console.warn(`Error from ${url}:`, errorText);
      } catch (error) {
        console.warn(`Failed to call ${url}:`, error);
        lastError = error;
        // Continue to next URL
      }
    }

    // If we get here, all URLs failed
    throw lastError || new Error("All API endpoints failed");
  } catch (error) {
    console.error("Failed to create Zoom meeting for booking:", error);
    throw error;
  }
};
