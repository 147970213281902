// Updated ExitModalDisplay component
import ExitModal from "../../modals/caution-exit-modal.component";

const ExitModalDisplay = ({ onExit }) => (
  <div className="display w-full h-screen absolute z-30">
    <ExitModal onExit={onExit} />
  </div>
);

export default ExitModalDisplay;
