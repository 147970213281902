// src/components/exercises/exercise-renderer.jsx
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ExerciseComponents from "./exercise-components";
import { ExerciseContainer } from "./exercise-layout";
import BeatLoader from "react-spinners/BeatLoader";

const ExerciseRenderer = ({ exerciseIndex, learningMaterial }) => {
  const [direction, setDirection] = useState(1); // 1 for right-to-left, -1 for left-to-right
  const [previousIndex, setPreviousIndex] = useState(exerciseIndex);
  const [loading, setLoading] = useState(true);

  // Display loader for initial load only
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // Track direction of transition based on index change
  useEffect(() => {
    if (exerciseIndex !== previousIndex) {
      // If we're moving forward in exercises or looping back to start
      if (
        exerciseIndex > previousIndex ||
        (previousIndex === learningMaterial.length - 1 && exerciseIndex === 0)
      ) {
        setDirection(1); // right to left (forward)
      } else {
        setDirection(-1); // left to right (backward)
      }
      setPreviousIndex(exerciseIndex);
    }
  }, [exerciseIndex, previousIndex, learningMaterial.length]);

  if (!learningMaterial || learningMaterial.length === 0) {
    return (
      <ExerciseContainer>
        <div className="text-red-500 p-4">No exercises available</div>
      </ExerciseContainer>
    );
  }

  const exerciseType = learningMaterial[exerciseIndex]?.type;
  const ExerciseComponent = ExerciseComponents[exerciseType];

  if (!ExerciseComponent) {
    return (
      <ExerciseContainer>
        <div className="text-red-500 p-4">
          Component not found for type: {exerciseType}
        </div>
      </ExerciseContainer>
    );
  }

  // Animation variants for page turning effect - reduced distance for mobile
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? window.innerWidth * 0.5 : window.innerWidth * -0.5, // 50% of screen width
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? window.innerWidth * -0.5 : window.innerWidth * 0.5, // 50% of screen width
      opacity: 0,
    }),
  };

  // If initial loading, show the loader
  if (loading) {
    return (
      <ExerciseContainer>
        <div className="flex justify-center items-center h-full">
          <BeatLoader color="#848717" size={12} />
        </div>
      </ExerciseContainer>
    );
  }

  // Wrap the exercise component with animation
  return (
    <ExerciseContainer>
      <AnimatePresence mode="wait" custom={direction}>
        <motion.div
          key={exerciseIndex}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 150, damping: 30 },
            opacity: { duration: 0.3 },
          }}
          className="w-full flex justify-center"
        >
          <ExerciseComponent
            key={`exercise-${exerciseIndex}`}
            data={learningMaterial[exerciseIndex]}
          />
        </motion.div>
      </AnimatePresence>
    </ExerciseContainer>
  );
};

export default ExerciseRenderer;
