import React from "react";
import { Link } from "react-router-dom";

function CreateProfileCard() {
  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-8">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="font-headers text-left mt-4 w-full font-bold text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Create An Account
        </h1>
        <p className="text-left w-full text-sm sm:text-sm md:text-base lg:text-lg">
          Sign up to access more detailed user settings.
        </p>

        <div className="w-full mt-6 flex justify-center">
          <Link
            to="/register"
            className="w-full sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-1/2"
          >
            <button
              type="button"
              className="w-full text-sm md:text-base pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer
                           font-bold hover:text-white border-green bg-white hover:border-green hover:bg-green text-black
                           leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none 
                           focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
            >
              Sign Up To Create An Account
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CreateProfileCard;
