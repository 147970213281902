import React, { useState, useEffect } from "react";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component";
import { useNotifications } from "../../helpers/NotificationsContext";
import { motion } from "framer-motion";
import ClearNotificationsUtility from "../../utils/notifications/clear-notifications-utils";
import FeedbackMenu from "../../components/support/feedback-menu";
import FloatingActionMenu from "../../components/navigation-bar-2/floating-side-menu";
import { PuffLoader } from "react-spinners";

const NotificationsPage = () => {
  const {
    notifications,
    markAsRead,
    markAllAsRead,
    deleteNotification,
    unreadCount,
  } = useNotifications();
  const [activeTab, setActiveTab] = useState("all"); // Options: 'all', 'unread'
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const handleNotificationClick = (notificationId) => {
    markAsRead(notificationId);
  };

  const handleDelete = (e, notificationId) => {
    e.stopPropagation();
    deleteNotification(notificationId);
  };

  const filteredNotifications =
    activeTab === "all"
      ? notifications
      : notifications.filter((notification) => !notification.read);

  // Animation variants
  const tabVariants = {
    active: {
      borderBottom: "2px solid #991616",
      color: "#991616",
      fontWeight: "bold",
    },
    inactive: {
      borderBottom: "2px solid transparent",
      color: "#808080",
      fontWeight: "normal",
    },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  // Show loading state
  if (loading) {
    return (
      <div
        data-testid="loading-spinner"
        className="flex flex-row justify-center items-center h-screen"
      >
        <PuffLoader color={"#991616"} loading={loading} size={100} />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <NavigationBar />
      <FloatingActionMenu />
      <FeedbackMenu />

      <div className="container mx-auto px-4 sm:px-6 py-6 sm:py-8 max-w-4xl">
        {/* Header - Responsive layout that stacks on mobile */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800">
            Notifications
          </h1>

          {/* Action buttons that wrap nicely on small screens */}
          <div className="flex flex-wrap gap-2 w-full sm:w-auto">
            {unreadCount > 0 && (
              <button
                className="flex-grow sm:flex-grow-0 px-3 py-2 bg-green border-black border-b-4 border-2 text-black rounded text-sm hover:bg-green/90 transition"
                onClick={markAllAsRead}
              >
                Mark all as read
              </button>
            )}
            <div className="flex-grow sm:flex-grow-0">
              <ClearNotificationsUtility />
            </div>
          </div>
        </div>

        {/* Tabs - Equal width on mobile */}
        <div className="flex border-b border-gray mb-6">
          <motion.button
            className="flex-1 sm:flex-none px-3 sm:px-4 py-2 sm:mr-4 text-sm sm:text-base"
            variants={tabVariants}
            animate={activeTab === "all" ? "active" : "inactive"}
            onClick={() => setActiveTab("all")}
          >
            All ({notifications.length})
          </motion.button>
          <motion.button
            className="flex-1 sm:flex-none px-3 sm:px-4 py-2 text-sm sm:text-base"
            variants={tabVariants}
            animate={activeTab === "unread" ? "active" : "inactive"}
            onClick={() => setActiveTab("unread")}
          >
            Unread ({unreadCount})
          </motion.button>
        </div>

        {/* Notifications List */}
        <motion.div
          className="space-y-3 sm:space-y-4"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {filteredNotifications.length === 0 ? (
            <div className="text-center py-8 text-gray">
              {activeTab === "all"
                ? "You don't have any notifications yet."
                : "You don't have any unread notifications."}
            </div>
          ) : (
            filteredNotifications.map((notification) => (
              <motion.div
                key={notification.id}
                className={`p-3 sm:p-4 rounded border-2 border-b-4 ${
                  !notification.read
                    ? "bg-darkgray/5 border-green"
                    : "border-darkgray"
                } hover:shadow-md transition cursor-pointer`}
                onClick={() => handleNotificationClick(notification.id)}
                variants={itemVariants}
              >
                <div className="flex items-start justify-between">
                  {/* Notification content with improved spacing for small screens */}
                  <div className="pr-2 flex-1">
                    <div className="flex items-center gap-2 flex-wrap">
                      <h3
                        className={`text-base sm:text-lg ${
                          !notification.read ? "font-bold" : "font-medium"
                        }`}
                      >
                        {notification.title}
                      </h3>
                      {!notification.read && (
                        <span className="w-2 h-2 bg-green rounded-full flex-shrink-0"></span>
                      )}
                    </div>

                    <p className="text-gray text-sm sm:text-base mt-1">
                      {notification.message}
                    </p>

                    {/* Metadata with improved layout */}
                    <div className="flex flex-wrap items-center mt-2 text-xs text-gray-500 gap-1 sm:gap-2">
                      <span>
                        {notification.createdAt.toLocaleDateString()} at{" "}
                        {notification.createdAt.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>

                      {notification.type && (
                        <span className="px-2 py-0.5 bg-darkgray/20 rounded-full text-xs">
                          {notification.type}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Delete button - increased touch target for mobile */}
                  <button
                    className="text-gray hover:text-red p-1 sm:p-2 flex-shrink-0"
                    onClick={(e) => handleDelete(e, notification.id)}
                    aria-label="Delete notification"
                  >
                    <span className="text-lg sm:text-xl">🗑️</span>
                  </button>
                </div>
              </motion.div>
            ))
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default NotificationsPage;
