import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils";
import { User, Star, Calendar, Filter, X } from "lucide-react";

const CoachDirectory = () => {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    language: "",
    minRating: 0,
    availability: false,
  });
  const [showFilters, setShowFilters] = useState(false); // For mobile filter panel

  const languageOptions = [
    { value: "", label: "All Languages" },
    { value: "isiZulu", label: "isiZulu" },
    { value: "Sesotho", label: "Sesotho" },
    { value: "Kiswahilli", label: "Kiswahilli" },
    { value: "Chichewa", label: "Chichewa" },
    { value: "Yoruba", label: "Yoruba" },
    { value: "Amharic", label: "Amharic" },
  ];

  useEffect(() => {
    fetchCoaches();
  }, []);

  const fetchCoaches = async () => {
    try {
      setLoading(true);

      // Get coaches
      const coachesRef = collection(db, "users");
      const q = query(coachesRef, where("role", "==", "coach"));
      const querySnapshot = await getDocs(q);

      const coachList = [];

      for (const docSnap of querySnapshot.docs) {
        const coachData = docSnap.data();

        // Get availability count
        const availabilityRef = collection(
          db,
          `users/${docSnap.id}/availability`
        );
        const availabilityQ = query(
          availabilityRef,
          where("bookable", "==", true)
        );
        const availabilitySnap = await getDocs(availabilityQ);

        coachList.push({
          id: docSnap.id,
          ...coachData,
          availabilityCount: availabilitySnap.size,
        });
      }

      setCoaches(coachList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching coaches:", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // Filter coaches based on criteria
  const filteredCoaches = coaches.filter((coach) => {
    // Filter by language
    if (
      filters.language &&
      coach.languages &&
      !coach.languages.includes(filters.language)
    ) {
      return false;
    }

    // Filter by rating
    if (
      filters.minRating > 0 &&
      (!coach.averageRating || coach.averageRating < filters.minRating)
    ) {
      return false;
    }

    // Filter by availability
    if (filters.availability && coach.availabilityCount === 0) {
      return false;
    }

    return true;
  });

  // Toggle mobile filter panel
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Reset filters
  const resetFilters = () => {
    setFilters({ language: "", minRating: 0, availability: false });
  };

  // Count active filters
  const activeFilterCount = Object.values(filters).filter(
    (value) => value !== false && value !== "" && value !== 0
  ).length;

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <div className="mb-6">
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2 sm:mb-4">
          Find a Language Coach
        </h1>
        <p className="text-gray text-sm sm:text-base">
          Browse our selection of professional language coaches and book a
          session.
        </p>
      </div>

      {/* Mobile filter toggle button */}
      <button
        onClick={toggleFilters}
        className="mb-4 lg:hidden px-4 py-2 bg-white border-2 border-darkgray rounded-md flex items-center justify-between w-full"
      >
        <span className="flex items-center">
          <span className="mr-2">🎚️</span>
          Filters {activeFilterCount > 0 && `(${activeFilterCount})`}
        </span>
        <span>{showFilters ? <X size={18} /> : "+"}</span>
      </button>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-8">
        {/* Filters - Desktop (sidebar) and Mobile (expandable panel) */}
        <div
          className={`${
            showFilters ? "block" : "hidden"
          } lg:block lg:col-span-1`}
        >
          <div className="bg-white rounded border-2 border-b-4 border-darkgray shadow-md p-4 mb-4 lg:mb-0">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <span className="mr-2">🎚️ </span>
                <h2 className="text-lg font-medium">Filters</h2>
              </div>
              {/* Mobile close button */}
              <button
                onClick={toggleFilters}
                className="p-1 rounded-full hover:bg-gray-100 lg:hidden"
              >
                <X size={20} />
              </button>
            </div>

            <div className="mb-4">
              <label className="block text-gray mb-2">Language</label>
              <select
                value={filters.language}
                onChange={(e) => handleFilterChange("language", e.target.value)}
                className="w-full p-2 border border-gray rounded-md"
              >
                {languageOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray mb-2">Minimum Rating</label>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4, 5].map((rating) => (
                  <button
                    key={rating}
                    type="button"
                    onClick={() => handleFilterChange("minRating", rating)}
                    className={`flex-1 ${
                      filters.minRating === rating ? "text-gold" : "text-gray"
                    }`}
                  >
                    {rating === 0 ? (
                      "Any"
                    ) : (
                      <span
                        className={
                          filters.minRating >= rating ? "" : "grayscale-100"
                        }
                      >
                        ⭐️
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>

            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.availability}
                  onChange={(e) =>
                    handleFilterChange("availability", e.target.checked)
                  }
                  className="rounded border-gray bg-white text-gray text-green focus:ring-green"
                />
                <span className="ml-2 text-gray">Has Availability</span>
              </label>
            </div>

            <button
              onClick={resetFilters}
              className="w-full px-4 text-gray py-2 border-2 border-b-4 border-gray rounded-md hover:bg-darkgray"
            >
              Reset Filters
            </button>

            {/* Mobile apply filters button */}
            <button
              onClick={toggleFilters}
              className="w-full mt-2 px-4 py-2 border-2 border-b-4 border-green text-green rounded-md hover:bg-green hover:bg-opacity-10 lg:hidden"
            >
              Apply Filters
            </button>
          </div>
        </div>

        <div className="lg:col-span-3">
          {loading ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green"></div>
            </div>
          ) : filteredCoaches.length === 0 ? (
            <div className="bg-white rounded-lg shadow-md p-8 text-center">
              <h3 className="text-lg font-medium mb-2">No coaches found</h3>
              <p className="text-gray">
                Try adjusting your filters to see more results.
              </p>
              <button
                onClick={resetFilters}
                className="mt-4 px-4 py-2 text-gray border-2 border-gray rounded-md hover:bg-darkgray"
              >
                Reset Filters
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {filteredCoaches.map((coach) => (
                <div
                  key={coach.id}
                  className="bg-white rounded border-2 border-b-4 border-darkgray shadow-md p-4 sm:p-6"
                >
                  <div className="flex mb-4">
                    <div className="h-14 w-14 sm:h-16 sm:w-16 bg-darkgray rounded-full border-2 border-b-4 border-darkgray flex items-center justify-center mr-3 sm:mr-4 flex-shrink-0">
                      {coach.photoURL ? (
                        <img
                          src={coach.photoURL}
                          alt={coach.displayName || coach.name}
                          className="h-full w-full rounded-full object-cover"
                        />
                      ) : (
                        <span className="text-gray">👤</span>
                      )}
                    </div>
                    <div className="min-w-0">
                      <h3 className="text-lg font-medium truncate">
                        {coach.displayName || coach.name}
                      </h3>
                      <div className="flex items-center mt-1">
                        {coach.averageRating ? (
                          <>
                            <div className="flex">
                              {[1, 2, 3, 4, 5].map((star) => (
                                <Star
                                  key={star}
                                  size={16}
                                  fill={
                                    coach.averageRating >= star
                                      ? "#d19a10"
                                      : "none"
                                  }
                                  color="#d19a10"
                                />
                              ))}
                            </div>
                            <span className="text-gray text-sm ml-1">
                              ({coach.totalRatingSum || 0})
                            </span>
                          </>
                        ) : (
                          <span className="text-gray text-sm">
                            No ratings yet
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <p className="text-gray mb-4 line-clamp-3 text-sm sm:text-base">
                    {coach.bio || "No bio available"}
                  </p>

                  <div className="flex flex-wrap mb-4">
                    {coach.languages &&
                      coach.languages.map((language) => (
                        <span
                          key={language}
                          className="bg-gold bg-opacity-20 text-gold px-2 py-1 rounded-full text-xs mr-2 mb-2"
                        >
                          {language}
                        </span>
                      ))}
                  </div>

                  <div className="flex items-center text-gray mb-4">
                    <span className="mr-2">🗓️</span>
                    <span>
                      {coach.availabilityCount || 0} available time slots
                    </span>
                  </div>

                  <Link
                    to={`/booking/book/${coach.id}`}
                    className="block w-full px-4 py-2 border-2 border-black border-b-4 bg-green text-black text-center rounded hover:bg-opacity-80"
                  >
                    View Profile & Book
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoachDirectory;
