import React, { useState, useRef, useEffect } from "react";
import { Features } from "../../zombiedata/features";
import { motion, useAnimation } from "framer-motion";

function Feature() {
  const [width, setWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const carousel = useRef();
  const controls = useAnimation();

  // Calculate carousel width on initial render and resize
  useEffect(() => {
    const updateWidth = () => {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  // Auto-scroll effect with pause on hover/interaction
  useEffect(() => {
    let interval;

    if (!isDragging) {
      interval = setInterval(() => {
        scrollRight();
      }, 6000);
    }

    return () => clearInterval(interval);
  }, [isDragging, currentIndex]);

  const scrollLeft = () => {
    if (carousel.current) {
      const newScrollLeft = Math.max(carousel.current.scrollLeft - 300, 0);
      const newIndex = Math.floor(newScrollLeft / 300);

      carousel.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });

      setCurrentIndex(newIndex);
    }
  };

  const scrollRight = () => {
    if (carousel.current) {
      const maxScroll =
        carousel.current.scrollWidth - carousel.current.offsetWidth;
      const newScrollLeft = Math.min(
        carousel.current.scrollLeft + 300,
        maxScroll
      );
      const newIndex = Math.floor(newScrollLeft / 300);

      carousel.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });

      setCurrentIndex(newIndex);

      // If we've reached the end, scroll back to start after a delay
      if (newScrollLeft >= maxScroll) {
        setTimeout(() => {
          carousel.current.scrollTo({
            left: 0,
            behavior: "smooth",
          });
          setCurrentIndex(0);
        }, 2000);
      }
    }
  };

  // Animation variants
  const buttonVariants = {
    hover: {
      scale: 1.1,
      backgroundColor: "rgba(255, 255, 255, 1)",
      transition: { duration: 0.2 },
    },
    tap: {
      scale: 0.95,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      transition: { duration: 0.1 },
    },
    initial: {
      scale: 1,
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
  };

  const featureCardVariants = {
    hover: {
      y: -5,
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="relative px-4 py-6 w-full max-w-screen-2xl mx-auto">
      {/* Navigation Buttons */}
      <motion.button
        onClick={scrollLeft}
        className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 w-10 h-10 flex items-center justify-center bg-white/70 rounded-full shadow-md border border-gray-200"
        variants={buttonVariants}
        initial="initial"
        whileHover="hover"
        whileTap="tap"
        aria-label="Scroll left"
      >
        <span className="text-2xl">◀️</span>
      </motion.button>

      <motion.button
        onClick={scrollRight}
        className="absolute z-10 right-2 top-1/2 transform -translate-y-1/2 w-10 h-10 flex items-center justify-center bg-white/70 rounded-full shadow-md border border-gray-200"
        variants={buttonVariants}
        initial="initial"
        whileHover="hover"
        whileTap="tap"
        aria-label="Scroll right"
      >
        <span className="text-2xl">▶️</span>
      </motion.button>

      {/* Carousel */}
      <motion.div
        ref={carousel}
        className="overflow-hidden cursor-grab mt-6 relative rounded-lg"
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        onMouseLeave={() => setIsDragging(false)}
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          className="cursor-grab bg-otherwhite flex"
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          animate={controls}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
        >
          {Features.map((item, index) => (
            <motion.div
              key={index}
              className="min-w-[300px] md:min-w-[350px] m-2 p-4 bg-white rounded-lg border-2 border-b-4 border-darkgray shadow-md cursor-grab transition-all"
              variants={featureCardVariants}
              whileHover="hover"
            >
              <div className="flex flex-col items-center">
                {/* Feature image - now more prominent without the icon above it */}
                <motion.img
                  src={item.image}
                  alt={item.heading}
                  className="rounded-md mb-4 h-48 w-full object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                />

                <motion.h3
                  className="text-lg font-headers font-bold text-black mb-2 text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
                >
                  {item.heading}
                </motion.h3>

                <motion.p
                  className="text-center text-gray-700 font-body"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
                >
                  {item.description}
                </motion.p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Dots indicator */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(Features.length / 3) }).map(
          (_, index) => (
            <motion.button
              key={index}
              className={`w-3 h-3 mx-1 rounded-full ${
                currentIndex === index ? "bg-red" : "bg-gray-300"
              }`}
              onClick={() => {
                if (carousel.current) {
                  const newScrollLeft = index * 300;
                  carousel.current.scrollTo({
                    left: newScrollLeft,
                    behavior: "smooth",
                  });
                  setCurrentIndex(index);
                }
              }}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Feature;
