import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { motion } from "framer-motion";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";
import PuffLoader from "react-spinners/PuffLoader";
import NavigationBar from "../navigation-bar-2/side-navigation-bar.component.jsx";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils.js";
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
} from "recharts";
import FloatingActionMenu from "../navigation-bar-2/floating-side-menu.jsx";
import FeedbackMenu from "../support/feedback-menu.jsx";
import CourseProgressTab from "./course-progress-tab.jsx";
import AchievementsList from "../achievements/achievements-list.jsx";
import { RefreshCw } from "lucide-react";

// Simplified error boundary for better error handling
class ProfileErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Profile component error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-white min-h-screen">
          <div className="w-full">
            <NavigationBar />
          </div>
          <div className="max-w-screen-xl mx-auto px-4 py-8 w-full sm:w-5/6">
            <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-red shadow-lg rounded-lg">
              <h1 className="text-xl sm:text-2xl font-bold text-red mb-4">
                Something went wrong
              </h1>
              <p className="mb-4 text-sm sm:text-base">
                We encountered an error while loading your profile data. Please
                try again later.
              </p>
              <button
                className="px-4 py-2 bg-green text-white rounded hover:bg-green-600 text-sm sm:text-base"
                onClick={() => window.location.reload()}
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// The main component with enhanced analytics integration
const UserProfileWithAnalytics = () => {
  // Create a mount status ref to prevent updates after unmounting
  const isMounted = useRef(true);

  // Use this to prevent initial analytics refresh on mount
  const initialRenderComplete = useRef(false);

  // Use this to track manual refresh button clicks
  const isManualRefresh = useRef(false);

  // Get auth user
  const { user } = useAuth();

  // Use the enhanced analytics hook with convenience accessors
  // IMPORTANT: Destructure only what's needed to prevent unnecessary re-renders
  const {
    userStats,
    isLoading: analyticsLoading,
    totalXp,
    streak,
    todayXp,
    weeklyXp,
    achievements,
    calculateLevel,
    getLevelProgress,
    refreshAnalytics,
  } = useSafeAnalytics();

  // Local component state
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [activeTab, setActiveTab] = useState("profile");
  const [error, setError] = useState(null);

  // Control our own loading state for better UX
  const [isLoading, setIsLoading] = useState(true);

  // State for tracking if mobile tabs menu is open
  const [tabsMenuOpen, setTabsMenuOpen] = useState(false);

  // Memoized value for vocabulary to prevent recalculation
  const vocabulary = useMemo(() => {
    return userStats?.vocabulary_learned_count || 0;
  }, [userStats?.vocabulary_learned_count]);

  // Wrap refreshAnalytics to prevent excessive calls
  const handleManualRefresh = useCallback(() => {
    if (isManualRefresh.current) return; // Prevent multiple clicks

    isManualRefresh.current = true;
    refreshAnalytics(true);

    // Reset after a timeout
    setTimeout(() => {
      isManualRefresh.current = false;
    }, 5000);
  }, [refreshAnalytics]);

  // Format radar data safely using enhanced analytics
  const radarData = useMemo(() => {
    // Safety check
    if (!userStats) return [];

    try {
      const totalQuestions =
        (userStats.correct_answers_count || 0) +
        (userStats.incorrect_answers_count || 0);
      const accuracy =
        totalQuestions > 0
          ? Math.round(
              ((userStats.correct_answers_count || 0) / totalQuestions) * 100
            )
          : 0;

      return [
        {
          subject: "Vocab",
          A: Math.min(
            100,
            ((userStats.vocabulary_learned_count || 0) / 100) * 100
          ),
          fullMark: 100,
        },
        {
          subject: "Lessons",
          A: Math.min(
            100,
            ((userStats.lessons_completed_count || 0) / 10) * 100
          ),
          fullMark: 100,
        },
        {
          subject: "XP",
          A: Math.min(100, ((totalXp || 0) / 1000) * 100),
          fullMark: 100,
        },
        {
          subject: "Practice",
          A: Math.min(
            100,
            ((userStats.practice_questions_answered || 0) / 100) * 100
          ),
          fullMark: 100,
        },
        {
          subject: "Streak",
          A: Math.min(100, ((streak || 0) / 7) * 100),
          fullMark: 100,
        },
        { subject: "Accuracy", A: accuracy, fullMark: 100 },
      ];
    } catch (err) {
      console.error("Error formatting radar data:", err);
      return [];
    }
  }, [userStats, totalXp, streak]);

  // Calculate study time safely
  const studyTime = useMemo(() => {
    if (!userStats) return "0 minutes";

    try {
      const roughMinutes =
        (userStats.lessons_completed_count || 0) * 15 +
        (userStats.practice_questions_answered || 0) * 0.5;

      if (roughMinutes < 60) {
        return `${Math.round(roughMinutes)} minutes`;
      }

      return `${Math.round(roughMinutes / 60)} hours`;
    } catch (err) {
      console.error("Error calculating study time:", err);
      return "0 minutes";
    }
  }, [userStats]);

  // Handle component mount/unmount
  useEffect(() => {
    isMounted.current = true;
    initialRenderComplete.current = true;

    return () => {
      // Mark component as unmounted to prevent state updates
      isMounted.current = false;
    };
  }, []);

  // Fetch user profile data once with proper cleanup
  useEffect(() => {
    // Skip if no user or already loading
    if (!user?.uid || loadingUserData) return;

    // Set a timeout to avoid infinite loading state
    const timeoutId = setTimeout(() => {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }, 10000); // Force exit loading state after 10 seconds

    const fetchUserData = async () => {
      try {
        setLoadingUserData(true);

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (isMounted.current) {
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }
          setLoadingUserData(false);

          // Exit loading state after a short delay
          setTimeout(() => {
            if (isMounted.current) {
              setIsLoading(false);
            }
          }, 1000);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (isMounted.current) {
          setError("Failed to load user data. Please try again later.");
          setLoadingUserData(false);
          setIsLoading(false);
        }
      }
    };

    fetchUserData();

    // Clean up all timers when component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, [user?.uid]);

  // Update loading state when analytics loading changes - WITH SAFEGUARDS
  useEffect(() => {
    // Only run after initial render and if analytics loading state has changed
    if (!initialRenderComplete.current || !isMounted.current) return;

    // If analytics are done loading, update our loading state after a small delay
    if (!analyticsLoading) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [analyticsLoading]);

  // Format join date with better error handling
  const formatJoinDate = () => {
    if (!userData?.createdAt) return "recently";

    try {
      const getOrdinalSuffix = (day) => {
        const j = day % 10,
          k = day % 100;
        if (j === 1 && k !== 11) return "st";
        if (j === 2 && k !== 12) return "nd";
        if (j === 3 && k !== 13) return "rd";
        return "th";
      };

      let date;
      if (userData.createdAt && userData.createdAt.seconds) {
        date = new Date(userData.createdAt.seconds * 1000);
      } else if (typeof userData.createdAt === "string") {
        date = new Date(userData.createdAt);
      } else {
        return "recently";
      }

      if (isNaN(date.getTime())) return "recently";

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Use abbreviated month names on mobile
      const isMobile = window.innerWidth < 640;
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = isMobile
        ? monthNamesShort[date.getUTCMonth()]
        : monthNames[date.getUTCMonth()];

      const day = date.getUTCDate();
      const year = date.getUTCFullYear();

      return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "recently";
    }
  };

  // Toggle mobile tabs menu
  const toggleTabsMenu = () => {
    setTabsMenuOpen(!tabsMenuOpen);
  };

  // Set active tab and close mobile menu
  const handleSetActiveTab = (tab) => {
    setActiveTab(tab);
    setTabsMenuOpen(false);
  };

  // Handle error state
  if (error) {
    return (
      <div className="bg-white min-h-screen">
        <div className="w-full">
          <NavigationBar />
        </div>
        <div className="max-w-screen-xl mx-auto px-4 py-6 sm:py-8 w-full sm:w-5/6">
          <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-red shadow-lg rounded-lg">
            <h1 className="text-xl sm:text-2xl font-bold text-red mb-4">
              Error
            </h1>
            <p className="text-sm sm:text-base">{error}</p>
            <button
              className="mt-4 px-4 py-2 bg-green text-white rounded hover:bg-green-600 text-sm sm:text-base"
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Use our own loading state instead of relying on external ones
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <PuffLoader color="#991616" loading={true} size={100} />
      </div>
    );
  }

  const currentLevel = calculateLevel();
  const nextLevel = currentLevel + 1;
  const progressToNextLevel = getLevelProgress();
  const xpToNextLevel = 1000 - (totalXp % 1000);

  return (
    <motion.div
      className="bg-white min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className="w-full">
        <NavigationBar />
        <FloatingActionMenu
          showFlashcards={true}
          showPractice={true}
          showStats={true}
          additionalButtons={[]}
        />
        <FeedbackMenu />
      </div>

      <div className="max-w-screen-xl mx-auto px-4 py-4 sm:py-8 w-full sm:w-5/6">
        {/* Profile Header - Mobile Optimized */}
        <div className="flex flex-col md:flex-row items-center md:items-start gap-4 sm:gap-6 mb-6 sm:mb-8">
          <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-full border-2 border-b-4 bg-gray flex items-center justify-center text-3xl sm:text-4xl flex-shrink-0">
            {user?.photoURL ? (
              <img
                src={user.photoURL}
                alt="Profile"
                className="w-full h-full rounded-full border-2 border-black object-cover"
              />
            ) : (
              <div className="uppercase text-gray-600">
                {user?.email?.charAt(0) || "U"}
              </div>
            )}
          </div>

          <div className="text-center md:text-left">
            <h1 className="text-xl sm:text-3xl font-bold">
              {userData?.displayName || user?.email || "User"}
            </h1>
            <p className="text-gray-600 text-sm sm:text-base mb-2">
              {userData?.motive || "Learning African Languages"}
            </p>

            <div className="flex flex-wrap justify-center md:justify-start gap-2 sm:gap-4 text-xs sm:text-base">
              <div className="flex items-center gap-1 sm:gap-2">
                <span>🕥</span>
                <span>Joined {formatJoinDate()}</span>
              </div>
              <div className="flex items-center gap-1 sm:gap-2">
                <span>🏆</span>
                <span>Level {currentLevel}</span>
              </div>
              <div className="flex items-center gap-1 sm:gap-2">
                <span>🔥</span>
                <span>{streak} day streak</span>
              </div>
            </div>
          </div>

          {/* Mobile Refresh Button */}
          <div className="md:hidden mt-2 flex justify-center w-full">
            <button
              onClick={handleManualRefresh}
              disabled={isManualRefresh.current}
              className={`flex items-center px-4 py-2 bg-gray text-white rounded text-sm hover:bg-opacity-90 ${
                isManualRefresh.current ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <RefreshCw
                size={16}
                className={`mr-2 ${
                  isManualRefresh.current ? "animate-spin" : ""
                }`}
              />
              {isManualRefresh.current ? "Refreshing..." : "Refresh Stats"}
            </button>
          </div>

          {/* Desktop Action Buttons */}
          <div className="hidden md:block ml-auto">
            <button
              onClick={handleManualRefresh}
              disabled={isManualRefresh.current}
              className={`px-4 py-2 mr-2 bg-gray text-white rounded hover:bg-opacity-90 ${
                isManualRefresh.current ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isManualRefresh.current ? "Refreshing..." : "Refresh Data"}
            </button>
            <button className="px-4 py-2 bg-green text-white rounded hover:bg-green-600">
              Edit Profile
            </button>
          </div>
        </div>

        {/* Level Progress Bar */}
        <div className="mb-6 sm:mb-8 bg-white p-3 sm:p-4 border-2 border-b-4 border-darkgray shadow rounded-lg">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-1 sm:gap-0 mb-2">
            <div>
              <span className="font-bold text-sm sm:text-base">
                Level {currentLevel}
              </span>
              <span className="text-xs sm:text-sm text-gray-600 ml-2">
                {totalXp} XP
              </span>
            </div>
            <div className="text-xs sm:text-sm text-gray-600">
              {xpToNextLevel} XP to Level {nextLevel}
            </div>
          </div>
          <div className="h-3 sm:h-4 bg-darkgray border-gray border rounded-full overflow-hidden">
            <div
              className="h-full bg-green rounded-full"
              style={{ width: `${progressToNextLevel}%` }}
            ></div>
          </div>
        </div>

        {/* Mobile Tab Navigation with Dropdown */}
        <div className="md:hidden mb-4 relative">
          <button
            onClick={toggleTabsMenu}
            className="w-full flex items-center justify-between px-4 py-3 bg-white border-2 border-darkgray rounded-md"
          >
            <span>
              {activeTab === "profile" && "Profile Overview"}
              {activeTab === "achievements" && "Achievements"}
              {activeTab === "courses" && "Course Progress"}
              {activeTab === "language" && "Language Progress"}
            </span>
            <span className="transform transition-transform duration-200">
              {tabsMenuOpen ? "▲" : "▼"}
            </span>
          </button>

          {tabsMenuOpen && (
            <div className="absolute top-full left-0 right-0 z-10 bg-white border-2 border-darkgray rounded-b-md shadow-lg mt-1">
              {["profile", "achievements", "courses", "language"].map(
                (tab) =>
                  activeTab !== tab && (
                    <button
                      key={tab}
                      onClick={() => handleSetActiveTab(tab)}
                      className="w-full text-left px-4 py-3 hover:bg-gray-100 border-b border-gray-200 last:border-b-0"
                    >
                      {tab === "profile" && "Profile Overview"}
                      {tab === "achievements" && "Achievements"}
                      {tab === "courses" && "Course Progress"}
                      {tab === "language" && "Language Progress"}
                    </button>
                  )
              )}
            </div>
          )}
        </div>

        {/* Desktop Tab Navigation */}
        <div className="hidden md:block border-b-2 border-darkgray mb-6">
          <div className="flex flex-wrap -mb-px">
            <button
              onClick={() => setActiveTab("profile")}
              className={`inline-block py-4 px-4 text-sm font-medium ${
                activeTab === "profile"
                  ? "text-green border-b-4 border-green"
                  : "text-gray hover:text-black"
              }`}
            >
              Profile Overview
            </button>
            <button
              onClick={() => setActiveTab("achievements")}
              className={`inline-block py-4 px-4 text-sm font-medium ${
                activeTab === "achievements"
                  ? "text-green border-b-4 border-green"
                  : "text-gray hover:text-black"
              }`}
            >
              Achievements
            </button>
            <button
              onClick={() => setActiveTab("courses")}
              className={`inline-block py-4 px-4 text-sm font-medium ${
                activeTab === "courses"
                  ? "text-green border-b-4 border-green"
                  : "text-gray hover:text-black"
              }`}
            >
              Course Progress
            </button>
            <button
              onClick={() => setActiveTab("language")}
              className={`inline-block py-4 px-4 text-sm font-medium ${
                activeTab === "language"
                  ? "text-green border-b-4 border-green"
                  : "text-gray hover:text-black"
              }`}
            >
              Language Progress
            </button>
          </div>
        </div>

        {/* Tab Content - Profile Overview */}
        {activeTab === "profile" && (
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
              {/* Profile Stats */}
              <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
                <h3 className="text-base sm:text-lg font-semibold mb-4">
                  Your Learning Stats
                </h3>

                <div className="grid grid-cols-2 gap-3 sm:gap-4">
                  <div className="border-r border-b border-gray pb-3 sm:pb-4 pr-3 sm:pr-4">
                    <div className="text-xs sm:text-sm text-gray">Total XP</div>
                    <div className="text-xl sm:text-2xl font-bold text-gray">
                      {totalXp}
                    </div>
                  </div>
                  <div className="border-b border-gray pb-3 sm:pb-4">
                    <div className="text-xs sm:text-sm text-gray">
                      Lessons Completed
                    </div>
                    <div className="text-xl sm:text-2xl font-bold text-gray">
                      {userStats?.lessons_completed_count || 0}
                    </div>
                  </div>
                  <div className="border-r border-gray pr-3 sm:pr-4">
                    <div className="text-xs sm:text-sm text-gray">
                      Words Learned
                    </div>
                    <div className="text-xl sm:text-2xl font-bold text-gray">
                      {vocabulary}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs sm:text-sm text-gray">
                      Practice Questions
                    </div>
                    <div className="text-xl sm:text-2xl font-bold text-gray">
                      {userStats?.practice_questions_answered || 0}
                    </div>
                  </div>
                </div>

                <div className="mt-4 sm:mt-6 grid grid-cols-2 gap-3 sm:gap-4">
                  <div>
                    <div className="text-xs sm:text-sm text-gray mb-1">
                      Total Study Time
                    </div>
                    <div className="text-xl sm:text-2xl font-bold text-gray">
                      {studyTime}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs sm:text-sm text-gray mb-1">
                      Recent Activity
                    </div>
                    <div className="text-sm sm:text-md font-bold text-gray">
                      <div>Today: {todayXp} XP</div>
                      <div>This Week: {weeklyXp} XP</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Skill Radar Chart */}
              <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
                <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4">
                  Skills Overview
                </h3>
                <div className="h-56 sm:h-64">
                  {userStats && radarData.length > 0 ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <RadarChart
                        outerRadius={window.innerWidth < 640 ? 70 : 90}
                        data={radarData}
                      >
                        <PolarGrid />
                        <PolarAngleAxis
                          dataKey="subject"
                          tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
                        />
                        <PolarRadiusAxis
                          angle={30}
                          domain={[0, 100]}
                          tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
                        />
                        <Radar
                          name="Your Skills"
                          dataKey="A"
                          stroke="#848717"
                          fill="#848717"
                          fillOpacity={0.6}
                        />
                        <Tooltip />
                      </RadarChart>
                    </ResponsiveContainer>
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <p className="text-gray-500 text-sm sm:text-base">
                        No skill data available
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Recently Learned Words */}
            {userStats?.vocabulary_words &&
            Array.isArray(userStats.vocabulary_words) &&
            userStats.vocabulary_words.length > 0 ? (
              <div className="mt-4 sm:mt-6 bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
                <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4">
                  Recently Learned Words
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4">
                  {userStats.vocabulary_words
                    .slice(0, 8)
                    .map((wordObj, index) => {
                      try {
                        const word =
                          typeof wordObj === "object" && wordObj !== null
                            ? wordObj.word
                            : String(wordObj || "");
                        const translation =
                          typeof wordObj === "object" && wordObj !== null
                            ? wordObj.translation || ""
                            : "";

                        return (
                          <div
                            key={index}
                            className="p-3 sm:p-4 bg-gray-50 rounded-lg border border-gray-200"
                          >
                            <div className="font-semibold text-sm sm:text-base">
                              {word}
                            </div>
                            {translation && (
                              <div className="text-xs sm:text-sm text-gray-600">
                                {translation}
                              </div>
                            )}
                          </div>
                        );
                      } catch (err) {
                        console.error("Error rendering word:", err);
                        return null;
                      }
                    })}
                </div>
                {userStats.vocabulary_words.length > 8 && (
                  <div className="mt-4 text-center">
                    <button
                      onClick={() => setActiveTab("language")}
                      className="text-green hover:underline text-sm sm:text-base"
                    >
                      View all words
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-4 sm:mt-6 bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg text-center">
                <h3 className="text-base sm:text-lg font-semibold mb-2">
                  No Words Yet
                </h3>
                <p className="text-gray-600 mb-4 text-xs sm:text-sm">
                  Complete lessons to start building your vocabulary
                </p>
                <button className="px-4 py-2 bg-green text-white rounded hover:bg-green-600 text-sm sm:text-base">
                  Start Learning
                </button>
              </div>
            )}
          </div>
        )}

        {/* Tab Content - Achievements */}
        {activeTab === "achievements" && (
          <div>
            <AchievementsList
              preloadedAchievements={achievements}
              analyticsData={userStats}
            />
          </div>
        )}

        {/* Tab Content - Course Progress */}
        {activeTab === "courses" && <CourseProgressTab />}

        {/* Tab Content - Language Progress */}
        {activeTab === "language" && (
          <div>
            {userStats?.vocabulary_words &&
            Array.isArray(userStats.vocabulary_words) &&
            userStats.vocabulary_words.length > 0 ? (
              <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg mb-4 sm:mb-6">
                <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4">
                  Vocabulary Progress
                  <span className="ml-2 text-xs sm:text-sm text-gray">
                    {userStats.vocabulary_words.length} words learned
                  </span>
                </h3>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4">
                  {userStats.vocabulary_words.map((wordObj, index) => {
                    try {
                      const word =
                        typeof wordObj === "object" && wordObj !== null
                          ? wordObj.word || ""
                          : String(wordObj || "");
                      const translation =
                        typeof wordObj === "object" && wordObj !== null
                          ? wordObj.translation || ""
                          : "";
                      const context =
                        typeof wordObj === "object" && wordObj !== null
                          ? wordObj.context || ""
                          : "";

                      return (
                        <div
                          key={index}
                          className="p-3 sm:p-4 bg-gray rounded-lg border border-gray"
                        >
                          <div className="font-semibold text-sm sm:text-base">
                            {word}
                          </div>
                          {translation && (
                            <div className="text-xs sm:text-sm text-gray">
                              {translation}
                            </div>
                          )}
                          {context && (
                            <div className="text-xs text-gray-400 mt-1 italic">
                              {context}
                            </div>
                          )}
                        </div>
                      );
                    } catch (err) {
                      console.error("Error rendering word:", err);
                      return null;
                    }
                  })}
                </div>
              </div>
            ) : (
              <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg mb-4 sm:mb-6 text-center">
                <h3 className="text-base sm:text-lg font-semibold mb-4">
                  No Vocabulary Yet
                </h3>
                <p className="text-gray-600 mb-4 text-sm">
                  Complete lessons to start building your vocabulary
                </p>
              </div>
            )}

            {/* Practice features */}
            <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg text-center">
              <h3 className="text-base sm:text-lg font-semibold mb-2">
                Practice Your Vocabulary
              </h3>
              <p className="text-gray mb-4 text-xs sm:text-sm">
                Review your learned words with these practice modes
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4">
                <button className="p-3 sm:p-4 bg-green border-darkgray border-2 border-b-4 text-white rounded hover:opacity-90 transition-opacity">
                  <span className="text-xl sm:text-2xl mx-auto mb-2">⚡️</span>
                  <span className="text-sm sm:text-base">Flashcards</span>
                </button>
                <button className="p-3 sm:p-4 bg-green border-darkgray border-2 border-b-4 text-white rounded hover:opacity-90 transition-opacity">
                  <span className="text-xl sm:text-2xl mx-auto mb-2">🎓</span>
                  <span className="text-sm sm:text-base">Quiz Mode</span>
                </button>
                <button className="p-3 sm:p-4 bg-green border-darkgray border-2 border-b-4 text-white rounded hover:opacity-90 transition-opacity">
                  <span className="text-xl sm:text-2xl mx-auto mb-2">🧩</span>
                  <span className="text-sm sm:text-base">Matching Game</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

// This is the correct way to wrap a component with an error boundary
// First create the memoized component
const MemoizedUserProfile = React.memo(UserProfileWithAnalytics);

// Then wrap it with the error boundary and export
const UserProfileWithAnalyticsWithErrorBoundary = (props) => (
  <ProfileErrorBoundary>
    <MemoizedUserProfile {...props} />
  </ProfileErrorBoundary>
);

export default UserProfileWithAnalyticsWithErrorBoundary;
