// utils/bookings/meeting-service.js
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";

/**
 * Meeting service to handle Zoom meeting data
 * This client-side service focuses on displaying and formatting meeting information
 * that comes from the server's Zoom integration
 */
export const MeetingService = {
  /**
   * Determines if a meeting link is from Zoom
   * @param {string} link - The meeting link to check
   * @returns {boolean} True if it's a Zoom link
   */
  isZoomMeeting: (link) => {
    return link && typeof link === "string" && link.includes("zoom.us");
  },

  /**
   * Checks if a user is an alternative host for a meeting
   * @param {string} userEmail - The user's email address
   * @param {Array} alternativeHosts - List of alternative host emails
   * @returns {boolean} True if the user is an alternative host
   */
  isAlternativeHost: (userEmail, alternativeHosts) => {
    if (!userEmail || !alternativeHosts || !Array.isArray(alternativeHosts)) {
      return false;
    }

    return alternativeHosts.includes(userEmail);
  },

  /**
   * Format a booking object to display meeting info correctly
   * @param {Object} booking - The booking object from Firestore
   * @param {string} userEmail - The current user's email
   * @returns {Object} Enhanced booking with meeting information
   */
  formatBookingForDisplay: (booking, userEmail) => {
    // Default return if nothing to process
    if (!booking) return booking;

    // Check if this user is a host (either the main host or an alternative host)
    const isHost =
      booking.coachId === booking.userId ||
      (booking.alternativeHosts &&
        Array.isArray(booking.alternativeHosts) &&
        booking.alternativeHosts.includes(userEmail));

    const isZoomMeeting = MeetingService.isZoomMeeting(booking.meetingLink);

    // Return enhanced booking
    return {
      ...booking,
      isHost,
      isZoomMeeting,
      // Host gets the start URL, regular participants get the join URL
      meetingActionUrl:
        isHost && booking.meetingStartUrl
          ? booking.meetingStartUrl
          : booking.meetingLink,
      meetingActionLabel: isHost
        ? isZoomMeeting
          ? "Start Zoom Meeting (Host)"
          : "Start Meeting (Host)"
        : isZoomMeeting
        ? "Join Zoom Meeting"
        : "Join Meeting",
    };
  },

  /**
   * Fetch user details - useful for getting emails for Zoom integration
   * @param {string} userId - The user ID
   * @returns {Promise<Object>} User details
   */
  async fetchUserDetails(userId) {
    if (!userId) return null;

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        return userSnap.data();
      }
      return null;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  },

  /**
   * Extract just the meeting link from a booking or meeting details object
   * @param {Object|string} meetingData - Meeting details object or direct link string
   * @returns {string} The meeting link
   */
  getMeetingLink(meetingData) {
    if (!meetingData) return null;

    // If it's already a string, just return it
    if (typeof meetingData === "string") {
      return meetingData;
    }

    // If it's an object that has a meetingLink property, return that
    if (typeof meetingData === "object") {
      // Check for different property names that might contain the link
      return (
        meetingData.meetingLink ||
        meetingData.join_url ||
        meetingData.joinUrl ||
        null
      );
    }

    return null;
  },

  /**
   * Extract just the meeting password from a booking or meeting details object
   * @param {Object|string} meetingData - Meeting details object or direct password string
   * @returns {string} The meeting password
   */
  getMeetingPassword(meetingData) {
    if (!meetingData) return null;

    // If it's already a string, just return it
    if (typeof meetingData === "string") {
      return meetingData;
    }

    // If it's an object that has a password property, return that
    if (typeof meetingData === "object") {
      // Check for different property names that might contain the password
      return meetingData.meetingPassword || meetingData.password || null;
    }

    return null;
  },
};

export default MeetingService;
