// OnboardingTimeline.jsx
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const OnboardingTimeline = ({ onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const timelineData = [
    {
      date: "Week 1",
      title: "Orientation Phase",
      description:
        "Explore the platform, complete your profile, and try your first language lesson.",
      icon: "🚀",
      color: "red",
    },
    {
      date: "Weeks 2-3",
      title: "Core Feature Testing",
      description:
        "Test our language lessons, practice activities, and provide feedback on your experience.",
      icon: "🔍",
      color: "gold",
    },
    {
      date: "Weeks 4-5",
      title: "Community Interaction",
      description:
        "Engage with other early access users, participate in group sessions, and test social features.",
      icon: "👥",
      color: "green",
    },
    {
      date: "Weeks 6-8",
      title: "Advanced Features",
      description:
        "Help us test more advanced features including coaching sessions and assessment tools.",
      icon: "⚙️",
      color: "red",
    },
    {
      date: "Final Week",
      title: "Feedback & Transition",
      description:
        "Provide comprehensive feedback and prepare for transition to the public release.",
      icon: "🏁",
      color: "gold",
    },
  ];

  // Auto-play through timeline items
  useEffect(() => {
    let timer;
    if (autoPlay) {
      timer = setTimeout(() => {
        if (activeStep < timelineData.length - 1) {
          setActiveStep(activeStep + 1);
        } else {
          setAutoPlay(false);
        }
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [activeStep, autoPlay, timelineData.length]);

  const getColorClasses = (color) => {
    switch (color) {
      case "red":
        return { bg: "bg-red", text: "text-red", border: "border-red" };
      case "gold":
        return { bg: "bg-gold", text: "text-gold", border: "border-gold" };
      case "green":
        return { bg: "bg-green", text: "text-green", border: "border-green" };
      default:
        return { bg: "bg-gray", text: "text-gray", border: "border-gray" };
    }
  };

  return (
    <div className="flex flex-col">
      <p className="text-center mb-6">
        Here's what to expect during your early access journey. Each phase
        unlocks new features and opportunities to shape the future of Fast
        Fluency Africa.
      </p>

      {/* Active timeline item */}
      <motion.div
        key={activeStep}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="mb-6"
      >
        {timelineData.map((item, index) => {
          const colors = getColorClasses(item.color);
          return index === activeStep ? (
            <div
              key={index}
              className={`bg-white p-5 rounded-lg border-2 border-b-4 ${colors.border} shadow-lg`}
            >
              <div className="flex items-center mb-2">
                <div
                  className={`w-10 h-10 rounded-sm ${colors.bg} flex items-center justify-center mr-3 text-xl`}
                >
                  <span>{item.icon}</span>
                </div>
                <div>
                  <h3 className="text-xl font-bold font-headers">
                    {item.title}
                  </h3>
                  <span className={`font-medium ${colors.text}`}>
                    {item.date}
                  </span>
                </div>
              </div>
              <p className="text-gray-700 ml-13">{item.description}</p>
            </div>
          ) : null;
        })}
      </motion.div>

      {/* Timeline dots for navigation */}
      <div className="flex justify-center space-x-2 mb-6">
        {timelineData.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              setActiveStep(index);
              setAutoPlay(false);
            }}
            className={`w-3 h-3 rounded-full transition-all ${
              index === activeStep ? "bg-green scale-125" : "bg-darkgray"
            }`}
            aria-label={`Go to step ${index + 1}`}
          />
        ))}
      </div>

      <div className="text-center p-4 bg-gold/10 border-l-4 border-gold rounded-md mb-6">
        <p className="text-sm">
          <span className="font-bold">Expected Outcomes:</span> By participating
          in our early access program, you'll help shape the future of African
          language learning, gain exclusive access to new features, and receive
          special rewards for your valuable contributions.
        </p>
      </div>

      {/* Continue button */}
      <div className="w-full text-center mt-6">
        <button
          onClick={onComplete}
          className="px-8 py-3 border-2 rounded text-center mt-4 w-full md:w-auto
            cursor-pointer
            text-green font-bold border-green hover:border-white hover:bg-green hover:text-white
            text-sm md:text-base
            uppercase
            shadow-md
            hover:shadow-lg
            transition
            duration-150
            transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4"
        >
          I Understand the Timeline
        </button>
      </div>
    </div>
  );
};

export default OnboardingTimeline;
