import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { motion } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

// Maximum allowed attempts before marking as incorrect
const MAX_ATTEMPTS = 3;

function PictureMatchingMock({ data }) {
  const [optionStates, setOptionStates] = useState([]);
  const [firstOptionId, setFirstOptionId] = useState(null);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [exerciseCompleted, setExerciseCompleted] = useState(false);
  const [autoCheckTriggered, setAutoCheckTriggered] = useState(false);
  const [completionFeedback, setCompletionFeedback] = useState(null);

  // Audio state
  const [optionAudioUrls, setOptionAudioUrls] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioKey, setCurrentAudioKey] = useState(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  const audioRef = useRef(new Audio());

  const { setOptionChosen } = useContext(optionChosenContext);
  const { setFeedback } = useContext(feedbackContext);

  // Compute the exercise data with proper validation
  const exerciseData = useMemo(() => {
    if (!data) return null;
    if (!data.content || !Array.isArray(data.content.options)) return null;
    return data;
  }, [data]);

  // Apply audio settings when they change
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Fetch audio URLs for options
  useEffect(() => {
    const fetchAudioUrls = async () => {
      if (!exerciseData?.content?.options) return;

      const urls = {};
      for (const [index, option] of exerciseData.content.options.entries()) {
        if (option.audio) {
          try {
            // Use a consistent ID for each pair
            const pairId = `pair-${index}`;
            urls[pairId] = await getAudioDownloadUrl(option.audio);
          } catch (err) {
            console.error(`Error fetching audio for option ${index}:`, err);
          }
        }
      }

      setOptionAudioUrls(urls);
    };

    fetchAudioUrls();
  }, [exerciseData]);

  // Initialize items for the game
  useEffect(() => {
    if (!exerciseData?.content?.options) return;

    const flattened = exerciseData.content.options.flatMap((option, index) => [
      {
        value: option.a,
        pairId: `pair-${index}`,
        languageType: "base",
        isDisabled: false,
        isSelected: false,
        uniqueId: `pair-${index}-base`,
      },
      {
        value: option.b,
        pairId: `pair-${index}`,
        languageType: "target",
        isDisabled: false,
        isSelected: false,
        uniqueId: `pair-${index}-target`,
      },
    ]);
    setOptionStates(shuffleArray(flattened));
    setLoading(false);
  }, [exerciseData]);

  // Handle exercise completion
  useEffect(() => {
    if (exerciseCompleted || autoCheckTriggered) return;

    // Check if all items are matched (success case)
    const allMatched =
      optionStates.length > 0 && optionStates.every((item) => item.isDisabled);

    // Check if max attempts reached (failure case)
    const maxAttemptsReached = failedAttempts >= MAX_ATTEMPTS;

    // If either condition met, mark as completed
    if (allMatched || maxAttemptsReached) {
      // Show immediate visual feedback
      setCompletionFeedback(allMatched ? "success" : "failure");

      // Give a small delay for visual feedback before proceeding
      setTimeout(() => {
        setExerciseCompleted(true);

        if (allMatched) {
          // Success case
          setOptionChosen("completed");
          setFeedback("correct");
        } else {
          // Failure case
          setOptionChosen("failed");
          setFeedback("incorrect");
        }

        // Prevent multiple auto-check triggers
        setAutoCheckTriggered(true);

        // Trigger the auto-check after a short delay
        setTimeout(() => {
          // Use window.dispatchEvent for better reliability
          window.dispatchEvent(
            new CustomEvent("picture_matching_completed", {
              detail: {
                exerciseId: exerciseData?.id,
                exerciseType: "matching_images",
                isCorrect: allMatched,
                timestamp: Date.now(),
              },
            })
          );
        }, 300);
      }, 500);
    }
  }, [
    exerciseData,
    optionStates,
    failedAttempts,
    exerciseCompleted,
    autoCheckTriggered,
    setOptionChosen,
    setFeedback,
  ]);

  // Play audio for a pair
  const handlePlay = (pairId) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audioUrl = optionAudioUrls[pairId];
    if (audioUrl && audioRef.current) {
      // If already playing this audio, stop it
      if (isPlaying && currentAudioKey === pairId) {
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
        return;
      }

      // Set up audio playback
      audioRef.current.src = audioUrl;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlaying(true);
      setCurrentAudioKey(pairId);
    }
  };

  const handlePause = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioKey(null);
    }
  };

  const handleOptionClick = (clickedId) => {
    // Don't allow interaction if exercise is completed or max attempts reached
    if (exerciseCompleted || failedAttempts >= MAX_ATTEMPTS) return;

    const clickedItem = optionStates.find(
      (item) => item.uniqueId === clickedId
    );
    if (!clickedItem || clickedItem.isDisabled) return;

    if (firstOptionId === null) {
      // First selection
      setOptionStates((prev) =>
        prev.map((itm) => ({
          ...itm,
          isSelected: itm.uniqueId === clickedId,
        }))
      );
      setFirstOptionId(clickedId);
      return;
    }

    // Second selection - check for match
    const firstItem = optionStates.find(
      (itm) => itm.uniqueId === firstOptionId
    );
    let newItems = optionStates.map((itm) => ({ ...itm, isSelected: false }));

    if (firstItem?.pairId === clickedItem.pairId) {
      // Correct match
      newItems = newItems.map((itm) =>
        [firstItem.uniqueId, clickedItem.uniqueId].includes(itm.uniqueId)
          ? { ...itm, isDisabled: true }
          : itm
      );

      // Play success sound or animation if needed
    } else {
      // Incorrect match - increment attempts
      setFailedAttempts((prev) => prev + 1);
    }

    setOptionStates(newItems);
    setFirstOptionId(null);
  };

  // Proper error handling for invalid exercise data
  if (!exerciseData) {
    return <div className="text-red p-4">Invalid exercise data.</div>;
  }

  if (loading) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-full min-h-[300px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <BeatLoader color={"#848717"} loading={loading} size={15} />
      </motion.div>
    );
  }

  // Calculate remaining attempts
  const remainingAttempts = MAX_ATTEMPTS - failedAttempts;

  return (
    <motion.div
      className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
        <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
          <div className="max-w-[600px] mx-auto">
            <h3 className="text-base sm:text-lg italic text-center mb-4 sm:mb-6 relative border-2 border-b-4 border-black font-body p-3 sm:p-4">
              {exerciseData.content.question}
            </h3>

            {/* Completion feedback banner */}
            {completionFeedback && (
              <div
                className={`text-center mb-4 p-2 font-body rounded-md text-sm sm:text-base ${
                  completionFeedback === "success"
                    ? "bg-green bg-opacity-20 text-green"
                    : "bg-red bg-opacity-20 text-red"
                }`}
              >
                <span className="font-bold">
                  {completionFeedback === "success"
                    ? "Great job! All matches complete."
                    : "Maximum attempts reached."}
                </span>
              </div>
            )}

            {/* Audio disabled message */}
            {!audioEnabled && (
              <div className="text-center text-xs text-gray-500 mb-2">
                Audio is disabled globally. Enable it in Settings.
              </div>
            )}

            {/* Attempts indicator */}
            {remainingAttempts < MAX_ATTEMPTS &&
              remainingAttempts > 0 &&
              !completionFeedback && (
                <div className="text-center mb-3 sm:mb-4">
                  <span
                    className={`font-bold text-sm sm:text-base ${
                      remainingAttempts === 1 ? "text-red" : "text-gold"
                    }`}
                  >
                    {remainingAttempts} attempt
                    {remainingAttempts !== 1 ? "s" : ""} remaining
                  </span>
                </div>
              )}
          </div>

          {/* Grid for options - changed to 2 columns for mobile, 3 for larger screens */}
          <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-3">
            {optionStates.map((item) => {
              const isImage = /\p{Extended_Pictographic}/u.test(item.value);
              const isWordOption = item.languageType === "target";
              const isAudioPlaying =
                isPlaying && currentAudioKey === item.pairId;

              return (
                <button
                  key={item.uniqueId}
                  type="button"
                  disabled={
                    item.isDisabled ||
                    exerciseCompleted ||
                    failedAttempts >= MAX_ATTEMPTS
                  }
                  onClick={() => handleOptionClick(item.uniqueId)}
                  onMouseEnter={() =>
                    isWordOption && audioEnabled && handlePlay(item.pairId)
                  }
                  onMouseLeave={handlePause}
                  className={`
                    transition-all p-3 sm:p-4 rounded-lg border-2 border-b-4 font-body text-sm
                    flex flex-col items-center justify-center min-h-[70px] sm:min-h-[80px]
                    ${
                      item.isDisabled ||
                      exerciseCompleted ||
                      failedAttempts >= MAX_ATTEMPTS
                        ? "bg-gray text-darkgray border-darkgray cursor-not-allowed"
                        : item.isSelected
                        ? "hover:border-green border-b-4 bg-white border-green text-green shadow-lg hover:shadow-xl"
                        : "bg-white border-b-4 text-gray border-gray hover:text-green hover:border-green"
                    }
                    ${isWordOption && !audioEnabled ? "text-gray-500" : ""}
                  `}
                >
                  {isImage ? (
                    <span className="max-w-[60px] sm:max-w-[80px] text-outline-gray text-3xl sm:text-4xl max-h-[60px] sm:max-h-[80px] object-contain mx-auto">
                      {item.value}
                    </span>
                  ) : (
                    <span
                      className={
                        isWordOption
                          ? "cursor-pointer text-base sm:text-xl border-b-2 decoration-dotted"
                          : "text-base sm:text-xl"
                      }
                    >
                      {item.value}
                      {isWordOption && isAudioPlaying && (
                        <span className="ml-1 text-xs">🔊</span>
                      )}
                    </span>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

PictureMatchingMock.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.shape({
      question: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          a: PropTypes.string.isRequired,
          b: PropTypes.string.isRequired,
          audio: PropTypes.any,
        })
      ).isRequired,
    }).isRequired,
    topic: PropTypes.string,
    day: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};

export default PictureMatchingMock;
