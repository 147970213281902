// components/articles/popular-articles.jsx
import React from "react";
import { Link } from "react-router-dom";
import { getPopularArticles } from "../../zombiedata/articles-2";

const PopularArticles = ({ limit = 5 }) => {
  const articles = getPopularArticles(limit);

  return (
    <div className="bg-gray-50 p-5 rounded-lg">
      <h3 className="font-bold mb-4">Popular Articles</h3>
      <ul className="space-y-2">
        {articles.map((article) => (
          <li
            key={article.id}
            className="border-b border-gray-200 pb-2 last:border-b-0"
          >
            <div className="flex items-center mb-2">
              {article.image && (
                <div className="mr-2 w-10 h-10 flex-shrink-0">
                  <img
                    src={article.image}
                    alt={article.title}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              )}
              <Link
                to={`/articles/${article.slug}`}
                className="text-red hover:underline flex-grow"
              >
                {article.title}
              </Link>
            </div>
            <div className="text-xs text-gray-500">
              {new Date(article.publishDate).toLocaleDateString()} • ⏱️{" "}
              {article.readTime}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularArticles;
