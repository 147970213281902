// InviteFriends.js
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { IoLogoFacebook, IoLogoWhatsapp, IoLogoTwitter } from "react-icons/io";

const InviteFriends = () => {
  const [inviteLink, setInviteLink] = useState("");
  const [copyButtonText, setCopyButtonText] = useState("Copy Link");
  const defaultShareText =
    "Come join me at Fast Fluency Africa, the fun way to know African languages, cultures, and stories!";

  useEffect(() => {
    const getLink = async () => {
      const link = `fastfluencyafrica.com`;
      if (link) {
        setInviteLink(`https://${link}`);
      }
    };

    getLink();
  }, []);

  const handleCopyClick = () => {
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText("Copy Link");
    }, 2000); // Reset back to "Copy" after 2 seconds
  };

  return (
    <div className="bg-white w-full mt-4 border-2 border-b-4 border-darkgray shadow-2xl m-auto p-4 sm:p-6 md:p-8 rounded text-right">
      <div className="text-outline-black text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-2">
        📢🧘🏾🔊
      </div>
      <h3 className="font-headers font-semibold text-center text-sm sm:text-base md:text-lg mb-3">
        Let your friends and family know that mastering a new language with Fast
        Fluency Africa is fun!
      </h3>
      <input
        type="text"
        readOnly
        value={inviteLink}
        className="rounded p-2 w-full mb-4 mt-2 text-xs sm:text-sm outline-none border-2 border-b-4"
      />
      <CopyToClipboard text={inviteLink}>
        <button
          onClick={handleCopyClick}
          className="w-full sm:w-3/4 md:w-1/2 mx-auto py-2.5 px-4 text-center text-sm sm:text-base font-bold rounded hover:text-white border-2 border-b-4 border-green bg-white hover:border-green hover:bg-green text-black uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out block mb-4"
        >
          {copyButtonText}
        </button>
      </CopyToClipboard>

      <div className="flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-4 mt-4">
        <h3 className="text-sm sm:text-base mb-2 sm:mb-0">Or share via:</h3>
        <div className="flex gap-3">
          <FacebookShareButton url={inviteLink} quote={defaultShareText}>
            <button className="min-w-[44px] min-h-[44px] flex items-center justify-center py-2 px-3 font-bold rounded hover:text-white border-2 border-b-4 border-green bg-white hover:border-green hover:bg-green text-black uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
              <span data-testid="IoLogoFacebook" className="text-lg">
                <IoLogoFacebook />
              </span>
            </button>
          </FacebookShareButton>
          <TwitterShareButton url={inviteLink} title={defaultShareText}>
            <button className="min-w-[44px] min-h-[44px] flex items-center justify-center py-2 px-3 font-bold rounded hover:text-white border-2 border-b-4 border-green bg-white hover:border-green hover:bg-green text-black uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
              <span data-testid="IoLogoTwitter" className="text-lg">
                <IoLogoTwitter />
              </span>
            </button>
          </TwitterShareButton>
          <WhatsappShareButton url={inviteLink} title={defaultShareText}>
            <button className="min-w-[44px] min-h-[44px] flex items-center justify-center py-2 px-3 font-bold rounded hover:text-white border-2 border-b-4 border-green bg-white hover:border-green hover:bg-green text-black uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
              <span data-testid="IoLogoWhatsapp" className="text-lg">
                <IoLogoWhatsapp />
              </span>
            </button>
          </WhatsappShareButton>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
