// utils/shop-notification-utils.js
import { useNotifications } from "../../helpers/NotificationsContext";
import { useNotificationTemplates } from "./notification-util";

// Notification types for shop system
export const SHOP_NOTIFICATION_TYPES = {
  PURCHASE_CONFIRMED: "purchase_confirmed",
  ITEM_USED: "item_used",
  EFFECT_ACTIVATED: "effect_activated",
  EFFECT_EXPIRED: "effect_expired",
  COINS_RECEIVED: "coins_received",
  LOW_COINS: "low_coins",
};

/**
 * Create a notification for a successful purchase
 * @param {Object} product - The purchased product
 * @param {number} price - The price paid
 * @returns {Object} The notification object
 */
export const createPurchaseConfirmedNotification = (product, price) => {
  return {
    title: "Purchase Confirmed",
    message: `You've successfully purchased ${product.name} for ${price} coins.`,
    type: SHOP_NOTIFICATION_TYPES.PURCHASE_CONFIRMED,
    data: {
      productId: product.id,
      productName: product.name,
      price: price,
      category: product.category || product.type,
    },
    link: `/inventory`,
  };
};

/**
 * Create a notification for a successfully used item
 * @param {Object} item - The inventory item
 * @returns {Object} The notification object
 */
export const createItemUsedNotification = (item) => {
  return {
    title: "Item Used",
    message: `You've used ${item.name} from your inventory.`,
    type: SHOP_NOTIFICATION_TYPES.ITEM_USED,
    data: {
      inventoryItemId: item.id,
      itemName: item.name,
      category: item.category || item.type,
    },
    link: `/inventory`,
  };
};

/**
 * Create a notification for an activated effect
 * @param {string} effectType - Type of effect (xp_boost, dictionary, streak_saver, etc.)
 * @param {string} effectName - Display name of the effect
 * @param {Date|null} expiryDate - When the effect expires (if applicable)
 * @returns {Object} The notification object
 */
export const createEffectActivatedNotification = (
  effectType,
  effectName,
  expiryDate = null
) => {
  let message = `${effectName} has been activated.`;

  if (expiryDate) {
    // Format the expiry date
    const formattedDate = expiryDate.toLocaleDateString();
    const formattedTime = expiryDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    message += ` Active until ${formattedDate} at ${formattedTime}.`;
  }

  return {
    title: "Power-Up Activated",
    message,
    type: SHOP_NOTIFICATION_TYPES.EFFECT_ACTIVATED,
    data: {
      effectType,
      effectName,
      expiryDate: expiryDate ? expiryDate.toISOString() : null,
    },
    link: `/inventory`,
  };
};

/**
 * Create a notification for an expired effect
 * @param {string} effectType - Type of effect (xp_boost, dictionary, streak_saver, etc.)
 * @param {string} effectName - Display name of the effect
 * @returns {Object} The notification object
 */
export const createEffectExpiredNotification = (effectType, effectName) => {
  return {
    title: "Power-Up Expired",
    message: `Your ${effectName} has expired.`,
    type: SHOP_NOTIFICATION_TYPES.EFFECT_EXPIRED,
    data: {
      effectType,
      effectName,
    },
    link: `/shop`,
  };
};

/**
 * Create a notification for received coins
 * @param {number} amount - The amount of coins received
 * @param {string} source - Where the coins came from
 * @returns {Object} The notification object
 */
export const createCoinsReceivedNotification = (amount, source) => {
  // Format source for display
  const formattedSource = source
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return {
    title: "Coins Received",
    message: `You've received ${amount} coins from ${formattedSource}.`,
    type: SHOP_NOTIFICATION_TYPES.COINS_RECEIVED,
    data: {
      amount,
      source,
    },
    link: `/shop`,
  };
};

/**
 * Create a notification for low coins
 * @param {number} balance - Current coin balance
 * @returns {Object} The notification object
 */
export const createLowCoinsNotification = (balance) => {
  return {
    title: "Low Coin Balance",
    message: `You only have ${balance} coins remaining. Visit the Shop to learn how to earn more.`,
    type: SHOP_NOTIFICATION_TYPES.LOW_COINS,
    data: {
      balance,
    },
    link: `/shop`,
  };
};

/**
 * Hook to easily send shop-related notifications
 */
export const useShopNotifications = () => {
  const { addNotification } = useNotifications();
  const { sendNotification } = useNotificationTemplates();

  // Send a notification for a successful purchase
  const sendPurchaseConfirmedNotification = (product, price) => {
    // Try to use the template first
    const fromTemplate = sendNotification("PURCHASE_CONFIRMED", {
      item: product.name,
    });

    // If template fails or doesn't exist, use our custom notification
    if (!fromTemplate) {
      return addNotification(
        createPurchaseConfirmedNotification(product, price)
      );
    }

    return fromTemplate;
  };

  // Send a notification for a successfully used item
  const sendItemUsedNotification = (item) => {
    return addNotification(createItemUsedNotification(item));
  };

  // Send a notification for an activated effect
  const sendEffectActivatedNotification = (
    effectType,
    effectName,
    expiryDate = null
  ) => {
    return addNotification(
      createEffectActivatedNotification(effectType, effectName, expiryDate)
    );
  };

  // Send a notification for an expired effect
  const sendEffectExpiredNotification = (effectType, effectName) => {
    return addNotification(
      createEffectExpiredNotification(effectType, effectName)
    );
  };

  // Send a notification for received coins
  const sendCoinsReceivedNotification = (amount, source) => {
    return addNotification(createCoinsReceivedNotification(amount, source));
  };

  // Send a notification for low coins
  const sendLowCoinsNotification = (balance) => {
    // Only show this notification if we haven't shown it recently
    const lowCoinsKey = "low_coins_notified";
    if (!sessionStorage.getItem(lowCoinsKey)) {
      sessionStorage.setItem(lowCoinsKey, "true");

      // Clear this notification flag if they visit the shop
      setTimeout(() => {
        sessionStorage.removeItem(lowCoinsKey);
      }, 3600000); // Clear after 1 hour

      return addNotification(createLowCoinsNotification(balance));
    }
    return null;
  };

  return {
    sendPurchaseConfirmedNotification,
    sendItemUsedNotification,
    sendEffectActivatedNotification,
    sendEffectExpiredNotification,
    sendCoinsReceivedNotification,
    sendLowCoinsNotification,
  };
};

export default useShopNotifications;
