import { useEffect, useState } from "react";
import { useAttempts } from "../helpers/AttemptsContextProvider";
import { useLivesNotifications } from "../utils/notifications/attempts-notifications-utils";

/**
 * Enhanced hook that wraps useAttempts and adds notification functionality
 */
export const useAttemptsWithNotifications = () => {
  const attempts = useAttempts();
  const {
    sendLivesReplenishedNotification,
    sendLivesEarnedNotification,
    sendLivesLowNotification,
    sendLivesDepletedNotification,
  } = useLivesNotifications();

  // Track previous state to detect changes
  const [prevAttempts, setPrevAttempts] = useState(attempts.attempts.count);
  const [prevRefreshing, setPrevRefreshing] = useState(attempts.isRefreshing);

  // Detect when lives are replenished to maximum
  useEffect(() => {
    const maxAttempts = 5; // This should match your system's maxAttempts
    const currentAttempts = attempts.attempts.count;

    // console.log("Lives state changed:", {
    //   current: currentAttempts,
    //   previous: prevAttempts,
    //   refreshing: attempts.isRefreshing,
    //   prevRefreshing,
    // });

    // Check if refreshing state just turned from true to false (refresh completed)
    if (prevRefreshing && !attempts.isRefreshing) {
      // And if attempts went from less than max to max
      if (prevAttempts < maxAttempts && currentAttempts === maxAttempts) {
        // Send notification for fully replenished lives
        sendLivesReplenishedNotification(maxAttempts);
        console.log("Lives fully replenished notification sent");
      }
    }

    // Check if lives are low (1 or 2 left) but not depleted - also trigger if state loads directly at low lives
    if (
      (prevAttempts > currentAttempts || prevAttempts === 0) &&
      currentAttempts <= 2 &&
      currentAttempts > 0
    ) {
      // Only send notification if we haven't sent one for this specific count yet
      const lowLivesKey = `low_lives_notified_${currentAttempts}`;
      if (!sessionStorage.getItem(lowLivesKey)) {
        sendLivesLowNotification(currentAttempts);
        console.log(
          `Lives running low notification sent (${currentAttempts} lives)`
        );
        // Remember we've sent this notification
        sessionStorage.setItem(lowLivesKey, "true");

        // Clear notification record if lives increase
        if (currentAttempts < 2) {
          sessionStorage.removeItem("low_lives_notified_2");
        }
      }
    }

    // Check if lives are completely depleted - special case handling for initial state at 0
    if (
      (prevAttempts > 0 && currentAttempts === 0) ||
      (prevAttempts === 0 &&
        currentAttempts === 0 &&
        !sessionStorage.getItem("depleted_notified"))
    ) {
      // Avoid duplicate notifications in the same session
      if (!sessionStorage.getItem("depleted_notified")) {
        sendLivesDepletedNotification(attempts.attempts.nextRegeneration);
        console.log("Lives depleted notification sent");
        sessionStorage.setItem("depleted_notified", "true");
      }
    }

    // Clear notification records if lives are replenished
    if (currentAttempts > 2) {
      sessionStorage.removeItem("low_lives_notified_1");
      sessionStorage.removeItem("low_lives_notified_2");
    }

    if (currentAttempts > 0) {
      sessionStorage.removeItem("depleted_notified");
    }

    // Update previous values for next comparison
    setPrevAttempts(currentAttempts);
    setPrevRefreshing(attempts.isRefreshing);
  }, [
    attempts.attempts.count,
    attempts.isRefreshing,
    prevAttempts,
    prevRefreshing,
    sendLivesReplenishedNotification,
    sendLivesLowNotification,
    sendLivesDepletedNotification,
  ]);

  // Enhanced recordPracticeSession that sends notifications
  const recordPracticeSessionWithNotification = async () => {
    const result = await attempts.recordPracticeSession();

    // If lives were earned from practice, send a notification
    if (result.regeneratedLives > 0) {
      // Get the current attempts count after the update
      const currentAttempts = attempts.attempts.count;
      sendLivesEarnedNotification(result.regeneratedLives, currentAttempts);
      console.log(
        `Notification sent for earning ${result.regeneratedLives} lives from practice`
      );
    }

    return result;
  };

  // Enhanced deductAttempt function that directly sends notifications
  const deductAttemptWithNotification = async () => {
    try {
      // Get current attempts before deduction
      const currentAttempts = attempts.attempts.count;

      // First execute the regular deductAttempt
      await attempts.deductAttempt();

      // Check if we should show a notification based on the new count
      // We predict what the count will be after deduction
      const newCount = Math.max(0, currentAttempts - 1);

      // Log the change
      console.log("Lives deducted:", {
        before: currentAttempts,
        after: newCount,
      });

      // Directly send appropriate notification based on the new state
      if (newCount === 0) {
        // If this took us to 0, send the depleted notification
        if (!sessionStorage.getItem("depleted_notified")) {
          // Wait a brief moment to ensure state has updated
          setTimeout(() => {
            sendLivesDepletedNotification(attempts.attempts.nextRegeneration);
            console.log("Direct notification: Lives depleted");
            sessionStorage.setItem("depleted_notified", "true");
          }, 300);
        }
      } else if (newCount <= 2) {
        // If this took us to 1 or 2, send the low notification
        const lowLivesKey = `low_lives_notified_${newCount}`;
        if (!sessionStorage.getItem(lowLivesKey)) {
          // Wait a brief moment to ensure state has updated
          setTimeout(() => {
            sendLivesLowNotification(newCount);
            console.log(`Direct notification: Lives running low (${newCount})`);
            sessionStorage.setItem(lowLivesKey, "true");
          }, 300);
        }
      }

      // Return the result
      return newCount;
    } catch (error) {
      console.error("Error in deductAttemptWithNotification:", error);
      throw error;
    }
  };

  // Return original methods plus enhanced ones
  return {
    ...attempts,
    recordPracticeSessionWithNotification,
    deductAttemptWithNotification,
  };
};
