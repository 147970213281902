import React, { useEffect, useState } from "react";

import Footer from "../../components/footer/footer.component";
import Login from "../../components/login-register/login.component";

import { motion } from "framer-motion";
import PuffLoader from "react-spinners/PuffLoader";
import FeedbackMenu from "../../components/support/feedback-menu";

function LoginPage() {
  useEffect(() => {
    document.title = "Login | Fast Fluency Africa";
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return loading ? (
    <div
      className="flex flex-row justify-center items-center h-screen"
      data-testid="puff-loader"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div
        className="bg-gradient-to-b from-gold to-white"
        aria-label="login-component"
      >
        <Login />
        <FeedbackMenu />
      </div>
      <footer
        className="bg-gradient-to-b from-white to-gold mt-16 w-full"
        aria-label="footer"
      >
        <Footer />
      </footer>
    </motion.div>
  );
}

export default LoginPage;
