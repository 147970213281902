import React from "react";
import {
  AlertCircle,
  CheckCircle,
  XCircle,
  Loader2,
  ExternalLink,
} from "lucide-react";
import { useBooking } from "../../../helpers/BookingsContext";

/**
 * Component to display payment status notifications
 * This appears after returning from PayFast payment gateway
 */
const PaymentNotification = () => {
  const { pendingBooking, clearPendingBooking } = useBooking();

  if (!pendingBooking) return null;

  const getStatusIcon = () => {
    switch (pendingBooking.status) {
      case "success":
        return <CheckCircle className="h-5 w-5 sm:h-6 sm:w-6 text-green" />;
      case "failed":
        return <XCircle className="h-5 w-5 sm:h-6 sm:w-6 text-red-500" />;
      case "pending":
        return (
          <Loader2 className="h-5 w-5 sm:h-6 sm:w-6 text-gold animate-spin" />
        );
      case "error":
      default:
        return <AlertCircle className="h-5 w-5 sm:h-6 sm:w-6 text-red-500" />;
    }
  };

  const getStatusColor = () => {
    switch (pendingBooking.status) {
      case "success":
        return "bg-green bg-opacity-10 border-green";
      case "failed":
        return "bg-red-100 border-red-400";
      case "pending":
        return "bg-yellow-50 border-gold";
      case "error":
      default:
        return "bg-red-100 border-red-400";
    }
  };

  return (
    <div
      className={`mb-4 sm:mb-6 p-3 sm:p-4 rounded-md border ${getStatusColor()}`}
    >
      <div className="flex flex-col sm:flex-row">
        <div className="flex items-start">
          <div className="flex-shrink-0 mt-0.5">{getStatusIcon()}</div>
          <div className="ml-3 flex-1">
            <h3 className="text-sm font-medium">
              {pendingBooking.status === "success"
                ? "Payment Successful"
                : pendingBooking.status === "failed"
                ? "Payment Failed"
                : pendingBooking.status === "pending"
                ? "Payment Processing"
                : "Payment Status Unknown"}
            </h3>
            <div className="mt-1 sm:mt-2 text-xs sm:text-sm">
              <p>{pendingBooking.message}</p>

              {pendingBooking.status === "success" &&
                pendingBooking.meetingLink && (
                  <div className="mt-2">
                    <a
                      href={pendingBooking.meetingLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-green hover:underline"
                    >
                      Join Meeting <ExternalLink className="ml-1 h-4 w-4" />
                    </a>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 sm:mt-4 flex justify-end">
        <button
          type="button"
          onClick={clearPendingBooking}
          className="py-1.5 px-3 rounded text-sm font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors"
          aria-label="Dismiss notification"
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default PaymentNotification;
