import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Footer from "../../components/footer/footer.component";
import FeedbackMenu from "../../components/support/feedback-menu";
import { PuffLoader } from "react-spinners";
import { Link } from "react-router-dom";
import FourMomentsTimeline from "../../components/four-moments/timeline";

// Background geometric patterns component
const GeometricPatterns = () => (
  <>
    <div className="absolute top-20 left-0 w-40 h-40 rotate-45 bg-red/5 rounded-lg z-0"></div>
    <div className="absolute top-60 right-20 w-32 h-32 rotate-12 bg-gold/5 rounded-lg z-0"></div>
    <div className="absolute top-1/3 left-1/4 w-24 h-24 -rotate-12 bg-green/5 rounded-lg z-0"></div>
    <div className="absolute bottom-1/4 right-0 w-36 h-36 rotate-45 bg-black/5 rounded-lg z-0"></div>
    <div className="absolute bottom-20 left-16 w-28 h-28 rotate-20 bg-gold/5 rounded-lg z-0"></div>
  </>
);

const FourthMomentPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "The Fourth Moment of the Sun | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, []);

  return loading ? (
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col min-h-screen"
    >
      {/* Header Section */}
      <div className="bg-gradient-to-r from-red to-gold text-white">
        <NavBarPrimary />
        <FeedbackMenu />

        <div className="max-w-7xl mx-auto px-4 py-16 md:py-20 lg:py-24 w-5/6">
          <div className="text-center md:text-left md:flex md:items-center md:justify-between">
            <div className="md:max-w-2xl">
              <motion.h1
                className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-headers uppercase"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                The Fourth Moment of the Sun
              </motion.h1>
              <motion.p
                className="text-xl md:text-2xl mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                A Pan-African Vision for 2050
              </motion.p>
            </div>
          </div>
        </div>

        {/* Wave divider */}
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full h-auto"
          >
            <path
              fill="#FCFCEE"
              fillOpacity="1"
              d="M0,32L48,42.7C96,53,192,75,288,74.7C384,75,480,53,576,48C672,43,768,53,864,58.7C960,64,1056,64,1152,56C1248,48,1344,32,1392,24L1440,16L1440,100L1392,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
            />
          </svg>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow bg-[#FCFCEE] relative overflow-hidden">
        {/* Global page background patterns */}
        <GeometricPatterns />

        <div className="max-w-6xl mx-auto px-4 py-8 w-5/6 relative z-10">
          {" "}
          {/* Introduction to Kongo Cosmology */}
          <section className="mb-20 pt-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.7 }}
              className="relative bg-white border-2 border-b-4 border-red rounded-lg p-8 mb-16 shadow-lg"
            >
              <div className="absolute -top-6 -right-6 w-16 h-16 bg-red rounded-sm rotate-45 flex items-center justify-center shadow-lg">
                <span className="text-white text-3xl -rotate-45">☀️</span>
              </div>

              <h2 className="text-3xl font-bold text-red mb-6 font-headers">
                Kongo Cosmology & Our Vision
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                {/* Background geometric shapes */}
                <div className="absolute -top-16 -left-16 w-40 h-40 rotate-45 bg-red/5 rounded-lg z-0"></div>
                <div className="absolute top-1/4 -right-8 w-24 h-24 rotate-12 bg-gold/5 rounded-lg z-0"></div>
                <div className="absolute bottom-0 left-1/4 w-32 h-32 -rotate-12 bg-green/5 rounded-lg z-0"></div>

                <div className="relative z-10">
                  <p className="text-lg mb-4">
                    In Kongo cosmology, the{" "}
                    <span className="font-bold text-red">
                      Four Moments of the Sun
                    </span>{" "}
                    trace a cosmic diamond about the twinned worlds of the
                    living and the dead, circumscribed by the journey of the
                    sun.
                  </p>
                  <p className="text-lg mb-4">
                    The four moments are:{" "}
                    <span className="font-bold text-red">dawn</span>,{" "}
                    <span className="font-bold text-gold">noon</span>,{" "}
                    <span className="font-bold text-green">dusk</span>, and{" "}
                    <span className="font-bold text-black">midnight</span> (when
                    the sun, so it is believed, is shining on the kingdom of the
                    dead).
                  </p>
                  <p className="text-lg mb-4">
                    This cosmological diamond forms the foundation of our
                    business philosophy. The right hand sphere stands for dawn
                    which is the sign of a life beginning. Noon, the uppermost
                    position, indicates the flourishing of life and ascendant
                    power.
                  </p>
                  <p className="text-lg mb-4">
                    By the inevitable organic process, comes change and flux at
                    sunset, and finally midnight, marking the transition to
                    another realm. And then, assuming a cycle well traveled, we
                    may return within another dawn, emerging from the midnight
                    world to begin anew.
                  </p>
                  <p className="text-lg">
                    Just as the sun's four moments create a cycle of renewal and
                    rebirth, our business strategy follows this spiral of
                    development to create a sustainable model for preserving and
                    promoting African languages, culture, and stories by 2050.
                  </p>
                </div>

                <div className="relative z-10">
                  <div className="relative w-full aspect-square border-2 border-b-4 border-gray rounded-lg shadow-lg overflow-hidden">
                    <div className="absolute inset-0 bg-black/5"></div>

                    {/* Diamond Shape Overlay */}
                    {/* <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-3/4 h-3/4 rotate-45 border-2 border-dashed border-gray/60"></div>
                    </div> */}

                    {/* Sun Cycle Diagram */}
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="relative w-4/5 h-4/5 rounded-full border border-dashed border-gray/50">
                        {/* Connected lines for diamond shape */}
                        <div className="absolute inset-0">
                          <svg width="100%" height="100%" viewBox="0 0 200 200">
                            <line
                              x1="100"
                              y1="0"
                              x2="200"
                              y2="100"
                              stroke="rgba(128,128,128,0.3)"
                              strokeWidth="1"
                            />
                            <line
                              x1="200"
                              y1="100"
                              x2="100"
                              y2="200"
                              stroke="rgba(128,128,128,0.3)"
                              strokeWidth="1"
                            />
                            <line
                              x1="100"
                              y1="200"
                              x2="0"
                              y2="100"
                              stroke="rgba(128,128,128,0.3)"
                              strokeWidth="1"
                            />
                            <line
                              x1="0"
                              y1="100"
                              x2="100"
                              y2="0"
                              stroke="rgba(128,128,128,0.3)"
                              strokeWidth="1"
                            />
                          </svg>
                        </div>

                        {/* Dawn Point */}
                        <div className="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2">
                          <div className="w-12 h-12 rounded-full bg-red flex items-center justify-center text-white font-bold shadow-md">
                            1
                          </div>
                        </div>

                        {/* Noon Point */}
                        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <div className="w-12 h-12 rounded-full bg-gold flex items-center justify-center text-white font-bold shadow-md">
                            2
                          </div>
                        </div>

                        {/* Sunset Point */}
                        <div className="absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <div className="w-12 h-12 rounded-full bg-green flex items-center justify-center text-white font-bold shadow-md">
                            3
                          </div>
                        </div>

                        {/* Midnight Point */}
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                          <div className="w-12 h-12 rounded-full bg-black flex items-center justify-center text-white font-bold shadow-md">
                            4
                          </div>
                        </div>

                        {/* Center emblem */}
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="w-16 h-16 rounded-sm rotate-45 bg-white border-2 border-gray flex items-center justify-center shadow-lg">
                            <span className="text-2xl -rotate-45">🌍</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Labels */}
                    {/* <div className="absolute right-6 top-1/2 transform -translate-y-1/2 text-right">
                      <p className="font-bold text-red">Dawn</p>
                      <p className="text-sm text-red">Language</p>
                    </div>
                    <div className="absolute top-6 left-1/2 transform -translate-x-1/2 text-center">
                      <p className="font-bold text-gold">Noon</p>
                      <p className="text-sm text-gold">Culture</p>
                    </div>
                    <div className="absolute left-6 top-1/2 transform -translate-y-1/2">
                      <p className="font-bold text-green">Sunset</p>
                      <p className="text-sm text-green">Stories</p>
                    </div>
                    <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 text-center">
                      <p className="font-bold text-black">Midnight</p>
                      <p className="text-sm text-black">Digital Renaissance</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.7, delay: 0.2 }}
              className="text-center"
            >
              <h2 className="text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
                Our Pan-African Pilgrimage
              </h2>
              <p className="text-lg md:text-xl max-w-3xl mx-auto mb-8">
                We are on a journey to create a comprehensive ecosystem that
                serves as a bridge between traditional African wisdom and modern
                innovation. By 2050, we aim to document, preserve, and
                revitalize all African languages, cultural practices, and
                stories.
              </p>
              <div className="inline-block bg-white/80 rounded-lg border-2 border-b-4 border-black p-4 shadow-lg">
                <span className="text-2xl font-bold">
                  The Fun Way to Know Africa
                </span>
              </div>
            </motion.div>
          </section>
          {/* Four Moments Timeline */}
          <section className="mb-20 max-w-6xl mx-auto">
            <FourMomentsTimeline />
          </section>
          {/* Conclusion & Call to Action */}
          <section className="mb-20 relative">
            {/* Decorative diamond patterns */}
            <div className="absolute top-8 left-16 w-32 h-32 rotate-45 bg-white/5 rounded-lg z-0"></div>
            <div className="absolute bottom-12 right-24 w-40 h-40 rotate-20 bg-white/5 rounded-lg z-0"></div>

            <motion.div
              className="bg-gradient-to-r from-red to-gold rounded-lg py-12 px-6 shadow-xl text-white relative z-10 border-2 border-b-4 border-white/20"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.7 }}
            >
              <div className="relative z-10">
                <h2 className="text-3xl font-bold text-center font-headers mb-6">
                  Join Our Pan-African Pilgrimage
                </h2>
                <p className="text-xl max-w-3xl mx-auto text-center mb-8">
                  The sign of the four moments of the sun is the Kongo emblem of
                  spiritual continuity and renaissance par excellence. Just as
                  shells repeat within their spiral structure the wheeling of
                  the sun through time and space, our journey continues toward a
                  vibrant African future.
                </p>
                <div className="flex flex-wrap justify-center gap-4">
                  <Link
                    to="/early-access"
                    className="px-8 py-3 bg-white text-red font-bold rounded-md border-2 border-b-4 border-white shadow-lg hover:bg-white/90 transition-all transform hover:scale-105"
                  >
                    Join Our Waitlist
                  </Link>
                  <Link
                    to="/about"
                    className="px-8 py-3 bg-transparent text-white font-bold rounded-md border-2 border-b-4 border-white shadow-lg hover:bg-white/10 transition-all transform hover:scale-105"
                  >
                    Learn More About Us
                  </Link>
                </div>
              </div>
            </motion.div>
          </section>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-b from-white to-gold w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default FourthMomentPage;
