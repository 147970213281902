import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import CoachDashboard from "../coach/coach-dashboard";
import StudentDashboard from "../student/student-dashboard";
import CoachAvailabilityCalendar from "../coach/availability-calendar";
import StudentBookingCalendar from "../student-booking-calendar";
import CoachEarningsReport from "../coach/earning-report";
import CoachDirectory from "../student/coach-directory";
import BookingNavigation from "./booking-navigation";
import BookingBreadcrumbs from "./booking-breadcrumbs";
import MobileBookingNav from "./mobile-booking-nav";
import NavigationBar from "../../navigation-bar-2/side-navigation-bar.component";
import FloatingActionMenu from "../../navigation-bar-2/floating-side-menu";
import FeedbackMenu from "../../support/feedback-menu";
import BookingSuccess from "../shared/booking-success";
import BookingCancel from "../shared/booking-cancel";
import ManualBookingFix from "../manual-booking-fix";
import ZoomFixTool from "../shared/zoom-fix-tool";

// Wrapper component to handle dynamic coach booking
const BookCoachWrapper = () => {
  const { coachId } = useParams();
  const [coachExists, setCoachExists] = useState(null);
  const [coachData, setCoachData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkCoachExists = async () => {
      try {
        const coachRef = doc(db, "users", coachId);
        const coachSnap = await getDoc(coachRef);

        if (coachSnap.exists() && coachSnap.data().role === "coach") {
          setCoachExists(true);
          setCoachData(coachSnap.data());
        } else {
          setCoachExists(false);
        }
      } catch (error) {
        console.error("Error checking coach existence:", error);
        setCoachExists(false);
      } finally {
        setLoading(false);
      }
    };

    checkCoachExists();
  }, [coachId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full py-8 sm:py-16">
        <div className="animate-spin rounded-full h-10 w-10 sm:h-12 sm:w-12 border-t-2 border-b-2 border-green"></div>
      </div>
    );
  }

  if (coachExists === false) {
    return <Navigate to="/booking/coaches" replace />;
  }

  return (
    <>
      <BookingBreadcrumbs coachName={coachData?.name} />
      <StudentBookingCalendar coachId={coachId} />
    </>
  );
};

const BookingRouter = () => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  // Detect mobile viewport
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkMobile();

    // Listen for resize events
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const getUserRole = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setLoading(false);
          return;
        }

        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserRole(userData.role || "student");
        } else {
          setUserRole("student"); // Default to student
        }

        setLoading(false);
      } catch (error) {
        console.error("Error getting user role:", error);
        setLoading(false);
      }
    };

    getUserRole();
  }, []);

  // Special paths that should have simplified layout
  const isSpecialPath = [
    "/booking/success",
    "/booking/cancel",
    "/booking/fix-booking",
    "/booking/fix-zoom",
  ].some((path) => location.pathname.startsWith(path));

  // Booking with coach path
  const isBookingWithCoach = location.pathname.includes("/booking/book/");

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full py-8 sm:py-16">
        <div className="animate-spin rounded-full h-10 w-10 sm:h-12 sm:w-12 border-t-2 border-b-2 border-green"></div>
      </div>
    );
  }

  return (
    <div className="pb-16 md:pb-0">
      {" "}
      {/* Add padding for mobile nav bar */}
      {!isSpecialPath && <NavigationBar />}
      {!isSpecialPath && !isMobile && <FloatingActionMenu />}
      {!isSpecialPath && !isMobile && <FeedbackMenu />}
      {/* Only show navigation if we're not in special paths */}
      {!isBookingWithCoach && !isSpecialPath && (
        <BookingNavigation userRole={userRole} />
      )}
      <Routes>
        {/* IMPORTANT: Use relative paths (no leading slash) for nested routes */}

        {/* Routes available to both coaches and students */}
        <Route path="coaches" element={<CoachDirectory />} />
        <Route path="book/:coachId" element={<BookCoachWrapper />} />

        {/* Change these to relative paths for consistency */}
        <Route path="cancel" element={<BookingCancel />} />
        <Route path="success" element={<BookingSuccess />} />

        {/* Add the manual booking fix tool */}
        <Route path="fix-booking" element={<ManualBookingFix />} />

        {/* Admin routes */}
        <Route path="fix-zoom" element={<ZoomFixTool />} />

        {/* Coach-specific routes */}
        {userRole === "coach" && (
          <>
            <Route path="dashboard" element={<CoachDashboard />} />
            <Route
              path="availability"
              element={<CoachAvailabilityCalendar />}
            />
            <Route path="earnings" element={<CoachEarningsReport />} />
          </>
        )}

        {/* Student-specific routes */}
        {userRole === "student" && (
          <>
            <Route path="dashboard" element={<StudentDashboard />} />
          </>
        )}

        {/* Default route based on role */}
        <Route
          path="*"
          element={
            <Navigate
              to={userRole === "coach" ? "dashboard" : "coaches"}
              replace
            />
          }
        />
      </Routes>
      {/* Mobile Navigation */}
      <MobileBookingNav userRole={userRole} />
    </div>
  );
};

export default BookingRouter;
