import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../firebase/firebase.utils";
import { serverTimestamp } from "firebase/firestore";
import { useAuth } from "../../helpers/UserAuthContextProvider";

const partsOfSpeech = [
  "Noun",
  "Pronoun",
  "Verb",
  "Adjective",
  "Adverb",
  "Preposition",
  "Other…",
];

const DictionaryFeedbackModal = () => {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const timestamp = serverTimestamp();
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const openModal = () => {
    setIsOpen(true);
    // Prevent body scrolling when modal is open
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    // Re-enable body scrolling when modal is closed
    document.body.style.overflow = "";
  };

  // Additional form fields
  const [feedbackData, setFeedbackData] = useState({
    language: "",
    entry: "",
    pronunciationWritten: "",
    partOfSpeech: "",
    englishTranslations: "",
    definitions: "",
    examplesInASentence: "",
    synonyms: "",
    antonyms: "",
  });

  const handleInputChange = (event) => {
    setFeedbackData({
      ...feedbackData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const feedbackRef = collection(db, "feedback");
      const newFeedbackData = {
        userId: `${user?.uid}`,
        learningMaterialState: "dictionary",
        timestamp: timestamp,
        ...feedbackData,
      };
      await addDoc(feedbackRef, newFeedbackData);
      setFeedbackData({
        language: "",
        entry: "",
        pronunciationWritten: "",
        partOfSpeech: "",
        englishTranslations: "",
        definitions: "",
        examplesInASentence: "",
        synonyms: "",
        antonyms: "",
      });
      setSubmitMessage("Thank you! Your feedback has been received!");
    } catch (error) {
      console.error("Error creating feedback:", error);
      setSubmitMessage("Thank you! Your feedback has been received!");
    }
  };

  const formSubmission = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await handleSubmit(event);
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    setTimeout(() => {
      setLoading(false);
      setSubmitMessage("");
      closeModal();
    }, 750);
  };

  return (
    <div>
      <div className="feedback-icon rounded cursor-pointer" onClick={openModal}>
        <span className="font-body text-red hover:text-green text-xs sm:text-sm lg:text-base mt-4 font-semibold">
          complete this form here.
        </span>
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-3 sm:p-4">
          <div className="bg-white border-b-4 border-2 rounded p-4 w-full max-w-md max-h-[90vh] overflow-y-auto">
            <span
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-black text-2xl bg-white cursor-pointer border-2 border-black rounded px-2 sm:px-3 border-b-4 z-10"
              onClick={closeModal}
            >
              &times;
            </span>
            {!submitMessage ? (
              <form
                onSubmit={(event) => formSubmission(event)}
                className="pt-6"
              >
                <div className="space-y-3">
                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      Relevant Language
                    </label>
                    <p className="text-black text-xs font-light">
                      Specify which language you are making a submission for.
                    </p>
                    <input
                      type="text"
                      name="language"
                      onChange={handleInputChange}
                      value={feedbackData.language}
                      className="w-full mt-1 p-2 border border-gray rounded"
                    />
                  </div>

                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      Entry
                    </label>
                    <p className="text-black text-xs font-light">
                      Provide the written African word, proverb, expression, or
                      otherwise that you would like us to consider.
                    </p>
                    <input
                      type="text"
                      name="entry"
                      onChange={handleInputChange}
                      value={feedbackData.entry}
                      className="w-full mt-1 p-2 border border-gray rounded"
                    />
                  </div>

                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      Pronunciation (Written)
                    </label>
                    <p className="text-black text-xs font-light">
                      Add a written pronunciation in the form that emphasizes
                      the sounds of each syllable i.e. for "isizulu" →
                      "ee-see-zoo-loo".
                    </p>
                    <input
                      type="text"
                      name="pronunciationWritten"
                      onChange={handleInputChange}
                      value={feedbackData.pronunciationWritten}
                      className="w-full mt-1 p-2 border border-gray rounded"
                    />
                  </div>

                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      Part of Speech
                    </label>
                    <p className="text-black text-xs font-light">
                      Where the entry can be multiple parts of speech please
                      select 'Other'.
                    </p>
                    <select
                      name="partOfSpeech"
                      onChange={handleInputChange}
                      value={feedbackData.partOfSpeech}
                      className="text-sm w-full mt-1 p-2 border border-gray rounded"
                    >
                      <option value="" disabled>
                        Select part of speech
                      </option>
                      {partsOfSpeech.map((part) => (
                        <option key={part} value={part}>
                          {part}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      English Translations
                    </label>
                    <p className="text-black text-xs font-light">
                      Provide at least two different one word English equivalent
                      translations for the entry.
                    </p>
                    <input
                      type="text"
                      name="englishTranslations"
                      onChange={handleInputChange}
                      value={feedbackData.englishTranslations}
                      className="w-full mt-1 p-2 border border-gray rounded"
                    />
                  </div>

                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      Definitions
                    </label>
                    <p className="text-black text-xs font-light">
                      Please give at least two short one sentence definitions in
                      English for the entry.
                    </p>
                    <input
                      type="text"
                      name="definitions"
                      onChange={handleInputChange}
                      value={feedbackData.definitions}
                      className="w-full mt-1 p-2 border border-gray rounded"
                    />
                  </div>

                  <div className="text-left">
                    <label className="block text-sm text-black font-semibold">
                      Examples in a Sentence
                    </label>
                    <p className="text-black text-xs font-light">
                      Use the entry in at least two simple sentences.
                    </p>
                    <input
                      type="text"
                      name="examplesInASentence"
                      onChange={handleInputChange}
                      value={feedbackData.examplesInASentence}
                      className="w-full mt-1 p-2 border border-gray rounded"
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value={loading ? "Loading..." : "Submit"}
                  className="disabled:text-gray disabled:bg-darkgray disabled:cursor-not-allowed mt-4 w-full sm:min-w-1/3 capitalize cursor-pointer text-xs sm:text-sm block py-2 px-4 font-body text-center font-bold disabled:border-gray text-black bg-green border-black rounded border-b-4 border-2 disabled:border-b-4 disabled:border-2"
                  disabled={!feedbackData.language || !feedbackData.entry}
                />
              </form>
            ) : (
              <div className="message text-green text-center py-6">
                {submitMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DictionaryFeedbackModal;
