import React from "react";
import { DialogueFlowProvider } from "../../../helpers/DialogueFlowContext";
import InteractiveDialogue from "./interactive-text.component";
import PropTypes from "prop-types";

const DialogueContainer = ({ onComplete }) => {
  return (
    <DialogueFlowProvider>
      <InteractiveDialogue onDialogueComplete={onComplete} />
    </DialogueFlowProvider>
  );
};

DialogueContainer.propTypes = {
  onComplete: PropTypes.func,
};

DialogueContainer.defaultProps = {
  onComplete: () => {},
};

export default DialogueContainer;
