import React from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import MilestoneHub from "./milestone-hub";
import MilestoneDetails from "./milestone-details";
import { milestoneData } from "../../../zombiedata/milestoneData";
import { FiArrowLeft } from "react-icons/fi";

// Individual milestone details page with URL parameter
const MilestoneDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const milestone = milestoneData[id];

  // Handle navigation back to hub
  const handleBack = () => {
    navigate("/milestones");
  };

  if (!milestone) {
    return (
      <div className="min-h-screen bg-white p-6">
        <button
          onClick={handleBack}
          className="flex items-center text-red hover:text-black transition-colors"
        >
          <FiArrowLeft className="mr-2" /> Back to Milestones
        </button>
        <div className="mt-8 text-center">
          <h1 className="text-3xl font-bold text-red">Milestone Not Found</h1>
          <p className="mt-4">
            The milestone you're looking for doesn't exist or isn't available
            yet.
          </p>
        </div>
      </div>
    );
  }

  return <MilestoneDetails milestone={milestone} onBack={handleBack} />;
};

// Routes configuration component
const MilestoneRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MilestoneHub />} />
      <Route path="/:id" element={<MilestoneDetailsPage />} />
    </Routes>
  );
};

export default MilestoneRoutes;
