import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../pages/dashboard/dashboard.page.jsx";
import NotFound from "../../pages/404/404.page.jsx";
import HomePage from "../../pages/homepage/homepage.page.jsx";
import Dictionary from "../../pages/dictionary/dictionary.page.jsx";
import About from "../../pages/about/about.page.jsx";

import Articles from "../../pages/articles/articles.page.jsx";
import ArticlesIndex from "../../pages/articles/index.jsx";
import BlogPage from "../../pages/articles/articles.jsx";
import ArticlesCategoryPage from "../../pages/articles/category.jsx";
import ArticleSearchPage from "../../pages/articles/search.jsx";

import Privacy from "../../pages/privacy/privacy.page.jsx";
import Terms from "../../pages/terms/terms.page.jsx";
import VaultPage from "../../pages/vault/vault.page.jsx";
import OnboardingPage from "../../pages/onboarding/onboarding.page.jsx";
import LoginPage from "../../pages/login/login.page";
import RegisterPage from "../../pages/register/register.page.jsx";
import Sessions from "../../pages/sessions/sessions.page.jsx";
import Settings from "../../pages/settings/settings.page.jsx";
import Pricing from "../../pages/pricing/pricing.page";
import SandboxPage from "../../pages/sandbox/sandbox.page.jsx";
import AcademicsPage from "../../pages/academics/academics.page.jsx";
import CheckoutPage from "../../pages/checkout/checkout.page.jsx";
import CheckoutSuccess from "../../pages/checkout/checkout-success.page.jsx";
import CheckoutFailure from "../../pages/checkout/checkout-failure.page.jsx";
import CheckoutNotify from "../../pages/checkout/checkout-notify.page.jsx";
import ProtectedRoute from "../protected-route/protected-route.component.jsx";
import FFAQ from "../../pages/ffaqs/ffaqs.page.jsx";
import Units from "../../pages/unit/units.page.jsx";
import MilestoneDetails from "../../pages/milestones/milestones.page.jsx";
import { AnimatePresence } from "framer-motion";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";
import Guidebook from "../../pages/unit/guidebook.page.jsx";
import FlashCardPage from "../../pages/flashcards/flashcard.page.jsx";
import Shop from "../shop/shop.component.jsx";
import Inventory from "../shop/inventory.component.jsx";
import PurchaseHistory from "../shop/purchase-history.component.jsx";
import BookingRouter from "../bookings/navigation/booking-router.jsx";
import MilestoneRoutes from "../vault/milestones/milestone-routes.jsx";
import PracticeRoute from "../vault/quiz/practice-route.jsx";
import PracticePage from "../../pages/practice/practice.page.jsx";

import PowerUpTesterDashboard from "../debug/power-up-testing-dashbaord.jsx";

import ClassroomList from "../classroom/classroom-list";
import CreateClassroom from "../classroom/create-classroom";
import ClassroomDashboard from "../classroom/classroom-dashboard";
import StudentClassroomView from "../classroom/student-classroom-view";
import ClassroomAnalytics from "../classroom/classroom-analytics";
import ClassroomSettings from "../classroom/classroom-settings";
import SessionBooking from "../classroom/session-booking";
import ClassroomInitializer from "../classroom/classroom-initializer";

import SupportHome from "../../pages/support/index";
import CategoryPage from "../../pages/support/category/[category-slug]";
import ArticlePage from "../../pages/support/article/[article-id]";
import SearchPage from "../../pages/support/search";
import ContactPage from "../../pages/contact/contact.jsx";

import AdminFeedbackDashboard from "../admin/admin-feedback-dashboard.jsx";
import PowerUpProductForm from "../admin/power-up-product-form.jsx";

// Analytics Pages (NEW)
import AnalyticsDashboard from "../analytics/analytics-dashboard.component.jsx";
import UserProfileWithAnalytics from "../profile/user-profile-with-analytics.jsx";
import NotificationsPage from "../../pages/notifications/notifications.page.jsx";
import EarlyAccessPage from "../../pages/early-access/early-access.jsx";
import PowerUpInitializationService from "../../services/power-ups/power-up-initialization-service.js";
import AdminAnalyticsDashboard from "../debug/admin-analytics-dashboard.jsx";
import AdminDatabaseTool from "../admin/admin-datbase-tool.jsx";
import JoinTribePage from "../../pages/join-the-tribe/join-the-tribe.jsx";

import EarlyAccessOnboardingPage from "../../pages/early-access-onboarding/early-access-onboarding.page.jsx";
import AuthRedirectService from "../../services/auth-redirect.service.js";
import FourthMomentPage from "../../pages/fourth-moment-of-the-sun/fourth-moment-of-the-sun.jsx";
import LegacyDialogueContainer from "../vault/texts/legacy-dialogue-container.jsx";
import { useUserRole } from "../../hooks/useUserRole.jsx";
import { Navigate } from "react-router-dom";

// Protected route component for admin/coach only routes
const ProtectedStaffRoute = ({ children }) => {
  const { user } = useAuth();
  const { isAdmin, isCoach, loading } = useUserRole(user);

  // Show loading state while checking role
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  // Redirect to home if not admin or coach
  if (!isAdmin && !isCoach) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function AnimatedRoutes() {
  const location = useLocation();
  const { membership, user } = useAuth();
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.uid) {
      // Initialize power-ups when user authenticates
      PowerUpInitializationService.initialize(user.uid)
        .then((results) => {
          console.log("Power-ups initialized:", results);
        })
        .catch((error) => {
          console.error("Error initializing power-ups:", error);
        });
    }
  }, [user?.uid]);

  // useEffect(() => {
  //   const checkUserAccess = async () => {
  //     if (user && !loading) {
  //       const path = window.location.pathname;
  //       // Skip redirect check for these paths
  //       if (
  //         path === "/early-access-onboarding" ||
  //         path === "/login" ||
  //         path.startsWith("/checkout")
  //       ) {
  //         return;
  //       }

  //       const targetPath = await AuthRedirectService.getRedirectPath(user);
  //       if (targetPath !== "/dashboard" && targetPath !== path) {
  //         navigate(targetPath, { replace: true });
  //       }
  //     }
  //   };

  //   checkUserAccess();
  // }, [user, loading]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {/* Public Routes*/}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/early-access" element={<EarlyAccessPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/careers" element={<JoinTribePage />} />
        <Route
          path="/fourth-moment-of-the-sun"
          element={<FourthMomentPage />}
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Articles routes */}
        <Route path="/articles" element={<ArticlesIndex />} />
        <Route path="/articles/:articleSlug" element={<BlogPage />} />
        <Route
          path="/articles/category/:categorySlug"
          element={<ArticlesCategoryPage />}
        />
        <Route path="/articles/search" element={<ArticleSearchPage />} />

        {/* FFAQs/Support routes */}
        <Route path="/support" element={<SupportHome />} />
        <Route
          path="/support/category/:categorySlug"
          element={<CategoryPage />}
        />
        <Route path="/support/article/:articleId" element={<ArticlePage />} />
        <Route path="/support/search" element={<SearchPage />} />
        <Route path="/contact" element={<ContactPage />} />

        {/* Protected Routes */}

        {/* Admin Debug routes */}
        {user?.email === "peterkamlongera@gmail.com" ? (
          <Route
            path="/admin-feedback-dashboard"
            element={
              <ProtectedRoute>
                <AdminFeedbackDashboard />
              </ProtectedRoute>
            }
          />
        ) : null}
        {user?.email === "peterkamlongera@gmail.com" ? (
          <Route
            path="/admin-database-tool"
            element={
              <ProtectedRoute>{/* <AdminDatabaseTool /> */}</ProtectedRoute>
            }
          />
        ) : null}
        {user?.email === "peterkamlongera@gmail.com" ? (
          <Route
            path="/admin-analytics-dashboard"
            element={
              <ProtectedRoute>
                {/* <AdminAnalyticsDashboard /> */}
              </ProtectedRoute>
            }
          />
        ) : null}
        {user?.email === "peterkamlongera@gmail.com" ? (
          <Route
            path="/admin-power-up-form"
            element={
              <ProtectedRoute>{/* <PowerUpProductForm /> */}</ProtectedRoute>
            }
          />
        ) : null}
        {user?.email === "peterkamlongera@gmail.com" ? (
          <Route
            path="/testing/power-ups"
            element={
              <ProtectedRoute>
                {/* <PowerUpTesterDashboard /> */}
              </ProtectedRoute>
            }
          />
        ) : null}

        {/* Legacy dialogue route - protected for admin/coach only */}
        <Route
          path="/legacy"
          element={
            <ProtectedStaffRoute>
              <LegacyDialogueContainer />
            </ProtectedStaffRoute>
          }
        />

        {/* <Route
          path="/register"
          element={<ProtectedRoute>{<RegisterPage />}</ProtectedRoute>}
        />
        <Route
          path="/onboarding"
          element={<ProtectedRoute>{<OnboardingPage />}</ProtectedRoute>}
        /> */}
        <Route
          path="/early-access-onboarding"
          element={
            <ProtectedRoute>
              <EarlyAccessOnboardingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute>{<Dashboard />}</ProtectedRoute>}
        />
        <Route
          path="/notifications"
          element={<ProtectedRoute>{<NotificationsPage />}</ProtectedRoute>}
        />
        <Route
          path="/vault"
          element={<ProtectedRoute>{<VaultPage />}</ProtectedRoute>}
        />
        <Route
          path="/lesson"
          element={<ProtectedRoute>{<SandboxPage />}</ProtectedRoute>}
        />
        <Route
          path="/milestones/*"
          element={<ProtectedRoute>{<MilestoneRoutes />}</ProtectedRoute>}
        />

        <Route
          path="/unit-overview"
          element={<ProtectedRoute>{<Guidebook />}</ProtectedRoute>}
        />
        {/* <Route
          path="/academics"
          element={<ProtectedRoute>{<AcademicsPage />}</ProtectedRoute>}
        /> */}
        {/* <Route
          path="/milestones"
          element={
            <ProtectedRoute>
              <MilestoneDetails />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/flashcard"
          element={
            <ProtectedRoute>
              <FlashCardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/practice"
          element={<ProtectedRoute>{<PracticePage />}</ProtectedRoute>}
        />
        <Route
          path="/units"
          element={<ProtectedRoute>{<Units />}</ProtectedRoute>}
        />
        <Route
          path="/booking/*"
          element={<ProtectedRoute>{<BookingRouter />}</ProtectedRoute>}
        />
        <Route
          path="/dictionary"
          element={<ProtectedRoute>{<Dictionary />}</ProtectedRoute>}
        />
        <Route
          path="/shop"
          element={<ProtectedRoute>{<Shop />}</ProtectedRoute>}
        />
        <Route
          path="/inventory"
          element={<ProtectedRoute>{<Inventory />}</ProtectedRoute>}
        />
        <Route
          path="/purchase-history"
          element={<ProtectedRoute>{<PurchaseHistory />}</ProtectedRoute>}
        />

        {/* User Info Routes */}
        <Route
          path="/analytics"
          element={<ProtectedRoute>{<AnalyticsDashboard />}</ProtectedRoute>}
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>{<UserProfileWithAnalytics />}</ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={<ProtectedRoute>{<Settings />}</ProtectedRoute>}
        />

        {/* Classroom Routes */}
        {/* <Route
          path="/classroom-setup"
          element={<ProtectedRoute>{<ClassroomInitializer />}</ProtectedRoute>}
        /> */}
        {/* <Route
          path="/classrooms"
          element={<ProtectedRoute>{<ClassroomList />}</ProtectedRoute>}
        />
        <Route
          path="/classroom/create"
          element={
            <ProtectedRoute allowedRoles={["teacher", "admin"]}>
              <CreateClassroom />
            </ProtectedRoute>
          }
        />
        <Route
          path="/classroom/:classroomId"
          element={
            <ProtectedRoute allowedRoles={["teacher", "admin"]}>
              <ClassroomDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/classroom/:classroomId/student"
          element={<ProtectedRoute>{<StudentClassroomView />}</ProtectedRoute>}
        />
        <Route
          path="/classroom/:classroomId/analytics"
          element={
            <ProtectedRoute allowedRoles={["teacher", "admin"]}>
              <ClassroomAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/classroom/:classroomId/settings"
          element={
            <ProtectedRoute allowedRoles={["teacher", "admin"]}>
              <ClassroomSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/classroom/:classroomId/sessions"
          element={<ProtectedRoute>{<SessionBooking />}</ProtectedRoute>}
        /> */}

        {/* Checkout Routes */}
        {/* {membership === "guest" || membership === "george" ? (
          <Route
            path="/checkout"
            element={<ProtectedRoute>{<CheckoutPage />}</ProtectedRoute>}
          />
        ) : null}
        {membership === "guest" || membership === "george" ? (
          <Route
            path="/checkout-success"
            element={<ProtectedRoute>{<CheckoutSuccess />}</ProtectedRoute>}
          />
        ) : null}
        {membership === "guest" || membership === "george" ? (
          <Route
            path="/checkout-cancel"
            element={<ProtectedRoute>{<CheckoutFailure />}</ProtectedRoute>}
          />
        ) : null}
        {membership === "guest" || membership === "george" ? (
          <Route
            path="/checkout-notify"
            element={<ProtectedRoute>{<CheckoutNotify />}</ProtectedRoute>}
          />
        ) : null} */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
