import React from "react";
import PropTypes from "prop-types";
import { IoCloseSharp } from "react-icons/io5";
import { motion } from "framer-motion";
import { useContext } from "react";
import { LearningMaterialsContext } from "../../../../helpers/contexts";

const DialogueHeaderBar = ({
  handleOpenModal,
  attempts,
  currentStep,
  totalSteps,
}) => {
  const { exercisesCompleted } = useContext(LearningMaterialsContext);

  // Calculate progress as a fraction based on current step and total steps
  const getWidth = () => {
    if (totalSteps === 0) return "0/12";

    // Convert to a scale of 0-12 to match your existing component
    const progressFraction = Math.round((currentStep / totalSteps) * 12);
    return `${progressFraction}/12`;
  };

  return (
    <div className="text-2xl flex flex-row w-full justify-center items-center">
      <button className="cursor-pointer" onClick={handleOpenModal}>
        <IoCloseSharp className="text-left text-3xl text-gray hover:text-green" />
      </button>

      <div className="bg-darkgray border-gray border-2 border-b-4 h-6 sticky top-0 left-0 w-10/12 rounded">
        <motion.div
          className={`h-5 bg-green rounded-none`}
          initial={{ width: 0 }}
          animate={{ width: `calc(${getWidth()} * 100%)` }}
          exit={{ width: 0 }}
          transition={{ duration: 0.5 }}
        ></motion.div>
      </div>

      <div className="ml-2 flex items-center gap-1">
        <div className="text-lg text-outline-black">❤️</div>
        <div className="text-sm font-semibold">{attempts.count}</div>
      </div>
    </div>
  );
};

DialogueHeaderBar.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  attempts: PropTypes.shape({
    count: PropTypes.number.isRequired,
    max: PropTypes.number,
  }).isRequired,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
};

DialogueHeaderBar.defaultProps = {
  currentStep: 0,
  totalSteps: 1,
};

export default DialogueHeaderBar;
