import React, { useState, useEffect } from "react";
import { requestToken } from "../../firebase/firebase.utils";

const NotificationPermission = () => {
  const [permissionState, setPermissionState] = useState("default");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check current permission status
    setPermissionState(Notification.permission);

    // Only show the prompt if permission is not determined yet
    if (Notification.permission === "default") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const handleRequestPermission = async () => {
    try {
      // Request permission and get token
      const token = await requestToken(true);

      if (token) {
        // Update state if successful
        setPermissionState("granted");
        setIsVisible(false);

        // Show confirmation message
        alert("Notifications enabled successfully!");
      } else {
        // Update state if denied
        setPermissionState(Notification.permission);
        setIsVisible(false);
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      setPermissionState("error");
    }
  };

  const handleDismiss = () => {
    setIsVisible(false);
    // Store preference in localStorage to avoid showing again in the same session
    localStorage.setItem("notification_prompt_dismissed", "true");
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg border-b-4 border-2 border-darkgray max-w-sm z-50">
      <div className="flex items-start mb-2">
        <div className="mr-2 text-xl">🔔</div>
        <div>
          <h3 className="font-semibold text-gray-800">Enable Notifications</h3>
          <p className="text-sm text-gray-600 mt-1">
            Stay updated with important events like booking confirmations, live
            session reminders, and more!
          </p>
        </div>
      </div>

      <div className="flex justify-end space-x-2 mt-3">
        <button
          onClick={handleDismiss}
          className="px-3 py-1 text-sm text-gray-700 hover:text-gray-900"
        >
          Not Now
        </button>
        <button
          onClick={handleRequestPermission}
          className="px-3 py-1 bg-green text-white text-sm rounded hover:bg-green/90"
        >
          Enable Notifications
        </button>
      </div>
    </div>
  );
};

export default NotificationPermission;
