import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useFlashcardNotifications from "../../../utils/notifications/flashcard-notifications-utils";

function DeckUnlockNotification({ deck, onClose, onViewDeck }) {
  const [isVisible, setIsVisible] = useState(true);
  // Get notification functions if we need to send notifications outside this component
  const { sendDeckUnlockedNotification } = useFlashcardNotifications();

  useEffect(() => {
    // Auto-dismiss after 15 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  const handleViewDeck = () => {
    if (onViewDeck) onViewDeck(deck.id);
    handleClose();
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white w-11/12 sm:w-5/6 md:w-2/3 lg:max-w-md rounded border-2 border-b-4 border-darkgray overflow-hidden shadow-2xl"
            initial={{ scale: 0.8, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25 }}
          >
            <div className="bg-green text-white p-3 sm:p-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg sm:text-xl font-bold text-center flex-grow">
                  New Flashcards Unlocked!
                </h2>
                <button
                  onClick={handleClose}
                  className="text-white opacity-70 hover:opacity-100 text-xl p-1"
                >
                  ×
                </button>
              </div>
            </div>

            <div className="p-4 sm:p-6">
              <div className="mb-4 text-center">
                <motion.div
                  className="inline-block p-3 bg-green bg-opacity-10 rounded-sm -rotate-45 mb-3"
                  initial={{ rotate: -5 }}
                  animate={{ rotate: 5 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 0.5,
                  }}
                >
                  <span className="text-4xl -rotate-45">🎉</span>
                </motion.div>
                <h3 className="text-lg font-bold">{deck.name}</h3>
                <p className="text-gray-600 mt-2 text-sm sm:text-base">
                  {deck.unlockMessage ||
                    "You've unlocked a new set of flashcards!"}
                </p>
              </div>

              <div className="bg-gray-50 p-3 sm:p-4 rounded-lg text-xs sm:text-sm text-gray-600 mb-4">
                <p>
                  This deck contains {deck.cardCount} cards to help you practice
                  vocabulary related to {deck.name.toLowerCase()}.
                </p>

                {deck.unlockRequirements && (
                  <p className="mt-2">
                    <span className="font-medium">Unlocked by:</span> Completing
                    Skill {deck.unlockRequirements.skill}, Lesson{" "}
                    {deck.unlockRequirements.lesson}
                  </p>
                )}
              </div>

              <div className="flex flex-col sm:flex-row gap-3">
                <button
                  onClick={handleClose}
                  className="flex-1 px-4 py-3 rounded-md border-2 border-b-4 border-darkgray hover:bg-gray-50 text-sm sm:text-base"
                >
                  Later
                </button>

                <motion.button
                  onClick={handleViewDeck}
                  className="flex-1 px-4 py-3 bg-green text-white rounded-md border-2 border-b-4 border-darkgray hover:bg-opacity-90 text-sm sm:text-base"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                >
                  View Now
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default DeckUnlockNotification;
