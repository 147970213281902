import { createContext, useContext, useEffect, useState } from "react";
import { db, app } from "../firebase/firebase.utils";
import {
  doc,
  onSnapshot,
  runTransaction,
  setDoc,
  serverTimestamp,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useAuth } from "./UserAuthContextProvider";

const AttemptsContext = createContext();
const CLOUD_FUNCTION_URL =
  "https://attempt-handler-final-fmxsydetiq-uc.a.run.app";

export const AttemptsProvider = ({ children }) => {
  const { user } = useAuth();
  const [attempts, setAttempts] = useState({
    count: 0,
    nextRegeneration: null,
    loading: true,
    practiceSessionsCompleted: 0,
  });
  const [isRefreshing, setIsRefreshing] = useState(false);

  const executeRegenerationProtocol = async () => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (!user) throw new Error("Authentication failure - User not mobilized");

    try {
      const idToken = await user.getIdToken(true);
      const response = await fetch(CLOUD_FUNCTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Regeneration protocol failure:", error);
      throw error;
    }
  };

  const initializeAttemptsDoc = async (userId) => {
    const attemptsRef = doc(db, "userAttempts", userId);
    const docSnap = await getDoc(attemptsRef);

    if (!docSnap.exists()) {
      await setDoc(attemptsRef, {
        userId,
        maxAttempts: 5,
        currentAttempts: 5,
        regenerationInterval: 30,
        lastUsed: serverTimestamp(),
        nextRegeneration: null,
        practiceSessionsCompleted: 0,
        livesFromPractice: 0,
      });
    }

    try {
      const result = await executeRegenerationProtocol();
      const freshDoc = await getDoc(attemptsRef);

      if (freshDoc.exists()) {
        const data = freshDoc.data();
        setAttempts({
          count: data.currentAttempts,
          nextRegeneration: data.nextRegeneration?.toDate() || null,
          loading: false,
          practiceSessionsCompleted: data.practiceSessionsCompleted || 0,
        });
      }
    } catch (error) {
      console.error("Initialization failure:", error);
      throw error;
    }
  };

  // Realtime listener setup
  useEffect(() => {
    let unsubscribe;

    const initAttempts = async () => {
      if (!user?.uid) {
        setAttempts({
          count: 0,
          nextRegeneration: null,
          loading: false,
          practiceSessionsCompleted: 0,
        });
        return;
      }

      try {
        const attemptsRef = doc(db, "userAttempts", user.uid);
        const initialDoc = await getDoc(attemptsRef);

        if (initialDoc.exists()) {
          const data = initialDoc.data();
          setAttempts({
            count: data.currentAttempts,
            nextRegeneration: data.nextRegeneration?.toDate() || null,
            loading: false,
            practiceSessionsCompleted: data.practiceSessionsCompleted || 0,
          });
        }

        // Set up realtime listener for automatic updates
        unsubscribe = onSnapshot(attemptsRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            setAttempts((prev) => ({
              ...prev,
              count: data.currentAttempts,
              nextRegeneration: data.nextRegeneration?.toDate() || null,
              practiceSessionsCompleted: data.practiceSessionsCompleted || 0,
            }));
          }
        });

        await initializeAttemptsDoc(user.uid);
      } catch (error) {
        console.error("Initialization error:", error);
        setAttempts({
          count: 0,
          nextRegeneration: null,
          loading: false,
          practiceSessionsCompleted: 0,
        });
      }
    };

    initAttempts();
    return () => unsubscribe?.();
  }, [user?.uid]);

  // Enhanced refreshAttempts with debounce protection
  const refreshAttempts = async () => {
    // Prevent multiple simultaneous refresh calls
    if (isRefreshing) {
      console.log("Refresh already in progress, skipping");
      return null;
    }

    try {
      setIsRefreshing(true);
      console.log("Refreshing attempts...");
      const result = await executeRegenerationProtocol();

      // Update local state immediately for better UX
      setAttempts((prev) => ({
        ...prev,
        count: result.currentAttempts,
        nextRegeneration: result.nextRegeneration
          ? new Date(result.nextRegeneration)
          : null,
      }));

      console.log("Attempts refreshed successfully:", result);
      return result;
    } catch (error) {
      console.error("Refresh protocol failure:", error);
      throw error;
    } finally {
      setIsRefreshing(false);
    }
  };

  // Deduct attempts when a user fails a lesson
  const deductAttempt = async () => {
    if (!user?.uid) return;
    const attemptsRef = doc(db, "userAttempts", user.uid);

    try {
      await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(attemptsRef);
        if (!doc.exists()) throw new Error("Document missing");
        const data = doc.data();

        if (data.currentAttempts > 0) {
          transaction.update(attemptsRef, {
            currentAttempts: data.currentAttempts - 1,
            lastUsed: serverTimestamp(),
            // Calculate and set the next regeneration time if this is the first attempt used
            nextRegeneration:
              data.currentAttempts === data.maxAttempts
                ? new Date(Date.now() + data.regenerationInterval * 60 * 1000)
                : data.nextRegeneration,
          });
        }
      });
    } catch (error) {
      console.error("Deduction failure:", error);
    }
  };

  /**
   * Record completion of a practice session and check if attempts should be regenerated
   * Every 6 practice sessions completed will regenerate 2 lives
   */
  const recordPracticeSession = async () => {
    if (!user?.uid) return { regeneratedLives: 0, progress: 0 };
    const attemptsRef = doc(db, "userAttempts", user.uid);

    try {
      // Get current sessions count
      const docSnap = await getDoc(attemptsRef);
      if (!docSnap.exists()) throw new Error("Document missing");

      const data = docSnap.data();
      const currentSessions = data.practiceSessionsCompleted || 0;
      const newSessionsCount = currentSessions + 1;

      // Check if we reached a milestone (every 6 sessions = 2 lives)
      const livesToRegenerate = Math.floor(newSessionsCount / 6) * 2;
      const remainingSessions = newSessionsCount % 6;
      const progressPercentage = Math.floor((remainingSessions / 6) * 100);

      // Update the document
      await updateDoc(attemptsRef, {
        practiceSessionsCompleted: remainingSessions,
        livesFromPractice: increment(livesToRegenerate),
      });

      // If lives should be regenerated, add them
      if (livesToRegenerate > 0) {
        await updateDoc(attemptsRef, {
          currentAttempts: Math.min(
            data.currentAttempts + livesToRegenerate,
            data.maxAttempts
          ),
        });

        // Refresh the attempts state
        await refreshAttempts();
      }

      return {
        regeneratedLives: livesToRegenerate,
        progress: progressPercentage,
      };
    } catch (error) {
      console.error("Practice session recording failure:", error);
      return { regeneratedLives: 0, progress: 0 };
    }
  };

  return (
    <AttemptsContext.Provider
      value={{
        attempts,
        deductAttempt,
        refreshAttempts,
        recordPracticeSession,
        isRefreshing,
      }}
    >
      {children}
    </AttemptsContext.Provider>
  );
};

export const useAttempts = () => useContext(AttemptsContext);
