// contexts/ShopContext.js - Updated with ProductEffectService integration
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  arrayUnion,
  increment,
  updateDoc,
  onSnapshot, // Add this import
} from "firebase/firestore";
import { useAuth } from "./UserAuthContextProvider";

import { db } from "../firebase/firebase.utils";
import {
  purchaseProduct as purchaseProductApi,
  useInventoryItem as inventoryItemApi,
  addCoins as addCoinsApi,
  getPurchaseHistory as getPurchaseHistoryApi,
} from "../firebase/shop-api";
import ProductEffectService from "../services/power-ups/product-effect-service";
import { useShopNotifications } from "../utils/notifications/shop-notifications-utils";

// Create context
const ShopContext = createContext();

// Shop provider component
export const ShopProvider = ({ children }) => {
  const { user } = useAuth();
  const [balance, setBalance] = useState(0);
  const [inventory, setInventory] = useState([]);
  const [products, setProducts] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userStats, setUserStats] = useState(null);
  const [inventoryListener, setInventoryListener] = useState(null);

  // Add this new method to your provider
  const refreshInventory = useCallback(async () => {
    console.log("Legacy refreshInventory method called");
    return Promise.resolve();
  }, []);

  // Add the notifications hook
  const {
    sendPurchaseConfirmedNotification,
    sendItemUsedNotification,
    sendEffectActivatedNotification,
    sendEffectExpiredNotification,
    sendCoinsReceivedNotification,
    sendLowCoinsNotification,
  } = useShopNotifications();

  // STEP 6: Add an effect to monitor balance and show notification when low
  useEffect(() => {
    console.log(balance);
    // Check for low balance when it changes
    if (balance !== 0 && balance <= 10) {
      sendLowCoinsNotification(balance);
    }
  }, [balance]);

  // Add this useEffect to fetch user balance, stats, and check effect status
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        // Get user profile doc
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          // Get balance from user doc
          const userData = userDoc.data();
          setBalance(userData.coins || 0);

          // Set user stats
          setUserStats({
            dictionarySearchesRemaining:
              userData.dictionarySearchesRemaining || 0,
            dictionaryUnlockedUntil: userData.dictionaryUnlockedUntil,
            livesRemaining: userData.livesRemaining || 0,
            streakSaversRemaining: userData.streakSaversRemaining || 0,
            xpMultiplier: userData.xpMultiplier || 1,
            xpBoostExpiresAt: userData.xpBoostExpiresAt,
            unlockedAudioPacks: userData.unlockedAudioPacks || [],
          });

          // Check if any effects have expired - this was missing
          await ProductEffectService.checkEffectStatus(user.uid);
        }

        // Don't set isLoading=false here since inventory listener handles that
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        // Don't set isLoading=false here to avoid conflicts with inventory listener
      }
    };

    fetchUserData();
  }, [user]); // Only depend on user to avoid unnecessary re-fetches

  // Add this to fetch purchase history
  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      if (!user) return;

      try {
        const result = await getPurchaseHistoryApi();
        const purchases = result.purchases.map((purchase) => ({
          ...purchase,
          // Convert ISO string back to Date
          timestamp: purchase.timestamp ? new Date(purchase.timestamp) : null,
        }));

        setPurchaseHistory(purchases);
      } catch (error) {
        console.error("Failed to fetch purchase history:", error);
      }
    };

    fetchPurchaseHistory();
  }, [user]);

  // Fetch user balance, inventory, and stats from Firestore
  // Replace your existing useEffect that fetches inventory with this:
  // In ShopContext.js - Simplify the inventory listener setup
  // In ShopContext.js
  // In ShopContext.js - Improve the listener implementation
  useEffect(() => {
    let unsubscribe = null;

    const setupInventoryListener = async () => {
      if (!user) {
        setInventory([]);
        setIsLoading(false);
        return;
      }

      try {
        // console.log("Setting up inventory listener for user:", user.uid);

        // Create query for user's inventory
        const inventoryQuery = query(
          collection(db, "inventory"),
          where("userId", "==", user.uid)
        );

        // Clean up existing listener if there is one
        if (unsubscribe) {
          console.log("Cleaning up existing inventory listener");
          unsubscribe();
        }

        // Set up the new listener
        // console.log("Attaching new inventory listener");
        unsubscribe = onSnapshot(
          inventoryQuery,
          (snapshot) => {
            // console.log(
            //   `Real-time update received: ${snapshot.docs.length} items`
            // );
            // console.log(
            //   "Snapshot metadata:",
            //   `fromCache: ${snapshot.metadata.fromCache}`,
            //   `hasPendingWrites: ${snapshot.metadata.hasPendingWrites}`
            // );

            const inventoryItems = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              purchasedAt: doc.data().purchasedAt?.toDate(),
              expiresAt: doc.data().expiresAt?.toDate(),
            }));

            // console.log(
            //   "Updating inventory state with",
            //   inventoryItems.length,
            //   "items"
            // );
            setInventory(inventoryItems);
            setIsLoading(false);
          },
          (error) => {
            console.error("Inventory listener error:", error);
            setIsLoading(false);
          }
        );

        // We don't need to setState for the unsubscribe function anymore
        // This avoids re-triggering the effect
      } catch (error) {
        console.error("Error setting up inventory listener:", error);
        setIsLoading(false);
      }
    };

    // Set up the listener
    setupInventoryListener();

    // Clean up on unmount only
    return () => {
      if (unsubscribe) {
        // console.log("Unmounting - cleaning up inventory listener");
        unsubscribe();
      }
    };
  }, [user]); // Only depend on user, not the listener state itself

  const fetchProducts = async () => {
    try {
      const productsCollection = collection(db, "products");
      const productsSnapshot = await getDocs(productsCollection);

      const productList = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate(),
        endDate: doc.data().endDate?.toDate(),
      }));

      setProducts(productList);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  // Add this right after your inventory listener useEffect
  useEffect(() => {
    const fetchProducts = async () => {
      if (!user) return;

      try {
        // console.log("Fetching products from Firestore");
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);

        // console.log(
        //   `Found ${productsSnapshot.docs.length} products in Firestore`
        // );

        // Debug first product
        if (productsSnapshot.docs.length > 0) {
          const firstProduct = productsSnapshot.docs[0].data();
          // console.log("Sample product:", firstProduct);
          // console.log("Available type:", typeof firstProduct.available);
        }

        const productList = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Leave available as native boolean
          createdAt: doc.data().createdAt?.toDate(),
          updatedAt: doc.data().updatedAt?.toDate(),
          endDate: doc.data().endDate?.toDate(),
        }));

        // console.log(`Processed ${productList.length} products`);
        // console.log(
        //   `Available products: ${
        //     productList.filter((p) => p.available === true).length
        //   }`
        // );

        setProducts(productList);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    // Call the function
    fetchProducts();
  }, [user]); // Depend on user so it refetches when user changes

  // STEP 3: Update the purchaseProduct function to send a notification on success
  const purchaseProduct = async (productId) => {
    if (!user) {
      throw new Error("You must be logged in to make a purchase");
    }

    const product = products.find((p) => p.id === productId);

    if (!product) {
      throw new Error("Product not found");
    }

    if (balance < product.price) {
      throw new Error("Insufficient balance");
    }

    try {
      // Call the HTTP Cloud Function
      const result = await purchaseProductApi(productId);

      if (result.success) {
        // Apply product effects
        await ProductEffectService.applyPurchaseEffects(productId, user.uid);

        // Update local state
        setBalance((prevBalance) => prevBalance - product.price);

        // Send purchase notification
        sendPurchaseConfirmedNotification(product, product.price);

        // Remaining existing code...

        return {
          success: true,
          message: result.message,
        };
      } else {
        throw new Error(result.message || "Purchase failed");
      }
    } catch (error) {
      console.error("Purchase failed:", error);
      return {
        success: false,
        message: error.message || "Purchase failed",
      };
    }
  };

  // STEP 4: Update the inventoryItem function to send a notification on success
  const inventoryItem = async (inventoryItemId) => {
    if (!user) {
      throw new Error("You must be logged in to use an item");
    }

    try {
      // Find the item in inventory
      const item = inventory.find((item) => item.id === inventoryItemId);

      if (!item) {
        throw new Error("Item not found in inventory");
      }

      // Call the HTTP Cloud Function
      const result = await inventoryItemApi(inventoryItemId);

      if (result.success) {
        // Apply item effects
        await ProductEffectService.applyItemUseEffects(
          inventoryItemId,
          user.uid
        );

        // Send item used notification
        sendItemUsedNotification(item);

        // Remaining existing code...

        return {
          success: true,
          message: result.message,
        };
      } else {
        throw new Error(result.message || "Failed to use item");
      }
    } catch (error) {
      console.error("Failed to use inventory item:", error);
      return {
        success: false,
        message: error.message || "Failed to use item",
      };
    }
  };

  // STEP 5: Update the addCurrency function to send a notification on success
  const addCurrency = async (amount, source = "reward") => {
    if (!user) {
      throw new Error("You must be logged in to add currency");
    }

    if (amount <= 0) {
      throw new Error("Amount must be positive");
    }

    try {
      // Call the HTTP Cloud Function
      const result = await addCoinsApi(amount, source);

      if (result.success) {
        // Update local state
        setBalance((prevBalance) => prevBalance + amount);

        // Send coins received notification
        sendCoinsReceivedNotification(amount, source);

        return {
          success: true,
          message: result.message,
        };
      } else {
        throw new Error(result.message || "Failed to add currency");
      }
    } catch (error) {
      console.error("Failed to add currency:", error);
      return {
        success: false,
        message: error.message || "Failed to add currency",
      };
    }
  };

  // Get user stats that are affected by products
  const getUserStats = async () => {
    try {
      if (!user) {
        throw new Error("User not authenticated");
      }

      // Check if we already have stats loaded
      if (userStats) {
        return userStats;
      }

      // Fetch from Firestore if not already loaded
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        throw new Error("User document not found");
      }

      const userData = userSnap.data();

      const stats = {
        dictionarySearchesRemaining: userData.dictionarySearchesRemaining || 0,
        dictionaryUnlockedUntil: userData.dictionaryUnlockedUntil,
        livesRemaining: userData.livesRemaining || 0,
        streakSaversRemaining: userData.streakSaversRemaining || 0,
        xpMultiplier: userData.xpMultiplier || 1,
        xpBoostExpiresAt: userData.xpBoostExpiresAt,
        unlockedAudioPacks: userData.unlockedAudioPacks || [],
      };

      // Update local state
      setUserStats(stats);

      return stats;
    } catch (error) {
      console.error("Error fetching user stats:", error);
      return null;
    }
  };

  const value = {
    balance,
    products,
    inventory,
    purchaseHistory,
    isLoading,
    purchaseProduct,
    inventoryItem,
    addCurrency,
    getUserStats,
    refreshInventory, // Add this
    userStats,
  };

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

// Custom hook to use the shop context
export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    console.error("ShopContext is undefined when useShop is called");
    throw new Error("useShop must be used within a ShopProvider");
  }
  return context;
};
