import React, { useState } from "react";
// import {default as ffaloginlogo} from "../../assets/icons/ffa-logo-2023.svg";
import { default as ffaloginlogo } from "../../assets/icons/FFA-beta-logo-alt.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import * as IoIcons from "react-icons/io";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import BeatLoader from "react-spinners/BeatLoader";
import { FcGoogle } from "react-icons/fc";

import { Link } from "react-router-dom";

function Register({ onNext }) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false); // Add hovered state
  const [error, setError] = useState("");
  const { createUser, googleRegistration } = useAuth();
  const [googleLoading, setGoogleLoading] = useState(false); // Add guestLoading state
  const [emailLoading, setEmailLoading] = useState(false); // Add guestLoading state

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required!"),
      password: Yup.string().required("Required!"),
    }),
    onSubmit: async () => {
      setEmailLoading(true);
      try {
        await createUser(formik.values.email, formik.values.password);
        navigate("/dashboard");
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setEmailLoading(false);
      }
    },
  });

  const handleGoogleRegistration = async () => {
    setGoogleLoading(true);
    try {
      await googleRegistration();
      // navigate("/dashboard");
    } catch (error) {
      console.log(error);
      setError("Google SSO registration failed."); // Handle error if needed
    } finally {
      setGoogleLoading(false);
    }
  };

  return (
    <div className="bg-white flex flex-col items-center justify-center">
      <div className="mt-8 mb-8 p-8 flex flex-col items-center justify-center bg-white rounded shadow-xl w-2/3">
        <div className="mt-4 flex flex-col relative items-center justify-between">
          <Link to="/dashboard">
            <img className="h-40" src={ffaloginlogo} alt="ffa-login-logo" />
          </Link>
        </div>
        <div>
          <h3 className="uppercase w-full mt-4 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-center font-headers text-black font-bold">
            Sign up to know Africa
          </h3>
        </div>
        <div className="mt-4 text-center w-4/5 flex justify-center items-center">
          <button
            onMouseEnter={() => setHovered(true)} // Set hovered to true on mouse enter
            onMouseLeave={() => setHovered(false)} // Set hovered to false on mouse leave
            onClick={handleGoogleRegistration}
            className="uppercase bg-transparent text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg cursor-pointer w-5/6 w-min-2xl border border-black text-darkgray active:border-green hover:border-green flex flex-row justify-center items-center bg-other rounded space-x-6"
          >
            {googleLoading ? (
              <div className="p-2">
                <BeatLoader
                  color={hovered ? "#db4437" : "#848717"}
                  loading={googleLoading}
                  size={5}
                />
              </div>
            ) : (
              <div className="flex flex-row justify-start items-center p-2">
                <FcGoogle className="text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg" />
                <p className="text-black font-google font-light text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg px-2">
                  Sign up with Google
                </p>
              </div>
            )}
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="w-4/5">
          <div className="w-full mt-4 flex flex-row items-center justify-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg text-center">
            <div className="bg-black h-1 flex w-1/4"></div>
            <div className="w-2/4 uppercase"> sign up with email </div>
            <div className="bg-black h-1 flex w-1/4"></div>
          </div>
          <div className="mt-4 text-center w-full flex justify-center items-center">
            <div className="w-5/6 w-min-2xl border border-black active:border-green hover:border-green flex flex-row justify-center items-center bg-other rounded">
              <span className="text-xs m-auto p-2">✉️</span>
              <input
                className="mt-1 p-2 uppercase text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-lg rounded w-full focus:outline-none"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                style={{ outline: "none", background: "white" }}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-ms mb-1 text-center text-red m-2">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
          </div>
          <div className="mt-4 text-center w-full flex justify-center items-center">
            <div className="w-5/6 w-min-2xl border border-black active:border-green hover:border-green flex flex-row justify-center items-center bg-other rounded">
              <span className="text-xs m-auto p-2">🔒</span>
              <input
                className="mt-1 p-2 uppercase text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-lg rounded w-full focus:outline-none"
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                style={{ outline: "none", background: "white" }}
              />
              {formik.touched.password && formik.errors.password ? (
                <p className="text-ms mb-1 text-center text-red m-2">
                  {formik.errors.password}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col w-full justify-center items-center cursor-pointer">
            <button
              onMouseEnter={() => setHovered(true)} // Set hovered to true on mouse enter
              onMouseLeave={() => setHovered(false)} // Set hovered to false on mouse leave
              onClick={formik.handleSubmit}
              data-testid="sign-up-button"
              type="submit"
              text="submit"
              className="mt-4 px-6 mx-12 my-4 py-2 border-1 rounded w-5/6 w-min-2xl text-center cursor-pointer w-min-xl
              m-auto
              block pt-2 pb-2 pl-4 pr-4  text-green font-bold hover:text-white border-2 border-green hover:border-white hover:bg-green
              text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl
              leading-tight
              uppercase
              shadow-md
              hover:shadow-lg
              focus:shadow-lg focus:outline-none focus:ring-0
              active:shadow-lg
              transition
              duration-150
              ease-in-out"
            >
              {emailLoading ? (
                <div className="">
                  <BeatLoader
                    color={hovered ? "#FCFCEE" : "#848717"}
                    loading={emailLoading}
                    size={5}
                  />
                </div>
              ) : (
                "Sign Up"
              )}
            </button>
            <span className="hidden text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg">
              Already have an account?{" "}
              <Link to="/login" className="text-red underline hover:text-green">
                Sign In
              </Link>
            </span>
            {error ? (
              <p className="mb-1 text-center text-green cursor-text text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-4/6">
                Email and password combination invalid. Please try again with
                unique values.
              </p>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
