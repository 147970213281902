// hooks/useBooking.js
import { useContext } from "react";
import { BookingsContext } from "../helpers/BookingsContext";

/**
 * Custom hook to access the booking context
 *
 * This hook provides access to all booking-related state and functions.
 * Must be used within a BookingProvider component.
 *
 * @returns {Object} The booking context
 * @throws {Error} If used outside of a BookingProvider
 *
 * @example
 * // Access booking context in a component
 * const {
 *   userRole,
 *   isCoach,
 *   coaches,
 *   userBookings,
 *   upcomingBookings,
 *   completedBookings,
 *   bookSession,
 *   cancelBooking,
 *   etc...
 * } = useBooking();
 */
export const useBooking = () => {
  const context = useContext(BookingsContext);

  if (context === undefined) {
    throw new Error("useBooking must be used within a BookingProvider");
  }

  return context;
};

export default useBooking;
