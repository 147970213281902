import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

function TypeWhatYouHear({ data }) {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);

  // Loading simulation
  const [loading, setLoading] = useState(true);

  // Track user's typed answer
  const [userAnswer, setUserAnswer] = useState("");

  // State for the audio URL
  const [audioUrl, setAudioUrl] = useState("");

  // Audio playback state
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState(1.0);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Audio controller reference
  const audioRef = useRef(new Audio());

  /**
   * When userAnswer changes, update the context.
   */
  useEffect(() => {
    setOptionChosen(userAnswer);
  }, [userAnswer, setOptionChosen]);

  /**
   * Simulate a data fetch from the provided data prop.
   */
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 800);
    return () => clearTimeout(timer);
  }, [data]);

  /**
   * Set up audio event listeners
   */
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  /**
   * Apply audio settings when they change
   */
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
      }
    }
  }, [audioEnabled, isPlaying]);

  /**
   * Fetch the audio URL
   */
  useEffect(() => {
    const fetchAudioUrl = async () => {
      if (!data) return;

      try {
        const url = await getAudioDownloadUrl(data.audio);
        setAudioUrl(url);

        // Set the URL on the audio element
        audioRef.current.src = url;
        audioRef.current.load();
      } catch (err) {
        console.error("Error fetching audio URL:", err);
      }
    };

    fetchAudioUrl();
  }, [data]);

  /**
   * Play the audio at normal speed (1.0)
   */
  const handlePlayNormal = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audio = audioRef.current;

    // If already playing, pause it
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
      return;
    }

    // Otherwise play at normal speed
    audio.playbackRate = 1.0;
    audio.currentTime = 0;

    audio.play().catch((err) => {
      console.error("Error playing audio:", err);
    });

    setIsPlaying(true);
    setCurrentPlaybackRate(1.0);
  };

  /**
   * Play the audio at slower speed (0.75)
   */
  const handlePlaySlow = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audio = audioRef.current;

    // If already playing, pause it
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
      return;
    }

    // Otherwise play at slow speed
    audio.playbackRate = 0.75;
    audio.currentTime = 0;

    audio.play().catch((err) => {
      console.error("Error playing audio:", err);
    });

    setIsPlaying(true);
    setCurrentPlaybackRate(0.75);
  };

  // Get button content based on audio state
  const getButtonContent = (type) => {
    if (!audioEnabled) {
      return "🔇";
    }

    if (
      isPlaying &&
      ((type === "normal" && currentPlaybackRate === 1.0) ||
        (type === "slow" && currentPlaybackRate === 0.75))
    ) {
      return type === "normal" ? "⏸️" : "⏸️";
    }

    return type === "normal" ? "▶️" : "🐢";
  };

  // If still loading, show spinner
  if (loading) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-full min-h-[300px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <BeatLoader color={"#848717"} loading={loading} size={15} />
      </motion.div>
    );
  }

  // Get current exercise if available
  if (!data) {
    return <div>No audio exercises available.</div>;
  }

  return (
    <motion.div
      className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <div
          className="mb-3 sm:mb-4 font-semibold"
          data-testid="exercise-title"
        >
          <span className="text-outline-black">🔊</span> Listening Exercise
        </div>

        <div className="relative border-2 border-b-4 border-black">
          {/* Prompt or question */}
          <h3
            data-testid="exercise-question"
            className="text-base sm:text-lg italic text-center font-body px-3 py-2"
          >
            {data.content.question}
          </h3>
          <div className="absolute -bottom-3 right-4 bg-white px-2 text-xs text-green">
            Click the buttons to play the audio
          </div>

          {/* Buttons to play normal speed or slow speed - increased tap target sizes for mobile */}
          <div className="flex justify-center items-center p-3 sm:p-4">
            <button
              onClick={handlePlayNormal}
              className={`px-4 text-outline-black text-3xl sm:text-4xl bg-blue-500 text-black rounded-lg hover:bg-blue-600 transition-all duration-150 mx-2 h-12 w-14 flex items-center justify-center ${
                !audioEnabled ? "opacity-50" : ""
              }`}
              title={
                audioEnabled
                  ? "Play at normal speed"
                  : "Audio is disabled globally"
              }
            >
              {getButtonContent("normal")}
            </button>
            <button
              onClick={handlePlaySlow}
              className={`px-4 py-2 text-3xl sm:text-4xl text-outline-black bg-blue-500 text-black rounded-lg hover:bg-blue-600 transition-all duration-150 mx-2 h-12 w-14 flex items-center justify-center ${
                !audioEnabled ? "opacity-50" : ""
              }`}
              title={
                audioEnabled
                  ? "Play at slow speed"
                  : "Audio is disabled globally"
              }
            >
              {getButtonContent("slow")}
            </button>
          </div>

          {/* Audio disabled message */}
          {!audioEnabled && (
            <div className="text-center text-xs text-gray-500 pb-2">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}
        </div>

        {/* Input for user transcription - adjusted for mobile */}
        <div className="w-full max-w-md font-body mt-4">
          <textarea
            placeholder="Type the sentence here..."
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            className="w-full h-[180px] sm:h-[250px] p-3 border-2 border-black rounded text-base
             focus:outline-none focus:border-green
             placeholder-gray border-b-4"
            style={{ resize: "none" }}
          />
        </div>
      </div>
    </motion.div>
  );
}

TypeWhatYouHear.propTypes = {
  data: PropTypes.shape({
    audio: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        storagePath: PropTypes.string,
        url: PropTypes.string,
      }),
    ]),
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string,
      correctTranscription: PropTypes.string,
    }),
    content: PropTypes.shape({
      question: PropTypes.string,
    }),
    title: PropTypes.string,
    topic: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default TypeWhatYouHear;
