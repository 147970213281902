import React, { useState, useEffect } from "react";
import { Calendar, Clock, User, MapPin, Star, Check, X } from "lucide-react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils";
import useBooking from "../../../hooks/useBooking";

import {
  formatDateTime,
  formatDuration,
  getFriendlyDateDescription,
} from "../../../utils/bookings/dateUtils";

import RatingModal from "./rating-modal";

const SessionCard = ({
  booking,
  onCancel,
  onComplete,
  showRatingButton = true,
  showActions = true,
  className = "",
}) => {
  const { isCoach } = useBooking();
  const [otherUser, setOtherUser] = useState(null);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [loading, setLoading] = useState(true);

  // Determine if the current user is coach or student
  const userType = booking.userRole || (isCoach ? "coach" : "student");
  const otherUserType = userType === "coach" ? "student" : "coach";
  const otherUserId = booking[`${otherUserType}Id`];

  // Format session date/time
  const sessionDate = booking.sessionDateTime
    ? new Date(booking.sessionDateTime)
    : null;

  useEffect(() => {
    const fetchOtherUser = async () => {
      if (!otherUserId) {
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, "users", otherUserId));
        if (userDoc.exists()) {
          setOtherUser({
            id: otherUserId,
            ...userDoc.data(),
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        setLoading(false);
      }
    };

    fetchOtherUser();
  }, [otherUserId, otherUserType]);

  // Enhanced function to get the correct name considering all possible sources
  const getOtherUserName = () => {
    // Check all possible name sources with fallbacks
    if (otherUserType === "student") {
      return (
        booking.studentName || // Direct from booking if available
        otherUser?.name || // From fetched user data
        otherUser?.displayName || // Alternative field name
        "Student" // Generic fallback
      );
    } else {
      return (
        booking.coachName || // Direct from booking if available
        otherUser?.name || // From fetched user data
        otherUser?.displayName || // Alternative field name
        "Coach" // Generic fallback
      );
    }
  };

  const getStatusColor = () => {
    switch (booking.status) {
      case "upcoming":
        return "bg-gold bg-opacity-20 text-gold";
      case "completed":
        return "bg-green bg-opacity-20 text-green";
      case "cancelled":
        return "bg-red bg-opacity-20 text-red";
      default:
        return "bg-gray bg-opacity-20 text-gray";
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (onCancel) onCancel(booking.id);
  };

  const handleComplete = (e) => {
    e.stopPropagation();
    if (onComplete) onComplete(booking.id);
  };

  const handleOpenRatingModal = (e) => {
    e.stopPropagation();
    setShowRatingModal(true);
  };

  if (loading) {
    return (
      <div
        className={`border border-darkgray rounded-lg p-3 sm:p-4 ${className}`}
      >
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-darkgray h-10 w-10 sm:h-12 sm:w-12 flex-shrink-0"></div>
          <div className="flex-1 space-y-3 py-1">
            <div className="h-4 bg-darkgray rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-darkgray rounded"></div>
              <div className="h-4 bg-darkgray rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Get the other user's name using our enhanced function
  const otherUserName = getOtherUserName();

  // Determine date display format based on screen size
  const responsiveDateFormat = (date, isMobile = false) => {
    if (!date) return "";
    return formatDateTime(
      date,
      isMobile ? "MMM d, yyyy" : "EEEE, MMMM d, yyyy"
    );
  };

  return (
    <div
      className={`border-2 border-b-4 border-darkgray rounded p-3 sm:p-4 ${className}`}
    >
      <div className="flex items-start justify-between mb-3 flex-wrap gap-2">
        <div className="flex items-center">
          <User className="text-gray mr-2 flex-shrink-0" size={18} />
          <span className="font-medium text-gray truncate max-w-[150px] sm:max-w-none">
            {otherUserName}
          </span>
        </div>
        <span
          className={`inline-block px-2 py-1 rounded text-xs ${getStatusColor()}`}
        >
          {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
        </span>
      </div>

      {sessionDate && (
        <>
          <div className="flex items-center text-gray mb-1">
            <Calendar className="mr-2 flex-shrink-0" size={16} />
            <span className="text-sm sm:text-base">
              {responsiveDateFormat(sessionDate, window.innerWidth < 640)}
            </span>
          </div>

          <div className="flex items-center text-gray mb-2">
            <Clock className="mr-2 flex-shrink-0" size={16} />
            <span className="text-sm sm:text-base">
              {formatDateTime(sessionDate, "h:mm a")}
            </span>
            <span className="mx-1">•</span>
            <span className="text-sm sm:text-base">
              {formatDuration(booking.duration)}
            </span>
          </div>
        </>
      )}

      {otherUser?.timezone && (
        <div className="flex items-center text-gray mb-4">
          <MapPin className="mr-2 flex-shrink-0" size={16} />
          <span className="text-sm sm:text-base truncate">
            {otherUser.timezone.replace("_", " ")}
          </span>
        </div>
      )}

      {booking.rating > 0 && (
        <div className="flex items-center mb-4">
          <span className="text-gray mr-2 text-sm sm:text-base">Rating:</span>
          <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                size={16}
                fill={booking.rating >= star ? "#d19a10" : "none"}
                color="#d19a10"
              />
            ))}
          </div>
        </div>
      )}

      {booking.feedback && (
        <div className="mb-4">
          <p className="text-xs sm:text-sm text-gray italic">
            "{booking.feedback}"
          </p>
        </div>
      )}

      {showActions && booking.status === "upcoming" && (
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          {userType === "coach" && (
            <button
              onClick={handleComplete}
              className="sm:flex-1 flex items-center justify-center border-2 border-b-4 px-3 py-2 border-green text-green rounded-md hover:bg-green hover:bg-opacity-10"
            >
              <span className="mr-1">✅</span>
              Complete
            </button>
          )}

          <button
            onClick={handleCancel}
            className="sm:flex-1 flex items-center justify-center px-3 py-2 border-2 border-b-4 border-red text-red rounded-md hover:bg-red hover:bg-opacity-10"
          >
            <span className="mr-1">❌</span>
            Cancel
          </button>
        </div>
      )}

      {showActions &&
        booking.status === "completed" &&
        userType === "student" &&
        !booking.rating &&
        showRatingButton && (
          <button
            onClick={handleOpenRatingModal}
            className="w-full flex items-center justify-center px-3 py-2 border-2 border-b-4 border-gold text-gold rounded hover:bg-gold hover:bg-opacity-10"
          >
            <Star size={16} className="mr-1" />
            Rate Session
          </button>
        )}

      {booking.meetingLink && booking.status === "upcoming" && (
        <div>
          <a
            href={booking.meetingLink}
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full mt-4 px-4 py-2 bg-gold border-black border-2 border-b-4 text-black text-center rounded-md hover:bg-opacity-80"
          >
            Join Session
          </a>
          {booking.meetingPassword && (
            <div className="mt-2 text-center text-gray text-xs sm:text-sm">
              Password:{" "}
              <span className="font-bold">{booking.meetingPassword}</span>
            </div>
          )}
        </div>
      )}

      {/* For coaches, show the start URL instead of join URL */}
      {userType === "coach" &&
        booking.meetingStartUrl &&
        booking.status === "upcoming" && (
          <a
            href={booking.meetingStartUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full mt-4 px-4 py-2 bg-green text-white text-center rounded-md hover:bg-opacity-80"
          >
            Start Session
          </a>
        )}

      {/* Rating Modal */}
      {showRatingModal && (
        <RatingModal
          booking={booking}
          onClose={() => setShowRatingModal(false)}
          otherUser={otherUser}
        />
      )}
    </div>
  );
};

export default SessionCard;
