// pages/support/index.jsx (Support Home)
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import SupportHeader from "../../components/support/support-header";
import SearchBar from "../../components/support/search-bar";
import CategoryCard from "../../components/support/category-card";
import PopularArticles from "../../components/support/popular-articles";
import Footer from "../../components/footer/footer.component";
import { supportCategories } from "../../zombiedata/support-articles";
import FeedbackMenu from "../../components/support/feedback-menu";
import { PuffLoader } from "react-spinners";

const SupportHome = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    document.title = "FFAQs | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <SupportHeader />
      <FeedbackMenu />

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            How can we help you?
          </h1>
          <div className="max-w-2xl mx-auto">
            <SearchBar placeholder="Search for articles..." />
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Browse by Category</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {supportCategories.map((category) => (
              <CategoryCard key={category.id} category={category} />
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <h2 className="text-2xl font-bold mb-6">Recently Updated</h2>
            <div className="space-y-4">
              {supportCategories
                .flatMap((cat) => cat.articles)
                .sort(
                  (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
                )
                .slice(0, 3)
                .map((article) => {
                  const category = supportCategories.find((cat) =>
                    cat.articles.some((a) => a.id === article.id)
                  );
                  return (
                    <div
                      key={article.id}
                      className="p-5 border-2 border-b-4 border-darkgray rounded"
                    >
                      <span className="text-sm text-gray-500">
                        {category.title} • Updated{" "}
                        {new Date(article.lastUpdated).toLocaleDateString()}
                      </span>
                      <h3 className="font-medium my-2">
                        <a
                          href={`/support/article/${article.id}`}
                          className="text-red hover:underline"
                        >
                          {article.title}
                        </a>
                      </h3>
                      <p className="text-sm text-gray-600">{article.preview}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-6">Popular Articles</h2>
            <PopularArticles limit={6} />
          </div>
        </div>
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default SupportHome;
