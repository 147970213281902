// src/data/milestones.js
import { default as rocket } from "../assets/emojis/rocket-blast.png";
import { default as medal } from "../assets/emojis/victory.png";
import { default as plant } from "../assets/emojis/plant.png";
import { default as king } from "../assets/emojis/king.png";
import { default as lock } from "../assets/emojis/locked.png";

const milestoneData = {
  george: {
    id: "george",
    name: "George",
    subtitle: "Fluency Fundamentals",
    tagline:
      "Master core sounds, basic vocabulary, essential grammar structures, and simple conversational phrases for everyday situations.",
    description:
      "Master core sounds, basic vocabulary, essential grammar structures, and simple conversational phrases for everyday situations.",
    hours: "90-240",
    progress: 75,
    unlocked: true,
    order: 1,
    image: rocket,
    imageAlt: "Learning Progress",
    highlightText: "and simple conversational phrases",
    colors: {
      primary: "#991616",
      secondary: "#ffd4d4",
      gradient: {
        from: "#ffd4d4/90",
        via: "#ffb5b5",
        to: "#991616",
      },
      highlight: "#991616/60",
    },
    competencies: [
      {
        title: "Range",
        description:
          "Has a very basic repertoire of words and simple phrases related to personal details and particular concrete situations.",
        icon: "📚",
      },
      {
        title: "Accuracy",
        description:
          "Shows only limited control of a few simple grammatical structures and sentence patterns in a memorised repertoire.",
        icon: "🎯",
      },
      {
        title: "Fluency",
        description:
          "Can manage very short, isolated, mainly pre-packaged utterances, with much pausing to search for expressions, to articulate less familiar words, and to repair communication.",
        icon: "🌊",
      },
      {
        title: "Interaction",
        description:
          "Can ask and answer questions about personal details. Can interact in a simple way but communication is totally dependent on repetition, rephrasing and repair",
        icon: "👥",
      },
      {
        title: "Coherence",
        description: `Can link words or groups of words with very basic linear connectors like "and" or "then"`,
        icon: "🧩",
      },
    ],
    learningObjectives: [
      "Greetings, say thank you, and know how to say yes/no",
      "Create words from the 26-letter alphabet",
      "Distinguish between words with or without h/g",
      "Explain what you are doing at a specific time, and what you were doing a day before",
      "Know the names of body parts",
      "Count up to 100",
      "Know common words",
      "Differentiate between x, c, q, xh, ch, qh, gc, gx, gq",
      "Adjust tone when saying specific words",
    ],
    participants: {
      adults: { hours: "90-100", cumulative: "90-100" },
      teenagers: { hours: "150-200", cumulative: "150-200" },
      children: { hours: "180-240", cumulative: "180-240" },
    },
  },
  morena: {
    id: "morena",
    name: "Morena",
    subtitle: "Conversational Cornerstones",
    tagline:
      "Develop fluid dialogue skills, master complex sentence structures, for cultural context understanding and meaningful conversations.",
    description:
      "Develop fluid dialogue skills, master complex sentence structures, for cultural context understanding and meaningful conversations.",
    hours: "240-500",
    progress: 100,
    unlocked: false,
    order: 2,
    image: medal,
    imageAlt: "Achievement Badge",
    highlightText: "for cultural context understanding",
    colors: {
      primary: "#d19a10",
      secondary: "#f5e0b7",
      gradient: {
        from: "#f5e0b7",
        via: "#e2c78d",
        to: "#d19a10",
      },
      highlight: "#a87a0d/80",
    },
    competencies: [
      {
        title: "Range",
        description:
          "Has enough language to get by, with sufficient vocabulary to express themselves with some hesitation on topics such as family, hobbies, interests, work, travel, and current events.",
        icon: "📚",
      },
      {
        title: "Accuracy",
        description:
          "Uses reasonably accurately a repertoire of frequently used 'routines' and patterns associated with more predictable situations.",
        icon: "🎯",
      },
      {
        title: "Fluency",
        description:
          "Can keep going comprehensibly, even though pausing for grammatical and lexical planning and repair is very evident, especially in longer stretches of free production.",
        icon: "🌊",
      },
      {
        title: "Interaction",
        description:
          "Can initiate, maintain and close simple face-to-face conversation on topics that are familiar or of personal interest.",
        icon: "👥",
      },
      {
        title: "Coherence",
        description:
          "Can link a series of shorter, discrete simple elements into a connected, linear sequence of points.",
        icon: "🧩",
      },
    ],
    learningObjectives: [
      "Engage in everyday conversations with native speakers",
      "Express opinions on familiar topics",
      "Describe past experiences and events",
      "Understand context-specific language use",
      "Navigate social interactions with appropriate formality",
      "Explain plans and intentions",
      "Use common idioms and expressions",
      "Recognize and respond to cultural references",
      "Apply grammar rules consistently in conversation",
    ],
    participants: {
      adults: { hours: "240-300", cumulative: "330-400" },
      teenagers: { hours: "300-350", cumulative: "450-550" },
      children: { hours: "320-400", cumulative: "500-640" },
    },
  },
  chiefMorena: {
    id: "chiefMorena",
    name: "Chief Morena",
    subtitle: "Cultural Fluency Development",
    tagline:
      "Master nuanced expressions, idiomatic language use, and socio-linguistic awareness for authentic communication.",
    description:
      "Master nuanced expressions, idiomatic language use, and socio-linguistic awareness for authentic communication.",
    hours: "500-750",
    progress: 45,
    unlocked: false,
    order: 3,
    image: plant,
    imageAlt: "Cultural Growth",
    highlightText: "and socio-linguistic awareness",
    colors: {
      primary: "#818747",
      secondary: "#e5e8c7",
      gradient: {
        from: "#e5e8c7",
        via: "#c3c8a1",
        to: "#818747",
      },
      highlight: "#616a37/90",
    },
    competencies: [
      {
        title: "Range",
        description:
          "Has a sufficient range of language to be able to give clear descriptions, express viewpoints on most general topics without much conspicuous searching for words.",
        icon: "📚",
      },
      {
        title: "Accuracy",
        description:
          "Shows a relatively high degree of grammatical control. Does not make errors which cause misunderstandings.",
        icon: "🎯",
      },
      {
        title: "Fluency",
        description:
          "Can produce stretches of language with a fairly even tempo; although can be hesitant as they search for patterns and expressions.",
        icon: "🌊",
      },
      {
        title: "Interaction",
        description:
          "Can initiate discourse, take their turn when appropriate and end conversation when necessary, though may not always do this elegantly.",
        icon: "👥",
      },
      {
        title: "Coherence",
        description:
          "Can use a limited number of cohesive devices to link their utterances into clear, coherent discourse, though there may be some 'jumpiness' in a long contribution.",
        icon: "🧩",
      },
    ],
    learningObjectives: [
      "Navigate complex social situations with appropriate language",
      "Understand and use cultural references and idioms naturally",
      "Express abstract concepts and nuanced emotions",
      "Adjust speaking style based on context and audience",
      "Recognize regional dialects and variations",
      "Engage in debates and discussions on sophisticated topics",
      "Comprehend implicit meaning and cultural subtext",
      "Master indirect communication styles",
      "Understand humor, irony, and wordplay",
    ],
    participants: {
      adults: { hours: "200-300", cumulative: "530-700" },
      teenagers: { hours: "250-350", cumulative: "700-900" },
      children: { hours: "250-350", cumulative: "750-990" },
    },
  },
  ancestor: {
    id: "ancestor",
    name: "Ancestor",
    subtitle: "Linguistic, Artistry, Cultural, Nuance",
    tagline:
      "Attain native-level proficiency through advanced literature analysis, regional dialect mastery and cultural subtext interpretation.",
    description:
      "Attain native-level proficiency through advanced literature analysis, regional dialect mastery and cultural subtext interpretation.",
    hours: "750-1000+",
    progress: 45,
    unlocked: false,
    order: 4,
    image: king,
    imageAlt: "Cultural Rebirth",
    highlightText: "advanced literature analysis, regional dialect mastery",
    colors: {
      primary: "multicolor",
      secondary: "#ffcfce",
      gradient: {
        from: "#ffcfce",
        via: "#d19a10",
        to: "#2d2d2d",
      },
      highlight: "gradient",
    },
    competencies: [
      {
        title: "Range",
        description:
          "Has very good command of a wide range of language including idiomatic expressions and colloquialisms.",
        icon: "📚",
      },
      {
        title: "Accuracy",
        description:
          "Maintains consistent grammatical control of complex language, even while attention is otherwise engaged.",
        icon: "🎯",
      },
      {
        title: "Fluency",
        description:
          "Can express themselves spontaneously at length with a natural colloquial flow, avoiding or backtracking around any difficulty so smoothly that the interlocutor is hardly aware of it.",
        icon: "🌊",
      },
      {
        title: "Interaction",
        description:
          "Can interact with ease and skill, picking up and using non-verbal and intonational cues with little apparent effort.",
        icon: "👥",
      },
      {
        title: "Coherence",
        description:
          "Can create coherent and cohesive discourse making full and appropriate use of a variety of organisational patterns and a wide range of connectors and other cohesive devices.",
        icon: "🧩",
      },
    ],
    learningObjectives: [
      "Comprehend literary works in their original form",
      "Master all regional dialects and speech patterns",
      "Understand and create culturally significant content",
      "Navigate complex social hierarchies with appropriate language",
      "Engage in philosophical and abstract discussions",
      "Appreciate and analyze poetry, metaphor, and linguistic artistry",
      "Understand historical development of language and etymology",
      "Function professionally in all language contexts",
      "Interpret and convey subtle cultural implications",
    ],
    participants: {
      adults: { hours: "250-350", cumulative: "780-1050" },
      teenagers: { hours: "300-400", cumulative: "1000-1300" },
      children: { hours: "300-400", cumulative: "1050-1390" },
    },
  },
};

// This tracks the locked status of milestones
const lockedMilestones = {
  morena: {
    id: "morena",
    name: "MORENA",
    subtitle: "Advanced Mother Tongue Techniques",
    image: lock,
    imageAlt: "Locked Content",
    order: 2,
  },
  chiefMorena: {
    id: "chiefMorena",
    name: "CHIEF MORENA",
    subtitle: "Advanced Mother Tongue Techniques",
    image: lock,
    imageAlt: "Locked Content",
    order: 3,
  },
  ancestor: {
    id: "ancestor",
    name: "ANCESTOR",
    subtitle: "Advanced Mother Tongue Techniques",
    image: lock,
    imageAlt: "Locked Content",
    order: 4,
  },
};

export { milestoneData, lockedMilestones };
