// BookingCancel.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../../../helpers/BookingsContext";
import { useNotifications } from "../../../helpers/NotificationsContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils";
import { createBookingCancelledNotification } from "../../../utils/notifications/booking-notifications-utils";

const BookingCancel = () => {
  const navigate = useNavigate();
  const { refreshBookings } = useBooking();
  const { addNotification } = useNotifications();
  const [message, setMessage] = useState("Your payment was cancelled.");
  // Flag to track if we've sent a notification
  const [notificationSent, setNotificationSent] = useState(false);

  useEffect(() => {
    // Get the booking ID from URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Try different parameter names that PayFast might use
    const bookingId =
      urlParams.get("m_payment_id") ||
      urlParams.get("booking_id") ||
      urlParams.get("custom_str1") ||
      sessionStorage.getItem("pendingBookingId");

    console.log(
      "BookingCancel: Payment was cancelled for booking ID:",
      bookingId
    );

    // Check if we've already processed this cancellation
    const storageKey = `cancel_processed_${bookingId}`;
    const isAlreadyProcessed = localStorage.getItem(storageKey);

    const cleanup = async () => {
      try {
        if (bookingId && !isAlreadyProcessed && !notificationSent) {
          // Try to get booking details for notification
          try {
            const bookingRef = doc(db, "bookings", bookingId);
            const bookingSnap = await getDoc(bookingRef);

            if (bookingSnap.exists()) {
              const bookingData = bookingSnap.data();

              // Send cancellation notification
              await addNotification(
                createBookingCancelledNotification({
                  id: bookingId,
                  ...bookingData,
                })
              );

              // Mark as sent
              setNotificationSent(true);
              localStorage.setItem(storageKey, "true");
            } else {
              // Booking document not found, send a generic cancellation notification
              await addNotification({
                title: "Booking Cancelled",
                message:
                  "Your booking request was cancelled. The timeslot has been released.",
                type: "booking_cancelled",
                data: { bookingId },
              });

              // Mark as sent
              setNotificationSent(true);
              localStorage.setItem(storageKey, "true");
            }
          } catch (error) {
            console.error(
              "Error fetching booking details for notification:",
              error
            );

            // Still send a generic notification if one hasn't been sent yet
            if (!notificationSent) {
              await addNotification({
                title: "Booking Cancelled",
                message:
                  "Your booking request was cancelled. The timeslot has been released.",
                type: "booking_cancelled",
              });

              // Mark as sent
              setNotificationSent(true);
              localStorage.setItem(storageKey, "true");
            }
          }

          // The booking will be automatically marked as expired by the cloud function
          // after the tentative booking period, but we can refresh to show updated status
          await refreshBookings();
          setMessage(
            "Your payment was cancelled. The timeslot has been released."
          );
        }

        // Clear from session storage if it exists there
        sessionStorage.removeItem("pendingBookingId");

        // After 3 seconds, redirect to booking page
        setTimeout(() => {
          navigate("/booking/coaches");
        }, 3000);
      } catch (error) {
        console.error("Error handling cancelled payment:", error);
        setMessage(
          "There was an issue processing your cancellation. The slot will be automatically released soon."
        );

        // Still redirect after a delay
        setTimeout(() => {
          navigate("/booking/coaches");
        }, 3000);
      }
    };

    cleanup();
  }, [navigate, refreshBookings, addNotification, notificationSent]);

  return (
    <div className="p-4 sm:p-8 max-w-md mx-auto mt-6 sm:mt-12 bg-white rounded-lg shadow border-2 border-darkgray">
      <div className="text-center">
        <div className="inline-flex items-center justify-center w-14 h-14 sm:w-16 sm:h-16 bg-red bg-opacity-20 rounded-full mb-4">
          <span className="text-red text-xl sm:text-2xl">✕</span>
        </div>
        <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
          Payment Cancelled
        </h2>
        <p className="text-gray-600 text-sm sm:text-base">{message}</p>
        <p className="text-gray-500 text-xs sm:text-sm mt-4">
          Redirecting to booking page...
        </p>

        <button
          onClick={() => navigate("/booking/coaches")}
          className="mt-4 sm:mt-6 px-4 py-2 bg-green text-white rounded-md hover:bg-opacity-90"
        >
          Return to Booking
        </button>
      </div>
    </div>
  );
};

export default BookingCancel;
