// OnboardingTerms.jsx
import React, { useState } from "react";
import { motion } from "framer-motion";

const OnboardingTerms = ({ agreement, onAgree, onComplete }) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [canAgree, setCanAgree] = useState(false);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const percentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    setScrollPercentage(percentage);

    // Allow agreement after scrolling to 90% of document
    if (percentage > 90 && !canAgree) {
      setCanAgree(true);
    }
  };

  const handleAgreeChange = (e) => {
    onAgree(e.target.checked);
  };

  // Mock Terms & Conditions content
  const termsContent = `
    # TERMS AND CONDITIONS

    ## FAST FLUENCY AFRICA EARLY ACCESS PROGRAM

    These Terms and Conditions ("Terms") govern your participation in the Fast Fluency Africa Early Access Program (the "Program"). By accepting these Terms, you agree to be bound by them.

    ### 1. PROGRAM DESCRIPTION

    The Program provides participants with early access to the Fast Fluency Africa platform, including language learning tools, educational resources, and community features. The purpose of the Program is to gather feedback, identify bugs, and improve the platform before public release.

    ### 2. ELIGIBILITY

    To participate in the Program, you must:
    - Be at least 18 years of age
    - Have been invited by Fast Fluency Africa or have applied and been accepted
    - Have agreed to the Non-Disclosure Agreement
    - Accept these Terms and Conditions

    ### 3. PROGRAM DURATION

    The Program will run for approximately 8 weeks, though Fast Fluency Africa reserves the right to extend or shorten this period. Your access to the Program may be terminated at any time at the sole discretion of Fast Fluency Africa.

    ### 4. PARTICIPANT RESPONSIBILITIES

    As a participant, you agree to:
    - Use the platform regularly during the Program period
    - Provide honest, constructive feedback when requested
    - Report any bugs, issues, or concerns promptly
    - Participate in surveys or feedback sessions as requested
    - Comply with all reasonable instructions related to the Program
    - Maintain the confidentiality of the Program as detailed in the NDA

    ### 5. LIMITATIONS OF USE

    You may not:
    - Use the Program for any illegal purpose
    - Share your account credentials with others
    - Attempt to reverse engineer or extract the source code
    - Use the Program in a manner that could damage or overburden it
    - Exploit bugs or vulnerabilities for personal gain
    - Create multiple accounts to access the Program

    ### 6. INTELLECTUAL PROPERTY

    All content, features, and functionality of the Program are owned by Fast Fluency Africa and are protected by intellectual property laws. Participation in the Program does not grant you any ownership rights or licenses except as explicitly stated.

    ### 7. FEEDBACK AND CONTRIBUTIONS

    Any feedback, ideas, suggestions, or contributions you provide regarding the Program may be used by Fast Fluency Africa without restriction or compensation. By providing feedback, you grant Fast Fluency Africa a perpetual, irrevocable, worldwide, royalty-free license to use your feedback for any purpose.

    ### 8. PRIVACY

    Fast Fluency Africa will collect and process certain personal data as described in our Privacy Policy. By participating in the Program, you consent to such collection and processing.

    ### 9. DISCLAIMER OF WARRANTIES

    THE PROGRAM IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. FAST FLUENCY AFRICA DISCLAIMS ALL WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.

    ### 10. LIMITATION OF LIABILITY

    TO THE MAXIMUM EXTENT PERMITTED BY LAW, FAST FLUENCY AFRICA SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR PARTICIPATION IN THE PROGRAM.

    ### 11. INDEMNIFICATION

    You agree to indemnify and hold harmless Fast Fluency Africa from any claims, damages, liabilities, costs, or expenses arising from your violation of these Terms or your use of the Program.

    ### 12. MODIFICATION OF TERMS

    Fast Fluency Africa reserves the right to modify these Terms at any time. Continued participation in the Program after such modifications constitutes acceptance of the modified Terms.

    ### 13. TERMINATION

    Fast Fluency Africa may terminate your participation in the Program at any time, with or without cause, without notice. Upon termination, your right to access the Program will cease immediately.

    ### 14. GOVERNING LAW

    These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.

    ### 15. ENTIRE AGREEMENT

    These Terms, together with the Non-Disclosure Agreement and Privacy Policy, constitute the entire agreement between you and Fast Fluency Africa regarding the Program.

    BY CLICKING "I AGREE" OR SIMILAR BUTTON, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEM.
  `;

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <p>
          Please review and accept our Terms and Conditions for the Early Access
          Program. These terms outline your rights and responsibilities as a
          participant.
        </p>
      </div>

      {/* Document container with scroll */}
      <div className="relative mb-4">
        <div
          className="h-64 overflow-auto border-2 border-darkgray rounded-md p-4 bg-white font-body text-sm"
          onScroll={handleScroll}
        >
          <div className="prose prose-sm max-w-none whitespace-pre-line">
            {termsContent}
          </div>
        </div>

        {/* Scroll progress indicator */}
        <div className="w-full bg-darkgray h-1 mt-2 rounded-full overflow-hidden">
          <motion.div
            className="bg-green h-full"
            initial={{ width: 0 }}
            animate={{ width: `${scrollPercentage}%` }}
            transition={{ type: "spring", stiffness: 100 }}
          />
        </div>

        {!canAgree && (
          <div className="absolute bottom-2 right-2 bg-white bg-opacity-90 px-3 py-2 rounded-md text-sm border border-gray text-center animate-pulse">
            Please scroll to continue
          </div>
        )}
      </div>

      {/* Agreement checkbox */}
      <div className="flex items-center mb-6 mt-4">
        <input
          type="checkbox"
          id="agree-terms"
          checked={agreement}
          onChange={handleAgreeChange}
          disabled={!canAgree}
          className="w-5 h-5 mr-3 accent-green disabled:opacity-50"
        />
        <label
          htmlFor="agree-terms"
          className={`${
            !canAgree ? "opacity-50" : ""
          } cursor-pointer select-none`}
        >
          I have read and agree to the Terms and Conditions
        </label>
      </div>

      {/* Continue button */}
      <div className="w-full text-center mt-4">
        <button
          onClick={onComplete}
          disabled={!agreement}
          className={`px-8 py-3 border-2 rounded text-center mt-4 w-full md:w-auto
            ${agreement ? "cursor-pointer" : "opacity-50 cursor-not-allowed"}
            text-green font-bold border-green hover:border-white hover:bg-green hover:text-white
            text-sm md:text-base
            uppercase
            shadow-md
            hover:shadow-lg
            transition
            duration-150
            transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4`}
        >
          Accept and Continue
        </button>
      </div>
    </div>
  );
};

export default OnboardingTerms;
