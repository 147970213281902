// services/power-up-initialization-service.js

import ProductEffectService from "../power-ups/product-effect-service";
import PowerUpManager from "./power-up-manager";
import PowerUpUtils from "./power-up-utils";
import { toDate, isPast } from "../../utils/timestamp-utils";

/**
 * Service to handle power-up initialization and checks on app startup
 * This centralizes power-up status checks and error handling
 */
class PowerUpInitializationService {
  /**
   * Initialize all power-up related checks and listeners
   * Should be called when the app starts, preferably after authentication
   *
   * @param {string} userId - User ID
   * @returns {Promise<Object>} Results of initialization
   */
  static async initialize(userId) {
    if (!userId) {
      console.warn("PowerUpInitializationService: No user ID provided");
      return { success: false, message: "No user ID provided" };
    }

    try {
      // console.log(
      //   "PowerUpInitializationService: Initializing for user",
      //   userId
      // );

      const results = {
        expiredEffects: [],
        activePowerUps: [],
        xpStatus: null,
        streakStatus: null,
        success: true,
      };

      // Check for expired effects and send notifications
      const expiredResult = await ProductEffectService.checkEffectStatus(
        userId
      );
      results.expiredEffects = expiredResult.expiredEffects || [];

      // Get active power-ups
      const activePowerUps = await PowerUpManager.getActivePowerUps(userId);
      results.activePowerUps = activePowerUps;

      // Check XP boost status
      results.xpStatus = await this.checkXpBoostStatus(userId);

      // Check streak protection status
      results.streakStatus = await PowerUpUtils.checkStreakProtection(userId);

      // console.log(
      //   "PowerUpInitializationService: Initialization completed",
      //   results
      // );
      return results;
    } catch (error) {
      console.error(
        "PowerUpInitializationService: Initialization failed",
        error
      );
      return {
        success: false,
        message: "Failed to initialize power-ups",
        error: error.message,
      };
    }
  }

  /**
   * Check XP boost status and validate that it's not expired
   *
   * @param {string} userId - User ID
   * @returns {Promise<Object>} XP boost status
   */
  static async checkXpBoostStatus(userId) {
    if (!userId) return { active: false };

    try {
      // This is to double-check that we don't have an expired but still active XP boost
      const result = await PowerUpManager.checkExpiredXPBoost(userId);

      // Calculate a sample boosted XP amount to confirm functionality
      const baseXp = 10;
      const boostedResult = await PowerUpUtils.calculateBoostedXP(
        userId,
        baseXp
      );

      return {
        active: boostedResult.hasBoost,
        multiplier: boostedResult.multiplier,
        sampleCalculation: {
          baseXp,
          boostedXp: boostedResult.xp,
        },
        expiresAt: boostedResult.expiresAt || null,
      };
    } catch (error) {
      console.error("Error checking XP boost status:", error);
      return { active: false, error: error.message };
    }
  }

  /**
   * Check if the user's streak is at risk and potentially apply automatic protection
   *
   * @param {string} userId - User ID
   * @param {Date} lastActivityDate - User's last activity date
   * @returns {Promise<Object>} Result of streak check and protection
   */
  static async checkAndProtectStreak(userId, lastActivityDate) {
    if (!userId) return { protected: false };

    try {
      // Convert to standard Date
      const lastActivity = toDate(lastActivityDate);
      if (!lastActivity)
        return { protected: false, reason: "Invalid last activity date" };

      // Get current day starting at midnight
      const today = new Date();
      const yesterdayMidnight = new Date(today);
      yesterdayMidnight.setDate(today.getDate() - 1);
      yesterdayMidnight.setHours(0, 0, 0, 0);

      // Check if user's last activity was before yesterday's midnight (streak at risk)
      const streakAtRisk = lastActivity < yesterdayMidnight;

      if (!streakAtRisk) {
        return { protected: false, reason: "Streak not at risk" };
      }

      // Check if streak protection is already active
      const protectionStatus = await PowerUpUtils.checkStreakProtection(userId);

      if (protectionStatus.isProtected) {
        return {
          protected: true,
          automatic: false,
          message: "Streak already protected",
          source: protectionStatus.source,
          expiresAt: protectionStatus.expiresAt,
        };
      }

      // Attempt to automatically apply streak protection
      const result = await PowerUpManager.applyStreakFreeze(userId);

      if (result.success) {
        return {
          protected: true,
          automatic: true,
          message: "Streak automatically protected",
          expiresAt: new Date(new Date().setHours(23, 59, 59, 999)), // End of today
        };
      } else {
        return {
          protected: false,
          reason:
            result.message || "Failed to apply automatic streak protection",
        };
      }
    } catch (error) {
      console.error("Error checking and protecting streak:", error);
      return { protected: false, error: error.message };
    }
  }
}

export default PowerUpInitializationService;
