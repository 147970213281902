import React from "react";

const LoadingSpinner = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-gold"></div>
    <span className="ml-3 text-lg font-medium">Loading...</span>
  </div>
);

export default LoadingSpinner;
