// contact.page.jsx
import React, { useEffect, useState } from "react";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component.jsx";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer.component.jsx";
import { useInView } from "react-intersection-observer";
import { PuffLoader } from "react-spinners";
import { db } from "../../firebase/firebase.utils.js";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";

const ContactPage = () => {
  // Form state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "General Inquiry", // Default subject
    message: "",
  });

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  // Loading animation
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Page title
  useEffect(() => {
    document.title = "Contact Us | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Form field change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Form validation
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    } else if (formData.message.trim().length < 10) {
      errors.message = "Message must be at least 10 characters";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setSubmitting(true);

    try {
      // Add the contact submission to Firestore
      await addDoc(collection(db, "contact_submissions"), {
        ...formData,
        timestamp: serverTimestamp(),
      });

      // Reset form
      setFormData({
        name: "",
        email: "",
        subject: "General Inquiry",
        message: "",
      });

      setSubmitStatus({
        type: "success",
        message: "Your message has been sent! We'll get back to you soon.",
      });

      // Clear status after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitStatus({
        type: "error",
        message: "There was an error sending your message. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  // Intersection observer for animations
  const [formRef, formInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const [infoRef, infoInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return loading ? (
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col min-h-screen bg-white"
    >
      {/* Header Section */}
      <div className="bg-gradient-to-r from-red to-black text-white">
        <NavBarPrimary />
        <FeedbackMenu />
        <div className="max-w-6xl mx-auto px-4 py-16 md:py-20 lg:py-24 w-5/6">
          <div className="text-center md:text-left md:flex md:items-center md:justify-between">
            <div className="md:max-w-2xl">
              <motion.h1
                className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-headers uppercase"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Contact Us
              </motion.h1>
              <motion.p
                className="text-xl md:text-2xl mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                We'd love to hear from you
              </motion.p>
            </div>
          </div>
        </div>

        {/* Wave divider */}
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full h-auto"
          >
            <path
              fill="#FCFCEE"
              fillOpacity="1"
              d="M0,32L48,42.7C96,53,192,75,288,74.7C384,75,480,53,576,48C672,43,768,53,864,58.7C960,64,1056,64,1152,56C1248,48,1344,32,1392,24L1440,16L1440,100L1392,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
            />
          </svg>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow bg-[#FCFCEE] w-5/6 mx-auto">
        <div className="max-w-6xl mx-auto px-4 py-8">
          {/* Contact Section */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 mb-20">
            {/* Form Column */}
            <motion.div
              ref={formRef}
              className="lg:col-span-2"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: formInView ? 1 : 0, y: formInView ? 0 : 30 }}
              transition={{ duration: 0.7 }}
            >
              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-red shadow-lg">
                <h2 className="text-2xl md:text-3xl font-headers font-bold mb-6">
                  Send Us a Message
                </h2>

                {submitStatus && (
                  <div
                    className={`mb-6 p-4 rounded-md ${
                      submitStatus.type === "success"
                        ? "bg-green/20 text-green"
                        : "bg-red/20 text-red"
                    }`}
                  >
                    {submitStatus.message}
                  </div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                    <label
                      htmlFor="name"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Your Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={`w-full px-4 py-3 rounded-md border ${
                        formErrors.name ? "border-red" : "border-gray-300"
                      } focus:outline-none focus:ring-2 focus:ring-red/50`}
                      placeholder="John Doe"
                    />
                    {formErrors.name && (
                      <p className="mt-1 text-red text-sm">{formErrors.name}</p>
                    )}
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`w-full px-4 py-3 rounded-md border ${
                        formErrors.email ? "border-red" : "border-gray-300"
                      } focus:outline-none focus:ring-2 focus:ring-red/50`}
                      placeholder="john@example.com"
                    />
                    {formErrors.email && (
                      <p className="mt-1 text-red text-sm">
                        {formErrors.email}
                      </p>
                    )}
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="subject"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Subject
                    </label>
                    <select
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red/50"
                    >
                      <option value="General Inquiry">General Inquiry</option>
                      <option value="Technical Support">
                        Technical Support
                      </option>
                      <option value="Billing Question">Billing Question</option>
                      <option value="Partnership Opportunity">
                        Partnership Opportunity
                      </option>
                      <option value="Feedback">Feedback</option>
                    </select>
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="message"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Your Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="6"
                      className={`w-full px-4 py-3 rounded-md border ${
                        formErrors.message ? "border-red" : "border-gray-300"
                      } focus:outline-none focus:ring-2 focus:ring-red/50`}
                      placeholder="How can we help you?"
                    ></textarea>
                    {formErrors.message && (
                      <p className="mt-1 text-red text-sm">
                        {formErrors.message}
                      </p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={submitting}
                    className={`px-6 py-3 bg-red text-white rounded-md font-bold shadow-lg transform transition-all duration-300 ${
                      submitting
                        ? "opacity-70 cursor-not-allowed"
                        : "hover:bg-red/90 hover:scale-105"
                    }`}
                  >
                    {submitting ? "Sending..." : "Send Message"}
                  </button>
                </form>
              </div>
            </motion.div>

            {/* Contact Info Column */}
            <motion.div
              ref={infoRef}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: infoInView ? 1 : 0, y: infoInView ? 0 : 30 }}
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-gold shadow-lg mb-8">
                <h2 className="text-2xl font-headers font-bold mb-6">
                  Contact Information
                </h2>

                <div className="space-y-6">
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-red flex items-center justify-center mr-4 flex-shrink-0">
                      <span className="text-white text-xl -rotate-45 text-outline-red">
                        📧
                      </span>
                    </div>
                    <div>
                      <h3 className="font-bold text-gray-700">Email</h3>
                      <p className="text-gray-600">
                        support@fastfluencyafrica.com
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-gold flex items-center justify-center mr-4 flex-shrink-0">
                      <span className="text-white text-xl -rotate-45 text-outline-gold">
                        🕒
                      </span>
                    </div>
                    <div>
                      <h3 className="font-bold text-gray-700">Response Time</h3>
                      <p className="text-gray-600">
                        We aim to respond within 24-48 hours during business
                        days.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-green flex items-center justify-center mr-4 flex-shrink-0">
                      <span className="text-white text-xl -rotate-45 text-outline-green">
                        💬
                      </span>
                    </div>
                    <div>
                      <h3 className="font-bold text-gray-700">Live Chat</h3>
                      <p className="text-gray-600">
                        Available Monday-Friday, 9am-5pm (SAST)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg p-6 md:p-8 border-2 border-b-4 border-green shadow-lg">
                <h2 className="text-2xl font-headers font-bold mb-6">FAQs</h2>
                <p className="text-gray-600 mb-4">
                  Looking for quick answers? Check out our frequently asked
                  questions:
                </p>
                <motion.a
                  href="/support"
                  className="inline-block px-6 py-3 bg-green text-white rounded-md font-bold shadow-lg hover:bg-green/90 transform transition-all duration-300 hover:scale-105"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Visit Support Center
                </motion.a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-b from-white to-gold w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default ContactPage;
