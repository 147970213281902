import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import { Menu, X } from "lucide-react";

const ShopNavigation = () => {
  const { balance } = useShopWithPowerUps();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Close menu when changing routes
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  // Define navigation items
  const navItems = [
    { path: "/shop", label: "Shop" },
    { path: "/inventory", label: "My Items" },
    { path: "/purchase-history", label: "Purchase History" },
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-red"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm sticky top-0 z-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center py-4">
          <div className="flex w-full sm:w-auto items-center justify-between mb-4 sm:mb-0">
            <h1 className="text-xl sm:text-2xl font-bold text-black">Shop</h1>

            {/* Mobile menu toggle button */}
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="sm:hidden bg-white p-2 border-2 border-darkgray rounded-md"
            >
              {menuOpen ? <X size={20} /> : <Menu size={20} />}
            </button>
          </div>

          <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-auto justify-center">
            <div className="flex items-center bg-white border-2 border-b-4 px-3 sm:px-4 py-2 sm:mr-4 gap-1">
              <span className="text-sm sm:text-base">
                Your current balance:
              </span>
              <span className="text-outline-black">🟠</span>
              <span className="font-medium text-black text-sm sm:text-base">
                {" "}
                {Math.round(balance)}
              </span>
            </div>
          </div>

          {/* Mobile navigation menu (dropdown) */}
          <nav
            className={`${
              menuOpen ? "flex" : "hidden"
            } sm:flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 space-x-0 sm:space-x-4 w-full sm:w-auto sm:justify-start`}
          >
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`px-3 py-2 text-sm font-medium transition-colors w-full sm:w-auto text-center ${
                  location.pathname === item.path
                    ? "bg-green text-white border-black border-2 border-b-4"
                    : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
                }`}
              >
                {item.label}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ShopNavigation;
