import React, { useContext } from "react";
import { motion } from "framer-motion";
import { ModalContext } from "../../../../helpers/modalcontext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const DialogueExitModal = () => {
  const { setModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleExitSession = () => {
    navigate("/units");
    setModal(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="bg-white p-4 rounded-xl max-w-sm w-full m-4 shadow-2xl border-4 border-green"
      >
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold font-headers text-green mb-2">
            Are you sure?
          </h2>
          <p className="text-gray-700">
            If you exit now, your progress on this dialogue will not be saved.
          </p>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={handleCloseModal}
            className="py-3 px-4 bg-white text-green border-2 border-b-4 border-green text-lg font-medium hover:bg-green hover:text-white transition-all"
          >
            Keep Learning
          </button>
          <button
            onClick={handleExitSession}
            className="py-3 px-4 bg-gray text-white border-darkgray border-2 border-b-4 text-lg font-medium hover:bg-green-dark transition-all text-center"
          >
            Yes, Exit
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default DialogueExitModal;
