// Improved mobile-responsive dictionary-search-status.component.jsx
import React from "react";
import { motion } from "framer-motion";

const DictionarySearchStatus = ({
  searchStatus,
  hasSearchLimitExceeded,
  membership,
}) => {
  if (!searchStatus) return null;

  // Helper function to format remaining time
  const formatRemainingTime = (expiryDate) => {
    if (!expiryDate) return null;

    const now = new Date();
    const expiry = new Date(expiryDate);
    const diffMs = expiry - now;

    if (diffMs <= 0) return "Expired";

    // For time less than 1 hour, show minutes
    if (diffMs < 60 * 60 * 1000) {
      const minutes = Math.floor(diffMs / (60 * 1000));
      const seconds = Math.floor((diffMs % (60 * 1000)) / 1000);
      return `${minutes}m ${seconds}s`;
    }

    // For time less than 1 day, show hours
    if (diffMs < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(diffMs / (60 * 60 * 1000));
      const minutes = Math.floor((diffMs % (60 * 60 * 1000)) / (60 * 1000));
      return `${hours}h ${minutes}m`;
    }

    // For longer time, show days
    const days = Math.ceil(diffMs / (24 * 60 * 60 * 1000));
    return `${days} day${days !== 1 ? "s" : ""}`;
  };

  // Unlimited access section
  if (searchStatus.hasUnlimited) {
    const timeRemaining = formatRemainingTime(searchStatus.unlimitedExpiresAt);

    return (
      <motion.div
        className="bg-green text-white px-3 sm:px-4 py-2 rounded-lg w-full text-center mt-3 sm:mt-4 mb-2"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="font-bold text-sm sm:text-base">
          Dictionary Boost Active!
        </div>
        <div className="text-xs sm:text-sm">
          Unlimited searches for {timeRemaining}
        </div>
      </motion.div>
    );
  }

  // Show search limit information (base + additional)
  return (
    <div className="mt-2 w-full">
      {searchStatus.additionalSearches > 0 && (
        <motion.div
          className="border-gold border-2 border-b-4 text-black px-3 sm:px-4 py-2 rounded w-full text-center mb-2"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="font-bold text-sm sm:text-base">
            Dictionary Boost Active!
          </div>
          <div className="text-xs sm:text-sm">
            {searchStatus.additionalSearches} additional searches
            {searchStatus.additionalSearchesExpiresAt && (
              <span>
                {" "}
                (Expires in{" "}
                {formatRemainingTime(searchStatus.additionalSearchesExpiresAt)})
              </span>
            )}
          </div>
        </motion.div>
      )}

      <div className="text-xs sm:text-sm mt-2 font-bold">
        <div className="flex justify-start items-start gap-1">
          <span>Searches remaining today: </span>
          <span className="text-red">
            {" "}
            {searchStatus.totalSearchesRemaining}
          </span>
        </div>

        {/* Show where searches are coming from */}
        <div className="text-xs mt-1 bg-gray-100 p-1 sm:p-2 rounded">
          {/* Show base searches */}
          <span className="font-semibold">
            {searchStatus.baseSearches} base
          </span>

          {/* Show additional searches if any */}
          {searchStatus.additionalSearches > 0 && (
            <span>
              {" "}
              +{" "}
              <span className="font-bold text-green">
                {searchStatus.additionalSearches} bonus
              </span>
            </span>
          )}

          {/* Show used searches */}
          <span> - {searchStatus.usedToday} used</span>

          {/* Show equals total */}
          <span>
            {" "}
            ={" "}
            <span className="font-semibold text-red">
              {searchStatus.totalSearchesRemaining}
            </span>{" "}
            remaining
          </span>
        </div>

        {hasSearchLimitExceeded && (
          <div className="text-center mt-2 font-bold text-red text-xs sm:text-sm">
            {membership === "george" ? (
              <span>
                Daily limit reached! Upgrade your membership subscription to
                search more!
              </span>
            ) : (
              <span className="mx-auto">
                Guest limit reached! Register to continue
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DictionarySearchStatus;
