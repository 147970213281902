// services/achievements.service.js

/**
 * Achievement Types - for categorizing and filtering achievements
 */
export const ACHIEVEMENT_TYPES = {
  STREAK: "streak",
  VOCABULARY: "vocabulary",
  LESSONS: "lessons",
  XP: "xp",
  PRACTICE: "practice",
  ACCURACY: "accuracy",
};

/**
 * Master list of all possible achievements in the application
 */
export const ACHIEVEMENTS = [
  {
    id: "weekly-warrior",
    type: ACHIEVEMENT_TYPES.STREAK,
    icon: "🔥",
    title: "Weekly Warrior",
    description: "Maintain a 7-day streak",
    targetValue: 7,
    valueKey: "current_streak",
    unit: "days",
    order: 1,
    tier: 1, // Basic tier
    prerequisites: [], // No prerequisites
  },
  {
    id: "word-master",
    type: ACHIEVEMENT_TYPES.VOCABULARY,
    icon: "📚",
    title: "Word Master",
    description: "Learn 100 vocabulary words",
    targetValue: 100,
    valueKey: "vocabulary_learned_count",
    unit: "words",
    order: 2,
    tier: 1, // Basic tier
    prerequisites: [], // No prerequisites
  },
  {
    id: "lesson-champion",
    type: ACHIEVEMENT_TYPES.LESSONS,
    icon: "🎓",
    title: "Lesson Champion",
    description: "Complete 10 lessons",
    targetValue: 10,
    valueKey: "lessons_completed_count",
    unit: "lessons",
    order: 3,
    tier: 1, // Basic tier
    prerequisites: [], // No prerequisites
  },
  {
    id: "xp-milestone",
    type: ACHIEVEMENT_TYPES.XP,
    icon: "🏆",
    title: "XP Milestone",
    description: "Earn 1000 XP points",
    targetValue: 1000,
    valueKey: "total_xp",
    unit: "XP",
    order: 4,
    tier: 1, // Basic tier
    prerequisites: [], // No prerequisites
  },
  {
    id: "practice-pro",
    type: ACHIEVEMENT_TYPES.PRACTICE,
    icon: "💯",
    title: "Practice Pro",
    description: "Answer 100 practice questions",
    targetValue: 100,
    valueKey: "practice_questions_answered",
    unit: "questions",
    order: 5,
    tier: 1, // Basic tier
    prerequisites: [], // No prerequisites
  },
  {
    id: "accuracy-expert",
    type: ACHIEVEMENT_TYPES.ACCURACY,
    icon: "🎯",
    title: "Accuracy Expert",
    description: "Get 50 correct answers",
    targetValue: 50,
    valueKey: "correct_answers_count",
    unit: "correct answers",
    order: 6,
    tier: 1, // Basic tier
    prerequisites: [], // No prerequisites
  },

  // Advanced Achievements
  {
    id: "vocabulary-expert",
    type: ACHIEVEMENT_TYPES.VOCABULARY,
    icon: "📚",
    title: "Vocabulary Expert",
    description: "Learn 500 vocabulary words",
    targetValue: 500,
    valueKey: "vocabulary_learned_count",
    unit: "words",
    order: 7,
    tier: 2, // Advanced tier
    prerequisites: ["word-master"], // Requires Word Master achievement
  },
  {
    id: "monthly-warrior",
    type: ACHIEVEMENT_TYPES.STREAK,
    icon: "🔥",
    title: "Monthly Warrior",
    description: "Maintain a 30-day streak",
    targetValue: 30,
    valueKey: "current_streak",
    unit: "days",
    order: 8,
    tier: 2, // Advanced tier
    prerequisites: ["weekly-warrior"], // Requires Weekly Warrior achievement
  },
  {
    id: "lesson-master",
    type: ACHIEVEMENT_TYPES.LESSONS,
    icon: "🎓",
    title: "Lesson Master",
    description: "Complete 50 lessons",
    targetValue: 50,
    valueKey: "lessons_completed_count",
    unit: "lessons",
    order: 9,
    tier: 2, // Advanced tier
    prerequisites: ["lesson-champion"], // Requires Lesson Champion achievement
  },
  {
    id: "xp-guru",
    type: ACHIEVEMENT_TYPES.XP,
    icon: "👑",
    title: "XP Guru",
    description: "Earn a total of 5000 XP points",
    targetValue: 5000,
    valueKey: "total_xp",
    unit: "XP",
    order: 10,
    tier: 2, // Advanced tier
    prerequisites: ["xp-milestone"], // Requires XP Milestone achievement
  },
];

/**
 * Calculate progress info for a single achievement
 * @param {Object} achievement The achievement definition
 * @param {Object} analyticsData User analytics data
 * @returns {Object} Progress information
 */
export const getAchievementProgress = (achievement, analyticsData) => {
  if (!analyticsData) {
    return {
      currentValue: 0,
      targetValue: achievement.targetValue,
      progress: 0,
      isComplete: false,
      progressText: `0/${achievement.targetValue} ${achievement.unit}`,
    };
  }

  const currentValue = analyticsData[achievement.valueKey] || 0;
  const isComplete = currentValue >= achievement.targetValue;

  // Calculate progress percentage (capped at 100% and rounded to 1 decimal place)
  const progress = Math.min(
    100,
    Math.round((currentValue / achievement.targetValue) * 100 * 10) / 10
  );

  // Format progress text
  const displayValue = isComplete ? achievement.targetValue : currentValue;
  const progressText = `${displayValue}/${achievement.targetValue} ${achievement.unit}`;

  return {
    currentValue,
    targetValue: achievement.targetValue,
    progress,
    isComplete,
    progressText,
  };
};

/**
 * Check if all prerequisites for an achievement are completed
 * @param {Object} achievement The achievement to check
 * @param {Array} completedAchievements List of completed achievement IDs
 * @returns {Boolean} Whether prerequisites are met
 */
export const arePrerequisitesMet = (achievement, completedAchievements) => {
  if (!achievement.prerequisites || achievement.prerequisites.length === 0) {
    return true; // No prerequisites
  }

  // Check if all prerequisites are in the completed list
  return achievement.prerequisites.every((prereqId) =>
    completedAchievements.includes(prereqId)
  );
};

/**
 * Get achievements that should be visible to the user
 * @param {Object} analyticsData User analytics data
 * @returns {Array} List of visible achievements with progress information
 */
export const getUserAchievements = (analyticsData) => {
  // First, calculate progress for all achievements
  const allAchievements = ACHIEVEMENTS.map((achievement) => ({
    ...achievement,
    ...getAchievementProgress(achievement, analyticsData),
  }));

  // Get IDs of completed achievements
  const completedAchievementIds = allAchievements
    .filter((a) => a.isComplete)
    .map((a) => a.id);

  // Filter to only show achievements that should be visible:
  // 1. Tier 1 achievements (always visible)
  // 2. Higher tier achievements where prerequisites are met
  const visibleAchievements = allAchievements.filter(
    (achievement) =>
      achievement.tier === 1 || // Tier 1 achievements are always visible
      arePrerequisitesMet(achievement, completedAchievementIds) // Prerequisites are met
  );

  // Sort by order
  return visibleAchievements.sort((a, b) => a.order - b.order);
};

/**
 * Filter achievements by completion status
 * @param {Array} achievements List of achievements
 * @param {String} filter 'all', 'completed', or 'inProgress'
 * @returns {Array} Filtered achievements
 */
export const filterAchievements = (achievements, filter) => {
  if (filter === "all") return achievements;
  if (filter === "completed") return achievements.filter((a) => a.isComplete);
  if (filter === "inProgress") return achievements.filter((a) => !a.isComplete);
  return achievements;
};

/**
 * Get achievements formatted for the dashboard display
 * @param {Object} analyticsData User analytics data
 * @returns {Array} Achievements formatted for dashboard display
 */
export const getDashboardAchievements = (analyticsData) => {
  return getUserAchievements(analyticsData).map((achievement) => ({
    id: achievement.id,
    icon: achievement.icon,
    title: achievement.title,
    description: achievement.description,
    currentValue: achievement.currentValue,
    targetValue: achievement.targetValue,
    isComplete: achievement.isComplete,
    progress: achievement.progress,
  }));
};

/**
 * Get achievements by category
 * @param {Array} achievements List of achievements
 * @param {String} type Achievement type
 * @returns {Array} Filtered achievements
 */
export const getAchievementsByType = (achievements, type) => {
  return achievements.filter((a) => a.type === type);
};

/**
 * Count completed achievements
 * @param {Array} achievements List of achievements
 * @returns {Number} Count of completed achievements
 */
export const countCompletedAchievements = (achievements) => {
  return achievements.filter((a) => a.isComplete).length;
};

/**
 * Format achievement data for AchievementItem component
 * @param {Object} achievement Achievement with progress data
 * @returns {Object} Formatted for AchievementItem
 */
export const formatForAchievementItem = (achievement) => {
  return {
    id: achievement.id,
    icon: achievement.icon,
    title: achievement.title,
    description: achievement.description,
    progress: achievement.progress,
    progressText: achievement.progressText,
    isComplete: achievement.isComplete,
    currentValue: achievement.currentValue,
    targetValue: achievement.targetValue,
  };
};
