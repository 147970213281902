import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../../helpers/UserAuthContextProvider";

const CurrentEnrolmentsCard = () => {
  const { user } = useAuth();

  const [enrollment, setEnrollment] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userMotive, setUserMotive] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // Flag to check if component is still mounted

    const getCurrUser = async () => {
      if (user && isMounted) {
        setLoading(true);
        try {
          const userId = user?.uid;
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists() && isMounted) {
            setEnrollment({ ...docSnap.data(), id: docSnap.id });
            setIsSubscribed(docSnap.data().membership);
            setUserMotive(docSnap.data().motive);
          } else if (isMounted) {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching enrollment data:", error);
        } finally {
          if (isMounted) setLoading(false);
        }
      }
    };

    if (user) {
      getCurrUser();
    }

    return () => {
      isMounted = false; // Component is unmounting
    };
  }, [user?.uid]);

  return (
    <div className="mt-4 bg-white border-darkgray shadow-2xl w-full m-auto p-3 sm:p-5 md:p-8 rounded text-right border-2 border-b-4">
      {loading ? (
        <div className="flex justify-center items-center py-6">
          <BeatLoader color="#991616" size={10} />
        </div>
      ) : (
        <div>
          <h3 className="text-sm sm:text-base md:text-lg text-center font-headers font-bold">
            {enrollment &&
              (enrollment.motive === "Social" &&
              enrollment.currentEnrollSocial ? (
                enrollment.currentEnrollSocial.course
              ) : enrollment.motive === "Academic" &&
                enrollment.currentEnrollAcademic ? (
                enrollment.currentEnrollAcademic.course
              ) : (
                <div data-testid="beat-loader">
                  <BeatLoader color="#000000" size={5} />
                </div>
              ))}
          </h3>
          <div className="flex flex-col items-center justify-between mt-2">
            <span className="font-body text-xs sm:text-sm md:text-base">
              {enrollment &&
                (enrollment.motive === "Social" &&
                enrollment.currentEnrollSocial ? (
                  enrollment.currentEnrollSocial.title
                ) : enrollment.motive === "Academic" &&
                  enrollment.currentEnrollAcademic ? (
                  enrollment.currentEnrollAcademic.title
                ) : (
                  <BeatLoader color="#000000" size={5} />
                ))}
            </span>
          </div>

          <Link
            to="/vault"
            className="block w-full sm:w-2/3 md:w-1/2 mx-auto mt-4 py-3 px-4 text-center text-red font-bold hover:text-white border-2 border-b-4 border-red hover:border-white hover:bg-red text-sm sm:text-base leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out cursor-pointer"
          >
            Start Learning
          </Link>
        </div>
      )}
    </div>
  );
};

export default CurrentEnrolmentsCard;
