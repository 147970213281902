import React, { useEffect, useState } from "react";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Footer from "../../components/footer/footer.component";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { PuffLoader } from "react-spinners";
import FeedbackMenu from "../../components/support/feedback-menu";

// New FAQ Item Component (Same as in pricing page)
const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="border-b border-darkgray/30 last:border-b-0"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1, duration: 0.5 }}
    >
      <button
        className="flex justify-between items-center w-full py-4 text-left font-medium hover:text-red transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-headers">{question}</span>
        <div
          className={`h-6 w-6 bg-gold rounded-sm rotate-45 flex items-center justify-center transition-transform duration-300 ${
            isOpen ? "rotate-[225deg]" : "rotate-45"
          }`}
        >
          <svg
            className="w-4 h-4 text-white -rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <motion.div
        className="overflow-hidden"
        initial={false}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <p className="pb-4 text-gray-700">{answer}</p>
      </motion.div>
    </motion.div>
  );
};

const JoinTribePage = () => {
  // State for role filter
  const [activeRole, setActiveRole] = useState("all");

  // State for accordion sections
  const [openAccordion, setOpenAccordion] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Set page title
  useEffect(() => {
    document.title = "Join the Tribe | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Define roles data from the PDF
  const rolesData = [
    {
      id: "translator",
      title: "Translator & Proofreader",
      category: "content",
      shortDescription:
        "Create and perfect our learning materials in African languages",
      responsibilities: [
        "Regularly review Communication Channels for any relevant updates of new work",
        "Proofread all batches to ensure there are no spelling or grammatical mistakes",
        "Review Translator's Checklist when beginning a new batch of material",
        "Translate agreed upon number of learning materials weekly (minimum 20 texts/exercises per week)",
        "Review all completed translations against the Translator's Checklist",
      ],
      requirements: [
        "Have a reliable and stable internet connection",
        "Have access to your own laptop, tablet, or phone",
        "Have a passion for patiently helping new learners overcome language fears",
        "Have experience working in a teaching context, preferably via online instruction",
        "Enjoy meeting new people and learning about other cultures",
        "Be open to feedback and love working with others",
        "Experience with technologies like Zoom, Slack, WhatsApp, Google Workspace, etc.",
      ],
      bonusPoints: [
        "Completed a BEd Degree majoring in African languages",
        "Are a Mother Tongue Speaker born and bred in the region where the language is mostly spoken",
        "Can share a portfolio of previous work with adult language learners",
      ],
      color: "red",
    },
    {
      id: "coach",
      title: "isiZulu Language Coach",
      category: "teaching",
      shortDescription:
        "Guide and inspire language learners through engaging coaching sessions",
      responsibilities: [
        "Spend 5-10 hours per week working with the Service Delivery Team",
        "Conduct language coaching sessions with users based on demand",
        "Attend regular briefing meetings with Language Unit teams (full-time only)",
        "Prepare for weekly coaching sessions by reviewing lesson plans and materials",
        "Make edits and proofread mistakes in learning materials",
        "Provide weekly reports to Chief Language Coaches",
        "Develop and organize supplementary materials for coaching sessions",
      ],
      compensation:
        "R50/hour - R200/hour for each coaching session. Independent contractor basis with flexible scheduling.",
      requirements: [
        "Have a reliable and stable internet connection",
        "Have access to your own laptop, tablet, or phone",
        "Have a passion for patiently helping new learners overcome language fears",
        "Have experience working in a teaching context, preferably via online instruction",
        "Enjoy meeting new people and learning about other cultures",
        "Be open to feedback and love working with others",
        "Experience with technologies like Zoom, Slack, WhatsApp, Google Drive, etc.",
      ],
      bonusPoints: [
        "Currently studying a BEd Degree and majoring in isiZulu languages",
        "Mother Tongue Speaker born and bred in the region where the language is mostly spoken",
        "Can share a portfolio of previous work with adult language learners",
      ],
      color: "gold",
    },
    {
      id: "ambassador",
      title: "Ambassador",
      category: "marketing",
      shortDescription:
        "Help grow our community through referrals, networking, and sales",
      responsibilities: [
        "Secure Referrals: Identify and refer potential customers to expand our client base",
        "Head-hunt Staff: Identify qualified candidates to fill vacancies",
        "Sales and Marketing: Engage in promotional activities to increase brand visibility",
        "Service Existing Accounts: Maintain relationships with current customers",
        "Plan and Organize Work: Develop daily schedules to contact sales outlets and clients",
        "Customize Sales Presentations: Tailor pitches to specific customer needs",
        "Study Market Trends: Analyze market volume and competitor activities",
        "Manage Orders and Documentation: Process orders and maintain detailed records",
        "Report to Management: Provide regular updates and activity reports",
        "Monitor Competition: Gather information on competitors to stay competitive",
        "Recommend Improvements: Propose changes based on customer feedback",
        "Resolve Customer Complaints: Investigate and address customer issues",
        "Provide Customer Support: Keep customers engaged and booking sessions",
        "Enhance Professional Knowledge: Participate in workshops and networking",
      ],
      compensation:
        "Commission-based role. Earn a minimum of R50/month for every new customer secured, for every language coaching session they complete.",
      requirements: [
        "Have a reliable and stable internet connection",
        "Own a laptop, tablet, or smartphone",
        "Enjoy meeting new people and learning about different cultures",
        "Be open to feedback and thrive in a collaborative environment",
        "Experience using tools like Zoom, WhatsApp, Google Drive, etc.",
      ],
      bonusPoints: [
        "Experience in sales, marketing, or business development",
        "Strong interpersonal and networking skills",
        "Demonstrate initiative and creativity in identifying opportunities",
      ],
      color: "green",
    },
  ];

  // Values data
  const valuesData = [
    {
      name: "COMMUNITY",
      description:
        "We believe in building a supportive environment where everyone can contribute and grow together.",
      icon: "👥",
      color: "red",
    },
    {
      name: "FUN",
      description:
        "Learning should be enjoyable. We bring creativity and playfulness to everything we do.",
      icon: "🎮",
      color: "gold",
    },
    {
      name: "SPEED",
      description:
        "We move quickly and efficiently, without compromising on quality or attention to detail.",
      icon: "⚡",
      color: "green",
    },
    {
      name: "QUALITY",
      description:
        "We are committed to excellence in our products, services, and interactions.",
      icon: "✨",
      color: "red",
    },
    {
      name: "ORIGINALITY",
      description:
        "We value fresh ideas and unique approaches that push boundaries.",
      icon: "💡",
      color: "gold",
    },
  ];

  // Perks and benefits
  const perksData = [
    {
      title: "100% Remote Work",
      description: "Work from anywhere with an internet connection",
      icon: "🏠",
      color: "red",
    },
    {
      title: "Flexible Schedule",
      description: "Set your own hours and work at your pace",
      icon: "⏰",
      color: "gold",
    },
    {
      title: "Growth Opportunities",
      description: "Continuous learning and advancement paths",
      icon: "📈",
      color: "green",
    },
    {
      title: "Collaborative Culture",
      description:
        "Work with a diverse team passionate about African languages",
      icon: "🤝",
      color: "red",
    },
    {
      title: "Meaningful Impact",
      description: "Help preserve and promote African languages and cultures",
      icon: "🌍",
      color: "gold",
    },
    {
      title: "Innovative Environment",
      description: "Contribute to cutting-edge edutainment solutions",
      icon: "🚀",
      color: "green",
    },
  ];

  // Team testimonials
  const testimonialsData = [
    {
      name: "Sheron N.",
      role: "isiZulu Language Coach",
      quote:
        "Working with Fast Fluency Africa has given me the opportunity to share my mother tongue with eager learners while having the flexibility to work on my own terms.",
      avatar: "S",
      period: "Tribe member from 2022",
    },
    {
      name: "Phemelo S.",
      role: "Translator & Proofreader",
      quote:
        "I love being part of preserving our languages while working remotely. The team is supportive and I've grown both professionally and personally.",
      avatar: "P",
      period: "Tribe member from 2021",
    },
    {
      name: "Lesedi M.",
      role: "Ambassador",
      quote:
        "The commission structure is fair and the work is rewarding. I've built a great network and it's wonderful to see how excited people get about learning African languages.",
      avatar: "L",
      period: "Tribe member since 2025",
    },
  ];

  // FAQ data
  const faqData = [
    {
      question: "What is the application process like?",
      answer:
        "After submitting your application, qualified candidates will be invited for an interview. Successful applicants undergo a three-month probationary period to assess competency and fit.",
    },
    {
      question: "Do I need to speak an African language fluently?",
      answer:
        "For Language Coaches and Translators, yes. Being a mother tongue speaker from the relevant region is highly valued. Ambassadors don't necessarily need to speak an African language fluently.",
    },
    {
      question: "How many hours am I expected to work?",
      answer:
        "It varies by role. Language Coaches typically work 5-10 hours per week, while Translators have a minimum target of 20 texts/exercises per week. As an independent contractor, you have flexibility in your schedule.",
    },
    {
      question: "Is this a full-time position?",
      answer:
        "Most roles are flexible, independent contractor positions. You can work according to your own schedule, making it ideal to combine with other commitments.",
    },
    {
      question: "What support will I receive as a new Tribe member?",
      answer:
        "You'll receive comprehensive training, access to regular Tribe meetings, and ongoing support from experienced members to help you succeed in your role.",
    },
  ];

  // InView hooks for animations
  const [heroRef, heroInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [valuesRef, valuesInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [rolesRef, rolesInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [perksRef, perksInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [testimonialsRef, testimonialsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [faqRef, faqInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  // Helper function to get color classes based on color name
  const getColorClasses = (color) => {
    switch (color) {
      case "red":
        return {
          bg: "bg-red",
          text: "text-red",
          border: "border-red",
          hover: "hover:bg-red hover:text-white",
        };
      case "gold":
        return {
          bg: "bg-gold",
          text: "text-gold",
          border: "border-gold",
          hover: "hover:bg-gold hover:text-white",
        };
      case "green":
        return {
          bg: "bg-green",
          text: "text-green",
          border: "border-green",
          hover: "hover:bg-green hover:text-white",
        };
      default:
        return {
          bg: "bg-gray",
          text: "text-gray",
          border: "border-gray",
          hover: "hover:bg-gray hover:text-white",
        };
    }
  };

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <div className="bg-gradient-to-r from-red to-black text-white">
        <NavBarPrimary />
        <FeedbackMenu />

        {/* Hero Section */}
        <motion.div
          ref={heroRef}
          className="max-w-6xl mx-auto px-4 py-16 md:py-20 lg:py-24 w-5/6"
          initial={{ opacity: 0 }}
          animate={{ opacity: heroInView ? 1 : 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="text-center md:text-left md:flex md:items-center md:justify-between">
            <div className="md:max-w-2xl lg:max-w-3xl">
              <motion.h1
                className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-headers uppercase"
                initial={{ opacity: 0, y: -20 }}
                animate={{
                  opacity: heroInView ? 1 : 0,
                  y: heroInView ? 0 : -20,
                }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Join Our Tribe
              </motion.h1>
              <motion.p
                className="text-xl md:text-2xl mb-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: heroInView ? 1 : 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                Help thousands of people know African languages, cultures, and
                stories.
              </motion.p>
              <motion.p
                className="text-lg mb-8 max-w-3xl w-2/3"
                initial={{ opacity: 0 }}
                animate={{ opacity: heroInView ? 1 : 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                Fast Fluency Africa is the #1 African mother tongue edutainment
                platform. We're looking for passionate people to join our Tribe
                to help develop original content, coach our users, sell and
                promote our products, and build tech solutions.
              </motion.p>
              <motion.a
                href="https://bit.ly/ffarecruitment"
                target="_blank"
                rel="noreferrer"
                className="inline-block py-3 px-8 bg-gold text-white font-bold rounded-md border-2 border-b-4 border-white shadow-lg hover:bg-gold/90 transition-all transform hover:scale-105"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{
                  opacity: heroInView ? 1 : 0,
                  scale: heroInView ? 1 : 0.9,
                }}
                transition={{ duration: 0.5, delay: 0.8 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Apply Now
              </motion.a>
            </div>
            <motion.div
              className="hidden md:block md:w-2/5"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: heroInView ? 1 : 0, x: heroInView ? 0 : 50 }}
              transition={{ duration: 0.7, delay: 0.5 }}
            >
              <div className="relative">
                <div className="absolute -top-6 -left-6 w-24 h-24 bg-green/30 rounded-sm rotate-45 z-0"></div>
                <div className="absolute -bottom-6 -right-6 w-24 h-24 bg-red/30 rounded-sm rotate-45 z-0"></div>
                <div className="bg-white/10 backdrop-blur-sm p-6 border-2 border-b-4 border-white rounded-md shadow-xl relative z-10">
                  <h3 className="text-xl font-bold mb-4">
                    We're growing fast!
                  </h3>
                  <ul className="space-y-3">
                    <li className="flex items-center">
                      <div className="w-6 h-6 rounded-sm rotate-45 bg-gold/40 mr-3 flex items-center justify-center">
                        <span className="text-white -rotate-45">✓</span>
                      </div>
                      <span>Remote-first culture</span>
                    </li>
                    <li className="flex items-center">
                      <div className="w-6 h-6 rounded-sm rotate-45 bg-gold/40 mr-3 flex items-center justify-center">
                        <span className="text-white -rotate-45">✓</span>
                      </div>
                      <span>Flexible working hours</span>
                    </li>
                    <li className="flex items-center">
                      <div className="w-6 h-6 rounded-sm rotate-45 bg-gold/40 mr-3 flex items-center justify-center">
                        <span className="text-white -rotate-45">✓</span>
                      </div>
                      <span>Meaningful cultural impact</span>
                    </li>
                    <li className="flex items-center">
                      <div className="w-6 h-6 rounded-sm rotate-45 bg-gold/40 mr-3 flex items-center justify-center">
                        <span className="text-white -rotate-45">✓</span>
                      </div>
                      <span>Passionate, diverse team</span>
                    </li>
                  </ul>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Wave divider */}
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full h-auto"
          >
            <path
              fill="#FCFCEE"
              fillOpacity="1"
              d="M0,32L48,42.7C96,53,192,75,288,74.7C384,75,480,53,576,48C672,43,768,53,864,58.7C960,64,1056,64,1152,56C1248,48,1344,32,1392,24L1440,16L1440,100L1392,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
            />
          </svg>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow bg-[#FCFCEE] w-5/6 mx-auto">
        <div className="max-w-6xl mx-auto px-4 py-12">
          {/* Our Values Section */}
          <motion.section
            ref={valuesRef}
            className="mb-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: valuesInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
                Our Values
              </h2>
              <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                These core values guide everything we do at Fast Fluency Africa
                and shape our unique culture.
              </p>
            </div>

            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              variants={containerVariants}
              initial="hidden"
              animate={valuesInView ? "visible" : "hidden"}
            >
              {valuesData.map((value, index) => {
                const colors = getColorClasses(value.color);
                return (
                  <motion.div
                    key={index}
                    className={`bg-white rounded-lg border-2 border-b-4 ${colors.border} p-6 shadow-lg`}
                    variants={itemVariants}
                    whileHover={{ y: -5 }}
                  >
                    <div className="flex items-center mb-4">
                      <div
                        className={`w-12 h-12 ${colors.bg}/20 rounded-sm rotate-45 flex items-center justify-center mr-4`}
                      >
                        <span className={`text-2xl ${colors.text} -rotate-45`}>
                          {value.icon}
                        </span>
                      </div>
                      <h3 className={`text-xl font-bold ${colors.text}`}>
                        {value.name}
                      </h3>
                    </div>
                    <p className="text-gray-700">{value.description}</p>
                  </motion.div>
                );
              })}
            </motion.div>
          </motion.section>

          {/* Available Roles Section */}
          <motion.section
            ref={rolesRef}
            className="mb-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: rolesInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-gold mb-4 font-headers">
                Available Roles
              </h2>
              <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                Join our mission to make African language learning accessible,
                authentic, and enjoyable.
              </p>
            </div>

            {/* Role filter tabs */}
            <div className="flex justify-center mb-10">
              <div className="inline-flex border-2 border-darkgray rounded-lg overflow-hidden">
                <button
                  className={`px-4 py-2 text-sm font-medium ${
                    activeRole === "all"
                      ? "bg-gold text-white"
                      : "bg-white text-gray-700"
                  }`}
                  onClick={() => setActiveRole("all")}
                >
                  All Roles
                </button>
                <button
                  className={`px-4 py-2 text-sm font-medium ${
                    activeRole === "content"
                      ? "bg-red text-white"
                      : "bg-white text-gray-700"
                  }`}
                  onClick={() => setActiveRole("content")}
                >
                  Content
                </button>
                <button
                  className={`px-4 py-2 text-sm font-medium ${
                    activeRole === "teaching"
                      ? "bg-gold text-white"
                      : "bg-white text-gray-700"
                  }`}
                  onClick={() => setActiveRole("teaching")}
                >
                  Teaching
                </button>
                <button
                  className={`px-4 py-2 text-sm font-medium ${
                    activeRole === "marketing"
                      ? "bg-green text-white"
                      : "bg-white text-gray-700"
                  }`}
                  onClick={() => setActiveRole("marketing")}
                >
                  Marketing
                </button>
              </div>
            </div>

            {/* Role cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {rolesData
                .filter(
                  (role) => activeRole === "all" || role.category === activeRole
                )
                .map((role, index) => {
                  const colors = getColorClasses(role.color);
                  return (
                    <motion.div
                      key={role.id}
                      className={`bg-white rounded-lg border-2 border-b-4 ${colors.border} overflow-hidden shadow-lg`}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{
                        opacity: rolesInView ? 1 : 0,
                        y: rolesInView ? 0 : 20,
                      }}
                      transition={{
                        duration: 0.5,
                        delay: index * 0.1 + 0.2,
                      }}
                      whileHover={{ y: -5 }}
                    >
                      <div className={`${colors.bg} text-white p-4`}>
                        <h3 className="text-xl font-bold">{role.title}</h3>
                        <p className="text-white/90">{role.shortDescription}</p>
                      </div>

                      <div className="p-4">
                        <h4 className="font-bold text-gray-800 mb-2">
                          Key Responsibilities:
                        </h4>
                        <ul className="text-sm text-gray-600 space-y-1 mb-4">
                          {role.responsibilities.slice(0, 4).map((item, i) => (
                            <li key={i} className="flex items-start">
                              <div
                                className={`mt-1 h-3 w-3 ${colors.bg} rounded-sm mr-2 flex-shrink-0`}
                              ></div>
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>

                        {/* {role.compensation && (
                          <div className="mb-4">
                            <h4 className="font-bold text-gray-800 mb-1">
                              Compensation:
                            </h4>
                            <p className="text-sm text-gray-600">
                              {role.compensation}
                            </p>
                          </div>
                        )} */}

                        <div className="flex justify-between items-center mt-4">
                          <button
                            className="text-sm font-medium underline"
                            onClick={() =>
                              setOpenAccordion(
                                openAccordion === role.id ? null : role.id
                              )
                            }
                          >
                            {openAccordion === role.id
                              ? "Show less"
                              : "View details"}
                          </button>
                          <motion.a
                            href="https://bit.ly/ffarecruitment"
                            target="_blank"
                            rel="noreferrer"
                            className={`inline-block py-2 px-5 border-2 ${colors.border} ${colors.text} rounded ${colors.hover} transition-colors duration-200`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            Apply
                          </motion.a>
                        </div>

                        {/* Expandable details */}
                        <motion.div
                          className="overflow-hidden mt-4"
                          initial={false}
                          animate={{
                            height: openAccordion === role.id ? "auto" : 0,
                            opacity: openAccordion === role.id ? 1 : 0,
                          }}
                          transition={{ duration: 0.3 }}
                        >
                          {openAccordion === role.id && (
                            <div className="border-t border-gray-200 pt-4">
                              <h4 className="font-bold text-gray-800 mb-2">
                                Requirements:
                              </h4>
                              <ul className="text-sm text-gray-600 space-y-1 mb-4">
                                {role.requirements.map((item, i) => (
                                  <li key={i} className="flex items-start">
                                    <div
                                      className={`mt-1 h-3 w-3 ${colors.bg} rounded-sm mr-2 flex-shrink-0`}
                                    ></div>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>

                              <h4 className="font-bold text-gray-800 mb-2">
                                You'll get extra points if you:
                              </h4>
                              <ul className="text-sm text-gray-600 space-y-1 mb-4">
                                {role.bonusPoints.map((item, i) => (
                                  <li key={i} className="flex items-start">
                                    <div
                                      className={`mt-1 h-3 w-3 ${colors.bg} rounded-sm mr-2 flex-shrink-0`}
                                    ></div>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>

                              {role.responsibilities.length > 4 && (
                                <>
                                  <h4 className="font-bold text-gray-800 mb-2">
                                    All Responsibilities:
                                  </h4>
                                  <ul className="text-sm text-gray-600 space-y-1 mb-4">
                                    {role.responsibilities.map((item, i) => (
                                      <li key={i} className="flex items-start">
                                        <div
                                          className={`mt-1 h-3 w-3 ${colors.bg} rounded-sm mr-2 flex-shrink-0`}
                                        ></div>
                                        <span>{item}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}
                            </div>
                          )}
                        </motion.div>
                      </div>
                    </motion.div>
                  );
                })}
            </div>
          </motion.section>

          {/* Perks & Benefits Section */}
          <motion.section
            ref={perksRef}
            className="mb-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: perksInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-green mb-4 font-headers">
                Perks & Benefits
              </h2>
              <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                What makes being part of our Tribe special?
              </p>
            </div>

            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              variants={containerVariants}
              initial="hidden"
              animate={perksInView ? "visible" : "hidden"}
            >
              {perksData.map((perk, index) => {
                const colors = getColorClasses(perk.color);
                return (
                  <motion.div
                    key={index}
                    className="bg-white rounded-lg p-6 shadow-lg border-2 border-l-4 border-b-4 border-darkgray"
                    variants={itemVariants}
                    whileHover={{ x: 5 }}
                  >
                    <div className="flex items-center mb-4">
                      <div
                        className={`w-12 h-12 ${colors.bg}/20 rounded-sm rotate-45 flex items-center justify-center mr-4`}
                      >
                        <span className={`text-2xl ${colors.text} -rotate-45`}>
                          {perk.icon}
                        </span>
                      </div>
                      <h3 className={`text-xl font-bold ${colors.text}`}>
                        {perk.title}
                      </h3>
                    </div>
                    <p className="text-gray-700">{perk.description}</p>
                  </motion.div>
                );
              })}
            </motion.div>
          </motion.section>

          {/* Team Testimonials */}
          <motion.section
            ref={testimonialsRef}
            className="mb-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: testimonialsInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-red mb-4 font-headers">
                Meet Our Tribe
              </h2>
              <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                Hear from our Tribe members about what it's like to be part of
                Fast Fluency Africa.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonialsData.map((testimonial, index) => (
                <motion.div
                  key={index}
                  className="bg-white rounded-lg shadow-lg overflow-hidden border-2 border-b-4 border-darkgray"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: testimonialsInView ? 1 : 0,
                    y: testimonialsInView ? 0 : 20,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.1 + 0.2,
                  }}
                  whileHover={{ y: -5 }}
                >
                  <div className="p-6">
                    <div className="flex items-center mb-4">
                      <div className="w-12 h-12 bg-gold/20 rounded-full flex items-center justify-center mr-3 font-bold text-gold text-xl">
                        {testimonial.avatar}
                      </div>
                      <div>
                        <h3 className="font-bold text-gray-900">
                          {testimonial.name}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {testimonial.role}
                        </p>
                      </div>
                    </div>
                    <p className="text-gray-700 italic mb-4">
                      "{testimonial.quote}"
                    </p>
                    <p className="text-sm text-gray-500">
                      {testimonial.period}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* FAQ Section - Updated to use the new FAQItem component */}
          <motion.section
            ref={faqRef}
            className="mb-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: faqInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-gold mb-4 font-headers">
                Frequently Asked Questions
              </h2>
              <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                Have questions about working with us? Here are some common
                inquiries.
              </p>
            </div>

            <div className="max-w-3xl mx-auto">
              <div className="bg-white rounded-lg shadow-lg border-2 border-b-4 border-darkgray p-6">
                {faqData.map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </motion.section>

          {/* Final CTA */}
          <motion.section
            className="mb-20 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <div className="bg-gradient-to-r from-red to-gold rounded-lg py-12 px-6 shadow-xl">
              <h2 className="text-3xl font-bold text-white font-headers mb-4">
                Ready to Join Our Tribe?
              </h2>
              <p className="text-white text-lg mb-8 max-w-3xl mx-auto">
                Help us in our mission to make African languages accessible and
                enjoyable for everyone.
              </p>
              <motion.a
                href="https://bit.ly/ffarecruitment"
                target="_blank"
                rel="noreferrer"
                className="inline-block py-3 px-8 bg-white text-red font-bold rounded-md border-2 border-b-4 border-white shadow-lg hover:bg-white/90 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Apply Today
              </motion.a>
              <p className="text-white/80 text-sm mt-4">
                All successful applicants will undergo a three-month
                probationary period to assess competency and fit.
              </p>
            </div>
          </motion.section>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-b from-white to-gold w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default JoinTribePage;
