// components/articles/article-list.jsx
import React from "react";
import ArticlePreview from "./article-preview";

const ArticleList = ({ articles, emptyMessage = "No articles found" }) => {
  if (!articles || articles.length === 0) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-500">{emptyMessage}</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {articles.map((article) => (
        <ArticlePreview key={article.id} article={article} />
      ))}
    </div>
  );
};

export default ArticleList;
