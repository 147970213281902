import React, { useState } from "react";
import { db } from "../../firebase/firebase.utils";
import { doc, updateDoc } from "firebase/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateEmail,
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { useAuth } from "../../helpers/UserAuthContextProvider";

function AccountCard() {
  const [profileStatus, setProfileStatus] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { user } = useAuth();
  const initialValues = {
    username: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string(),
    email: Yup.string().email("Invalid email address"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required!"),
  });

  const promptForCredentials = () => {
    const email = prompt("Please enter your email:");
    const password = prompt("Please enter your password:");
    return EmailAuthProvider.credential(email, password);
  };

  const reauthenticateUser = async () => {
    try {
      // Prompt the user for their credentials
      const credential = promptForCredentials();

      // Reauthenticate the user with the provided credentials
      await reauthenticateWithCredential(user, credential);

      // User has been successfully reauthenticated

      // Perform the sensitive action (e.g., updating the user profile)
      // Implement your logic here
    } catch (error) {
      // An error occurred during reauthentication
      console.error("Error during reauthentication:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    resetForm();
    setIsSubmitted(true);

    if (user) {
      try {
        // Update user profile
        await updateProfile(user, {
          displayName: formik.values.username,
        });

        // Update email and password
        await updateEmail(user, formik.values.email);
        await updatePassword(user, formik.values.password);

        // Update user collection in Firestore
        const userRef = doc(db, "users", user?.uid);
        await updateDoc(userRef, {
          username: formik.values.username,
          email: formik.values.email,
        });

        setProfileStatus("Your profile has successfully been updated!");
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          reauthenticateUser();
        }
        console.error("Error updating profile and collection:", error);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-8">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="font-headers text-left mt-4 w-full font-bold text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Profile
        </h1>
        <p className="text-left w-full text-sm sm:text-sm md:text-base lg:text-lg">
          Update your user information to keep your credentials up to date and
          tailored to you.
        </p>
        <form action="" className="w-full mt-4" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="username"
              className="font-semibold text-base sm:text-base md:text-lg lg:text-lg"
            >
              Name
            </label>
            <div className="text-sm w-full pr-2 inline rounded box-border bg-white focus:outline-none font-bold p-2">
              {user && user?.displayName ? user?.displayName : null}
            </div>
            <input
              type="text"
              id="username"
              name="username"
              {...formik.getFieldProps("username")}
              className="text-sm w-full pt-2 pr-2 inline rounded box-border bg-white px-3 py-3 mt-2 font-bold leading-tight focus:outline-none focus:shadow-outline shadow appearance-none border-2 border-b-4"
              placeholder="Select a new username"
            />
            {formik.touched.username && formik.errors.username && (
              <div className="text-sm text-red mt-1">
                {formik.errors.username}
              </div>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label
              htmlFor="email"
              className="font-semibold text-base sm:text-base md:text-lg lg:text-lg"
            >
              Email
            </label>
            <div className="text-sm w-full pr-2 inline rounded box-border bg-white font-bold p-2">
              {user && user?.email ? user?.email : null}
            </div>
            <input
              type="email"
              id="email"
              name="email"
              {...formik.getFieldProps("email")}
              className="text-sm w-full pt-2 pr-2 inline rounded box-border bg-white px-3 py-3 mt-2 font-bold leading-tight focus:outline-none focus:shadow-outline shadow appearance-none border-2 border-b-4"
              placeholder="Set a new email address"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-sm text-red mt-1">{formik.errors.email}</div>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label
              htmlFor="password"
              className="font-semibold text-base sm:text-base md:text-lg lg:text-lg"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              {...formik.getFieldProps("password")}
              className="text-sm w-full pr-2 px-3 py-3 inline rounded box-border bg-white mt-2 font-bold leading-tight focus:outline-none focus:shadow-outline shadow appearance-none border-2 border-b-4"
              placeholder="Type in a new password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-sm text-red mt-1">
                {formik.errors.password}
              </div>
            )}
          </div>

          <div className="mt-6 flex flex-col items-center justify-center w-full">
            <button
              type="submit"
              onClick={formik.handleSubmit}
              value="update profile"
              className="mt-2 text-sm md:text-base w-full sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-1/2 pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 text-center cursor-pointer
                              m-auto cursor:pointer pb-2 pl-4 font-bold hover:text-white border-green bg-white hover:border-green hover:bg-green text-black
                              leading-tight
                              uppercase
                              shadow-md
                              hover:shadow-lg
                              focus:shadow-lg focus:outline-none focus:ring-0
                              active:shadow-lg
                              transition
                              duration-150
                              ease-in-out border-b-4"
            >
              Update profile
            </button>
            {isSubmitted && (
              <span className="mt-4 text-sm md:text-base text-green font-semibold">
                {profileStatus}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AccountCard;
