// Optimized useSafeAnalytics hook for performance
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useAnalytics } from "../helpers/EnhancedAnalyticsContextProvider";
import { useAuth } from "../helpers/UserAuthContextProvider";

export const useSafeAnalytics = () => {
  const { user } = useAuth();
  const analytics = useAnalytics();
  const [localLoading, setLocalLoading] = useState(true);

  // Precomputed derived fields to avoid recalculation on every render
  const derivedFields = useMemo(
    () => ({
      todayXp: analytics.userStats?.today_xp || 0,
      weeklyXp: analytics.userStats?.weekly_xp || 0,
      totalXp: analytics.userStats?.total_xp || 0,
      streak: analytics.userStats?.current_streak || 0,
      maxStreak: analytics.userStats?.max_streak || 0,
      vocabularyLearned: analytics.userStats?.vocabulary_learned_count || 0,
      lessonsCompleted: analytics.userStats?.lessons_completed_count || 0,
    }),
    [analytics.userStats]
  );

  // Enhanced refresh control with queuing
  const refreshControl = useRef({
    lastRefreshTime: 0,
    isRefreshing: false,
    pendingRefresh: false,
    minRefreshInterval: 2000, // 2 seconds minimum between refreshes
    refreshQueue: [],
    forceNextRefresh: false,
  });

  // Enhanced toggleTracking function for useSafeAnalytics hook
  // Add this inside your useSafeAnalytics.jsx

  // Replace the existing toggleTracking passthrough with this custom implementation
  const enhancedToggleTracking = useCallback(() => {
    console.log("useSafeAnalytics: Toggle tracking called");

    try {
      // First use the context's toggle function
      if (typeof analytics.toggleTracking === "function") {
        analytics.toggleTracking();
      }

      // Then ensure the service is directly updated too
      const newValue = !analytics.trackingEnabled;

      // Import AnalyticsService to directly update it
      import("../services/enhanced-analytics.service")
        .then((module) => {
          const AnalyticsService = module.AnalyticsService;
          AnalyticsService.updateTrackingCache(newValue);
          console.log(
            `useSafeAnalytics: Directly updated service cache to ${newValue}`
          );
        })
        .catch((err) => {
          console.error("Error importing AnalyticsService:", err);
        });

      // Also update localStorage directly as a fallback
      localStorage.setItem("analytics-tracking-enabled", String(newValue));
    } catch (error) {
      console.error("useSafeAnalytics: Error in toggleTracking:", error);
    }
  }, [analytics.toggleTracking, analytics.trackingEnabled]);

  // Refined loading state management
  useEffect(() => {
    if (!analytics.isLoading) {
      setLocalLoading(false);
    } else {
      // Set a maximum loading time to prevent UI from being stuck
      const timer = setTimeout(() => setLocalLoading(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [analytics.isLoading]);

  // Process the refresh queue with efficient throttling
  const processRefreshQueue = useCallback(async () => {
    const control = refreshControl.current;

    if (control.isRefreshing || control.refreshQueue.length === 0) return;

    const now = Date.now();
    const timeSinceLastRefresh = now - control.lastRefreshTime;

    // Throttle refreshes
    if (
      timeSinceLastRefresh < control.minRefreshInterval &&
      !control.forceNextRefresh
    ) {
      // Schedule a future refresh
      if (!control.refreshScheduled) {
        control.refreshScheduled = true;
        setTimeout(() => {
          control.refreshScheduled = false;
          processRefreshQueue();
        }, control.minRefreshInterval - timeSinceLastRefresh + 50);
      }
      return;
    }

    try {
      control.isRefreshing = true;

      // Take the next refresh from the queue
      const nextRefresh = control.refreshQueue.shift();
      const forceRefresh = nextRefresh.force || control.forceNextRefresh;
      control.forceNextRefresh = false;

      // Execute the refresh
      await analytics.refreshAnalytics(forceRefresh);

      // Update control state
      control.lastRefreshTime = Date.now();
    } catch (error) {
      console.error("Error processing refresh:", error);
    } finally {
      control.isRefreshing = false;

      // Process next item in queue if any
      if (control.refreshQueue.length > 0) {
        setTimeout(processRefreshQueue, 50);
      }
    }
  }, [analytics]);

  // Queue a refresh operation - more efficient than direct refreshes
  const queueRefresh = useCallback(
    (force = false) => {
      const control = refreshControl.current;

      // Add to queue
      control.refreshQueue.push({ force });

      // If force=true, make sure next refresh is forced
      if (force) {
        control.forceNextRefresh = true;
      }

      // Start processing if not already running
      if (!control.isRefreshing && !control.refreshScheduled) {
        processRefreshQueue();
      }
    },
    [processRefreshQueue]
  );

  // Optimized refresh function
  const refreshAnalytics = useCallback(
    (force = false) => {
      if (!user?.uid) return Promise.resolve();

      // Queue the refresh operation
      queueRefresh(force);

      // Return a promise that resolves immediately
      // This prevents components from waiting for refresh
      return Promise.resolve();
    },
    [user?.uid, queueRefresh]
  );

  // Event tracking with batching support
  const eventBatch = useRef([]);
  const isProcessingEvents = useRef(false);

  // Process batched events
  const processEventBatch = useCallback(async () => {
    if (isProcessingEvents.current || eventBatch.current.length === 0) return;

    isProcessingEvents.current = true;

    try {
      const events = [...eventBatch.current];
      eventBatch.current = [];

      // Process events in groups of 3 for efficiency
      const chunks = [];
      for (let i = 0; i < events.length; i += 3) {
        chunks.push(events.slice(i, i + 3));
      }

      for (const chunk of chunks) {
        // Process each chunk in parallel
        await Promise.all(
          chunk.map(async (event) => {
            try {
              // Add user ID to event data
              const enrichedData = { ...event.data, userId: user?.uid };

              // Track the event
              await analytics.trackEvent(event.type, enrichedData);

              // Call completion callback if provided
              if (event.onComplete) {
                event.onComplete(true);
              }
            } catch (error) {
              console.error(`Error tracking event ${event.type}:`, error);
              if (event.onComplete) {
                event.onComplete(false);
              }
            }
          })
        );
      }

      // After processing events, queue a refresh if needed
      // but only refresh once regardless of event count
      if (events.some((e) => e.refreshAfter)) {
        queueRefresh(false);
      }
    } finally {
      isProcessingEvents.current = false;

      // Check if more events were added during processing
      if (eventBatch.current.length > 0) {
        setTimeout(processEventBatch, 50);
      }
    }
  }, [analytics, user?.uid, queueRefresh]);

  // Add event to batch
  const queueEvent = useCallback(
    (type, data, refreshAfter = false, onComplete) => {
      eventBatch.current.push({ type, data, refreshAfter, onComplete });

      if (!isProcessingEvents.current) {
        setTimeout(processEventBatch, 0);
      }

      return Promise.resolve(true);
    },
    [processEventBatch]
  );

  // Track an event with optimized batching
  const trackEvent = useCallback(
    async (eventType, eventData = {}, refreshAfter = false) => {
      if (!user?.uid) return false;

      return new Promise((resolve) => {
        queueEvent(eventType, eventData, refreshAfter, (success) => {
          resolve(success);
        });
      });
    },
    [user?.uid, queueEvent]
  );

  // Memoized power-up utility functions
  const powerUpUtils = useRef({
    lastCheckTime: 0,
    cachedStatus: null,
  });

  // Calculate XP boost with caching
  const calculateBoostedXP = useCallback(
    async (baseXP) => {
      if (!user?.uid) return { xp: baseXP, hasBoost: false, multiplier: 1 };

      try {
        const PowerUpUtils = (
          await import("../services/power-ups/power-up-utils")
        ).default;
        return await PowerUpUtils.calculateBoostedXP(user.uid, baseXP);
      } catch (error) {
        console.error("Error calculating boosted XP:", error);
        return { xp: baseXP, hasBoost: false, multiplier: 1 };
      }
    },
    [user?.uid]
  );

  // Get power-up status with caching
  const getPowerUpStatus = useCallback(
    async (forceRefresh = false) => {
      if (!user?.uid) {
        return {
          xpBoost: { active: false },
          streakProtection: { active: false },
        };
      }

      const now = Date.now();
      const control = powerUpUtils.current;

      // Use cached value if recent and not forcing refresh
      if (
        !forceRefresh &&
        control.cachedStatus &&
        now - control.lastCheckTime < 30000 // 30 second cache
      ) {
        return control.cachedStatus;
      }

      try {
        const PowerUpUtils = (
          await import("../services/power-ups/power-up-utils")
        ).default;

        const xpBoost = await PowerUpUtils.checkXpBoost(user.uid);
        const streakProtection = await PowerUpUtils.checkStreakProtection(
          user.uid
        );

        const result = { xpBoost, streakProtection };

        // Update cache
        control.cachedStatus = result;
        control.lastCheckTime = now;

        return result;
      } catch (error) {
        console.error("Error getting power-up status:", error);
        return {
          xpBoost: { active: false },
          streakProtection: { active: false },
        };
      }
    },
    [user?.uid]
  );

  // Return memoized value to prevent unnecessary re-renders
  return useMemo(
    () => ({
      // Analytics data with fallbacks
      userStats: analytics.userStats || null,
      activityData: analytics.activityData || [],
      achievements: analytics.achievements || [],

      // Efficiently pre-calculated derived fields
      ...derivedFields,

      // Status
      isLoading: localLoading || !!analytics.isLoading,

      // Optimized functions
      trackEvent,
      refreshAnalytics,
      forceRefresh: () => refreshAnalytics(true),
      calculateBoostedXP,
      getPowerUpStatus,

      // Pass-through functions
      toggleTracking: enhancedToggleTracking,
      changeTimeRange: analytics.changeTimeRange || (() => {}),
      calculateLevel: analytics.calculateLevel || (() => 1),
      getLevelProgress: analytics.getLevelProgress || (() => 0),
    }),
    [
      analytics.userStats,
      analytics.activityData,
      analytics.achievements,
      analytics.isLoading,
      enhancedToggleTracking,
      analytics.changeTimeRange,
      analytics.calculateLevel,
      analytics.getLevelProgress,
      localLoading,
      derivedFields,
      trackEvent,
      refreshAnalytics,
      calculateBoostedXP,
      getPowerUpStatus,
    ]
  );
};

export default useSafeAnalytics;
