// components/articles/article-preview.jsx
import React from "react";
import { Link } from "react-router-dom";

const ArticlePreview = ({ article }) => {
  return (
    <Link
      to={`/articles/${article.slug}`}
      className="block p-5 mb-4 border-2 border-b-4 border-darkgray rounded hover:border-green transition-colors flex flex-row"
    >
      {article.image && (
        <div className="hidden md:block mr-4 w-32 h-32 flex-shrink-0">
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-full object-cover rounded"
          />
        </div>
      )}

      <div className="flex-grow">
        <div className="flex items-center text-sm text-gray-500 mb-1">
          <span className="mr-2">{article.author}</span>
          <span className="mr-2">•</span>
          <span>{new Date(article.publishDate).toLocaleDateString()}</span>
          <span className="mr-2 ml-2">•</span>
          <span>⏱️ {article.readTime}</span>
        </div>

        <h3 className="text-lg font-medium mb-2">{article.title}</h3>
        <p className="text-sm text-gray-600 mb-3">{article.preview}</p>

        <div className="flex justify-between items-center">
          <div className="flex space-x-2">
            {article.tags.slice(0, 2).map((tag) => (
              <span
                key={tag}
                className="px-2 py-1 bg-gray-100 text-xs rounded-full"
              >
                {tag}
              </span>
            ))}
          </div>
          <span className="text-red text-sm">Read more →</span>
        </div>
      </div>
    </Link>
  );
};

export default ArticlePreview;
