import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronRight, Home } from "lucide-react";

const BookingBreadcrumbs = ({ coachName }) => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);

  // Map path segments to readable names
  const getReadableName = (segment, index, segments) => {
    // Special case for coach ID in booking path
    if (segment === "book" && index < segments.length - 1 && coachName) {
      return `Booking with ${coachName}`;
    }

    const pathNames = {
      booking: "Bookings",
      dashboard: "Dashboard",
      coaches: "Find Coaches",
      availability: "My Availability",
      earnings: "Earnings Report",
      book: "Book Session",
    };

    return pathNames[segment] || segment;
  };

  // Build breadcrumb items
  const breadcrumbs = pathSegments.map((segment, index, segments) => {
    // Build the path up to this segment
    const path = `/${segments.slice(0, index + 1).join("/")}`;

    // Get readable name for this segment
    const name = getReadableName(segment, index, segments);

    // Return breadcrumb item
    return { path, name };
  });

  if (breadcrumbs.length === 0) {
    return null;
  }

  // Get the last two breadcrumbs for mobile view
  const mobileBreadcrumbs =
    breadcrumbs.length > 2 ? breadcrumbs.slice(-2) : breadcrumbs;

  return (
    <div className="flex items-center text-sm text-gray-500 mb-4 sm:mb-6 mx-3 sm:ml-5 overflow-x-auto mt-3 sm:mt-6">
      {/* Home icon for all views */}
      <Link
        to="/dashboard"
        className="hover:text-green transition-colors flex-shrink-0"
        aria-label="Home"
      >
        <Home size={16} className="hidden sm:block" />
        <span className="sm:hidden">Home</span>
      </Link>

      {/* For mobile: only show limited breadcrumbs */}
      <div className="sm:hidden flex items-center">
        {breadcrumbs.length > 2 && (
          <>
            <ChevronRight size={16} className="mx-1 flex-shrink-0" />
            <span className="text-gray-400">...</span>
          </>
        )}

        {mobileBreadcrumbs.map((crumb, index) => (
          <React.Fragment key={crumb.path}>
            <ChevronRight size={16} className="mx-1 flex-shrink-0" />
            {index === mobileBreadcrumbs.length - 1 ? (
              <span className="font-medium text-gray-700 truncate max-w-[150px]">
                {crumb.name}
              </span>
            ) : (
              <Link
                to={crumb.path}
                className="hover:text-green transition-colors truncate max-w-[100px]"
              >
                {crumb.name}
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* For desktop: show all breadcrumbs */}
      <div className="hidden sm:flex sm:items-center">
        {breadcrumbs.map((crumb, index) => (
          <React.Fragment key={crumb.path}>
            <ChevronRight size={16} className="mx-2 flex-shrink-0" />
            {index === breadcrumbs.length - 1 ? (
              <span className="font-medium text-gray-700">{crumb.name}</span>
            ) : (
              <Link
                to={crumb.path}
                className="hover:text-green transition-colors"
              >
                {crumb.name}
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BookingBreadcrumbs;
