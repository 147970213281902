// src/helpers/AnalyticsContextProvider.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useAuth } from "./UserAuthContextProvider";
import { AnalyticsService } from "../services/enhanced-analytics.service";
import { useLearningAnalytics } from "../hooks/useLearningAnalytics";

// Create context with default values to prevent "undefined" errors
export const AnalyticsContext = createContext({
  userStats: null,
  activityData: [],
  achievements: [],
  isLoading: false,
  trackingEnabled: false,
  timeRange: "week",
  trackEvent: () => Promise.resolve(),
  toggleTracking: () => {},
  changeTimeRange: () => {},
  refreshAnalytics: () => {},
  calculateLevel: () => 1,
  getLevelProgress: () => 0,
});

// Create hook for using analytics context with error handling
export const useAnalytics = () => {
  try {
    const context = useContext(AnalyticsContext);
    if (!context) {
      console.warn("useAnalytics must be used within an AnalyticsProvider");
      // Return default values instead of throwing
      return {
        userStats: null,
        activityData: [],
        achievements: [],
        isLoading: false,
        trackingEnabled: false,
        timeRange: "week",
        trackEvent: () => Promise.resolve(),
        toggleTracking: () => {},
        changeTimeRange: () => {},
        refreshAnalytics: () => {},
        calculateLevel: () => 1,
        getLevelProgress: () => 0,
      };
    }
    return context;
  } catch (error) {
    console.error("Error in useAnalytics:", error);
    // Return default values in case of error
    return {
      userStats: null,
      activityData: [],
      achievements: [],
      isLoading: false,
      trackingEnabled: false,
      timeRange: "week",
      trackEvent: () => Promise.resolve(),
      toggleTracking: () => {},
      changeTimeRange: () => {},
      refreshAnalytics: () => {},
      calculateLevel: () => 1,
      getLevelProgress: () => 0,
    };
  }
};

export const AnalyticsProvider = ({ children }) => {
  // Get user auth data with error handling
  const auth = useAuth();
  const user = auth?.user || null;

  // Use separate loading state for legacy data to avoid race conditions
  const { learningProgress, isLoading: legacyLoading } = useLearningAnalytics();

  // Use refs to minimize re-renders
  const userStatsRef = useRef(null);
  const activityDataRef = useRef([]);
  const achievementsRef = useRef([]);

  // Expose state for components that need to react to changes
  const [userStats, setUserStats] = useState(null);
  const [activityData, setActivityData] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Store tracking preference in ref to avoid frequent re-renders
  const trackingEnabledRef = useRef(false); // Default to false for safety

  // Initialize tracking preference properly
  useEffect(() => {
    try {
      const savedPreference = localStorage.getItem(
        "analytics-tracking-enabled"
      );
      const initialValue =
        savedPreference !== null ? savedPreference === "true" : true;

      // Update ref
      trackingEnabledRef.current = initialValue;

      // Update React state
      setTrackingEnabled(initialValue);

      // Sync with service cache
      AnalyticsService.updateTrackingCache(initialValue);
    } catch (error) {
      console.warn("Error reading tracking preference:", error);
      // Default to false in case of errors
      trackingEnabledRef.current = false;
      setTrackingEnabled(false);
      AnalyticsService.updateTrackingCache(false);
    }
  }, []);

  // Expose state for UI components
  const [trackingEnabled, setTrackingEnabled] = useState(false);
  const [timeRange, setTimeRange] = useState("week");

  // Flags with safer initialization
  const legacyMergeComplete = useRef(false);
  const initialLoadComplete = useRef(false);

  // Toggle analytics tracking with minimal re-renders
  const toggleTracking = useCallback(() => {
    try {
      const newValue = !trackingEnabledRef.current;
      trackingEnabledRef.current = newValue;

      // Update localStorage
      localStorage.setItem("analytics-tracking-enabled", newValue.toString());

      // Update React state
      setTrackingEnabled(newValue);

      // Sync with the service's cache
      AnalyticsService.updateTrackingCache(newValue);

      console.log(`Analytics tracking ${newValue ? "enabled" : "disabled"}`);
    } catch (error) {
      console.warn("Error toggling tracking:", error);
    }
  }, []);

  // Memoized fetch user stats function with error handling
  const fetchUserStats = useCallback(
    async (forceRefresh = false) => {
      if (!user?.uid || (initialLoadComplete.current && !forceRefresh))
        return null;

      try {
        const stats = await AnalyticsService.getUserAnalyticsSummary(user.uid);
        userStatsRef.current = stats;
        setUserStats(stats);

        // Only run achievements check during initial load or forced refresh
        if (!initialLoadComplete.current || forceRefresh) {
          checkForAchievements(stats);
        }

        return stats;
      } catch (error) {
        console.error("Error fetching user stats:", error);
        return null;
      } finally {
        initialLoadComplete.current = true;
      }
    },
    [user?.uid]
  );

  // Simplified calculateXpSummaries function with core functionality
  const calculateXpSummaries = useCallback(() => {
    console.log("Starting XP summary calculation");

    // Skip if no user stats
    if (!userStatsRef.current) {
      console.log("No userStatsRef.current, skipping calculation");
      return null;
    }

    try {
      // Initialize values
      let todayXp = 0;
      let weeklyXp = 0;

      // Get today's date in user's local timezone
      const today = new Date();
      const todayStr = today.toISOString().split("T")[0];

      // Create date 7 days ago for weekly calculation
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      console.log("XP calculation date info:", {
        todayStr,
      });

      // PRIORITY 1: Check daily_activity for today's XP (primary source)
      if (
        userStatsRef.current.daily_activity &&
        userStatsRef.current.daily_activity[todayStr]
      ) {
        // Check for xp_earned in today's daily activity
        const todayActivity = userStatsRef.current.daily_activity[todayStr];

        if (todayActivity && typeof todayActivity.xp_earned === "number") {
          todayXp = todayActivity.xp_earned;
          console.log(
            `Found today's XP in daily_activity: ${todayXp} from ${todayStr}`
          );
        }
      }

      // PRIORITY 2: Calculate weekly XP from daily_activity
      if (userStatsRef.current.daily_activity) {
        console.log("Calculating weekly XP from daily_activity");

        Object.entries(userStatsRef.current.daily_activity).forEach(
          ([dateStr, activityData]) => {
            if (!activityData) return;

            try {
              const entryDate = new Date(dateStr);
              if (isNaN(entryDate.getTime())) return;

              if (entryDate >= oneWeekAgo && entryDate <= today) {
                // Use xp_earned for this date's contribution to weekly total
                if (typeof activityData.xp_earned === "number") {
                  weeklyXp += activityData.xp_earned;
                  console.log(
                    `Added ${activityData.xp_earned} XP from ${dateStr} to weekly total`
                  );
                }
              }
            } catch (e) {
              console.error("Error processing daily activity date:", e);
            }
          }
        );

        console.log(`Weekly XP calculated from daily_activity: ${weeklyXp}`);
      }

      // Update the state with calculated values and return
      // Use function interface if available
      if (typeof setUserStats === "function") {
        const updatedStats = {
          ...userStatsRef.current,
          today_xp: todayXp,
          weekly_xp: weeklyXp,
        };

        userStatsRef.current = updatedStats;
        setUserStats(updatedStats);
      } else {
        // No state update function, just update ref
        userStatsRef.current.today_xp = todayXp;
        userStatsRef.current.weekly_xp = weeklyXp;
      }

      console.log("Final XP summary calculated:", { todayXp, weeklyXp });
      return { todayXp, weeklyXp };
    } catch (error) {
      console.error("Error calculating XP summaries:", error);
      return { todayXp: 0, weeklyXp: 0 };
    }
  }, []);

  // Fixed fetchActivityData function with no dependency on activityDataCache
  const fetchActivityData = useCallback(
    async (forceRefresh = false, options = {}) => {
      if (!user?.uid) return [];

      try {
        let days = 7;
        if (timeRange === "month") days = 30;
        if (timeRange === "year") days = 365;

        // If we have skipCache option, just clear the activityDataRef
        if (options && options.skipCache) {
          // Clear the current activity data reference
          activityDataRef.current = [];
        }

        // Use directly for compatibility with your existing code
        const activity = await AnalyticsService.getUserDailyActivity(
          user.uid,
          days
        );

        activityDataRef.current = activity;
        setActivityData(activity);

        // If calculateXpSummaries exists, call it
        if (typeof calculateXpSummaries === "function") {
          calculateXpSummaries();
        }

        return activity;
      } catch (error) {
        console.error("Error fetching activity data:", error);
        return [];
      }
    },
    [timeRange, user?.uid, calculateXpSummaries]
  );

  // Then modify your refreshAnalytics function to call this:
  const refreshAnalytics = useCallback(
    (forceRefresh = false) => {
      setIsLoading(true);

      // Pass the forceRefresh flag to both fetch functions
      return Promise.all([
        fetchUserStats(forceRefresh),
        fetchActivityData(forceRefresh),
      ])
        .then(([statsResult, activityResult]) => {
          // Success handling - calculate XP summaries
          console.log("Analytics refresh completed successfully");

          // Calculate XP summaries after data refresh
          setTimeout(() => {
            calculateXpSummaries();
          }, 100); // Small delay to ensure state updates have propagated

          return { statsResult, activityResult };
        })
        .catch((error) => {
          console.error("Error refreshing analytics:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [fetchUserStats, fetchActivityData, calculateXpSummaries]
  );

  // Check for achievements based on user stats - optimized to reduce calculations
  const checkForAchievements = useCallback((stats) => {
    if (!stats) return [];

    try {
      const newAchievements = [];

      // Streak achievements
      if (stats.current_streak >= 7) {
        newAchievements.push({
          id: "streak-7",
          icon: "🔥",
          title: "7-Day Streak",
          description: "You've been learning for 7 days in a row!",
          progress: Math.min(100, (stats.current_streak / 7) * 100),
          progressText: `${stats.current_streak}/7 days`,
          isComplete: stats.current_streak >= 7,
        });
      }

      if (stats.current_streak >= 30) {
        newAchievements.push({
          id: "streak-30",
          icon: "🔥🔥🔥",
          title: "30-Day Streak",
          description: "A full month of language learning!",
          progress: Math.min(100, (stats.current_streak / 30) * 100),
          progressText: `${stats.current_streak}/30 days`,
          isComplete: stats.current_streak >= 30,
        });
      }

      // XP achievements
      newAchievements.push({
        id: "xp-1000",
        icon: "🏆",
        title: "XP Milestone",
        description: "Earn 1000 XP points",
        progress: Math.min(100, (stats.total_xp || 0) / 1000) * 100,
        progressText: `${stats.total_xp || 0}/1000 XP`,
        isComplete: (stats.total_xp || 0) >= 1000,
      });

      // Vocabulary achievements
      newAchievements.push({
        id: "vocabulary-100",
        icon: "📚",
        title: "Word Master",
        description: "Learn 100 vocabulary words",
        progress:
          Math.min(100, (stats.vocabulary_learned_count || 0) / 100) * 100,
        progressText: `${stats.vocabulary_learned_count || 0}/100 words`,
        isComplete: (stats.vocabulary_learned_count || 0) >= 100,
      });

      // Lesson achievements
      newAchievements.push({
        id: "lessons-10",
        icon: "🎓",
        title: "Lesson Champion",
        description: "Complete 10 lessons",
        progress:
          Math.min(100, (stats.lessons_completed_count || 0) / 10) * 100,
        progressText: `${stats.lessons_completed_count || 0}/10 lessons`,
        isComplete: (stats.lessons_completed_count || 0) >= 10,
      });

      // Practice achievements
      newAchievements.push({
        id: "practice-100",
        icon: "💯",
        title: "Practice Pro",
        description: "Answer 100 practice questions",
        progress:
          Math.min(100, (stats.practice_questions_answered || 0) / 100) * 100,
        progressText: `${stats.practice_questions_answered || 0}/100 questions`,
        isComplete: (stats.practice_questions_answered || 0) >= 100,
      });

      // Accuracy achievements
      if (
        stats.correct_answers_count !== undefined ||
        stats.incorrect_answers_count !== undefined
      ) {
        newAchievements.push({
          id: "accuracy-50",
          icon: "🎯",
          title: "Accuracy Expert",
          description: "Get 50 correct answers",
          progress:
            Math.min(100, (stats.correct_answers_count || 0) / 50) * 100,
          progressText: `${
            stats.correct_answers_count || 0
          }/50 correct answers`,
          isComplete: (stats.correct_answers_count || 0) >= 50,
        });
      }

      achievementsRef.current = newAchievements;
      setAchievements(newAchievements);
      return newAchievements;
    } catch (error) {
      console.error("Error checking achievements:", error);
      return [];
    }
  }, []);

  // Track an analytics event - optimized with early returns and caching
  const trackEvent = useCallback(
    async (eventType, eventData = {}) => {
      if (!user?.uid || !trackingEnabledRef.current) return Promise.resolve();

      try {
        await AnalyticsService.trackEvent(user.uid, eventType, eventData);

        // Only refresh stats for significant events
        const significantEvents = [
          "lesson_completed",
          "practice_completed",
          "xp_earned",
          "vocabulary_learned",
        ];

        if (significantEvents.includes(eventType)) {
          // Use a small timeout to prevent immediate re-fetch that might block rendering
          setTimeout(() => {
            fetchUserStats(true);
            fetchActivityData(true);
          }, 100);
        }

        return true;
      } catch (error) {
        console.error("Error tracking event:", error);
        return false;
      }
    },
    [fetchUserStats, fetchActivityData, user?.uid]
  );

  // Change time range for activity data - memoized
  const changeTimeRange = useCallback((range) => {
    if (range && typeof range === "string") {
      setTimeRange(range);
    }
  }, []);

  // Calculate user level - memoized for performance with null checks
  const calculateLevel = useCallback(() => {
    if (!userStatsRef.current || !userStatsRef.current.total_xp) return 1;
    return Math.floor(userStatsRef.current.total_xp / 1000) + 1;
  }, []);

  // Get progress to next level - memoized for performance with null checks
  const getLevelProgress = useCallback(() => {
    if (!userStatsRef.current || !userStatsRef.current.total_xp) return 0;
    return (userStatsRef.current.total_xp % 1000) / 10; // As percentage
  }, []);

  // Fetch initial data when user changes - with dependencies optimized
  useEffect(() => {
    if (!user?.uid) {
      userStatsRef.current = null;
      activityDataRef.current = [];
      achievementsRef.current = [];
      setUserStats(null);
      setActivityData([]);
      setAchievements([]);
      initialLoadComplete.current = false;
      return;
    }

    // Only fetch if we haven't already loaded data
    if (!initialLoadComplete.current) {
      setIsLoading(true);

      Promise.all([fetchUserStats(), fetchActivityData()])
        .catch((error) => {
          console.error("Error loading initial data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // Reset merge flag when user changes
    legacyMergeComplete.current = false;
  }, [user?.uid, fetchUserStats, fetchActivityData]);

  // Re-fetch activity data when time range changes, but only if initial load is complete
  useEffect(() => {
    if (user?.uid && initialLoadComplete.current) {
      fetchActivityData().catch((error) => {
        console.error(
          "Error fetching activity data on time range change:",
          error
        );
      });
    }
  }, [timeRange, fetchActivityData, user?.uid]);

  // Merge legacy data - optimized to prevent unnecessary processing
  useEffect(() => {
    // Only proceed if we have both data sources and haven't merged yet
    if (
      learningProgress &&
      userStatsRef.current &&
      user?.uid &&
      !legacyMergeComplete.current
    ) {
      try {
        // Mark that we've done the merge to prevent future runs
        legacyMergeComplete.current = true;

        // Check if there's any meaningful difference before updating state
        let needsUpdate = false;
        const mergedStats = { ...userStatsRef.current };

        // Apply any missing legacy data
        if (
          !mergedStats.lessons_started_count &&
          learningProgress.lessons_started
        ) {
          mergedStats.lessons_started_count = learningProgress.lessons_started;
          needsUpdate = true;
        }

        if (
          !mergedStats.lessons_completed_count &&
          learningProgress.lessons_completed
        ) {
          mergedStats.lessons_completed_count =
            learningProgress.lessons_completed;
          needsUpdate = true;
        }

        if (!mergedStats.total_xp && learningProgress.total_xp) {
          mergedStats.total_xp = learningProgress.total_xp;
          needsUpdate = true;
        }

        // Only update state if there's actually a change to make
        if (needsUpdate) {
          userStatsRef.current = mergedStats;
          setUserStats(mergedStats);
        }
      } catch (error) {
        console.error("Error merging legacy data:", error);
      }
    }
  }, [learningProgress, user?.uid]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      userStats: userStats || null,
      activityData: activityData || [],
      achievements: achievements || [],
      isLoading: isLoading || legacyLoading || false,
      trackingEnabled: trackingEnabled || false,
      timeRange: timeRange || "week",
      trackEvent,
      toggleTracking,
      changeTimeRange,
      refreshAnalytics,
      calculateLevel,
      getLevelProgress,
      calculateXpSummaries, // Add this
    }),
    [
      userStats,
      activityData,
      achievements,
      isLoading,
      legacyLoading,
      trackingEnabled,
      timeRange,
      trackEvent,
      toggleTracking,
      changeTimeRange,
      refreshAnalytics,
      calculateLevel,
      getLevelProgress,
      calculateXpSummaries, // Add this
    ]
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
