import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import PropTypes from "prop-types";
import {
  getAudioDownloadUrl,
  createAudioController,
} from "../../../utils/audio-utils";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";

const ShuffleExercise = ({ data }) => {
  const [selectedWords, setSelectedWords] = useState([]);
  const [shuffledWords, setShuffledWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wordAudioUrls, setWordAudioUrls] = useState({});
  const audioControllerRef = useRef(null);

  const { setOptionChosen } = useContext(optionChosenContext);
  const { setFeedback } = useContext(feedbackContext);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  const exerciseData = useMemo(() => data || {}, [data]);

  // Initialize audio controller
  useEffect(() => {
    audioControllerRef.current = createAudioController();
    return () => {
      if (audioControllerRef.current) {
        audioControllerRef.current.destroy();
      }
    };
  }, []);

  // Fetch audio URLs for all words
  useEffect(() => {
    const fetchAudioUrls = async () => {
      if (!exerciseData?.content) return;

      const urls = {};

      // Fetch core words audio
      if (exerciseData.content.targetSentence?.wordBreakdown) {
        for (const word of exerciseData.content.targetSentence.wordBreakdown) {
          if (word.audio) {
            urls[word.text] = await getAudioDownloadUrl(word.audio);
          }
        }
      }

      // Fetch extra words audio
      if (exerciseData.content.extraWords) {
        for (const word of exerciseData.content.extraWords) {
          if (word.audio) {
            urls[word.text] = await getAudioDownloadUrl(word.audio);
          }
        }
      }

      setWordAudioUrls(urls);
    };

    fetchAudioUrls();
  }, [exerciseData]);

  // Prepare shuffled words
  useEffect(() => {
    if (exerciseData?.content) {
      const coreWords =
        exerciseData.content.targetSentence?.wordBreakdown?.map((w) => ({
          text: w.text,
          audio: w.audio,
          isCore: true,
        })) || [];

      const extraWords =
        exerciseData.content.extraWords?.map((w) => ({
          text: w.text,
          audio: w.audio,
          isCore: false,
        })) || [];

      const allWords = [...coreWords, ...extraWords];
      setShuffledWords(shuffleArray(allWords));
      setLoading(false);
    }
  }, [exerciseData]);

  // Update parent contexts when selected words change
  useEffect(() => {
    setOptionChosen(selectedWords.join(" "));
    setFeedback(null);
  }, [selectedWords, setOptionChosen, setFeedback]);

  // Handle word playback
  const handlePlay = (wordText) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audioUrl = wordAudioUrls[wordText];
    if (audioUrl && audioControllerRef.current) {
      audioControllerRef.current.setUrl(audioUrl);
      audioControllerRef.current.play();
    }
  };

  const handlePause = () => {
    if (audioControllerRef.current) {
      audioControllerRef.current.pause();
    }
  };

  // Handle word selection
  const handleWordSelect = (word) => {
    if (selectedWords.includes(word)) {
      setSelectedWords(selectedWords.filter((w) => w !== word));
    } else {
      setSelectedWords([...selectedWords, word]);
    }
  };

  if (!exerciseData?.content) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  if (loading) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-full min-h-[300px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <BeatLoader color={"#848717"} loading={loading} size={15} />
      </motion.div>
    );
  }

  return (
    <div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <h1 className="text-lg sm:text-xl font-bold text-left font-headers text-black mb-3 sm:mb-4">
          {exerciseData.content.question}:
        </h1>

        <div className="mb-3 sm:mb-4 border-2 border-b-4 max-w-[600px] mx-auto border-black font-body p-3 sm:p-5">
          <p
            className={`italic ${
              exerciseData.isSmallFont ? "text-sm" : "text-base sm:text-lg"
            }`}
          >
            "{exerciseData.content.sourceSentence}"
          </p>
        </div>

        {/* Audio disabled message */}
        {!audioEnabled && (
          <div className="text-center text-xs text-gray-500 mb-3 sm:mb-4">
            Audio is disabled globally. Enable it in Settings.
          </div>
        )}

        {/* Answer Block - Responsive height */}
        <div className="relative w-full mb-4 sm:mb-6 min-h-[80px] sm:min-h-[120px] p-3 sm:p-5 bg-white rounded border-2 border-b-4 border-black">
          <div className="flex flex-wrap gap-2">
            <AnimatePresence>
              {selectedWords.map((word, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  className={`transition-all cursor-pointer p-2 sm:p-4 rounded-lg border-2 border-b-4 font-body text-xs sm:text-sm flex items-center justify-center bg-white border-green text-green shadow-lg hover:shadow-xl ${
                    !audioEnabled ? "opacity-75" : ""
                  }`}
                  onClick={() => handleWordSelect(word)}
                  onMouseEnter={() => audioEnabled && handlePlay(word)}
                  onMouseLeave={handlePause}
                >
                  {word}
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>

        {/* Shuffled Word Options - changed from 2-column fixed to responsive grid */}
        <div className="w-full grid grid-cols-2 sm:grid-cols-2 gap-2 p-3 sm:p-4 bg-gray-50 min-h-[200px] sm:min-h-[336px]">
          {shuffledWords
            .filter((wordObj) => !selectedWords.includes(wordObj.text))
            .map((wordObj, index) => (
              <motion.button
                key={index}
                onClick={() => handleWordSelect(wordObj.text)}
                onMouseEnter={() => audioEnabled && handlePlay(wordObj.text)}
                onMouseLeave={handlePause}
                className={`transition-all p-3 sm:p-5 rounded-lg border-2 border-b-4 font-body text-xs sm:text-sm flex items-center justify-center h-[55px] sm:h-[67px]
                  ${
                    !wordObj.isCore
                      ? "border-gray text-gray underline decoration-dotted"
                      : "underline decoration-dotted border-gray text-gray"
                  }
                  ${!audioEnabled ? "opacity-75" : ""}`}
                title={!audioEnabled ? "Audio is disabled globally" : ""}
              >
                {wordObj.text}
              </motion.button>
            ))}
        </div>
      </div>
    </div>
  );
};

ShuffleExercise.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      correctAnswer: PropTypes.string,
      sourceSentence: PropTypes.string,
      question: PropTypes.string,
      targetSentence: PropTypes.shape({
        text: PropTypes.string,
        audio: PropTypes.any,
        wordBreakdown: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            audio: PropTypes.any,
            isCore: PropTypes.bool,
          })
        ),
      }),
      extraWords: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          audio: PropTypes.any,
          isExtra: PropTypes.bool,
        })
      ),
    }),
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string,
    }),
    isSmallFont: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
};

// Shuffle array utility
function shuffleArray(array) {
  return [...array].sort(() => Math.random() - 0.5);
}

export default ShuffleExercise;
