// OnboardingVideo.jsx
import React, { useState } from "react";
import { motion } from "framer-motion";

const OnboardingVideo = ({ onComplete }) => {
  const [videoWatched, setVideoWatched] = useState(false);

  const handleVideoEnd = () => {
    setVideoWatched(true);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full mb-6 relative">
        {/* Mock video player - would be replaced with real iframe */}
        <div className="relative w-full aspect-video bg-black rounded-md overflow-hidden">
          <img
            src="/api/placeholder/800/450"
            alt="Placeholder video thumbnail"
            className="w-full h-full object-cover opacity-60"
          />

          {/* Play button overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <button
              onClick={handleVideoEnd}
              className="w-20 h-20 rounded-full bg-red/80 hover:bg-red text-white flex items-center justify-center shadow-lg transform transition-transform duration-300 hover:scale-110"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ml-1"
              >
                <polygon points="5 3 19 12 5 21 5 3"></polygon>
              </svg>
            </button>
          </div>
        </div>

        {/* Video info */}
        <div className="mt-4">
          <h3 className="font-headers font-bold text-xl">
            Welcome to Fast Fluency Africa Early Access
          </h3>
          <p className="text-gray text-sm mt-1">
            4:32 • Introduction to our early access program
          </p>
        </div>
      </div>

      <div className="w-full p-4 bg-green/10 border-l-4 border-green rounded-md mb-6">
        <p className="text-sm">
          <span className="font-bold">Note:</span> Please watch the entire video
          before continuing. This video explains important details about the
          early access program, including what to expect and how to provide
          feedback.
        </p>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{
          opacity: videoWatched ? 1 : 0.5,
          y: 0,
        }}
        transition={{ duration: 0.5 }}
        className="w-full text-center mt-4"
      >
        <button
          onClick={onComplete}
          disabled={!videoWatched}
          className={`px-8 py-3 border-2 rounded text-center mt-4 w-full md:w-auto
            ${videoWatched ? "cursor-pointer" : "opacity-50 cursor-not-allowed"}
            text-green font-bold border-green hover:border-white hover:bg-green hover:text-white
            text-sm md:text-base
            uppercase
            shadow-md
            hover:shadow-lg
            transition
            duration-150
            transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4`}
        >
          I've Watched the Video
        </button>
      </motion.div>
    </div>
  );
};

export default OnboardingVideo;
