// Improved mobile-responsive dictionary.page.jsx
import React, { useState, useEffect, useCallback } from "react";
import Results from "../../components/dictionary/results.component.jsx";
import { motion } from "framer-motion";
import { onAuthStateChanged, getAuth, signInAnonymously } from "firebase/auth";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";
import { doc, onSnapshot, runTransaction, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils.js";
import RegisterOverlay from "../../components/login-register/register-overlay.component.jsx";
import DictionaryFeedbackModal from "../../components/modals/dictionary-feedback-modal.component.jsx";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";
import { AnalyticsService } from "../../services/enhanced-analytics.service.js";
import FloatingActionMenu from "../../components/navigation-bar-2/floating-side-menu.jsx";
import useDictionarySearch from "../../hooks/useDictionarySearch";
import PowerUpManager from "../../services/power-ups/power-up-manager";
import DictionarySearchStatus from "../../components/dictionary/dictionary-search-status.jsx";
import PatternBackground from "../../components/common/pattern-background.jsx"; // Import the separate component
import { PuffLoader } from "react-spinners";
import africanPattern from "../../assets/imgs/patterns-2.png";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";

function Dictionary() {
  // State declarations
  const { user } = useAuth();
  const [inputValue, setInputValue] = useState("");
  const [membership, setMembership] = useState("guest");
  const [searchLimitExceeded, setSearchLimitExceeded] = useState(false);
  const [remainingSearches, setRemainingSearches] = useState(null);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [searchStatus, setSearchStatus] = useState(null);
  const auth = getAuth();
  const [loading, setLoading] = useState(false);

  // Custom search hook
  const {
    searchTerm,
    results,
    isLoading,
    error,
    searchHistory,
    search,
    clearResults,
    hasResults,
  } = useDictionarySearch("", () => {});

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    document.title = "Dictionary | Fast Fluency Africa";
  }, []);

  // Effect to load dictionary search status and check for expired boosts
  useEffect(() => {
    let isMounted = true;

    const loadDictionaryStatus = async () => {
      if (!user?.uid) return;

      try {
        // Check for expired dictionary boosts first
        await PowerUpManager.checkExpiredBoosts?.(user.uid);

        // Get the current dictionary search status
        const status = await PowerUpManager.getDictionarySearchStatus?.(
          user.uid
        );

        if (isMounted && status) {
          setSearchStatus(status);
          setRemainingSearches(status.totalSearchesRemaining);
          setSearchLimitExceeded(status.totalSearchesRemaining <= 0);
        }
      } catch (error) {
        console.error("Failed to load dictionary status:", error);
      }
    };

    loadDictionaryStatus();

    // Set up an interval to check for expired boosts every minute
    const intervalId = setInterval(loadDictionaryStatus, 60000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [user?.uid]);

  // Track dictionary page view
  useEffect(() => {
    if (user?.uid) {
      AnalyticsService.trackEvent(user.uid, "dictionary_visited", {
        membership: membership,
      });
    }
  }, [user?.uid, membership]);

  // Function to check search limit
  const checkSearchLimit = useCallback(async () => {
    if (!user) return false;

    try {
      // Get current search status
      const status = await PowerUpManager.getDictionarySearchStatus?.(user.uid);
      setSearchStatus(status);

      if (!status) return false;

      // If user has unlimited searches or has remaining searches
      if (status.hasUnlimited || status.totalSearchesRemaining > 0) {
        // Increment the search count
        const searchCountRef = doc(db, "searchCounts", user.uid);

        await runTransaction(db, async (transaction) => {
          const docSnap = await transaction.get(searchCountRef);
          const now = new Date();
          const today = now.toISOString().split("T")[0];

          let newCount = 1; // Start with 1 for first search
          let additionalSearches = 0;
          let additionalSearchesExpiry = null;
          let baseSearches = status.baseSearches || 0;

          // If we already have a record for today
          if (docSnap.exists()) {
            const data = docSnap.data();

            // Only use today's data
            if (data.date === today) {
              newCount = (data.count || 0) + 1;
              additionalSearches = data.additionalSearches || 0;
              additionalSearchesExpiry = data.additionalSearchesExpiry || null;
            }
          }

          // Update the search count document
          transaction.set(
            searchCountRef,
            {
              count: newCount,
              date: today,
              lastUpdated: new Date(),
              userId: user.uid,
              baseSearches: baseSearches,
              // Preserve additional searches and expiry
              additionalSearches: additionalSearches,
              additionalSearchesExpiry: additionalSearchesExpiry,
            },
            { merge: true }
          );

          // Update UI state
          const newTotal = status.hasUnlimited
            ? 999 // Unlimited
            : status.totalSearchesRemaining - 1; // Decrement remaining

          setRemainingSearches(newTotal);
          setSearchLimitExceeded(newTotal <= 0);
        });

        return true;
      } else {
        // No searches remaining
        setSearchLimitExceeded(true);
        return false;
      }
    } catch (error) {
      console.error("Search limit check failed:", error);
      setSearchLimitExceeded(true);
      return false;
    }
  }, [user]);

  // Authentication effect
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        try {
          await signInAnonymously(auth);
        } catch (error) {
          console.error("Anonymous sign-in failed:", error);
          setMembership("guest");
        }
      } else {
        const userDoc = doc(db, "users", user?.uid);
        const unsubscribeFirestore = onSnapshot(userDoc, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setMembership(docSnapshot.data().membership || "guest");
          }
        });
        return unsubscribeFirestore;
      }
    });
    return () => unsubscribeAuth();
  }, [user]);

  // Handle search results - track analytics
  const handleSearchResults = useCallback(
    async (hasResults) => {
      if (user?.uid && searchTerm) {
        // Standard event tracking
        AnalyticsService.trackEvent(
          user.uid,
          hasResults ? "search_with_results" : "search_no_results",
          {
            word: searchTerm,
          }
        );

        // Enhanced tracking with results data if available
        if (hasResults && results && results.length > 0) {
          // Get the first result's translation data
          const firstResult = results[0];

          try {
            // Update the dictionary search with translation results
            await AnalyticsService.trackDictionarySearch(user.uid, searchTerm, {
              translation: firstResult.translation || null,
              definition: firstResult.definition || null,
              partOfSpeech: firstResult.partofspeech || null,
            });
          } catch (error) {
            console.error(
              "Error updating search with translation data:",
              error
            );
          }
        }
      }
    },
    [user, searchTerm, results]
  );

  // Handle search submission
  const handleSearch = async (e) => {
    e.preventDefault();
    const trimmedValue = inputValue.trim().toLowerCase();

    if (!trimmedValue) {
      clearResults();
      return;
    }

    setResultsLoading(true);

    try {
      const allowed = await checkSearchLimit();
      if (!allowed) {
        setResultsLoading(false);
        return;
      }

      // Use the search function from the hook
      search(trimmedValue);

      // Track word search in analytics
      if (user?.uid) {
        // Basic tracking remains the same
        AnalyticsService.trackEvent(user.uid, "word_searched", {
          word: trimmedValue,
          membership: membership,
          remainingSearches: remainingSearches - 1,
        });

        // Enhanced tracking for dictionary
        await AnalyticsService.trackDictionarySearch(user.uid, trimmedValue);
      }
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      setResultsLoading(false);
    }
  };

  // Render the component
  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <PatternBackground
      patternUrl={africanPattern}
      backgroundColor="#FCFCEE"
      patternSize={400}
      opacity={0.15}
    >
      <motion.div
        className="min-h-screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <div className="w-full">
          <NavigationBar />
          <FloatingActionMenu
            showFlashcards={false}
            showStats={true}
            additionalButtons={[]}
          />
          <FeedbackMenu />
        </div>

        {membership === "guest" ? (
          <RegisterOverlay />
        ) : (
          <div className="flex flex-col m-auto items-center justify-between min-h-[40vh] w-full pb-6 sm:pb-8">
            <div className="flex flex-col justify-center items-center relative w-screen">
              <div className="flex flex-col justify-center items-center w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 mt-4">
                <div className="justify-center items-center flex flex-col w-full">
                  <div className="text-outline-black text-4xl sm:text-5xl md:text-6xl">
                    📖🔍
                  </div>
                  <h1 className="text-black text-xl sm:text-2xl md:text-3xl font-semibold font-headers uppercase">
                    Dictionary
                  </h1>
                  <h1 className="text-sm sm:text-base md:text-lg font-semibold font-body text-center">
                    Discover new words with our African dictionary!
                  </h1>

                  {/* Dictionary Search Status Component */}
                  {searchStatus && (
                    <DictionarySearchStatus
                      searchStatus={searchStatus}
                      hasSearchLimitExceeded={searchLimitExceeded}
                      membership={membership}
                    />
                  )}

                  <div className="my-3"></div>
                  <form
                    onSubmit={handleSearch}
                    className="flex flex-col w-full"
                  >
                    <div className="flex flex-row justify-center items-center border-black border-2 border-b-4 bg-white shadow-lg rounded px-3 sm:px-4 w-full h-12 min-h-[48px]">
                      <span className="w-6 h-7 text-black">🔍</span>
                      <input
                        className="flex-1 ml-2 focus:outline-none bg-white text-base"
                        placeholder="Type a word..."
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className={`
                        ${
                          isLoading || resultsLoading
                            ? "text-green px-3 border-2 font-bold border-green border-b-4"
                            : "text-black bg-green px-3 border-2 font-bold border-black border-b-4"
                        } py-3 rounded mt-3 min-h-[48px] text-base
                      `}
                      disabled={isLoading || resultsLoading}
                    >
                      {isLoading || resultsLoading
                        ? "Consulting the Ancestors..."
                        : "SEARCH"}
                    </button>

                    {/* Display search limit exceeded message if not shown in DictionarySearchStatus */}
                    {searchLimitExceeded && !searchStatus && (
                      <div
                        className="text-center mt-2 font-bold text-sm"
                        style={{ color: "#991616" }}
                      >
                        {membership === "george" ? (
                          <span>
                            Daily limit reached! Upgrade your membership
                            subscription to search more!
                          </span>
                        ) : (
                          <span className="mx-auto ">
                            Guest limit reached! <RegisterOverlay /> to continue
                          </span>
                        )}
                      </div>
                    )}
                  </form>

                  {/* Recent searches */}
                  {searchHistory.length > 0 && (
                    <div className="mt-3 sm:mt-4 w-full">
                      <h3 className="text-xs sm:text-sm font-bold">
                        Recent searches:
                      </h3>
                      <div className="flex flex-wrap gap-1 sm:gap-2 mt-1">
                        {searchHistory.map((term, index) => (
                          <button
                            key={index}
                            className="px-2 sm:px-3 py-1 bg-gray-100 border border-gray-300 rounded-full text-xs sm:text-sm mb-1 min-h-[32px]"
                            onClick={() => {
                              setInputValue(term);
                              // Trigger search using the hook
                              search(term);
                              // Track reuse of search term
                              if (user?.uid) {
                                AnalyticsService.trackEvent(
                                  user.uid,
                                  "reused_search_term",
                                  {
                                    word: term,
                                  }
                                );
                              }
                            }}
                          >
                            {term}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!searchTerm && (
              <div className="min-h-full w-full flex justify-center pt-4">
                <div className="w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 text-center">
                  <h3 className="text-justify text-xs sm:text-sm font-normal font-body mt-3 sm:mt-4">
                    Our dictionary is the perfect resource for anyone who wants
                    to learn new words in African languages. We currently offer
                    definitions and translations for isiZulu, but we are
                    constantly expanding our database with new vocabulary.
                  </h3>
                  <h3 className="text-center text-xs sm:text-sm font-normal font-body mt-3 sm:mt-4">
                    If you would like to help us by suggesting a new word in any
                    African language,{" "}
                    <span>
                      <DictionaryFeedbackModal />
                    </span>
                  </h3>
                </div>
              </div>
            )}
            {searchTerm && (
              <div className="min-h-full w-full flex justify-center">
                <div className="w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12">
                  {/* Pass the results from the hook directly to the Results component */}
                  <Results
                    searchTerm={searchTerm}
                    isLoading={isLoading || resultsLoading}
                    membership={membership}
                    onResultsLoaded={handleSearchResults}
                    // New prop - pass results directly
                    searchResults={results}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </motion.div>
    </PatternBackground>
  );
}

export default Dictionary;
