import { useEffect, useState } from "react";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";

// useUserData.js
export const useUserData = (user) => {
  const [enrollment, setEnrollment] = useState({});

  useEffect(() => {
    let isMounted = true;
    const fetchUserData = async () => {
      if (user?.uid) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && isMounted) {
          setEnrollment(docSnap.data());
        }
      }
    };
    fetchUserData();
    return () => {
      isMounted = false;
    };
  }, [user?.uid]);

  return { enrollment };
};

// useDialogues.js
// export const useDialogues = () => {
//   const [dialogues, setDialogues] = useState([]);

//   useEffect(() => {
//     let isMounted = true;
//     const fetchDialogues = async () => {
//       try {
//         const querySnapshot = await getDocs(collection(db, "dialogues"));
//         if (isMounted) {
//           setDialogues(
//             querySnapshot.docs.map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//               speechInfo: doc.data().speechInfo || [],
//             }))
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching dialogues:", error);
//       }
//     };
//     fetchDialogues();
//     return () => {
//       isMounted = false;
//     };
//   }, []);

//   return dialogues;
// };

export const useDialogues = () => {
  const [dialogues, setDialogues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDialogues = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "textSocials"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDialogues(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDialogues();
  }, []);

  return { dialogues, loading, error };
};

export const useAudioManager = () => {
  const [audioQueue, setAudioQueue] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);

  const playNext = () => {
    if (audioQueue.length > 0) {
      const next = audioQueue[0];
      const audio = new Audio(next.url);
      audio.onended = () => {
        setAudioQueue((prev) => prev.slice(1));
        next.onComplete?.();
      };
      setCurrentAudio(audio);
      audio.play();
    }
  };

  const queueAudio = (audioData) => {
    setAudioQueue((prev) => [...prev, ...audioData]);
  };

  return { queueAudio, playNext, stopAll: () => currentAudio?.pause() };
};
