import { default as texts } from "../assets/imgs/home/texts.jpg";
import { default as exercises } from "../assets/imgs/home/exercises.jpg";
import { default as sessions } from "../assets/imgs/home/sessions.jpg";
import { default as lessons } from "../assets/imgs/home/units.jpg";
import { default as milestones } from "../assets/imgs/home/milestones.jpg";
import { default as flashcards } from "../assets/imgs/home/flashcards.jpg";
import { default as dictionary } from "../assets/imgs/home/dictionary.jpg";
import { default as powerups } from "../assets/imgs/home/power-ups.jpg";
import { default as gamification } from "../assets/imgs/home/gamification.jpg";
import { default as analytics } from "../assets/imgs/home/analytics.jpg";
import { default as notifications } from "../assets/imgs/home/notifications.jpg";

export const Features = [
  {
    image: milestones,
    icon: "",
    heading: "Milestones",
    description: "Follow structured content curated for your needs.",
  },
  {
    image: lessons,
    icon: "",
    heading: "Lessons",
    description: "Bite sized lessons covering key language concepts.",
  },
  {
    image: texts,
    icon: "",
    heading: "Texts",
    description: "Leaf through our texts with English translations.",
  },
  {
    image: exercises,
    icon: "",
    heading: "Exercises",
    description: "Fast track fluency gains with interactive activities.",
  },
  {
    image: flashcards,
    icon: "",
    heading: "Flashcards",
    description: "Expand your vocabulary with our comprehensive flashcards.",
  },
  {
    image: dictionary,
    icon: "",
    heading: "Dictionary",
    description: "Consolidate your knowledge with 1000+ dictionary entries",
  },
  {
    image: sessions,
    icon: "",
    heading: "Sessions",
    description: "Book sessions with qualified Mother Tongue speakers.",
  },
  {
    image: powerups,
    icon: "",
    heading: "Shop",
    description: "Supercharge your learning with in-app Power-Ups.",
  },
  {
    image: gamification,
    icon: "",
    heading: "Gamification",
    description: "Stay engaged with a gamified learning experience",
  },
  {
    image: analytics,
    icon: "",
    heading: "Statistics",
    description: "Track your learning curve with in-depth analytics",
  },
  {
    image: notifications,
    icon: "",
    heading: "Notifications",
    description: "Never miss a lesson with reminders and updates",
  },
];
