// components/notifications/safe-notification-permission.jsx
import React from "react";
import NotificationPermission from "./notification-permission";

const SafeNotificationPermission = (props) => {
  // Check for iOS Safari - this has the most Notification API issues
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // For this specific user's case, we'll show an alternative UI
  if (
    isIOS &&
    Notification.permission === "denied" &&
    typeof Notification.requestPermission !== "function"
  ) {
    return (
      <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg border-b-4 border-2 border-darkgray max-w-sm z-50">
        <div className="flex items-start mb-2">
          <div className="mr-2 text-xl">🔔</div>
          <div>
            <h3 className="font-semibold text-gray-800">Notifications</h3>
            <p className="text-sm text-gray-600 mt-1">
              Notifications aren't fully supported in your browser. You'll still
              receive updates in the app when you visit.
            </p>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-3">
          <button
            onClick={() => {
              // Just hide this component
              // Store in localStorage if it's available
              try {
                localStorage.setItem("notification_prompt_dismissed", "true");
              } catch (e) {
                // Ignore localStorage errors
              }
              // Force rerender by using a state update through props
              if (props.onDismiss) props.onDismiss();
            }}
            className="px-3 py-1 bg-green text-white text-sm rounded hover:bg-green/90"
          >
            OK, Got it
          </button>
        </div>
      </div>
    );
  }

  // For all other users, show the normal component
  return <NotificationPermission {...props} />;
};

export default SafeNotificationPermission;
