import { default as blog } from "../assets/imgs/1.jpg";
import { default as cheatsheet } from "../assets/imgs/2.jpg";
import { default as phrasebookcomingsoon } from "../assets/imgs/3.jpg";

export const Articles = [
  {
    image: blog,
    heading: "Fluency Road Map: isiZulu",
    description:
      "Our step by step guide on how to get started with your language learning journey today. Many have tried, but many more have failed, and we think it might be because they didn't bother reading this first.",
    calltoaction: "READ MORE",
    path: "/isizulu-roadmap-2022",
    target: "",
  },
  {
    image: cheatsheet,
    heading: "Click Sounds Tongue Twisters",
    description:
      "Master the click sounds with our Tongue Twisters like nothing you will find anywhere. We can almost guarantee that after you give these tongue twisters a try the Q's, X's, and C's will stop stressing you.",
    calltoaction: "DOWNLOAD",
    path: "https://mailchi.mp/c99901b31394/click-sounds",
    target: "_blank",
  },
  {
    image: phrasebookcomingsoon,
    heading: "isiZulu Socials Phrasebook",
    description:
      "Dive into the most comprehensive isiZulu Phrasebook available anywhere online, absolutely for free. Make sure you're never in a tight spot when you're out in these streets chopping it up with friends and fam.",
    calltoaction: "DOWNLOAD",
    path: "https://mailchi.mp/da3d51619f4c/iszulu-phrasebook-socials",
    target: "_blank",
  },
];
