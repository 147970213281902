import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { motion } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import PropTypes from "prop-types";
import AudioWord from "../common/audio-word.component.jsx";
import useFetchAudioUrls from "../../../hooks/useFetchAudioUrl.jsx";
import {
  getAudioDownloadUrl,
  createAudioController,
} from "../../../utils/audio-utils";

function AudioMCQMock({ data }) {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const audioControllerRef = useRef(null);
  const { audioUrls, error } = useFetchAudioUrls(data?.content?.options);
  const [promptAudioUrl, setPromptAudioUrl] = useState("");

  const exerciseData = useMemo(() => {
    if (!data) return null;
    return {
      ...data,
    };
  }, [data]);

  // Initialize audio controller
  useEffect(() => {
    audioControllerRef.current = createAudioController();
    return () => {
      if (audioControllerRef.current) {
        audioControllerRef.current.destroy();
      }
    };
  }, []);

  // Load the prompt audio URL
  useEffect(() => {
    const loadPromptAudio = async () => {
      if (exerciseData?.audioPrompt) {
        const url = await getAudioDownloadUrl(exerciseData.audioPrompt);
        setPromptAudioUrl(url);
      }
    };

    loadPromptAudio();
  }, [exerciseData]);

  // Set up shuffled options
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (
        exerciseData &&
        Array.isArray(exerciseData.content?.options) &&
        exerciseData.content?.options.length > 0
      ) {
        const markedOptions = exerciseData.content.options.map((opt) => ({
          ...opt,
          // if the text matches correctAnswer, it's the correct one
          isCorrect: opt.text === exerciseData.validation?.correctAnswer,
        }));
        setShuffledOptions(shuffleArray(markedOptions));
      } else {
        setShuffledOptions([]);
      }

      // Reset chosen option & feedback
      setOptionChosen(null);
      setFeedback(null);
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [exerciseData, setOptionChosen, setFeedback]);

  // Handle playing the audio at normal speed
  const handlePlayAudio = () => {
    if (audioControllerRef.current && promptAudioUrl) {
      audioControllerRef.current.setUrl(promptAudioUrl);
      audioControllerRef.current.play(1.0);
    }
  };

  // Handle playing the audio at slower speed
  const handlePlaySlow = () => {
    if (audioControllerRef.current && promptAudioUrl) {
      audioControllerRef.current.setUrl(promptAudioUrl);
      audioControllerRef.current.play(0.75);
    }
  };

  // Handle user selecting an option
  const handleOptionClick = (index) => {
    // Prevent changes once feedback is shown
    if (!feedback) {
      setOptionChosen(shuffledOptions[index].text);
    }
  };

  // Shuffle array
  const shuffleArray = (array) => {
    // Shallow copy to avoid mutating original
    return [...array].sort(() => Math.random() - 0.5);
  };

  // Defensive checks for valid data
  if (!exerciseData || !exerciseData.content?.options) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  // If we literally have no options
  if (exerciseData.content.options.length === 0) {
    return <div>No audio MCQ exercises available.</div>;
  }

  if (loading) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-full min-h-[300px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <BeatLoader color={"#848717"} loading={loading} size={15} />
      </motion.div>
    );
  }

  // Main render
  return (
    <motion.div
      className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12">
        <div
          className="mb-3 sm:mb-4 font-semibold"
          data-testid="exercise-title"
        >
          {exerciseData.title || "Listen and Select"}
        </div>

        <div className="relative border-2 border-b-4 max-w-[600px] mx-auto border-black mb-3 sm:mb-4">
          {/* Question prompt */}
          <h3
            data-testid="exercise-question"
            className="text-base sm:text-lg italic text-center font-body px-3 py-2 sm:px-4 sm:py-2"
          >
            {exerciseData.content.question}
          </h3>

          {/* Audio control buttons - increased tap target sizes for mobile */}
          <div className="flex justify-center items-center">
            <button
              onClick={handlePlayAudio}
              className="px-3 py-3 sm:px-4 sm:py-2 bg-blue-500 text-outline-black text-black text-3xl sm:text-4xl rounded-lg hover:bg-blue-600 transition-all duration-150 m-2 transform active:scale-95 active:translate-y0 ease-in-out"
              aria-label="Play audio at normal speed"
            >
              ▶️
            </button>
            <button
              onClick={handlePlaySlow}
              className="px-3 py-3 sm:px-4 sm:py-2 bg-blue-500 text-outline-black text-black text-3xl sm:text-4xl rounded-lg hover:bg-blue-600 transition-all duration-150 m-2 transform active:scale-95 active:translate-y0 ease-in-out"
              aria-label="Play audio at slower speed"
            >
              🐢
            </button>
          </div>
        </div>

        {/* The multiple choice options - changed from 2 columns on all screens to 1 on mobile, 2 on larger screens */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 w-full max-w-[600px] mx-auto">
          {shuffledOptions.map((option, index) => {
            // Determine if this option is chosen by the user
            const isChosen = shuffledOptions[index].text === optionChosen;

            // Build button class based on user feedback & correctness
            let buttonClass = `
              transition-all
              p-3 sm:p-4 rounded-lg border-2 border-b-4 font-body text-xs sm:text-sm 
              flex flex-col items-center justify-center min-h-[60px] sm:min-h-[80px]
              cursor-pointer
              focus:outline-none transform active:scale-95 active:translate-y3 active:border-b-2 ease-in-out
            `;

            if (feedback) {
              if (option.isCorrect) {
                // Correct option
                buttonClass += "bg-green text-white border-green";
              } else if (!option.isCorrect && isChosen) {
                // user picked a wrong option
                buttonClass += " bg-white border-red text-red";
              } else {
                // any unselected wrong option
                buttonClass += " bg-white text-gray border-gray";
              }
            } else {
              // no feedback yet
              if (isChosen) {
                buttonClass += "text-gold border-gold";
              } else {
                buttonClass +=
                  "bg-white text-gray border-gray hover:text-green hover:border-green hover:bg-white";
              }
            }

            return (
              <button
                key={index}
                onClick={() => handleOptionClick(index)}
                disabled={feedback} // lock if feedback is already shown
                className={buttonClass}
              >
                <AudioWord
                  text={option.text}
                  audioUrl={audioUrls[option.text]} // Use fetched download URL
                  onPlay={() =>
                    console.log(`Audio for "${option.text}" started playing`)
                  }
                />
              </button>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}

AudioMCQMock.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      options: PropTypes.array.isRequired,
      question: PropTypes.string.isRequired,
    }).isRequired,
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string.isRequired,
    }),
    audioPrompt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        storagePath: PropTypes.string,
        url: PropTypes.string,
      }),
    ]),
    title: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default AudioMCQMock;
