import React, { useState, useContext } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { useAuth } from "../../helpers/UserAuthContextProvider";

import { LearningMaterialsContext } from "../../helpers/contexts";
import { LMContext } from "../../helpers/context3";
import { serverTimestamp } from "firebase/firestore";

const AnswerFeedbackModal = () => {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { learningMaterialId } = useContext(LMContext);
  const { learningMaterialState } = useContext(LearningMaterialsContext);
  const timestamp = serverTimestamp();
  const [submitMessage, setSubmitMessage] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedOptions("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const feedbackRef = collection(db, "feedback");
      const feedbackData = {
        userId: `${user?.uid}`,
        learningMaterialId: learningMaterialId,
        learningMaterialState: learningMaterialState,
        options: selectedOptions,
        timestamp: timestamp,
      };
      await addDoc(feedbackRef, feedbackData);
      setSubmitMessage("Thank you! Your feedback has been received!");
    } catch (error) {
      console.error("Error creating feedback:", error);
      setSubmitMessage("An error occurred while submitting your feedback.");
    }
  };

  const formSubmission = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await handleSubmit(event);
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    setTimeout(() => {
      setLoading(false);
      setSubmitMessage("");
      closeModal();
    }, 500);
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(
        selectedOptions.filter((selectedOption) => selectedOption !== option)
      );
    }
  };

  return (
    <div>
      <div className="feedback-icon rounded cursor-pointer" onClick={openModal}>
        <span className="font-body text-white text-ms sm:text-xs md:text-sm lg:text-base xl:text-lg uppercase font-semibold hover:text-green">
          Feedback
        </span>
      </div>
      {isOpen && (
        <div className="modal fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50 p-3 sm:p-0">
          <div className="modal-content bg-white border-b-4 border-2 border-black rounded p-4 sm:p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
            <span
              className="close absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-500 text-2xl sm:text-4xl bg-white cursor-pointer border-2 rounded px-2 sm:px-3 z-10"
              onClick={closeModal}
            >
              &times;
            </span>
            {!submitMessage ? (
              <form
                onSubmit={(event) => formSubmission(event)}
                className="pt-4"
              >
                <div className="space-y-2 sm:space-y-3">
                  <label className="flex items-start mt-1">
                    <input
                      type="checkbox"
                      name="feedback-option"
                      value="The audio is missing."
                      checked={selectedOptions.includes(
                        "The audio is missing."
                      )}
                      onChange={handleOptionChange}
                      className="mt-1 mr-2"
                    />
                    <span>
                      <b> There isn't any audio</b> for the content.
                    </span>
                  </label>

                  <label className="flex items-start">
                    <input
                      type="checkbox"
                      name="feedback-option"
                      value="My answer should be accepted."
                      checked={selectedOptions.includes(
                        "My answer should be accepted."
                      )}
                      onChange={handleOptionChange}
                      className="mt-1 mr-2"
                    />
                    <span>
                      <b> My answer</b> is the correct option.
                    </span>
                  </label>

                  <label className="flex items-start">
                    <input
                      type="checkbox"
                      name="feedback-option"
                      value="My screen keeps freezing or crashing."
                      checked={selectedOptions.includes(
                        "My screen keeps freezing or crashing."
                      )}
                      onChange={handleOptionChange}
                      className="mt-1 mr-2"
                    />
                    <span>
                      My screen <b>keeps freezing or crashing.</b>
                    </span>
                  </label>

                  <label className="flex items-start">
                    <input
                      type="checkbox"
                      name="feedback-option"
                      value="There is a spelling or grammar mistake."
                      checked={selectedOptions.includes(
                        "There is a spelling or grammar mistake."
                      )}
                      onChange={handleOptionChange}
                      className="mt-1 mr-2"
                    />
                    <span>
                      There is a <b>spelling or grammar mistake.</b>
                    </span>
                  </label>

                  <label className="flex items-start">
                    <input
                      type="checkbox"
                      name="feedback-option"
                      value="Something else went wrong."
                      checked={selectedOptions.includes(
                        "Something else went wrong."
                      )}
                      onChange={handleOptionChange}
                      className="mt-1 mr-2"
                    />
                    <span>
                      <b>Something else</b> went wrong.
                    </span>
                  </label>
                </div>

                <input
                  disabled={!selectedOptions.length || loading}
                  className="disabled:text-gray disabled:bg-darkgray disabled:cursor-not-allowed mt-4 w-full sm:min-w-1/3 capitalize cursor-pointer text-xs sm:text-sm md:text-base block py-2 px-4 font-body text-center font-bold disabled:border-gray text-black bg-green border-black rounded border-b-4 border-2 disabled:border-b-4 disabled:border-2"
                  type="submit"
                  value={loading ? "Loading..." : "Submit"}
                />
              </form>
            ) : (
              <div className="message text-green text-center py-4">
                {submitMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerFeedbackModal;
