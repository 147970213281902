import React, { useRef, useEffect, useState } from "react";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils.js";
import { useAuth } from "../../../helpers/UserAuthContextProvider.js";
import { useAttemptsWithNotifications } from "../../../hooks/useAttemptsHook.jsx";
import { useLearningAnalytics } from "../../../hooks/useLearningAnalytics.jsx";
import BeatLoader from "react-spinners/BeatLoader";

const UpdatedSkill = ({
  skill,
  enrollment,
  selectLearningMaterialText,
  setLearningMaterialId,
  setLearningMaterialState,
  learningMaterialId,
  updateSkillProgress,
  navigate,
  onClose,
  unitName,
}) => {
  const { user } = useAuth();
  const dropdownRef = useRef(null);
  const totalLessons = skill.lessons ? skill.lessons.length : 0;
  const { attempts, refreshAttempts } = useAttemptsWithNotifications();

  const [timeRemaining, setTimeRemaining] = useState("--:--");
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState("");
  const [regenerationInProgress, setRegenerationInProgress] = useState(false);

  // Add loading states for buttons
  const [lessonLoading, setLessonLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [practiceLoading, setPracticeLoading] = useState(false);

  // Get the current skill and lesson from enrollment
  const currentSkill = enrollment?.currentEnroll?.currentSkill || 1;
  const currentLesson = enrollment?.currentEnroll?.currentLesson || 1;

  // Initialize the current lesson index based on user's progress
  const [currentLessonIndex, setCurrentLessonIndex] = useState(() => {
    if (enrollment && skill && skill.lessons) {
      // Find the index of the current lesson in this skill's lesson array
      const lessonIndex = skill.lessons.findIndex(
        (lesson) => Number(lesson.lessonNumber) === Number(currentLesson)
      );

      // If found, use it. Otherwise, start from 0
      return lessonIndex >= 0 ? lessonIndex : 0;
    }
    return 0;
  });

  // Tracking analytics
  const { trackLessonStart } = useLearningAnalytics();

  // Time display component with auto-refresh
  useEffect(() => {
    let interval;

    const calculateTime = () => {
      if (!attempts.nextRegeneration) return "00:00";

      // Get current time
      const now = new Date();

      // Ensure we have a proper Date object
      let regenTime = attempts.nextRegeneration;

      // Calculate time difference in milliseconds
      const difference = regenTime - now;

      if (difference > 0) {
        const minutes = Math.floor(difference / 60000);
        const seconds = Math.floor((difference % 60000) / 1000);

        // Update seconds for progress circle
        setTimeRemainingSeconds(minutes * 60 + seconds);

        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
        )}`;
      }

      // Reset seconds when time is up
      setTimeRemainingSeconds(0);
      return "00:00";
    };

    const handleTimeUp = async () => {
      if (regenerationInProgress) return;

      setRegenerationInProgress(true);
      try {
        // Call the refreshAttempts to trigger the regeneration
        await refreshAttempts();
      } catch (error) {
        console.error("Failed to refresh attempts:", error);
      } finally {
        setRegenerationInProgress(false);
      }
    };

    if (attempts.count === 0 && attempts.nextRegeneration) {
      // Initial calculation
      const initialTime = calculateTime();
      setTimeRemaining(initialTime);

      // If already at zero, trigger refresh
      if (initialTime === "00:00" && !regenerationInProgress) {
        handleTimeUp();
      }

      // Update every second
      interval = setInterval(() => {
        const newTime = calculateTime();
        setTimeRemaining(newTime);

        // If we've reached zero, refresh the attempts
        if (newTime === "00:00" && !regenerationInProgress) {
          handleTimeUp();
        }
      }, 1000);
    } else {
      // Reset when we have attempts available
      setTimeRemainingSeconds(0);
      setTimeRemaining("00:00");
    }

    return () => clearInterval(interval);
  }, [
    attempts.nextRegeneration,
    attempts.count,
    refreshAttempts,
    regenerationInProgress,
  ]);

  // BARE MINIMUM click outside handler - both mouse and touch
  useEffect(() => {
    const clickHandler = (e) => {
      // Only process if we have a ref and the click is outside
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        console.log("[DEBUG] Dropdown click outside detected");
        // Call the onClose callback
        onClose();
      }
    };

    // Add the event listeners
    document.addEventListener("mousedown", clickHandler);
    document.addEventListener("touchstart", clickHandler);

    // Clean up
    return () => {
      document.removeEventListener("mousedown", clickHandler);
      document.removeEventListener("touchstart", clickHandler);
    };
  }, [onClose]);

  // When the user clicks "Start Lesson"
  const handleStartLesson = async (isPracticeMode = false) => {
    try {
      // Set the appropriate loading state based on mode
      if (isPracticeMode) {
        setPracticeLoading(true);
      } else {
        setLessonLoading(true);
      }

      const currentEnrollmentRef = doc(db, "users", user.uid);

      if (isPracticeMode) {
        // Practice Mode Logic
        const completedLessons = Object.entries(enrollment.progress || {})
          .filter(([_, lesson]) => lesson?.completed)
          .map(([lessonId]) => lessonId);

        setLearningMaterialId({
          mode: "practice",
          skill: skill.skillNumber,
          lessons: completedLessons,
        });
      } else {
        // Regular Lesson Mode Logic
        const lesson = skill.lessons[currentLessonIndex];

        console.log("Starting lesson with data:", {
          skillNumber: skill.skillNumber,
          lessonNumber: lesson.lessonNumber,
          lessonIndex: currentLessonIndex,
        });

        setLearningMaterialId({
          mode: "lesson",
          skill: skill.skillNumber,
          lesson: lesson,
        });

        // Update only specific fields using dot notation
        await updateDoc(currentEnrollmentRef, {
          "currentEnroll.course": "isiZuluSocial",
          "currentEnroll.currentSkill": skill.skillNumber,
          "currentEnroll.currentLesson": lesson.lessonNumber,
          "currentEnroll.lastUpdated": serverTimestamp(),
          [`progress.${skill.skillNumber}.${lesson.lessonNumber}`]: {
            attempted: true,
            lastAttempt: serverTimestamp(),
          },
        });

        // Only update local state, but don't increment yet
        updateSkillProgress(
          Math.min((currentLessonIndex / totalLessons) * 100, 100)
        );
      }

      selectLearningMaterialText();
      navigate("/lesson");
      trackLessonStart({
        courseId: enrollment.currentEnroll.course,
        language: enrollment.language,
        timestamp: new Date(),
      });
    } catch (err) {
      console.error("Lesson start failed:", err);
      // Reset loading states in case of error
      setLessonLoading(false);
      setPracticeLoading(false);
    }
  };

  const handleStartText = async () => {
    try {
      setTextLoading(true);
      await setLearningMaterialId(1);
      await setLearningMaterialState("dialogue");
      await navigate("/lesson");
    } catch (err) {
      console.error("Text start failed:", err);
      setTextLoading(false);
    }
  };

  const handlePracticeMode = () => {
    handleStartLesson(true);
  };

  // Calculate which lesson number to display
  // Show the correct lesson number based on the current index
  const displayLessonNumber =
    skill.lessons && skill.lessons[currentLessonIndex]
      ? skill.lessons[currentLessonIndex].lessonNumber
      : currentLesson;

  return (
    <div
      ref={dropdownRef}
      className="absolute z-50 w-[280px] xs:w-[300px] p-4 rounded shadow-lg border-2 border-b-4 bg-red"
      style={{ top: "90%", left: "50%", transform: "translateX(-50%)" }}
    >
      {/* Close button at the top right */}
      <button
        onClick={onClose}
        className="absolute top-1 right-1 w-6 h-6 flex items-center justify-center rounded-full bg-white/20 hover:bg-white/30 text-white transition-colors duration-150"
        aria-label="Close dropdown"
      >
        ✕
      </button>

      {currentLessonIndex < totalLessons ? (
        <div className="flex flex-col items-center">
          <div className="text-lg xs:text-xl font-semibold text-white mb-2">
            {skill.name}
          </div>
          {skill.emoji !== "💬" ? (
            <div className="text-sm xs:text-base font-light text-white mb-2">
              Skill {skill.skillNumber} • Lesson {displayLessonNumber}
            </div>
          ) : null}
          {skill.emoji !== "💬" ? (
            <button
              onClick={
                attempts.count > 0 && !lessonLoading
                  ? () => handleStartLesson(false)
                  : null
              }
              disabled={attempts.count === 0 || lessonLoading}
              className={
                attempts.count > 0
                  ? `mt-2 bg-red text-white px-4 py-2 rounded border-2 border-white p-2 border-b-4 transform
                  ${lessonLoading ? "" : "active:scale-95 active:translate-y-1"}
                  transition-all duration-150 ease-out-expo font-semibold shadow-lg
                  text-sm sm:text-md md:text-lg hover:shadow-xl
                  hover:bg-[#932929] relative overflow-hidden ${
                    lessonLoading ? "cursor-wait" : "cursor-pointer"
                  }`
                  : `mt-2 bg-gray text-black px-4 py-2 rounded border-2 border-black p-2 border-b-4 transform
                  transition-all duration-150 ease-out-expo font-semibold shadow-lg
                  text-sm sm:text-md md:text-lg hover:shadow-xl
                  hover:bg-gray relative overflow-hidden cursor-not-allowed`
              }
            >
              {lessonLoading ? (
                <BeatLoader size={8} color="#ffffff" />
              ) : attempts.count > 0 ? (
                `Start Lesson`
              ) : (
                `Out of Lives`
              )}
            </button>
          ) : (
            <button
              className={`mt-2 bg-red text-white px-4 py-2 rounded border-2 border-white p-2 border-b-4 transform
              ${textLoading ? "" : "active:scale-95 active:translate-y-1"}
              transition-all duration-150 ease-out-expo font-semibold shadow-lg
              text-sm sm:text-md md:text-lg hover:shadow-xl
              hover:bg-[#932929] relative overflow-hidden ${
                textLoading ? "cursor-wait" : "cursor-pointer"
              }`}
              onClick={textLoading ? null : () => handleStartText()}
              disabled={textLoading}
            >
              {textLoading ? (
                <BeatLoader size={8} color="#ffffff" />
              ) : (
                "Start Text"
              )}
            </button>
          )}
          {attempts.count === 0 && attempts.nextRegeneration && (
            <div className="mt-2 flex items-center gap-2 text-xs xs:text-sm text-white/80 animate-pulse">
              <svg className="w-4 h-4" viewBox="0 0 24 24">
                <circle
                  className="text-white/30"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="transparent"
                  r="10"
                  cx="12"
                  cy="12"
                />
                <circle
                  className="text-white/80"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="transparent"
                  strokeLinecap="round"
                  strokeDasharray="60"
                  strokeDashoffset={60 - (timeRemainingSeconds / 1800) * 60}
                  r="10"
                  cx="12"
                  cy="12"
                />
              </svg>
              <span>
                {" "}
                Next life in:{" "}
                {timeRemaining !== "00:00"
                  ? timeRemaining
                  : regenerationInProgress
                  ? "Loading..."
                  : "Ready!"}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="text-base xs:text-lg text-white text-center">
          <div className="text-lg xs:text-xl font-semibold text-white mb-2">
            {unitName}
          </div>
          <div className="text-sm xs:text-base font-light text-white mb-2">
            Refresh your memory with previous lessons
          </div>
          <button
            onClick={practiceLoading ? null : handlePracticeMode}
            disabled={practiceLoading}
            className={`mt-2 bg-red border-2 border-b-4 text-white px-4 py-2 rounded
            ${practiceLoading ? "cursor-wait" : "cursor-pointer"}
            ${
              practiceLoading
                ? ""
                : "hover:bg-[#932929] active:scale-95 active:translate-y-1"
            }`}
          >
            {practiceLoading ? (
              <BeatLoader size={8} color="#ffffff" />
            ) : (
              "Practice"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default UpdatedSkill;
