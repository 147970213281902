import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import UnitOverview from "./unit-overview";
import {
  getUnitStyles,
  getUnitInlineStyles,
} from "../../../utils/units/unitStyles";
import unitOverviewData from "../../../zombiedata/unit-overview-data";

const UnitDetailsModal = ({ unit, isOpen, onClose }) => {
  const modalRef = useRef(null);
  const styles = getUnitStyles(unit);
  const inlineStyles = getUnitInlineStyles(unit);

  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Handle escape key to close
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // Handle touch events for mobile
    const handleTouchOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleTouchOutside);
      document.addEventListener("keydown", handleEscKey);
      // Prevent scrolling on body when modal is open
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleTouchOutside);
      document.removeEventListener("keydown", handleEscKey);
      document.body.style.overflow = "auto";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Create a portal target or use document.body as fallback
  const portalTarget = document.getElementById("modal-root") || document.body;

  // Force use of the default data to ensure tables have content
  const dataToUse = unitOverviewData["george1-unit1"];

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm p-2 sm:p-0">
      <div
        ref={modalRef}
        className="bg-white rounded shadow-xl w-full xs:w-11/12 sm:w-4/5 md:w-3/4 lg:w-2/3 max-h-[90vh] overflow-y-auto"
        style={{
          boxShadow: `0 10px 25px -5px ${styles.colors.primary}, 0 8px 10px -6px rgba(0, 0, 0, 0.1)`,
        }}
      >
        {/* Mobile close button */}
        <div className="sticky top-0 bg-white z-10 p-2 text-right border-b border-gray-200 sm:hidden">
          <button
            onClick={onClose}
            className="inline-flex items-center justify-center p-2 rounded-full bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <UnitOverview
          unit={{ ...unit, name: dataToUse.name }}
          unitData={dataToUse} // Force use of the default data with complete tables
          onBack={onClose}
          isModal={true}
        />
      </div>
    </div>,
    portalTarget
  );
};

export default UnitDetailsModal;
