import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import { useAuth } from "../helpers/UserAuthContextProvider";

/**
 * Custom hook to get a user's role from Firestore
 * @param {Object} user - The current user object from Firebase Auth
 * @returns {Object} - Object containing role, loading state, and error
 */
export const useUserRole = () => {
  const { user } = useAuth();
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user || !user.uid) {
        console.log("😃 User data does not exist");
        setRole(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("😃 User data exists:", userData);
          setRole(userData.role || null);
        } else {
          setRole(null);
        }
      } catch (err) {
        console.error("Error fetching user role:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
    console.log("😃 User's current role is:", role);
  }, [user]);

  return {
    role,
    loading,
    error,
    isAdmin: role === "admin",
    isCoach: role === "coach",
  };
};
