// services/product-effect-service.js - Fixed version with correct method names
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import PowerUpManager from "./power-up-manager";

// Store notification sender reference
let notificationSender = null;

class ProductEffectService {
  // Method to register notification sender
  registerNotificationSender(sender) {
    console.log("Registering notification sender with ProductEffectService");
    notificationSender = sender;
  }

  // Apply product effects when a product is purchased
  async applyPurchaseEffects(productId, userId) {
    try {
      console.log("Applying purchase effects for:", productId, "user:", userId);

      // Get product details
      const productRef = doc(db, "products", productId);
      const productSnap = await getDoc(productRef);

      if (!productSnap.exists()) {
        console.error("Product not found:", productId);
        throw new Error("Product not found");
      }

      const product = productSnap.data();
      console.log("Product data:", product);

      // Variables for notifications
      let effectActivated = false;
      let effectType = "";
      let effectName = "";
      let expiryDate = null;
      let result = { success: false };

      // Apply different effects based on product type
      switch (product.type) {
        case "dictionary":
          console.log("Applying dictionary effect");

          if (product.unlimited) {
            // This is an unlimited dictionary access product
            result = await this.applyUnlimitedDictionary(
              userId,
              product.durationHours || 24
            );

            if (result.success) {
              effectActivated = true;
              effectType = "dictionary_unlimited";
              effectName = "Unlimited Dictionary Access";
              expiryDate = result.expiresAt;
            }
          } else {
            // This is a dictionary search pack with expiration
            const durationMinutes = product.durationMinutes || 60; // Default 60 minutes
            const searchesGranted = product.searchesGranted || 50;

            result = await this.addDictionarySearches(
              userId,
              searchesGranted,
              durationMinutes
            );

            if (result.success) {
              effectActivated = true;
              effectType = "dictionary_searches";
              effectName = `${searchesGranted} Dictionary Searches`;
              expiryDate = result.expiresAt;
            }
          }
          break;

        case "lives":
          console.log("Applying lives effect");
          result = await this.addLives(userId, product.livesGranted || 5);

          if (result.success) {
            effectActivated = true;
            effectType = "lives";
            effectName = `${product.livesGranted || 5} Extra Lives`;
          }
          break;

        case "streak-saver":
          console.log("Applying streak saver effect");

          result = await this.addStreakSavers(
            userId,
            product.saversGranted || 1
          );

          if (result.success) {
            effectActivated = true;
            effectType = "streak_saver";
            effectName = `${product.saversGranted || 1} Streak Saver${
              product.saversGranted > 1 ? "s" : ""
            }`;
          }
          break;

        case "xp-booster":
          console.log("Applying XP booster effect");

          result = await this.applyXPBoost(
            userId,
            product.multiplier || 2,
            product.durationHours || 24
          );

          if (result.success) {
            effectActivated = true;
            effectType = "xp_boost";
            effectName = `${product.multiplier || 2}× XP Boost`;
            expiryDate = result.expiresAt;
          }
          break;

        case "audio-pack":
          console.log("Applying audio pack effect");
          result = await this.unlockAudioPack(userId, product.audioPackId);

          if (result.success) {
            effectActivated = true;
            effectType = "audio_pack";
            effectName = `${product.name || "Audio Pack"}`;
          }
          break;

        case "coaching":
          console.log("Creating coaching session in inventory");

          result = await this.createCoachingSession(
            userId,
            product.name,
            product.description,
            product.imagePath
          );

          if (result.success) {
            effectActivated = true;
            effectType = "coaching";
            effectName = `${product.name || "Coaching Session"}`;
          }
          break;

        default:
          // For other product types, no special effect needed
          console.log(`No special effect for product type: ${product.type}`);
          result = {
            success: true,
            message: `Purchased ${product.name} successfully!`,
          };
      }

      // Send notification for activated effect
      if (
        effectActivated &&
        notificationSender &&
        notificationSender.sendEffectActivatedNotification
      ) {
        console.log(
          "Sending effect activated notification:",
          effectType,
          effectName
        );
        notificationSender.sendEffectActivatedNotification(
          effectType,
          effectName,
          expiryDate
        );
      }

      return {
        success: result.success,
        message:
          result.message || `Applied ${product.name} effects to your account!`,
      };
    } catch (error) {
      console.error("Error applying product effects:", error);
      return {
        success: false,
        message: "Failed to apply product effects: " + error.message,
      };
    }
  }

  // Apply item effects when an inventory item is used
  async applyItemUseEffects(inventoryItemId, userId) {
    try {
      console.log("Using inventory item:", inventoryItemId);

      // Get inventory item details
      const itemRef = doc(db, "inventory", inventoryItemId);
      const itemSnap = await getDoc(itemRef);

      if (!itemSnap.exists()) {
        return { success: false, message: "Item not found", type: "unknown" };
      }

      const item = itemSnap.data();
      const itemUserId = item.userId || userId;
      const itemType = item.type || item.category || "";

      let result = {
        success: false,
        message: "Unknown item type",
        type: itemType,
      };

      // Apply different effects based on item type
      switch (itemType.toLowerCase()) {
        case "dictionary":
          if (item.unlimited) {
            // Unlimited dictionary access
            result = await this.applyUnlimitedDictionary(
              itemUserId,
              item.durationHours || 1
            );
          } else {
            // Additional searches
            result = await this.addDictionarySearches(
              itemUserId,
              item.searchesGranted || 50,
              item.durationMinutes || 60
            );
          }
          break;

        // Update the relevant cases in your applyItemUseEffects method in product-effect-service.js

        case "streak-freeze":
        case "streak_freeze":
          // Apply streak freeze for today (fromInventory = true)
          result = await this.applyStreakFreeze(itemUserId, true);
          break;

        case "special":
          // Handle special category items - check for streak savers based on name or productId
          console.log("Processing special category item:", item.name);
          if (
            item.name?.toLowerCase().includes("streak") ||
            item.productId?.toLowerCase().includes("streak") ||
            item.description?.toLowerCase().includes("streak")
          ) {
            console.log("Identified as streak saver from special category");
            // Pass true for fromInventory to bypass streak savers check
            result = await this.applyStreakFreeze(itemUserId, true);
            result.type = "streak_freeze"; // Set proper type for notification
          } else {
            // For other special items, provide a more specific message
            result = {
              success: false,
              message: `Unrecognized special item: ${item.name}`,
              type: "special",
            };
          }
          break;

        case "xp-booster":
        case "xp_booster":
          // Apply XP boost
          result = await this.applyXPBoost(
            itemUserId,
            item.multiplier || 2,
            item.durationHours || 1
          );
          break;

        // Add this case to your switch statement in applyItemUseEffects method in product-effect-service.js

        case "boosters":
          // Handle boosters category - check what type of booster it is
          console.log("Processing boosters category item:", item.name);

          // Check if it's an XP booster
          if (
            item.name?.toLowerCase().includes("xp") ||
            item.productId?.toLowerCase().includes("xp-booster") ||
            item.description?.toLowerCase().includes("xp")
          ) {
            console.log("Identified as XP booster from boosters category");

            // Extract multiplier and duration if available
            let multiplier = 2; // Default multiplier
            let durationHours = 1; // Default duration

            // Try to extract multiplier from name or description
            if (item.multiplier) {
              multiplier = item.multiplier;
            } else if (item.description?.toLowerCase().includes("double")) {
              multiplier = 2;
            } else if (item.description?.toLowerCase().includes("triple")) {
              multiplier = 3;
            }

            // Try to extract duration from the item
            if (item.durationHours) {
              durationHours = item.durationHours;
            } else if (item.name?.toLowerCase().includes("hour")) {
              // Try to extract hours from name (like "1 Hour XP Booster")
              const hourMatch = item.name.match(/(\d+)\s*hour/i);
              if (hourMatch && hourMatch[1]) {
                durationHours = parseInt(hourMatch[1]);
              }
            }

            // Apply XP boost with the extracted parameters
            result = await this.applyXPBoost(
              itemUserId,
              multiplier,
              durationHours
            );
            result.type = "xp_boost"; // Set proper type for notification
          } else {
            // For other booster items, provide a more specific message
            result = {
              success: false,
              message: `Unrecognized booster item: ${item.name}`,
              type: "boosters",
            };
          }
          break;

        case "coaching":
          // Coaching sessions just need to be marked as used
          result = {
            success: true,
            message: "Coaching session redeemed",
            type: "coaching",
          };
          break;

        default:
          result = {
            success: false,
            message: `Unknown item type: ${itemType}`,
            type: "unknown",
          };
      }

      // If successful, mark the item as used
      if (result.success) {
        if (item.uses !== undefined && item.uses > 1) {
          // Multi-use item
          await updateDoc(itemRef, {
            uses: item.uses - 1,
            lastUsed: new Date(),
          });
        } else {
          // Single-use item
          await updateDoc(itemRef, {
            isUsed: true,
            usedAt: new Date(),
          });
        }

        // Add the item name to the success message
        result.message = `${item.name}: ${result.message}`;
      }

      // Extract notification information
      let effectType = result.type || itemType;
      let effectName = item.name || "Item";
      let expiryDate = result.expiresAt || null;

      // Send notification based on result
      if (
        result.success &&
        notificationSender &&
        notificationSender.sendEffectActivatedNotification
      ) {
        console.log(
          "Sending effect activated notification:",
          effectType,
          effectName
        );
        notificationSender.sendEffectActivatedNotification(
          effectType,
          effectName,
          expiryDate
        );
      }

      return {
        success: result.success,
        message: result.message || "Item used successfully!",
      };
    } catch (error) {
      console.error("Error using item:", error);
      return {
        success: false,
        message: "Failed to use item: " + error.message,
      };
    }
  }

  // Check if product effects are still valid (for items with durations)
  async checkEffectStatus(userId) {
    try {
      // Use the PowerUpManager to check all expired effects
      const { expired, messages } =
        await PowerUpManager.checkAllExpiredPowerUps(userId);

      // Send notifications for expired effects
      if (
        notificationSender &&
        notificationSender.sendEffectExpiredNotification &&
        expired.length > 0
      ) {
        expired.forEach((effect, index) => {
          const message = messages[index] || `Your ${effect.type} has expired`;

          console.log(
            "Sending effect expired notification:",
            effect.type,
            message
          );

          notificationSender.sendEffectExpiredNotification(
            effect.type,
            message
          );
        });
      }

      return { success: true, expiredEffects: expired };
    } catch (error) {
      console.error("Error checking effect status:", error);
      return { success: false, error: error.message };
    }
  }

  // Helper methods to delegate to PowerUpManager
  async applyUnlimitedDictionary(userId, durationHours) {
    return PowerUpManager.applyUnlimitedDictionary(userId, durationHours);
  }

  async addDictionarySearches(userId, searches, durationMinutes) {
    return PowerUpManager.addDictionarySearches(
      userId,
      searches,
      durationMinutes
    );
  }

  async applyXPBoost(userId, multiplier, durationHours) {
    return PowerUpManager.applyXPBoost(userId, multiplier, durationHours);
  }

  async applyStreakFreeze(userId, fromInventory = false) {
    return PowerUpManager.applyStreakFreeze(userId, fromInventory);
  }

  async addStreakSavers(userId, count) {
    return PowerUpManager.addStreakSavers(userId, count);
  }

  async addLives(userId, count) {
    // Add implementation or delegate to PowerUpManager
    const userRef = doc(db, "users", userId);

    try {
      // Update lives count
      await updateDoc(userRef, {
        livesRemaining: increment(count),
      });

      return {
        success: true,
        message: `Added ${count} extra lives`,
      };
    } catch (error) {
      console.error("Error adding lives:", error);
      return { success: false, message: "Failed to add lives" };
    }
  }

  async unlockAudioPack(userId, audioPackId) {
    if (!userId || !audioPackId) {
      return { success: false, message: "Missing required parameters" };
    }

    try {
      const userRef = doc(db, "users", userId);

      // Unlock audio content
      await updateDoc(userRef, {
        unlockedAudioPacks: arrayUnion(audioPackId),
      });

      return {
        success: true,
        message: `Unlocked audio pack: ${audioPackId}`,
      };
    } catch (error) {
      console.error("Error unlocking audio pack:", error);
      return { success: false, message: "Failed to unlock audio pack" };
    }
  }

  async createCoachingSession(userId, name, description, imagePath) {
    // Implementation to create a coaching session in inventory
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      // Create coaching session in inventory (implementation depends on your database structure)
      // This is placeholder code - you'll need to adapt it to your actual database operations
      const inventoryRef = doc(db, "inventory", `coaching-${Date.now()}`);
      await setDoc(inventoryRef, {
        userId,
        name: name || "Language Coaching Session",
        description:
          description || "One-on-one coaching session with a language expert",
        imagePath: imagePath || "images/products/coaching.png",
        category: "coaching",
        type: "coaching",
        isUsed: false,
        createdAt: new Date(),
      });

      return {
        success: true,
        message: "Coaching session added to your inventory",
      };
    } catch (error) {
      console.error("Error creating coaching session:", error);
      return { success: false, message: "Failed to create coaching session" };
    }
  }
}

export default new ProductEffectService();
