import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import Confetti from "react-confetti";
import PuffLoader from "react-spinners/PuffLoader";
import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  serverTimestamp,
  increment,
} from "firebase/firestore";

// UPDATED: Removed direct AnalyticsService import
// import { AnalyticsService } from "../../../services/enhanced-analytics.service.js";
import { useSafeAnalytics } from "../../../hooks/useSafeAnalytics"; // NEW: Import the hook

import { LearningAnalyticsEvents } from "../../../firebase/firebase.utils.js";

import { ScoreContext } from "../../../helpers/ScoreContext.js";
import { LearningMaterialsContext } from "../../../helpers/contexts.js";
import { LMContext } from "../../../helpers/context3.js";
import { db } from "../../../firebase/firebase.utils.js";
import { useAuth } from "../../../helpers/UserAuthContextProvider.js";
import { SkillsSocialData } from "../../../zombiedata/skills-social";
import LessonReviewModal from "./lesson-review-modal.jsx";
import PowerUpUtils from "../../../services/power-ups/power-up-utils.js";
import DeckUnlockService from "../../../services/flashcard/deck-unlock-service.js";
import {
  FaStar,
  FaHeart,
  FaBook,
  FaTrophy,
  FaChartBar,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import { IoMdList } from "react-icons/io";

import { toDate, isFuture } from "../../../utils/timestamp-utils";
import { useShopWithPowerUps } from "../../../hooks/useShopWithPowerUps";
import PowerUpInitializationService from "../../../services/power-ups/power-up-initialization-service.js";
import { checkStreakProtection } from "../../../helpers/quiz-methods.js";

const ExerciseSummary = () => {
  const navigate = useNavigate();
  const { score, getScorePercentage } = useContext(ScoreContext);
  const { analyticsData } = useContext(LearningMaterialsContext);
  const { learningMaterialId } = useContext(LMContext);
  const { user } = useAuth();

  // UPDATED: Use the safe analytics hook
  const {
    trackEvent,
    calculateBoostedXP,
    refreshPowerUpStatus,
    getPowerUpStatus,
  } = useSafeAnalytics();

  // Using the hook pattern instead of direct context
  const { powerUpStatus } = useShopWithPowerUps();

  const [powerUpMessages, setPowerUpMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [achievements, setAchievements] = useState([]);
  const [userStats, setUserStats] = useState(null);
  const [enrollment, setEnrollment] = useState({});
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [boostedXpData, setBoostedXpData] = useState(null);
  const [setUnlockedDeckNotification] = useState(null);

  useEffect(() => {
    if (analyticsData && !loading && user?.uid) {
      performXpBoostCalculation();
    }
  }, [analyticsData, loading, user?.uid]);

  // Initialize power-ups on component mount
  useEffect(() => {
    if (user?.uid) {
      PowerUpInitializationService.initialize(user.uid)
        .then((result) => {
          console.log("Power-ups initialized:", result);

          // Check for any messages to display about power-ups
          const messages = [];

          if (result.xpStatus?.active) {
            messages.push({
              type: "success",
              text: `XP Boost active: ${result.xpStatus.multiplier}× multiplier!`,
            });
          }

          if (result.streakStatus?.active) {
            messages.push({
              type: "info",
              text: "Your streak is protected today!",
            });
          }

          setPowerUpMessages(messages);
        })
        .catch((error) => {
          console.error("Error initializing power-ups:", error);
        });
    }
  }, [user?.uid]);

  // Count animation
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));

  // Helper function for safe analytics value retrieval
  const safeAnalyticsValue = (key, defaultValue = 0) => {
    if (!analyticsData) return defaultValue;
    return typeof analyticsData[key] === "number" && !isNaN(analyticsData[key])
      ? analyticsData[key]
      : defaultValue;
  };

  // Correctly implemented percentage calculation function
  const calculatePercentageScore = () => {
    // If we have analytics data, use it for more accurate calculation
    if (analyticsData) {
      const totalQuestions = safeAnalyticsValue("questionsAnswered");
      const correctAnswers = safeAnalyticsValue("correctAnswers");

      // If we have valid analytics data, use it for score calculation
      if (totalQuestions > 0) {
        // Calculate percentage based on correct/total questions from analytics
        const analyticsPercentage = (correctAnswers / totalQuestions) * 100;
        return analyticsPercentage;
      }
    }

    // If no analytics data, use the context's method if available
    if (typeof getScorePercentage === "function") {
      return getScorePercentage();
    }

    // Fallback to original calculation if nothing else works
    // Enforce the standard 12 exercises as denominator for consistency
    const standardExerciseCount = 12;
    return (score / standardExerciseCount) * 100;
  };

  const performXpBoostCalculation = async () => {
    if (!user?.uid || !analyticsData) return;

    try {
      // Get the ORIGINAL base XP amount, not the potentially already-boosted totalXP
      // Check if analyticsData already has originalXP set (from prior calculations)
      const baseXP =
        analyticsData.originalXP ||
        // If analyticsData has multiplier info, derive the original value
        (analyticsData.xpMultiplier &&
        analyticsData.xpMultiplier > 1 &&
        analyticsData.totalXP
          ? Math.round(analyticsData.totalXP / analyticsData.xpMultiplier)
          : analyticsData.totalXP || 0);

      console.log("Base XP before boost calculation:", baseXP);

      // UPDATED: Use the calculateBoostedXP from useSafeAnalytics
      const result = await calculateBoostedXP(baseXP);

      // If we have a boost, store the boosted values in state
      if (result.hasBoost) {
        setBoostedXpData({
          originalXP: baseXP,
          boostedXP: result.xp,
          multiplier: result.multiplier,
          expiresAt: toDate(result.expiresAt),
        });

        console.log("XP Boost applied:", {
          originalXP: baseXP,
          boostedXP: result.xp,
          multiplier: result.multiplier,
        });

        // UPDATED: Using trackEvent from useSafeAnalytics
        trackEvent("xp_boost_applied", {
          baseXP: baseXP,
          boostedXP: result.xp,
          multiplier: result.multiplier,
          source: "lesson_completion",
        });
      } else {
        // Even if no boost, still set the data for consistency
        setBoostedXpData({
          originalXP: baseXP,
          boostedXP: baseXP, // No boost applied
          multiplier: 1,
          expiresAt: null,
        });
      }
    } catch (error) {
      console.error("Error calculating boosted XP:", error);
    }
  };

  // Use the new function
  const percentScore = calculatePercentageScore();

  // Ensure the percentage is between 0-100 and round to whole number
  const roundedPercentScore = Math.min(
    Math.max(0, Math.round(percentScore)),
    100
  );

  const displayCompleteStats = () => {
    const hasXpBoost = analyticsData && analyticsData.xpMultiplier > 1;

    return (
      <>
        <div className="grid grid-cols-3 gap-2 mb-3 text-center">
          <div className="p-2 bg-gray bg-opacity-10 rounded border-b-4 border-2 border-gray">
            <p className="text-xl font-bold">
              {safeAnalyticsValue("questionsAnswered")}
            </p>
            <p className="text-sm">Questions</p>
          </div>
          <div className="p-2 bg-green bg-opacity-10 rounded border-b-4 border-2 border-green text-center">
            <p className="text-xl font-bold text-green">
              {safeAnalyticsValue("correctAnswers")}
            </p>
            <p className="text-sm">Correct</p>
          </div>
          <div className="p-2 bg-red bg-opacity-10 rounded border-b-4 border-2 border-red text-center">
            <p className="text-xl font-bold text-red">
              {safeAnalyticsValue("incorrectAnswers")}
            </p>
            <p className="text-sm">Incorrect</p>
          </div>
        </div>

        <div className="p-3 bg-gold bg-opacity-10 rounded border-b-4 border-2 border-gold text-center mb-3">
          <p className="text-xl font-bold text-gold">{roundedPercentScore}%</p>
          <p className="text-sm">Accuracy</p>
        </div>

        {/* Add XP Boost display if active */}
        {hasXpBoost && (
          <div className="p-3 bg-green bg-opacity-10 rounded border-b-4 border-2 border-green text-center mb-3">
            <div className="flex justify-center items-center">
              <span className="text-lg mr-2">⚡</span>
              <div>
                <p className="text-sm font-medium">XP Boost Active!</p>
                <p className="text-xs">
                  {analyticsData.originalXP} × {analyticsData.xpMultiplier} ={" "}
                  {analyticsData.totalXP} XP
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderActivePowerUps = () => {
    if (
      !powerUpStatus?.xpBoost?.active &&
      !powerUpStatus?.streakProtection?.active
    ) {
      return null;
    }

    return (
      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2 flex items-center">
          <span className="mr-2">⚡</span>
          Active Power-Ups
        </h3>

        <div className="space-y-2">
          {powerUpStatus.xpBoost?.active && (
            <div className="p-3 bg-gold bg-opacity-10 rounded border-b-4 border-2 border-gold">
              <div className="flex items-center">
                <span className="text-xl mr-2">⚡</span>
                <div>
                  <p className="font-bold">XP Boost Active</p>
                  <p className="text-sm">
                    {powerUpStatus.xpBoost.multiplier}× multiplier
                    {powerUpStatus.xpBoost.expiresAt && (
                      <span className="ml-1">
                        until{" "}
                        {new Date(
                          powerUpStatus.xpBoost.expiresAt
                        ).toLocaleString()}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}

          {powerUpStatus.streakProtection?.active && (
            <div className="p-3 bg-blue-100 rounded border-b-4 border-2 border-blue-500">
              <div className="flex items-center">
                <span className="text-xl mr-2">🛡️</span>
                <div>
                  <p className="font-bold">Streak Protected</p>
                  <p className="text-sm">Your streak is safe for today!</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Initial loading effect
  useEffect(() => {
    setLoading(true);

    // Simulate loading delay to match original component
    setTimeout(() => {
      setLoading(false);
      setShowSummary(true);
    }, 2000);
  }, []);

  // Animation for count
  useEffect(() => {
    const controls = animate(count, 50);
    return controls.stop;
  }, [count]);

  // Confetti effect for high scores
  useEffect(() => {
    if (roundedPercentScore >= 75) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 60000);
    }
  }, [roundedPercentScore]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setEnrollment(docSnap.data());
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchUserData();
  }, [user]);

  // Fetch user's analytics summary
  useEffect(() => {
    const fetchUserStats = async () => {
      if (user?.uid) {
        try {
          // UPDATED: Use the hook to get the most recent analytics data
          // We'll get it from the context which already has the user analytics
          // Or we can force a refresh to ensure it's up to date
          await refreshPowerUpStatus();
          const currentPowerUpStatus = await getPowerUpStatus();
          setUserStats(currentPowerUpStatus);
          checkForAchievements(currentPowerUpStatus);
        } catch (error) {
          console.error("Error fetching analytics:", error);
        }
      }
    };

    fetchUserStats();
  }, [user?.uid, refreshPowerUpStatus, getPowerUpStatus]);

  // Check for any new achievements
  const checkForAchievements = (stats) => {
    const newAchievements = [];

    // Check streak achievements
    if (stats?.current_streak >= 7 && stats?.current_streak < 14) {
      newAchievements.push({
        icon: "🔥",
        title: "7-Day Streak",
        description: "You've been learning for 7 days in a row!",
      });
    } else if (stats?.current_streak >= 14 && stats?.current_streak < 30) {
      newAchievements.push({
        icon: "🔥🔥",
        title: "14-Day Streak",
        description: "Two weeks of consistent learning!",
      });
    } else if (stats?.current_streak >= 30) {
      newAchievements.push({
        icon: "🔥🔥🔥",
        title: "30-Day Streak",
        description: "A whole month of dedication!",
      });
    }

    // Check XP achievements
    if (stats?.total_xp >= 1000 && stats?.total_xp < 5000) {
      newAchievements.push({
        icon: "🏆",
        title: "1000 XP Club",
        description: "You've earned over 1000 XP points!",
      });
    } else if (stats?.total_xp >= 5000) {
      newAchievements.push({
        icon: "🏆🏆",
        title: "XP Master",
        description: "5000 XP points and counting!",
      });
    }

    // Check vocabulary achievements
    if (
      stats?.vocabulary_learned_count >= 100 &&
      stats?.vocabulary_learned_count < 500
    ) {
      newAchievements.push({
        icon: "📚",
        title: "Word Collector",
        description: "You've learned 100 words!",
      });
    } else if (stats?.vocabulary_learned_count >= 500) {
      newAchievements.push({
        icon: "📚📚",
        title: "Vocabulary Master",
        description: "500 words in your vocabulary arsenal!",
      });
    }

    setAchievements(newAchievements);
  };

  // Calculate accuracy from analytics data
  const calculateAccuracy = () => {
    if (!analyticsData) return "N/A";

    const total =
      safeAnalyticsValue("correctAnswers") +
      safeAnalyticsValue("incorrectAnswers");
    if (total === 0) return "N/A";

    return `${Math.round(
      (safeAnalyticsValue("correctAnswers") / total) * 100
    )}%`;
  };

  // Get top exercise types from analytics data
  const getTopExerciseTypes = () => {
    if (!analyticsData?.exerciseTypes) return [];

    return Object.entries(analyticsData.exerciseTypes)
      .sort(([, countA], [, countB]) => countB - countA)
      .slice(0, 3);
  };

  // Handle review button click
  const handleReview = () => {
    setShowReviewModal(true);
  };

  // Fixed version of handleProgression in lesson-completion.component.jsx
  const handleProgression = async () => {
    if (!user?.uid || !enrollment.currentEnroll.course) {
      console.error("Missing user or enrollment data");
      return;
    }

    const userRef = doc(db, "users", user.uid);

    try {
      // Extract the course data in a more flexible way
      let skills = [];
      const motive = enrollment.motive; // e.g., 'isiZuluSocial'
      const course = enrollment.currentEnroll.course; // e.g., 'isiZuluSocial'
      console.log(course);

      // Log the state for debugging
      console.log("Enrollment motive:", course);
      console.log("SkillsSocialData keys:", Object.keys(SkillsSocialData));

      // Check if the motive exists in the data
      if (!SkillsSocialData[course]) {
        throw new Error(`Course data not found for motive: ${course}`);
      }

      // Get the membership from enrollment or default to "George"
      const membership = enrollment.membership || "George";
      console.log("Using membership:", membership);

      // Access the data with proper existence checks at each level
      if (SkillsSocialData[course][membership]) {
        // If we have direct access to the membership level
        const courseData = SkillsSocialData[course][membership];

        // Check if courseData is an array
        if (Array.isArray(courseData)) {
          // Extract skills from the first course in the array (which is your structure)
          skills = courseData[0].skills || [];
        } else if (courseData.skills) {
          // Direct access to skills property
          skills = courseData.skills;
        }
      } else {
        // Try alternative approach - search for the skills in any top-level key
        // This is a fallback approach if the membership key doesn't match exactly
        console.warn(
          `No direct match for ${membership} in ${course}, trying fallback`
        );

        // Try to find any available membership data
        const availableMemberships = Object.keys(SkillsSocialData[course]);
        if (availableMemberships.length > 0) {
          const firstAvailableMembership = availableMemberships[0];
          console.log(`Using fallback membership: ${firstAvailableMembership}`);

          const courseData = SkillsSocialData[course][firstAvailableMembership];
          if (Array.isArray(courseData)) {
            skills = courseData[0].skills || [];
          } else if (courseData.skills) {
            skills = courseData.skills;
          }
        }
      }

      if (!skills.length) {
        throw new Error(
          `No skills found for motive: ${course} and membership: ${membership}`
        );
      }

      console.log(`Found ${skills.length} skills`);

      // Find the current skill by skillNumber
      const currentSkill = skills.find(
        (s) => s.skillNumber === learningMaterialId.skill
      );

      if (!currentSkill) {
        throw new Error(`Skill ${learningMaterialId.skill} not found`);
      }

      // Find the current lesson
      const targetLessonId = learningMaterialId.lesson?.lessonId;
      if (!targetLessonId) {
        throw new Error("Lesson ID not found in learningMaterialId");
      }

      const currentLesson = currentSkill.lessons.find(
        (l) => l.lessonId === targetLessonId
      );

      if (!currentLesson) {
        throw new Error(`Lesson ${targetLessonId} not found`);
      }

      // Find the next lesson number
      const nextLessonNumber = currentLesson.lessonNumber + 1;

      // Check if the next lesson exists in this skill
      const hasNextLesson = currentSkill.lessons.some(
        (l) => l.lessonNumber === nextLessonNumber
      );

      // If there's no next lesson in this skill, we might want to increment the skill
      const nextSkill = hasNextLesson
        ? currentSkill.skillNumber
        : currentSkill.skillNumber + 1;

      // Firestore updates for grades
      const updates = {
        [`grades.${currentSkill.skillNumber}`]: arrayUnion({
          id: `${currentSkill.skillNumber}_${currentLesson.lessonNumber}`,
          score: roundedPercentScore,
        }),
        // Update the lesson progression correctly
        "currentEnroll.currentSkill": nextSkill,
        "currentEnroll.currentLesson": hasNextLesson ? nextLessonNumber : 1, // Reset to 1 when moving to next skill
        // Add to completedLessons array in the skills object too
        [`currentEnroll.skills.${currentSkill.skillNumber}.completedLessons`]:
          arrayUnion(currentLesson.lessonNumber),
      };

      console.log("Updating user progress:", {
        currentSkill: currentSkill.skillNumber,
        currentLesson: currentLesson.lessonNumber,
        nextSkill,
        nextLessonNumber: hasNextLesson ? nextLessonNumber : 1,
        score: roundedPercentScore,
      });

      await updateDoc(userRef, updates);

      // Track analytics event for lesson completion if not already tracked
      if (user?.uid && analyticsData) {
        const skillNumber = currentSkill.skillNumber;
        const lessonNumber = currentLesson.lessonNumber;

        // UPDATED: Using trackEvent from useSafeAnalytics
        // Track score achievement
        trackEvent("lesson_score", {
          skillId: skillNumber,
          lessonId: lessonNumber,
          score: roundedPercentScore,
          xpEarned: analyticsData.totalXP || 0,
        });
      }

      // Add this code inside the handleProgression method in lesson-completion.component.jsx
      // Place it just before the navigate("/units") call

      // Track vocabulary learned in this lesson
      if (user?.uid && analyticsData) {
        try {
          console.log("Starting vocabulary tracking for completed lesson");

          // Get the exerciseResults from analyticsData
          const exerciseResults = analyticsData.exerciseResults || {};

          // Only extract vocabulary from correctly answered exercises
          const correctlyAnsweredExercises = Object.values(
            exerciseResults
          ).filter((result) => result.isCorrect === true);

          console.log(
            `Found ${correctlyAnsweredExercises.length} correctly answered exercises`
          );

          // Collect vocabulary words from correctly answered exercises
          const vocabularyWords = [];

          correctlyAnsweredExercises.forEach((exercise) => {
            // Check if the exercise has vocabulary field (we added this earlier)
            if (exercise.vocabulary && Array.isArray(exercise.vocabulary)) {
              vocabularyWords.push(...exercise.vocabulary);
            } else {
              // Fallback if vocabulary field is missing
              if (exercise.correctAnswer) {
                vocabularyWords.push({
                  word:
                    typeof exercise.correctAnswer === "object"
                      ? exercise.correctAnswer.text
                      : exercise.correctAnswer,
                  translation: null,
                  source: "exercise_correct_answer",
                });
              }
            }
          });

          // Remove duplicates
          const uniqueWords = [
            ...new Set(
              vocabularyWords.map((v) => (typeof v === "object" ? v.word : v))
            ),
          ].map((word) => {
            // Convert strings to objects for consistency
            if (typeof word === "string") {
              return { word, translation: null, source: "exercise_completion" };
            }
            return word;
          });

          if (uniqueWords.length > 0) {
            console.log(
              `Found ${uniqueWords.length} vocabulary words to track`
            );

            // Update the user's analytics document
            const userAnalyticsRef = doc(db, "user_analytics", user.uid);

            // Add to vocabulary fields
            await updateDoc(userAnalyticsRef, {
              vocabulary_words: arrayUnion(...uniqueWords),
              vocabulary_learned_count: increment(uniqueWords.length),
              [`daily_activity.${
                new Date().toISOString().split("T")[0]
              }.vocabulary_learned`]: increment(uniqueWords.length),
              last_updated: serverTimestamp(),
            });

            console.log(
              `Successfully updated user analytics with ${uniqueWords.length} vocabulary words`
            );

            // UPDATED: Using trackEvent from useSafeAnalytics
            // Also track as vocabulary event
            trackEvent(LearningAnalyticsEvents.VOCABULARY_UNLOCKED, {
              count: uniqueWords.length,
              words: uniqueWords.map((w) =>
                typeof w === "object" ? w.word : w
              ),
              skillId: currentSkill.skillNumber,
              lessonId: currentLesson.lessonNumber,
            });
          } else {
            console.log(
              "No vocabulary words found in correctly answered exercises"
            );
          }
        } catch (vocabError) {
          console.error("Error tracking vocabulary:", vocabError);
          // Don't block progression if vocabulary tracking fails
        }
      }
      // Inside handleProgression() in lesson-completion.component.jsx
      // After the vocabulary tracking code and before navigate("/units")

      // Check for decks to unlock based on completed lesson
      try {
        console.log("Checking for decks to unlock...");
        if (user?.uid) {
          // Get the skill and lesson numbers
          const skillNumber = currentSkill.skillNumber;
          const lessonNumber = currentLesson.lessonNumber;

          console.log(
            `Checking for decks to unlock - Skill: ${skillNumber}, Lesson: ${lessonNumber}`
          );

          // Call the deck unlock service
          const newlyUnlockedDecks =
            await DeckUnlockService.checkForDecksToUnlock(
              user.uid,
              skillNumber,
              lessonNumber
            );

          // Track the unlocked decks in analytics and show notification
          if (newlyUnlockedDecks.length > 0) {
            console.log(
              `Unlocked ${newlyUnlockedDecks.length} flashcard decks:`,
              newlyUnlockedDecks
            );

            // UPDATED: Using trackEvent from useSafeAnalytics
            // Track in analytics
            trackEvent("flashcard_decks_unlocked", {
              count: newlyUnlockedDecks.length,
              deckIds: newlyUnlockedDecks,
              sourceSkill: skillNumber,
              sourceLesson: lessonNumber,
            });

            // Set flag in sessionStorage for newly unlocked decks
            try {
              sessionStorage.setItem("decks_unlocked_recently", "true");
              sessionStorage.setItem(
                "decks_unlocked_timestamp",
                Date.now().toString()
              );
              sessionStorage.setItem(
                "newly_unlocked_deck_ids",
                JSON.stringify(newlyUnlockedDecks)
              );
              console.log(
                "🔓 Set flag for newly unlocked decks:",
                newlyUnlockedDecks
              );
            } catch (e) {
              console.error("Error storing unlock data in sessionStorage:", e);
            }

            // You could also show a notification here (optional)
            try {
              const pendingNotifications = JSON.parse(
                localStorage.getItem("pendingDeckNotifications") || "[]"
              );
              pendingNotifications.push({
                timestamp: new Date().toISOString(),
                deckIds: newlyUnlockedDecks,
                seen: false,
              });
              localStorage.setItem(
                "pendingDeckNotifications",
                JSON.stringify(pendingNotifications)
              );
            } catch (e) {
              console.error("Error saving notification data:", e);
            }
          }
        }
      } catch (deckUnlockError) {
        console.error("Error checking for decks to unlock:", deckUnlockError);
        // Don't block progression if deck unlocking fails
      }

      navigate("/units");
    } catch (error) {
      console.error("Progression error:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
      className="text-lg mt-1 ml-1"
    >
      {loading ? (
        <div className="flex flex-row justify-center items-center h-screen bg-white">
          <PuffLoader color={"#991616"} loading={loading} size={100} />
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-screen py-4">
          {showConfetti && <Confetti />}

          {/* Score Summary Display */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: showSummary ? 1 : 0, y: showSummary ? 0 : 20 }}
            transition={{ duration: 0.5 }}
            className="bg-white border-2 border-darkgray border-b-4 rounded shadow-lg p-4 w-full max-w-[600px] mx-auto"
          >
            <div className="text-center mb-2">
              <h2 className="text-xl font-bold text-green">
                {roundedPercentScore >= 100
                  ? "Perfect Score!"
                  : roundedPercentScore >= 75
                  ? "Well done!"
                  : roundedPercentScore >= 50
                  ? "Keep practicing!"
                  : "Needs improvement"}
              </h2>
              <p className="text-gray">You've completed the lesson</p>
            </div>

            {/* Score summary - 2 columns */}
            <div className="grid grid-cols-2 gap-3 mb-3">
              <div className="bg-white bg-opacity-10 p-3 rounded border-b-4 border-2 border-darkgray">
                <div className="flex items-center">
                  <span className="text-xl mr-2">⭐️</span>
                  <div>
                    <h3 className="font-bold">XP Earned</h3>
                    {boostedXpData ? (
                      <div>
                        <p className="text-xl font-bold">
                          {boostedXpData.boostedXP}
                        </p>
                        <p className="text-xs text-gold">
                          {boostedXpData.originalXP} ×{" "}
                          {boostedXpData.multiplier} boost
                        </p>
                      </div>
                    ) : (
                      <p className="text-xl font-bold">
                        {safeAnalyticsValue("totalXP")}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white bg-opacity-10 p-3 rounded border-b-4 border-2 border-darkgray">
                <div className="flex items-center">
                  <span className="text-xl mr-2">🏆</span>
                  <div>
                    <h3 className="font-bold">Score</h3>
                    <p className="text-xl font-bold">{roundedPercentScore}%</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Two-column layout for the main content */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {/* Left column */}
              <div>
                {/* Performance Summary */}
                {/* Performance Summary */}
                {analyticsData && (
                  <div className="mb-3">
                    <h3 className="text-lg font-bold mb-2 flex items-center">
                      <span className="mr-2">📊</span>
                      Performance Summary
                    </h3>

                    {/* Use the displayCompleteStats function here */}
                    {displayCompleteStats()}
                  </div>
                )}

                {/* Exercise Types */}
                {getTopExerciseTypes().length > 0 && (
                  <div className="p-3 bg-gray border-b-4 border-2 bg-opacity-10 border-darkgray rounded mb-3">
                    <div className="flex items-center mb-2">
                      <span className="mr-2">📚</span>
                      <h4 className="font-bold">Exercise Types</h4>
                    </div>
                    <ul className="space-y-1">
                      {getTopExerciseTypes().map(([type, count]) => (
                        <li key={type} className="flex justify-between">
                          <span className="capitalize">
                            {type.replace(/_/g, " ")}
                          </span>
                          <span className="font-bold">{count}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Vocabulary Learned Section */}
                {analyticsData?.vocabularyLearned &&
                  analyticsData.vocabularyLearned.length > 0 && (
                    <div className="p-3 bg-green bg-opacity-10 border-green border-2 border-b-4 rounded mb-3">
                      <div className="flex items-center mb-2">
                        <span className="mr-2">📔</span>
                        <h4 className="font-bold">Vocabulary Learned</h4>
                      </div>
                      <p>
                        {analyticsData.vocabularyLearned.length} words learned
                      </p>
                    </div>
                  )}
              </div>

              {/* Right column */}
              <div>
                {/* Achievements Section */}
                {achievements.length > 0 && (
                  <div className="mb-3">
                    <h3 className="text-lg font-bold mb-2 flex items-center">
                      <span className="mr-2">🏆</span>
                      Achievements
                    </h3>
                    <div className="space-y-2">
                      {achievements.map((achievement, index) => (
                        <motion.div
                          key={index}
                          className="flex items-center bg-white p-3 rounded border-2 border-green border-b-4"
                          initial={{ x: -20, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ delay: 0.3 + index * 0.1 }}
                        >
                          <div className="text-2xl mr-2">
                            {achievement.icon}
                          </div>
                          <div className="text-left">
                            <div className="font-bold text-sm">
                              {achievement.title}
                            </div>
                            <div className="text-xs text-gray-600">
                              {achievement.description}
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Active Power Ups Section */}
                {renderActivePowerUps()}

                {/* Learning Streak Section */}
                {userStats?.current_streak > 0 && (
                  <div className="mb-3">
                    <h3 className="text-lg font-bold mb-2 flex items-center">
                      <span className="mr-2">🔥</span>
                      Learning Streak
                    </h3>
                    <div className="p-3 bg-white border-2 border-b-4 border-gold rounded">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="text-xl">🔥</div>
                        <div className="text-left">
                          <div className="font-bold">
                            {userStats.current_streak} Day Streak
                          </div>
                          <div className="text-xs text-gray">
                            Keep it going!
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray h-3 border-darkgray border rounded-full overflow-hidden">
                        <div
                          className="bg-green h-full rounded-full"
                          style={{
                            width: `${Math.min(
                              100,
                              ((userStats.current_streak % 7) / 7) * 100
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}

                {/* We'll keep empty space here if there are no achievements or streaks */}
                {!achievements.length && !userStats?.current_streak && (
                  <div className="p-3 bg-white border-2 border-b-4 border-darkgray rounded mb-3">
                    <div className="flex items-center justify-center p-3">
                      <div className="text-center">
                        <div className="text-2xl mb-2">⭐️</div>
                        <p className="font-medium">
                          Keep learning to earn achievements!
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Action buttons - always at the bottom, full width */}
            <div className="grid grid-cols-2 gap-3 mt-3">
              <button
                onClick={handleReview}
                className="flex flex-col items-center border-b-4 justify-center p-3 border-2 border-gold text-gold font-bold rounded hover:bg-gold hover:bg-opacity-10"
              >
                <span className="mb-1 text-xl">📊</span>
                <span>Review</span>
              </button>

              <button
                onClick={handleProgression}
                className="flex flex-col items-center justify-center p-3 border-black border-b-4 border-2 bg-green text-white font-bold rounded hover:bg-opacity-90"
              >
                <span className="text-xl mb-1">✅</span>
                <span>Continue</span>
              </button>
            </div>
          </motion.div>

          {/* Review Modal */}
          {showReviewModal && analyticsData && (
            <LessonReviewModal
              analyticsData={analyticsData}
              onClose={() => setShowReviewModal(false)}
            />
          )}
        </div>
      )}
    </motion.div>
  );
};

export default ExerciseSummary;
