// EarlyAccessOnboarding.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";

// Import custom components
import OnboardingVideo from "./onboarding-video";
import OnboardingTimeline from "./onboarding-timeline";
import OnboardingNDA from "./onboarding-nda";
import OnboardingTerms from "./onboarding-terms";
import OnboardingComplete from "./onboarding-complete";

const EarlyAccessOnboarding = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState({
    nda: false,
    terms: false,
  });

  // Save progress to localStorage
  useEffect(() => {
    const savedProgress = localStorage.getItem("earlyAccessOnboardingProgress");
    if (savedProgress) {
      setCurrentStep(parseInt(savedProgress));
    }
  }, []);

  // Update localStorage when step changes
  useEffect(() => {
    localStorage.setItem(
      "earlyAccessOnboardingProgress",
      currentStep.toString()
    );
  }, [currentStep]);

  const steps = [
    {
      id: "welcome-video",
      title: "Welcome to Fast Fluency Africa Early Access",
      component: <OnboardingVideo onComplete={() => handleNext()} />,
    },
    {
      id: "timeline",
      title: "Early Access Program Timeline",
      component: <OnboardingTimeline onComplete={() => handleNext()} />,
    },
    {
      id: "nda",
      title: "Non-Disclosure Agreement",
      component: (
        <OnboardingNDA
          agreement={agreement.nda}
          onAgree={(agreed) => handleAgreement("nda", agreed)}
          onComplete={() => handleNext()}
        />
      ),
    },
    {
      id: "terms",
      title: "Terms & Conditions",
      component: (
        <OnboardingTerms
          agreement={agreement.terms}
          onAgree={(agreed) => handleAgreement("terms", agreed)}
          onComplete={() => handleNext()}
        />
      ),
    },
    {
      id: "complete",
      title: "You're All Set!",
      component: (
        <OnboardingComplete onComplete={() => handleCompletedOnboarding()} />
      ),
    },
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setLoading(true);
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
        setLoading(false);
      }, 500); // Small delay for transition effect
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setLoading(true);
      setTimeout(() => {
        setCurrentStep(currentStep - 1);
        setLoading(false);
      }, 500); // Small delay for transition effect
    }
  };

  const handleAgreement = (type, agreed) => {
    setAgreement({
      ...agreement,
      [type]: agreed,
    });
  };

  const handleCompletedOnboarding = () => {
    // Set a flag in localStorage to indicate onboarding completion
    localStorage.setItem("earlyAccessOnboardingCompleted", "true");
    // Redirect to dashboard
    navigate("/dashboard");
  };

  const currentStepData = steps[currentStep];
  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0;

  const progressPercentage = ((currentStep + 1) / steps.length) * 100;

  return (
    <div className="h-full flex flex-col bg-white">
      {/* Header */}
      <div className="bg-gradient-to-r from-red to-black text-white py-4 px-6">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <h1 className="text-2xl font-headers font-bold uppercase">
              Fast Fluency Africa
            </h1>
            <span className="ml-4 bg-gold text-white px-3 py-1 rounded-full text-sm font-bold">
              Early Access
            </span>
          </div>
        </div>
      </div>

      {/* Progress bar */}
      <div className="bg-white border-b border-gray">
        <div className="max-w-6xl mx-auto px-6 py-2">
          <div className="flex justify-between items-center text-sm font-body mb-2">
            <span className="font-bold">Onboarding Progress</span>
            <span>
              Step {currentStep + 1} of {steps.length}
            </span>
          </div>
          <div className="w-full bg-darkgray rounded-full h-3">
            <motion.div
              className="bg-green rounded-full h-3"
              initial={{ width: 0 }}
              animate={{ width: `${progressPercentage}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>
      </div>

      {/* Main content - Fixed height with vertical centering */}
      <div className="flex-grow bg-[#FCFCEE] overflow-y-auto flex items-center justify-center">
        <div className="w-5/6 max-w-4xl mx-auto px-6 py-8">
          <AnimatePresence mode="wait">
            {loading ? (
              <motion.div
                key="loader"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex justify-center items-center h-48"
              >
                <BeatLoader color="#991616" loading={loading} size={15} />
              </motion.div>
            ) : (
              <motion.div
                key={currentStepData.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <div className="bg-white rounded-lg border-2 border-b-4 border-gold shadow-lg p-6 mb-6">
                  <h2 className="text-2xl font-headers font-bold mb-6 text-center">
                    {currentStepData.title}
                  </h2>
                  {currentStepData.component}
                </div>

                {/* Navigation buttons */}
                <div className="flex justify-between mt-8">
                  <button
                    onClick={handlePrevious}
                    disabled={isFirstStep}
                    className={`px-6 py-2 border-2 rounded text-center
                      ${
                        isFirstStep
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }
                      text-black font-bold border-black hover:border-black hover:bg-black hover:text-white
                      text-sm md:text-base
                      uppercase
                      shadow-md
                      hover:shadow-lg
                      transition
                      duration-150
                      transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4`}
                  >
                    {isFirstStep ? "Start" : "Previous"}
                  </button>

                  {!isLastStep && (
                    <button
                      onClick={handleNext}
                      disabled={
                        (currentStep === 2 && !agreement.nda) ||
                        (currentStep === 3 && !agreement.terms)
                      }
                      className={`px-6 py-2 border-2 rounded text-center
                        ${
                          (currentStep === 2 && !agreement.nda) ||
                          (currentStep === 3 && !agreement.terms)
                            ? "opacity-50 cursor-not-allowed"
                            : "cursor-pointer"
                        }
                        text-green font-bold border-green hover:border-green hover:bg-green hover:text-white
                        text-sm md:text-base
                        uppercase
                        shadow-md
                        hover:shadow-lg
                        transition
                        duration-150
                        transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4`}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default EarlyAccessOnboarding;
