// src/components/common/audio-word.component.jsx
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";

const AudioWord = ({
  text,
  audioUrl,
  className = "",
  isPlaying = false,
  audioEnabled: propAudioEnabled,
  onPlay = () => {},
}) => {
  // If audioEnabled is passed as a prop, use it, otherwise use the global setting
  const { audioEnabled: contextAudioEnabled } = useAudioSettings();
  const audioEnabled =
    propAudioEnabled !== undefined ? propAudioEnabled : contextAudioEnabled;

  return (
    <div
      className={`audio-word relative inline-flex items-center ${className}`}
      onMouseEnter={() => {
        if (audioUrl && audioEnabled) {
          onPlay();
        }
      }}
    >
      <span className="relative">
        {text}
        {audioUrl && (
          <div
            className={`absolute -right-4 top-0 text-xs ${
              audioEnabled ? "text-green" : "text-gray-400"
            }`}
            title={audioEnabled ? "Has audio" : "Audio disabled"}
          >
            {isPlaying ? "🔊" : audioEnabled ? "🔈" : "🔇"}
          </div>
        )}
      </span>
    </div>
  );
};

AudioWord.propTypes = {
  text: PropTypes.string.isRequired,
  audioUrl: PropTypes.string,
  className: PropTypes.string,
  isPlaying: PropTypes.bool,
  audioEnabled: PropTypes.bool,
  onPlay: PropTypes.func,
};

export default AudioWord;
