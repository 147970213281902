// quizHelpers.js
// import { playSound } from "./audioHelpers"; // Optional: Separate audio handling

export const checkQuestion = ({
  currentExercise,
  optionChosen,
  isCorrect: providedIsCorrect, // Accept explicitly provided isCorrect
  setFeedback,
  setScore,
  deductAttempt,
  setOptionChosen,
}) => {
  // Use provided isCorrect if available, otherwise determine it here
  const isCorrect =
    providedIsCorrect !== undefined
      ? providedIsCorrect
      : currentExercise.correctAnswer === optionChosen;

  // Log for debugging
  console.log("checkQuestion:", {
    providedIsCorrect,
    calculatedIsCorrect: currentExercise.correctAnswer === optionChosen,
    finalIsCorrect: isCorrect,
  });

  // Update score if answer is correct
  if (isCorrect) {
    setScore((prev) => prev + 1);
    setFeedback("correct");
  } else {
    // Deduct an attempt for wrong answer
    deductAttempt();
    setFeedback("incorrect");
  }

  // Clear the currently selected option
  setOptionChosen(null);
};

export const skipQuestion = ({
  feedback,
  deductAttempt,
  setFeedback,
  setOptionChosen,
}) => {
  if (feedback !== "incorrect") {
    deductAttempt().catch(console.error);
  }
  setFeedback("incorrect");
  //   playSound("incorrect");
  setOptionChosen(null);
};

export const nextExercise = ({
  exerciseIndex,
  learningMaterial,
  setExerciseIndex,
  setLearningMaterialState,
  setExercisesCompleted,
  handleLessonCompletion,
}) => {
  const hasMoreExercises = exerciseIndex < learningMaterial.length - 1;

  if (hasMoreExercises) {
    const nextIndex = exerciseIndex + 1;
    setExerciseIndex(nextIndex);
    // setLearningMaterialState(learningMaterial[nextIndex].type);
    setExercisesCompleted((prev) => prev + 1);

    // Optional: Scroll to top for new exercise
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    handleLessonCompletion();
    setLearningMaterialState("complete");

    // Optional: Track completion analytics
    // trackAnalyticsEvent('lesson_completed');
  }
};

export const handleAnswer = ({
  setFeedback,
  setOptionChosen,
  handleNextExercise, // Add this parameter
}) => {
  // Reset UI states
  setFeedback(null);
  setOptionChosen(null);

  // Trigger progression logic
  handleNextExercise();
};

export const handleContinue = ({ setLoading, handleAnswer }) => {
  setLoading(true);
  setTimeout(() => {
    setLoading(false);
    handleAnswer(); // Now triggers full progression flow
  }, 1000);
};

export const checkStreakProtection = async (userId) => {
  if (!userId) return { isProtected: false };

  try {
    // Import PowerUpUtils dynamically to avoid circular dependencies
    const PowerUpUtils = (await import("../services/power-ups/power-up-utils"))
      .default;
    return await PowerUpUtils.checkStreakProtection(userId);
  } catch (error) {
    console.error("Error checking streak protection:", error);
    return { isProtected: false };
  }
};

export const openModal = (setModal) => {
  setModal(true);
  document.body.style.overflow = "hidden";
};
