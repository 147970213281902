import React from "react";

const AnswerButtons = ({ onSkip, onCheck, optionChosen, hidden }) => (
  <div
    id="answer-responses"
    className="border-t-2 border-darkgray w-full h-[70px] sm:h-[90px] flex items-center"
  >
    <div
      className={
        hidden
          ? "hidden"
          : "flex flex-row justify-between w-full sm:w-2/3 m-auto px-2 sm:px-0"
      }
    >
      <div>
        <input
          type="button"
          value="SKIP"
          className="capitalize cursor-pointer bg-white text-sm sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-1 pb-1 sm:pt-2 sm:pb-2 pl-3 pr-3 sm:pl-4 sm:pr-4 font-body text-center px-2 py-3 sm:py-4 m-2 border-2 text-gray font-bold hover:text-gray border-gray hover:border-darkgray hover:bg-darkgray rounded transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
          onClick={onSkip}
        />
      </div>
      <div>
        <input
          disabled={optionChosen === null}
          type="button"
          className={
            optionChosen === null
              ? "capitalize cursor-not-allowed bg-darkgray text-sm sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-1 pb-1 sm:pt-2 sm:pb-2 pl-3 pr-3 sm:pl-4 sm:pr-4 font-body text-center px-2 py-3 sm:px-4 sm:py-4 m-2 border-2 text-gray font-bold hover:text-gray border-otherwhite rounded transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
              : "capitalize cursor-pointer bg-white text-sm sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-1 pb-1 sm:pt-2 sm:pb-2 pl-3 pr-3 sm:pl-4 sm:pr-4 font-body text-center px-2 py-3 sm:px-4 sm:py-4 m-2 border-2 text-green font-bold hover:text-white border-green hover:border-green hover:bg-green rounded transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
          }
          value="CHECK"
          onClick={onCheck}
        />
      </div>
    </div>
  </div>
);

export default AnswerButtons;
