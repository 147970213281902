import React, { useState, useEffect } from "react";
import { format, isValid, parseISO } from "date-fns";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import { completeSession, cancelBooking } from "../../../firebase/bookings-api";
import {
  Home,
  Calendar,
  User,
  Clock,
  CheckCircle,
  XCircle,
  RefreshCw,
} from "lucide-react";

// Helper function to safely format dates
const safeFormatDate = (date, formatPattern, fallback = "Date unavailable") => {
  try {
    // Handle different date types
    let dateObj = date;

    // If it's a Firestore timestamp with toDate method
    if (date && typeof date.toDate === "function") {
      dateObj = date.toDate();
    }
    // If it's a string, try to parse it
    else if (typeof date === "string") {
      dateObj = parseISO(date);
    }
    // If it's already a date, use as is

    // Only format if we have a valid date
    if (dateObj && isValid(dateObj)) {
      return format(dateObj, formatPattern);
    }

    return fallback;
  } catch (error) {
    console.error("Date formatting error:", error, "Date value was:", date);
    return fallback;
  }
};

const CoachDashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchBookings();
  }, [activeTab]);

  const fetchBookings = async () => {
    try {
      setLoading(true);
      setError(null);

      const userId = auth.currentUser?.uid;
      if (!userId) {
        setLoading(false);
        return;
      }

      const bookingsRef = collection(db, "bookings");
      const q = query(
        bookingsRef,
        where("coachId", "==", userId),
        where("status", "==", activeTab)
      );

      const querySnapshot = await getDocs(q);

      const bookingsList = [];

      for (const docSnap of querySnapshot.docs) {
        try {
          const bookingData = docSnap.data();

          // Extract and safely convert the session date time
          let sessionDateTime = null;
          let sessionDate = "Date unavailable";
          let sessionTime = "Time unavailable";

          if (bookingData.sessionDateTime) {
            try {
              // Handle Firestore timestamp
              if (typeof bookingData.sessionDateTime.toDate === "function") {
                sessionDateTime = bookingData.sessionDateTime.toDate();
              }
              // Handle string date
              else if (typeof bookingData.sessionDateTime === "string") {
                sessionDateTime = new Date(bookingData.sessionDateTime);
              }
              // Already a Date object
              else if (bookingData.sessionDateTime instanceof Date) {
                sessionDateTime = bookingData.sessionDateTime;
              }

              // Check if the date is valid before formatting
              if (sessionDateTime && isValid(sessionDateTime)) {
                sessionDate = format(sessionDateTime, "MMMM d, yyyy");
                sessionTime = format(sessionDateTime, "h:mm a");
              } else {
                console.warn(
                  `Invalid date for booking ${docSnap.id}:`,
                  bookingData.sessionDateTime
                );
              }
            } catch (dateError) {
              console.error("Date processing error:", dateError);
            }
          }

          // Get student information
          let studentData = null;
          try {
            if (bookingData.studentId) {
              const studentRef = doc(db, "users", bookingData.studentId);
              const studentSnap = await getDoc(studentRef);

              if (studentSnap.exists()) {
                studentData = studentSnap.data();

                // If the booking doesn't have a student name already, add it from the user data
                if (!bookingData.studentName) {
                  bookingData.studentName =
                    studentData.name || studentData.displayName;
                }
              }
            }
          } catch (studentError) {
            console.error("Error fetching student data:", studentError);
          }

          // Add processed booking to list with safe data
          bookingsList.push({
            id: docSnap.id,
            ...bookingData,
            student: studentData,
            // Use pre-formatted strings for display
            sessionDate,
            sessionTime,
            // Preserve the actual date object for sorting if valid
            sessionDateTime: isValid(sessionDateTime) ? sessionDateTime : null,
            // Set user role for proper display in UI
            userRole: "coach",
          });
        } catch (bookingError) {
          console.error(
            `Error processing booking ${docSnap.id}:`,
            bookingError
          );
        }
      }

      // Sort bookings by date (handling null dates)
      bookingsList.sort((a, b) => {
        if (!a.sessionDateTime) return 1; // Null dates go last
        if (!b.sessionDateTime) return -1;
        return a.sessionDateTime - b.sessionDateTime;
      });

      setBookings(bookingsList);
    } catch (error) {
      console.error("Error fetching coach bookings:", error);
      setError("Failed to load bookings. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchBookings();
    setTimeout(() => {
      setRefreshing(false);
    }, 500); // Show the animation for visual feedback
  };

  const getStudentName = (booking) => {
    // Check all possible name sources with fallbacks
    return (
      booking.studentName || // Direct from booking if available
      booking.student?.name || // From fetched user data
      booking.student?.displayName || // Alternative field name
      "Student" // Generic fallback
    );
  };

  const handleCancelSession = async (bookingId) => {
    if (!window.confirm("Are you sure you want to cancel this session?")) {
      return;
    }

    try {
      setLoading(true);
      await cancelBooking(bookingId);
      await fetchBookings();
      alert("Session cancelled successfully");
    } catch (error) {
      console.error("Error cancelling session:", error);
      alert("There was an error cancelling the session: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteSession = async (bookingId) => {
    if (!window.confirm("Mark this session as completed?")) {
      return;
    }

    try {
      setLoading(true);
      await completeSession(bookingId);
      // After completing, switch to completed tab to see the result
      setActiveTab("completed");
      await fetchBookings();
      alert("Session marked as completed");
    } catch (error) {
      console.error("Error completing session:", error);
      alert("There was an error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Render bookings directly for more control over date handling
  const renderBookingsList = () => {
    if (loading) {
      return (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green"></div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-center py-8 text-red">
          <p>{error}</p>
          <button
            onClick={fetchBookings}
            className="mt-4 px-4 py-2 bg-green text-white rounded-md"
          >
            Try Again
          </button>
        </div>
      );
    }

    if (bookings.length === 0) {
      return (
        <div className="text-center py-8 text-gray-500">
          You have no {activeTab} sessions.
          {activeTab === "upcoming" && (
            <div className="mt-4">
              <a
                href="/booking/availability"
                className="inline-block px-4 py-2 bg-green text-black border-2 border-b-4 border-black rounded-md hover:bg-opacity-90"
              >
                <Calendar size={18} className="inline mr-2" />
                Manage Availability
              </a>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {bookings.map((booking) => (
          <div
            key={booking.id}
            className="border-2 border-b-4 border-darkgray p-3 sm:p-4 rounded"
          >
            <div className="flex flex-wrap items-start justify-between mb-3 gap-2">
              <div className="flex items-center">
                <span className="mr-2 flex-shrink-0">👤</span>
                <span className="font-medium text-sm sm:text-base truncate max-w-[150px] sm:max-w-none">
                  {getStudentName(booking)}
                </span>
              </div>
              <span className="inline-block px-2 py-1 rounded-full text-xs bg-green bg-opacity-20 text-green">
                {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
              </span>
            </div>

            <div className="flex items-center text-gray-600 mb-2">
              <span className="mr-2 flex-shrink-0">🗓️</span>
              <span className="text-sm sm:text-base">
                {booking.sessionDate}
              </span>
            </div>

            <div className="flex items-center text-gray-600 mb-4">
              <span className="mr-2 flex-shrink-0">🕣</span>
              <span className="text-sm sm:text-base">
                {booking.sessionTime}
              </span>
              <span className="mx-1">•</span>
              <span className="text-sm sm:text-base">
                {booking.duration || 0} minutes
              </span>
            </div>

            {/* Action buttons for upcoming sessions only */}
            {booking.meetingStartUrl && activeTab === "upcoming" && (
              <a
                href={booking.meetingStartUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full mt-4 px-4 py-2 bg-gold border-black border-b-4 border-2 text-black text-center rounded-md hover:bg-opacity-80"
              >
                Start Session
              </a>
            )}

            {activeTab === "upcoming" && (
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
                <button
                  onClick={() => handleCompleteSession(booking.id)}
                  className="flex-1 flex items-center justify-center border-black px-3 py-2 bg-green rounded-md text-black border-2 border-b-4 hover:bg-opacity-80"
                >
                  <span className="mr-1">✅</span>
                  Complete
                </button>

                <button
                  onClick={() => handleCancelSession(booking.id)}
                  className="flex-1 flex items-center justify-center px-3 py-2 border-red rounded-md text-red border-2 border-b-4 hover:bg-red hover:bg-opacity-10"
                >
                  <span className="mr-1">❌</span>
                  Cancel
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <div className="mb-4 sm:mb-8">
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2 sm:mb-4">
          Coach Dashboard
        </h1>
        <p className="text-gray-600 text-sm sm:text-base">
          Manage your coaching sessions and availability.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-8">
        {/* Dashboard Summary - First on mobile, third on desktop */}
        <div className="order-first md:order-last">
          <div className="bg-white border-2 border-b-4 border-darkgray shadow-md p-4">
            <h2 className="text-lg sm:text-xl font-bold text-gray-800 mb-4">
              Dashboard Summary
            </h2>

            <div className="grid grid-cols-2 gap-4">
              <div className="border-2 border-b-4 rounded border-darkgray p-4 text-center">
                <div className="text-2xl sm:text-3xl font-bold text-green mb-1">
                  {bookings.length}
                </div>
                <div className="text-xs sm:text-sm text-gray-600">
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{" "}
                  Sessions
                </div>
              </div>

              <div className="border-2 border-b-4 rounded border-darkgray p-4 text-center">
                <div className="text-2xl sm:text-3xl font-bold text-gold mb-1">
                  {bookings.reduce(
                    (total, booking) => total + (booking.duration || 0),
                    0
                  )}
                </div>
                <div className="text-xs sm:text-sm text-gray-600">
                  Total Minutes
                </div>
              </div>
            </div>

            <div className="mt-4">
              <button
                onClick={handleRefresh}
                className={`w-full flex items-center justify-center px-4 py-2 bg-green bg-opacity-10 text-green rounded border-green border-2 border-b-4 hover:bg-opacity-20`}
                disabled={refreshing}
              >
                <RefreshCw
                  size={18}
                  className={`mr-2 ${refreshing ? "animate-spin" : ""}`}
                />
                {refreshing ? "Refreshing..." : "Refresh Dashboard"}
              </button>
            </div>

            <div className="mt-4">
              <a
                href="/booking/availability"
                className="block w-full px-4 py-2 bg-green text-black rounded text-center border-2 border-b-4 border-black hover:bg-opacity-90"
              >
                Manage Availability
              </a>
            </div>

            <div className="mt-4">
              <a
                href="/booking/earnings"
                className="block w-full px-4 py-2 bg-gold text-black rounded text-center border-2 border-b-4 border-black hover:bg-opacity-90"
              >
                View Earnings
              </a>
            </div>
          </div>
        </div>

        {/* Session List - Second on mobile and spans 2 columns on desktop */}
        <div className="md:col-span-2 order-last md:order-first">
          {/* Session Tabs */}
          <div className="bg-white border-2 border-b-4 border-darkgray shadow-md">
            <div className="flex border-b overflow-x-auto">
              <button
                className={`px-3 sm:px-4 py-3 font-medium text-xs sm:text-sm flex-1 whitespace-nowrap ${
                  activeTab === "upcoming"
                    ? "border-b-2 border-green text-green"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab("upcoming")}
              >
                Upcoming Sessions
              </button>
              <button
                className={`px-3 sm:px-4 py-3 font-medium text-xs sm:text-sm flex-1 whitespace-nowrap ${
                  activeTab === "completed"
                    ? "border-b-2 border-green text-green"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab("completed")}
              >
                Completed Sessions
              </button>
              <button
                className={`px-3 sm:px-4 py-3 font-medium text-xs sm:text-sm flex-1 whitespace-nowrap ${
                  activeTab === "cancelled"
                    ? "border-b-2 border-green text-green"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab("cancelled")}
              >
                Cancelled Sessions
              </button>
            </div>

            <div className="p-3 sm:p-4">{renderBookingsList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachDashboard;
