import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

import { motion } from "framer-motion";

function Privacy() {
  useEffect(() => {
    document.title = "Privacy Policy | Fast Fluency Africa";
  }, []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return loading ? (
    <div
      className="flex flex-row justify-center items-center h-screen"
      data-testid="puff-loader"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="bg-transparent mx-auto w-4/6 text-justify"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div>
        <strong>
          <span style={{ fontSize: 26 }}>
            <span className="font-headers text-2xl text-black">
              PRIVACY NOTICE
            </span>
          </span>
        </strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <strong>
            <span style={{ fontSize: 15 }}>
              <span className="font-sans text-base text-gray-600">
                <span>Last updated August 31, 2023</span>
              </span>
            </span>
          </strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              This privacy notice for{" "}
              <span className="question">Fast Fluency Africa</span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span className="block-component" />
                </span>
              </span>{" "}
              (doing business as{" "}
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span className="question">Fast Fluency)</span>
                  <span className="statement-end-if-in-editor" />
                </span>
              </span>{" "}
              (<span className="block-component" />'
              <span className="block-component" />
              <span className="question">
                <strong>Fast Fluency</strong>
              </span>
              <span className="else-block" />
              ', '<strong>we</strong>', '<strong>us</strong>', or '
              <strong>our</strong>',
              <span className="else-block" />
            </span>
            <span className="font-nunito text-base text-gray-600">
              ), describes how and why we might collect, store, use, and/or
              share (<span className="block-component" />'
              <strong>process</strong>'<span className="else-block" />) your
              information when you use our services (
              <span className="block-component" />'<strong>Services</strong>'
              <span className="else-block" />
              ), such as when you:
            </span>
          </span>
        </span>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span className="font-nunito text-base text-gray-600">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span
                className="font-nunito text-base text-gray-600"
                aria-label="visit-website"
              >
                Visit our website fastfluencyafrica.com , or any website of ours
                that links to this privacy notice
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <span className="block-component">
          <span style={{ fontSize: 15 }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span className="font-nunito text-base text-gray-600">
                <strong>Questions or concerns? </strong>Reading this privacy
                notice will help you understand your privacy rights and choices.
                If you do not agree with our policies and practices, please do
                not use our Services. If you still have any questions or
                concerns, please contact us at{" "}
                <span className="question">info@fastfluencyafrica.co.za</span>.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <strong>
            <span style={{ fontSize: 15 }}>
              <span className="font-body text-xl text-black">
                SUMMARY OF KEY POINTS
              </span>
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>
                <em>
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our
                  table of contents below to find the section you are looking
                  for. You can also click{" "}
                </em>
              </strong>
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#toc"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">
                <strong>
                  <em>here</em>
                </strong>
              </span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>
                <em> to go directly to our table of contents.</em>
              </strong>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with{" "}
              <span className="block-component" />
              <span className="question">Fast Fluency</span>
              <span className="else-block" /> and the Services, the choices you
              make, and the products and features you use. Click
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#personalinfo"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to learn more.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>Do we process any sensitive personal information?</strong>
              <span className="block-component" />
              We may process sensitive personal information when necessary with
              your consent or as otherwise permitted by applicable law. Click
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#sensitiveinfo"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to learn more.
              <span className="statement-end-if-in-editor" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>Do we receive any information from third parties?</strong>
              <span className="block-component" />
              We do not receive any information from third parties.
              <span className="else-block" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Click{" "}
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#infouse"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to learn more.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>
                In what situations and with which{" "}
                <span className="block-component" />
                parties do we share personal information?
              </strong>{" "}
              We may share information in specific situations and with specific{" "}
              <span className="block-component" />
              third parties. Click{" "}
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#whoshare"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to learn more.
              <span className="block-component" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>How do we keep your information safe?</strong> We have{" "}
              <span className="block-component" />
              organisational
              <span className="else-block" /> and technical processes and
              procedures in place to protect your personal information. However,
              no electronic transmission over the internet or information
              storage technology can be guaranteed to be 100% secure, so we
              cannot promise or guarantee that hackers, cybercriminals, or other{" "}
              <span className="block-component" />
              unauthorised
              <span className="else-block" /> third parties will not be able to
              defeat our security and improperly collect, access, steal, or
              modify your information. Click{" "}
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#infosafe"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to learn more.
              <span className="statement-end-if-in-editor" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information. Click{" "}
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#privacyrights"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to learn more.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by filling out our data subject request
              form available <span className="block-component" />
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="https://app.termly.io/notify/63e8bdad-b3df-42dd-aba1-87aabfd2816d"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <span className="block-component" />, or by contacting us. We will
              consider and act upon any request in accordance with applicable
              data protection laws.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              Want to learn more about what <span className="block-component" />
              <span className="question">Fast Fluency</span>
              <span className="else-block" /> does with any information we
              collect? Click
            </span>
          </span>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#toc"
          >
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              {" "}
              to review the notice in full.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="toc" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span className="font-body text-xl text-black">
                    TABLE OF CONTENTS
                  </span>
                </strong>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#infocollect"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#infouse"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                2. HOW DO WE PROCESS YOUR INFORMATION?
                <span className="block-component" />
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#legalbases"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                3.{" "}
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?
                  </span>
                </span>
                <span className="statement-end-if-in-editor" />
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a
                className="font-sans text-base text-blue-600 break-words"
                href="#whoshare"
              >
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </span>
            <span className="font-nunito text-base text-gray-600">
              <span className="block-component" />
            </span>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#cookies"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="statement-end-if-in-editor" />
                  </span>
                </span>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#sociallogins"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </span>
                </span>
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#inforetain"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#infosafe"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span className="statement-end-if-in-editor" />
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a
                className="font-sans text-base text-blue-600 break-words"
                href="#privacyrights"
              >
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#DNT"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#caresidents"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a
              className="font-sans text-base text-blue-600 break-words"
              href="#policyupdates"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                12. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#contact"
          >
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a
            className="font-sans text-base text-blue-600 break-words"
            href="#request"
          >
            <span style={{ color: "rgb(89, 89, 89)" }}>
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="infocollect" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span className="font-body text-xl text-black">
                        1. WHAT INFORMATION DO WE COLLECT?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="personalinfo" style={{ lineHeight: "1.5" }}>
          <span
            className="font-sans text-lg text-black"
            style={{ color: "rgb(0, 0, 0)" }}
          >
            <span style={{ fontSize: 15 }}>
              <strong>Personal information you disclose to us</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <strong>
                          <em>In Short:</em>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <strong>
                          <em> </em>
                        </strong>
                        <em>
                          We collect personal information that you provide to
                          us.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                We collect personal information that you voluntarily provide to
                us when you{" "}
                <span style={{ fontSize: 15 }}>
                  <span className="block-component" />
                </span>
                register on the Services,{" "}
              </span>
              <span style={{ fontSize: 15 }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="statement-end-if-in-editor" />
                  </span>
                </span>
                <span className="font-nunito text-base text-gray-600">
                  express an interest in obtaining information about us or our
                  products and Services, when you participate in activities on
                  the Services, or otherwise when you contact us.
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <strong>Personal Information Provided by You.</strong> The
                personal information that we collect depends on the context of
                your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">names</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">phone numbers</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">email addresses</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">mailing addresses</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">usernames</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">passwords</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">contact preferences</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">
                        contact or authentication data
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">
                        debit/credit card numbers
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">billing addresses</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">job titles</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">target language</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15 }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span className="question">home language</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="forloop-component" />
                  </span>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <strong>Sensitive Information.</strong>
              <span className="block-component" />
              When necessary, with your consent or as otherwise permitted by
              applicable law, we process the following categories of sensitive
              information:
              <span className="forloop-component" />
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="question">
                  information revealing race or ethnic origin
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <span className="forloop-component" />
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="question">student data</span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <span className="forloop-component" />
            </span>
            <span className="font-nunito text-base text-gray-600">
              <span className="statement-end-if-in-editor" />
            </span>
          </span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <strong>Payment Data.</strong> We may collect data necessary to
                process your payment if you make purchases, such as your payment
                instrument number (such as a credit card number), and the
                security code associated with your payment instrument. All
                payment data is stored by
                <span className="forloop-component" />
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span style={{ fontSize: 15 }}>
                        <span className="font-nunito text-base text-gray-600">
                          <span className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span className="question"> Payfast</span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span className="font-nunito text-base text-gray-600">
                            <span style={{ fontSize: 15 }}>
                              <span className="font-nunito text-base text-gray-600">
                                <span className="block-component" />
                              </span>
                            </span>
                          </span>
                          <span className="font-nunito text-base text-gray-600">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="font-nunito text-base text-gray-600">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span className="font-nunito text-base text-gray-600">
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span className="font-nunito text-base text-gray-600">
                                              <span className="forloop-component" />
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="block-component" />
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600"></span>
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="font-nunito text-base text-gray-600">
                                                                                <span className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        . You may find their
                                                        privacy notice link(s)
                                                        here:
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="forloop-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="question text-green underline">
                                                          <a
                                                            href="https://www.payfast.co.za/privacy-policy/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="font-sans text-base text-green underline break-words"
                                                          >
                                                            https://www.payfast.co.za/privacy-policy/
                                                          </a>
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span className="forloop-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="block-component" />{" "}
                                                                        and{" "}
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="question">
                                                                                <a
                                                                                  href="https://payfast.co.za/terms"
                                                                                  target="_blank"
                                                                                  rel="noreferrer"
                                                                                  className="font-sans text-base text-blue-600 break-words"
                                                                                >
                                                                                  https://payfast.co.za/terms
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span className="font-nunito text-base text-gray-600">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span className="font-nunito text-base text-gray-600">
                                                                                        <span className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="forloop-component" />
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span className="font-nunito text-base text-gray-600">
                                                                            .
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="statement-end-if-in-editor">
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <strong>Social Media Login Data. </strong>We may provide you
                with the option to register with us using your existing social
                media account details, like your Facebook, Twitter, or other
                social media account. If you choose to register in this way, we
                will collect the information described in the section called{" "}
                <span className="block-component" />'
                <span className="else-block" />
                <span style={{ fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <a
                          className="font-sans text-base text-blue-600 break-words"
                          href="#sociallogins"
                        >
                          HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <span className="block-component" />'
                <span className="else-block" /> below.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ fontSize: 15 }}>
                  <span className="statement-end-if-in-editor">
                    <span className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span className="block-component" />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span
            className="font-sans text-lg text-black"
            style={{ color: "rgb(0, 0, 0)" }}
          >
            <span style={{ fontSize: 15 }}>
              <strong>Information automatically collected</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <strong>
                          <em>In Short:</em>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <strong>
                          <em> </em>
                        </strong>
                        <em>
                          Some information — such as your Internet Protocol (IP)
                          address and/or browser and device characteristics — is
                          collected automatically when you visit our Services.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                Like many businesses, we also collect information through
                cookies and similar technologies.{" "}
                <span className="block-component" />
                <span className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="statement-end-if-in-editor">
                  <span className="font-nunito text-base text-gray-600" />
                </span>
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                The information we collect includes:
                <span className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <em>Log and Usage Data.</em> Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the Services
                  <span style={{ fontSize: 15 }}> </span>(such as the date/time
                  stamps associated with your usage, pages and files viewed,
                  searches, and other actions you take such as which features
                  you use), device event information (such as system activity,
                  error reports (sometimes called{" "}
                  <span className="block-component" />
                  'crash dumps'
                  <span className="else-block" />
                  ), and hardware settings).
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="block-component">
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600" />
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <em>Device Data.</em> We collect device data such as
                  information about your computer, phone, tablet, or other
                  device you use to access the Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span className="block-component">
            <span style={{ fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600" />
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <em>Location Data.</em> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <span className="block-component">
            <span style={{ fontSize: 15 }} />
          </span>
          <span className="statement-end-if-in-editor" />
          <span className="font-nunito text-base text-gray-600">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span className="font-nunito text-base text-gray-600">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="statement-end-if-in-editor">
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span className="font-nunito text-base text-gray-600">
              <span className="block-component" />
            </span>
          </span>
        </div>
        <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span className="font-body text-xl text-black">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span className="font-nunito text-base text-gray-600">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <strong>
                          <em>In Short: </em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <span className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.{" "}
                  </strong>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="font-nunito text-base text-gray-600">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span className="font-nunito text-base text-gray-600">
                                    <span className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span className="font-nunito text-base text-gray-600">
                <span className="block-component" />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span className="block-component" />
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="font-nunito text-base text-gray-600">
                    <strong>
                      To deliver and facilitate delivery of services to the
                      user.{" "}
                    </strong>
                    We may process your information to provide you with the
                    requested service.
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="font-nunito text-base text-gray-600">
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span className="font-nunito text-base text-gray-600">
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span className="font-nunito text-base text-gray-600">
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span className="font-nunito text-base text-gray-600">
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span className="font-nunito text-base text-gray-600">
                  <span className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="font-nunito text-base text-gray-600">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="font-nunito text-base text-gray-600">
                      <strong>
                        To respond to user inquiries/offer support to users.{" "}
                      </strong>
                      We may process your information to respond to your
                      inquiries and solve any potential issues you might have
                      with the requested service.
                      <span className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span className="block-component">
                <span style={{ fontSize: 15 }} />
              </span>
              <div style={{ lineHeight: "1.5" }}>
                <span className="block-component">
                  <span style={{ fontSize: 15 }} />
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="font-nunito text-base text-gray-600">
                        <strong>
                          To send administrative information to you.{" "}
                        </strong>
                        We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="font-nunito text-base text-gray-600">
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="font-nunito text-base text-gray-600">
                                    <span className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span className="block-component">
                  <span style={{ fontSize: 15 }} />
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span className="block-component">
                    <span style={{ fontSize: 15 }}>
                      <span className="font-nunito text-base text-gray-600" />
                    </span>
                  </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span className="font-nunito text-base text-gray-600">
                      <span style={{ fontSize: 15 }}>
                        <strong>
                          To <span className="block-component" />
                          fulfil
                          <span className="else-block" /> and manage your
                          orders.
                        </strong>{" "}
                        We may process your information to{" "}
                        <span className="block-component" />
                        fulfil
                        <span className="else-block" /> and manage your orders,
                        payments, returns, and exchanges made through the
                        Services.
                      </span>
                    </span>
                    <span className="statement-end-if-in-editor">
                      <span style={{ fontSize: 15 }}>
                        <span className="font-nunito text-base text-gray-600" />
                      </span>
                    </span>
                  </li>
                </ul>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <span className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </span>
                </p>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <span className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </span>
                </p>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span className="font-nunito text-base text-gray-600">
                            <strong>
                              To enable user-to-user communications.{" "}
                            </strong>
                            We may process your information if you choose to use
                            any of our offerings that allow for communication
                            with another user.
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="font-nunito text-base text-gray-600">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span className="font-nunito text-base text-gray-600">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span className="font-nunito text-base text-gray-600">
                                          <span className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <span className="block-component" />
                </p>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <span className="block-component" />
                </p>
                <div style={{ lineHeight: "1.5" }}>
                  <span className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <span className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <span className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </span>
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span className="block-component">
                            <span className="font-nunito text-base text-gray-600" />
                          </span>
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                          <span className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span className="font-nunito text-base text-gray-600" />
                            </span>
                          </span>
                          <div style={{ lineHeight: "1.5" }}>
                            <span className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span className="font-nunito text-base text-gray-600" />
                              </span>
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                              <span className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span className="font-nunito text-base text-gray-600" />
                                </span>
                              </span>
                              <div style={{ lineHeight: "1.5" }}>
                                <span className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span className="font-nunito text-base text-gray-600" />
                                  </span>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                  <span className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span className="font-nunito text-base text-gray-600" />
                                    </span>
                                  </span>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span className="font-nunito text-base text-gray-600" />
                                      </span>
                                    </span>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <span className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span className="font-nunito text-base text-gray-600" />
                                        </span>
                                      </span>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span className="font-nunito text-base text-gray-600" />
                                          </span>
                                        </span>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span className="font-nunito text-base text-gray-600" />
                                            </span>
                                          </span>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600" />
                                              </span>
                                            </span>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <strong>
                                                      To save or protect an
                                                      individual's vital
                                                      interest.
                                                    </strong>{" "}
                                                    We may process your
                                                    information when necessary
                                                    to save or protect an
                                                    individual’s vital interest,
                                                    such as to prevent harm.
                                                  </span>
                                                </span>
                                                <span className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div
                                              id="legalbases"
                                              style={{ lineHeight: "1.5" }}
                                            >
                                              <strong>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-body text-xl text-black">
                                                    3. WHAT LEGAL BASES DO WE
                                                    RELY ON TO PROCESS YOUR
                                                    INFORMATION?
                                                  </span>
                                                </span>
                                              </strong>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <em>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <strong>In Short: </strong>
                                                    We only process your
                                                    personal information when we
                                                    believe it is necessary and
                                                    we have a valid legal reason
                                                    (i.e.
                                                    <span className="block-component" />{" "}
                                                    legal basis) to do so under
                                                    applicable law, like with
                                                    your consent, to comply with
                                                    laws, to provide you with
                                                    services to enter into or{" "}
                                                    <span className="block-component" />
                                                    fulfil
                                                    <span className="else-block" />{" "}
                                                    our contractual obligations,
                                                    to protect your rights, or
                                                    to{" "}
                                                    <span className="block-component" />
                                                    fulfil
                                                    <span className="else-block" />{" "}
                                                    our legitimate business
                                                    interests.
                                                  </span>
                                                </span>
                                              </em>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span className="block-component" />
                                                </span>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <em>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <strong>
                                                      <u>
                                                        If you are located in
                                                        the EU or UK, this
                                                        section applies to you.
                                                      </u>
                                                    </strong>
                                                  </span>
                                                </span>
                                              </em>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  The General Data Protection
                                                  Regulation (GDPR) and UK GDPR
                                                  require us to explain the
                                                  valid legal bases we rely on
                                                  in order to process your
                                                  personal information. As such,
                                                  we may rely on the following
                                                  legal bases to process your
                                                  personal information:
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <strong>Consent. </strong>We
                                                    may process your information
                                                    if you have given us
                                                    permission (i.e.
                                                    <span className="block-component" />{" "}
                                                    consent) to use your
                                                    personal information for a
                                                    specific purpose. You can
                                                    withdraw your consent at any
                                                    time. Click{" "}
                                                  </span>
                                                </span>
                                                <a
                                                  className="font-sans text-base text-blue-600 break-words"
                                                  href="#withdrawconsent"
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600">
                                                      here
                                                    </span>
                                                  </span>
                                                </a>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    {" "}
                                                    to learn more.
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <strong>
                                                      Performance of a Contract.
                                                    </strong>{" "}
                                                    We may process your personal
                                                    information when we believe
                                                    it is necessary to{" "}
                                                    <span className="block-component" />
                                                    fulfil
                                                    <span className="else-block" />{" "}
                                                    our contractual obligations
                                                    to you, including providing
                                                    our Services or at your
                                                    request prior to entering
                                                    into a contract with you.
                                                  </span>
                                                </span>
                                                <span className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <strong>
                                                      Legal Obligations.
                                                    </strong>{" "}
                                                    We may process your
                                                    information where we believe
                                                    it is necessary for
                                                    compliance with our legal
                                                    obligations, such as to
                                                    cooperate with a law
                                                    enforcement body or
                                                    regulatory agency, exercise
                                                    or defend our legal rights,
                                                    or disclose your information
                                                    as evidence in litigation in
                                                    which we are involved.
                                                    <span className="statement-end-if-in-editor" />
                                                    <br />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <strong>
                                                      Vital Interests.
                                                    </strong>{" "}
                                                    We may process your
                                                    information where we believe
                                                    it is necessary to protect
                                                    your vital interests or the
                                                    vital interests of a third
                                                    party, such as situations
                                                    involving potential threats
                                                    to the safety of any person.
                                                  </span>
                                                </span>
                                                <span className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <u>
                                                      <em>
                                                        If you are located in
                                                        Canada, this section
                                                        applies to you.
                                                      </em>
                                                    </u>
                                                  </strong>
                                                  <span className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  We may process your
                                                  information if you have given
                                                  us specific permission (i.e.
                                                  <span className="block-component" />{" "}
                                                  express consent) to use your
                                                  personal information for a
                                                  specific purpose, or in
                                                  situations where your
                                                  permission can be inferred
                                                  (i.e.
                                                  <span className="block-component" />{" "}
                                                  implied consent). You can
                                                  withdraw your consent at any
                                                  time. Click{" "}
                                                </span>
                                              </span>
                                              <a
                                                className="font-sans text-base text-blue-600 break-words"
                                                href="#withdrawconsent"
                                              >
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    here
                                                  </span>
                                                </span>
                                              </a>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  {" "}
                                                  to learn more.
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  In some exceptional cases, we
                                                  may be legally permitted under
                                                  applicable law to process your
                                                  information without your
                                                  consent, including, for
                                                  example:
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If collection is clearly in
                                                    the interests of an
                                                    individual and consent
                                                    cannot be obtained in a
                                                    timely way
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    For investigations and fraud
                                                    detection and prevention
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    For business transactions
                                                    provided certain conditions
                                                    are met
                                                  </span>
                                                </span>
                                                <span className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If it is contained in a
                                                    witness statement and the
                                                    collection is necessary to
                                                    assess, process, or settle
                                                    an insurance claim
                                                  </span>
                                                </span>
                                                <span className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    For identifying injured,
                                                    ill, or deceased persons and
                                                    communicating with next of
                                                    kin
                                                  </span>
                                                </span>
                                                <span className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If we have reasonable
                                                    grounds to believe an
                                                    individual has been, is, or
                                                    may be victim of financial
                                                    abuse
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If it is reasonable to
                                                    expect collection and use
                                                    with consent would
                                                    compromise the availability
                                                    or the accuracy of the
                                                    information and the
                                                    collection is reasonable for
                                                    purposes related to
                                                    investigating a breach of an
                                                    agreement or a contravention
                                                    of the laws of Canada or a
                                                    province
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="font-nunito text-base text-gray-600">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If disclosure is required to
                                                    comply with a subpoena,
                                                    warrant, court order, or
                                                    rules of the court relating
                                                    to the production of records
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    If it was produced by an
                                                    individual in the course of
                                                    their employment, business,
                                                    or profession and the
                                                    collection is consistent
                                                    with the purposes for which
                                                    the information was produced
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    If the collection is solely
                                                    for journalistic, artistic,
                                                    or literary purposes
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    If the information is
                                                    publicly available and is
                                                    specified by the regulations
                                                  </span>
                                                  <span className="statement-end-if-in-editor">
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="statement-end-if-in-editor">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <span className="statement-end-if-in-editor">
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div
                                              id="whoshare"
                                              style={{ lineHeight: "1.5" }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(127, 127, 127)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        id="control"
                                                        style={{
                                                          color: "rgb(0, 0, 0)",
                                                        }}
                                                      >
                                                        <strong>
                                                          <span className="font-body text-xl text-black">
                                                            4. WHEN AND WITH
                                                            WHOM DO WE SHARE
                                                            YOUR PERSONAL
                                                            INFORMATION?
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <strong>
                                                      <em>In Short:</em>
                                                    </strong>
                                                    <em>
                                                      {" "}
                                                      We may share information
                                                      in specific situations
                                                      described in this section
                                                      and/or with the following{" "}
                                                      <span className="block-component" />
                                                      third parties.
                                                    </em>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <span className="block-component"></span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  We{" "}
                                                  <span className="block-component" />
                                                  may need to share your
                                                  personal information in the
                                                  following situations:
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <strong>
                                                      Business Transfers.
                                                    </strong>{" "}
                                                    We may share or transfer
                                                    your information in
                                                    connection with, or during
                                                    negotiations of, any merger,
                                                    sale of company assets,
                                                    financing, or acquisition of
                                                    all or a portion of our
                                                    business to another company.
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="font-nunito text-base text-gray-600">
                                                  <span className="block-component" />
                                                </span>
                                              </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    <strong>
                                                      When we use Google Maps
                                                      Platform APIs.
                                                    </strong>{" "}
                                                    We may share your
                                                    information with certain
                                                    Google Maps Platform APIs
                                                    (e.g.
                                                    <span className="block-component" />{" "}
                                                    Google Maps API, Places
                                                    API). To find out more about
                                                    Google’s Privacy Policy,
                                                    please refer to this{" "}
                                                  </span>
                                                </span>
                                                <a
                                                  className="font-sans text-base text-blue-600 break-words"
                                                  href="https://policies.google.com/privacy"
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600">
                                                      link
                                                    </span>
                                                  </span>
                                                </a>
                                                <span style={{ fontSize: 15 }}>
                                                  <span className="font-nunito text-base text-gray-600">
                                                    .
                                                    <span className="block-component" />
                                                    <span className="block-component" />{" "}
                                                    We obtain and store on your
                                                    device (
                                                    <span className="block-component" />
                                                    'cache'
                                                    <span className="else-block" />
                                                    ) your location
                                                    <span className="block-component" />{" "}
                                                    for{" "}
                                                    <span className="question">
                                                      three (3)
                                                    </span>{" "}
                                                    months
                                                    <span className="statement-end-if-in-editor" />
                                                    . You may revoke your
                                                    consent anytime by
                                                    contacting us at the contact
                                                    details provided at the end
                                                    of this document.
                                                    <span className="statement-end-if-in-editor" />
                                                    <span className="block-component" />
                                                  </span>
                                                </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span style={{ fontSize: 15 }}>
                                                <span className="block-component">
                                                  <span className="font-nunito text-base text-gray-600" />
                                                </span>
                                              </span>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600" />
                                                  </span>
                                                </span>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600" />
                                                    </span>
                                                  </span>
                                                </div>
                                                <ul>
                                                  <li
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <strong>
                                                          Other Users.
                                                        </strong>{" "}
                                                        When you share personal
                                                        information
                                                        <span className="block-component" />{" "}
                                                        (for example, by posting
                                                        comments, contributions,
                                                        or other content to the
                                                        Services)
                                                        <span className="statement-end-if-in-editor" />{" "}
                                                        or otherwise interact
                                                        with public areas of the
                                                        Services, such personal
                                                        information may be
                                                        viewed by all users and
                                                        may be publicly made
                                                        available outside the
                                                        Services in perpetuity.
                                                        <span className="block-component" />{" "}
                                                        If you interact with
                                                        other users of our
                                                        Services and register
                                                        for our Services through
                                                        a social network (such
                                                        as Facebook), your
                                                        contacts on the social
                                                        network will see your
                                                        name, profile photo, and
                                                        descriptions of your
                                                        activity.
                                                        <span className="statement-end-if-in-editor" />{" "}
                                                        Similarly, other users
                                                        will be able to view
                                                        descriptions of your
                                                        activity, communicate
                                                        with you within our
                                                        Services, and view your
                                                        profile.
                                                        <span className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600" />
                                                    </span>
                                                  </span>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600" />
                                                      </span>
                                                    </span>
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="block-component">
                                                                <span className="font-body text-xl text-black" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="cookies"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  5. DO WE USE
                                                                  COOKIES AND
                                                                  OTHER TRACKING
                                                                  TECHNOLOGIES?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            {" "}
                                                            We may use cookies
                                                            and other tracking
                                                            technologies to
                                                            collect and store
                                                            your information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We may use cookies and
                                                          similar tracking
                                                          technologies (like web
                                                          beacons and pixels) to
                                                          access or store
                                                          information. Specific
                                                          information about how
                                                          we use such
                                                          technologies and how
                                                          you can refuse certain
                                                          cookies is set out in
                                                          our Cookie Notice
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                              .
                                                            </span>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="sociallogins"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  6. HOW DO WE
                                                                  HANDLE YOUR
                                                                  SOCIAL LOGINS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <em>In Short: </em>
                                                          </strong>
                                                          <em>
                                                            If you choose to
                                                            register or log in
                                                            to our services
                                                            using a social media
                                                            account, we may have
                                                            access to certain
                                                            information about
                                                            you.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          Our Services offer you
                                                          the ability to
                                                          register and log in
                                                          using your third-party
                                                          social media account
                                                          details (like your
                                                          Facebook or Twitter
                                                          logins). Where you
                                                          choose to do this, we
                                                          will receive certain
                                                          profile information
                                                          about you from your
                                                          social media provider.
                                                          The profile
                                                          information we receive
                                                          may vary depending on
                                                          the social media
                                                          provider concerned,
                                                          but will often include
                                                          your name, email
                                                          address, friends list,
                                                          and profile picture,
                                                          as well as other
                                                          information you choose
                                                          to make public on such
                                                          a social media
                                                          platform.{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We will use the
                                                          information we receive
                                                          only for the purposes
                                                          that are described in
                                                          this privacy notice or
                                                          that are otherwise
                                                          made clear to you on
                                                          the relevant Services.
                                                          Please note that we do
                                                          not control, and are
                                                          not responsible for,
                                                          other uses of your
                                                          personal information
                                                          by your third-party
                                                          social media provider.
                                                          We recommend that you
                                                          review their privacy
                                                          notice to understand
                                                          how they collect, use,
                                                          and share your
                                                          personal information,
                                                          and how you can set
                                                          your privacy
                                                          preferences on their
                                                          sites and apps.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span className="font-nunito text-base text-gray-600">
                                                                            <span className="statement-end-if-in-editor" />
                                                                          </span>
                                                                          <span className="block-component">
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="inforetain"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  7. HOW LONG DO
                                                                  WE KEEP YOUR
                                                                  INFORMATION?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <em>In Short: </em>
                                                          </strong>
                                                          <em>
                                                            We keep your
                                                            information for as
                                                            long as necessary to{" "}
                                                            <span className="block-component" />
                                                            fulfil
                                                            <span className="else-block" />{" "}
                                                            the purposes
                                                            outlined in this
                                                            privacy notice
                                                            unless otherwise
                                                            required by law.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We will only keep your
                                                          personal information
                                                          for as long as it is
                                                          necessary for the
                                                          purposes set out in
                                                          this privacy notice,
                                                          unless a longer
                                                          retention period is
                                                          required or permitted
                                                          by law (such as tax,
                                                          accounting, or other
                                                          legal requirements).
                                                          No purpose in this
                                                          notice will require us
                                                          keeping your personal
                                                          information for longer
                                                          than{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span className="block-component" />
                                                          <span className="question">
                                                            eighteen (18)
                                                          </span>{" "}
                                                          months past the start
                                                          of the idle period of
                                                          the user's account
                                                          <span className="statement-end-if-in-editor" />
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="else-block" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          When we have no
                                                          ongoing legitimate
                                                          business need to
                                                          process your personal
                                                          information, we will
                                                          either delete or{" "}
                                                          <span className="block-component" />
                                                          anonymise
                                                          <span className="else-block" />{" "}
                                                          such information, or,
                                                          if this is not
                                                          possible (for example,
                                                          because your personal
                                                          information has been
                                                          stored in backup
                                                          archives), then we
                                                          will securely store
                                                          your personal
                                                          information and
                                                          isolate it from any
                                                          further processing
                                                          until deletion is
                                                          possible.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="infosafe"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  8. HOW DO WE
                                                                  KEEP YOUR
                                                                  INFORMATION
                                                                  SAFE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <em>In Short: </em>
                                                          </strong>
                                                          <em>
                                                            We aim to protect
                                                            your personal
                                                            information through
                                                            a system of{" "}
                                                            <span className="block-component" />
                                                            organisational
                                                            <span className="else-block" />{" "}
                                                            and technical
                                                            security measures.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We have implemented
                                                          appropriate and
                                                          reasonable technical
                                                          and{" "}
                                                          <span className="block-component" />
                                                          organisational
                                                          <span className="else-block" />{" "}
                                                          security measures
                                                          designed to protect
                                                          the security of any
                                                          personal information
                                                          we process. However,
                                                          despite our safeguards
                                                          and efforts to secure
                                                          your information, no
                                                          electronic
                                                          transmission over the
                                                          Internet or
                                                          information storage
                                                          technology can be
                                                          guaranteed to be 100%
                                                          secure, so we cannot
                                                          promise or guarantee
                                                          that hackers,
                                                          cybercriminals, or
                                                          other{" "}
                                                          <span className="block-component" />
                                                          unauthorised
                                                          <span className="else-block" />{" "}
                                                          third parties will not
                                                          be able to defeat our
                                                          security and
                                                          improperly collect,
                                                          access, steal, or
                                                          modify your
                                                          information. Although
                                                          we will do our best to
                                                          protect your personal
                                                          information,
                                                          transmission of
                                                          personal information
                                                          to and from our
                                                          Services is at your
                                                          own risk. You should
                                                          only access the
                                                          Services within a
                                                          secure environment.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="statement-end-if-in-editor" />
                                                          </span>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="privacyrights"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  9. WHAT ARE
                                                                  YOUR PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            {" "}
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <em>
                                                                    <span className="block-component" />
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            In some regions,
                                                            such as{" "}
                                                            <span className="block-component" />
                                                            the European
                                                            Economic Area (EEA),
                                                            United Kingdom (UK),
                                                            and Canada
                                                            <span className="block-component" />
                                                            , you have rights
                                                            that allow you
                                                            greater access to
                                                            and control over
                                                            your personal
                                                            information.
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <em>
                                                                    <span className="statement-end-if-in-editor" />
                                                                  </em>
                                                                </span>
                                                              </span>{" "}
                                                            </span>
                                                            You may review,
                                                            change, or terminate
                                                            your account at any
                                                            time.
                                                          </em>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          In some regions (like{" "}
                                                          <span className="block-component" />
                                                          the EEA, UK, and
                                                          Canada
                                                          <span className="block-component" />
                                                          ), you have certain
                                                          rights under
                                                          applicable data
                                                          protection laws. These
                                                          may include the right
                                                          (i) to request access
                                                          and obtain a copy of
                                                          your personal
                                                          information, (ii) to
                                                          request rectification
                                                          or erasure; (iii) to
                                                          restrict the
                                                          processing of your
                                                          personal information;
                                                          and (iv) if
                                                          applicable, to data
                                                          portability. In
                                                          certain circumstances,
                                                          you may also have the
                                                          right to object to the
                                                          processing of your
                                                          personal information.
                                                          You can make such a
                                                          request by contacting
                                                          us by using the
                                                          contact details
                                                          provided in the
                                                          section{" "}
                                                          <span className="block-component" />
                                                          '
                                                          <span className="else-block" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      className="font-sans text-base text-blue-600 break-words"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span className="block-component" />
                                                          '
                                                          <span className="else-block" />{" "}
                                                          below.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We will consider and
                                                          act upon any request
                                                          in accordance with
                                                          applicable data
                                                          protection laws.
                                                        </span>
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      {" "}
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          If you are located in
                                                          the EEA or UK and you
                                                          believe we are
                                                          unlawfully processing
                                                          your personal
                                                          information, you also
                                                          have the right to
                                                          complain to your local
                                                          data protection
                                                          supervisory authority.
                                                          You can find their
                                                          contact details here:{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(48, 48, 241)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <a
                                                                      className="font-sans text-base text-blue-600 break-words"
                                                                      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                      </span>
                                                                    </a>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          If you are located in
                                                          Switzerland, the
                                                          contact details for
                                                          the data protection
                                                          authorities are
                                                          available here:{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(48, 48, 241)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="font-sans text-base text-blue-600 break-words"
                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="withdrawconsent"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <u>
                                                              Withdrawing your
                                                              consent:
                                                            </u>
                                                          </strong>{" "}
                                                          If we are relying on
                                                          your consent to
                                                          process your personal
                                                          information,
                                                          <span className="block-component" />{" "}
                                                          which may be express
                                                          and/or implied consent
                                                          depending on the
                                                          applicable law,
                                                          <span className="statement-end-if-in-editor" />{" "}
                                                          you have the right to
                                                          withdraw your consent
                                                          at any time. You can
                                                          withdraw your consent
                                                          at any time by
                                                          contacting us by using
                                                          the contact details
                                                          provided in the
                                                          section{" "}
                                                          <span className="block-component" />
                                                          '
                                                          <span className="else-block" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      className="font-sans text-base text-blue-600 break-words"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span className="block-component" />
                                                          '
                                                          <span className="else-block" />{" "}
                                                          below
                                                          <span className="block-component" />{" "}
                                                          or updating your
                                                          preferences
                                                          <span className="statement-end-if-in-editor" />
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        However, please note
                                                        that this will not
                                                        affect the lawfulness of
                                                        the processing before
                                                        its withdrawal, nor
                                                        <span className="block-component" />{" "}
                                                        when applicable law
                                                        allows,
                                                        <span className="statement-end-if-in-editor" />{" "}
                                                        will it affect the
                                                        processing of your
                                                        personal information
                                                        conducted in reliance on
                                                        lawful processing
                                                        grounds other than
                                                        consent.
                                                        <span className="block-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <strong>
                                                          <u>
                                                            Opting out of
                                                            marketing and
                                                            promotional
                                                            communications:
                                                          </u>
                                                        </strong>
                                                        <strong>
                                                          <u> </u>
                                                        </strong>
                                                        You can unsubscribe from
                                                        our marketing and
                                                        promotional
                                                        communications at any
                                                        time by
                                                        <span className="block-component" />{" "}
                                                        clicking on the
                                                        unsubscribe link in the
                                                        emails that we send,
                                                        <span className="statement-end-if-in-editor" />
                                                        <span className="block-component" />{" "}
                                                        replying{" "}
                                                        <span className="block-component" />
                                                        'STOP' or 'UNSUBSCRIBE'
                                                        <span className="else-block" />{" "}
                                                        to the SMS messages that
                                                        we send,
                                                        <span className="statement-end-if-in-editor" />
                                                        <span className="block-component" />{" "}
                                                        or by contacting us
                                                        using the details
                                                        provided in the section{" "}
                                                        <span className="block-component" />
                                                        '
                                                        <span className="else-block" />
                                                      </span>
                                                    </span>
                                                    <a
                                                      className="font-sans text-base text-blue-600 break-words"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          HOW CAN YOU CONTACT US
                                                          ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="block-component" />
                                                        '
                                                        <span className="else-block" />{" "}
                                                        below. You will then be
                                                        removed from the
                                                        marketing lists.
                                                        However, we may still
                                                        communicate with you —
                                                        for example, to send you
                                                        service-related messages
                                                        that are necessary for
                                                        the administration and
                                                        use of your account, to
                                                        respond to service
                                                        requests, or for other
                                                        non-marketing purposes.
                                                      </span>
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                    <span className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-sans text-lg text-black">
                                                        <strong>
                                                          Account Information
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        If you would at any time
                                                        like to review or change
                                                        the information in your
                                                        account or terminate
                                                        your account, you can:
                                                        <span className="forloop-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="question">
                                                            Log in to your
                                                            account settings and
                                                            update your user
                                                            account.
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="forloop-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="question">
                                                            Contact us using the
                                                            contact information
                                                            provided.
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="forloop-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        Upon your request to
                                                        terminate your account,
                                                        we will deactivate or
                                                        delete your account and
                                                        information from our
                                                        active databases.
                                                        However, we may retain
                                                        some information in our
                                                        files to prevent fraud,
                                                        troubleshoot problems,
                                                        assist with any
                                                        investigations, enforce
                                                        our legal terms and/or
                                                        comply with applicable
                                                        legal requirements.
                                                      </span>
                                                    </span>
                                                    <span className="statement-end-if-in-editor">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600" />
                                                      </span>
                                                    </span>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <u>
                                                              Cookies and
                                                              similar
                                                              technologies:
                                                            </u>
                                                          </strong>{" "}
                                                          Most Web browsers are
                                                          set to accept cookies
                                                          by default. If you
                                                          prefer, you can
                                                          usually choose to set
                                                          your browser to remove
                                                          cookies and to reject
                                                          cookies. If you choose
                                                          to remove cookies or
                                                          reject cookies, this
                                                          could affect certain
                                                          features or services
                                                          of our Services. To
                                                          opt out of
                                                          interest-based
                                                          advertising by
                                                          advertisers on our
                                                          Services visit{" "}
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(48, 48, 241)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <a
                                                                className="font-sans text-base text-blue-600 break-words"
                                                                href="http://www.aboutads.info/choices/"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  http://www.aboutads.info/choices/
                                                                </span>
                                                              </a>
                                                            </span>
                                                          </span>
                                                          .{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="block-component" />
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="font-nunito text-base text-gray-600">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        If you have questions or
                                                        comments about your
                                                        privacy rights, you may
                                                        email us at{" "}
                                                        <span className="question">
                                                          info@fastfluencyafrica.com
                                                        </span>
                                                        .
                                                      </span>
                                                    </span>
                                                    <span className="statement-end-if-in-editor">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="DNT"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  10. CONTROLS
                                                                  FOR
                                                                  DO-NOT-TRACK
                                                                  FEATURES
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          Most web browsers and
                                                          some mobile operating
                                                          systems and mobile
                                                          applications include a
                                                          Do-Not-Track (
                                                          <span className="block-component" />
                                                          'DNT'
                                                          <span className="else-block" />
                                                          ) feature or setting
                                                          you can activate to
                                                          signal your privacy
                                                          preference not to have
                                                          data about your online
                                                          browsing activities
                                                          monitored and
                                                          collected. At this
                                                          stage no uniform
                                                          technology standard
                                                          for{" "}
                                                          <span className="block-component" />
                                                          recognising
                                                          <span className="else-block" />{" "}
                                                          and implementing DNT
                                                          signals has been{" "}
                                                          <span className="block-component" />
                                                          finalised
                                                          <span className="else-block" />
                                                          . As such, we do not
                                                          currently respond to
                                                          DNT browser signals or
                                                          any other mechanism
                                                          that automatically
                                                          communicates your
                                                          choice not to be
                                                          tracked online. If a
                                                          standard for online
                                                          tracking is adopted
                                                          that we must follow in
                                                          the future, we will
                                                          inform you about that
                                                          practice in a revised
                                                          version of this
                                                          privacy notice.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="caresidents"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  11. DO
                                                                  CALIFORNIA
                                                                  RESIDENTS HAVE
                                                                  SPECIFIC
                                                                  PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            <em>In Short: </em>
                                                          </strong>
                                                          <em>
                                                            Yes, if you are a
                                                            resident of
                                                            California, you are
                                                            granted specific
                                                            rights regarding
                                                            access to your
                                                            personal
                                                            information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          California Civil Code
                                                          Section 1798.83, also
                                                          known as the{" "}
                                                          <span className="block-component" />
                                                          'Shine The Light'
                                                          <span className="else-block" />{" "}
                                                          law, permits our users
                                                          who are California
                                                          residents to request
                                                          and obtain from us,
                                                          once a year and free
                                                          of charge, information
                                                          about categories of
                                                          personal information
                                                          (if any) we disclosed
                                                          to third parties for
                                                          direct marketing
                                                          purposes and the names
                                                          and addresses of all
                                                          third parties with
                                                          which we shared
                                                          personal information
                                                          in the immediately
                                                          preceding calendar
                                                          year. If you are a
                                                          California resident
                                                          and would like to make
                                                          such a request, please
                                                          submit your request in
                                                          writing to us using
                                                          the contact
                                                          information provided
                                                          below.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          If you are under 18
                                                          years of age, reside
                                                          in California, and
                                                          have a registered
                                                          account with Services,
                                                          you have the right to
                                                          request removal of
                                                          unwanted data that you
                                                          publicly post on the
                                                          Services. To request
                                                          removal of such data,
                                                          please contact us
                                                          using the contact
                                                          information provided
                                                          below and include the
                                                          email address
                                                          associated with your
                                                          account and a
                                                          statement that you
                                                          reside in California.
                                                          We will make sure the
                                                          data is not publicly
                                                          displayed on the
                                                          Services, but please
                                                          be aware that the data
                                                          may not be completely
                                                          or comprehensively
                                                          removed from all our
                                                          systems (e.g.
                                                          <span className="block-component" />{" "}
                                                          backups, etc.).
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      className="font-sans text-lg text-black"
                                                      style={{
                                                        color: "rgb(0, 0, 0)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <strong>
                                                          CCPA Privacy Notice
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <div>
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  The California
                                                                  Code of
                                                                  Regulations
                                                                  defines a{" "}
                                                                  <span className="block-component" />
                                                                  'resident'
                                                                  <span className="else-block" />{" "}
                                                                  as:
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                      marginLeft: 20,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          (1) every individual
                                                          who is in the State of
                                                          California for other
                                                          than a temporary or
                                                          transitory purpose and
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                      marginLeft: 20,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          (2) every individual
                                                          who is domiciled in
                                                          the State of
                                                          California who is
                                                          outside the State of
                                                          California for a
                                                          temporary or
                                                          transitory purpose
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          All other individuals
                                                          are defined as{" "}
                                                          <span className="block-component" />
                                                          'non-residents'.
                                                          <span className="else-block" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          If this definition of{" "}
                                                          <span className="block-component" />
                                                          'resident'
                                                          <span className="else-block" />{" "}
                                                          applies to you, we
                                                          must adhere to certain
                                                          rights and obligations
                                                          regarding your
                                                          personal information.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            What categories of
                                                            personal information
                                                            do we collect?
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We have collected the
                                                          following categories
                                                          of personal
                                                          information in the
                                                          past twelve (12)
                                                          months:
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <table
                                                    style={{ width: "100%" }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <strong>
                                                                  Category
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderTop:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <strong>
                                                                  Examples
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <strong>
                                                                  Collected
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  A. Identifiers
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderTop:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Contact
                                                                  details, such
                                                                  as real name,
                                                                  alias, postal
                                                                  address,
                                                                  telephone or
                                                                  mobile contact
                                                                  number, unique
                                                                  personal
                                                                  identifier,
                                                                  online
                                                                  identifier,
                                                                  Internet
                                                                  Protocol
                                                                  address, email
                                                                  address, and
                                                                  account name
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            verticalAlign:
                                                              "middle",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  YES
                                                                  <span className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  B. Personal
                                                                  information
                                                                  categories
                                                                  listed in the
                                                                  California
                                                                  Customer
                                                                  Records
                                                                  statute
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Name, contact
                                                                  information,
                                                                  education,
                                                                  employment,
                                                                  employment
                                                                  history, and
                                                                  financial
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  NO
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  C. Protected
                                                                  classification
                                                                  characteristics
                                                                  under
                                                                  California or
                                                                  federal law
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Gender and
                                                                  date of birth
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  NO
                                                                  <span className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  D. Commercial
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Transaction
                                                                  information,
                                                                  purchase
                                                                  history,
                                                                  financial
                                                                  details, and
                                                                  payment
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  YES
                                                                  <span className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  E. Biometric
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Fingerprints
                                                                  and
                                                                  voiceprints
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  NO
                                                                  <span className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  F. Internet or
                                                                  other similar
                                                                  network
                                                                  activity
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Browsing
                                                                  history,
                                                                  search
                                                                  history,
                                                                  online{" "}
                                                                  <span className="block-component" />
                                                                  behaviour
                                                                  <span className="else-block" />
                                                                  , interest
                                                                  data, and
                                                                  interactions
                                                                  with our and
                                                                  other
                                                                  websites,
                                                                  applications,
                                                                  systems, and
                                                                  advertisements
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  NO
                                                                  <span className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  G. Geolocation
                                                                  data
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Device
                                                                  location
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  NO
                                                                  <span className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  H. Audio,
                                                                  electronic,
                                                                  visual,
                                                                  thermal,
                                                                  olfactory, or
                                                                  similar
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Images and
                                                                  audio, video
                                                                  or call
                                                                  recordings
                                                                  created in
                                                                  connection
                                                                  with our
                                                                  business
                                                                  activities
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  YES
                                                                  <span className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "33.8274%",
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  I.
                                                                  Professional
                                                                  or
                                                                  employment-related
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "51.4385%",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Business
                                                                  contact
                                                                  details in
                                                                  order to
                                                                  provide you
                                                                  our services
                                                                  at a business
                                                                  level or job
                                                                  title, work
                                                                  history, and
                                                                  professional
                                                                  qualifications
                                                                  if you apply
                                                                  for a job with
                                                                  us
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "14.9084%",
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  YES
                                                                  <span className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderLeft:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                            width: "33.8274%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  J. Education
                                                                  Information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                            width: "51.4385%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Student
                                                                  records and
                                                                  directory
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                            width: "14.9084%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  YES
                                                                  <span className="else-block" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                              "black",
                                                            borderStyle:
                                                              "solid",
                                                            width: "33.8274%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  K. Inferences
                                                                  drawn from
                                                                  other personal
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            borderBottom:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                            borderRight:
                                                              "1px solid black",
                                                            width: "51.4385%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  Inferences
                                                                  drawn from any
                                                                  of the
                                                                  collected
                                                                  personal
                                                                  information
                                                                  listed above
                                                                  to create a
                                                                  profile or
                                                                  summary about,
                                                                  for example,
                                                                  an
                                                                  individual’s
                                                                  preferences
                                                                  and
                                                                  characteristics
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            borderRight:
                                                              "1px solid black",
                                                            borderBottom:
                                                              "1px solid black",
                                                            borderTop:
                                                              "1px solid black",
                                                            width: "14.9084%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                  YES
                                                                  <span className="else-block"></span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="block-component" />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We may also collect
                                                          other personal
                                                          information outside of
                                                          these categories
                                                          instances where you
                                                          interact with us in
                                                          person, online, or by
                                                          phone or mail in the
                                                          context of:
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            Receiving help
                                                            through our customer
                                                            support channels;
                                                          </span>
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span className="font-nunito text-base text-gray-600">
                                                                                <span className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            Participation in
                                                            customer surveys or
                                                            contests; and
                                                          </span>
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span className="font-nunito text-base text-gray-600">
                                                                                <span className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            Facilitation in the
                                                            delivery of our
                                                            Services and to
                                                            respond to your
                                                            inquiries.
                                                          </span>
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span className="font-nunito text-base text-gray-600">
                                                                                <span className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            How do we use and
                                                            share your personal
                                                            information?
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                More information
                                                                about our data
                                                                collection and
                                                                sharing
                                                                practices can be
                                                                found in this
                                                                privacy notice
                                                                <span className="block-component" />
                                                                .
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          You may contact us{" "}
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                          by email at{" "}
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span className="question">
                                                              info@fastfluencyafrica.com
                                                            </span>
                                                            ,{" "}
                                                            <span className="statement-end-if-in-editor" />
                                                            <span className="block-component" />
                                                          </span>
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span className="block-component" />
                                                            <span className="block-component">
                                                              <span className="font-nunito text-base text-gray-600" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span className="font-nunito text-base text-gray-600">
                                                      or by referring to the
                                                      contact details at the
                                                      bottom of this document.
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          If you are using an{" "}
                                                          <span className="block-component" />
                                                          authorised
                                                          <span className="else-block" />{" "}
                                                          agent to exercise your
                                                          right to opt out we
                                                          may deny a request if
                                                          the{" "}
                                                          <span className="block-component" />
                                                          authorised
                                                          <span className="else-block" />{" "}
                                                          agent does not submit
                                                          proof that they have
                                                          been validly{" "}
                                                          <span className="block-component" />
                                                          authorised
                                                          <span className="else-block" />{" "}
                                                          to act on your behalf.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            Will your
                                                            information be
                                                            shared with anyone
                                                            else?
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We may disclose your
                                                          personal information
                                                          with our service
                                                          providers pursuant to
                                                          a written contract
                                                          between us and each
                                                          service provider. Each
                                                          service provider is a
                                                          for-profit entity that
                                                          processes the
                                                          information on our
                                                          behalf.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We may use your
                                                          personal information
                                                          for our own business
                                                          purposes, such as for
                                                          undertaking internal
                                                          research for
                                                          technological
                                                          development and
                                                          demonstration. This is
                                                          not considered to be{" "}
                                                          <span className="block-component" />
                                                          'selling'
                                                          <span className="else-block" />{" "}
                                                          of your personal
                                                          information.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span className="question">
                                                            Fast Fluency Africa
                                                          </span>
                                                        </span>
                                                        <span className="font-nunito text-base text-gray-600">
                                                          {" "}
                                                          has not disclosed or
                                                          sold any personal
                                                          information to third
                                                          parties for a business
                                                          or commercial purpose
                                                          in the preceding
                                                          twelve (12) months.{" "}
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="question">
                                                                  Fast Fluency
                                                                  Africa
                                                                </span>
                                                              </span>
                                                            </span>{" "}
                                                          </span>
                                                          will not sell personal
                                                          information in the
                                                          future belonging to
                                                          website visitors,
                                                          users, and other
                                                          consumers.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <strong>
                                                            Your rights with
                                                            respect to your
                                                            personal data
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <u>
                                                            Right to request
                                                            deletion of the data
                                                            — Request to delete
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          You can ask for the
                                                          deletion of your
                                                          personal information.
                                                          If you ask us to
                                                          delete your personal
                                                          information, we will
                                                          respect your request
                                                          and delete your
                                                          personal information,
                                                          subject to certain
                                                          exceptions provided by
                                                          law, such as (but not
                                                          limited to) the
                                                          exercise by another
                                                          consumer of his or her
                                                          right to free speech,
                                                          our compliance
                                                          requirements resulting
                                                          from a legal
                                                          obligation, or any
                                                          processing that may be
                                                          required to protect
                                                          against illegal
                                                          activities.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <u>
                                                            Right to be informed
                                                            — Request to know
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          Depending on the
                                                          circumstances, you
                                                          have a right to know:
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            whether we collect
                                                            and use your
                                                            personal
                                                            information;
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            the categories of
                                                            personal information
                                                            that we collect;
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            the purposes for
                                                            which the collected
                                                            personal information
                                                            is used;
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            whether we sell your
                                                            personal information
                                                            to third parties;
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            the categories of
                                                            personal information
                                                            that we sold or
                                                            disclosed for a
                                                            business purpose;
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            the categories of
                                                            third parties to
                                                            whom the personal
                                                            information was sold
                                                            or disclosed for a
                                                            business purpose;
                                                            and
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            the business or
                                                            commercial purpose
                                                            for collecting or
                                                            selling personal
                                                            information.
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          In accordance with
                                                          applicable law, we are
                                                          not obligated to
                                                          provide or delete
                                                          consumer information
                                                          that is de-identified
                                                          in response to a
                                                          consumer request or to
                                                          re-identify individual
                                                          data to verify a
                                                          consumer request.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <u>
                                                            Right to
                                                            Non-Discrimination
                                                            for the Exercise of
                                                            a Consumer’s Privacy
                                                            Rights
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We will not
                                                          discriminate against
                                                          you if you exercise
                                                          your privacy rights.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <u>
                                                            Verification process
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          Upon receiving your
                                                          request, we will need
                                                          to verify your
                                                          identity to determine
                                                          you are the same
                                                          person about whom we
                                                          have the information
                                                          in our system. These
                                                          verification efforts
                                                          require us to ask you
                                                          to provide information
                                                          so that we can match
                                                          it with information
                                                          you have previously
                                                          provided us. For
                                                          instance, depending on
                                                          the type of request
                                                          you submit, we may ask
                                                          you to provide certain
                                                          information so that we
                                                          can match the
                                                          information you
                                                          provide with the
                                                          information we already
                                                          have on file, or we
                                                          may contact you
                                                          through a
                                                          communication method
                                                          (e.g.
                                                          <span className="block-component" />{" "}
                                                          phone or email) that
                                                          you have previously
                                                          provided to us. We may
                                                          also use other
                                                          verification methods
                                                          as the circumstances
                                                          dictate.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We will only use
                                                          personal information
                                                          provided in your
                                                          request to verify your
                                                          identity or authority
                                                          to make the request.
                                                          To the extent
                                                          possible, we will
                                                          avoid requesting
                                                          additional information
                                                          from you for the
                                                          purposes of
                                                          verification. However,
                                                          if we cannot verify
                                                          your identity from the
                                                          information already
                                                          maintained by us, we
                                                          may request that you
                                                          provide additional
                                                          information for the
                                                          purposes of verifying
                                                          your identity and for
                                                          security or
                                                          fraud-prevention
                                                          purposes. We will
                                                          delete such
                                                          additionally provided
                                                          information as soon as
                                                          we finish verifying
                                                          you.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <u>
                                                            Other privacy rights
                                                          </u>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            You may object to
                                                            the processing of
                                                            your personal
                                                            information.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            You may request
                                                            correction of your
                                                            personal data if it
                                                            is incorrect or no
                                                            longer relevant, or
                                                            ask to restrict the
                                                            processing of the
                                                            information.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            You can designate an{" "}
                                                            <span className="block-component" />
                                                            authorised
                                                            <span className="else-block" />{" "}
                                                            agent to make a
                                                            request under the
                                                            CCPA on your behalf.
                                                            We may deny a
                                                            request from an{" "}
                                                            <span className="block-component" />
                                                            authorised
                                                            <span className="else-block" />{" "}
                                                            agent that does not
                                                            submit proof that
                                                            they have been
                                                            validly{" "}
                                                            <span className="block-component" />
                                                            authorised
                                                            <span className="else-block" />{" "}
                                                            to act on your
                                                            behalf in accordance
                                                            with the CCPA.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span className="font-nunito text-base text-gray-600">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            You may request to
                                                            opt out from future
                                                            selling of your
                                                            personal information
                                                            to third parties.
                                                            Upon receiving an
                                                            opt-out request, we
                                                            will act upon the
                                                            request as soon as
                                                            feasibly possible,
                                                            but no later than
                                                            fifteen (15) days
                                                            from the date of the
                                                            request submission.
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span className="font-nunito text-base text-gray-600">
                                                                              <span className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          To exercise these
                                                          rights, you can
                                                          contact us{" "}
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="block-component" />
                                                                by email at{" "}
                                                                <span className="question">
                                                                  info@fastfluencyafrica.com
                                                                </span>
                                                                ,{" "}
                                                                <span className="statement-end-if-in-editor" />
                                                                <span className="block-component" />
                                                              </span>
                                                              <span className="font-nunito text-base text-gray-600">
                                                                <span className="block-component" />
                                                                <span className="block-component">
                                                                  <span className="font-nunito text-base text-gray-600" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span className="font-nunito text-base text-gray-600">
                                                      or by referring to the
                                                      contact details at the
                                                      bottom of this document.
                                                      If you have a complaint
                                                      about how we handle your
                                                      data, we would like to
                                                      hear from you.
                                                    </span>
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="block-component">
                                                          <span className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="font-nunito text-base text-gray-600">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span className="font-nunito text-base text-gray-600">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="font-nunito text-base text-gray-600">
                                                                                <span className="statement-end-if-in-editor">
                                                                                  <span className="statement-end-if-in-editor" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="policyupdates"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  12. DO WE MAKE
                                                                  UPDATES TO
                                                                  THIS NOTICE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <em>
                                                            <strong>
                                                              In Short:{" "}
                                                            </strong>
                                                            Yes, we will update
                                                            this notice as
                                                            necessary to stay
                                                            compliant with
                                                            relevant laws.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          We may update this
                                                          privacy notice from
                                                          time to time. The
                                                          updated version will
                                                          be indicated by an
                                                          updated{" "}
                                                          <span className="block-component" />
                                                          'Revised'
                                                          <span className="else-block" />{" "}
                                                          date and the updated
                                                          version will be
                                                          effective as soon as
                                                          it is accessible. If
                                                          we make material
                                                          changes to this
                                                          privacy notice, we may
                                                          notify you either by
                                                          prominently posting a
                                                          notice of such changes
                                                          or by directly sending
                                                          you a notification. We
                                                          encourage you to
                                                          review this privacy
                                                          notice frequently to
                                                          be informed of how we
                                                          are protecting your
                                                          information.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="contact"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  13. HOW CAN
                                                                  YOU CONTACT US
                                                                  ABOUT THIS
                                                                  NOTICE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          If you have questions
                                                          or comments about this
                                                          notice, you may{" "}
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                              email us at{" "}
                                                              <span className="question">
                                                                info@fastfluencyafrica.com
                                                              </span>
                                                              <span className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="font-nunito text-base text-gray-600">
                                                                {" "}
                                                                or by post to:
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="question">
                                                                    Fast Fluency
                                                                    Africa
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span className="font-nunito text-base text-gray-600">
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="question">
                                                          STAND 11 FORTUNE
                                                          STREET UNIT D
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      className="font-nunito text-base text-gray-600"
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="question">
                                                        TEMBA KUBUDE
                                                        <span className="font-nunito text-base text-gray-600">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span className="question">
                                                          PRETORIA
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span className="block-component" />
                                                            ,{" "}
                                                            <span className="question">
                                                              GAUTENG
                                                            </span>
                                                            <span className="statement-end-if-in-editor" />
                                                            <span className="block-component" />
                                                            <span className="question">
                                                              0040
                                                            </span>
                                                            <span className="statement-end-if-in-editor" />
                                                            <span className="block-component" />
                                                            <span className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="question">
                                                          South Africa
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span className="font-nunito text-base text-gray-600">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span className="font-nunito text-base text-gray-600">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span className="font-nunito text-base text-gray-600">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span className="font-nunito text-base text-gray-600">
                                                                      <span className="block-component">
                                                                        <span className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="font-nunito text-base text-gray-600">
                                                                  <span className="statement-end-if-in-editor">
                                                                    <span className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="request"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span className="font-body text-xl text-black">
                                                                  14. HOW CAN
                                                                  YOU REVIEW,
                                                                  UPDATE, OR
                                                                  DELETE THE
                                                                  DATA WE
                                                                  COLLECT FROM
                                                                  YOU?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span className="font-nunito text-base text-gray-600">
                                                          Based on the
                                                          applicable laws of
                                                          your country, you may
                                                          have the right to
                                                          request access to the
                                                          personal information
                                                          we collect from you,
                                                          change that
                                                          information, or delete
                                                          it. To request to
                                                          review, update, or
                                                          delete your personal
                                                          information, please{" "}
                                                          <span className="block-component" />
                                                          submit a request form
                                                          by clicking{" "}
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(48, 48, 241)",
                                                          }}
                                                        >
                                                          <span className="font-nunito text-base text-gray-600">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <a
                                                                className="font-sans text-base text-blue-600 break-words"
                                                                href="https://app.termly.io/notify/63e8bdad-b3df-42dd-aba1-87aabfd2816d"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                here
                                                              </a>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="block-component">
                                                          <span className="font-nunito text-base text-gray-600" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span className="font-nunito text-base text-gray-600">
                                                      .
                                                    </span>
                                                  </div>
                                                  <style
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        "\n                                                ul {\n                                                  list-style-type: square;\n                                                }\n\n                                                ul>li>ul {\n                                                  list-style-type: circle;\n                                                }\n\n                                                ul>li>ul>li>ul {\n                                                  list-style-type: square;\n                                                }\n\n                                                ol li {\n                                                  font-family: Arial;\n                                                }\n                                              ",
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    color: "#595959",
                                                    fontSize: 14,
                                                    fontFamily: "Arial",
                                                    paddingTop: 16,
                                                  }}
                                                >
                                                  This privacy policy was
                                                  created using Termly's{" "}
                                                  <a
                                                    style={{
                                                      color:
                                                        "rgb(48, 48, 241) !important",
                                                    }}
                                                    href="https://termly.io/products/privacy-policy-generator"
                                                  >
                                                    Privacy Policy Generator
                                                  </a>
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Privacy;
