// hooks/useShopWithPowerUps.js
import { useState, useEffect, useCallback } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import { useShop } from "../helpers/shopContext";
import { useAuth } from "../helpers/UserAuthContextProvider";
import PowerUpInitializationService from "../services/power-ups/power-up-initialization-service";
import PowerUpUtils from "../services/power-ups/power-up-utils";
import { toDate, isFuture } from "../utils/timestamp-utils";

/**
 * Enhanced shop hook that specifically focuses on power-up functionality
 * Adds real-time listeners for critical power-up changes
 */
export const useShopWithPowerUps = () => {
  const shop = useShop();
  const { user } = useAuth();

  // Additional state for power-up specific functionality
  const [powerUpStatus, setPowerUpStatus] = useState({
    initialized: false,
    initializing: false,
    xpBoost: {
      active: false,
      multiplier: 1,
      expiresAt: null,
    },
    streakProtection: {
      active: false,
      expiresAt: null,
      source: null,
    },
    activePowerUps: [],
  });

  // Initialize power-ups when user authenticates
  useEffect(() => {
    if (!user?.uid || powerUpStatus.initializing || powerUpStatus.initialized)
      return;

    const initializePowerUps = async () => {
      setPowerUpStatus((prev) => ({ ...prev, initializing: true }));

      try {
        // Run initialization service
        const results = await PowerUpInitializationService.initialize(user.uid);

        // Update state with results
        setPowerUpStatus({
          initialized: true,
          initializing: false,
          xpBoost: {
            active: results.xpStatus?.active || false,
            multiplier: results.xpStatus?.multiplier || 1,
            expiresAt: results.xpStatus?.expiresAt || null,
          },
          streakProtection: {
            active: results.streakStatus?.isProtected || false,
            expiresAt: results.streakStatus?.expiresAt || null,
            source: results.streakStatus?.source || null,
          },
          activePowerUps: results.activePowerUps || [],
        });
      } catch (error) {
        console.error("Error initializing power-ups:", error);
        setPowerUpStatus((prev) => ({
          ...prev,
          initializing: false,
          initialized: true,
          error: error.message,
        }));
      }
    };

    initializePowerUps();
  }, [user?.uid, powerUpStatus.initializing, powerUpStatus.initialized]);

  // Set up real-time listener for XP boost changes
  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);

    // This listener will update when xpMultiplier or xpBoostExpiresAt changes
    const unsubscribe = onSnapshot(
      userRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.data();

          // Update XP boost status
          if (userData.xpMultiplier || userData.xpBoostExpiresAt) {
            const expiresAt = toDate(userData.xpBoostExpiresAt);
            const isActive =
              userData.xpMultiplier > 1 && expiresAt && isFuture(expiresAt);

            setPowerUpStatus((prev) => ({
              ...prev,
              xpBoost: {
                active: isActive,
                multiplier: userData.xpMultiplier || 1,
                expiresAt: expiresAt,
              },
            }));
          }

          // Update streak protection status
          if (userData.streakFreezeApplied) {
            PowerUpUtils.checkStreakProtection(user.uid).then((result) => {
              setPowerUpStatus((prev) => ({
                ...prev,
                streakProtection: {
                  active: result.isProtected,
                  expiresAt: result.expiresAt || null,
                  source: result.source || null,
                },
              }));
            });
          }
        }
      },
      (error) => {
        console.error("Error in power-up listener:", error);
      }
    );

    // Clean up listener on unmount
    return () => unsubscribe();
  }, [user?.uid]);

  // Function to calculate boosted XP amount
  const calculateBoostedXP = useCallback(
    async (baseXp) => {
      if (!user?.uid) return { xp: baseXp, multiplier: 1, hasBoost: false };

      try {
        return await PowerUpUtils.calculateBoostedXP(user.uid, baseXp);
      } catch (error) {
        console.error("Error calculating boosted XP:", error);
        return {
          xp: baseXp,
          multiplier: 1,
          hasBoost: false,
          error: error.message,
        };
      }
    },
    [user?.uid]
  );

  // Function to check if streak is protected
  const checkStreakProtection = useCallback(async () => {
    if (!user?.uid) return { isProtected: false };

    try {
      return await PowerUpUtils.checkStreakProtection(user.uid);
    } catch (error) {
      console.error("Error checking streak protection:", error);
      return { isProtected: false, error: error.message };
    }
  }, [user?.uid]);

  // Function to refresh power-up status
  const refreshPowerUpStatus = useCallback(async () => {
    if (!user?.uid) return;

    try {
      const results = await PowerUpInitializationService.initialize(user.uid);

      setPowerUpStatus({
        initialized: true,
        initializing: false,
        xpBoost: {
          active: results.xpStatus?.active || false,
          multiplier: results.xpStatus?.multiplier || 1,
          expiresAt: results.xpStatus?.expiresAt || null,
        },
        streakProtection: {
          active: results.streakStatus?.isProtected || false,
          expiresAt: results.streakStatus?.expiresAt || null,
          source: results.streakStatus?.source || null,
        },
        activePowerUps: results.activePowerUps || [],
      });

      return results;
    } catch (error) {
      console.error("Error refreshing power-up status:", error);
      return { success: false, error: error.message };
    }
  }, [user?.uid]);

  // Return enhanced shop context with power-up specific additions
  return {
    ...shop,
    powerUpStatus,
    calculateBoostedXP,
    checkStreakProtection,
    refreshPowerUpStatus,
  };
};

export default useShopWithPowerUps;
