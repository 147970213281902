// src/utils/audio-utils.js
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebase.utils";

/**
 * Special handling for dictionary audio paths
 * @param {string} path - The audio path from dictionary entry
 * @returns {string} - The processed path
 */
export const processDictionaryAudioPath = (path) => {
  if (!path) return null;

  // If it's already a full URL, return it
  if (path.startsWith("http")) return path;

  // Check if it's a Firebase Storage path structure
  if (path.includes("/")) {
    // It's likely a Firebase Storage path like "sounds/zulu-1/words/yebo.mp3"
    return path;
  }

  // Default case - add path prefix if needed
  return `sounds/zulu-1/words/${path}`;
};

/**
 * Standardizes storage paths to ensure consistency
 * @param {string} path - The storage path
 * @returns {string} - The standardized path
 */
export const standardizeStoragePath = (path) => {
  if (!path) return null;

  // If it's already a URL, return it
  if (path.startsWith("http")) return path;

  // Remove gs:// bucket prefix if present
  if (path.startsWith("gs://")) {
    const pathParts = path.split("/");
    return pathParts.slice(3).join("/");
  }

  // Remove leading slash if present
  return path.startsWith("/") ? path.substring(1) : path;
};

/**
 * Get the audio path for a speech item
 * @param {Object} speechItem - The speech item from dialogue data
 * @param {number} index - The index of the speech item
 * @returns {string} - The audio path
 */
export const getSpeechAudioPath = (speechItem, index) => {
  if (!speechItem) return null;

  // If the speech item has an audio property, use that
  if (speechItem.audio) return speechItem.audio;

  // Otherwise generate a path based on the pattern seen in your files
  return `texts/dialogues/zul-1/1-ukwazisana/speech-${index + 1}.mp3`;
};

/**
 * Extracts the storage path from various audio data formats
 * @param {string|Object} audioData - Audio data which could be in different formats
 * @returns {string|null} The extracted storage path or null if not found
 */
export const extractAudioPath = (audioData) => {
  if (!audioData) return null;

  try {
    // Case 1: Direct string path (non-empty string check)
    if (typeof audioData === "string" && audioData.trim() !== "") {
      return standardizeStoragePath(audioData);
    }

    // Case 2: Nested path for mark_correct_meaning (as seen in your Firestore)
    // validation.correctAnswer.audio.fullPhrase.audio
    if (
      audioData.correctAnswer?.audio?.fullPhrase?.audio &&
      typeof audioData.correctAnswer.audio.fullPhrase.audio === "string" &&
      audioData.correctAnswer.audio.fullPhrase.audio.trim() !== ""
    ) {
      return standardizeStoragePath(
        audioData.correctAnswer.audio.fullPhrase.audio
      );
    }

    // Case 3: One level down - correctAnswer
    if (
      audioData.audio?.fullPhrase?.audio &&
      typeof audioData.audio.fullPhrase.audio === "string" &&
      audioData.audio.fullPhrase.audio.trim() !== ""
    ) {
      return standardizeStoragePath(audioData.audio.fullPhrase.audio);
    }

    // Case 4: Direct fullPhrase
    if (
      audioData.fullPhrase?.audio &&
      typeof audioData.fullPhrase.audio === "string" &&
      audioData.fullPhrase.audio.trim() !== ""
    ) {
      return standardizeStoragePath(audioData.fullPhrase.audio);
    }

    // Case 5: narrationAudio field (for dialogues)
    if (
      audioData.narrationAudio &&
      typeof audioData.narrationAudio === "string" &&
      audioData.narrationAudio.trim() !== ""
    ) {
      return standardizeStoragePath(audioData.narrationAudio);
    }

    return null;
  } catch (error) {
    console.error("Error in extractAudioPath:", error);
    return null;
  }
};

/**
 * Gets a download URL for an audio file from Firebase Storage
 * @param {string|Object} audioData - The audio data or path
 * @returns {Promise<string>} The download URL for the audio file
 */
export const getAudioDownloadUrl = async (audioData) => {
  try {
    if (!audioData) {
      console.warn("No audio data provided to getAudioDownloadUrl");
      return "";
    }

    // If it's already a URL, return it
    if (typeof audioData === "string" && audioData.startsWith("http")) {
      return audioData;
    }

    // Extract the path
    const path = extractAudioPath(audioData);
    if (!path || path.trim() === "") {
      console.warn("Could not extract valid path from audio data:", audioData);
      return "";
    }

    console.log("Getting download URL for path:", path);

    // Create a storage reference and get the download URL
    const storageRef = ref(storage, path);
    const url = await getDownloadURL(storageRef);

    console.log("Download URL retrieved successfully:", url);
    return url;
  } catch (error) {
    console.error("Error getting audio download URL:", error);
    console.error("For audio data:", audioData);

    // Add more detailed error information
    if (error.code) {
      switch (error.code) {
        case "storage/object-not-found":
          console.error("File does not exist at the specified path");
          break;
        case "storage/unauthorized":
          console.error("User does not have permission to access the file");
          break;
        case "storage/canceled":
          console.error("User canceled the operation");
          break;
        case "storage/unknown":
          console.error("Unknown error occurred");
          break;
        default:
          console.error(`Storage error code: ${error.code}`);
      }
    }

    return "";
  }
};

/**
 * Creates an audio controller for managing audio playback
 * @param {string} url - The URL of the audio file
 * @returns {Object} An audio controller object
 */
export const createAudioController = (url = "") => {
  const audio = new Audio(url);

  // Add additional event logging for debugging
  audio.addEventListener("canplay", () => console.log("Audio can play"));
  audio.addEventListener("canplaythrough", () =>
    console.log("Audio can play through")
  );
  audio.addEventListener("error", (e) => console.error("Audio error:", e));
  audio.addEventListener("stalled", () => console.warn("Audio stalled"));
  audio.addEventListener("waiting", () => console.warn("Audio waiting"));

  return {
    audio,
    play: (rate = 1.0) => {
      console.log("Attempting to play audio:", url);
      audio.playbackRate = rate;
      audio.currentTime = 0;

      // Check if browser allows autoplay
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => console.log("Audio playback started successfully"))
          .catch((err) => {
            console.error("Audio playback error:", err);
            // Handle autoplay restrictions
            if (err.name === "NotAllowedError") {
              console.warn(
                "Autoplay prevented by browser. User interaction required."
              );
            }
          });
        return playPromise;
      }
      return Promise.resolve();
    },
    pause: () => {
      console.log("Pausing audio");
      audio.pause();
    },
    setUrl: (newUrl) => {
      if (newUrl && audio.src !== newUrl) {
        console.log("Setting new audio URL:", newUrl);
        audio.src = newUrl;
        audio.load();
      }
    },
    destroy: () => {
      console.log("Destroying audio controller");
      audio.pause();
      audio.src = "";

      // Remove all event listeners
      audio.removeEventListener("canplay", () => {});
      audio.removeEventListener("canplaythrough", () => {});
      audio.removeEventListener("error", () => {});
      audio.removeEventListener("stalled", () => {});
      audio.removeEventListener("waiting", () => {});
    },
    getElement: () => audio,
  };
};

/**
 * Direct function for playing audio - useful for simple, one-off audio playback
 * @param {string} audioPath - Path to the audio file
 * @returns {Promise<HTMLAudioElement>} - The audio element that's playing
 */
export const playAudioDirectly = async (audioPath) => {
  try {
    const url = await getAudioDownloadUrl(audioPath);
    const audio = new Audio(url);

    // Add event listeners
    audio.addEventListener("error", (e) =>
      console.error("Audio playback error:", e)
    );

    // Play the audio
    await audio.play();
    return audio;
  } catch (error) {
    console.error("Failed to play audio directly:", error);
    throw error;
  }
};
