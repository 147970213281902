// enhanced-analytics.service.js
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  increment,
  arrayUnion,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import {
  db,
  analytics,
  LearningAnalyticsEvents,
} from "../firebase/firebase.utils";

/**
 * Define which events qualify for streak counting
 * Place this BEFORE the class definition but AFTER imports
 */
const STREAK_QUALIFYING_EVENTS = [
  LearningAnalyticsEvents.LESSON_COMPLETED,
  "practice_completed",
  "flashcard_session_completed",
  "coaching_session_completed",
];

/**
 * Enhanced Learning Analytics Service with performance optimizations
 */
class EnhancedAnalyticsService {
  constructor() {
    this.analytics = analytics;
    this.db = db;
    this.auth = getAuth();
    this.trackingCache = null;
    this.userAnalyticsCache = new Map();
    this.activityDataCache = new Map();
    this.pendingOperations = [];
    this.operationTimeout = null;
  }

  /**
   * Check if analytics tracking is enabled for the current user
   * Uses caching to avoid repeated localStorage reads
   * @returns {boolean} Whether tracking is enabled
   */
  isTrackingEnabled() {
    if (this.trackingCache !== null) {
      return this.trackingCache;
    }

    const savedPreference = localStorage.getItem("analytics-tracking-enabled");
    this.trackingCache =
      savedPreference !== null ? savedPreference === "true" : true;
    return this.trackingCache;
  }

  /**
   * Update tracking status in cache and localStorage
   * @param {boolean} isEnabled - Whether tracking should be enabled
   */
  updateTrackingCache(isEnabled) {
    console.log(`Updating analytics service tracking cache: ${isEnabled}`);
    this.trackingCache = isEnabled;

    // Also update localStorage for consistency
    try {
      localStorage.setItem("analytics-tracking-enabled", isEnabled.toString());
    } catch (error) {
      console.warn("Error updating localStorage:", error);
    }
  }

  /**
   * Track a learning event only if tracking is enabled
   * Implements batching of non-critical operations for performance
   * @param {string} userId - User ID
   * @param {string} eventType - Type of event (lesson_completed, xp_earned, etc.)
   * @param {object} eventData - Additional data about the event
   */
  async trackEvent(userId, eventType, eventData = {}) {
    try {
      if (!userId) return;
      if (!this.isTrackingEnabled()) return;

      // Always log to Firebase Analytics immediately (lightweight operation)
      logEvent(this.analytics, eventType, {
        user_id: userId,
        ...eventData,
      });

      // For Firestore operations, we'll batch non-critical updates
      const isCriticalEvent = [
        "lesson_completed",
        "practice_completed",
        "streak_updated",
        "purchase_completed",
      ].includes(eventType);

      if (isCriticalEvent) {
        // Handle critical events immediately
        try {
          await this._recordEventToFirestore(userId, eventType, eventData);
          await this.updateUserAnalyticsSummary(userId, eventType, eventData);
        } catch (error) {
          // Silently handle Firestore permission errors
          if (
            !error.message.includes("permission") &&
            !error.message.includes("insufficient")
          ) {
            console.warn("Analytics error (non-critical):", error.message);
          }
        }
      } else {
        // Queue non-critical events for batch processing
        this.queueOperation(() =>
          this._recordEventToFirestore(userId, eventType, eventData)
        );
        this.queueOperation(() =>
          this.updateUserAnalyticsSummary(userId, eventType, eventData)
        );
      }
    } catch (error) {
      // Silently fail for analytics errors to prevent app disruption
      console.warn("Analytics error:", error.message);
    }
  }

  /**
   * Queue an operation for batch processing
   * This improves performance by reducing the number of Firestore operations
   */
  queueOperation(operation) {
    this.pendingOperations.push(operation);

    // Clear any existing timeout
    if (this.operationTimeout) {
      clearTimeout(this.operationTimeout);
    }

    // Set a new timeout to process operations in batch
    this.operationTimeout = setTimeout(() => {
      this.processPendingOperations();
    }, 3000); // Process every 3 seconds
  }

  /**
   * Process all pending operations in batch
   */
  async processPendingOperations() {
    if (this.pendingOperations.length === 0) return;

    const operationsToProcess = [...this.pendingOperations];
    this.pendingOperations = [];

    // Execute operations in parallel with error handling
    await Promise.all(
      operationsToProcess.map(async (operation) => {
        try {
          await operation();
        } catch (error) {
          // Silently handle Firestore permission errors
          if (
            !error.message.includes("permission") &&
            !error.message.includes("insufficient")
          ) {
            console.warn("Batch operation error:", error.message);
          }
        }
      })
    );
  }

  /**
   * Internal helper to record an event to Firestore
   */
  async _recordEventToFirestore(userId, eventType, eventData) {
    try {
      await addDoc(collection(this.db, "learning_events"), {
        userId,
        eventType,
        timestamp: serverTimestamp(),
        ...eventData,
      });
    } catch (error) {
      // Check for permission errors and rethrow if it's a different issue
      if (
        !error.message.includes("permission") &&
        !error.message.includes("insufficient")
      ) {
        throw error;
      }
    }
  }

  /**
   * Update user's analytics summary based on the event
   * Optimized for performance with early returns and error handling
   */
  async updateUserAnalyticsSummary(userId, eventType, eventData) {
    if (!this.isTrackingEnabled() || !userId) return;

    try {
      const userAnalyticsRef = doc(this.db, "user_analytics", userId);
      let userAnalyticsData;

      // Try to get from cache first
      if (this.userAnalyticsCache.has(userId)) {
        userAnalyticsData = this.userAnalyticsCache.get(userId);
      } else {
        // Fall back to Firestore
        try {
          const userAnalyticsSnap = await getDoc(userAnalyticsRef);
          if (userAnalyticsSnap.exists()) {
            userAnalyticsData = userAnalyticsSnap.data();
            this.userAnalyticsCache.set(userId, userAnalyticsData);
          } else {
            // Initialize if needed
            await this.initializeUserAnalytics(userId);
            const newDoc = await getDoc(userAnalyticsRef);
            if (newDoc.exists()) {
              userAnalyticsData = newDoc.data();
              this.userAnalyticsCache.set(userId, userAnalyticsData);
            }
          }
        } catch (error) {
          // Handle Firestore permission errors
          if (
            error.message.includes("permission") ||
            error.message.includes("insufficient")
          ) {
            return; // Exit silently
          }
          throw error;
        }
      }

      if (!userAnalyticsData) return;

      const today = new Date().toISOString().split("T")[0];
      const updates = {};

      // Update specific metrics based on event type
      switch (eventType) {
        case LearningAnalyticsEvents.LESSON_STARTED:
          updates["lessons_started_count"] = increment(1);
          updates[`daily_activity.${today}.lessons_started`] = increment(1);
          break;

        case LearningAnalyticsEvents.LESSON_COMPLETED:
          updates["lessons_completed_count"] = increment(1);
          updates[`daily_activity.${today}.lessons_completed`] = increment(1);
          if (eventData.skillId && eventData.lessonId) {
            updates["completed_lessons"] = arrayUnion(
              `${eventData.skillId}_${eventData.lessonId}`
            );
          }
          break;

        case "xp_earned":
          updates["total_xp"] = increment(eventData.xp || 0);
          updates[`daily_activity.${today}.xp_earned`] = increment(
            eventData.xp || 0
          );
          break;

        case LearningAnalyticsEvents.VOCABULARY_UNLOCKED:
          updates["vocabulary_learned_count"] = increment(eventData.count || 1);
          updates[`daily_activity.${today}.vocabulary_learned`] = increment(
            eventData.count || 1
          );
          if (eventData.words && Array.isArray(eventData.words)) {
            updates["vocabulary_words"] = arrayUnion(...eventData.words);
          }
          break;

        case LearningAnalyticsEvents.PRACTICE_QUESTION_ANSWERED:
          updates["practice_questions_answered"] = increment(1);
          updates[`daily_activity.${today}.practice_questions`] = increment(1);
          break;

        case "correct_answer":
          updates["correct_answers_count"] = increment(1);
          updates[`daily_activity.${today}.correct_answers`] = increment(1);
          break;

        case "incorrect_answer":
          updates["incorrect_answers_count"] = increment(1);
          updates[`daily_activity.${today}.incorrect_answers`] = increment(1);
          break;

        case "attempt_used":
          updates["attempts_used_count"] = increment(1);
          updates[`daily_activity.${today}.attempts_used`] = increment(1);
          break;

        case LearningAnalyticsEvents.DICTIONARY_SEARCHED:
          updates["words_searched_count"] = increment(1);
          updates[`daily_activity.${today}.words_searched`] = increment(1);
          if (eventData.word) {
            updates["searched_words"] = arrayUnion(eventData.word);

            // If detailed search data is available, add to dictionary_searches
            if (!updates["dictionary_searches"] && eventData.searchDetails) {
              updates["dictionary_searches"] = arrayUnion({
                word: eventData.word,
                translation: eventData.searchDetails.translation || null,
                definition: eventData.searchDetails.definition || null,
                timestamp: new Date().toISOString(),
                source: "dictionary",
              });
            }
          }
          break;

        case "flashcard_session_completed":
          updates["flashcard_sessions_count"] = increment(1);
          updates[`daily_activity.${today}.flashcard_sessions`] = increment(1);
          break;

        case "coaching_session_completed":
          updates["coaching_sessions_count"] = increment(1);
          updates[`daily_activity.${today}.coaching_completed`] = increment(1);
          break;
      }

      if (STREAK_QUALIFYING_EVENTS.includes(eventType)) {
        try {
          const streakUpdate = await this.calculateStreak(
            userId,
            today,
            userAnalyticsData
          );
          Object.assign(updates, streakUpdate);
        } catch (error) {
          console.warn("Streak update error:", error);
        }
      }

      // Update streak if they've had activity today
      if (Object.keys(updates).length > 0) {
        try {
          const streakUpdate = await this.calculateStreak(
            userId,
            today,
            userAnalyticsData
          );
          Object.assign(updates, streakUpdate);

          // Only update if there are actual changes to make
          if (Object.keys(updates).length > 0) {
            await updateDoc(userAnalyticsRef, updates);

            // Update the cache with the new values (approximate)
            const newData = { ...userAnalyticsData };
            Object.keys(updates).forEach((key) => {
              if (updates[key] === increment(1)) {
                newData[key] = (newData[key] || 0) + 1;
              } else if (typeof updates[key] !== "object") {
                newData[key] = updates[key];
              }
            });
            this.userAnalyticsCache.set(userId, newData);
          }
        } catch (error) {
          // Handle Firestore permission errors
          if (
            error.message.includes("permission") ||
            error.message.includes("insufficient")
          ) {
            return; // Exit silently
          }
          throw error;
        }
      }
    } catch (error) {
      // Only log actual errors, not permission issues
      if (
        !error.message.includes("permission") &&
        !error.message.includes("insufficient")
      ) {
        console.warn("Analytics update error:", error.message);
      }
    }
  }

  /**
   * Initialize user analytics document
   * Optimized with permission error handling
   */
  async initializeUserAnalytics(userId) {
    if (!this.isTrackingEnabled() || !userId) return;

    try {
      const userAnalyticsRef = doc(this.db, "user_analytics", userId);
      const today = new Date().toISOString().split("T")[0];

      const initialData = {
        userId,
        created_at: serverTimestamp(),
        last_active: today,
        current_streak: 0,
        longest_streak: 0,
        lessons_started_count: 0,
        lessons_completed_count: 0,
        total_xp: 0,
        vocabulary_learned_count: 0,
        practice_questions_answered: 0,
        correct_answers_count: 0,
        incorrect_answers_count: 0,
        attempts_used_count: 0,
        words_searched_count: 0,
        completed_lessons: [],
        vocabulary_words: [],
        searched_words: [],
        dictionary_searches: [],
        daily_activity: {
          [today]: {
            lessons_started: 0,
            lessons_completed: 0,
            xp_earned: 0,
            vocabulary_learned: 0,
            practice_questions: 0,
            correct_answers: 0,
            incorrect_answers: 0,
            attempts_used: 0,
            words_searched: 0,
          },
        },
      };

      await setDoc(userAnalyticsRef, initialData);
      this.userAnalyticsCache.set(userId, initialData);
      return initialData;
    } catch (error) {
      // Handle permission errors
      if (
        error.message.includes("permission") ||
        error.message.includes("insufficient")
      ) {
        // Return default data even on permission error
        return this.getEmptyAnalyticsStats(userId);
      }
      throw error;
    }
  }

  // This is the fixed version of calculateStreak for your enhanced-analytics.service.js
  // Replace your current implementation with this one

  /**
   * Calculate user streak based on daily activity
   * Fixed to properly handle streak_activities field
   */
  async calculateStreak(userId, today, userData = null) {
    try {
      let data = userData;

      if (!data) {
        // Only load from Firestore if not provided
        const userAnalyticsRef = doc(this.db, "user_analytics", userId);
        const userAnalyticsSnap = await getDoc(userAnalyticsRef);

        if (!userAnalyticsSnap.exists()) {
          return {
            current_streak: 1,
            longest_streak: 1,
            last_active: today,
            streak_activities: { [today]: ["initial_activity"] },
          };
        }

        data = userAnalyticsSnap.data();
      }

      const lastActive = data.last_active;
      let currentStreak = data.current_streak || 0;
      let longestStreak = data.longest_streak || 0;

      // If already processed today, no change needed
      if (lastActive === today) {
        return {};
      }

      // Check if there was a qualifying activity today
      const dailyActivity = data.daily_activity || {};
      const todayActivity = dailyActivity[today] || {};

      // Check if any qualifying activities happened today
      const hasQualifyingActivity =
        todayActivity.lessons_completed > 0 ||
        todayActivity.practice_questions > 0 ||
        todayActivity.flashcard_sessions > 0 ||
        todayActivity.coaching_completed > 0;

      if (!hasQualifyingActivity) {
        // No qualifying activity today, don't update streak
        return {};
      }

      // Check if yesterday or streak broken
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayStr = yesterday.toISOString().split("T")[0];

      if (lastActive === yesterdayStr) {
        // Consecutive day with qualifying activity
        currentStreak += 1;
        if (currentStreak > longestStreak) {
          longestStreak = currentStreak;
        }
      } else {
        // Streak broken, start a new one
        currentStreak = 1;
      }

      // Track which activities contributed to today's streak
      const streakActivities = [];
      if (todayActivity.lessons_completed > 0) streakActivities.push("lesson");
      if (todayActivity.practice_questions > 0)
        streakActivities.push("practice");
      if (todayActivity.flashcard_sessions > 0)
        streakActivities.push("flashcard");
      if (todayActivity.coaching_completed > 0)
        streakActivities.push("coaching");

      // Create a proper update object
      const updates = {
        current_streak: currentStreak,
        longest_streak: longestStreak,
        last_active: today,
      };

      // Handle the streak_activities field properly
      if (streakActivities.length > 0) {
        // Initialize streak_activities if it doesn't exist
        if (!data.streak_activities) {
          updates.streak_activities = { [today]: streakActivities };
        } else {
          // Create a copy of the existing streak_activities
          const updatedActivities = { ...data.streak_activities };
          updatedActivities[today] = streakActivities;
          updates.streak_activities = updatedActivities;
        }
      }

      return updates;
    } catch (error) {
      if (
        !error.message.includes("permission") &&
        !error.message.includes("insufficient")
      ) {
        console.warn("Streak calculation error:", error.message);
      }
      return {};
    }
  }

  // Add this to enhanced-analytics.service.js - enhanced getUserAnalyticsSummary method

  /**
   * Get analytics summary for a user with option to bypass cache
   * @param {string} userId - User ID
   * @param {boolean} skipCache - Whether to bypass the cache and fetch directly from Firestore
   * @returns {Promise<object>} User analytics data
   */
  async getUserAnalyticsSummary(userId, skipCache = false) {
    if (!userId) return null;

    try {
      // Skip cache if requested
      if (!skipCache && this.userAnalyticsCache.has(userId)) {
        return this.userAnalyticsCache.get(userId);
      }

      const userAnalyticsRef = doc(this.db, "user_analytics", userId);

      try {
        // Use getDoc with an options param to force fresh data if skipCache is true
        const userAnalyticsSnap = await getDoc(userAnalyticsRef, {
          source: skipCache ? "server" : "default",
        });

        if (userAnalyticsSnap.exists()) {
          const data = userAnalyticsSnap.data();

          // Update cache with fresh data
          this.userAnalyticsCache.set(userId, data);
          return data;
        }

        // Initialize if doesn't exist and tracking is enabled
        if (this.isTrackingEnabled()) {
          const newData = await this.initializeUserAnalytics(userId);
          return newData;
        }

        // Return empty stats if tracking is disabled
        const emptyStats = this.getEmptyAnalyticsStats(userId);
        this.userAnalyticsCache.set(userId, emptyStats);
        return emptyStats;
      } catch (error) {
        // Handle permission errors
        if (
          error.message.includes("permission") ||
          error.message.includes("insufficient")
        ) {
          const emptyStats = this.getEmptyAnalyticsStats(userId);
          this.userAnalyticsCache.set(userId, emptyStats);
          return emptyStats;
        }
        throw error;
      }
    } catch (error) {
      console.warn("Error getting analytics summary:", error.message);
      return this.getEmptyAnalyticsStats(userId);
    }
  }

  /**
   * Get empty analytics stats structure
   */
  getEmptyAnalyticsStats(userId) {
    return {
      userId,
      current_streak: 0,
      longest_streak: 0,
      lessons_started_count: 0,
      lessons_completed_count: 0,
      total_xp: 0,
      vocabulary_learned_count: 0,
      practice_questions_answered: 0,
      correct_answers_count: 0,
      incorrect_answers_count: 0,
      daily_activity: {},
    };
  }

  // Enhanced Analytics Service Fix
  // Replace the getUserDailyActivity method in enhanced-analytics.service.js with this version

  /**
   * Get daily activity history for visualization
   * Enhanced with improved error handling and logging
   */
  async getUserDailyActivity(userId, days = 30) {
    console.log(
      `getUserDailyActivity called for user ${userId} requesting ${days} days`
    );

    if (!userId) {
      console.warn("getUserDailyActivity: No userId provided");
      return [];
    }

    try {
      // Generate cache key based on user and days
      const cacheKey = `${userId}-${days}`;

      // Check cache first but with more detailed logging
      if (this.activityDataCache.has(cacheKey)) {
        const cachedData = this.activityDataCache.get(cacheKey);
        console.log(
          `getUserDailyActivity: Using cached data with ${cachedData.length} entries`
        );
        return cachedData;
      }

      console.log("getUserDailyActivity: Cache miss, fetching from Firestore");

      // Fallback to Firestore
      let userData;

      if (this.userAnalyticsCache.has(userId)) {
        userData = this.userAnalyticsCache.get(userId);
        console.log("getUserDailyActivity: Using cached user data");
      } else {
        try {
          console.log(
            `getUserDailyActivity: Fetching user analytics for ${userId}`
          );
          const userAnalyticsRef = doc(this.db, "user_analytics", userId);
          const userAnalyticsSnap = await getDoc(userAnalyticsRef);

          if (userAnalyticsSnap.exists()) {
            userData = userAnalyticsSnap.data();
            this.userAnalyticsCache.set(userId, userData);
            console.log(
              "getUserDailyActivity: Retrieved user data from Firestore"
            );
          } else {
            console.warn(
              `getUserDailyActivity: No data exists for user ${userId}`
            );
            return [];
          }
        } catch (error) {
          // Handle permission errors
          console.error(
            `getUserDailyActivity: Error fetching user data: ${error.message}`
          );
          if (
            error.message.includes("permission") ||
            error.message.includes("insufficient")
          ) {
            return []; // Return empty array on permission error
          }
          throw error;
        }
      }

      // Log structure of userData to help diagnose issues
      console.log("getUserDailyActivity: User data structure:", {
        hasUserData: !!userData,
        hasDailyActivity: !!(userData && userData.daily_activity),
        dailyActivityKeys:
          userData && userData.daily_activity
            ? Object.keys(userData.daily_activity).length
            : 0,
      });

      const dailyActivity = userData.daily_activity || {};

      // Get dates for the last N days
      const result = [];
      const today = new Date();

      for (let i = 0; i < days; i++) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        const dateStr = date.toISOString().split("T")[0];

        // Check if we have activity for this date
        const hasDailyData = !!dailyActivity[dateStr];

        result.push({
          date: dateStr,
          ...(dailyActivity[dateStr] || {
            lessons_completed: 0,
            lessons_started: 0,
            xp_earned: 0,
            vocabulary_learned: 0,
            practice_questions: 0,
            correct_answers: 0,
            incorrect_answers: 0,
            words_searched: 0,
          }),
        });

        // Log whether we found data for this date
        if (i < 7) {
          // Only log first week to avoid spam
          console.log(
            `getUserDailyActivity: Date ${dateStr}: ${
              hasDailyData ? "Has data" : "No data"
            }`
          );
        }
      }

      console.log(
        `getUserDailyActivity: Generated ${result.length} days of activity data`
      );

      const sortedResult = result.reverse(); // Chronological order

      // Create mock data if results are empty (FOR TESTING ONLY - REMOVE IN PRODUCTION)
      // This helps diagnose whether the issue is with data or with chart rendering
      if (
        sortedResult.length === 0 ||
        !sortedResult.some((day) => day.xp_earned > 0)
      ) {
        console.warn(
          "getUserDailyActivity: No actual activity data found, consider creating test activities"
        );
        /* 
      // Uncomment this code to test with fake data
      const mockData = [];
      for (let i = 0; i < days; i++) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        const dateStr = date.toISOString().split("T")[0];
        
        mockData.push({
          date: dateStr,
          xp_earned: Math.floor(Math.random() * 100),
          lessons_completed: Math.floor(Math.random() * 3),
          vocabulary_learned: Math.floor(Math.random() * 10),
          correct_answers: Math.floor(Math.random() * 20),
          incorrect_answers: Math.floor(Math.random() * 5),
        });
      }
      
      // Use mock data for testing
      this.activityDataCache.set(cacheKey, mockData.reverse());
      console.log("getUserDailyActivity: Using MOCK DATA for testing:", mockData);
      return mockData.reverse();
      */
      }

      // Always log the actual results
      console.log("Analytics service activity data results:", sortedResult);

      // Cache and return
      this.activityDataCache.set(cacheKey, sortedResult);
      return sortedResult;
    } catch (error) {
      console.error(
        `getUserDailyActivity: Unhandled error: ${error.message}`,
        error
      );
      return [];
    }
  }

  /**
   * Extract and track vocabulary words from lesson exercises
   * Optimized for performance with error handling
   */
  async trackLessonVocabulary(userId, exerciseResults, metadata = {}) {
    if (!this.isTrackingEnabled() || !userId || !exerciseResults) {
      return [];
    }

    try {
      // Convert to array if it's not already
      const resultsArray = Array.isArray(exerciseResults)
        ? exerciseResults
        : Object.values(exerciseResults);

      // Filter for correctly answered questions only
      const correctlyAnsweredExercises = resultsArray.filter(
        (exercise) => exercise.isCorrect === true
      );

      if (correctlyAnsweredExercises.length === 0) {
        return [];
      }

      // Extract vocabulary from exercises that have a vocabulary field
      const vocabularyWords = [];
      const seenWords = new Set(); // Track unique words

      correctlyAnsweredExercises.forEach((exercise) => {
        // First check for dedicated vocabulary field (your suggested approach)
        if (exercise.vocabulary && Array.isArray(exercise.vocabulary)) {
          exercise.vocabulary.forEach((word) => {
            if (!seenWords.has(word)) {
              seenWords.add(word);

              // Create vocabulary entry
              vocabularyWords.push({
                word: word,
                translation: exercise.translation || null,
                exerciseType: exercise.type,
                timestamp: new Date().toISOString(),
              });
            }
          });
        }
      });

      if (vocabularyWords.length === 0) {
        return [];
      }

      // Queue vocabulary update for later processing
      this.queueOperation(async () => {
        try {
          const userAnalyticsRef = doc(this.db, "user_analytics", userId);
          const today = new Date().toISOString().split("T")[0];

          await updateDoc(userAnalyticsRef, {
            vocabulary_words: arrayUnion(...vocabularyWords),
            vocabulary_learned_count: increment(vocabularyWords.length),
            [`daily_activity.${today}.vocabulary_learned`]: increment(
              vocabularyWords.length
            ),
          });

          // Track the vocabulary learned event
          this.trackEvent(userId, LearningAnalyticsEvents.VOCABULARY_UNLOCKED, {
            count: vocabularyWords.length,
            words: vocabularyWords.map((w) => w.word),
            skillId: metadata.skillId,
            lessonId: metadata.lessonId,
          });
        } catch (error) {
          // Handle permission errors silently
          if (
            !error.message.includes("permission") &&
            !error.message.includes("insufficient")
          ) {
            throw error;
          }
        }
      });

      return vocabularyWords;
    } catch (error) {
      console.warn("Error tracking vocabulary:", error.message);
      return [];
    }
  }

  /**
   * Track dictionary searches with vocabulary data
   * Optimized for performance with queue handling
   */
  async trackDictionarySearch(userId, word, resultData = null) {
    if (!this.isTrackingEnabled() || !userId || !word) {
      return null;
    }

    try {
      const vocabularyEntry = {
        word: word,
        translation: resultData?.translation || null,
        definition: resultData?.definition || null,
        source: "dictionary",
        timestamp: new Date().toISOString(),
      };

      // Queue dictionary search update
      this.queueOperation(async () => {
        try {
          const today = new Date().toISOString().split("T")[0];
          await updateDoc(doc(this.db, "user_analytics", userId), {
            dictionary_searches: arrayUnion(vocabularyEntry),
            words_searched_count: increment(1),
            [`daily_activity.${today}.words_searched`]: increment(1),
          });
        } catch (error) {
          // Handle permission errors silently
          if (
            !error.message.includes("permission") &&
            !error.message.includes("insufficient")
          ) {
            throw error;
          }
        }
      });

      // Track as regular event
      this.trackEvent(userId, LearningAnalyticsEvents.DICTIONARY_SEARCHED, {
        word: word,
        has_results: resultData != null,
        searchDetails: resultData,
      });

      return vocabularyEntry;
    } catch (error) {
      console.warn("Error tracking dictionary search:", error.message);
      return null;
    }
  }

  // Legacy methods (to ensure compatibility with existing code)

  /**
   * Log a generic event with optional custom parameters
   * Compatibility with existing analytics service - optimized
   */
  logGenericEvent(eventName, eventParams = {}) {
    const user = this.auth.currentUser;
    if (!user || !this.isTrackingEnabled()) return;

    this.trackEvent(user.uid, eventName, eventParams);
  }

  /**
   * Log lesson-related events - optimized
   */
  logLessonEvent(eventType, details) {
    if (!this.isTrackingEnabled()) return;
    this.logGenericEvent(eventType, details);
  }

  /**
   * Log vocabulary-related events - optimized
   */
  logVocabularyEvent(eventType, details) {
    if (!this.isTrackingEnabled()) return;
    this.logGenericEvent(eventType, details);
  }

  /**
   * Log practice session events - optimized
   */
  logPracticeEvent(eventType, details) {
    if (!this.isTrackingEnabled()) return;
    this.logGenericEvent(eventType, details);
  }

  /**
   * Log mistake tracking event - optimized
   */
  logMistakeEvent(questionId, expectedAnswer, userAnswer, questionType) {
    if (!this.isTrackingEnabled()) return;
    this.logGenericEvent(LearningAnalyticsEvents.MISTAKE_MADE, {
      question_id: questionId,
      expected_answer: expectedAnswer,
      user_answer: userAnswer,
      question_type: questionType,
    });
  }
}

// Export as singleton
export const AnalyticsService = new EnhancedAnalyticsService();
export default AnalyticsService;
