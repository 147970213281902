import React from "react";
import { motion } from "framer-motion";
import {
  IoCheckmarkCircle,
  IoStar,
  IoArrowForwardCircle,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  containerVariants,
  stepTransition,
} from "../../../../helpers/animations";

const CompletionScreen = ({
  score,
  totalQuestions,
  correctAnswers,
  attempts,
  onContinue,
}) => {
  const percentage = Math.round((correctAnswers / totalQuestions) * 100) || 0;

  const getResultMessage = () => {
    if (percentage === 100) return "Perfect Score! 🎉";
    if (percentage >= 80) return "Excellent Work! 👏";
    if (percentage >= 60) return "Good Job! 👍";
    return "Keep Practicing! 💪";
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={stepTransition}
      className="completion-screen"
    >
      <div className="border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-b-4 md:border-b-6 lg:border-b-8 xl:border-b-10 2xl:border-b-12 3xl:border-b-14 border-green px-4 py-6 m-4 shadow-2xl bg-white">
        {/* Result Header */}
        <div className="text-center mb-6">
          <IoCheckmarkCircle className="w-20 h-20 text-green mx-auto mb-4" />
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green mb-2">
            {getResultMessage()}
          </h2>
          <p className="text-lg sm:text-xl md:text-2xl text-gray-700">
            Dialogue Complete!
          </p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 gap-2 max-w-xs mx-auto mb-8">
          <div className="stat-box text-center">
            <div className="text-3xl font-bold text-green">{percentage}%</div>
            <p className="text-sm">Accuracy</p>
          </div>
          <div className="stat-box text-center">
            <div className="text-3xl font-bold text-green">
              {correctAnswers}/{totalQuestions}
            </div>
            <p className="text-sm">Correct Answers</p>
          </div>
          <div className="stat-box text-center">
            <div className="text-3xl font-bold text-green">{attempts}</div>
            <p className="text-sm">Total Attempts</p>
          </div>
          <div className="stat-box text-center">
            <div className="flex items-center justify-center gap-1">
              {[...Array(3)].map((_, i) => (
                <IoStar
                  key={i}
                  className={`w-6 h-6 ${
                    i < percentage / 33 ? "text-yellow-400" : "text-gray-300"
                  }`}
                />
              ))}
            </div>
            <p className="text-sm mt-1">Rating</p>
          </div>
        </div>

        {/* Continue Button */}
        <div className="text-center">
          <button
            onClick={onContinue}
            className="bg-white hover:bg-green-dark text-green font-bold py-3 px-8 border-green border-2 border-b-4 
                      text-lg sm:text-xl md:text-2xl transition-all duration-300 inline-flex 
                      items-center gap-2"
          >
            Continue
            <IoArrowForwardCircle className="w-6 h-6" />
          </button>
        </div>

        {/* Additional Options */}
        <div className="mt-6 text-center space-x-4">
          <Link
            to="/practice"
            className="text-green hover:text-green-dark underline text-sm md:text-base"
          >
            Practice Again
          </Link>
          <Link
            to="/units"
            className="text-green hover:text-green-dark underline text-sm md:text-base"
          >
            Return to Dashboard
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default CompletionScreen;
