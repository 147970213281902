import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { motion } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import AudioWord from "../common/audio-word.component.jsx";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

function FillInBlank({ data }) {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Audio state
  const [optionAudioUrls, setOptionAudioUrls] = useState({});
  const [partAudioUrls, setPartAudioUrls] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioKey, setCurrentAudioKey] = useState(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  const audioRef = useRef(new Audio());

  const exerciseData = useMemo(() => data || {}, [data]);

  // Apply audio settings when they change
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Effect to load audio URLs for options
  useEffect(() => {
    const loadAudioUrls = async () => {
      if (!exerciseData?.content?.options) return;

      const urls = {};
      for (const option of exerciseData.content.options) {
        urls[option.text] = await getAudioDownloadUrl(option.audio);
      }
      setOptionAudioUrls(urls);
    };

    loadAudioUrls();
  }, [exerciseData]);

  // Effect to load audio URLs for sentence parts
  useEffect(() => {
    const loadPartAudioUrls = async () => {
      if (!exerciseData?.content?.sentenceParts) return;

      const urls = {};
      for (const part of exerciseData.content.sentenceParts) {
        if (part.type === "blank") {
          if (part.answer && part.answer.audio) {
            urls.blank = await getAudioDownloadUrl(part.answer.audio);
          }
        } else if (part.audio) {
          urls[part.text] = await getAudioDownloadUrl(part.audio);
        }
      }
      setPartAudioUrls(urls);
    };

    loadPartAudioUrls();
  }, [exerciseData]);

  // Initialize exercise when first loaded
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (exerciseData?.content?.options) {
        const blankPart = exerciseData.content.sentenceParts.find(
          (p) => p.type === "blank"
        );
        const correctAnswer = blankPart?.answer?.text;

        const options = exerciseData.content.options.map((opt) => ({
          ...opt,
          isCorrect: opt.text === correctAnswer,
        }));

        setShuffledOptions([...options].sort(() => Math.random() - 0.5));
      }
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [exerciseData]);

  // Play audio for an option
  const handlePlay = (optionText) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const url = optionAudioUrls[optionText];
    if (!url || !audioRef.current) return;

    // If already playing this audio, stop it
    if (isPlaying && currentAudioKey === optionText) {
      audioRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioKey(null);
      return;
    }

    // Set up audio playback
    audioRef.current.src = url;
    audioRef.current.currentTime = 0;

    // Play and update state
    audioRef.current.play().catch((err) => {
      console.error("Audio playback error:", err);
    });

    setIsPlaying(true);
    setCurrentAudioKey(optionText);
  };

  const handlePause = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioKey(null);
    }
  };

  // Handle option selection
  const handleOptionClick = (index) => {
    if (!feedback) {
      setOptionChosen(shuffledOptions[index].text);
    }
  };

  if (!exerciseData?.content?.options) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  if (loading) {
    return (
      <motion.div className="flex flex-col justify-center items-center h-full min-h-[300px]">
        <BeatLoader color="#848717" size={15} />
      </motion.div>
    );
  }

  return (
    <motion.div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12">
        <div className="mb-3 sm:mb-4 font-semibold">{exerciseData.title}</div>

        <div className="relative border-2 border-b-4 max-w-[600px] mx-auto border-black mb-3 sm:mb-4 p-3 sm:p-4">
          <div className="flex flex-row justify-center items-center">
            <span className="text-5xl sm:text-7xl text-outline-black">👩</span>
            <h3 className="text-base sm:text-lg italic text-center font-body">
              {exerciseData.content.sentenceParts?.map((part, index) => {
                if (part.type === "blank") {
                  return (
                    <motion.span
                      key={index}
                      className="inline-block min-w-[80px] sm:min-w-[120px] h-8 sm:h-10 border-b-2 border-gray-400 mx-1"
                    >
                      {optionChosen && (
                        <AudioWord
                          text={optionChosen}
                          audioUrl={optionAudioUrls[optionChosen] || ""}
                          isPlaying={
                            isPlaying && currentAudioKey === optionChosen
                          }
                          audioEnabled={audioEnabled}
                          onPlay={() => handlePlay(optionChosen)}
                        />
                      )}
                    </motion.span>
                  );
                }
                return (
                  <span key={index} className="mx-1">
                    <AudioWord
                      text={part.text}
                      audioUrl={partAudioUrls[part.text] || ""}
                      isPlaying={isPlaying && currentAudioKey === part.text}
                      audioEnabled={audioEnabled}
                      onPlay={() => handlePlay(part.text)}
                    />
                  </span>
                );
              })}
            </h3>
          </div>

          {/* Audio disabled message */}
          {!audioEnabled && (
            <div className="text-center text-xs text-gray-500 mt-2">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 w-full max-w-[600px]">
          {shuffledOptions.map((option, index) => {
            const isChosen = option.text === optionChosen;
            let buttonClass = `transition-all p-3 sm:p-4 rounded-lg border-2 border-b-4 font-body text-xs sm:text-sm
              flex flex-col items-center justify-center min-h-[60px] cursor-pointer focus:outline-none
              transform active:scale-95 active:translate-y-1 ease-in-out `;

            if (feedback) {
              buttonClass += option.isCorrect
                ? " bg-green text-white border-green"
                : isChosen
                ? " bg-white border-red text-red"
                : " bg-white text-gray border-gray";
            } else {
              buttonClass += isChosen
                ? " text-gold border-gold"
                : " bg-white text-gray border-gray hover:text-green hover:border-green hover:bg-white";
            }

            return (
              <button
                key={index}
                onClick={() => handleOptionClick(index)}
                onMouseEnter={() => audioEnabled && handlePlay(option.text)}
                onMouseLeave={handlePause}
                disabled={feedback}
                className={buttonClass}
              >
                {option.text}
                {isPlaying && currentAudioKey === option.text && (
                  <span className="ml-1 text-xs">🔊</span>
                )}
              </button>
            );
          })}
        </div>
      </div>
      <audio ref={audioRef} className="hidden" />
    </motion.div>
  );
}

FillInBlank.propTypes = {
  data: PropTypes.shape({
    day: PropTypes.number,
    question: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        audio: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            url: PropTypes.string,
            storagePath: PropTypes.string,
          }),
        ]),
      })
    ).isRequired,
    topic: PropTypes.string,
    type: PropTypes.string,
    sentenceParts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        audio: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            url: PropTypes.string,
            storagePath: PropTypes.string,
          }),
        ]),
        answer: PropTypes.shape({
          text: PropTypes.string,
          audio: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              url: PropTypes.string,
              storagePath: PropTypes.string,
            }),
          ]),
        }),
      })
    ).isRequired,
    title: PropTypes.string,
    correctAnswer: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default FillInBlank;
