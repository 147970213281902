import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import useBooking from "../../../hooks/useBooking";
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import { DollarSign, Calendar, BarChart, Users } from "lucide-react";
import { formatDateTime } from "../../../utils/bookings/dateUtils";

const EarningsReport = () => {
  const { userBookings, isCoach } = useBooking();
  const [period, setPeriod] = useState("month");
  const [earnings, setEarnings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isCoach) return;

    calculateEarnings();
  }, [userBookings, period, isCoach]);

  const calculateEarnings = () => {
    if (!userBookings || userBookings.length === 0) {
      setEarnings({
        totalEarnings: 0,
        sessionCount: 0,
        sessions: [],
      });
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      // Filter completed sessions only
      const completedSessions = userBookings.filter(
        (booking) =>
          booking.status === "completed" && booking.userRole === "coach"
      );

      // Apply time period filter
      const now = new Date();
      let filteredSessions = [...completedSessions];

      if (period === "week") {
        const lastWeek = new Date(now);
        lastWeek.setDate(lastWeek.getDate() - 7);

        filteredSessions = completedSessions.filter(
          (session) => new Date(session.sessionDateTime) >= lastWeek
        );
      } else if (period === "month") {
        const lastMonth = new Date(now);
        lastMonth.setMonth(lastMonth.getMonth() - 1);

        filteredSessions = completedSessions.filter(
          (session) => new Date(session.sessionDateTime) >= lastMonth
        );
      }

      // Calculate total earnings
      const totalEarnings = filteredSessions.reduce(
        (sum, session) => sum + (session.price || 0),
        0
      );

      // Sort sessions by date (newest first)
      filteredSessions.sort(
        (a, b) => new Date(b.sessionDateTime) - new Date(a.sessionDateTime)
      );

      setEarnings({
        totalEarnings,
        sessionCount: filteredSessions.length,
        sessions: filteredSessions,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error calculating earnings:", error);
      setLoading(false);
    }
  };

  // Group earnings by date
  const getGroupedEarnings = () => {
    if (!earnings || !earnings.sessions || earnings.sessions.length === 0)
      return [];

    const grouped = {};

    earnings.sessions.forEach((session) => {
      const date = format(new Date(session.sessionDateTime), "yyyy-MM-dd");

      if (!grouped[date]) {
        grouped[date] = {
          date,
          displayDate: format(new Date(session.sessionDateTime), "MMM d"),
          earnings: 0,
          sessions: 0,
        };
      }

      grouped[date].earnings += session.price || 0;
      grouped[date].sessions += 1;
    });

    return Object.values(grouped).sort((a, b) => a.date.localeCompare(b.date));
  };

  const groupedData = getGroupedEarnings();

  if (!isCoach) {
    return (
      <div className="bg-white border-2 border-b-4 border-darkgray shadow-md p-4 sm:p-6 text-center">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Coach Earnings Report
        </h2>
        <p className="text-gray-600">Only available for coach accounts.</p>
      </div>
    );
  }

  return (
    <div className="bg-white border-2 border-b-4 border-darkgray shadow-md p-3 sm:p-4 container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 gap-3">
        <h2 className="text-xl font-bold text-black">Earnings Report</h2>

        <div className="flex flex-wrap justify-center gap-2 w-full sm:w-auto">
          <button
            onClick={() => setPeriod("week")}
            className={`flex-1 sm:flex-none px-3 py-1 ${
              period === "week"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            Week
          </button>
          <button
            onClick={() => setPeriod("month")}
            className={`flex-1 sm:flex-none px-3 py-1 ${
              period === "month"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            Month
          </button>
          <button
            onClick={() => setPeriod("all")}
            className={`flex-1 sm:flex-none px-3 py-1 ${
              period === "all"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            All Time
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green"></div>
        </div>
      ) : earnings ? (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
            <div className="bg-darkgray bg-opacity-20 border-darkgray border-2 border-b-4 p-4">
              <div className="flex items-center">
                <div className="bg-green bg-opacity-20 p-3 mr-4">
                  <span>💰</span>
                </div>
                <div>
                  <p className="text-gray-600">Total Earnings</p>
                  <h3 className="text-2xl font-bold">
                    ZAR{earnings.totalEarnings.toFixed(2)}
                  </h3>
                </div>
              </div>
            </div>

            <div className="bg-darkgray bg-opacity-20 border-darkgray border-2 border-b-4 p-4">
              <div className="flex items-center">
                <div className="bg-gold bg-opacity-20 p-3 mr-4">
                  <span>🗓️</span>
                </div>
                <div>
                  <p className="text-gray-600">Sessions Completed</p>
                  <h3 className="text-2xl font-bold">
                    {earnings.sessionCount}
                  </h3>
                </div>
              </div>
            </div>

            <div className="bg-darkgray bg-opacity-20 border-darkgray border-2 border-b-4 p-4">
              <div className="flex items-center">
                <div className="bg-red bg-opacity-20 p-3 mr-4">
                  <span>📈</span>
                </div>
                <div>
                  <p className="text-gray-600">Average per Session</p>
                  <h3 className="text-2xl font-bold">
                    ZAR
                    {earnings.sessionCount > 0
                      ? (
                          earnings.totalEarnings / earnings.sessionCount
                        ).toFixed(2)
                      : "0.00"}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          {groupedData.length > 0 ? (
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-3">Earnings Breakdown</h3>

              <div className="relative overflow-x-auto">
                <div className="flex items-end h-40 mt-6 space-x-1 sm:space-x-2 min-w-[300px]">
                  {groupedData.map((item, index) => {
                    const height =
                      earnings.totalEarnings > 0
                        ? (item.earnings / earnings.totalEarnings) * 100
                        : 0;

                    return (
                      <div
                        key={index}
                        className="flex-1 flex flex-col items-center min-w-[30px]"
                      >
                        <div className="w-full text-center text-xs text-gray mb-1">
                          ${item.earnings.toFixed(0)}
                        </div>
                        <div
                          className="w-full bg-green bg-opacity-70 rounded-t-sm"
                          style={{ height: `${Math.max(5, height)}%` }}
                        ></div>
                        <div className="w-full text-center text-xs mt-1">
                          {item.displayDate}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-8">
                <h3 className="text-lg font-medium mb-3">Recent Sessions</h3>
                <div className="overflow-x-auto -mx-4 px-4">
                  <table className="w-full min-w-[500px]">
                    <thead>
                      <tr className="border-b border-gray">
                        <th className="text-left py-2">Date</th>
                        <th className="text-left py-2">Student</th>
                        <th className="text-left py-2">Duration</th>
                        <th className="text-right py-2">Earnings</th>
                      </tr>
                    </thead>
                    <tbody>
                      {earnings.sessions.slice(0, 5).map((session) => (
                        <tr key={session.id} className="border-b border-gray">
                          <td className="py-2">
                            {formatDateTime(
                              new Date(session.sessionDateTime),
                              "MMM d, yyyy"
                            )}
                          </td>
                          <td className="py-2">
                            {session.studentName || "Student"}
                          </td>
                          <td className="py-2">{session.duration} min</td>
                          <td className="py-2 text-right">
                            ${session.price?.toFixed(2) || "0.00"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8 text-gray">
              No earnings data available for this period.
            </div>
          )}
        </>
      ) : (
        <div className="text-center py-8 text-gray">
          Error loading earnings data. Please try again.
        </div>
      )}
    </div>
  );
};

export default EarningsReport;
