// Performance-optimized and mobile-responsive FlashcardDeck component
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useAuth } from "../../../helpers/UserAuthContextProvider";
import FlashcardService from "../../../services/flashcard/firebase-data-adapter";
import Flashcard from "./flashcard.component";
import { motion } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import { useSafeAnalytics } from "../../../hooks/useSafeAnalytics";
import { updateDoc, doc, increment, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase.utils";

function FlashcardDeck({
  deckId,
  initialMode = "flashcards",
  onProgressUpdate = null,
}) {
  const { user } = useAuth();
  const { trackEvent, calculateBoostedXP } = useSafeAnalytics();

  // Refs for performance optimization
  const pendingOperations = useRef([]);
  const isProcessing = useRef(false);

  // Memoized states to reduce re-renders
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flashcards, setFlashcards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const [userAnswer, setUserAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const [deckDetails, setDeckDetails] = useState(null);
  const [progress, setProgress] = useState({});
  const [shuffled, setShuffled] = useState(false);
  const [debugInfo, setDebugInfo] = useState({
    queriedDeckId: deckId,
    flashcardsFound: 0,
    queryMethod: null,
    errorDetails: null,
  });

  // Batch process pending operations to reduce Firebase calls
  const processPendingOperations = useCallback(async () => {
    if (isProcessing.current || pendingOperations.current.length === 0) return;

    isProcessing.current = true;

    try {
      const operations = [...pendingOperations.current];
      pendingOperations.current = [];

      // Group operations by type to optimize batching
      const progressUpdates = operations.filter((op) => op.type === "progress");
      const xpUpdates = operations.filter((op) => op.type === "xp");
      const events = operations.filter((op) => op.type === "event");

      // Process progress updates in a batch
      if (progressUpdates.length > 0 && user?.uid) {
        const batch = writeBatch(db);

        for (const op of progressUpdates) {
          await FlashcardService.updateFlashcardProgress(
            user.uid,
            op.deckId,
            op.cardId,
            op.level
          );
        }

        await batch.commit();

        // Update local progress state once
        setProgress((prev) => {
          const newProgress = { ...prev };
          for (const op of progressUpdates) {
            newProgress[op.cardId] = {
              ...(prev[op.cardId] || {}),
              knowledgeLevel: op.level,
              lastStudied: new Date().toISOString(),
            };
          }
          return newProgress;
        });
      }

      // Process XP updates as a single operation
      if (xpUpdates.length > 0 && user?.uid) {
        const totalXp = xpUpdates.reduce((sum, op) => sum + op.xp, 0);
        if (totalXp > 0) {
          const userRef = doc(db, "users", user.uid);
          await updateDoc(userRef, {
            total_xp: increment(totalXp),
          });
        }
      }

      // Track events in a batch (if possible)
      if (events.length > 0) {
        for (const event of events) {
          await trackEvent(event.name, event.data, false);
        }
      }

      // Only refresh analytics once at the end if there were operations
      if (operations.length > 0 && onProgressUpdate) {
        onProgressUpdate(deckId);
      }
    } catch (error) {
      console.error("Error processing operations:", error);
    } finally {
      isProcessing.current = false;

      // Check if more operations were added during processing
      if (pendingOperations.current.length > 0) {
        setTimeout(processPendingOperations, 100);
      }
    }
  }, [user?.uid, deckId, trackEvent, onProgressUpdate]);

  // Queue an operation to be processed in batch
  const queueOperation = useCallback(
    (operation) => {
      pendingOperations.current.push(operation);

      // Schedule processing if not already in progress
      if (!isProcessing.current) {
        setTimeout(processPendingOperations, 50);
      }
    },
    [processPendingOperations]
  );

  // Memoized fetch function to avoid recreating it on rerenders
  const fetchFlashcards = useCallback(async () => {
    if (!deckId) return;

    setLoading(true);
    setError(null);
    setDebugInfo((prev) => ({ ...prev, queriedDeckId: deckId }));

    try {
      // Fetch deck details first for context
      const deck = await FlashcardService.getDeckById(deckId);
      setDeckDetails(deck);

      // Try both query methods and use whichever returns results
      let cards = [];
      let queryMethod = "";

      // Method 1: Query by deckId field
      try {
        const byDeckIdField = await FlashcardService.getFlashcards(deckId);
        if (byDeckIdField && byDeckIdField.length > 0) {
          cards = byDeckIdField;
          queryMethod = "deckId field query";
        }
      } catch (err) {
        console.error(`Error querying by deckId field:`, err);
      }

      // Method 2: If method 1 failed, try querying by document ID pattern
      if (cards.length === 0) {
        try {
          const byDocumentId =
            await FlashcardService.getFlashcardsByDocumentIdPattern(deckId);
          if (byDocumentId && byDocumentId.length > 0) {
            cards = byDocumentId;
            queryMethod = "document ID pattern query";
          }
        } catch (err) {
          console.error(`Error querying by document ID pattern:`, err);
        }
      }

      // Update debug info
      setDebugInfo((prev) => ({
        ...prev,
        flashcardsFound: cards.length,
        queryMethod,
      }));

      if (cards.length === 0) {
        const errorMsg = `No flashcards found for deck "${deckId}" using any query method`;
        setError(errorMsg);
        setLoading(false);
        return;
      }

      // Sort cards by order property if available
      const sortedCards = cards.sort((a, b) => (a.order || 0) - (b.order || 0));
      setFlashcards(sortedCards);

      // If user is logged in, fetch their progress for these cards
      if (user?.uid) {
        const userProgress = await FlashcardService.getUserFlashcardProgress(
          user.uid,
          deckId
        );
        setProgress(userProgress);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching flashcards:", error);
      setError(`Error loading flashcards: ${error.message}`);
      setDebugInfo((prev) => ({
        ...prev,
        errorDetails: error.message,
      }));
      setLoading(false);
    }
  }, [deckId, user?.uid]);

  // Load cards on initial mount or deckId change
  useEffect(() => {
    fetchFlashcards();
  }, [fetchFlashcards]);

  // Optimized knowledge level handler
  const handleKnowledgeLevel = useCallback(
    async (level) => {
      if (!user?.uid || !flashcards[currentCardIndex]) return;

      const cardId = flashcards[currentCardIndex].id;

      // Update UI immediately for responsive feel
      setProgress((prevProgress) => ({
        ...prevProgress,
        [cardId]: {
          ...(prevProgress[cardId] || {}),
          knowledgeLevel: level,
          lastStudied: new Date().toISOString(),
        },
      }));

      // Move to next card immediately for better UX
      requestAnimationFrame(() => {
        setShowAnswer(false);
        setShowHint(false);
        setUserAnswer("");
        setIsCorrect(null);

        if (currentCardIndex < flashcards.length - 1) {
          setCurrentCardIndex(currentCardIndex + 1);
        } else {
          // End of deck, loop back to beginning
          setCurrentCardIndex(0);
        }
      });

      // Calculate XP amounts
      const baseXpValues = {
        0: 1, // Don't know
        1: 3, // Learning
        2: 5, // Familiar
        3: 10, // Mastered
      };

      const baseXp = baseXpValues[level] || 0;
      let awardedXp = baseXp;

      try {
        // Calculate boosted XP if applicable (without blocking UI)
        if (baseXp > 0) {
          const xpResult = await calculateBoostedXP(baseXp);
          if (xpResult.hasBoost) {
            awardedXp = xpResult.xp;

            // Queue event for the XP boost
            queueOperation({
              type: "event",
              name: "xp_boost_applied",
              data: {
                baseXP: baseXp,
                boostedXP: awardedXp,
                multiplier: xpResult.multiplier,
                source: "flashcard_knowledge_level",
              },
            });
          }
        }

        // Queue all needed operations for background processing
        if (user?.uid) {
          // Queue progress update
          queueOperation({
            type: "progress",
            deckId,
            cardId,
            level,
          });

          // Queue XP update if applicable
          if (awardedXp > 0) {
            queueOperation({
              type: "xp",
              xp: awardedXp,
            });

            // Queue analytics event
            queueOperation({
              type: "event",
              name: "xp_earned",
              data: {
                xp: awardedXp,
                originalXp: baseXp,
                source: "flashcard_knowledge_level",
                deckId,
                cardId,
                level,
              },
            });
          }
        }
      } catch (error) {
        console.error("Error in knowledge level handling:", error);
      }
    },
    [
      user?.uid,
      deckId,
      flashcards,
      currentCardIndex,
      calculateBoostedXP,
      queueOperation,
    ]
  );

  // Optimized UI interaction handlers
  const handleCardFlip = useCallback(() => {
    setShowAnswer((prev) => !prev);

    // Track flip in analytics if showing answer
    if (!showAnswer && user?.uid) {
      queueOperation({
        type: "event",
        name: "flashcard_flipped",
        data: {
          deckId,
          cardId: flashcards[currentCardIndex]?.id,
        },
      });
    }
  }, [
    showAnswer,
    user?.uid,
    deckId,
    flashcards,
    currentCardIndex,
    queueOperation,
  ]);

  const handleNextCard = useCallback(() => {
    setShowAnswer(false);
    setShowHint(false);
    setUserAnswer("");
    setIsCorrect(null);

    setCurrentCardIndex((prev) => {
      if (prev < flashcards.length - 1) {
        return prev + 1;
      } else {
        return 0; // Loop back to beginning
      }
    });
  }, [flashcards.length]);

  const handlePrevCard = useCallback(() => {
    setShowAnswer(false);
    setShowHint(false);
    setUserAnswer("");
    setIsCorrect(null);

    setCurrentCardIndex((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return flashcards.length - 1; // Loop to end
      }
    });
  }, [flashcards.length]);

  // Get current card knowledge level - memoized to avoid recalculation
  const getCurrentCardKnowledgeLevel = useCallback(() => {
    if (
      !flashcards[currentCardIndex] ||
      !progress[flashcards[currentCardIndex].id]
    ) {
      return 0;
    }
    return progress[flashcards[currentCardIndex].id].knowledgeLevel || 0;
  }, [flashcards, currentCardIndex, progress]);

  // Handle toggle hint with optimized event tracking
  const handleToggleHint = useCallback(() => {
    setShowHint((prev) => {
      const newShowHint = !prev;

      // Track hint usage in analytics when showing hint
      if (newShowHint && user?.uid && flashcards[currentCardIndex]) {
        queueOperation({
          type: "event",
          name: "flashcard_hint_used",
          data: {
            deckId,
            cardId: flashcards[currentCardIndex]?.id,
          },
        });
      }

      return newShowHint;
    });
  }, [user?.uid, deckId, flashcards, currentCardIndex, queueOperation]);

  // Handle checking answer in write mode - optimized
  const handleCheckAnswer = useCallback(() => {
    if (!flashcards[currentCardIndex]) return;

    const correctAnswer =
      flashcards[currentCardIndex].translation.toLowerCase();
    const userInput = userAnswer.toLowerCase().trim();
    const isUserCorrect = userInput === correctAnswer;

    setIsCorrect(isUserCorrect);
    setShowAnswer(true);

    // Update knowledge level based on correctness
    const currentLevel = getCurrentCardKnowledgeLevel();
    let newLevel = currentLevel;

    if (isUserCorrect || showAnswer) {
      // Increase knowledge level if correct, but cap at 3
      newLevel = Math.min(currentLevel + 1, 3);
    } else {
      // Decrease knowledge level if wrong, but floor at 0
      newLevel = Math.max(currentLevel - 1, 0);
    }

    // Only update if level changed
    if (newLevel !== currentLevel) {
      // Queue progress update
      if (user?.uid) {
        queueOperation({
          type: "progress",
          deckId,
          cardId: flashcards[currentCardIndex].id,
          level: newLevel,
        });
      }
    }

    // Track answer check in analytics
    if (user?.uid) {
      queueOperation({
        type: "event",
        name: "flashcard_answer_checked",
        data: {
          deckId,
          cardId: flashcards[currentCardIndex]?.id,
          isCorrect: isUserCorrect,
        },
      });
    }
  }, [
    flashcards,
    currentCardIndex,
    userAnswer,
    showAnswer,
    getCurrentCardKnowledgeLevel,
    user?.uid,
    deckId,
    queueOperation,
  ]);

  // Handle audio playback - optimized
  const handlePlayAudio = useCallback(() => {
    if (user?.uid && flashcards[currentCardIndex]) {
      queueOperation({
        type: "event",
        name: "flashcard_audio_played",
        data: {
          deckId,
          cardId: flashcards[currentCardIndex]?.id,
        },
      });
    }
  }, [user?.uid, deckId, flashcards, currentCardIndex, queueOperation]);

  // Handle shuffle deck - optimized
  const handleShuffleDeck = useCallback(() => {
    setFlashcards((prev) => [...prev].sort(() => Math.random() - 0.5));
    setCurrentCardIndex(0);
    setShuffled(true);

    // Track shuffle in analytics
    if (user?.uid) {
      queueOperation({
        type: "event",
        name: "flashcard_deck_shuffled",
        data: { deckId },
      });
    }
  }, [user?.uid, deckId, queueOperation]);

  // Reset order - optimized to reuse fetch function
  const handleResetOrder = useCallback(() => {
    fetchFlashcards();
    setCurrentCardIndex(0);
    setShuffled(false);
  }, [fetchFlashcards]);

  // Loading state
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-40 sm:min-h-64 py-6 sm:py-12">
        <BeatLoader color={"#848717"} loading={true} size={12} />
        <p className="mt-4 text-gray-600 text-sm sm:text-base">
          Loading flashcards...
        </p>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4 sm:p-6 flex flex-col items-center">
        <div className="text-red-600 text-lg sm:text-xl mb-4">
          😕 Problem Loading Flashcards
        </div>
        <p className="text-gray-800 mb-4 text-sm sm:text-base text-center">
          {error}
        </p>

        <div className="bg-white p-3 sm:p-4 rounded border border-gray-200 text-xs sm:text-sm text-gray-700 mb-4 w-full">
          <h3 className="font-bold mb-2">Debug Information:</h3>
          <p>
            <strong>Deck ID:</strong> {debugInfo.queriedDeckId}
          </p>
          <p>
            <strong>Query Method:</strong>{" "}
            {debugInfo.queryMethod || "None succeeded"}
          </p>
          <p>
            <strong>Cards Found:</strong> {debugInfo.flashcardsFound}
          </p>
          {debugInfo.errorDetails && (
            <p>
              <strong>Error Details:</strong> {debugInfo.errorDetails}
            </p>
          )}
        </div>

        <button
          onClick={fetchFlashcards}
          className="px-4 py-2 bg-green text-white rounded hover:bg-opacity-90 min-h-[44px]"
        >
          Try Again
        </button>
      </div>
    );
  }

  // Show empty state
  if (!flashcards || flashcards.length === 0) {
    return (
      <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 sm:p-6 flex flex-col items-center">
        <div className="text-4xl mb-4">📚</div>
        <p className="text-lg sm:text-xl font-bold mb-2">No Flashcards Found</p>
        <p className="text-gray-600 mb-4 text-center">
          This deck doesn't have any flashcards yet.
        </p>
      </div>
    );
  }

  // Flashcards view
  if (mode === "flashcards") {
    return (
      <div className="flex flex-col items-center px-2 sm:px-0 mx-auto max-w-[600px]">
        {/* Mode toggle */}
        <div className="w-full max-w-md mb-4 sm:mb-6 flex justify-between items-center">
          <div className="flex gap-2 sm:gap-3">
            <button
              className={`px-2 sm:px-3 py-1 rounded-full text-sm font-medium min-h-[36px] ${
                mode === "flashcards"
                  ? "bg-green text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setMode("flashcards")}
            >
              Flashcards
            </button>
            <button
              className={`px-2 sm:px-3 py-1 rounded-full text-sm font-medium min-h-[36px] ${
                mode === "write"
                  ? "bg-green text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setMode("write")}
            >
              Write
            </button>
          </div>
          <div className="flex gap-2">
            <button
              onClick={shuffled ? handleResetOrder : handleShuffleDeck}
              className="text-sm text-gray-600 flex items-center min-h-[36px] px-2"
              title={shuffled ? "Reset original order" : "Shuffle cards"}
            >
              {shuffled ? "🔄 Reset" : "🔀 Shuffle"}
            </button>
          </div>
        </div>

        {/* Progress indicator */}
        <div className="w-full max-w-md mb-3 sm:mb-4 flex flex-col">
          <div className="flex justify-between text-xs sm:text-sm text-gray-600 mb-1">
            <span>
              Card {currentCardIndex + 1} of {flashcards.length}
            </span>
            <span className="truncate ml-2">
              {deckDetails?.name || "Flashcard Deck"}
            </span>
          </div>
          <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
            <div
              className="h-full bg-green"
              style={{
                width: `${((currentCardIndex + 1) / flashcards.length) * 100}%`,
              }}
            ></div>
          </div>
        </div>

        {/* The flashcard */}
        <Flashcard
          card={flashcards[currentCardIndex]}
          isFlipped={showAnswer}
          onFlip={handleCardFlip}
          showHint={showHint}
          onHintToggle={handleToggleHint}
          onPlayAudio={handlePlayAudio}
          knowledgeLevel={getCurrentCardKnowledgeLevel()}
        />

        {/* Navigation controls */}
        <div className="w-full max-w-md flex justify-between mt-4 sm:mt-6 gap-3">
          <button
            onClick={handlePrevCard}
            className="px-3 sm:px-4 py-2 border-2 border-b-4 border-darkgray text-gray rounded-md hover:bg-opacity-60 min-h-[44px] text-sm sm:text-base flex-1"
          >
            Previous
          </button>
          <button
            onClick={handleNextCard}
            className="px-3 sm:px-4 py-2 border-2 border-b-4 border-darkgray text-gray rounded-md hover:bg-opacity-60 min-h-[44px] text-sm sm:text-base flex-1"
          >
            Next
          </button>
        </div>

        {/* Knowledge level controls - only show when answer is visible */}
        {showAnswer && (
          <motion.div
            className="w-full max-w-md mt-6"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <p className="text-center mb-3 text-xs sm:text-sm text-gray-600">
              How well do you know this card?
            </p>
            <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:gap-2 sm:justify-between">
              <button
                onClick={() => handleKnowledgeLevel(0)}
                className="py-2 border-2 border-b-4 border-black text-black rounded-md hover:bg-opacity-60 min-h-[44px] text-xs sm:text-sm"
              >
                Don't Know
              </button>
              <button
                onClick={() => handleKnowledgeLevel(1)}
                className="py-2 border-2 border-b-4 border-gray text-gray rounded-md hover:bg-opacity-60 min-h-[44px] text-xs sm:text-sm"
              >
                Learning
              </button>
              <button
                onClick={() => handleKnowledgeLevel(2)}
                className="py-2 border-2 border-b-4 border-gold text-gold rounded-md hover:bg-opacity-90 min-h-[44px] text-xs sm:text-sm"
              >
                Familiar
              </button>
              <button
                onClick={() => handleKnowledgeLevel(3)}
                className="py-2 border-2 border-b-4 border-green text-green rounded-md hover:bg-opacity-90 min-h-[44px] text-xs sm:text-sm"
              >
                Mastered
              </button>
            </div>
          </motion.div>
        )}
      </div>
    );
  }

  // Write mode
  return (
    <div className="flex flex-col items-center px-2 sm:px-0">
      {/* Mode toggle */}
      <div className="w-full max-w-md mb-4 sm:mb-6 flex justify-between items-center">
        <div className="flex gap-2 sm:gap-3">
          <button
            className={`px-2 sm:px-3 py-1 rounded-full text-sm font-medium min-h-[36px] ${
              mode === "flashcards"
                ? "bg-green text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setMode("flashcards")}
          >
            Flashcards
          </button>
          <button
            className={`px-2 sm:px-3 py-1 rounded-full text-sm font-medium min-h-[36px] ${
              mode === "write"
                ? "bg-green text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setMode("write")}
          >
            Write
          </button>
        </div>
        <div className="flex gap-2">
          <button
            onClick={shuffled ? handleResetOrder : handleShuffleDeck}
            className="text-sm text-gray-600 flex items-center min-h-[36px] px-2"
            title={shuffled ? "Reset original order" : "Shuffle cards"}
          >
            {shuffled ? "🔄 Reset" : "🔀 Shuffle"}
          </button>
        </div>
      </div>

      {/* Progress indicator */}
      <div className="w-full max-w-md mb-3 sm:mb-4 flex flex-col">
        <div className="flex justify-between text-xs sm:text-sm text-gray-600 mb-1">
          <span>
            Card {currentCardIndex + 1} of {flashcards.length}
          </span>
          <span className="truncate ml-2">
            {deckDetails?.name || "Flashcard Deck"}
          </span>
        </div>
        <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-green"
            style={{
              width: `${((currentCardIndex + 1) / flashcards.length) * 100}%`,
            }}
          ></div>
        </div>
      </div>

      {/* Write mode card */}
      <div className="w-full max-w-md border-b-4 border-2 shadow-lg border-black rounded-xl p-3 sm:p-6 bg-white">
        <h3 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-center">
          {flashcards[currentCardIndex].targetWord}
        </h3>

        {/* Show image if available */}
        {flashcards[currentCardIndex].image && (
          <div className="flex justify-center mb-3 sm:mb-4">
            <img
              src={flashcards[currentCardIndex].image}
              alt={flashcards[currentCardIndex].targetWord}
              className="h-24 sm:h-32 object-contain"
            />
          </div>
        )}

        {/* Input field */}
        <div className="mb-3 sm:mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Enter the translation:
          </label>
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            disabled={showAnswer}
            className={`w-full p-2 border ${
              isCorrect === null
                ? "border-gray-300"
                : isCorrect
                ? "border-green bg-green bg-opacity-10"
                : "border-red-500 bg-red-50"
            } rounded focus:outline-none focus:ring-2 focus:ring-green min-h-[44px]`}
            placeholder="Type the translation..."
            onKeyDown={(e) => {
              if (e.key === "Enter" && !showAnswer) {
                handleCheckAnswer();
              }
            }}
          />
        </div>

        {/* Answer feedback */}
        {showAnswer && (
          <div
            className={`p-3 rounded ${
              isCorrect
                ? "bg-green bg-opacity-10 text-green"
                : "bg-red-50 text-red-600"
            } mb-4`}
          >
            <p className="font-medium">
              {isCorrect ? "Correct! 👍" : "Not quite right 🤔"}
            </p>
            <p>
              Correct answer:{" "}
              <span className="font-bold">
                {flashcards[currentCardIndex].translation}
              </span>
            </p>
          </div>
        )}

        {/* Button controls */}
        <div className="flex justify-between mt-3 sm:mt-4 gap-2">
          {!showAnswer ? (
            <>
              <button
                onClick={handleToggleHint}
                className="px-3 sm:px-4 py-2 bg-gold text-white rounded hover:bg-opacity-90 min-h-[44px] flex-1"
              >
                {showHint ? "Hide Hint" : "Show Hint"}
              </button>
              <button
                onClick={handleCheckAnswer}
                disabled={!userAnswer.trim()}
                className={`px-3 sm:px-4 py-2 bg-green text-white rounded min-h-[44px] flex-1 ${
                  !userAnswer.trim()
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-opacity-90"
                }`}
              >
                Check
              </button>
            </>
          ) : (
            <button
              onClick={handleNextCard}
              className="w-full px-3 sm:px-4 py-2 bg-green text-white rounded hover:bg-opacity-90 min-h-[44px]"
            >
              Next Card
            </button>
          )}
        </div>

        {/* Hint */}
        {showHint && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-3 sm:mt-4 p-3 bg-gray-50 border border-gray-200 rounded text-xs sm:text-sm"
          >
            <span className="font-medium">Hint:</span>{" "}
            {flashcards[currentCardIndex].hint ||
              "First letter: " +
                flashcards[currentCardIndex].translation
                  .charAt(0)
                  .toUpperCase()}
          </motion.div>
        )}
      </div>

      {/* Navigation controls */}
      <div className="w-full max-w-md flex justify-between mt-4 sm:mt-6 gap-3">
        <button
          onClick={handlePrevCard}
          className="px-3 sm:px-4 py-2 bg-darkgray hover:bg-gray-300 rounded text-sm sm:text-base min-h-[44px] flex-1"
        >
          Previous
        </button>
        {!showAnswer && (
          <button
            onClick={handleNextCard}
            className="px-3 sm:px-4 py-2 bg-darkgray hover:bg-gray-300 rounded text-sm sm:text-base min-h-[44px] flex-1"
          >
            Skip
          </button>
        )}
      </div>
    </div>
  );
}

export default React.memo(FlashcardDeck);
