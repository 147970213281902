// hooks/useShopWithNotifications.js
import { useState, useEffect, useCallback } from "react";
import { useShopWithPowerUps } from "./useShopWithPowerUps";
import useShopNotifications from "../utils/notifications/shop-notifications-utils";
import ProductEffectService from "../services/power-ups/product-effect-service";

/**
 * Enhanced shop hook that integrates notifications with the shop system
 * This hook should be used in components that interact with the shop
 */
export const useShopWithNotifications = () => {
  const shop = useShopWithPowerUps();
  const notifications = useShopNotifications();
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Register the notification sender with the Product Effect Service
  useEffect(() => {
    // Register the notification methods with the ProductEffectService
    ProductEffectService.registerNotificationSender(notifications);
    console.log("Shop notifications registered with ProductEffectService");

    // Monitor balance for low coins notification
    if (shop.balance > 0 && shop.balance <= 10) {
      notifications.sendLowCoinsNotification(shop.balance);
    }
  }, [notifications, shop.balance]);

  // Function to refresh inventory
  const refreshInventory = useCallback(() => {
    setRefreshTrigger((prev) => prev + 1);
    if (shop.refreshInventory) {
      shop.refreshInventory();
    }
  }, [shop]);

  // Simplify the purchaseProductWithNotification function
  const purchaseProductWithNotification = useCallback(
    async (productId) => {
      try {
        const product = shop.products.find((p) => p.id === productId);
        const result = await shop.purchaseProduct(productId);

        if (result.success && product) {
          notifications.sendPurchaseConfirmedNotification(
            product,
            product.price
          );

          // No need for manual refresh or timeout logic
          // The real-time listener will automatically update the inventory
          console.log(
            "Purchase successful - inventory will update automatically via listener"
          );
        }

        return result;
      } catch (error) {
        console.error("Error in purchaseProductWithNotification:", error);
        return { success: false, message: error.message };
      }
    },
    [shop, notifications]
  );

  // Also simplify the handleInventoryUse function
  const handleInventoryUse = useCallback(
    async (inventoryItemId) => {
      try {
        console.log("Using inventory item:", inventoryItemId);

        // Find the item in inventory
        const item = shop.inventory.find((item) => item.id === inventoryItemId);

        if (!item) {
          console.error("Item not found in inventory");
          return { success: false, message: "Item not found in inventory" };
        }

        // Use ProductEffectService to apply the item effects
        const result = await ProductEffectService.applyItemUseEffects(
          inventoryItemId,
          shop.user?.uid
        );

        // If successful, notification but no manual refresh needed
        if (result.success && item) {
          notifications.sendItemUsedNotification(item);
        }

        return result;
      } catch (error) {
        console.error("Error using inventory item:", error);
        return {
          success: false,
          message: error.message || "An error occurred while using the item",
        };
      }
    },
    [shop.inventory, shop.user, notifications]
  );

  // Enhanced add currency function with notification
  const addCurrencyWithNotification = useCallback(
    async (amount, source = "reward") => {
      const result = await shop.addCurrency(amount, source);

      if (result.success) {
        notifications.sendCoinsReceivedNotification(amount, source);
      }

      return result;
    },
    [shop, notifications]
  );

  // Return the original shop context plus enhanced functions
  return {
    ...shop,
    refreshInventory,
    refreshTrigger,
    purchaseProductWithNotification,
    inventoryItemWithNotification: handleInventoryUse, // Fixed name and implementation
    addCurrencyWithNotification,
    // Include the notification functions directly for convenience
    ...notifications,
  };
};

export default useShopWithNotifications;
