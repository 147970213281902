// src/helpers/CourseContextProvider.jsx

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import { useAuth } from "./UserAuthContextProvider";

export const CourseContext = createContext();

// Normalize course name function - ensures consistent naming throughout the app
const normalizeCourse = (course) => {
  if (course === "Social") return "isiZuluSocial";
  return course || "isiZuluSocial";
};

export const CourseContextProvider = ({ children }) => {
  // Initialize with the normalized default value
  const [courses, setCourses] = useState("isiZuluSocial");
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  // Separate initialization from update to avoid render loops
  useEffect(() => {
    let isMounted = true;
    let isInitialFetch = true;

    const initializeCourse = async () => {
      if (!user?.uid) {
        if (isMounted) setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          // Normalize the course name from the database
          const storedCourse = userData.currentEnroll?.course;
          const normalizedCourse = normalizeCourse(storedCourse);

          // Only update state if the normalized value is different to prevent render loops
          if (isMounted && normalizedCourse !== courses) {
            setCourses(normalizedCourse);
          }

          // If firebase has the old course name, update it silently in the background
          if (storedCourse === "Social") {
            console.log(
              "[CourseProvider] Migrating 'Social' to 'isiZuluSocial' in Firebase"
            );
            await setDoc(
              docRef,
              {
                currentEnroll: {
                  ...(userData.currentEnroll || {}),
                  course: "isiZuluSocial",
                },
              },
              { merge: true }
            );
          }
          // If no course is stored, update with default (only once)
          else if (!storedCourse) {
            await setDoc(
              docRef,
              {
                currentEnroll: {
                  ...(userData.currentEnroll || {}),
                  course: "isiZuluSocial",
                },
              },
              { merge: true }
            );
          }
        } else {
          // Create user document with default course
          await setDoc(
            docRef,
            {
              currentEnroll: {
                course: "isiZuluSocial",
              },
            },
            { merge: true }
          );
        }
      } catch (err) {
        console.error("[CourseProvider] Error initializing course:", err);
        // Always set a default on error
        if (isMounted && courses !== "isiZuluSocial") {
          setCourses("isiZuluSocial");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          isInitialFetch = false;
        }
      }
    };

    initializeCourse();

    // Cleanup function to prevent state updates after unmount
    return () => {
      isMounted = false;
    };
  }, [user]); // Only run when user changes

  // Function to update course (both state and Firebase)
  const updateCourse = async (newCourse) => {
    // Normalize the new course name
    const normalizedCourse = normalizeCourse(newCourse);

    // Prevent unnecessary updates
    if (normalizedCourse === courses) {
      return;
    }

    if (!user?.uid) {
      console.error(
        "[CourseProvider] Cannot update course: No authenticated user"
      );
      return;
    }

    try {
      console.log(`[CourseProvider] Updating course to: ${normalizedCourse}`);
      setLoading(true);

      // Update state
      setCourses(normalizedCourse);

      // Update Firebase
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.exists() ? docSnap.data() : {};

      await setDoc(
        docRef,
        {
          currentEnroll: {
            ...(userData.currentEnroll || {}),
            course: normalizedCourse,
          },
        },
        { merge: true }
      );

      console.log("[CourseProvider] Course updated successfully in Firebase");
    } catch (err) {
      console.error("[CourseProvider] Error updating course:", err);
    } finally {
      setLoading(false);
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      courses,
      setCourses: updateCourse,
      loading,
    }),
    [courses, loading]
  );

  // Only log when courses or loading actually changes
  useEffect(() => {
    console.log(
      `[CourseProvider] Current course: ${courses}, loading: ${loading}`
    );
  }, [courses, loading]);

  return (
    <CourseContext.Provider value={contextValue}>
      {children}
    </CourseContext.Provider>
  );
};

// Custom hook for easier context use
export const useCourse = () => {
  const context = useContext(CourseContext);
  if (context === undefined) {
    throw new Error("useCourse must be used within a CourseContextProvider");
  }
  return context;
};
