import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

export const ProgressControls = ({
  onContinue,
  disabled,
  currentStep,
  totalSteps,
  phase,
}) => {
  // Only show progress indicators in dialogue phase
  const showProgress = phase === "dialogue" && totalSteps > 0;

  // Calculate progress percentage
  const progressPercentage = showProgress
    ? Math.round(((currentStep + 1) / totalSteps) * 100)
    : 0;

  return (
    <div className="progress-controls pb-4 max-w-md mx-auto">
      {/* Progress indicator
      {showProgress && (
        <div className="progress-indicator mb-4">
          <div className="h-1 bg-gray-200 rounded-full w-full overflow-hidden">
            <motion.div
              className="h-full bg-green"
              initial={{ width: 0 }}
              animate={{ width: `${progressPercentage}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className="text-xs text-gray-500 mt-1 text-right">
            {currentStep + 1} / {totalSteps}
          </div>
        </div>
      )} */}

      {/* Continue button */}
      <motion.button
        onClick={onContinue}
        disabled={disabled}
        className={`
          
          ${
            disabled
              ? "capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 border-2 text-gray font-bold hover:text-gray border-otherwhite m-auto transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
              : "capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 border-2 text-green font-bold hover:text-white border-green hover:border-green hover:drop-shadow-3xl hover:bg-green m-auto transform active:scale-95 active:translate-y0 active:border-b-2 ease-in-out border-b-4"
          }
        `}
      >
        {disabled && phase === "dialogue" ? (
          <>
            <span className="animate-pulse">●</span>
            <span>Listening...</span>
          </>
        ) : (
          <>
            <span>{getContinueText(phase)}</span>
            <span className="text-xl">→</span>
          </>
        )}
      </motion.button>
    </div>
  );
};

// Helper to get context-appropriate button text
const getContinueText = (phase) => {
  switch (phase) {
    case "narrator":
      return "Start Conversation";
    case "dialogue":
      return "Continue";
    case "question":
      return "Next Question";
    case "complete":
      return "Finish";
    default:
      return "Continue";
  }
};

ProgressControls.propTypes = {
  onContinue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  phase: PropTypes.string,
};

ProgressControls.defaultProps = {
  disabled: false,
  currentStep: 0,
  totalSteps: 0,
  phase: "dialogue",
};
