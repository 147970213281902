// Utility functions for booking-related notifications
import { formatDistanceToNow } from "date-fns";

// Booking notification types
export const BOOKING_NOTIFICATION_TYPES = {
  BOOKING_CREATED: "booking_created",
  BOOKING_CONFIRMED: "booking_confirmed",
  BOOKING_CANCELLED: "booking_cancelled",
  PAYMENT_RECEIVED: "payment_received",
  PAYMENT_FAILED: "payment_failed",
  REMINDER: "session_reminder",
  SESSION_COMPLETED: "session_completed",
};

// Create a booking creation notification
export const createBookingNotification = (bookingData) => {
  const { coachName, sessionDateTime } = bookingData;

  const sessionDate =
    typeof sessionDateTime === "string"
      ? new Date(sessionDateTime)
      : sessionDateTime instanceof Date
      ? sessionDateTime
      : new Date();

  const formattedDate = sessionDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return {
    title: "Booking Created Successfully",
    message: `You've scheduled a session with ${
      coachName || "your coach"
    } on ${formattedDate}.`,
    type: BOOKING_NOTIFICATION_TYPES.BOOKING_CREATED,
    data: {
      bookingId: bookingData.id,
      coachId: bookingData.coachId,
      sessionDateTime: sessionDate,
    },
    link: `/booking/dashboard`,
  };
};

// Create a payment confirmation notification
export const createPaymentConfirmedNotification = (bookingData) => {
  return {
    title: "Payment Confirmed",
    message: `Your payment for the session with ${
      bookingData.coachName || "your coach"
    } has been confirmed.`,
    type: BOOKING_NOTIFICATION_TYPES.PAYMENT_RECEIVED,
    data: {
      bookingId: bookingData.id,
    },
    link: `/booking/dashboard`,
  };
};

// Create a payment failed notification
export const createPaymentFailedNotification = (bookingData, reason = null) => {
  return {
    title: "Payment Failed",
    message: `There was an issue with your payment for the session with ${
      bookingData.coachName || "your coach"
    }${reason ? `: ${reason}` : "."}`,
    type: BOOKING_NOTIFICATION_TYPES.PAYMENT_FAILED,
    data: {
      bookingId: bookingData.id,
    },
    link: `/booking/dashboard`,
  };
};

// Create a booking cancellation notification
export const createBookingCancelledNotification = (
  bookingData,
  byCoach = false
) => {
  const message = byCoach
    ? `Your session with ${
        bookingData.coachName || "your coach"
      } has been cancelled by the coach.`
    : `You've cancelled your session with ${
        bookingData.coachName || "your coach"
      }.`;

  return {
    title: "Booking Cancelled",
    message,
    type: BOOKING_NOTIFICATION_TYPES.BOOKING_CANCELLED,
    data: {
      bookingId: bookingData.id,
    },
    link: `/booking/dashboard`,
  };
};

// Create a session reminder notification
export const createSessionReminderNotification = (bookingData) => {
  const sessionDate =
    typeof bookingData.sessionDateTime === "string"
      ? new Date(bookingData.sessionDateTime)
      : bookingData.sessionDateTime instanceof Date
      ? bookingData.sessionDateTime
      : new Date();

  const timeUntilSession = formatDistanceToNow(sessionDate, {
    addSuffix: true,
  });

  return {
    title: "Upcoming Session Reminder",
    message: `Your session with ${
      bookingData.coachName || "your coach"
    } is scheduled ${timeUntilSession}.`,
    type: BOOKING_NOTIFICATION_TYPES.REMINDER,
    data: {
      bookingId: bookingData.id,
      sessionDateTime: sessionDate,
    },
    link: `/booking/dashboard`,
  };
};

// Create a session completed notification
export const createSessionCompletedNotification = (bookingData) => {
  return {
    title: "Session Completed",
    message: `Your session with ${
      bookingData.coachName || "your coach"
    } has been marked as completed.`,
    type: BOOKING_NOTIFICATION_TYPES.SESSION_COMPLETED,
    data: {
      bookingId: bookingData.id,
    },
    link: `/booking/dashboard`,
  };
};
