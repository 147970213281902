export const SkillsSocialData = {
  isiZuluSocial: {
    George: [
      {
        courseId: "isiZuluSocial",
        courseName: "Social isiZulu",
        unitId: 1,
        skills: [
          {
            skillId: 1,
            skillNumber: 1,
            name: "Vowels",
            emoji: "🅰️",
            description: "Master vowel sounds and basic phonetics",
            unlocked: true,
            dependencies: [],
            lessons: [
              {
                lessonId: 1,
                lessonNumber: 1,
                name: "Vowels I",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 2,
                lessonNumber: 2,
                name: "Vowels II",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 3,
                lessonNumber: 3,
                name: "Vowels III",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 4,
                lessonNumber: 4,
                name: "Vowels IV",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
            ],
          },
          {
            skillId: 2,
            skillNumber: 2,
            name: "Consonants",
            emoji: "🅰️",
            description: "Master consenents and basic phonetics",
            unlocked: true,
            dependencies: [],
            lessons: [
              {
                lessonId: 1,
                lessonNumber: 1,
                name: "Consonants I",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 2,
                lessonNumber: 2,
                name: "Consonants II",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 3,
                lessonNumber: 3,
                name: "Consonants III",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 4,
                lessonNumber: 4,
                name: "Consonants IV",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
            ],
          },
          {
            skillId: 3,
            skillNumber: 3,
            name: "Ukwazisana",
            emoji: "💬",
            description:
              "Follow along a brief dialogue between two Zulu speakers",
            unlocked: true,
            dependencies: [],
            lessons: [
              {
                lessonId: 1,
                lessonNumber: 1,
                name: "Ukwazisana",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
            ],
          },
          {
            skillId: 4,
            skillNumber: 4,
            name: "Semi-vowels",
            emoji: "🅰️",
            description:
              "Follow along a brief dialogue between two Zulu speakers",
            unlocked: true,
            dependencies: [],
            lessons: [
              {
                lessonId: 1,
                lessonNumber: 1,
                name: "Semi-vowels I",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 2,
                lessonNumber: 2,
                name: "Semi-vowels II",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 3,
                lessonNumber: 3,
                name: "Semi-vowels III",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 4,
                lessonNumber: 4,
                name: "Semi-vowels IV",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
            ],
          },
          {
            skillId: 5,
            skillNumber: 5,
            name: "Clicks",
            emoji: "🅰️",
            description: "Master the various click sounds of isiZulu",
            unlocked: true,
            dependencies: [],
            lessons: [
              {
                lessonId: 1,
                lessonNumber: 1,
                name: "Clicks I",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 2,
                lessonNumber: 2,
                name: "Clicks II",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 3,
                lessonNumber: 3,
                name: "Clicks III",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
              {
                lessonId: 4,
                lessonNumber: 4,
                name: "Clicks IV",
                minScore: 70,
                duration: 5,
                exerciseCount: 12,
              },
            ],
          },
        ],
      },
    ],
  },
};
