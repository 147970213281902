import { useNotifications } from "../../helpers/NotificationsContext";
import { LearningAnalyticsEvents } from "../../firebase/firebase.utils";

// Predefined notification templates
export const NotificationTemplates = {
  // Booking Notifications
  BOOKING_CONFIRMED: {
    title: "👩🏽‍🏫 Booking Confirmed",
    message: "Your session with {expert} on {date} has been confirmed.",
    type: "booking",
  },
  BOOKING_REMINDER: {
    title: "🗓️ Upcoming Session",
    message: "Don't forget your session with {expert} tomorrow at {time}.",
    type: "booking",
  },

  // Achievement Notifications
  ACHIEVEMENT_UNLOCKED: {
    title: "🏆 Achievement Unlocked!",
    message: "You've earned the {achievement} badge. Keep up the great work!",
    type: "achievement",
  },
  STREAK_MILESTONE: {
    title: "🔥 Streak Milestone!",
    message: "Amazing! You've maintained a {days}-day learning streak!",
    type: "achievement",
  },
  LEVEL_UP: {
    title: "🎉 Level Up!",
    message: "Congratulations! You've reached level {level}.",
    type: "achievement",
  },

  // Power-up Notifications
  PURCHASE_CONFIRMED: {
    title: "🧾 Purchase Confirmed",
    message: "You've successfully purchased {item}.",
    type: "purchase",
  },
  LIVES_REPLENISHED: {
    title: "❤️ Lives Replenished",
    message: "Your lives have been fully restored. Time to continue learning!",
    type: "system",
  },

  // Content Notifications
  NEW_COURSE: {
    title: "📚 New Content Available",
    message: "A new course '{courseName}' is now available!",
    type: "content",
  },
  COURSE_UPDATE: {
    title: "🎊 Course Updated",
    message: "The '{courseName}' course has been updated with new content.",
    type: "content",
  },

  // System Notifications
  MEMBERSHIP_UPGRADED: {
    title: "💪 Membership Upgraded",
    message:
      "Your membership has been upgraded to {tier}. Enjoy your new benefits!",
    type: "system",
  },
  DAILY_REMINDER: {
    title: "⏰ Daily Reminder",
    message: "Don't break your streak! Take a few minutes to practice today.",
    type: "reminder",
  },
  WEEKLY_SUMMARY: {
    title: "📊 Weekly Progress Summary",
    message: "This week you completed {lessons} lessons and earned {xp} XP!",
    type: "summary",
  },
};

// Function to create a notification from a template with variable substitution
export const createNotificationFromTemplate = (template, variables = {}) => {
  if (!template) return null;

  // Create a copy of the template
  const notification = { ...template };

  // Replace variables in the message
  if (variables && typeof variables === "object") {
    notification.message = Object.entries(variables).reduce(
      (message, [key, value]) => message.replace(`{${key}}`, value),
      notification.message
    );

    notification.title = Object.entries(variables).reduce(
      (title, [key, value]) => title.replace(`{${key}}`, value),
      notification.title
    );
  }

  return notification;
};

// Hook to easily create notifications from templates
export const useNotificationTemplates = () => {
  const { addNotification } = useNotifications();

  const sendNotification = (templateKey, variables = {}) => {
    const template = NotificationTemplates[templateKey];
    if (!template) {
      console.error(`Notification template "${templateKey}" not found`);
      return null;
    }

    const notification = createNotificationFromTemplate(template, variables);
    return addNotification(notification);
  };

  // Helper functions for common notifications
  const sendBookingConfirmation = (expert, date) => {
    return sendNotification("BOOKING_CONFIRMED", { expert, date });
  };

  const sendAchievementNotification = (achievement) => {
    return sendNotification("ACHIEVEMENT_UNLOCKED", { achievement });
  };

  const sendStreakMilestone = (days) => {
    return sendNotification("STREAK_MILESTONE", { days });
  };

  const sendPurchaseConfirmation = (item) => {
    return sendNotification("PURCHASE_CONFIRMED", { item });
  };

  const sendLivesReplenished = () => {
    return sendNotification("LIVES_REPLENISHED");
  };

  const sendNewCourseNotification = (courseName) => {
    return sendNotification("NEW_COURSE", { courseName });
  };

  const sendDailyReminder = () => {
    return sendNotification("DAILY_REMINDER");
  };

  const sendWeeklySummary = (lessons, xp) => {
    return sendNotification("WEEKLY_SUMMARY", { lessons, xp });
  };

  return {
    sendNotification,
    sendBookingConfirmation,
    sendAchievementNotification,
    sendStreakMilestone,
    sendPurchaseConfirmation,
    sendLivesReplenished,
    sendNewCourseNotification,
    sendDailyReminder,
    sendWeeklySummary,
  };
};

// Map learning analytics events to notification templates
export const analyticsEventToNotification = {
  [LearningAnalyticsEvents.LESSON_COMPLETED]: "ACHIEVEMENT_UNLOCKED",
  [LearningAnalyticsEvents.STREAK_MAINTAINED]: "STREAK_MILESTONE",
  [LearningAnalyticsEvents.EXPERT_SESSION_BOOKED]: "BOOKING_CONFIRMED",
  [LearningAnalyticsEvents.FLASHCARD_MASTERED]: "ACHIEVEMENT_UNLOCKED",
};
