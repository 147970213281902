import React from "react";

function ContactUsCard() {
  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-8 bg-white">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="text-left w-full font-bold font-headers text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Contact Us
        </h1>
        <div className="bg-white w-full">
          <p className="text-sm sm:text-sm md:text-base lg:text-lg">
            Need help with your account? Get help fast via your favorite channel
            within 24hrs.
          </p>

          {/* Contact Options */}
          <div className="mt-4 space-y-2">
            <a
              href="https://wa.me/27836663487?text=Hello,%20I%20need%20help%20with"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center p-3 rounded-md hover:bg-gray-100 transition-colors"
            >
              <span className="text-2xl mr-3">📞</span>
              <p className="text-sm md:text-base">
                Start a <b className="underline">WhatsApp</b> conversation
              </p>
            </a>

            <a
              href="mailto:support@fastfluencyafrica.com"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center p-3 rounded-md hover:bg-gray-100 transition-colors"
            >
              <span className="text-2xl mr-3">📧</span>
              <p className="text-sm md:text-base">
                Email us at:{" "}
                <b className="underline">support@fastfluencyafrica.com</b>
              </p>
            </a>
          </div>
        </div>

        {/* FAQs Section */}
        <h1 className="text-left mt-8 w-full font-bold font-headers text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          FFAQs
        </h1>
        <div className="bg-white w-full">
          <p className="text-sm sm:text-sm md:text-base lg:text-lg">
            Check out our most frequently asked questions to find solutions and
            get the information you need.
          </p>

          <div className="mt-4 space-y-4">
            {/* FAQ Items */}
            <div className="border-b pb-4">
              <p className="font-semibold text-base md:text-lg mb-2">
                The language I want to learn isn't available yet - what can I
                do?
              </p>
              <p className="text-sm md:text-base">
                If your target language is currently unavailable you can always
                notify us so that we can gauge the existing demand for the
                language and if there is enough interest (usually at least 25
                other requests) we will prepare a beta program just for you,
                with limited resources primarily centred around coaching
                sessions, for you to start learning the language you want as
                soon as you can.
              </p>
            </div>

            <div className="border-b pb-4">
              <p className="font-semibold text-base md:text-lg mb-2">
                Why is the Africa continent upside down in your logo?
              </p>
              <p className="text-sm md:text-base">
                Is it? <span className="text-outline-black">🤯</span>
              </p>
            </div>

            <div className="border-b pb-4">
              <p className="font-semibold text-base md:text-lg mb-2">
                What do you mean by "edutainment?"
              </p>
              <p className="text-sm md:text-base">
                This is a portmanteau of education and entertainment. Our
                interpretation means creating educational experiences which have
                entertainment value. This means that even in our purely
                pedagogical products we aspire to make the content as
                entertaining as possible so that even if you aren't interested
                in learning you'll still show up just for the entertainment
                value.
              </p>
            </div>

            <div className="border-b pb-4">
              <p className="font-semibold text-base md:text-lg mb-2">
                For more information and answers
              </p>
              <p className="text-sm md:text-base">
                Visit our official{" "}
                <a href="/ffaqs" target="_blank">
                  <span className="font-semibold underline text-red">
                    FFAQs page
                  </span>
                </a>
              </p>
            </div>
          </div>

          {/* Links Section */}
          <div className="mt-6 space-y-2">
            <a
              href="/privacy"
              target="_blank"
              className="flex flex-row items-center p-3 rounded-md hover:bg-gray-100 transition-colors"
            >
              <span className="text-2xl mr-3">🔒</span>
              <p className="font-semibold text-base md:text-lg">Privacy</p>
            </a>

            <a
              href="/terms"
              target="_blank"
              className="flex flex-row items-center p-3 rounded-md hover:bg-gray-100 transition-colors"
            >
              <span className="text-2xl mr-3">📃</span>
              <p className="font-semibold text-base md:text-lg">
                Terms & Conditions
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsCard;
