import React from "react";
import Register from "./register.component";

const RegisterOverlay = () => {
  return (
    <div className="absolute w-full h-full bg-white bg-opacity-90 flex flex-col justify-center items-center z-50 m-auto top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
      <div className="text-black text-center">
        <h3 className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-semibold mx-4">
          Sign up to access this exclusive members only feature
        </h3>
      </div>
      <div className="mt-4 w-full">
        <Register />
      </div>
    </div>
  );
};

export default RegisterOverlay;
