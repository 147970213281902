import React, { createContext, useContext, useState, useEffect } from "react";
import { db, auth } from "../firebase/firebase.utils";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  limit,
} from "firebase/firestore";
import toast from "react-hot-toast";

// Create a global reference to the addNotification function
let globalAddNotification = null;

// This function allows adding notifications without the React hook context
export const addGlobalNotification = async (notification) => {
  if (!globalAddNotification) {
    console.warn("Global notification function not yet initialized");
    // Queue notification to be sent when available
    queuedNotifications.push(notification);
    return null;
  }

  return globalAddNotification(notification);
};

// Queue for notifications attempted before context is ready
const queuedNotifications = [];

// Create the context
const NotificationsContext = createContext();

// Export the hook to use notifications
export const useNotifications = () => useContext(NotificationsContext);

// Global set to track notifications already sent in this session
// This persists across component mounts/unmounts
const GLOBAL_NOTIFICATION_CACHE = new Set();

const NOTIFICATIONS_IN_PROGRESS = {};

// Helper function to generate a hash from notification content
const generateNotificationHash = (notification) => {
  // Include bookingId in the hash if available to make it more specific
  const bookingId = notification.data?.bookingId || "";
  const content = `${notification.title}|${notification.message}|${
    notification.type || ""
  }|${bookingId}`;

  // Simple hash function
  let hash = 0;
  for (let i = 0; i < content.length; i++) {
    const char = content.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString(36);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [notificationCache, setNotificationCache] = useState(
    new Set(GLOBAL_NOTIFICATION_CACHE)
  );

  // Register the addNotification function globally
  useEffect(() => {
    globalAddNotification = addNotification;

    // Process any queued notifications
    if (queuedNotifications.length > 0) {
      console.log(
        `Processing ${queuedNotifications.length} queued notifications`
      );

      queuedNotifications.forEach((notification) => {
        try {
          addNotification(notification);
        } catch (error) {
          console.error("Error processing queued notification:", error);
        }
      });

      // Clear the queue
      queuedNotifications.length = 0;
    }

    return () => {
      // Clear global reference when provider unmounts
      if (globalAddNotification === addNotification) {
        globalAddNotification = null;
      }
    };
  }, []);

  useEffect(() => {
    // console.log("[NotificationsContext] Setting up notifications listener");

    const user = auth.currentUser;
    if (!user) {
      // console.log(
      //   "[NotificationsContext] No user logged in, skipping listener setup"
      // );
      setNotifications([]);
      setUnreadCount(0);
      setLoading(false);
      return;
    }

    const notificationsRef = collection(db, "notifications");
    const q = query(
      notificationsRef,
      where("userId", "==", user.uid),
      orderBy("createdAt", "desc"),
      limit(100)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      // console.log(
      //   "[NotificationsContext] Received snapshot with",
      //   snapshot.docs.length,
      //   "notifications"
      // );

      const newNotifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setNotifications(newNotifications);
      setUnreadCount(newNotifications.filter((n) => !n.read).length);
      setLoading(false);

      // Update cache with current notification hashes
      const hashes = new Set();
      newNotifications.forEach((notification) => {
        const hash = generateNotificationHash({
          title: notification.title,
          message: notification.message,
          type: notification.type,
          data: notification.data,
        });
        hashes.add(hash);
        GLOBAL_NOTIFICATION_CACHE.add(hash); // Update global cache
      });
      setNotificationCache(hashes);
    });

    return () => {
      // console.log("[NotificationsContext] Cleaning up notifications listener");
      unsubscribe();
    };
  }, [auth.currentUser]);

  // Add a new notification with enhanced deduplication
  const addNotification = async (notification) => {
    try {
      // console.log(
      //   "[NotificationsContext] Attempting to add notification:",
      //   notification
      // );

      const user = auth.currentUser;
      if (!user) {
        // console.log(
        //   "[NotificationsContext] No user logged in, cannot add notification"
        // );
        return null;
      }

      // Generate a hash to check for duplicates
      const contentHash = generateNotificationHash(notification);
      // console.log(
      //   "[NotificationsContext] Generated hash for notification:",
      //   contentHash
      // );

      // CRITICAL ADDITION: Check if this notification is currently being processed
      if (NOTIFICATIONS_IN_PROGRESS[contentHash]) {
        // console.log(
        //   "[NotificationsContext] Notification is already being processed, skipping:",
        //   contentHash
        // );
        return null;
      }

      // Check global cache first (prevents duplicates across component re-renders)
      if (GLOBAL_NOTIFICATION_CACHE.has(contentHash)) {
        // console.log(
        //   "[NotificationsContext] Skipping duplicate notification (global cache hit):",
        //   notification
        // );
        return null;
      }

      // Then check local cache (component state)
      if (notificationCache.has(contentHash)) {
        // console.log(
        //   "[NotificationsContext] Skipping duplicate notification (local cache hit):",
        //   notification
        // );
        return null;
      }

      // Check localStorage to prevent duplicates across page refreshes
      const storageKey = `notification_sent_${contentHash}`;
      if (localStorage.getItem(storageKey)) {
        // console.log(
        //   "[NotificationsContext] Skipping duplicate notification (localStorage hit):",
        //   notification
        // );
        return null;
      }

      // CRITICAL ADDITION: Mark this notification as being processed
      // This prevents race conditions where two calls hit this code simultaneously
      NOTIFICATIONS_IN_PROGRESS[contentHash] = Date.now();

      try {
        // Check the database too (more reliable but slower)
        // First check if we've recently created the same notification
        const currentTime = new Date();
        const fiveMinutesAgo = new Date(currentTime.getTime() - 5 * 60 * 1000);

        const notificationsRef = collection(db, "notifications");
        const q = query(
          notificationsRef,
          where("userId", "==", user.uid),
          where("title", "==", notification.title),
          where("message", "==", notification.message),
          where("createdAt", ">", fiveMinutesAgo)
        );

        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          // console.log(
          //   "[NotificationsContext] Skipping duplicate recent notification (Firestore):",
          //   notification
          // );

          // If found in Firestore but not in our caches, update our caches
          GLOBAL_NOTIFICATION_CACHE.add(contentHash);
          setNotificationCache((prev) => {
            const newCache = new Set(prev);
            newCache.add(contentHash);
            return newCache;
          });
          localStorage.setItem(storageKey, "true");

          // CRITICAL ADDITION: Remove from in-progress
          delete NOTIFICATIONS_IN_PROGRESS[contentHash];

          return snapshot.docs[0].id;
        }
      } catch (queryError) {
        console.warn(
          "[NotificationsContext] Error checking for duplicates:",
          queryError
        );
        // Continue with adding if query fails
      }

      // If we get here, add new notification
      const newNotification = {
        userId: user.uid,
        read: false,
        createdAt: serverTimestamp(),
        contentHash,
        data: notification.data || {}, // Ensure data exists
        ...notification,
      };

      // console.log(
      //   "[NotificationsContext] Adding new notification to Firestore:",
      //   newNotification
      // );

      // CRITICAL ADDITION: Use try/catch to ensure we clean up NOTIFICATIONS_IN_PROGRESS
      let docRef = null;
      try {
        docRef = await addDoc(collection(db, "notifications"), newNotification);
        // console.log(
        //   "[NotificationsContext] Successfully added notification with ID:",
        //   docRef.id
        // );
      } catch (addError) {
        console.error(
          "[NotificationsContext] Error adding to Firestore:",
          addError
        );

        // CRITICAL ADDITION: Remove from in-progress on error
        delete NOTIFICATIONS_IN_PROGRESS[contentHash];

        // Re-throw or handle error as needed
        throw addError;
      }

      // Add to all caches to prevent immediate duplicates
      GLOBAL_NOTIFICATION_CACHE.add(contentHash);
      setNotificationCache((prev) => {
        const newCache = new Set(prev);
        newCache.add(contentHash);
        return newCache;
      });
      localStorage.setItem(storageKey, "true");

      // CRITICAL ADDITION: Remove from in-progress after successful creation
      delete NOTIFICATIONS_IN_PROGRESS[contentHash];

      // Show toast notification
      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md bg-white shadow-lg rounded border-b-4 border-2 border-darkgray w-4/6 pointer-events-auto flex`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div>🔔</div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-black">
                    {notification.title}
                  </p>
                  <p className="mt-1 text-sm text-gray">
                    {notification.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-darkgray">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-red hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Close
              </button>
            </div>
          </div>
        ),
        {
          duration: 5000,
          position: "top-right",
        }
      );

      return docRef?.id || null;
    } catch (error) {
      console.error("[NotificationsContext] Error adding notification:", error);
      return null;
    }
  };

  // Mark a notification as read
  const markAsRead = async (notificationId) => {
    try {
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        read: true,
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Mark all notifications as read
  const markAllAsRead = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const unreadNotifications = notifications.filter((n) => !n.read);

      const promises = unreadNotifications.map((notification) => {
        const notificationRef = doc(db, "notifications", notification.id);
        return updateDoc(notificationRef, { read: true });
      });

      await Promise.all(promises);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  // Delete a notification
  const deleteNotification = async (notificationId) => {
    try {
      const notificationRef = doc(db, "notifications", notificationId);
      await deleteDoc(notificationRef);
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const value = {
    notifications,
    unreadCount,
    loading,
    addNotification,
    markAsRead,
    markAllAsRead,
    deleteNotification,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

// Export for direct access to the context
export default NotificationsContext;
