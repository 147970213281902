import React, { useState } from "react";
import { motion } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5";
import { FaCheck, FaTimes, FaChartBar, FaBook } from "react-icons/fa";

/**
 * Modal for reviewing practice session performance
 * Shows detailed stats by exercise type and vocabulary reviewed
 * Custom tab implementation without react-tabs dependency
 */
const PracticeReviewModal = ({ analyticsData, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Prepare exercise performance data
  const exerciseTypesData = Object.entries(analyticsData.exerciseTypes || {})
    .map(([type, count]) => ({
      type,
      count,
      displayName: type
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
    }))
    .sort((a, b) => b.count - a.count);

  // Calculate accuracy by exercise type (if available)
  const exerciseTypeAccuracy = {};
  if (analyticsData.exerciseResults) {
    Object.entries(analyticsData.exerciseResults).forEach(
      ([exerciseId, result]) => {
        const type = result.type;
        if (!exerciseTypeAccuracy[type]) {
          exerciseTypeAccuracy[type] = { correct: 0, total: 0 };
        }
        if (result.isCorrect) {
          exerciseTypeAccuracy[type].correct += 1;
        }
        exerciseTypeAccuracy[type].total += 1;
      }
    );
  }

  // Prepare vocabulary data
  const vocabularyData = analyticsData.vocabularyLearned || [];

  return (
    <div className="fixed inset-0 bg-black border-b-4 border-2 bg-opacity-50 flex items-center justify-center z-50 p-4">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white p-6 w-full max-w-3xl mx-auto relative border-2 border-b-4 border-darkgray max-h-[80vh] overflow-y-auto"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray hover:text-green"
        >
          <IoCloseSharp size={24} />
        </button>

        <h2 className="text-xl font-bold mb-6 text-center text-green flex items-center justify-center">
          <span className="mr-2">📊</span>
          Practice Session Review
        </h2>

        {/* Custom tab navigation */}
        <div className="mb-4">
          <div className="flex border-b border-darkgray mb-4">
            <button
              className={`px-4 py-2 focus:outline-none ${
                activeTab === 0
                  ? "border-b-2 border-green text-green font-bold"
                  : ""
              }`}
              onClick={() => setActiveTab(0)}
            >
              Performance Summary
            </button>
            {/* <button
              className={`px-4 py-2 focus:outline-none ${
                activeTab === 1
                  ? "border-b-2 border-green text-green font-bold"
                  : ""
              }`}
              onClick={() => setActiveTab(1)}
            >
              Vocabulary Reviewed
            </button> */}
          </div>

          {/* Tab content */}
          <div className="tab-content">
            {/* Performance Summary Tab */}
            {activeTab === 0 && (
              <div className="mb-6">
                <h3 className="text-lg font-bold mb-3">Overall Performance</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                  <div className="p-4 bg-gray bg-opacity-10 rounded border-2 border-b-4 border-gray text-center">
                    <p className="text-2xl font-bold">
                      {analyticsData.questionsAnswered}
                    </p>
                    <p className="text-sm">Total Questions</p>
                  </div>
                  <div className="p-4 bg-green bg-opacity-10 rounded border-2 border-b-4 border-green text-center">
                    <p className="text-2xl font-bold text-green">
                      {analyticsData.correctAnswers}
                    </p>
                    <p className="text-sm">Correct Answers</p>
                  </div>
                  <div className="p-4 bg-red bg-opacity-10 rounded border-2 border-b-4 border-red text-center">
                    <p className="text-2xl font-bold text-red">
                      {analyticsData.incorrectAnswers}
                    </p>
                    <p className="text-sm">Incorrect Answers</p>
                  </div>
                  <div className="p-4 bg-gold bg-opacity-10 rounded border-2 border-b-4 border-gold text-center">
                    <p className="text-2xl font-bold text-gold">
                      {analyticsData.correctAnswers > 0
                        ? Math.round(
                            (analyticsData.correctAnswers /
                              analyticsData.questionsAnswered) *
                              100
                          )
                        : 0}
                      %
                    </p>
                    <p className="text-sm">Accuracy</p>
                  </div>
                </div>

                <h3 className="text-lg font-bold mb-3">
                  Performance by Exercise Type
                </h3>
                <div className="border-2 border-b-4 border-darkgray rounded overflow-hidden">
                  <table className="w-full">
                    <thead className="bg-darkgray bg-opacity-20">
                      <tr>
                        <th className="px-4 py-2 text-left">Exercise Type</th>
                        <th className="px-4 py-2 text-center">Count</th>
                        <th className="px-4 py-2 text-center">Accuracy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exerciseTypesData.map((item, index) => (
                        <tr
                          key={item.type}
                          className={index % 2 === 0 ? "bg-gray-50" : ""}
                        >
                          <td className="px-4 py-3 border-t border-darkgray">
                            {item.displayName}
                          </td>
                          <td className="px-4 py-3 border-t border-darkgray text-center">
                            {item.count}
                          </td>
                          <td className="px-4 py-3 border-t border-darkgray text-center">
                            {exerciseTypeAccuracy[item.type] ? (
                              <div className="flex items-center justify-center">
                                <span
                                  className={
                                    exerciseTypeAccuracy[item.type].correct /
                                      exerciseTypeAccuracy[item.type].total >=
                                    0.7
                                      ? "text-green"
                                      : "text-red"
                                  }
                                >
                                  {Math.round(
                                    (exerciseTypeAccuracy[item.type].correct /
                                      exerciseTypeAccuracy[item.type].total) *
                                      100
                                  )}
                                  %
                                </span>
                                {exerciseTypeAccuracy[item.type].correct /
                                  exerciseTypeAccuracy[item.type].total >=
                                0.7 ? (
                                  <span className="ml-1">✅</span>
                                ) : (
                                  <span className="ml-1">❌</span>
                                )}
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                      {exerciseTypesData.length === 0 && (
                        <tr>
                          <td
                            colSpan="3"
                            className="px-4 py-3 text-center text-gray-500"
                          >
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/*
                <div className="mt-6">
                  <h3 className="text-lg font-bold mb-2">Recommendations</h3>
                  <div className="p-4 bg-green border-b-4 border-2 border-green bg-opacity-10 rounded">
                    <ul className="list-disc list-inside space-y-2">
                      {exerciseTypesData.length > 0 && (
                        <li>
                          You practiced most with{" "}
                          <span className="font-bold">
                            {exerciseTypesData[0].displayName}
                          </span>{" "}
                          exercises.
                        </li>
                      )}
                      {Object.entries(exerciseTypeAccuracy).length > 0 && (
                        <li>
                          {Object.entries(exerciseTypeAccuracy)
                            .filter(
                              ([, stats]) =>
                                stats.total >= 2 &&
                                stats.correct / stats.total < 0.6
                            )
                            .slice(0, 1)
                            .map(([type]) => (
                              <span key={type}>
                                Consider focusing more on{" "}
                                <span className="font-bold">
                                  {type.replace(/_/g, " ")}
                                </span>{" "}
                                exercises to improve.
                              </span>
                            ))}
                        </li>
                      )}
                      <li>
                        Regular practice helps retain vocabulary and grammar
                        patterns.
                      </li>
                    </ul>
                  </div>
                </div>
                */}
              </div>
            )}

            {/* Vocabulary Tab */}
            {/* {activeTab === 1 && (
              <div>
                <div className="mb-4 flex items-center">
                  <span className="mr-2">📔</span>
                  <h3 className="text-lg font-bold">Vocabulary Practiced</h3>
                  <span className="ml-2 bg-gold text-white text-xs font-bold px-2 py-1 rounded">
                    {vocabularyData.length}
                  </span>
                </div>

                {vocabularyData.length > 0 ? (
                  <div className="border border-darkgray rounded overflow-hidden">
                    <table className="w-full">
                      <thead className="bg-darkgray bg-opacity-20">
                        <tr>
                          <th className="px-4 py-2 text-left">Word</th>
                          <th className="px-4 py-2 text-left">Translation</th>
                          <th className="px-4 py-2 text-left">Context</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vocabularyData.map((item, index) => (
                          <tr
                            key={index}
                            className={index % 2 === 0 ? "bg-gray-50" : ""}
                          >
                            <td className="px-4 py-3 border-t border-darkgray font-bold">
                              {item.word}
                            </td>
                            <td className="px-4 py-3 border-t border-darkgray">
                              {item.translation}
                            </td>
                            <td className="px-4 py-3 border-t border-darkgray text-gray">
                              {item.context || "—"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="p-4 bg-gray border-2 border-b-4 border-gray bg-opacity-10 rounded text-center text-gray">
                    <p>No vocabulary data available for this session.</p>
                    <p className="mt-2 text-sm">
                      Vocabulary data is collected from specific exercise types
                      like flashcards, audio questions, translations, and
                      matching exercises.
                    </p>
                  </div>
                )}
              </div>
            )} */}
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-green text-black border-b-4 border-2 border-black font-bold rounded hover:bg-opacity-90 transform active:scale-95"
          >
            Close
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default PracticeReviewModal;
