import { SpeechBubble } from "./speech-bubble";

export const DialogueContent = ({ currentDialogue, toggled }) => (
  <div className="space-y-6">
    {currentDialogue.speechInfo?.map((speechItem, index) => (
      <SpeechBubble
        key={index}
        turn={speechItem.turn}
        gender={speechItem.gender}
        text={speechItem.text}
        translation={speechItem.translation}
        speaker={speechItem.speaker}
        toggled={toggled}
      />
    ))}
  </div>
);
