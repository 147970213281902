import React from "react";
import { Link } from "react-router-dom";
import africanPattern from "../../assets/imgs/african-patterns.png"; // Replace with the actual image path

const RegistrationCard = () => {
  const cardStyle = {
    backgroundImage: `url(${africanPattern})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div className="w-full">
      <div
        data-testid="registration-card"
        className="mt-4 w-full m-auto p-4 sm:p-6 md:p-8 rounded-lg text-right border border-white"
        style={cardStyle}
      >
        <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl">🔥☄️</div>
        <div className="pt-1 pr-2">
          <h1 className="font-headers text-base sm:text-lg md:text-xl text-white text-center">
            <span className="font-semibold uppercase">
              Unlock African Edutainment
            </span>
          </h1>
          <h3 className="font-regular text-xs sm:text-sm md:text-base text-white text-center font-italic font-light mt-1">
            Embark on an Enriching Journey to Discover Vibrant Cultures and Rich
            Vocabulary. 🌱🚀
          </h3>
        </div>
        <Link
          to="/register"
          className="block w-full sm:w-3/4 md:w-1/2 mx-auto mt-4 py-3 px-4 text-center text-white font-bold hover:text-white border-2 border-white hover:border-white hover:bg-green text-sm sm:text-base leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out cursor-pointer"
        >
          Sign Up Now
        </Link>
      </div>
    </div>
  );
};

export default RegistrationCard;
