// components/support/PopularArticles.jsx
import React from "react";
import { Link } from "react-router-dom";
import { getPopularArticles } from "../../zombiedata/support-articles";

const PopularArticles = ({ limit = 5 }) => {
  const articles = getPopularArticles(limit);

  return (
    <div className="bg-gray-50 p-5 rounded-lg">
      <h3 className="font-bold mb-4">Popular Articles</h3>
      <ul className="space-y-2">
        {articles.map((article) => (
          <li key={article.id}>
            <Link
              to={`/support/article/${article.id}`}
              className="text-red hover:underline block py-1"
            >
              {article.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularArticles;
