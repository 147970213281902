// src/components/exercises/layout/exercise-layout.jsx
import React from "react";

/**
 * Main container for all exercise components
 * Ensures consistent sizing and positioning
 */
export const ExerciseContainer = ({ children, className = "" }) => (
  <div
    className={`min-h-[400px] sm:min-h-[500px] md:min-h-[625px] w-full max-w-3xl mx-auto flex items-center justify-center px-2 sm:px-4 ${className}`}
  >
    {children}
  </div>
);

/**
 * Standard content wrapper for exercise components
 * Maintains consistent width and structure
 */
export const ExerciseContent = ({ children, className = "" }) => (
  <div
    className={`w-full max-w-[350px] sm:max-w-[450px] md:max-w-[500px] lg:max-w-[550px] flex flex-col ${className}`}
  >
    {children}
  </div>
);

/**
 * Standard header section for exercises
 */
export const ExerciseHeader = ({ children, className = "" }) => (
  <div className={`mb-3 sm:mb-4 ${className}`}>{children}</div>
);

/**
 * Main body section that expands to fill available space
 */
export const ExerciseBody = ({ children, className = "" }) => (
  <div className={`flex-1 flex flex-col justify-center ${className}`}>
    {children}
  </div>
);

/**
 * Footer section with consistent height
 */
export const ExerciseFooter = ({ children, className = "" }) => (
  <div
    className={`min-h-[60px] sm:min-h-[80px] flex justify-center items-end ${className}`}
  >
    {children}
  </div>
);

/**
 * Consistent question styling
 */
export const QuestionText = ({ children, className = "" }) => (
  <h1
    className={`text-lg sm:text-xl font-bold text-left font-headers text-black mb-3 sm:mb-4 ${className}`}
  >
    {children}
  </h1>
);

/**
 * Consistent sentence/prompt styling
 */
export const PromptBox = ({ children, className = "" }) => (
  <div
    className={`mb-3 sm:mb-4 border-2 border-b-4 border-black font-body p-3 sm:p-5 ${className}`}
  >
    {children}
  </div>
);

/**
 * Options container with consistent grid layout
 */
export const OptionsGrid = ({ children, columns = 2, className = "" }) => (
  <div
    className={`w-full grid grid-cols-1 sm:grid-cols-${columns} gap-2 sm:gap-3 mb-4 sm:mb-6 ${className}`}
  >
    {children}
  </div>
);
