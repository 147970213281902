import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import {
  getMilestoneStyles,
  getMilestoneInlineStyles,
} from "../../../utils/milestones/milestoneStyles";

const MilestoneDetails = ({ milestone, onBack, isModal = false }) => {
  // Get dynamic styles
  const styles = getMilestoneStyles(milestone);
  const inlineStyles = getMilestoneInlineStyles(milestone);

  return (
    <div className={`bg-white ${!isModal ? "min-h-screen" : ""} p-4 sm:p-6`}>
      {/* Header */}
      <div className="mb-6 sm:mb-8">
        <button
          onClick={onBack}
          className={`flex items-center ${styles.text.primaryColor} hover:text-black transition-colors text-sm sm:text-base`}
        >
          <FiArrowLeft className="mr-2" /> {isModal ? "Close" : "Back to Vault"}
        </button>
        <h1
          className={`text-xl sm:text-2xl md:text-3xl font-bold mt-4 ${styles.text.primaryColor}`}
        >
          {milestone.name}
        </h1>
        <p className="text-gray mt-2 text-sm sm:text-base">
          {milestone.subtitle} | Mastery Hours: {milestone.hours}
        </p>
        <h3
          className={`text-lg sm:text-xl md:text-2xl font-bold mt-4 ${styles.text.primaryColor}`}
        >
          {milestone.description}
        </h3>
      </div>

      {/* Competencies Grid */}
      <section className="mb-8 sm:mb-12">
        <h2
          className={`text-lg sm:text-xl md:text-2xl font-semibold ${styles.text.primaryColor} mb-4 sm:mb-6 flex items-center`}
        >
          <span className={`mr-2 ${styles.text.primaryColor}`}>⚡</span>
          Key Competencies
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          {milestone.competencies.map((competency, index) => (
            <div
              key={index}
              className="border-2 rounded shadow-lg border-b-4 p-3 sm:p-6"
              style={{ borderColor: styles.colors.primary }}
            >
              <div className="flex items-center mb-3 sm:mb-4">
                <div className="text-2xl sm:text-3xl mr-2 sm:mr-3">
                  <span style={{ color: styles.colors.primary }}>
                    {competency.icon}
                  </span>
                </div>
                <h3
                  className={`text-base sm:text-lg md:text-xl font-semibold ${styles.text.primaryColor}`}
                >
                  {competency.title}
                </h3>
              </div>
              <p className="text-gray text-sm sm:text-base">
                {competency.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Learning Objectives */}
      <section className="mb-8 sm:mb-12">
        <h2
          className={`text-lg sm:text-xl md:text-2xl font-semibold ${styles.text.primaryColor} mb-4 sm:mb-6 flex items-center`}
        >
          <span className={`${styles.text.primaryColor} mr-2`}>🎯</span>
          Learning Objectives
        </h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4">
          {milestone.learningObjectives.map((objective, index) => (
            <li
              key={index}
              className="flex items-start text-gray bg-white p-3 sm:p-4 rounded shadow-lg border-b-4 border-2 text-sm sm:text-base"
              style={{ borderColor: styles.colors.primary }}
            >
              <span
                style={{ color: styles.colors.primary, marginRight: "0.5rem" }}
              >
                •
              </span>
              {objective}
            </li>
          ))}
        </ul>
      </section>

      {/* Participants & Hours Table */}
      <section className="mb-8 sm:mb-12">
        <h2
          className={`text-lg sm:text-xl md:text-2xl font-semibold ${styles.text.primaryColor} mb-4 sm:mb-6 flex items-center`}
        >
          <span className={`${styles.text.primaryColor} mr-2`}>⏱️</span>
          Recommended Study Hours
        </h2>
        <div
          className="overflow-x-auto rounded shadow-lg border-b-4 border-2"
          style={{ borderColor: styles.colors.primary }}
        >
          <table className="w-full border-collapse">
            <thead>
              <tr style={{ backgroundColor: styles.colors.primary }}>
                <th className="p-2 sm:p-3 text-left text-white text-xs sm:text-sm">
                  Group
                </th>
                <th className="p-2 sm:p-3 text-center text-white text-xs sm:text-sm">
                  Hours
                </th>
                <th className="p-2 sm:p-3 text-center text-white text-xs sm:text-sm">
                  Cumulative
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(milestone.participants).map(
                ([group, data], index) => (
                  <tr key={index} className="border-b border-darkgray">
                    <td className="p-2 sm:p-3 text-gray capitalize text-xs sm:text-sm">
                      {group}
                    </td>
                    <td className="p-2 sm:p-3 text-center text-gray text-xs sm:text-sm">
                      {data.hours}
                    </td>
                    <td className="p-2 sm:p-3 text-center text-gray text-xs sm:text-sm">
                      {data.cumulative}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </section>

      {/* Added navigation section at the bottom for easier access */}
      {!isModal && (
        <div className="mt-8 sm:mt-12 pt-4 sm:pt-6 border-t border-gray">
          <button
            onClick={onBack}
            className={`flex items-center ${styles.text.primaryColor} hover:text-black transition-colors text-sm sm:text-base`}
          >
            <FiArrowLeft className="mr-2" /> Back to Milestone Overview
          </button>
        </div>
      )}
    </div>
  );
};

export default MilestoneDetails;
