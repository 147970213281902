import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Calendar, Users, Clock, BarChart, Home, Menu, X } from "lucide-react";

const BookingNavigation = ({ userRole }) => {
  const location = useLocation();
  const isCoach = userRole === "coach";
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // Close menu when route changes
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  // Common navigation items for both roles
  const commonNavItems = [
    {
      path: "/booking/dashboard",
      label: "Dashboard",
      icon: <span>🏠</span>,
    },
  ];

  // Coach-specific navigation items
  const coachNavItems = [
    {
      path: "/booking/availability",
      label: "My Availability",
      icon: <span>🗓️</span>,
    },
    {
      path: "/booking/earnings",
      label: "Earnings",
      icon: <span>📈</span>,
    },
  ];

  // Student-specific navigation items
  const studentNavItems = [
    {
      path: "/booking/coaches",
      label: "Find Coaches",
      icon: <span>🔍</span>,
    },
  ];

  // Combine appropriate nav items based on user role
  const navItems = [
    ...commonNavItems,
    ...(isCoach ? coachNavItems : studentNavItems),
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center h-20">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green"></div>
      </div>
    );
  }

  return (
    <div className="bg-white border-darkgray border-b-2 shadow-md p-3 sm:p-4 mb-6 relative">
      {/* Mobile menu toggle button */}
      <div className="flex md:hidden justify-between items-center">
        <h2 className="text-lg font-bold">Bookings</h2>
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="p-2 bg-white border-2 border-darkgray rounded-md"
          aria-label={menuOpen ? "Close menu" : "Open menu"}
        >
          {menuOpen ? <X size={20} /> : <Menu size={20} />}
        </button>
      </div>

      {/* Mobile dropdown menu */}
      {menuOpen && (
        <div className="md:hidden absolute left-0 right-0 top-full z-20 bg-white border-darkgray border-b-2 shadow-md p-2 space-y-2">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) =>
                `flex items-center px-4 py-3 transition-colors ${
                  isActive
                    ? "bg-green text-black border-black border-2 border-b-4"
                    : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
                }`
              }
            >
              <span className="mr-2">{item.icon}</span>
              {item.label}
            </NavLink>
          ))}
        </div>
      )}

      {/* Desktop horizontal navigation */}
      <div className="hidden md:flex flex-wrap gap-2">
        {navItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              `flex items-center px-4 py-2 transition-colors ${
                isActive
                  ? "bg-green text-black border-black border-2 border-b-4"
                  : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
              }`
            }
          >
            <span className="mr-2">{item.icon}</span>
            {item.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BookingNavigation;
