// services/power-up-manager.js - Fixed version
import { db } from "../../firebase/firebase.utils";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  Timestamp,
  increment,
  collection,
  query,
  where,
  getDocs,
  arrayUnion,
} from "firebase/firestore";

/**
 * Service to manage all types of power-ups with consistent handling
 */
class PowerUpManager {
  /**
   * Check and update all expired power-ups for a user
   * @param {string} userId - User ID
   * @returns {Promise<{expired: Array, messages: Array}>}
   */
  static async checkAllExpiredPowerUps(userId) {
    if (!userId) return { expired: [], messages: [] };

    try {
      const expiredItems = [];
      const messages = [];

      // 1. Check dictionary search packs
      const dictionaryResult = await this.checkExpiredDictionarySearches(
        userId
      );
      if (dictionaryResult.expired) {
        expiredItems.push({
          type: "dictionary_searches",
          count: dictionaryResult.removedSearches,
        });
        messages.push(dictionaryResult.message);
      }

      // 2. Check XP boosters
      const xpResult = await this.checkExpiredXPBoost(userId);
      if (xpResult.expired) {
        expiredItems.push({
          type: "xp_boost",
          multiplier: xpResult.oldMultiplier,
        });
        messages.push(xpResult.message);
      }

      // 3. Check unlimited dictionary access
      const unlimitedResult = await this.checkExpiredUnlimitedDictionary(
        userId
      );
      if (unlimitedResult.expired) {
        expiredItems.push({
          type: "dictionary_unlimited",
        });
        messages.push(unlimitedResult.message);
      }

      // 4. Check streak freeze (expires daily)
      const streakResult = await this.checkExpiredStreakFreeze(userId);
      if (streakResult.expired) {
        expiredItems.push({
          type: "streak_freeze",
        });
        messages.push(streakResult.message);
      }

      return {
        expired: expiredItems,
        messages,
      };
    } catch (error) {
      console.error("Error checking expired power-ups:", error);
      return { expired: [], messages: [] };
    }
  }

  /**
   * Check for expired dictionary searches
   * @param {string} userId - User ID
   * @returns {Promise<{expired: boolean, removedSearches: number, message: string}>}
   */
  static async checkExpiredDictionarySearches(userId) {
    if (!userId) return { expired: false, removedSearches: 0 };

    try {
      // Check searchCounts for expired additional searches
      const searchCountRef = doc(db, "searchCounts", userId);
      const searchCountSnap = await getDoc(searchCountRef);

      if (!searchCountSnap.exists()) {
        return { expired: false, removedSearches: 0 };
      }

      const data = searchCountSnap.data();

      // If there are no additional searches or no expiry, nothing to do
      if (!data.additionalSearches || !data.additionalSearchesExpiry) {
        return { expired: false, removedSearches: 0 };
      }

      // Check if additional searches have expired
      const expiryDate =
        data.additionalSearchesExpiry instanceof Timestamp
          ? data.additionalSearchesExpiry.toDate()
          : new Date(data.additionalSearchesExpiry);

      const now = new Date();

      // If expired, remove the additional searches
      if (expiryDate < now) {
        const removedSearches = data.additionalSearches;

        await updateDoc(searchCountRef, {
          additionalSearches: 0,
          additionalSearchesExpiry: null,
          lastUpdated: serverTimestamp(),
        });

        return {
          expired: true,
          removedSearches,
          message: `Your ${removedSearches} additional dictionary searches have expired`,
        };
      }

      return { expired: false, removedSearches: 0 };
    } catch (error) {
      console.error("Error checking expired dictionary searches:", error);
      return { expired: false, removedSearches: 0, error };
    }
  }

  /**
   * Check for expired XP boost
   * @param {string} userId - User ID
   * @returns {Promise<{expired: boolean, oldMultiplier: number, message: string}>}
   */
  static async checkExpiredXPBoost(userId) {
    if (!userId) return { expired: false, oldMultiplier: 1 };

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { expired: false, oldMultiplier: 1 };
      }

      const userData = userSnap.data();

      // If no XP boost or no expiry, nothing to do
      if (
        !userData.xpMultiplier ||
        userData.xpMultiplier <= 1 ||
        !userData.xpBoostExpiresAt
      ) {
        return { expired: false, oldMultiplier: 1 };
      }

      // Check if XP boost has expired
      const expiryDate =
        userData.xpBoostExpiresAt instanceof Timestamp
          ? userData.xpBoostExpiresAt.toDate()
          : new Date(userData.xpBoostExpiresAt);

      const now = new Date();

      // If expired, reset to normal
      if (expiryDate < now) {
        const oldMultiplier = userData.xpMultiplier;

        await updateDoc(userRef, {
          xpMultiplier: 1,
          xpBoostExpiresAt: null,
        });

        return {
          expired: true,
          oldMultiplier,
          message: `Your ${oldMultiplier}× XP boost has expired`,
        };
      }

      return { expired: false, oldMultiplier: 1 };
    } catch (error) {
      console.error("Error checking expired XP boost:", error);
      return { expired: false, oldMultiplier: 1, error };
    }
  }

  /**
   * Check for expired unlimited dictionary access
   * @param {string} userId - User ID
   * @returns {Promise<{expired: boolean, message: string}>}
   */
  static async checkExpiredUnlimitedDictionary(userId) {
    if (!userId) return { expired: false };

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { expired: false };
      }

      const userData = userSnap.data();

      // If no unlimited dictionary access, nothing to do
      if (!userData.dictionaryUnlockedUntil) {
        return { expired: false };
      }

      // Check if unlimited access has expired
      const expiryDate =
        userData.dictionaryUnlockedUntil instanceof Timestamp
          ? userData.dictionaryUnlockedUntil.toDate()
          : new Date(userData.dictionaryUnlockedUntil);

      const now = new Date();

      // If expired, reset
      if (expiryDate < now) {
        await updateDoc(userRef, {
          dictionaryUnlockedUntil: null,
        });

        return {
          expired: true,
          message: `Your unlimited dictionary access has expired`,
        };
      }

      return { expired: false };
    } catch (error) {
      console.error(
        "Error checking expired unlimited dictionary access:",
        error
      );
      return { expired: false, error };
    }
  }

  /**
   * Check for expired streak freeze
   * @param {string} userId - User ID
   * @returns {Promise<{expired: boolean, message: string}>}
   */
  static async checkExpiredStreakFreeze(userId) {
    if (!userId) return { expired: false };

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { expired: false };
      }

      const userData = userSnap.data();

      // If no streak freeze applied, nothing to do
      if (!userData.streakFreezeApplied) {
        return { expired: false };
      }

      // Check if streak freeze has expired (should be just for today)
      const freezeDate =
        userData.streakFreezeApplied instanceof Timestamp
          ? userData.streakFreezeApplied.toDate()
          : new Date(userData.streakFreezeApplied);

      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const freezeDay = new Date(
        freezeDate.getFullYear(),
        freezeDate.getMonth(),
        freezeDate.getDate()
      );

      // If the freeze day is before today, it has expired
      if (freezeDay < today) {
        await updateDoc(userRef, {
          streakFreezeApplied: null,
        });

        return {
          expired: true,
          message: `Your streak freeze protection has expired`,
        };
      }

      return { expired: false };
    } catch (error) {
      console.error("Error checking expired streak freeze:", error);
      return { expired: false, error };
    }
  }

  /**
   * Get all active power-ups for a user
   * @param {string} userId - User ID
   * @returns {Promise<Array>} - Array of active power-ups
   */
  static async getActivePowerUps(userId) {
    if (!userId) return [];

    try {
      const activePowerUps = [];

      // Get user data for most power-ups
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return [];
      }

      const userData = userSnap.data();
      const now = new Date();

      // 1. Check XP boost
      if (userData.xpMultiplier > 1 && userData.xpBoostExpiresAt) {
        const expiryDate =
          userData.xpBoostExpiresAt instanceof Timestamp
            ? userData.xpBoostExpiresAt.toDate()
            : new Date(userData.xpBoostExpiresAt);

        if (expiryDate > now) {
          activePowerUps.push({
            type: "xp",
            name: "XP Boost",
            description: `${userData.xpMultiplier}× XP multiplier`,
            expiresAt: expiryDate,
            icon: "⚡",
            color: "gold",
          });
        }
      }

      // 2. Check unlimited dictionary
      if (userData.dictionaryUnlockedUntil) {
        const expiryDate =
          userData.dictionaryUnlockedUntil instanceof Timestamp
            ? userData.dictionaryUnlockedUntil.toDate()
            : new Date(userData.dictionaryUnlockedUntil);

        if (expiryDate > now) {
          activePowerUps.push({
            type: "dictionary",
            name: "Dictionary Boost",
            description: `Unlimited searches`,
            expiresAt: expiryDate,
            icon: "📚",
            color: "green",
          });
        }
      }

      // 3. Check streak freeze
      if (userData.streakFreezeApplied) {
        const freezeDate =
          userData.streakFreezeApplied instanceof Timestamp
            ? userData.streakFreezeApplied.toDate()
            : new Date(userData.streakFreezeApplied);

        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        const freezeDay = new Date(
          freezeDate.getFullYear(),
          freezeDate.getMonth(),
          freezeDate.getDate()
        );

        if (freezeDay.getTime() === today.getTime()) {
          // Streak freeze expires at the end of the day
          const endOfDay = new Date(today);
          endOfDay.setHours(23, 59, 59, 999);

          activePowerUps.push({
            type: "streak",
            name: "Streak Freeze",
            description: "Your streak is protected for today",
            expiresAt: endOfDay,
            icon: "🧊",
            color: "blue",
          });
        }
      }

      // 4. Check dictionary search packs
      const searchCountRef = doc(db, "searchCounts", userId);
      const searchCountSnap = await getDoc(searchCountRef);

      if (searchCountSnap.exists()) {
        const data = searchCountSnap.data();

        if (data.additionalSearches > 0 && data.additionalSearchesExpiry) {
          const expiryDate =
            data.additionalSearchesExpiry instanceof Timestamp
              ? data.additionalSearchesExpiry.toDate()
              : new Date(data.additionalSearchesExpiry);

          if (expiryDate > now) {
            activePowerUps.push({
              type: "dictionary_searches",
              name: "Dictionary Searches",
              description: `${data.additionalSearches} additional searches`,
              expiresAt: expiryDate,
              icon: "🔍",
              color: "gold",
            });
          }
        }
      }

      // 5. Check upcoming coaching sessions (not yet used)
      const inventoryRef = collection(db, "inventory");
      const coachingQuery = query(
        inventoryRef,
        where("userId", "==", userId),
        where("category", "==", "coaching"),
        where("isUsed", "==", false)
      );

      const coachingSnap = await getDocs(coachingQuery);

      if (!coachingSnap.empty) {
        coachingSnap.forEach((doc) => {
          const coaching = doc.data();
          activePowerUps.push({
            type: "coaching",
            name: "Language Coaching",
            description: coaching.name || "Available coaching session",
            id: doc.id,
            icon: "👨‍🏫",
            color: "purple",
          });
        });
      }

      // 6. Get available streak savers
      if (userData.streakSaversRemaining > 0) {
        activePowerUps.push({
          type: "streak_savers",
          name: "Streak Savers",
          description: `${userData.streakSaversRemaining} available`,
          count: userData.streakSaversRemaining,
          icon: "🔥",
          color: "red",
        });
      }

      return activePowerUps;
    } catch (error) {
      console.error("Error getting active power-ups:", error);
      return [];
    }
  }

  /**
   * Get available inventory items by category
   * @param {string} userId - User ID
   * @param {string} category - Item category
   * @returns {Promise<Array>} - Array of unused inventory items
   */
  static async getAvailableInventoryItems(userId, category) {
    if (!userId) return [];

    try {
      const inventoryRef = collection(db, "inventory");
      const itemQuery = query(
        inventoryRef,
        where("userId", "==", userId),
        where("category", "==", category),
        where("isUsed", "==", false)
      );

      const itemSnap = await getDocs(itemQuery);
      const items = [];

      itemSnap.forEach((doc) => {
        items.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return items;
    } catch (error) {
      console.error(`Error getting ${category} inventory items:`, error);
      return [];
    }
  }

  /**
   * Apply an XP booster
   * @param {string} userId - User ID
   * @param {number} multiplier - XP multiplier
   * @param {number} durationHours - Duration in hours
   * @returns {Promise<{success: boolean, message: string, expiresAt: Date|null}>}
   */
  static async applyXPBoost(userId, multiplier = 2, durationHours = 24) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { success: false, message: "User not found" };
      }

      // Calculate expiry time
      const expiryDate = new Date();
      expiryDate.setHours(expiryDate.getHours() + durationHours);

      // Update user with XP boost
      await updateDoc(userRef, {
        xpMultiplier: multiplier,
        xpBoostExpiresAt: expiryDate,
      });

      return {
        success: true,
        message: `Applied ${multiplier}× XP boost for ${durationHours} hours`,
        expiresAt: expiryDate,
      };
    } catch (error) {
      console.error("Error applying XP boost:", error);
      return { success: false, message: "Failed to apply XP boost" };
    }
  }

  /**
   * Apply a streak freeze for today
   * @param {string} userId - User ID
   * @param {boolean} fromInventory - Whether this is called from an inventory item (bypass savers check)
   * @returns {Promise<{success: boolean, message: string}>}
   */
  static async applyStreakFreeze(userId, fromInventory = false) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { success: false, message: "User not found" };
      }

      const userData = userSnap.data();

      // Check if streak freeze is already applied for today
      if (userData.streakFreezeApplied) {
        const freezeDate =
          userData.streakFreezeApplied instanceof Timestamp
            ? userData.streakFreezeApplied.toDate()
            : new Date(userData.streakFreezeApplied);

        const now = new Date();
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        const freezeDay = new Date(
          freezeDate.getFullYear(),
          freezeDate.getMonth(),
          freezeDate.getDate()
        );

        if (freezeDay.getTime() === today.getTime()) {
          return {
            success: false,
            message: "Streak freeze already active for today",
          };
        }
      }

      // If using from inventory, bypass the streakSaversRemaining check
      if (!fromInventory) {
        // Check if user has streak savers available
        if (
          !userData.streakSaversRemaining ||
          userData.streakSaversRemaining <= 0
        ) {
          return { success: false, message: "No streak savers available" };
        }

        // Decrement streak savers remaining
        await updateDoc(userRef, {
          streakFreezeApplied: new Date(),
          streakSaversRemaining: increment(-1),
        });
      } else {
        // Just apply the streak freeze without checking/decrementing streakSaversRemaining
        await updateDoc(userRef, {
          streakFreezeApplied: new Date(),
        });
      }

      return {
        success: true,
        message: "Streak freeze applied for today",
      };
    } catch (error) {
      console.error("Error applying streak freeze:", error);
      return { success: false, message: "Failed to apply streak freeze" };
    }
  }

  /**
   * Apply unlimited dictionary access
   * @param {string} userId - User ID
   * @param {number} durationHours - Duration in hours
   * @returns {Promise<{success: boolean, message: string, expiresAt: Date|null}>}
   */
  static async applyUnlimitedDictionary(userId, durationHours = 24) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      const userRef = doc(db, "users", userId);

      // Calculate expiry time
      const expiryDate = new Date();
      expiryDate.setHours(expiryDate.getHours() + durationHours);

      // Update user with unlimited dictionary access
      await updateDoc(userRef, {
        dictionaryUnlockedUntil: expiryDate,
      });

      return {
        success: true,
        message: `Unlimited dictionary access for ${durationHours} hours`,
        expiresAt: expiryDate,
      };
    } catch (error) {
      console.error("Error applying unlimited dictionary access:", error);
      return {
        success: false,
        message: "Failed to apply unlimited dictionary access",
      };
    }
  }

  /**
   * Add additional dictionary searches with expiration
   * @param {string} userId - User ID
   * @param {number} searches - Number of searches to add
   * @param {number} durationMinutes - Duration in minutes (default: 60)
   * @returns {Promise<{success: boolean, message: string, expiresAt: Date|null}>}
   */
  static async addDictionarySearches(userId, searches, durationMinutes = 60) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      const searchCountRef = doc(db, "searchCounts", userId);
      const searchCountSnap = await getDoc(searchCountRef);

      // Calculate expiry time
      const expiryDate = new Date();
      expiryDate.setMinutes(expiryDate.getMinutes() + durationMinutes);

      const today = new Date().toISOString().split("T")[0];

      if (searchCountSnap.exists()) {
        const data = searchCountSnap.data();

        // If the record is for today, update it
        if (data.date === today) {
          // Get current additional searches
          const currentAdditional = data.additionalSearches || 0;

          // Update with new searches and expiry
          await updateDoc(searchCountRef, {
            additionalSearches: currentAdditional + searches,
            additionalSearchesExpiry: expiryDate,
            lastUpdated: serverTimestamp(),
          });
        } else {
          // If it's from a previous day, create a new record
          await setDoc(searchCountRef, {
            count: 0,
            additionalSearches: searches,
            additionalSearchesExpiry: expiryDate,
            date: today,
            lastUpdated: serverTimestamp(),
            userId: userId,
          });
        }
      } else {
        // Create new record if it doesn't exist
        await setDoc(searchCountRef, {
          count: 0,
          additionalSearches: searches,
          additionalSearchesExpiry: expiryDate,
          date: today,
          lastUpdated: serverTimestamp(),
          userId: userId,
        });
      }

      return {
        success: true,
        message: `Added ${searches} dictionary searches for ${durationMinutes} minutes`,
        expiresAt: expiryDate,
      };
    } catch (error) {
      console.error("Error adding additional searches:", error);
      return { success: false, message: "Failed to add dictionary searches" };
    }
  }

  /**
   * Add streak savers
   * @param {string} userId - User ID
   * @param {number} count - Number of streak savers to add
   * @returns {Promise<{success: boolean, message: string}>}
   */
  static async addStreakSavers(userId, count = 1) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      const userRef = doc(db, "users", userId);

      // Update streak savers count
      await updateDoc(userRef, {
        streakSaversRemaining: increment(count),
      });

      return {
        success: true,
        message: `Added ${count} streak saver${count !== 1 ? "s" : ""}`,
      };
    } catch (error) {
      console.error("Error adding streak savers:", error);
      return { success: false, message: "Failed to add streak savers" };
    }
  }

  /**
   * Add lives
   * @param {string} userId - User ID
   * @param {number} count - Number of lives to add
   * @returns {Promise<{success: boolean, message: string}>}
   */
  static async addLives(userId, count = 5) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      const userRef = doc(db, "users", userId);

      // Update lives count
      await updateDoc(userRef, {
        livesRemaining: increment(count),
      });

      return {
        success: true,
        message: `Added ${count} extra ${count === 1 ? "life" : "lives"}`,
      };
    } catch (error) {
      console.error("Error adding lives:", error);
      return { success: false, message: "Failed to add lives" };
    }
  }

  /**
   * Unlock audio pack
   * @param {string} userId - User ID
   * @param {string} audioPackId - Audio pack ID
   * @returns {Promise<{success: boolean, message: string}>}
   */
  static async unlockAudioPack(userId, audioPackId) {
    if (!userId || !audioPackId) {
      return { success: false, message: "Missing required parameters" };
    }

    try {
      const userRef = doc(db, "users", userId);

      // Unlock audio content
      await updateDoc(userRef, {
        unlockedAudioPacks: arrayUnion(audioPackId),
      });

      return {
        success: true,
        message: `Unlocked audio pack: ${audioPackId}`,
      };
    } catch (error) {
      console.error("Error unlocking audio pack:", error);
      return { success: false, message: "Failed to unlock audio pack" };
    }
  }

  /**
   * Create coaching session
   * @param {string} userId - User ID
   * @param {string} name - Session name
   * @param {string} description - Session description
   * @param {string} imagePath - Image path
   * @returns {Promise<{success: boolean, message: string}>}
   */
  static async createCoachingSession(userId, name, description, imagePath) {
    if (!userId) return { success: false, message: "User ID is required" };

    try {
      // Implementation to add inventory item
      // This is placeholder code - need real implementation based on your db
      console.log("Creating coaching session for user:", userId);

      return {
        success: true,
        message: "Coaching session added to your inventory",
      };
    } catch (error) {
      console.error("Error creating coaching session:", error);
      return { success: false, message: "Failed to create coaching session" };
    }
  }

  // This is an updated method to add to the PowerUpManager class

  /**
   * Get the current dictionary search status including expiration info
   * @param {string} userId - User ID
   * @returns {Promise<object>} Dictionary search status
   */
  static async getDictionarySearchStatus(userId) {
    if (!userId) return null;

    try {
      // First check unlimited access
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      let result = {
        hasUnlimited: false,
        unlimitedExpiresAt: null,
        additionalSearches: 0,
        additionalSearchesExpiresAt: null,
        totalSearchesRemaining: 0,
        baseSearches: 0,
        usedToday: 0,
      };

      // Get base searches from membership
      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Check unlimited access
        if (userData.dictionaryUnlockedUntil) {
          const expiryDate =
            userData.dictionaryUnlockedUntil instanceof Timestamp
              ? userData.dictionaryUnlockedUntil.toDate()
              : new Date(userData.dictionaryUnlockedUntil);

          if (expiryDate > new Date()) {
            result.hasUnlimited = true;
            result.unlimitedExpiresAt = expiryDate;
          }
        }

        // Get base searches from membership - IMPORTANT: match these with your app's membership levels
        const memberships = { guest: 5, george: 20, morena: Infinity };
        result.baseSearches = memberships[userData.membership] || 5;
      }

      // Check additional searches
      const searchCountRef = doc(db, "searchCounts", userId);
      const searchCountSnap = await getDoc(searchCountRef);

      if (searchCountSnap.exists()) {
        const data = searchCountSnap.data();
        const today = new Date().toISOString().split("T")[0];

        if (data.date === today) {
          // Get additional searches
          result.additionalSearches = data.additionalSearches || 0;

          // Get expiry time if exists
          if (data.additionalSearchesExpiry) {
            const expiryDate =
              data.additionalSearchesExpiry instanceof Timestamp
                ? data.additionalSearchesExpiry.toDate()
                : new Date(data.additionalSearchesExpiry);

            result.additionalSearchesExpiresAt = expiryDate;
          }

          // Get searches used today
          result.usedToday = data.count || 0;
        }
      }

      // Calculate remaining searches
      if (result.hasUnlimited) {
        result.totalSearchesRemaining = 999; // Just a high number for unlimited
      } else {
        result.totalSearchesRemaining =
          result.baseSearches + result.additionalSearches - result.usedToday;
        if (result.totalSearchesRemaining < 0)
          result.totalSearchesRemaining = 0;
      }

      return result;
    } catch (error) {
      console.error("Error getting dictionary search status:", error);
      return null;
    }
  }

  // Update this section in your PowerUpManager.js file's useInventoryItem method

  /**
   * Use an inventory item
   * @param {string} inventoryItemId - Inventory item ID
   * @returns {Promise<{success: boolean, message: string, type: string}>}
   */
  static async useInventoryItem(inventoryItemId) {
    if (!inventoryItemId)
      return { success: false, message: "Item ID is required", type: "error" };

    try {
      // Get inventory item details
      const itemRef = doc(db, "inventory", inventoryItemId);
      const itemSnap = await getDoc(itemRef);

      if (!itemSnap.exists()) {
        return { success: false, message: "Item not found", type: "unknown" };
      }

      const item = itemSnap.data();
      const userId = item.userId;
      const itemType = item.type || item.category || "";
      const itemName = item.name || "";
      const productId = item.productId || "";

      console.log("Processing item:", {
        id: inventoryItemId,
        type: itemType,
        name: itemName,
        productId: productId,
      });

      let result = {
        success: false,
        message: "Unknown item type",
        type: itemType,
      };

      // Apply different effects based on item type or special checks
      switch (itemType.toLowerCase()) {
        case "dictionary":
          if (item.unlimited) {
            // Unlimited dictionary access
            result = await this.applyUnlimitedDictionary(
              userId,
              item.durationHours || 1
            );
          } else {
            // Additional searches
            result = await this.addDictionarySearches(
              userId,
              item.searchesGranted || 50,
              item.durationMinutes || 60
            );
          }
          break;

        case "streak-freeze":
        case "streak_freeze":
          // Apply streak freeze for today
          result = await this.applyStreakFreeze(userId);
          break;

        case "special":
          // Handle special category items - check for streak savers based on name or productId
          if (
            itemName.toLowerCase().includes("streak") ||
            productId.toLowerCase().includes("streak") ||
            item.description?.toLowerCase().includes("streak")
          ) {
            console.log("Identified as streak saver from special category");
            result = await this.applyStreakFreeze(userId);
            result.type = "streak_freeze"; // Set proper type for notification
          } else {
            // For other special items, provide a more specific message
            result = {
              success: false,
              message: `Unrecognized special item: ${itemName}`,
              type: "special",
            };
          }
          break;

        case "xp-booster":
        case "xp_booster":
          // Apply XP boost
          result = await this.applyXPBoost(
            userId,
            item.multiplier || 2,
            item.durationHours || 1
          );
          break;

        case "coaching":
          // Coaching sessions just need to be marked as used
          result = {
            success: true,
            message: "Coaching session redeemed",
            type: "coaching",
          };
          break;

        default:
          result = {
            success: false,
            message: `Unknown item type: ${itemType}`,
            type: "unknown",
          };
      }

      // If successful, mark the item as used
      if (result.success) {
        if (item.uses !== undefined && item.uses > 1) {
          // Multi-use item
          await updateDoc(itemRef, {
            uses: item.uses - 1,
            lastUsed: new Date(),
          });
        } else {
          // Single-use item
          await updateDoc(itemRef, {
            isUsed: true,
            usedAt: new Date(),
          });
        }

        // Add the item name to the success message
        result.message = `${item.name}: ${result.message}`;
      }

      return result;
    } catch (error) {
      console.error("Error using inventory item:", error);
      return {
        success: false,
        message: "Failed to use item: " + error.message,
        type: "error",
      };
    }
  }
}

export default PowerUpManager;
