// pages/articles/index.jsx
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { motion } from "framer-motion";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Footer from "../../components/footer/footer.component";
import ArticleSearch from "../../components/articles/search";
import ArticleList from "../../components/articles/article-list";
import PopularArticles from "../../components/articles/popular-articles";
import CategoryList from "../../components/articles/category-list";
import {
  articleCategories,
  getRecentArticles,
} from "../../zombiedata/articles-2";
import FeedbackMenu from "../../components/support/feedback-menu";

const ArticlesIndex = () => {
  useEffect(() => {
    document.title = "Articles | Fast Fluency Africa";
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [loading, setLoading] = useState(false);
  const [recentArticles, setRecentArticles] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Simulate loading for demo purposes
    const timeoutId = setTimeout(() => {
      setRecentArticles(getRecentArticles(4));
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="flex flex-col justify-between items-center m-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className="bg-red w-full border-b-4 border-darkgray">
        <NavBarPrimary />
        <FeedbackMenu />
      </div>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Fast Fluency Africa Blog
          </h1>
          <div className="max-w-2xl mx-auto">
            <ArticleSearch placeholder="Search for articles..." />
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Browse by Category</h2>
          <CategoryList categories={articleCategories} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <h2 className="text-2xl font-bold mb-6">Recently Published</h2>
            <ArticleList articles={recentArticles} />
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-6">Popular Articles</h2>
            <PopularArticles limit={6} />
          </div>
        </div>
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default ArticlesIndex;
