// In speech-phase.jsx
import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { SpeechBubble } from "../speech-bubble";
import AudioVisualizer from "../audio-visualiser";
import { getAudioDownloadUrl } from "../../../../utils/audio-utils";

export const SpeechPhase = ({
  step,
  dialogue,
  isPlaying,
  canShowComprehensionCheck,
  onShowQuestion,
  onPlayAudio,
}) => {
  const [progress, setProgress] = useState(0);
  const [localIsPlaying, setLocalIsPlaying] = useState(false);
  const prevStepRef = useRef(-1);

  // Get current speech item safely
  const speechItem = dialogue?.speechInfo?.[step] || {};

  // Find the previous speaker to handle continuing dialogue
  const getPreviousSpeaker = () => {
    if (step === 0) return "";

    // Look backwards from current step to find the last defined speaker
    for (let i = step - 1; i >= 0; i--) {
      const prevItem = dialogue?.speechInfo?.[i];
      if (prevItem?.speaker && prevItem.speaker !== "null") {
        return prevItem.speaker;
      }
    }
    return "";
  };

  const previousSpeaker = getPreviousSpeaker();

  // Animation variants only for the speech bubble content
  const speechBubbleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4 },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 },
    },
  };

  // Generate audio path for the current speech item
  const generateAudioPath = () => {
    if (speechItem.audio) return speechItem.audio;

    // Generate path based on step number - modify as needed for your structure
    return `texts/dialogues/zul-1/1-ukwazisana/speech-${step + 1}.mp3`;
  };

  // Update localIsPlaying when parent's isPlaying changes
  useEffect(() => {
    setLocalIsPlaying(isPlaying);

    // Reset progress when audio stops
    if (!isPlaying) {
      setProgress(0);
    }
  }, [isPlaying]);

  // Log step changes for debugging
  useEffect(() => {
    if (prevStepRef.current !== step) {
      console.log(
        `Speech phase step changed from ${prevStepRef.current} to ${step}`
      );
      console.log(`Current speech text: "${speechItem.text}"`);
      console.log(`Audio path: ${generateAudioPath()}`);

      // Reset progress when step changes
      setProgress(0);
      prevStepRef.current = step;
    }
  }, [step, speechItem.text]);

  // Simulated progress when audio is playing
  useEffect(() => {
    let progressInterval;

    if (localIsPlaying) {
      // Simulate progress over 5 seconds (adjust as needed)
      const totalDuration = 5000; // 5 seconds
      const updateInterval = 100; // Update every 100ms
      const incrementAmount = (100 * updateInterval) / totalDuration;

      progressInterval = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + incrementAmount;
          return newProgress > 100 ? 100 : newProgress;
        });
      }, updateInterval);
    }

    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [localIsPlaying]);

  return (
    <div className="speech-phase-container py-4 max-w-2xl mx-auto">
      {/* Static header - not animated */}
      <div className="border-2 border-b-4 border-gray px-6 py-4 shadow-2xl bg-white mb-4">
        {/* Icon */}
        <p className="text-2xl md:text-3xl lg:text-4xl mb-2 text-outline-black text-center">
          {dialogue.icon || "🗣️"}
        </p>
        {/* Title */}
        <h1 className="text-center text-md md:text-lg lg:text-xl p-2 mb-2 font-bold font-headers">
          {dialogue.title?.toUpperCase() || "DIALOGUE"}
        </h1>
      </div>

      {/* Animated speech bubble content */}
      <motion.div
        key={`speech-${step}`}
        variants={speechBubbleVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="speech-bubble-wrapper mt-8"
      >
        <SpeechBubble
          text={speechItem.text}
          translation={speechItem.translation}
          speaker={speechItem.speaker}
          gender={speechItem.gender}
          turn={speechItem.turn}
          audioUrl={generateAudioPath()}
          previousSpeaker={previousSpeaker}
          isPlaying={localIsPlaying}
        />

        {/* Audio visualization */}
        <div className="mt-4 flex flex-col items-center">
          {/* Audio visualizer with progress */}
          <div className="w-full max-w-sm mt-3">
            <AudioVisualizer isPlaying={localIsPlaying} />

            {/* Audio progress bar */}
            <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-gold h-2 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              ></div>
            </div>

            {/* Playing status */}
            <div className="text-center text-sm text-gray-500 mt-2">
              {localIsPlaying ? (
                <div className="flex items-center justify-center">
                  <div className="w-2 h-2 bg-green rounded-full mr-2"></div>
                  <span>Listening to speech...</span>
                </div>
              ) : (
                <span>Press Continue to proceed</span>
              )}
            </div>
          </div>
        </div>
      </motion.div>

      {canShowComprehensionCheck && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="mt-8 text-center"
        >
          <button
            onClick={onShowQuestion}
            className="bg-gray text-white px-6 py-3 rounded-full
                     transition-all duration-300 transform hover:scale-105 font-medium"
          >
            Check Comprehension
          </button>
        </motion.div>
      )}
    </div>
  );
};

SpeechPhase.propTypes = {
  step: PropTypes.number.isRequired,
  dialogue: PropTypes.object.isRequired,
  isPlaying: PropTypes.bool,
  canShowComprehensionCheck: PropTypes.bool,
  onShowQuestion: PropTypes.func,
  onPlayAudio: PropTypes.func,
};

SpeechPhase.defaultProps = {
  isPlaying: false,
  canShowComprehensionCheck: false,
  onShowQuestion: () => {},
  onPlayAudio: () => {},
};
