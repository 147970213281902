import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import {
  getAudioDownloadUrl,
  createAudioController,
} from "../../../utils/audio-utils";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";

// Maximum allowed attempts before marking as incorrect
const MAX_ATTEMPTS = 3;

function TagThePairs({ data }) {
  const { setOptionChosen } = useContext(optionChosenContext);
  const { setFeedback } = useContext(feedbackContext);
  const [items, setItems] = useState([]);
  const [firstTapId, setFirstTapId] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const [exerciseCompleted, setExerciseCompleted] = useState(false);
  const [autoCheckTriggered, setAutoCheckTriggered] = useState(false);
  const [completionFeedback, setCompletionFeedback] = useState(null);
  const [audioUrls, setAudioUrls] = useState({});
  const audioControllerRef = useRef(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Initialize audio controller
  useEffect(() => {
    audioControllerRef.current = createAudioController();
    return () => {
      if (audioControllerRef.current) {
        audioControllerRef.current.destroy();
      }
    };
  }, []);

  // Play audio for a pair
  const handlePlay = (pairId) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audioUrl = audioUrls[pairId];
    if (audioUrl && audioControllerRef.current) {
      audioControllerRef.current.setUrl(audioUrl);
      audioControllerRef.current.play();
    }
  };

  const handlePause = () => {
    if (audioControllerRef.current) {
      audioControllerRef.current.pause();
    }
  };

  // Fetch audio URLs for pairs
  useEffect(() => {
    const fetchAudioUrls = async () => {
      if (!data?.content?.pairs) return;

      const urls = {};
      for (const [index, pair] of data.content.pairs.entries()) {
        if (pair.audio) {
          try {
            // Use a consistent ID for each pair
            const pairId = `pair-${index}`;
            urls[pairId] = await getAudioDownloadUrl(pair.audio);
          } catch (err) {
            console.error(`Error fetching audio for pair ${index}:`, err);
          }
        }
      }

      setAudioUrls(urls);
    };

    fetchAudioUrls();
  }, [data]);

  // Initialize items for the game
  useEffect(() => {
    if (!data?.content?.pairs) return;

    const flattened = data.content.pairs.flatMap((pair, index) => [
      {
        value: pair.base,
        pairId: `pair-${index}`,
        languageType: "base",
        isDisabled: false,
        isSelected: false,
        uniqueId: `pair-${index}-base`,
      },
      {
        value: pair.target,
        pairId: `pair-${index}`,
        languageType: "target",
        isDisabled: false,
        isSelected: false,
        uniqueId: `pair-${index}-target`,
      },
    ]);
    setItems(shuffleArray(flattened));
  }, [data?.content?.pairs]);

  // Handle exercise completion
  useEffect(() => {
    if (exerciseCompleted || autoCheckTriggered) return;

    // Check if all items are matched (success case)
    const allMatched =
      items.length > 0 && items.every((item) => item.isDisabled);

    // Check if max attempts reached (failure case)
    const maxAttemptsReached = attempts >= MAX_ATTEMPTS;

    // If either condition met, mark as completed
    if (allMatched || maxAttemptsReached) {
      // Show immediate visual feedback
      setCompletionFeedback(allMatched ? "success" : "failure");

      // Give a small delay for visual feedback before proceeding
      setTimeout(() => {
        setExerciseCompleted(true);

        if (allMatched) {
          // Success case
          setOptionChosen("completed");
          setFeedback("correct");
        } else {
          // Failure case
          setOptionChosen("failed");
          setFeedback("incorrect");
        }

        // Prevent multiple auto-check triggers
        setAutoCheckTriggered(true);

        // Trigger the auto-check after a short delay
        setTimeout(() => {
          // Use window.dispatchEvent for better reliability
          window.dispatchEvent(
            new CustomEvent("tagging_exercise_completed", {
              detail: {
                exerciseId: data.id,
                exerciseType: "tagging_pairs",
                isCorrect: allMatched,
                timestamp: Date.now(),
              },
            })
          );
        }, 300);
      }, 500);
    }
  }, [
    data,
    items,
    attempts,
    exerciseCompleted,
    autoCheckTriggered,
    setOptionChosen,
    setFeedback,
  ]);

  const handleTap = (clickedId) => {
    // Don't allow interaction if exercise is completed or max attempts reached
    if (exerciseCompleted || attempts >= MAX_ATTEMPTS) return;

    const clickedItem = items.find((item) => item.uniqueId === clickedId);
    if (!clickedItem || clickedItem.isDisabled) return;

    if (firstTapId === null) {
      // First selection
      setItems((prev) =>
        prev.map((itm) => ({
          ...itm,
          isSelected: itm.uniqueId === clickedId,
        }))
      );
      setFirstTapId(clickedId);
      return;
    }

    // Second selection - check for match
    const firstItem = items.find((itm) => itm.uniqueId === firstTapId);
    let newItems = items.map((itm) => ({ ...itm, isSelected: false }));

    if (firstItem?.pairId === clickedItem.pairId) {
      // Correct match
      newItems = newItems.map((itm) =>
        [firstItem.uniqueId, clickedItem.uniqueId].includes(itm.uniqueId)
          ? { ...itm, isDisabled: true }
          : itm
      );
    } else {
      // Incorrect match - increment attempts
      setAttempts((prev) => prev + 1);
    }

    setItems(newItems);
    setFirstTapId(null);
  };

  if (!data?.content?.pairs) {
    return <div className="text-red-500 p-4">Invalid exercise data.</div>;
  }

  // Calculate remaining attempts
  const remainingAttempts = MAX_ATTEMPTS - attempts;

  return (
    <motion.div
      className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <div className="text-center w-full max-w-md">
          <h3 className="text-base sm:text-lg italic text-center mb-4 sm:mb-6 border-2 border-b-4 border-black font-body p-3 sm:p-4">
            {data.content.question}
          </h3>

          {/* Audio disabled message */}
          {!audioEnabled && (
            <div className="text-center text-xs text-gray-500 mb-3 sm:mb-4">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}

          {/* Completion feedback banner */}
          {completionFeedback && (
            <div
              className={`text-center mb-3 sm:mb-4 p-2 rounded-md ${
                completionFeedback === "success"
                  ? "bg-green bg-opacity-20 text-green"
                  : "bg-red bg-opacity-20 text-red"
              }`}
            >
              <span className="font-bold text-sm sm:text-base">
                {completionFeedback === "success"
                  ? "Great job! All pairs tagged correctly."
                  : "Maximum attempts reached."}
              </span>
            </div>
          )}

          {/* Attempts indicator */}
          {attempts > 0 && attempts < MAX_ATTEMPTS && !completionFeedback && (
            <div className="mb-3 sm:mb-4">
              <span
                className={`font-bold text-sm sm:text-base ${
                  remainingAttempts === 1 ? "text-red" : "text-gold"
                }`}
              >
                {remainingAttempts} attempt{remainingAttempts !== 1 ? "s" : ""}{" "}
                remaining
              </span>
            </div>
          )}

          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 mb-6">
            {items.map((item) => {
              const buttonClasses = `transition-all p-3 sm:p-4 rounded-lg border-2 border-b-4 font-body text-sm
                flex flex-col items-center justify-center min-h-[60px] sm:min-h-[70px]
                ${
                  item.isDisabled ||
                  exerciseCompleted ||
                  attempts >= MAX_ATTEMPTS
                    ? "bg-gray text-darkgray border-darkgray cursor-not-allowed"
                    : item.isSelected
                    ? "bg-white border-green text-green shadow-lg hover:shadow-xl"
                    : "bg-white text-gray border-gray hover:text-green hover:border-green"
                }
                ${
                  item.languageType === "target"
                    ? "underline decoration-dotted"
                    : ""
                }
                ${
                  !audioEnabled && item.languageType === "target"
                    ? "opacity-75"
                    : ""
                }`;

              return (
                <button
                  key={item.uniqueId}
                  onClick={() => handleTap(item.uniqueId)}
                  onMouseEnter={() =>
                    audioEnabled &&
                    item.languageType === "target" &&
                    handlePlay(item.pairId)
                  }
                  onMouseLeave={handlePause}
                  disabled={
                    item.isDisabled ||
                    exerciseCompleted ||
                    attempts >= MAX_ATTEMPTS
                  }
                  className={buttonClasses}
                  title={
                    !audioEnabled && item.languageType === "target"
                      ? "Audio is disabled globally"
                      : ""
                  }
                >
                  {item.value}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

TagThePairs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.shape({
      question: PropTypes.string.isRequired,
      pairs: PropTypes.arrayOf(
        PropTypes.shape({
          base: PropTypes.string.isRequired,
          target: PropTypes.string.isRequired,
          audio: PropTypes.any,
        })
      ).isRequired,
    }).isRequired,
    topic: PropTypes.string,
    day: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};

export default TagThePairs;
