import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ModalContext } from "../../helpers/modalcontext";

function ExitModal() {
  const [toggled] = useState(false);
  const { setModal } = useContext(ModalContext);

  const closeModal = () => {
    setModal(false);
    document.body.style.overflow = ""; // Enable scrolling
  };

  return (
    <div className="bg-white h-screen absolute w-full top-0 left-0 z-50">
      <div
        className={
          toggled !== true
            ? "bg-white opacity-100 h-full absolute top-0 left-0 w-full"
            : "hidden"
        }
      >
        <div className="w-max-[600px] mx-auto flex flex-col justify-center items-center h-full bg-white p-4">
          <div className="text-center max-w-sm mx-auto">
            <h1 className="text-5xl sm:text-6xl md:text-7xl mb-4">🤨</h1>
            <h2 className="font-bold font-headers text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-2">
              Are you sure you want to quit?
            </h2>
            <p className="font-lighter text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl font-body mb-4">
              All your progress from this session will be lost.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-4 max-w-xs">
            <div className="w-full">
              <input
                className="w-full px-3 sm:px-6 py-2 border-1 rounded text-center cursor-pointer block border-2 text-xs sm:text-sm md:text-md lg:text-lg leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out font-body font-bold bg-white text-gray hover:text-gray border-gray hover:border-darkgray hover:bg-darkgray border-b-4"
                type="button"
                value="No"
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
            <Link className="w-full" to="/units">
              <input
                className="w-full px-3 sm:px-6 py-2 border-1 rounded text-center cursor-pointer block border-2 text-xs sm:text-sm md:text-md lg:text-lg leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out font-body font-bold bg-green text-white hover:text-white border-white hover:border-green border-b-4"
                type="button"
                value="Yes"
                onClick={() => {
                  closeModal();
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExitModal;
