import React, { useState, useEffect, useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import PropTypes from "prop-types";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

const MultipleChoiceV1 = ({ data }) => {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);
  const [shuffledOptions, setShuffledOptions] = useState([]);

  // Audio state
  const [fullPhraseUrl, setFullPhraseUrl] = useState("");
  const [wordAudioUrls, setWordAudioUrls] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioKey, setCurrentAudioKey] = useState(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  const audioRef = useRef(new Audio());

  // Apply audio settings when they change
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audioEnabled;

      // If audio is disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlaying(false);
      setCurrentAudioKey(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Play the audio at normal speed
  const handlePlayNormal = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioRef.current && fullPhraseUrl) {
      // If already playing this audio, stop it
      if (isPlaying && currentAudioKey === "phrase") {
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
        return;
      }

      // Set up audio playback
      audioRef.current.src = fullPhraseUrl;
      audioRef.current.playbackRate = 1.0;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlaying(true);
      setCurrentAudioKey("phrase");
    }
  };

  // Play the audio at slower speed
  const handlePlaySlow = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioRef.current && fullPhraseUrl) {
      // If already playing this audio, stop it
      if (isPlaying && currentAudioKey === "phrase-slow") {
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
        return;
      }

      // Set up audio playback
      audioRef.current.src = fullPhraseUrl;
      audioRef.current.playbackRate = 0.75;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlaying(true);
      setCurrentAudioKey("phrase-slow");
    }
  };

  // Play individual word audio
  const handlePlayWord = (wordText) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audioUrl = wordAudioUrls[wordText];
    if (audioUrl && audioRef.current) {
      // If already playing this audio, stop it
      if (isPlaying && currentAudioKey === wordText) {
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentAudioKey(null);
        return;
      }

      // Set up audio playback
      audioRef.current.src = audioUrl;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlaying(true);
      setCurrentAudioKey(wordText);
    }
  };

  const handlePauseWord = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioKey(null);
    }
  };

  // Shuffle options on mount and when data changes
  useEffect(() => {
    const shuffleArray = (array) => [...array].sort(() => Math.random() - 0.5);
    if (data) {
      const allOptions = [
        data.validation.correctAnswer,
        ...data.content.distractors,
      ];
      setShuffledOptions(shuffleArray(allOptions));
    }
    setOptionChosen(null);
    setFeedback(null);
  }, [data, setFeedback, setOptionChosen]);

  // Fetch the full phrase audio URL
  useEffect(() => {
    const fetchPhraseAudio = async () => {
      if (!data || !data.content.audio) return;

      try {
        const url = await getAudioDownloadUrl(data.content.audio);
        setFullPhraseUrl(url);

        // Set the URL on the audio element
        audioRef.current.src = url;
        audioRef.current.load();
      } catch (err) {
        console.error("Error fetching phrase audio:", err);
      }
    };

    fetchPhraseAudio();
  }, [data]);

  // Fetch individual word audio URLs if wordBreakdown is provided
  useEffect(() => {
    const fetchWordAudios = async () => {
      if (!data || !data.content.audio || !data.content.audio.wordBreakdown)
        return;

      const wordBreakdown = data.content.audio.wordBreakdown;
      const urls = {};

      for (const word of wordBreakdown) {
        if (word.audio) {
          try {
            urls[word.text] = await getAudioDownloadUrl(word.audio);
          } catch (err) {
            console.error(`Error fetching audio for ${word.text}:`, err);
          }
        }
      }

      setWordAudioUrls(urls);
    };

    fetchWordAudios();
  }, [data]);

  const handleSelect = (option) => {
    if (!feedback) {
      setOptionChosen(option);
    }
  };

  // Helper functions to handle the UI appearance for audio controls
  const getAudioButtonContent = (type) => {
    if (!audioEnabled) {
      return "🔇";
    }

    if (
      isPlaying &&
      ((type === "normal" && currentAudioKey === "phrase") ||
        (type === "slow" && currentAudioKey === "phrase-slow"))
    ) {
      return "⏸️";
    }

    return type === "normal" ? "🔊" : "🐢";
  };

  if (
    !data ||
    !data.content.distractors ||
    !Array.isArray(data.content.distractors)
  ) {
    return <div className="text-red">Invalid exercise data.</div>;
  }

  return (
    <div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <h1 className="text-lg sm:text-xl font-bold text-left font-headers text-black mb-3 sm:mb-4">
          {data.content.question}
        </h1>

        <div className="mb-4 border-2 border-b-4 border-black font-body p-3 sm:p-5">
          {/* Audio control buttons */}
          <div className="flex space-x-2 mb-2">
            <button
              className={`text-outline-black p-2 rounded-lg border ${
                !audioEnabled ? "opacity-50" : ""
              }`}
              onClick={handlePlayNormal}
              title={
                audioEnabled
                  ? "Play at normal speed"
                  : "Audio is disabled globally"
              }
            >
              {getAudioButtonContent("normal")}
            </button>
            <button
              className={`text-outline-black p-2 rounded-lg border ${
                !audioEnabled ? "opacity-50" : ""
              }`}
              onClick={handlePlaySlow}
              title={
                audioEnabled
                  ? "Play at slow speed"
                  : "Audio is disabled globally"
              }
            >
              {getAudioButtonContent("slow")}
            </button>
          </div>

          {/* Display phrase text with word highlighting */}
          {data.content.audio && data.content.audio.wordBreakdown ? (
            <div className="text-base sm:text-lg italic">
              {data.content.audio.wordBreakdown.map((word, index) => (
                <span
                  key={index}
                  className={`cursor-pointer underline decoration-dotted mx-1 ${
                    !audioEnabled ? "text-gray-500" : ""
                  }`}
                  onMouseEnter={() => audioEnabled && handlePlayWord(word.text)}
                  onMouseLeave={handlePauseWord}
                >
                  {word.text}
                  {isPlaying && currentAudioKey === word.text && (
                    <span className="ml-1 text-xs">🔊</span>
                  )}
                </span>
              ))}
            </div>
          ) : (
            <p
              className={`text-base sm:text-lg italic cursor-pointer ${
                !audioEnabled ? "text-gray-500" : ""
              }`}
              onMouseEnter={audioEnabled ? handlePlayNormal : undefined}
              onMouseLeave={handlePauseWord}
            >
              "{data.content.sourceSentence}"
              {isPlaying &&
                (currentAudioKey === "phrase" ||
                  currentAudioKey === "phrase-slow") && (
                  <span className="ml-1 text-xs">🔊</span>
                )}
            </p>
          )}

          {/* Audio disabled message */}
          {!audioEnabled && (
            <div className="text-center text-xs text-gray-500 mt-2">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}
        </div>

        <div className="w-full grid gap-2 sm:gap-3 mb-6">
          {shuffledOptions.map((option, index) => (
            <motion.button
              key={index}
              onClick={() => handleSelect(option)}
              className={`transition-all p-4 rounded-lg border-2 border-b-4 font-body text-sm 
                flex flex-col items-center justify-center ${
                  optionChosen === option
                    ? "border-green bg-green-100 text-green shadow-lg"
                    : "bg-white text-gray border-gray hover:border-green hover:text-green"
                } ${feedback ? "cursor-default" : "cursor-pointer"}`}
              whileHover={!feedback ? { scale: 1.02 } : {}}
              whileTap={!feedback ? { scale: 0.98 } : {}}
            >
              {option}
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
};

MultipleChoiceV1.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      question: PropTypes.string.isRequired,
      sourceSentence: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      distractors: PropTypes.arrayOf(PropTypes.string).isRequired,
      audio: PropTypes.shape({
        audioPhrase: PropTypes.string,
        wordBreakdown: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            audio: PropTypes.any,
          })
        ),
      }),
    }).isRequired,
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string,
  }).isRequired,
};

export default MultipleChoiceV1;
