import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import { default as skyline } from "../../assets/imgs/skyline-africa.svg";
import { default as hero } from "../../assets/imgs/hero-2-min-beta.png";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";

function Hero() {
  const [hovered, setHovered] = useState(false);
  const [guestLoading, setGuestLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [earlyAccessLoading, setEarlyAccessLoading] = useState(false);
  const { guestLogin, user } = useAuth(); // Extract currentUser from auth context
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Force component to re-render when loading state changes
  useEffect(() => {
    return () => {
      if (earlyAccessLoading) {
        setEarlyAccessLoading(false);
      }
    };
  }, []);

  const handleEarlyAccessNavigate = () => {
    setEarlyAccessLoading(true);
    setTimeout(() => {
      navigate("/early-access");
    }, 1000);
  };

  const handleGuestLogin = async () => {
    try {
      await setGuestLoading(true);
      await guestLogin();
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      await setGuestLoading(false);
    }
  };

  const userSignIn = async () => {
    try {
      await setUserLoading(true);
      navigate("/login");
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      await setUserLoading(false);
    }
  };

  // New function to navigate to dashboard
  const goToVault = async () => {
    try {
      await setUserLoading(true);
      navigate("/vault");
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      await setUserLoading(false);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    tap: {
      scale: 0.95,
      y: 4,
      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.1 },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="w-full"
    >
      <div className="flex-col md:flex md:flex-row bg-gradient-to-b from-red to-gold relative overflow-hidden">
        {/* Decorative elements */}
        <motion.div
          className="absolute top-20 left-10 w-24 h-24 bg-white/5 rounded-full"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
        <motion.div
          className="absolute bottom-40 right-10 w-32 h-32 bg-white/5 rounded-full"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1, delay: 0.7 }}
        />

        <div className="flex flex-col items-center justify-between">
          <motion.div variants={itemVariants} className="w-full">
            <NavBarPrimary />
          </motion.div>

          <div className="flex flex-col justify-center items-center relative">
            <motion.div
              className="flex justify-center items-center flex-col w-5/12 mt-10"
              variants={itemVariants}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              <img
                src={hero}
                alt="Fast Fluency Africa Hero"
                className="w-full ms:w-2/3"
              />
            </motion.div>

            <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
              <div className="justify-center items-center flex flex-col w-full">
                <motion.div
                  className="text-white font-bold"
                  variants={itemVariants}
                >
                  <motion.h1
                    className="mx-4 text-lg sm:text-xl md:text-2xl font-semibold font-body text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                  >
                    The fun way to know African languages, cultures, and stories
                  </motion.h1>

                  <motion.div
                    className="flex flex-col sm:flex-row w-full justify-center items-center mt-6"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.7 }}
                  >
                    <div className="flex flex-col justify-center items-center min-w-xl sm:w-full">
                      <motion.button
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        onClick={handleEarlyAccessNavigate}
                        disabled={earlyAccessLoading}
                        className="mt-2 mx-1 px-6 py-2 min-w-[300px] border-1 rounded text-center cursor-pointer
                                  text-white font-bold hover:text-white border-2 hover:border-green border-white bg-green
                                  text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl
                                  leading-tight uppercase shadow-md hover:shadow-2xl
                                  focus:shadow-2xl focus:outline-none focus:ring-0
                                  transition duration-150
                                  m-auto transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4
                                  disabled:opacity-90 disabled:cursor-not-allowed"
                        variants={buttonVariants}
                        whileHover="hover"
                        whileTap="tap"
                      >
                        <div className="h-5 flex items-center justify-center">
                          {earlyAccessLoading ? (
                            <BeatLoader
                              color="#FCFCEE"
                              loading={true}
                              size={8}
                              speedMultiplier={0.8}
                            />
                          ) : (
                            "Join Waitlist"
                          )}
                        </div>
                      </motion.button>
                    </div>

                    <div className="flex flex-col justify-center items-center min-w-xl sm:w-full mt-4 sm:mt-0">
                      <motion.button
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        onClick={user ? goToVault : userSignIn}
                        disabled={userLoading}
                        className="mt-2 mx-1 px-6 py-2 min-w-[300px] border-1 rounded text-center cursor-pointer
                                  m-auto transform 
                                  text-white font-bold hover:text-black border-2 hover:border-black border-white 
                                  text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl
                                  leading-tight uppercase shadow-md hover:shadow-2xl
                                  focus:shadow-2xl focus:outline-none focus:ring-0
                                  active:scale-95 active:translate-y-1 active:border-b-2
                                  transition duration-150
                                  ease-in-out border-b-4
                                  disabled:opacity-90 disabled:cursor-not-allowed"
                        variants={buttonVariants}
                        whileHover="hover"
                        whileTap="tap"
                      >
                        <div className="h-5 flex items-center justify-center">
                          {userLoading ? (
                            <BeatLoader
                              color={hovered ? "#FCFCEE" : "#000000"}
                              loading={true}
                              size={8}
                              speedMultiplier={0.8}
                            />
                          ) : user ? (
                            "Return to Vault"
                          ) : (
                            "Sign In"
                          )}
                        </div>
                      </motion.button>
                    </div>
                  </motion.div>
                </motion.div>
                <br />
              </div>
            </div>

            <motion.div
              className="w-full"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              <img
                className="w-full text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl"
                src={skyline}
                alt="African skyline"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Hero;
