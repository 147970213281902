import PropTypes from "prop-types";

export const dialoguePropTypes = {
  no: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  narrationAudio: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["mcq", "checkbox", "fill"]),
      options: PropTypes.array,
      correctAnswers: PropTypes.array.isRequired,
    })
  ),
  speechInfo: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      audio: PropTypes.string.isRequired,
      speaker: PropTypes.string,
      turn: PropTypes.number.isRequired,
    })
  ).isRequired,
};
