// utils/bookings/dateUtils.js
import {
  format,
  parseISO,
  addMinutes,
  isSameDay,
  differenceInDays,
  addDays,
} from "date-fns";
import { formatInTimeZone, toZonedTime, fromZonedTime } from "date-fns-tz";

/**
 * Get the user's local timezone
 * @returns {string} Timezone identifier (e.g., 'America/New_York')
 */
export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Convert a date to UTC
 * @param {Date|string} date - The date to convert
 * @param {string} timezone - Source timezone
 * @returns {Date} Date in UTC
 */
export const convertToUTC = (date, timezone) => {
  if (!date) return null;

  // If date is a string, parse it first
  const dateObj = typeof date === "string" ? parseISO(date) : date;

  return fromZonedTime(dateObj, timezone);
};

/**
 * Convert UTC date to specified timezone
 * @param {Date|string} date - The UTC date to convert
 * @param {string} timezone - Target timezone
 * @returns {Date} Date in specified timezone
 */
export const convertFromUTC = (date, timezone) => {
  if (!date) return null;

  // If date is a string, parse it first
  const dateObj = typeof date === "string" ? parseISO(date) : date;

  return toZonedTime(dateObj, timezone);
};

/**
 * Format a date with the user's timezone
 * @param {Date|string} date - The date to format
 * @param {string} formatStr - Format string (date-fns format)
 * @param {string} timezone - Target timezone (defaults to user's timezone)
 * @returns {string} Formatted date string
 */
export const formatDate = (
  date,
  formatStr = "PPP",
  timezone = getUserTimezone()
) => {
  if (!date) return "";

  // If date is a string, parse it first
  const dateObj = typeof date === "string" ? parseISO(date) : date;

  // Format with the specified timezone
  return formatInTimeZone(dateObj, timezone, formatStr);
};

/**
 * Format a time with the user's timezone
 * @param {Date|string} date - The date to format
 * @param {string} formatStr - Format string (date-fns format)
 * @param {string} timezone - Target timezone (defaults to user's timezone)
 * @returns {string} Formatted time string
 */
export const formatTime = (
  date,
  formatStr = "p",
  timezone = getUserTimezone()
) => {
  if (!date) return "";

  // If date is a string, parse it first
  const dateObj = typeof date === "string" ? parseISO(date) : date;

  // Format with the specified timezone
  return formatInTimeZone(dateObj, timezone, formatStr);
};

/**
 * Format a date and time with the user's timezone
 * @param {Date|string} date - The date to format
 * @param {string} formatStr - Format string (date-fns format)
 * @param {string} timezone - Target timezone (defaults to user's timezone)
 * @returns {string} Formatted date and time string
 */
export const formatDateTime = (
  date,
  formatStr = "PPp",
  timezone = getUserTimezone()
) => {
  if (!date) return "";

  // If date is a string, parse it first
  const dateObj = typeof date === "string" ? parseISO(date) : date;

  // Format with the specified timezone
  return formatInTimeZone(dateObj, timezone, formatStr);
};

/**
 * Get a friendly date description (Today, Tomorrow, In X days, etc.)
 * @param {Date|string} date - The date to describe
 * @param {string} timezone - Timezone (defaults to user's timezone)
 * @returns {string} Friendly date description
 */
export const getFriendlyDateDescription = (
  date,
  timezone = getUserTimezone()
) => {
  if (!date) return "";

  // If date is a string, parse it first
  const dateObj = typeof date === "string" ? parseISO(date) : date;

  // Convert to target timezone
  const localDate = toZonedTime(dateObj, timezone);
  const today = new Date();

  if (isSameDay(localDate, today)) {
    return "Today";
  } else if (isSameDay(localDate, addDays(today, 1))) {
    return "Tomorrow";
  } else {
    const daysAway = differenceInDays(localDate, today);
    if (daysAway < 7) {
      return `In ${daysAway} days`;
    } else {
      return formatDate(localDate, "PPP", timezone);
    }
  }
};

/**
 * Calculate duration between two dates in minutes
 * @param {Date|string} startDate - Start date
 * @param {Date|string} endDate - End date
 * @returns {number} Duration in minutes
 */
export const getDurationInMinutes = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;

  // If dates are strings, parse them first
  const startObj =
    typeof startDate === "string" ? parseISO(startDate) : startDate;
  const endObj = typeof endDate === "string" ? parseISO(endDate) : endDate;

  // Calculate difference in milliseconds and convert to minutes
  return Math.round((endObj - startObj) / (1000 * 60));
};

/**
 * Format a duration in a human-readable format
 * @param {number} minutes - Duration in minutes
 * @returns {string} Formatted duration (e.g., "1 hour 30 minutes")
 */
export const formatDuration = (minutes) => {
  if (!minutes) return "0 minutes";

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
  } else if (remainingMinutes === 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""}`;
  } else {
    return `${hours} hour${hours !== 1 ? "s" : ""} ${remainingMinutes} minute${
      remainingMinutes !== 1 ? "s" : ""
    }`;
  }
};

/**
 * Get a list of available timezones grouped by region
 * @returns {Array} Array of timezone groups
 */
export const getTimezoneOptions = () => {
  // This is a simplified list - you can expand it as needed
  return [
    {
      label: "Africa",
      options: [
        {
          value: "Africa/Nairobi",
          label: "East African Time (EAT) - Kenya, Tanzania, Uganda",
        },
        {
          value: "Africa/Johannesburg",
          label: "South African Standard Time (SAST) - South Africa",
        },
        { value: "Africa/Lagos", label: "West African Time (WAT) - Nigeria" },
        { value: "Africa/Cairo", label: "Eastern European Time (EET) - Egypt" },
        {
          value: "Africa/Casablanca",
          label: "Western European Time (WET) - Morocco",
        },
        { value: "Africa/Accra", label: "Greenwich Mean Time (GMT) - Ghana" },
        {
          value: "Africa/Addis_Ababa",
          label: "East African Time (EAT) - Ethiopia",
        },
        {
          value: "Africa/Algiers",
          label: "Central European Time (CET) - Algeria",
        },
        { value: "Africa/Dakar", label: "Greenwich Mean Time (GMT) - Senegal" },
        {
          value: "Africa/Dar_es_Salaam",
          label: "East African Time (EAT) - Tanzania",
        },
        { value: "Africa/Kampala", label: "East African Time (EAT) - Uganda" },
        {
          value: "Africa/Khartoum",
          label: "Central Africa Time (CAT) - Sudan",
        },
        {
          value: "Africa/Kinshasa",
          label: "West Africa Time (WAT) - DR Congo (west)",
        },
        {
          value: "Africa/Lubumbashi",
          label: "Central Africa Time (CAT) - DR Congo (east)",
        },
        { value: "Africa/Luanda", label: "West Africa Time (WAT) - Angola" },
        {
          value: "Africa/Maputo",
          label: "Central Africa Time (CAT) - Mozambique",
        },
        {
          value: "Africa/Mogadishu",
          label: "East African Time (EAT) - Somalia",
        },
        {
          value: "Africa/Tripoli",
          label: "Eastern European Time (EET) - Libya",
        },
        {
          value: "Africa/Tunis",
          label: "Central European Time (CET) - Tunisia",
        },
        {
          value: "Africa/Windhoek",
          label: "Central Africa Time (CAT) - Namibia",
        },
      ],
    },
    {
      label: "North America",
      options: [
        { value: "America/New_York", label: "Eastern Time (ET)" },
        { value: "America/Chicago", label: "Central Time (CT)" },
        { value: "America/Denver", label: "Mountain Time (MT)" },
        { value: "America/Los_Angeles", label: "Pacific Time (PT)" },
        { value: "America/Anchorage", label: "Alaska Time" },
        { value: "Pacific/Honolulu", label: "Hawaii Time" },
      ],
    },
    {
      label: "Europe",
      options: [
        { value: "Europe/London", label: "UK (GMT/BST)" },
        { value: "Europe/Paris", label: "Central European Time (CET)" },
        { value: "Europe/Helsinki", label: "Eastern European Time (EET)" },
      ],
    },
    {
      label: "Asia",
      options: [
        { value: "Asia/Tokyo", label: "Japan Time (JST)" },
        { value: "Asia/Shanghai", label: "China Time (CST)" },
        { value: "Asia/Kolkata", label: "India Time (IST)" },
        { value: "Asia/Singapore", label: "Singapore Time (SGT)" },
      ],
    },
    {
      label: "Australia & Pacific",
      options: [
        { value: "Australia/Sydney", label: "Australian Eastern Time (AET)" },
        { value: "Australia/Perth", label: "Australian Western Time (AWT)" },
        { value: "Pacific/Auckland", label: "New Zealand Time (NZT)" },
      ],
    },
  ];
};

/**
 * Get the time difference between two timezones as a string
 * @param {string} timezone1 - First timezone
 * @param {string} timezone2 - Second timezone
 * @returns {string} Time difference description (e.g., "3 hours ahead" or "2 hours behind")
 */
export const getTimezoneDifference = (timezone1, timezone2) => {
  const now = new Date();
  const time1 = toZonedTime(now, timezone1);
  const time2 = toZonedTime(now, timezone2);

  // Calculate difference in minutes
  const diffMinutes =
    time2.getHours() * 60 +
    time2.getMinutes() -
    (time1.getHours() * 60 + time1.getMinutes());

  // Handle day boundary crossings
  const adjustedDiffMinutes =
    diffMinutes > 720
      ? diffMinutes - 1440
      : diffMinutes < -720
      ? diffMinutes + 1440
      : diffMinutes;

  // Convert to hours
  const diffHours = Math.abs(adjustedDiffMinutes) / 60;
  const hours = Math.floor(diffHours);
  const minutes = Math.round((diffHours - hours) * 60);

  let result = "";

  if (hours > 0) {
    result += `${hours} hour${hours !== 1 ? "s" : ""}`;
  }

  if (minutes > 0) {
    if (result) result += " ";
    result += `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  }

  if (!result) {
    return "same time";
  }

  return adjustedDiffMinutes > 0 ? `${result} ahead` : `${result} behind`;
};
