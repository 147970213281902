import React, { useState, useEffect, useContext } from "react";
import { db } from "../../firebase/firebase.utils";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCourse } from "../../helpers/CourseContextProvider";
import { useAuth } from "../../helpers/UserAuthContextProvider";

function CourseCard() {
  const [courseStatus, setCourseStatus] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { user } = useAuth();
  const [enrollment, setEnrollment] = useState([]);

  // Sample courses data - replace with your courses
  const { courses, setCourses } = useCourse();

  const initialValues = {
    selectedCourse: "",
  };

  const validationSchema = Yup.object({
    selectedCourse: Yup.string().required("Goal selection is required!"),
  });

  useEffect(() => {
    const getCurrUser = async () => {
      if (user) {
        const docRef = doc(db, "users", user?.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setEnrollment({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log("No such document!");
        }
      }
    };
    getCurrUser();
  }, [user]);

  useEffect(() => {
    // Fetch courses data here if it's dynamic and set it using setCourses
    return () => {
      // Reset the courses in context to the default value
      setCourses(["Social", "Academic"]);
    };
  }, [setCourses]);

  const handleSubmit = async (values, { resetForm }) => {
    resetForm();
    setIsSubmitted(true);

    if (user) {
      try {
        // Update course in Firestore
        const userRef = doc(db, "users", user?.uid);
        // await setCourses(values.selectedCourse);
        await updateDoc(userRef, {
          motive: values.selectedCourse,
        });

        setCourseStatus("Your course has successfully been updated!");
      } catch (error) {
        console.error("Error updating course:", error);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const coursesOptions = ["Social", "Academic"];

  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-8">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="font-headers text-left mt-4 w-full font-bold text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Language Goals
        </h1>
        <p className="text-left w-full text-sm sm:text-sm md:text-base lg:text-lg">
          Update your language learning goal.
        </p>

        <div className="w-full mt-4">
          <p className="font-semibold text-base mb-1">Current Goal</p>
          <div className="bg-gray-50 p-3 rounded-md border border-gray-200 text-base mb-4">
            {enrollment.motive || "No goal selected"}
          </div>

          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="selectedCourse"
                className="font-semibold text-base mb-2"
              >
                Select Goal
              </label>
              <select
                id="selectedCourse"
                name="selectedCourse"
                {...formik.getFieldProps("selectedCourse")}
                className="bg-white border-2 border-b-4 px-3 py-3 rounded focus:outline-none text-sm md:text-base w-full"
              >
                <option value="">Select a goal</option>
                {Array.isArray(coursesOptions) &&
                  coursesOptions.map((course, index) => (
                    <option key={index} value={course}>
                      {course}
                    </option>
                  ))}
              </select>
              {formik.touched.selectedCourse &&
                formik.errors.selectedCourse && (
                  <div className="text-red text-sm mt-1">
                    {formik.errors.selectedCourse}
                  </div>
                )}
            </div>

            <div className="mt-6 flex flex-col items-center justify-center w-full">
              <button
                type="submit"
                className="mt-2 text-sm md:text-base w-full sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-1/2 pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer m-auto font-bold hover:text-white border-green bg-white hover:border-green hover:bg-green text-black leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
              >
                Update Course
              </button>

              {isSubmitted && (
                <span className="mt-4 text-sm md:text-base text-green font-semibold">
                  {courseStatus}
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
