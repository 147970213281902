export const UnitsSocialData = {
  Social: {
    George: [
      {
        name: "Master the Zulu Alphabet",
        description:
          "Delve into the basic structure of language, understanding the 26 letters of the alphabet, and forming words.",
        emoji: "🅰️",
        id: 1,
      },
      {
        name: "Engage in basic greetings",
        description:
          "Introduce yourself, members of your family, and participate in everyday activities.",
        emoji: "🙂",
        id: 2,
      },
      {
        name: `Identify words with(out) "H/G"`,
        description:
          "Learn to identify and use words that do not contain the letters 'h' or 'g', emphasizing vocabulary expansion.",
        emoji: "H 🚫 G",
        id: 3,
      },
      // {
      //   name: "Describe past and present actions",
      //   description:
      //     "Understand how to talk about current actions and reflect on past events, with a focus on the day before",
      //   emoji: "⏰",
      //   id: 4,
      // },
      // {
      //   name: "Recognize different body parts",
      //   description:
      //     "Familiarize with the different parts of the body, their names, and their significance.",
      //   emoji: "🦵",
      //   id: 5,
      // },
      // {
      //   name: "Use common everyday words",
      //   description:
      //     "Enhance your everyday vocabulary with commonly used words in various contexts.",
      //   emoji: "📖",
      //   id: 6,
      // },
      // {
      //   name: "VII",
      //   description:
      //     "Learn to count, understand the concept of numbers, and confidently count up to 100.",
      //   emoji: "1️⃣0️⃣0️⃣",
      //   id: 7,
      // },
      // {
      //   name: "VIII",
      //   description:
      //     "Focus on differentiating between specific sets of letters and their phonetic variations.",
      //   emoji: "🎶",
      //   id: 8,
      // },
      // {
      //   name: "IX",
      //   description:
      //     "Understand the importance of adjusting tone, emphasizing specific words, and how it affects meaning",
      //   emoji: "🔊",
      //   id: 9,
      // },
      // {
      //   name: "X",
      //   description:
      //     "Building on basic interactions, this unit delves into more profound conversational topics, allowing learners to connect on a deeper level and express more complex thoughts and emotions.",
      //   emoji: "🤝",
      //   id: 10,
      // },
      // {
      //   name: "XI",
      //   description:
      //     "Transitioning from spoken words to the written form, this unit emphasizes the importance of writing, reading, and understanding the transition between speech and text.",
      //   emoji: "✍️",
      //   id: 11,
      // },
      // {
      //   name: "XII",
      //   description:
      //     "Learn about the cultural nuances, idioms, and phrases that are essential in daily interactions. This unit explores how language is deeply rooted in culture and how understanding cultural contexts can enhance communication.",
      //   emoji: "🌍",
      //   id: 12,
      // },
    ],
  },
  isiZuluSocial: {
    George: [
      {
        name: "Master the Zulu Alphabet",
        description:
          "Delve into the basic structure of language, understanding the 26 letters of the alphabet, and forming words.",
        emoji: "🅰️",
        id: 1,
      },
      {
        name: "Engage in basic greetings",
        description:
          "Introduce yourself, members of your family, and participate in everyday activities.",
        emoji: "🙂",
        id: 2,
      },
      {
        name: `Identify words with(out) "H/G"`,
        description:
          "Learn to identify and use words that do not contain the letters 'h' or 'g', emphasizing vocabulary expansion.",
        emoji: "H 🚫 G",
        id: 3,
      },
      // Other units...
    ],
  },
};
