import React, { useState, useEffect } from "react";
import { format, parseISO, isSameDay, getDay } from "date-fns";
import { ChevronLeft, ChevronRight, Calendar, Clock } from "lucide-react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase/firebase.utils";
import {
  getUserTimezone,
  convertFromUTC,
} from "../../utils/bookings/timezoneUtils";
import PaymentModal from "./shared/payment-modal";
import BookingConfirmation from "./shared/booking-confirmation";
import { bookSession } from "../../firebase/bookings-api";
import { MeetingService } from "../../utils/bookings/meeting-service";
import PaymentNotification from "./shared/payment-notification";
import { useBooking } from "../../helpers/BookingsContext";

const StudentBookingCalendar = ({ coachId }) => {
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [coachProfile, setCoachProfile] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [userTimezone, setUserTimezone] = useState("");
  const [loading, setLoading] = useState(true);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [bookingError, setBookingError] = useState(null);
  const [confirmedBooking, setConfirmedBooking] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [meetingLink, setMeetingLink] = useState(null);
  const [meetingPassword, setMeetingPassword] = useState(null);
  const [meetingStartUrl, setMeetingStartUrl] = useState(null);
  const [alternativeHosts, setAlternativeHosts] = useState([]);
  const [paymentModalError, setPaymentModalError] = useState(null);

  useEffect(() => {
    const timezone = getUserTimezone();
    setUserTimezone(timezone);
    fetchCoachProfile();
  }, [coachId]);

  useEffect(() => {
    if (coachId) {
      fetchAvailableSlots();
    }
  }, [coachId, selectedDate, userTimezone]);

  const fetchCoachProfile = async () => {
    try {
      const coachDocRef = doc(db, "users", coachId);
      const coachDocSnap = await getDoc(coachDocRef);

      if (coachDocSnap.exists()) {
        const coachData = coachDocSnap.data();
        setCoachProfile({
          id: coachDocRef.id,
          ...coachData,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching coach profile:", error);
      setLoading(false);
    }
  };

  const fetchCurrentUserInfo = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return null;

      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        return userDoc.data();
      }
      return null;
    } catch (error) {
      console.error("Error fetching current user info:", error);
      return null;
    }
  };

  const fetchAvailableSlots = async () => {
    try {
      setLoading(true);
      // Get the beginning and end of the selected date
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      // Query available slots for the coach on the selected date
      const availabilityRef = collection(db, `users/${coachId}/availability`);
      const q = query(
        availabilityRef,
        where("bookable", "==", true),
        where("startDateTime", ">=", startOfDay),
        where("startDateTime", "<=", endOfDay)
      );

      const querySnapshot = await getDocs(q);
      const slots = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Convert UTC times to user's local timezone for display
        slots.push({
          id: doc.id,
          ...data,
          startDateTime: convertFromUTC(
            data.startDateTime.toDate(),
            userTimezone
          ),
          endDateTime: convertFromUTC(data.endDateTime.toDate(), userTimezone),
        });
      });

      // Sort slots by start time
      const sortedSlots = slots.sort(
        (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime)
      );

      setAvailableSlots(sortedSlots);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching available slots:", error);
      setLoading(false);
    }
  };

  const handlePrevDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
    setShowPaymentModal(true);

    // Reset any previous booking states
    setBookingSuccess(false);
    setBookingError(null);
    setShowConfirmation(false);

    // Clear any previous meeting data
    setMeetingLink(null);
    setMeetingPassword(null);
    setMeetingStartUrl(null);
    setAlternativeHosts([]);
  };

  const handleBookSession = async (paymentMethod) => {
    if (!selectedSlot || !auth.currentUser) return;

    // Clear previous errors/success
    setBookingError(null);
    setPaymentModalError(null);
    setLoading(true);

    try {
      // Get current user info before booking to have access to the name
      const currentUserData = await fetchCurrentUserInfo();
      const studentName =
        currentUserData?.name || currentUserData?.displayName || "Student";

      // Call the cloud function to book the session
      const result = await bookSession(
        coachProfile.id,
        selectedSlot.id,
        paymentMethod
      );

      // Extract meeting details from the server response
      setMeetingLink(result.meetingLink);
      setMeetingPassword(result.meetingPassword);
      setMeetingStartUrl(result.meetingStartUrl);

      // Set alternative hosts if provided
      if (result.alternativeHosts && Array.isArray(result.alternativeHosts)) {
        setAlternativeHosts(result.alternativeHosts);
      }

      // Calculate duration (needed for display)
      const duration =
        (new Date(selectedSlot.endDateTime) -
          new Date(selectedSlot.startDateTime)) /
        (1000 * 60);

      // Store confirmed booking details WITH NAMES
      setConfirmedBooking({
        id: result.bookingId,
        coachId: coachProfile.id,
        studentId: auth.currentUser.uid,
        studentName: studentName,
        coachName: coachProfile?.name || coachProfile?.displayName,
        sessionDateTime: selectedSlot.startDateTime,
        duration: duration,
        meetingLink: result.meetingLink,
        meetingPassword: result.meetingPassword,
        meetingStartUrl: result.meetingStartUrl,
        alternativeHosts: result.alternativeHosts || [],
        status: "upcoming",
      });

      // Successfully completed the booking
      setBookingSuccess(true);

      // Close payment modal and show confirmation
      setShowPaymentModal(false);
      setShowConfirmation(true);

      // Refresh available slots in the background
      fetchAvailableSlots();
    } catch (error) {
      console.error("Error booking session:", error);

      // Format user-friendly error message
      let errorMessage = error.message || "Failed to book session";

      if (
        errorMessage.includes("Insufficient reward points") ||
        errorMessage.includes("Insufficient points") ||
        errorMessage.toLowerCase().includes("not enough")
      ) {
        errorMessage = "You don't have enough reward points for this session.";
      }

      // Set error in payment modal
      setPaymentModalError(errorMessage);

      // Also set overall booking error
      setBookingError(errorMessage);

      // Keep payment modal open with error displayed
      setLoading(false);
    }
  };

  const handleConfirmationDone = () => {
    // Reset all booking states
    setShowConfirmation(false);
    setSelectedSlot(null);
    setConfirmedBooking(null);
    setBookingSuccess(false);
    setMeetingLink(null);
    setMeetingPassword(null);
    setMeetingStartUrl(null);
    setAlternativeHosts([]);
    setLoading(false);
  };

  const formatSlotTime = (slot) => {
    return `${format(new Date(slot.startDateTime), "h:mm a")} - ${format(
      new Date(slot.endDateTime),
      "h:mm a"
    )}`;
  };

  const getDurationInMinutes = (slot) => {
    const start = new Date(slot.startDateTime);
    const end = new Date(slot.endDateTime);
    return (end - start) / (1000 * 60);
  };

  if (loading && !selectedSlot && !showConfirmation) {
    return (
      <div className="w-full p-8 flex justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green"></div>
      </div>
    );
  }

  // Group slots by morning (before noon), afternoon (noon-5pm), evening (after 5pm)
  const groupSlotsByTimeOfDay = () => {
    const morning = [];
    const afternoon = [];
    const evening = [];

    availableSlots.forEach((slot) => {
      const hour = new Date(slot.startDateTime).getHours();
      if (hour < 12) {
        morning.push(slot);
      } else if (hour < 17) {
        afternoon.push(slot);
      } else {
        evening.push(slot);
      }
    });

    return { morning, afternoon, evening };
  };

  const groupedSlots = groupSlotsByTimeOfDay();
  const isSlotGroupEmpty = !availableSlots.length;

  return (
    <div className="w-full max-w-3xl mx-auto bg-white border-2 border-b-4 border-darkgray rounded shadow-md p-3 sm:p-4">
      {/* Add PaymentNotification at the top */}
      <PaymentNotification />
      {bookingSuccess && !showConfirmation && (
        <div className="mb-4 p-3 bg-green bg-opacity-20 text-green rounded-md">
          Your session has been booked successfully!
        </div>
      )}

      {bookingError && (
        <div className="mb-4 p-3 bg-red bg-opacity-10 text-red rounded-md">
          Error: {bookingError}
        </div>
      )}

      {coachProfile && !showConfirmation && (
        <div className="mb-6">
          <h2 className="text-xl font-bold text-gray-800">
            Book a Session with {coachProfile.name}
          </h2>
          <p className="text-gray text-sm sm:text-base">
            {coachProfile.bio?.length > 180
              ? `${coachProfile.bio.substring(0, 180)}...`
              : coachProfile.bio}
          </p>
          <div className="mt-2 flex flex-wrap gap-1">
            {coachProfile.languages?.map((language) => (
              <span
                key={language}
                className="inline-block bg-gold bg-opacity-20 text-gold px-2 py-1 rounded-full text-xs mr-1 mb-1"
              >
                {language}
              </span>
            ))}
          </div>
        </div>
      )}

      {!showConfirmation && (
        <>
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={handlePrevDay}
              className="p-2 rounded-full hover:bg-darkgray"
            >
              <ChevronLeft size={20} />
            </button>
            <div className="text-lg font-medium text-center">
              {format(selectedDate, "EEEE, MMMM d, yyyy")}
            </div>
            <button
              onClick={handleNextDay}
              className="p-2 rounded-full hover:bg-darkgray"
            >
              <ChevronRight size={20} />
            </button>
          </div>

          <div className="mt-4">
            <h3 className="text-lg font-medium mb-3">Available Time Slots</h3>

            {isSlotGroupEmpty ? (
              <div className="text-center py-8 text-gray">
                No available slots for this day. Try another date.
              </div>
            ) : (
              <div className="space-y-6">
                {/* Morning Slots */}
                {groupedSlots.morning.length > 0 && (
                  <div>
                    <h4 className="text-sm text-gray-500 font-medium mb-2 flex items-center">
                      <span>☀️</span>
                      <span className="ml-2">Morning</span>
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                      {groupedSlots.morning.map((slot) => (
                        <button
                          key={slot.id}
                          onClick={() => handleSelectSlot(slot)}
                          className="border-2 border-b-4 border-darkgray rounded p-3 hover:bg-green hover:bg-opacity-10 hover:border-green flex items-center"
                        >
                          <Clock
                            className="mr-2 text-green flex-shrink-0"
                            size={18}
                          />
                          <div className="text-left">
                            <div className="font-medium text-sm">
                              {formatSlotTime(slot)}
                            </div>
                            <div className="text-xs text-gray">
                              {getDurationInMinutes(slot)} minutes
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* Afternoon Slots */}
                {groupedSlots.afternoon.length > 0 && (
                  <div>
                    <h4 className="text-sm text-gray-500 font-medium mb-2 flex items-center">
                      <span>🌤️</span>
                      <span className="ml-2">Afternoon</span>
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                      {groupedSlots.afternoon.map((slot) => (
                        <button
                          key={slot.id}
                          onClick={() => handleSelectSlot(slot)}
                          className="border-2 border-b-4 border-darkgray rounded p-3 hover:bg-green hover:bg-opacity-10 hover:border-green flex items-center"
                        >
                          <Clock
                            className="mr-2 text-green flex-shrink-0"
                            size={18}
                          />
                          <div className="text-left">
                            <div className="font-medium text-sm">
                              {formatSlotTime(slot)}
                            </div>
                            <div className="text-xs text-gray">
                              {getDurationInMinutes(slot)} minutes
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* Evening Slots */}
                {groupedSlots.evening.length > 0 && (
                  <div>
                    <h4 className="text-sm text-gray-500 font-medium mb-2 flex items-center">
                      <span>🌙</span>
                      <span className="ml-2">Evening</span>
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                      {groupedSlots.evening.map((slot) => (
                        <button
                          key={slot.id}
                          onClick={() => handleSelectSlot(slot)}
                          className="border-2 border-b-4 border-darkgray rounded p-3 hover:bg-green hover:bg-opacity-10 hover:border-green flex items-center"
                        >
                          <Clock
                            className="mr-2 text-green flex-shrink-0"
                            size={18}
                          />
                          <div className="text-left">
                            <div className="font-medium text-sm">
                              {formatSlotTime(slot)}
                            </div>
                            <div className="text-xs text-gray">
                              {getDurationInMinutes(slot)} minutes
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}

      {showPaymentModal && selectedSlot && (
        <PaymentModal
          slot={selectedSlot}
          coachProfile={coachProfile}
          onClose={() => {
            setShowPaymentModal(false);
            setPaymentModalError(null);
          }}
          onConfirm={handleBookSession}
          error={paymentModalError}
        />
      )}

      {showConfirmation && confirmedBooking && (
        <BookingConfirmation
          bookingDetails={confirmedBooking}
          coachProfile={coachProfile}
          sessionDateTime={confirmedBooking.sessionDateTime}
          meetingLink={meetingLink}
          meetingPassword={meetingPassword}
          meetingStartUrl={meetingStartUrl}
          alternativeHosts={alternativeHosts}
          userRole="student"
          onDone={handleConfirmationDone}
        />
      )}
    </div>
  );
};

export default StudentBookingCalendar;
