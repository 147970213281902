import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import { motion } from "framer-motion";
import SideNavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";
import AccountCard from "../../components/settings/account-card.component.jsx";
import ContactUsCard from "../../components/settings/contact-us-card.component.jsx";
import CourseCard from "../../components/settings/course-card.component.jsx";
import CreateProfileCard from "../../components/settings/create-profile-card.component.jsx";
import IngoziCard from "../../components/settings/ingozi-card.component.jsx";
import AudioSettingsCard from "../../components/settings/audio-settings-card.component.jsx";
import AnalyticsSettingsCard from "../../components/settings/analytics-settings-card.jsx";
import SubscriptionsCard from "../../components/settings/subscriptions-card.component.jsx";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils.js";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";
import AnalyticsProviderDiagnostic from "../../components/settings/analytics-provider-diagnostic.jsx";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";

function Settings() {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [membership, setMembership] = useState(null);

  useEffect(() => {
    document.title = "Settings | Fast Fluency Africa";
  }, []);

  useEffect(() => {
    const userDoc = doc(db, "users", `${user?.uid}`);

    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Assuming `membership` is a field in the user's Firestore document
        setMembership(docSnapshot.data().membership);
      }
    });

    // Cleanup
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Make sure to handle this asynchronously
    const fetchMembership = async () => {
      if (user && user?.reloadUserInfo?.customAttributes) {
        const membership = JSON.parse(
          user?.reloadUserInfo?.customAttributes
        )?.membership;
        setMembership(membership);
      } else {
        return null;
      }
    };
    fetchMembership();

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [user]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return loading ? (
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="bg-white min-h-screen pb-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <SideNavigationBar />
      <FeedbackMenu />
      {/* Mobile-first approach: full width on mobile, constrained on larger screens */}
      <div className="mx-auto w-full px-4 sm:px-6 sm:w-11/12 md:w-5/6 lg:w-4/5 text-justify">
        {/* <AnalyticsProviderDiagnostic /> */}
        {membership === "guest" ? <CreateProfileCard /> : <AccountCard />}
        <CourseCard />
        <AudioSettingsCard />
        <AnalyticsSettingsCard />
        {membership === "mabena" ? <SubscriptionsCard /> : null}
        <ContactUsCard />
        {membership === "guest" ? null : <IngoziCard />}
      </div>
    </motion.div>
  );
}

export default Settings;
