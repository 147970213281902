import React, { useState, useEffect, useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";

const ArrangeExercise = ({ data }) => {
  const { setOptionChosen } = useContext(optionChosenContext);
  const { feedback } = useContext(feedbackContext);
  const exerciseConfig = data;

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Audio state
  const audioRef = useRef(new Audio());
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [currentAudioWord, setCurrentAudioWord] = useState(null);

  // State for audio URLs
  const [phraseAudioUrl, setPhraseAudioUrl] = useState("");
  const [wordAudioUrls, setWordAudioUrls] = useState({});

  // Determine the phrase text from targetWord
  const phraseText =
    typeof exerciseConfig.content.targetWord === "object" &&
    exerciseConfig.content.targetWord.text
      ? exerciseConfig.content.targetWord.text
      : exerciseConfig.content.targetWord || "";

  // Determine if the phrase contains multiple words
  const isLongPhrase = phraseText.trim().split(/\s+/).length > 1;

  // For multi-word phrases, check for wordBreakdown
  const wordBreakdown =
    isLongPhrase &&
    typeof exerciseConfig.content.targetWord === "object" &&
    exerciseConfig.content.targetWord.audio &&
    exerciseConfig.content.targetWord.audio.wordBreakdown
      ? exerciseConfig.content.targetWord.audio.wordBreakdown
      : null;

  // Apply audio settings when they change
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = !audioEnabled;

      // If audio is disabled while playing, stop it
      if (!audioEnabled && isPlayingAudio) {
        audioRef.current.pause();
        setIsPlayingAudio(false);
        setCurrentAudioWord(null);
      }
    }
  }, [audioEnabled, isPlayingAudio]);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlayingAudio(false);
      setCurrentAudioWord(null);
    };

    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setIsPlayingAudio(false);
      setCurrentAudioWord(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
      audio.pause();
      audio.src = "";
    };
  }, []);

  // Fetch the full phrase audio URL
  useEffect(() => {
    const fetchPhraseAudio = async () => {
      if (!data) return;

      try {
        // If targetWord is an object with audio
        if (
          typeof data.content.targetWord === "object" &&
          data.content.targetWord.audio
        ) {
          setPhraseAudioUrl(
            await getAudioDownloadUrl(data.content.targetWord.audio)
          );
        }
        // If there's an audio field at the top level
        else if (data.content.audio) {
          setPhraseAudioUrl(await getAudioDownloadUrl(data.content.audio));
        }
        // If there's an audioPrompt field
        else if (data.audioPrompt) {
          setPhraseAudioUrl(await getAudioDownloadUrl(data.audioPrompt));
        }
      } catch (err) {
        console.error("Error fetching phrase audio:", err);
      }
    };

    fetchPhraseAudio();
  }, [data]);

  // Fetch individual word audio URLs if wordBreakdown is provided
  useEffect(() => {
    const fetchWordAudios = async () => {
      if (!wordBreakdown) return;

      const urls = {};
      for (const word of wordBreakdown) {
        if (word.audio) {
          try {
            urls[word.text] = await getAudioDownloadUrl(word.audio);
          } catch (err) {
            console.error(`Error fetching audio for ${word.text}:`, err);
          }
        }
      }

      setWordAudioUrls(urls);
    };

    fetchWordAudios();
  }, [wordBreakdown]);

  // Functions to control full phrase playback
  const handlePlayNormal = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioRef.current && phraseAudioUrl) {
      // Stop any currently playing audio
      audioRef.current.pause();

      // Set up and play new audio
      audioRef.current.src = phraseAudioUrl;
      audioRef.current.playbackRate = 1.0;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlayingAudio(true);
      setCurrentAudioWord("phrase");
    }
  };

  const handlePlaySlow = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioRef.current && phraseAudioUrl) {
      // Stop any currently playing audio
      audioRef.current.pause();

      // Set up and play new audio at slower speed
      audioRef.current.src = phraseAudioUrl;
      audioRef.current.playbackRate = 0.75;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlayingAudio(true);
      setCurrentAudioWord("phrase-slow");
    }
  };

  // Handle word audio playback
  const handlePlayWord = (wordText) => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    const audioUrl = wordAudioUrls[wordText];
    if (audioUrl && audioRef.current) {
      // Stop any currently playing audio
      audioRef.current.pause();

      // Set up and play word audio
      audioRef.current.src = audioUrl;
      audioRef.current.currentTime = 0;

      // Play and update state
      audioRef.current.play().catch((err) => {
        console.error("Audio playback error:", err);
      });

      setIsPlayingAudio(true);
      setCurrentAudioWord(wordText);
    }
  };

  const handlePauseWord = () => {
    if (audioRef.current && isPlayingAudio) {
      audioRef.current.pause();
      setIsPlayingAudio(false);
      setCurrentAudioWord(null);
    }
  };

  // For assembling the user's answer
  const [selectedWords, setSelectedWords] = useState([]);
  const [usedIndices, setUsedIndices] = useState(new Set());
  const [wordBank, setWordBank] = useState([]);

  // Update the parent with the current assembled answer
  useEffect(() => {
    const userAnswer = selectedWords.join(" ");
    setOptionChosen(userAnswer);
  }, [selectedWords, setOptionChosen]);

  // Initialize the word bank by shuffling potential answers
  useEffect(() => {
    const shuffled = [...exerciseConfig.content.potentialAnswers].sort(
      () => Math.random() - 0.5
    );
    setWordBank(shuffled);
    setSelectedWords([]);
    setUsedIndices(new Set());
  }, [exerciseConfig]);

  const handleWordSelect = (word, index) => {
    if (usedIndices.has(index)) {
      setSelectedWords((prev) => prev.filter((w) => w !== word));
      setUsedIndices((prev) => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      });
    } else {
      setSelectedWords((prev) => [...prev, word]);
      setUsedIndices((prev) => new Set([...prev, index]));
    }
  };

  // Helper functions to handle the UI appearance for audio controls
  const getAudioButtonContent = (type) => {
    if (!audioEnabled) {
      return "🔇";
    }

    if (
      isPlayingAudio &&
      ((type === "normal" && currentAudioWord === "phrase") ||
        (type === "slow" && currentAudioWord === "phrase-slow"))
    ) {
      return "⏸️";
    }

    return type === "normal" ? "▶️" : "🐢";
  };

  const getAudioButtonTitle = (type) => {
    if (!audioEnabled) {
      return "Audio is disabled globally";
    }

    if (
      isPlayingAudio &&
      ((type === "normal" && currentAudioWord === "phrase") ||
        (type === "slow" && currentAudioWord === "phrase-slow"))
    ) {
      return "Pause audio";
    }

    return type === "normal" ? "Play at normal speed" : "Play at slower speed";
  };

  return (
    <div className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl">
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        <div className="w-full text-center">
          <h1 className="text-lg sm:text-xl font-bold text-left font-headers text-black capitalize mb-2">
            {exerciseConfig.content.question}
          </h1>
          <div className="border-2 border-b-4 border-black font-body p-2 sm:p-4 flex flex-col items-center justify-center">
            {/* Render the phrase */}
            <div className="flex flex-wrap justify-center">
              {wordBreakdown ? (
                wordBreakdown.map((word, idx) => (
                  <div
                    key={idx}
                    className={`mx-1 cursor-pointer underline decoration-dotted ${
                      !audioEnabled ? "text-gray-500" : ""
                    }`}
                    onMouseEnter={() =>
                      audioEnabled && handlePlayWord(word.text)
                    }
                    onMouseLeave={handlePauseWord}
                  >
                    {word.text}
                    {isPlayingAudio && currentAudioWord === word.text && (
                      <span className="ml-1 text-xs">🔊</span>
                    )}
                  </div>
                ))
              ) : (
                <div
                  className={`cursor-pointer underline decoration-dotted ${
                    !audioEnabled ? "text-gray-500" : ""
                  }`}
                  onMouseEnter={audioEnabled ? handlePlayNormal : undefined}
                  onMouseLeave={handlePauseWord}
                >
                  {phraseText}
                  {isPlayingAudio && currentAudioWord === "phrase" && (
                    <span className="ml-1 text-xs">🔊</span>
                  )}
                </div>
              )}
            </div>

            {/* Full phrase playback buttons */}
            <div className="mt-4 flex space-x-4">
              <button
                onClick={handlePlayNormal}
                className={`p-2 border rounded bg-white hover:bg-gray-100 ${
                  !audioEnabled ? "opacity-50" : ""
                }`}
                title={getAudioButtonTitle("normal")}
              >
                {getAudioButtonContent("normal")}
              </button>
              <button
                onClick={handlePlaySlow}
                className={`p-2 border rounded bg-white hover:bg-gray-100 ${
                  !audioEnabled ? "opacity-50" : ""
                }`}
                title={getAudioButtonTitle("slow")}
              >
                {getAudioButtonContent("slow")}
              </button>
            </div>

            {/* Audio disabled message */}
            {!audioEnabled && (
              <div className="text-xs text-gray-500">
                Audio is disabled globally. Enable it in Settings.
              </div>
            )}
          </div>
          <div className="">
            <span className="text-5xl sm:text-7xl text-outline-gray">😃</span>
          </div>
        </div>

        {/* Selected Words Area */}
        <div className="relative w-full mx-auto min-h-[150px] sm:min-h-[200px] border-gray-200 rounded-lg overflow-hidden">
          <div className="absolute inset-3 bg-[repeating-linear-gradient(transparent_0_3rem,rgba(0,0,0,0.10)_3rem_3.1rem)] pointer-events-none" />
          <div className="relative z-10 flex flex-wrap items-start gap-2 p-4">
            <AnimatePresence>
              {selectedWords.map((word, idx) => (
                <motion.div
                  key={`selected-${idx}`}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  className={`inline-flex items-center px-3 py-2 text-xs sm:text-sm bg-white border-2 border-b-4 rounded-lg cursor-pointer ${
                    feedback === "correct"
                      ? "border-green text-green"
                      : feedback === "incorrect"
                      ? "border-red text-red"
                      : "border-gray text-gray"
                  }`}
                  onClick={() => {
                    const originalIndex = wordBank.indexOf(word);
                    handleWordSelect(word, originalIndex);
                  }}
                >
                  {word}
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>

        {/* Word Bank - Changed from fixed width to fluid, and grid from 3 to responsive */}
        <div className="w-full mx-auto grid grid-cols-2 sm:grid-cols-3 gap-2 p-4 bg-gray-50 rounded-lg border-gray-200">
          {wordBank.map((word, index) => (
            <motion.div key={`word-${index}`} className="relative">
              {usedIndices.has(index) ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="px-4 py-5 h-4 bg-darkgray border-2 border-darkgray rounded-lg cursor-not-allowed"
                />
              ) : (
                <motion.button
                  onClick={() => handleWordSelect(word, index)}
                  className="w-full px-3 py-2 text-xs sm:text-sm rounded-lg transition-all text-gray border-b-4 bg-white border-2 border-gray"
                >
                  {word}
                </motion.button>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

ArrangeExercise.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      targetWord: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string,
          audio: PropTypes.any,
        }),
      ]),
      question: PropTypes.string,
      potentialAnswers: PropTypes.array.isRequired,
      audio: PropTypes.any,
    }).isRequired,
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string,
    }),
    audioPrompt: PropTypes.any,
    id: PropTypes.string,
  }).isRequired,
};

export default ArrangeExercise;
