import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { default as mailoptin } from "../../assets/imgs/5-minute-muti-lrg.jpg";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { BeatLoader } from "react-spinners";
import { useFormik } from "formik";
import * as Yup from "yup";

function MailOptinForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [formFocus, setFormFocus] = useState(null);

  // Function to submit the form data to Firestore
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    setError("");

    try {
      // Add a timestamp to the data before sending it to Firestore
      const dataWithTimestamp = {
        ...values,
        timestamp: serverTimestamp(),
      };

      await addDoc(collection(db, "newsletter"), dataWithTimestamp);

      setSubmissionSuccess(true);
      resetForm({});
      setSubmitting(false);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Your name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Your email is required"),
    }),
    onSubmit: handleSubmit,
  });

  // Reset success message after 5 seconds
  useEffect(() => {
    let timeout;
    if (submissionSuccess) {
      timeout = setTimeout(() => {
        setSubmissionSuccess(false);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [submissionSuccess]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
    hover: {
      scale: 1.02,
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: { duration: 0.3, ease: "easeOut" },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    tap: {
      scale: 0.95,
      y: 4,
      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.1 },
    },
  };

  return (
    <motion.div
      className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden max-w-5xl mx-auto border-2 border-b-4 border-darkgray"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Left Side - Image and Title */}
      <motion.div className="md:w-1/2 relative overflow-hidden">
        <motion.img
          src={mailoptin}
          alt="5 Minute Muti Method"
          className="w-full h-full object-cover"
          variants={imageVariants}
          whileHover="hover"
        />
        <motion.div
          className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent flex flex-col justify-end p-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <motion.h2
            className="text-center text-white text-xl md:text-2xl lg:text-3xl mb-2 font-headers font-semibold"
            variants={itemVariants}
          >
            Join the Lekgotla
          </motion.h2>
          <motion.div
            className="h-1 w-24 bg-green mx-auto mb-4"
            initial={{ width: 0 }}
            animate={{ width: "6rem" }}
            transition={{ duration: 0.8, delay: 0.5 }}
          />
        </motion.div>
      </motion.div>

      {/* Right Side - Form and Description */}
      <motion.div
        className="md:w-1/2 p-6 md:p-8 flex flex-col justify-between"
        variants={itemVariants}
      >
        <div>
          <motion.p className="text-black text-md mb-6" variants={itemVariants}>
            Unlock the secrets to mastering African languages with our exclusive
            PDF guide,{" "}
            <span className="font-bold">The 5 Minute Muti Method</span>,
            available for <span className="font-bold text-green">free</span>{" "}
            when you sign up to our Lekgotla.
          </motion.p>
          <motion.p
            className="text-gray-700 text-sm mb-6"
            variants={itemVariants}
          >
            Turn mundane minutes into magical moments with our holistic approach
            that fuses linguistic learning with cultural wisdom. Subscribe now
            and begin a journey that transcends mere words.
          </motion.p>
        </div>

        <AnimatePresence mode="wait">
          {submissionSuccess ? (
            <motion.div
              key="success"
              className="bg-green/10 border-l-4 border-green p-4 mb-4 rounded"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <div className="flex items-center">
                <div className="flex-shrink-0 w-6 h-6 bg-green rounded-full flex items-center justify-center text-white mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="text-green text-md font-medium">
                  Congratulations! You have successfully subscribed.
                </p>
              </div>
              <p className="text-green text-sm mt-2">
                Check your email inbox soon for your free guide.
              </p>
            </motion.div>
          ) : (
            <motion.form
              key="form"
              onSubmit={formik.handleSubmit}
              className="space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className="relative"
                animate={{
                  scale: formFocus === "name" ? 1.02 : 1,
                  transition: { duration: 0.2 },
                }}
              >
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setFormFocus(null);
                  }}
                  onFocus={() => setFormFocus("name")}
                  value={formik.values.name}
                  className={`w-full border-2 border-b-4 ${
                    formik.touched.name && formik.errors.name
                      ? "border-red focus:border-red"
                      : "border-gray-300 focus:border-green hover:border-green"
                  } rounded py-2 px-3 bg-white focus:outline-none transition-colors duration-200`}
                  placeholder="Your name"
                />
                {formik.touched.name && formik.errors.name && (
                  <motion.p
                    className="text-red text-xs italic mt-1"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {formik.errors.name}
                  </motion.p>
                )}
              </motion.div>

              <motion.div
                className="relative"
                animate={{
                  scale: formFocus === "email" ? 1.02 : 1,
                  transition: { duration: 0.2 },
                }}
              >
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setFormFocus(null);
                  }}
                  onFocus={() => setFormFocus("email")}
                  value={formik.values.email}
                  className={`w-full border-2 border-b-4 ${
                    formik.touched.email && formik.errors.email
                      ? "border-red focus:border-red"
                      : "border-gray-300 focus:border-green hover:border-green"
                  } rounded py-2 px-3 bg-white focus:outline-none transition-colors duration-200`}
                  placeholder="Your email address"
                />
                {formik.touched.email && formik.errors.email && (
                  <motion.p
                    className="text-red text-xs italic mt-1"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {formik.errors.email}
                  </motion.p>
                )}
              </motion.div>

              <motion.div className="flex items-center justify-center mt-6">
                <motion.button
                  type="submit"
                  disabled={isLoading}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  className="px-6 py-3 border-2 border-b-4 border-green bg-white hover:bg-green hover:text-white text-black font-medium text-sm uppercase rounded shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green focus:ring-opacity-50 transition-all duration-300 disabled:opacity-70"
                  variants={buttonVariants}
                  whileHover="hover"
                  whileTap="tap"
                >
                  {isLoading ? (
                    <BeatLoader
                      color={hovered ? "#FCFCEE" : "#000000"}
                      size={8}
                    />
                  ) : (
                    "Subscribe Now"
                  )}
                </motion.button>
              </motion.div>

              {error && (
                <motion.p
                  className="text-red text-xs italic text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  {error}
                </motion.p>
              )}
            </motion.form>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
}

export default MailOptinForm;
