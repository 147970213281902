// pages/articles/category/[category-slug].jsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import PuffLoader from "react-spinners/PuffLoader";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Breadcrumbs from "../../components/articles/breadcrumbs";
import ArticleList from "../../components/articles/article-list";
import Footer from "../../components/footer/footer.component";
import ArticleSearch from "../../components/articles/search";
import { getCategoryBySlug } from "../../zombiedata/articles-2";

const ArticlesCategoryPage = () => {
  const { categorySlug } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    // Get category data
    const foundCategory = getCategoryBySlug(categorySlug);

    if (!foundCategory) {
      // If category not found, redirect to articles index
      navigate("/articles");
      return;
    }

    setCategory(foundCategory);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [categorySlug, navigate]);

  useEffect(() => {
    if (category) {
      document.title = `${category.title} Articles | Fast Fluency Africa`;
    }
  }, [category]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [categorySlug]);

  if (loading) {
    return (
      <div
        data-testid="loading-spinner"
        className="flex flex-row justify-center items-center h-screen"
      >
        <PuffLoader color={"#991616"} loading={loading} size={100} />
      </div>
    );
  }

  if (!category) return null;

  const breadcrumbItems = [
    { label: "Blog", href: "/articles" },
    { label: category.title },
  ];

  return (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <div className="bg-red w-full">
        <NavBarPrimary />
      </div>

      <main className="flex-grow container mx-auto px-4 py-8">
        <Breadcrumbs items={breadcrumbItems} />

        <div className="mb-8">
          <div className="flex items-center mb-4">
            <span className="text-3xl mr-3">{category.icon}</span>
            <h1 className="text-3xl font-bold">{category.title}</h1>
          </div>
          <p className="text-gray-600">{category.description}</p>
        </div>

        <div className="max-w-xl mb-8">
          <ArticleSearch
            placeholder={`Search in ${category.title}...`}
            categorySlug={categorySlug}
          />
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            All Articles ({category.articles.length})
          </h2>
          <ArticleList
            articles={category.articles.map((article) => ({
              ...article,
              categoryId: category.id,
              categoryTitle: category.title,
              categorySlug: category.slug,
            }))}
            emptyMessage="No articles found in this category"
          />
        </div>
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default ArticlesCategoryPage;
