// OnboardingComplete.jsx
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import confetti from "canvas-confetti";

const OnboardingComplete = ({ onComplete }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Trigger confetti effect
    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;

    const randomInRange = (min, max) => {
      return Math.random() * (max - min) + min;
    };

    const confettiInterval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(confettiInterval);
        return;
      }

      confetti({
        particleCount: 3,
        startVelocity: 0,
        ticks: 300,
        origin: {
          x: randomInRange(0.1, 0.9),
          y: Math.random() - 0.2,
        },
        colors: ["#991616", "#d19a10", "#848717"],
        shapes: ["circle", "square"],
        gravity: randomInRange(0.4, 0.6),
        scalar: randomInRange(0.8, 1.2),
      });
    }, 150);

    // Show button after a delay
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 2000);

    return () => {
      clearInterval(confettiInterval);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="flex flex-col items-center text-center">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
          duration: 1,
        }}
        className="mb-6"
      >
        <div className="w-24 h-24 bg-green rounded-full flex items-center justify-center mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
        </div>
      </motion.div>

      <motion.h3
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="text-2xl font-bold font-headers mb-4"
      >
        You're Ready to Begin!
      </motion.h3>

      <motion.p
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="mb-6"
      >
        Thank you for completing the onboarding process. You're now officially
        part of our early access program and ready to start your language
        learning journey!
      </motion.p>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        className="p-4 bg-gold/10 border-l-4 border-gold rounded-md mb-8 text-left"
      >
        <h4 className="font-bold text-lg mb-2">What's Next?</h4>
        <ul className="list-disc list-inside space-y-2">
          <li>Explore our language lessons in the Vault</li>
          <li>Complete your profile and select your preferred languages</li>
          <li>Join our Discord community for early access participants</li>
          <li>Check the Feedback section to share your thoughts</li>
        </ul>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: showButton ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        className="w-full"
      >
        <button
          onClick={onComplete}
          className="px-8 py-3 border-2 rounded text-center w-full md:w-auto
            cursor-pointer
            text-green font-bold border-green hover:border-white hover:bg-green hover:text-white
            text-md md:text-lg
            uppercase
            shadow-md
            hover:shadow-lg
            transition
            duration-150
            transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4"
        >
          Start Exploring
        </button>
      </motion.div>
    </div>
  );
};

export default OnboardingComplete;
