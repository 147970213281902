import { createContext } from "react";
import { LMContext } from "./context3";

export const ExerciseContext = createContext();
export const LearningMaterialsContext = createContext();
export const ViewStateContext = createContext();
export const OnboardingContext = createContext();
export const LoginRegistrationContext = createContext();
// export const CourseContext = createContext();
// export const MilestoneContext = createContext();
export const GuidebookContext = createContext();
