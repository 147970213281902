// src/helpers/EnhancedAnalyticsProvider.js - PATCHED VERSION
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useAuth } from "./UserAuthContextProvider";
import { AnalyticsService } from "../services/enhanced-analytics.service";
import { useLearningAnalytics } from "../hooks/useLearningAnalytics";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import {
  shouldAllowRefresh,
  trackRefreshStart,
  trackRefreshComplete,
} from "../utils/analytics/analytics-guard";

// Create context with default values
export const AnalyticsContext = createContext({
  userStats: null,
  activityData: [],
  achievements: [],
  isLoading: false,
  trackingEnabled: false,
  timeRange: "week",
  trackEvent: () => Promise.resolve(),
  toggleTracking: () => {},
  changeTimeRange: () => {},
  refreshAnalytics: () => {},
  calculateLevel: () => 1,
  getLevelProgress: () => 0,
});

// Create hook for using analytics context with error handling
export const useAnalytics = () => {
  try {
    const context = useContext(AnalyticsContext);
    if (!context) {
      console.warn("useAnalytics must be used within an AnalyticsProvider");
      // Return default values instead of throwing
      return {
        userStats: null,
        activityData: [],
        achievements: [],
        isLoading: false,
        trackingEnabled: false,
        timeRange: "week",
        trackEvent: () => Promise.resolve(),
        toggleTracking: () => {},
        changeTimeRange: () => {},
        refreshAnalytics: () => {},
        calculateLevel: () => 1,
        getLevelProgress: () => 0,
      };
    }
    return context;
  } catch (error) {
    console.error("Error in useAnalytics:", error);
    // Return default values in case of error
    return {
      userStats: null,
      activityData: [],
      achievements: [],
      isLoading: false,
      trackingEnabled: false,
      timeRange: "week",
      trackEvent: () => Promise.resolve(),
      toggleTracking: () => {},
      changeTimeRange: () => {},
      refreshAnalytics: () => {},
      calculateLevel: () => 1,
      getLevelProgress: () => 0,
    };
  }
};

export const EnhancedAnalyticsProvider = ({ children }) => {
  // Get user auth data with error handling
  const auth = useAuth();
  const user = auth?.user || null;

  // Use separate loading state for legacy data to avoid race conditions
  const { learningProgress, isLoading: legacyLoading } = useLearningAnalytics();

  // State for analytics data
  const [userStats, setUserStats] = useState(null);
  const [activityData, setActivityData] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trackingEnabled, setTrackingEnabled] = useState(false);
  const [timeRange, setTimeRange] = useState("week");

  // References for tracking state between renders
  const userStatsRef = useRef(null);
  const activityDataRef = useRef([]);
  const firestoreListenerRef = useRef(null);
  const isInitializedRef = useRef(false);

  // Lifecycle flags
  const legacyMergeComplete = useRef(false);
  const initialLoadComplete = useRef(false);
  const isMountedRef = useRef(true);
  const listenerInitialized = useRef(false);

  // Initialize component
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;

      // Clean up Firestore listener
      if (firestoreListenerRef.current) {
        firestoreListenerRef.current();
        firestoreListenerRef.current = null;
      }
    };
  }, []);

  // Initialize tracking preference properly
  useEffect(() => {
    try {
      const savedPreference = localStorage.getItem(
        "analytics-tracking-enabled"
      );
      const initialValue =
        savedPreference !== null ? savedPreference === "true" : true;
      setTrackingEnabled(initialValue);
    } catch (error) {
      console.warn("Error reading tracking preference:", error);
      setTrackingEnabled(false);
    }
  }, []);

  // 4. Add this effect to initialize timeRange properly
  useEffect(() => {
    if (user?.uid && !timeRange) {
      console.log("Setting default timeRange to 'week'");
      setTimeRange("week");
    }
  }, [user?.uid, timeRange]);

  // Toggle analytics tracking
  const toggleTracking = useCallback(() => {
    try {
      const newValue = !trackingEnabled;
      localStorage.setItem("analytics-tracking-enabled", newValue.toString());
      setTrackingEnabled(newValue);

      // Update service cache as well
      AnalyticsService.updateTrackingCache(newValue);
    } catch (error) {
      console.warn("Error toggling tracking:", error);
    }
  }, [trackingEnabled]);

  // Process raw Firestore analytics data to add calculated fields
  const processAnalyticsData = useCallback((data) => {
    if (!data) return null;

    try {
      // Clone the data to avoid modifying the original
      const processedData = { ...data };

      // Calculate today's XP
      const today = new Date().toISOString().split("T")[0];
      let todayXp = 0;

      if (
        processedData.daily_activity &&
        processedData.daily_activity[today] &&
        typeof processedData.daily_activity[today].xp_earned === "number"
      ) {
        todayXp = processedData.daily_activity[today].xp_earned;
      }

      // Calculate weekly XP
      let weeklyXp = 0;

      if (processedData.daily_activity) {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        Object.entries(processedData.daily_activity).forEach(
          ([dateStr, dayData]) => {
            if (!dayData) return;

            try {
              const entryDate = new Date(dateStr);
              if (isNaN(entryDate.getTime())) return;

              if (entryDate >= oneWeekAgo) {
                weeklyXp += dayData.xp_earned || 0;
              }
            } catch (e) {
              // Silently handle error to avoid console spam
            }
          }
        );
      }

      // Add calculated fields
      processedData.today_xp = todayXp;
      processedData.weekly_xp = weeklyXp;

      // Add a timestamp for when this data was processed
      processedData._processed_at = new Date().getTime();

      return processedData;
    } catch (error) {
      return data; // Return original data if processing fails
    }
  }, []);

  // Set up Firestore snapshot listener for real-time updates - COMPLETELY REVISED
  useEffect(() => {
    // Run once per user session, don't recreate on every render
    if (!user?.uid || !isMountedRef.current || listenerInitialized.current)
      return;

    // Guard against creating multiple listeners
    if (firestoreListenerRef.current) {
      return;
    }

    // Set the flag to true to prevent multiple listener setups
    listenerInitialized.current = true;

    try {
      const userAnalyticsRef = doc(db, "user_analytics", user.uid);

      // Set up snapshot listener with strict update rules
      firestoreListenerRef.current = onSnapshot(
        userAnalyticsRef,
        (docSnapshot) => {
          if (!isMountedRef.current) return;

          if (docSnapshot.exists()) {
            const firestoreData = docSnapshot.data();

            // Only update if there's a significant change (total_xp changed by at least 10)
            const currentXp = userStatsRef.current?.total_xp || 0;
            const newXp = firestoreData.total_xp || 0;

            if (Math.abs(newXp - currentXp) < 10 && userStatsRef.current) {
              // Skip minor updates
              return;
            }

            // Store raw Firestore data in ref
            userStatsRef.current = firestoreData;

            // Process data with calculated fields
            const enhancedData = processAnalyticsData(firestoreData);

            // Update state with processed data - don't trigger loading
            setUserStats(enhancedData);

            // Only update achievements if this was a significant update
            if (Math.abs(newXp - currentXp) >= 50) {
              checkForAchievements(enhancedData);
            }
          }
        },
        (error) => {
          // Silence error logging to prevent console spam
        }
      );
    } catch (error) {
      // Silence error logging to prevent console spam
    }
  }, [user?.uid, processAnalyticsData]);

  // Fetch user stats from Firestore - COMPLETELY REVISED WITH GLOBAL GUARD
  const fetchUserStats = useCallback(
    async (forceRefresh = false) => {
      // Check if refresh should be allowed via global guard
      if (!user?.uid || !shouldAllowRefresh(forceRefresh)) {
        return userStatsRef.current || null;
      }

      try {
        // Track refresh start via global guard
        trackRefreshStart();
        setIsLoading(true);

        const stats = await AnalyticsService.getUserAnalyticsSummary(
          user.uid,
          forceRefresh
        );

        if (!isMountedRef.current) {
          trackRefreshComplete();
          return null;
        }

        // Process stats with calculated fields
        const processedStats = processAnalyticsData(stats);

        // Update refs and state
        userStatsRef.current = processedStats;
        setUserStats(processedStats);

        // Once loaded, mark as initialized
        if (!isInitializedRef.current) {
          isInitializedRef.current = true;
        }

        return processedStats;
      } catch (error) {
        return userStatsRef.current || null;
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
          initialLoadComplete.current = true;
          // Track refresh complete via global guard
          trackRefreshComplete();
        }
      }
    },
    [user?.uid, processAnalyticsData]
  );

  // Replace the entire fetchActivityData function in EnhancedAnalyticsContextProvider.js with this version

  // 2. Replace your fetchActivityData function with this version that handles undefined timeRange:
  const fetchActivityData = useCallback(
    async (forceRefresh = false) => {
      if (!user?.uid) {
        console.log("fetchActivityData: No user ID available");
        return [];
      }

      // Check and handle undefined timeRange
      const effectiveTimeRange = timeRange || "week";
      console.log(`fetchActivityData: Using timeRange: ${effectiveTimeRange}`);

      try {
        let days = 7; // Default for week
        if (effectiveTimeRange === "month") days = 30;
        if (effectiveTimeRange === "year") days = 365;

        console.log(
          `fetchActivityData: Requesting ${days} days of activity data`
        );

        const activity = await AnalyticsService.getUserDailyActivity(
          user.uid,
          days
        );

        if (!isMountedRef.current) {
          return [];
        }

        // Store activity data even if empty
        activityDataRef.current = activity || [];
        setActivityData(activity || []);

        return activity;
      } catch (error) {
        console.error("Error fetching activity data:", error);
        return activityDataRef.current;
      }
    },
    [timeRange, user?.uid]
  );

  // Refresh all analytics data - COMPLETELY REVISED WITH GLOBAL GUARD
  const refreshAnalytics = useCallback(
    async (forceRefresh = false) => {
      if (!user?.uid || !isMountedRef.current) return;

      // Check if refresh should be allowed via global guard
      if (!shouldAllowRefresh(forceRefresh)) {
        return;
      }

      try {
        // Track refresh start via global guard
        trackRefreshStart();
        setIsLoading(true);

        const [statsResult, activityResult] = await Promise.all([
          fetchUserStats(forceRefresh),
          fetchActivityData(forceRefresh),
        ]);

        return { statsResult, activityResult };
      } catch (error) {
        // Silence errors to prevent console spam
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
          // Track refresh complete via global guard
          trackRefreshComplete();
        }
      }
    },
    [fetchUserStats, fetchActivityData, user?.uid]
  );

  // Check for achievements - SIMPLIFIED
  const checkForAchievements = useCallback((stats) => {
    if (!stats || !isMountedRef.current) return [];

    try {
      const newAchievements = [];

      // Streak achievements
      if (stats.current_streak >= 7) {
        newAchievements.push({
          id: "streak-7",
          icon: "🔥",
          title: "7-Day Streak",
          description: "You've been learning for 7 days in a row!",
          progress: Math.min(100, (stats.current_streak / 7) * 100),
          progressText: `${stats.current_streak}/7 days`,
          isComplete: stats.current_streak >= 7,
        });
      }

      if (stats.current_streak >= 30) {
        newAchievements.push({
          id: "streak-30",
          icon: "🔥🔥🔥",
          title: "30-Day Streak",
          description: "A full month of language learning!",
          progress: Math.min(100, (stats.current_streak / 30) * 100),
          progressText: `${stats.current_streak}/30 days`,
          isComplete: stats.current_streak >= 30,
        });
      }

      // XP achievements
      newAchievements.push({
        id: "xp-1000",
        icon: "🏆",
        title: "XP Milestone",
        description: "Earn 1000 XP points",
        progress: Math.min(100, (stats.total_xp || 0) / 1000) * 100,
        progressText: `${stats.total_xp || 0}/1000 XP`,
        isComplete: (stats.total_xp || 0) >= 1000,
      });

      // Vocabulary achievements
      newAchievements.push({
        id: "vocabulary-100",
        icon: "📚",
        title: "Word Master",
        description: "Learn 100 vocabulary words",
        progress:
          Math.min(100, (stats.vocabulary_learned_count || 0) / 100) * 100,
        progressText: `${stats.vocabulary_learned_count || 0}/100 words`,
        isComplete: (stats.vocabulary_learned_count || 0) >= 100,
      });

      setAchievements(newAchievements);
      return newAchievements;
    } catch (error) {
      return [];
    }
  }, []);

  // Event queue to batch process events
  const eventQueue = useRef([]);
  const isProcessingEvents = useRef(false);

  // Track an analytics event - OPTIMIZED
  const trackEvent = useCallback(
    async (eventType, eventData = {}) => {
      if (!user?.uid || !trackingEnabled || !isMountedRef.current)
        return Promise.resolve();

      try {
        // For offline support, queue events when offline
        if (!navigator.onLine) {
          return Promise.resolve();
        }

        // Queue events for processing, but don't process automatically
        // This prevents excessive operations
        eventQueue.current.push({
          type: eventType,
          data: eventData,
          timestamp: new Date().getTime(),
        });

        // Only process XP events immediately for UI update
        if (eventType === "xp_earned" && eventData.xp && userStatsRef.current) {
          const updatedStats = { ...userStatsRef.current };

          // Update total_xp
          updatedStats.total_xp = (updatedStats.total_xp || 0) + eventData.xp;

          // Update today's XP
          const today = new Date().toISOString().split("T")[0];
          if (!updatedStats.daily_activity) updatedStats.daily_activity = {};
          if (!updatedStats.daily_activity[today])
            updatedStats.daily_activity[today] = {};

          updatedStats.daily_activity[today].xp_earned =
            (updatedStats.daily_activity[today].xp_earned || 0) + eventData.xp;

          // Process with calculated fields
          const processedStats = processAnalyticsData(updatedStats);

          // Update state immediately for responsive UI
          if (isMountedRef.current) {
            userStatsRef.current = processedStats;
            setUserStats(processedStats);
          }

          // Process event in background without triggering refresh
          AnalyticsService.trackEvent(user.uid, eventType, eventData).catch(
            () => {}
          );
        }

        return true;
      } catch (error) {
        return false;
      }
    },
    [user?.uid, trackingEnabled, processAnalyticsData]
  );

  // 3. Replace the changeTimeRange function with this enhanced version:
  const changeTimeRange = useCallback(
    (range) => {
      console.log(`changeTimeRange called with: ${range}`);

      // Validate range and use default if invalid
      if (!range || !["week", "month", "year"].includes(range)) {
        console.warn(`Invalid timeRange: ${range}, defaulting to week`);
        range = "week";
      }

      setTimeRange(range);

      // Force data refresh when time range changes explicitly
      if (user?.uid && initialLoadComplete.current) {
        console.log(`Time range changed to ${range}, refreshing data`);
        setTimeout(() => fetchActivityData(true), 50);
      }
    },
    [fetchActivityData, user?.uid]
  );

  // Calculate user level based on XP
  const calculateLevel = useCallback(() => {
    const totalXp = userStatsRef.current?.total_xp || 0;
    return Math.floor(totalXp / 1000) + 1;
  }, []);

  // Get progress to next level
  const getLevelProgress = useCallback(() => {
    const totalXp = userStatsRef.current?.total_xp || 0;
    return (totalXp % 1000) / 10; // As percentage (0-100)
  }, []);

  // Fetch initial data when user changes - SIMPLIFIED AND GUARDED
  useEffect(() => {
    if (!user?.uid) {
      // Reset data when user logs out
      userStatsRef.current = null;
      activityDataRef.current = [];
      setUserStats(null);
      setActivityData([]);
      setAchievements([]);
      initialLoadComplete.current = false;
      isInitializedRef.current = false;
      listenerInitialized.current = false;

      // Clean up Firestore listener
      if (firestoreListenerRef.current) {
        firestoreListenerRef.current();
        firestoreListenerRef.current = null;
      }

      return;
    }

    // Only fetch if we haven't already loaded data
    if (!initialLoadComplete.current && isMountedRef.current) {
      // Use global guard to control refresh
      if (shouldAllowRefresh(true)) {
        refreshAnalytics(true);
      }
    }

    // Reset merge flag when user changes
    legacyMergeComplete.current = false;
  }, [user?.uid, refreshAnalytics]);

  // Re-fetch activity data when time range changes - GUARDED
  useEffect(() => {
    if (user?.uid && initialLoadComplete.current && isMountedRef.current) {
      // Only refetch if we have already fetched once and time range changed
      // if (activityDataRef.current.length > 0) {
      // Use global guard to control refresh
      if (shouldAllowRefresh(false)) {
        fetchActivityData(true);
      }
      // }
    }
  }, [timeRange, fetchActivityData, user?.uid]);

  // Merge legacy data - WITH SAFETY CHECKS - SIMPLIFIED
  useEffect(() => {
    if (
      learningProgress &&
      userStatsRef.current &&
      user?.uid &&
      !legacyMergeComplete.current &&
      isMountedRef.current
    ) {
      try {
        legacyMergeComplete.current = true;
        let needsUpdate = false;
        const mergedStats = { ...userStatsRef.current };

        // Apply any missing legacy data
        if (
          !mergedStats.lessons_started_count &&
          learningProgress.lessons_started
        ) {
          mergedStats.lessons_started_count = learningProgress.lessons_started;
          needsUpdate = true;
        }

        if (
          !mergedStats.lessons_completed_count &&
          learningProgress.lessons_completed
        ) {
          mergedStats.lessons_completed_count =
            learningProgress.lessons_completed;
          needsUpdate = true;
        }

        if (!mergedStats.total_xp && learningProgress.total_xp) {
          mergedStats.total_xp = learningProgress.total_xp;
          needsUpdate = true;
        }

        // Only update state if there's actually a change
        if (needsUpdate && isMountedRef.current) {
          const processedStats = processAnalyticsData(mergedStats);
          userStatsRef.current = processedStats;
          setUserStats(processedStats);
        }
      } catch (error) {
        // Silence errors to prevent console spam
      }
    }
  }, [learningProgress, user?.uid, processAnalyticsData]);

  // Memoize context value to prevent unnecessary renders
  const contextValue = useMemo(
    () => ({
      userStats,
      activityData,
      achievements,
      isLoading: isLoading || legacyLoading,
      trackingEnabled,
      timeRange,
      trackEvent,
      toggleTracking,
      changeTimeRange,
      refreshAnalytics,
      calculateLevel,
      getLevelProgress,
    }),
    [
      userStats,
      activityData,
      achievements,
      isLoading,
      legacyLoading,
      trackingEnabled,
      timeRange,
      trackEvent,
      toggleTracking,
      changeTimeRange,
      refreshAnalytics,
      calculateLevel,
      getLevelProgress,
    ]
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default EnhancedAnalyticsProvider;
