import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";

function FlashcardDeckDetailsModal({
  deck,
  onClose,
  onStartPractice,
  userProgress = {},
  progressStats = null, // Add this prop for detailed stats
  isLoading = false,
}) {
  const [selectedMode, setSelectedMode] = useState("flashcards");

  // Calculate stats - use progressStats if available, otherwise calculate from userProgress
  const masteredCards = progressStats
    ? progressStats.masteredCards
    : deck.cardCount > 0
    ? Object.values(userProgress).filter((card) => card?.knowledgeLevel === 3)
        .length
    : 0;

  const familiarCards = progressStats
    ? progressStats.familiarCards
    : deck.cardCount > 0
    ? Object.values(userProgress).filter((card) => card?.knowledgeLevel === 2)
        .length
    : 0;

  const learningCards = progressStats
    ? progressStats.learningCards
    : deck.cardCount > 0
    ? Object.values(userProgress).filter((card) => card?.knowledgeLevel === 1)
        .length
    : 0;

  const completionPercentage = progressStats
    ? progressStats.completionPercentage
    : deck.cardCount > 0
    ? Math.round((masteredCards / deck.cardCount) * 100)
    : 0;

  const progressPercentage = progressStats
    ? progressStats.progressPercentage
    : deck.cardCount > 0
    ? Math.round(
        ((masteredCards + familiarCards + learningCards) / deck.cardCount) * 100
      )
    : 0;

  const handleStartPractice = () => {
    onStartPractice(selectedMode);
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-3 sm:p-4">
        <motion.div
          className="bg-white w-full max-w-2xl border-b-4 border-2 border-black shadow-xl overflow-hidden rounded-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ type: "spring", damping: 25 }}
        >
          {isLoading ? (
            <div className="p-8 flex flex-col items-center justify-center h-80">
              <BeatLoader color={"#848717"} loading={true} size={15} />
              <p className="mt-4 text-gray-600">Loading deck details...</p>
            </div>
          ) : (
            <>
              {/* Header with deck image or icon */}
              <div className="bg-green text-white p-4 sm:p-6">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl sm:text-2xl font-bold truncate pr-4">
                    {deck.name}
                  </h2>
                  <button
                    onClick={onClose}
                    className="text-white opacity-70 hover:opacity-100 text-xl flex-shrink-0"
                  >
                    ×
                  </button>
                </div>
                <p className="mt-2 opacity-90 text-sm sm:text-base">
                  {deck.cardCount} cards in this deck
                </p>
              </div>

              {/* Content */}
              <div className="p-4 sm:p-6 max-h-[70vh] overflow-y-auto">
                {/* Description */}
                <div className="mb-6">
                  <h3 className="font-bold text-base sm:text-lg mb-2">
                    Description
                  </h3>
                  <p className="text-gray-700 text-sm sm:text-base">
                    {deck.description ||
                      "Practice these flashcards to improve your vocabulary and language skills."}
                  </p>
                </div>

                {/* Stats */}
                <div className="mb-6">
                  <h3 className="font-bold text-base sm:text-lg mb-3">
                    Your Progress
                  </h3>
                  <div className="grid grid-cols-3 gap-2 sm:gap-4">
                    <div className="bg-white p-2 sm:p-3 rounded border-b-4 border-2 text-center">
                      <div className="text-xl sm:text-2xl text-green">
                        {masteredCards}
                      </div>
                      <div className="text-xs text-gray-600">Mastered</div>
                    </div>
                    <div className="bg-white p-2 sm:p-3 rounded border-b-4 border-2 text-center">
                      <div className="text-xl sm:text-2xl text-gold">
                        {familiarCards + learningCards}
                      </div>
                      <div className="text-xs text-gray">In Progress</div>
                    </div>
                    <div className="bg-white p-2 sm:p-3 rounded border-b-4 border-2 text-center">
                      <div className="text-xl sm:text-2xl">
                        {completionPercentage}%
                      </div>
                      <div className="text-xs text-gray-600">Complete</div>
                    </div>
                  </div>

                  {/* Progress bar */}
                  <div className="mt-4">
                    <div className="flex justify-between text-xs mb-1">
                      <span>{progressPercentage}% of cards studied</span>
                      <span>
                        {masteredCards + familiarCards + learningCards}/
                        {deck.cardCount} cards
                      </span>
                    </div>
                    <div className="w-full h-3 border-gray border bg-darkgray rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green"
                        style={{ width: `${progressPercentage}%` }}
                      ></div>
                    </div>
                  </div>

                  {/* Detailed breakdown */}
                  {(masteredCards > 0 ||
                    familiarCards > 0 ||
                    learningCards > 0) && (
                    <div className="mt-4 grid grid-cols-3 gap-2 text-center text-xs sm:text-sm">
                      <div>
                        <div className="font-medium mb-1">Learning</div>
                        <div className="bg-darkgray text-gray rounded-md py-1">
                          {learningCards}
                        </div>
                      </div>
                      <div>
                        <div className="font-medium mb-1">Familiar</div>
                        <div className="bg-gold bg-opacity-20 text-gold rounded-md py-1">
                          {familiarCards}
                        </div>
                      </div>
                      <div>
                        <div className="font-medium mb-1">Mastered</div>
                        <div className="bg-green bg-opacity-20 text-green rounded-md py-1">
                          {masteredCards}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Tags/Categories */}
                {deck.tags && deck.tags.length > 0 && (
                  <div className="mb-6">
                    <h3 className="font-bold text-base sm:text-lg mb-2">
                      Categories
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {deck.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-green bg-opacity-10 text-green rounded-full text-xs sm:text-sm"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {/* Study mode selection */}
                <div className="mb-6">
                  <h3 className="font-bold text-base sm:text-lg mb-3">
                    Study Mode
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                    <button
                      className={`p-3 sm:p-4 rounded border-b-4 border-2 flex flex-col items-center ${
                        selectedMode === "flashcards"
                          ? "border-green bg-green bg-opacity-10"
                          : "border-gray-200"
                      }`}
                      onClick={() => setSelectedMode("flashcards")}
                    >
                      <div className="text-xl sm:text-2xl mb-2">🔄</div>
                      <div className="font-medium">Flashcards</div>
                      <div className="text-xs text-gray-600 mt-1 text-center">
                        Flip cards to test your recall
                      </div>
                    </button>
                    <button
                      className={`p-3 sm:p-4 rounded border-b-4 border-2 flex flex-col items-center ${
                        selectedMode === "write"
                          ? "border-green bg-green bg-opacity-10"
                          : "border-gray-200"
                      }`}
                      onClick={() => setSelectedMode("write")}
                    >
                      <div className="text-xl sm:text-2xl mb-2">✏️</div>
                      <div className="font-medium">Write</div>
                      <div className="text-xs text-gray-600 mt-1 text-center">
                        Type answers to test your spelling
                      </div>
                    </button>
                  </div>
                </div>

                {/* Action buttons */}
                <div className="flex flex-col sm:flex-row gap-3 justify-end">
                  <button
                    onClick={onClose}
                    className="px-4 py-3 rounded border-b-4 border-2 hover:bg-opacity-90 order-2 sm:order-1"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleStartPractice}
                    className="px-4 py-3 bg-green text-black rounded border-black border-b-4 border-2 hover:bg-opacity-90 order-1 sm:order-2"
                  >
                    Start Practice
                  </button>
                </div>
              </div>
            </>
          )}
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

export default FlashcardDeckDetailsModal;
