// utils/bookings/timezoneUtils.js
import { fromZonedTime, toZonedTime } from "date-fns-tz";

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertToUTC = (date, timezone) => {
  if (!date) return null;
  return fromZonedTime(date, timezone);
};

export const convertFromUTC = (date, timezone) => {
  if (!date) return null;
  return toZonedTime(date, timezone);
};
