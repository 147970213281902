import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";
import BeatLoader from "react-spinners/BeatLoader";
import NavigationBar from "../navigation-bar-2/side-navigation-bar.component.jsx";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from "recharts";
import * as IoIcons from "react-icons/io5";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FloatingActionMenu from "../navigation-bar-2/floating-side-menu.jsx";
import FeedbackMenu from "../support/feedback-menu.jsx";
import AchievementsList from "../achievements/achievements-list";
import useChartStyles from "../../hooks/useChartStyles.jsx";

const AnalyticsDashboard = () => {
  const { user } = useAuth();
  const [activeSection, setActiveSection] = useState("overview");
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [useTestData, setUseTestData] = useState(false);

  // Use refs to prevent infinite loops
  const lastTimeRange = useRef(null);
  const hasManuallyRefreshed = useRef(false);

  // Use the enhanced analytics system
  const analytics = useSafeAnalytics();

  // Extract values to prevent repeated access which can cause re-renders
  const {
    userStats,
    activityData,
    isLoading,
    timeRange: contextTimeRange = "week",
    changeTimeRange,
    refreshAnalytics,
    totalXp,
    streak,
  } = analytics;

  const chartStyles = useChartStyles();
  // Create a local timeRange state that syncs with context but has a default
  const [timeRange, setTimeRange] = useState(contextTimeRange || "week");

  // Sync local timeRange with context when it changes
  useEffect(() => {
    if (contextTimeRange && contextTimeRange !== timeRange) {
      console.log(`Syncing local timeRange with context: ${contextTimeRange}`);
      setTimeRange(contextTimeRange);
    }
  }, [contextTimeRange, timeRange]);

  // Force a timeRange value if it's undefined
  useEffect(() => {
    if (!contextTimeRange && changeTimeRange) {
      console.log(
        "Setting default timeRange to 'week' because it was undefined"
      );
      changeTimeRange("week");
    }
  }, [contextTimeRange, changeTimeRange]);

  // Force data refresh on mount with the correct timeRange
  useEffect(() => {
    console.log(`Initial load with timeRange: ${timeRange}`);
    const timer = setTimeout(() => {
      refreshAnalytics(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // Add this at the top of your component function, right after your existing state declarations
  // Makes timeRange changes and refreshes visible in console
  useEffect(() => {
    console.log("Analytics Dashboard - Current state:", {
      timeRange: analytics.timeRange,
      hasActivityData:
        Array.isArray(analytics.activityData) &&
        analytics.activityData.length > 0,
      activityDataLength: Array.isArray(analytics.activityData)
        ? analytics.activityData.length
        : "not an array",
      isLoading: analytics.isLoading,
    });
  }, [analytics.timeRange, analytics.activityData, analytics.isLoading]);

  // Add this to force an initial data load when the component mounts
  useEffect(() => {
    // Force one data refresh on initial mount
    const timer = setTimeout(() => {
      console.log("Analytics Dashboard - Initial data refresh triggered");
      refreshAnalytics(true);
    }, 500); // Small delay to avoid React 18 StrictMode double-mount issues

    return () => clearTimeout(timer);
  }, []); // Empty dependency array = only run once on mount

  // Log analytics data - but don't trigger refresh to avoid loops
  useEffect(() => {
    console.log("Analytics data loaded:", {
      timeRange,
      hasActivityData: Array.isArray(activityData) && activityData.length > 0,
      activityDataLength: Array.isArray(activityData)
        ? activityData.length
        : "not an array",
      totalXp,
      streak,
    });
  }, [activityData, timeRange, totalXp, streak]);

  useEffect(() => {
    refreshAnalytics(true);
  }, []);

  // Custom time range change handler that uses local state first
  const handleTimeRangeChange = (range) => {
    console.log(`Time range changing to: ${range}`);

    // Update local state immediately
    setTimeRange(range);

    // Then update context
    if (changeTimeRange) {
      changeTimeRange(range);
    }

    // Force refresh after a short delay
    setTimeout(() => {
      refreshAnalytics(true);
    }, 100);
  };

  // Sample data generator for when real data isn't available
  const getSampleData = (type) => {
    switch (type) {
      case "xp":
        return [
          { date: "03/15", xp: 120 },
          { date: "03/16", xp: 150 },
          { date: "03/17", xp: 200 },
          { date: "03/18", xp: 180 },
          { date: "03/19", xp: 220 },
        ];
      case "lesson":
        return [
          { date: "03/15", completed: 2, started: 1 },
          { date: "03/16", completed: 3, started: 0 },
          { date: "03/17", completed: 1, started: 2 },
          { date: "03/18", completed: 4, started: 1 },
        ];
      case "vocabulary":
        return [
          { date: "03/15", words: 15, searches: 20 },
          { date: "03/16", words: 12, searches: 18 },
          { date: "03/17", words: 20, searches: 25 },
        ];
      case "accuracy":
        return [
          { date: "03/15", accuracy: 85 },
          { date: "03/16", accuracy: 90 },
          { date: "03/17", accuracy: 78 },
        ];
      default:
        return [];
    }
  };

  // Generate and download PDF report
  const generatePDFReport = async () => {
    setIsGeneratingReport(true);
    try {
      const reportElement = document.getElementById("analytics-report");
      const canvas = await html2canvas(reportElement, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(
        `language-learning-report-${new Date().toISOString().split("T")[0]}.pdf`
      );
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGeneratingReport(false);
    }
  };

  // Share report image to social media
  const shareToSocialMedia = async (platform) => {
    try {
      const reportElement = document.getElementById("analytics-report");
      const canvas = await html2canvas(reportElement, {
        scale: 2,
        useCORS: true,
        backgroundColor: "#ffffff",
      });

      const imgData = canvas.toDataURL("image/png");
      const blob = await fetch(imgData).then((res) => res.blob());

      if (navigator.share) {
        await navigator.share({
          title: "My Language Learning Progress",
          text: "Check out my progress in learning African languages!",
          files: [
            new File([blob], "language-progress.png", { type: "image/png" }),
          ],
        });
        return;
      }

      const shareUrl = {
        twitter: `https://twitter.com/intent/tweet?text=Check out my language learning progress!&hashtags=language,learning`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          window.location.href
        )}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          window.location.href
        )}`,
      };

      if (shareUrl[platform]) {
        window.open(shareUrl[platform], "_blank");
      }
    } catch (error) {
      console.error("Error sharing to social media:", error);
    }
  };

  // Handle manual refresh - using ref to prevent loop
  const handleManualRefresh = () => {
    console.log("Manual refresh triggered");
    hasManuallyRefreshed.current = true;
    refreshAnalytics(true);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <BeatLoader color="#848717" loading={isLoading} size={15} />
      </div>
    );
  }

  // Format analytics data for charts
  const formatXpData = () => {
    if (
      !activityData ||
      !Array.isArray(activityData) ||
      activityData.length === 0
    ) {
      return useTestData ? getSampleData("xp") : [];
    }

    try {
      const formattedData = activityData.map((day) => {
        if (!day) return { date: "Unknown", xp: 0 };

        const dateStr = day.date
          ? day.date.split("-").slice(1).join("/")
          : "Unknown";

        return {
          date: dateStr,
          xp: day.xp_earned || 0,
        };
      });

      return formattedData;
    } catch (error) {
      console.error("Error in formatXpData:", error);
      return useTestData ? getSampleData("xp") : [];
    }
  };

  const formatLessonData = () => {
    if (
      !activityData ||
      !Array.isArray(activityData) ||
      activityData.length === 0
    ) {
      return useTestData ? getSampleData("lesson") : [];
    }

    try {
      const formattedData = activityData.map((day) => {
        if (!day) return { date: "Unknown", completed: 0, started: 0 };

        const dateStr = day.date
          ? day.date.split("-").slice(1).join("/")
          : "Unknown";

        const completed = day.lessons_completed || 0;
        const started = (day.lessons_started || 0) - completed;

        return {
          date: dateStr,
          completed: completed,
          started: started >= 0 ? started : 0, // Ensure no negative values
        };
      });

      return formattedData;
    } catch (error) {
      console.error("Error in formatLessonData:", error);
      return useTestData ? getSampleData("lesson") : [];
    }
  };

  const formatVocabularyData = () => {
    if (
      !activityData ||
      !Array.isArray(activityData) ||
      activityData.length === 0
    ) {
      return useTestData ? getSampleData("vocabulary") : [];
    }

    try {
      const formattedData = activityData.map((day) => {
        if (!day) return { date: "Unknown", words: 0, searches: 0 };

        const dateStr = day.date
          ? day.date.split("-").slice(1).join("/")
          : "Unknown";

        return {
          date: dateStr,
          words: day.vocabulary_learned || 0,
          searches: day.words_searched || 0,
        };
      });

      return formattedData;
    } catch (error) {
      console.error("Error in formatVocabularyData:", error);
      return useTestData ? getSampleData("vocabulary") : [];
    }
  };

  const formatAccuracyData = () => {
    if (
      !activityData ||
      !Array.isArray(activityData) ||
      activityData.length === 0
    ) {
      return useTestData ? getSampleData("accuracy") : [];
    }

    try {
      const formattedData = activityData.map((day) => {
        if (!day) return { date: "Unknown", accuracy: 0 };

        const dateStr = day.date
          ? day.date.split("-").slice(1).join("/")
          : "Unknown";

        const correct = day.correct_answers || 0;
        const incorrect = day.incorrect_answers || 0;
        const total = correct + incorrect;

        return {
          date: dateStr,
          accuracy: total > 0 ? Math.round((correct / total) * 100) : 0,
        };
      });

      return formattedData;
    } catch (error) {
      console.error("Error in formatAccuracyData:", error);
      return useTestData ? getSampleData("accuracy") : [];
    }
  };

  // Calculate stats for radar chart with normalized data (0-100 scale)
  const calculateSkillRadar = () => {
    if (!userStats) return [];

    try {
      const totalQuestions =
        (userStats.correct_answers_count || 0) +
        (userStats.incorrect_answers_count || 0);

      const accuracy =
        totalQuestions > 0
          ? Math.round(
              ((userStats.correct_answers_count || 0) / totalQuestions) * 100
            )
          : 0;

      return [
        {
          subject: "Vocabulary",
          A: Math.min(
            100,
            ((userStats.vocabulary_learned_count || 0) / 100) * 100
          ),
          fullMark: 100,
        },
        {
          subject: "Lessons",
          A: Math.min(
            100,
            ((userStats.lessons_completed_count || 0) / 10) * 100
          ),
          fullMark: 100,
        },
        {
          subject: "XP",
          A: Math.min(100, ((totalXp || 0) / 1000) * 100),
          fullMark: 100,
        },
        {
          subject: "Practice",
          A: Math.min(
            100,
            ((userStats.practice_questions_answered || 0) / 100) * 100
          ),
          fullMark: 100,
        },
        {
          subject: "Streak",
          A: Math.min(100, ((streak || 0) / 7) * 100),
          fullMark: 100,
        },
        {
          subject: "Accuracy",
          A: accuracy,
          fullMark: 100,
        },
      ];
    } catch (error) {
      console.error("Error in calculateSkillRadar:", error);
      return [];
    }
  };

  // Generate pie chart data with safety check
  const generatePracticeVsMistakeData = () => {
    if (!userStats) return [];

    try {
      const correct = userStats.correct_answers_count || 0;
      const incorrect = userStats.incorrect_answers_count || 0;

      const total = correct + incorrect;

      if (total === 0) return [];

      // Calculating percentages correctly
      const correctPercent = Math.round((correct / total) * 100);
      const incorrectPercent = 100 - correctPercent; // This ensures they sum to 100%

      return [
        {
          name: "Correct",
          value: correct,
          percent: correctPercent,
          fill: "#848717",
        },
        {
          name: "Incorrect",
          value: incorrect,
          percent: incorrectPercent,
          fill: "#991616",
        },
      ];
    } catch (error) {
      console.error("Error in generatePracticeVsMistakeData:", error);
      return [];
    }
  };

  return (
    <motion.div
      className="bg-white min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className="w-full">
        <NavigationBar />
        <FloatingActionMenu
          showFlashcards={true}
          showPractice={true}
          showStats={true}
          additionalButtons={[]}
        />
        <FeedbackMenu />
      </div>

      {/* Mobile responsive container - full width on mobile, constrained on larger screens */}
      <div className="w-full px-4 md:w-11/12 lg:w-5/6 mx-auto py-6 md:py-8">
        {/* Header section with responsive layout */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 md:mb-8">
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl font-bold text-black">
              Learning Analytics
            </h1>
            <p className="text-gray text-sm md:text-base">
              Track your language learning journey
            </p>
          </div>

          {/* Time range filters - stack on mobile, row on larger screens */}
          <div className="flex flex-wrap gap-2 self-stretch sm:self-auto">
            <button
              onClick={() => handleTimeRangeChange("week")}
              className={`px-3 py-1.5 md:px-4 md:py-2 rounded text-sm md:text-base border ${
                timeRange === "week"
                  ? "bg-green text-white border-black border-2 border-b-4"
                  : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
              }`}
            >
              Week
            </button>
            <button
              onClick={() => handleTimeRangeChange("month")}
              className={`px-3 py-1.5 md:px-4 md:py-2 rounded text-sm md:text-base border ${
                timeRange === "month"
                  ? "bg-green text-white border-black border-2 border-b-4"
                  : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
              }`}
            >
              Month
            </button>
            <button
              onClick={() => handleTimeRangeChange("year")}
              className={`px-3 py-1.5 md:px-4 md:py-2 rounded text-sm md:text-base border ${
                timeRange === "year"
                  ? "bg-green text-white border-black border-2 border-b-4"
                  : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
              }`}
            >
              Year
            </button>
          </div>
        </div>

        {/* Section tabs - horizontally scrollable on mobile */}
        <div className="flex overflow-x-auto pb-2 mb-4 md:mb-6 gap-2 md:flex-wrap">
          <button
            onClick={() => setActiveSection("overview")}
            className={`px-3 py-1.5 md:px-4 md:py-2 rounded whitespace-nowrap text-sm md:text-base border ${
              activeSection === "overview"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveSection("progress")}
            className={`px-3 py-1.5 md:px-4 md:py-2 rounded whitespace-nowrap text-sm md:text-base border ${
              activeSection === "progress"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            Progress
          </button>
          <button
            onClick={() => setActiveSection("vocabulary")}
            className={`px-3 py-1.5 md:px-4 md:py-2 rounded whitespace-nowrap text-sm md:text-base border ${
              activeSection === "vocabulary"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            Vocabulary
          </button>
          <button
            onClick={() => setActiveSection("achievements")}
            className={`px-3 py-1.5 md:px-4 md:py-2 rounded whitespace-nowrap text-sm md:text-base border ${
              activeSection === "achievements"
                ? "bg-green text-white border-black border-2 border-b-4"
                : "text-black hover:bg-green hover:text-white border-black bg-white border-2 border-b-4"
            }`}
          >
            Achievements
          </button>
        </div>

        {/* Action buttons - stack on mobile, align right on larger screens */}
        <div className="flex flex-col sm:flex-row sm:justify-end mb-4 gap-2">
          <button
            onClick={handleManualRefresh}
            className="flex items-center justify-center gap-2 px-4 py-2 bg-green border-black border-2 border-b-4 text-white rounded hover:bg-opacity-90 text-sm md:text-base"
          >
            <span>↻</span>
            <span>Refresh Data</span>
          </button>
          <button
            onClick={generatePDFReport}
            disabled={isGeneratingReport}
            className="flex items-center justify-center gap-2 px-4 py-2 bg-red border-black border-2 border-b-4 text-white rounded hover:bg-red-600 disabled:opacity-50 text-sm md:text-base"
          >
            {isGeneratingReport ? (
              <BeatLoader color="#ffffff" size={8} />
            ) : (
              <>
                <IoIcons.IoDocumentText />
                <span className="whitespace-nowrap">Download Report</span>
              </>
            )}
          </button>
        </div>

        {/* Analytics content - this div will be captured for reports */}
        <div
          id="analytics-report"
          className="bg-white p-4 md:p-6 border-2 border-b-4 border-gray shadow-lg"
        >
          {/* Stats Overview - grid that adapts from 2 columns on mobile to 4 on desktop */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 mb-6 md:mb-8">
            <div className="bg-white p-3 md:p-4 rounded-lg border-2 border-b-4 border-darkgray shadow hover:shadow-lg transition-shadow duration-300 flex flex-col items-center transform hover:scale-105 transition-transform">
              <div className="text-2xl md:text-4xl text-green font-semibold mb-1 md:mb-2">
                {totalXp || 0}
              </div>
              <div className="text-gray text-xs md:text-sm text-center font-body">
                Total XP
              </div>
              <div className="w-full h-1 bg-darkgray mt-1 md:mt-2 rounded-full overflow-hidden">
                <div
                  className="h-full bg-green rounded-full"
                  style={{
                    width: `${Math.min(100, ((totalXp || 0) % 1000) / 10)}%`,
                  }}
                ></div>
              </div>
              <div className="text-xs text-gray mt-1">
                Level {Math.floor((totalXp || 0) / 1000) + 1}
              </div>
            </div>

            <div className="bg-white p-3 md:p-4 rounded-lg border-2 border-b-4 border-darkgray shadow hover:shadow-lg transition-shadow duration-300 flex flex-col items-center transform hover:scale-105 transition-transform">
              <div className="text-2xl md:text-4xl text-green font-semibold mb-1 md:mb-2">
                {streak || 0}
              </div>
              <div className="text-gray text-xs md:text-sm text-center font-body">
                Day Streak
              </div>
              <div className="flex mt-1 md:mt-2">
                {[...Array(Math.min(7, streak || 0))].map((_, i) => (
                  <div key={i} className="text-gold text-xs">
                    🔥
                  </div>
                ))}
                {[...Array(Math.max(0, 7 - (streak || 0)))].map((_, i) => (
                  <div key={i} className="text-darkgray text-xs">
                    ○
                  </div>
                ))}
              </div>
              <div className="text-xs text-gray mt-1">
                {streak || 0} days in a row
              </div>
            </div>

            <div className="bg-white p-3 md:p-4 rounded-lg border-2 border-b-4 border-darkgray shadow hover:shadow-lg transition-shadow duration-300 flex flex-col items-center transform hover:scale-105 transition-transform">
              <div className="text-2xl md:text-4xl text-green font-semibold mb-1 md:mb-2">
                {userStats?.lessons_completed_count || 0}
              </div>
              <div className="text-gray text-xs md:text-sm text-center font-body">
                Lessons Completed
              </div>
              <div className="flex justify-center mt-1 md:mt-2">
                <span className="text-gold">📚</span>
              </div>
              <div className="text-xs text-gray mt-1">
                {userStats?.lessons_completed_count || 0} total lessons
              </div>
            </div>

            <div className="bg-white p-3 md:p-4 rounded-lg border-2 border-b-4 border-darkgray shadow hover:shadow-lg transition-shadow duration-300 flex flex-col items-center transform hover:scale-105 transition-transform">
              <div className="text-2xl md:text-4xl text-green font-semibold mb-1 md:mb-2">
                {userStats?.vocabulary_learned_count || 0}
              </div>
              <div className="text-gray text-xs md:text-sm text-center font-body">
                Vocabulary Words
              </div>
              <div className="w-full h-1 bg-darkgray mt-1 md:mt-2 rounded-full overflow-hidden">
                <div
                  className="h-full bg-green rounded-full"
                  style={{
                    width: `${Math.min(
                      100,
                      ((userStats?.vocabulary_learned_count || 0) / 100) * 100
                    )}%`,
                  }}
                ></div>
              </div>
              <div className="text-xs text-gray mt-1">
                {Math.min(
                  100,
                  Math.floor(
                    ((userStats?.vocabulary_learned_count || 0) / 100) * 100
                  )
                )}
                % to goal
              </div>
            </div>
          </div>

          {/* Charts Section - stacked on mobile, 2 columns on large screens */}
          {activeSection === "overview" && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6">
              {/* XP Chart */}
              <div className={`${chartStyles.chartContainer} mb-6 lg:mb-0`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  XP Earned Over Time
                </h3>
                {(!activityData || !activityData.length) && !useTestData && (
                  <div
                    className={`${chartStyles.emptyState} text-sm md:text-base`}
                  >
                    No activity data available for this time period.
                    <button
                      onClick={() => setUseTestData(true)}
                      className="ml-2 text-red underline hover:text-redtwo"
                    >
                      Use sample data?
                    </button>
                  </div>
                )}
                <div className="h-52 md:h-64 lg:h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={formatXpData()}>
                      <CartesianGrid
                        strokeDasharray={chartStyles.lineChart.gridStyle}
                        stroke="#DADADA"
                      />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                      />
                      <YAxis
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: "#FCFCEE",
                          border: "1px solid #848717",
                          borderRadius: "4px",
                          boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                        }}
                        itemStyle={{ color: "#000000" }}
                        labelStyle={{ fontWeight: "bold", color: "#000000" }}
                      />
                      <Legend
                        wrapperStyle={{
                          paddingTop: "10px",
                          fontFamily: "Nunito",
                          fontSize: "12px",
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="xp"
                        name="XP Points"
                        stroke={chartStyles.colors.primary.main}
                        strokeWidth={chartStyles.lineChart.strokeWidth}
                        activeDot={{
                          r: chartStyles.lineChart.activeDotRadius,
                          fill: chartStyles.colors.primary.accent,
                        }}
                        dot={{ fill: chartStyles.colors.primary.main, r: 3 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Skill Radar Chart */}
              <div className={`${chartStyles.chartContainer} mb-6 lg:mb-0`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  Skills Overview
                </h3>
                <div className="h-52 md:h-64 lg:h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <RadarChart outerRadius="70%" data={calculateSkillRadar()}>
                      <PolarGrid stroke="#DADADA" />
                      <PolarAngleAxis
                        dataKey="subject"
                        tick={{
                          fill: "#000000",
                          fontSize: "10px",
                          fontFamily: "Nunito",
                        }}
                      />
                      <PolarRadiusAxis
                        angle={30}
                        domain={[0, 100]}
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#DADADA" }}
                        tickCount={chartStyles.radarChart.polarGridLines}
                      />
                      <Radar
                        name="Your Skills"
                        dataKey="A"
                        stroke={chartStyles.colors.primary.main}
                        fill={chartStyles.colors.primary.main}
                        fillOpacity={chartStyles.radarChart.fillOpacity}
                      />
                      <Tooltip
                        formatter={(value) => [`${value}%`, "Progress"]}
                        contentStyle={{
                          backgroundColor: "#FCFCEE",
                          border: "1px solid #848717",
                          borderRadius: "4px",
                          boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                        }}
                        itemStyle={{ color: "#000000" }}
                        labelStyle={{ fontWeight: "bold", color: "#000000" }}
                      />
                    </RadarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Practice Accuracy Chart */}
              <div className={`${chartStyles.chartContainer} mb-6 lg:mb-0`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  Answer Accuracy
                </h3>
                {(!activityData || !activityData.length) && !useTestData && (
                  <div
                    className={`${chartStyles.emptyState} text-sm md:text-base`}
                  >
                    No activity data available for this time period.
                  </div>
                )}
                <div className="h-52 md:h-64 lg:h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={formatAccuracyData()}>
                      <CartesianGrid
                        strokeDasharray={chartStyles.lineChart.gridStyle}
                        stroke="#DADADA"
                      />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                      />
                      <YAxis
                        domain={[0, 100]}
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                        tickFormatter={(value) => `${value}%`}
                      />
                      <Tooltip
                        formatter={(value) => [`${value}%`, "Accuracy"]}
                        contentStyle={{
                          backgroundColor: "#FCFCEE",
                          border: "1px solid #991616",
                          borderRadius: "4px",
                          boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                        }}
                        itemStyle={{ color: "#000000" }}
                        labelStyle={{ fontWeight: "bold", color: "#000000" }}
                      />
                      <Legend
                        wrapperStyle={{
                          paddingTop: "10px",
                          fontFamily: "Nunito",
                          fontSize: "12px",
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="accuracy"
                        name="Correct Answer %"
                        stroke={chartStyles.colors.secondary.main}
                        strokeWidth={chartStyles.lineChart.strokeWidth}
                        activeDot={{
                          r: chartStyles.lineChart.activeDotRadius,
                          fill: chartStyles.colors.secondary.accent,
                        }}
                        dot={{ fill: chartStyles.colors.secondary.main, r: 3 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Practice vs Mistakes Pie Chart */}
              <div className={`${chartStyles.chartContainer} mb-6 lg:mb-0`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  Practice vs Mistakes
                </h3>
                <div className="h-52 md:h-64 lg:h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={generatePracticeVsMistakeData()}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        outerRadius={80}
                        paddingAngle={chartStyles.pieChart.paddingAngle}
                        dataKey="value"
                        label={({ name, percent }) => `${name}: ${percent}%`}
                      >
                        {generatePracticeVsMistakeData().map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.fill}
                            stroke="#FCFCEE"
                            strokeWidth={1}
                          />
                        ))}
                      </Pie>
                      <Tooltip
                        formatter={(value, name, props) => [value, name]}
                        contentStyle={{
                          backgroundColor: "#FCFCEE",
                          border: "1px solid #808080",
                          borderRadius: "4px",
                          boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                        }}
                        itemStyle={{ color: "#000000" }}
                        labelStyle={{ fontWeight: "bold", color: "#000000" }}
                      />
                      <Legend
                        verticalAlign="bottom"
                        align="center"
                        layout="horizontal"
                        iconSize={15}
                        iconType="circle"
                        wrapperStyle={{
                          paddingTop: "15px",
                          fontFamily: "Nunito",
                          fontSize: "12px",
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}

          {activeSection === "progress" && (
            <div className="grid grid-cols-1 gap-6">
              {/* Lessons Progress Bar Chart */}
              <div className={`${chartStyles.chartContainer} mb-6`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  Lessons Progress
                </h3>
                {(!activityData || !activityData.length) && !useTestData && (
                  <div
                    className={`${chartStyles.emptyState} text-sm md:text-base`}
                  >
                    No activity data available for this time period.
                  </div>
                )}
                <div className="h-60 md:h-72 lg:h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={formatLessonData()}
                      barSize={chartStyles.barChart.barSize}
                      barGap={2}
                    >
                      <CartesianGrid
                        strokeDasharray={chartStyles.lineChart.gridStyle}
                        stroke="#DADADA"
                        vertical={false}
                      />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                      />
                      <YAxis
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: "#FCFCEE",
                          border: "1px solid #848717",
                          borderRadius: "4px",
                          boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                        }}
                        itemStyle={{ color: "#000000" }}
                        labelStyle={{ fontWeight: "bold", color: "#000000" }}
                      />
                      <Legend
                        wrapperStyle={{
                          paddingTop: "10px",
                          fontFamily: "Nunito",
                          fontSize: "12px",
                        }}
                        iconSize={15}
                        iconType="circle"
                      />
                      <Bar
                        dataKey="completed"
                        stackId="a"
                        fill={chartStyles.colors.primary.main}
                        name="Completed Lessons"
                        radius={[4, 4, 0, 0]}
                      />
                      <Bar
                        dataKey="started"
                        stackId="a"
                        fill={chartStyles.colors.primary.secondary}
                        name="Started but not completed"
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Daily XP Progress Bar Chart */}
              <div className="bg-white p-4 rounded border-2 border-b-4 border-darkgray shadow mb-6">
                <h3 className="text-base md:text-lg font-semibold mb-4">
                  Daily XP Progress
                </h3>
                <div className="h-60 md:h-72 lg:h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={formatXpData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tick={{ fontSize: "10px" }} />
                      <YAxis tick={{ fontSize: "10px" }} />
                      <Tooltip
                        formatter={(value) => [`${value} XP`, "Earned"]}
                        labelFormatter={(label) => `Date: ${label}`}
                      />
                      <Legend wrapperStyle={{ fontSize: "12px" }} />
                      <Bar dataKey="xp" fill="#848717" name="XP Earned" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}

          {activeSection === "vocabulary" && (
            <div className="grid grid-cols-1 gap-6">
              {/* Vocabulary Growth Chart */}
              <div className={`${chartStyles.chartContainer} mb-6`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  Vocabulary Growth
                </h3>
                {(!activityData || !activityData.length) && !useTestData && (
                  <div
                    className={`${chartStyles.emptyState} text-sm md:text-base`}
                  >
                    No activity data available for this time period.
                  </div>
                )}
                <div className="h-60 md:h-72 lg:h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={formatVocabularyData()}>
                      <CartesianGrid
                        strokeDasharray={chartStyles.lineChart.gridStyle}
                        stroke="#DADADA"
                      />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                      />
                      <YAxis
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                        yAxisId="left"
                      />
                      <YAxis
                        orientation="right"
                        tick={{ fill: "#000000", fontSize: "10px" }}
                        axisLine={{ stroke: "#808080" }}
                        tickLine={{ stroke: "#808080" }}
                        yAxisId="right"
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: "#FCFCEE",
                          border: "1px solid #848717",
                          borderRadius: "4px",
                          boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                        }}
                        itemStyle={{ color: "#000000" }}
                        labelStyle={{ fontWeight: "bold", color: "#000000" }}
                      />
                      <Legend
                        wrapperStyle={{
                          paddingTop: "10px",
                          fontFamily: "Nunito",
                          fontSize: "12px",
                        }}
                      />
                      <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="words"
                        name="New Words Learned"
                        stroke={chartStyles.colors.primary.main}
                        strokeWidth={chartStyles.lineChart.strokeWidth}
                        activeDot={{
                          r: chartStyles.lineChart.activeDotRadius,
                          fill: chartStyles.colors.primary.accent,
                        }}
                        dot={{ fill: chartStyles.colors.primary.main, r: 3 }}
                      />
                      <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="searches"
                        name="Dictionary Searches"
                        stroke={chartStyles.colors.secondary.secondary}
                        strokeWidth={chartStyles.lineChart.strokeWidth - 0.5}
                        activeDot={{
                          r: chartStyles.lineChart.activeDotRadius - 1,
                          fill: chartStyles.colors.secondary.accent,
                        }}
                        dot={{
                          fill: chartStyles.colors.secondary.secondary,
                          r: 2,
                        }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Recently Learned Words - responsive grid */}
              <div className={`${chartStyles.chartContainer} mb-6`}>
                <h3
                  className={`${chartStyles.chartTitle} text-base md:text-lg`}
                >
                  Recently Learned Words
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4">
                  {userStats?.vocabulary_words
                    .slice(0, 12)
                    .map((wordObj, index) => {
                      const word =
                        typeof wordObj === "object" && wordObj !== null
                          ? wordObj.word || ""
                          : String(wordObj || "");
                      const translation =
                        typeof wordObj === "object" && wordObj !== null
                          ? wordObj.translation || ""
                          : "";

                      return (
                        <div
                          key={index}
                          className="p-2 md:p-3 bg-white rounded border-2 border-darkgray hover:border-green transition-colors duration-200 transform hover:scale-105 transition-transform"
                        >
                          <div className="font-semibold text-base md:text-lg text-black">
                            {word}
                          </div>
                          {translation && (
                            <div className="text-xs md:text-sm text-gray mt-1 italic">
                              {translation}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

          {activeSection === "achievements" && (
            <div>
              {/* Use the proper achievements component */}
              <AchievementsList analyticsData={userStats} />
            </div>
          )}

          {/* Watermark for PDF report */}
          <div className="mt-8 text-center text-xs md:text-sm text-gray-400">
            Fast Fluency Africa - Language Learning Report - Generated{" "}
            {new Date().toLocaleDateString()}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AnalyticsDashboard;
