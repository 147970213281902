// components/support/CategoryCard.jsx
import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ category }) => {
  return (
    <Link
      to={`/support/category/${category.slug}`}
      className="block p-6 border-2 border-b-4 border-darkgray rounded hover:border-green transition-colors"
    >
      <div className="flex items-center mb-3">
        <span className="text-2xl mr-3">{category.icon}</span>
        <h3 className="text-lg font-bold">{category.title}</h3>
      </div>
      <p className="text-sm text-gray-600">{category.description}</p>
      <div className="mt-4 text-red text-sm font-medium">
        View articles ({category.articles.length}) →
      </div>
    </Link>
  );
};

export default CategoryCard;
