// components/modals/StreaksModalContent.jsx - Mobile Responsive Version
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { useShopWithPowerUps } from "../../hooks/useShopWithPowerUps";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";
// Import the separate DayDetailModal component
import DayDetailModal from "./streak-day-detail-modal";

const StreaksModalContent = ({ onClose }) => {
  const { user } = useAuth();
  const { powerUpStatus, refreshPowerUpStatus } = useShopWithPowerUps();

  // Replace direct Firestore access with the analytics context
  const {
    userStats,
    activityData: contextActivityData,
    isLoading,
    refreshAnalytics,
    streak,
  } = useSafeAnalytics();

  // State variables
  const [view, setView] = useState("week"); // week, month, year
  const [activityData, setActivityData] = useState([]);
  const [streakSavers, setStreakSavers] = useState(0);
  const [streakFreezeActive, setStreakFreezeActive] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [showDayDetail, setShowDayDetail] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  // Process activity data when context data or view changes
  useEffect(() => {
    const processActivityData = () => {
      if (!contextActivityData || contextActivityData.length === 0) {
        setActivityData([]);
        return;
      }

      console.log(`Processing activity data for ${view} view`);
      setLocalLoading(true);

      try {
        // Calculate how many days to include based on view
        let daysToInclude = 7;
        if (view === "month") daysToInclude = 31;
        if (view === "year") daysToInclude = 90;

        // Calculate start date
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - daysToInclude);

        // Filter activity data for the current view period
        const filteredData = contextActivityData.filter((day) => {
          const activityDate = new Date(day.date);
          return activityDate >= startDate && activityDate <= endDate;
        });

        setActivityData(filteredData);
        console.log(`Found ${filteredData.length} days for the current view`);
      } catch (error) {
        console.error("Error processing activity data:", error);
      } finally {
        setLocalLoading(false);
      }
    };

    processActivityData();
  }, [view, contextActivityData]);

  // Setup streak savers and freeze status from power-ups
  useEffect(() => {
    if (powerUpStatus) {
      // Check for streak savers
      setStreakSavers(powerUpStatus.streakProtection?.remaining || 0);

      // Check for active streak freeze
      setStreakFreezeActive(!!powerUpStatus.streakProtection?.active);
    }

    // Also refresh power-up status
    if (refreshPowerUpStatus) {
      refreshPowerUpStatus();
    }
  }, [powerUpStatus, refreshPowerUpStatus]);

  // Helper function to get streak activities for a date
  const getStreakActivitiesForDay = (dateStr) => {
    if (!userStats || !userStats.streak_activities) {
      return [];
    }

    // Direct access to streak_activities object
    return userStats.streak_activities[dateStr] || [];
  };

  const handleChangeView = (newView) => {
    setView(newView);
  };

  // Function to generate days for calendar view
  const generateCalendarDays = () => {
    const today = new Date();
    const days = [];
    let daysToShow = 7;
    let startDate = new Date(today);

    if (view === "month") {
      daysToShow = 30;
      // Start from beginning of month for "month" view
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      // Adjust daysToShow to show the full month
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).getDate();
      daysToShow = lastDayOfMonth;
    } else if (view === "year") {
      daysToShow = 90;
      // Start from 3 months ago for "year" view (showing 3 months)
      startDate = new Date(today);
      startDate.setMonth(startDate.getMonth() - 2);
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    } else {
      // Week view - show current week starting from Sunday
      const dayOfWeek = today.getDay(); // 0 = Sunday, 6 = Saturday
      startDate.setDate(today.getDate() - dayOfWeek);
    }

    // Generate all days in the range
    for (let i = 0; i < daysToShow; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      // Format date as YYYY-MM-DD for comparison
      const formattedDate = date.toISOString().split("T")[0];

      // Check if this is a future date
      const isFutureDate = date > today;

      // Check if we have activity data for this day (only for past/present)
      const dayData = !isFutureDate
        ? activityData.find((day) => {
            const activityDate =
              typeof day.date === "string"
                ? day.date.split("T")[0]
                : day.date instanceof Date
                ? day.date.toISOString().split("T")[0]
                : null;

            return activityDate === formattedDate;
          })
        : null;

      // Get streak activity types for this day (if any)
      const streakActivities = !isFutureDate
        ? getStreakActivitiesForDay(formattedDate)
        : [];

      // Calculate XP intensity for color gradient (0-100%)
      const xpValue = dayData?.xp_earned || 0;
      const maxXP = 100; // Adjust based on your app's typical daily XP
      const xpIntensity = Math.min(100, Math.round((xpValue / maxXP) * 100));

      // Fixed opacity calculation for Tailwind
      const opacityClass =
        xpIntensity > 75
          ? "bg-opacity-70"
          : xpIntensity > 50
          ? "bg-opacity-50"
          : xpIntensity > 25
          ? "bg-opacity-30"
          : "bg-opacity-10";

      days.push({
        date,
        formattedDate,
        isFutureDate,
        hasActivity: !!dayData,
        hasStreakActivity: streakActivities.length > 0,
        streakActivities,
        xp: xpValue,
        xpIntensity,
        opacityClass,
        activityDetails: dayData || null,
      });
    }

    return days;
  };

  // Function to render a calendar day
  const renderCalendarDay = (day) => {
    if (!day) return <div className="aspect-square"></div>;

    // Activity type icons
    const activityIcons = {
      lesson: "📚",
      practice: "✏️",
      flashcard: "🔄",
      coaching: "👨‍🏫",
    };

    // Get activity icons if any
    const icons = day.streakActivities
      .map((act) => activityIcons[act] || "")
      .join(" ");

    // Determine the CSS class based on activity
    let dayClass =
      "aspect-square flex flex-col items-center justify-center rounded-md text-xs";

    if (day.isFutureDate) {
      // Future date styling - use gray (darker) instead of darkgray (lighter)
      dayClass += " border-gray border-2 border-b-4 text-gray opacity-50";
    } else if (day.hasStreakActivity) {
      // Day with streak-qualifying activity - use pre-computed opacity class
      dayClass += ` border-green border-2 border-b-4 text-black bg-green ${day.opacityClass}`;
    } else if (day.hasActivity) {
      // Day with some activity but not streak-qualifying
      dayClass += " border-darkgray border-2 border-b-4 text-gray";
    } else {
      // No activity
      dayClass += " border-darkgray border-1 text-gray opacity-50";
    }

    // Create tooltip content with detailed stats
    const xpInfo = day.xp > 0 ? `${day.xp} XP` : "No XP";
    const tooltipContent = day.isFutureDate
      ? `${day.date.toLocaleDateString()}: Future date`
      : `${day.date.toLocaleDateString()}: ${xpInfo}${
          icons ? ` | ${icons}` : ""
        }`;

    return (
      <div
        className={dayClass}
        title={tooltipContent}
        onClick={() => !day.isFutureDate && handleDayClick(day)}
        style={{ cursor: day.isFutureDate ? "default" : "pointer" }}
      >
        <span>{day.date.getDate()}</span>
        {!day.isFutureDate && day.xp > 0 && (
          <span className="text-xs mt-1 font-bold">{day.xp}</span>
        )}
        {!day.isFutureDate && icons && <span className="text-xs">{icons}</span>}
      </div>
    );
  };

  // Handle day click
  const handleDayClick = (day) => {
    // Don't do anything for future dates or days without activity details
    if (day.isFutureDate || !day.activityDetails) return;

    // Set selected day in state
    setSelectedDay(day);

    // Show day detail modal
    setShowDayDetail(true);
  };

  // Organize days into weeks for the calendar
  const organizeCalendarWeeks = (days) => {
    const weeks = [];
    let week = [];

    // Find the first day of the week for the first day
    const firstDay = days[0]?.date.getDay() || 0; // 0 = Sunday, 1 = Monday, etc.

    // Add empty slots for days before the first day
    for (let i = 0; i < firstDay; i++) {
      week.push(null);
    }

    // Add all days to weeks
    days.forEach((day) => {
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      week.push(day);
    });

    // Add empty slots for remaining days in the last week
    while (week.length < 7) {
      week.push(null);
    }

    // Add the last week if it has any days
    if (week.length > 0) {
      weeks.push(week);
    }

    return weeks;
  };

  // Manual refresh function - now uses context's refreshAnalytics
  const forceRefresh = () => {
    console.log("Manual refresh requested");
    setLocalLoading(true);

    // Refresh analytics data through context
    refreshAnalytics(true);

    // Also refresh power-ups
    if (refreshPowerUpStatus) {
      refreshPowerUpStatus();
    }

    // Set a short timeout to prevent flickering
    setTimeout(() => setLocalLoading(false), 500);
  };

  const calendarDays = generateCalendarDays();
  const calendarWeeks = organizeCalendarWeeks(calendarDays);

  // Combined loading state
  const showLoading = isLoading || localLoading;

  return (
    <div className="space-y-3 md:space-y-4 px-2 md:px-0">
      {showLoading ? (
        <div className="text-center p-4 md:p-6">
          <div className="inline-block animate-spin rounded-full h-6 w-6 md:h-8 md:w-8 border-b-2 border-green mb-2 md:mb-3"></div>
          <p className="text-sm md:text-base">Loading streak data...</p>
        </div>
      ) : (
        <>
          <div className="text-center">
            <span className="text-3xl md:text-4xl">🔥</span>
            <h3 className="text-xl md:text-2xl font-bold mt-1 md:mt-2">
              Your Streak: {streak || userStats?.current_streak || 0} days
            </h3>
            <p className="text-xs md:text-sm text-gray mt-1">
              Keep learning daily to maintain your streak!
            </p>
          </div>

          {/* Streak Freeze Banner */}
          {streakFreezeActive && (
            <div className="bg-darkgray border border-gray text-black p-2 md:p-3 rounded-lg">
              <div className="flex items-center">
                <span className="text-lg md:text-xl mr-2">🥶</span>
                <div>
                  <p className="font-bold text-sm md:text-base">
                    Streak Freeze Active
                  </p>
                  <p className="text-xs md:text-sm">
                    Your streak is protected for today
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Streak Savers Counter */}
          {streakSavers > 0 && (
            <div className="bg-green bg-opacity-10 p-3 md:p-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="font-semibold text-green text-sm md:text-base">
                    Streak Savers
                  </h4>
                  <p className="text-xs md:text-sm">
                    Use these to protect your streak
                  </p>
                </div>
                <div className="flex items-center gap-1 md:gap-2">
                  <span className="font-bold text-base md:text-lg">
                    {streakSavers}
                  </span>
                  <span className="text-lg md:text-xl">🛡️</span>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-center space-x-1 sm:space-x-2 pb-2">
            <button
              onClick={() => handleChangeView("week")}
              className={`px-2 sm:px-4 py-1 rounded-sm text-xs sm:text-sm ${
                view === "week"
                  ? "border-green border-2 border-b-4 text-green"
                  : "border-gray border-2 border-b-4 text-gray hover:bg-darkgray"
              }`}
            >
              Week
            </button>
            <button
              onClick={() => handleChangeView("month")}
              className={`px-2 sm:px-4 py-1 rounded-sm text-xs sm:text-sm ${
                view === "month"
                  ? "border-green border-2 border-b-4 text-green"
                  : "border-gray border-2 border-b-4 text-gray hover:bg-darkgray"
              }`}
            >
              Month
            </button>
            <button
              onClick={() => handleChangeView("year")}
              className={`px-2 sm:px-4 py-1 rounded-sm text-xs sm:text-sm ${
                view === "year"
                  ? "border-green border-2 border-b-4 text-green"
                  : "border-gray border-2 border-b-4 text-gray hover:bg-darkgray"
              }`}
            >
              3 Months
            </button>
          </div>

          {/* Calendar display */}
          <div className="bg-white rounded-lg p-2 md:p-4 overflow-x-auto">
            <div className="min-w-[280px]">
              <div className="grid grid-cols-7 gap-1 mb-2">
                {["S", "M", "T", "W", "T", "F", "S"].map((day, i) => (
                  <div
                    key={i}
                    className="text-center text-xs font-medium text-gray"
                  >
                    {day}
                  </div>
                ))}
              </div>
              {calendarWeeks.map((week, weekIndex) => (
                <div key={weekIndex} className="grid grid-cols-7 gap-1 mb-1">
                  {week.map((day, dayIndex) => (
                    <div key={dayIndex}>{renderCalendarDay(day)}</div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="border-darkgray border-2 border-b-4 rounded p-3 md:p-4">
            <h4 className="font-semibold mb-2 text-sm md:text-base">
              Streak Stats
            </h4>
            <div className="grid grid-cols-2 gap-3 md:gap-4">
              <div>
                <p className="text-xs md:text-sm text-gray">Current Streak</p>
                <p className="text-lg md:text-2xl font-bold">
                  {streak || userStats?.current_streak || 0} days
                </p>
              </div>
              <div>
                <p className="text-xs md:text-sm text-gray">Longest Streak</p>
                <p className="text-lg md:text-2xl font-bold">
                  {userStats?.longest_streak || 0} days
                </p>
              </div>
            </div>
          </div>

          {/* Shop Link for Streak Savers */}
          <Link to="/shop" onClick={onClose}>
            <div className="bg-opacity-10 p-3 md:p-4 mt-2 rounded border-gold border-2 border-b-4 cursor-pointer hover:bg-opacity-20 transition-all">
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="font-semibold text-gold text-sm md:text-base">
                    Get Streak Savers
                  </h4>
                  <p className="text-xs md:text-sm mt-1">
                    Protect your streak on busy days
                  </p>
                </div>
                <div className="border-gold border-b-4 border-2 text-gold px-2 md:px-3 py-1 rounded text-xs md:text-sm font-bold">
                  SHOP NOW
                </div>
              </div>
            </div>
          </Link>

          <div className="bg-opacity-10 p-3 md:p-4 rounded border-red border-2 border-b-4">
            <h4 className="font-semibold text-red text-sm md:text-base">
              Streak Rewards
            </h4>
            <ul className="text-xs md:text-sm mt-2 space-y-1">
              <li>• 3 days: 15 coins</li>
              <li>• 7 days: 30 coins</li>
              <li>• 14 days: 70 coins + 1 streak freeze</li>
              <li>• 30 days: 150 coins + special badge</li>
            </ul>
          </div>

          {/* Day Detail Modal - Imported from streak-day-detail-modal.jsx */}
          {showDayDetail && selectedDay && (
            <DayDetailModal
              day={selectedDay}
              userStats={userStats}
              onClose={() => {
                setShowDayDetail(false);
                setSelectedDay(null);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default StreaksModalContent;
