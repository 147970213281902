import { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const useLearningAnalytics = () => {
  const [learningProgress, setLearningProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchLearningProgress = async () => {
      const user = auth.currentUser;
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        const progressRef = doc(
          db,
          "users",
          user.uid,
          "analytics",
          "learning_progress"
        );

        const docSnap = await getDoc(progressRef);

        if (docSnap.exists()) {
          setLearningProgress(docSnap.data());
        } else {
          // Initialize the document if it doesn't exist
          const initialProgress = {
            lessons_completed: 0,
            lessons_started: 0, // Added lessons_started
            total_xp: 0,
            vocabulary_learned: 0,
            practice_questions_answered: 0,
            correct_answers: 0,
            last_updated: new Date(),
          };

          // Create the document with initial data
          await setDoc(progressRef, initialProgress);

          // Set the state with initial progress
          setLearningProgress(initialProgress);
        }
      } catch (err) {
        console.error("Error fetching learning progress:", err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (auth.currentUser) {
      fetchLearningProgress();
    }
  }, [auth.currentUser?.uid]);

  // Method to track lesson start
  const trackLessonStart = async (lessonDetails = {}) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const progressRef = doc(
        db,
        "users",
        user.uid,
        "analytics",
        "learning_progress"
      );

      // Increment lessons_started and update additional details
      await updateDoc(progressRef, {
        lessons_started: increment(1),
        last_lesson_started: new Date(),
        last_lesson_details: lessonDetails,
      });

      // Optimistically update local state
      setLearningProgress((prev) => ({
        ...prev,
        lessons_started: (prev.lessons_started || 0) + 1,
        last_lesson_started: new Date(),
        last_lesson_details: lessonDetails,
      }));
    } catch (err) {
      console.error("Error tracking lesson start:", err);
      setError(err);
    }
  };

  // Method to update learning progress
  const updateLearningProgress = async (updates) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const progressRef = doc(
        db,
        "users",
        user.uid,
        "analytics",
        "learning_progress"
      );

      await updateDoc(progressRef, {
        ...updates,
        last_updated: new Date(),
      });

      // Optimistically update local state
      setLearningProgress((prev) => ({
        ...prev,
        ...updates,
        last_updated: new Date(),
      }));
    } catch (err) {
      console.error("Error updating learning progress:", err);
      setError(err);
    }
  };

  return {
    learningProgress,
    isLoading,
    error,
    trackLessonStart,
    updateLearningProgress,
  };
};
