import { db } from "../../firebase/firebase.utils";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { AnalyticsService } from "../enhanced-analytics.service";

class DeckUnlockService {
  /**
   * Cache for deck unlock status to improve performance and preserve state
   * @type {Object}
   * @private
   */
  _unlockStatusCache = {};

  /**
   * Notification handlers for this service
   * @type {Object|null}
   * @private
   */
  _notificationHandlers = null;

  /**
   * Set notification handlers for this service
   * @param {Object} handlers - Object containing notification handlers
   */
  setNotificationHandlers(handlers) {
    this._notificationHandlers = handlers;
    // console.log(
    //   handlers
    //     ? "Notification handlers registered with deck unlock service"
    //     : "Notification handlers cleared from deck unlock service"
    // );
  }

  /**
   * Caches deck unlock status for fast retrieval
   * @param {string} userId - User ID
   * @param {string} deckId - Deck ID
   * @param {boolean} isUnlocked - Whether the deck is unlocked
   */
  cacheUnlockStatus(userId, deckId, isUnlocked) {
    if (!userId || !deckId) return;

    if (!this._unlockStatusCache[userId]) {
      this._unlockStatusCache[userId] = {};
    }

    this._unlockStatusCache[userId][deckId] = {
      isUnlocked,
      timestamp: Date.now(),
    };

    // console.log(
    //   `Cached unlock status for deck ${deckId}: ${
    //     isUnlocked ? "UNLOCKED" : "LOCKED"
    //   }`
    // );
  }

  /**
   * Gets cached unlock status if available and recent
   * @param {string} userId - User ID
   * @param {string} deckId - Deck ID
   * @returns {boolean|null} - Unlock status or null if not cached or expired
   */
  getCachedUnlockStatus(userId, deckId) {
    if (!userId || !deckId) return null;

    const cached = this._unlockStatusCache[userId]?.[deckId];
    if (!cached) return null;

    // Cache expires after 5 minutes
    const CACHE_TTL = 5 * 60 * 1000;
    if (Date.now() - cached.timestamp > CACHE_TTL) {
      return null;
    }

    return cached.isUnlocked;
  }

  /**
   * Force a cache-busting reload of user unlock status
   * @param {string} userId - User ID
   * @returns {Promise<Object>}
   */
  async forceUnlockStatusRefresh(userId) {
    if (!userId) return { success: false };

    try {
      // console.log("🔄 Forcing a fresh update of unlock status from Firestore");

      // Clear the cache for this user to force fresh data
      this._unlockStatusCache[userId] = {};
      // console.log(
      //   `Cleared unlock status cache for user ${userId} due to forced refresh`
      // );

      // Get a fresh reference to the user document
      const userRef = doc(db, "users", userId);

      // Use cache-busting options for this particular read
      const userDoc = await getDoc(userRef, { source: "server" });

      if (!userDoc.exists()) {
        console.error("User document not found during forced refresh");
        return { success: false };
      }

      // Process completed skills and lessons
      const userData = userDoc.data();
      const unlockedDecks = userData.unlockedDecks || {};

      // console.log(
      //   `Found ${
      //     Object.keys(unlockedDecks).length
      //   } unlocked decks in user document`
      // );

      return {
        success: true,
        unlockedCount: Object.keys(unlockedDecks).length,
        timestamp: new Date().toISOString(),
      };
    } catch (error) {
      console.error("Error during forced unlock status refresh:", error);
      return { success: false, error: error.message };
    }
  }

  /**
   * Check if a user has completed a specific skill/lesson
   * @param {string} userId - The user ID
   * @param {number} skill - The skill number
   * @param {number} lesson - The lesson number
   * @returns {Promise<boolean>} Whether the lesson is completed
   */
  async isLessonCompleted(userId, skill, lesson) {
    try {
      // Get user document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        return false;
      }

      // Check if lesson is completed using your progress structure
      const progress = userDoc.data().progress;

      // This assumes your progress structure is progress[skillNumber][lessonNumber].completed
      return (
        progress &&
        progress[skill] &&
        progress[skill][lesson] &&
        progress[skill][lesson].completed === true
      );
    } catch (error) {
      console.error("Error checking lesson completion:", error);
      return false;
    }
  }

  /**
   * Get all flashcard decks that should be unlocked for a specific skill/lesson
   * @param {number} skill - The skill number
   * @param {number} lesson - The lesson number
   * @returns {Promise<Array>} Array of deck objects that can be unlocked
   */
  async getUnlockableDecks(skill, lesson) {
    try {
      // Query for decks that match these unlock requirements
      const decksQuery = query(
        collection(db, "flashcard_decks"),
        where("isLockedByDefault", "==", true),
        where("unlockRequirements.skill", "==", skill),
        where("unlockRequirements.lesson", "==", lesson)
      );

      const snapshot = await getDocs(decksQuery);

      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error getting unlockable decks:", error);
      return [];
    }
  }

  /**
   * Check for and unlock any flashcard decks based on completed lesson
   * @param {string} userId - The user ID
   * @param {number} skill - The skill number that was completed
   * @param {number} lesson - The lesson number that was completed
   * @returns {Promise<Array>} Array of unlocked deck IDs
   */
  async checkForDecksToUnlock(userId, skill, lesson) {
    if (!userId) return [];

    try {
      // console.log(
      //   `Checking for decks to unlock for skill ${skill}, lesson ${lesson}`
      // );

      // Get unlockable decks for this skill/lesson
      const unlockableDecks = await this.getUnlockableDecks(skill, lesson);

      if (unlockableDecks.length === 0) {
        // console.log("No unlockable decks found for this skill/lesson");
        return [];
      }

      // console.log(
      //   `Found ${unlockableDecks.length} unlockable decks:`,
      //   unlockableDecks.map((d) => d.name)
      // );

      // Check if this lesson is completed
      const isCompleted = await this.isLessonCompleted(userId, skill, lesson);

      if (!isCompleted) {
        // console.log(`Skill ${skill}, Lesson ${lesson} is not completed yet`);
        return [];
      }

      // Get user document to check already unlocked decks
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        console.error("User document not found");
        return [];
      }

      // Get or initialize the user's unlockedDecks field
      const userData = userDoc.data();
      const unlockedDecks = userData.unlockedDecks || {};

      // Track which decks were newly unlocked
      const newlyUnlockedDeckIds = [];
      const newlyUnlockedDecks = []; // Store the full deck objects for notifications

      // Unlock each deck that isn't already unlocked
      for (const deck of unlockableDecks) {
        // Skip if already unlocked
        if (unlockedDecks[deck.id]) {
          // console.log(`Deck ${deck.id} already unlocked`);
          continue;
        }

        // Add to list of newly unlocked decks
        newlyUnlockedDeckIds.push(deck.id);
        newlyUnlockedDecks.push(deck);

        // Add unlock data to unlockedDecks object
        unlockedDecks[deck.id] = {
          unlockedAt: new Date().toISOString(),
          notificationShown: false,
        };

        // Cache the unlock status
        this.cacheUnlockStatus(userId, deck.id, true);

        // Track the unlock event
        if (userId) {
          AnalyticsService.trackEvent(userId, "flashcard_deck_unlocked", {
            deckId: deck.id,
            deckName: deck.name,
            unlockedBySkill: skill,
            unlockedByLesson: lesson,
          });
        }
      }

      // If no new decks were unlocked, return empty array
      if (newlyUnlockedDeckIds.length === 0) {
        return [];
      }

      // Update the user document with unlocked decks
      await updateDoc(userRef, {
        unlockedDecks: unlockedDecks,
        lastUpdated: serverTimestamp(),
      });

      // console.log(
      //   `Unlocked ${newlyUnlockedDeckIds.length} new decks:`,
      //   newlyUnlockedDeckIds
      // );

      // Send notifications for each newly unlocked deck
      if (
        this._notificationHandlers &&
        this._notificationHandlers.sendDeckUnlockedNotification
      ) {
        for (const deck of newlyUnlockedDecks) {
          try {
            // console.log(`Sending notification for unlocked deck: ${deck.name}`);
            await this._notificationHandlers.sendDeckUnlockedNotification(
              deck,
              skill,
              lesson
            );
          } catch (notificationError) {
            console.error(
              "Error sending deck unlock notification:",
              notificationError
            );
            // Continue with the next deck even if notification fails
          }
        }
      } else {
        // console.log("Notification handlers not set up, skipping notifications");
      }

      return newlyUnlockedDeckIds;
    } catch (error) {
      console.error("Error checking for decks to unlock:", error);
      return [];
    }
  }

  /**
   * Mark deck notifications as shown
   * @param {string} userId - User ID
   * @param {string} deckId - Deck ID
   * @returns {Promise<boolean>} - Success status
   */
  async markNotificationShown(userId, deckId) {
    if (!userId || !deckId) return false;

    try {
      const userRef = doc(db, "users", userId);

      await updateDoc(userRef, {
        [`unlockedDecks.${deckId}.notificationShown`]: true,
      });

      return true;
    } catch (error) {
      console.error("Error marking notification as shown:", error);
      return false;
    }
  }

  /**
   * Check if a deck is unlocked for a user
   * @param {string} userId - User ID
   * @param {string} deckId - Deck ID
   * @returns {Promise<boolean>} Whether the deck is unlocked
   */
  async isDeckUnlocked(userId, deckId) {
    if (!userId) return false;

    try {
      // Check cache first
      const cachedStatus = this.getCachedUnlockStatus(userId, deckId);
      if (cachedStatus !== null) {
        // console.log(
        //   `Using cached unlock status for ${deckId}: ${
        //     cachedStatus ? "UNLOCKED" : "LOCKED"
        //   }`
        // );
        return cachedStatus;
      }

      // Get deck details
      const deckRef = doc(db, "flashcard_decks", deckId);
      const deckDoc = await getDoc(deckRef);

      if (!deckDoc.exists()) {
        return false;
      }

      const deckData = deckDoc.data();

      // If deck is not locked by default, it's always unlocked
      if (deckData.isLockedByDefault !== true) {
        this.cacheUnlockStatus(userId, deckId, true);
        return true;
      }

      // Get user data
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        return false;
      }

      const userData = userDoc.data();
      let isUnlocked = false;

      // Check if this deck is in the user's unlockedDecks
      if (userData.unlockedDecks && userData.unlockedDecks[deckId]) {
        isUnlocked = true;
      } else {
        // Check if the user has completed the required lesson
        if (deckData.unlockRequirements) {
          const { skill, lesson } = deckData.unlockRequirements;
          isUnlocked = await this.isLessonCompleted(userId, skill, lesson);
        }
      }

      // Cache the result before returning
      this.cacheUnlockStatus(userId, deckId, isUnlocked);
      return isUnlocked;
    } catch (error) {
      console.error("Error checking if deck is unlocked:", error);
      return false;
    }
  }

  /**
   * Get all flashcard decks with their unlock status for a user
   * @param {string} userId - The user ID
   * @returns {Promise<Array>} Array of deck objects with unlock status
   */
  async getAllDecksWithUnlockStatus(userId) {
    try {
      // console.log(`Getting all decks with unlock status for user: ${userId}`);

      // Get all decks from the database
      const decksQuery = query(collection(db, "flashcard_decks"));
      const snapshot = await getDocs(decksQuery);

      // If no user is logged in, return decks with only default unlock status
      if (!userId) {
        return snapshot.docs.map((doc) => {
          const deckData = doc.data();
          return {
            id: doc.id,
            ...deckData,
            isUnlocked: !deckData.isLockedByDefault,
            isNewlyUnlocked: false,
          };
        });
      }

      // Get user document to check unlocked decks
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        console.error("User document not found");
        return [];
      }

      const userData = userDoc.data();
      const unlockedDecks = userData.unlockedDecks || {};
      // console.log("User unlocked decks:", unlockedDecks);

      // Process each deck with unlock status
      const decksWithStatus = await Promise.all(
        snapshot.docs.map(async (deckDoc) => {
          const deckData = deckDoc.data();
          const deckId = deckDoc.id;

          // If deck is not locked by default, it's always unlocked
          if (deckData.isLockedByDefault !== true) {
            // Cache this result
            this.cacheUnlockStatus(userId, deckId, true);
            return {
              id: deckId,
              ...deckData,
              isUnlocked: true,
              isNewlyUnlocked: false,
            };
          }

          // Check if deck is in user's unlockedDecks
          let isUnlocked = false;
          let isNewlyUnlocked = false;

          // First check the cache for quicker response
          const cachedStatus = this.getCachedUnlockStatus(userId, deckId);
          if (cachedStatus !== null) {
            isUnlocked = cachedStatus;
            // console.log(
            //   `Using cached unlock status for ${deckId}: ${
            //     isUnlocked ? "UNLOCKED" : "LOCKED"
            //   }`
            // );
          } else if (unlockedDecks[deckId]) {
            isUnlocked = true;
            isNewlyUnlocked = unlockedDecks[deckId].notificationShown === false;
            // console.log(
            //   `Deck ${deckId} (${deckData.name}) has unlock record:`,
            //   {
            //     isUnlocked,
            //     isNewlyUnlocked,
            //     record: unlockedDecks[deckId],
            //   }
            // );

            // Cache this result
            this.cacheUnlockStatus(userId, deckId, isUnlocked);
          } else {
            // If not directly unlocked, check if user completed the required lesson
            if (deckData.unlockRequirements) {
              const { skill, lesson } = deckData.unlockRequirements;
              isUnlocked = await this.isLessonCompleted(userId, skill, lesson);
              // console.log(
              //   `Deck ${deckId} (${deckData.name}) lesson completion check:`,
              //   {
              //     isUnlocked,
              //     skill,
              //     lesson,
              //   }
              // );

              // Cache this result
              this.cacheUnlockStatus(userId, deckId, isUnlocked);

              // If unlocked by lesson completion but not in user's unlockedDecks,
              // we should update the user document and mark as newly unlocked
              if (isUnlocked) {
                try {
                  // console.log(
                  //   `Automatically recording unlock for deck ${deckId} based on lesson completion`
                  // );

                  // Add to user's unlockedDecks
                  unlockedDecks[deckId] = {
                    unlockedAt: new Date().toISOString(),
                    notificationShown: false,
                  };

                  await updateDoc(userRef, {
                    [`unlockedDecks.${deckId}`]: unlockedDecks[deckId],
                    lastUpdated: serverTimestamp(),
                  });

                  isNewlyUnlocked = true;

                  // Send notification for this auto-detected unlock
                  if (
                    this._notificationHandlers &&
                    this._notificationHandlers.sendDeckUnlockedNotification
                  ) {
                    try {
                      const { skill, lesson } = deckData.unlockRequirements;
                      await this._notificationHandlers.sendDeckUnlockedNotification(
                        { id: deckId, ...deckData },
                        skill,
                        lesson
                      );
                    } catch (notificationError) {
                      console.error(
                        "Error sending deck unlock notification:",
                        notificationError
                      );
                    }
                  }
                } catch (updateError) {
                  console.error(
                    `Error updating user with auto-detected unlock for deck ${deckId}:`,
                    updateError
                  );
                }
              }
            }
          }

          const result = {
            id: deckId,
            ...deckData,
            isUnlocked,
            isNewlyUnlocked,
          };

          // Debug log
          // console.log(`Processed deck ${deckId} (${deckData.name}):`, {
          //   isUnlocked: result.isUnlocked,
          //   isNewlyUnlocked: result.isNewlyUnlocked,
          // });

          return result;
        })
      );

      // Final result summary
      const unlockedCount = decksWithStatus.filter((d) => d.isUnlocked).length;
      const lockedCount = decksWithStatus.length - unlockedCount;
      console.log(
        `Processed ${decksWithStatus.length} decks: ${unlockedCount} unlocked, ${lockedCount} locked`
      );

      return decksWithStatus;
    } catch (error) {
      console.error("Error getting decks with unlock status:", error);
      return [];
    }
  }

  /**
   * Refresh deck unlock status after a lesson is completed
   * This should be called when returning to the flashcard page
   * @param {string} userId - User ID
   * @param {boolean} forceServerRefresh - Whether to force a server refresh
   * @returns {Promise<Object>}
   */
  async refreshDeckUnlockStatus(userId, forceServerRefresh = false) {
    if (!userId) return { timestamp: new Date().toISOString() };

    try {
      // console.log(
      //   `Refreshing deck unlock status for user: ${userId}${
      //     forceServerRefresh ? " (FORCED)" : ""
      //   }`
      // );

      // If forcing a refresh, clear the cache
      if (forceServerRefresh) {
        this._unlockStatusCache[userId] = {};
        // console.log(
        //   `Cleared unlock status cache for user ${userId} due to forced refresh`
        // );

        // Call the special method for server refresh
        await this.forceUnlockStatusRefresh(userId);
      }

      // Forcibly clear any frontend caching by returning a timestamp
      const timestamp = new Date().toISOString();

      // Get all skills and lessons the user has completed
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        console.error("User document not found");
        return { timestamp };
      }

      const userData = userDoc.data();

      // Check user's progress structure
      if (!userData.progress) {
        // console.log("No progress data found");
        return { timestamp };
      }

      // Find skill and lesson records that are completed but might not have unlocked decks
      const completedSkillsAndLessons = [];

      Object.entries(userData.progress).forEach(([skill, lessons]) => {
        Object.entries(lessons).forEach(([lesson, data]) => {
          if (data && data.completed === true) {
            completedSkillsAndLessons.push({
              skill: parseInt(skill),
              lesson: parseInt(lesson),
            });
          }
        });
      });

      // console.log(
      //   "Found completed skills and lessons:",
      //   completedSkillsAndLessons
      // );

      // Process each completed skill/lesson to ensure decks are unlocked
      let totalNewlyUnlocked = 0;

      for (const { skill, lesson } of completedSkillsAndLessons) {
        const unlockedDeckIds = await this.checkForDecksToUnlock(
          userId,
          skill,
          lesson
        );
        totalNewlyUnlocked += unlockedDeckIds.length;
      }

      // console.log(
      //   `Refresh complete. Newly unlocked decks: ${totalNewlyUnlocked}`
      // );

      return {
        timestamp,
        totalNewlyUnlocked,
      };
    } catch (error) {
      console.error("Error refreshing deck unlock status:", error);
      return { timestamp: new Date().toISOString() };
    }
  }
}

export default new DeckUnlockService();
