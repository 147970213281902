// src/data/unitOverviewData.js

const unitOverviewData = {
  "george1-unit1": {
    id: "george1-unit1",
    name: "The Sounds of isiZulu",
    topic: "The Sounds of isiZulu",
    overview:
      "In this unit you will navigate the different sounds native to isiZulu. By the end of this unit you will be introduced to the pronunciations of each alphabetic symbol to make sure that you can easily work your way through speaking isiZulu with the confidence of a native mother tongue speaker.",
    outcomes: [
      "Identify the different vowel and consonant sounds in isiZulu",
      "Learn how to pronounce the click sounds found in isiZulu",
    ],
    // Add audio paths
    titleAudio: "sounds/units/george1-unit1/title.mp3",
    overviewAudio: "sounds/units/george1-unit1/overview.mp3",
    "outcomeAudio-0":
      "sounds/units/george1-unit1/outcome_vowels_consonants.mp3",
    "outcomeAudio-1": "sounds/units/george1-unit1/outcome_clicks.mp3",
    estimatedDuration: "60 MINUTES",
    keyConcepts: ["VOWELS", "CONSONANTS", "CLICKS"],
    color: "#991616", // Red color for George 1
    vowelsData: [
      {
        englishSound: "a",
        howToWrite: "ah",
        example: "hamba (hah-mbah)",
        meaning: "go",
        audioSrc: "sounds/zulu-1/words/hamba.mp3",
      },
      {
        englishSound: "e",
        howToWrite: "eh",
        example: "Ngiyezwa (ngee-yeh-zwah)",
        meaning: "I understand",
        audioSrc: "sounds/zulu-1/words/ngiyezwa.mp3",
      },
      {
        englishSound: "i",
        howToWrite: "ee",
        example: "emini (eh-mee-nee)",
        meaning: "at midday",
        audioSrc: "sounds/zulu-1/words/emini.mp3",
      },
    ],
    consonantsData: [
      {
        englishSound: "b & bh",
        howToWrite: "b",
        example: "libhubesi (lee-boo-beh-see)",
        meaning: "lion",
        audioSrc: "sounds/zulu-1/words/libhubesi.mp3",
      },
      {
        englishSound: "nk",
        howToWrite: "nk",
        example: "Akunankinga (ah-goo-nah-nkee-ngah)",
        meaning: "No problem",
        audioSrc: "sounds/zulu-1/words/akunankinga.mp3",
      },
      {
        englishSound: "ng",
        howToWrite: "ng",
        example: "Ngiyabonga (ngee-yah-baw-ngah)",
        meaning: "thank you",
        audioSrc: "sounds/zulu-1/words/ngiyabonga.mp3",
      },
    ],
    clicksData: [
      {
        englishSound: "c",
        howToWrite: "/",
        example: "ngicela (ngee-/eh-lah)",
        meaning: "please",
        audioSrc: "sounds/zulu-1/words/ngicela.mp3",
        howExplanation:
          "Place the tip of your tongue against the back of your top front teeth and pull the tongue down sharply to make a sucking sound (like 'tut tut').",
      },
      {
        englishSound: "q",
        howToWrite: "!",
        example: "amaqanda (ah-mah-!ah-ndah)",
        meaning: "eggs",
        audioSrc: "sounds/zulu-1/words/amaqanda.mp3",
        howExplanation:
          "Curl your tongue backward, place the front part against the middle part of your mouth's roof, and pull down sharply (like pulling a cork).",
      },
      {
        englishSound: "x",
        howToWrite: "//",
        example: "Uxolo (oo-//aw-law)",
        meaning: "Excuse me",
        audioSrc: "sounds/zulu-1/words/uxolo.mp3",
        howExplanation:
          "Keep your tongue against the roof of your mouth and suck in air through the sides (like a rider urging a horse).",
      },
    ],
  },
  "george1-unit2": {
    id: "george1-unit2",
    name: "Basic Greetings",
    topic: "Basic Greetings in isiZulu",
    overview:
      "In this unit, you'll learn how to greet people in isiZulu for different times of day and social situations. You'll practice formal and informal greetings along with appropriate responses.",
    outcomes: [
      "Greet people appropriately during different times of the day",
      "Introduce yourself in isiZulu",
      "Ask how someone is feeling and respond to such questions",
    ],
    // Add audio paths
    titleAudio: "sounds/units/george1-unit2/title.mp3",
    overviewAudio: "sounds/units/george1-unit2/overview.mp3",
    "outcomeAudio-0": "sounds/units/george1-unit2/outcome_greetings.mp3",
    "outcomeAudio-1": "sounds/units/george1-unit2/outcome_introductions.mp3",
    "outcomeAudio-2": "sounds/units/george1-unit2/outcome_questions.mp3",
    estimatedDuration: "45 MINUTES",
    keyConcepts: ["GREETINGS", "INTRODUCTIONS", "FORMAL vs INFORMAL"],
    color: "#991616", // Red color for George 1
    vowelsData: [], // This can be empty if not relevant
    consonantsData: [], // This can be empty if not relevant
    clicksData: [], // This can be empty if not relevant
  },
  // Add more units as needed
};

// Export a helper function to find unit data by various identifiers
export const findUnitData = (identifier) => {
  // First, try direct lookup
  if (unitOverviewData[identifier]) {
    return unitOverviewData[identifier];
  }

  // Normalize and try common formats
  const normalized = identifier.toLowerCase().replace(/\s+/g, "");

  // Try different formats
  const possibleFormats = [
    normalized,
    `${normalized}-unit1`,
    normalized.replace("unit", "-unit"),
  ];

  for (const format of possibleFormats) {
    if (unitOverviewData[format]) {
      return unitOverviewData[format];
    }
  }

  // If all else fails, return the first unit as default
  console.warn(`Could not find unit data for ${identifier}, returning default`);
  return unitOverviewData["george1-unit1"];
};

export default unitOverviewData;
