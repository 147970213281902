// ManualBookingFix.jsx
import React, { useState } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";

const ManualBookingFix = () => {
  const [bookingId, setBookingId] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);

  const handleCheckBooking = async () => {
    if (!bookingId) {
      setMessage("Please enter a booking ID");
      return;
    }

    setIsLoading(true);
    setMessage("");

    try {
      const bookingRef = doc(db, "bookings", bookingId);
      const bookingDoc = await getDoc(bookingRef);

      if (!bookingDoc.exists()) {
        setMessage("Booking not found");
        setBookingDetails(null);
      } else {
        const data = bookingDoc.data();
        setBookingDetails(data);
        setMessage(`Found booking: ${data.status}`);
      }
    } catch (error) {
      console.error("Error checking booking:", error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateBooking = async () => {
    if (!bookingId || !status) {
      setMessage("Booking ID and status are required");
      return;
    }

    setIsLoading(true);
    setMessage("");

    try {
      const bookingRef = doc(db, "bookings", bookingId);

      // First check if booking exists
      const bookingDoc = await getDoc(bookingRef);
      if (!bookingDoc.exists()) {
        setMessage("Booking not found");
        setIsLoading(false);
        return;
      }

      // Update the booking status
      await updateDoc(bookingRef, {
        status: status,
        updatedAt: new Date(),
      });

      // If we're updating to "upcoming", also update the availability
      if (status === "upcoming") {
        const bookingData = bookingDoc.data();
        if (bookingData.availabilityId && bookingData.coachId) {
          const availabilityRef = doc(
            db,
            "users",
            bookingData.coachId,
            "availability",
            bookingData.availabilityId
          );

          await updateDoc(availabilityRef, {
            bookable: false,
            bookedBy: bookingData.studentId,
            tentativelyBookedBy: null,
            tentativeBookingExpiry: null,
            updatedAt: new Date(),
          });

          setMessage("Successfully updated booking and availability");
        } else {
          setMessage(
            "Updated booking but couldn't update availability (missing data)"
          );
        }
      } else {
        setMessage(`Successfully updated booking status to ${status}`);
      }

      // Refresh booking details
      handleCheckBooking();
    } catch (error) {
      console.error("Error updating booking:", error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-8 max-w-md mx-auto mt-12 bg-white rounded-lg shadow border-2 border-darkgray">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Booking Troubleshooter
      </h2>

      {/* Booking ID Input */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Booking ID</label>
        <input
          type="text"
          value={bookingId}
          onChange={(e) => setBookingId(e.target.value)}
          className="w-full p-2 border-2 border-darkgray rounded"
          placeholder="Enter booking ID"
        />
      </div>

      {/* Check Booking Button */}
      <button
        onClick={handleCheckBooking}
        disabled={isLoading || !bookingId}
        className="w-full px-4 py-2 bg-gold text-black border-2 border-b-4 border-black rounded mb-4 hover:bg-opacity-90 disabled:opacity-50"
      >
        {isLoading ? "Checking..." : "Check Booking Status"}
      </button>

      {/* Booking Details */}
      {bookingDetails && (
        <div className="mb-4 p-4 bg-gray-100 rounded-lg">
          <h3 className="font-medium mb-2">Booking Details</h3>
          <p>
            <strong>Status:</strong> {bookingDetails.status}
          </p>
          <p>
            <strong>Coach:</strong> {bookingDetails.coachName}
          </p>
          <p>
            <strong>Student:</strong> {bookingDetails.studentName}
          </p>
          <p>
            <strong>Created:</strong>{" "}
            {bookingDetails.createdAt?.toDate?.().toLocaleString() || "Unknown"}
          </p>
          {bookingDetails.meetingLink && (
            <p>
              <strong>Meeting Link:</strong>{" "}
              <a
                href={bookingDetails.meetingLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green underline"
              >
                Join Meeting
              </a>
            </p>
          )}
        </div>
      )}

      {/* Update Status Section */}
      <div className="mt-6 pt-6 border-t-2 border-darkgray">
        <h3 className="font-medium mb-2">Update Booking Status</h3>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">New Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full p-2 border-2 border-darkgray rounded"
          >
            <option value="">Select Status</option>
            <option value="upcoming">Upcoming</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
            <option value="pending_payment">Pending Payment</option>
          </select>
        </div>

        <button
          onClick={handleUpdateBooking}
          disabled={isLoading || !bookingId || !status}
          className="w-full px-4 py-2 bg-green text-white border-2 border-b-4 border-black rounded hover:bg-opacity-90 disabled:opacity-50"
        >
          {isLoading ? "Updating..." : "Update Booking"}
        </button>
      </div>

      {/* Message Display */}
      {message && (
        <div
          className={`mt-4 p-3 rounded ${
            message.includes("Error")
              ? "bg-red bg-opacity-20 text-red"
              : "bg-green bg-opacity-20 text-green"
          }`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default ManualBookingFix;
