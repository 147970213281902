import React, { useState } from "react";
import {
  milestoneData,
  lockedMilestones,
} from "../../../zombiedata/milestoneData";
import MilestoneCard from "./milestone-card";
import LockedMilestoneCard from "./locked-milestone-card";
import MilestoneDetailsModal from "./milestone-details-modal";

const MilestoneHub = () => {
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Sort milestones by order property
  const sortedMilestones = Object.values(milestoneData).sort(
    (a, b) => a.order - b.order
  );

  // Handle click on "See More Details"
  const handleDetailsClick = (milestone) => {
    setSelectedMilestone(milestone);
    setModalOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="container mx-auto px-3 sm:px-4 py-4 sm:py-8">
      {/* Optional heading if needed */}
      {/* <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-left mb-4 sm:mb-8">Milestones</h1> */}

      {/* Milestone Cards - reduced space between cards on mobile */}
      <div className="space-y-6 sm:space-y-8">
        {sortedMilestones.map((milestone) =>
          milestone.unlocked ? (
            <MilestoneCard
              key={milestone.id}
              milestone={milestone}
              onDetailsClick={handleDetailsClick}
            />
          ) : (
            <LockedMilestoneCard
              key={milestone.id}
              milestone={lockedMilestones[milestone.id]}
            />
          )
        )}
      </div>

      {/* Modal for milestone details */}
      {modalOpen && selectedMilestone && (
        <MilestoneDetailsModal
          milestone={selectedMilestone}
          isOpen={modalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default MilestoneHub;
