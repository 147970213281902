// components/modals/CoursesModalContent.jsx - Mobile Responsive Version
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";

const CoursesModalContent = ({ onClose, currentSkillData }) => {
  const { user } = useAuth();
  const { userStats } = useSafeAnalytics(); // Get analytics data
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserCourses = async () => {
      if (!user?.uid) return;

      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();

          // If there's a current enrollment, use it
          if (userData.currentEnroll) {
            const currentCourse = {
              id: userData.currentEnroll.course || "isiZuluSocial",
              title: "isiZulu Social",
              emoji: "🇿🇦",
              language: "Zulu",
              progress: calculateProgress(userData.progress || {}),
              currentSkill: userData.currentEnroll.currentSkill || 1,
              currentLesson: userData.currentEnroll.currentLesson || 1,
            };

            setCourses([currentCourse]);
          }
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCourses();
  }, [user?.uid]);

  // Enhanced progress calculation that can use analytics data if available
  const calculateProgress = (progressData) => {
    if (!progressData) return 0;

    // If we have analytics data with lessons_completed_count, use it for additional insight
    const analyticsLessonsCompleted = userStats?.lessons_completed_count || 0;

    // Count completed lessons from progress data
    let completedLessons = 0;
    let totalLessons = 500; // Default estimate

    Object.values(progressData).forEach((skill) => {
      if (skill && typeof skill === "object") {
        Object.values(skill).forEach((lesson) => {
          totalLessons++;
          if (lesson && lesson.completed) {
            completedLessons++;
          }
        });
      }
    });

    // Use the higher of the two counts to ensure most up-to-date information
    completedLessons = Math.max(completedLessons, analyticsLessonsCompleted);

    return totalLessons > 0 ? (completedLessons / totalLessons) * 100 : 0;
  };

  return (
    <div className="space-y-3 md:space-y-4 px-2 md:px-0">
      <div className="text-center">
        <span className="text-3xl md:text-4xl">🇿🇦</span>
        <h3 className="text-xl md:text-2xl font-bold mt-1 md:mt-2">
          Your Courses
        </h3>
        <p className="text-xs md:text-sm text-gray-600 mt-1">
          Track your progress across all language courses
        </p>
      </div>

      {loading ? (
        <div className="flex justify-center p-4 md:p-8">
          <div className="animate-pulse space-y-3 md:space-y-4 w-full">
            <div className="h-4 md:h-6 bg-gray-300 rounded w-3/4 mx-auto"></div>
            <div className="h-20 md:h-24 bg-gray-300 rounded"></div>
            <div className="h-8 md:h-12 bg-gray-300 rounded w-1/2 mx-auto"></div>
          </div>
        </div>
      ) : courses.length > 0 ? (
        <div className="space-y-3 md:space-y-4">
          {courses.map((course) => (
            <div
              key={course.id}
              className="border-darkgray border-2 border-b-4 w-full mx-auto rounded p-3 md:p-4"
            >
              <div className="flex items-center mb-2">
                <span className="text-xl md:text-2xl mr-2">
                  {course.emoji || "📚"}
                </span>
                <h4 className="text-base md:text-lg font-semibold">
                  {course.title || "Course"}
                </h4>
              </div>

              <div className="mt-2">
                <div className="flex justify-between text-xs md:text-sm mb-1">
                  <span>Progress</span>
                  <span>{Math.round(course.progress)}%</span>
                </div>
                <div className="w-full bg-darkgray border-gray border rounded-full h-2 md:h-3">
                  <div
                    className="bg-green h-full rounded-full"
                    style={{ width: `${course.progress}%` }}
                  ></div>
                </div>
              </div>

              <div className="mt-3 md:mt-4 text-xs md:text-sm text-gray-600">
                <p>Current Skill: {currentSkillData?.skillName}</p>
                <p>Current Lesson: {course.currentLesson}</p>
                <p>
                  Lessons Completed: {userStats?.lessons_completed_count || 0}
                </p>
              </div>

              <div className="mt-3 md:mt-4">
                <Link to="/vault" onClick={onClose}>
                  <button className="w-full border-black bg-green text-black border-b-4 border-2 py-1.5 md:py-2 rounded-md hover:bg-opacity-90 transition-all duration-200 text-xs md:text-sm font-medium">
                    Continue Learning
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center p-3 md:p-4 bg-gray-100 rounded-lg">
          <p className="mb-3 text-sm md:text-base">
            You haven't enrolled in any courses yet.
          </p>
          <Link to="/courses" onClick={onClose}>
            <button className="bg-green text-white py-1.5 md:py-2 px-3 md:px-4 rounded-md hover:bg-opacity-90 transition-all duration-200 text-xs md:text-sm">
              Explore Courses
            </button>
          </Link>
        </div>
      )}

      {/* Course Stats Box - enhanced with analytics data */}
      <div className="border-2 border-b-4 border-darkgray p-3 md:p-4 rounded">
        <h4 className="font-semibold mb-2 text-sm md:text-base">
          Your Learning Stats
        </h4>
        <div className="grid grid-cols-2 gap-2 mb-3">
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-xs text-gray-600">Total XP</p>
            <p className="font-bold text-sm md:text-base">
              {userStats?.total_xp || 0}
            </p>
          </div>
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-xs text-gray-600">Streak</p>
            <p className="font-bold text-sm md:text-base">
              {userStats?.current_streak || 0} days
            </p>
          </div>
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-xs text-gray-600">Lessons</p>
            <p className="font-bold text-sm md:text-base">
              {userStats?.lessons_completed_count || 0}
            </p>
          </div>
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-xs text-gray-600">Vocabulary</p>
            <p className="font-bold text-sm md:text-base">
              {userStats?.vocabulary_learned_count || 0}
            </p>
          </div>
        </div>
        <ul className="space-y-1 text-xs md:text-sm text-gray-700">
          <li>• Complete lessons to advance through skills</li>
          <li>• Earn XP for each completed lesson</li>
          <li>• Practice regularly to maintain your streak</li>
          <li>• Track your progress on the dashboard</li>
        </ul>
      </div>
    </div>
  );
};

export default CoursesModalContent;
