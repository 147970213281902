import React, { useState, useContext, useRef } from "react";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase/firebase.utils";
import { LearningMaterialsContext } from "../../helpers/contexts";
import { LMContext } from "../../helpers/context3";
import { serverTimestamp } from "firebase/firestore";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useUserRole } from "../../hooks/useUserRole";

const FeedbackMenu = () => {
  const { user } = useAuth();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { isAdmin, isCoach } = useUserRole();
  const [feedbackType, setFeedbackType] = useState(null); // "bug" or "idea"
  const [bugDetails, setBugDetails] = useState({
    activity: "",
    description: "",
    options: [],
  });
  const [ideaDetails, setIdeaDetails] = useState({
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [screenshotFile, setScreenshotFile] = useState(null);
  const [screenshotPreview, setScreenshotPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [contactAllowed, setContactAllowed] = useState(false);
  const [bugStep, setBugStep] = useState(1);
  const { learningMaterialId } = useContext(LMContext) || {
    learningMaterialId: null,
  };
  const { learningMaterialState } = useContext(LearningMaterialsContext) || {
    learningMaterialState: null,
  };

  // Predefined bug options
  const bugOptions = [
    "The interface is not responding",
    "I encountered an error message",
    "My progress was not saved",
    "Something doesn't look right",
    "The content has a mistake",
    "Something else is wrong",
  ];

  // Open feedback panel
  const openPanel = () => {
    setIsPanelOpen(true);
    resetForm();
  };

  // Close feedback panel
  const closePanel = () => {
    setIsPanelOpen(false);
    setFeedbackType(null);
    setBugStep(1);
    resetForm();
  };

  // Reset form data
  const resetForm = () => {
    setBugDetails({
      activity: "",
      description: "",
      options: [],
    });
    setIdeaDetails({
      description: "",
    });
    setSubmitMessage(null);
    setScreenshotFile(null);
    setScreenshotPreview(null);
    setContactAllowed(false);
  };

  // Handle bug option selection
  const handleBugOptionChange = (option) => {
    const updatedOptions = [...bugDetails.options];
    const optionIndex = updatedOptions.indexOf(option);

    if (optionIndex === -1) {
      updatedOptions.push(option);
    } else {
      updatedOptions.splice(optionIndex, 1);
    }

    setBugDetails({
      ...bugDetails,
      options: updatedOptions,
    });
  };

  // Handle screenshot selection with file size validation
  const handleScreenshotChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size - limit to 2MB
      const fileSize = file.size;
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      if (fileSize > maxSize) {
        setSubmitMessage({
          type: "error",
          text: "Screenshot is too large. Please upload an image smaller than 2MB.",
        });
        return;
      }

      setScreenshotFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setScreenshotPreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Clear any previous error messages
      if (submitMessage && submitMessage.type === "error") {
        setSubmitMessage(null);
      }
    }
  };

  // Trigger file input click
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // Remove selected screenshot
  const removeScreenshot = () => {
    setScreenshotFile(null);
    setScreenshotPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Submit feedback
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Prepare feedback data
      const feedbackData = {
        type: feedbackType,
        userId: user?.uid || "anonymous",
        userEmail: user?.email || "anonymous",
        timestamp: serverTimestamp(),
        contactAllowed,
      };

      // Add context data if available
      if (learningMaterialId) {
        feedbackData.learningMaterialId = learningMaterialId;
      }

      if (learningMaterialState) {
        feedbackData.learningMaterialState = learningMaterialState;
      }

      // Add type-specific data
      if (feedbackType === "bug") {
        feedbackData.activity = bugDetails.activity;
        feedbackData.description = bugDetails.description;
        feedbackData.options = bugDetails.options;
      } else {
        feedbackData.description = ideaDetails.description;
      }

      // Upload screenshot if provided
      if (screenshotFile) {
        try {
          // Create a more organized path structure
          const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
          const folderPath = `user-feedback/${feedbackType}-reports`;
          const fileName = `${timestamp}-${screenshotFile.name}`;
          const screenshotRef = ref(storage, `${folderPath}/${fileName}`);

          // Upload with metadata
          const metadata = {
            contentType: screenshotFile.type,
            customMetadata: {
              userId: user?.uid || "anonymous",
              feedbackType: feedbackType,
              uploadDate: timestamp,
            },
          };

          await uploadBytes(screenshotRef, screenshotFile, metadata);
          const screenshotUrl = await getDownloadURL(screenshotRef);
          feedbackData.screenshotUrl = screenshotUrl;
          feedbackData.screenshotPath = `${folderPath}/${fileName}`;
        } catch (error) {
          console.error("Error uploading screenshot:", error);
          throw new Error(`Screenshot upload failed: ${error.message}`);
        }
      }

      // Add to Firestore
      const feedbackRef = collection(db, "feedback");
      await addDoc(feedbackRef, feedbackData);

      // Show success message
      setSubmitMessage({
        type: "success",
        text: "Thank you! Your feedback has been received!",
      });

      // Reset form after delay
      setTimeout(() => {
        closePanel();
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setSubmitMessage({
        type: "error",
        text: "An error occurred while submitting your feedback.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle bug form next step
  const handleBugNextStep = (e) => {
    e.preventDefault();
    if (bugDetails.activity.trim()) {
      setBugStep(2);
    }
  };

  // Panel animation variants
  const panelVariants = {
    closed: { x: "100%", opacity: 0 },
    open: { x: 0, opacity: 1 },
  };

  return (
    <div className="fixed z-30">
      {/* Floating Feedback Button */}
      {isPanelOpen ? null : (
        <div
          className="feedback-icon bg-white rotate-45 border drop-shadow rounded border-darkgray border-b-2 border-r-2 text-gold flex items-center justify-center w-14 h-14 fixed bottom-[10rem] right-6 cursor-pointer hover:bg-gold hover:text-white shadow-md z-30"
          onClick={openPanel}
          title="Feedback"
        >
          <span className="text-2xl -rotate-45 text-outline-darkgray">💡</span>
        </div>
      )}

      {/* Feedback Panel */}
      <AnimatePresence>
        {isPanelOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={panelVariants}
            transition={{ type: "spring", stiffness: 350, damping: 30 }}
            className="fixed inset-y-0 right-0 bg-white border-l-2 border-darkgray shadow-lg w-full sm:w-96 z-40 overflow-y-auto"
          >
            {/* Panel Header */}
            <div className="flex items-center justify-between border-b-2 border-darkgray p-4">
              <button
                onClick={closePanel}
                className="text-gray hover:text-red-600 p-2"
              >
                &times;
              </button>
              <h2 className="text-xl font-bold text-center flex-grow">
                {feedbackType === "bug"
                  ? "Report an issue"
                  : feedbackType === "idea"
                  ? "Suggest an idea"
                  : "Send feedback"}
              </h2>
              <div className="w-8"></div> {/* Spacer for balance */}
            </div>

            {/* Panel Content */}
            <div className="p-6">
              {submitMessage ? (
                /* Success/Error Message */
                <div
                  className={`text-center p-4 rounded border-2 border-b-4 ${
                    submitMessage.type === "success"
                      ? "bg-green bg-opacity-20 text-green"
                      : "bg-red-100 text-red-600"
                  }`}
                >
                  <p className="font-bold text-lg mb-2">
                    {submitMessage.type === "success" ? "✅" : "❌"}
                  </p>
                  <p>{submitMessage.text}</p>
                </div>
              ) : feedbackType === null ? (
                /* Feedback Type Selection */
                <div className="space-y-6">
                  <p className="text-center mb-6">What would you like to do?</p>

                  <button
                    onClick={() => setFeedbackType("bug")}
                    className="w-full flex items-center p-4 border-2 border-darkgray  border-b-4 hover:border-red rounded mb-4"
                  >
                    <span className="mr-3 text-2xl">🐞</span>
                    <div className="text-left">
                      <h3 className="font-bold">Report an issue</h3>
                      <p className="text-sm text-gray-600">
                        Tell us what's not working
                      </p>
                    </div>
                  </button>

                  <button
                    onClick={() => setFeedbackType("idea")}
                    className="w-full flex items-center p-4 border-2 border-darkgray border-b-4 hover:border-green rounded"
                  >
                    <span className="mr-3 text-2xl">💡</span>
                    <div className="text-left">
                      <h3 className="font-bold">Suggest an idea</h3>
                      <p className="text-sm text-gray-600">
                        Help us improve the product
                      </p>
                    </div>
                  </button>

                  {user?.email === "peterkamlongera@gmail.com" ? (
                    <Link to="/admin-feedback-dashboard" target="_blank">
                      <button className="w-full flex items-center p-4 border-2 mt-4 border-darkgray border-b-4 hover:border-green rounded">
                        <span className="mr-3 text-2xl">👷🏾‍♂️</span>
                        <div className="text-left">
                          <h3 className="font-bold">Admin Dashboard</h3>
                          <p className="text-sm text-gray-600">
                            Diagnostics & Insights Panel
                          </p>
                        </div>
                      </button>
                    </Link>
                  ) : null}

                  {/* Legacy Dialogues Access Button - Only for admin/coach roles */}
                  {(isAdmin || isCoach) && (
                    <Link to="/legacy">
                      <button className="w-full flex items-center p-4 border-2 mt-4 border-darkgray border-b-4 hover:border-gold rounded">
                        <span className="mr-3 text-2xl">📚</span>
                        <div className="text-left">
                          <h3 className="font-bold">Legacy Dialogues</h3>
                          <p className="text-sm text-gray-600">
                            Access archived dialogue content
                          </p>
                        </div>
                      </button>
                    </Link>
                  )}
                </div>
              ) : feedbackType === "bug" ? (
                /* Bug Report Form */
                <form
                  onSubmit={bugStep === 1 ? handleBugNextStep : handleSubmit}
                >
                  {bugStep === 1 ? (
                    /* Bug Step 1 - What were you doing */
                    <div className="space-y-4">
                      <h3 className="font-bold mb-2">
                        When you noticed this issue, what were you trying to do?
                      </h3>
                      <textarea
                        value={bugDetails.activity}
                        onChange={(e) =>
                          setBugDetails({
                            ...bugDetails,
                            activity: e.target.value,
                          })
                        }
                        className="w-full border-2 border-b-4 outline-none border-darkgray p-3 rounded h-32"
                        placeholder="I was trying to..."
                        required
                      />
                      <div className="flex justify-between mt-4">
                        <button
                          type="button"
                          onClick={() => setFeedbackType(null)}
                          className="px-4 py-2 border-2 border-b-4 text-gray border-darkgray rounded"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 border-b-4 border-2 border-gold text-gold rounded"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  ) : (
                    /* Bug Step 2 - Details */
                    <div className="space-y-4">
                      <h3 className="font-bold mb-2">
                        Describe the issue you're experiencing
                      </h3>
                      <textarea
                        value={bugDetails.description}
                        onChange={(e) =>
                          setBugDetails({
                            ...bugDetails,
                            description: e.target.value,
                          })
                        }
                        className="w-full border-2 border-b-4 outline-none border-darkgray p-3 rounded h-24"
                        placeholder="Tell us what happened and what's not working..."
                        required
                      />

                      <div className="space-y-2">
                        <p className="font-medium">Select all that apply:</p>
                        <div className="space-y-2 border-2 border-b-4 border-darkgray p-3 rounded">
                          {bugOptions.map((option, index) => (
                            <div key={index} className="flex items-start">
                              <input
                                type="checkbox"
                                id={`bug-option-${index}`}
                                checked={bugDetails.options.includes(option)}
                                onChange={() => handleBugOptionChange(option)}
                                className="mt-1"
                              />
                              <label
                                htmlFor={`bug-option-${index}`}
                                className="ml-2"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Screenshot uploader */}
                      <div className="mt-4">
                        <p className="font-medium mb-2">
                          Add a screenshot (optional) [2MB limit]
                        </p>
                        <input
                          type="file"
                          ref={fileInputRef}
                          accept="image/*"
                          onChange={handleScreenshotChange}
                          className="hidden"
                        />

                        {screenshotPreview ? (
                          <div className="relative border-2 border-b-4 border-darkgray p-2 rounded">
                            <img
                              src={screenshotPreview}
                              alt="Screenshot preview"
                              className="max-h-40 mx-auto"
                            />
                            <button
                              type="button"
                              onClick={removeScreenshot}
                              className="absolute top-2 right-2 bg-white rounded-full w-6 h-6 flex items-center justify-center shadow border border-darkgray text-red"
                            >
                              &times;
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={triggerFileInput}
                            className="w-full border-2 border-darkgray border-b-4 border-dashed p-4 rounded text-center hover:bg-gray-50"
                          >
                            📷 Upload screenshot
                          </button>
                        )}
                      </div>

                      {/* Contact permission */}
                      <div className="flex items-start mt-4">
                        <input
                          type="checkbox"
                          id="contact-permission"
                          checked={contactAllowed}
                          onChange={(e) => setContactAllowed(e.target.checked)}
                          className="mt-1"
                        />
                        <label
                          htmlFor="contact-permission"
                          className="ml-2 text-sm"
                        >
                          We may email you for more information or updates
                        </label>
                      </div>

                      {/* Privacy notice */}
                      <p className="text-xs text-gray-500 mt-2">
                        Some account and system information may be sent with
                        your feedback. We'll use it to fix problems and improve
                        our services.
                      </p>

                      {/* Form actions */}
                      <div className="flex justify-between mt-4">
                        <button
                          type="button"
                          onClick={() => setBugStep(1)}
                          className="px-4 py-2 border-2 border-b-4 text-gray border-darkgray rounded"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          disabled={loading}
                          className="px-4 py-2 border-green text-green border-2 border-b-4 rounded disabled:border-darkgray disabled:text-darkgray disabled:bg-gray"
                        >
                          {loading ? "Sending..." : "Send"}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              ) : (
                /* Idea Suggestion Form */
                <form onSubmit={handleSubmit}>
                  <h3 className="font-bold mb-2">Describe your suggestion</h3>
                  <textarea
                    value={ideaDetails.description}
                    onChange={(e) =>
                      setIdeaDetails({
                        ...ideaDetails,
                        description: e.target.value,
                      })
                    }
                    className="w-full border-2 border-b-4 border-darkgray p-3 rounded outline-none h-32"
                    placeholder="Tell us how we can improve our product..."
                    required
                  />

                  {/* Screenshot uploader */}
                  <div className="mt-4">
                    <p className="font-medium mb-2">
                      Add a screenshot (optional)
                    </p>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      onChange={handleScreenshotChange}
                      className="hidden"
                    />

                    {screenshotPreview ? (
                      <div className="relative border-2 border-b-4 border-darkgray p-2 rounded">
                        <img
                          src={screenshotPreview}
                          alt="Screenshot preview"
                          className="max-h-40 mx-auto"
                        />
                        <button
                          type="button"
                          onClick={removeScreenshot}
                          className="absolute top-2 right-2 bg-white rounded-full w-6 h-6 flex items-center justify-center shadow border border-darkgray text-red"
                        >
                          &times;
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={triggerFileInput}
                        className="w-full border-2 border-darkgray border-b-4 border-dashed p-4 rounded text-center hover:bg-gray-50"
                      >
                        📷 Upload screenshot
                      </button>
                    )}
                  </div>

                  {/* Contact permission */}
                  <div className="flex items-start mt-4">
                    <input
                      type="checkbox"
                      id="contact-permission-idea"
                      checked={contactAllowed}
                      onChange={(e) => setContactAllowed(e.target.checked)}
                      className="mt-1"
                    />
                    <label
                      htmlFor="contact-permission-idea"
                      className="ml-2 text-sm"
                    >
                      We may email you for more information or updates
                    </label>
                  </div>

                  {/* Privacy notice */}
                  <p className="text-xs text-gray-500 mt-2">
                    Some account and system information may be sent with your
                    feedback. We'll use it to fix problems and improve our
                    services.
                  </p>

                  {/* Form actions */}
                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={() => setFeedbackType(null)}
                      className="px-4 py-2 border-2 border-b-4 text-gray border-darkgray rounded"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className="px-4 py-2 border-green border-2 border-b-4 text-green rounded disabled:border-darkgray disabled:text-darkgray disabled:bg-gray"
                    >
                      {loading ? "Sending..." : "Send"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FeedbackMenu;
