// services/power-up-utils.js
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";

/**
 * Utility functions to apply power-ups to core app functionality
 */
class PowerUpUtils {
  /**
   * Calculate XP with active boosters applied
   * @param {string} userId - User ID
   * @param {number} baseXp - Base XP amount before multipliers
   * @returns {Promise<{xp: number, multiplier: number, hasBoost: boolean}>}
   */
  static async calculateBoostedXP(userId, baseXp = 0) {
    if (!userId || baseXp <= 0) {
      return { xp: baseXp, multiplier: 1, hasBoost: false };
    }

    try {
      // Get user data to check for active XP booster
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { xp: baseXp, multiplier: 1, hasBoost: false };
      }

      const userData = userSnap.data();

      // Check if user has active XP boost
      if (userData.xpMultiplier > 1 && userData.xpBoostExpiresAt) {
        const expiryDate =
          userData.xpBoostExpiresAt instanceof Date
            ? userData.xpBoostExpiresAt
            : userData.xpBoostExpiresAt.toDate
            ? userData.xpBoostExpiresAt.toDate()
            : new Date(userData.xpBoostExpiresAt);

        const now = new Date();

        // Apply multiplier if boost is active
        if (expiryDate > now) {
          const multiplier = userData.xpMultiplier;
          const boostedXp = Math.round(baseXp * multiplier);

          return {
            xp: boostedXp,
            multiplier: multiplier,
            hasBoost: true,
            expiresAt: expiryDate,
          };
        }
      }

      // No active boost found or boost expired
      return { xp: baseXp, multiplier: 1, hasBoost: false };
    } catch (error) {
      console.error("Error calculating boosted XP:", error);
      // Return default values in case of error
      return { xp: baseXp, multiplier: 1, hasBoost: false };
    }
  }

  /**
   * Check if a user's streak is protected by a streak freeze
   * @param {string} userId - User ID
   * @returns {Promise<{isProtected: boolean}>}
   */
  static async checkStreakProtection(userId) {
    if (!userId) return { isProtected: false };

    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        return { isProtected: false };
      }

      const userData = userSnap.data();

      // Check if streak freeze is active for today
      if (userData.streakFreezeApplied) {
        const freezeDate =
          userData.streakFreezeApplied instanceof Date
            ? userData.streakFreezeApplied
            : userData.streakFreezeApplied.toDate
            ? userData.streakFreezeApplied.toDate()
            : new Date(userData.streakFreezeApplied);

        const now = new Date();
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        const freezeDay = new Date(
          freezeDate.getFullYear(),
          freezeDate.getMonth(),
          freezeDate.getDate()
        );

        // Return true if the freeze is applied for today
        if (freezeDay.getTime() === today.getTime()) {
          return {
            isProtected: true,
            source: "streak_freeze",
            expiresAt: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              23,
              59,
              59,
              999
            ),
          };
        }
      }

      // No active streak protection
      return { isProtected: false };
    } catch (error) {
      console.error("Error checking streak protection:", error);
      return { isProtected: false };
    }
  }

  /**
   * Get all active power-ups for a user (wrapper around PowerUpManager)
   * @param {string} userId - User ID
   * @returns {Promise<Array>} - Array of active power-ups
   */
  static async getActivePowerUps(userId) {
    try {
      // We'll need to import PowerUpManager dynamically to avoid circular dependencies
      const PowerUpManager = (await import("./power-up-manager")).default;
      return await PowerUpManager.getActivePowerUps(userId);
    } catch (error) {
      console.error("Error getting active power-ups:", error);
      return [];
    }
  }
}

export default PowerUpUtils;
