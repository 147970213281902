// firebase/shop-api.js
// Client-side code to call Cloud Functions via HTTP endpoints

import { getAuth } from "firebase/auth";
import { app } from "./firebase.utils";

// Cloud Function URL - update this with your actual deployed function URL
const CLOUD_FUNCTION_URL = "https://shophandler-fmxsydetiq-uc.a.run.app";

// API endpoints
const PURCHASE_PRODUCT_URL = `${CLOUD_FUNCTION_URL}/purchaseProduct`;
const USE_INVENTORY_ITEM_URL = `${CLOUD_FUNCTION_URL}/useInventoryItem`;
const ADD_COINS_URL = `${CLOUD_FUNCTION_URL}/addCoins`;
const GET_PURCHASE_HISTORY_URL = `${CLOUD_FUNCTION_URL}/getPurchaseHistory`;
const GET_USER_PROFILE_URL = `${CLOUD_FUNCTION_URL}/getUserProfile`;
const UPDATE_USER_PROFILE_URL = `${CLOUD_FUNCTION_URL}/updateUserProfile`;

/**
 * Base function to call a Cloud Function HTTP endpoint
 * @param {string} url - The Cloud Function URL
 * @param {string} method - HTTP method (GET or POST)
 * @param {object|null} data - Data to send (for POST requests)
 * @returns {Promise<object>} - Response from the Cloud Function
 */
const callCloudFunction = async (url, method = "GET", data = null) => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  if (!user) {
    throw new Error("Authentication failure - User not authenticated");
  }

  try {
    // Get fresh ID token
    const idToken = await user.getIdToken(true);

    // Prepare request options
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    };

    // Add body for POST requests
    if (method === "POST" && data) {
      requestOptions.body = JSON.stringify(data);
    }

    // Make the request
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      // Try to parse error message from response
      try {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error ${response.status}`);
      } catch (jsonError) {
        // If error response isn't JSON, use status text
        throw new Error(
          `HTTP error ${response.status} - ${response.statusText}`
        );
      }
    }

    return await response.json();
  } catch (error) {
    console.error(`Cloud function call failed: ${url}`, error);
    throw error;
  }
};

/**
 * Purchase a product
 * @param {string} productId - ID of product to purchase
 * @returns {Promise<object>} - Response with purchase result
 */
export const purchaseProduct = async (productId) => {
  return await callCloudFunction(PURCHASE_PRODUCT_URL, "POST", { productId });
};

/**
 * Use an inventory item
 * @param {string} inventoryItemId - ID of inventory item to use
 * @returns {Promise<object>} - Response with usage result
 */
export const useInventoryItem = async (inventoryItemId) => {
  return await callCloudFunction(USE_INVENTORY_ITEM_URL, "POST", {
    inventoryItemId,
  });
};

/**
 * Add coins to user balance
 * @param {number} amount - Amount of coins to add
 * @param {string} source - Source of coins (e.g., 'reward', 'daily')
 * @returns {Promise<object>} - Response with result
 */
export const addCoins = async (amount, source = "reward") => {
  return await callCloudFunction(ADD_COINS_URL, "POST", { amount, source });
};

/**
 * Get user's purchase history
 * @returns {Promise<object>} - Response with purchase history
 */
export const getPurchaseHistory = async () => {
  return await callCloudFunction(GET_PURCHASE_HISTORY_URL, "GET");
};

/**
 * Get user profile data
 * @returns {Promise<object>} - Response with user profile
 */
export const getUserProfile = async () => {
  return await callCloudFunction(GET_USER_PROFILE_URL, "GET");
};

/**
 * Update user profile data
 * @param {object} profileData - Profile data to update (e.g., displayName, avatar)
 * @returns {Promise<object>} - Response with update result
 */
export const updateUserProfile = async (profileData) => {
  return await callCloudFunction(UPDATE_USER_PROFILE_URL, "POST", profileData);
};
