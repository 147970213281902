// src/helpers/AudioSettingsContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.utils";
import { useAuth } from "./UserAuthContextProvider";

// Create context
const AudioSettingsContext = createContext();

// Create provider component
export const AudioSettingsProvider = ({ children }) => {
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  // Load audio settings from user profile when authenticated
  useEffect(() => {
    const loadAudioSettings = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          // If audioEnabled setting exists, use it, otherwise default to true
          const userSettings = userDoc.data();
          const savedAudioSetting = userSettings.audioEnabled;

          if (savedAudioSetting !== undefined) {
            setAudioEnabled(savedAudioSetting);
          } else {
            // Initialize to true if not set
            await updateDoc(userDocRef, { audioEnabled: true });
          }
        }
      } catch (error) {
        console.error("Error loading audio settings:", error);
        // Default to enabled if there's an error
        setAudioEnabled(true);
      } finally {
        setLoading(false);
      }
    };

    loadAudioSettings();
  }, [user]);

  // Toggle audio enabled state and persist to user profile
  const toggleAudio = async () => {
    const newValue = !audioEnabled;
    setAudioEnabled(newValue);

    // Persist setting to user profile if authenticated
    if (user) {
      try {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, { audioEnabled: newValue });
        console.log("Audio setting updated:", newValue);
      } catch (error) {
        console.error("Error saving audio setting:", error);
      }
    }
  };

  // Check if audio should play (considering global setting)
  const shouldPlayAudio = () => {
    return audioEnabled;
  };

  // Mute/unmute any active HTMLAudioElement
  const applyAudioSetting = (audioElement) => {
    if (audioElement && audioElement instanceof HTMLAudioElement) {
      audioElement.muted = !audioEnabled;
    }
  };

  return (
    <AudioSettingsContext.Provider
      value={{
        audioEnabled,
        toggleAudio,
        shouldPlayAudio,
        applyAudioSetting,
        loading,
      }}
    >
      {children}
    </AudioSettingsContext.Provider>
  );
};

// Create custom hook for using the context
export const useAudioSettings = () => {
  const context = useContext(AudioSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useAudioSettings must be used within an AudioSettingsProvider"
    );
  }
  return context;
};

export default AudioSettingsContext;
