import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const AudioVisualizer = ({ isPlaying = true }) => {
  // Animation values for the bars
  const barCount = 5;
  const bars = Array.from({ length: barCount });

  return (
    <motion.div
      className="audio-visualizer flex items-center justify-center my-3"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex items-center gap-3">
        {/* Audio wave bars */}
        <div className="flex items-end h-6 gap-1">
          {bars.map((_, i) => (
            <motion.div
              key={i}
              className="bg-green w-1.5 rounded-full"
              initial={{ height: 5 }}
              animate={
                isPlaying
                  ? {
                      height: [5, 15, 8, 20, 5],
                      transition: {
                        duration: 1.2,
                        repeat: Infinity,
                        repeatType: "reverse",
                        delay: i * 0.1,
                      },
                    }
                  : { height: 5 }
              }
            />
          ))}
        </div>

        {/* Text indicator */}
        <p className="text-sm text-gray-600 italic">
          {isPlaying ? "Listening..." : "Audio complete"}
        </p>
      </div>
    </motion.div>
  );
};

AudioVisualizer.propTypes = {
  isPlaying: PropTypes.bool,
};

export default AudioVisualizer;
