import { useMemo } from "react";

const useChartStyles = () => {
  // Return consistent styles for all charts
  return useMemo(
    () => ({
      // Chart container styling
      chartContainer:
        "bg-white p-4 rounded-lg border-2 border-b-4 border-darkgray shadow-md hover:shadow-lg transition-shadow duration-300",

      // Chart title styling
      chartTitle:
        "text-lg font-headers font-semibold text-black mb-3 border-b border-darkgray pb-2",

      // Chart height classes
      chartHeight: "h-64 md:h-72",
      tallChartHeight: "h-72 md:h-80",

      // Empty state styling
      emptyState:
        "text-sm text-red italic mb-2 bg-redthree bg-opacity-20 p-2 rounded",

      // Color schemes for charts
      colors: {
        // Primary color set
        primary: {
          main: "#848717", // green
          secondary: "#989B49", // greenthree
          tertiary: "#52540E", // greentwo
          accent: "#d19a10", // gold
        },
        // Secondary color set
        secondary: {
          main: "#991616", // red
          secondary: "#660E0E", // redthree
          tertiary: "#FFAC8A", // redtwo
          accent: "#d19a10", // gold
        },
        // For charts needing more colors
        extended: [
          "#848717", // green
          "#991616", // red
          "#d19a10", // gold
          "#989B49", // greenthree
          "#FFAC8A", // redtwo
          "#52540E", // greentwo
          "#660E0E", // redthree
        ],
      },

      // Chart-specific configurations
      lineChart: {
        strokeWidth: 2,
        activeDotRadius: 6,
        gridStyle: "3 3",
      },
      barChart: {
        barSize: 25,
      },
      pieChart: {
        outerRadius: "80%",
        innerRadius: "0%",
        paddingAngle: 2,
      },
      radarChart: {
        fillOpacity: 0.6,
        polarGridLines: 5,
      },
    }),
    []
  );
};

export default useChartStyles;
