// ZoomFixTool.jsx - Add this as a new component
import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import { createZoomMeetingForBooking } from "../../../firebase/bookings-api";

const ZoomFixTool = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetchBookingsWithoutMeetings();
  }, []);

  const fetchBookingsWithoutMeetings = async () => {
    try {
      setLoading(true);
      setStatus("Fetching bookings without meeting links...");

      // Query for upcoming bookings without meeting links
      const bookingsRef = collection(db, "bookings");
      const q = query(
        bookingsRef,
        where("status", "==", "upcoming"),
        where("meetingLink", "==", null)
      );

      const querySnapshot = await getDocs(q);
      const bookingsList = [];

      querySnapshot.forEach((doc) => {
        bookingsList.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setBookings(bookingsList);
      setStatus(`Found ${bookingsList.length} bookings without meeting links.`);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setStatus(`Error: ${error.message}`);
      setLoading(false);
    }
  };

  const createAllMeetings = async () => {
    if (bookings.length === 0) return;

    setProcessing(true);
    setStatus("Processing all bookings...");

    const results = {
      success: 0,
      failed: 0,
    };

    for (const booking of bookings) {
      try {
        setStatus(`Creating meeting for booking ${booking.id}...`);
        await createMeetingForBooking(booking.id);
        results.success++;
      } catch (error) {
        console.error(
          `Failed to create meeting for booking ${booking.id}:`,
          error
        );
        results.failed++;
      }
    }

    setStatus(
      `Completed! Success: ${results.success}, Failed: ${results.failed}`
    );
    setProcessing(false);
    fetchBookingsWithoutMeetings(); // Refresh the list
  };

  const createMeetingForBooking = async (bookingId) => {
    try {
      // First try to use the cloud function
      const result = await createZoomMeetingForBooking(bookingId);
      return result;
    } catch (error) {
      console.error(`Error creating meeting via API for ${bookingId}:`, error);

      // Fallback: Manual update with placeholder data
      const bookingRef = doc(db, "bookings", bookingId);
      await updateDoc(bookingRef, {
        meetingLink: `https://zoom.us/placeholder-${bookingId}`,
        meetingPassword: "placeholder",
        updatedAt: new Date(),
      });

      return { success: true, message: "Created placeholder meeting" };
    }
  };

  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Zoom Meeting Fix Tool</h1>
      <p className="mb-4">
        This tool finds bookings without Zoom meetings and creates them.
      </p>

      {/* Status and controls */}
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <div className="mb-4">Status: {status}</div>

        <div className="flex space-x-4">
          <button
            onClick={fetchBookingsWithoutMeetings}
            disabled={loading || processing}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
          >
            Refresh Bookings
          </button>

          <button
            onClick={createAllMeetings}
            disabled={loading || processing || bookings.length === 0}
            className="px-4 py-2 bg-green text-white rounded disabled:opacity-50"
          >
            Fix All Bookings
          </button>
        </div>
      </div>

      {/* Bookings list */}
      <div className="border rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Booking ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Student
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Coach
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {bookings.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                  {loading
                    ? "Loading bookings..."
                    : "No bookings found without meeting links."}
                </td>
              </tr>
            ) : (
              bookings.map((booking) => (
                <tr key={booking.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {booking.id.slice(0, 8)}...
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {booking.studentName || "Unknown Student"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {booking.coachName || "Unknown Coach"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {booking.sessionDateTime?.toDate().toLocaleString() ||
                      "Unknown Date"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => createMeetingForBooking(booking.id)}
                      disabled={processing}
                      className="text-green hover:text-green-700 disabled:opacity-50"
                    >
                      Fix Meeting
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ZoomFixTool;
