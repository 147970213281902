import { db } from "../../firebase/firebase.utils";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  updateDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  documentId,
} from "firebase/firestore";

/**
 * Adapter service to normalize data fetching from Firestore
 * for the flashcard feature, handling different data structures
 */
export class FlashcardService {
  /**
   * Fetches all available flashcard decks, handling different data structures
   * @param {string} userId - User ID for custom decks
   * @returns {Array} - Array of normalized deck objects
   */
  static async getFlashcardDecks(userId = null) {
    // console.log("Fetching flashcard decks...");

    try {
      // Public decks query - try different possible structures
      const publicDecks = await this._fetchPublicDecks();

      // Custom decks query (if user is logged in)
      const customDecks = userId ? await this._fetchCustomDecks(userId) : [];

      // console.log(
      //   `Found ${publicDecks.length} public decks and ${customDecks.length} custom decks`
      // );

      return {
        publicDecks,
        customDecks,
      };
    } catch (error) {
      console.error("Error fetching flashcard decks:", error);
      throw error;
    }
  }

  /**
   * Get details of a specific deck by ID
   * @param {string} deckId - The ID of the deck
   * @returns {Promise<Object|null>} - Deck object or null if not found
   */
  static async getDeckById(deckId) {
    try {
      console.log(`Getting details for deck "${deckId}"`);

      const deckRef = doc(db, "flashcard_decks", deckId);
      const deckDoc = await getDoc(deckRef);

      if (!deckDoc.exists()) {
        console.log(`Deck "${deckId}" not found`);
        return null;
      }

      return {
        id: deckDoc.id,
        ...deckDoc.data(),
      };
    } catch (error) {
      console.error(`Error getting deck details for "${deckId}":`, error);
      throw error;
    }
  }

  /**
   * Get progress for a specific user/flashcard
   * @param {string} userId - Firebase auth user ID
   * @param {string} deckId - Deck ID
   * @param {string} cardId - Flashcard ID (optional)
   * @returns {Promise<Object>} - User's progress for this card or deck
   */
  static async getUserFlashcardProgress(userId, deckId, cardId = null) {
    try {
      console.log(
        `Getting progress for user ${userId}, deck ${deckId}${
          cardId ? `, card ${cardId}` : ""
        }`
      );

      if (!userId) {
        console.log("No user ID provided, returning empty progress");
        return {};
      }

      // Get the user's progress document
      const userProgressRef = doc(db, "user_progress", userId);
      const userProgressDoc = await getDoc(userProgressRef);

      if (!userProgressDoc.exists()) {
        console.log(`No progress data found for user ${userId}`);
        return {};
      }

      const progressData = userProgressDoc.data();

      // If specific card ID is provided, return just that card's progress
      if (cardId) {
        const cardProgress = progressData.cards?.[deckId]?.[cardId] || {
          knowledgeLevel: 0,
          lastStudied: null,
        };
        return cardProgress;
      }

      // Otherwise return progress for all cards in the deck
      const deckProgress = progressData.cards?.[deckId] || {};
      return deckProgress;
    } catch (error) {
      console.error("Error getting user flashcard progress:", error);
      return {};
    }
  }

  /**
   * Fetches flashcards for a specific deck, handling different data structures
   * @param {string} deckId - Deck ID
   * @returns {Array} - Array of normalized flashcard objects
   */
  static async getFlashcards(deckId) {
    // console.log(`Fetching flashcards for deck: ${deckId}`);

    try {
      // Try the standard structure first
      let flashcardsQuery = query(
        collection(db, "flashcards"),
        where("deckId", "==", deckId)
      );

      let snapshot = await getDocs(flashcardsQuery);

      // If no results, try alternative field name
      if (snapshot.empty) {
        // console.log("No cards found with deckId field, trying deck field...");
        flashcardsQuery = query(
          collection(db, "flashcards"),
          where("deck", "==", deckId)
        );
        snapshot = await getDocs(flashcardsQuery);
      }

      // If still no results, try subcollection approach
      if (snapshot.empty) {
        // console.log("Trying subcollection approach...");
        try {
          const subcollectionPath = `flashcard_decks/${deckId}/cards`;
          flashcardsQuery = query(collection(db, subcollectionPath));
          snapshot = await getDocs(flashcardsQuery);
        } catch (error) {
          // console.log("Subcollection approach failed:", error);
        }
      }

      // If we found cards, normalize them
      if (!snapshot.empty) {
        const cards = snapshot.docs.map((doc) =>
          this._normalizeFlashcard(doc.id, doc.data())
        );
        // console.log(`Found ${cards.length} flashcards for deck ${deckId}`);
        return cards;
      }

      // console.log(`No flashcards found for deck ${deckId}`);
      return [];
    } catch (error) {
      console.error(`Error fetching flashcards for deck ${deckId}:`, error);
      throw error;
    }
  }

  /**
   * Fetches user progress for flashcard decks
   * @param {string} userId - User ID
   * @param {Array} deckIds - Array of deck IDs
   * @returns {Object} - Object mapping deck IDs to progress data
   */
  static async getUserProgress(userId, deckIds) {
    if (!userId || !deckIds.length) return {};

    const progressData = {};

    for (const deckId of deckIds) {
      try {
        const progressRef = doc(
          db,
          "users",
          userId,
          "flashcardProgress",
          deckId
        );
        const progressSnap = await getDoc(progressRef);

        if (progressSnap.exists()) {
          progressData[deckId] = progressSnap.data().cards || {};
        } else {
          // Initialize empty progress object
          progressData[deckId] = {};
        }
      } catch (error) {
        console.error(`Error fetching progress for deck ${deckId}:`, error);
        progressData[deckId] = {};
      }
    }

    return progressData;
  }

  /**
   * Get all flashcards for a specific deck by checking the deckId field
   * @param {string} deckId - The ID of the deck
   * @returns {Promise<Array>} - Array of flashcard objects
   */
  async getFlashcards(deckId) {
    try {
      console.log(`Getting flashcards for deck "${deckId}" using deckId field`);

      // Query for flashcards with matching deckId field
      const flashcardsQuery = query(
        collection(db, "flashcards"),
        where("deckId", "==", deckId),
        orderBy("order", "asc")
      );

      const snapshot = await getDocs(flashcardsQuery);

      console.log(
        `Found ${snapshot.docs.length} flashcards with deckId = "${deckId}"`
      );

      if (snapshot.empty) {
        return [];
      }

      const cards = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return cards;
    } catch (error) {
      console.error("Error getting flashcards by deckId field:", error);
      throw error;
    }
  }

  /**
   * Get all flashcards for a specific deck by checking the document ID pattern
   * @param {string} deckId - The ID prefix to search for
   * @returns {Promise<Array>} - Array of flashcard objects
   */
  async getFlashcardsByDocumentIdPattern(deckId) {
    try {
      console.log(`Getting flashcards with document ID pattern "${deckId}-*"`);

      // Use the documentId() function from Firebase v9
      const flashcardsQuery = query(
        collection(db, "flashcards"),
        where(documentId(), ">=", `${deckId}-`),
        where(documentId(), "<", `${deckId}.`)
      );

      const snapshot = await getDocs(flashcardsQuery);

      console.log(
        `Found ${snapshot.docs.length} flashcards with document ID pattern "${deckId}-*"`
      );

      if (snapshot.empty) {
        return [];
      }

      const cards = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort by order if available, otherwise by document ID
      return cards.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        return a.id.localeCompare(b.id);
      });
    } catch (error) {
      console.error("Error getting flashcards by document ID pattern:", error);
      throw error;
    }
  }

  /**
   * Get details of a specific deck by ID
   * @param {string} deckId - The ID of the deck
   * @returns {Promise<Object|null>} - Deck object or null if not found
   */
  async getDeckById(deckId) {
    try {
      console.log(`Getting details for deck "${deckId}"`);

      const deckRef = doc(db, "flashcard_decks", deckId);
      const deckDoc = await getDoc(deckRef);

      if (!deckDoc.exists()) {
        console.log(`Deck "${deckId}" not found`);
        return null;
      }

      return {
        id: deckDoc.id,
        ...deckDoc.data(),
      };
    } catch (error) {
      console.error(`Error getting deck details for "${deckId}":`, error);
      throw error;
    }
  }

  /**
   * Updates user progress for a flashcard
   * @param {string} userId - User ID
   * @param {string} deckId - Deck ID
   * @param {string} cardId - Card ID
   * @param {Object} progressData - Progress data to update
   */
  static async updateCardProgress(userId, deckId, cardId, progressData) {
    if (!userId) return;

    try {
      const userCardRef = doc(db, "users", userId, "flashcardProgress", deckId);

      // Check if document exists
      const docSnap = await getDoc(userCardRef);

      if (docSnap.exists()) {
        // Update existing document
        await updateDoc(userCardRef, {
          [`cards.${cardId}`]: {
            ...progressData,
            lastUpdated: serverTimestamp(),
          },
          lastStudied: serverTimestamp(),
        });
      } else {
        // Create new document with initial structure
        await updateDoc(
          userCardRef,
          {
            deckId,
            lastStudied: serverTimestamp(),
            cards: {
              [cardId]: {
                ...progressData,
                lastUpdated: serverTimestamp(),
              },
            },
          },
          { merge: true }
        );
      }
    } catch (error) {
      console.error(`Error updating progress for card ${cardId}:`, error);
      throw error;
    }
  }

  /**
   * Updates progress for a specific flashcard
   * @param {string} userId - Firebase auth user ID
   * @param {string} deckId - Deck ID
   * @param {string} cardId - Card ID
   * @param {number} knowledgeLevel - Knowledge level (0-3)
   * @returns {Promise<void>}
   */
  static async updateFlashcardProgress(userId, deckId, cardId, knowledgeLevel) {
    if (!userId) return;

    try {
      console.log(
        `Updating progress for user ${userId}, deck ${deckId}, card ${cardId}, level ${knowledgeLevel}`
      );

      // Create progress data object
      const progressData = {
        knowledgeLevel,
        lastStudied: new Date().toISOString(),
      };

      // Reference to the user's flashcard progress document
      const userCardRef = doc(db, "users", userId, "flashcardProgress", deckId);

      // Check if document exists
      const docSnap = await getDoc(userCardRef);

      if (docSnap.exists()) {
        // Update existing document
        await updateDoc(userCardRef, {
          [`cards.${cardId}`]: {
            ...progressData,
            lastUpdated: serverTimestamp(),
          },
          lastStudied: serverTimestamp(),
        });
        console.log(`Updated existing progress document for deck ${deckId}`);
      } else {
        // Create new document with setDoc and merge option
        await setDoc(
          userCardRef,
          {
            deckId,
            lastStudied: serverTimestamp(),
            cards: {
              [cardId]: {
                ...progressData,
                lastUpdated: serverTimestamp(),
              },
            },
          },
          { merge: true }
        );
        console.log(`Created new progress document for deck ${deckId}`);
      }

      // Also update the user_progress collection for consistency
      try {
        // This is the collection used by userProgressService
        const userProgressRef = doc(db, "user_progress", userId);
        const userProgressSnap = await getDoc(userProgressRef);

        const cardPath = `decks.${deckId}.cards.${cardId}`;
        const updateObj = {
          [cardPath]: {
            knowledgeLevel,
            lastStudied: serverTimestamp(),
          },
          [`decks.${deckId}.lastStudied`]: serverTimestamp(),
          lastUpdated: serverTimestamp(),
        };

        if (userProgressSnap.exists()) {
          await updateDoc(userProgressRef, updateObj);
        } else {
          await setDoc(
            userProgressRef,
            {
              userId,
              decks: {
                [deckId]: {
                  lastStudied: serverTimestamp(),
                  masteredCards: knowledgeLevel === 3 ? 1 : 0,
                  cards: {
                    [cardId]: {
                      knowledgeLevel,
                      lastStudied: serverTimestamp(),
                    },
                  },
                },
              },
              lastUpdated: serverTimestamp(),
            },
            { merge: true }
          );
        }
      } catch (err) {
        console.error("Error updating user_progress collection:", err);
        // Continue execution even if this fails
      }
    } catch (error) {
      console.error(`Error in updateFlashcardProgress:`, error);
      throw error;
    }
  }

  /**
   * Creates a custom flashcard deck
   * @param {string} userId - User ID
   * @param {Object} deckData - Deck data
   * @param {Array} cards - Cards for the deck
   * @returns {Object} - Created deck data
   */
  static async createCustomDeck(userId, deckData, cards) {
    if (!userId) throw new Error("User ID is required");

    try {
      // Create deck document
      const deckRef = await addDoc(collection(db, "flashcard_decks"), {
        ...deckData,
        createdBy: userId,
        createdAt: serverTimestamp(),
        isCustom: true,
        cardCount: cards.length,
      });

      // Create card documents
      const deckId = deckRef.id;
      const cardPromises = cards.map((card, index) =>
        addDoc(collection(db, "flashcards"), {
          ...card,
          deckId,
          order: index,
          createdBy: userId,
          createdAt: serverTimestamp(),
        })
      );

      await Promise.all(cardPromises);

      return {
        id: deckId,
        ...deckData,
        cardCount: cards.length,
      };
    } catch (error) {
      console.error("Error creating custom deck:", error);
      throw error;
    }
  }

  // ----- Private Helper Methods -----

  /**
   * Fetches public flashcard decks
   * @returns {Array} - Array of normalized deck objects
   * @private
   */
  static async _fetchPublicDecks() {
    // Standard structure query
    const publicDecksQuery = query(
      collection(db, "flashcard_decks"),
      where("isPublic", "==", true)
    );

    const publicSnapshot = await getDocs(publicDecksQuery);

    // Map and normalize deck data
    const decks = publicSnapshot.docs
      .filter((doc) => doc.data().isCustom !== true)
      .map((doc) => this._normalizeDeck(doc.id, doc.data()));

    return decks;
  }

  /**
   * Fetches custom flashcard decks for a user
   * @param {string} userId - User ID
   * @returns {Array} - Array of normalized deck objects
   * @private
   */
  static async _fetchCustomDecks(userId) {
    const customDecksQuery = query(
      collection(db, "flashcard_decks"),
      where("createdBy", "==", userId),
      where("isCustom", "==", true)
    );

    const customSnapshot = await getDocs(customDecksQuery);

    // Map and normalize deck data
    const decks = customSnapshot.docs.map((doc) =>
      this._normalizeDeck(doc.id, doc.data(), true)
    );

    return decks;
  }

  /**
   * Normalizes deck data to ensure all required fields exist
   * @param {string} id - Document ID
   * @param {Object} data - Document data
   * @param {boolean} isCustom - Whether this is a custom deck
   * @returns {Object} - Normalized deck data
   * @private
   */
  static _normalizeDeck(id, data, isCustom = false) {
    // Extract categories/tags from various possible fields
    let tags = [];
    if (data.tags && Array.isArray(data.tags)) {
      tags = [...data.tags];
    } else if (data.category) {
      tags = [data.category];
    } else if (data.categories && Array.isArray(data.categories)) {
      tags = [...data.categories];
    }

    return {
      id,
      name: data.name || "Unnamed Deck",
      description: data.description || "",
      cardCount: data.cardCount || 0,
      difficulty: data.difficulty || (data.level ? parseInt(data.level) : 1),
      tags,
      isPublic: data.isPublic !== false,
      isCustom,
      isLocked: false, // Default to unlocked
      emoji: data.emoji || "🃏",
      language: data.language || "",
      createdAt: data.createdAt || null,
      updatedAt: data.updatedAt || null,
      createdBy: data.createdBy || null,
    };
  }

  /**
   * Normalizes flashcard data to ensure all required fields exist
   * @param {string} id - Document ID
   * @param {Object} data - Document data
   * @returns {Object} - Normalized flashcard data
   * @private
   */
  static _normalizeFlashcard(id, data) {
    return {
      id,
      targetWord: data.targetWord || "",
      translation: data.translation || "",
      hint: data.hint || "",
      example: data.example || "",
      image: data.image || "",
      audioSrc: data.audioSrc || "",
      deckId: data.deckId || data.deck || "",
      order: data.order || 0,
      createdAt: data.createdAt || null,
      updatedAt: data.updatedAt || null,
      emoji: data.emoji || "📝", // Added emoji field with default fallback
      notes: data.notes || "", // Also added notes field in case it's used
      phonetic: data.phonetic || "", // And phonetic field for pronunciation
    };
  }
}

export default FlashcardService;
