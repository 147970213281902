import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useStorageImage } from "../../../hooks/useStorageImage";
import { getAudioDownloadUrl } from "../../../utils/audio-utils";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";

function Flashcard({
  card,
  isFlipped = false,
  onFlip,
  showHint = false,
  onHintToggle,
  onPlayAudio,
  knowledgeLevel = 0,
}) {
  // Get audio settings from context
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();
  const [, setImageError] = useState(false);

  // Clean up image path - remove leading slash if present
  const imagePath = card.image ? card.image.replace(/^\//, "") : "";

  // Use the custom hook to load the image from Firebase Storage with cleaned path
  const {
    url: imageUrl,
    loading: imageLoading,
    error: imageError,
  } = useStorageImage(imagePath);

  // Add state to track when the image has fully loaded
  const [imageReady, setImageReady] = useState(false);

  // Add audio states
  const [isAudioLoading, setIsAudioLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [frontAudioUrl, setFrontAudioUrl] = useState("");
  const [backAudioUrl, setBackAudioUrl] = useState("");

  // Audio references
  const frontAudioRef = useRef(null);
  const backAudioRef = useRef(null);

  // Apply audio settings when they change
  useEffect(() => {
    if (frontAudioRef.current) {
      frontAudioRef.current.muted = !audioEnabled;
    }
    if (backAudioRef.current) {
      backAudioRef.current.muted = !audioEnabled;
    }
  }, [audioEnabled]);

  // Load audio URLs when card changes
  useEffect(() => {
    let isMounted = true;

    const loadAudio = async () => {
      setIsAudioLoading(true);

      try {
        // Load front audio (target word pronunciation)
        if (card.audioSrc) {
          const frontUrl = await getAudioDownloadUrl(card.audioSrc);
          if (isMounted) setFrontAudioUrl(frontUrl);
        }

        // Load back audio (translation pronunciation) if available
        if (card.translationAudio) {
          const backUrl = await getAudioDownloadUrl(card.translationAudio);
          if (isMounted) setBackAudioUrl(backUrl);
        }

        if (isMounted) setIsAudioLoading(false);
      } catch (error) {
        console.error("Error loading audio:", error);
        if (isMounted) setIsAudioLoading(false);
      }
    };

    loadAudio();

    return () => {
      isMounted = false;
    };
  }, [card.id, card.audioSrc, card.translationAudio]);

  // Handle image load complete
  const handleImageLoaded = () => {
    setImageReady(true);
  };

  // Reset image ready state when card changes
  useEffect(() => {
    setImageReady(false);
    setImageError(false);
  }, [card.id]);

  // Handle card flip
  const handleCardFlip = () => {
    if (onFlip) onFlip();
  };

  // Handle audio playback end
  useEffect(() => {
    const frontAudio = frontAudioRef.current;
    const backAudio = backAudioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);
    };

    if (frontAudio) {
      frontAudio.addEventListener("ended", handleEnded);
    }

    if (backAudio) {
      backAudio.addEventListener("ended", handleEnded);
    }

    return () => {
      if (frontAudio) {
        frontAudio.removeEventListener("ended", handleEnded);
      }
      if (backAudio) {
        backAudio.removeEventListener("ended", handleEnded);
      }
    };
  }, [frontAudioRef.current, backAudioRef.current]);

  // Play appropriate audio based on which side is showing
  const handlePlayAudio = (e) => {
    e.stopPropagation(); // Prevent card flip

    // Check if audio is globally disabled
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      // We'll still indicate that audio was "played" for UI feedback
      if (onPlayAudio) onPlayAudio();
      return;
    }

    const frontAudio = frontAudioRef.current;
    const backAudio = backAudioRef.current;

    // If already playing, stop
    if (isPlaying) {
      if (frontAudio) frontAudio.pause();
      if (backAudio) backAudio.pause();
      setIsPlaying(false);
      return;
    }

    // Play appropriate audio based on which side is showing
    if (isFlipped) {
      if (backAudio && backAudioUrl) {
        backAudio.currentTime = 0;
        backAudio
          .play()
          .catch((err) => console.error("Audio playback error:", err));
        setIsPlaying(true);
      }
    } else {
      if (frontAudio && frontAudioUrl) {
        frontAudio.currentTime = 0;
        frontAudio
          .play()
          .catch((err) => console.error("Audio playback error:", err));
        setIsPlaying(true);
      }
    }

    if (onPlayAudio) onPlayAudio();
  };

  // Handle hint toggle
  const handleToggleHint = (e) => {
    e.stopPropagation(); // Prevent card flip
    if (onHintToggle) onHintToggle();
  };

  // Get knowledge level color
  const getKnowledgeLevelColor = (level) => {
    switch (level) {
      case 3: // Mastered
        return "bg-green";
      case 2: // Familiar
        return "bg-gold";
      case 1: // Learning
        return "bg-gray";
      case 0: // Unknown
      default:
        return "bg-black";
    }
  };

  const knowledgeLevelColor = getKnowledgeLevelColor(knowledgeLevel);

  // Render loading state
  if (imageLoading) {
    return (
      <div className="flashcard-container w-full px-4">
        {/* Knowledge Level Indicator */}
        <div className="mb-4 flex items-center justify-center">
          <span className="mr-2 text-sm">Knowledge level:</span>
          <div className="flex space-x-1">
            {[0, 1, 2, 3].map((level) => (
              <div
                key={level}
                className={`w-3 h-3 rounded-full border border-black border-b-2 ${
                  level <= knowledgeLevel ? knowledgeLevelColor : "bg-gray-200"
                }`}
              ></div>
            ))}
          </div>
        </div>

        {/* Loading Card Placeholder */}
        <motion.div
          className="relative w-full max-w-md mx-auto h-48 sm:h-64 md:h-80 border-b-4 border-2 shadow-lg bg-white border-black rounded-xl p-4 flex flex-col items-center justify-center"
          initial={{ opacity: 0.6 }}
          animate={{ opacity: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.8,
          }}
        >
          <div className="h-24 sm:h-32 md:h-40 mb-4 flex items-center justify-center">
            <div className="animate-pulse bg-gray-200 w-24 h-24 sm:w-32 sm:h-32 rounded"></div>
          </div>
          <div className="animate-pulse bg-gray-200 h-6 sm:h-8 w-32 sm:w-40 rounded"></div>
        </motion.div>
      </div>
    );
  }

  // Helper function to render audio button with correct state
  const renderAudioButton = (side) => {
    let buttonText = "🔊";
    let buttonTooltip = "Play audio";
    let hasAudioUrl = side === "front" ? frontAudioUrl : backAudioUrl;

    if (isAudioLoading) {
      buttonText = "🔄";
      buttonTooltip = "Loading audio...";
    } else if (isPlaying) {
      buttonText = "⏸️";
      buttonTooltip = "Pause audio";
    } else if (!audioEnabled) {
      buttonText = "🔇";
      buttonTooltip = "Audio is globally disabled";
    }

    return (
      <button
        onClick={handlePlayAudio}
        title={buttonTooltip}
        className={`absolute top-2 sm:top-3 right-2 sm:right-3 w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center text-lg sm:text-xl rounded-full
          ${
            isAudioLoading
              ? "bg-gray-200 text-gray-500"
              : !audioEnabled
              ? "text-gray-400 hover:bg-gray-100"
              : isPlaying
              ? "bg-gold text-white"
              : "text-black hover:bg-gray-100"
          }`}
        disabled={isAudioLoading || (!audioEnabled && !hasAudioUrl)}
      >
        {buttonText}
      </button>
    );
  };

  // Render main card content
  return (
    <div className="flashcard-container w-full px-4">
      {/* Audio elements */}
      <audio ref={frontAudioRef} src={frontAudioUrl} />
      <audio ref={backAudioRef} src={backAudioUrl} />

      {/* Knowledge Level Indicator */}
      <div className="mb-4 flex items-center justify-center">
        <span className="mr-2 text-xs sm:text-sm">Knowledge level:</span>
        <div className="flex space-x-1">
          {[0, 1, 2, 3].map((level) => (
            <div
              key={level}
              className={`w-2 h-2 sm:w-3 sm:h-3 rounded-full border border-black border-b-2 ${
                level <= knowledgeLevel ? knowledgeLevelColor : "bg-gray-200"
              }`}
            ></div>
          ))}
        </div>
      </div>

      {/* Flashcard */}
      <div
        className="relative w-full max-w-md mx-auto h-48 sm:h-64 md:h-80 cursor-pointer perspective mb-6"
        onClick={handleCardFlip}
      >
        <div
          className={`w-full h-full transition-transform duration-500 transform-gpu ${
            isFlipped ? "rotate-y-180" : ""
          } relative`}
          style={{ transformStyle: "preserve-3d" }}
        >
          {/* Front: target word */}
          <div
            className="absolute w-full h-full flex flex-col items-center justify-center border-b-4 border-2 shadow-lg bg-white border-black rounded-xl p-3 sm:p-4"
            style={{ backfaceVisibility: "hidden" }}
          >
            {renderAudioButton("front")}

            {imageError ? (
              <div className="h-20 sm:h-28 md:h-32 mb-2 sm:mb-4 flex items-center justify-center">
                <div className="text-center">
                  <div className="text-4xl sm:text-5xl mb-2">
                    {card.emoji || "📝"}
                  </div>
                </div>
              </div>
            ) : (
              <img
                src={imageUrl}
                alt={card.targetWord}
                className={`h-20 sm:h-28 md:h-32 mb-2 sm:mb-4 object-contain transition-opacity duration-300 ${
                  imageReady ? "opacity-100" : "opacity-0"
                }`}
                onLoad={handleImageLoaded}
                onError={() => setImageError(true)}
                style={{
                  visibility: imageUrl ? "visible" : "hidden",
                  maxWidth: "100%",
                }}
              />
            )}

            <div className="text-xl sm:text-2xl md:text-3xl font-bold text-center text-black">
              {card.targetWord}
            </div>

            {card.phonetic && (
              <div className="text-xs sm:text-sm text-gray-600 italic mt-1">
                {card.phonetic}
              </div>
            )}
          </div>

          {/* Back: translation */}
          <div
            className="absolute w-full h-full flex flex-col items-center justify-center bg-white border-b-4 border-2 shadow-lg border-black rounded-xl p-3 sm:p-4"
            style={{
              backfaceVisibility: "hidden",
              transform: "rotateY(180deg)",
            }}
          >
            {renderAudioButton("back")}

            <div className="text-xl sm:text-2xl md:text-3xl font-bold text-center">
              {card.translation}
            </div>

            {card.example && (
              <div className="mt-3 sm:mt-4 text-sm sm:text-md text-gray-600 italic text-center px-4">
                {card.example}
              </div>
            )}

            {card.notes && (
              <div className="mt-2 sm:mt-4 text-xs sm:text-sm text-gray-500 text-center">
                <strong>Note:</strong> {card.notes}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Controls */}
      <div className="flex flex-wrap justify-center gap-3 mt-4">
        <button
          onClick={handleToggleHint}
          className="px-3 sm:px-4 py-2 border-gold border-2 border-b-4 text-gold rounded hover:opacity-90 transition-all duration-150 text-sm sm:text-base"
        >
          {showHint ? "Hide Hint" : "Show Hint"}
        </button>
      </div>

      {/* Hint */}
      <AnimatePresence>
        {showHint && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="mt-4 sm:mt-6 p-3 sm:p-4 border-2 border-b-4 border-gold rounded-lg text-center mx-auto max-w-md"
          >
            <div className="text-sm sm:text-md text-black">
              <span className="font-semibold">Hint:</span> {card.hint}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Audio Disabled Notification (shown when needed) */}
      {!audioEnabled && (frontAudioUrl || backAudioUrl) && (
        <div className="mt-4 p-2 bg-gray-100 border border-gray-300 rounded text-xs sm:text-sm text-gray-500 text-center mx-auto max-w-md">
          Audio is currently disabled globally. You can enable it in Settings.
        </div>
      )}
    </div>
  );
}

export default Flashcard;
