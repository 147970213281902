import React from "react";
import { useStorageImage } from "../../hooks/useStorageImage";

const InventoryItemCard = ({ group, onUse }) => {
  const { url: imageUrl, loading: imageLoading } = useStorageImage(
    group.imagePath
  );

  // Check if any items in the group have expiry dates
  const hasExpiryDate = group.items.some((item) => item.expiresAt);

  // Helper function to determine button text based on item type
  const getButtonText = () => {
    const itemType = group.items[0]?.type || group.items[0]?.category || "";

    switch (itemType.toLowerCase()) {
      case "dictionary":
        return "Use Dictionary Boost";
      case "xp-booster":
      case "xp_booster":
        return "Activate XP Boost";
      case "streak-freeze":
      case "streak_freeze":
        return "Apply Streak Freeze";
      case "coaching":
        return "Schedule Session";
      case "lives":
        return "Add Lives";
      case "audio-pack":
        return "Unlock Audio";
      default:
        return "Use Item";
    }
  };

  // Helper function to determine card color based on item type
  const getCardColors = () => {
    const itemType = group.items[0]?.type || group.items[0]?.category || "";

    switch (itemType.toLowerCase()) {
      case "dictionary":
        return {
          accent: "border-green",
          button: "bg-green hover:bg-[#647c0e]",
        };
      case "xp-booster":
      case "xp_booster":
        return {
          accent: "border-gold",
          button: "bg-gold hover:bg-[#a37b0d]",
        };
      case "streak-freeze":
      case "streak_freeze":
      case "special":
        return {
          accent: "border-black",
          button: "bg-white hover:bg-blue-600",
        };
      case "coaching":
        return {
          accent: "border-black",
          button: "bg-white hover:bg-purple-600",
        };
      case "lives":
        return {
          accent: "border-red",
          button: "bg-red hover:bg-[#7d1212]",
        };
      case "audio-pack":
        return {
          accent: "border-black",
          button: "bg-white hover:bg-indigo-600",
        };
      default:
        return {
          accent: "border-gray",
          button: "bg-green hover:bg-[#647c0e]",
        };
    }
  };

  // Get the soonest expiry date if multiple items have different expiry dates
  const getExpiryInfo = () => {
    if (!hasExpiryDate) return null;

    const itemsWithExpiry = group.items.filter((item) => item.expiresAt);
    if (itemsWithExpiry.length === 0) return null;

    // Sort by expiry date (earliest first)
    itemsWithExpiry.sort(
      (a, b) => new Date(a.expiresAt) - new Date(b.expiresAt)
    );

    const earliestExpiry = new Date(itemsWithExpiry[0].expiresAt);
    const now = new Date();

    // Calculate days remaining
    const daysRemaining = Math.ceil(
      (earliestExpiry - now) / (1000 * 60 * 60 * 24)
    );

    if (daysRemaining <= 0) {
      return { text: "Expired", className: "text-red" };
    } else if (daysRemaining <= 3) {
      return {
        text: `Expires in ${daysRemaining} day${
          daysRemaining !== 1 ? "s" : ""
        }`,
        className: "text-gold",
      };
    } else {
      return {
        text: `Expires in ${daysRemaining} days`,
        className: "text-gray",
      };
    }
  };

  const expiryInfo = getExpiryInfo();
  const colors = getCardColors();

  return (
    <div
      className={`bg-white shadow-md overflow-hidden transition-transform hover:transform hover:-translate-y-1 hover:shadow-lg flex flex-col border-2 border-b-4 ${colors.accent} h-full min-h-[350px]`}
    >
      <div className="h-36 sm:h-48 overflow-hidden relative">
        {imageLoading ? (
          <div className="animate-pulse flex items-center justify-center h-full w-full bg-gray">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 sm:h-12 sm:w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt={group.name}
            className="h-full w-full object-cover"
          />
        ) : (
          <div className="flex items-center justify-center h-full w-full bg-gray text-darkgray">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 sm:h-16 sm:w-16"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
        )}
        <div className="absolute top-2 right-2 bg-green text-white border border-b-2 border-black h-7 w-7 sm:h-8 sm:w-8 flex items-center justify-center">
          <span className="font-bold text-sm sm:text-base">{group.count}</span>
        </div>
      </div>
      <div className="p-3 sm:p-4 flex flex-col flex-grow">
        <div className="flex-grow">
          <h3 className="font-bold text-base sm:text-lg mb-1 text-gray-800">
            {group.name}
          </h3>
          <p className="text-gray-600 text-xs sm:text-sm mb-3 line-clamp-2">
            {group.description}
          </p>

          {/* Uses left for items with limited uses */}
          {group.items[0]?.uses !== undefined && (
            <p className="text-xs sm:text-sm text-gray mb-2">
              {group.items[0].uses} use{group.items[0].uses !== 1 ? "s" : ""}{" "}
              remaining
            </p>
          )}

          {/* Display expiry info if applicable */}
          {expiryInfo && (
            <p className={`text-xs sm:text-sm mb-2 ${expiryInfo.className}`}>
              {expiryInfo.text}
            </p>
          )}

          {/* Item specific details based on type */}
          <div className="text-xs sm:text-sm">
            {renderItemTypeDetails(group.items[0])}
          </div>
        </div>

        <button
          className={`w-full py-2 px-3 sm:px-4 border-2 border-b-4 border-black font-medium mt-2 text-black text-sm sm:text-base ${colors.button} transition-colors`}
          onClick={() => onUse(group.items[0].id)}
        >
          {getButtonText()}
        </button>
      </div>
    </div>
  );
};

// Helper function to render type-specific details
const renderItemTypeDetails = (item) => {
  if (!item) return null;

  const itemType = item.type || item.category || "";

  switch (itemType.toLowerCase()) {
    case "dictionary":
      if (item.unlimited) {
        return (
          <p className="text-green mb-2">
            Unlimited searches for {item.durationHours || 1} hour
            {item.durationHours !== 1 ? "s" : ""}
          </p>
        );
      } else {
        return (
          <p className="text-green mb-2">
            {item.searchesGranted || 50} searches for{" "}
            {item.durationMinutes
              ? `${item.durationMinutes} minutes`
              : "a limited time"}
          </p>
        );
      }

    case "xp-booster":
    case "xp_booster":
      return (
        <p className="text-gold mb-2">
          {item.multiplier || 2}× XP boost for {item.durationHours || 1} hour
          {item.durationHours !== 1 ? "s" : ""}
        </p>
      );

    case "streak-freeze":
    case "streak_freeze":
      return (
        <p className="text-black mb-2">
          Protects your streak for one missed day
        </p>
      );

    case "coaching":
      return (
        <p className="text-black mb-2">
          One-on-one session with a language expert
        </p>
      );

    case "lives":
      return (
        <p className="text-red mb-2">
          Adds {item.livesGranted || 5} extra lives to your account
        </p>
      );

    default:
      return null;
  }
};

export default InventoryItemCard;
