import React from "react";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";
import { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { PuffLoader } from "react-spinners";
import Joyride, { STATUS } from "react-joyride";
import { db } from "../../firebase/firebase.utils.js";
import { doc, updateDoc, onSnapshot, getDoc } from "firebase/firestore";
import { useAuth } from "../../helpers/UserAuthContextProvider.js";
import { useNavigate } from "react-router-dom";
import { useCourse } from "../../helpers/CourseContextProvider.js";
import FloatingActionMenu from "../../components/navigation-bar-2/floating-side-menu.jsx";
import FeedbackMenu from "../../components/support/feedback-menu.jsx";

import MilestoneHub from "../../components/vault/milestones/milestone-hub.jsx";

function Vault() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { courses } = useCourse(); // No need to use setCourses here

  const [userSnap, setUserSnap] = useState(false);

  useEffect(() => {
    document.title = "Vault | Fast Fluency Africa";
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const handleOnboardingUpdate = async () => {
    if (!user?.uid) return;

    const onboardingRef = doc(db, "users", user.uid);
    try {
      await updateDoc(onboardingRef, {
        vaultOnboardingCompleted: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [completedWalkthrough, setCompletedWalkthrough] = useState(false);

  useEffect(() => {
    if (!user?.uid) return;

    const userDoc = doc(db, "users", user.uid);

    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setCompletedWalkthrough(docSnapshot.data().vaultOnboardingCompleted);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [user]); // Add user as dependency

  // Fetch user data once on component mount or when user changes
  useEffect(() => {
    const getCurrUserSnap = async () => {
      if (!user?.uid) return;

      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserSnap({ ...docSnap.data(), id: docSnap.id });
          // We should NOT set courses here, as it's handled by the CourseContextProvider
          // Let CourseContextProvider manage the course state
        }
      } catch (error) {
        console.error("[Vault] Error fetching user data:", error);
      }
    };

    getCurrUserSnap();
  }, [user]); // Only run when user changes

  const [run, setRun] = useState(null);
  const [steps, setSteps] = useState([
    {
      target: "#units",
      content: (
        <>
          <h3>
            <b>Welcome to the Language Vault!</b>
          </h3>
          <p>
            Dive into bite sized language lessons organized in units. Each unit
            covers specific topics to help you master the language.
          </p>
        </>
      ),
    },
    {
      target: "#lessons",
      content: (
        <>
          <h3>
            <b>Level Up!</b>
          </h3>
          <p>
            Hone your language skills here. Whether it's vocabulary or grammar,
            each lesson is designed to get you fluent faster.
          </p>
        </>
      ),
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      handleOnboardingUpdate();
    }
  };

  const startTour = () => {
    setRun(true);
  };

  return (
    // Remove motion effects from the parent div
    <div className="bg-white h-screen">
      {/* Navigation components - Always visible, no animations */}
      <NavigationBar />
      <div className="">
        {/* Move FloatingActionMenu and FeedbackMenu outside of the motion.div content area */}
        <motion.div
          className="fixed z-50 right"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.75 }}
        >
          <FloatingActionMenu
            showFlashcards={false}
            showStats={true}
            additionalButtons={[]}
          />
        </motion.div>
        <FeedbackMenu />
      </div>

      {/* Content area - Apply motion effects only to this section */}
      <motion.div
        className="flex flex-col items-center bg-white w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        {loading ? (
          <div className="flex flex-row justify-center items-center h-[calc(100vh-64px)] w-full">
            <PuffLoader color={"#991616"} loading={loading} size={100} />
          </div>
        ) : (
          <>
            <section className="w-full">
              <div className="">
                {/* Display course name from context provider */}
                {/* <h1 className="font-bold font-display text-center bg-green text-white border-black border-b-4">
                  {courses === "isiZuluSocial" ? "isiZulu Social™" : "isiZulu Academic™"}
                </h1> */}
              </div>
            </section>
            <section className="m-auto w-12/12">
              <MilestoneHub />
            </section>
          </>
        )}
      </motion.div>

      {/* Joyride tour - Accessible regardless of loading state */}
      <Joyride
        steps={steps}
        run={run}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#848717",
            backgroundColor: "#FCFCEE",
            overlayColor: "rgba(79, 26, 0, 0.1)",
            primaryColor: "#000",
            textColor: "#000000",
            width: 500,
            zIndex: 1000,
            fontFamily: "Nunito",
          },
        }}
      />
    </div>
  );
}

export default Vault;
