import React from "react";

const LockedMilestoneCard = ({ milestone }) => {
  return (
    <div
      data-testid={`locked-card-${milestone.id}`}
      className="mt-4 w-full sm:w-11/12 md:w-5/6 mx-auto mb-6 sm:mb-8 p-4 sm:p-6 rounded border-2 border-b-[6px] shadow-2xl flex flex-col md:flex-row 
            bg-darkgray
            border-black hover:border-gray transition-all duration-300 group hover:scale-[101%]"
    >
      {/* Left Content Section */}
      <div className="flex-1 pr-0 md:pr-8 text-left relative z-10">
        {/* Section Header */}
        <div className="mb-4 sm:mb-6">
          <div className="w-fit bg-darkgray px-3 py-1 sm:px-4 sm:py-2 rounded border-2 border-b-4 border-black shadow-lg">
            <span className="font-headers font-bold text-xs sm:text-sm md:text-lg uppercase text-black tracking-wide">
              {milestone.name}
            </span>
          </div>
          <h1 className="bg-gray text-gray mt-3 sm:mt-4 font-headers text-base sm:text-xl md:text-2xl text-black">
            {milestone.subtitle}
          </h1>
        </div>

        {/* Progress Bar */}
        <div className="mb-4 sm:mb-8 h-3 sm:h-4 bg-[#ffcfce]/20 backdrop-blur-sm border-2 border-darkgray border-b-4 rounded-full overflow-hidden">
          <div
            className="h-full bg-gray rounded-full transition-all duration-500 ease-out-expo"
            style={{ width: "100%" }}
          >
            <div className="absolute right-0 h-full w-[2px] bg-white/50 animate-pulse" />
          </div>
        </div>

        {/* Locked Content Info */}
        <div className="">
          <div className="text-xs sm:text-sm md:text-lg font-medium">
            <span className="grayscale-100">🔒</span>{" "}
            <span className="bg-gray text-gray">
              Unlock 30 Units & 50 Lessons
            </span>
          </div>
        </div>
        <div className="my-2 sm:my-4"></div>

        {/* Description */}
        <p className="highlight bg-gray text-gray mb-4 sm:mb-8 font-medium text-xs sm:text-sm md:text-lg leading-relaxed">
          Master nuanced expressions, idiomatic language use, and
          socio-linguistic awareness for authentic communication.
        </p>

        {/* Action Buttons */}
        <div className="flex flex-col xs:flex-row gap-3 sm:gap-4">
          <button
            className="px-4 sm:px-8 py-2 sm:py-3 bg-darkgray text-black rounded transform
                  active:scale-95 active:translate-y-1 border-2 border-b-4 border-black
                  transition-all duration-150 ease-out-expo font-semibold shadow-lg
                  text-xs sm:text-sm md:text-lg hover:shadow-xl
                  hover:bg-[#bcbcbc] relative overflow-hidden"
          >
            <span className="relative z-10">Coming Soon</span>
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,#ffcfce/15%,transparent)]" />
          </button>

          <button
            className="self-center relative group text-black font-medium text-xs sm:text-sm md:text-lg 
                        border-b-2 border-dashed border-transparent pb-[2px]
                        bg-gradient-to-r from-black to-black bg-[length:0%_2px] bg-no-repeat bg-left-bottom hover:text-black
                        transition-all duration-300 ease-out-expo hover:bg-[length:100%_2px]"
          >
            Subscribe For Notifications →
          </button>
        </div>
      </div>

      {/* Right Image Section */}
      <div className="mt-6 md:mt-0 flex items-center justify-center md:w-2/5 relative">
        <div className="absolute w-[120%] h-[120%] bg-[radial-gradient(circle,#e5e8c7/15%,transparent)]" />
        <img
          src={milestone.image}
          className="grayscale-100 h-32 xs:h-40 sm:h-48 md:h-64 max-h-[25rem] transform group-hover:scale-105 md:group-hover:scale-110 group-hover:rotate-3 md:group-hover:rotate-6 transition-all duration-500 ease-out-expo"
          alt={milestone.imageAlt}
        />
      </div>
    </div>
  );
};

export default LockedMilestoneCard;
