// pages/support/category/[categorySlug].jsx
import React, { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import SupportHeader from "../../../components/support/support-header";
import Breadcrumbs from "../../../components/support/breadcrumbs";
import ArticleList from "../../../components/support/article-list";
import Footer from "../../../components/footer/footer.component";
import { getCategoryBySlug } from "../../../zombiedata/support-articles";
import FeedbackMenu from "../../../components/support/feedback-menu";

const CategoryPage = () => {
  const { categorySlug } = useParams();
  const navigate = useNavigate();
  const category = getCategoryBySlug(categorySlug);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (!category) {
      navigate("/support");
      return;
    }

    document.title = `${category.title} | Support Center | Fast Fluency Africa`;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [category, categorySlug, navigate]);

  if (!category) return null;

  const breadcrumbItems = [
    { label: "Support Center", href: "/support" },
    { label: category.title },
  ];

  return loading ? (
    <div
      data-testid="loading-spinner"
      className="flex flex-row justify-center items-center h-screen"
    >
      <PuffLoader color={"#991616"} loading={loading} size={100} />
    </div>
  ) : (
    <motion.div
      className="flex flex-col min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.3 }}
    >
      <SupportHeader />
      <FeedbackMenu />

      <main className="flex-grow container mx-auto px-4 py-8">
        <Breadcrumbs items={breadcrumbItems} />

        <div className="mb-8">
          <div className="flex items-center mb-4">
            <span className="text-3xl mr-3">{category.icon}</span>
            <h1 className="text-3xl font-bold">{category.title}</h1>
          </div>
          <p className="text-gray-600">{category.description}</p>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            All Articles ({category.articles.length})
          </h2>
          <ArticleList
            articles={category.articles}
            emptyMessage="No articles found in this category"
          />
        </div>
      </main>

      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default CategoryPage;
