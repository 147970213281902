import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Calendar, Users, Clock, BarChart, Home, Settings } from "lucide-react";

const MobileBookingNav = ({ userRole }) => {
  const location = useLocation();
  const isCoach = userRole === "coach";

  // Navigation items based on user role
  const navItems = isCoach
    ? [
        {
          path: "/booking/dashboard",
          label: "Dashboard",
          icon: <span>🏠</span>,
        },
        {
          path: "/booking/availability",
          label: "Calendar",
          icon: <span>🗓️</span>,
        },
        {
          path: "/booking/earnings",
          label: "Earnings",
          icon: <span>📈</span>,
        },
      ]
    : [
        {
          path: "/booking/dashboard",
          label: "My Sessions",
          icon: "🕣",
        },
        {
          path: "/booking/coaches",
          label: "Find Coaches",
          icon: "👩🏽‍🏫",
        },
      ];

  // Only show on paths that include booking in the URL
  const shouldShow = location.pathname.includes("/booking");

  // Don't show on success/cancel pages
  const hideOnPaths = [
    "/booking/success",
    "/booking/cancel",
    "/booking/fix-booking",
    "/booking/fix-zoom",
  ];

  // Check if current path is in the hide list
  const isHiddenPath = hideOnPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  // Don't show when booking with a specific coach (to maximize screen space)
  const isBookingWithCoach = location.pathname.includes("/booking/book/");

  if (!shouldShow || isHiddenPath || isBookingWithCoach) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 flex justify-around items-center py-2 md:hidden z-10 shadow-lg">
      {navItems.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          className={({ isActive }) =>
            `flex flex-col items-center px-3 py-1 ${
              isActive ? "text-green font-medium" : "text-gray-500"
            }`
          }
        >
          {item.icon}
          <span className="text-xs">{item.label}</span>
        </NavLink>
      ))}

      {/* Add profile or settings link */}
      <NavLink
        to="/profile"
        className={({ isActive }) =>
          `flex flex-col items-center px-3 py-1 ${
            isActive ? "text-green font-medium" : "text-gray-500"
          }`
        }
      >
        <span>⚙️</span>
        <span className="text-xs">Profile</span>
      </NavLink>
    </div>
  );
};

export default MobileBookingNav;
