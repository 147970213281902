import React, { useState } from "react";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { useNavigate } from "react-router-dom";

function IngoziCard() {
  const [profileStatus, setProfileStatus] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { deleteCurrentUser } = useAuth();

  const promptForCredentials = () => {
    const email = prompt("Please enter your email:");
    const password = prompt("Please enter your password:");
    return EmailAuthProvider.credential(email, password);
  };

  const reauthenticateGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(user, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      await reauthenticateWithCredential(user, credential);
      // User has been successfully reauthenticated
    } catch (error) {
      // An error occurred during reauthentication
      console.error("Error during reauthentication:", error);
    }
  };

  const reauthenticateUser = async () => {
    try {
      // Prompt the user for their credentials
      const credential = promptForCredentials();

      // Reauthenticate the user with the provided credentials
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      console.error("Error during reauthentication:", error);
    }
  };

  const confirmDelete = () => {
    const firstConfirmation = window.confirm(
      "Are you sure you want to delete your account?"
    );
    if (!firstConfirmation) return false;

    const secondConfirmation = window.confirm(
      "This is irreversible! Are you absolutely sure?"
    );
    return secondConfirmation;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    setIsSubmitted(true);

    if (!user) return;

    if (!confirmDelete()) {
      // If user cancels the deletion, we just return without doing anything
      return;
    }

    try {
      await deleteCurrentUser(user);
      setProfileStatus("Your profile has successfully been deleted!");
      navigate("/login");
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        if (
          user.providerData.some((data) => data.providerId === "google.com")
        ) {
          reauthenticateGoogle();
        } else {
          reauthenticateUser();
        }
      }
      console.error("Error deleting profile:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center mt-8 mb-12">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="font-headers text-left mt-4 w-full font-bold text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Ingozi
        </h1>
        <p className="text-left w-full text-sm sm:text-sm md:text-base lg:text-lg">
          Delete your user information and permanently remove all your personal
          and associated account information from our servers. Please note that
          this is irreversible!
        </p>

        <div className="w-full mt-6 p-4 sm:p-6 border-2 border-red bg-red bg-opacity-5 rounded-lg">
          <div className="flex flex-col items-center">
            <p className="text-red font-semibold text-base mb-4 text-center">
              Account deletion is permanent and cannot be undone. All your data
              will be permanently removed.
            </p>

            <form action="" className="w-full" onSubmit={handleSubmit}>
              <div className="flex flex-col items-center justify-center w-full">
                <button
                  type="submit"
                  value="delete profile"
                  className="mt-2 text-sm md:text-base w-full sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-1/2 pt-2 pr-2 inline rounded box-border px-6 py-3 border-2 border-b-4 text-center cursor-pointer
                                  font-bold hover:text-white border-red bg-white hover:border-red hover:bg-red text-red
                                  leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none 
                                  focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Delete profile
                </button>

                {isSubmitted && (
                  <span className="mt-4 text-sm md:text-base text-green font-semibold">
                    {profileStatus}
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IngoziCard;
