// Mobile-responsive results.component.jsx for Dictionary
import React, { useRef, useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import DictionaryFeedbackModal from "../modals/dictionary-feedback-modal.component.jsx";
import {
  getAudioDownloadUrl,
  createAudioController,
} from "../../utils/audio-utils";
import { useAudioSettings } from "../../helpers/AudioSettingsContext";

/**
 * Results component to display dictionary search results
 * Updated to work with the useDictionarySearch hook and handle Firebase audio paths
 */
const Results = ({
  searchTerm,
  isLoading,
  membership,
  onResultsLoaded = () => {},
  // Optional prop to directly pass results (when using the hook)
  searchResults = null,
}) => {
  // Use provided searchResults if available, otherwise use internal state
  const results = searchResults || [];
  const hasResults = results && results.length > 0;
  const audioControllerRef = useRef(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Track processed audio URLs
  const [processedAudioUrls, setProcessedAudioUrls] = useState({});
  const [audioLoading, setAudioLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingId, setCurrentPlayingId] = useState(null);

  // Initialize audio controller
  useEffect(() => {
    audioControllerRef.current = createAudioController();

    // Add event listener for audio ending
    const audio = audioControllerRef.current.getElement();
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentPlayingId(null);
    };

    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      if (audioControllerRef.current) {
        audioControllerRef.current.destroy();
      }
    };
  }, []);

  // Apply audio settings when they change
  useEffect(() => {
    if (audioControllerRef.current) {
      const audio = audioControllerRef.current.getElement();
      audio.muted = !audioEnabled;

      // If audio was disabled while playing, pause it
      if (!audioEnabled && isPlaying) {
        audio.pause();
        setIsPlaying(false);
      }
    }
  }, [audioEnabled, isPlaying]);

  // Process audio URLs when results change
  useEffect(() => {
    const processAudioUrls = async () => {
      if (!hasResults) return;

      setAudioLoading(true);
      const processedUrls = {};

      try {
        // Process each entry's audio URL
        for (const entry of results) {
          if (entry.audioUrl) {
            try {
              const url = await getAudioDownloadUrl(entry.audioUrl);
              processedUrls[entry.id] = url;
            } catch (err) {
              console.error(`Error processing audio for ${entry.entry}:`, err);
            }
          }

          // Also process example audio if available
          if (entry.exampleAudioUrl) {
            try {
              const url = await getAudioDownloadUrl(entry.exampleAudioUrl);
              processedUrls[`example-${entry.id}`] = url;
            } catch (err) {
              console.error(
                `Error processing example audio for ${entry.entry}:`,
                err
              );
            }
          }
        }

        setProcessedAudioUrls(processedUrls);
      } catch (err) {
        console.error("Error processing audio URLs:", err);
      } finally {
        setAudioLoading(false);
      }
    };

    processAudioUrls();
  }, [results, hasResults]);

  // Call onResultsLoaded callback when results change
  useEffect(() => {
    if (searchTerm && !isLoading) {
      onResultsLoaded(hasResults);
    }
  }, [searchTerm, isLoading, hasResults, onResultsLoaded]);

  // Play audio function with better error handling
  const handlePlayAudio = async (entryId, directPath = null) => {
    // If audio is globally disabled, don't play
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    // If already playing this entry, stop it
    if (isPlaying && currentPlayingId === entryId) {
      audioControllerRef.current.pause();
      setIsPlaying(false);
      setCurrentPlayingId(null);
      return;
    }

    // If a direct path is provided, convert it first
    let audioUrl;

    if (directPath) {
      try {
        audioUrl = await getAudioDownloadUrl(directPath);
      } catch (err) {
        console.error("Failed to process direct path:", directPath, err);
        return;
      }
    } else {
      audioUrl = processedAudioUrls[entryId];
    }

    if (!audioUrl) {
      console.warn("No audio URL available for:", entryId);
      return;
    }

    if (audioControllerRef.current) {
      try {
        // Stop any currently playing audio
        audioControllerRef.current.pause();

        // Set the new URL and play
        audioControllerRef.current.setUrl(audioUrl);
        await audioControllerRef.current.play();

        // Update state
        setIsPlaying(true);
        setCurrentPlayingId(entryId);
      } catch (err) {
        console.error("Error playing audio:", err);
        setIsPlaying(false);
        setCurrentPlayingId(null);
      }
    }
  };

  // Get the audio button content based on state
  const getAudioButtonContent = (entryId) => {
    if (isPlaying && currentPlayingId === entryId) {
      return "⏸️";
    }
    if (!audioEnabled) {
      return "🔇";
    }
    return "🔊";
  };

  return (
    <div className="results-container">
      {isLoading || audioLoading ? (
        <div className="flex justify-center mt-4">
          <BeatLoader color="#848717" loading={true} size={12} />
        </div>
      ) : (
        <>
          {hasResults ? (
            <div>
              {results.map((entry) => (
                <div
                  key={entry.id}
                  className="entry-card p-3 sm:p-4 border-2 border-b-4 mt-4 max-w-4xl shadow-lg rounded hover:shadow-xl"
                  style={{ borderColor: "#991616" }}
                >
                  <h1 className="text-lg sm:text-xl md:text-2xl font-headers font-semibold flex flex-wrap items-center">
                    <span
                      className="cursor-pointer underline decoration-dotted mr-2"
                      onClick={() => handlePlayAudio(entry.id, entry.audioUrl)}
                    >
                      {entry.entry}
                    </span>
                    {entry.audioUrl && (
                      <button
                        onClick={() =>
                          handlePlayAudio(entry.id, entry.audioUrl)
                        }
                        className={`text-xl p-1 sm:p-2 text-outline-black hover:opacity-80 min-h-[44px] min-w-[44px] ${
                          !audioEnabled ? "opacity-50" : ""
                        }`}
                        title={
                          audioEnabled
                            ? "Play audio"
                            : "Audio is disabled globally"
                        }
                      >
                        {getAudioButtonContent(entry.id)}
                      </button>
                    )}
                    {entry.partofspeech && (
                      <span
                        className="text-xs sm:text-sm md:text-base p-1 sm:p-2"
                        style={{ color: "#991616" }}
                      >
                        ({entry.partofspeech})
                      </span>
                    )}
                  </h1>
                  {entry.pronunciation && (
                    <span
                      className="text-xs sm:text-sm md:text-base block"
                      style={{ color: "#991616" }}
                    >
                      {entry.pronunciation}
                    </span>
                  )}
                  {entry.translation && (
                    <div className="text-sm sm:text-base font-headers font-semibold mt-3 sm:mt-4">
                      <h3>
                        Translation for{" "}
                        <span style={{ color: "#991616" }}>{entry.entry}</span>
                      </h3>
                      <p className="text-xs sm:text-sm mt-1 sm:mt-2">
                        {entry.translation}
                      </p>
                    </div>
                  )}
                  {entry.definition && (
                    <div className="text-sm sm:text-base font-headers font-semibold mt-3 sm:mt-4">
                      <h3>
                        Definition of{" "}
                        <span style={{ color: "#991616" }}>{entry.entry}</span>
                      </h3>
                      <p className="text-xs sm:text-sm mt-1 sm:mt-2">
                        {entry.definition}
                      </p>
                    </div>
                  )}
                  {entry.example && (
                    <div className="text-sm sm:text-base font-headers font-semibold mt-3 sm:mt-4">
                      <h3 className="flex flex-wrap items-center">
                        <span className="mr-1">
                          Examples of{" "}
                          <span style={{ color: "#991616" }}>
                            {entry.entry}
                          </span>{" "}
                          in a sentence
                        </span>
                        {entry.exampleAudioUrl && (
                          <button
                            onClick={() =>
                              handlePlayAudio(
                                `example-${entry.id}`,
                                entry.exampleAudioUrl
                              )
                            }
                            className={`p-1 sm:p-2 text-lg text-outline-black hover:opacity-80 min-h-[44px] min-w-[44px] ${
                              !audioEnabled ? "opacity-50" : ""
                            }`}
                            title={
                              audioEnabled
                                ? "Play example audio"
                                : "Audio is disabled globally"
                            }
                          >
                            {getAudioButtonContent(`example-${entry.id}`)}
                          </button>
                        )}
                      </h3>
                      <ol className="text-xs sm:text-sm mt-1 sm:mt-2 list-inside">
                        <li
                          className="list-decimal cursor-pointer"
                          onClick={() =>
                            handlePlayAudio(
                              `example-${entry.id}`,
                              entry.exampleAudioUrl
                            )
                          }
                        >
                          {entry.example}
                          {entry.englishexample && (
                            <em className="block sm:inline sm:ml-1 text-gray-700 mt-1 sm:mt-0">
                              ({entry.englishexample})
                            </em>
                          )}
                        </li>
                      </ol>
                    </div>
                  )}

                  {/* Debug info during development - remove in production */}
                  {process.env.NODE_ENV === "development" && (
                    <div className="mt-4 text-xs text-gray-500 border-t pt-2">
                      <div>Audio path: {entry.audioUrl || "None"}</div>
                      <div>
                        Processed URL:{" "}
                        {processedAudioUrls[entry.id] || "Not processed"}
                      </div>
                      <div>
                        Audio globally {audioEnabled ? "enabled" : "disabled"}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            searchTerm && (
              <div className="no-results text-center text-xs sm:text-sm mt-4">
                No results found for "<strong>{searchTerm}</strong>"
                <div className="mt-2">
                  <DictionaryFeedbackModal />
                </div>
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default Results;
