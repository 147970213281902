// OnboardingNDA.jsx
import React, { useState } from "react";
import { motion } from "framer-motion";

const OnboardingNDA = ({ agreement, onAgree, onComplete }) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [canAgree, setCanAgree] = useState(false);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const percentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    setScrollPercentage(percentage);

    // Allow agreement after scrolling to 90% of document
    if (percentage > 90 && !canAgree) {
      setCanAgree(true);
    }
  };

  const handleAgreeChange = (e) => {
    onAgree(e.target.checked);
  };

  // Mock NDA content
  const ndaContent = `
    # NON-DISCLOSURE AGREEMENT

    ## FAST FLUENCY AFRICA EARLY ACCESS PROGRAM

    This Non-Disclosure Agreement (the "Agreement") is made effective as of the date of electronic acceptance.

    BETWEEN:
    Fast Fluency Africa (the "Company")

    AND:
    The Early Access Participant (the "Participant")

    ### 1. PURPOSE

    The Participant has been invited to participate in the Fast Fluency Africa Early Access Program (the "Program"). During participation in the Program, the Participant may have access to confidential information, unreleased features, and proprietary materials.

    ### 2. CONFIDENTIAL INFORMATION

    For the purposes of this Agreement, "Confidential Information" means all information disclosed by the Company to the Participant related to the Program, including but not limited to:

    - Unreleased features and functionality
    - Program roadmaps and development plans
    - User interface designs and prototypes
    - Technical specifications and implementation details
    - Feedback mechanisms and collected feedback
    - Business strategies related to the Program
    - Any other information specifically designated as confidential

    ### 3. PARTICIPANT OBLIGATIONS

    The Participant agrees to:

    a) Keep all Confidential Information strictly confidential;
    b) Not disclose Confidential Information to any third party without prior written consent from the Company;
    c) Use Confidential Information solely for the purpose of participating in the Program;
    d) Take reasonable measures to protect the secrecy of the Confidential Information;
    e) Not capture screenshots, recordings, or other media of the Program without express permission;
    f) Not reverse engineer, decompile, or disassemble any software provided as part of the Program.

    ### 4. EXCLUSIONS

    The obligations in this Agreement do not apply to information that:

    a) Was lawfully known to the Participant before disclosure by the Company;
    b) Is or becomes publicly available through no fault of the Participant;
    c) Is independently developed by the Participant without use of the Confidential Information;
    d) Is disclosed with prior written approval of the Company;
    e) Is required to be disclosed by law or court order, provided the Participant gives the Company reasonable notice to contest such disclosure.

    ### 5. TERM AND TERMINATION

    This Agreement shall remain in effect throughout the Participant's involvement in the Program and for a period of two (2) years thereafter.

    ### 6. REMEDIES

    The Participant acknowledges that monetary damages may not be a sufficient remedy for unauthorized disclosure of Confidential Information and that the Company shall be entitled to seek injunctive or other equitable relief to prevent or remedy any breach of this Agreement.

    ### 7. OWNERSHIP

    All Confidential Information remains the property of the Company. No license or other rights to Confidential Information are granted to the Participant under this Agreement.

    ### 8. FEEDBACK

    Any feedback, suggestions, or ideas provided by the Participant regarding the Program may be used by the Company without obligation of any kind to the Participant.

    ### 9. GOVERNING LAW

    This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].

    ### 10. ENTIRE AGREEMENT

    This Agreement constitutes the entire understanding between the parties concerning the subject matter hereof.

    BY CLICKING "I AGREE" OR SIMILAR BUTTON, THE PARTICIPANT ACKNOWLEDGES THAT THEY HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.
  `;

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <p>
          Before proceeding, please read and accept our Non-Disclosure
          Agreement. This ensures that our early access program and unreleased
          features remain confidential.
        </p>
      </div>

      {/* Document container with scroll */}
      <div className="relative mb-4">
        <div
          className="h-64 overflow-auto border-2 border-darkgray rounded-md p-4 bg-white font-body text-sm"
          onScroll={handleScroll}
        >
          <div className="prose prose-sm max-w-none whitespace-pre-line">
            {ndaContent}
          </div>
        </div>

        {/* Scroll progress indicator */}
        <div className="w-full bg-darkgray h-1 mt-2 rounded-full overflow-hidden">
          <motion.div
            className="bg-green h-full"
            initial={{ width: 0 }}
            animate={{ width: `${scrollPercentage}%` }}
            transition={{ type: "spring", stiffness: 100 }}
          />
        </div>

        {!canAgree && (
          <div className="absolute bottom-2 right-2 bg-white bg-opacity-90 px-3 py-2 rounded-md text-sm border border-gray text-center animate-pulse">
            Please scroll to continue
          </div>
        )}
      </div>

      {/* Agreement checkbox */}
      <div className="flex items-center mb-6 mt-4">
        <input
          type="checkbox"
          id="agree-nda"
          checked={agreement}
          onChange={handleAgreeChange}
          disabled={!canAgree}
          className="w-5 h-5 mr-3 accent-green disabled:opacity-50"
        />
        <label
          htmlFor="agree-nda"
          className={`${
            !canAgree ? "opacity-50" : ""
          } cursor-pointer select-none`}
        >
          I have read and agree to the Non-Disclosure Agreement
        </label>
      </div>

      {/* Continue button */}
      <div className="w-full text-center mt-4">
        <button
          onClick={onComplete}
          disabled={!agreement}
          className={`px-8 py-3 border-2 rounded text-center mt-4 w-full md:w-auto
            ${agreement ? "cursor-pointer" : "opacity-50 cursor-not-allowed"}
            text-green font-bold border-green hover:border-white hover:bg-green hover:text-white
            text-sm md:text-base
            uppercase
            shadow-md
            hover:shadow-lg
            transition
            duration-150
            transform active:scale-95 active:translate-y-1 active:border-b-2 ease-in-out border-b-4`}
        >
          Accept and Continue
        </button>
      </div>
    </div>
  );
};

export default OnboardingNDA;
