// components/support/SupportHeader.jsx
import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "./search-bar";

const SupportHeader = () => {
  return (
    <div className="bg-red w-full border-b-4 border-darkgray">
      <div className="container mx-auto py-6 px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <Link
            to="/support"
            className="text-white text-2xl font-bold mb-4 md:mb-0 font-headers"
          >
            Fast Fluency Africa Questions
          </Link>
          <div className="w-full md:w-1/2">
            <SearchBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportHeader;
