import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { motion } from "framer-motion";
import { ErrorBoundary } from "../error-boundary/error-boundary.jsx";
import BeatLoader from "react-spinners/BeatLoader";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <BeatLoader color="#FCFCEE" loading={loading} size={15} />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default ProtectedRoute;
