import React, { useState, useEffect, useContext } from "react";

import "./App.css";

import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { LearningMaterialsContext } from "./helpers/contexts.js";
import { OnboardingContext } from "./helpers/contexts.js";
import { AuthContextProvider } from "./helpers/UserAuthContextProvider";
import { InputContext } from "./helpers/context3.js";
import { LoaderContext } from "./helpers/context3.js";
import { ModalContext } from "./helpers/modalcontext";
import { ScoreContext, ScoreProvider } from "./helpers/ScoreContext.js";
// import { CourseContext } from "./helpers/contexts.js";
import { LMContext } from "./helpers/context3.js";
import { optionChosenContext } from "./helpers/context3.js";
import { feedbackContext } from "./helpers/context3.js";
import AnimatedRoutes from "./components/animated-routes/animated-routes.component";
import { AncestorLoaderContext } from "./helpers/context3.js";
import { CheckoutContext } from "./helpers/context3.js";
import PuffLoader from "react-spinners/PuffLoader";
import { CourseContextProvider } from "./helpers/CourseContextProvider.js";
import { MilestoneProvider } from "./helpers/MilestoneProvider.js";
import { GuidebookContext } from "./helpers/contexts.js";
import { AttemptsProvider } from "./helpers/AttemptsContextProvider.js";
import { ErrorBoundary } from "./components/error-boundary/error-boundary.jsx";
import { DialogueFlowProvider } from "./helpers/DialogueFlowContext.js";
import { ShopProvider } from "./helpers/shopContext";
import { AnalyticsProvider } from "./helpers/AnalyticsContextProvider.js";
import { BookingProvider } from "./helpers/BookingsContext.js";
import { AudioSettingsProvider } from "./helpers/AudioSettingsContext.js";
import { ClassroomProvider } from "./helpers/ClassroomContextProvider";
import { NotificationProvider } from "./helpers/NotificationsContext";

import NotificationToast from "./components/notifications/notification-toast.component";
import NotificationPermission from "./components/notifications/notification-permission.jsx";
import SafeNotificationPermission from "./components/notifications/safe-notification-permission";
import NotificationErrorBoundary from "./components/notifications/notification-error-boundary";
import notificationInitService from "./services/notifications-initialization-service";

import EnhancedAnalyticsProvider from "./helpers/EnhancedAnalyticsContextProvider.js";

function App() {
  const [onboardingState, setOnboardingState] = useState("one");
  const [learningMaterialState, setLearningMaterialState] = useState("");
  const [learningMaterialId, setLearningMaterialId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [score, setScore] = useState(0);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [optionChosen, setOptionChosen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [ancestorLoading, setAncestorLoading] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [courses, setCourses] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [milestone, setMilestone] = useState("");
  const [guidebook, setGuidebook] = useState("");

  // Add this useEffect in your component
  useEffect(() => {
    // Initialize notification system
    notificationInitService.initialize();
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <div className="App">
          {
            // loading ? (
            //   <div className="flex flex-row justify-center items-center h-screen bg-white">
            //     <PuffLoader color={"#991616"} loading={loading} size={100} />
            //   </div>
            // ) :

            <AuthContextProvider>
              <InputContext.Provider
                value={{
                  inputValue,
                  setInputValue,
                  resultsLoading,
                  setResultsLoading,
                }}
              >
                <LoaderContext.Provider value={{ loader, setLoader }}>
                  <ScoreProvider>
                    <LearningMaterialsContext.Provider
                      value={{
                        learningMaterialState,
                        setLearningMaterialState,
                      }}
                    >
                      <LMContext.Provider
                        value={{
                          learningMaterialId,
                          setLearningMaterialId,
                        }}
                      >
                        <ModalContext.Provider value={{ modal, setModal }}>
                          <OnboardingContext.Provider
                            value={{ onboardingState, setOnboardingState }}
                          >
                            <optionChosenContext.Provider
                              value={{ optionChosen, setOptionChosen }}
                            >
                              <feedbackContext.Provider
                                value={{ feedback, setFeedback }}
                              >
                                <AncestorLoaderContext.Provider
                                  value={{
                                    ancestorLoading,
                                    setAncestorLoading,
                                  }}
                                >
                                  <CheckoutContext.Provider
                                    value={{ checkout, setCheckout }}
                                  >
                                    <CourseContextProvider
                                      value={{ courses, setCourses }}
                                    >
                                      <MilestoneProvider
                                        value={{ milestone, setMilestone }}
                                      >
                                        <GuidebookContext.Provider
                                          value={{ guidebook, setGuidebook }}
                                        >
                                          <AttemptsProvider>
                                            <DialogueFlowProvider>
                                              <EnhancedAnalyticsProvider>
                                                {" "}
                                                {/* Replace old AnalyticsProvider */}
                                                <AudioSettingsProvider>
                                                  <ClassroomProvider>
                                                    <NotificationProvider>
                                                      <ShopProvider>
                                                        <BookingProvider>
                                                          <NotificationErrorBoundary>
                                                            <NotificationToast />
                                                          </NotificationErrorBoundary>
                                                          <AnimatedRoutes />
                                                          <NotificationErrorBoundary>
                                                            <SafeNotificationPermission />
                                                          </NotificationErrorBoundary>
                                                        </BookingProvider>
                                                      </ShopProvider>
                                                    </NotificationProvider>
                                                  </ClassroomProvider>
                                                </AudioSettingsProvider>
                                              </EnhancedAnalyticsProvider>
                                            </DialogueFlowProvider>
                                          </AttemptsProvider>
                                        </GuidebookContext.Provider>
                                      </MilestoneProvider>
                                    </CourseContextProvider>
                                  </CheckoutContext.Provider>
                                </AncestorLoaderContext.Provider>
                              </feedbackContext.Provider>
                            </optionChosenContext.Provider>
                          </OnboardingContext.Provider>
                        </ModalContext.Provider>
                      </LMContext.Provider>
                    </LearningMaterialsContext.Provider>
                  </ScoreProvider>
                </LoaderContext.Provider>
              </InputContext.Provider>
            </AuthContextProvider>
          }
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
