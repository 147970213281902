import React, { useEffect, useState } from "react";
import { useAuth } from "../../helpers/UserAuthContextProvider";

function NewsFeedCard() {
  const { user } = useAuth();

  return (
    <div className="w-full flex flex-col justify-start items-center mt-4 my-4 p-4 sm:p-6 md:p-8 bg-white border-2 border-b-4 border-darkgray shadow-2xl african-pattern">
      <div className="w-full sm:w-3/4 md:w-1/2 text-center mb-2">
        <div className="text-outline-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
          👩🏾‍🏫 👨🏽‍🏫
        </div>
      </div>
      <div className="w-full">
        <h1 className="font-headers text-lg sm:text-xl md:text-2xl text-black text-center mb-2">
          <span className="font-semibold">
            <span className="text-outline-black">🔥</span>Your Pan-African
            Pilgrimage Starts Now!
          </span>
        </h1>
        <h3 className="text-xs sm:text-sm md:text-base text-center font-italic text-black font-light">
          Welcome to our Edutainment Vault{" "}
          {user && user?.displayName ? (
            <span className="capitalize">
              {" "}
              <b>{user?.displayName}</b>!
            </span>
          ) : null}{" "}
          <span className="text-outline-black"> 📚🎉</span> Join us and explore
          original edutainment content that will help you master African
          languages, culture, and stories at your own pace.
          <span className="text-outline-black"> 🚀🌱</span> Whether you're a
          beginner or a seasoned learner, our diverse collection of resources
          will engage and inspire you. Let's embark on this educational
          adventure together.
        </h3>
      </div>
    </div>
  );
}

export default NewsFeedCard;
