import { motion } from "framer-motion";

export const stepTransition = {
  type: "spring",
  damping: 25,
  stiffness: 120,
  mass: 0.5,
};

export const containerVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 },
};

export const AnimatedDiv = motion.div;
