// In narration-phase.jsx
import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import AudioVisualizer from "../audio-visualiser";
import { getAudioDownloadUrl } from "../../../../utils/audio-utils";

export const NarratorPhase = ({ dialogue, isPlaying, onPlayAudio }) => {
  const [audioElement, setAudioElement] = useState(null);
  const [audioReady, setAudioReady] = useState(false);
  const [audioError, setAudioError] = useState(null);
  const [localIsPlaying, setLocalIsPlaying] = useState(false);

  const audioRef = useRef(null);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    exit: {
      opacity: 0,
      y: -30,
      transition: { duration: 0.3 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Audio animation variants
  const audioBarVariants = {
    playing: {
      scaleY: [0.3, 1, 0.3],
      transition: { duration: 0.8, repeat: Infinity },
    },
    paused: { scaleY: 0.3 },
  };

  // Update localIsPlaying when parent's isPlaying changes
  useEffect(() => {
    setLocalIsPlaying(isPlaying);
  }, [isPlaying]);

  // This is now a standalone component that doesn't manage its own audio
  // The audio is managed by the parent component through useDialogueAudio
  // We only manage the visual feedback here based on the isPlaying prop

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="narration-container max-w-3xl mx-auto"
    >
      <div className="px-4 py-6">
        <motion.div
          className="border-2 border-b-4 border-gray px-6 py-8 shadow-2xl bg-white"
          variants={containerVariants}
        >
          <div className="flex flex-col justify-between items-center">
            {/* Icon */}
            <motion.p
              className="text-5xl md:text-6xl lg:text-7xl mb-4 text-outline-black"
              variants={itemVariants}
            >
              {dialogue.icon || "🗣️"}
            </motion.p>

            {/* Title */}
            <motion.h1
              className="text-center text-2xl md:text-3xl lg:text-4xl p-2 mb-4 font-bold font-headers"
              variants={itemVariants}
            >
              {dialogue.title?.toUpperCase() || "DIALOGUE"}
            </motion.h1>

            {/* Description */}
            <motion.div
              className="text-lg md:text-xl p-2 mb-6 text-justify"
              variants={itemVariants}
            >
              {dialogue.description || "No description available"}
            </motion.div>

            {/* Audio visualizer */}
            <motion.div
              variants={itemVariants}
              className="w-full max-w-md flex flex-col items-center"
            >
              {/* Custom audio visualizer bars */}
              <div className="flex justify-center items-end h-12 gap-1 mb-2">
                {[...Array(10)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="w-2 bg-gold rounded-t"
                    style={{ height: `${Math.random() * 60 + 20}%` }}
                    variants={audioBarVariants}
                    animate={localIsPlaying ? "playing" : "paused"}
                    transition={{
                      delay: i * 0.08,
                      duration: 0.7,
                      repeat: Infinity,
                      repeatType: "reverse",
                    }}
                  />
                ))}
              </div>

              <div className="text-center text-sm text-gray-500 mt-2">
                {localIsPlaying ? (
                  <div className="flex items-center justify-center">
                    <div className="w-2 h-2 bg-green rounded-full mr-2"></div>
                    <span>Narration in progress...</span>
                  </div>
                ) : (
                  <span>Narration available. Press Continue to proceed</span>
                )}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

NarratorPhase.propTypes = {
  dialogue: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    narrationAudio: PropTypes.string,
  }).isRequired,
  isPlaying: PropTypes.bool,
  onPlayAudio: PropTypes.func,
};

NarratorPhase.defaultProps = {
  isPlaying: false,
  onPlayAudio: () => {},
};
