import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import FeedbackMenu from "../../components/support/feedback-menu";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Footer from "../../components/footer/footer.component";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "404 Not Found | Fast Fluency Africa";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col min-h-screen bg-white"
    >
      {/* Header Section */}
      <div className="bg-gradient-to-r from-red to-black text-white">
        <NavBarPrimary />
        <FeedbackMenu />
        <div className="max-w-6xl mx-auto px-4 py-16 md:py-20 lg:py-24 w-5/6">
          <div className="text-center md:text-center">
            <div className="max-w-3xl mx-auto">
              <motion.h1
                className="text-4xl md:text-6xl lg:text-7xl font-bold mb-6 font-headers"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                404
              </motion.h1>
              <motion.div
                className="text-2xl md:text-3xl mb-4 flex justify-center space-x-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <span>🧐</span>
                <span>🤨</span>
                <span>🔍</span>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Wave divider */}
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full h-auto"
          >
            <path
              fill="#FCFCEE"
              fillOpacity="1"
              d="M0,32L48,42.7C96,53,192,75,288,74.7C384,75,480,53,576,48C672,43,768,53,864,58.7C960,64,1056,64,1152,56C1248,48,1344,32,1392,24L1440,16L1440,100L1392,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
            />
          </svg>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow bg-[#FCFCEE] w-5/6 mx-auto">
        <div className="max-w-5xl mx-auto px-4 py-12">
          <motion.section
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="mb-16"
          >
            {/* Main message card */}
            <motion.div
              variants={itemVariants}
              className="bg-white rounded-lg p-8 md:p-12 border-2 border-b-4 border-red shadow-lg text-center"
            >
              <div className="flex justify-center mb-8">
                <div className="w-16 h-16 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-gold flex items-center justify-center">
                  <span className="text-white text-3xl -rotate-45 text-outline-gold">
                    🔍
                  </span>
                </div>
              </div>

              <h2 className="text-xl md:text-2xl lg:text-3xl mb-4 font-body">
                Ooops... It seems we can't find the page you're looking for
              </h2>

              <p className="text-md md:text-lg mb-8 text-gray">
                Please check the URL again or let us take you back to the
                homepage
              </p>

              <div className="flex flex-wrap justify-center gap-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-8 py-3 bg-red text-white font-bold rounded-md border-2 border-b-4 border-red shadow-lg hover:bg-red/90 transition-colors uppercase"
                >
                  <Link to="./" className="text-white no-underline">
                    Back to Homepage
                  </Link>
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate(-1)}
                  className="px-8 py-3 bg-transparent text-gold font-bold rounded-md border-2 border-b-4 border-gold shadow-lg hover:bg-gold/10 transition-colors uppercase"
                >
                  Go Back
                </motion.button>
              </div>
            </motion.div>
          </motion.section>

          {/* Suggested Links Section */}
          <motion.section
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="mb-16"
          >
            <div className="flex items-center mb-6 justify-center">
              <div className="w-10 h-10 rounded-sm rotate-45 border-2 border-b-4 border-r-4 border-green flex items-center justify-center mr-4">
                <span className="text-white text-xl -rotate-45 text-outline-green">
                  💡
                </span>
              </div>
              <h2 className="text-xl md:text-2xl lg:text-3xl font-headers font-bold">
                You might be looking for
              </h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <motion.div
                variants={itemVariants}
                whileHover={{ y: -5 }}
                className="bg-white p-5 rounded-lg border-2 border-b-4 border-red shadow-lg text-center"
              >
                <h3 className="font-bold text-red mb-2">Dictionary</h3>
                <p className="text-sm mb-4">
                  Explore our comprehensive database of African language words
                </p>
                <Link
                  to="/dictionary"
                  className="text-red hover:underline font-bold"
                >
                  Browse Dictionary →
                </Link>
              </motion.div>

              <motion.div
                variants={itemVariants}
                whileHover={{ y: -5 }}
                className="bg-white p-5 rounded-lg border-2 border-b-4 border-gold shadow-lg text-center"
              >
                <h3 className="font-bold text-gold mb-2">Vault</h3>
                <p className="text-sm mb-4">
                  Access your personalized learning materials and progress
                </p>
                <Link
                  to="/vault"
                  className="text-gold hover:underline font-bold"
                >
                  Open Vault →
                </Link>
              </motion.div>

              <motion.div
                variants={itemVariants}
                whileHover={{ y: -5 }}
                className="bg-white p-5 rounded-lg border-2 border-b-4 border-green shadow-lg text-center"
              >
                <h3 className="font-bold text-green mb-2">Support</h3>
                <p className="text-sm mb-4">
                  Need help? Our support team is ready to assist you
                </p>
                <Link
                  to="/support"
                  className="text-green hover:underline font-bold"
                >
                  Get Help →
                </Link>
              </motion.div>
            </div>
          </motion.section>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-b from-white to-gold w-full">
        <Footer />
      </footer>
    </motion.div>
  );
};

export default NotFound;
