// Utility functions for lives/attempts-related notifications
import { useNotifications } from "../../helpers/NotificationsContext";
import { useNotificationTemplates } from "./notification-util";

// Notification types for lives system
export const LIVES_NOTIFICATION_TYPES = {
  LIVES_REPLENISHED: "lives_replenished",
  LIVES_EARNED: "lives_earned",
  LIVES_LOW: "lives_low",
  LIVES_DEPLETED: "lives_depleted",
};

/**
 * Create a notification for when lives are fully replenished
 * @param {number} maxLives - The maximum number of lives the user can have
 * @returns {Object} The notification object
 */
export const createLivesReplenishedNotification = (maxLives) => {
  return {
    title: "❤️ Lives Replenished",
    message: `Your lives have been fully restored to ${maxLives}. Time to continue learning!`,
    type: LIVES_NOTIFICATION_TYPES.LIVES_REPLENISHED,
    data: {
      currentLives: maxLives,
    },
    link: `/dashboard`,
  };
};

/**
 * Create a notification for when lives are earned from practice
 * @param {number} livesEarned - Number of lives earned
 * @param {number} currentLives - Current number of lives after earning
 * @returns {Object} The notification object
 */
export const createLivesEarnedNotification = (livesEarned, currentLives) => {
  return {
    title: "🎁 Lives Earned",
    message: `You've earned ${livesEarned} ${
      livesEarned === 1 ? "life" : "lives"
    } from your practice sessions! You now have ${currentLives} ${
      currentLives === 1 ? "life" : "lives"
    }.`,
    type: LIVES_NOTIFICATION_TYPES.LIVES_EARNED,
    data: {
      livesEarned,
      currentLives,
    },
    link: `/dashboard`,
  };
};

/**
 * Create a notification for when user is low on lives
 * @param {number} remainingLives - Number of lives remaining
 * @returns {Object} The notification object
 */
export const createLivesLowNotification = (remainingLives) => {
  return {
    title: "⚠️ Lives Running Low",
    message: `You only have ${remainingLives} ${
      remainingLives === 1 ? "life" : "lives"
    } remaining. Consider doing some practice sessions to earn more!`,
    type: LIVES_NOTIFICATION_TYPES.LIVES_LOW,
    data: {
      remainingLives,
    },
    link: `/practice`,
  };
};

/**
 * Create a notification for when user is out of lives
 * @param {Date} nextRegenerationTime - When the next life will regenerate
 * @returns {Object} The notification object
 */
export const createLivesDepletedNotification = (nextRegenerationTime) => {
  // Format the time until regeneration
  const formattedTime = nextRegenerationTime
    ? formatRegenerationTime(nextRegenerationTime)
    : "soon";

  return {
    title: "❌ Out of Lives",
    message: `You're out of lives! Your next life will regenerate ${formattedTime}. Complete practice sessions to earn more lives sooner.`,
    type: LIVES_NOTIFICATION_TYPES.LIVES_DEPLETED,
    data: {
      nextRegenerationTime,
    },
    link: `/practice`,
  };
};

/**
 * Format regeneration time in a user-friendly way
 * @param {Date} regenerationTime - The time when regeneration will occur
 * @returns {string} Formatted time string
 */
const formatRegenerationTime = (regenerationTime) => {
  const now = new Date();
  const diffMs = regenerationTime - now;
  const diffMinutes = Math.floor(diffMs / 60000);

  if (diffMinutes < 1) {
    return "in less than a minute";
  } else if (diffMinutes === 1) {
    return "in 1 minute";
  } else if (diffMinutes < 60) {
    return `in ${diffMinutes} minutes`;
  } else {
    const hours = Math.floor(diffMinutes / 60);
    const remainingMinutes = diffMinutes % 60;
    if (remainingMinutes === 0) {
      return `in ${hours} ${hours === 1 ? "hour" : "hours"}`;
    } else {
      return `in ${hours} ${
        hours === 1 ? "hour" : "hours"
      } and ${remainingMinutes} ${
        remainingMinutes === 1 ? "minute" : "minutes"
      }`;
    }
  }
};

/**
 * Hook to easily send lives-related notifications
 */
export const useLivesNotifications = () => {
  const { addNotification } = useNotifications();
  const { sendNotification } = useNotificationTemplates();

  // Send a notification when lives are fully replenished
  const sendLivesReplenishedNotification = (maxLives) => {
    // Try to use the template first
    const fromTemplate = sendNotification("LIVES_REPLENISHED");

    // If template fails or doesn't exist, use our custom notification
    if (!fromTemplate) {
      return addNotification(createLivesReplenishedNotification(maxLives));
    }

    return fromTemplate;
  };

  // Send a notification when lives are earned from practice
  const sendLivesEarnedNotification = (livesEarned, currentLives) => {
    return addNotification(
      createLivesEarnedNotification(livesEarned, currentLives)
    );
  };

  // Send a notification when user is low on lives
  const sendLivesLowNotification = (remainingLives) => {
    return addNotification(createLivesLowNotification(remainingLives));
  };

  // Send a notification when user is out of lives
  const sendLivesDepletedNotification = (nextRegenerationTime) => {
    return addNotification(
      createLivesDepletedNotification(nextRegenerationTime)
    );
  };

  return {
    sendLivesReplenishedNotification,
    sendLivesEarnedNotification,
    sendLivesLowNotification,
    sendLivesDepletedNotification,
  };
};
