// services/notification-initialization.service.js
import deckUnlockService from "./flashcard/deck-unlock-service";
import { createDeckUnlockedNotification } from "../utils/notifications/flashcard-notifications-utils";
import { addGlobalNotification } from "../helpers/NotificationsContext";

/**
 * Initialize notification handlers globally for the application
 * This decouples notification sending from component lifecycles
 */
class NotificationInitializationService {
  constructor() {
    this.initialized = false;
    this.initialize();
  }

  /**
   * Set up all global notification handlers
   */
  initialize() {
    if (this.initialized) return;

    console.log("🔔 Initializing global notification handlers");

    // Register deck unlock notification handler with the deck unlock service
    deckUnlockService.setNotificationHandlers({
      sendDeckUnlockedNotification:
        this.sendDeckUnlockedNotification.bind(this),
    });

    this.initialized = true;
  }

  /**
   * Send notification for a newly unlocked deck
   * This uses the global notification context rather than component hooks
   */
  async sendDeckUnlockedNotification(deck, skill, lesson) {
    try {
      console.log(`🔔 Sending notification for unlocked deck: ${deck.name}`);

      // Create the notification object
      const notification = createDeckUnlockedNotification(deck, skill, lesson);

      // Add to global notification system
      await addGlobalNotification(notification);

      return true;
    } catch (error) {
      console.error("Error sending deck unlock notification:", error);
      return false;
    }
  }
}

// Create a singleton instance
const notificationService = new NotificationInitializationService();

export default notificationService;
