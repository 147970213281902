// src/helpers/UserAuthContextProvider.js
import { createContext, useContext, useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInAnonymously,
  getAuth,
  // Note: We are redefining deleteCurrentUser below so we don't import it from firebase/auth
  EmailAuthProvider,
  linkWithCredential,
  linkWithPopup,
  linkWithRedirect,
  deleteUser,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase.utils";

const UserContext = createContext();
const EARLY_ACCESS_ENABLED = true; // Toggle feature flag

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: null,
    membership: null,
    loading: true,
    error: null,
  });

  // New state for role management
  const [userRole, setUserRole] = useState(null);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  // Listen for Firebase auth state changes to persist the session on refresh.
  // Modify your auth state listener
  useEffect(() => {
    let isMounted = true;
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!isMounted) return;

      try {
        if (firebaseUser) {
          // Maintain Firebase user instance
          const token = await firebaseUser.getIdTokenResult(true);
          setAuthState({
            user: firebaseUser,
            membership: token.claims.membership || "guest",
            loading: false,
          });
          console.log("Auth state updated:", {
            uid: firebaseUser?.uid,
            email: firebaseUser?.email,
            claims: token?.claims,
          });
        } else {
          setAuthState({ user: null, loading: false, error: null });
          // Reset role state when logged out
          setUserRole(null);
          setIsTeacher(false);
          setIsAdmin(false);
        }
      } catch (error) {
        setAuthState((prev) => ({
          ...prev,
          loading: false,
          error: error.message,
        }));
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);

  // New effect to fetch and monitor user role
  useEffect(() => {
    if (authState.user?.uid) {
      const userDocRef = doc(db, "users", authState.user.uid);

      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserRole(userData.role || "student");
            setIsTeacher(
              userData.role === "teacher" || userData.role === "admin"
            );
            setIsAdmin(userData.role === "admin");
          } else {
            // If the user doc doesn't exist, role defaults will be set when creating the user
            setUserRole("student");
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      };

      fetchUserData();
    }
  }, [authState.user]);

  const handleUpdate = async () => {
    const onboardingRef = doc(db, "users", `${auth.currentUser.uid}`);
    try {
      await updateDoc(onboardingRef, {
        membership: "george",
        email: auth.currentUser.email,
      });
      await auth.currentUser.reload();
      await auth.currentUser.getIdToken(true);
    } catch (err) {
      console.log(err);
    }
  };

  const checkFirstTimeUser = async (userId) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data().isFirstTime; // assume you have an "isFirstTime" field
    } else {
      await setDoc(docRef, {
        isFirstTime: true,
        role: "student", // Default role for new users
      });
      return true;
    }
  };

  const getMembership = async (user) => {
    if (!user) return null;

    try {
      // Use direct Firebase user reference
      const token = await user.getIdTokenResult(true);
      return token.claims.membership || null;
    } catch (error) {
      console.error("Error getting membership:", error);
      return null;
    }
  };

  // New function to check if a user has early access permission
  const checkEarlyAccessAuthorization = async (email) => {
    if (!EARLY_ACCESS_ENABLED) return true; // Skip check if feature disabled

    try {
      // Query the authorized_users collection to check if this email is approved
      const authUsersRef = collection(db, "authorized_users");
      const q = query(
        authUsersRef,
        where("email", "==", email),
        where("status", "==", "approved")
      );
      const querySnapshot = await getDocs(q);

      return !querySnapshot.empty; // User is authorized if we found a matching document
    } catch (error) {
      console.error("Error checking early access authorization:", error);
      return false;
    }
  };

  const waitForClaimUpdate = async (user, timeout = 5000, interval = 1000) => {
    const startTime = Date.now();

    const checkClaim = async () => {
      try {
        await user?.getIdToken(true);
        const tokenResult = await user?.getIdTokenResult();
        const membership = tokenResult.claims.membership;
        if (membership === "george") {
          return membership;
        }

        // If the claim hasn't been updated yet and we haven't timed out, check again
        if (Date.now() - startTime < timeout) {
          return new Promise((resolve) => {
            setTimeout(() => resolve(checkClaim()), interval);
          });
        } else {
          throw new Error("Timed out waiting for custom claim update.");
        }
      } catch (error) {
        console.warn("Error getting custom claims:", error);
        throw error;
      }
    };

    return checkClaim();
  };

  const createUser = async (email, password) => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      const usercred = await linkWithCredential(auth.currentUser, credential);
      const newUser = usercred.user;
      // Optionally update user state: setUser(newUser);
      await sendEmailVerification(auth.currentUser);
      await handleUpdate();
      await waitForClaimUpdate(auth.currentUser);
      localStorage.setItem("isAuthenticated", "true");
    } catch (error) {
      console.log("Error upgrading anonymous account", error);
    }
  };

  const deleteCurrentUserAccount = async () => {
    if (!auth.currentUser) {
      console.error("No user is currently signed in.");
      return;
    }

    const userId = auth.currentUser.uid;
    const userRef = doc(db, "users", userId); // Assuming a 'users' collection

    try {
      await deleteDoc(userRef);
    } catch (error) {
      console.error("Error deleting user data from Firestore:", error);
    }

    try {
      await deleteUser(auth.currentUser);
      console.log("User account deleted from Firebase Auth");
    } catch (error) {
      console.error("Error deleting user from Firebase Auth:", error);
    }
  };

  const googleRegistration = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      await linkWithPopup(auth.currentUser, googleAuthProvider);
      await sendEmailVerification(auth.currentUser);
      await handleUpdate();
      await waitForClaimUpdate(auth.currentUser);
      navigate("/dashboard"); // Replace with your actual dashboard route
      localStorage.setItem("isAuthenticated", "true");
    } catch (error) {
      console.log("Error upgrading anonymous account", error);
    }
  };

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // onAuthStateChanged will update the user state automatically.
    } catch (error) {
      console.error("Error signing in:", error);
      throw error; // Propagate the error to the caller
    }
  };

  // Modified googleSignIn function
  const googleSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();

    try {
      // First, get the user's Google credentials without fully signing in
      const result = await signInWithPopup(auth, googleAuthProvider);
      const userEmail = result.user.email;

      // Check if user is authorized for early access
      const isAuthorized = await checkEarlyAccessAuthorization(userEmail);

      if (!isAuthorized) {
        // Sign out the user since they're not authorized
        await signOut(auth);
        throw new Error("early_access_unauthorized");
      }

      // Proceed with normal flow if authorized
      localStorage.setItem("isAuthenticated", "true");

      const user = result.user;
      if (user) {
        const isFirstTime = await checkFirstTimeUser(user?.uid);
        const membership = await getMembership(user);
        const idToken = await user.getIdToken();

        if (isFirstTime) {
          // navigate("/onboarding");
          navigate("/early-access-onboarding");
        } else {
          membership === "guest"
            ? navigate("/onboarding")
            : navigate("/dashboard");
        }
      }
    } catch (error) {
      // Special error handling for early access unauthorized errors
      if (error.message === "early_access_unauthorized") {
        console.log("User not authorized for early access");
        return { error: "early_access_unauthorized" };
      }
      console.log("Error logging in with Google", error);
      return { error: error.message };
    }
  };

  const guestLogin = async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      const currentUser = userCredential.user;
      const maxAttempts = 10;
      const interval = 1000;
      let attempts = 0;
      let membership;
      do {
        await new Promise((resolve) => setTimeout(resolve, interval));
        await currentUser.reload();
        membership = getMembership(currentUser);
        attempts++;
      } while (!membership && attempts < maxAttempts);

      if (membership) {
        localStorage.setItem("isAuthenticated", "true");

        if (membership === "george") {
          navigate("/dashboard");
        } else {
          navigate("/onboarding");
        }
      } else {
        console.warn("customAttributes not available after polling");
      }
    } catch (error) {
      console.error("Error logging in as a guest:", error);
    }
  };

  const logout = () => {
    if (auth.currentUser) {
      signOut(auth)
        .then(() => {
          localStorage.removeItem("isAuthenticated");
          navigate("/login"); // Redirect to the login page after logout
        })
        .catch((error) => {
          console.error("Error logging out:", error);
        });
    }
  };

  // New methods for role management
  const setUserAsTeacher = async () => {
    if (!authState.user?.uid)
      return { success: false, error: "User not authenticated" };

    try {
      const userDocRef = doc(db, "users", authState.user.uid);
      await updateDoc(userDocRef, {
        role: "teacher",
        teacherProfile: {
          bio: "",
          specialties: [],
          teachingLanguages: ["zu"], // Default to isiZulu
          availability: [],
        },
      });

      setUserRole("teacher");
      setIsTeacher(true);
      return { success: true };
    } catch (error) {
      console.error("Error setting user as teacher:", error);
      return { success: false, error: error.message };
    }
  };

  const setUserAsStudent = async () => {
    if (!authState.user?.uid)
      return { success: false, error: "User not authenticated" };

    try {
      const userDocRef = doc(db, "users", authState.user.uid);
      await updateDoc(userDocRef, {
        role: "student",
      });

      setUserRole("student");
      setIsTeacher(false);
      return { success: true };
    } catch (error) {
      console.error("Error setting user as student:", error);
      return { success: false, error: error.message };
    }
  };

  return (
    <UserContext.Provider
      value={{
        createUser,
        ...authState,
        deleteCurrentUser: deleteCurrentUserAccount,
        guestLogin,
        logout,
        signIn,
        googleSignIn,
        // Add new value
        EARLY_ACCESS_ENABLED,
        googleRegistration,
        getMembership,
        // New role-related values
        userRole,
        isTeacher,
        isAdmin,
        setUserAsTeacher,
        setUserAsStudent,
      }}
    >
      {/* Only render children once the loading state is false */}
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserContext);
};
