// hooks/useStorageImage.js - Enhanced with better error handling
import { useState, useEffect } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebase.utils";

export const useStorageImage = (path) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchImage = async () => {
      // If no path provided, return early
      if (!path) {
        if (isMounted) {
          setLoading(false);
          setError("No image path provided");
        }
        return;
      }

      try {
        setLoading(true);
        console.log(`Fetching image from path: ${path}`);

        const storageRef = ref(storage, path);
        const downloadUrl = await getDownloadURL(storageRef);

        if (isMounted) {
          setUrl(downloadUrl);
          setLoading(false);
        }
      } catch (err) {
        console.error(`Error loading image from ${path}:`, err);

        // Only set error state if component is still mounted
        if (isMounted) {
          setError(err.message || "Failed to load image");
          setLoading(false);

          // Try to use a default image based on category if available
          if (path.includes("dictionary")) {
            setUrl("/images/defaults/dictionary-default.png");
          } else if (path.includes("coaching")) {
            setUrl("/images/defaults/coaching-default.png");
          } else {
            setUrl("/images/defaults/product-default.png");
          }
        }
      }
    };

    fetchImage();

    // Clean up function
    return () => {
      isMounted = false;
    };
  }, [path]);

  return { url, loading, error };
};

export default useStorageImage;
