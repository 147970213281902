// components/modals/streak-day-detail-modal.jsx - Mobile Responsive Version
import React, { useMemo } from "react";

const DayDetailModal = ({ day, onClose, userStats }) => {
  // Get direct streak data from userStats for this day
  const directStreakActivities = useMemo(() => {
    if (!day || !userStats?.streak_activities) return [];
    return userStats.streak_activities[day.formattedDate] || [];
  }, [day, userStats]);

  // Use either the passed streak activities or our direct lookup
  const displayActivities = useMemo(() => {
    if (!day) return [];
    return day.streakActivities?.length > 0
      ? day.streakActivities
      : directStreakActivities;
  }, [day, directStreakActivities]);

  // Now do the early return after all hooks have been called
  if (!day) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-3 sm:p-4">
      <div className="bg-white rounded-lg p-3 sm:p-4 w-full max-w-md mx-auto max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-3 sm:mb-4">
          <h3 className="text-lg sm:text-xl font-bold">
            Activity for {day.date.toLocaleDateString()}
          </h3>
          <button onClick={onClose} className="text-gray hover:text-red">
            ✕
          </button>
        </div>

        <div className="space-y-3 sm:space-y-4">
          {/* Summary section */}
          <div className="flex justify-between items-center p-2 sm:p-3 bg-darkgray bg-opacity-20 rounded">
            <div>
              <p className="font-bold text-sm sm:text-base">XP Earned</p>
              <p className="text-xl sm:text-2xl">{day.xp || 0}</p>
            </div>
            <div className="text-2xl sm:text-3xl">
              {displayActivities.length > 0 ? "🔥" : ""}
            </div>
          </div>

          {/* Activity breakdown */}
          <div className="space-y-2">
            <h4 className="font-bold text-sm sm:text-base">
              Activity Breakdown
            </h4>

            <div className="grid grid-cols-2 gap-2">
              <div className="border border-darkgray p-2 rounded">
                <p className="text-xs sm:text-sm text-gray">Lessons</p>
                <p className="font-bold text-sm sm:text-base">
                  {day.activityDetails?.lessons_completed || 0} completed
                </p>
              </div>

              <div className="border border-darkgray p-2 rounded">
                <p className="text-xs sm:text-sm text-gray">Practice</p>
                <p className="font-bold text-sm sm:text-base">
                  {day.activityDetails?.practice_questions || 0} questions
                </p>
              </div>

              <div className="border border-darkgray p-2 rounded">
                <p className="text-xs sm:text-sm text-gray">Flashcards</p>
                <p className="font-bold text-sm sm:text-base">
                  {day.activityDetails?.flashcard_sessions || 0} sessions
                </p>
              </div>

              <div className="border border-darkgray p-2 rounded">
                <p className="text-xs sm:text-sm text-gray">Words Learned</p>
                <p className="font-bold text-sm sm:text-base">
                  {day.activityDetails?.vocabulary_learned || 0} words
                </p>
              </div>
            </div>
          </div>

          {/* Streak contribution */}
          <div className="p-2 sm:p-3 rounded border-2 border-b-4 border-green">
            <h4 className="font-bold text-sm sm:text-base">
              Streak Contribution
            </h4>
            {displayActivities.length > 0 ? (
              <ul className="mt-2 space-y-1">
                {displayActivities.map((activity, index) => (
                  <li key={index} className="flex items-center gap-2 text-sm">
                    <span>
                      {activity === "lesson"
                        ? "📚"
                        : activity === "practice"
                        ? "✏️"
                        : activity === "flashcard"
                        ? "🔄"
                        : "👨‍🏫"}
                    </span>
                    <span className="capitalize">{activity}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray italic text-xs sm:text-sm mt-1">
                No streak activities on this day
              </p>
            )}
          </div>
        </div>

        <div className="mt-3 sm:mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-3 sm:px-4 py-2 bg-green text-white rounded hover:bg-opacity-80 text-sm sm:text-base"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DayDetailModal;
