import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { useSafeAnalytics } from "../../hooks/useSafeAnalytics";

const CourseProgressTab = () => {
  const { user } = useAuth();
  const { userStats } = useSafeAnalytics();
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseData = async () => {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        // Fetch user document to get enrollment data
        const userDoc = await getDoc(doc(db, "users", user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Create a course object with data from user doc and placeholder data
          const courseProgress = {
            id: userData.currentEnroll?.course || "isiZuluSocial",
            title: userData.currentEnroll?.courseName || "isiZulu Social",
            emoji: "🇿🇦",
            language: "Zulu",
            progress: calculateProgress(userData.progress || {}),
            currentSkill: userData.currentEnroll?.currentSkill || 1,
            currentSkillName:
              userData.currentEnroll?.currentSkillName || "Basics",
            currentLesson: userData.currentEnroll?.currentLesson || 1,
            startDate: userData.createdAt,
            lastActive: userData.lastActive || new Date(),
            overallCompletion: calculateOverallCompletion(userData),
            skillsCompleted: countCompletedSkills(userData.progress || {}),
            totalSkills: 25, // Placeholder - ideally would come from course data
            recentActivity: generateRecentActivity(userData),
            // Add more data as needed
          };

          setCourseData(courseProgress);
        } else {
          setError("User data not found");
        }
      } catch (err) {
        console.error("Error fetching course data:", err);
        setError("Failed to load course data");
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [user]);

  // Helper function to calculate progress percentage - enhanced with analytics data
  const calculateProgress = (progressData) => {
    if (!progressData) return 0;

    // Use lessons_completed_count from analytics data if available
    if (userStats?.lessons_completed_count) {
      // Estimate total lessons based on course progression or fixed value
      const estimatedTotalLessons = 100; // Adjust as needed
      return Math.min(
        100,
        Math.round(
          (userStats.lessons_completed_count / estimatedTotalLessons) * 100
        )
      );
    }

    // Fall back to legacy calculation
    let completedLessons = 0;
    let totalLessons = 0;

    Object.values(progressData).forEach((skill) => {
      if (skill && typeof skill === "object") {
        Object.values(skill).forEach((lesson) => {
          totalLessons++;
          if (lesson && lesson.completed) {
            completedLessons++;
          }
        });
      }
    });

    return totalLessons > 0 ? (completedLessons / totalLessons) * 100 : 0;
  };

  // Calculate overall completion including exercises, vocab, etc.
  const calculateOverallCompletion = (userData) => {
    // Enhanced with analytics data
    if (userStats) {
      // Create a weighted calculation based on multiple factors
      const xpFactor = Math.min(1, (userStats.total_xp || 0) / 5000) * 100;
      const vocabFactor =
        Math.min(1, (userStats.vocabulary_learned_count || 0) / 500) * 100;
      const lessonFactor =
        Math.min(1, (userStats.lessons_completed_count || 0) / 50) * 100;

      // Weighted average (adjust weights as needed)
      return Math.round(
        xpFactor * 0.4 + vocabFactor * 0.3 + lessonFactor * 0.3
      );
    }

    // Fall back to legacy calculation
    return userData.progress
      ? Math.min(85, calculateProgress(userData.progress))
      : 25;
  };

  // Count completed skills
  const countCompletedSkills = (progressData) => {
    if (!progressData) return 0;

    let completedSkills = 0;

    Object.values(progressData).forEach((skill) => {
      if (skill && typeof skill === "object") {
        // Check if all lessons in this skill are completed
        const lessons = Object.values(skill);
        const allCompleted =
          lessons.length > 0 &&
          lessons.every((lesson) => lesson && lesson.completed);

        if (allCompleted) {
          completedSkills++;
        }
      }
    });

    return completedSkills;
  };

  // Generate recent activity data - enhanced with real data from analytics
  const generateRecentActivity = (userData) => {
    const activities = [];

    // Use streak_activities from userStats if available
    if (userStats?.streak_activities) {
      const today = new Date();
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(today.getDate() - 7);

      // Get dates in descending order (newest first)
      const dates = Object.keys(userStats.streak_activities)
        .filter((dateStr) => {
          const date = new Date(dateStr);
          return date >= oneWeekAgo && date <= today;
        })
        .sort((a, b) => new Date(b) - new Date(a));

      // Create activity entries for each day with streak activities
      dates.slice(0, 3).forEach((dateStr) => {
        const activities = userStats.streak_activities[dateStr];
        if (activities && activities.length > 0) {
          // Use the first activity type for this entry
          const activityType = activities[0];
          activities.push({
            type:
              activityType === "lesson"
                ? "lesson_completed"
                : activityType === "practice"
                ? "practice_completed"
                : "skill_completed",
            skillName: "Various Skills",
            date: new Date(dateStr),
          });
        }
      });

      // If we have some activities, return them
      if (activities.length > 0) return activities;
    }

    // Fall back to placeholder data if needed
    return [
      {
        type: "lesson_completed",
        skillName: "Greetings",
        lessonName: "Basic Greetings",
        date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
      },
      {
        type: "skill_completed",
        skillName: "Family",
        date: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000), // 5 days ago
      },
      {
        type: "practice_completed",
        skillName: "Basic Phrases",
        date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 days ago
      },
    ];
  };

  // Format date for display
  const formatDate = (date) => {
    if (!date) return "N/A";

    try {
      const d =
        date instanceof Date
          ? date
          : date.seconds
          ? new Date(date.seconds * 1000)
          : new Date(date);
      return d.toLocaleDateString();
    } catch (err) {
      return "N/A";
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-pulse space-y-4 w-full">
          <div className="h-6 bg-gray-300 rounded w-3/4 mx-auto"></div>
          <div className="h-24 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded w-1/2 mx-auto"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 sm:p-6 bg-red bg-opacity-10 border-2 border-red rounded-lg">
        <p className="text-red mb-2 font-bold">Error loading course data</p>
        <p className="text-sm">{error}</p>
      </div>
    );
  }

  if (!courseData) {
    return (
      <div className="text-center p-4 sm:p-6 bg-gray-100 rounded-lg">
        <p className="mb-4 text-sm sm:text-base">
          You haven't enrolled in any courses yet.
        </p>
        <Link to="/courses">
          <button className="bg-green text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-200 text-sm sm:text-base">
            Explore Courses
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6">
      {/* Course Overview Card */}
      <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
        <div className="flex items-center mb-4">
          <span className="text-3xl sm:text-4xl mr-3">{courseData.emoji}</span>
          <div>
            <h3 className="text-lg sm:text-xl font-bold">{courseData.title}</h3>
            <p className="text-gray-600 text-sm sm:text-base">
              {courseData.language} Course
            </p>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex justify-between text-xs sm:text-sm mb-1">
            <span>Overall Progress</span>
            <span>{Math.round(courseData.overallCompletion)}%</span>
          </div>
          <div className="w-full bg-darkgray border-gray border rounded-full h-2 sm:h-3">
            <div
              className="bg-green h-full rounded-full"
              style={{ width: `${courseData.overallCompletion}%` }}
            ></div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-4">
          <div className="border-gray border rounded-lg p-2 sm:p-3">
            <p className="text-xs sm:text-sm text-gray-600">Skills Completed</p>
            <p className="text-base sm:text-xl font-bold">
              {courseData.skillsCompleted}/{courseData.totalSkills}
            </p>
          </div>
          <div className="border-gray border rounded-lg p-2 sm:p-3">
            <p className="text-xs sm:text-sm text-gray-600">Current Skill</p>
            <p className="text-base sm:text-xl font-bold truncate">
              {courseData.currentSkillName}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3 sm:gap-4">
          <div className="border-gray border rounded-lg p-2 sm:p-3">
            <p className="text-xs sm:text-sm text-gray-600">Started On</p>
            <p className="text-sm sm:text-lg font-semibold">
              {formatDate(courseData.startDate)}
            </p>
          </div>
          <div className="border-gray border rounded-lg p-2 sm:p-3">
            <p className="text-xs sm:text-sm text-gray-600">Last Active</p>
            <p className="text-sm sm:text-lg font-semibold">
              {formatDate(courseData.lastActive)}
            </p>
          </div>
        </div>
      </div>

      {/* New: Analytics Insight Card - Using analytics context data */}
      {userStats && (
        <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
          <h3 className="text-base sm:text-lg font-bold mb-3">
            Your Learning Analytics
          </h3>

          <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-4">
            <div className="border-gray border rounded-lg p-2 sm:p-3">
              <p className="text-xs sm:text-sm text-gray-600">Total XP</p>
              <p className="text-base sm:text-xl font-bold">
                {userStats.total_xp || 0}
              </p>
            </div>
            <div className="border-gray border rounded-lg p-2 sm:p-3">
              <p className="text-xs sm:text-sm text-gray-600">Current Streak</p>
              <p className="text-base sm:text-xl font-bold">
                {userStats.current_streak || 0} days
              </p>
            </div>
            <div className="border-gray border rounded-lg p-2 sm:p-3">
              <p className="text-xs sm:text-sm text-gray-600">
                Lessons Completed
              </p>
              <p className="text-base sm:text-xl font-bold">
                {userStats.lessons_completed_count || 0}
              </p>
            </div>
            <div className="border-gray border rounded-lg p-2 sm:p-3">
              <p className="text-xs sm:text-sm text-gray-600">
                Vocabulary Learned
              </p>
              <p className="text-base sm:text-xl font-bold">
                {userStats.vocabulary_learned_count || 0} words
              </p>
            </div>
          </div>

          <div className="text-xs sm:text-sm text-gray-600">
            <p>
              Your learning metrics are updated in real-time as you complete
              lessons and practice sessions.
            </p>
          </div>
        </div>
      )}

      {/* Current Lesson Card */}
      <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
        <h3 className="text-base sm:text-lg font-bold mb-3">
          Continue Learning
        </h3>

        <div className="border-gray border rounded-lg p-3 sm:p-4 mb-4">
          <div className="flex items-center mb-2">
            <div className="w-8 h-8 sm:w-10 sm:h-10 bg-green bg-opacity-20 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
              <span className="text-base sm:text-lg">
                {courseData.currentSkill}
              </span>
            </div>
            <div>
              <h4 className="font-semibold text-sm sm:text-base">
                {courseData.currentSkillName}
              </h4>
              <p className="text-xs sm:text-sm text-gray-600">
                Lesson {courseData.currentLesson}
              </p>
            </div>
          </div>

          <div className="mt-3">
            <Link to="/vault">
              <button className="w-full bg-green text-white py-2 rounded-md hover:bg-opacity-90 transition-all duration-200 text-sm sm:text-base">
                Continue Learning
              </button>
            </Link>
          </div>
        </div>

        <div className="text-xs sm:text-sm text-gray-600">
          <p>
            Tip: Practice daily to maintain your streak and build your
            vocabulary faster!
          </p>
        </div>
      </div>

      {/* Recent Activity */}
      <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-darkgray shadow rounded-lg">
        <h3 className="text-base sm:text-lg font-bold mb-3">Recent Activity</h3>

        <div className="divide-y">
          {courseData.recentActivity.map((activity, index) => (
            <div key={index} className="py-3">
              <div className="flex items-start">
                <div className="w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-gray-200 flex items-center justify-center mr-3 flex-shrink-0">
                  {activity.type === "lesson_completed" && "📚"}
                  {activity.type === "skill_completed" && "🏆"}
                  {activity.type === "practice_completed" && "✏️"}
                </div>
                <div>
                  {activity.type === "lesson_completed" && (
                    <p className="font-medium text-sm sm:text-base">
                      Completed lesson in {activity.skillName}
                    </p>
                  )}
                  {activity.type === "skill_completed" && (
                    <p className="font-medium text-sm sm:text-base">
                      Completed {activity.skillName} skill
                    </p>
                  )}
                  {activity.type === "practice_completed" && (
                    <p className="font-medium text-sm sm:text-base">
                      Practiced {activity.skillName}
                    </p>
                  )}
                  <p className="text-xs text-gray-500">
                    {formatDate(activity.date)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Recommendation */}
      <div className="bg-white p-4 sm:p-6 border-2 border-b-4 border-green shadow rounded-lg">
        <h3 className="text-base sm:text-lg font-bold mb-3 text-green">
          Recommended Next Steps
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
          <div className="border-gray border rounded-lg p-3 sm:p-4 hover:bg-gray-50 transition-colors cursor-pointer">
            <div className="text-xl sm:text-2xl mb-2">🔥</div>
            <h4 className="font-semibold text-sm sm:text-base mb-1">
              Practice Daily
            </h4>
            <p className="text-xs sm:text-sm text-gray-600">
              Build a streak to reinforce your learning
            </p>
          </div>

          <div className="border-gray border rounded-lg p-3 sm:p-4 hover:bg-gray-50 transition-colors cursor-pointer">
            <div className="text-xl sm:text-2xl mb-2">🎮</div>
            <h4 className="font-semibold text-sm sm:text-base mb-1">
              Try Vocabulary Games
            </h4>
            <p className="text-xs sm:text-sm text-gray-600">
              Fun way to memorize more words
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseProgressTab;
