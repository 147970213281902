import React, { useState, useRef, useEffect } from "react";
import { TestimonialData } from "../../zombiedata/testimonials";
import { motion, useAnimation } from "framer-motion";

function Testimonials() {
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const carousel = useRef();
  const controls = useAnimation();

  // Calculate carousel dimensions
  useEffect(() => {
    const updateWidth = () => {
      if (carousel.current) {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  // Autoplay functionality
  useEffect(() => {
    let interval;

    if (autoplay) {
      interval = setInterval(() => {
        const newIndex = (activeIndex + 1) % TestimonialData.length;
        setActiveIndex(newIndex);
        scrollToIndex(newIndex);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [autoplay, activeIndex]);

  // Scroll to testimonial by index
  const scrollToIndex = (index) => {
    if (carousel.current) {
      const itemWidth =
        carousel.current.querySelector(".testimonial-card").offsetWidth + 16; // Add margin
      const scrollPosition = index * itemWidth;

      carousel.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  // Variants for animations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
    hover: {
      y: -10,
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: { type: "spring", stiffness: 400, damping: 10 },
    },
  };

  return (
    <div className="relative px-4 py-6 mx-auto">
      {/* Large quote mark decorations */}
      <motion.div
        className="absolute left-2 top-0 text-7xl font-serif text-red/10 select-none"
        initial={{ opacity: 0, x: -30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        "
      </motion.div>
      <motion.div
        className="absolute right-2 bottom-0 text-7xl font-serif text-red/10 select-none transform rotate-180"
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        "
      </motion.div>

      {/* Carousel */}
      <motion.div
        ref={carousel}
        className="overflow-hidden mx-auto relative z-10"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        onMouseEnter={() => setAutoplay(false)}
        onMouseLeave={() => setAutoplay(true)}
      >
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          animate={controls}
          className="flex relative"
          style={{
            touchAction: "pan-y",
          }}
        >
          {TestimonialData.map((item, index) => (
            <motion.div
              key={index}
              className="testimonial-card w-10/12 sm:w-[380px] mx-2 flex-shrink-0"
              variants={cardVariants}
              whileHover="hover"
            >
              <div className="h-full bg-otherwhite border-darkgray border-2 border-b-4 rounded-lg shadow-lg overflow-hidden transform transition-all">
                {/* Image container */}
                <div className="relative w-full h-64 overflow-hidden">
                  <motion.img
                    src={item.image}
                    alt={item.customer}
                    className="w-full h-full object-cover"
                    initial={{ opacity: 0, scale: 1.05 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                </div>

                {/* Content container */}
                <div className="p-4">
                  <div className="font-body font-light text-center mb-3">
                    <motion.p
                      className="italic text-sm md:text-md relative z-10"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.3 }}
                    >
                      "{item.quotes}"
                    </motion.p>
                  </div>

                  <div className="flex flex-col items-center font-body font-semibold text-center">
                    <span className="font-bold text-sm text-red">
                      {item.customer}
                    </span>
                    <span className="font-medium text-xs">
                      {item.nationality}
                    </span>
                    <div className="flex flex-col text-xs text-gray-600">
                      <span className="font-semibold">{item.product}</span>
                      <span className="font-semibold">{item.year}</span>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Navigation buttons */}
      <div className="flex justify-between absolute top-1/2 transform -translate-y-1/2 left-0 right-0 px-4 z-20">
        <motion.button
          className="w-10 h-10 rounded-full bg-white/80 shadow-md flex items-center justify-center border border-gray-200"
          onClick={() => {
            const newIndex =
              (activeIndex - 1 + TestimonialData.length) %
              TestimonialData.length;
            setActiveIndex(newIndex);
            scrollToIndex(newIndex);
          }}
          whileHover={{ scale: 1.1, backgroundColor: "rgba(255,255,255,1)" }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="text-xl">◀️</span>
        </motion.button>

        <motion.button
          className="w-10 h-10 rounded-full bg-white/80 shadow-md flex items-center justify-center border border-gray-200"
          onClick={() => {
            const newIndex = (activeIndex + 1) % TestimonialData.length;
            setActiveIndex(newIndex);
            scrollToIndex(newIndex);
          }}
          whileHover={{ scale: 1.1, backgroundColor: "rgba(255,255,255,1)" }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="text-xl">▶️</span>
        </motion.button>
      </div>

      {/* Indicator dots */}
      <div className="flex justify-center mt-6">
        {TestimonialData.map((_, index) => (
          <motion.button
            key={index}
            className={`w-3 h-3 mx-1 rounded-full ${
              activeIndex === index ? "bg-red" : "bg-gray-300"
            }`}
            onClick={() => {
              setActiveIndex(index);
              scrollToIndex(index);
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          />
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
