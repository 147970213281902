import React, { useContext, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { LearningMaterialsContext } from "../../../helpers/contexts.js";
import { ScoreContext } from "../../../helpers/ScoreContext.js";
import { useAttemptsWithNotifications } from "../../../hooks/useAttemptsHook.jsx";
import PracticeReviewModal from "./practice-review-modal.jsx";
import {
  FaStar,
  FaHeart,
  FaBook,
  FaTrophy,
  FaChartBar,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import { IoMdList } from "react-icons/io";
import PowerUpUtils from "../../../services/power-ups/power-up-utils.js";
import { useAuth } from "../../../helpers/UserAuthContextProvider.js";
// UPDATED: Remove the direct AnalyticsService import
// import { AnalyticsService } from "../../../services/enhanced-analytics.service.js";
import { useSafeAnalytics } from "../../../hooks/useSafeAnalytics"; // NEW: Import the safe analytics hook
import { toDate, isFuture } from "../../../utils/timestamp-utils";
import { useShopWithPowerUps } from "../../../hooks/useShopWithPowerUps";
import PowerUpInitializationService from "../../../services/power-ups/power-up-initialization-service.js";

/**
 * Summary component displayed after completing a practice session
 * Shows performance statistics and allows reviewing exercises
 */
const PracticeCompletionSummary = () => {
  const { user } = useAuth();
  // NEW: Use the safe analytics hook
  const { trackEvent, calculateBoostedXP, getPowerUpStatus } =
    useSafeAnalytics();

  // Using the hook pattern instead of direct context
  const { powerUpStatus } = useShopWithPowerUps();

  const navigate = useNavigate();
  const { analyticsData } = useContext(LearningMaterialsContext);
  const { score } = useContext(ScoreContext);
  const { refreshAttempts } = useAttemptsWithNotifications();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [attemptsRegained, setAttemptsRegained] = useState(0);
  const [progressToNextLife, setProgressToNextLife] = useState(0);

  const [originalXP, setOriginalXP] = useState(0);
  const [boostedXP, setBoostedXP] = useState(0);
  const [xpMultiplier, setXpMultiplier] = useState(1);
  const [hasXpBoost, setHasXpBoost] = useState(false);

  const safeAnalyticsValue = (value, defaultValue = 0) => {
    return typeof value === "number" && !isNaN(value) ? value : defaultValue;
  };

  // Enhanced safety function to handle analytics data
  const getAnalyticsValue = (data, key, fallback = 0) => {
    if (!data) return fallback;
    if (typeof data[key] === "number" && !isNaN(data[key])) return data[key];

    // Special handling for exercise results
    if (key === "exerciseResults" && data[key]) {
      return Object.keys(data[key]).length;
    }

    // Special handling for exerciseTypes
    if (key === "exerciseTypes" && data[key]) {
      return Object.entries(data[key]);
    }

    return fallback;
  };

  useEffect(() => {
    const applyXpBoost = async () => {
      if (!user?.uid || !analyticsData) return;

      try {
        // Get the ORIGINAL base XP amount - be careful not to use already-boosted values
        // First check if originalXP exists directly in analyticsData
        const baseXP =
          analyticsData.originalXP ||
          // If multiplier info exists, derive the original value
          (analyticsData.xpMultiplier &&
          analyticsData.xpMultiplier > 1 &&
          analyticsData.totalXP
            ? Math.round(analyticsData.totalXP / analyticsData.xpMultiplier)
            : analyticsData.totalXP || 0);

        console.log("Original XP value before boost:", baseXP);
        setOriginalXP(baseXP);

        // UPDATED: Use the calculateBoostedXP from useSafeAnalytics
        const result = await calculateBoostedXP(baseXP);

        if (result.hasBoost) {
          setHasXpBoost(true);
          setXpMultiplier(result.multiplier);
          setBoostedXP(result.xp);

          console.log("Boost calculation result:", {
            baseXP,
            boostedXP: result.xp,
            multiplier: result.multiplier,
          });

          // UPDATED: Using trackEvent from useSafeAnalytics
          // Analytics tracking
          trackEvent("xp_boost_applied", {
            baseXP,
            boostedXP: result.xp,
            multiplier: result.multiplier,
            source: "practice_completion",
            expiresAt: result.expiresAt
              ? toDate(result.expiresAt).toISOString()
              : null,
          });
        } else {
          // No boost, just use the original XP
          setBoostedXP(baseXP);
          setXpMultiplier(1);
          setHasXpBoost(false);
        }
      } catch (error) {
        console.error("Error applying XP boost:", error);
        // Fall back to analyticsData if there's an error
        setBoostedXP(analyticsData.totalXP || 0);
      }
    };

    if (analyticsData && user?.uid) {
      applyXpBoost();
    }
  }, [analyticsData, user?.uid, calculateBoostedXP, trackEvent]);

  useEffect(() => {
    if (user?.uid) {
      PowerUpInitializationService.initialize(user.uid).catch((error) =>
        console.error("Error initializing power-ups:", error)
      );
    }
  }, [user?.uid]);

  // Add this useEffect at the top of your component to validate data
  useEffect(() => {
    // Ensure the analytics data contains the expected number of questions
    const questionsAnswered = getAnalyticsValue(
      analyticsData,
      "questionsAnswered"
    );
    const correctAnswers = getAnalyticsValue(analyticsData, "correctAnswers");
    const incorrectAnswers = getAnalyticsValue(
      analyticsData,
      "incorrectAnswers"
    );

    console.log("Summary analytics validation:", {
      questionsAnswered,
      correctAnswers,
      incorrectAnswers,
      exerciseResults: analyticsData?.exerciseResults
        ? Object.keys(analyticsData.exerciseResults).length
        : 0,
    });

    // Validate that questions = correct + incorrect
    if (questionsAnswered !== correctAnswers + incorrectAnswers) {
      console.warn("Analytics data inconsistency detected:", {
        questionsAnswered,
        correctAnswers,
        incorrectAnswers,
        sum: correctAnswers + incorrectAnswers,
      });
    }
  }, [analyticsData]);

  useEffect(() => {
    // Log analytics data to help debug
    console.log("Practice completion analytics:", analyticsData);
  }, [analyticsData]);

  useEffect(() => {
    const checkAttemptsRegained = async () => {
      try {
        // Calculate completed sessions from localStorage or default to 0
        const sessionsCompleted =
          parseInt(localStorage.getItem("practiceSessionsCompleted") || "0") +
          1;

        // Every 6 sessions = 2 lives regained (update this logic as needed)
        const newAttemptsRegained = Math.floor(sessionsCompleted / 6) * 2;
        const newSessionCount = sessionsCompleted % 6;

        // Calculate progress percentage to next life regen (0-100)
        const progressPercent = Math.floor((newSessionCount / 6) * 100);

        // Store new count
        localStorage.setItem(
          "practiceSessionsCompleted",
          newSessionCount.toString()
        );

        // Update state
        setAttemptsRegained(newAttemptsRegained);
        setProgressToNextLife(progressPercent);

        // If attempts were regained, refresh the attempts state
        if (newAttemptsRegained > 0) {
          await refreshAttempts();
        }
      } catch (error) {
        console.error("Error checking attempts regained:", error);
      }
    };

    checkAttemptsRegained();
  }, [refreshAttempts]);

  // Handle going to home screen
  const handleContinue = () => {
    navigate("/units");
  };

  // Open review modal to see exercise performance
  const handleReview = () => {
    setShowReviewModal(true);
  };

  // Start a new practice session
  const handlePracticeAgain = () => {
    // Navigate to practice mode with flag to start immediately
    navigate("/practice", { state: { skipConfirmation: true } });
  };

  // Categorize exercise performance by type
  const exerciseTypePerformance = Object.entries(
    analyticsData?.exerciseTypes || {}
  )
    .sort(([, countA], [, countB]) => countB - countA)
    .slice(0, 3); // Get top 3 most common exercise types

  // Function to render active power-ups
  const renderActivePowerUps = () => {
    if (
      !powerUpStatus?.xpBoost?.active &&
      !powerUpStatus?.streakProtection?.active
    ) {
      return null;
    }

    return (
      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2 flex items-center">
          <span className="mr-2">⚡</span>
          Active Power-Ups
        </h3>

        <div className="space-y-2">
          {powerUpStatus.xpBoost?.active && (
            <div className="p-3 bg-gold bg-opacity-10 rounded border-b-4 border-2 border-gold">
              <div className="flex items-center">
                <span className="text-xl mr-2">⚡</span>
                <div>
                  <p className="font-bold">XP Boost Active</p>
                  <p className="text-sm">
                    {powerUpStatus.xpBoost.multiplier}× multiplier
                    {powerUpStatus.xpBoost.expiresAt && (
                      <span className="ml-1">
                        until{" "}
                        {new Date(
                          powerUpStatus.xpBoost.expiresAt
                        ).toLocaleString()}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}

          {powerUpStatus.streakProtection?.active && (
            <div className="p-3 bg-blue-100 rounded border-b-4 border-2 border-blue-500">
              <div className="flex items-center">
                <span className="text-xl mr-2">🛡️</span>
                <div>
                  <p className="font-bold">Streak Protected</p>
                  <p className="text-sm">Your streak is safe for today!</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <motion.div
      className="p-4 max-w-2xl mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="bg-white border-2 border-darkgray border-b-4 rounded shadow-lg p-6 max-w-[600px] mx-auto">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-green">Practice Complete!</h2>
          <p className="text-gray">Great job practicing your skills</p>
        </div>

        {/* Score summary */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="bg-green bg-opacity-10 p-4 rounded border-b-4 border-2 border-green">
            <div className="flex items-center">
              <span className="text-2xl mr-2">⭐️</span>
              <div>
                <h3 className="font-bold">XP Earned</h3>
                <div>
                  {hasXpBoost ? (
                    <div>
                      <p className="text-2xl font-bold">{boostedXP}</p>
                      <p className="text-xs text-gold">
                        {originalXP} × {xpMultiplier} boost
                      </p>
                    </div>
                  ) : (
                    <p className="text-2xl font-bold">
                      {analyticsData?.totalXP || boostedXP || 0}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-red bg-opacity-10 p-4 rounded border-b-4 border-2 border-red">
            <div className="flex items-center">
              <span className="text-2xl mr-2">❤️</span>
              <div>
                <h3 className="font-bold">Lives Used</h3>
                <p className="text-2xl font-bold">
                  {getAnalyticsValue(analyticsData, "incorrectAnswers")}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Performance summary */}
        <div className="mb-6">
          <h3 className="text-lg font-bold mb-2 flex items-center">
            <span className="mr-2">📊</span>
            Performance Summary
          </h3>

          <div className="grid grid-cols-3 gap-2 mb-4 text-center">
            <div className="p-3 bg-gray bg-opacity-10 rounded border-b-4 border-2 border-gray">
              <p className="text-2xl font-bold">
                {getAnalyticsValue(analyticsData, "questionsAnswered")}
              </p>
              <p className="text-sm">Questions</p>
            </div>
            <div className="p-4 bg-green bg-opacity-10 rounded border-b-4 border-2 border-green text-center">
              <p className="text-2xl font-bold text-green">
                {getAnalyticsValue(analyticsData, "correctAnswers")}
              </p>
              <p className="text-sm">Correct Answers</p>
            </div>

            <div className="p-4 bg-red bg-opacity-10 rounded border-b-4 border-2 border-red text-center">
              <p className="text-2xl font-bold text-red">
                {getAnalyticsValue(analyticsData, "incorrectAnswers")}
              </p>
              <p className="text-sm">Incorrect Answers</p>
            </div>
          </div>
          <div className="p-4 bg-gold bg-opacity-10 rounded border-b-4 border-2 border-gold text-center mb-4">
            <p className="text-2xl font-bold text-gold">
              {(() => {
                const correct = getAnalyticsValue(
                  analyticsData,
                  "correctAnswers"
                );
                const total = getAnalyticsValue(
                  analyticsData,
                  "questionsAnswered"
                );
                if (correct === 0 || total === 0) return "0%";
                return `${Math.round((correct / total) * 100)}%`;
              })()}
            </p>
            <p className="text-sm">Accuracy</p>
          </div>

          {analyticsData?.vocabularyLearned &&
            analyticsData.vocabularyLearned.length > 0 && (
              <div className="p-4 bg-gold bg-opacity-10 border-gold border-2 border-b-4 rounded mb-4">
                <div className="flex items-center mb-2">
                  <span className="mr-2">📔</span>
                  <h4 className="font-bold">Vocabulary Reviewed</h4>
                </div>
                <p>{analyticsData.vocabularyLearned.length} words practiced</p>
              </div>
            )}

          {exerciseTypePerformance.length > 0 && (
            <div className="p-4 bg-gray border-b-4 border-2 bg-opacity-10 border-darkgray rounded">
              <div className="flex items-center mb-2">
                <span className="mr-2">📚</span>
                <h4 className="font-bold">Exercise Types</h4>
              </div>
              <ul className="space-y-1">
                {exerciseTypePerformance.map(([type, count]) => (
                  <li key={type} className="flex justify-between">
                    <span className="capitalize">
                      {type.replace(/_/g, " ")}
                    </span>
                    <span className="font-bold">{count}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Active Power-Ups Section */}
          {renderActivePowerUps()}
        </div>

        {/* Lives regeneration progress */}
        <div className="mb-6">
          <h3 className="text-lg font-bold mb-2 flex items-center">
            <span className="mr-2">❤️</span>
            Lives Regeneration
          </h3>

          {attemptsRegained > 0 ? (
            <div className="p-4 bg-green border-b-4 border-2 bg-opacity-10 rounded border-green">
              <div className="flex items-center">
                <span className="text-xl mr-2">🏆</span>
                <p className="font-bold">
                  Congratulations! You earned {attemptsRegained} additional
                  lives!
                </p>
              </div>
            </div>
          ) : (
            <div className="p-4 bg-gray border-darkgray bg-opacity-10 border-b-4 border-2 rounded">
              <p className="mb-2">Practice progress towards 2 extra lives:</p>
              <div className="w-full bg-darkgray border border-gray rounded-full h-4">
                <div
                  className="bg-green h-3.5 rounded-full border-gray"
                  style={{ width: `${progressToNextLife}%` }}
                ></div>
              </div>
              <p className="text-sm mt-1 text-right">
                {Math.floor((progressToNextLife / 100) * 6)}/6 sessions
                completed
              </p>
            </div>
          )}
        </div>

        {/* Action buttons */}
        <div className="grid grid-cols-3 gap-3">
          <button
            onClick={handleReview}
            className="flex flex-col items-center border-b-4 justify-center p-3 border-2 border-gold text-gold font-bold rounded hover:bg-gold hover:bg-opacity-10"
          >
            <span className="mb-1 text-xl">📊</span>
            <span>Review</span>
          </button>

          <button
            onClick={handlePracticeAgain}
            className="flex flex-col items-center justify-center p-3 border-black border-b-4 border-2 bg-green text-black font-bold rounded hover:bg-opacity-90"
          >
            <span className="text-xl mb-1">✅</span>
            <span>Practice Again</span>
          </button>

          <button
            onClick={handleContinue}
            className="flex flex-col items-center justify-center p-3 border-b-4 border-2 border-gray text-gray font-bold rounded hover:bg-gray hover:bg-opacity-10"
          >
            <span className="text-xl mb-1">❌</span>
            <span>Exit</span>
          </button>
        </div>
      </div>

      {/* Practice review modal */}
      {showReviewModal && (
        <PracticeReviewModal
          analyticsData={analyticsData}
          onClose={() => setShowReviewModal(false)}
        />
      )}
    </motion.div>
  );
};

export default PracticeCompletionSummary;
