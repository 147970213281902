import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

/**
 * Cookie Consent Manager Component
 *
 * This component provides a GDPR-compliant cookie consent manager that allows users
 * to accept or reject different categories of cookies. It saves user preferences
 * to localStorage and can be integrated into any page.
 */
function CookieConsentManager() {
  // States for managing visibility and selections
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Always enabled
    functional: true,
    analytics: true,
    marketing: false,
  });

  // Check if user has already made cookie choices
  useEffect(() => {
    const hasConsent = localStorage.getItem("cookie-consent");

    if (!hasConsent) {
      // Show the consent banner if no preferences are saved
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000); // Delay to not interrupt initial page load animations

      return () => clearTimeout(timer);
    } else {
      // Load saved preferences
      try {
        const savedPreferences = JSON.parse(hasConsent);
        setCookiePreferences(savedPreferences);
      } catch (error) {
        console.error("Error parsing cookie preferences:", error);
        setIsVisible(true);
      }
    }
  }, []);

  // Handle accepting all cookies
  const handleAcceptAll = () => {
    const allEnabled = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };

    setCookiePreferences(allEnabled);
    localStorage.setItem("cookie-consent", JSON.stringify(allEnabled));
    setIsVisible(false);

    // You would activate actual cookies/tracking here based on preferences
    applyPreferences(allEnabled);
  };

  // Handle rejecting optional cookies
  const handleRejectAll = () => {
    const minimalPreferences = {
      necessary: true, // Always enabled
      functional: false,
      analytics: false,
      marketing: false,
    };

    setCookiePreferences(minimalPreferences);
    localStorage.setItem("cookie-consent", JSON.stringify(minimalPreferences));
    setIsVisible(false);

    // Apply minimal preferences
    applyPreferences(minimalPreferences);
  };

  // Handle saving custom preferences
  const handleSavePreferences = () => {
    localStorage.setItem("cookie-consent", JSON.stringify(cookiePreferences));
    setIsVisible(false);
    setShowDetails(false);

    // Apply custom preferences
    applyPreferences(cookiePreferences);
  };

  // Toggle individual cookie category
  const toggleCategory = (category) => {
    if (category === "necessary") return; // Cannot disable necessary cookies

    setCookiePreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Function to reopen consent manager
  const openConsentManager = () => {
    setIsVisible(true);
  };

  // Apply cookie preferences
  const applyPreferences = (preferences) => {
    // This function would integrate with your actual cookie/tracking systems
    console.log("Applying cookie preferences:", preferences);

    // Example: enable/disable Google Analytics based on analytics preference
    if (preferences.analytics) {
      // Enable analytics tracking
      console.log("Analytics tracking enabled");
    } else {
      // Disable analytics tracking
      console.log("Analytics tracking disabled");
    }

    // Add similar logic for other cookie categories
  };

  // Animation variants
  const containerVariants = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100, damping: 15 },
    },
    exit: {
      y: 100,
      opacity: 0,
      transition: { duration: 0.3 },
    },
  };

  const detailsVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: {
      height: "auto",
      opacity: 1,
      transition: { duration: 0.3 },
    },
    exit: {
      height: 0,
      opacity: 0,
      transition: { duration: 0.2 },
    },
  };

  const buttonVariants = {
    hover: { scale: 1.05, transition: { duration: 0.2 } },
    tap: { scale: 0.95, transition: { duration: 0.1 } },
  };

  return (
    <>
      {/* Cookie settings button (always visible in corner) */}
      <motion.button
        onClick={openConsentManager}
        className="fixed bottom-4 left-4 z-50 p-2 bg-white text-gray rounded-full shadow-lg border border-gray flex items-center justify-center w-10 h-10"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
        aria-label="Cookie Settings"
      >
        <span className="text-outline-gray text-2xl">🍪</span>
      </motion.button>

      {/* Main cookie consent banner */}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className="fixed bottom-0 left-0 right-0 z-50 p-4 bg-white border-t-2 border-gold shadow-lg"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="max-w-6xl mx-auto">
              {/* Main banner content */}
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                <div className="mb-4 md:mb-0 md:mr-6">
                  <h2 className="text-lg font-bold text-gray-800 mb-2 font-headers">
                    Cookie Preferences
                  </h2>
                  <p className="text-sm text-gray-600">
                    We use cookies to enhance your browsing experience, serve
                    personalized ads or content, and analyze our traffic. By
                    clicking "Accept All", you consent to our use of cookies.
                  </p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <motion.button
                    onClick={() => setShowDetails(!showDetails)}
                    className="px-4 py-2 border-2 border-gray-300 text-gray-700 rounded font-medium text-sm"
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    {showDetails ? "Hide Details" : "Customize Settings"}
                  </motion.button>
                  <motion.button
                    onClick={handleRejectAll}
                    className="px-4 py-2 border-2 border-red text-red rounded font-medium text-sm"
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    Reject All
                  </motion.button>
                  <motion.button
                    onClick={handleAcceptAll}
                    className="px-4 py-2 border-2 border-green bg-green text-white rounded font-medium text-sm"
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    Accept All
                  </motion.button>
                </div>
              </div>

              {/* Detailed settings section */}
              <AnimatePresence>
                {showDetails && (
                  <motion.div
                    className="mt-4 border-t border-gray-200 pt-4 overflow-hidden"
                    variants={detailsVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {/* Necessary cookies */}
                      <div className="bg-gray-50 p-4 rounded border border-gray-200">
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="font-medium text-gray-800">
                            Necessary Cookies
                          </h3>
                          <div className="relative inline-block w-10 align-middle select-none">
                            <input
                              type="checkbox"
                              checked={cookiePreferences.necessary}
                              disabled={true}
                              className="sr-only"
                            />
                            <div className="block h-6 bg-gray-300 rounded-full shadow-inner"></div>
                            <div
                              className={`absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-green transform translate-x-full`}
                            ></div>
                          </div>
                        </div>
                        <p className="text-xs text-gray-600">
                          These cookies are required for the website to function
                          and cannot be disabled.
                        </p>
                      </div>

                      {/* Functional cookies */}
                      <div className="bg-gray-50 p-4 rounded border border-gray-200">
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="font-medium text-gray-800">
                            Functional Cookies
                          </h3>
                          <div className="relative inline-block w-10 align-middle select-none">
                            <input
                              type="checkbox"
                              checked={cookiePreferences.functional}
                              onChange={() => toggleCategory("functional")}
                              className="sr-only"
                              id="functional-toggle"
                            />
                            <label
                              htmlFor="functional-toggle"
                              className="block h-6 bg-gray-300 rounded-full shadow-inner cursor-pointer"
                            ></label>
                            <div
                              className={`absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${
                                cookiePreferences.functional
                                  ? "bg-green transform translate-x-full"
                                  : "bg-white"
                              }`}
                            ></div>
                          </div>
                        </div>
                        <p className="text-xs text-gray-600">
                          These cookies enable personalized website
                          functionality and options like language preferences or
                          saved progress.
                        </p>
                      </div>

                      {/* Analytics cookies */}
                      <div className="bg-gray-50 p-4 rounded border border-gray-200">
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="font-medium text-gray-800">
                            Analytics Cookies
                          </h3>
                          <div className="relative inline-block w-10 align-middle select-none">
                            <input
                              type="checkbox"
                              checked={cookiePreferences.analytics}
                              onChange={() => toggleCategory("analytics")}
                              className="sr-only"
                              id="analytics-toggle"
                            />
                            <label
                              htmlFor="analytics-toggle"
                              className="block h-6 bg-gray-300 rounded-full shadow-inner cursor-pointer"
                            ></label>
                            <div
                              className={`absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${
                                cookiePreferences.analytics
                                  ? "bg-green transform translate-x-full"
                                  : "bg-white"
                              }`}
                            ></div>
                          </div>
                        </div>
                        <p className="text-xs text-gray-600">
                          These cookies help us understand how visitors interact
                          with our website, helping us improve our services.
                        </p>
                      </div>

                      {/* Marketing cookies */}
                      <div className="bg-gray-50 p-4 rounded border border-gray-200">
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="font-medium text-gray-800">
                            Marketing Cookies
                          </h3>
                          <div className="relative inline-block w-10 align-middle select-none">
                            <input
                              type="checkbox"
                              checked={cookiePreferences.marketing}
                              onChange={() => toggleCategory("marketing")}
                              className="sr-only"
                              id="marketing-toggle"
                            />
                            <label
                              htmlFor="marketing-toggle"
                              className="block h-6 bg-gray-300 rounded-full shadow-inner cursor-pointer"
                            ></label>
                            <div
                              className={`absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${
                                cookiePreferences.marketing
                                  ? "bg-green transform translate-x-full"
                                  : "bg-white"
                              }`}
                            ></div>
                          </div>
                        </div>
                        <p className="text-xs text-gray-600">
                          These cookies track your online activity to help
                          advertisers deliver more relevant advertising or to
                          limit how many times you see an ad.
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 text-center">
                      <motion.button
                        onClick={handleSavePreferences}
                        className="px-6 py-2 bg-gold text-white rounded font-medium text-sm"
                        variants={buttonVariants}
                        whileHover="hover"
                        whileTap="tap"
                      >
                        Save Preferences
                      </motion.button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Privacy policy link */}
              <div className="mt-2 text-center text-xs text-gray-500">
                View our{" "}
                <a
                  href="/privacy"
                  target="_blank"
                  className="underline text-gold hover:text-red transition-colors"
                >
                  Privacy Policy
                </a>{" "}
                for more information.
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default CookieConsentManager;
