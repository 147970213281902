// components/power-ups/active-power-ups.component.jsx
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import PowerUpManager from "../../services/power-ups/power-up-manager";
import { useAuth } from "../../helpers/UserAuthContextProvider";

/**
 * Component to display all active power-ups for a user
 */
const ActivePowerUps = () => {
  const { user } = useAuth();
  const [activePowerUps, setActivePowerUps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(0); // For updating expiry times

  // Load active power-ups
  useEffect(() => {
    let isMounted = true;

    const loadActivePowerUps = async () => {
      if (!user?.uid) return;

      setLoading(true);

      try {
        // Check for expired power-ups first
        await PowerUpManager.checkAllExpiredPowerUps(user.uid);

        // Then get active power-ups
        const powerUps = await PowerUpManager.getActivePowerUps(user.uid);

        if (isMounted) {
          setActivePowerUps(powerUps);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error loading active power-ups:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadActivePowerUps();

    // Refresh active power-ups every 60 seconds
    const intervalId = setInterval(loadActivePowerUps, 60000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [user?.uid]);

  // Update countdown every second to show real-time expiry
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Helper function to format remaining time
  const formatRemainingTime = (expiryDate) => {
    if (!expiryDate) return null;

    const now = new Date();
    const expiry = new Date(expiryDate);
    const diffMs = expiry - now;

    if (diffMs <= 0) return "Expired";

    // For time less than 1 minute, show seconds
    if (diffMs < 60 * 1000) {
      const seconds = Math.ceil(diffMs / 1000);
      return `${seconds}s`;
    }

    // For time less than 1 hour, show minutes
    if (diffMs < 60 * 60 * 1000) {
      const minutes = Math.floor(diffMs / (60 * 1000));
      const seconds = Math.floor((diffMs % (60 * 1000)) / 1000);
      return `${minutes}m ${seconds}s`;
    }

    // For time less than 1 day, show hours and minutes
    if (diffMs < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(diffMs / (60 * 60 * 1000));
      const minutes = Math.floor((diffMs % (60 * 60 * 1000)) / (60 * 1000));
      return `${hours}h ${minutes}m`;
    }

    // For longer time, show days and hours
    const days = Math.floor(diffMs / (24 * 60 * 60 * 1000));
    const hours = Math.floor(
      (diffMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    );
    return `${days}d ${hours}h`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-16">
        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-red"></div>
      </div>
    );
  }

  if (!activePowerUps.length) {
    return null; // Don't show anything if there are no active power-ups
  }

  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-3">Active Power-Ups</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {activePowerUps.map((powerUp, index) => (
          <motion.div
            key={`${powerUp.type}-${index}`}
            className={`bg-${powerUp.color}-100 p-4 rounded-lg border border-${powerUp.color}-300`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <div className="flex items-center">
              <span className="text-2xl mr-3">{powerUp.icon}</span>
              <div className="flex-grow">
                <h3 className={`font-bold text-${powerUp.color}-700`}>
                  {powerUp.name}
                </h3>
                <p className="text-sm text-gray-700">{powerUp.description}</p>

                {/* Show remaining time for power-ups with expiry */}
                {powerUp.expiresAt && (
                  <p className="text-xs mt-1 text-gray-500">
                    Expires in {formatRemainingTime(powerUp.expiresAt)}
                  </p>
                )}

                {/* Show count for power-ups with quantities */}
                {powerUp.count && !powerUp.expiresAt && (
                  <p className="text-xs mt-1 text-gray-500">
                    {powerUp.count} available
                  </p>
                )}
              </div>

              {/* For coaching sessions, show a button to schedule */}
              {powerUp.type === "coaching" && powerUp.id && (
                <button
                  className="ml-2 px-3 py-1 bg-purple-500 text-white text-xs rounded hover:bg-purple-600"
                  onClick={() => {
                    // Add schedule coaching action here
                    console.log("Schedule coaching session", powerUp.id);
                  }}
                >
                  Schedule
                </button>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ActivePowerUps;
