// src/components/settings/audio-settings-card.component.jsx
import React from "react";
import { useAudioSettings } from "../../helpers/AudioSettingsContext";

function AudioSettingsCard() {
  const { audioEnabled, toggleAudio, loading } = useAudioSettings();

  return (
    <div className="flex flex-col justify-center items-center mt-4 mb-8">
      {/* Mobile-first approach: full width container on mobile, constrained on larger screens */}
      <div className="flex flex-col justify-center items-center w-full sm:w-11/12 md:w-5/6 lg:w-4/5 xl:w-3/4">
        <h1 className="font-headers text-left mt-4 w-full font-bold text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl">
          Audio Settings
        </h1>
        <p className="text-left w-full text-sm sm:text-sm md:text-base lg:text-lg">
          Manage audio settings and preferences for your learning experience.
        </p>

        <div className="w-full mt-4 p-4 sm:p-6 border-2 border-b-4 rounded-lg">
          {loading ? (
            <div className="flex justify-center">
              <div className="animate-pulse w-12 h-6 bg-gray-200 rounded"></div>
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
              <label className="flex items-center cursor-pointer w-full mb-3 sm:mb-0">
                <span className="text-base sm:text-lg mr-3 sm:mr-4">
                  Enable Audio
                </span>
                <div className="relative">
                  {/* Toggle switch */}
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={audioEnabled}
                    onChange={toggleAudio}
                    aria-label="Toggle audio"
                  />
                  <div
                    className={`block w-12 sm:w-14 h-7 sm:h-8 rounded-full ${
                      audioEnabled ? "bg-green" : "bg-gray"
                    }`}
                  ></div>
                  <div
                    className={`absolute left-1 top-1 bg-white w-5 sm:w-6 h-5 sm:h-6 rounded-full transition-transform ${
                      audioEnabled
                        ? "transform translate-x-5 sm:translate-x-6"
                        : ""
                    }`}
                  ></div>
                </div>
                <span className="ml-3 sm:ml-4 text-xs sm:text-sm text-gray">
                  {audioEnabled ? "Audio is enabled" : "Audio is disabled"}
                </span>
              </label>

              <div className="text-3xl sm:text-4xl mt-2 sm:mt-0">
                {audioEnabled ? "🔊" : "🔇"}
              </div>
            </div>
          )}

          <p className="text-sm text-gray-500 mt-4">
            When disabled, audio will not play throughout the app. This can be
            useful for studying in quiet environments or conserving data usage.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AudioSettingsCard;
