// early-access-onboarding.page.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import EarlyAccessOnboarding from "../../components/early-access/early-access-onboarding";
import { useAuth } from "../../helpers/UserAuthContextProvider";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import AuthRedirectService from "../../services/auth-redirect.service";

const EarlyAccessOnboardingPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Add this to your onboarding component
  const updateFirestoreDirectly = async (userId) => {
    try {
      const userRef = doc(db, "authorized_users", userId);
      await updateDoc(userRef, {
        onboarding_completed: true,
        onboarding_completed_date: serverTimestamp(),
      });
      console.log("Database updated successfully");
      return true;
    } catch (error) {
      console.error("Error updating database:", error);
      return false;
    }
  };

  const handleOnboardingComplete = async () => {
    console.log("Completing onboarding for user:", user?.uid);
    if (user?.uid) {
      const result = await AuthRedirectService.updateOnboardingStatus(
        user.uid,
        true
      );
      console.log("Onboarding update result:", result);
      navigate("/dashboard", { replace: true });
    }
  };

  // Animation variants for page transitions (consistent with your app)
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      transition={{ duration: 0.3 }}
    >
      <EarlyAccessOnboarding onComplete={handleOnboardingComplete} />
    </motion.div>
  );
};

export default EarlyAccessOnboardingPage;
