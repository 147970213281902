import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  TiSocialInstagramCircular,
  TiSocialTwitterCircular,
} from "react-icons/ti";
import { TbBrandWhatsapp } from "react-icons/tb";
import { default as skyline } from "../../assets/imgs/skyline-africa.svg";
import { default as logo } from "../../assets/imgs/hero-2-min-beta.png"; // Assuming you have a logo

function Footer() {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
        duration: 0.6,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 50 },
    },
  };

  return (
    <div className="relative mt-16 w-full">
      {/* Wave divider for top of footer */}
      <div className="absolute top-0 left-0 right-0 transform -translate-y-full w-full overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 100"
          className="w-full"
        >
          <path
            fill="#FCFCEE"
            d="M0,64L60,69.3C120,75,240,85,360,80C480,75,600,53,720,48C840,43,960,53,1080,58.7C1200,64,1320,64,1380,64L1440,64L1440,100L1380,100C1320,100,1200,100,1080,100C960,100,840,100,720,100C600,100,480,100,360,100C240,100,120,100,60,100L0,100Z"
          ></path>
        </svg>
      </div>

      {/* Main footer content */}
      <motion.div
        className="pt-12 bg-gradient-to-b from-white to-gold"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="max-w-6xl mx-auto w-5/6">
          {/* Footer grid - Desktop */}
          <div className="hidden md:grid md:grid-cols-4 gap-8 mb-8">
            {/* Column 1: About */}
            <motion.div variants={itemVariants} className="flex flex-col">
              <h3 className="font-headers font-bold text-lg mb-4 text-red">
                About Us
              </h3>
              <Link
                to="/about"
                className="mb-2 hover:text-green transition-colors"
              >
                About
              </Link>
              <Link
                to="/privacy"
                className="mb-2 hover:text-green transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="mb-2 hover:text-green transition-colors"
              >
                Terms & Conditions
              </Link>
              <Link
                to="/careers"
                className="mb-2 hover:text-green transition-colors"
              >
                Join the Tribe
              </Link>
            </motion.div>

            {/* Column 2: Resources */}
            <motion.div variants={itemVariants} className="flex flex-col">
              <h3 className="font-headers font-bold text-lg mb-4 text-red">
                Resources
              </h3>
              <Link
                to="/articles"
                className="mb-2 hover:text-green transition-colors"
              >
                Articles
              </Link>
              <Link
                to="/support"
                className="mb-2 hover:text-green transition-colors"
              >
                FFAQs
              </Link>
              <Link
                to="/pricing"
                className="mb-2 hover:text-green transition-colors"
              >
                Pricing
              </Link>
              <Link
                to="/early-access"
                className="mb-2 hover:text-green transition-colors"
              >
                Early Access
              </Link>
            </motion.div>

            {/* Column 3: Follow Us */}
            <motion.div variants={itemVariants} className="flex flex-col">
              <h3 className="font-headers font-bold text-lg mb-4 text-red">
                Follow Us
              </h3>
              <a
                href="https://www.instagram.com/fastfluencyza"
                target="_blank"
                rel="noreferrer"
                className="flex items-center mb-2 hover:text-green transition-colors"
              >
                <TiSocialInstagramCircular className="text-2xl mr-2" />
                Instagram
              </a>
              <a
                href="https://www.twitter.com/fastfluencyza"
                target="_blank"
                rel="noreferrer"
                className="flex items-center mb-2 hover:text-green transition-colors"
              >
                <TiSocialTwitterCircular className="text-2xl mr-2" />
                Twitter
              </a>
              <a
                href="https://wa.me/27615180427?text=Hello%20I%20would%20like%20to%20book%20an%20information%20session%20with%20you"
                target="_blank"
                rel="noreferrer"
                className="flex items-center mb-2 hover:text-green transition-colors"
              >
                <TbBrandWhatsapp className="text-2xl mr-2" />
                WhatsApp
              </a>
            </motion.div>

            {/* Column 4: Contact */}
            <motion.div variants={itemVariants} className="flex flex-col">
              <h3 className="font-headers font-bold text-lg mb-4 text-red">
                Contact
              </h3>
              <div className="mb-4">
                <p className="mb-1">Email: info@fastfluencyafrica.com</p>
                <p className="mb-1">Phone: +27 61 518 0427</p>
              </div>
              <motion.img
                src={logo}
                alt="Fast Fluency Africa"
                className="w-32 mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.8 }}
              />
            </motion.div>
          </div>

          {/* Mobile footer (accordion or simplified) */}
          <div className="md:hidden mb-8">
            <div className="grid grid-cols-2 gap-4">
              {/* Left column */}
              <div className="flex flex-col">
                <h3 className="font-headers font-bold text-md mb-2 text-red">
                  Quick Links
                </h3>
                <Link
                  to="/about"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  About
                </Link>
                <Link
                  to="/pricing"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  Pricing
                </Link>
                <Link
                  to="/articles"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  Articles
                </Link>
                <Link
                  to="/support"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  FFAQs
                </Link>
                <Link
                  to="/privacy"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  Terms
                </Link>
              </div>

              {/* Right column */}
              <div className="flex flex-col">
                <h3 className="font-headers font-bold text-md mb-2 text-red">
                  Connect
                </h3>
                <a
                  href="https://wa.me/27615180427?text=Hello%20I%20would%20like%20to%20book%20an%20information%20session%20with%20you"
                  className="text-sm mb-1 hover:text-green transition-colors"
                  target="_blank"
                  rel="noreferrer"
                >
                  WhatsApp
                </a>
                <a
                  href="https://www.instagram.com/fastfluencyza"
                  className="text-sm mb-1 hover:text-green transition-colors"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  href="https://www.twitter.com/fastfluencyza"
                  className="text-sm mb-1 hover:text-green transition-colors"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
                <Link
                  to="/careers"
                  className="text-sm mb-1 hover:text-green transition-colors"
                >
                  Join the Tribe
                </Link>
              </div>
            </div>

            <div className="flex justify-center mt-6">
              <Link to="/">
                <motion.img
                  src={logo}
                  alt="Fast Fluency Africa"
                  className="w-24"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                />
              </Link>
            </div>
          </div>

          {/* Campaign tag */}
          <motion.div variants={itemVariants} className="text-center mb-4">
            <span className="uppercase italic text-md font-headers font-semibold">
              #savekhotso
            </span>
          </motion.div>

          {/* The Fourth Moment of the Sun */}
          <motion.div variants={itemVariants} className="text-center mb-4">
            <span className="italic font-bold text-md hover:text-green cursor-pointer">
              <Link to="/fourth-moment-of-the-sun">
                the fourth moment of the sun
              </Link>
            </span>
          </motion.div>

          {/* Social media icons (larger, centered) */}
          <motion.div
            variants={itemVariants}
            className="flex justify-center space-x-4 mb-6"
          >
            <a
              href="https://www.instagram.com/fastfluencyza"
              target="_blank"
              rel="noreferrer"
              className="hover:text-green transition-transform hover:scale-110"
            >
              <TiSocialInstagramCircular className="text-4xl" />
            </a>
            <a
              href="https://wa.me/27615180427?text=Hello%20I%20would%20like%20to%20book%20an%20information%20session%20with%20you"
              target="_blank"
              rel="noreferrer"
              className="hover:text-green transition-transform hover:scale-110"
            >
              <TbBrandWhatsapp className="text-3xl" />
            </a>
            <a
              href="https://www.twitter.com/fastfluencyza"
              target="_blank"
              rel="noreferrer"
              className="hover:text-green transition-transform hover:scale-110"
            >
              <TiSocialTwitterCircular className="text-4xl" />
            </a>
          </motion.div>
        </div>

        {/* Skyline image */}
        <motion.div variants={itemVariants} className="w-full mt-4">
          <img className="w-full" src={skyline} alt="Africa skyline" />
        </motion.div>

        {/* Copyright */}
        <motion.div
          variants={itemVariants}
          className="bg-white text-center py-2 text-xs"
        >
          <span className="font-headers font-semibold">
            COPYRIGHT FAST FLUENCY AFRICA (PTY) LTD © 2016 - 2025
          </span>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Footer;
