import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import {
  optionChosenContext,
  feedbackContext,
} from "../../../helpers/context3.js";
import PropTypes from "prop-types";
import {
  getAudioDownloadUrl,
  createAudioController,
} from "../../../utils/audio-utils";
import { useAudioSettings } from "../../../helpers/AudioSettingsContext";

function TranslationMock({ data }) {
  const { optionChosen, setOptionChosen } = useContext(optionChosenContext);
  const { feedback, setFeedback } = useContext(feedbackContext);
  const [loading, setLoading] = useState(true);
  const [sentenceAudioUrl, setSentenceAudioUrl] = useState("");
  const audioControllerRef = useRef(null);

  // Get global audio settings
  const { audioEnabled, shouldPlayAudio } = useAudioSettings();

  // Initialize audio controller
  useEffect(() => {
    audioControllerRef.current = createAudioController();
    return () => {
      if (audioControllerRef.current) {
        audioControllerRef.current.destroy();
      }
    };
  }, []);

  // Play audio at normal speed
  const handlePlayNormal = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioControllerRef.current && sentenceAudioUrl) {
      audioControllerRef.current.setUrl(sentenceAudioUrl);
      audioControllerRef.current.play(1.0);
    }
  };

  // Play audio at slower speed
  const handlePlaySlow = () => {
    // Check global audio setting
    if (!shouldPlayAudio()) {
      console.log("Audio is globally disabled");
      return;
    }

    if (audioControllerRef.current && sentenceAudioUrl) {
      audioControllerRef.current.setUrl(sentenceAudioUrl);
      audioControllerRef.current.play(0.75);
    }
  };

  // Fetch audio URL for the sentence
  useEffect(() => {
    const fetchAudioUrl = async () => {
      if (!data) return;

      try {
        // Check for audio data in different possible locations
        if (data.audio) {
          const url = await getAudioDownloadUrl(data.audio);
          setSentenceAudioUrl(url);
        } else if (data.content?.sentence?.audio) {
          const url = await getAudioDownloadUrl(data.content.sentence.audio);
          setSentenceAudioUrl(url);
        }
      } catch (err) {
        console.error("Error fetching sentence audio:", err);
      }
    };

    fetchAudioUrl();
  }, [data]);

  // Simulate loading
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  // Helper function for audio button content
  const getAudioButtonContent = (speed) => {
    if (!audioEnabled) {
      return "🔇";
    }
    return speed === 1.0 ? "▶️" : "🐢";
  };

  if (loading) {
    return (
      <motion.div
        className="flex flex-col justify-center items-center h-full min-h-[300px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <BeatLoader color={"#848717"} loading={loading} size={15} />
      </motion.div>
    );
  }

  if (!data || !data.validation?.correctAnswer) {
    return <div className="text-red">No translation data found.</div>;
  }

  return (
    <motion.div
      className="flex flex-col items-center p-2 sm:p-4 w-full mx-auto max-w-3xl lg:max-w-2xl xl:max-w-3xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-center w-full max-w-md lg:w-11/12 xl:w-10/12 max-w-[600px] mx-auto">
        {/* Render the question prompt */}
        <h3
          data-testid="exercise-question"
          className="font-headers font-semibold text-lg sm:text-xl text-left px-2 sm:px-4 mb-3 sm:mb-6"
        >
          {data.content.question}
        </h3>

        {/* Render the sentence user must translate */}
        <div
          className={`text-base sm:text-lg italic text-center mb-4 sm:mb-6 relative border-2 border-b-4 border-black font-body p-3 sm:p-4 ${
            !audioEnabled ? "text-gray-500" : ""
          }`}
          onMouseEnter={audioEnabled ? handlePlayNormal : undefined}
          onMouseLeave={() => {
            if (audioControllerRef.current) {
              audioControllerRef.current.pause();
            }
          }}
        >
          <p className="mb-2">{`"${data.content.sentence}"`}</p>

          {sentenceAudioUrl && (
            <div className="flex justify-center space-x-4 mt-2">
              <button
                onClick={handlePlayNormal}
                className={`text-outline-black text-2xl ${
                  !audioEnabled ? "opacity-50" : ""
                }`}
                title={
                  audioEnabled
                    ? "Play at normal speed"
                    : "Audio is disabled globally"
                }
              >
                {getAudioButtonContent(1.0)}
              </button>
              <button
                onClick={handlePlaySlow}
                className={`text-outline-black text-2xl ${
                  !audioEnabled ? "opacity-50" : ""
                }`}
                title={
                  audioEnabled
                    ? "Play at slow speed"
                    : "Audio is disabled globally"
                }
              >
                {getAudioButtonContent(0.75)}
              </button>
            </div>
          )}

          {/* Audio disabled message */}
          {!audioEnabled && (
            <div className="text-center text-xs text-gray-500 mt-2">
              Audio is disabled globally. Enable it in Settings.
            </div>
          )}
        </div>

        {/* The input field for user to type in translation */}
        <div className="w-full max-w-md mb-4">
          <textarea
            placeholder="Type your translation here..."
            value={optionChosen || ""}
            onChange={(e) => setOptionChosen(e.target.value)}
            className="w-full h-[200px] sm:h-[300px] p-3 border-2 border-black rounded text-base
             focus:outline-none focus:border-green
             placeholder-gray border-b-4"
            style={{ resize: "none" }}
          />
        </div>
      </div>
    </motion.div>
  );
}

TranslationMock.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      question: PropTypes.string.isRequired,
      sentence: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string,
          audio: PropTypes.any,
        }),
      ]).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
      correctAnswer: PropTypes.string.isRequired,
    }).isRequired,
    audio: PropTypes.any,
    id: PropTypes.string,
  }).isRequired,
};

export default TranslationMock;
