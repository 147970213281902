import React, { useState, useEffect } from "react";
import {
  format,
  parseISO,
  addMinutes,
  startOfWeek,
  addDays,
  isSameDay,
  isBefore,
} from "date-fns";
import { ChevronLeft, ChevronRight, Plus, X, Clock } from "lucide-react";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase.utils";
import {
  getUserTimezone,
  convertToUTC,
  convertFromUTC,
} from "../../../utils/bookings/timezoneUtils";

const HOURS = Array.from({ length: 24 }, (_, i) => i);
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const SESSION_DURATIONS = [30, 60, 90, 120]; // minutes

const CoachAvailabilityCalendar = () => {
  const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date()));
  const [availabilities, setAvailabilities] = useState([]);
  const [isCreatingSlot, setIsCreatingSlot] = useState(false);
  const [newSlot, setNewSlot] = useState({
    day: null,
    startHour: 9,
    duration: 60,
    recurring: false,
  });
  const [userTimezone, setUserTimezone] = useState("");
  const [selectedDay, setSelectedDay] = useState(0); // For mobile view - day index 0-6
  const [viewMode, setViewMode] = useState("week"); // "week" or "day"

  // Detect screen size
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      // Default to day view on mobile, week view on desktop
      setViewMode(window.innerWidth < 768 ? "day" : "week");
    };

    // Set initial state
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timezone = getUserTimezone();
    setUserTimezone(timezone);
    fetchAvailability();
  }, [currentWeek, selectedDay]);

  const fetchAvailability = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      // Calculate week range for query
      const weekStart = currentWeek;
      const weekEnd = addDays(currentWeek, 7);

      const availabilityRef = collection(db, `users/${userId}/availability`);
      const q = query(
        availabilityRef,
        where("startDateTime", ">=", weekStart),
        where("startDateTime", "<", weekEnd)
      );

      const querySnapshot = await getDocs(q);
      const slots = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        slots.push({
          id: doc.id,
          ...data,
          startDateTime: convertFromUTC(
            data.startDateTime.toDate(),
            userTimezone
          ),
          endDateTime: convertFromUTC(data.endDateTime.toDate(), userTimezone),
        });
      });

      setAvailabilities(slots);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const handlePrevWeek = () => {
    setCurrentWeek(addDays(currentWeek, -7));
  };

  const handleNextWeek = () => {
    setCurrentWeek(addDays(currentWeek, 7));
  };

  const handlePrevDay = () => {
    setSelectedDay((prev) => (prev === 0 ? 6 : prev - 1));
  };

  const handleNextDay = () => {
    setSelectedDay((prev) => (prev === 6 ? 0 : prev + 1));
  };

  const handleCellClick = (day, hour) => {
    const selectedDate = addDays(
      currentWeek,
      viewMode === "week" ? day : selectedDay
    );

    setNewSlot({
      day: selectedDate,
      startHour: hour,
      duration: 60,
      recurring: false,
    });

    setIsCreatingSlot(true);
  };

  const handleSaveSlot = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || !newSlot.day) return;

      // Create date objects
      const startDate = new Date(newSlot.day);
      startDate.setHours(newSlot.startHour, 0, 0, 0);

      const endDate = new Date(startDate);
      endDate.setMinutes(endDate.getMinutes() + newSlot.duration);

      // Check for conflicts
      const hasConflict = availabilities.some((slot) => {
        const slotStart = new Date(slot.startDateTime);
        const slotEnd = new Date(slot.endDateTime);

        // Check if new slot overlaps with existing slot
        return (
          (startDate >= slotStart && startDate < slotEnd) ||
          (endDate > slotStart && endDate <= slotEnd) ||
          (startDate <= slotStart && endDate >= slotEnd)
        );
      });

      if (hasConflict) {
        alert("This time slot conflicts with an existing availability.");
        return;
      }

      // Convert to UTC for storage
      const utcStart = convertToUTC(startDate, userTimezone);
      const utcEnd = convertToUTC(endDate, userTimezone);

      // Create new availability in Firestore
      const availabilityRef = collection(db, `users/${userId}/availability`);
      await addDoc(availabilityRef, {
        startDateTime: utcStart,
        endDateTime: utcEnd,
        recurringPattern: newSlot.recurring ? "weekly" : null,
        bookable: true,
        createdAt: new Date(),
      });

      // Reset form and refresh
      setIsCreatingSlot(false);
      setNewSlot({
        day: null,
        startHour: 9,
        duration: 60,
        recurring: false,
      });

      fetchAvailability();
    } catch (error) {
      console.error("Error saving availability:", error);
    }
  };

  const handleDeleteSlot = async (slotId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      // Delete from Firestore
      const slotRef = doc(db, `users/${userId}/availability/${slotId}`);
      await deleteDoc(slotRef);

      // Refresh the data
      fetchAvailability();
    } catch (error) {
      console.error("Error deleting availability:", error);
    }
  };

  const getSlotsByDay = (dayIndex) => {
    const day = addDays(currentWeek, dayIndex);
    return availabilities.filter((slot) =>
      isSameDay(new Date(slot.startDateTime), day)
    );
  };

  // Get slots for the current selected day (mobile view)
  const selectedDaySlots = getSlotsByDay(selectedDay);

  // Toggle between day view and week view
  const toggleViewMode = () => {
    setViewMode(viewMode === "week" ? "day" : "week");
  };

  // Render the week view calendar (desktop)
  const renderWeekView = () => (
    <>
      <div className="grid grid-cols-8 gap-1 mb-2">
        <div className="text-gray-500 h-10 flex items-end justify-center">
          Hour
        </div>
        {DAYS_OF_WEEK.map((day, i) => (
          <div key={i} className="text-center">
            <div className="font-medium">{day}</div>
            <div className="text-sm">
              {format(addDays(currentWeek, i), "d")}
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-8 gap-1">
        {HOURS.map((hour) => (
          <React.Fragment key={hour}>
            <div className="h-12 border-t border-gray-200 text-sm text-gray-500 flex items-center justify-center">
              {hour === 0
                ? "12 AM"
                : hour < 12
                ? `${hour} AM`
                : hour === 12
                ? "12 PM"
                : `${hour - 12} PM`}
            </div>

            {Array.from({ length: 7 }, (_, dayIndex) => {
              const slots = getSlotsByDay(dayIndex);
              const hasSlotThisHour = slots.some((slot) => {
                const slotHour = new Date(slot.startDateTime).getHours();
                return slotHour === hour;
              });

              return (
                <div
                  key={dayIndex}
                  className={`h-12 border-t border-gray-200 ${
                    hasSlotThisHour
                      ? "bg-green bg-opacity-30"
                      : "hover:bg-darkgray hover:bg-opacity-30 cursor-pointer"
                  }`}
                  onClick={() =>
                    !hasSlotThisHour && handleCellClick(dayIndex, hour)
                  }
                >
                  {slots
                    .filter(
                      (slot) => new Date(slot.startDateTime).getHours() === hour
                    )
                    .map((slot) => (
                      <div
                        key={slot.id}
                        className="rounded-md bg-green text-white p-1 text-xs flex justify-between items-center"
                      >
                        <span className="truncate">
                          {format(new Date(slot.startDateTime), "h:mm a")} -
                          {format(new Date(slot.endDateTime), "h:mm a")}
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteSlot(slot.id);
                          }}
                          className="hover:bg-red hover:bg-opacity-50 rounded-full p-1 flex-shrink-0"
                        >
                          <X size={12} />
                        </button>
                      </div>
                    ))}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </>
  );

  // Render the day view (mobile optimized)
  const renderDayView = () => {
    const selectedDate = addDays(currentWeek, selectedDay);

    return (
      <>
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handlePrevDay}
            className="p-2 rounded-full hover:bg-darkgray"
          >
            <ChevronLeft size={20} />
          </button>
          <div className="text-center">
            <div className="font-medium">{DAYS_OF_WEEK[selectedDay]}</div>
            <div className="text-sm font-bold">
              {format(selectedDate, "MMMM d, yyyy")}
            </div>
          </div>
          <button
            onClick={handleNextDay}
            className="p-2 rounded-full hover:bg-darkgray"
          >
            <ChevronRight size={20} />
          </button>
        </div>

        <div className="mt-4">
          {HOURS.map((hour) => {
            const hasSlotThisHour = selectedDaySlots.some((slot) => {
              const slotHour = new Date(slot.startDateTime).getHours();
              return slotHour === hour;
            });

            return (
              <div
                key={hour}
                className={`flex items-center border-t border-gray-200 ${
                  hasSlotThisHour
                    ? "bg-green bg-opacity-10"
                    : "hover:bg-darkgray hover:bg-opacity-10"
                }`}
              >
                <div className="w-16 py-3 text-sm text-gray-500 flex items-center justify-center flex-shrink-0">
                  {hour === 0
                    ? "12 AM"
                    : hour < 12
                    ? `${hour} AM`
                    : hour === 12
                    ? "12 PM"
                    : `${hour - 12} PM`}
                </div>

                <div
                  className="flex-grow py-2 px-2 min-h-12 cursor-pointer"
                  onClick={() =>
                    !hasSlotThisHour && handleCellClick(selectedDay, hour)
                  }
                >
                  {selectedDaySlots
                    .filter(
                      (slot) => new Date(slot.startDateTime).getHours() === hour
                    )
                    .map((slot) => (
                      <div
                        key={slot.id}
                        className="rounded-md bg-green text-white p-2 text-sm flex justify-between items-center mb-1"
                      >
                        <span>
                          {format(new Date(slot.startDateTime), "h:mm a")} -
                          {format(new Date(slot.endDateTime), "h:mm a")}
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteSlot(slot.id);
                          }}
                          className="hover:bg-red hover:bg-opacity-50 rounded-full p-1 ml-2"
                        >
                          <X size={16} />
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="w-full bg-white border-2 border-b-4 border-darkgray shadow-md p-4 container mx-auto px-2 sm:px-4 py-6 sm:py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 gap-2">
        <h2 className="text-xl font-bold text-gray-800">Your Availability</h2>

        <div className="flex items-center space-x-2">
          {/* View toggle button (only show on tablet and up) */}
          <button
            onClick={toggleViewMode}
            className="hidden sm:block p-2 border-2 border-darkgray rounded-md hover:bg-darkgray hover:bg-opacity-30"
          >
            {viewMode === "week" ? "Day View" : "Week View"}
          </button>

          <div className="flex space-x-2">
            <button
              onClick={viewMode === "week" ? handlePrevWeek : handlePrevDay}
              className="p-2 rounded-full hover:bg-darkgray"
            >
              <ChevronLeft size={20} />
            </button>
            <div className="text-gray-800 font-medium">
              {viewMode === "week" ? (
                <>
                  {format(currentWeek, "MMM d")} -{" "}
                  {format(addDays(currentWeek, 6), "MMM d, yyyy")}
                </>
              ) : (
                <>{format(addDays(currentWeek, selectedDay), "MMM d, yyyy")}</>
              )}
            </div>
            <button
              onClick={viewMode === "week" ? handleNextWeek : handleNextDay}
              className="p-2 rounded-full hover:bg-darkgray"
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        {isMobile || viewMode === "day" ? renderDayView() : renderWeekView()}
      </div>

      {/* Floating add button for mobile */}
      <button
        onClick={() => {
          const now = new Date();
          const currentHour = now.getHours();
          handleCellClick(selectedDay, currentHour);
        }}
        className="md:hidden fixed bottom-20 right-6 w-14 h-14 rounded-full bg-green text-white shadow-lg flex items-center justify-center z-10"
      >
        <Plus size={24} />
      </button>

      {/* Create New Slot Modal - made responsive */}
      {isCreatingSlot && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-md">
            <h3 className="text-lg font-bold mb-4">Create Availability Slot</h3>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Date</label>
              <div className="text-lg font-medium">
                {newSlot.day && format(newSlot.day, "EEEE, MMMM d, yyyy")}
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Start Time</label>
              <div className="relative">
                <select
                  value={newSlot.startHour}
                  onChange={(e) =>
                    setNewSlot({
                      ...newSlot,
                      startHour: parseInt(e.target.value),
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded-md appearance-none"
                >
                  {HOURS.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour === 0
                        ? "12:00 AM"
                        : hour < 12
                        ? `${hour}:00 AM`
                        : hour === 12
                        ? "12:00 PM"
                        : `${hour - 12}:00 PM`}
                    </option>
                  ))}
                </select>
                <Clock
                  className="absolute right-2 top-2.5 text-gray-500"
                  size={20}
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Duration</label>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                {SESSION_DURATIONS.map((duration) => (
                  <button
                    key={duration}
                    type="button"
                    className={`p-2 border ${
                      newSlot.duration === duration
                        ? "bg-green text-white border-green"
                        : "border-gray-300 hover:bg-darkgray"
                    } rounded-md text-center`}
                    onClick={() => setNewSlot({ ...newSlot, duration })}
                  >
                    {duration} min
                  </button>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={newSlot.recurring}
                  onChange={(e) =>
                    setNewSlot({ ...newSlot, recurring: e.target.checked })
                  }
                  className="rounded border-gray-300 text-green focus:ring-green"
                />
                <span className="ml-2 text-gray-700">Repeat weekly</span>
              </label>
            </div>

            <div className="flex flex-col sm:flex-row sm:justify-end space-y-2 sm:space-y-0 sm:space-x-3">
              <button
                type="button"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-darkgray"
                onClick={() => setIsCreatingSlot(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-green text-white rounded-md hover:bg-opacity-80"
                onClick={handleSaveSlot}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachAvailabilityCalendar;
